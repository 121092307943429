import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import { urls } from "../../URLs";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import crypto from "crypto";
import { ContextMenuTrigger } from "react-contextmenu";
import Error404 from "../../distributor/error-pages/Error404";
import {
  COMPANY_LOGIN_NAME,
  COMPANY_NAME,
  SUPPORT_MOBILE_NUMBER,
  SUPPORT_EMAIL,
  LOGIN_COMPANY_NAME,
  CERTIFICATE_WEBSITE,
} from "../../utils/StringConstants";
import { COLOR_LOGIN_SCREEN_STRIPE } from "../../utils/Themer";
import { generateCaptcha } from "../../utils/GenerateCaptcha";

export default function AdminLogin() {
  const history = useHistory();
  const { login: loginUrl } = urls;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [generatedCaptcha, setGeneratedCaptcha] = useState(generateCaptcha());
  const { setUserInfo , setTransactionLoaderState } = useContext(GlobalContext);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  // const [userType, setUserType] = useState("");
  console.log(setUserInfo);
  const createHash = () => {
    return crypto.createHash("md5").update(password).digest("hex");
  };

  const loadCaptchaEnginge = (length) => {
    setGeneratedCaptcha(generateCaptcha());
  };
  const validateCaptcha = (captcha) => {
    return captcha == generatedCaptcha;
  };

  const login = async () => {
    if (validateCaptcha(captcha) == true) {
      try {
        setTransactionLoaderState(true)
        console.log("Hiii");
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          loginid: userName,
          password: password,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_API_TRANXT_API}/api/admin/login/get_login_admininfo.aspx`,
          requestOptions
        );
        const data = await res.json();
        if (data.status == "Y") {
          setUserInfo(data);
          history.push("/admin/dashboard");
        } else {
          setError("Invalid Credentials");
          loadCaptchaEnginge(4);
        }
        console.log(data);
        setTransactionLoaderState(false)
      } catch (error) {
        setTransactionLoaderState(false)
        setError("Invalid Credentials");
        loadCaptchaEnginge(4);
        console.log("error", error);
      }
    } else {
      setError("Invalid Captcha");
      loadCaptchaEnginge(4);
    }
  };

  const goToForgetPassword = () => history.push("/forget/password");

  const showPasswordClick = () => {
    setPassShow(!passShow);
  };

  useEffect(() => {
    try {
      setTransactionLoaderState(true)
      loadCaptchaEnginge(4);
      setTransactionLoaderState(false)
    } catch (error) {setTransactionLoaderState(false)}
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div>
          <div className="py-1 px-4">
            <img
              src={require("../../../assets/images/company/logo_square.png")}
              style={{ height: "10vh" }}
            />
          </div>
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{
              backgroundColor: COLOR_LOGIN_SCREEN_STRIPE,
              borderTop: "4px solid #EE343A",
            }}
          >
            <div className="row ml-auto">
              <span style={{ color: "#FFF" }} className="font-weight-bold mr-4">
                <i className="mdi mdi-phone text-light mr-1"></i>
                {SUPPORT_MOBILE_NUMBER}
              </span>
              <span style={{ color: "#FFF" }} className="font-weight-bold mr-4">
                <i className="mdi mdi-email text-light mr-1"></i>
                {SUPPORT_EMAIL}
              </span>
            </div>
          </div>
          <div style={{ maxHeight: "470px" }} className="row m-0 p-0">
            <div className="col-12 col-md-8 m-0 p-0">
              <img
                style={{ width: "100%", height: "100%" }}
                src={require("../../../assets/images/company/admin_banner.png")}
              />
            </div>
            <div className="col-12 col-md-4 m-0 p-0" style={{ height: "100%" }}>
              <div
                style={{
                  background: "#F6F7FC",
                }}
                className="card auth-form-transparent text-left px-3 py-1 m-0 h-100"
              >
                <div className="brand-logo" style={{}}>
                  <strong>
                    {/* <img
                        src={require("../../assets/images/company/logo_square.png")}
                        style={{ width: "2rem", margin: "0 1rem 1rem 0" }}
                      /> */}
                    <span style={{ fontSize: "1.3rem" }}>
                      Login to <strong>{COMPANY_LOGIN_NAME} Admin</strong>
                    </span>
                  </strong>
                  <p>India's Best Online Money transfer Platform.</p>
                </div>
                <form className="pt-1">
                  <div className="form-group">
                    <label>Username</label>
                    <div className="input-group">
                      {/* <div className="input-group-prepend bg-transparent">
                          <span
                            style={{ border: "1px solid #ced4da" }}
                            className="input-group-text  bg-transparent border-right-0"
                          >
                            <i className="mdi mdi-account-outline text-primary"></i>
                          </span>
                        </div> */}
                      <input
                        type="text"
                        className="form-control form-control-lg no-glow"
                        id="exampleInputEmail"
                        placeholder="Username"
                        onChange={(e) => {
                          console.log(context);
                          setError("");
                          setUserName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0 no-glow">
                            <i className="mdi mdi-lock-outline text-primary"></i>
                          </span>
                        </div>
                        <input
                          type={passShow ? "text" : "password"}
                          className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                          id="exampleInputPassword"
                          placeholder="Password"
                          onChange={(e) => {
                            setError("");
                            setPassword(e.target.value);
                          }}
                        />
                        <div className="input-group-prepend bg-transparent">
                          <span
                            className="input-group-text bg-transparent border-left-0 no-glow"
                            onClick={showPasswordClick}
                            style={{borderRadius:'4px'}}
                          >
                            <i
                              className={`mdi text-primary ${
                                passShow ? "mdi-eye" : "mdi-eye-off"
                              }`}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <p className="text-danger text-left p-0">
                        {formErrors.password}
                      </p>
                    </div>
                   


                  <div className="d-flex flex-row">
                    <div className="px-4 py-0">
                      <strong style={{ fontSize: "2rem" }}>
                        {generatedCaptcha}
                      </strong>
                    </div>

                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-lg no-glow"
                        id="exampleInputPassword"
                        placeholder="captcha"
                        onChange={(e) => {
                          setError("");
                          setCaptcha(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" />
                        <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                    </div>
                    {/* <a
                      href="!#"
                      onClick={(event) => {
                        event.preventDefault();
                        goToForgetPassword();
                      }}
                      className="auth-link text-muted"
                    >
                      Forgot password?
                    </a> */}
                  </div>
                  {error && <Invalid text={error} />}
                  <div className="mt-3">
                    <div
                      style={{ backgroundColor: "#00A452" }}
                      className="rounded-0 btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={() => {
                        login((user) => {
                          localStorage.setItem("PdrId", user.ReferId);
                          context.setUserInfo(user);
                        });
                      }}
                    >
                      Sign In
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="px-2 py-3 d-flex flex-row justify-content-between"
            style={{
              backgroundColor: COLOR_LOGIN_SCREEN_STRIPE,
              borderTop: "4px solid #EE343A",
              color: "#FFF",
            }}
          >
            <strong>
              {" "}
              Copyright © 2023 {COMPANY_NAME} - All Rights Reserved | Powered By{" "}
              <a
                href={CERTIFICATE_WEBSITE}
                className="text-light"
                style={{ textDecoration: "underline" }}
              >
                {COMPANY_NAME}
              </a>
            </strong>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
}

const Invalid = ({ text }) => {
  console.log("hiii");
  return (
    <div className="card-inverse-danger">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body" style={{ padding: "10px" }}>
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};
