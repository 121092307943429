import React, { useState, useEffect, useContext } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import JSONTable from "../../utils/JsonTable2";
import { Height } from "@material-ui/icons";
const UserReviewDetails = () => {
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [formData, setFormData] = useState({});
    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);

    const getAllKycReviewReport = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: "BR1004",
                adminid: "9"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/getAllKycReviewReportAdmin`,
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setFormData({ ...data.data[0], data_to_app: JSON.parse(data.data[0]?.aadhaar_verify_response) });
            } else {
                swal("Failed", data.msg, "error");
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
            swal("Failed", "Error From Server", "error");
        }
    };

    const fetchkycdata = async () => {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
    
          const raw = JSON.stringify({
            userid: "BR1004"
          });
    
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
    
          const res = await fetch(
            "https://apitranxt.bumppypay.com/api/kyc/fetchkycdata.aspx",
            requestOptions
          );
          const data = await res.json();
          console.log(data);
    
          if (data.status === "0") {
            setImages(data.data);
          } else {
            swal("Failed", "Invalid Details", "error");
          }
        } catch (error) {
          console.log("error", error);
        }
      };


    useEffect(() => {
        getAllKycReviewReport();
        fetchkycdata();
    }, []);
    return (
        <>
            <div style={{ padding: "1rem" }} className="bg-light">
                <div style={{ borderRadius: "10px" }} className="card mt-4 mx-2 p-0">
                    <div className="card-body m-0 p-1 px-2">
                        <div>
                            <div className="p-2 pl-1"
                                style={{
                                    "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                    , padding: "0px",
                                    background: "#FFFFFF",
                                    color: "#4267B2",
                                    fontWeight: "bolder",
                                    fontfamily: "Poppins !impotent",
                                    fontSize: "28px",
                                }}
                            >
                                Review Documents
                            </div>
                            <div>
                                <React.Fragment>
                                    {formData?.data_to_app && (
                                        <JSONTable data={formData?.data_to_app}
                                         />
                                    )}
                                </React.Fragment>
                            </div>
<div>
{images.map((image) => (
    <div className="row">

          <img src={image.imgpancard} className="col-4"
          style={{height:"20%" , width :"auto"}} />
      
    
          <img src={image.imgaadharfront} className="col-4" 
          style={{height:"20%" , width :"auto"}} />
     
          <img src={image.imgaadharback} className="col-4" 
          style={{height:"20%" , width :"auto"}} />
    
          </div>
        ))}
</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default UserReviewDetails;