import React, { useState, useEffect, useContext } from "react";
import '../../api_partner/custom-tabs.css';
import { Badge, Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import Select from "react-select";

const QueueManagement = () => {
    const [agentId, setAgentId] = useState("");

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    return (


        <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>

            <div style={{ background: "#fff" }}>
                {" "}
                <div
                    className="row justify-content-start m-0 px-4 py-2"
                    style={{ background: "" }}
                >
                    <h2
                        style={{
                            margin: "0",
                            fontWeight: "bolder",
                        }}
                    >
                        Queued Transactions
                    </h2>
                </div>
            </div>
            <div>

                <div>

                    <div className="card m-0 p-0 pt-3">
                        <div className="card-body m-0 p-0">



                            <div className="">
                                <div defaultActiveKey="profile" className=" tab-header justify-content-start">
                                    <div
                                        className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        Payout Queued Transactions
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Bill Payment Queued Transactions
                                    </div>

                                </div>
                                <div className="tab-content">
                                    {activeTab === 0 && <div><PayoutQueuedTransactionsTable agentId={agentId} /></div>}


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </div>

    );
};

const PayoutQueuedTransactionsTable = () => {
    const { user } = useContext(GlobalContext);
    const [formData, setFormData] = useState({
        signerName: "",
        signerEmail: "",
        signerCity: "",
        purpose: "",
        pdfFile: null,
        numPages: null,
        file: null
    });

    const [state, setState] = useState({
        amount: "",
    });

    const [selectedVendor, setSelectedVendor] = useState({});
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const OpenModal = () => {
        setShowModal(!showModal);
    };

    const getVendorAccounts = async (e) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                adminId: user.pkid,
                fromDate: user.pkid,
                toDate: user.pkid,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Settlement/getAllQueuedSettlements`,
                requestOptions
            );

            const data = await res.json();

            if (data.responseCode == "00") {
                setData(data.data);
            } else {
            }
        } catch (error) {
            console.log("error", error);
        }



    };

    const refundVerification = (tid) => {
        swal({
            title: "Are you sure?",
            text: `You want to settle this amount`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                processSettlement(tid);
            }
        });
    };

    const processSettlement = async (id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "adminid": user.pkid,
                "settlements": [
                    id
                ]
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Settlement/processPendingSettlements`,
                requestOptions
            );

            const data = await res.json();
            if (data.responseCode == "00") {
                swal("Success", data.responseMessage, "success");
            } else {
                swal("Error", "Something went wrong", "error");
            }
        } catch (error) { }


    };
    const twoDecimalPlaces = (amount) => {
        return Number(amount).toFixed(2);
    }
    const clearForm = () => {
        setFormData({
            signerName: "",
            signerEmail: "",
            signerCity: "",
            purpose: "",
            pdfFile: null,
            numPages: null,
            file: null
        });
    }

    useEffect(() => {
        getVendorAccounts()
    }
        , [])


    return (
        <div className="m-0">

            <div className="m-0 p-0 card mx-3 center-block scroll-inner">
                <table
                    className="dashboard-table-style"
                    style={{ width: "100%", overflowX: "auto" }}
                >
                    <tr>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}> {"Sr"}</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>order id</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Service</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Pipe </th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>account</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Amount</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Txn Type</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>date</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Action</th>
                    </tr>
                    {data.map((e, ind) => (
                        <tr key={ind}>
                            <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
                            <td style={{ fontSize: "1rem" }}>
                                <div>
                                    <div>{e.refrence_id}</div>
                                </div>
                            </td>
                            <td style={{ fontSize: "1rem" }}>{e.service}</td>
                            <td style={{ fontSize: "1rem" }}>{e.pipe}</td>
                            <td style={{ fontSize: "1rem" }}>  <div>
                                <div>{e.accountNumber}</div>
                                <div>{e.accountIfsc}</div>
                            </div></td>
                            <td style={{ fontSize: "1rem" }}>{twoDecimalPlaces(e.amount)}</td>
                            <td style={{ fontSize: "1rem" }}>

                                {e.transactionType}
                            </td>
                            <td style={{ fontSize: "1rem" }}>

                                {e.indate}
                            </td>
                            <td style={{ fontSize: "1rem" }}>

                                <Button
                                    style={{ borderRadius: ".4rem", border: "1.5px solid rgba(43, 193, 85, 1)", background: "rgba(43, 193, 85, 0.1) ", color: "rgba(43, 193, 85, 1)" }}
                                    onClick={() => refundVerification(e.id)}
                                >
                                    Process
                                </Button>

                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default QueueManagement;
