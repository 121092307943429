import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ExportToExcel, twoDecimalPlaces } from "../../utils/utilFuctions";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import cogoToast from "cogo-toast";
import { offsetArr } from "../../utils/CommonConfig";
import { COMPANY_LOGIN_NAME } from "../../utils/StringConstants";

const months = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const dateFormatToDisplay = (date) => {
  if (date) {
    const d = new Date(date);

    let hours = d.getHours();
    let minutes = d.getMinutes();
    let ap = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let mergeTime = hours + ":" + minutes + " " + ap;
    return `${d.getDate()} ${
      months[d.getMonth()]
    } ${d.getFullYear()} ${mergeTime}`;
  } else {
    return "";
  }
};

const paymentModes = {
  3: "Cash Deposite",
  2: "UPI/IMPS",
  5: "NEFT/RTGS",
  9: "CDM",
  8: "Fund Transfer",
  9: "CHEQUE",
};

// const paymentModes =
//   {
//     3: "Cash Deposite",
//     2: "UPI/IMPS",
//     5: "NEFT/RTGS",
//     9: "CDM",
//     8: "Fund Transfer",
//     9: "CHEQUE",
//   },
// ];

const columns = [
  // {
  //   dataField: "S_No",
  //   text: "S No.",
  //   sort: false,
  // },
  {
    dataField: "DepositeDate",
    text: "Request Date",
    sort: false,
  },
  {
    dataField: "UserName",
    text: "User Name",
    sort: false,
  },
  {
    dataField: "Reference",
    text: "Reference",
    sort: false,
  },
  {
    dataField: "BankName",
    text: "Bank name",
    sort: false,
  },
  {
    dataField: "Type",
    text: "Type",
    sort: false,
  },
  {
    dataField: "RequestDate",
    text: "Deposit Date",
    sort: false,
  },

  {
    dataField: "Amount",
    text: "Amount",
    sort: false,
  },
  {
    dataField: "TransactionId",
    text: "Transaction Id",
    sort: false,
  },
  {
    dataField: "Status",
    text: "Status",
    sort: false,
  },
  {
    dataField: "Remark2",
    text: "Remark by Dist",
    sort: false,
  },
  {
    dataField: "Remark",
    text: `${COMPANY_LOGIN_NAME} Remark`,
    sort: false,
  },

  // {
  //   dataField: "UpdateDate",
  //   text: "Update Date",
  //   sort: false,
  // },
];

const TopupViewTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const { user } = useContext(GlobalContext);
  console.log(user);

  const [ledger, setLedger] = useState([]);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const dateToStr = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate()}`;

  // const dateFormatter = (date) => {
  //   return `${date.getFullYear()}-${
  //     date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
  //   }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  // };

  const fetchTopupViewReport = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_fund_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setData(result.data);
            //   cogoToast.success(result.msg);
          } else {
            setLedger([]);
            cogoToast.error(result.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchTopupViewReport();
  }, []);
  console.log(ledger);
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="card"
            style={{
              border: "1px solid",
              width: "100%",
              borderRadius: "1rem",
              margin: "1rem",
            }}
          >
            <div className="row">
              <div className="col-12 col-sm-4 col-md-2">
                <Form.Group>
                  <Form.Label> From Date </Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control"
                    placeholderText="Start Date"
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-4 col-md-2">
               
                <Form.Group >
                  <Form.Label >
                    {" "}
                    To Date{" "}
                  </Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="form-control"
                    placeholderText="End Date"
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-4 col-md-2 mt-4 ">
                <Form.Group >
                  <Button
                    className="rounded-0"
                    variant=""
                    style={{
                                      background:"#56BE89",
                                      color:"white"
                                    }}
                    onClick={fetchTopupViewReport}
                  >
                    {"Search"}
                  </Button>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 col-md-2">
                <Form.Group >
                  <Form.Label style={{ marginLeft: "5px" }}>
                    {" "}
                    Search in Table
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control rounded-0"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-4 col mt-4">
                <div
                  style={{ cursor: "pointer"}}
                  onClick={() => {
                    ExportToExcel(`Topup_Report_${now()}`, dataToDisplay);
                  }}
                >
                  <img
                    src={require("../../../assets/images/excel.png")}
                    style={{ width: "1.6rem" }}
                  />
                  <strong style={{ marginLeft: "2px" }}>Export to Excel</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
        <div
          className="card mt-3 scroll-inner fix-width"
          style={{
            border: "1px solid",
            width: "98%",
            borderRadius: "1rem",
            margin: "1rem",
          }}
        >
          <table style={{ width: "100%" }} className="dashboard-table-style">
            <tr>
              <th
                style={{ fontSize: "0.9rem", width: " 5%" }}
                className="text-center"
              >
                {" "}
                {"Sr.No"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {"Payment ID"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {"Refernce ID"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {"Type"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {"Received By"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {"Open Balance"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {" Amount"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {"Closing Balance"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 10%" }}
                className="text-center"
              >
                {" "}
                {"Date"}
              </th>
              <th
                style={{ fontSize: "0.9rem", width: " 15%" }}
                className="text-center"
              >
                {" "}
                {" Comments"}
              </th>
            </tr>
            {dataToDisplay.map((e, ind) => (
              <tr>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {ind + 1}
                </td>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {e.pk_payment_id}
                </td>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {e.pay_reference}
                </td>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  <div className="row justify-content-center">
                    {e.pay_type == 0 ? (
                      <span class="badge rounded-pill bg-danger text-light p-2">
                        Debit
                      </span>
                    ) : (
                      <span className="flex-fill badge rounded-pill bg-success text-light p-2">
                        Credit
                      </span>
                    )}
                  </div>
                </td>

                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {e.ReceivedBy}
                </td>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {e.pay_before_balance}
                </td>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {e.pay_balance}
                </td>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {e.pay_after_balance}
                </td>
                <td style={{ fontSize: "0.8rem" }} className="text-center">
                  {" "}
                  {e.pay_indate}
                </td>
                <td
                  style={{ fontSize: "0.8rem", width: " 15%" }}
                  className="text-center"
                >
                  {" "}
                  {e.pay_user_comment}
                </td>
              </tr>
            ))}
          </table>

        </div>
     
      </div>
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex">
        <select
          className="form-control"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="" style={{border:"1px solid"}}> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export default TopupViewTable;
