import React from "react";
import { Link, withRouter } from "react-router-dom";

const ShortCuts = ({ path }) => {
  if (!path) path = "";
  console.log(path);
  return (
    <div
      style={{
        background: "#F2F7F8",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "2rem",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
      }}
    >
      <ShortCutIcons
        image={require("../../assets/images/bbps/bill.png")}
        label={"Bill Payment"}
        url={"/retailer/bill_payment"}
        selected={"bill_payment".includes(path)}
      />
      <ShortCutIcons
        image={require("../../assets/icons/prepaid recharge.png")}
        label={"Postpaid Mobile Bill"}
        url={"/retailer/recharge"}
        selected={path.includes("recharge")}
      />
      <ShortCutIcons
        image={require("../../assets/icons/electricity.png")}
        label={"Electricity Bill"}
        url={"/retailer/electricity_payment"}
        selected={path.includes("electricity_payment")}
      />
      <ShortCutIcons
        image={require("../../assets/icons/gas cylinder.png")}
        label={"Gas Booking"}
        url={"/retailer/gas_payment"}
        selected={path.includes("gas_payment")}
      />
      {/* <ShortCutIcons
        image={require("../../assets/icons/insurance.png")}
        label={"LIC"}
        url={"/retailer/lic"}
        selected={path.includes("lic")}
      /> */}
      <ShortCutIcons
        image={require("../../assets/icons/dth.png")}
        label={"DTH Recharge"}
        url={"/retailer/dth_recharge"}
        selected={path.includes("/retailer/dth_recharge")}
      />
      <ShortCutIcons
        image={require("../../assets/icons/water bill.png")}
        label={"Water Bill"}
        url={"/retailer/water_bill_payment"}
        selected={path.includes("water_bill_payment")}
      />
    </div>
  );
};
const ShortCutIcons = ({ image, label, url, selected }) => {
  return (
    <Link to={url}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          alignItems: "center",
          margin: "0rem 1rem",
        }}
      >
        <img src={image} style={{ width: selected ? "2.5rem" : "2rem" }} />
        <span style={{ color: selected ? "#035FB3" : "black" }}>{label}</span>
      </div>
    </Link>
  );
};

export default ShortCuts;
