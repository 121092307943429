import React, { Suspense, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import Spinner from "./shared/Spinner";
import Dashboard from "./dashboard/Dashboard";
import Topup,{TopupRequest} from "./topup/Topup";
import Recharge from "./Recharge/Recharge";
import Mobile from "./MobilePostpaid/Mobile";
import Cable from "./CableRecharge/Cable";
import Pipedgas from "./PipedGas/Pipedgas";
import Landline from "./LandlinePostpaid/Landline";
import Broadband from "./BroadbandPostpaid/Broadband";
import Housing from "./Housing/Housing";
import Hospital from "./Hospital/Hospital";
import Education from "./EducationFees/Education";
import HealthInsurance from "./Health Insurance/HealthInsurance";
import Municipal from "./Municipal/Municipal";
import Loan from "./LoanRepayment/Loan";
import Subscription from "./Subscription/Subscription";
import Insurance from "./Insurance/Insurance";
import Fastag from "./Fastag/Fastag";
import ElectricityPay from "./Electricity Payment/ElectricityPay";
import GasBillPay from "./Gas Bill Payment/GasBillPay";
import Life from "./Life/Life";
import MunicipalTax from "./MunicipalTax/MunicipalTax";
import DthRecharge from "./DTH Recharge/DthRecharge";
import WaterBillPay from "./Water Bill Payment/WaterBillPay";
import TopupView from "./Topup View/TopupView";
import WalletToWalletTransfer from "./Wallet To Wallet Transfer/WalletToWalletTransfer";
import BBPSReport from "./BBPS Report/BBPSReport";
import RechargeReport from "./Recharge Report/RechargeReport";
import LedgerReport from "./Ledger/LedgerReport";
import BillPayment from "./bill payment/BillPayment";
import MyProfile from "./my profile/MyProfile";
import Error404 from "./error-pages/Error404";
import Error500 from "./error-pages/Error500";
import Login2 from "../user-pages/Login2";
import PrepaidRecharge from "./MplanPrepaid/PrepaidRecharge";
import OfflineBillPayment from "./Offline Bill Payment/OfflineBillPayment";
import OfflineGasBill from "./BBPS Offline/Offline Gas Bill/OfflineGasBill";
import OfflineElectricityPay from "./BBPS Offline/Offline Electricity/OfflineElectricityPay"
import OfflineWaterBill from "./BBPS Offline/Offline Water Bill/OfflineWaterBill"
import OfflineCable from "./BBPS Offline/Offline Cable TV/OfflineCable"
import OfflinePrepaidMobileRecharge from "./BBPS Offline/Offline Prepaid Mobile/OfflineMobilePrepaidRecharge"
import OfflineInsurace from "./BBPS Offline/Offline Insurance/OfflineInsurance"
import Lic from "./LIC/Lic"
import { checkSession, SESSION_TIMER } from "../utils/Session";
import { GlobalContext } from "../Context/GlobalState";
import LoanEnquiry from "./Loan Enquiry/LoanEnquiry";
import LoanUI from "./LoansAndMore/LoanAndMore";
import HelpAndSupport from "./HelpAndSupport/HelpAndSupport";
import BbpsScreen from "./bbps-screen/BBPSScreen";
import MoneyTransfer from "../retailer/money-transfer/MoneyTransfer";
import Transactions from "./transactions/Transactions";
import VirtualPayment from "./virtual-payments/VirtualPayment";
import Settlements from "./settlements/Settlements";
import QRcode from "./QRcode/QRcode";
import InvoiceScreen from "./invoice-screen/InvoiceScreen";
import Offers from "./offers/Offers";
import DmtScreen from "./dmt/DmtScreen";
import Customers from "./customers/customers";
import AepsScreen from "./aeps/AepsScreen";
import VirtualReport from "./virtual report/VirtualReport";

const AppRoutes = () => {
  const { removeUserInfo } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    removeUserInfo();
    checkSession();
  };

  const onActive = (event) => {};

  const onAction = (event) => {};

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* Retailer */}
        <Route exact path="/user/dashboard" component={Dashboard} />
        {/* <Route exact path="/user/flight" component={FlightApp} /> */}
        <Route exact path="/user/money-transfer" component={MoneyTransfer} />
        <Route exact path="/user/bbps" component={BbpsScreen} />
        <Route exact path="/user/aeps" component={AepsScreen} />
        <Route exact path="/user/transactions" component={Transactions} />
        <Route exact path="/user/virtual_report" component={VirtualReport} />
        <Route exact path="/user/virtual-payments" component={VirtualPayment} />
        <Route exact path="/user/settlements" component={Settlements} />
        <Route exact path="/user/customers" component={Customers} />
        <Route exact path="/user/recharge" component={Recharge} />
        <Route exact path="/user/qrcode" component={QRcode} />
        <Route exact path="/user/subscription" component={Subscription} />
        <Route exact path="/user/invoices" component={InvoiceScreen} />
        <Route exact path="/user/dmt" component={DmtScreen} />
        <Route exact path="/user/offers" component={Offers} />
        <Route exact path="/user/Help-And-Support" component={HelpAndSupport} />
        <Route
          exact
          path="/user/electricity_payment"
          component={ElectricityPay}
        />

        <Route exact path="/user/gas_payment" component={GasBillPay} />
        <Route exact path="/user/Life" component={Life} />
        <Route exact path="/user/dth_recharge" component={DthRecharge} />
        <Route exact path="/user/MobilePostpaid" component={Mobile} />
        <Route exact path="/user/LandlinePostpaid" component={Landline} />
        <Route exact path="/user/BroadbandPostpaid" component={Broadband} />
        <Route exact path="/user/PipedGas" component={Pipedgas} />
        <Route exact path="/user/CableRecharge" component={Cable} />
        <Route exact path="/user/Fastag" component={Fastag} />
        <Route exact path="/user/Municipal" component={Municipal} />
        <Route exact path="/user/MunicipalTax" component={MunicipalTax} />
        <Route exact path="/user/Housing" component={Housing} />
        <Route exact path="/user/LoanRepayment" component={Loan} />
        <Route exact path="/user/EducationFees" component={Education} />
        <Route exact path="/user/Insurance" component={Insurance} />
        <Route exact path="/user/Health Insurance" component={HealthInsurance} />
        <Route exact path="/user/Subscription" component={Subscription} />
        <Route exact path="/user/Hospital" component={Hospital} />
        <Route exact path="/user/MplanPrepaid" component={PrepaidRecharge} />
        <Route exact path="/user/lic" component={Lic} />
        <Route exact path="/user/loanenquiry" component={LoanEnquiry} />
        <Route exact path="/user/LoansAndMore" component={LoanUI} />

        <Route
          exact
          path="/user/water_bill_payment"
          component={WaterBillPay}
        />
        <Route exact path="/user/bill_payment" component={BillPayment} />
        {/* Offline BBPS */}
        <Route exact path="/user/offline_bill_payment" component={OfflineBillPayment} />
        <Route exact path="/user/offline/PipedGas" component={OfflineGasBill} />
        <Route exact path="/user/offline/electricity" component={OfflineElectricityPay} />
        <Route exact path="/user/offline/water_bill" component={OfflineWaterBill} />
        <Route exact path="/user/offline/CableRecharge" component={OfflineCable} />
        <Route exact path="/user/offline/recharge" component={OfflinePrepaidMobileRecharge} />
        <Route exact path="/user/offline/insurance" component={OfflineInsurace} />

        <Route exact path="/user/topup_request" component={TopupRequest} />
        <Route exact path="/user/topup_view" component={TopupView} />
        <Route exact path="/user/my_profile" component={MyProfile} />
        <Route
          exact
          path="/user/wallet_to_wallet_transfer"
          component={WalletToWalletTransfer}
        />
        <Route exact path="/user/bbps_history" component={BBPSReport} />
        <Route
          exact
          path="/user/recharge_history"
          component={RechargeReport}
        />
        <Route exact path="/user/ledger" component={LedgerReport} />
        <Route path="/user-pages/login-2" component={Login2} />

        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />

        <Redirect to="/user-pages/login-2" />

      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
