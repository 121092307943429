import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { urls } from "../../URLs";
import { generateHashKey2 } from "../../utils/utilFuctions";
const { DMTBankitUrl } = urls;

const DMT1Table = ({
  successToast,
  errorToast,
  data,
  columns,
  remitterNumber,
  userId,
  refresh,
  setSidebarDetails,
}) => {
  const { setTransactionLoaderState,user } = useContext(GlobalContext);
  const recordPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [idx, setIdx] = useState(-1);
  const deleteBenificiary = async (benId) => {
    setTransactionLoaderState(true)

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "delete");
    urlencoded.append("remitter", remitterNumber);
    urlencoded.append("beneid", benId);
    urlencoded.append("uscode", userId);

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        DMTBankitUrl,
        requestOptions
      );

      const raw = await res.text();
      const data = JSON.parse(raw);
      successToast("Beneficiary deleted successfully");
      setTransactionLoaderState(false)
      refresh();
    } catch (error) {
      setTransactionLoaderState(false)
      successToast("something went wrong.");
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(
      Math.floor((filteredData.length + (recordPerPage - 1)) / recordPerPage)
    );
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = recordPerPage - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    const startIdx = (currentPage - 1) * recordPerPage;
    const endIdx = currentPage * recordPerPage - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (recordPerPage - 1)) / recordPerPage));
    const startIdx = 0;
    const endIdx = recordPerPage - 1;
    setTotal(data.length);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, []);

  useEffect(() => {
    setPages(Math.floor((data.length + (recordPerPage - 1)) / recordPerPage));
    const startIdx = 0;
    const endIdx = recordPerPage - 1;
    setTotal(data.length);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });

    setDataToDisplay(filteredData);
  }, [data]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
        </Form.Group>
      </div>
      <table
        style={{
          width: "100%",
        }}
      >
        <tr
          style={{
            width: "100%",
          }}
        >
          {columns.map((heading, ind) => (
            <th key={ind} style={{ fontSize: "0.9rem" }}>
              {heading.text}
            </th>
          ))}
          <th style={{ fontSize: "0.9rem" }}> {"Action"}</th>
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr style={{ backgroundColor: `${ind === idx ? "green" : ""}`,color: `${ind === idx ? "white" : ""}` }}>
            {columns.map((cell, ind) =>
              cell.dataField == "status" ? (
                e[cell.dataField] == "success" ? (
                  <td key={ind} style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-success"
                    >
                      {"Success"}
                    </label>{" "}
                  </td>
                ) : (
                  <td key={ind} style={{ fontSize: "0.8rem" }}>
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-warning"
                    >
                      {e[cell.dataField]}
                    </label>
                  </td>
                )
              ) : (
                <td key={ind} style={{ fontSize: ".8rem" }}>
                  {e[cell.dataField]}
                </td>
              )
            )}
            <td>
              <div>
                <Button
                  size="sm"
                  style={{
                    backgroundColor: "#3bafda",
                    border: "none",
                    margin: "10px 0px",
                  }}
                  className="rounded-0"
                  onClick={() => {
                    setIdx(ind);
                    setSidebarDetails(e);
                  }}
                >
                  IMPS
                </Button>
                <Button
                  size="sm"
                  style={{ backgroundColor: "#4a89dc", border: "none" }}
                  className="rounded-0"
                >
                  {" "}
                  NEFT
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    deleteBenificiary(e.recipient_id);
                  }}
                  style={{ backgroundColor: "red", border: "none" }}
                  className="rounded-0"
                >
                  {"DELETE"}
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </table>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default DMT1Table;
