import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { basicAuth } from "../../config";
import { urls } from "../../URLs";
import { BASE_URL_PAYNOW } from "../../utils/Constants";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const RechargeSidebar = ({ details }) => {
  const { username, password } = basicAuth;
  const { getAllMobileRechargeOperatorsUrl } = urls;
  const [state, setState] = useState({
    rechargeType: "Prepaid",
    mobileNumber: "",
    provider: "Choose Provider",
    amount: "",
  });
  const [rechargeType, setRechargeType] = useState("Broadband Postpaid");
  const [operators, setOperators] = useState([]);

  const payBill = async () => {
    const biller = operators.filter((operator) => {
      return state.provider == operator.billerName;
    });
    if (biller.length == 0) {
      return;
    }
    console.log(biller);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq7E_u804ASEqPvM_yW_G_4eXqt18Wn9I-n8I3WtQtS1k63fuzYkZ3II2cGaUfAh06-OButEn8YXvZ_OKTOUg7gP0eyR30BQ_8eI347G7RyBVkL94bbcqn4rlloSevdhRK_itdZf7Cy1pikoJc95VBZtpNVRo8GfCCremQfVWnDGdC-uhQO4_iWArZNQ52pD6yFCxKrKQMZoDpLPBgU_RqwND6lpbx4PqGA89-SxvMRcqNeUppHj8jYHVcp1CnK1n1ncVhEtsR0luOqY1IHgz_J-pRI-lUsGJUDsgFNCww0eb7Mlk8LWwiu4qgYXpPYLlBZf0K7b2mTMgNpH25rMS4-VahYcpxG4zXe1vKHCEHeR7MjVXiAwNh_mdR2GXlgtM9FZ3bOSHjwCYuB6J6uzOd4gNgQcUWzkM1TJ_Fcq04jq6pdB2Ne-aN8XkGNAxnrVQ4SPVwVh-NkRSZYXpdS0XG2r658waXxYLVif7Sw40A-M3V1tLMqWwNSu2rMg5NFhDevaKKiJXcuqHXdrjRSOWLNgkPHS5lmjY11bV0QenW9H7Ahv3xbre32MmYrHlkX7HxKXtlS-uTURS1gQT0_bwPBt3webYhgPJMO8wuIP1HCTlsD98IkmuLeiQ5sSuaylpGJuo22sseXh04qbA3aj_8uo"
    );

    var raw = JSON.stringify({
      amount: state.amount,
      customerMobileNo: "8700601581",
      billDetails: {
        billerId: biller[0].billerId,
        customerParams: [
          {
            name: biller[0].customerParams[0].paramName,
            value: state.amount,
          },
        ],
      },
      refId: null,
      isDirectPay: true,
    });
    console.log({
      amount: state.amount,
      customerMobileNo: "8700601581",
      billDetails: {
        billerId: biller[0].billerId,
        customerParams: [
          {
            name: biller[0].customerParams[0].paramName,
            value: state.amount,
          },
        ],
      },
      refId: null,
      isDirectPay: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillPay`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getAllOperators = async () => {
    const categoryId =
      rechargeType == "Broadband Postpaid"
        ? 1
        : rechargeType == "Landline Postpaid"
        ? 12
        : rechargeType == "Mobile Postpaid"
        ? 16
        : 1;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq6V3uK79RWBrEyPTWlfS1Qzjhr4NzYDI8biMoTl8PvcwDKuoY5QW1Nn55xdDXuCsyFVfCiy2CVRQeHaysVe2ofPMCmrvUCXFNWZ-0tWr5U-V_aZRLs6NbDAYeUKR0i0NAi9ZSppftoYff1TkQiXtKTOPEVOfO79-kkVYkfw2clbNLm7Rlsrmsb4zWUVzNikFEn9HPICqNMdU03VeK6joZZIjFW82wS1ANOU8bfmgbCtCdHGMZihpMaEbjjtm-75yFrvg5cOw38x51Ivj4GI_zc-kn6y3_0e5awDxMS2AzRN-VoUDhI3GGrR7q108ad8AH6EGQtBR6-TYUpoB4AnuSwbbWXiLDlAQTVMlbC51RSMAJL_luqhDhcJPjMHdpFgz7UzDy1vozGXhlxHTwx3vqU9IkVu65CqFf834_vZdHuWXEiRt2fHySVkBFPz_z3YlMvjzNdpaNxZhkkOxAqjyL3SFk4Id6Ms64o9td2X3ZkkMIyEjB-z4eQtTBZ5P23hZltULbf-GyhcqOw5_OWAzL8uD78rLvTWGCFQhl4IKCGfTQSkpDdxkhQNGaXIcFy45xobZvx20Zs0-X2DljZI-QGGH0rOaiglukgBXF0V05aoH_nCs25D2CQdgzu7ay-k_CA045E-6N0M07cTi_LDItHk"
    );

    var raw = JSON.stringify({
      userName: "string",
      categoryId: categoryId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(getAllMobileRechargeOperatorsUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data != null) setOperators(data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAllOperators();
  }, [rechargeType]);
  useEffect(() => {
    setOperators(details.operators);
  }, []);
  console.log(operators);
  const biller = operators.filter((operator) => {
    return state.provider == operator.billerName;
  });
  useEffect(() => {
    document
      .querySelector(".content-wrapper")
      .classList.remove("sidebar-icon-only");
  }, []);
  console.log(state.provider);
  console.log(biller);
  return (
    <nav
      className="side-sheet sidebar Sidebar23"
      id="sidebar2"
      style={Sidebar22}
    >
      <div
        style={{
          padding: "0px",
          marginLeft: "10px",
          marginRight: "10px",
          display: "flex",
        }}
        className="row"
      >
        <div
          style={{
            padding: "6px",
            display: "flex",
            alignItems: "center",
            flex: "1",
          }}
          className="col"
        >
          <strong style={{ color: "#1b68ca" }}>
            {" "}
            Mobile Recharge and Bill Payment
          </strong>
        </div>
        <div style={{ paddingRight: "0px" }}>
          <span
            style={{
              fontSize: "2rem",
              display: "flex",
              color: "#b2b2b3",
              justifyContent: "end",
              ariaHidden: "true",
              paddingRight: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only");
            }}
          >
            &times;
          </span>
        </div>
      </div>
      <Dropdown.Divider />

      <Card style={{ margin: "10px", padding: "10px" }}>
        {/* <div style={{ display: "flex", padding: "10px 0px" }}>
          <div
            style={{ display: "flex" }}
            onClick={() => {
              setState({ rechargeType: "Prepaid" });
            }}
          >
            <input type="radio" checked={state.rechargeType == "Prepaid"} />
            <span style={{ margin: "0px 0px 0px 4px", display: "flex" }}>
              Prepaid
            </span>
          </div>
          <div
            style={{ margin: "0px 0px 0px 10px", display: "flex" }}
            onClick={() => {
              setState({ ...state, rechargeType: "Postpaid" });
            }}
          >
            <input
              type="radio"
              onChange={(e) => console.log(e)}
              checked={state.rechargeType == "Postpaid"}
            />
            <span style={{ margin: "0px 0px 0px 4px", display: "flex" }}>
              Postpaid
            </span>
          </div>
        </div> */}

        {/* <Form.Group className="rounded-0" style={{ flex: "1" }}>
          <select
            className="rounded-0"
            style={{ padding: "5px", outline: "none", width: "100%" }}
            onChange={(e) => {
              setRechargeType(e.target.value);
            }}
            value={rechargeType}
          >
            <option>{"Broadband Postpaid"}</option>
            <option>{"Landline Postpaid"}</option>
            <option>{"Mobile Postpaid"}</option>
          </select>
        </Form.Group> */}
        {/* <Form.Group className="rounded-0" style={{ flex: "1" }}>
          <select
            className="rounded-0"
            style={{ padding: "5px", outline: "none", width: "100%" }}
            onChange={(e) => {
              setState({
                ...state,
                provider: e.target.value,
              });
            }}
            value={state.provider}
          >
            {operators.map((operator) => (
              <option key={operator.bId}> {operator.billerName}</option>
            ))}
          </select>
        </Form.Group> */}
        <Form.Group style={{ flex: "1", paddingRight: "10px" }}>
          <Form.Control
            type="number"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              setState({
                ...state,
                mobileNumber: e.target.value,
              });
            }}
            placeholder={`Mobile Number`}
          />
        </Form.Group>
        <div style={{ display: "flex", alignItems: "center" }}></div>
        <div style={{ display: "flex" }}>
          <Form.Group style={{ flex: "1" }}>
            <Form.Control
              onChange={(e) => {
                setState({
                  ...state,
                  amount: e.target.value,
                });
              }}
              type="number"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={"Amount"}
            />
          </Form.Group>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="rounded-0"
            style={{ margin: "0px 10px", minWidth: "11rem" }}
            variant="primary"
          >
            {"Check Offers"}
          </Button>
          <Button
            className="rounded-0"
            style={{ margin: "0px 10px", minWidth: "11rem" }}
            variant="success"
            onClick={() => {
              payBill()
            }}
          >
            {"Next"}
          </Button>
        </div>
      </Card>
      {/* <div >
            <Button className="rounded-0" variant="outline-info" style={{float:'right'}}>Online </Button>
            <Button className="rounded-0" variant="outline-info" style={{float:'right'}}> Offline</Button>
          </div> */}
    </nav>
  );
};

export default withRouter(RechargeSidebar);
