import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import MasterTariffTable from "./masterTariffTable";

function masterTariff() {
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "1rem" }}>
          <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
            <div className="card-body">
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  Master-Tariff
                </div>
                <MasterTariffTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
}

export default masterTariff;
