import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  getDiviceInfo,
} from "../../utils/utilFuctions";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import swal from "sweetalert";
import { offsetArr } from "../../utils/CommonConfig";

const months = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const dateFormatToDisplay = (date) => {
  if (date) {
    const d = new Date(date);

    let hours = d.getHours();
    let minutes = d.getMinutes();
    let ap = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let mergeTime = hours + ":" + minutes + " " + ap;
    return `${d.getDate()} ${
      months[d.getMonth()]
    } ${d.getFullYear()} ${mergeTime}`;
  } else {
    return "";
  }
};

const paymentModes = {
  3: "Cash Deposite",
  2: "UPI/IMPS",
  5: "NEFT/RTGS",
  9: "CDM",
  8: "Fund Transfer",
  9: "CHEQUE",
};

// const paymentModes =
//   {
//     3: "Cash Deposite",
//     2: "UPI/IMPS",
//     5: "NEFT/RTGS",
//     9: "CDM",
//     8: "Fund Transfer",
//     9: "CHEQUE",
//   },
// ];

const columns = [
  // {
  //   dataField: "S_No",
  //   text: "S No.",
  //   sort: false,
  // },
  {
    dataField: "fk_refer_id",
    text: "User ID",
    sort: false,
  },
  {
    dataField: "va_bene_name",
    text: "Beneficiary Name",
    sort: false,
  },
  {
    dataField: "va_mode",
    text: "Mode",
    sort: false,
  },
  {
    dataField: "va_utr_no",
    text: "UTR",
    sort: false,
  },
  {
    dataField: "va_razor_id",
    text: "REF ID",
    sort: false,
  },
  {
    dataField: "va_amount",
    text: "Amount",
    sort: false,
  },

  {
    dataField: "va_sender_accno",
    text: "Sender AC NO.",
    sort: false,
  },
  {
    dataField: "va_sender_name",
    text: "Sender Name",
    sort: false,
  },
  {
    dataField: "va_sender_ifsc",
    text: "Sender IFSC",
    sort: false,
  },
  {
    dataField: "va_indate",
    text: "Date & Time",
    sort: false,
  },
  {
    dataField: "va_reference_no",
    text: "REF",
    sort: false,
  },
  {
    dataField: "update_status",
    text: "Acceptance",
    sort: false,
  },

  // {
  //   dataField: "UpdateDate",
  //   text: "Update Date",
  //   sort: false,
  // },
];

const VirtualReportTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const { user, Profileinfo } = useContext(GlobalContext);
  console.log(user);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/aeps_print?id=${data.tid}&type=AEPS`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const dateToStr = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate()}`;

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getVirtualPaymentReport = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        retailer_id: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/master_virtual_payment_acceptence_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "0") {
            setData(data.data);
          } else {
            setData([]);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };

  useEffect(() => {
    getVirtualPaymentReport();
  }, []);
  console.log(ledger);
  return (
    <div className="card m-2">
      <span className="text-bold" style={{fontSize:'1.5rem',fontWeight:'600'}}  > Transactions</span>
      <div className="d-flex justify-content-between align-items-center">
        <div className="row px-4 align-items-center">
          <Form.Group>
            <Form.Label>{`Search`}</Form.Label>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group className="mx-2">
            <Form.Label>{`From Date`}</Form.Label>
            <DatePicker  dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-0 border-primary"
              placeholderText="Start Date"
            />
          </Form.Group>
          <Form.Group className="mx-2">
            <Form.Label>{`To Date`}</Form.Label>
            <DatePicker  dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-0 border-primary"
              placeholderText="Start Date"
            />
          </Form.Group>
        
        
          <Form.Group className="mt-4">
            <Button className="rounded-0" variant="success" onClick={() => {}}>
              {"Search"}
            </Button>
          </Form.Group>
          <div
            className="ml-5"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `Virtual_report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
            <img
              src={require("../../../assets/images/excel.png")}
              style={{ width: "1.6rem" }}
            />
            <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
          </div>
        </div>
      </div>
      <div className="scroll-inner fix-width">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "scroll" }}
        >
          <tr>
            <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
            {columns.map((heading) => (
              <th style={{ fontSize: "0.9rem" }}>{heading.text}</th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}> {ind + 10}</td>
              {columns.map((cell) =>
                cell.text == "Acceptance" ? (
                  e[cell.dataField] == "Y" ? (
                    <td style={{ fontSize: "0.8rem" }}>
                      {" "}
                      <label
                        style={{
                          padding: "4px",
                          borderRadius: "4px",
                          minWidth: "4rem",
                        }}
                        className="badge badge-success"
                      >
                        {"Accepted"}
                      </label>{" "}
                    </td>
                  ) : (
                    <td style={{ fontSize: "0.8rem" }}>
                      <Button onClick={() => {}}> Accept</Button>
                    </td>
                  )
                ) : (
                  <td style={{ fontSize: ".8rem" }}>{e[cell.dataField]}</td>
                )
              )}
              {/* <td>
                <Button
                  onClick={() => {
                    openInNewTab(e);
                  }}
                  variant="danger"
                  className="rounded-0"
                >
                  {" "}
                  Print
                </Button>
              </td> */}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </div>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex">
        <select
          className="form-control"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default VirtualReportTable;
