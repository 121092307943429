import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Dropdown } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import userAvtar from "../../../assets/images/user.png";
import pemoreLogo from "../../../assets/images/company/logo_square.png";
import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { urls } from "../../URLs";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_API_TRANXT,
} from "../../utils/Constants";
import { COMPANY_NAME_NAVBAR } from "../../utils/StringConstants";
import { generateHashKey2 } from "../../utils/utilFuctions";
const Navbar = () => {
  const history = useHistory();
  const {
    user,
    setWalletBalance,
    myAccountInfo,
    setProfileinfo,
    removeUserInfo,
    wallet,
  } = useContext(GlobalContext);
  //   console.log(wBalance);
  //const [wallet, setWallet] = useState(null);
  const [paynnowAgentId, setPaynnowAgentId] = useState("");

  const getWalletBalance = async () => {
    try {
      const raw = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getbalance_new&userid=${user.ReferId}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      console.log(data);
      setWalletBalance(data);
      //  setWallet(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  const GetProfile = () => {
    var raw = JSON.stringify({
      PdrID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/master_dist_profile_details.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setProfileinfo(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getPaynnowAgentId = async () => {
    console.log("hiii...");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/get_paynnow_agentid.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.yesbank_kyc == "Y") {
          setPaynnowAgentId(data.agentid);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetProfile();
    getWalletBalance();
    getPaynnowAgentId();
  }, []);
  console.log(wallet);
  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null || state.user.UserType !== "1" ? (
          <Redirect to="/" />
        ) : (
          <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
              <Link className="navbar-brand brand-logo-mini" to="/">
                <img src={pemoreLogo} />
              </Link>
            </div>

            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <button
                className="navbar-toggler align-self-center"
                type="button"
                // onClick={() =>
                //   document.body.classList.toggle("sidebar-icon-only")
                // }
              >
                <img
                  src={require("../../../assets/images/company/navbar_logo.png")}
                  style={{ height: "4rem", paddingRight: "1rem" }}
                />
                {/* <a href={"/"} style={{ fontSize: "1.5rem",textDecoration:'none' }}>
                  {COMPANY_NAME_NAVBAR}
                </a> */}
              </button>

              <ul className="navbar-nav navbar-nav-right">
                {state.user == null ? null : (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        getWalletBalance();
                      }}
                    >
                      <div className="navbar-profile ">
                        <a
                          style={{
                            fontWeight: "600",
                            fontSize: "1.5rem",
                            flexDirection: "row",
                          }}
                        >
                          <a style={{ paddingRight: "2px" }}>₹</a>
                          {`INR ${
                            wallet
                              ? wallet.WalletBalance
                              : state.user.walletBalance
                          } `}
                        </a>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}
                {/* {state.user == null ? null : (
                  <li onClick={getWalletBalance}>
                    <span
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        paddingRight: "2rem",
                      }}
                    >
                      {`INR ${
                        wallet ? wallet.WalletBalance : state.user.walletBalance
                      } `}
                    </span>
                  </li>
                )} */}

                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret"
                  >
                    <div className="navbar-profile">
                      <img
                        className="img-xs rounded-circle"
                        src={userAvtar}
                        alt="profile"
                      />
                      <p className="mb-0 d-none d-sm-block navbar-profile-name">
                        <Trans>
                          {state.user == null ? (
                            ""
                          ) : (
                            <span style={{ fontWeight: "600" }}>
                              {state.user.ReferId}
                            </span>
                          )}
                        </Trans>
                      </p>
                      <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ minWidth: "20rem" }}
                    className="navbar-dropdown preview-list navbar-profile-dropdown-menu"
                  >
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        history.push("/user/my_profile");
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-gear"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>My Profile</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={(evt) => {
                        evt.preventDefault();
                        localStorage.setItem("kyc", JSON.stringify(user));
                        history.push("/kyc/bussiness-details");
                      }}
                      className="dropdown-item preview-item"
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-support"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Document KYC</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={(evt) => {
                        evt.preventDefault();
                        history.push("/agent-kyc");
                      }}
                      className="dropdown-item preview-item"
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-support"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Agent KYC </Trans>
                      </div>

                      {paynnowAgentId ? (
                        <div className="badge badge-success badge-pill ml-auto">
                          Completed
                        </div>
                      ) : (
                        <div className="badge badge-danger badge-pill ml-auto">
                          InComplete
                        </div>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={(evt) => {
                        evt.preventDefault();
                        history.push("/user/Help-And-Support");
                      }}
                      className="dropdown-item preview-item"
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-support"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Help & Support</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        removeUserInfo();
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-sign-out"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Logout</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                onClick={toggleOffcanvas}
              >
                <span className="mdi mdi-format-line-spacing"></span>
              </button>
            </div>
          </nav>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Navbar;
