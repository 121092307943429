import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_PAYNOW } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { basicAuth } from "../../config";
import swal from "sweetalert";

/*
{
  "validateId": 389764,
  "customerCode": "454647",
  "beneAccountNo": "454647000001229820",
  "beneAccountIfsc": "YESB0CMSNOC",
  "beneFullName": "BUMPPY MEDIA PRIVATE LIMITED",
  "transferType": "UPI",
  "transferUniqueNo": "414910153251",
  "transferTimestamp": "2024-05-28T10:19:41",
  "transferCcy": "INR",
  "transferAmt": 1010,
  "createdBy": "rajnish@example.com",
  "createdOn": "2024-05-28T10:20:00",
  "userId": 2
}
*/

export const columns = [
  {
    dataField: "beneAccountNo",
    text: "Virtual Account",
  },
  {
    dataField: "beneAccountIfsc",
    text: "IFSC",
  },
  {
    dataField: "beneFullName",
    text: "Beneficiary Name",
  },
  {
    dataField: "transferUniqueNo",
    text: "RRN",
  },
  {
    dataField: "transferTimestamp",
    text: "Date",
  },
  {
    dataField: "transferAmt",
    text: "Amount",
  },

];


const positionStyle1 = {
  position: 'absolute',
  top: -38,
  left: 0,
  "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
};

const VirtualPaymentReportTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [inputState, setInputState] = useState({
    userType: { label: "Debit", value: "1" },
  });
  const [offset, setOffset] = useState(100000000);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    // setCurrentPage(1);
  }, [searchText]);

  // did mount

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const virtualPaymentRehitConfirmation = (e) => {
    swal({
      title: "Are you sure?",
      text: `Rehit for amount of ${e.transferAmt} to ${e.beneAccountNo}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        virtualPaymentRehit(e);
      }
    });
  };

  const getVirtualPaymentReport = async () => {
    setTransactionLoaderState(true);
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: offset,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {


      const res = await fetch(
        "https://apitranxt.paynnow.com/api/VirtualInfo/GetAllVirtualPayment",
        requestOptions
      ).then((response) => response.json())
        .then((result) => {

          if (result.responseCode == "00") {
            setData(result.data.reverse());

        //  setData(result.data.reverse());
          }

        })
        .catch((error) => console.log("error", error));
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };



  const virtualPaymentRehit = async (e) => {

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "userId": "",
        "beneName": "BUMPPY",
        "mode": e.transferType,
        "utrno": e.transferUniqueNo,
        "razorId": e.beneAccountNo,
        "sAccNo": "",
        "sName": "",
        "sBank": "",
        "sIfsc": "",
        "fullResponse": JSON.stringify(e),
        "amount": e.transferAmt
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const res = await fetch(`${BASE_URL_APISERVICES}/api/Payment/virtual/virtualPaymentWebhook`, requestOptions);
      const data = await res.json();

      if (data.status == "00") {
        swal("Success", `Status : ${data.data.status}`, "success");
      } else {
        swal("Failed", `${data.msg}`, "error");
      }
    } catch (error) {
      swal("Failed", `Something went wrong`, "error");
    }

  }

  useEffect(() => {
    getVirtualPaymentReport();
  }, [currentPage, offset]);




  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height: '20%',

  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily": "Poppins",
    "fontWeight": "400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };


  return (
    <>
      <div className="row m-0 p-0  pt-1 align-items-center">
        <div className="col-4 m-0 p-0">

          <div className="m-0 p-0 pl-2"
            style={{
              "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
              , padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
            Virtual Payment Report
          </div>
        </div>

        <div className="col-8 m-0 p-0 ">
          <div className="row  m-0 p-0  justify-content-end">

            <div style={searchBarStyle} className="bg-light m-0 p-0 px-1">
              <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2 " />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div className=" pl-2 pr-1 p-0 m-0" style={{ width: '18%', height: '20%', }}>
              <Form.Group className="p-0 m-0">

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
              <Form.Group className="p-0 m-0">

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>


            <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-50 btn btn-primary btn-lg btn-block "

                  onClick={getVirtualPaymentReport}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
            <div className="m-0 p-0 hover-container "
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `VirtualPaymentReportTable${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img className="pl-1"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: '85%' }}
              />
              <div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0 p-0" />
      <div className=" my-table scroll-inner admin-fix-width transparent-table">
        <table style={{ width: "100%", backgroundColor: '#FFFFFF' }} className="my-table  borderless transparent-table">
          <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Sr"}</th>

            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
              {" "}
              {"Bank RR No."}
            </th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
              {" "}
              {"Bank Account"}
            </th>

            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
              {" "}
              {"Transaction Mode"}
            </th>

            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
              {" "}
              {"Amount"}
            </th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
              {" "}
              {"Date"}
            </th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
              {" "}
              {"Action"}
            </th>
          </tr>
          {data.map((e, ind) => (
            <tr className="my-table transparent-table border-bottom py-5">
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  {ind + 1}
                </div>
              </td>
              <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {e.transferUniqueNo}
              </td>
              <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div>{e.beneAccountNo}</div>
                <div>{e.beneFullName}</div>
                <div>{e.beneAccountIfsc}</div>
              </td>
              <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {e.transferType}
              </td>
              <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {e.transferAmt}
              </td>
              <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {e.createdOn}
              </td>
              <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="row m-0 p-0 " >
                  {user.pkid == "9" && (
                    <Button style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      color: 'rgba(255, 164, 27, 1)',
                      bordercolor: 'rgba(255, 255, 255, 1)',
                    }}
                      className="btn btn-sm  rounded-100 m-0 p-1"

                      onClick={() => {
                        virtualPaymentRehitConfirmation(e)
                      }}

                    >
                      {" "}

                      <div className="hover-container"  >
                        <img
                          src={require("../../../assets/images/Rehit.png")}
                          style={{ width: "" }}
                        />
                        <div style={positionStyle1} className="hover-text bg-dark"><div>REHIT</div></div>
                      </div>
                    </Button>

                  )}


                  <Button style={{
                    borderRadius: "5px",
                    border: "1px none",
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: 'rgba(255, 164, 27, 1)',
                    bordercolor: 'rgba(255, 255, 255, 1)',
                  }} className="btn btn-sm  rounded-100 m-0 p-1">
                    {" "}

                    <div className="hover-container"  >
                      <img
                        src={require("../../../assets/images/Bank Response.png")}
                        style={{ width: "" }}
                      />
                      <div style={positionStyle1} className="hover-text bg-dark"><div>Bank Response</div></div>
                    </div>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={data.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
          className="rounded-0"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default VirtualPaymentReportTable;