import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Tabs, Tab, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Transactions, { Payin, Payout } from "./Transactions";
import { urls } from "./devUrls";
import Topup from "./Topup";
import SmartCollectVirtualAccountTable from "./SmartCollectVirtualAccountTable";
import SmartCollectPaymentsTable from "./SmartCollectPaymentsTable";
import Sidebarnew from "./Sidebarnew";
import Chart from "chart.js";
import { Ledger } from "./Ledger";
import { twoDecimalPlaces } from "../utils/utilFuctions";

const Balances = () => {
        // previous day date
        const previousDay = (date = new Date()) => {
            return new Date(date.setDate(date.getDate() - 1));
        };
    const [startDate, setStartDate] = useState(previousDay());
    const [endDate, setEndDate] = useState(new Date());
    const [details, setDetails] = useState({});
    const [info, setInfo] = useState({});
    const [balance, setBalance] = useState(0);
    const { removeMyAccountInfo, setTransactionLoaderState } = useContext(GlobalContext);
    let { myAccountInfo
    } = useContext(GlobalContext);

    const history = useHistory();

    useEffect(() => {
        getAllVirtualPayments();
    }, []);

    const dateFormatter = (date = new Date()) => {
        const mm = date.getMonth() + 1;
        return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };



    const getAllVirtualPayments = async () => {
        const accessToken = sessionStorage.getItem("accessToken");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Cookie",
            ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
        );

        var raw = JSON.stringify({
            "fromDate": dateFormatter(startDate),
            "toDate": dateFormatter(endDate),
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        try {
            setTransactionLoaderState(true)
            const res = await fetch(
                "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
                requestOptions
            );
            console.log("response", res);
            if (res.status == 401) {
                removeMyAccountInfo();
            } else if (res.ok) {
                const data = await res.json();
                console.log(data);
                if (data && data.length > 0) {

                    const dataLoad = data
                        .map((acc, i) => {
                            if (i == 0) {
                                const ccB = twoDecimalPlaces(Number(acc.opening_bal) + Number(acc.today_topup) + Number(acc.today_refund) - Number(acc.payout_today) - Number(acc.payout_fee) - Number(acc.payin_fee) + Number(acc.settlement_today))
                                setInfo({ paymentAPIBalance: ccB })
                            }
                            return {
                                ...acc,
                                upi: `${acc.beneAccountNo}@yesbankltd`,
                                date: new Date(acc.createdOn),
                                closingBal: twoDecimalPlaces(Number(acc.opening_bal) + Number(acc.today_topup) + Number(acc.today_refund) - Number(acc.payout_today) - Number(acc.payout_fee) - Number(acc.payin_fee) + Number(acc.settlement_today))
                            };
                        });
                    dataLoad.sort(function (a, b) {
                        return new Date(b.date) - new Date(a.date);
                    });
                    console.log(dataLoad);
                    setBalance(dataLoad[0].closingBal)
                    // setData(dataLoad);
                    //  setDataToDisplay(dataLoad);
                }
            }
            setTransactionLoaderState(false)
            // setPayments(JSON.parse(body).data);
        } catch (error) {
            setTransactionLoaderState(false)
            console.log("401 catched", error);
        }
    };

    if (info == null) {
        history.push("/error-pages/error-404");
        return;
    }

    return (
        <div className="row mt-3">
            <div className="col-7">
                <div className="row m-0 pt-2" style={{ background: "#fff" }}>
                    <div className="col mx-2 p-0 d-flex flex-column ">
                        <h6 style={{ color: "#a0a0a0" }}>DMT Balance</h6>
                        <h5>{info.dmtBalance}</h5>
                    </div>

                    <div className="col d-flex justify-content-end align-items-center">
                        <Button
                            style={{
                                border: "1px solid",
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "rgba(43, 193, 85, 1)",
                                bordercolor: "rgba(43, 193, 85, 1)",
                                height: "2.5rem",
                            }}
                        >
                            Activate
                        </Button>
                    </div>
                </div>

                {myAccountInfo.userId == 2 && (
                    <React.Fragment>
                        <div
                            className="row m-0 mt-3 pt-2"
                            style={{ background: "#fff" }}
                        >

                            <div className="col mx-2 p-0 d-flex flex-column ">
                                <h6 style={{ color: "#a0a0a0" }}>AEPS Balance</h6>
                                <h5>{info.aepsBalance}</h5>
                            </div>

                            <div className="col d-flex justify-content-end align-items-center">
                                <Button
                                    style={{
                                        border: "1px solid",
                                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                                        color: "rgba(43, 193, 85, 1)",
                                        bordercolor: "rgba(43, 193, 85, 1)",
                                        height: "2.5rem",
                                    }}
                                >
                                    Activate
                                </Button>
                            </div>
                        </div>
                        <div
                            className="row m-0 mt-3 pt-2"
                            style={{ background: "#fff" }}
                        >
                            <div className="col mx-2 p-0 d-flex flex-column ">
                                <h6 style={{ color: "#a0a0a0" }}>BBPS Wallet Balance</h6>
                                <h5>{info.bbpsBalance}</h5>
                            </div>

                            <div className="col d-flex justify-content-end align-items-center">
                                <Button
                                    style={{
                                        border: "1px solid",
                                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                                        color: "rgba(43, 193, 85, 1)",
                                        bordercolor: "rgba(43, 193, 85, 1)",
                                        height: "2.5rem",
                                    }}
                                >
                                    Activate
                                </Button>
                            </div>
                        </div>
                        <div
                            className="row m-0 mt-3 pt-2"
                            style={{ background: "#fff" }}
                        >
                            <div className="col mx-2 p-0 d-flex flex-column ">
                                <h6 style={{ color: "#a0a0a0" }}>
                                    BBPS Offline Wallet Balance
                                </h6>
                                <h5>{info.bbpsOfflineBalance}</h5>
                            </div>

                            <div className="col d-flex justify-content-end align-items-center">
                                <Button
                                    style={{
                                        border: "1px solid",
                                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                                        color: "rgba(43, 193, 85, 1)",
                                        bordercolor: "rgba(43, 193, 85, 1)",
                                        height: "2.5rem",
                                    }}
                                >
                                    Activate
                                </Button>
                            </div>
                        </div>

                    </React.Fragment>
                )}




                <div
                    className="row m-0 mt-3 pt-2"
                    style={{ background: "#fff" }}
                >
                    <div className="col mx-2 p-0 d-flex flex-column ">
                        <h6 style={{ color: "#a0a0a0" }}>Payment Api Balance</h6>
                        <h5>{info.paymentAPIBalance}</h5>
                    </div>

                    <div className="col d-flex justify-content-end align-items-center">
                        <Button
                            style={{
                                border: "1px solid",
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                color: "rgba(43, 193, 85, 1)",
                                bordercolor: "rgba(43, 193, 85, 1)",
                                height: "2.5rem",
                            }}
                        >
                            Activate
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col mt-3 d-flex flex-column align-items-center text-center">
                <img
                    alt=""
                    src={require("../../assets/images/develpoer_balance.png")}
                    style={{ height: "20rem" }}
                />
                <div className="d-flex flex-column align-items-start">
                    <h3>Earn</h3>
                    <h3>Predict & Prosper</h3>
                </div>
            </div>
        </div>
    );
};

export default Balances;
