import React, { useState, useEffect, useContext } from "react";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";

const FundHoldReportTable = () => {
  const { user } = useContext(GlobalContext);
  const history = useHistory();
  const [pageAccess, setPageAccess] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    const updatedSelectedIds = [...selectedIds];
    const index = updatedSelectedIds.indexOf(id);

    if (index === -1) {
      updatedSelectedIds.push(id);
    } else {
      updatedSelectedIds.splice(index, 1);
    }

    setSelectedIds(updatedSelectedIds);
  };

  const handleSelectAllChange = () => {
    const allIds = data.map((e) => e.id);
    const updatedSelectedIds = selectedIds.length === allIds.length ? [] : allIds;
    setSelectedIds(updatedSelectedIds);
  };

  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const fetchPGReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: "",
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/pg/getAllFundHoldReport`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const releaseSelectedTransactions = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "adminid": user.pkid,
        "remarks": "Credited",
        "fundIds": selectedIds
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log(selectedIds);

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/pg/releaseFundHoldingByIds`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == "0") {
        swal(data.msg, "", "success");
        fetchPGReport();
      } else {
        swal(data.msg, "", "error");
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  console.log(selectedIds);
  const ReleaseHold = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify();

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/pg/releaseHoldingAmountCron`,
        requestOptions
      );
      const data = await res.json();

      if (data) {
        swal(data.message, "", "success");
      }
    } catch (error) {
      console.log(error);

    }
  };


  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const getPageAccess = (menuId) => {
    const sub_menus_access_info = JSON.parse(
      sessionStorage.getItem("sub_menus")
    );
    sub_menus_access_info.forEach((acc) => {
      if (acc.pk_menu_id == menuId) {
        setPageAccess(acc);
      }
    });
  };

  useEffect(() => {
    fetchPGReport();
    getPageAccess(14);
  }, []);

  return (
    <div>

      <div className="m-2 d-flex justify-content-end">

        <div
          className=" p-0 m-1"
          style={{ width: "18%", height: "20%" }}
        >
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="form-control rounded-100"
            placeholderText="Start Date"
          />
        </div>
        <div className="  p-0 m-1" style={{ width: "18%", height: "20%" }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="form-control rounded-100"
            placeholderText="End Date"
          />
        </div>
        <div className=" p-0 m-1">
          <Button
            className="rounded-100 btn btn-lg btn-block "
            onClick={fetchPGReport}
            style={{ width: "8rem" }}
          >
            {"Search"}
          </Button>
        </div>
        <div className=" p-0 m-1">
          <Button
            className="rounded-100 btn btn-lg btn-block "
            onClick={releaseSelectedTransactions}
            style={{ width: "22rem" }}
          >
            {"Release Selected Transactions"}
          </Button>
        </div>
      </div>
      <hr className="m-0 p-0" />

      <div style={{ width: "100%" }}>
        <div style={{ alignItems: "center" }}>
          <div style={{ flex: "1" }}></div>
        </div>
      </div>
      <div className=" scroll-inner admin-fix-width borderless-table ">
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              <input
                type="checkbox"
                onChange={handleSelectAllChange}
                checked={selectedIds.length === data.length}
              /></th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Transaction ID
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              User
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Amount
            </th>

            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Date Time
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Duration
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Status
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(e.id)}
                  checked={selectedIds.includes(e.id)}
                />
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                {ind + 1}
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.rch_transaction_id}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.user_code}</div>
                <div>{e.user_name}</div>
                <div> {e.user_mobile}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {twoDecimalPlaces(e.rch_amount)}
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.rch_insert_date}</div>
              </td>

              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.duration}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {e.status.toString().toLowerCase() === "success" ? (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(43, 193, 85, 0.1)",
                      color: "rgba(43, 193, 85, 1)",
                      borderColor: "rgba(43, 193, 85, 1)",
                    }}
                  >
                    {"Success"}
                  </label>
                ) : e.status.toString().toLowerCase() === "hold" ? (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(254, 128, 36, 0.1)",
                      color: "rgba(254, 128, 36, 1)",
                      bordercolor: "rgba(254, 128, 36, 1)",
                      width: "4rem",
                    }}
                  >
                    {e.status}
                  </label>
                ) : e.status.toString().toLowerCase() === "settled" ? (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(43, 193, 85, 0.1)",
                      color: "rgba(43, 193, 85, 1)",
                      bordercolor: "rgba(43, 193, 85, 1)",
                      width: "4rem",
                    }}
                  >
                    {e.status}
                  </label>
                ) : (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(254, 128, 36, 0.1)",
                      color: "rgba(254, 128, 36, 1)",
                      borderColor: "rgba(254, 128, 36, 1)",
                    }}
                  >
                    {e.status}
                  </label>
                )}
              </td>
            </tr>
          ))}
        </table>
      </div>

      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default FundHoldReportTable;