import React, { useEffect, useState } from "react";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const ConcernFormPrint = ({ }) => {

    const printInfo = JSON.parse(localStorage.getItem("printInfo"));
    const Userstate = {
        agentID:printInfo.agentID,
        agentName: printInfo.agentName,
        shopName: printInfo.shopName,
        customerName: printInfo.customerName,
        productName: printInfo.productName,
        customerContactName: printInfo.customerContactName,
        cardLast4Digits: printInfo.cardLast4Digits,
        transactionDate: "",
        transactionAmount: "",
        UTRRef: "",
        txnDate: "",
        txnAmount: "",
        merchant: "",
        aadhaarFront: "",
        aadhaarBack: "",
        aadhaarnumber: printInfo.aadhaarnumber,
        panNumber: printInfo.panNumber,
    };

    const [state, setState] = useState(Userstate);

    useEffect(() => {
        let aadhaarFront1 = localStorage.getItem("aadhaarFront");
        let aadhaarBack1 = localStorage.getItem("aadhaarBack");
        setState((prevState) => ({
            ...prevState,
            aadhaarFront: aadhaarFront1,
            aadhaarBack: aadhaarBack1,
        }));
    }, []);

    const  generatePDF = async () => {
        // Create new jsPDF instance
        const doc = new jsPDF();

        console.log("generate pdf");
    
        // Reference to the form element
        const form = document.getElementById('pdf-form');
    
        // Use html2canvas to render the form as an image
      //  const res = await html2canvas(form);
      //  console.log(res);
        
      html2canvas(form).then((canvas) => {
            console.log("generate pdf 2");
          // Convert the canvas to a Base64 image data URL
          const imgData = canvas.toDataURL('image/png');
    
          // Add the image to the PDF
          doc.addImage(imgData, 'PNG', 10, 10, 180, 180); // Adjust the position and size as needed
    
          // Save or download the PDF
          doc.save('form.pdf');
        }).catch((error) => {
            console.log("error", error);
        }
        );
      };

    useEffect(() => {
        if (state.aadhaarBack && state.aadhaarFront) {
            generatePDF();
         //   window.print();
        } else {
          //  window.print();
        }
    }, [state.aadhaarFront, state.aadhaarBack]);

    return (
        <>
            <div className="pdf-form">
                {/* <div className="col-12">
                    <button style={{ marginLeft: "auto" }}
                        className="hide-on-print" onClick={printReceipt}>Print</button>
                </div> */}
                <div className="col p-5 d-flex justify-content-center">
                    <div>
                        <div className="p-3">
                            <p
                                className="d-flex justify-content-center"
                                style={{ fontSize: "1.7rem" }}
                            >
                                To Whom It Concern
                            </p>
                        </div>
                        <div className="row">
                            <div className="col">
                                <img
                                    src={state.aadhaarFront}
                                    alt="aadhaarFront"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            </div>
                            <div className="col">
                                <img
                                    src={state.aadhaarBack}
                                    alt="aadhaarBack"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            </div>
                        </div>
                        <div className="px-4 mt-5">
                            <p style={{ fontSize: "1rem" }}>
                                I hereby declare that the card which is being used on the
                                payment gateway is owned by Undersigned and I will fully give
                                consent to Agent ID &nbsp;
                                <span style={{ textDecoration: "underline" }}>
                                    {state.agentID}
                                </span>
                                &nbsp; And Agent Name
                                &nbsp;
                                <span style={{ textDecoration: "underline" }}>
                                    {state.agentName}
                                </span>
                                &nbsp;

                                Shop Name
                                &nbsp;
                                <span style={{ textDecoration: "underline" }}>
                                    {state.shopName}
                                </span>
                                &nbsp;

                                to use my card, and I consent and hereby provide my KYC for the
                                same.
                            </p>
                        </div>
                        <div className="px-4 ">
                            <p style={{ fontSize: "1.1rem" }}>Details of the Card Holder:</p>
                            <div className="row p-0 m-0">
                                <p className="col-6 p-0 m-0">Customer Name:</p>
                                <input
                                    style={{ border: "none", borderBottom: "1px solid" }}
                                    type="text"
                                    value={state.customerName}
                                    readOnly
                                />
                            </div>
                            <div className="row p-0 m-0">
                                <p className="col-6 p-0 m-0">Produce Name:</p>
                                <input
                                    style={{ border: "none", borderBottom: "1px solid" }}
                                    type="text"
                                    value={state.productName}
                                    readOnly
                                />
                            </div>
                            <div className="row p-0 m-0">
                                <p className="col-6 p-0 m-0">Customer Contact Name:</p>
                                <input
                                    style={{ border: "none", borderBottom: "1px solid" }}
                                    type="text"
                                    value={state.customerContactName}
                                    readOnly
                                />
                            </div>
                            <div className="row p-0 m-0">
                                <p className="col-6 p-0 m-0">Card Number ( Last 4 Digit):</p>
                                <input
                                    style={{ border: "none", borderBottom: "1px solid" }}
                                    type="text"
                                    value={state.cardLast4Digits}
                                    readOnly
                                />
                            </div>
                            <div className="row p-0 m-0">
                                <p className="col-6 p-0 m-0">Transaction Date:</p>
                                <input
                                    style={{ border: "none", borderBottom: "1px solid" }}
                                    type="text"
                                    value={state.transactionDate}
                                    readOnly
                                />
                            </div>
                            <div className="row p-0 m-0">
                                <p className="col-6 p-0 m-0">Transaction Amount:</p>
                                <input
                                    style={{ border: "none", borderBottom: "1px solid" }}
                                    type="text"
                                    value={state.transactionAmount}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="p-4 ">
                            <p style={{ fontSize: "1.1rem" }}>Customer KYC ID No.</p>
                            <div className="px-3 ">
                                <div className="row p-0 m-0">
                                    <p className="col-6 p-0 m-0">1. Aadhaar card :</p>
                                    <input
                                        style={{ border: "none", borderBottom: "1px solid" }}
                                        type="text"
                                        value={state.aadhaarnumber}
                                        readOnly
                                    />
                                </div>
                                <div className="row p-0 m-0">
                                    <p className="col-6 p-0 m-0">2. PAN card :</p>
                                    <input
                                        style={{ border: "none", borderBottom: "1px solid" }}
                                        type="text"
                                        value={state.panNumber}
                                        readOnly
                                    />
                                </div>
                                <div className="row p-0 m-0">
                                    <p className="col-6 p-0 m-0">3. Card Picture :</p>

                                </div>

                            </div>
                        </div>
                        <div className="px-4 ">
                            <p style={{ fontSize: "1.1rem" }}>Customer Concern/N.O.C</p>
                            <div className="p-1" style={{ border: "1px solid black" }}>
                                <p style={{ fontSize: "1rem" }}>
                                    This is regarding the complaint raised against the transaction
                                    with reference UTR/Ref.
                                    <input
                                        style={{ border: "none", borderBottom: "1px solid" }}
                                        type="text"
                                        value={state.UTRRef}
                                        readOnly
                                    />
                                    Processed on Date on Txn
                                    <input
                                        style={{ border: "none", borderBottom: "1px solid" }}
                                        type="text"
                                        value={state.txnDate}
                                        readOnly
                                    />
                                    for the amount of Rs.
                                    <input
                                        style={{ border: "none", borderBottom: "1px solid" }}
                                        type="text"
                                        value={state.txnAmount}
                                        readOnly
                                    />
                                    to the merchant
                                    <input
                                        style={{ border: "none", borderBottom: "1px solid" }}
                                        type="text"
                                        value={state.merchant}
                                        readOnly
                                    />
                                    . I received the services from the merchant against the said
                                    transaction which they agreed for and I don’t have any issue
                                    for the same. I request you to please cancel/Withdraw my
                                    complaint raised with you.
                                </p>
                            </div>
                        </div>
                        <div className="p-4 ">
                            <p style={{ fontSize: "1.1rem" }}>Cardholder Signature</p>
                            <input
                                style={{ border: "none", borderBottom: "1px solid" }}
                                className="col-2  "
                                type="text"
                            />
                        </div>
                        <div className="p-4 d-flex justify-content-center" >
                            <p
                                className="d-flex justify-content-center"
                                style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                            >
                                Note: All details are to be filled mandatorily
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConcernFormPrint;