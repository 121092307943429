import React, { useState, useEffect, useContext } from "react";


const ChaafoDocSubmit = () => {
    const [aadharPreview, setAadharPreview] = useState(null);
    const [panPreview, setPanPreview] = useState(null);
    const [gstPreview, setGstPreview] = useState(null);

    const handleFileChange = (event, setPreview) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setPreview(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        alert('Form submitted successfully!');
    };

    const handleClear = () => {
        setAadharPreview(null);
        setPanPreview(null);
        setGstPreview(null);
    };

    const handleBack = () => {
        window.history.back();
    };

    const formContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
        padding: '20px',
    };

    const formStyle = {
        background: 'white',
        padding: '40px',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '1000px',
        height: '600px',
        textAlign: 'center',
    };

    const headingStyle = {
        marginBottom: '20px',
        fontSize: '1.5rem',
        color: '#333',
    };

    const formRowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    };

    const formGroupStyle = {
        flex: '1',
        marginRight: '10px',
        textAlign: 'left',
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '8px',
        fontSize: '0.9rem',
        color: '#555',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        fontSize: '0.9rem',
        border: '1px solid #ddd',
        borderRadius: '4px',
    };

    const previewStyle = {
        marginTop: '10px',
        maxWidth: '100%',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '5px',
        backgroundColor: '#f7f7f7',
    };

    const buttonStyle = {
        backgroundColor: '#007BFF',
        color: 'white',
        padding: '10px 15px',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        margin: '5px',
        transition: 'background-color 0.3s ease',
    };

    const buttonStyle1 = {
        backgroundColor: 'grey',
        color: 'white',
        padding: '10px 15px',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        margin: '5px',
        transition: 'background-color 0.3s ease',
    };

    const buttonStyle2 = {
        backgroundColor: 'red',
        color: 'white',
        padding: '10px 15px',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        margin: '5px',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: '#0056b3',
    };
    const buttonHoverStyle1 = {
        backgroundColor: '#243642',
    };
    const buttonHoverStyle2 = {
        backgroundColor: '#C62E2E',
    };

  return (
 <>
   <div style={formContainerStyle}>
            <form onSubmit={handleSubmit} style={formStyle}>
                <h2 style={headingStyle}>Document Upload</h2>

                <div style={formRowStyle}>
                    <div style={formGroupStyle}>
                        <label style={labelStyle}>Aadhar Card</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={(e) => handleFileChange(e, setAadharPreview)} 
                            required
                            style={inputStyle}
                        />
                        {aadharPreview && <img src={aadharPreview} alt="Aadhar Preview" style={previewStyle} />}
                    </div>

                    <div style={formGroupStyle}>
                        <label style={labelStyle}>PAN Card</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={(e) => handleFileChange(e, setPanPreview)} 
                            required
                            style={inputStyle}
                        />
                        {panPreview && <img src={panPreview} alt="PAN Preview" style={previewStyle} />}
                    </div>

                    <div style={formGroupStyle}>
                        <label style={labelStyle}>GST Certificate (Optional)</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={(e) => handleFileChange(e, setGstPreview)} 
                            style={inputStyle}
                        />
                        {gstPreview && <img src={gstPreview} alt="GST Preview" style={previewStyle} />}
                    </div>
                </div>

                <div>
                    <button 
                        type="submit" 
                        style={buttonStyle}
                        onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
                        onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}
                    >
                        Submit
                    </button>
                    <button 
                        type="button" 
                        style={buttonStyle1}
                        onClick={handleClear}
                        onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle1.backgroundColor}
                        onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle1.backgroundColor}
                    >
                        Clear
                    </button>
                    <button 
                        type="button" 
                        style={buttonStyle2}
                        onClick={handleBack}
                        onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle2.backgroundColor}
                        onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle2.backgroundColor}
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
        </>
  );
};

export default ChaafoDocSubmit;
