import React from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import FundHoldReportTable from "./FundHoldReportTable";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import swal from "sweetalert";
const CreditCardBillPaymentReport = () => {

    const ReleaseHold = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify();

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/pg/releaseHoldingAmountCron`,
                requestOptions
            );
            const data = await res.json();

            if (data) {
                swal(data.message, "", "success");
            }
        } catch (error) {
            console.log(error);

        }
    };

    return (
        <GlobalContext.Consumer>
            {(context) => (
                <div style={{ padding: "0.5rem" }} className="bg-light">
                    <div style={{ borderRadius: "10px" }} className="card  m-0 mt-4 ml-1 mr-1 p-0">
                        <div className="card-body m-0 p-2">
                            <div>
                                <div className="row justify-content-between px-3 pb-2"
                                    style={{
                                        "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                        , padding: "0px",
                                        background: "#FFFFFF",
                                        color: "#4267B2",
                                        fontWeight: "bolder",
                                        fontfamily: "Poppins !impotent",
                                        fontSize: "32px",
                                    }}
                                >
                                    <span>  Fund Hold Report</span>
                                    <Button
                                        className="rounded-100 btn btn-block btn-success "
                                        onClick={ReleaseHold}
                                        style={{ width: "9rem" }}
                                    >
                                        {"Release Hold"}
                                    </Button>

                                </div>

                                <FundHoldReportTable />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </GlobalContext.Consumer>
    );
};

export default CreditCardBillPaymentReport;
