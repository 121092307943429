import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { generateHashKey2, uniqueID2 } from "../utils/utilFuctions";
import Spinner from "./shared/Spinner";
import { checkSession, SESSION_TIMER } from "../utils/Session";
import { GlobalContext } from "../Context/GlobalState";
import Dashboard from "./dashboard/Dashboard";
import ViewRetailer from "./InsidePages/ViewRetailer";
import DistributorReportByid from "./InsidePages/DistributorReportByid";
import NewDistributor from "./InsidePages/NewDistributor";
import ManagePayment from "./InsidePages/ManagePayment";
import CommissionChart from "./InsidePages/CommissionChart";
import TopupView from "../retailer/Topup View/TopupView";
import DistributorView from "./DistributorView/DistributorView";
// import Topup from "../retailer/topup/Topup";
import { TopupRequest } from "../retailer/topup/Topup2";
import VirtualReport from "../retailer/virtual report/VirtualReport";
import RetailerLedgerReport from "../distributor/RetailerReport/RetailerLedgerReport";
import LedgerReport from "../retailer/Ledger/LedgerReport";
import MyProfile from "../retailer/my profile/MyProfile";
import CreditRequest from "../retailer/credit-request/CreditRequest";

// Manage Distributor

const CreateDistributor = lazy(() =>
  import("./Manage Distributor/CreateDistributor")
);
const ViewDistributor = lazy(() =>
  import("./Manage Distributor/ViewDistributor")
);
const TopupHistory = lazy(() => import("./Manage Distributor/TopupHistory"));
const DistributorReport = lazy(() =>
  import("./Manage Distributor/DistributorReport")
);

// Funds

const FundReports = lazy(() => import("./Funds/FundReports"));
const FundStatement = lazy(() => import("./Funds/FundStatement"));

// Wallet
const BankDetails = lazy(() => import("./Wallet Reports/BankDetails"));

// Wallet to wallet transfer

const WalletToWalletTransfer = lazy(() =>
  import("./Wallet to wallet Transfer/WalletToWalletTransfer")
);

// Report

const Ledger = lazy(() => import("./Report/Ledger"));
const OperatorWiseReport = lazy(() => import("./Report/OperatorWiseReport"));
const ProfitReport = lazy(() => import("./Report/ProfitReport"));
const TransactionReport = lazy(() => import("./Report/TransactionReport"));

const Error404 = lazy(() => import("./error-pages/Error404"));

const Login2 = lazy(() => import("../user-pages/Login2"));

const AppRoutes = () => {

  const { removeUserInfo, user } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    removeUserInfo();
    checkSession();
  };

  const onActive = (event) => { };

  const onAction = (event) => { };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const checkSession2 = async (sessionId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/check_session.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
      } else {
        removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addSession = async () => {
    const sessionId = uniqueID2();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/add_session.aspx",
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        sessionStorage.setItem("sessionId", sessionId);
      } else {
      //  removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionId = sessionStorage.getItem("sessionId");
      if (sessionId) {
        checkSession2(sessionId);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const sessionId = sessionStorage.getItem("sessionId");
    // if (!sessionId) {
   // addSession();
    //  }
  }, [user]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Disable right-click
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
        )
          return false;
      };
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route
          exact
          path="/master-distributor/dashboard"
          component={Dashboard}
        />
        <Route
          exact
          path="/master-distributor/Profile/MyProfile"
          component={MyProfile}
        />

        {/* Manage Distributor */}
        <Route
          exact
          path="/master-distributor/Manage Distributor/CreateDistributor"
          component={CreateDistributor}
        />
        <Route
          exact
          path="/master-distributor/Manage Distributor/ViewDistributor"
          component={Dashboard}
        />
        <Route
          exact
          path="/master-distributor/Manage Distributor/TopupHistory"
          component={TopupHistory}
        />
        <Route
          exact
          path="/master-distributor/Manage Distributor/DistributorReport"
          component={DistributorReport}
        />

        {/* Funds */}

        <Route
          exact
          path="/master-distributor/Funds/FundReports"
          component={FundReports}
        />
        <Route
          exact
          path="/master-distributor/Funds/FundStatement"
          component={FundStatement}
        />

        {/* Wallet Reports */}

        <Route
          exact
          path="/master-distributor/Wallet Reports/TopupRequest"
          component={TopupRequest}
        />
        <Route
          exact
          path="/master-distributor/Wallet Reports/TopupView"
          component={TopupView}
        />
        <Route
          exact
          path="/master-distributor/Wallet Reports/BankDetails"
          component={BankDetails}
        />

        {/* Reports  */}

        <Route
          exact
          path="/master-distributor/Report/TransactionReport"
          component={TransactionReport}
        />
        <Route
          exact
          path="/master-distributor/Report/ProfitReport"
          component={ProfitReport}
        />
        <Route
          exact
          path="/master-distributor/Report/OperatorWiseReport"
          component={OperatorWiseReport}
        />
        <Route
          exact
          path="/master-distributor/Report/Ledger"
          component={LedgerReport}
        />

        {/* Wallet to wallet transfer */}

        <Route
          exact
          path="/master-distributor/Wallet to wallet Transfer/WalletToWalletTransfer"
          component={WalletToWalletTransfer}
        />

        {/* InsidePages */}
        <Route
          exact
          path="/master-distributor/InsidePages/ViewRetailer"
          component={ViewRetailer}
        />
        <Route
          exact
          path="/master-distributor/InsidePages/DistributorReportByid"
          component={DistributorReportByid}
        />
        <Route
          exact
          path="/master-distributor/InsidePages/NewDistributor"
          component={NewDistributor}
        />
        <Route
          exact
          path="/master-distributor/InsidePages/ManagePayment"
          component={ManagePayment}
        />
        <Route
          exact
          path="/master-distributor/InsidePages/CommissionChart"
          component={CommissionChart}
        />

        {/* Distributor view */}
        <Route
          exact
          path="/master-distributor/InsidePages/distributor_view"
          component={DistributorView}
        />
        {/* Retailer Report */}
        <Route
          exact
          path="/master-distributor/retailer_report"
          component={RetailerLedgerReport}
        />

        <Route
          exact
          path="/master-distributor/virtual_report"
          component={VirtualReport}
        />

        <Route exact path="/master-distributor/credit-request" component={CreditRequest} />

        <Route
          exact
          path="/master-distributor/topup_view"
          component={TopupView}
        />
        <Route path="/user-pages/login-2" component={Login2} />
        <Route path="/error-pages/error-404" component={Error404} />

        <Redirect to="/user-pages/login-2" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
