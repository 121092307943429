import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { RaiseTicketModal } from "../util/RaiseTicketModal";

export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },
  {
    dataField: "user_code",
    text: "User Code",
  },
  {
    dataField: "user_mobile",
    text: "User Mobile",
  },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "rch_mobile",
    text: "Beneficiary",
  },
  {
    dataField: "utrno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "sales_user_code",
    text: "Sales Code",
  },
  {
    dataField: "sales_user_name",
    text: "Sales Name",
  },
  {
    dataField: "sales_user_mobile",
    text: "Sales Mobile Number",
  },
  {
    dataField: "dmt_transfer_date",
    text: "Date&Time",
  },
  {
    dataField: "shop_pin_code",
    text: "Pin Code",
  },
  {
    dataField: "shop_city",
    text: "City",
  },
  {
    dataField: "shop_state",
    text: "State",
  },
];

const AEPSFingpayResponseListTable = () => {
  const [data, setData] = useState([]);
  const [salesPersons, setSalesPersons] = useState(null);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [raiseTicketModal, setRaiseTicketModal] = useState(false);
  const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.com/apibumppypay/v1/web/fingpay/recond_fullreport.php",
        requestOptions
      );
      const data = await res.json();

      console.log(data);

      setData(
        data.map((e) => {
          try {
            return {
              ...e,
              fingpayResponse: e.fullresponse ? JSON.parse(e.fullresponse) : {},
            };
          } catch (error) {
            return { ...e, fingpayResponse: {} };
          }
        })
      );

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const showResponse = (response) => {
    swal2(
      <div className="d-flex overflow-auto">
        <pre>{JSON.stringify(response)}</pre>
        {/* <strong>{JSON.stringify(response)}</strong> */}
      </div>
    );
  };

  const raiseTicketShow = (data) => {
    setRaiseTicketDetails({
      tid: data.tid,
      category: "DMT",
      user_id: data.username.toString().split("/")[0],
    });
    setRaiseTicketModal(true);
  };

  useEffect(() => {
    getDMTReport();
  }, []);

  console.log(data);

  return (
    <>
      <div className="row">
        <Col md={2} sm={12}>
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control border-primary rounded-0"
              placeholderText="Start Date"
            />
          </Form.Group>
        </Col>
        <Col md={2} sm={12}>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control border-primary rounded-0"
              placeholderText="End Date"
            />
          </Form.Group>
        </Col>

        <Col md={2} sm={6}>
          <Form.Group>
            <Form.Label>
              {/* <span style={{ color: "red" }}>*</span> */}
              {`Sales Person`}
            </Form.Label>
            <Select
              options={salesPersons}
              onChange={(e) => {
                setSelectedSalesPerson(e);
              }}
            />
          </Form.Group>
        </Col>

        <div className="col-3 p-0 mr-2 mt-4">
          <Form.Group>
            <Button
              className="rounded-0 mt-4"
              variant="success"
              onClick={getDMTReport}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>
            <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(`DMT_Report_${now()}`, excelMapping(data, columns));
            }}
          >
            <img
              src={require("../../../assets/images/excel.png")}
              style={{ width: "1.6rem" }}
            />
            <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
          </div>
        </div>
      </div>

      <div className="scroll-inner admin-fix-width">
        <table
          style={{ width: "100%", border: "1px" }}
          className="admin-table-style"
        >
          <tr className="text-center">
            <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>

            <th style={{ fontSize: "0.9rem" }}> {"Op ID"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Agent ID"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Adhaar No"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Bank name"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Amount"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Action"}</th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{`merchantTransactionId : ${e.merchantTransactionId}`}</span>
                  <span className="text-warning">{`fingpayTransactionId : ${e.fingpayTransactionId}`}</span>
                  <span>{`transactionRrn : ${e.transactionRrn}`}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.a_agentid}</span>
                </div>
              </td>

              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.a_aadharno}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.a_bankname}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.amountvalue}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <div className="mt-1 mb-1">
                    <Button
                      className="btn btn-sm btn-warning btn-block rounded-0 m-1"
                      onClick={() => {
                        if (
                          e.fingpayResponse &&
                          e.fingpayResponse.status &&
                          e.fingpayResponse.data
                        ) {
                          showResponse(e.fingpayResponse.data);
                        }
                      }}
                    >
                      {" "}
                      Full Bank Response
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </table>
        {data.length <= 0 && <NoDataFound />}
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export const NoDataFound = () => {
  return (
    <tr className="row w-100 justify-content-center">
      <div className="d-flex flex-column align-items-center p-3">
        <img
          src={require("../../../assets/images/no_data_found.png")}
          style={{ height: "5rem", width: "auto" }}
        />
        <strong>No Data Found</strong>
      </div>
    </tr>
  );
};

export default AEPSFingpayResponseListTable;
