import React, { useEffect, useState } from "react";
import { useQuery } from "../custom-hooks/useQuery";
import logo from '../../assets/images/yes_bank_Logo.png'
import { statement as statement2 } from "./statement";



const AccountStatementPrint = () => {
    let printData = localStorage.getItem("print");
    const [txnData, setTxnData] = useState([]);
    const [total, setTotal] = useState(0);
    if (printData) {
        printData = JSON.parse(printData);
    } else {
        printData = {};
    }
    let query = useQuery();
    const receiptNo = query.get("id");
    const Mode = query.get("type");
    const printReceipt = () => {
        window.print();
    };
    const NOTE =
        "This is Computer Generated receipt & does not require physical signature. Charges minimum Rs.10 or 1%.";

   // const [data, setData] = useState(statement);

    const [statement, setStatement] = useState(statement2.reverse());
    const [dataToDisplay, setDataToDisplay] = useState([]);

    // create array of days in january 2024 in format '2024-01-01' , '2024-01-02' etc
    const dates = Array.from({ length: 31 }, (_, i) => {
        const day = i + 1;
        return `2024-01-${day < 10 ? `0${day}` : day}`;
    });

    console.log(dates);

    const getAccountStatement = async (date, page = 1) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
            var raw = JSON.stringify({
                "pageNo": page,
                "startDate": '2024-08-15',
                "endDate": '2024-08-23'
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Admin/getAccountStatement",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                if (data.data.length > 0) {
                    setStatement([...statement, ...data.data]);
                    getAccountStatement(date, page + 1);
                }
                // setData(data.data);
            } else {
                // getAccountStatement(date, page + 1);
                // setData([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // dates.forEach((date) => {
        //     getAccountStatement(date,1);
        //     return;
        // });
        //  getAccountStatement('2024-08-23',1);
    }, []);

    return (
        <>
            <div
                style={{
                    margin: "5vw",
                    borderRadius: "5px",
                    padding: "1rem",
                }}
            >
                <div style={{ display: "flex", padding: "1rem 0rem" }}>
                    <div style={{ flex: "1" }} />
                    <button
                        style={{ marginLeft: "auto" }}
                        className="hide-on-print"
                        onClick={printReceipt}
                    >
                        {"Print"}
                    </button>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>

                    <div>
                        <table style={{
                            fontSize: ".6rem",
                            marginLeft: ".5rem"
                        }}>
                            <div> <h4>STATEMENT OF ACCOUNT</h4></div>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    CUSTOMER ID
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    : 16291555
                                </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                > ACCOUNT NO
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >: 001463400006363 </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    ACCOUNT NAME
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    : BUMPPY MEDIA PRIVATE LIMITED
                                </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    STATEMENT PERIOD
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    : {"04/11/2024"} to {"04/11/2024"}
                                </td>
                            </tr>

                        </table>
                    </div>
                    <div>
                        {" "}
                        <img
                            src={logo}
                            style={{ width: "25rem", margin: "0px" }}
                        />{" "}
                    </div>
                </div>

                <div style={{
                    //  display: "flex", justifyContent: "space-between"
                }}></div>
                <div className="row">
                    <div className="col">
                        <table style={{ fontSize: ".6rem" }}>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    <b>BUMPPY MEDIA PRIVATE,</b>
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >

                                </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    BUMPPY MEDIA PRIVATE LIMITED, B06 H-140 BSI BUSINESS PARK H BLOCK,
                                    SECTOR-63 NOIDA,
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >

                                </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    GAUTAM BUDDHA NAGAR,
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >

                                </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    201307
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >

                                </td>
                            </tr>
                            <li style={{ listStyleType: "none", fontSize: ".9rem", }}>EMAIL ID : <span
                                style={{ color: "blue" }}>admin@bumppy.com</span></li>
                            <tr>   <td
                                style={{
                                    fontSize: ".9rem",
                                    padding: "0px 12px 0px 0px",
                                    border: "none",
                                }}
                            >
                                Phone No </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                > </td>
                            </tr>


                        </table>
                        <table style={{ fontSize: ".6rem" }}>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    <b>Opening Balance </b>
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    : 609334.87
                                </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    <b>Closing Balance </b>
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    : 1448171.69
                                </td>
                            </tr>
                            <tr
                                style={{ padding: "0px", border: "none", background: "none" }}
                            >
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    <b>Report generated on </b>
                                </td>
                                <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                    : 05/11/2024
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col">
                        <div>
                            <table style={{ fontSize: ".6rem" }}>

                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        <b>BRANCH CODE </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : 0014
                                    </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    > <b>ACCOUNT BRANCH </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    > : Noida </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        <b>BRANCH ADDRESS </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : Plot No. 38, Block H 1 A, Sector 6, 3,
                                        Noida, Noida, UTTAR PRADESH
                                    </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        <b>RTGS/NEFT/IFSC </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : YESB0000014
                                    </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        <b>MICR </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : 110532007
                                    </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        <b>ACCOUNT STATUS </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : ACTIVE
                                    </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        <b>ACCOUNT TYPE </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : CURRENT ACCOUNT
                                    </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                >  <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                        <b>PRODUCT DESCRIPTION </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : CURRENT ACCOUNT - EXCLUSIVE

                                        BUSINESS
                                    </td>
                                </tr>
                                <tr
                                    style={{ padding: "0px", border: "none", background: "none" }}
                                > <td
                                    style={{
                                        fontSize: ".9rem",
                                        padding: "0px 12px 0px 0px",
                                        border: "none",
                                    }}
                                >
                                        <b>CURRENCY </b>
                                    </td>
                                    <td
                                        style={{
                                            fontSize: ".9rem",
                                            padding: "0px 12px 0px 0px",
                                            border: "none",
                                        }}
                                    >
                                        : INR
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>

                <div style={{ display: "flex" }}>
                    <table style={{ width: "100%", fontSize: "0.9rem" }}>
                        <tr style={{ padding: "0px", border: "none", background: "none" }}>
                            <td style={{ fontSize: ".8rem", border: "2px solid black", maxWidth: "2rem" }}>
                                {" "}
                                <b>Transaction Date</b>
                            </td>
                            <td style={{ fontSize: ".8rem", border: "2px solid black", maxWidth: "2rem" }}>
                                {" "}
                                <b> Value Date</b>
                            </td>
                            <td style={{ fontSize: ".8rem", border: "2px solid black", maxWidth: "4rem", overflow: "hidden", marginLeft: "5px", paddingLeft: "5px" }}>
                                {" "}
                                <b> Transaction Description</b>
                            </td>
                            <td style={{ fontSize: ".8rem", border: "2px solid black", maxWidth: "2rem" }}>
                                {" "}
                                <b>Reference No</b>
                            </td>
                            <td style={{ fontSize: ".8rem", border: "2px solid black", maxWidth: "2rem" }}>
                                {" "}
                                <b>Debit Amount</b>
                            </td>
                            <td style={{ fontSize: ".8rem", border: "2px solid black", maxWidth: "2rem" }}>
                                {" "}
                                <b>Credit Amount</b>
                            </td>
                            <td style={{ fontSize: ".8rem", border: "2px solid black", maxWidth: "2rem", }}>
                                {" "}
                                <b>Running Balance</b>
                            </td>
                        </tr>
                        {statement.map((e) => (
                            <tr
                                style={{
                                    padding: "0px",
                                    border: "2px solid #05569A",
                                    background: "none",
                                }}
                            > <td style={{ fontSize: ".6rem", border: "2px solid #05569A", maxWidth: "2rem", marginLeft: "2px", paddingLeft: "2px" }}>
                                    {" "}
                                    <b> {e.transactionDate}</b>
                                </td>
                                <td style={{ fontSize: ".6rem", border: "2px solid #05569A", maxWidth: "2rem" }}>
                                    <b>{e.fcrPostingDate}</b>
                                </td>
                                <td style={{ fontSize: ".6rem", border: "2px solid black", maxWidth: "3rem", overflow: "hidden", marginLeft: "5px", paddingLeft: "5px" }}>
                                    <b>{e.narrative}</b>
                                </td>
                                <td style={{ fontSize: ".6rem", border: "2px solid black", maxWidth: "2rem" , overflow:"hidden !important"}}>
                                    <b>{e.userReferenceNumber && e.userReferenceNumber.toString().substring(0,12)}...</b>
                                </td>
                                <td style={{ fontSize: ".6rem", border: "2px solid black", maxWidth: "2rem" }}>
                                    <b>{e.drcrFlag == "D" ? e.transactionAmount : "0.00"}</b>
                                </td>
                                <td style={{ fontSize: ".6rem", border: "2px solid black", maxWidth: "2rem" }}>
                                    <b> {e.drcrFlag == "C" ? e.transactionAmount : "0.00"}</b>
                                </td>
                                <td style={{ fontSize: ".6rem", border: "2px solid black", maxWidth: "2rem" }}>
                                    <b>{e.runningBalance}</b>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </>
    );
};

export default AccountStatementPrint;