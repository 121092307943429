import React, { useState } from 'react';

const Test = () => {
    const [imageUrls, setImageUrls] = useState('');
    const [results, setResults] = useState([]);
    const [errors, setErrors] = useState([]);

    return (
        <>
        </>
    );
};

export default Test;
