import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { GlobalContext } from "../Context/GlobalState";
// import axios
import axios from "axios";
import swal from "sweetalert";
import { excelMapping, ExportToExcel, now } from "../utils/utilFuctions";
// import { CLIENT } from "../../config";

export const columns = [
  {
    dataField: "merchantTransactionid",
    text: "Merchant Txnt ID",
    color: "#586275",
  },
  {
    dataField: "refid",
    text: "Ref id",
    color: "#586275",
  },
  {
    dataField: "utnno",
    text: "UTR No",
    color: "#586275",
  },
  {
    dataField: "transactiontype",
    text: "Transaction type",
    color: "#586275",
  },
  {
    dataField: "transactionAmount",
    text: "Amount",
    color: "#586275",
  },
  {
    dataField: "cgstAmount",
    text: "CGST Amount",
    color: "#586275",
  },
  {
    dataField: "sgstAmount",
    text: "SGST Amount",
    color: "#586275",
  },
  {
    dataField: "chargesAmount",
    text: "Charges",
    color: "#586275",
  },
  {
    dataField: "debitAmount",
    text: "Debit",
    color: "#586275",
  },
  {
    dataField: "creditAmount",
    text: "Credit",
    color: "#586275",
  },
  {
    dataField: "balance",
    text: "Balance",
    color: "#586275",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#000",
  },

  {
    dataField: "createdOn",
    text: "Created On",
    color: "#586275",
  },
];

const Transactions = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { removeMyAccountInfo } = useContext(GlobalContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm}-${
      date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
    }`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc) => {
            return {
              ...acc,
              upi: `${acc.beneAccountNo}@yesbankltd`,
              date: new Date(acc.createdOn),
            };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }

      // setPayments(JSON.parse(body).data);
    } catch (error) {
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div style={{ background: "white" }}>
      <div className="row">
        <div className="col-7 m-2">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `Transaction_Report_${now()}`,
                excelMapping(ledger, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>

        <div className=" col m-2 daterange-picker">
          <Form.Group>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>
          <div style={{ marginLeft: "10px" }}>
            {" "}
            <Button
              className=""
              style={{ borderRadius: ".4rem" }}
              variant="primary"
              onClick={getAllVirtualPayments}
            >
              {"Search"}
            </Button>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className="text-center"
                style={{
                  fontSize: "0.9rem",
                  background: "#f5f6f8",
                  color: "#586275",
                }}
              >
                {heading.text}
              </th>
            ))}
          </tr>

          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td
                  key={cell.dataField}
                  style={{ fontSize: ".8rem", color: cell.color }}
                >
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        "refunded" ||
                        e[cell.dataField].toString().toLowerCase() ===
                          "refund") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}....
                        </label>
                      )}
                      {e[cell.dataField] === "SettlementCompleted" && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" ||
                        e[cell.dataField] === "Accepted") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        "failed" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};
export const Payin = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { removeMyAccountInfo, setTransactionLoaderState } =
    useContext(GlobalContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data
            .filter((acc) => {
              return Number(acc.creditAmount) != 0;
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      setTransactionLoaderState(false);
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div div style={{ background: "white" }}>
      <div
        style={{
          background: "#fff",
        }}
      >
        <div className="row">
          <div className="col-7 m-2">
            <div
              className="d-flex"
              style={{
                cursor: "pointer",
                border: "1px solid",
                height: "2.5rem",
                width: "11rem",
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                bordercolor: "rgba(43, 193, 85, 1)",
                borderRadius: ".4rem",
              }}
              onClick={() => {
                ExportToExcel(
                  `DMT_Report_${now()}`,
                  excelMapping(dataToDisplay, columns)
                );
              }}
            >
              <img
                alt=""
                src={require("../../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              />
              <strong
                className="d-flex align-items-center"
                style={{ marginLeft: "2px" }}
              >
                Export to Excel
              </strong>
            </div>
          </div>

          <div className=" col m-2 daterange-picker">
            <Form.Group>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
            <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>

            <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                placeholderText="End Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
            <div style={{ marginLeft: "10px" }}>
              {" "}
              <Button
                className=""
                style={{ borderRadius: ".4rem" }}
                variant="primary"
                onClick={() => {}}
              >
                {"Search"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className="text-center"
                style={{
                  fontSize: "0.9rem",
                  background: "#f5f6f8",
                  color: "#586275",
                }}
              >
                {heading.text}
              </th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td
                  key={cell.dataField}
                  style={{ fontSize: ".8rem", color: cell.color }}
                >
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        "refunded" ||
                        e[cell.dataField].toString().toLowerCase() ===
                          "refund") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField] === "SettlementCompleted" && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" ||
                        e[cell.dataField] === "Accepted") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        "failed" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};

export const PayIN = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { removeMyAccountInfo, setTransactionLoaderState } =
    useContext(GlobalContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm}-${
      date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
    }`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data

            .filter((acc) => {
              return acc.transactiontype == "Virtual";
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),

              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      setTransactionLoaderState(false);
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div style={{ background: "white" }}>
      <div className="row">
        <div className="col-7 m-2">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `Payout_Report_${now()}`,
                excelMapping(ledger, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>

        <div className=" col m-2 daterange-picker">
          <Form.Group>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>
          <div style={{ marginLeft: "10px" }}>
            {" "}
            <Button
              className=""
              style={{ borderRadius: ".4rem" }}
              variant="primary"
              onClick={getAllVirtualPayments}
            >
              {"Search"}
            </Button>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className="text-center"
                style={{
                  fontSize: "0.9rem",
                  background: "#f5f6f8",
                  color: "#586275",
                }}
              >
                {heading.text}
              </th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td
                  key={cell.dataField}
                  style={{ fontSize: ".8rem", color: cell.color }}
                >
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        "refunded" ||
                        e[cell.dataField].toString().toLowerCase() ===
                          "refund") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField].toString().toLowerCase() ==
                        "settlementcompleted" ||
                        e[cell.dataField].toString().toLowerCase() ==
                          "success") && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" ||
                        e[cell.dataField] === "Accepted") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        "failed" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};

export const Payout = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { removeMyAccountInfo, setTransactionLoaderState } =
    useContext(GlobalContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    const mm = date.getMonth() + 1;
    return `${date.getFullYear()}-${mm > 9 ? mm : "0" + mm}-${
      date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
    }`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data

            .filter((acc) => {
              return Number(acc.debitAmount) != 0;
            })
            .map((acc) => {
              return {
                ...acc,
                upi: `${acc.beneAccountNo}@yesbankltd`,
                date: new Date(acc.createdOn),
              };
            });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      setTransactionLoaderState(false);
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div style={{ background: "white" }}>
      <div className="row">
        <div className="col-7 m-2">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `Payout_Report_${now()}`,
                excelMapping(ledger, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>

        <div className=" col m-2 daterange-picker">
          <Form.Group>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>
          <div style={{ marginLeft: "10px" }}>
            {" "}
            <Button
              className=""
              style={{ borderRadius: ".4rem" }}
              variant="primary"
              onClick={getAllVirtualPayments}
            >
              {"Search"}
            </Button>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className="text-center"
                style={{
                  fontSize: "0.9rem",
                  background: "#f5f6f8",
                  color: "#586275",
                }}
              >
                {heading.text}
              </th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td
                  key={cell.dataField}
                  style={{ fontSize: ".8rem", color: cell.color }}
                >
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        "refunded" ||
                        e[cell.dataField].toString().toLowerCase() ===
                          "refund") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField].toString().toLowerCase() ==
                        "settlementcompleted" ||
                        e[cell.dataField].toString().toLowerCase() ==
                          "success") && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" ||
                        e[cell.dataField] === "Accepted") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        "failed" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};

export const Ledger = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { removeMyAccountInfo, setTransactionLoaderState } =
    useContext(GlobalContext);

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10000000,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Payments/GetUserledger",
        requestOptions
      );
      console.log("response", res);
      if (res.status == 401) {
        removeMyAccountInfo();
      } else if (res.ok) {
        const data = await res.json();
        console.log(data);
        if (data && data.length > 0) {
          const dataLoad = data.map((acc) => {
            return {
              ...acc,
              upi: `${acc.beneAccountNo}@yesbankltd`,
              date: new Date(acc.createdOn),
            };
          });
          dataLoad.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          console.log(dataLoad);
          setLedger(dataLoad);
          setDataToDisplay(dataLoad);
        }
      }
      setTransactionLoaderState(false);
      // setPayments(JSON.parse(body).data);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("401 catched", error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 999) / 1000));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 999;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 1000;
    const endIdx = currentPage * 1000 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 999) / 1000));
    const startIdx = 0;
    const endIdx = 999;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <div style={{ background: "white" }}>
      <div className="row">
        <div className="col-7 m-2">
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
              border: "1px solid",
              height: "2.5rem",
              width: "11rem",
              backgroundColor: "rgba(43, 193, 85, 0.1)",
              color: "rgba(43, 193, 85, 1)",
              bordercolor: "rgba(43, 193, 85, 1)",
              borderRadius: ".4rem",
            }}
            onClick={() => {
              ExportToExcel(
                `DMT_Report_${now()}`,
                excelMapping(dataToDisplay, columns)
              );
            }}
          >
            <img
              alt=""
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem", marginLeft: "15px" }}
            />
            <strong
              className="d-flex align-items-center"
              style={{ marginLeft: "2px" }}
            >
              Export to Excel
            </strong>
          </div>
        </div>

        <div className=" col m-2 daterange-picker">
          <Form.Group>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              placeholderText="Start Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: "10px", width: "8rem" }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control"
              placeholderText="End Date"
              style={{ borderRadius: "0px" }}
            />
          </Form.Group>
          <div style={{ marginLeft: "10px" }}>
            {" "}
            <Button
              className=""
              style={{ borderRadius: ".4rem" }}
              variant="primary"
              onClick={() => {}}
            >
              {"Search"}
            </Button>
          </div>
        </div>
      </div>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            {columns.map((heading, index) => (
              <th
                key={index}
                className="text-center"
                style={{
                  fontSize: "0.9rem",
                  background: "#f5f6f8",
                  color: "#586275",
                }}
              >
                {heading.text}
              </th>
            ))}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              {columns.map((cell) => (
                <td
                  key={cell.dataField}
                  style={{ fontSize: ".8rem", color: cell.color }}
                >
                  {cell.dataField === "status" && (
                    <>
                      {(e[cell.dataField].toString().toLowerCase() ===
                        "refunded" ||
                        e[cell.dataField].toString().toLowerCase() ===
                          "refund") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField] === "SettlementCompleted" && (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                          }}
                          className="badge badge-success"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {(e[cell.dataField] === "Received" ||
                        e[cell.dataField] === "Accepted") && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          {e[cell.dataField]}
                        </label>
                      )}
                      {e[cell.dataField].toString().toLowerCase() ===
                        "failed" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-danger"
                        >
                          Failed
                        </label>
                      )}
                      {e[cell.dataField] === "0" && (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                            color: "rgba(0, 123, 255, 1)",
                            border: "1px solid rgba(0, 123, 255, 1)",
                            borderColor: "rgba(0, 123, 255, 1)",
                            fontSize: "0.8rem",
                          }}
                          className="badge badge-warning"
                        >
                          Virtual
                        </label>
                      )}
                    </>
                  )}
                  {cell.dataField !== "status" && e[cell.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  );
};

export const PayinSettlement = ({ setDetails }) => {
  // const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [settlementButton, setsettlementButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  let { myAccountInfo: info } = useContext(GlobalContext);

  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const reportApi = async () => {
    setLoading(true);
    const rawData = {
      //"merchantId": "2",
      merchantId: info.userId == "66" ? "1043" : info.userId == "2" ? "2" : "",
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      const response = await axios.post(
        `https://bumppy.in/payin/api/sdkClientSettlementRecords.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data);
        setsettlementButton("true");
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateSettlementStatus = async (ledgerId, status) => {
    console.log("ledgerId", ledgerId, "status", status);
    try {
      let apiStatus = status === "SETTLED" ? "UNSETTLED" : "SETTLED";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://reports.remittpe.com/api/Intent/updatePayinSettlement",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const calculateSettlement = async (ledgerId) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: "",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/calculatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const initiateSettlement = async (ledgerId, settlement, status) => {
    try {
      let apiStatus = status === "N" ? "Y" : "N";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        settlement: settlement,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/initiatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();
      if (responseData.status == "00") {
        swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const ViewUPIIntentReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        // merchant_id: "1043",
        merchant_id: info.userId == "66" ? "1043" : info.userId == "2" ? "2" : "",
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://reports.remittpe.com/api/Intent/GetMerchantLedgerReport`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(
          data.data.map((e) => ({
            ...e,
            settlementAmount:
              parseFloat(e.payin_amount) -
              parseFloat(e.payin_charge) -
              parseFloat(e.payin_gst),
          }))
        );
        setDataToDisplay(
          data.data.map((e) => ({
            ...e,
            settlementAmount:
              parseFloat(e.payin_amount) -
              parseFloat(e.payin_charge) -
              parseFloat(e.payin_gst),
          }))
        );
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    ViewUPIIntentReport();
  }, []);

  const PayAllApi = async () => {
    setLoading(true);
    const rawData = {
      // merchantId: "2",
      merchantId: info.userId == "66" ? "1043" : info.userId == "2" ? "2" : "",
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      const response = await axios.post(
        ` https://bumppy.in/payin/api/sdkClientSettlementAdmin.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    reportApi();
  };

  const handleSettleConfirmation = (id, status) => {
    swal({
      title: "Are you sure?",
      text: `You are about to ${
        status === "SETTLED" ? "Unsettle" : "Settle"
      } the entry with ID ${id}.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willProceed) => {
      if (willProceed) {
        updateSettlementStatus(id, status);
      }
    });
  };

  const TopUp = (id) => {
    swal({
      title: "Are you sure?",
      text: `You want to Settle the Amount with ID ${id}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        payinAmountSettlement(id);
      }
    });
  };

  const payinAmountSettlement = async (id, settlementAmount) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cGd1c2VybmV3OlRlc3RAMTIzNDU=");

      var raw = JSON.stringify({
        // merchantId: user.merchantId,
        settlementId: id,
        amount: settlementAmount,
        merchantSecret: "wetcvb346346dfgdryer5ye5y",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://reports.remittpe.com/api/Intent/payinAmountSettlement`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == "00") {
        swal("Success", data.msg, "success");
      } else if (data.status == "01") {
        swal("Failed", data.msg, "error");
      } else {
        swal("Failed", data.msg ? data.msg : "Something went wrong", "warning");
      }
    } catch (err) {
      swal("Failed", data.msg ? data.msg : "Something went wrong", "warning");
    }
  };

  return (
    <div
      className=" p-4"
      style={{ backgroundColor: "#F1EFEF", fontFamily: "poppins" }}
    >
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">Please wait...</div>
        </div>
      )}
      <div className="card shadow rounded m-2" style={{ background: "white" }}>
        <div
          className="row m-2 p-0 pt-4 align-items-center"
          id="parentFilters"
          style={{ background: "white" }}
        >
          <div
            className="m-0 p-0 pl-2 flex-grow-1 d-flex "
            style={{
              fontFamily: "Poppins",
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",
              color: "#2c3e50",
              flex: 1,
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
              minWidth: "150px",
            }}
          >
            Settlement Pay : <span className="text-success">{"1043"}</span>
          </div>
          <div
            id="filters"
            style={{ gap: 3, display: "flex", background: "white" }}
          >
            <Form.Group
              className="p-0 m-0 "
              id="parentDatePicker"
              style={{ minWidth: "250px", display: "flex", gap: 3 }}
            >
              <DatePicker
                className="form-control rounded-1 d-flex flex-grow-1"
                style={{
                  borderColor: "#ccc",
                }}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />

              <DatePicker
                className="form-control rounded-1 d-flex flex-grow-1"
                style={{
                  borderColor: "#ccc",
                }}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Form.Group>
            <div className="d-flex align-items-center" id="searchButton">
              <Button
                className=" btn btn-lg d-flex flex-grow-1 "
                style={{ fontSize: "0.7rem", background: "#2c3e50" }}
                onClick={handleSubmit}
              >
                {"Search"}
              </Button>
              {settlementButton && (
                <>
                  <div className="mt-4 col-12 col-lg-2">
                    <button
                      onClick={PayAllApi()}
                      className=" btn btn-lg btn-block btn-danger"
                      style={{ width: "100%" }}
                    >
                      All Settlement
                    </button>
                  </div>
                </>
              )}
            </div>{" "}
            <div
              className=" mb-3 d-flex align-items-center justify-content-center "
              style={{
                cursor: "pointer",
                border: "1px solid",
                marginTop: "15px",
                width: "11rem",
                alignItems: "center",
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                bordercolor: "rgba(43, 193, 85, 1)",
                borderRadius: ".4rem",
                minWidth: "170px",
              }}
              onClick={() => {
                ExportToExcel(
                  `payin_ledger_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                alt=""
                // src={require("../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              />
              <strong
                className="d-flex align-items-center m-2 "
                style={{ minWidth: "120px", fontSize: "0.8rem" }}
              >
                {" "}
                Export to Excel
              </strong>
            </div>
          </div>
        </div>
        <div className=" bg-white p-2 ">
          <table className="table" style={{ fontFamily: "poppins" }}>
            <thead>
              <tr>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Id
                </th>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Payin Amount
                </th>
                <th
                  className=" text-white text-center py-3"
                  style={{ background: "#2c3e50" }}
                >
                  Payin Charge
                </th>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Payin Gst
                </th>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Settlement Amount
                </th>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Transaction time
                </th>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Settlement time
                </th>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Last Updated
                </th>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >
                  Status
                </th>
                {info && info.userId == "2" && (
                  <th
                    className=" text-white text-center py-3"
                    style={{ background: "#2c3e50" }}
                  >
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                dataToDisplay.map((item) => (
                  <tr key={item.UserName} style={{ border: "none" }}>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.id || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.payin_amount || "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.payin_charge !== undefined
                        ? parseFloat(item.payin_charge).toFixed(2)
                        : "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.payin_gst !== undefined
                        ? parseFloat(item.payin_gst).toFixed(2)
                        : "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.settlementAmount !== undefined
                        ? parseFloat(item.settlementAmount).toFixed(2)
                        : "NA"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.settlement_time}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.settlement_time == "5PM-8AM"
                        ? "03:00 PM"
                        : "11:00 PM"}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.indate}
                    </td>
                    <td
                      className="text-center align-middle"
                      style={{ border: "none" }}
                    >
                      {item.status !== undefined &&
                      item.status === "SETTLED" ? (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            width: "100px",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                            textAlign: "center",
                          }}
                          className="badge badge-success"
                        >
                          {"Settled"}
                        </label>
                      ) : item.status !== undefined &&
                        item.status === "UNSETTLED" ? (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            width: "100px",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                            color: "rgba(255, 0, 0, 1)",
                            border: "1px solid rgba(255, 0, 0, 1)",
                            borderColor: "rgba(255, 0, 0, 1)",
                            fontSize: "0.8rem",
                            textAlign: "center",
                          }}
                          className="badge badge-danger"
                        >
                          {"Unsettled"}
                        </label>
                      ) : (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            width: "100px",
                            backgroundColor: "rgba(255, 238, 0, 0.1)",
                            color: "rgba(255, 196, 0, 1)",
                            border: "1px solid rgba(255, 238, 0, 1)",
                            borderColor: "rgba(255, 238, 0, 1)",
                            fontSize: "0.8rem",
                            textAlign: "center",
                          }}
                          className="badge badge-warning"
                        >
                          {"Unsettled"}
                        </label>
                      )}
                    </td>
                    {info && info.userId == "2" && (
                      <td
                        className="text-center align-middle"
                        style={{ border: "none" }}
                      >
                        <Button
                          className="btn"
                          style={{
                            width: "150px",
                            backgroundColor: "#427D9D",
                            color: "white",
                            border: "none",
                          }}
                          onClick={() =>
                            handleSettleConfirmation(item.id, item.status)
                          }
                        >
                          {item.status === "SETTLED" ? "UNSETTLE" : "SETTLE"}
                        </Button>
                        <Button
                          className="btn m-1"
                          style={{
                            width: "80px",
                            backgroundColor: "#DDF2FD",
                            color: "#192655",
                            border: "1px #192655",
                          }}
                          onClick={() => {
                            TopUp(item.id, item.settlementAmount);
                          }}
                        >
                          {"TOP-UP"}
                        </Button>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.visibility = "visible";
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.opacity = "1";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.visibility = "hidden";
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.opacity = "0";
                          }}
                        >
                          <Button
                            style={{
                              borderRadius: "5px",
                              border: "1px none",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              color: "rgba(255, 164, 27, 1)",
                              borderColor: "rgba(255, 255, 255, 1)",
                            }}
                            className="btn btn-sm rounded-100 m-0 p-1"
                            onClick={() => {
                              calculateSettlement(item.id);
                            }}
                          >
                            <div className="hover-container">
                              <a>
                                <img
                                // src={Image2}
                                // alt="Clickable Image"
                                />
                              </a>
                            </div>
                          </Button>
                          <span
                            style={{
                              visibility: "hidden",
                              width: "100px",
                              backgroundColor: "#555",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px 0",
                              position: "absolute",
                              zIndex: "1",
                              bottom: "125%",
                              left: "50%",
                              marginLeft: "-50px",
                              opacity: "0",
                              transition: "opacity 0.3s",
                            }}
                            className="tooltip-text"
                          >
                            Calculate Settlement
                          </span>
                        </div>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.visibility = "visible";
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.opacity = "1";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.visibility = "hidden";
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.opacity = "0";
                          }}
                        >
                          <Button
                            style={{
                              borderRadius: "5px",
                              border: "1px none",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              color: "rgba(255, 164, 27, 1)",
                              borderColor: "rgba(255, 255, 255, 1)",
                            }}
                            className="btn btn-sm rounded-100 m-0 p-1"
                            onClick={() => {
                              calculateSettlement(item.id);
                            }}
                          >
                            <div className="hover-container">
                              <a>
                                <img
                                // src={require("../assets/images/Update Access.png")}
                                // alt="Clickable Image"
                                />
                              </a>
                            </div>
                          </Button>

                          <span
                            style={{
                              visibility: "hidden",
                              width: "100px",
                              backgroundColor: "#555",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px 0",
                              position: "absolute",
                              zIndex: "1",
                              bottom: "125%",
                              left: "50%",
                              marginLeft: "-50px",
                              opacity: "0",
                              transition: "opacity 0.3s",
                            }}
                            className="tooltip-text"
                          >
                            Settlement 1
                          </span>
                        </div>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.visibility = "visible";
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.opacity = "1";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.visibility = "hidden";
                            e.currentTarget.querySelector(
                              ".tooltip-text"
                            ).style.opacity = "0";
                          }}
                        >
                          <Button
                            style={{
                              borderRadius: "5px",
                              border: "1px none",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              color: "rgba(255, 164, 27, 1)",
                              borderColor: "rgba(255, 255, 255, 1)",
                            }}
                            className="btn btn-sm rounded-100 m-0 p-1"
                            onClick={() => {
                              initiateSettlement(
                                item.id,
                                "2",
                                item.settlement_status_2
                              );
                            }}
                          >
                            <div className="hover-container">
                              <a>
                                <img
                                // src={require("../assets/images/Rehit.png")}
                                // alt="Clickable Image"
                                />
                              </a>
                            </div>
                          </Button>
                          <span
                            style={{
                              visibility: "hidden",
                              width: "100px",
                              backgroundColor: "#555",
                              color: "#fff",
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px 0",
                              position: "absolute",
                              zIndex: "1",
                              bottom: "125%",
                              left: "50%",
                              marginLeft: "-50px",
                              opacity: "0",
                              transition: "opacity 0.3s",
                            }}
                            className="tooltip-text"
                          >
                            Settlement 2
                          </span>
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* {data.length <= 0 && <NoDataFound />} */}
          <hr />
          <Pagination
            page={currentPage}
            limit={pages}
            callback={(page, offsetPassed) => {
              if (currentPage != page) setCurrentPage(page);
              if (offset != offsetPassed) setOffset(offsetPassed);
            }}
            count={dataToDisplay.length}
            total={total}
          />
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Transactions;
