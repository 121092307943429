import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { basicAuth } from "../../../config";
import Select from "react-select";
import cogoToast from "cogo-toast";
import { BASE_URL_PAYNOW } from "../../../utils/Constants";
import { OFFLINE_TEXT, ONLINE_TEXT } from "../../../utils/StringConstants";
import { onlyNumeric, uniqueID } from "../../../utils/utilFuctions";
import { GlobalContext } from "../../../Context/GlobalState";
import { PRINT_ICON_PATH, BBPS_LOGO_PATH } from "../../../utils/Assets";
import {
  bbpsRechargeFailedPopup,
  bbpsRechargeSuccessPopup,
} from "../../../utils/Models";
import { validateTPin } from "../../../utils/Validations";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const OfflineMobilePrepaidRechargeSidebar = ({ details }) => {
  const { user, refreshWallet, Profileinfo, checkWalletBeforeBBPSBillPay } =
    useContext(GlobalContext);
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  const { getRechargeReport } = details;
  const [providers, setProviders] = useState([]);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [PayClicked, setPayClicked] = useState(false);
  const [CustP, setCustP] = useState([]);
  const [Amount, setAmount] = useState("");
  const [tPin, setTPin] = useState("");
  const [formErrors, setFormErrors] = useState({
    provider: "",
    consumer: "",
    amount: "",
  });
  const [tpinShow, setTpinShow] = useState(false);

  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify(data));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const validate = (callback) => {
    let errors = {};
    if (state.provider == "") {
      errors.provider = "please select valid provider";
    }
    if (state.consumer.toString().trim() == "") {
      errors.consumer = "please enter valid consumer number";
    }

    if (state.consumer.toString().trim().length != 10) {
      errors.consumer = "please enter valid consumer number";
    }
    if (Amount.toString().trim() == "" || Amount == 0) {
      errors.amount = "please enter valid Amount";
    }
    if (tPin.length == 0) {
      errors.tPin = "please enter T Pin";
    }
    if (tPin.length != 6) {
      errors.tPin = "please enter valid T Pin";
    }
    if (Object.keys(errors).length === 0) {
      callback();
    } else {
      setFormErrors(errors);
    }
  };
  const payBillClickHandler = () => {
    validate(payBill);
  };

  const clear = () => {
    setState({
      provider: "",
      consumer: "",
      mobileNumber: "",
      custparams: "",
    });
    setAmount("");
    document
      .querySelector(".content-wrapper")
      .classList.toggle("sidebar-icon-only");
  };

  const pay = () => {
    setPayClicked(true);
    checkWalletBeforeBBPSBillPay(
      Amount,
      () => {
        tpinValidation();
      },
      () => {
        setPayClicked(false);
      }
    );
  };

  const tpinValidation = async () => {
    validateTPin(
      Profileinfo.mobile,
      tPin,
      () => {
        payBill();
      },
      () => {
        setPayClicked(false);
      }
    );
  };

  const payBill = async () => {
    setPayClicked(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLBfoOkVkY05kLzokPEjB2gYPZGX2IFI8p3H0PEzF-p7wNZjiZfnXRdvZdIMIyaC1lYuwdjzFmdperUM0s9TM94lKMbwmIV0Pb59n7l7GoS9_79dTq5PWPZ0y3VdPKnyVo03tMA9YcwFLWgiuSXqENgj4_89begK4a8D2ZtoerNgjvoUB42YQrPaqK03_hQ_bQ5gokfp-WDObRNe2E6tchKD-li8J2lJqv_mk2nggZqHG3Ifugxw76p8N8_zupiGMhMnAbh4f96POibRScoZR7TL21UYKn9whd8OfiXzt9qT0HP2wraqsSCBvhjGeeocfJXi7LJ-AQGVnlLK2F15MNC5DxCRMnYP85B6qX3UoJ0IxXTcwJarMuClTLT0C_cYOkLDz7NF-DcFx4FtokyCvN6Jz4ZvTjOjnB9Coz75_GGjwHJNP3Un0RS87YFiDCssQYLLc2_rhS0BcGQz0Ex-oqnYRflsTI0guSTVj65oieVS1U9nE1RovsN6rUECWw4OMao1iRrVEgg77JZsYWRnGSuPXG2s4H8W5LvTjKoCA71m0wTV10FRhRHRa4-jrxHjv7nU9bzfvHNalmofvZgmIZuMzU2fTr35aYeg9E2ReWTIg6iq60FYeFZCnf3ce_N_MAWxHo11U0OWyt3uy9HEdHU0"
    );

    var raw = JSON.stringify({
      consumer_no: state.consumer,
      amount: Amount,
      operatorcode: state.provider.code,
      ebill_type: state.provider.biller_type,
      unique_id: uniqueID(),
      mobile_no: Profileinfo.mobile,
      retailer_id: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillPayOffline`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data.status == "00") {
        bbpsRechargeSuccessPopup("", Amount, data.responseMSG);
      } else {
        bbpsRechargeFailedPopup(
          "",
          Amount,
          data.responseMSG ? data.responseMSG : "Failed"
        );
      }
      setPayClicked(false);
      refreshWallet();
      getRechargeReport();
      clear();
      //cogoToast.info(data.status);
    } catch (error) {
      console.log("error", error);
      bbpsRechargeFailedPopup("", Amount, "Failed");
      setPayClicked(false);
      refreshWallet();
      getRechargeReport();
      clear();
    }
  };

  useEffect(() => {
    setProviders(
      details.providers.map((pro) => {
        return { ...pro, value: pro.biller_name, label: pro.biller_name };
      })
    );
  }, [details]);

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  return (
    <nav
      className="side-sheet sidebar Sidebar23"
      id="sidebar2"
      style={Sidebar22}
    >
      <div
        style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
        className="row"
      >
        <div
          style={{ padding: "6px", display: "flex", alignItems: "center" }}
          className="col"
        >
          <strong style={{ color: "#1b68ca" }}>
            {"Prepaid Mobile Recharge"}
          </strong>
        </div>
        <div style={{ paddingRight: "0px" }} className="col">
          <span
            style={{
              fontSize: "2rem",
              display: "flex",
              color: "#b2b2b3",
              justifyContent: "end",
              ariaHidden: "true",
              paddingRight: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only");
            }}
          >
            &times;
          </span>
        </div>
      </div>
      <Dropdown.Divider />

      <Card style={{ margin: "10px", padding: "10px" }}>
        <Form.Group style={{ flex: "1" }}>
          <Select
            placeholder={`Select Provider`}
            options={providers}
            onChange={(e) => {
              setState({
                ...state,
                provider: e,
              });
            }}
          />
          <p style={{ color: "red" }} className="">
            {formErrors.provider}
          </p>
        </Form.Group>
        <Form.Group style={{ flex: "1" }}>
          <Form.Label>{`Mobile No`}</Form.Label>
          <Form.Control
            type="number"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter Mobile No"
            onChange={(e) => {
              setState({ ...state, consumer: e.target.value });
              //   setAmount(e.target.value);
            }}
            value={state.consumer}
          />
          <p style={{ color: "red" }} className="">
            {formErrors.consumer}
          </p>
        </Form.Group>

        <Form.Group style={{ flex: "1" }}>
          <Form.Label>{`Amount`}</Form.Label>
          <Form.Control
            type="number"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter Amount"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            value={Amount}
          />
          <p style={{ color: "red" }} className="">
            {formErrors.amount}
          </p>
        </Form.Group>

        <Form.Group style={{ flex: "1" }}>
          <Form.Label>{`T PIN`}</Form.Label>
          <div className="input-group">
            <div className="input-group-prepend bg-transparent">
              <span className="input-group-text bg-transparent border-right-0 no-glow">
                <i className="mdi mdi-lock-outline text-primary"></i>
              </span>
            </div>
            <input
           autocomplete="new-password"
           autoCorrect="off"
           autoSave="new-password"
              type={tpinShow ? "text" : "password"}
              className="form-control form-control-lg border-left-0 border-right-0 no-glow"
              id="exampleInputPassword"
              placeholder="Enter T Pin"
              onChange={(e) => {
                if (e.target.value != "" && !onlyNumeric(e.target.value)) {
                  setFormErrors({
                    ...formErrors,
                    tPin: "T Pin contains only numeric values",
                  });
                } else {
                  setTPin(e.target.value);
                  setFormErrors({ ...formErrors, tPin: "" });
                }
              }}
              value={tPin}
            />
            <div className="input-group-prepend bg-transparent">
              <span
                className="input-group-text bg-transparent border-left-0 no-glow"
                onClick={showTPinClick}
                style={{ borderRadius: "4px" }}
              >
                <i
                  className={`mdi text-primary ${
                    tpinShow ? "mdi-eye" : "mdi-eye-off"
                  }`}
                ></i>
              </span>
            </div>
          </div>
          <p style={{ color: "red" }} className="">
            {formErrors.tPin}
          </p>
        </Form.Group>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {PayClicked ? (
            <Button className="rounded-0" variant="success">
              {" "}
              Please Wait ...
            </Button>
          ) : (
            <Button
              className="rounded-0"
              variant="success"
              onClick={() => {
                validate(pay);
              }}
            >
              {" "}
              Make Recharge
            </Button>
          )}
        </div>
      </Card>
      {billDetails && (
        <Card style={{ margin: "10px", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={BBPS_LOGO_PATH}
              style={{
                width: "auto",
                height: "1.2rem",
                backgroundColor: "whitesmoke",
              }}
            />
            <img
              onClick={() => {
                openInNewTab(billDetails);
              }}
              style={{ height: "1.5rem" }}
              src={PRINT_ICON_PATH}
            />
          </div>
          <table style={{ border: "none", fontFamily: "-webkit-body" }}>
            <tr style={{ background: "none", border: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Name
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.customerName}
              </td>
            </tr>
            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Bill Number
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.billNumber}
              </td>
            </tr>
            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Amount
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.amount}
              </td>
            </tr>
            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Bill date
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.billDate}
              </td>
            </tr>

            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Due Date.
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.dueDate}
              </td>
            </tr>
            <td
              style={{
                paddingLeft: "1rem",
                border: "none",
                background: "none",
                width: "50%",
                fontSize: "0.8rem",
              }}
            >
              {" "}
              Reference Id
            </td>
            <td
              style={{
                paddingLeft: "1rem",
                border: "none",
                background: "none",
                width: "50%",
                fontSize: "0.8rem",
              }}
            >
              {" "}
              {billDetails.refId}
            </td>
          </table>
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "5rem",
            }}
          >
            {PayClicked ? (
              <Button className="rounded-0" variant="success">
                {" "}
                Please Wait ...
              </Button>
            ) : (
              <Button
                className="rounded-0"
                variant="success"
                onClick={() => {
                  validate(pay);
                }}
              >
                {" "}
                Make Recharge
              </Button>
            )}
          </div>
        </Card>
      )}
    </nav>
  );
};

export default withRouter(OfflineMobilePrepaidRechargeSidebar);
