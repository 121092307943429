import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Card, Dropdown, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import DMTExpressTable from "./DMTExpressTable";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";
import cogoToast from "cogo-toast";
import Select from "react-select";
import swal from "sweetalert";

import {
  bumppyKYCWarning,
  customerKYCFailedPopup,
  dmtTransactionFailedPopup,
  dmtTransactionSuccessPopup,
} from "../../utils/Models";
import {
  addRemitterValidation,
  generateHashKey2,
  onlyAlpha,
  validateIFSCCode2,
  validateNumber,
} from "../../utils/utilFuctions";
import DMTExpressSidebar from "./DMTExpressSidebar";
import { RecordNotFound } from "../DMT/DMT";
import DMTExpressSendMoneyModal from "./DMTExpressSendMoneyModal";

const { DMTExpressUrl, DMTWithKYCUrl } = urls;
const columns = [
  {
    dataField: "recipient_name",
    text: "Name",
    sort: false,
  },
  {
    dataField: "bank",
    text: "Bank Name",
    sort: false,
  },
  {
    dataField: "account",
    text: "Account Number",
    sort: false,
  },
  {
    dataField: "ifsc",
    text: "IFSC",
    sort: false,
  },
  {
    dataField: "isVerifiedBenName",
    text: "Verify",
    sort: false,
  },
  // {
  //   dataField: "Mode",
  //   text: "Mode",
  //   sort: false,
  // },
];

const toastoptions = {
  hideAfter: 5,
  position: "top-center",
};
toast.configure();
const DMTExpress = () => {
  const history = useHistory();
  const globalState = useContext(GlobalContext);
  const [number, setNumber] = useState("");
  const [remitterName, setRemmiterName] = useState("");
  const [found, setFound] = useState(true);
  const [remitter, setRemmiter] = useState(null);
  const [beneficiary, setBenificiary] = useState([]);
  const [info, setInfo] = useState(null);
  const [details, setDetails] = useState({});
  const { user, setTransactionLoaderState } = globalState;
  const [formErrors, setFormErrors] = useState({ remitterNameErrors: "" });

  const [sendMoneyModalState, setSendMoneyModelState] = useState({
    isOpen: false,
    details,
  });

  console.log(globalState);

  const successToast = (msg) => {
    cogoToast.success(msg, toastoptions);
  };
  const errorToast = (msg) => {
    cogoToast.error(msg, toastoptions);
  };
  // const success = (msg) => {
  //   cogoToast.success(msg, this.toastoptions);
  // };
  // const success = (msg) => {
  //   cogoToast.success(msg, this.toastoptions);
  // };
  useEffect(() => {
    if (number.length == 10) {
      checkRemitter();
    }
    setFound(true);
  }, [number]);
  const checkRemitter = async () => {
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "checkremitter");
    urlencoded.append("loginid", number);
    urlencoded.append("mobile", number);
    urlencoded.append("name", "N");
    
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);
      const data = await res.json();
      if (data.status == "1") {
        setFound(true);
        setRemmiter(data);
        setRemmiterName(data.name);
        getBeneficiary();
      } else {
        setRemmiterName("");
        setFound(false);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const getBeneficiary = async () => {
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "getbeneficiary2");
    urlencoded.append("mobile", number);
    urlencoded.append("userId", number);
    urlencoded.append("route", "0");
    urlencoded.append("amount", "0");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      setInfo(data);
      setBenificiary(data.data.recipient_list);
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const CreateRemmiter = async () => {
    const error = addRemitterValidation(remitterName);
    if (error.length > 0) {
      // Toast
      swal("Failed", error, "error");
      return;
    }
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "checkremitter");
    urlencoded.append("loginid", number);
    urlencoded.append("mobile", number);
    urlencoded.append("name", remitterName);

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.status == "00" || data.status == 2) {
        // successToast("Remmiter Created successfully");
        swal("Success", "Remmiter Created successfully", "success");
      } else {
        // errorToast("Remmiter not Created");
        swal("Failed", "Remmiter not Created", "error");
      }
      checkRemitter();
      //getBeneficiary();
      setTransactionLoaderState(false);
    } catch (error) {
      // errorToast("OTP not sent, something went wrong");
      swal("Failed", "Error From Server", "error");
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    document
      .querySelector(".content-wrapper")
      .classList.remove("sidebar-icon-only");
    if (user.bp_ob == "N") {
      bumppyKYCWarning(() => {
        history.push("/retailer/dashboard");
      });
    }
  }, []);

  const refreshLimit = async () => {
    checkRemitter();
  };

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <div>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <DMTExpressSendMoneyModal modalstate={sendMoneyModalState} />

              <DMTExpressSidebar
                details={{
                  benificiary: details,
                  user: globalState.user,
                  successToast,
                  errorToast,
                  limit: remitter
                    ? remitter.routes
                      ? remitter.routes.length > 0
                        ? remitter.routes[0].limit
                        : null
                      : null
                    : null,
                  remitterNumber: number,
                }}
              />
            </div>
            <div
              style={{
                padding: "10px",
                textAlign: "center",
              }}
            >
              {remitter == null ? (
                <div style={{ background: "#495579", marginBottom: "10px" }}>
                  <strong style={{ color: "#FFF", fontSize: "1.5rem" }}>
                    {`Available Limit: `}
                  </strong>
                </div>
              ) : (
                <div style={{ background: "#495579", marginBottom: "10px" }}>
                  <strong style={{ color: "#FFF", fontSize: "1.5rem" }}>
                    {`Daily Limit: ${remitter.routes[0].dailylimit} , Monthy Limit: ${remitter.routes[0].monthlylimit}`}
                  </strong>
                </div>
              )}

              <div className="row">
                <div className="col text-left">
                  <span style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                    Find or Create Customer
                  </span>
                </div>
                <div className="col">
                  <Modal2
                    successToast={successToast}
                    errorToast={errorToast}
                    remitter={remitter}
                    remitterNumber={number}
                    userId={globalState.user.ReferId}
                    refresh={() => {
                      getBeneficiary();
                    }}
                  />
                </div>
              </div>

              {/* <div style={{ display: "flex" }}>
          <Button
            className="rounded-0"
            style={{ margin: "6px 0px 6px auto" }}
            variant="success"
            onClick={() =>
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only")
            }
          >
            Transfer to Beneficiary
          </Button>
        </div> */}
              <div style={{ width: "40vw", margin: "10px 0px" }}>
                <div style={{ display: "flex" }}>
                  <Form.Group style={{ flex: "1" }}>
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Customer Mobile Number"
                      onChange={(e) => {
                        setFound(false);
                        setRemmiter(null);
                        setRemmiterName("");
                        setBenificiary([]);
                        if (e.target.value.length <= 10)
                          setNumber(e.target.value);
                      }}
                      value={number}
                      maxLength="10"
                    />
                  </Form.Group>
                </div>
                {remitter && (
                  <div style={{ display: "flex" }}>
                    <Form.Group style={{ flex: "1" }}>
                      <Form.Control
                        value={remitter.name}
                        //  style={{ marginLeft: "10px" }}
                        type="text"
                        className="form-control rounded-0"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        // placeholder="Enter Remitter Name"
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                )}
                {!found && number.length == 10 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {/* <Button
                      style={{
                        backgroundColor: "#3bafda",
                        border: "none",
                        margin: "10px 0px",
                      }}
                      className="rounded-0"
                    >
                      Find Another Number
                    </Button>
                     */}
                    <div
                      className="card-inverse-warning"
                      style={{
                        margin: "10px 0px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {" "}
                        {`Your number is not registered as remitter please create remitter first`}
                      </span>
                    </div>
                    <Form.Group style={{ flex: "1" }}>
                      <Form.Control
                        onChange={(e) => {
                          if (onlyAlpha(e.target.value)) {
                            // console.log("alpha");
                            setFormErrors({
                              ...formErrors,
                              remitterNameErrors: "",
                            });
                          } else {
                            //console.log("numeric");
                            setFormErrors({
                              ...formErrors,
                              remitterNameErrors:
                                "Customer Name Contains only Alphabates",
                            });
                            // alert("message");
                          }
                          setRemmiterName(e.target.value);
                        }}
                        value={remitterName}
                        type="text"
                        className="form-control rounded-0"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Customer Name"
                      />
                      <p style={{ color: "red" }} className="text-center">
                        {formErrors.remitterNameErrors}
                      </p>
                    </Form.Group>

                    <Button
                      style={{ backgroundColor: "#4a89dc", border: "none" }}
                      className="rounded-0"
                      onClick={CreateRemmiter}
                    >
                      {`Create Customer`}
                    </Button>
                  </div>
                )}
              </div>

              {beneficiary.length > 0 && (
                <DMTExpressTable
                  successToast={successToast}
                  errorToast={errorToast}
                  data={beneficiary}
                  columns={columns}
                  remitterNumber={number}
                  userId={context.user.ReferId}
                  refresh={() => {
                    getBeneficiary();
                  }}
                  setSidebarDetails={(detail) => {
                    setDetails(detail);
                    setSendMoneyModelState({
                      setModelState: setSendMoneyModelState,
                      refreshLimit: refreshLimit,
                      isOpen: true,
                      details: {
                        benificiary: detail,
                        user: globalState.user,
                        successToast,
                        errorToast,
                        limit: remitter
                          ? remitter.routes
                            ? remitter.routes.length > 0
                              ? remitter.routes[0].limit
                              : null
                            : null
                          : null,
                        remitterNumber: number,
                      },
                    });
                  }}
                  limit={
                    remitter
                      ? remitter.routes
                        ? remitter.routes.length > 0
                          ? remitter.routes[0].limit
                          : null
                        : null
                      : null
                  }
                />
              )}

              {beneficiary.length == 0 && <RecordNotFound />}
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

function Modal2({
  remitterNumber,
  userId,
  refresh,
  remitter,
  errorToast,
  successToast,
}) {
  const [mdShow, setMdShow] = useState(false);
  const [bank, setBank] = useState({});
  const [ifscCode, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [banks, setBanks] = useState([]);
  const [mobileNo, setMobileNo] = useState("");
  const [popupState, setPopupState] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [textOTP, setTextOTP] = useState("");
  const [formErrors, setFormErrors] = useState({ beneficiaryName: "" });
  const { setTransactionLoaderState,user } = useContext(GlobalContext);

  const validate = () => {
    let errors = {};
    if (beneficiaryName.toString().trim().length < 3) {
      errors.beneficiaryName = "Beneficiary name atleast 3 character long`";
    }
    if (formErrors.beneficiaryName) {
      errors.beneficiaryName = formErrors.beneficiaryName;
    }
    if (accountNumber.toString().trim().length < 9) {
      errors.accountNumber = "please enter valid account number";
    }
    if (ifscCode.toString().trim().length < 8) {
      errors.ifsc = "please enter valid IFSC code";
    }
    if (mobileNo.toString().trim().length != 10) {
      errors.mobile = "please enter valid mobile number";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      sendOTPForAddBeneficiary();
    }
  };

  const getBanks = async () => {

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "banklistnew2");
    urlencoded.append("beneficiaryid", "111");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);

      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data !== null && data.data !== null) {
        const options = data.data.bankList.map((e) => {
          return { ...e, label: e.bankName, value: e.ifsc };
        });
        setBanks(options);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  const sendOTPForAddBeneficiary = async () => {
    if (beneficiaryName.length < 3) {
      swal("Failed", `Beneficiary name atleast 3 character long`, "error");
      return;
    }
    if (formErrors.beneficiaryName.length > 0) {
      swal("Failed", `Beneficiary name only contains alphabates`, "error");
      return;
    }
    if (mobileNo.length == 10) {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("dmt", "GenerateOtpBeneficiary");
      urlencoded.append("ifsctype", "IMPS");
      urlencoded.append("remitterNumber", remitterNumber);
      urlencoded.append("beneName", beneficiaryName);
      urlencoded.append("beneNumber", mobileNo);
      urlencoded.append("ifscCode", ifscCode);
      urlencoded.append("accountNumber", accountNumber);
      urlencoded.append("bankId", bank.bankCode);
      urlencoded.append("userid", userId);
      urlencoded.append("IsVerify", "0");
      urlencoded.append("bank", bank.bankName);
   
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      try {
        const res = await fetch(DMTWithKYCUrl, requestOptions);

        const raw = await res.text();
        const data = JSON.parse(raw);
        if (data.status == 0) {
          successToast("OTP send Successfully");
          setOtpSent(true);
        } else {
          errorToast("something went wrong");
        }
        setTransactionLoaderState(false);

        // refresh();
        // setMdShow(false);
      } catch (error) {
        swal("Failed", `Error From Server`, "error");
        errorToast("something went wrong");
        setTransactionLoaderState(false);
        console.log("error", error);
      }
    } else {
      //
      swal("INVALID", `Please Enter a Valid Number`, "error");
    }
  };
  const resendOTP = async () => {
    setOtpSent(false);
    sendOTPForAddBeneficiary();
  };
  const addBeneficiary = async () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "addbeneficiary");
    urlencoded.append("ifsctype", "IMPS");
    urlencoded.append("remitterNumber", remitterNumber);
    urlencoded.append("beneName", beneficiaryName);
    urlencoded.append("beneNumber", mobileNo);
    urlencoded.append("ifscCode", ifscCode);
    urlencoded.append("accountNumber", accountNumber);
    urlencoded.append("bankId", bank.bankCode);
    urlencoded.append("userid", userId);
    urlencoded.append("IsVerify", textOTP);
    urlencoded.append("bank", bank.bankName);
   
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);

      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.status == 0) {
        successToast("Beneficiary Added successfully");
        swal("Success", `Beneficiary Added successfully`, "success");
        refresh();
        setMdShow(false);
      } else {
        errorToast("something went wrong");
        swal("Failed", `Beneficiary Not Added`, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      errorToast("Invalid OTP");
      setTransactionLoaderState(false);
    }
  };
  const clearForm = () => {
    setIfscCode("");
    setAccountNumber("");
    setBeneficiaryName("");
    setMobileNo("");
    setOtpSent(false);
  };
  useEffect(() => {
    clearForm();
  }, [mdShow]);

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <React.Fragment>
      <Button
        variant="success"
        onClick={() => {
          if (remitter) {
            setMdShow(true);
          } else {
            customerKYCFailedPopup("", "Failed", "Please Enter Valid Customer Number");
            // errorToast("Please Enter Valid Remmiter");
          }
        }}
        className="btn float-right m-2"
      >
        Add Beneficiary
      </Button>
      <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Add Beneficiary</Modal.Title>
        </Modal.Header>
        {/* <SweetAlert
          show={popupState}
          title="Are you sure?"
          text="Rs 3 deducted from your Account"
          type="warning"
          showCancelButton
          onConfirm={() => {
            addBeneficiaryVerify();
            setPopupState(false);
          }}
        /> */}

        <Modal.Body
          style={{ width: "100%", display: "block" }}
          className="account"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Group style={{ flex: "1" }}>
              <Select
                options={banks}
                onChange={(e) => {
                  setBank(e);
                  setIfscCode(e.ifsc);
                }}
              />
              {/* <Dropdown style={{ width: "100%" }}>
                <Dropdown.Toggle
                  style={{ flex: "1", textAlign: "left" }}
                  variant="light"
                  id="dropdown-basic"
                >
                  {bank === null ? "Select Bank" : bank.bankName}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: "100%" }}>
                  {banks.map((bank) => (
                    <Dropdown.Item
                      key={bank.bankCode}
                      onClick={() => {
                        setBank(bank);
                        setIfscCode(bank.ifsc);
                      }}
                    >
                      {bank.bankName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}
            </Form.Group>
            <Form.Group style={{ flex: "1" }}>
              <Form.Control
                value={ifscCode}
                onChange={(e) => {
                  if (validateIFSCCode2(e.target.value))
                    setIfscCode(e.target.value);
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`IFSC Code`}
              />
            </Form.Group>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Group style={{ flex: "1", paddingRight: "10px" }}>
              <Form.Control
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Account Number`}
              />
              <p style={{ color: "red" }} className="text-center">
                {formErrors.accountNumber}
              </p>
            </Form.Group>
            <Form.Group style={{ flex: "1" }}>
              <Form.Control
                onChange={(e) => {
                  if (onlyAlpha(e.target.value)) {
                    // console.log("alpha");
                    setFormErrors({ ...formErrors, beneficiaryName: "" });
                  } else {
                    //console.log("numeric");
                    setFormErrors({
                      ...formErrors,
                      beneficiaryName:
                        "Beneficiary Name Contains only Alphabates",
                    });
                    // alert("message");
                  }
                  setBeneficiaryName(e.target.value);
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Beneficiary Name`}
              />
              <p style={{ color: "red" }} className="text-center">
                {formErrors.beneficiaryName}
              </p>
            </Form.Group>
          </div>
          <Form.Group style={{ flex: "1" }}>
            <Form.Control
              onChange={(e) => {
                if (validateNumber(e.target.value)) setMobileNo(e.target.value);
              }}
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Beneficiary Mobile Number`}
              value={mobileNo}
            />
            <p style={{ color: "red" }} className="text-center">
              {formErrors.mobile}
            </p>
          </Form.Group>
          {!otpSent && (
            <div style={{ display: "flex" }}>
              <Button
                className="rounded-0"
                style={{ flex: "1", margin: "0px 0px" }}
                variant="primary"
                onClick={validate}
              >
                {"Send OTP"}
              </Button>
            </div>
          )}
          {otpSent && (
            <Form.Group style={{ flex: "1" }}>
              <Form.Control
                onChange={(e) => {
                  setTextOTP(e.target.value);
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter OTP`}
                value={textOTP}
              />
            </Form.Group>
          )}
          {otpSent && (
            <div style={{ display: "flex" }}>
              <Button
                className="rounded-0"
                style={{ flex: "1", margin: "0px 0px" }}
                variant="success"
                onClick={addBeneficiary}
              >
                {"Add Beneficiary"}
              </Button>
              <Button
                className="rounded-0"
                style={{ flex: "1", margin: "0px 0px 0px 12px" }}
                variant="primary"
                onClick={resendOTP}
              >
                {"Resend OTP"}
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default DMTExpress;
