import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { ContextMenuTrigger } from "react-contextmenu";
import swal from "sweetalert";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_APISERVICES,
} from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import Banner1 from "../../../assets/images/Bumppy Payments1.png";
import Banner2 from "../../../assets/images/Bumppy Payments2.png";
import Banner3 from "../../../assets/images/Bumppy Payments3.png";
import Banner4 from "../../../assets/images/Bumppy Payments4.png";

function ServicesRequest() {
  const images = [Banner1, Banner4, Banner2];
  const initialState = {
    name: "",
    userid: "",
    type: "",
    remark: "",
  };
  const [state, setState] = useState(initialState);
  const [userRoles, setUserRoles] = useState([]);
  const [remark, setRemark] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [data, setData] = useState([]);
  const { user, setTransactionLoaderState, Profileinfo } =
    useContext(GlobalContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
    const interval = setInterval(changeImage, 3000);

    return () => clearInterval(interval);
  }, []);

  const validateForm = () => {
    if (selectedService == null) {
      swal("Invalid", "Please Select service", "error");
      return;
    }
    submitServiceForm();
  };

  const clearForm = () => {
    setSelectedService(null);
    setRemark("");
  };
  const submitServiceForm = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
        serviceId: selectedService.value,
        serviceName: selectedService.label,
        remarks: remark,
        audoDeductConsent: "YES",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/User/submitServiceRequest`,
        requestOptions
      );

      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMessage, "success");
        clearForm();
      } else {
        swal("Failed", data.responseMessage, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("User Registration Failed", "Error From Server", "error");
    }
  };

  const servicesList = async () => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/services/getServicesRequestList",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserRoles(
          data.data.map((data) => {
            return {
              label: `${data.service_name}`,
              value: data.service_id,
            };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    servicesList();
  }, []);

  return (
    <div
      className="row vh-100"
      style={{
        background: "#F2F6FC",
      }}
    >
      <div
        className="col-md-6 "
        style={{
          borderRadius: "8px",
          background: "#FFFFFF",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "2rem",
        }}
      >
        <div style={{ width: "100%" }}>
          <h2
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "28px",
              fontWeight: "600",
              color: "#4267B2",
              textAlign: "left",
              marginBottom: "1.5rem",
            }}
          >
            Services Request
          </h2>

          <div className="row mb-3">
            <div className="col">
              <div>
                <Form.Group>
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {`Name`}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Enter Name"
                    style={{ borderRadius: "0px" }}
                    value={Profileinfo?.name}
                    disabled
                  />
                </Form.Group>
              </div>
            </div>
            <div className="col">
              <div>
                <Form.Group>
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {`User ID`}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Enter Name"
                    style={{ borderRadius: "0px" }}
                    disabled
                    value={user.ReferId}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <div>
                <Form.Group>
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {`Service`}
                  </Form.Label>
                  <Select
                    options={userRoles}
                    value={selectedService}
                    onChange={(e) => {
                      setSelectedService(e);
                    }}
                  ></Select>
                </Form.Group>
              </div>
            </div>
            <div className="col">
              <div>
                <Form.Group>
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {`Plan Type`}
                  </Form.Label>
                  <Select
                    options={[{ value: "monthly", label: "One Time Payment" }]}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderRadius: "8px",
                        borderColor: "#CED4DA",
                      }),
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="d-flex mt-4" style={{ gap: "20px" }}>
            <Button
              className="btn btn-primary"
              style={{
                borderRadius: "8px",
                padding: "10px 20px",
                fontWeight: "bold",
              }}
              onClick={validateForm}
            >
              Submit
            </Button>
            <Button
              className="btn"
              style={{
                borderRadius: "8px",
                padding: "10px 20px",
                fontWeight: "bold",
                background: "#FFCC01",
                color: "#ffffff",
                border: "none",
              }}
              onClick={clearForm}
            >
              Clear
            </Button>
          </div>

          <div className="mt-3 ml-1">
            <span style={{ color: "#0F4791" }}>Note :</span> Amount of 799 + GST
            will be deducted from your wallet
          </div>
        </div>
      </div>

      <div className="image-slider my-4 h-80">
        <div className="slide active ">
          <div className="">
            <img
              src={images[currentImageIndex]}
              alt={`Image `}
              style={{
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesRequest;
