import React from "react";
import { GlobalContext } from "../../Context/GlobalState";
import QrReportTable from "./QrReportTable";
const QrReport = () => {
  return (

    <GlobalContext.Consumer>
      {(context) => (
       <div style={{ padding: "0.5rem" }} className="bg-light">
       <div style={{ borderRadius: "10px" }} className="card  m-0 mt-4 ml-1 mr-1 p-0">
         <div className="card-body m-0 p-2">
           <div>
            <QrReportTable/>
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default QrReport;