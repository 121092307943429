import React from "react";
import { Button } from "react-bootstrap";
const BBPSPrint3 = () => {
  const printData = JSON.parse(localStorage.getItem("print"));
  const printReceipt = () => {
    window.print();
  };
  const NOTE = "";

  return (
    <div
      style={{
        margin: "5vw",
        border: "1px solid #000",
        borderRadius: "5px",
        padding: "1rem",
      }}
    >
      <div>
        <div
          style={{
            margin: "0px 10px",
            padding: "0px 10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assets/images/BBPSSidebarLogo.png")}
            style={{
              width: "auto",
              height: "3.5rem",
            }}
          />
          <img
            src={require("../../assets/images/company/slip_logo.png")}
            style={{
              width: "auto",
              height: "7rem",
            }}
          />
        </div>
        <div>
          {printData && (
            <div className="card" style={{ margin: "10px", padding: "10px" }}>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    Shop Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    {printData.shopName}
                  </td>
                </tr>
                {
                  printData.params && printData.params.length > 0 && (
                    printData.params.map((param) => (
                      <tr style={{ background: "none" }}>
                        <td
                          style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: "50%",
                            fontSize: "1rem",
                          }}
                        >
                         {param.name}
                        </td>
                        <td
                          style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: "50%",
                            fontSize: "1rem",
                          }}
                        >
                          {" "}
                          {param.value}
                        </td>
                      </tr>
                    ))

                  )
                }
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    Consumer Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    {printData.Name}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {"₹ "}
                    {printData.rch_amount}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    Provider
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    {printData.rch_operator_id}
                  </td>
                </tr>


                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    Status
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    {printData.rch_status_desc}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    {printData.refId}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    Recharge date
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "1rem",
                    }}
                  >
                    {" "}
                    {printData.rch_indate}
                  </td>
                </tr>



              </table>
            </div>
          )}
        </div>
      </div>

      <div style={{ display: "flex", fontSize: "1rem", margin: "0px 10px", padding: "0px 10px" }}>
        <strong> {`Visit us at : www.payments.bumppy.com`}</strong>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }} />
        <button
          style={{ marginLeft: "auto" }}
          className="hide-on-print"
          onClick={printReceipt}
        >
          {"Print"}
        </button>
      </div>
    </div>
  );
};

export default BBPSPrint3;
