import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  generateHashKey2,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { Link } from "react-router-dom";
import {
  OverlayTrigger,
  Tooltip,
  ButtonToolbar,
  Popover,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./hover.css";
import { updateUserDetails } from "../../api/admin/AdminApi";
export const columns = [
  {
    dataField: "uName",
    text: "Name",
  },

  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "uCode",
    text: "Code",
  },
  {
    dataField: "logId",
    text: "Mobile",
  },
  {
    dataField: "wallet_bal",
    text: "Wallet Balance",
  },
  {
    dataField: "aeps_bal",
    text: "AEPS Limit",
  },
  {
    dataField: "pg_bal",
    text: "PG Limit",
  },
  {
    dataField: "lien_bal",
    text: "Lien Amount",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "usParent",
    text: "Parent",
  },
];

// hi github copilot

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const AllUserListTable = () => {
  const [data, setData] = useState([]);
  const { user } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [details, setDetails] = useState({});
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [inputState, setInputState] = useState({
    userType: "",
  });
  const { Profileinfo, setTransactionLoaderState } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "", "height=600,width=800'");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getAllUserList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        usertype: inputState.userType,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_all_user_list_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      const dataMapped = data.data.map((a) => ({
        ...a,
        wallet_bal: a.wlBal?.toString().split("|")[0],
        aeps_bal: a.wlBal?.toString().split("|")[1],
        pg_bal: a.wlBal?.toString().split("|")[2],
        lien_bal: a.wlBal?.toString().split("|")[3],
      }));
      console.log(dataMapped);
      setData(dataMapped);
      if (Array.isArray(data.data)) {
        let sum = 0;
        data.data.forEach((a) => {
          sum = sum + Number(a.wlBal?.toString().split("|")[0]);
        });
        console.log(sum);
        setTotalBalance(sum);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const requestTPin = async (mobile) => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(mobile);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        mobileno: mobile,
        reset_type: "TPIN",
        password: mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        swal("Success", "T PIN Requested Successfully", "success");
      } else {
        swal("Failed", "T PIN Requested Failed", "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const requestMPin = async (mobile) => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(mobile);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        mobileno: mobile,
        reset_type: "MPIN",
        password: mobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        swal("Success", "M PIN Requested Successfully", "success");
      } else {
        swal("Failed", "M PIN Requested Failed", "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const openModal = (details) => {
    setUserDetails(details.logId);
    setModalOpen(true);
  };
  useEffect(() => {
    getAllUserList();
  }, []);

  const popoverClick = (
    <Popover id="popover-trigger-click" title="Popover bottom">
      <strong>Holy guacamole!</strong> Check this info.
    </Popover>
  );
  const PopoverComponent = ({ myData }) => {
    return (
      <Popover id="popover-trigger-click" title="Popover bottom">
        <strong>{myData}</strong> Plan Info...
      </Popover>
    );
  };
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };
  const positionStyle = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  return (
    <>
      <div className="row m-0 p-0  pt-1 align-items-center">
        <div className="col-4 m-0 p-0">
          <div
            className="m-0 p-0 pl-2"
            style={{
              fontFamily: "Poppins",
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
            All User List
          </div>
        </div>
        <ChangePassword
          details={userDetails}
          isModalOpen={modalOpen}
          setModalOpen={(modalState) => {
            setModalOpen(modalState);
          }}
          refresh={getAllUserList}
        />

        <UserDetailsUpdate
          details={details}
          isModalOpen={modalOpen3}
          setModalOpen={(modalState) => {
            setModalOpen3(modalState);
          }}
          refresh={getAllUserList}
        />

        <TariffSetModal
          details={details}
          isModalOpen={modalOpen2}
          setModalOpen={(modalState) => {
            setModalOpen2(modalState);
          }}
          refresh={getAllUserList}
        />
        <div className="col-8 m-0 p-0 ">
          <div className="row  m-0 p-0 pr-1 pl-2 justify-content-end">
            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div
              className="pr-1 pl-2 p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <Select
                  className="bg-light"
                  options={userTypes}
                  onChange={(e) => {
                    setInputState({ ...inputState, userType: e.value });
                  }}
                />
              </Form.Group>
            </div>
            <div
              className="pl-1   p-0 m-0"
              style={{ width: "14%", height: "20%" }}
            >
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-50 btn btn-primary btn-lg btn-block "
                  onClick={getAllUserList}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
            <div
              className="m-0 p-0 hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `User_List_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div
                div
                style={{ color: "rgba(255, 255,255, 1)" }}
                className="hover-text bg-dark "
              >
                Export to Excel
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0 pl-2 pb-2">
        <Form.Group className="m-0 p-0">
          <strong className="text-primary">
            Total Balance :{twoDecimalPlaces(totalBalance)}
          </strong>
        </Form.Group>
      </div>

      <hr className="m-0 p-0" />

      <div className=" my-table scroll-inner admin-fix-width transparent-table ">
        <table
          style={{ width: "100%", backgroundColor: "#FFFFFF" }}
          className=" my-table  borderless transparent-table"
        >
          <tr
            className="my-table transparent-table text-center border-bottom "
            style={{ fontFamily: "Poppins", backgroundColor: "#FFFFFF" }}
          >
            <th
              className=" py-3 my-table transparent-table text-center border-bottom p-0 m-0  "
              style={{
                fontSize: "0.9rem",
                backgroundColor: "#FFFFFF",
                color: "#4267B2",
                textalign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>

            <th
              className="my-table transparent-table text-center border-bottom  p-0 m-0 "
              style={{
                fontFamily: "Poppins",
                fontSize: "0.9rem",
                backgroundColor: "#FFFFFF",
                color: "#4267B2",
                textalign: "center",
              }}
            >
              {" "}
              {"Name"}
            </th>
            {/* <th
              className="my-table transparent-table text-center border-bottom  p-0 m-0 "
              style={{
                fontFamily: "Poppins",
                fontSize: "0.9rem",
                backgroundColor: "#FFFFFF",
                color: "#4267B2",
                textalign: "center",
              }}
            >
              {" "}
              {"Load Balance"}
            </th> */}
            <th
              className="my-table transparent-table text-center border-bottom p-0 m-0  "
              style={{
                fontFamily: "Poppins",
                fontSize: "0.9rem",
                backgroundColor: "#FFFFFF",
                color: "#4267B2",
                textalign: "center",
              }}
            >
              {" "}
              {"Balance"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom p-0 m-0  "
              style={{
                fontFamily: "Poppins",
                fontSize: "0.9rem",
                backgroundColor: "#FFFFFF",
                color: "#4267B2",
                textalign: "center",
              }}
            >
              {" "}
              {"Parent"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom p-0 m-0  "
              style={{
                fontFamily: "Poppins",
                fontSize: "0.9rem",
                backgroundColor: "#FFFFFF",
                color: "#4267B2",
                textalign: "center",
              }}
            >
              {" "}
              {"Status"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom p-0 m-0  "
              style={{
                fontFamily: "Poppins",
                fontSize: "0.9rem",
                backgroundColor: "#FFFFFF",
                color: "#4267B2",
                textalign: "center",
              }}
            >
              {" "}
              {"Action"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="my-table transparent-table border-bottom py-5 p-0 m-0 ">
              <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0 ">
                  {ind + 1}
                </div>
              </td>
              <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0 ">
                  <span>{e.uName}</span>
                  <span>{`${e.uCode}- ${e.uCode}`}</span>
                  <span>{`${e.logId}`}</span>
                </div>
              </td>
              <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>
                    {" "}
                    {`Wallet Bal : ${twoDecimalPlaces(e.wallet_bal)}`}
                  </span>
                  <span> {`AEPS Limit : ${twoDecimalPlaces(e.aeps_bal)}`}</span>
                  <span> {`PG Limit : ${twoDecimalPlaces(e.pg_bal)}`}</span>
                  <span> {`Lien Limit : ${twoDecimalPlaces(e.lien_bal)}`}</span>
                </div>
              </td>
              {/* <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0 ">
                  <span>{twoDecimalPlaces(e.wlBal)}</span>
                </div>
              </td> */}
              <td
                className="text-center"
                style={{
                  width: "20rem",
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                <span dangerouslySetInnerHTML={{ __html: e.usParent }}></span>
                <span
                  dangerouslySetInnerHTML={{ __html: e.usParentlvl2 }}
                ></span>
                <span
                  dangerouslySetInnerHTML={{ __html: e.usParentlvl3 }}
                ></span>
              </td>
              <td
                className="text-center"
                style={{
                  width: "20rem",
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                }}
              >
                <div className="d-flex flex-column align-items-center m-0">
                  {e.status == "ACTIVE" ||
                  e.status == "Processing" ||
                  e.status == "InComplete" ||
                  e.status == "Initiated" ? (
                    <span
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                      }}
                      className="p-2"
                    >
                      {`${e.status}`}
                    </span>
                  ) : e.status === "DEACTIVE" ||
                    e.status === "Failed" ||
                    e.status == "Suspected" ? (
                    <span
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                        color: " rgba(255, 0, 0, 1)",
                        bordercolor: " rgba(255, 0, 0, 1)",
                      }}
                      className="p-2"
                    >
                      {`${e.status}`}
                    </span>
                  ) : (
                    <span
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(254, 128, 36, 0.1)",
                        color: "rgba(254, 128, 36, 1)",
                        bordercolor: "rgba(254, 128, 36, 1)",
                      }}
                      className="p-2"
                    >
                      {`${e.status}`}
                    </span>
                  )}
                </div>
              </td>
              <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0 ">
                  <div className="row">
                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                        fontSize: "4px !important",
                      }}
                      className="btn rounded-100  m-0 p-1"
                    >
                      {" "}
                      <div onClick={() => requestTPin(e.logId)}>
                        <div className="hover-container">
                          <img
                            src={require("../../../assets/images/Reset T-PIN.png")}
                            style={{ width: "" }}
                          />

                          {/* <div className="hover-text">Reset T-PIN</div> */}
                          <div
                            style={positionStyle1}
                            className="hover-text bg-dark "
                          >
                            Reset T-PIN
                          </div>
                        </div>
                      </div>
                      {/* Rehit */}
                    </Button>

                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                    >
                      {" "}
                      <div
                        onClick={() => requestMPin(e.logId)}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Reset M-PIN.png")}
                          style={{ width: "" }}
                        />
                        {/* <div style={{"fontFamily":"Poppins",  color:'rgba(248, 204, 44, 1)', }} className="hover-text">Reset M-PIN</div> */}
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Reset M-PIN
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                    >
                      {" "}
                      <div className="hover-container">
                        <a href={`/admin/user_access_manage?id=${e.uCode}`}>
                          <img
                            src={require("../../../assets/images/Update Access.png")}
                            alt="Clickable Image"
                          />
                        </a>
                        {/* <div div style={{ "fontFamily":"Poppins", color:'rgba(135, 135, 240, 1)', }} className="hover-text">Update Access</div> */}
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Update Access
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      onClick={() => {
                        setDetails(e);
                        setModalOpen3(true);
                      }}
                    >
                      {" "}
                      <div className="hover-container">
                        <img
                          src={require("../../../assets/images/Edit Details.png")}
                          alt="Clickable Image"
                        />
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Edit Details
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      onClick={() => {
                        setDetails(e);
                        setModalOpen2(true);
                      }}
                    >
                      {" "}
                      <div className="hover-container">
                        <img
                          src={require("../../../assets/images/Update Access.png")}
                          alt="Clickable Image"
                        />
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Update Tariff
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      onClick={() => {
                        openInNewTab(
                          `/marcom/marcom-doc-print?userid=${e.uCode}&pk_user_id=${e.id}`
                        );
                      }}
                    >
                      {" "}
                      <div className="hover-container">
                        <div>
                          <img
                            src={require("../../../assets/images/printer.png")}
                            alt="Clickable Image"
                          />
                        </div>
                        {/* <div div style={{ "fontFamily":"Poppins", color:'rgba(135, 135, 240, 1)', }} className="hover-text">Update Access</div> */}
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Dowload Marcom
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const ChangePassword = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const [state, setState] = useState({
    mobile: "",
  });
  const [inputState, setInputState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    mobile: "",
  });

  const changePassword = async () => {
    if (inputState.newPassword !== inputState.confirmPassword) {
      swal("Invalid", "New Password and Confirm Password should same", "error");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: state.mobile,
      reset_type: "PASSWORD",
      password: inputState.newPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          swal("Success", "Password Changed Successfully", "success");
        } else {
          swal("Failed", "Password Changed Failed", "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
        swal("Failed", "Password Changed Failed Error From Server", "error");
      });
    setInputState({ ...inputState, currentPassword: "" });
  };

  useEffect(() => {
    if (details) {
      setState({
        ...state,
        mobile: details,
      });
    }
  }, [details]);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="md"
    >
      <Modal.Body style={{ width: "100%", display: "block" }}>
        <div
          style={{
            padding: "12px",
            background: "#3676D8",
            color: "#FFF",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong> Change Password</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div>
          <Form.Control
            placeholder="new password"
            type="password"
            className="m-1 rounded-0 form-control form-control-sm"
            onChange={(e) =>
              setInputState({
                ...inputState,
                newPassword: e.target.value,
              })
            }
          />
          <Form.Control
            placeholder="confirm password"
            type="password"
            className="m-1 rounded-0 form-control form-control-sm"
            onChange={(e) =>
              setInputState({
                ...inputState,
                confirmPassword: e.target.value,
              })
            }
          />
          <Button
            variant="success"
            className="m-1 rounded-0 btn-block"
            onClick={changePassword}
          >
            Submit New Password
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const TariffSetModal = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const [state, setState] = useState({
    tariff: "",
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const setTariffPlans = async () => {
    console.log(state.tariff);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userDetails.uCode,
        adminId: user.pkid,
        tariffId: state.tariff.value,
        service: "PG",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/setTariffPlanAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", "Tariff Plan Set Successfully", "success");
        setState({
          tariff: "",
        });
        refresh();
        setModalOpen(false);
      } else {
        swal("Failed", "Tariff Plan Set Failed", "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const getAllCreditRequests = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.pkid,
        service: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllTariffPlansAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(
          data.data
            .filter((e) => {
              return e.service_type == "PG";
            })
            .map((user) => {
              return {
                value: user.fk_tarrif_id,
                label: `${user.tariff_alias}`,
              };
            })
        );
      } else {
        setData([]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (details) {
      setUserDetails(details);
      getAllCreditRequests();
    }
  }, [details]);

  console.log(state);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="md"
    >
      <Modal.Body style={{ width: "100%", display: "block" }}>
        <div
          style={{
            padding: "12px",
            background: "#3676D8",
            color: "#FFF",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong> Change Plan</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div>
          <Form.Group className="p-0 m-0">
            <Select
              className="bg-light"
              options={data}
              onChange={(e) => {
                setState({ ...state, tariff: e });
              }}
              value={state.tariff}
            />
          </Form.Group>
          <Button
            variant="success"
            className="m-1 rounded-0 btn-block"
            onClick={setTariffPlans}
          >
            Set Tariff Plan
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const UserDetailsUpdate = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const changes = [
    { label: "YES_BANK_BC_CODE_UPDATE", value: "YES_BANK_BC_CODE_UPDATE" },
    { label: "AGENT_DEACTIVATED", value: "AGENT_DEACTIVATED" },
    { label: "AGENT_ACIVATE", value: "AGENT_ACIVATE" },
    { label: "PG_LIMIT_UPDATE", value: "PG_LIMIT_UPDATE" },
    { label: "AEPS_LIMIT_UPDATE", value: "AEPS_LIMIT_UPDATE" },
    { label: "CREDIT_CARD_KYC_REMOVE", value: "CREDIT_CARD_KYC_REMOVE" },
  ];
  const [state, setState] = useState({
    action: "",
    p1: "",
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const updateAgentDetails = async (event, p1, p2, p3, p4) => {
    setTransactionLoaderState(true);
    try {
      const request = {
        userid: userDetails.uCode,
        adminId: user.pkid,
        action: event,
        p1: p1,
        p2: p2,
        p3: p3,
        p4: p4,
      };

      const res = await updateUserDetails(request);
      if (res.responseCode == "00") {
        setTransactionLoaderState(false);
        swal("Success", res.responseMessage, "success");
      } else {
        setTransactionLoaderState(false);
        swal("Failed", res.responseMessage, "error");
      }
    } catch (error) {
      swal("Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (details) {
      setUserDetails(details);
    }
  }, [details]);

  console.log(state);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="md"
    >
      <Modal.Body style={{ width: "100%", display: "block" }}>
        <div
          style={{
            padding: "12px",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong> User Details Update </strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div>
          <Form.Group className="p-0 m-0">
            <Select
              className="bg-light"
              options={changes}
              onChange={(e) => {
                setState({ ...state, action: e });
              }}
              value={state.tariff}
            />
          </Form.Group>
          <Form.Label>
            <span style={{ color: "red" }}>*</span>
            {` Enter Details`}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={`Enter ${
              state.action.value == "YES_BANK_BC_CODE_UPDATE"
                ? "Agent Code"
                : state.action.value == "AGENT_DEACTIVATED"
                ? "Optional"
                : state.action.value == "AGENT_ACIVATE"
                ? "Optional"
                : state.action.value == "PG_LIMIT_UPDATE"
                ? "PG Limit"
                : state.action.value == "AEPS_LIMIT_UPDATE"
                ? "AEPS Limit"
                : ""
            }`}
            name="otp"
            className="rounded-0"
            value={state.p1}
            onChange={(e) => {
              setState({ ...state, p1: e.target.value });
            }}
          />
          <Button
            variant="success"
            className="m-1 rounded-0 btn-block"
            onClick={() => {
              updateAgentDetails(
                state.action.value,
                state.p1,
                state.p2,
                state.p3,
                state.p4
              );
            }}
          >
            Apply Changes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AllUserListTable;
