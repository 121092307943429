import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import CryptoJS from "crypto-js";
import { createHash } from "../../utils/utilFuctions";

const CMS = () => {
  const [token, setToken] = useState("");
  const [data, setData] = useState("");
  const [data2, setData2] = useState("");
  const { user } = useContext(GlobalContext);

  const validate = (event) => {
    event.preventDefault();
    let errors = {};
    if (Object.keys(errors).length === 0) {
      const form = event.target;
      openInNewTab(
        `https://fpcorp.tapits.in/UberCMSBC/#/login?data=${data}&skey=${data2}`
      );
    }
    return false;
  };

  const openInNewTab = (data) => {
    const newWindow = window.open(data, "", "height=800,width=1000'");
    if (newWindow) newWindow.opener = null;
  };

  const getUrl = () => {
    const hash = createHash(user.ReferId);
    let loginrequestdata = {
      additionalParams: null,
      latitude: 13.0641367,
      longitude: 80.2480973,
      loginType: "2",
      supermerchantId: 1179,
      merchantId: user.ReferId,
      merchantPin: user.ReferId,
      mobileNumber: "9168805979",
      amount: "100",
      superMerchantSkey:
        "9db3f8c9dbe3fa2f0986ad8a17541d6671e2fba0a406753a1909d91164d3d5f4",
    };

    console.log(loginrequestdata);
    let parsedBase64Key = CryptoJS.enc.Base64.parse(
      loginrequestdata.superMerchantSkey
    );

    console.log(parsedBase64Key);
    const encryptInfo = window.btoa(
      CryptoJS.AES.encrypt(JSON.stringify(loginrequestdata), parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
    );
    setData(encryptInfo);
    setData2(loginrequestdata.superMerchantSkey);
  };

  useEffect(() => {
    getUrl();
    // https://fpcorp.tapits.in/UberCMSBC/#/login?
    // data=ekxIOC96VkI0WU8xNkhSVkFiME0xK1VWeWJzYmJaNFhLcFU5Y1hXODRrNElDazN6RnVGekI1RVV3YlVHSXZ4NW85WDBZNGk1S0FaT1ZDd0M5T0o0Zmt3UzZvQm16VDNIY0pCY0pkVlF6UFhmbXRpNERQVnhlVkVRZjV5Ny9HajFuSmJSR3JOV1ZhbE9GQXNyVmcreXdIdGFMWFhmbFNaWkM5dUFhSVJabFNnQ2tjaENWTnFVS09yTXhjejR2Y1BnMGRmQUE3WWFSWmRaOFFnOEl6ZmdBMEhKVEp6MHNyNzNNdlc4ZVgvOTFyaGgvVWU3SHBxOG5OeGdDNnF5SWV2K1ZoTmFVbkFtYXNnd2NOWjY1RWpQcEw5bW4yUDFMTm9OTUdLeHRpUVM0dUR0RWh4b0czQ29zVVJQQUVPTTN2Tms5M3FoWXptcnQ3RHg0Wnk5clR1OEhEcW9jb0VkVHc4K2owSnBaakpTL3MzYlAwK2ltNHR1dlNQN2MvTFBQZGNS
    // &skey=f5d6f77fc73d75b7b77dad9fd3df3a69df1ad7be78d5debaef57b67db6b46b8d3aef9ddad7dd3d77dd75eb87777797f8

    // https://fpcorp.tapits.in/UberCMSBC/#/login?
    // data=ekxIOC96VkI0WU8xNkhSVkFiME0xK1VWeWJzYmJaNFhLcFU5Y1hXODRrNElDazN6RnVGekI1RVV3YlVHSXZ4NW85WDBZNGk1S0FaT1ZDd0M5T0o0Zmt3UzZvQm16VDNIY0pCY0pkVlF6UFhmbXRpNERQVnhlVkVRZjV5Ny9HajFuSmJSR3JOV1ZhbE9GQXNyVmcreXdIdGFMWFhmbFNaWkM5dUFhSVJabFNnQ2tjaENWTnFVS09yTXhjejR2Y1BnMGRmQUE3WWFSWmRaOFFnOEl6ZmdBMEhKVEp6MHNyNzNNdlc4ZVgvOTFyaGgvVWU3SHBxOG5OeGdDNnF5SWV2K1ZoTmFVbkFtYXNnd2NOWjY1RWpQcEw5bW4yUDFMTm9OTUdLeHRpUVM0dUR0RWh4b0czQ29zVVJQQUVPTTN2Tms5M3FoWXptcnQ3RHg0Wnk5clR1OEhEcW9jb0VkVHc4K2owSnBaakpTL3MzYlAwK2ltNHR1dlNQN2MvTFBQZGNS
    // &skey=9db3f8c9dbe3fa2f0986ad8a17541d6671e2fba0a406753a1909d91164d3d5f4
  }, []);
  console.log(token);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-4">
        <div
          style={{
            width: "100%",
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-7 p-md-5 p-sm-4">
              {" "}
              <h4 className="d-flex align-items-center">CMS Service</h4>
              <p
                className="d-flex align-items-center"
                style={{ color: "black", fontSize: "1rem" }}
              >
                Cash Management Services (CMS) Airtel Bank's Cash Management
                Services offers a full range of products and services to
                efficiently process your receivables and payables. We cater to
                all your cash management requirements to optimise your cash flow
                position and to facilitate effective management of your business
                operation.
              </p>
              <form
                //    action={`https://fpcorp.tapits.in/UberCMSBC/#/login?data=${data}&skey=${data2}`}
                method="Post"
                onSubmit={validate}
              >
                <input type="hidden" name="authentication" value={token} />{" "}
                <button
                  className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                  type="submit"
                  style={{
                    borderRadius: ".2rem",
                    fontWeight: "bold",
                    width: "10rem",
                    border: "none",
                    color: "#ffff",
                    fontSize: "1rem",
                    height: "2em",
                    display: "flex",
                  }}
                >
                  {" "}
                  Proceed for CMS
                </button>
              </form>
            </div>
            <div className="col-5 d-flex justify-content-center align-items-center">
              <img
                alt=""
                style={{ height: "20rem" }}
                src={require("../../../assets/images/epan.png")}
              />
            </div>
          </div>
          <div className="card m-md-4 m-sm-3 p-md-3 p-sm-2">
            <h5>Information</h5>
            <p>
              Cash Management Services (CMS) Airtel Bank's Cash Management
              Services offers a full range of products and services to
              efficiently process your receivables and payables. We cater to all
              your cash management requirements to optimise your cash flow
              position and to facilitate effective management of your business
              operation. Benefits of Cash Management Services Leverage of vast
              network of locations within India Wide range of CMS products
              Efficient logistics support and advanced technology support
              Ensuring timely deposit of collections and timely execution of
              disbursement requests
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CMS;
