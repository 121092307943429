import React, { useState, useEffect } from "react";
import CustomerTable from "./CustomerTable";

const Customers = () => {


  useEffect(() => {
    // getCustomers();
  }, []);

  return <CustomerTable />;
};

export default Customers;
