import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "User1",
    text: "Admin ID",
  },

  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "Ttype",
    text: "Type",
  },
  {
    dataField: "BeforeBal",
    text: "Opening Bal",
  },
  {
    dataField: "Bal",
    text: "Amount",
  },
  {
    dataField: "AfterBal",
    text: "Closing Bal",
  },
  {
    dataField: "Type",
    text: "Trans Type",
  },
  {
    dataField: "Tid",
    text: "Tid",
  },
  {
    dataField: "pay_user_comment",
    text: "Remark",
  },
  {
    dataField: "Date",
    text: "Date",
  },
];

const userTypes = [
  { label: "Debit", value: "2" },
  { label: "Credit", value: "1" },
];

const AccountLedgerTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user ,  setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [inputState, setInputState] = useState({
    userType: { label: "Debit", value: "1" },
  });
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getAccountLedger = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        paytype: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_account_ledger_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAccountLedger();
  }, []);




  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height:'20%',
   
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };


  return (
    <>
              <div className="row m-0 p-0  pt-1 align-items-center">
              <div className="col-4 m-0 p-0">

               <div className="m-0 p-0 pl-2"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                  }}
                >
                  Account Ledger
                </div>
                </div>

<div className="col-8 m-0 p-0 ">
<div className="row  m-0 p-0  justify-content-end">
        
                <div style={searchBarStyle} className="bg-light m-0 p-0 px-1">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2 "/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
       
        <div className=" pl-2 pr-1 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}} >
          <Form.Group className="p-0 m-0">
            
            <Select
              options={userTypes}
              onChange={(e) => {
                setInputState({ ...inputState, userType: e });
              }}
            />
          </Form.Group>
        </div>
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
            <Button
              className="rounded-50 btn btn-primary btn-lg btn-block "
              
              onClick={getAccountLedger}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
        <div className="m-0 p-0 hover-container "
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `Account_Ledger_Report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
              <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
             <div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
      </div>




</div>

      </div>

      <hr className="m-0 p-0"/>
     

      <div className=" my-table scroll-inner admin-fix-width transparent-table">
        <table style={{  width: "100%" ,backgroundColor: '#FFFFFF'}} className="my-table  borderless transparent-table">
          <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
            <th  className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}> {"Sr"}</th>

            <th  className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}} >
              {" "}
              {"Admin ID"}
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}} >
              {" "}
              {"Type"}
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}} >
              {" "}
             
              <div>
              {"Opening"}
              </div>
              <div>
              {"Balance"}
              </div>
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{ "fontFamily":"Poppins",backgroundColor: '#FFFFFF',}} >
              {" "}
              {"Amount"}
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}} >
              {" "}
             
              <div>
              {"Closing"}
              </div>
              <div>
              {" Balance"}
              </div>
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{ "fontFamily":"Poppins",backgroundColor: '#FFFFFF',}} >
              {" "}
             
              <div>
              {"Transaction"}
              </div>
              <div>
              {"Type"}
              </div>
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{ "fontFamily":"Poppins",backgroundColor: '#FFFFFF',}} >
              {" "}
              {"Tid"}
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}} >
              {" "}
              {"Remark"}
            </th>
            <th  className="my-table transparent-table text-center border-bottom"style={{ "fontFamily":"Poppins",backgroundColor: '#FFFFFF',}} >
              {" "}
              {"Date"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="my-table transparent-table border-bottom py-5">
              <td  style={{ "fontFamily":"Poppins", fontSize: "0.8rem" }}> 
              {" "}
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
              {ind + 1}
              </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span >{`${e.User1}`}</span>
                </div>
              </td>
              <td style={{ "fontFamily":"Poppins",fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span>{e.Ttype}</span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span >
                    {twoDecimalPlaces(e.BeforeBal)}
                  </span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span >
                    {twoDecimalPlaces(e.Bal)}
                  </span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span >
                    {twoDecimalPlaces(e.AfterBal)}
                  </span>
                </div>
              </td>
              {/* <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span>{`${e.User2}`}</span>
                </div>
              </td> */}
              <td style={{ "fontFamily":"Poppins",fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span >{`${e.Type}`}</span>
                </div>
              </td>
              <td style={{ "fontFamily":"Poppins",fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span>{`${e.Tid}`}</span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" ,width:"100px"}}>
                <div style={{ width: '300px', }} className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span >{`${e.pay_user_comment}`}</span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div  className="d-flex flex-column align-items-center py-3 p-0 m-0">
                  <span >{`${e.Date}`}</span>
                </div>
              </td>
            
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default AccountLedgerTable;
