import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Col, Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ContextMenuTrigger } from "react-contextmenu";
import {
    BASE_URL_AEPS,
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT,
    BASE_URL_PAYNOW,
} from "../../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import { PRINT_BASE_URL, basicAuth } from "../../config";
import {
    generateHashKey2,
    onlyAlpha,
    onlyNumeric,
    validateIFSCCode2,
} from "../../utils/utilFuctions";
import { validateTPin } from "../../utils/Validations";

const PGClaimModal = ({ mdShow, setMdShow, aadhaar, orderDetails, callback }) => {
    const style = {
        backgroundColor: "#002970",
        color: "white",
    };
    const { username, password } = basicAuth;

    const [Bal, setBal] = useState("");
    const [inputState, setInputState] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", otpSent: false, otp: "", aadhaar_request_id: "", aadhaarVerified: false, custPan: "", aadhaarDetails: "" });
    const [formErrors, setFormErrors] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", });

    const [isCardSubmit, setIsCardSubmit] = useState(false);

    const {
        user,
        refreshWallet,
        wallet,
        setTransactionLoaderState,
        Profileinfo,
    } = useContext(GlobalContext);

    const openInNewTab = () => {
        const newWindow = window.open(
            `${PRINT_BASE_URL}/pages/concern_form`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputState({ ...inputState, [name]: value });
    };

    // Final Settlement End

    // Add Bank Account Start

    const InitialAddBankValues = {
        Mobile: "",
        AccHolderName: "",
        BankAcNumber: "",
        SelectBank: "",
        IFSCcode: "",
    };


    const claimTransaction2 = async () => {
        try {
            let orderId = orderDetails.refId;
            if (!orderId) {
                orderId = orderDetails.pk_order_id
            }
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({

                "userid": orderDetails.userid,
                "orderid": orderId,
                "status": "Y",
                "custName": orderDetails.cust_name,
                "cardNo": orderDetails.card_no,
                "aadhaarNo": aadhaar

            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch(`${BASE_URL_APISERVICES}/api/PG/transactionClaim`, requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                swal("Success", result.msg, 'success');

            } else {
                swal("Failed", result.msg, 'error');
            }
            callback();
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };

    const claimTransaction = async (verificationResponse) => {
        try {
            let orderId = orderDetails.refId;
            if (!orderId) {
                orderId = orderDetails.pk_order_id
            }
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({

                "userid": orderDetails.userid,
                "orderid": orderId,
                "status": "Y",
                "custName": orderDetails.cust_name,
                "cardNo": orderDetails.card_no,
                "aadhaarNo": aadhaar,
                "verificationResponse": verificationResponse

            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch(`https://bumppy.in/api_integration/agent_concern/v1/claimPGTransaction2.php`, requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                swal("Success", result.msg, 'success');

            } else {
                swal("Failed", result.msg, 'error');
            }
            callback();
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };

    const aadhaarVerificationSendOtp = async () => {

        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Cookie",
                ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0s7bi6I2oq4bBIN05pcn-22zDbEC2K0B-nviBgFvqOVktA2C3i9oiZosmjfVMRHl6yl4r7gQLI2ZVmyyizy0WMvnTrhuMiYZcm4mq1dFs3gDB12EfgpSmQlELsaMjdVtHd6afBouTY7NsGoGe-5C3qq8ltT0zaCLnxVWjHIaJqSwNpWvun0YiFGI5tQ4hUFCPE6ilxS4u2myz3VyS2eSrGtFcvaPilfGyORm_J7W558pqhOshhR8JXR-3q8miWrt1Ji5twUizGXy0CfYM8UbEY3gPvXkyqHttYp-8X3WxmtrickDNedeBLMSs9xK80SG1HgErLU1plIlnUnmfeuUnNfLOejtKfnSkIQrMnMvKvvz-LrgPPRcf5FQntJNVXzZmZayQ8dHefiQhcfhNBwVc52CfLIzVCRcv0gICybhfOg-mQitlKMh-dXkMlhB7SUfBJX5SAQMcAWfS8DM-LvQjN6NKbXnsdy4vo5Qn0eEESnDlyX_lSEzb2lBdDlGvmFY-J9wrCk_49rT7lq-aweV8CFa6qrEBUO3t6uNY9i4s4s_7uICtutJvtiMJOi24bxNTWB22eVhsuroLrVtIswVKXMA8lRiECbsjG8lcKUk0_LU1FQz7soVehwvZa3j0y8xZ2NJPzMdl04OMGCOCWl-Up"
            );

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Verify/aadharotpsend`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100" && data.result) {

                    setInputState({
                        ...inputState, otpSent: data.result.is_otp_sent,
                        aadhaar_request_id: data.request_id,
                    });
                } else {
                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    // let username = "Rajneesh@example.com", password = "Test@12345";

    const aadhaarVerification = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                request_id: inputState.aadhaar_request_id,
                otp: inputState.otp,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Verify/aadharotpverify`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, aadhaarVerified: true });
                    claimTransaction(data.result);
                } else {
                    let err = data.response_message;
                    if(!err){
                        err = "Error in Aadhaar Verification";
                    }else{
                        err = err + " while verifying Aadhaar OTP"
                    }
                    swal("Failed", err, 'error');
                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };


    useEffect(() => {
        setInputState({ ...inputState, aadhaarNo: aadhaar })
    }, [aadhaar]);

    return (

        <Modal
            show={mdShow}
            onHide={() => setMdShow(false)}
            aria-labelledby="example-modal-sizes-title-md"
        >
            <Modal.Header closeButton>
                <Modal.Title> Claim Transaction </Modal.Title>
            </Modal.Header>

            <Modal.Body
                style={{ width: "100%", display: "block", overflowY: "auto" }}
                className="account"
            >
                <div
                    className="row w-100"
                    style={{
                        //  background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
                        borderRadius: "1rem",
                    }}
                >

                    <div className="row mx-1" >
                        *The One-Time Password (OTP) will be verified for the E-Sign process.
                    </div>

                    <table>
                        <tr>
                            <td>
                                Transaction Id
                            </td>
                            <td>
                                {orderDetails?.refId}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Transaction Amount
                            </td>
                            <td>
                                {orderDetails?.amount}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Customer Name
                            </td>
                            <td>
                                {orderDetails?.cust_name}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Customer Aadhaar
                            </td>
                            <td>
                                {orderDetails?.cust_aadhaar}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Customer Pan
                            </td>
                            <td>
                                {orderDetails?.cust_pan}
                            </td>
                        </tr>


                    </table>



                    <div className="row text-danger mx-1" >
                        Rs. 3 will be deducted from Wallet for E Signature
                    </div>

                    {!isCardSubmit && (
                        <div className="row w-100">

                            {!inputState.otpSent && (<Button onClick={aadhaarVerificationSendOtp} variant="success" className="btn-sm float-right rounded-0 m-2">
                                Send OTP
                            </Button>)}
                            {(inputState.otpSent && !inputState.aadhaarVerified) && (
                                <Form.Group className="col-12 py-0 px-1'">

                                    <Form.Label>
                                        <span style={{ color: "red" }}>*</span>
                                        {` Enter OTP`}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter OTP"
                                        name="otp"
                                        className="rounded-0"
                                        value={inputState.otp}
                                        onChange={handleChange}
                                    />
                                    <p style={{ color: "red" }}>{formErrors.otp}</p>

                                    <Button onClick={aadhaarVerification} variant="success" className="btn-sm float-right rounded-0 m-2">
                                        Validate
                                    </Button>
                                </Form.Group>
                            )}


                        </div>
                    )}


                </div>
            </Modal.Body>
        </Modal>


    );
};

export default PGClaimModal;
