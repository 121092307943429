import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { useHistory } from "react-router";
import { basicAuth } from "../../config";


const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const CreateTariff = () => {

  const initialState = {
    name: "",
    code: "",
    service: "",
  };

  const [state, setState] = useState(initialState);
  const [userRoles, setUserRoles] = useState([]);
  const [parentList, setParentList] = useState([]);
 
  const { user ,  setTransactionLoaderState } = useContext(GlobalContext);

  const validateForm = () => {
    if (state.name == "" || state.code == "" || state.service == "") {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    createTariff();
  };

  const clearForm = () => {
    setState(initialState);
  };

  const createTariff = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        tariff_name: state.name,
        tariff_code: state.code,
        service: state.service.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/create_tariff.aspx`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("Tariff Creation Successful", data.msg, "success");
        clearForm();
      } else {
        swal("Tariff Creation Failed", data.msg, "error");
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
      swal("Tariff Creation Failed", "Error From Server", "error");
    }
  };

  const getUserList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_services_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserRoles(
          data.data.map((role) => {
            return {
              label: `${role.st_name}`,
              value: role.pk_service_id,
            };
          })
        );
      }  setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div style={{ padding: "1rem" }} className="bg-light">
    <div style={{ borderRadius: "8px" }} className="card m-0 p-0 mt-4 mr-2 ml-2">
      <div className="card-body m-0 p-1 px-2">
          <div>

          <div className="p-2 pl-1"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",

                   

                  }}
                >
                Create Tariff
                </div>


             
            

            <div>
             
              <div className="row col-12 justify-content-start m-0 pl-10 py-0 pb-1">
                <div className="col-5 pr-3 m-0 p-0 ">
                  <Form.Group>
                    <Form.Label className="text-dark">
                      <span style={{ color: "red" }}>*</span>
                      {`Tariff Name`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control rounded-lg"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Tariff Name"
                      value={state.name}
                      onChange={(e) => {
                        setState({ ...state, name: e.target.value });
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>

                <div className="col-5 p-0 m-0 pr-3">
                  <Form.Group>
                    <Form.Label className="text-dark">
                      <span style={{ color: "red" }}>*</span>
                      {`Tariff Code`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control rounded-lg"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Tariff Code"
                      value={state.code}
                      onChange={(e) => {
                        setState({ ...state, code: e.target.value });
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>
                
              </div>

              <div className="row col-12 pl-10 py-0 m-0 pb-3">
              
                <div className="col-5 m-0 p-0 pr-3 ">
                                
                  <Form.Group >
                    <Form.Label className="text-dark">
                      <span style={{ color: "red" }}>*</span>
                      {`Select Service`}
                    </Form.Label>
                    <Select
                    className=" rounded-lg"
                      options={userRoles}
                      value={state.service}
                      onChange={(e) => {
                        setState({ ...state, service: e });
                      }}
                    />
                  </Form.Group>
                </div>

                  <div className="col-2 p-0 mt-4 pr-3 ">
                    <Button
                      className="rounded-10 btn-block  "
                      variant="primary"
                      onClick={validateForm}
                    >
                      {" "}
                      Submit
                    </Button>
                  </div>
                  <div className="col-2 p-0 m-0  mt-4 pr-3">
                    <Button
                      className="rounded-10 btn-block border border-primary"
                      variant="white"
                      onClick={clearForm}
                    >
                      {" "}
                      Clear
                    </Button>
                  </div>


                 

                  <div className="col p-0"></div>
                  <div className="col p-0"></div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateTariff;
