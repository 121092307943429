import React, { useState } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import RechargeReportTable from "./RechargeReportTable";

const RechargeReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "1" ? (
          <Redirect to="/" />
        ) : (
          <div style={{backgroundColor:"#F1F2F4" , height:"100%" , width:"100%" }}>
            <div
                style={{
                display: "flex",
               padding: "10px",
                justifyContent: "space-between",
              }}>
              <h4 className="ml-2">
              <strong>Recharge History</strong>
              </h4>
           
            </div>
            <RechargeReportTable />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default RechargeReport;
