import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { BASE_URL_APISERVICES, } from "../../../utils/Constants";
import { GlobalContext } from "../../../Context/GlobalState";
import swal from "sweetalert";


const PgCommercials = () => {
    const [data, setData] = useState([]);
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [editableCell, setEditableCell] = useState(null);
    const [state, setState] = useState({
        tariff_name: "",
        charge: "",
        payout_charges: "",
        gst: "",
        tf_ret_comm: "",
        tf_dist_comm: "",
        tf_cnf_comm: "",
        route_name: "",
    });

    const [updateState, setUpdateState] = useState([])

    const getAllPGCommercials = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.pkid,
                service: "PG"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Admin/getAllPGCommercials`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                //setData(data.data);
                setDataToDisplay(data.tariffs)
            } else {
                setData([]);
            }

            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAllPGCommercials();
    }, []);


    const updateCommercials = async () => {
        try {
            setTransactionLoaderState(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            console.log("updateState", updateState);

            return

            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const raw = JSON.stringify({
                adminId: user.pkid,
                commercials: updateState
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Admin/updatePGCommercials`,
                requestOptions
            );

            const data = await res.json();
            if (data.responseCode == "00") {
                swal("Tariff Updated Successful", data.responseMessage, "success");
            } else {
                swal("Tariff Updated Failed", data.responseMessage, "error");
            }
            setTransactionLoaderState(false)
        } catch (error) {
            setTransactionLoaderState(false)
            console.log("error", error);
            swal("Tariff Updation Failed", "Error From Server", "error");
        }
    };


    console.log(dataToDisplay);



    return (
        <div style={{ padding: "1rem" }} className="bg-light">
            <div style={{ borderRadius: "8px" }} className="card m-0 p-0 mt-4 mr-2 ml-2">
                <div className="card-body m-0 p-1 px-2">
                    <div>

                        <div className="p-2 pl-1"
                            style={{
                                "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                , padding: "0px",
                                background: "#FFFFFF",
                                color: "#4267B2",
                                fontWeight: "bolder",
                                fontfamily: "Poppins !impotent",
                                fontSize: "28px",
                            }}
                        >
                            PG Charges Configuration
                        </div>
                        <div>
                            <div className="scroll-inner admin-fix-width">
                                {dataToDisplay?.map((e, ind) => (
                                    e?.tariff_name && (
                                        <div className="card my-1">
                                            <h3
                                                className="m-2 p-2"
                                                style={{
                                                    background: "#FFFFFF",
                                                    color: "#4267B2",
                                                }}
                                            >{e.tariff_desc}</h3>
                                            <table
                                                className="my-table transparent-table text-center border-bottom " >
                                                <tr className="borderless-table text-center border-bottom">
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Tariff Name"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"From Amount"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"To Amount"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"PG Charges"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Payout Charges"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"GST"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Retailer Comm"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Distributor Comm"}</th>
                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Master Distributor Comm"}</th>

                                                    <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Action"}</th>
                                                </tr>

                                                {e?.tariffs?.map((e2) => (
                                                    <tr className="border-bottom">
                                                        <td className="text-center "

                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}
                                                        >
                                                            {ind + 1}
                                                        </td>
                                                        <td className="text-center "

                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>  <div>{e2.settlement}</div>

                                                        </td>
                                                        <td className="text-center "
                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.amountSlabs.from}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "from");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "from", fieldValue: e.target.value, tariffId: e2.id }]);

                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                        </td>
                                                        <td className="text-center "
                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.amountSlabs.to}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "to");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "to", fieldValue: e.target.value, tariffId: e2.id }]);
                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                        </td>
                                                        <td className="text-center "

                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.charges.charge}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "charge");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "charge", fieldValue: e.target.value, tariffId: e2.id }]);
                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                        </td>
                                                        <td className="text-center "
                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.charges.payout_charges}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "payout_charges");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "payout_charges", fieldValue: e.target.value, tariffId: e2.id }]);
                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />

                                                        </td>
                                                        <td className="text-center "

                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.charges.gst}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "gst");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "gst", fieldValue: e.target.value, tariffId: e2.id }]);
                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                        </td>
                                                        <td className="text-center "

                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.commissions.tf_ret_comm}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "tf_ret_comm");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "tf_ret_comm", fieldValue: e.target.value, tariffId: e2.id }]);
                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                        </td>
                                                        <td className="text-center "

                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.commissions.tf_dist_comm}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "tf_dist_comm");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "tf_dist_comm", fieldValue: e.target.value, tariffId: e2.id }]);
                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                        </td>
                                                        <td className="text-center "

                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control rounded-lg"
                                                                aria-label="Notes"
                                                                aria-describedby="basic-addon1"
                                                                placeholder="Enter Tariff Code"
                                                                value={e2.commissions.tf_cnf_comm}
                                                                onChange={(e) => {
                                                                    let inputWithoutField = updateState.filter((e) => e.fieldName !== "tf_cnf_comm");
                                                                    setUpdateState([...inputWithoutField, { fieldName: "tf_cnf_comm", fieldValue: e.target.value, tariffId: e2.id }]);
                                                                }}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                        </td>

                                                        <td className="text-center "
                                                            style={{
                                                                "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", marginLeft: "3px",
                                                                width: "20px", paddingTop: " 34px"
                                                            }}>
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={updateCommercials}
                                                            >
                                                                Update
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    )
                                ))}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PgCommercials;