import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import DashSidebar from "./DashSidebar";
import { writeFile, utils } from "xlsx";
import { Redirect, useHistory } from "react-router";
import { BASE_URL_AGENT, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import { Link } from "react-router-dom";
import { offsetArr } from "../../utils/CommonConfig";
import swal from "sweetalert";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { Dropdown, Card } from "react-bootstrap";

import cogoToast from "cogo-toast";

import { validateTPin } from "../../utils/Validations";
import { failedPopup, successPopup } from "../../utils/Models";
import "./hover.css";
export const Dashboard = () => {
  const { user } = useContext(GlobalContext);
  const history = useHistory();

  const InitialValues = { UserName: "" };
  const [assignTariffModal, setAssignTariffModal] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [Shop, setShop] = useState([]);
  const [DistView, setDistView] = useState([]);
  const [dataToSend, setDataToSend] = useState({});
  const [ShopObj, setShopObj] = useState([]);
  const [DistributorID, setDistributorID] = useState("");
  const [Mobile, setMobile] = useState("");
  const [details, setDetails] = useState({});
  const [selectedOptions, setSelectedOptions] = useState();
  const [sidebarState, setSidebarState] = useState({});
  const [Provider, setProvider] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [selectedOptionsList, setselectedOptionsList] = useState();
  const [RetailerID, setRetailerID] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [SendotpAdd, setSendotpAdd] = useState(true);
  const [SendotpRev, setSendotpRev] = useState(true);
  const { Profileinfo } = useContext(GlobalContext);

  const [selectedData, setSelectedData] = useState({
    id: "",
    shop: "",
    oldBalance: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      GetDistributorView();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.UserName) {
      errors.UserName = "*Shop name is required!";
    }
    return errors;
  };

  const Reset = () => {
    setFormValues(InitialValues);
    setFormErrors({});
  };

  const GetShopname = () => {
    var raw = JSON.stringify({
      UserID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/get_shopname_list.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) setShop(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const GetDistributorView = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      dist_ID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/get_provider_dist_view_master_dist.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setData(result.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    GetShopname();
    GetDistributorView();
  }, []);

  useEffect(() => {
    if (Profileinfo) {
      otpYes();
    }
  }, [Profileinfo]);

  const otpYes = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: Profileinfo?.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/getUserConfigSettings",

        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.data.add_balance_otp == "N") {
          setSendotpAdd(false);
        }
        if (data.data.reverse_balance_otp == "N") {
          setSendotpRev(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [type, setType] = useState("KYC");

  const ExportToExcel = (fileName, DistView) => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(DistView);
    utils.book_append_sheet(wb, ws, "sheet");
    writeFile(wb, `${fileName}.xlsx`);
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    for (let key in DistView[0]) {
      console.table(key, DistView[0][key]);
    }
  }, [DistView]);

  const redirect = () => {
    history.push("/InsidePages/DistributorReportByid");
  };

  const ShopWithId = () => {
    const getShopsObject = DistView.filter((data) => {
      return formValues.UserName == data.pk_user_id;
    });
    setShopObj(getShopsObject);
    console.log(getShopsObject);
  };

  useEffect(() => {
    ShopWithId();
  }, [formValues.UserName]);

  useEffect(() => {
    setDistributorID(ShopObj.length > 0 ? ShopObj[0].us_code : "");
    setMobile(ShopObj.length > 0 ? ShopObj[0].us_loginid : "");
    console.log(ShopObj.length > 0 ? ShopObj[0].us_code : "");
  }, [ShopObj]);

  const positionStyle = {
    position: "absolute",
    top: -28,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
    borderRadius: "0.2rem",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -44,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
    borderRadius: "0.2rem",
  };

  useEffect(() => {
    setType(details);
  }, [details]);
  console.log(details);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div style={{ overflowY: "hidden", overflowX: "hidden" }}>
            {/* <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch" style={{overflowY: 'hidden',overflowX: 'hidden'}} >
              <DashSidebar details={dataToSend} style={{overflowY: 'hidden',overflowX: 'hidden'}} />
            </div> */}
            <AssignTariffPlanModal
              details={{ ...details, userId: user.ReferId }}
              setModalOpen={setAssignTariffModal}
              isModalOpen={assignTariffModal}
            //  refresh={GetDistributorView}
            />
            <ADD
              // functionA={noOtp}
              SendotpAdd={SendotpAdd}
              selectedData={selectedData}
              details={{ ...details, userId: user.ReferId }}
              isModalOpen={modalOpen}
              setModalOpen={(modalState) => {
                setModalOpen(modalState);
              }}
              refresh={GetDistributorView}
            />
            <REVERSE
              SendotpRev={SendotpRev}
              selectedData={selectedData}
              details={{ ...details, userId: user.ReferId }}
              isModalOpen2={modalOpen2}
              setModalOpen2={(modalState2) => {
                setModalOpen2(modalState2);
              }}
              refresh={GetDistributorView}
            />
            <div
              style={{ overflowY: "hidden", overflowX: "hidden" }}
              className="bg-light"
            >
              <div className="row ">
                <div className="col-12 grid-margin">
                  <div
                    className="card p-0 mt-4"
                    style={{
                      borderRadius: "8px",
                      overflowY: "hidden",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="card-body py-3 px-2 p-0 m-0 ">
                      <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ flex: "1" }}>
                            <div className="row  p-0 m-0 mx-3 d-flex justify-content-between">
                              <div
                                className="row  m-0 p-0"
                                style={{
                                  color: "rgba(66, 103, 178, 1)",
                                  fontFamily: "Poppins",
                                  fontSize: "24px",
                                  fontWeight: "600",
                                  lineHeight: "36px",
                                  letterSpacing: "0em",
                                  textAlign: "left",
                                }}
                              >
                                View Distributor
                              </div>
                              <div className="row  m-0 p-0">
                                <Form.Group
                                  style={{
                                    maxWidth: "30rem",
                                    borderRadius: "6px",
                                  }}
                                  className="ml-2 "
                                >
                                  <Form.Control
                                    type="text"
                                    className="form-control rounded-100 "
                                    aria-label="Notes"
                                    aria-describedby="basic-addon1"
                                    placeholder=" Search in Table."
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                    value={searchText}
                                  />
                                </Form.Group>
                                <Form.Group style={{ marginLeft: "15px" }}>
                                  <Button
                                    className="btn btn-block btn-lg px-5"
                                    style={{ borderRadius: "5px" }}
                                  >
                                    Search
                                  </Button>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="overflowY: 'hidden',overflowX: 'hidden'">
                          <table
                            style={{
                              width: "100%",
                              overflowY: "hidden",
                              overflowX: "hidden",
                            }}
                            className="dashboard-table-style"
                          >
                            <tr>
                              <th
                                className="my-table transparent-table text-center border-bottom py-4"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                S No.
                              </th>
                              <th
                                className="my-table transparent-table text-center border-bottom "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                Shop Name{" "}
                              </th>
                              <th
                                className="my-table transparent-table text-center border-bottom "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                User ID{" "}
                              </th>
                              <th
                                className="my-table transparent-table text-center border-bottom "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                Mobile{" "}
                              </th>
                              <th
                                className="my-table transparent-table text-center border-bottom "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                Balance{" "}
                              </th>
                              <th
                                className="my-table transparent-table text-center border-bottom "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                Last Bal Update{" "}
                              </th>
                              <th
                                className="my-table transparent-table text-center border-bottom "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                KYC Status
                              </th>
                              <th
                                className="my-table transparent-table text-center border-bottom "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                Action{" "}
                              </th>
                            </tr>

                            {dataToDisplay?.map((user, i) => (
                              <tr>
                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  {Number(i) + 1}
                                </td>
                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* {user.img && (
                                  <img
                                    style={{ height: "1.5rem" }}
                                    src={`${BASE_URL_AGENT}${user.img.substring(
                                      2
                                    )}`}
                                    alt="face"
                                  />
                                )} */}
                                  <Link
                                    to={`/master-distributor/InsidePages/distributor_view?id=${user.us_code}`}
                                  >
                                    {user.us_shop_name}
                                  </Link>
                                </td>
                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  {user.us_code}{" "}
                                </td>
                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  {user.us_loginid}{" "}
                                </td>
                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  {user.userbalance}{" "}
                                </td>
                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  {user.balanceupdate}{" "}
                                </td>

                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  <div className="d-flex flex-column align-items-center">
                                    {user.kycstatus == "Approved" ? (
                                      <span
                                        style={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: "1px solid",
                                          backgroundColor:
                                            "rgba(43, 193, 85, 0.1)",
                                          color: "rgba(43, 193, 85, 1)",
                                          bordercolor: "rgba(43, 193, 85, 1)",
                                        }}
                                        className="py-1"
                                      >
                                        {`${user.kycstatus}`}
                                      </span>
                                    ) : user.kycstatus == "Rejected" ||
                                      user.kycstatus == "Reject" ? (
                                      <span
                                        style={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: "1px solid",
                                          backgroundColor:
                                            "rgba(255, 46, 46, 0.1)",
                                          color: "rgba(255, 46, 46, 1)",
                                          bordercolor: "rgba(255, 46, 46, 1)",
                                        }}
                                        className="py-1"
                                      >
                                        {`${user.kycstatus}`}
                                      </span>
                                    ) : user.kycstatus == "Re Submitted" ||
                                      user.kycstatus == "Re Submitted" ? (
                                      <span
                                        style={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: "1px solid",
                                          backgroundColor:
                                            "rgba(232, 129, 36, 0.1)",
                                          color: "rgba(232, 129, 36, 0.5)",
                                          bordercolor:
                                            "  rgba(232, 129, 36, 0.5)",
                                        }}
                                        className="py-1"
                                      >
                                        {`${user.kycstatus}`}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: "1px solid",
                                          backgroundColor:
                                            "rgba(232, 129, 36, 0.1)",
                                          color: "rgba(232, 129, 36, 0.5)",
                                          bordercolor:
                                            "  rgba(232, 129, 36, 0.5)",
                                        }}
                                        className="py-1"
                                      >
                                        {`${user.kycstatus}`}
                                      </span>
                                    )}
                                  </div>
                                </td>

                                <td
                                  className="text-center border-bottom"
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    style={{
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0 p-1"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "kyc",
                                        JSON.stringify(user)
                                      );
                                      history.push("/kyc/bussiness-details");
                                    }}
                                    disabled={
                                      user.kycstatus.toString() == "Approved"
                                    }
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../../assets/images/KYC.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle}
                                        className="hover-text bg-dark px-2 rounded-100"
                                      >
                                        {" "}
                                        KYC
                                      </div>
                                    </div>
                                  </Button>

                                  <Button
                                    style={{
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0  p-1"
                                    onClick={() => {
                                      setDetails({
                                        ...details,
                                        userId: user.us_code,
                                        ...user,
                                      });

                                      setModalOpen(true);
                                      {
                                        setSelectedData({
                                          id: user.us_code,
                                          shop: user.us_shop_name,
                                          oldBalance: user.userbalance,
                                        });
                                      }
                                    }}
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../../assets/images/Add.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle}
                                        className="hover-text bg-dark px-2"
                                      >
                                        {" "}
                                        Add
                                      </div>
                                    </div>
                                  </Button>

                                  <Button
                                    style={{
                                      // paddingLeft:"3px",
                                      // padding: "5px",
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0  p-1"
                                    onClick={() => {
                                      setDetails({
                                        ...details,
                                        ...user,
                                        userId: user.us_code,
                                      });
                                      setModalOpen2(true);
                                      {
                                        setSelectedData({
                                          id: user.us_code,
                                          shop: user.us_shop_name,
                                          oldBalance: user.userbalance,
                                        });
                                      }
                                    }}
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../../assets/images/Reverse.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle}
                                        className="hover-text bg-dark"
                                      >
                                        {" "}
                                        Reverse
                                      </div>
                                    </div>
                                  </Button>

                                  <Button
                                    style={{
                                      // paddingLeft:"3px",
                                      // padding: "5px",
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0  p-1"
                                    onClick={() => {
                                      setDetails({
                                        ...details,
                                        userId: user.us_code,
                                      });
                                      setAssignTariffModal(true);
                                    }}
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../../assets/images/Assign Tariff.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle1}
                                        className="hover-text bg-dark"
                                      >
                                        {" "}
                                        Assign Tariff
                                      </div>
                                    </div>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </table>
                          <Pagination
                            page={currentPage}
                            limit={pages}
                            callback={(page) => {
                              setCurrentPage(page);
                            }}
                            count={dataToDisplay.length}
                            total={total}
                            callback2={(offsetValue) => {
                              setOffset(offsetValue);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row m-1"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
        fontFamily: "Poppins",
        fontSize: "0.8rem",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group  my-1 ">
        <select
          className="rounded-0 form-select-sm my-1"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0  my-1"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0  my-1"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <div className="my-1">
          <Button
            variant="primary"
            className="btn btn-block m-0 p-0 px-2 rounded-0"
          >
            {" "}
            {page.toString()}
          </Button>
        </div>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0  my-1"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const AssignTariffPlanModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const [state, setState] = useState({
    userId: "",
    dmt: "",
    aeps: "",
    bbps: "",
  });

  const [dmtTariff, setDmtTariff] = useState([]);
  const [aepsTariff, setAepsTariff] = useState([]);
  const [bbpsTariff, setBbpsTariff] = useState([]);
  const clearForm = () => {
    setState({
      dmt: "",
      aeps: "",
      bbps: "",
      userId: "",
    });
  };

  const getTariffPlans = async () => {
    try {
      var raw = JSON.stringify({
        UserID: details.userId,
      });

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/get_master_tariff_plans.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setDmtTariff(
          data.dmt.map((role) => {
            return {
              label: `${role.name}`,
              value: role.id,
            };
          })
        );
        setAepsTariff(
          data.aeps.map((role) => {
            return {
              label: `${role.name}`,
              value: role.id,
            };
          })
        );

        setBbpsTariff(
          data.bbps.map((role) => {
            return {
              label: `${role.name}`,
              value: role.id,
            };
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const assignTariff = async () => {
    try {
      var raw = JSON.stringify({
        UserID: state.userId,
        DmtTariff: state.dmt.value,
        AepsTariff: state.aeps.value,
        BbpsTariff: state.bbps.value,
      });

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/assign_tariff_master.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        clearForm();
        setModalOpen(false);
      } else {
        swal("Failled", data.msg, "error");
      }
    } catch (error) {
      swal("Failled", "Error From server", "error");
      console.log("error", error);
    }
  };

  const validateForm = () => {
    if (state.dmt == "" || state.aeps == "" || state.bbps == "") {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    assignTariff();
  };
  useEffect(() => {
    getTariffPlans();
  }, []);
  useEffect(() => {
    if (details) {
      setState({ ...state, userId: details.userId });
      getTariffPlans();
    }
  }, [details]);
  return (
    <Modal
      style={{ width: "50%", left: "25%" }}
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="40%"
    >
      <Modal.Body
        style={{ width: "100%", display: "block", overflowY: "hidden" }}
        className="account"
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "left",
            padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontWeight: "bolder",
            fontfamily: "Poppins !impotent",
            fontSize: "24px",
          }}
          className="d-flex justify-content-between"
        >
          [{state.userId}] Assign Tariff Plan
          {/* <div
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            <img
                                    src={require("../../../assets/images/Vector.png")}
                                    style={{ width: "" }}
                                    />
          </div> */}
          <div style={{ paddingRight: "0px" }} className="col">
            <span
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "4px",
                cursor: "pointer",
              }}
              // onClick={() => {
              // document
              // .querySelector(".content-wrapper")
              // .classList.toggle("sidebar-icon-only");
              // }}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <hr className="m-1 p-1" />
        <div className="">
          <div className="col-12 ">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                <span style={{ color: "black" }}>Select Dmt Tariff</span>
              </Form.Label>
              <Select
                options={dmtTariff}
                value={state.dmt}
                onChange={(e) => {
                  setState({ ...state, dmt: e });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-12 ">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                <span style={{ color: "black" }}>Select Aeps Tariff</span>
              </Form.Label>
              <Select
                options={aepsTariff}
                value={state.aeps}
                onChange={(e) => {
                  setState({ ...state, aeps: e });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-12 ">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                <span style={{ color: "black" }}>Select Bbps Tariff</span>
              </Form.Label>
              <Select
                options={bbpsTariff}
                value={state.bbps}
                onChange={(e) => {
                  setState({ ...state, bbps: e });
                }}
              />
            </Form.Group>
          </div>
          <div
            className="col-12 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button className="rounded-100  " onClick={validateForm}>
              Assign Tariff
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ADD = ({
  SendotpAdd,
  details,
  isModalOpen,
  setModalOpen,
  selectedData,
  refresh,
}) => {
  const [tpinShow, setTpinShow] = useState(false);
  const { user, refreshWallet, Profileinfo, setTransactionLoaderState } =
    useContext(GlobalContext);
  const InitialValues = { Ptype: "", Amount: "", Tpin: "" };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [comment, setComment] = useState("");
  const [Addbalance, setAddBalance] = useState([]);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpText, setOtpText] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  const clearForm = () => {
    setFormValues(InitialValues);
    setComment("");
  };

  const tpinValidation = async (e) => {
    validateTPin(
      Profileinfo.mobile,
      formValues.Tpin,
      () => {
        AddBalance();
      },
      () => {
        // setPayClicked(false);
        clearForm();
        document
          .querySelector(".content-wrapper")
          .classList.toggle("sidebar-icon-only");
      }
    );
  };

  const AddBalance = () => {
    setTransactionLoaderState(true);
    var raw = JSON.stringify({
      paytype: formValues.Ptype,
      retpkid: details.pk_user_id,
      distripkid: user.pk_user_id,
      amount: formValues.Amount,
      comment: comment,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/api_master_dist_add_balance.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setAddBalance(result.data);
            cogoToast.success(result.msg);
            refreshWallet();
            refresh()
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
          } else {
            cogoToast.error(result.msg);
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
          }
          setModalOpen(false);
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Ptype) {
      errors.Ptype = "*Payment type is required!";
    }
    if (!values.Amount) {
      errors.Amount = "*Amount is required!";
    } else if (values.Amount === NaN) {
      errors.Amount = "Enter valid Amount";
    }
    return errors;
  };

  const sendOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: Profileinfo?.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/sendOtp",
        requestOptions
      );
      const data = await res.json();
      console.log("API Data:", data);
      if (data.status == "0") {
        setOtpSent(true);
        cogoToast.success(data.msg);
      } else {
        cogoToast.success(data.msg);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: Profileinfo.mobile,
        otp: otpText,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/validateOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setOtpValidated(true);
        cogoToast.success(data.msg);
      } else {
        cogoToast.success(data.msg);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  if (details) {
    return (
      <>
        <Modal
          show={isModalOpen}
          onHide={() => setModalOpen(false)}
          aria-labelledby=""
          size="40%"
          style={{}}
        >
          <Modal.Body
            style={{
              width: "100%",
              display: "block",
              height: "100%",
              overflowY: "hidden",
            }}
            className="account container-fluid"
          >
            <div
              style={{
                padding: "0px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              className="row"
            >
              <div
                className="row mt-2 m-0 p-2"
                style={{
                  color: "rgba(66, 103, 178, 1)",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "36px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                <strong style={{ color: "#1b68ca" }}>Amount(ADD)</strong>
              </div>
              <div style={{ paddingRight: "0px" }} className="col">
                <span
                  style={{
                    fontSize: "2rem",
                    display: "flex",
                    color: "#b2b2b3",
                    justifyContent: "end",
                    ariaHidden: "true",
                    paddingRight: "4px",
                    cursor: "pointer",
                  }}
                  // onClick={() => {
                  // document
                  // .querySelector(".content-wrapper")
                  // .classList.toggle("sidebar-icon-only");
                  // }}
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
            <Dropdown.Divider />

            <div style={{ margin: "10px", padding: "10px" }}>
              <Form.Group style={{ flex: "1" }}>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Payment Type:
                </Form.Label>
                <Form.Control
                  as="select"
                  name="Ptype"
                  value={formValues.Ptype}
                  onChange={handleChange}
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder={`Advance Payment`}
                  style={{ borderRadius: "4px" }}
                >
                  <option selected>Payment type</option>
                  <option value={1}>IMPS</option>
                  <option value={2}>NEFT</option>
                </Form.Control>
                <p style={{ color: "red" }}>{formErrors.Ptype}</p>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Distributor ID:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  // value={details.us_code}
                  value={selectedData.id}
                  style={{ cursor: "not-allowed", borderRadius: "4px" }}
                />
                <br></br>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  User/Shop Name:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  // value={details.UserData}
                  value={selectedData.shop}
                  style={{ cursor: "not-allowed", borderRadius: "4px" }}
                />
                <br></br>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Old Balance:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  // value={details.userbalance}
                  value={selectedData.oldBalance}
                  style={{ cursor: "not-allowed", borderRadius: "4px" }}
                />
                <br></br>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Add Balance:
                </Form.Label>
                <Form.Control
                  name="Amount"
                  value={formValues.Amount}
                  onChange={handleChange}
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder={`Enter New Amount`}
                  style={{ borderRadius: "4px" }}
                />
                <p style={{ color: "red" }}>{formErrors.Amount}</p>

                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Remarks:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  style={{ borderRadius: "4px" }}
                />

                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Enter Pin:
                </Form.Label>

                <div className="input-group">
                  <div className="input-group-prepend bg-transparent">
                    <span className="input-group-text bg-transparent border-right-0 no-glow">
                      <i className="mdi mdi-lock-outline text-primary"></i>
                    </span>
                  </div>
                  <input
                    autocomplete="new-password"
                    autoCorrect="off"
                    autoSave="new-password"
                    type={tpinShow ? "text" : "password"}
                    className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                    id="exampleInputPassword"
                    placeholder={`Enter T Pin`}
                    onChange={handleChange}
                    name="Tpin"
                    value={formValues.Tpin}
                    style={{ borderRadius: "0px" }}
                  />
                  <div className="input-group-prepend bg-transparent">
                    <span
                      className="input-group-text bg-transparent border-left-0 no-glow"
                      onClick={showTPinClick}
                      style={{ borderRadius: "4px" }}
                    >
                      <i
                        className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                          }`}
                      ></i>
                    </span>
                  </div>
                </div>

                <p style={{ color: "red" }}>{formErrors.Tpin}</p>
              </Form.Group>

              <div style={{ display: "flex", justifyContent: "center" }}>
                {!SendotpAdd && !otpSent && !otpValidated && (
                  <Button
                    className="rounded-100 px-4"
                    style={{
                      margin: "0px 10px",
                      minWidth: "11rem",
                      borderRadius: "4px",
                    }}
                    onClick={tpinValidation}
                  >
                    {"Submit"}
                  </Button>
                )}
              </div>

              {otpSent && !otpValidated && (
                <Form.Group
                  as={Row}
                  className=" m-0 p-0"
                  style={{ flex: "1", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                    }}
                    className="text-dark m-0 p-0"
                  >
                    OTP:
                  </div>

                  <Col sm="12 m-0 p-0">
                    <Form.Control
                      onChange={(e) => {
                        setOtpText(e.target.value);
                      }}
                      type="text"
                      className="form-control rounded-100 "
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter OTP`}
                      style={{ opacity: 0.5, borderRadius: "4px" }}
                    />
                  </Col>
                </Form.Group>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                {SendotpAdd && otpSent && !otpValidated && (
                  <Button
                    className="rounded-100 px-4 mt-2"
                    style={{
                      margin: "0px 10px",
                      minWidth: "5rem",
                      borderRadius: "4px",
                    }}
                    onClick={validateOtp}
                  >
                    {"Validate OTP"}
                  </Button>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {SendotpAdd && !otpSent && !otpValidated && (
                  <Button
                    className="rounded-100 px-4"
                    style={{
                      margin: "0px 10px",
                      minWidth: "5rem",
                      borderRadius: "4px",
                    }}
                    onClick={sendOtp}
                  >
                    {"Send OTP"}
                  </Button>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {SendotpAdd && otpValidated && (
                  <Button
                    className="rounded-100 px-4"
                    style={{
                      margin: "0px 10px",
                      minWidth: "11rem",
                      borderRadius: "4px",
                    }}
                    onClick={tpinValidation}
                  >
                    {"Submit"}
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return null;
};

export const REVERSE = ({
  SendotpRev,
  details,
  isModalOpen2,
  setModalOpen2,
  selectedData,
  refresh,
}) => {
  const [tpinShow, setTpinShow] = useState(false);
  const { user, refreshWallet, Profileinfo, setTransactionLoaderState } =
    useContext(GlobalContext);
  const InitialValues = { Amount: "", Tpin: "" };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [comment, setComment] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpText, setOtpText] = useState("");
  const [Revbalance, setRevBalance] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.Tpin) {
      errors.Tpin = "*pin is required!";
    } else if (values.Tpin === NaN) {
      errors.Tpin = "Enter valid pin";
    }
    if (!values.Amount) {
      errors.Amount = "*Amount is required!";
    } else if (values.Amount === NaN) {
      errors.Amount = "Enter valid Amount";
    }
    return errors;
  };

  const clearForm = () => {
    setFormValues(InitialValues);
    setComment("");
  };

  const tpinValidation = async (e) => {
    validateTPin(
      Profileinfo.mobile,
      formValues.Tpin,
      () => {
        RevBalance();
      },
      () => {
        // setPayClicked(false);
        clearForm();
        document
          .querySelector(".content-wrapper")
          .classList.toggle("sidebar-icon-only");
      }
    );
  };

  const RevBalance = () => {
    setTransactionLoaderState(true);
    var raw = JSON.stringify({
      Tpin: formValues.Tpin,
      retpkid: details.pk_user_id,
      distripkid: user.pk_user_id,
      amount: formValues.Amount,
      comment: comment,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/api_master_dist_reverse_balance.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setRevBalance(result);
            cogoToast.success(result.msg);
            refreshWallet();
            refresh()
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
            successPopup("Successfull Transaction", result.msg);
          } else {
            cogoToast.error(result.msg);
            document
              .querySelector(".content-wrapper")
              .classList.toggle("sidebar-icon-only");
            clearForm();
            failedPopup("Failed Transaction", result.msg);
          }
          setModalOpen2(false);
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: details.us_loginid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/sendOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setOtpSent(true);
        cogoToast.success(data.msg);
      } else {
        cogoToast.success(data.msg);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  console.log(details);

  const validateOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: details.us_loginid,
        otp: otpText,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/validateOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setOtpValidated(true);
        cogoToast.success(data.msg);
      } else {
        cogoToast.success(data.msg);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log(details);

  if (details) {
    return (
      <>
        <Modal
          show={isModalOpen2}
          onHide={() => setModalOpen2(false)}
          aria-labelledby=""
          size="40%"
          style={{}}
        >
          <Modal.Body
            style={{
              width: "100%",
              display: "block",
              height: "100%",
              overflowY: "hidden",
            }}
            className="account container-fluid"
          >
            <div
              style={{
                padding: "0px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              className="row"
            >
              <div
                className="row mt-2 m-0 p-2"
                style={{
                  color: "rgba(66, 103, 178, 1)",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "36px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                <strong style={{ color: "#1b68ca" }}>Amount(REVERSE)</strong>
              </div>
              <div style={{ paddingRight: "0px" }} className="col">
                <span
                  style={{
                    fontSize: "2rem",
                    display: "flex",
                    color: "#b2b2b3",
                    justifyContent: "end",
                    ariaHidden: "true",
                    paddingRight: "4px",
                    cursor: "pointer",
                  }}
                  // onClick={() => {
                  // document
                  // .querySelector(".content-wrapper")
                  // .classList.toggle("sidebar-icon-only");
                  // }}
                  onClick={() => {
                    setModalOpen2(false);
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
            <Dropdown.Divider />

            <div style={{ margin: "10px", padding: "10px" }}>
              <Form.Group style={{ flex: "1" }}>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Distributor ID:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  // value={details.us_code}
                  value={selectedData.id}
                  style={{ borderRadius: "4px" }}
                />
                <br></br>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Old Balance:
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  // value={details.userbalance}
                  value={selectedData.oldBalance}
                  style={{ borderRadius: "4px" }}
                />
                <br></br>
                <Form.Label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Reverse Amount:
                </Form.Label>
                <Form.Control
                  name="Amount"
                  value={formValues.Amount}
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder={`Enter New Amount`}
                  onChange={handleChange}
                  style={{ borderRadius: "4px" }}
                />
                <p style={{ color: "red" }}>{formErrors.Amount}</p>
                <br></br>
                <Form.Label
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Remarks:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  className="form-control rounded-100"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder={`Enter Remarks`}
                  onChange={(e) => setComment(e.target.value)}
                  style={{ borderRadius: "4px" }}
                />
                <br></br>
                <Form.Label
                  style={{
                    fontSize: 15,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  }}
                  className="text-dark"
                >
                  Enter Pin:
                </Form.Label>

                <div className="input-group">
                  <div className="input-group-prepend bg-transparent">
                    <span className="input-group-text bg-transparent border-right-0 no-glow">
                      <i className="mdi mdi-lock-outline text-primary"></i>
                    </span>
                  </div>
                  <input
                    autocomplete="new-password"
                    autoCorrect="off"
                    autoSave="new-password"
                    type={tpinShow ? "text" : "password"}
                    className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                    id="exampleInputPassword"
                    placeholder={`Enter T Pin`}
                    onChange={handleChange}
                    name="Tpin"
                    value={formValues.Tpin}
                    style={{ borderRadius: "0px" }}
                  />
                  <div className="input-group-prepend bg-transparent">
                    <span
                      className="input-group-text bg-transparent border-left-0 no-glow"
                      onClick={showTPinClick}
                      style={{ borderRadius: "4px" }}
                    >
                      <i
                        className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                          }`}
                      ></i>
                    </span>
                  </div>
                </div>
                <p style={{ color: "red" }}>{formErrors.Tpin}</p>
              </Form.Group>

              {SendotpRev && otpSent && !otpValidated && (
                <Form.Group
                  as={Row}
                  className="mb-1"
                  style={{
                    flex: "1",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: 15,
                      fontFamily: "Poppins",
                      fontSize: "14px",
                    }}
                    className="text-dark"
                  >
                    OTP:
                  </div>

                  <Col sm="12 m-0 p-0">
                    <Form.Control
                      onChange={(e) => {
                        setOtpText(e.target.value);
                      }}
                      type="text"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter OTP`}
                      style={{ opacity: 0.5, borderRadius: "4px" }}
                    />
                  </Col>
                </Form.Group>
              )}

              <div style={{ display: "flex", justifyContent: "center" }}>
                {SendotpRev && otpSent && !otpValidated && (
                  <Button
                    className="rounded-100 px-4"
                    style={{
                      margin: "0px 10px",
                      minWidth: "5rem",
                      borderRadius: "4px",
                    }}
                    onClick={validateOtp}
                  >
                    {"Validate OTP"}
                  </Button>
                )}

                {!SendotpRev && !otpSent && !otpValidated && (
                  <Button
                    className="rounded-100 px-4"
                    style={{
                      margin: "0px 10px",
                      minWidth: "11rem",

                      borderRadius: "4px",
                    }}
                    onClick={tpinValidation}
                  >
                    {"Submit"}
                  </Button>
                )}

                {SendotpRev && !otpSent && !otpValidated && (
                  <Button
                    className="rounded-100 px-4"
                    style={{
                      margin: "0px 10px",
                      minWidth: "5rem",
                      borderRadius: "4px",
                    }}
                    onClick={sendOtp}
                  >
                    {"Send OTP"}
                  </Button>
                )}

                {SendotpRev && otpValidated && (
                  <Button
                    className="rounded-100 px-4"
                    style={{
                      margin: "0px 10px",
                      minWidth: "11rem",

                      borderRadius: "4px",
                    }}
                    onClick={tpinValidation}
                  >
                    {"Submit"}
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } else return null;
};

export default Dashboard;
