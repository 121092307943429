import React, { useState, useEffect, useRef , useContext } from "react";
import { Tabs, Tab, Button, Dropdown, Modal } from "react-bootstrap";
import { Collapse, Spinner } from "react-bootstrap";
import Sidebarnew from "./Sidebarnew";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import SmartCollectVirtualAccountTable from "./SmartCollectVirtualAccountTable";
import SmartCollectPaymentsTable from "./SmartCollectPaymentsTable";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };
const rowStyle = (row, index) => {
  if (index % 2 == 0) {
    return greyStyle;
  } else {
    return whiteStyle;
  }
};


function Modals() {
  const [mdShow, setMdShow] = useState(false);
  const [panNo, setPanNo] = useState("");
  const [adhaarNo, setAdhaarNo] = useState("");
  const [mobileNO, setMobileNo] = useState("");
  const { user , setTransactionLoaderState } = useContext(GlobalContext);
  const [createVirtualLoading, setCreateVirtualLoading] = useState(false);
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const validate = () => {
    if (panNo === "") {
      toast.error("Please Enter PAN Number");
      return false;
    } else if (adhaarNo === "") {
      toast.error("Please Enter Aadhar Number");
      return false;
    } else if (mobileNO === "") {
      toast.error("Please Enter Mobile Number");
      return false;
    }
    return true;
  };
  const createVirtualAccount = async () => {
    if (!validate()) {
      return;
    }
    console.log(panNo, adhaarNo, mobileNO);
    setCreateVirtualLoading(true);

    var myHeaders = new Headers();
    const accessToken = sessionStorage.getItem("accessToken");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-izR5UycBJE4PYYYv5_e2oQ_7DetaoeEk_kakkCrL3xBMq8IVNgG1vhuSzKztp4vtHWkE8ncls1NA-W0Y-0c6Xi-zYTxEL__nJOixKyLgO6GAI-AjaqoNT2BQk12D03alzxkNi0CYjhZze3N83Hxxx0IFplJdZJTH6IUZaUEBZwB9GLEgCPHp3Oa3Gk_-mk-t_ksuPXhYY083k5nn9NjTXH21i-sA2Cj3DBWZ37LfX-oJ33Q6-NKunCGWj_0carTYRwGKEbgItH3nCXx1h1kgt47n42c0x9TAaLlPeyeduZ9t76f6xmKzdWuj4AzCM8fVzQ3xMo8WjIlAVD30NknuPf4GqhsLn7cKEclegrok2dcdgPtBi3Q6AOGKYjNgzbGaoXLNw3EvOEgpv1cZ9oj-92j0z2W5UJlK7enRpIsNqNjX775V8EMlWKDDxbEsKOFO5ITIdml-Tz_-ophGBmoj3Id2IHAs7KyxpQdbMvBy-c86RGh3mazh7cWbne2G0lWRXrV8QucJDlbI4kPBKje1UuGA7-uVTYUyE68Fc_oDNj-woBV6ep19BQv40lgW8IXaa87QnJ05Toso0erOgt0h6PVTsIVbdcS8w4huMv0PcvkhJro8DxpajU-fx6oGJ-WhfcrupKyWa-B1cW7TppkLuGK"
    );

    var raw = JSON.stringify({
      mobileNo: mobileNO,
      panNo: panNo,
      aadharNo: adhaarNo,
      beneficiaryName: beneficiaryName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true)
      const res = await fetch(`${BASE_URL_PAYNOW}/api/VirtualInfo/CreateVirtualAccount`, requestOptions);
      console.log(res);
      if (res.ok) {
        const json = await res.text();
        const body = JSON.parse(json);
        console.log(body);
        setCreateVirtualLoading(false);
        toast.success("Virtual Account created Successfuly");
        setMdShow(false);
      } else {
        setCreateVirtualLoading(false);
        toast.error("Something went Wrong");
        setMdShow(false);
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      setCreateVirtualLoading(false);
      toast.error("Something went Wrong");
      setMdShow(false);
    }

    // setMdShow(false);
  };

  return (
    <div>
      <div className="text-center">
        <ToastContainer
          autoClose={3000}
          hideProgressBar
          draggableDirection="y"
        />
        <Button onClick={() => setMdShow(true)} className="btn-sm float-right">
          + Create Virtual Account
        </Button>
      </div>
      <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Virtual Account</Modal.Title>
        </Modal.Header>

        <Modal.Body className="account">
          <p> Methods to accept payments in this account</p>

          <div
            style={{
              background: "#FCFDFE",
              border: "1px solid  #86C6F4",
              padding: "0px",
            }}
            className="card"
          >
            <span style={{ padding: "10px" }}>
              <i className="fa fa-inr"> UPI Transfers(GPay, PhonePe, etc.)</i>
            </span>

            <div className="col">
              <div
                style={{
                  border: "1px solid #c2c2c2",
                }}
                className="input-group mb-2 mr-sm-2 mb-sm-0"
              >
                <input
                  style={{
                    margin: "0px",
                    outline: "none",
                    border: "none",
                    width: "70%",
                  }}
                  type="text"
                  placeholder="Add user"
                />
                <span
                  style={{
                    padding: "5px",
                    background: "#EEEEEE",
                    marginLeft: "auto",
                  }}
                >
                  @icici
                </span>
              </div>

              <p style={{ margin: "0px" }}>
                UPI ID is auto generated if details are left blank
              </p>
              <p style={{ margin: "0px" }}>
                To update "payto00000" prefix
                <button className="btn btn-link">click here</button>
              </p>
            </div>
          </div>

          {/* Bank Details */}

          <div
            style={{
              marginTop: "10px",
              background: "#FCFDFE",
              border: "1px solid  #86C6F4",
              padding: "0px",
            }}
            className="card"
          >
            <span style={{ padding: "10px" }}>
              <i className="fa fa-bank"> Bank Transfers(NEFT,RTGS,IMPS)</i>
            </span>

            <div className="col">
              {/* mobile no */}
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <span style={{ padding: "10px", width: "8rem" }}>
                  {" "}
                  Mobile Number{" "}
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  value={mobileNO}
                  onChange={(event) => setMobileNo(event.target.value)}
                />
              </div>
              {/* pan no */}
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <span style={{ padding: "10px", width: "8rem" }}>
                  {" "}
                  PAN Number{" "}
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="PAN Number"
                  value={panNo}
                  onChange={(event) => setPanNo(event.target.value)}
                />
              </div>
              {/* adhaar no */}
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <span style={{ padding: "10px", width: "8rem" }}>
                  {" "}
                  Adhaar Number{" "}
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Adhaar Number"
                  value={adhaarNo}
                  onChange={(event) => setAdhaarNo(event.target.value)}
                />
              </div>
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <span style={{ padding: "10px", width: "8rem" }}>
                  {"beneficiary Name"}
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add user"
                  value={beneficiaryName}
                  onChange={(event) => setBeneficiaryName(event.target.value)}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div>
              <span>Customers (Optonal)</span>
            </div>
            <select
              style={{
                width: "100%",
                border: "1px solid #ced4da",
                padding: "6px",
              }}
            >
              <option disabled selected value>
                {" "}
                -- select an option --{" "}
              </option>
              <option> Add New</option>
            </select>
          </div>
          <CollapseView />
        </Modal.Body>

        <Modal.Footer className="fleex-wrap">
          <Button variant="light m-2" onClick={() => setMdShow(false)}>
            Cancel
          </Button>
          <Button variant="success m-2" onClick={createVirtualAccount}>
            Create Virtual Account
          </Button>
          {createVirtualLoading ? <Spinner animation="border" /> : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const SmartCollect = () => {
  const [accounts, setAccounts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [details, setDetails] = useState({});


  useEffect(() => {
    // getAllVirtualAccounts();
    // getAllVirtualPayments();
  }, []);
  console.log(accounts);
  console.log(payments);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div>

      
      






      
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch m-0 p-0">
        <Sidebarnew details={details} />
      </div>
      <div className=" p-2 bg-light">
      <div style={{ borderRadius: "10px" }} className="card m-0 mt-4 mx-2 p-1">
        
        <div className="card-body m-0 p-0 pl-3">

        <div className="row pl-7">
          
          <div
            style={{
              "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
              ,padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize:"32px",
            }}
          >
          Virtual Report
          </div>
  
          </div>
       

          {/* <Modals /> */}

          <div className=" col-4 tab-header justify-content-between  m-0 p-0">
              <div
                className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                onClick={() => handleTabClick(0)}
              >
                Virtual Accounts
              </div>
              <div
                className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >       
                Payments
              </div>
            </div>

              <div className="tab-content">
              {activeTab === 0 && (
                <div>
                  <Tab.Pane eventKey="first">
                  <SmartCollectVirtualAccountTable
                setDetails={(details) => {
                  setDetails({ ...details, sidebarType: "virtualAccount" });
                  document
                    .querySelector(".content-wrapper")
                    .classList.toggle("sidebar-icon-only");
                }}
              />
                  </Tab.Pane>
                </div>
              )}

           
              {activeTab === 1 && (
                <div>
                  <Tab.Pane eventKey="second">
                  <SmartCollectPaymentsTable
                  setDetails={(details) => {
                  setDetails({ ...details, sidebarType: "payments" });
                  document
                    .querySelector(".content-wrapper")
                    .classList.toggle("sidebar-icon-only");
                  }}
                  />
                    
                  </Tab.Pane>
                </div>
              )}

           </div>



       



        </div>
      </div>
      </div>
    </div>
  );
};

const CollapseView = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          border: "1px solid #ced4da",
          padding: "6px",
        }}
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        View Advanced Options
        <i className="mdi mdi-chevron-down"></i>
      </div>
      <Collapse in={open}>
        <div id="example-collapse-text">
          <h6>Option 1</h6>
          <h6>Option 2</h6>
          <h6>Option 3</h6>
          <h6>Option 4</h6>
        </div>
      </Collapse>
    </>
  );
};
export default SmartCollect;
