import React, { Suspense, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { generateHashKey2, uniqueID2 } from "../utils/utilFuctions";

import Spinner from "./shared/Spinner";
import DMTBankit from "./DMT1/Dmt1";
import Recharge from "./Recharge/Recharge";
import Mobile from "./MobilePostpaid/Mobile";
import Cable from "./CableRecharge/Cable";
import Pipedgas from "./PipedGas/Pipedgas";
import Landline from "./LandlinePostpaid/Landline";
import Broadband from "./BroadbandPostpaid/Broadband";
import Housing from "./Housing/Housing";
import Hospital from "./Hospital/Hospital";
import Education from "./EducationFees/Education";
import HealthInsurance from "./Health Insurance/HealthInsurance";
import Municipal from "./Municipal/Municipal";
import Loan from "./LoanRepayment/Loan";
import Subscription from "./Subscription/Subscription";
import Insurance from "./Insurance/Insurance";
import Fastag from "./Fastag/Fastag";
import ElectricityPay from "./Electricity Payment/ElectricityPay";
import GasBillPay from "./Gas Bill Payment/GasBillPay";
import Life from "./Life/Life";
import MunicipalTax from "./MunicipalTax/MunicipalTax";
import DthRecharge from "./DTH Recharge/DthRecharge";
import WaterBillPay from "./Water Bill Payment/WaterBillPay";
import TopupView from "./Topup View/TopupView";
import BankDetails from "./Bank Details/BankDetails";
import WalletToWalletTransfer from "./Wallet To Wallet Transfer/WalletToWalletTransfer";
import RechargeReport from "./Recharge Report/RechargeReport";
// import Aeps2 from "./AEPS/Aeps2";
// import Aeps2 from "./AEPS/Aeps3";
import Aeps2 from "./AEPS/Aeps7";
import Aeps3 from "./AEPS/Aeps8";
import Aeps from "./AEPS/Aeps6";
import MyProfile from "./my profile/MyProfile";
import Error404 from "./error-pages/Error404";
import Error500 from "./error-pages/Error500";
import Login2 from "../user-pages/Login2";
import AEPSsettlement from "./AEPSSettlement/AEPSSettlement2";
import EKYC from "./Agent EKYC/EKYC";
import PrepaidRecharge from "./MplanPrepaid/PrepaidRecharge";
import CustomerKyc from "./CKYC/CustomerKyc";
import DMTExpress from "./DMT Express/DMTExpress";
import DMTWithKYC from "./DMTWithKYC/DMTWithKYC";
import OfflineBillPayment from "./Offline Bill Payment/OfflineBillPayment2";
import RechargeComponent from "./Offline Bill Payment/OfflineBillPayment4";
import OfflineGasBill from "./BBPS Offline/Offline Gas Bill/OfflineGasBill";
import OfflineElectricityPay from "./BBPS Offline/Offline Electricity/OfflineElectricityPay";
import OfflineWaterBill from "./BBPS Offline/Offline Water Bill/OfflineWaterBill";
import OfflineCable from "./BBPS Offline/Offline Cable TV/OfflineCable";
import OfflinePrepaidMobileRecharge from "./BBPS Offline/Offline Prepaid Mobile/OfflineMobilePrepaidRecharge";
import OfflineInsurace from "./BBPS Offline/Offline Insurance/OfflineInsurance";
import Lic from "./LIC/Lic";
import { checkSession, SESSION_TIMER } from "../utils/Session";
import { GlobalContext } from "../Context/GlobalState";
import LoanEnquiry from "./Loan Enquiry/LoanEnquiry";
import LoanUI from "./LoansAndMore/LoanAndMore";
import HelpAndSupport from "./HelpAndSupport/HelpAndSupport2";
import AgentCertificate from "../company/AgentCertificate";
import VirtualReport from "./virtual report/VirtualReport";
import CommissionChart from "../master-distributor/InsidePages/CommissionChart";
import Dashboard from "./dashboard/Dashboard3";
import { TopupRequest } from "./topup/Topup7";
import RetailerCommission from "./retailer-commision/RetailerCommission";
import BbpsScreen from "./bbps-screen/BbpsScreen";
import Transactions from "./transactions/Transactions";
import IncomeCalculator from "./income-calculator/IncomeCalculator";
import MoneyTransfer from "./money-transfer/MoneyTransfer";
import AEPSReconcileReport from "./AEPS Reconcile Report/AEPSReconcileReport";
import PaymentGatewayReport from "./payment-gateway-report/PymentsGetwayReport";
import ServicesRequest from "./services-request/ServicesRequest";
import ServicesRequest2 from "./service-request/ServicesRequest";
// import { MyApp as FlightApp } from "../../Flights/src/App";
import Developer from "./developer/Developer";
import Lic2 from "./LIC/Lic2";
// import DMT2 from "./DMT2/DMT2";
import DMT2 from "./DMT3/DMT3";
import DMT4 from "./DMT4/DMT4";
import DMT5 from "./DMT5/DMT5";
// import ViewLoadAgent from "./view-load/ViewLoad";
import ViewLoadAgent from "./Topup View/TopupView";
import EPan from "./EPAN/EPan";
import CreditRequest from "./credit-request/CreditRequest";
import LedgerReport2 from "./ledger2/LedgerReport2";
import DMTHistory2 from "./DMT History 2/DMTHistory2";
import BBPSReport2 from "./BBPS Report 2/BBPSReport2";
import AEPSReport2 from "./AEPS Report 2/AEPSReport2";
import PGSettlement from "./PGSettlement/PGSettlement";
import EpanReport from "./EPanReport/EPanReport";
import PGSettlementReport from "./pg-settlement-report/PGSettlementReport";
import CMS from "./cms/Cms";
import CreditCardBillPayment from "./credit-card-bill-payment/CreditCardBillPayment";
import CreditCardBillPaymentReport from "./credit-card-bill-payment-report/CreditCardBillPaymentReport";
import FundHoldReport from "./fund_hold_report/AllFundHoldReport";
import VendorPaymentSystem from "./vendor_payment_system/VendorPaymentSystem";
import VendorAccountList from "./vendor_payment_system/VendorAccountList";
import MarcamForm from "../company/MarcomDoc";
import { ImageUpload } from "./Test/ImageUploadTest";
import CreditCardKYC from "./credit-card-kyc/CreditCardKYC";
import ImageCropper from "./credit-card-kyc/ImageCropper";
import AadhaarVerifyOTPScreen from "./otp-verify/AadhaarVerifyOtp";
import RewardsScreen from "./rewards/RewardsScreen";
import YesBankAEPS from "./AEPS/YesBankAEPS2";
import DmtRejectedTransactions from "./dmt-rejected-transactions/DmtRejectedTransactions";
import DMTRejectedTransactionsReport from "./dmt-rejected-transactions/DMTRejectedTransactionsReport";
import MobileRecharge from "./mobile-recharge/MonbileRecharge";
import VendorPaymentsReport from "./vendor-payments-report/VendorPaymentsReport";
import CmsReport from "./cms-report/CmsReport";
import CMS2 from "./CMS2/CMS2";
import Chaafo from "./Chaafo/Chaafo";
import Anlook from "./Anlook/Anlook";
import GoSeo from "./GoSeo/GoSeo";
import Trawlo from "./Trawlo/Trawlo";
import AnlookForm from "./Anlook/AnlookForm";
import ChaafoForm from "./Chaafo/ChaafoForm";
import GoSeoDocSubmit from "./GoSeo/GoSeoDocSubmit";
import ChaafoDocSubmit from "./Chaafo/ChaafoDocSubmit";
import CmsFingpay from "./CmsFingpay/CmsFingpay";
import RetailerBusiness from "./Retailer_Business/RetailerBusiness";
import AepsSettlementReport from "./AepsSettlementReport/AepsSettlementReport";
import AepsBankit from "./AepsBankIt/AepsBankit";
// import GoSeoForm from "./goseo/GoseoForm";

// import CMS from "./cms/Cms";

const AppRoutes = () => {
  const { removeUserInfo, user } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    removeUserInfo();
    checkSession();
  };

  const onActive = (event) => {};

  const onAction = (event) => {};

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const checkSession2 = async (sessionId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/check_session.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
      } else {
        removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addSession = async () => {
    const sessionId = uniqueID2();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/add_session.aspx",
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        sessionStorage.setItem("sessionId", sessionId);
      } else {
        removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionId = sessionStorage.getItem("sessionId");
      if (sessionId) {
        // checkSession2(sessionId);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const sessionId = sessionStorage.getItem("sessionId");
    // if (!sessionId) {
    // addSession();
    //  }
  }, [user]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Disable right-click
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
        )
          return false;
      };
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* Retailer */}
        <Route exact path="/retailer/dashboard" component={Dashboard} />
        <Route exact path="/retailer/Chaafo" component={Chaafo} />
        <Route exact path="/retailer/ChaafoForm" component={ChaafoForm} />
        <Route
          exact
          path="/retailer/ChaafoDocSubmit"
          component={ChaafoDocSubmit}
        />
        {/* <Route exact path="/retailer/ChaafoForm" component={ChaafoForm} /> */}
        <Route exact path="/retailer/Anlook" component={Anlook} />
        <Route exact path="/retailer/AnlookForm" component={AnlookForm} />
        <Route exact path="/retailer/GoSeo" component={GoSeo} />
        <Route
          exact
          path="/retailer/GoSeoDocSubmit"
          component={GoSeoDocSubmit}
        />
        <Route exact path="/retailer/Trawlo" component={Trawlo} />
        <Route exact path="/retailer/transactions" component={Transactions} />
        <Route
          exact
          path="/retailer/money-transfer"
          component={MoneyTransfer}
        />
        <Route
          exact
          path="/retailer/income-calculator"
          component={IncomeCalculator}
        />
        {/* <Route exact path="/retailer/dmt" component={DMT} /> */}
        <Route exact path="/retailer/IMPS" component={DMTBankit} />
        <Route exact path="/retailer/dmt" component={DMT2} />
        <Route exact path="/retailer/dmt2" component={DMT4} />
        <Route exact path="/retailer/dmt3" component={DMT5} />
        <Route exact path="/retailer/e-suvidha" component={DMTExpress} />
        <Route
          exact
          path="/retailer/vendor-payment"
          component={VendorPaymentSystem}
        />
        <Route
          exact
          path="/retailer/vendor-accounts"
          component={VendorAccountList}
        />
        <Route exact path="/retailer/full-kyc" component={DMTWithKYC} />
        <Route exact path="/retailer/aeps" component={Aeps2} />
        <Route exact path="/retailer/aeps2" component={Aeps} />
        <Route exact path="/retailer/biometric" component={Aeps3} />

        <Route exact path="/retailer/aeps/yes" component={YesBankAEPS} />
        <Route
          exact
          path="/retailer/service-request"
          component={ServicesRequest2}
        />
        <Route exact path="/retailer/cms" component={CMS2} />
        <Route exact path="/retailer/AepsBankit" component={AepsBankit} />
        <Route exact path="/retailer/cms2" component={CMS} />
        <Route exact path="/retailer/cmsFingpay" component={CmsFingpay} />
        <Route exact path="/retailer/goseo" component={GoSeo} />

        <Route exact path="/retailer/rewards" component={RewardsScreen} />

        <Route
          exact
          path="/retailer/rejected-dmt-transaction"
          component={DMTRejectedTransactionsReport}
        />

        <Route
          exact
          path="/retailer/credit-request"
          component={CreditRequest}
        />

        <Route
          exact
          path="/retailer/aeps-settlement"
          component={AEPSsettlement}
        />

        <Route exact path="/retailer/pg-settlement" component={PGSettlement} />

        <Route
          exact
          path="/retailer/fund-transfer"
          component={AEPSsettlement}
        />
        {/* <Route exact path="/retailer/recharge" component={Recharge} /> */}
        <Route
          exact
          path="/retailer/Help-And-Support"
          component={HelpAndSupport}
        />
        <Route
          exact
          path="/retailer/electricity_payment"
          component={ElectricityPay}
        />
        <Route exact path="/retailer/EKYC" component={EKYC} />
        <Route exact path="/retailer/e_pan" component={EPan} />
        <Route
          exact
          path="/retailer/credit_card_bill_payment"
          component={CreditCardBillPayment}
        />

        <Route exact path="/retailer/gas_payment" component={GasBillPay} />
        <Route
          exact
          path="/retailer/commission-chart"
          component={RetailerCommission}
        />
        <Route
          exact
          path="/retailer/services_request"
          component={ServicesRequest}
        />

        {/* <Route exact path="/retailer/flight" component={FlightApp} /> */}
        <Route exact path="/retailer/Life" component={Life} />
        <Route exact path="/retailer/dth_recharge" component={DthRecharge} />
        <Route exact path="/retailer/MobilePostpaid" component={Mobile} />
        <Route exact path="/retailer/LandlinePostpaid" component={Landline} />
        <Route exact path="/retailer/BroadbandPostpaid" component={Broadband} />
        <Route exact path="/retailer/PipedGas" component={Pipedgas} />
        <Route exact path="/retailer/CableRecharge" component={Cable} />
        <Route exact path="/retailer/Fastag" component={Fastag} />
        <Route exact path="/retailer/Municipal" component={Municipal} />
        <Route exact path="/retailer/MunicipalTax" component={MunicipalTax} />
        <Route exact path="/retailer/Housing" component={Housing} />
        <Route exact path="/retailer/LoanRepayment" component={Loan} />
        <Route exact path="/retailer/EducationFees" component={Education} />
        <Route exact path="/retailer/Insurance" component={Insurance} />
        <Route exact path="/retailer/marcomm" component={MarcamForm} />
        <Route
          exact
          path="/retailer/retailer-business"
          component={RetailerBusiness}
        />
        <Route
          exact
          path="/retailer/aepsSettlementReport"
          component={AepsSettlementReport}
        />

        <Route
          exact
          path="/retailer/credit-card-kyc"
          component={CreditCardKYC}
        />
        <Route
          exact
          path="/retailer/Health Insurance"
          component={HealthInsurance}
        />
        <Route exact path="/retailer/Subscription" component={Subscription} />
        <Route exact path="/retailer/Hospital" component={Hospital} />
        <Route
          exact
          path="/retailer/MplanPrepaid"
          component={PrepaidRecharge}
        />
        <Route exact path="/retailer/lic" component={Lic2} />
        {/* <Route exact path="/retailer/aadhaar_verify_otp" component={AadhaarVerifyOTPScreen} /> */}
        <Route exact path="/retailer/loanenquiry" component={LoanEnquiry} />
        <Route exact path="/retailer/LoansAndMore" component={LoanUI} />

        <Route
          exact
          path="/retailer/water_bill_payment"
          component={WaterBillPay}
        />
        <Route exact path="/retailer/bill_payment" component={BbpsScreen} />
        {/* Offline BBPS */}
        <Route
          exact
          path="/retailer/offline_bill_payment"
          component={OfflineBillPayment}
        />
        <Route exact path="/retailer/recharge" component={MobileRecharge} />

        <Route
          exact
          path="/retailer/offline/PipedGas"
          component={OfflineGasBill}
        />
        <Route
          exact
          path="/retailer/offline/electricity"
          component={OfflineElectricityPay}
        />
        <Route
          exact
          path="/retailer/offline/water_bill"
          component={OfflineWaterBill}
        />
        <Route
          exact
          path="/retailer/offline/CableRecharge"
          component={OfflineCable}
        />
        <Route
          exact
          path="/retailer/offline/recharge"
          component={OfflinePrepaidMobileRecharge}
        />
        <Route
          exact
          path="/retailer/offline/insurance"
          component={OfflineInsurace}
        />

        <Route exact path="/retailer/image_cropper" component={ImageCropper} />
        <Route
          exact
          path="/retailer/credit_card_kyc"
          component={CreditCardKYC}
        />
        <Route exact path="/retailer/topup_request" component={TopupRequest} />

        <Route exact path="/retailer/imageupload" component={ImageUpload} />
        <Route exact path="/retailer/topup_view" component={ViewLoadAgent} />
        {/* Virtual Report */}
        <Route
          exact
          path="/retailer/virtual_report"
          component={VirtualReport}
        />
        <Route exact path="/retailer/bank_details" component={BankDetails} />
        <Route exact path="/retailer/my_profile" component={MyProfile} />
        <Route
          exact
          path="/retailer/wallet_to_wallet_transfer"
          component={WalletToWalletTransfer}
        />

        <Route exact path="/retailer/reports/dmt" component={DMTHistory2} />
        <Route
          exact
          path="/retailer/reports/vendor-payments"
          component={VendorPaymentsReport}
        />
        <Route exact path="/retailer/reports/aeps" component={AEPSReport2} />
        <Route exact path="/retailer/reports/cms" component={CmsReport} />
        <Route
          exact
          path="/retailer/reports/pg"
          component={PaymentGatewayReport}
        />
        <Route
          exact
          path="/retailer/reports/aeps-reconcile"
          component={AEPSReconcileReport}
        />
        <Route exact path="/retailer/bbps_history" component={BBPSReport2} />
        <Route path="/retailer/customer-kyc" component={CustomerKyc} />
        <Route
          exact
          path="/retailer/recharge_history"
          component={RechargeReport}
        />
        <Route
          exact
          path="/retailer/reports/epan_report"
          component={EpanReport}
        />
        <Route
          exact
          path="/retailer/reports/credit_card_bill_payment_report"
          component={CreditCardBillPaymentReport}
        />
        <Route
          exact
          path="/retailer/reports/pg_settlement_report"
          component={PGSettlementReport}
        />
        <Route
          exact
          path="/retailer/reports/fund_hold_report"
          component={FundHoldReport}
        />
        <Route exact path="/retailer/ledger" component={LedgerReport2} />
        <Route
          exact
          path="/retailer/certificate"
          component={AgentCertificate}
        />
        <Route
          exact
          path="/retailer/commission-chart"
          component={CommissionChart}
        />

        {/* Developer */}
        <Route exact path="/retailer/developer" component={Developer} />

        <Route path="/user-pages/login-2" component={Login2} />

        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />

        <Redirect to="/user-pages/login-2" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
