import React from "react";

import { GlobalContext } from "../../../Context/GlobalState";
import AllUserLedgerTable from "./AllUserLedgerTable";
const AllUserLedger = () => {
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "1rem" }} className="bg-light ">
        <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
          <div className="card-body m-0 p-2">
            <div>
               <AllUserLedgerTable/>
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default AllUserLedger;