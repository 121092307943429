import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import swal from "sweetalert";
import Select from "react-select";
import DatePicker from "react-datepicker";

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

const CreateLoadRequest = () => {
  const [state, setState] = useState({
    userCode: "",
    utrNo: "",
    amount: "",
    bank: null,
    date: "",
    paymode: "",
    remark: "",
  });
  const [userInfo, setUserInfo] = useState(null);
  const [banks, setBanks] = useState([]);
  const { user ,  setTransactionLoaderState } = useContext(GlobalContext);

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const searchUser = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: state.userCode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_user_details_create_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserInfo(data.data);
      } else {
        swal(
          "User Not Found",
          "Please Enter Valid ID Or Mobile number",
          "error"
        );
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  const clearData = () => {
    setState({
      userCode: "",
      utrNo: "",
      amount: "",
      bank: null,
      date: "",
      paymode: "",
      remark: "",
    });
    setUserInfo(null);
  };

  const getBankDetails = async () => {
    try {
      setTransactionLoaderState(true)
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      console.log(data);
      setBanks(
        data.map((bank) => {
          return {
            ...bank,
            value: `${bank.bankName} | ${bank.ifscCode}`,
            label: `${bank.bankName} | ${bank.ifscCode}`,
          };
        })
      ); setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  const validateForm = () => {
    if (
      state.amount == "" ||
      state.bank == "" ||
      state.utrNo == "" ||
      state.paymode == "" ||
      state.date == ""
    ) {
      swal("Invalid", "Fill All Mandatory Fields", "error");
      return;
    }
    createLoadRequest();
  };

  const createLoadRequest = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: state.userCode,
        paytype: "3",
        amount: state.amount,
        description: state.remark,
        priority: "1",
        usertype: userInfo.us_type,
        bankId: state.bank.bankId,
        utrno: state.utrNo,
        paymentMode: state.paymode.label,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/create_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.data.result, "success");
      } else {
        swal("Failed", "Load Request Failed", "error");
      }  setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
      swal("Failed", "Internal Server Error", "error");
    }
  };

  useEffect(() => {
    getBankDetails();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "1rem" }} className="bg-light ">
        <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
          <div className="card-body m-0 p-3">
            <div>
                <div
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                  }}
                >
                  Create Load Request
                </div>

                <div className="row">
                  <div className="col-5 m-0 p-0 mx-2 ">
                    <Form.Group>
                      <Form.Label>
                        <span  className="text-dark"><span style={{ color: "red" }}>*</span>
                        {`Enter User Code Or Mobile Number`}
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User Code Or Mobile Number"
                        value={state.userCode}
                        onChange={(e) => {
                          setState({ ...state, userCode: e.target.value });
                        }}
                        style={{ borderRadius: "5px" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-2 p-0 m-0 mx-2">
                    <Form.Group className="mt-4">
                      <Button
                        onClick={searchUser}
                        className="rounded-50 btn btn-primary btn-lg btn-block"
                      >
                        {" "}
                        Search
                      </Button>
                    </Form.Group>
                    </div>
                    <div className="col-2 p-0 m-0 mx-2">
                    <Form.Group className="mt-4 ">
                      <Button
                        onClick={clearData}
                        className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
                      >
                        {" "}
                        Clear
                      </Button>
                    </Form.Group>
                  </div>
                </div>

                {userInfo && (
                  <div
                  className="card "
                    style={{
                      padding: "12px",
                      background: "#3676D8",
                      color: "#FFF",
                      fontWeight: "bolder",
                    }}
                  >
                    <div className="row d-flex rounded-50">

                      <div className="col-6">
                      <div className="col-12 mb-3">
                        <strong>
                          {" "}
                          {`User : ${userInfo.us_name} [${userInfo.us_code}]`}
                        </strong>
                      </div>


                      <div className="col-12 mb-3">
                        <strong>
                          {" "}
                          {`User Mobile : ${userInfo.us_loginid}`}
                        </strong>
                      </div>
                      </div>

                      <div className="col-6">
                      <div className="col-12 mb-3">
                        <strong> {`User Type : ${userInfo.usrtype}`}</strong>
                      </div>


                      <div className="col-12 mb-3">
                        <strong>
                          {" "}
                          {`User Balance : ${twoDecimalPlaces(
                            userInfo.usrbal
                          )}`}
                        </strong>
                      </div>
                      </div>

                    </div>
                  </div>
                )}

                {userInfo && (
                  <div className="">
                    <div className="row m-0 p-0 mt-3">
                    <div className="col-6 ">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter UTR No`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control  "
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter UTR No"
                          value={state.utrNo}
                          onChange={(e) => {
                            setState({ ...state, utrNo: e.target.value });
                          }}
                          style={{ borderRadius: "5px" }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Amount`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control rounded-100"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Amount"
                          value={state.amount}
                          onChange={(e) => {
                            setState({ ...state, amount: e.target.value });
                          }}
                          style={{ borderRadius: "5px" }}
                        />
                      </Form.Group>
                    </div>
                    </div>
                    <div className="row m-0 p-0">
                    <div className="col-6">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Bank`}
                        </Form.Label>
                        <Select
                        className="rounded-100"
                          options={banks}
                          value={state.bank}
                          onChange={(e) => {
                            setState({ ...state, bank: e });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-6">
                      <Form.Group>
                        <Form.Label>Enter Date</Form.Label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={state.date}
                          onChange={(date) =>
                            setState({ ...state, date: date })
                          }
                          className="form-control  rounded-50"
                          placeholderText="Select Date"
                        />
                      </Form.Group>
                    </div>
                    </div>

                    <div className="row m-0 p-0">     
                    <div className="col-6 ">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Payment Mode`}
                        </Form.Label>
                        <Select
                         className="rounded-100 "
                          options={paymentModes}
                          value={state.paymode}
                          onChange={(e) => {
                            setState({ ...state, paymode: e });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Remark`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control rounded-50"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Remark"
                          value={state.remark}
                          onChange={(e) => {
                            setState({ ...state, remark: e.target.value });
                          }}
                          
                        />
                      </Form.Group>
                    </div>
                    </div>

                    <div className="row m-0 p-0">
                    <div className="col-2 m-0 p-0 ml-3">
                      <Form.Group className="mt-1 p-0 m-0">
                        <Button
                          onClick={validateForm}
                          className="rounded-50 btn btn-primary btn-lg btn-block"
                        >
                          {" "}
                          Submit
                        </Button>
                      </Form.Group>
                    </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default CreateLoadRequest;
