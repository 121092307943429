import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Card, Accordion, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import swal from "sweetalert";
import { useLocation } from "react-router";
import { PRINT_ICON_PATH, BBPS_LOGO_PATH } from "../../utils/Assets";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { bbpsBillFailedPopup, bbpsBillSuccessPopup } from "../../utils/Models";

import Cable from "../../../assets/svg/cable.svg";
import Electricity from "../../../assets/svg/electricity.svg";
import Dth from "../../../assets/svg/dth.svg";
import PrepaidRecharge from "../../../assets/svg/prepaid_recharge.svg";
import WaterBill from "../../../assets/svg/water_bill.svg";
import PipedGas from "../../../assets/svg/piped_gas.svg";
import Broadband from "../../../assets/svg/broadband.svg";
import BusTicketIcon from "../../../assets/svg/bus_ticket.svg";
import EducationIcon from "../../../assets/svg/education.svg";
import FastagIcon from "../../../assets/svg/fastag.svg";
import FlightTicketIcon from "../../../assets/svg/flight_ticket.svg";
import GasCylinderIcon from "../../../assets/svg/gas_cylinder.svg";
import HospitalIcon from "../../../assets/svg/hospital.svg";
import HotelIcon from "../../../assets/svg/hotel.svg";
import HousingSocietyIcon from "../../../assets/svg/housing_society.svg";
import InsuranceIcon from "../../../assets/svg/insurance.svg";
import LandlineIcon from "../../../assets/svg/landline.svg";
import LoanIcon from "../../../assets/svg/load_repayment.svg";
import MuncipalTaxIcon from "../../../assets/svg/muncipal_tax.svg";
import TrainTicketIcon from "../../../assets/svg/train_ticket.svg";

// Services icon
import Cable_2 from "../../../assets/svg/cable_2.svg";
import Electricity_2 from "../../../assets/svg/electricity_2.svg";
import Dth_2 from "../../../assets/svg/dth_2.svg";
import PrepaidRecharge_2 from "../../../assets/svg/prepaid_recharge_2.svg";
import WaterBill_2 from "../../../assets/svg/water_bill_2.svg";
import PipedGas_2 from "../../../assets/svg/piped_gas_2.svg";
import Broadband_2 from "../../../assets/svg/broadband_2.svg";
import BusTicketIcon_2 from "../../../assets/svg/bus_ticket_2.svg";
import EducationIcon_2 from "../../../assets/svg/education_2.svg";
import FastagIcon_2 from "../../../assets/svg/fastag_2.svg";
import FlightTicketIcon_2 from "../../../assets/svg/flight_ticket_2.svg";
import GasCylinderIcon_2 from "../../../assets/svg/gas_cylinder_2.svg";
import HospitalIcon_2 from "../../../assets/svg/hospital_2.svg";
import HotelIcon_2 from "../../../assets/svg/hotel_2.svg";
import HousingSocietyIcon_2 from "../../../assets/svg/housing_society_2.svg";
import InsuranceIcon_2 from "../../../assets/svg/insurance_2.svg";
import LandlineIcon_2 from "../../../assets/svg/landline_2.svg";
import LoanIcon_2 from "../../../assets/svg/load_repayment_2.svg";
import MuncipalTaxIcon_2 from "../../../assets/svg/muncipal_tax_2.svg";
import TrainTicketIcon_2 from "../../../assets/svg/train_ticket_2.svg";

import { BillerResponse } from "../../utils/BillerResponse";
import { regexValidation, uniqueID } from "../../utils/utilFuctions";

const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const categoryInfo = [
  {
    categoryId: 1,
    categoryName: "Electricity",
  },
  {
    categoryId: 2,
    categoryName: "Gas",
  },
  {
    categoryId: 3,
    categoryName: "Water",
  },
  {
    categoryId: 4,
    categoryName: "Insurance",
  },
  {
    categoryId: 5,
    categoryName: "Cable TV",
  },
  {
    categoryId: 6,
    categoryName: "Prepaid Recharge",
  },
  {
    categoryId: 7,
    categoryName: "LIC",
    url: "lic",
  },
];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

export const Lic2 = ({ discription }) => {
  const location = useLocation();
  let basicAuth = JSON.parse(sessionStorage.getItem("basic_auth"));
  const [formErrors, setFormErrors] = useState({});
  const [fetchBillModal, setFetchBillModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [Tpin, setTpin] = useState("");
  const [serviceSelected, setServiceSelected] = useState(7);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const {
    token,
    myAccountInfo,
    user,
    setTransactionLoaderState,
    checkWalletBeforeBBPSBillPay,
    Profileinfo,
    refreshWallet,
  } = useContext(GlobalContext);
  const [dashboardInfo, setDashboardInfo] = useState({});
  if (!basicAuth) {
    basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  }
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  // const [providers, setProviders] = useState([]);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [billPayResponse, setBillPayResponse] = useState(null);
  const [PayClicked, setPayClicked] = useState(false);
  const [CustP, setCustP] = useState([]);
  const [billerResponse, setBillerResponse] = useState({
    msg: "",
    success: "",
    error: [],
  });
  const [providers, setProviders] = useState([]);
  const [allProviders, setAllProviders] = useState([]);
  const [billFetched, setBillFetched] = useState(false);
  const [tpinShow, setTpinShow] = useState(false);
  const [consumerNo, setConsumerNo] = useState("");
  const [offlineBillDetails, setOfflineBillDetails] = useState(null);
  // get all operators
  const getAllProviders = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLDeudbzWuuuGKwhiSsa75bUtX_NqwCaj_v4o_hj4oq9t2cr4CA5MVcuNkvX4mpyg54H4S0w3SoSQAIpD0xsTsF30dEOh3UNorm1OTqZUAALf9maVz0uehddtUJFuzniN9qu9RJuKC_2AT8zdbdrxjTpr0Wl2TTwOjqhJhhfJEYxexBisigKKzH8JQ5jYsat6DqofW5DCOS3jgKAPc6Xlz9cxt7gv1QzfkjONGG97fMJy-roetHLJC3ZHeckzq12FZoG7biAgSxL-crQOu056sRyI57fSPtPMdQxjZx-oPpocqKE69utlorNhTrVLULgbWq8pxcBXqkxm8CjGqX9G9Ml7EKXJ9dEE_nRZsufrhgWIXimbxjgHXVP6joIZJSEi1jCpb_EWDzEV8MOsbza5HNgc2A5wAHakm6BPCT3QqFit3KF0MP35HbuehToEJW0DVrXrUyz9ZsbS4ndq96ncud0pxfTHoFBFJaUAWl5ylrRKmZ1La38FafnjGBPtNcJqNjgT2xmEJXbobUIL60eZzmgWL8H2f6yllunvIO0n30EJyFVOELJojx0WQGrYWFfc8lXzzreQuz9XZwLAukacbhZ-ciW3i2rSx1H9yGQkHHnQvoxX18K4DwJCikRGJq-BSZBFzSYj0pICNQot2rj1qTT"
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Operator/GetAllOfflineOperators`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data) {
        setAllProviders(data.data);
        setProviders(
          data.data
            .filter((pro) => {
              return pro.category_id == serviceSelected;
            })
            .map((pro) => {
              return { ...pro, value: pro.biller_name, label: pro.biller_name };
            })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify(data));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const validate = (callback) => {
    let errors = {};
    if (state.provider == "") {
      errors.provider = "please select valid provider";
    }
    if (consumerNo.toString().trim() == "") {
      errors.consumer = "please enter valid consumer number";
    }
    if (Object.keys(errors).length === 0) {
      callback();
    } else {
      setFormErrors(errors);
    }
  };
  const payBillClickHandler = () => {
    validate(pay);
  };
  const fetchBillClickHandler = () => {
    validate(fetchBill);
  };

  const pay = () => {
    setPayClicked(true);
    checkWalletBeforeBBPSBillPay(
      amount,
      () => {
        payBill();
      },
      () => {
        setPayClicked(false);
      }
    );
  };

  const payBill = async () => {
    setPayClicked(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLBfoOkVkY05kLzokPEjB2gYPZGX2IFI8p3H0PEzF-p7wNZjiZfnXRdvZdIMIyaC1lYuwdjzFmdperUM0s9TM94lKMbwmIV0Pb59n7l7GoS9_79dTq5PWPZ0y3VdPKnyVo03tMA9YcwFLWgiuSXqENgj4_89begK4a8D2ZtoerNgjvoUB42YQrPaqK03_hQ_bQ5gokfp-WDObRNe2E6tchKD-li8J2lJqv_mk2nggZqHG3Ifugxw76p8N8_zupiGMhMnAbh4f96POibRScoZR7TL21UYKn9whd8OfiXzt9qT0HP2wraqsSCBvhjGeeocfJXi7LJ-AQGVnlLK2F15MNC5DxCRMnYP85B6qX3UoJ0IxXTcwJarMuClTLT0C_cYOkLDz7NF-DcFx4FtokyCvN6Jz4ZvTjOjnB9Coz75_GGjwHJNP3Un0RS87YFiDCssQYLLc2_rhS0BcGQz0Ex-oqnYRflsTI0guSTVj65oieVS1U9nE1RovsN6rUECWw4OMao1iRrVEgg77JZsYWRnGSuPXG2s4H8W5LvTjKoCA71m0wTV10FRhRHRa4-jrxHjv7nU9bzfvHNalmofvZgmIZuMzU2fTr35aYeg9E2ReWTIg6iq60FYeFZCnf3ce_N_MAWxHo11U0OWyt3uy9HEdHU0"
    );

    var raw = JSON.stringify({
      consumer_no: consumerNo,
      amount: amount,
      operatorcode: state.provider.code,
      ebill_type: state.provider.biller_type,
      unique_id: uniqueID(),
      mobile_no: Profileinfo.mobile,
      retailer_id: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillPayOffline`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data.status == "00") {
        bbpsBillSuccessPopup("", amount, data.responseMSG);
      } else {
        bbpsBillFailedPopup(
          "",
          amount,
          data.responseMSG ? data.responseMSG : "Failed"
        );
      }
      setPayClicked(false);
      refreshWallet();
    } catch (error) {
      bbpsBillFailedPopup("", amount, "Failed");
      setPayClicked(false);
      refreshWallet();
    }
  };
  console.log(billDetails);

  const fetchBill = async () => {
    setBillDetails(null);
    setFetchButtonText("Fetching bill ...");
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLC_1TlMl8CCKRbhja1vIHH4qb3lf5EJ9TYJYhxx9HzZwFh8bwvqTLT20kiANNSX2zPiCnoTbIVw4U_pJSRPTxokNgWrAfkySgA3FaA3pgiWpduBH4o_kwc1Ktx9_wgcDUYFrpoVVhpoVpoVuyu9bRnURZhZqKeh04eSZwdlFY3_J7eQNvNOp0VxxWl3Q3h35GyhlIFm9F72tKO7s6lbzxFCvvBQC8rSlDsw3vBnPGY02Kssv2VQC0FO3cUd6WqqlkOHUqh4IfXcTGg_rfpcAw5jY_YIIcObc3RnuUUBi8r0zV6fj7KoCU4dseTw1Cx5m4mFCK_ijZ9TJ8JtXyJ1WRZh2cgCU2anaqrzfqDOaFpn6UCWgNdJCwWlRIEzlQ774k9jKrOEMw3Ij1Kn8K8-5T0XyUtT2WP4JZuispQWCB9rKtMaWaZK_qm5MBpGpzYIUNyOoZRu53wb400pM42MEWKVPgImHYmZkYWx5yOw5umC_6c0f-Rjmlm_3sS9gjsRukecC_s5yTIEk1yoInGKpWqMdlwKBchE_cyT11mEAJBE0NFVflKKOzEXcnBDaWNsRd2MvQyZwOmsTm5S0dFXPltD4_L_WmChB1S3V-FYVW3EQ0fhYMVGlvpCTZIk0xUDecc_H-GlXCVCOSOf-gyOkt3a"
      );

      var raw = JSON.stringify({
        consumer_no: consumerNo,
        operatorcode: state.provider.code,
        ebill_type: state.provider.biller_type,
        unique_id: uniqueID(),
        mobile_no: Profileinfo.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillFetchOffline`,
        requestOptions
      );
      const data = await res.json();
      setOfflineBillDetails(data);

      if (Array.isArray(data.rdata)) {
        if (data.rdata && data.rdata.length > 0) {
          const rdata = data.rdata[0];
          if (rdata.customerName && rdata.customerName != "") {
            const details = {
              refId: "",
              billerResponse: {
                customerName: rdata.customerName,
                billNumber: "",
                amount: rdata.billamount ? rdata.billamount : rdata.netamount,
                billDate: rdata.billdate,
                dueDate: rdata.duedate,
                desc: rdata.desc,
              },
            };
            setAmount(rdata.billamount ? rdata.billamount : rdata.netamount);
            setBillDetails(details);
            setFetchBillModal(true);
          } else {
            setBillerResponse({
              ...billerResponse,
              success: "",
              error: rdata.desc,
              msg: rdata.desc,
            });
          }
        } else {
          setBillerResponse({
            ...billerResponse,
            success: "",
            error: "Something went wrong",
            msg: "Something went wrong",
          });
        }
        setTransactionLoaderState(false);
      } else {
        if (data.rdata) {
          //  const rdata = data.rdata[0];
          const rdata = data.rdata;
          const details = {
            refId: "",
            billerResponse: {
              customerName: rdata.customerName,
              billNumber: "",
              amount: rdata.billamount,
              billDate: rdata.billdate,
              dueDate: rdata.duedate,
            },
          };
          setAmount(rdata.billamount);
          setBillDetails(details);
          setFetchBillModal(true);
        }
        setTransactionLoaderState(false);
      }

      setFetchButtonText("Fetch bill");
    } catch (error) {
      setFetchButtonText("Fetch bill");
      console.log("error", error);
    }
  };

  useEffect(() => {
    setState({ ...state, provider: "" });
    setBillDetails(null);
    setCustP([]);
    getAllProviders();
    setBillPayResponse(null);
    setConsumerNo("");
    setProviders(
      allProviders
        .filter((pro) => {
          return pro.category_id == serviceSelected;
        })
        .map((pro) => {
          return { ...pro, value: pro.biller_name, label: pro.biller_name };
        })
    );
  }, [serviceSelected]);

  return (
    <div className="bg-white-theme">
      <FetchBill
        fetchBillModal={fetchBillModal}
        setFetchBillModal={setFetchBillModal}
        billDetails={billDetails}
        billerResponse={billerResponse}
        callback={() => {
          payBillClickHandler();
        }}
        billPayResponse={billPayResponse}
        setAmount={setAmount}
        amount={amount}
        setTpin={setTpin}
        Tpin={Tpin}
      />
      <div
        style={{
          background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
          borderRadius: "1rem",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="m-3 rouded-3 p-4"
      >
        <div className="row">
          <h3 className="text-dark">Lic</h3>
        </div>
        <div
          className="row rouded-3 p-4 mx-0 my-2"
          style={{ background: "#FFFFFF", borderRadius: "1rem" }}
        >
          <Form.Group className="col-6 p-1 m-0 px-0">
            <Form.Label>{`Select ${categoryInfo[serviceSelected - 1].categoryName
              } Provider`}</Form.Label>
            <Select
              options={providers}
              placeholder={`Select Provider`}
              onChange={(e) => {
                setState({
                  ...state,
                  provider: e,
                });
              }}
              value={state.provider}
            />
            <p style={{ color: "red" }} className="">
              {formErrors.provider}
            </p>
          </Form.Group>

          {serviceSelected == 6 ? (
            <Form.Group className="col-6 p-1 m-0 px-0">
              <Form.Label>
                <span style={{ color: "red" }}>*</span> {`Mobile number`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Mobile Number"
                onChange={(e) => {
                  setConsumerNo(e.target.value);
                }}
                value={consumerNo}
              />
              <p style={{ color: "red" }} className="">
                {formErrors.consumer}
              </p>
            </Form.Group>
          ) : (
            <Form.Group className="col-6 p-1 m-0 px-0">
              <Form.Label>
                <span style={{ color: "red" }}>*</span> {`consumer number`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Consumer Number"
                onChange={(e) => {
                  setConsumerNo(e.target.value);
                }}
                value={consumerNo}
              />
              <p style={{ color: "red" }} className="">
                {formErrors.consumer}
              </p>
            </Form.Group>
          )}
          {serviceSelected == 6 && (
            <Form.Group className="col-6 p-1 m-0 px-0">
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span> {`Amount`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Amount"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                value={amount}
              />
              <p style={{ color: "red" }} className="">
                {formErrors.consumer}
              </p>
            </Form.Group>
          )}
          <div className="col-6 p-1 m-0 px-0 mt-3">
            {billerResponse.error != "" && (
              <BillerResponse msg={billerResponse.msg} />
            )}
          </div>
          {serviceSelected == 6 ? (
            <div className="col-6 p-1 m-0 px-0 mt-3">
              <Button
                className="rounded-0"
                style={{ margin: "0px 10px", minWidth: "11rem" }}
                variant="primary"
                onClick={payBillClickHandler}
              >
                Recharge
              </Button>
            </div>
          ) : (
            <div className="col-6 p-1 m-0 px-0 mt-3">
              <Button
                className="rounded-0"
                style={{ margin: "0px 10px", minWidth: "11rem" }}
                variant="primary"
                onClick={fetchBillClickHandler}
                disabled={fetchButtonDisable}
              >
                {fetchButtonText}
              </Button>
            </div>
          )}
        </div>
      </div>

      {discription && (
        <div
          style={{
            background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
            borderRadius: "1rem",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
          className="m-3 rouded-3 p-4"
        >
          <div className="row">
            <div className="col-12 col-md-7">
              <h4>Bharat Bill Payment System &nbsp;( &nbsp;BBPS &nbsp;)</h4>
              <span>
                It is an Integrated online platform which is being developed by
                the National Payment corporation of India for all kind of Bill
                Payments
              </span>
              <div className="row">
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(5);
                  }}
                  image={Electricity_2}
                  title={"Electricity"}
                  selected={serviceSelected == 5}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(16);
                  }}
                  image={PrepaidRecharge_2}
                  title={"Prepaid Recharge"}
                  selected={serviceSelected == 16}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(2);
                  }}
                  image={Dth_2}
                  title={"DTH"}
                  selected={serviceSelected == 2}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(3);
                  }}
                  image={Cable_2}
                  title={"Cable Recharge"}
                  selected={serviceSelected == 3}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(20);
                  }}
                  image={WaterBill_2}
                  title={"Water Bill"}
                  selected={serviceSelected == 20}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(7);
                  }}
                  image={PipedGas_2}
                  title={"Piped Gas"}
                  selected={serviceSelected == 7}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(1);
                  }}
                  image={Broadband_2}
                  title={"Broadband"}
                  selected={serviceSelected == 1}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(4);
                  }}
                  image={EducationIcon_2}
                  title={"Education"}
                  selected={serviceSelected == 4}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(6);
                  }}
                  image={FastagIcon_2}
                  title={"Fastag Recharge"}
                  selected={serviceSelected == 6}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(20);
                  }}
                  image={GasCylinderIcon_2}
                  title={"Gas Cylinder"}
                  selected={serviceSelected == 20}
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <img
                src={require("../../../assets/images/dashboard/Group126@2x.png")}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BBPSIconCard = ({ image, title, selected, onClick }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
      onClick={onClick}
    >
      <div
        style={{
          borderRadius: "3rem",
          border: selected ? "2px solid #3C79F5" : "2px solid #FFF",
          background: "#FFF",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{ height: "2rem", width: "2rem", color: "#EC5665" }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
          fontWeight: selected ? "700" : "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

const BBPSServiceCard = ({ image, title }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
    >
      <div
        style={{
          borderRadius: "3rem",
          background: "#C42F47",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{
            height: "2rem",
            width: "2rem",
            color: "#EC5665",
            fill: "#FFF",
            stroke: "#FFF",
          }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

function FetchBill({
  setFetchBillModal,
  fetchBillModal,
  payBill,
  billDetails,
  billerResponse,
  callback,
  billPayResponse,
  setAmount,
  amount,
  setTpin,
  Tpin,
}) {
  const [tpinShow, setTpinShow] = useState(false);
  const [PayClicked, setPayClicked] = useState(false);
  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify(data));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  return (
    <div>
      <Modal
        show={fetchBillModal}
        onHide={() => setFetchBillModal(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Bill Details</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ width: "100%", display: "block" }}
          className="account m-0 p-0"
        >
          {billDetails && !billPayResponse && (
            <Card style={{ margin: "10px", padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={require("../../../assets/images/BBPSSidebarLogo.png")}
                  style={{
                    width: "auto",
                    height: "1.2rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <img
                  onClick={() => {
                    openInNewTab(billDetails);
                  }}
                  style={{ height: "1.5rem" }}
                  src={require("../../../assets/icons/print_1.png")}
                />
              </div>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {amount}
                  </td>
                </tr>

                {/* Additional Info */}
                {billDetails.additionalInfo &&
                  billDetails.additionalInfo.length > 0 &&
                  billDetails.additionalInfo.map((info) => (
                    <tr style={{ background: "none" }}>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.name}
                      </td>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.value}
                      </td>
                    </tr>
                  ))}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill date
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billDate}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Due Date.
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.dueDate}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.refId}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter Amount`}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    T Pin
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <div className="input-group">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent border-right-0 no-glow">
                          <i className="mdi mdi-lock-outline text-primary"></i>
                        </span>
                      </div>
                      <input
                        autocomplete="new-password"
                        autoCorrect="off"
                        autoSave="new-password"
                        type={tpinShow ? "text" : "password"}
                        className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                        id="exampleInputPassword"
                        placeholder={`Enter T Pin`}
                        onChange={(e) => {
                          setTpin(e.target.value);
                        }}
                        value={Tpin}
                      />
                      <div className="input-group-prepend bg-transparent">
                        <span
                          className="input-group-text bg-transparent border-left-0 no-glow"
                          onClick={showTPinClick}
                          style={{ borderRadius: "4px" }}
                        >
                          <i
                            className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                              }`}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {PayClicked ? (
                  <Button className="rounded-0" variant="success">
                    {" "}
                    Paying ...
                  </Button>
                ) : (
                  <Button
                    className="rounded-0"
                    variant="success"
                    onClick={callback}
                  >
                    {" "}
                    Pay Bill
                  </Button>
                )}
              </div>
            </Card>
          )}
          {billDetails && billPayResponse && (
            <Card style={{ margin: "10px", padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={require("../../../assets/images/BBPSSidebarLogo.png")}
                  style={{
                    width: "auto",
                    height: "1.2rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <img
                  onClick={() => {
                    openInNewTab(billDetails);
                  }}
                  style={{ height: "1.5rem" }}
                  src={require("../../../assets/icons/print_1.png")}
                />
              </div>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>

                {billDetails.billerResponse.billNumber && (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      Bill Number
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      {billDetails.billerResponse.billNumber}
                    </td>
                  </tr>
                )}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {amount}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billPayResponse.refId}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    Transaction Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billPayResponse.txnReferenceId}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Status
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <span className="badge badge-pill badge-success p-3">
                      {billPayResponse.responseReason}
                    </span>
                  </td>
                </tr>
              </table>
            </Card>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Lic2;
