import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { basicAuth } from "../../config";
import Select from "react-select";
import cogoToast from "cogo-toast";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { OFFLINE_TEXT, ONLINE_TEXT } from "../../utils/StringConstants";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const DTHSidebar = ({ details }) => {
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  const [providers, setProviders] = useState([]);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [PayClicked, setPayClicked] = useState(false);
  const [CustP, setCustP] = useState([]);
  const [Amount, setAmount] = useState("");

  console.log(billDetails);

  const payBill = async () => {
    setPayClicked(true);
    const biller = state.provider;
    if (biller.length == 0) {
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq7E_u804ASEqPvM_yW_G_4eXqt18Wn9I-n8I3WtQtS1k63fuzYkZ3II2cGaUfAh06-OButEn8YXvZ_OKTOUg7gP0eyR30BQ_8eI347G7RyBVkL94bbcqn4rlloSevdhRK_itdZf7Cy1pikoJc95VBZtpNVRo8GfCCremQfVWnDGdC-uhQO4_iWArZNQ52pD6yFCxKrKQMZoDpLPBgU_RqwND6lpbx4PqGA89-SxvMRcqNeUppHj8jYHVcp1CnK1n1ncVhEtsR0luOqY1IHgz_J-pRI-lUsGJUDsgFNCww0eb7Mlk8LWwiu4qgYXpPYLlBZf0K7b2mTMgNpH25rMS4-VahYcpxG4zXe1vKHCEHeR7MjVXiAwNh_mdR2GXlgtM9FZ3bOSHjwCYuB6J6uzOd4gNgQcUWzkM1TJ_Fcq04jq6pdB2Ne-aN8XkGNAxnrVQ4SPVwVh-NkRSZYXpdS0XG2r658waXxYLVif7Sw40A-M3V1tLMqWwNSu2rMg5NFhDevaKKiJXcuqHXdrjRSOWLNgkPHS5lmjY11bV0QenW9H7Ahv3xbre32MmYrHlkX7HxKXtlS-uTURS1gQT0_bwPBt3webYhgPJMO8wuIP1HCTlsD98IkmuLeiQ5sSuaylpGJuo22sseXh04qbA3aj_8uo"
    );

    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });

    var raw = JSON.stringify({
      amount: Amount,
      mobileNo: "8700601581",
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
      refId: "",
      isDirectPay: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillPay`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      setPayClicked(false);
      cogoToast.info(data.status);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log(billDetails);

  useEffect(() => {
    if (state.provider) {
      setCustP(
        state.provider.customerParams.map((param, i) => {
          return { ...param, id: i, value: "" };
        })
      );
    }
    // console.log(CustP);
  }, [state]);

  useEffect(() => {
    setProviders(
      details.operators.map((pro) => {
        return { ...pro, value: pro.billerName, label: pro.billerName };
      })
    );
  }, [details]);
  useEffect(() => {
    document
      .querySelector(".content-wrapper")
      .classList.remove("sidebar-icon-only");
  }, []);
  console.log(state);
  return (
    <nav
      className="side-sheet sidebar Sidebar23"
      id="sidebar2"
      style={Sidebar22}
    >
      <div
        style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
        className="row"
      >
        <div
          style={{ padding: "6px", display: "flex", alignItems: "center" }}
          className="col"
        >
          <strong style={{ color: "#1b68ca" }}>{"DTH Bill Payment"}</strong>
        </div>
        <div style={{ paddingRight: "0px" }} className="col">
          <span
            style={{
              fontSize: "2rem",
              display: "flex",
              color: "#b2b2b3",
              justifyContent: "end",
              ariaHidden: "true",
              paddingRight: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only");
            }}
          >
            &times;
          </span>
        </div>
      </div>
      <Dropdown.Divider />

      <Card style={{ margin: "10px", padding: "10px" }}>
        <Form.Group style={{ flex: "1" }}>
          <Select
            placeholder={`Select Provider`}
            options={providers}
            onChange={(e) => {
              setState({
                ...state,
                provider: e,
              });
            }}
          />
        </Form.Group>

        {CustP.map((cust, i) => (
          <Form.Group key={cust.id} style={{ flex: "1" }}>
            {cust.optional == "true" ? (
              <Form.Label>{`${cust.paramName} (optional)`}</Form.Label>
            ) : (
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {` ${cust.paramName}`}
              </Form.Label>
            )}

            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter ${cust.paramName}`}
              onChange={(e) => {
                const copy = [...CustP];
                copy.forEach((ele) => {
                  if (ele.id == cust.id) {
                    ele.value = e.target.value;
                  }
                });
                setCustP(copy);
              }}
              value={cust.value}
            />
          </Form.Group>
        ))}
        <Form.Group style={{ flex: "1" }}>
          <Form.Label>{`Amount`}</Form.Label>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter Amount"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </Form.Group>
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {PayClicked ? (
            <Button className="rounded-0" variant="success">
              {" "}
              Paying ...
            </Button>
          ) : (
            <Button className="rounded-0" variant="success" onClick={payBill}>
              {" "}
              Pay Bill
            </Button>
          )}
        </div>
      </Card>
    </nav>
  );
};

export default withRouter(DTHSidebar);
