import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import DashSidebar from "../dashboard/DashSidebar";
import { writeFile, utils } from "xlsx";
import { Redirect, useHistory } from "react-router";
import { BASE_URL_AGENT, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import { Link } from "react-router-dom";
import { offsetArr } from "../../utils/CommonConfig";
import swal from "sweetalert";
import Select from "react-select";
import { useQuery } from "../../custom-hooks/useQuery";

export const DistributorView = () => {
  const { user } = useContext(GlobalContext);
  const history = useHistory();

  const InitialValues = { UserName: "" };
  const [assignTariffModal, setAssignTariffModal] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [Shop, setShop] = useState([]);
  const [DistView, setDistView] = useState([]);
  const [dataToSend, setDataToSend] = useState({});
  const [ShopObj, setShopObj] = useState([]);
  const [DistributorID, setDistributorID] = useState("");
  const [Mobile, setMobile] = useState("");
  const [details, setDetails] = useState({});

    // Search Params
  const query = useQuery();
  const DistributorId = query.get("id");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      GetDistributorView();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.UserName) {
      errors.UserName = "*Shop name is required!";
    }
    return errors;
  };

  const Reset = () => {
    setFormValues(InitialValues);
    setFormErrors({});
  };

  const GetShopname = () => {
    var raw = JSON.stringify({
      UserID: DistributorId,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/get_shopname_list.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) setShop(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    GetShopname();
  }, []);

  // const FilterData = () => {
  //   if (formValues.Mobile != "") {
  //     const filteredData = dataToDisplay.filter((Data) => Data.us_loginid.includes(formValues.Mobile))
  //     setDataToDisplay(filteredData)
  //     console.log(filteredData);
  //   } else if (formValues.DistributorID != "") {
  //     const filteredData = dataToDisplay.filter((Data) => Data.us_code.toLowerCase().includes(formValues.DistributorID.toLowerCase()))
  //     setDataToDisplay(filteredData);
  //     console.log(filteredData);
  //   }
  //   else {
  //     setDataToDisplay(DistView)
  //   }
  // }

  // useEffect(() => {
  //   FilterData()
  // }, [Mobile, DistributorID])

  const GetDistributorView = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: DistributorId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_get_provider_list.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setData(result.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    GetDistributorView();
  }, []);

  const [type, setType] = useState("KYC");
  console.log(localStorage.getItem("accessToken"));

  const ExportToExcel = (fileName, DistView) => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(DistView);
    utils.book_append_sheet(wb, ws, "sheet");
    writeFile(wb, `${fileName}.xlsx`);
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    for (let key in DistView[0]) {
      console.table(key, DistView[0][key]);
    }
  }, [DistView]);

  const redirect = () => {
    history.push("/InsidePages/DistributorReportByid");
  };

  const ShopWithId = () => {
    const getShopsObject = DistView.filter((data) => {
      return formValues.UserName == data.pk_user_id;
    });
    setShopObj(getShopsObject);
    console.log(getShopsObject);
  };

  useEffect(() => {
    ShopWithId();
  }, [formValues.UserName]);

  useEffect(() => {
    setDistributorID(ShopObj.length > 0 ? ShopObj[0].us_code : "");
    setMobile(ShopObj.length > 0 ? ShopObj[0].us_loginid : "");
    console.log(ShopObj.length > 0 ? ShopObj[0].us_code : "");
  }, [ShopObj]);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <DashSidebar details={dataToSend} />
            </div>
            <AssignTariffPlanModal
              details={{ ...details, userId: user.ReferId }}
              setModalOpen={setAssignTariffModal}
              isModalOpen={assignTariffModal}
              refresh={() => {}}
            />
            <div style={{ backgroundColor: COLOR_BACKGROUND_BODY }}>
              <div className="row ">
                <div className="col-12 grid-margin">
                  <div
                    className="card p-1 mt-4"
                    style={{ borderRadius: "22px" }}
                  >
                    <h4 className="ml-2"> View Distributor</h4>
                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flex: "1" }}>
                          <div className="row ">
                            <Form.Group
                              style={{ maxWidth: "12rem", margin: "10px 0px" }}
                              className="ml-2"
                            >
                              <Form.Label style={{ marginLeft: "5px" }}>
                                {" "}
                                Search in Table
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="form-control rounded-0"
                                aria-label="Notes"
                                aria-describedby="basic-addon1"
                                placeholder="Search"
                                onChange={(e) => {
                                  setSearchText(e.target.value);
                                }}
                                value={searchText}
                              />
                            </Form.Group>
                            <Form.Group
                              style={{ marginLeft: "15px", marginTop: "34px" }}
                            >
                              <Button
                                variant="success"
                                style={{ borderRadius: "0px" }}
                              >
                                Search
                              </Button>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table
                          style={{ width: "100%" }}
                          className="dashboard-table-style"
                        >
                          <tr>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              {" "}
                              S No.
                            </th>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              {" "}
                              Shop Name{" "}
                            </th>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              {" "}
                              User ID{" "}
                            </th>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              {" "}
                              Mobile{" "}
                            </th>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              {" "}
                              Balance{" "}
                            </th>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              {" "}
                              Last Bal Update{" "}
                            </th>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              KYC Status
                            </th>
                            <th
                              style={{ fontSize: "0.8rem" }}
                              className="text-center"
                            >
                              {" "}
                              Action{" "}
                            </th>
                          </tr>

                          {dataToDisplay?.map((user, i) => (
                            <tr>
                              <td
                                style={{ fontSize: "0.8rem" }}
                                className="text-center"
                              >
                                {Number(i) + 1}
                              </td>
                              <td
                                style={{ fontSize: "0.8rem" }}
                                className="text-center"
                              >
                                {/* {user.img && (
                                  <img
                                    style={{ height: "1.5rem" }}
                                    src={`${BASE_URL_AGENT}${user.img.substring(
                                      2
                                    )}`}
                                    alt="face"
                                  />
                                )}  */}
                                <Link
                                  to={`/master-distributor/retailer_report?id=${user.us_code}`}
                                >
                                  {user.us_shop_name}
                                </Link>
                              </td>
                              <td
                                style={{
                                  cursor: "pointer",
                                  fontSize: "0.8rem",
                                }}
                                onClick={redirect}
                                className="text-center"
                              >
                                {" "}
                                {user.us_code}{" "}
                              </td>
                              <td
                                style={{ fontSize: "0.8rem" }}
                                className="text-center"
                              >
                                {" "}
                                {user.us_loginid}{" "}
                              </td>
                              <td
                                style={{ fontSize: "0.8rem" }}
                                className="text-center"
                              >
                                {" "}
                                {user.userbalance}{" "}
                              </td>
                              <td
                                style={{ fontSize: "0.8rem" }}
                                className="text-center"
                              >
                                {" "}
                                {user.balanceupdate}{" "}
                              </td>

                              <td
                                style={{ color: "#4a89dc", fontSize: "0.8rem" }}
                              >
                                <div className="d-flex flex-column align-items-center">
                                  {user.kycstatus == "Approved" ? (
                                    <span className="badge bg-success text-light btn-block p-2">
                                      {`${user.kycstatus}`}
                                    </span>
                                  ) : user.kycstatus == "Rejected" ||
                                    user.kycstatus == "Reject" ? (
                                    <span className="badge bg-danger text-light btn-block p-2">
                                      {`${user.kycstatus}`}
                                    </span>
                                  ) : user.kycstatus == "Re Submitted" ||
                                    user.kycstatus == "Re Submitted" ? (
                                    <span className="badge bg-info text-light btn-block p-2">
                                      {`${user.kycstatus}`}
                                    </span>
                                  ) : (
                                    <span className="badge bg-warning text-light btn-block p-2">
                                      {`${user.kycstatus}`}
                                    </span>
                                  )}
                                </div>
                              </td>

                              <td
                                style={{ fontSize: "0.8rem" }}
                                className="text-center"
                                s
                              >
                                <div className="badge">
                                  <Button
                                    type="button"
                                    className="btn btn-sm"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "kyc",
                                        JSON.stringify(user)
                                      );
                                      history.push("/kyc/bussiness-details");
                                    }}
                                    variant="info"
                                    style={{ borderRadius: "0px" }}
                                    disabled={
                                      user.kycstatus.toString() == "Approved"
                                    }
                                  >
                                    KYC
                                  </Button>
                                  <span> </span>
                                  <Button
                                    type="button"
                                    className="btn btn-sm"
                                    onClick={() => {
                                      setDataToSend({ user, type: "ADD" });
                                      document
                                        .querySelector(".content-wrapper")
                                        .classList.toggle("sidebar-icon-only");
                                    }}
                                    variant="success"
                                    style={{ borderRadius: "0px" }}
                                  >
                                    Add
                                  </Button>
                                  <span> </span>
                                  <Button
                                    type="button"
                                    className="btn btn-sm"
                                    value="REVERSE"
                                    onClick={() => {
                                      setDataToSend({
                                        user,
                                        type: "REVERSE",
                                      });
                                      document
                                        .querySelector(".content-wrapper")
                                        .classList.toggle("sidebar-icon-only");
                                    }}
                                    variant="primary"
                                    style={{ borderRadius: "0px" }}
                                  >
                                    Reverse
                                  </Button>
                                  <span> </span>
                                  <Button
                                    type="button"
                                    className="btn btn-sm"
                                    value="REVERSE"
                                    onClick={() => {
                                      setDetails({
                                        ...details,
                                        userId: user.us_code,
                                      });
                                      setAssignTariffModal(true);
                                    }}
                                    variant="success"
                                    style={{ borderRadius: "0px" }}
                                  >
                                    Assign Tariff
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </table>
                        <Pagination
                          page={currentPage}
                          limit={pages}
                          callback={(page) => {
                            setCurrentPage(page);
                          }}
                          count={dataToDisplay.length}
                          total={total}
                          callback2={(offsetValue) => {
                            setOffset(offsetValue);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>

      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
    </div>
  );
};

export const AssignTariffPlanModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const [state, setState] = useState({
    userId: "",
    dmt: "",
    aeps: "",
    bbps: "",
  });

  const [dmtTariff, setDmtTariff] = useState([]);
  const [aepsTariff, setAepsTariff] = useState([]);
  const [bbpsTariff, setBbpsTariff] = useState([]);
  const clearForm = () => {
    setState({
      dmt: "",
      aeps: "",
      bbps: "",
      userId: "",
    });
  };

  const getTariffPlans = async () => {
    try {
      var raw = JSON.stringify({
        UserID: details.userId,
      });

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/get_master_tariff_plans.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setDmtTariff(
          data.dmt.map((role) => {
            return {
              label: `${role.name}`,
              value: role.id,
            };
          })
        );
        setAepsTariff(
          data.aeps.map((role) => {
            return {
              label: `${role.name}`,
              value: role.id,
            };
          })
        );

        setBbpsTariff(
          data.bbps.map((role) => {
            return {
              label: `${role.name}`,
              value: role.id,
            };
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const assignTariff = async () => {
    try {
      var raw = JSON.stringify({
        UserID: state.userId,
        DmtTariff: state.dmt.value,
        AepsTariff: state.aeps.value,
        BbpsTariff: state.bbps.value,
      });

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/assign_tariff_master.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        clearForm();
        setModalOpen(false);
      } else {
        swal("Failled", data.msg, "error");
      }
    } catch (error) {
      swal("Failled", "Error From server", "error");
      console.log("error", error);
    }
  };

  const validateForm = () => {
    if (state.dmt == "" || state.aeps == "" || state.bbps == "") {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    assignTariff();
  };
  useEffect(() => {
    getTariffPlans();
  }, []);
  useEffect(() => {
    if (details) {
      setState({ ...state, userId: details.userId });
      getTariffPlans();
    }
  }, [details]);
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="xl"
    >
      <Modal.Body
        style={{ width: "100%", display: "block" }}
        className="account"
      >
        <div
          style={{
            padding: "12px",
            background: "#3676D8",
            color: "#FFF",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong>[{state.userId}] Assign Tariff Plan</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Select Dmt Tariff`}
              </Form.Label>
              <Select
                options={dmtTariff}
                value={state.dmt}
                onChange={(e) => {
                  setState({ ...state, dmt: e });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Select Aeps Tariff`}
              </Form.Label>
              <Select
                options={aepsTariff}
                value={state.aeps}
                onChange={(e) => {
                  setState({ ...state, aeps: e });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Select Bbps Tariff`}
              </Form.Label>
              <Select
                options={bbpsTariff}
                value={state.bbps}
                onChange={(e) => {
                  setState({ ...state, bbps: e });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 mt-3 pt-1">
            <Button
              className="rounded-0"
              variant="success"
              onClick={validateForm}
            >
              Assign Tariff
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DistributorView;
