import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import { useQuery } from "../../custom-hooks/useQuery";

import { RaiseTicketModel9 } from "../util/RaiseTicketModel9";

export const columns = [
  {
    dataField: "id",
    text: "id",
  },
  {
    dataField: "name",
    text: "name",
  },
  {
    dataField: "code",
    text: "code",
  },
  {
    dataField: "status",
    text: "status",
  },
  {
    dataField: "type",
    text: "type",
  },

  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
];

function MasterTariffTable() {
  const query = useQuery();
  let userId = query.get("id") ? query.get("id") : "1";
  const [data, setData] = useState([]);
  const [dmt, setDmt] = useState([]);
  const [aeps, setAeps] = useState([]);
  const [bbps, setBbps] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [raiseTicketModal, setRaiseTicketModal] = useState(false);

  const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const MasterTariff = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      var raw = JSON.stringify({
        UserID: userId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/get_master_tariff_plans.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setDmt(data.dmt);
        setAeps(data.aeps);
        setBbps(data.bbps);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const removeTariff = async (tariff) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: userId,
        TariffId: tariff.id,
        TariffType: tariff.type,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/master_remove_tariff_plan.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        MasterTariff();
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    MasterTariff();
  }, []);

  return (
    <div>
      <RaiseTicketModel9
        details={raiseTicketDetails}
        isModalOpen={raiseTicketModal}
        setModalOpen={(modalState) => {
          setRaiseTicketModal(modalState);
        }}
        refresh={MasterTariff}
      />
      <div className="scroll-inner admin-fix-width">
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6 d-flex justify-content-end">
            <button
              style={{
                backgroundColor: "rgb(247, 147, 39)",
                color: "white",
                border: "none",
                borderRadius: "4px",
                height: "4vh",
                width: "7vw",
              }}
              onClick={() => {
                setRaiseTicketDetails({ userId: userId });
                setRaiseTicketModal(true);
              }}
            >
              Allow Tariff
            </button>
          </div>
        </div>

        <table
          style={{ width: "100%", border: "1px" }}
          className="admin-table-style "
        >
          <tr className="text-center">
            <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>

            <th style={{ fontSize: "0.9rem" }}> {"ID"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Code"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Name"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Status"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Type"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Allow"}</th>
          </tr>
          <h5>DMT</h5>
          {dmt.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.id}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.name}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.code}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.status}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.type}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    removeTariff({ ...e, type: "DMT" });
                  }}
                >
                  Remove Access
                </button>
              </td>
            </tr>
          ))}
          <h5>AEPS</h5>
          {aeps.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.id}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.name}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.code}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.status}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.type}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    removeTariff({ ...e, type: "AEPS" });
                  }}
                >
                  Remove Access
                </button>
              </td>
            </tr>
          ))}
          <h5>BBPS</h5>
          {bbps.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.id}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.name}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.code}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.status}</td>
              <td style={{ fontSize: "0.8rem" }}>{e.type}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    removeTariff({ ...e, type: "BBPS" });
                  }}
                >
                  Remove Access
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
      {/* <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      /> */}
    </div>
  );
}

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const NoDataFound = () => {
  return (
    <tr className="row w-100 justify-content-center">
      <div className="d-flex flex-column align-items-center p-3">
        <img
          src={require("../../../assets/images/no_data_found.png")}
          style={{ height: "5rem", width: "auto" }}
        />
        <strong>No Data Found</strong>
      </div>
    </tr>
  );
};

export default MasterTariffTable;
