import React, { useContext, useState, useEffect } from "react";
import { Col, Form, Button, Modal } from "react-bootstrap";

const Test2 = () => {
   
    const [inputState, setInputState] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", otpSent: false, otp: "", aadhaar_request_id: "", aadhaarVerified: false, custPan: "", aadhaarDetails: "" });
    const [formErrors, setFormErrors] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputState({ ...inputState, [name]: value });
    };

    const aadhaarVerificationSendOtp = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ==");

            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Cookie",
                ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0s7bi6I2oq4bBIN05pcn-22zDbEC2K0B-nviBgFvqOVktA2C3i9oiZosmjfVMRHl6yl4r7gQLI2ZVmyyizy0WMvnTrhuMiYZcm4mq1dFs3gDB12EfgpSmQlELsaMjdVtHd6afBouTY7NsGoGe-5C3qq8ltT0zaCLnxVWjHIaJqSwNpWvun0YiFGI5tQ4hUFCPE6ilxS4u2myz3VyS2eSrGtFcvaPilfGyORm_J7W558pqhOshhR8JXR-3q8miWrt1Ji5twUizGXy0CfYM8UbEY3gPvXkyqHttYp-8X3WxmtrickDNedeBLMSs9xK80SG1HgErLU1plIlnUnmfeuUnNfLOejtKfnSkIQrMnMvKvvz-LrgPPRcf5FQntJNVXzZmZayQ8dHefiQhcfhNBwVc52CfLIzVCRcv0gICybhfOg-mQitlKMh-dXkMlhB7SUfBJX5SAQMcAWfS8DM-LvQjN6NKbXnsdy4vo5Qn0eEESnDlyX_lSEzb2lBdDlGvmFY-J9wrCk_49rT7lq-aweV8CFa6qrEBUO3t6uNY9i4s4s_7uICtutJvtiMJOi24bxNTWB22eVhsuroLrVtIswVKXMA8lRiECbsjG8lcKUk0_LU1FQz7soVehwvZa3j0y8xZ2NJPzMdl04OMGCOCWl-Up"
            );

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apitranxt.paynnow.com/api/Verify/aadharotpsend`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100" && data.result) {

                    setInputState({
                        ...inputState, otpSent: data.result.is_otp_sent,
                        aadhaar_request_id: data.request_id,
                    });
                } else {
                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    // let username = "Rajneesh@example.com", password = "Test@12345";

    const aadhaarVerification = async () => {
        try {
            var myHeaders = new Headers();
             myHeaders.append("Authorization", "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ==");
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                request_id: inputState.aadhaar_request_id,
                otp: inputState.otp,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apitranxt.paynnow.com/api/Verify/aadharotpverify`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, aadhaarVerified: true });
                    updateReviewKycDetails(5, 'Y', JSON.stringify(data.result));

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const updateReviewKycDetails = async (step, verification, details) => {
        try {
            console.log("Submitted");

        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div
            className="row p-2 m-1 w-100"
        >
            <div className="col-12 row justify-content-between align-items-center py-0 px-2 m-0">
                <h5 className="text-center">Verify OTP</h5>


            </div>

            <div className="row w-100">

                <Form.Group className="col-12 py-0 px-1'">
                    <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        {` Enter Aadhaar No`}
                    </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter Aadhaar No"
                        name="aadhaarNo"
                        className="rounded-0"
                        value={inputState.aadhaarNo}
                        onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                    {!inputState.otpSent && (<Button onClick={aadhaarVerificationSendOtp} variant="success" className="btn-sm float-right rounded-0 m-2">
                        Verify
                    </Button>)}

                </Form.Group>
                {(inputState.otpSent && !inputState.aadhaarVerified) && (
                    <Form.Group className="col-12 py-0 px-1'">

                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter OTP`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter OTP"
                            name="otp"
                            className="rounded-0"
                            value={inputState.otp}
                            onChange={handleChange}
                        />
                        <p style={{ color: "red" }}>{formErrors.otp}</p>

                        <Button onClick={aadhaarVerification} variant="success" className="btn-sm float-right rounded-0 m-2">
                            Validate
                        </Button>
                    </Form.Group>
                )}


            </div>


        </div>


    );
};

export default Test2;
