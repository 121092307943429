import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },

  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "rch_mobile",
    text: "Beneficiary",
  },
  {
    dataField: "utnno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const ViewLoanEnquirytListTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user , setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getTicketList = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
        service: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/users/get_all_loan_enquiry_list.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  useEffect(() => {
    getTicketList();
  }, []);

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height:'20%',
   
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };


  return (
    <>
  <div className="row m-0 p-0  py-2 align-items-center">
              <div className="col-4 m-0 p-0">

               <div className="m-0 p-0 pl-3"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                  }}
                >
                  Loan Enquiry List View
                </div>
                </div>

<div className="col-8 m-0 p-0 ">
<div className="row  m-0 p-0 pr-2 justify-content-end">
        
                <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
        
             
        
        
        <div className="pr-1 pl-2 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

       
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
          <Button
              className="rounded-100 btn btn-lg btn-block "
             
              onClick={getTicketList}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
      </div>




</div>

      </div>


      
      <hr className="m-0 p-0"/>     

      <div className="scroll-inner admin-fix-width">
        <table  className="my-table transparent-table text-center border-bottom"
          style={{ width: "100%", overflowX: "auto" ,border: "0px" ,overflow: "auto", }}>
          <tr className="borderless-table text-center border-bottom">
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              {" "}
              {"Sr"}
            </th>

            <th className="my-table transparent-table text-center border-bottom "  style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              {" "}
              {"Loan Enquiry ID"}
            </th>
            <th className="my-table transparent-table text-center border-bottom "  style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              {" "}
              {"User ID"}
            </th>
            <th className="my-table transparent-table text-center border-bottom "  style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              {" "}
              {"Type"}
            </th>
            <th className="my-table transparent-table text-center border-bottom "  style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              {" "}
              {"Enquiry Amount"}
            </th>
            <th className="my-table transparent-table text-center border-bottom "  style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              {" "}
              {"Enquiry Date"}
            </th>
            <th className="my-table transparent-table text-center border-bottom "  style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              {" "}
              {"Remark"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom" >
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {ind + 1}</td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.load_id}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.fk_refer_id}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">{e.load_type}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.load_amount}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.load_indate}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.load_remark}</span>
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ViewLoanEnquirytListTable;
