import React, { Component } from 'react';

class JSONTable extends Component {
    renderTable() {
        const { data } = this.props;

        console.log("data123", data);
        return (
            <div className="scroll-inner admin-fix-width">
                <table className="my-table transparent-table text-center border-bottom " >
                    <tbody>
                        {Object.entries(data).map(([key, value], index) => (
                            <tr className="my-table transparent-table text-center border-bottom " key={index}>
                                <td>{key}</td>
                                <td>{typeof value === 'object' ?
                                    Object.entries(data).map(([key, value], index) => (
                                        <tr className="my-table transparent-table text-center border-bottom "
                                            style={{ fontWeight: "2px" }}
                                            key={index}>
                                            <td>{key}</td>
                                            <td>{JSON.stringify(value)}</td>
                                        </tr>
                                    ))
                                    : value
                                }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderTable()}
            </div>
        );
    }
}

export default JSONTable;