import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import {
    dateFormatter,
    generateHashKey2,
    getLatLong,
} from "../../utils/utilFuctions";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";


const UserActivity = () => {
    // const [pageAccess, setPageAccess] = useState({});
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const { user, setTransactionLoaderState } = useContext(GlobalContext);

    const getAllUserActivityLog = async () => {
        try {
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            let coords = await getLatLong()
            
            var raw = JSON.stringify({
                adminid: "9",
                userid: "",
                fromdate: dateFormatter(new Date()),
                todate: dateFormatter(new Date()),
                device: "WEB",
                latitude: coords[0],
                longitude: coords[1],
            });
            console.log(raw)
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/DMT/YesBank/Main/getAllUserActivityLogs`,
                requestOptions
            );
            const data = await res.json();
           if(data.status=="0" || data.status=="1"){
            setData(data.data)
           }

  
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);


    const searchBarStyle = {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '6px',
        padding: '0px',
        backgroundColor: '#fff',
        width: '35%', // Adjust the width as needed
        height: '20%',

    };

    const positionStyle1 = {
        position: 'absolute',
        top: -38,
        left: 0,
        "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
    };



    useEffect(() => {
        getAllUserActivityLog();
    }, [])


    return (

        <div style={{ padding: "0.5rem" }} className="bg-light">
            <div style={{ borderRadius: "10px" }} className="card  m-0 mt-4 ml-1 mr-1 p-0">
                <div className="card-body m-0 p-2">
                    <div>
                        <div className="px-3 pb-2"
                            style={{
                                "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                , padding: "0px",
                                background: "#FFFFFF",
                                color: "#4267B2",
                                fontWeight: "bolder",
                                fontfamily: "Poppins !impotent",
                                fontSize: "32px",
                            }}
                        >
                            User Activity Log
                        </div>


                        <div >

                            <div style={{ width: "100%" }}>
                                <div style={{ alignItems: "center" }}>
                                    <div style={{ flex: "1" }}></div>
                                </div>
                            </div>
                            <div className=" scroll-inner admin-fix-width borderless-table ">
                                <table
                                    className="my-table transparent-table text-center border-bottom"
                                    style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}   >
                                    <tr className="borderless-table text-center border-bottom">
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            User
                                        </th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            Activity
                                        </th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            IP Address
                                        </th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            latitude
                                        </th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            longitude
                                        </th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            date
                                        </th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            Sales Person
                                        </th>
                                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                            Action
                                        </th>
                                    </tr>
                                    {dataToDisplay.map((e, ind) => (
                                        <tr className="border-bottom">
                                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                {" "}
                                                {ind + 1}
                                            </td>
                                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div>{e.user_code}</div>
                                                <div>{e.user_name}</div>
                                                <div>{e.user_mobile}</div>
                                            </td>
                                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div>{e.activity}</div>
                                            </td>
                                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div>{e.ipaddress}</div>
                                            </td>
                                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div>{e.latitude}</div></td>
                                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div>{e.longitude}</div>
                                            </td>
                                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div>{e.date}</div>
                                            </td>
                                            <td className="text-center  text-bold border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div>{e.sales_code}</div>
                                                <div>{e.sales_name}</div>
                                                <div>{e.sales_mobile}</div></td>
                                            <td className="text-center m-0 p-0 pr-0 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                                <div className="row m-0 p-0 " >
                                                    <Button style={{
                                                        paddingLeft: "3px",
                                                        padding: "5px",
                                                        borderRadius: "5px",
                                                        border: "1px none",
                                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                                        color: 'rgba(255, 164, 27, 1)',
                                                        bordercolor: 'rgba(255, 255, 255, 1)',
                                                    }} className="btn btn-sm  rounded-100 m-0 p-0 px-2">
                                                        {" "}

                                                        <div
                                                            onClick={() => {

                                                                //showResponse(e.ApiResponse)

                                                            }}
                                                            className="hover-container">
                                                            <img
                                                                src={require("../../../assets/images/Bank Response.png")}
                                                                style={{ width: "" }}
                                                            />
                                                            <div className="hover-text bg-dark"><div  >Bank</div><div > Response</div></div>
                                                        </div>


                                                    </Button>

                                                </div>



                                            </td>

                                        </tr>
                                    ))}
                                </table>
                            </div>
                            <Pagination
                                page={currentPage}
                                limit={pages}
                                callback={(page) => {
                                    setCurrentPage(page);
                                }}
                                count={dataToDisplay.length}
                                total={total}
                                callback2={(offsetValue) => {
                                    setOffset(offsetValue);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>




    );
};

export const Pagination = ({
    page,
    limit,
    callback,
    total,
    count,
    callback2,
}) => {
    console.table({ page, limit, callback });
    const selectStyleLeftBorder = {

        borderRadius: '0px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
        borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
        borderRadius: '0px',
        borderLeft: 'none', // Remove right side border
        borderTop: 'none', // Remove top border
        borderBottom: 'none', // Remove bottom border
        "fontFamily": "Poppins",
        'fontsize': '16px',




    };
    const selectStyleBorder = {
        border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
        borderRadius: '8px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
    };
    const selectStyle = {

        border: 'none', // Remove the border
        "fontFamily": "Poppins",
        'fontsize': '16px',

    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",

            }}
        >
            <div style={{ "fontFamily": "Poppins", }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>


            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}

                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">{offset}</option>
                    ))}

                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"

                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}



                <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default UserActivity;