import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import swal from "sweetalert";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";

const apps = [
  { label: "BUMPPY", value: "BUMPPY" },
  { label: "BUMPPY_SALES", value: "BUMPPY_SALES" },
];

const AppVersionChange = () => {
  const initialState = {
    version: "",
    app: { label: "BUMPPY", value: "BUMPPY" },
  };

  const [state, setState] = useState(initialState);
  const [userRoles, setUserRoles] = useState([]);
  const [parentList, setParentList] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const validateForm = () => {
    if (state.version == "") {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    createTariff();
  };

  const clearForm = () => {
    setState(initialState);
  };

  const createTariff = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.pkid,
        version: state.version,
        app: state.app.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/appVersionChange`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        clearForm();
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("Failed", "Error From Server", "error");
    }
  };

  const getUserList = async () => {
    try {
      setTransactionLoaderState(true);
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_services_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserRoles(
          data.data.map((role) => {
            return {
              label: `${role.st_name}`,
              value: role.pk_service_id,
            };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div style={{ padding: "1rem" }}>
      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <div className="card-body">
          <div>
            <div
              style={{
                padding: "12px",
                background: "#3676D8",
                color: "#FFF",
                fontWeight: "bolder",
              }}
            >
              App Version Change
            </div>

            <div>
              <div className="row p-3">
                <span>App Version</span>
              </div>
              <div className="row">
                <div className="col p-0 mr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`App Version`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Tariff Name"
                      value={state.name}
                      onChange={(e) => {
                        setState({ ...state, version: e.target.value });
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>
                <div className="col p-0 mr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Select App`}
                    </Form.Label>
                    <Select
                      options={apps}
                      value={state.app}
                      onChange={(e) => {
                        setState({ ...state, app: e });
                      }}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="">
                <div className="row">
                  <div className="col px-0">
                    <Button
                      className="rounded-0 btn-block"
                      variant="success"
                      onClick={validateForm}
                    >
                      {" "}
                      Change Version
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppVersionChange;
