import React, { useState, useEffect, useContext } from "react";
import "../../api_partner/custom-tabs.css";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import Select from "react-select";

const APITopupAdmin = () => {
  const [agentId, setAgentId] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  return (
    <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>
      <div style={{ background: "#fff" }}>
        {" "}
        <div
          className="row justify-content-start m-0 px-4 py-2"
          style={{ background: "" }}
        >
          <h2
            style={{
              margin: "0",
              fontWeight: "bolder",
            }}
          >
            Vendor List
          </h2>
        </div>
      </div>
      <div>
        <div>
          <div className="card m-0 p-0 pt-3">
            <div className="card-body m-0 p-0">
              <div className="">
                <div
                  defaultActiveKey="profile"
                  className=" tab-header justify-content-start"
                >
                  <div
                    className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => handleTabClick(0)}
                  >
                    Vendor List
                  </div>
                  <div
                    className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => handleTabClick(1)}
                  >
                    Vendor Topup Report
                  </div>
                </div>
                <div className="tab-content">
                  {activeTab === 0 && (
                    <div>
                      <VendoList2 agentId={agentId} />
                    </div>
                  )}
                  {activeTab === 1 && (
                    <div>
                      <VendorTopupReport agentId={agentId} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const VendoList = ({ group_name }) => {
  const accountList = [
    { label: "YES BANK [001463400003400]", value: "001463400003400" },
    { label: "YES BANK [001463400006363]", value: "001463400006363" },
    { label: "IDFC [10191997124]", value: "435111000023" },
  ];
  const { user } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    signerName: "",
    signerEmail: "",
    signerCity: "",
    purpose: "",
    pdfFile: null,
    numPages: null,
    file: null,
  });

  const [state, setState] = useState({
    amount: "",
    topupVia: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
  });

  const [selectedVendor, setSelectedVendor] = useState({});
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const OpenModal = () => {
    setShowModal(!showModal);
  };

  const getVendorAccounts = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_APISERVICES}/api/Admin/getAllVendorAccountsAdmin`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == "0") {
            setData(data?.data.filter((e) => e.group_name === group_name));
          } else {
            swal("Error", "Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const vendorTopup = async (e) => {
    // return;
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        adminid: user.pkid,
        vendorid: selectedVendor.id,
        amount: state.amount,
        topupVia: state.topupVia.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_APISERVICES}/api/Admin/adminVendorTopup`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == "0") {
            // setData(data?.data)
            swal("Success", data.msg, "success");
          } else {
            swal("Error", "Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  };

  const clearForm = () => {
    setFormData({
      signerName: "",
      signerEmail: "",
      signerCity: "",
      purpose: "",
      pdfFile: null,
      numPages: null,
      file: null,
    });
  };

  useEffect(() => {
    getVendorAccounts();
  }, []);

  return (
    <div className="m-0">
      <Modal show={showModal} onHide={OpenModal}>
        <Modal.Header closeButton>
          <Modal.Title> Topup Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="card-body m-0 p-1 px-2">
              <div>
                <div className="row ">
                  <div className="col ">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Vendor Name`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User ID"
                        value={selectedVendor.vendor_name}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                    {errors.UserID && (
                      <div className="text-danger">{errors.UserID}</div>
                    )}
                  </div>

                  <div className="col">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Account Number`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Transaction ID"
                        value={selectedVendor.acc_no}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                    {errors.TransactionID && (
                      <div className="text-danger">{errors.TransactionID}</div>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div className="col ">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Vendor Ifsc`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        maxLength={10}
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Customer Mobile"
                        value={selectedVendor.acc_ifsc}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>{" "}
                    {errors.Mobile && (
                      <div className="text-danger">{errors.Mobile}</div>
                    )}
                  </div>

                  <div className="col">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Topup Amount`}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Amount"
                        value={state.Name}
                        onChange={(e) => {
                          setState({ ...state, amount: e.target.value });
                        }}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>{" "}
                    {errors.Name && (
                      <div className="text-danger">{errors.Name}</div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <Form.Group className="p-0 m-0">
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Topup Via`}
                      </Form.Label>

                      <Select
                        value={state.topupVia}
                        options={accountList}
                        onChange={(e) => {
                          setState({ ...state, topupVia: e });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-3 ">
                    <Button
                      className="rounded-10 btn-block "
                      variant="primary"
                      onClick={vendorTopup}
                    >
                      {" "}
                      Submit
                    </Button>
                  </div>
                  <div className="col-3 ">
                    <Button
                      className="rounded-10 btn-block border border-primary"
                      variant="white"
                      onClick={clearForm}
                    >
                      {" "}
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              vendor_name
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              account
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              ifsc
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              bank
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              Txn Mode
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              Action
            </th>
          </tr>
          {data.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              <td style={{ fontSize: "1rem" }}>
                <div>
                  <div>{e.vendor_name}</div>
                </div>
              </td>
              <td style={{ fontSize: "1rem" }}>
                {" "}
                <div>
                  <div>{e.acc_no}</div>
                </div>
              </td>
              <td style={{ fontSize: "1rem" }}>
                {" "}
                <div>
                  <div>{e.acc_ifsc}</div>
                </div>
              </td>
              <td style={{ fontSize: "1rem" }}>
                {" "}
                <div>
                  <div>{e.bank_name}</div>
                </div>
              </td>
              <td style={{ fontSize: "1rem" }}>{e.txn_mode}</td>
              <td style={{ fontSize: "1rem" }}>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setSelectedVendor(e);
                    OpenModal();
                  }}
                >
                  Topup
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

const VendoList2 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const { user } = useContext(GlobalContext);

  return (
    <div className="">
      <div
        defaultActiveKey="profile"
        className=" tab-header justify-content-start"
      >
        <div
          className={`tab-item ${activeTab === 0 ? "active" : ""}`}
          onClick={() => handleTabClick(0)}
        >
          Api Providers
        </div>
        <div
          className={`tab-item ${activeTab === 1 ? "active" : ""}`}
          onClick={() => handleTabClick(1)}
        >
          Bumppy
        </div>
        <div
          className={`tab-item ${activeTab === 4 ? "active" : ""}`}
          onClick={() => handleTabClick(7)}
        >
          Chaafo
        </div>
        <div
          className={`tab-item ${activeTab === 4 ? "active" : ""}`}
          onClick={() => handleTabClick(4)}
        >
          Rajneesh
        </div>
        <div
          className={`tab-item ${activeTab === 5 ? "active" : ""}`}
          onClick={() => handleTabClick(5)}
        >
          Pooja
        </div>
        <div
          className={`tab-item ${activeTab === 6 ? "active" : ""}`}
          onClick={() => handleTabClick(6)}
        >
          Maintenance
        </div>
        <div
          className={`tab-item ${activeTab === 2 ? "active" : ""}`}
          onClick={() => handleTabClick(2)}
        >
          Favourite
        </div>
        <div
          className={`tab-item ${activeTab === 3 ? "active" : ""}`}
          onClick={() => handleTabClick(3)}
        >
          Other
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <div>
            <VendoList group_name={"API_VENDOR"} />
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <VendoList group_name={"BUMPPY"} />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <VendoList group_name={"OTHER"} />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <VendoList group_name={"FAVOURITE"} />
          </div>
        )}
        {activeTab === 4 && (
          <div>
            <VendoList group_name={"RAJNEESH"} />
          </div>
        )}
        {activeTab === 5 && (
          <div>
            <VendoList group_name={"POOJA"} />
          </div>
        )}
        {activeTab === 6 && (
          <div>
            <VendoList group_name={"MAINTENANCE"} />
          </div>
        )}
         {activeTab === 7 && (
          <div>
            <VendoList group_name={"CHAAFO"} />
          </div>
        )}
      </div>
    </div>
  );
};

const VendorTopupReport = () => {
  const { user } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    signerName: "",
    signerEmail: "",
    signerCity: "",
    purpose: "",
    pdfFile: null,
    numPages: null,
    file: null,
  });

  const [state, setState] = useState({
    amount: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
  });

  const [selectedOption, setSelectedOption] = useState("");

  const [selectedVendor, setSelectedVendor] = useState({});
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const OpenModal = () => {
    setShowModal(!showModal);
  };

  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const getVendorAccounts = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_APISERVICES}/api/Admin/getAllVendorTopupAdmin`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == "0") {
            setData(data?.data);
            /*
 {
 "id": "5",
 "admin_user": "9",
 "acc_no": "0161009300057929",
 "acc_ifsc": "PUNB0016100",
 "api_response": "N",
 "status": "Y",
 "orderid": "10000000014483",
 "vendor": "",
 "indate": "3/14/2024 12:30:18 PM",
 "update": "3/14/2024 12:30:18 PM"
 }
 */
          } else {
            swal("Error", "Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  };

  const vendorTopup = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        adminid: user.pkid,
        vendorid: selectedVendor.id,
        amount: state.amount,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_APISERVICES}/api/Admin/adminVendorTopup`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == "0") {
            // setData(data?.data);
            swal("Success", "Topup Success", "success");
          } else {
            swal("Error", "Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  };
  const twoDecimalPlaces = (amount) => {
    return Number(amount).toFixed(2);
  };
  const clearForm = () => {
    setFormData({
      signerName: "",
      signerEmail: "",
      signerCity: "",
      purpose: "",
      pdfFile: null,
      numPages: null,
      file: null,
    });
  };

  const getPayoutStatus = async (orderid) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        // "payoutId": tid
        txnId: orderid,
        service: "VENDOR_TOPUP",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Admin/getPayoutStatus",
        requestOptions
      );
      const data = await res.json();

      if (data.responseCode === "00") {
        swal(
          "Success",
          `Status : ${data.data?.status}\nRRN : ${data.data?.endToEndIdentification}`,
          "success"
        );
      } else {
        swal("Failed", `${data.responseMsg}`, "error");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      swal("Failed", `Something went wrong`, "error");
    }
  };

  useEffect(() => {
    getVendorAccounts();
  }, []);

  return (
    <div className="m-0">
      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="dashboard-table-style"
          style={{ width: "100%", overflowX: "auto" }}
        >
          <tr>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              order id
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              Bank RRN
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              vendor{" "}
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              account
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              Amount
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              date
            </th>
            <th
              className="text-center"
              style={{
                fontSize: "0.9rem",
                background: "#f5f6f8",
                color: "#586275",
              }}
            >
              Action
            </th>
          </tr>
          {data.map((e, ind) => (
            <tr key={ind}>
              <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
              <td style={{ fontSize: "1rem" }}>
                <div>
                  <div>{e.orderid}</div>
                </div>
              </td>
              <td style={{ fontSize: "1rem" }}>{e.rrn}</td>
              <td style={{ fontSize: "1rem" }}>{e.vendor}</td>
              <td style={{ fontSize: "1rem" }}>
                {" "}
                <div>
                  <div>{e.acc_no}</div>
                  <div>{e.acc_ifsc}</div>
                </div>
              </td>
              <td style={{ fontSize: "1rem" }}>{twoDecimalPlaces(e.amount)}</td>
              <td style={{ fontSize: "1rem" }}>{e.indate}</td>
              <td>
                {" "}
                <Button
                  style={{
                    // paddingLeft:"3px",
                    // padding: "5px",
                    borderRadius: "5px",
                    border: "1px none",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    color: "rgba(255, 164, 27, 1)",
                    bordercolor: "rgba(255, 255, 255, 1)",
                  }}
                  className="btn btn-sm rounded-100 m-0 p-0"
                  onClick={() => {
                    getPayoutStatus(e.orderid);
                  }}
                >
                  {" "}
                  <div className="hover-container">
                    {/* Your content here */}
                    <img
                      src={require("../../../assets/images/Raise Ticket.png")}
                      style={{ width: "2.1rem" }}
                    />
                    <div style={positionStyle1} className="hover-text bg-dark">
                      {" "}
                      Generate RRN
                    </div>
                  </div>
                </Button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default APITopupAdmin;
