import React, { useState, useEffect, useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import CustomerSideBar from "./CustomerSideBar";
import { GlobalContext } from "../../Context/GlobalState";
const { SearchBar } = Search;

const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };
const rowStyle = (row, index) => {
  if (index % 2 == 0) {
    return greyStyle;
  } else {
    return whiteStyle;
  }
};

const columns = [
  {
    dataField: "customerId",
    text: "Customer Id",
    sort: true,
    formatter: (row, col) => {
      return (
        <div>
          <button
            className="btn btn-link btn-fw "
            type="button"
            keyField="id"
            onClick={() =>
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only")
            }
          >
            {row}
          </button>
        </div>
      );
    },
  },
  {
    dataField: "name",
    text: "Customer Name",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "city",
    text: "City",
    sort: true,
  },
  {
    dataField: "customerMob",
    text: "Customer Mobile",
    sort: true,
  },
];

const defaultSorted = [
  {
    dataField: "customerId",
    order: "desc",
  },
];

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [details, setDetails] = useState({});
  const { Profileinfo, setTransactionLoaderState } = useContext(GlobalContext);

  const setsidebarwidth = () => {
    document
      .querySelector(".content-wrapper")
      .classList.toggle("sidebar-icon-only");
  };

  const getCustomers = async () => {
    var myHeaders = new Headers();
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-ixWTq2CMqneGqLqb-BbP9Ujnfo1G38wJ_i-9LVAbvcAN4min4BcXzKCGmTGAa648RwBaLjSiVYGzQBfPMDszUXpHCecxCWfqG1j1zKy6jvUSYSr6A7FXTmlyrCd8txoSNXDJPngd0vPqIFJ2iGtxv3_EiQLO3C9mABt0vchwZSkoXRSVZATUhNt-VDDkh9bZkQcVp1p3kT6g1WCRanTjNQ4IRBLeNlBcBF_3xJafe36ka45O019gYptNpJ6OuYvA9UWnezm--9024h4O9jg1nTd_GbmrQ5_hvRoLjw1lVInkM9x4ERQ4dl1nUqk-ctME831pj7wlPL-3zHszBGfJJ2vT0oMgvTy8vSAjpNsRMp76eL1aTvzaNCD2n7OOGvVX3uHIEdSF1N-1hFqgBhAUp80diHtIlgAkQccV4cHpmofI2tknA-xRa2WdexcuqWSxtbdV0TTsMzPjbnUmFrya8abhTtl08ADcvGGwEaQYDWpgACynKyjEPNJPo5AM527eLMEg_Q8lCcR4-Lw6DJzdpk3A6kui1ykczTXB84gvFZsrs-tEb9jI3xIyHb05eBTS1pJM_CmYDV3vO414IdCkDSQt1JFp8l5f2B5Yk8urVOzDMetJa2rq_v5QXcP6NeID5EwqpCNanBIHoDpcW_pnbxg"
    );

    var raw = JSON.stringify({
      pageIndex: 1,
      pageSize: 100000,
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Report/GetCustomers`,
        requestOptions
      );
      if (res.ok) {
        const json = await res.text();
        const body = JSON.parse(json);
        setCustomers(body.data);
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);
  console.log(customers);

  

 

  

  return (
    <div>
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img
            src={require("../../../assets/images/logo-mini.svg")}
            alt="logo"
          />
        </Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <CustomerSideBar details={details} />
      </div>
      <div style={{ padding: "1rem" }} className="bg-light ">
      <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
        <div className="card-body m-0 p-3">
        <div
          style={{
            "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
            ,padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontWeight: "bolder",
            fontfamily: "Poppins !impotent",
            fontSize:"28px",
          }}
        >
          All Customers
        </div>
        <hr className="m-0 p-0"/>
        
            <div className="row m-0 p-0">
              <div className="col-12 m-0 p-0">
            
                  <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0">
                      {customers.length == 0 ? (
                        <EmptyArrayHandler />
                      ) : (
                        <div className="scroll-inner admin-fix-width borderless-table">
                          <table
                            style={{  width: "100%" ,backgroundColor: '#FFFFFF'}} className="my-table  borderless transparent-table"
                          >
                            <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
                              <th
                               className="my-table transparent-table text-center border-bottom py-3"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}
                              >
                                {" "}
                                {"Sr"}
                              </th>
                              {columns.map((heading) => (
                                <th
                                className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}
                                >
                                  {heading.text}
                                </th>
                              ))}
                            </tr>
                            {customers.map((e, ind) => (
                              <tr className="my-table transparent-table border-bottom py-5">
                                <td
                                  className="text-center" style={{width: '20rem',"fontFamily":"Poppins", fontSize: "0.8rem" }}
                                >
                                  {" "}
                                  {ind + 1}
                                </td>
                                {columns.map((cell) =>
                                  cell.dataField == "customerId" ? (
                                    <td 
                                    className="text-center" style={{width: '20rem',"fontFamily":"Poppins", fontSize: "0.8rem" }}
                                      onClick={() => {
                                        setDetails(e)
                                        document
                                          .querySelector(".content-wrapper")
                                          .classList.toggle(
                                            "sidebar-icon-only"
                                          );
                                      }}


                                    
                                    >
                                         <button  className="btn text-success"  >
                                      {e[cell.dataField]}
                                      </button>
                                    </td>
                                  ) : (
                                    <td
                                    className="text-center" 
                                      style={{
                                        width: '20rem',"fontFamily":"Poppins", fontSize: "0.8rem" ,
                                        color: cell.color,
                                        cursor:
                                          cell.dataField == "transferUniqueNo"
                                            ? "pointer"
                                            : "",
                                      }}
                                    >
                                      {e[cell.dataField]}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
   
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default Customers;
