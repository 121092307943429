

export const getPaynnowTransactionStatus = async (refid) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ==");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", ".AspNetCore.Identity.Application=CfDJ8DmgTTCaRqRGqptPGoMyPwU9pj_KGHglf4IfD-ST2XmTL0nXXUTVUDlKKotXwTHn46d_FRWgH18Zh9VS6l40bmLDETjq8CgwWfVQkDjLPmp78eBlKrOIr0QpbAsCctWnCevEBaJt-lShonzhBlxjyr8hviEqczClLUoUicup5a2HeVfathdJixtv_cKGxazZUHbDKgx2dymn-kAVVoQxswjnD7xObeWVLjB8E8xSXw15EI8TcH3e668C5VlyVNFNUtJUPS90l7Li_2PDqH6pisVr3ydBL5PtyEEguwQQSsKhnRtgfs9OEuN2iWd7wlJv4C-ZaJfXvPiz-I3fSSb0g8L8cH7twb1yjGFXb8gFvYiwa909E9KtX-fh74KkSSjhb6biCgAAYqk1jsh5QzOfdfRsWjGOERC_WJ9jlfOx-MPZLED-hhqi2deWuPfX7_HAzV69cyFlQBLE9pJrcbBerZfpBtLVIqYvOo5BimvsrYLMNMX0Fd5jogs-LLSr1-piG_ohELhcGPbDreaQisEmd9uOfo5_MBtAIBCRwWV5ovKNw9-gGH7TxBSmGen5DZGmJ83e2TFwbLABkZ3u3aHF3A7ndLKiwTuzk1YwTbbKzbUrFVnbSFvWZLwTonH4cUQycU-DcD_Pn1pQ3OQP1AOGMwahL4UDAHg4ltIKod8DuQLm");

        var raw = JSON.stringify({
            "referencevalue": refid
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const res = await
            fetch("https://apitranxt.paynnow.com/api/Payments/FundDetailsByRefOnly", requestOptions);
        const data = await res.json();
        return data;
    } catch (error) {
        return { responseCode: "01", responseMsg: "Something went wrong", status: "Something went wrong" }
    }
}

export const getPaynnowTransactionStatus2 = async (refid) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ==");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", ".AspNetCore.Identity.Application=CfDJ8DmgTTCaRqRGqptPGoMyPwU9pj_KGHglf4IfD-ST2XmTL0nXXUTVUDlKKotXwTHn46d_FRWgH18Zh9VS6l40bmLDETjq8CgwWfVQkDjLPmp78eBlKrOIr0QpbAsCctWnCevEBaJt-lShonzhBlxjyr8hviEqczClLUoUicup5a2HeVfathdJixtv_cKGxazZUHbDKgx2dymn-kAVVoQxswjnD7xObeWVLjB8E8xSXw15EI8TcH3e668C5VlyVNFNUtJUPS90l7Li_2PDqH6pisVr3ydBL5PtyEEguwQQSsKhnRtgfs9OEuN2iWd7wlJv4C-ZaJfXvPiz-I3fSSb0g8L8cH7twb1yjGFXb8gFvYiwa909E9KtX-fh74KkSSjhb6biCgAAYqk1jsh5QzOfdfRsWjGOERC_WJ9jlfOx-MPZLED-hhqi2deWuPfX7_HAzV69cyFlQBLE9pJrcbBerZfpBtLVIqYvOo5BimvsrYLMNMX0Fd5jogs-LLSr1-piG_ohELhcGPbDreaQisEmd9uOfo5_MBtAIBCRwWV5ovKNw9-gGH7TxBSmGen5DZGmJ83e2TFwbLABkZ3u3aHF3A7ndLKiwTuzk1YwTbbKzbUrFVnbSFvWZLwTonH4cUQycU-DcD_Pn1pQ3OQP1AOGMwahL4UDAHg4ltIKod8DuQLm");

        var raw = JSON.stringify({
            "p1_partnerRefid": refid
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const res = await
            fetch("https://apitranxt.paynnow.com/api/Payout/FetchtransferStatus", requestOptions);
        const data = await res.json();
        return data;
    } catch (error) {
        return { responseCode: "01", responseMsg: "Something went wrong", status: "Something went wrong" }
    }
}