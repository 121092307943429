import React, { useState, useEffect, useContext } from "react";
import cogoToast from "cogo-toast";
import Select from "react-select";
import { GlobalContext } from "./../../Context/GlobalState";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  BASE_URL_AEPS,
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
  COMPANY_CODE,
} from "./../../utils/Constants";
import {
  createHash,
  generateHashKey2,
  getLatLong,
} from "./../../utils/utilFuctions";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { basicAuth } from "../../config";

import {
  balanceEnquirySuccessPopup,
  cashWithdrawlSuccessPopup,
} from "./../../utils/Models";
import {
  AEPS_SUPER_MERCHANT_ID,
  COMPANY_RETAILER_COMPANY_ID,
} from "../../utils/Client";
import "./custom-tabs.css";
import AEPSReconcileReportTable from "../AEPS Reconcile Report/AEPSReconcileReportTable";
// import AEPSReconcileReportTable from "../AEPS Reconcile Report/AEPSReconcileReportTable";
var convert = require("xml-js");

const termsUrl = [
  {
    label: "BEN",
    value:
      "https://bumppy.in/aeps/T&C/1081_BEN_YB_UIDAI Authentication Regulation_.pdf",
  },
  {
    label: "MAL",
    value:
      "https://bumppy.in/aeps/T&C/1081_MAL_YB_UIDAI Authentication Regulation_.pdf",
  },
  {
    label: "ODI",
    value:
      "https://bumppy.in/aeps/T&C/1081_ODI_YB_UIDAI Authentication Regulation_.pdf",
  },
  {
    label: "TAM",
    value:
      "https://bumppy.in/aeps/T&C/1081_TAM_YB_UIDAI Authentication Regulation_.pdf",
  },
  {
    label: "TEL",
    value:
      "https://bumppy.in/aeps/T&C/1081_TEL_YB_UIDAI Authentication Regulation_.pdf",
  },
  {
    label: "English",
    value:
      "https://bumppy.in/aeps/T&C/English_UIDAI Authentication Regulation.pdf",
  },
  {
    label: "Haryanvi",
    value:
      "https://bumppy.in/aeps/T&C/Haryanvi_UIDAI Authentication Regulation.pdf",
  },
  {
    label: "Hindi",
    value:
      "https://bumppy.in/aeps/T&C/Hindi_UIDAI Authentication Regulation.pdf",
  },
  {
    label: "Kannnada",
    value:
      "https://bumppy.in/aeps/T&C/Kannnada_UIDAI Authentication Regulation.pdf",
  },
  {
    label: "Marathi",
    value:
      "https://bumppy.in/aeps/T&C/Marathi_UIDAI Authentication Regulation.pdf",
  },
  {
    label: "Rajasthani",
    value:
      "https://bumppy.in/aeps/T&C/Rajasthani_UIDAI Authentication Regulation.pdf",
  },
];

const deviceOptions = [
  // { value: -1, label: "Select Device" },
  { value: 0, label: "Mantra MFS 100" },
  { value: 1, label: "Morpho MSO 1300" },
  { value: 2, label: "Startek FM220U" },
];

const App = ({ credentials }) => {
  const {
    refreshWallet,
    Profileinfo,
    setTransactionLoaderState,
    user: user2,
  } = useContext(GlobalContext);
  const { username, password } = basicAuth;
  const [protocall, setProtocall] = useState("https");
  const user = {
    ReferId: user2.ReferId,
    shopname: Profileinfo.shopname,
    image: require("../../../assets/images/company/logo_square.png"),
  };
  const [pageIdx, setPageIdx] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [kycOtpValidate, setKycOtpValidate] = useState(false);
  const [aepsTwoFactorDone, setAepsTwoFactorDone] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [twoFactorDone, setTwoFactorDone] = useState(true);
  const [agentKycData, setAgentKycData] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [biAuthDone, setBiAuthDone] = useState(true);

  const [apTwoFactorDone, setApTwoFactorDone] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [cwTwoFactorDone, setCwTwoFactorDone] = useState(false);
  const [cwFingpayTwoFactorDone, setCwFingpayTwoFactorDone] = useState(false);

  const [ekycState, setEkycState] = useState({
    mobile: "",
    aadhar: "",
    pan: "",
    email: "",
    device: "",
    otp: "",
    primaryKeyId: "",
    encodeFPTxnId: "",
    error: "",
    success: "",
  });
  const [balanceEnquiryState, setBalanceEnquiryState] = useState({
    mobile: "",
    aadhar: "",
    bank: null,
    device: "",
    res: null,
    error: "",
    success: "",
    consent: false,
    buttonText: "Submit",
    isLoading: false,
    response: null,
    isAllowed: false,
  });
  const [cashWithdrawalState, setCashWithdrawalState] = useState({
    mobile: "",
    aadhar: "",
    bank: null,
    amount: "",
    device: "",
    res: null,
    error: "",
    success: "",
    consent: false,
    buttonText: "Submit",
    isLoading: false,
  });

  const [twoFactorAuth, setTwoFactorAuth] = useState({
    mobile: "",
    aadhar: "",
    bank: null,
    amount: "",
    device: "",
    res: null,
    error: "",
    success: "",
    consent: false,
    buttonText: "Submit",
    isLoading: false,
  });
  const [miniStatementState, setMiniStatementState] = useState({
    mobile: "",
    aadhar: "",
    bank: null,
    device: "",
    res: null,
    error: "",
    success: "",
    consent: false,
    buttonText: "Submit",
    isLoading: false,
  });
  const [adhaarPayState, setAdhaarPayState] = useState({
    mobile: "",
    aadhar: "",
    bank: null,
    amount: "",
    device: "",
    res: null,
    error: "",
    success: "",
    consent: false,
    buttonText: "Submit",
    isLoading: false,
  });
  const [isKycDone, setIsKycDone] = useState(false);
  const [pidData, setPidData] = useState(null);
  const [bankList, setBankList] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const storedDevice = localStorage.getItem("device")
    ? localStorage.getItem("device")
    : -1;
  const [device, setDevice] = useState(storedDevice);
  const [selectedDevice, setSelectedDevice] = useState(
    deviceOptions.find((d) => d.value == storedDevice)
  );
  const [otpButtonText, setOtpButtonText] = useState("Send OTP");
  const [otpSend, setOtpSend] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    error: "",
    success: "",
  });
  const [devicePort, setDevicePort] = useState("11100");

  const mantraPorts = [
    "11100",
    "11101",
    "11102",
    "11103",
    "11104",
    "11105",
    "11106",
  ];

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "", "height=600,width=800'");
    if (newWindow) newWindow.opener = null;
  };

  const discoverDevice = () => {
    let found = false;

    const protocalls = ["http", "https"];
    // https
    protocalls.forEach((p) => {
      for (let i = 0; i < mantraPorts.length && !found; i++) {
        const currentPort = mantraPorts[i];
        try {
          discoverDeviceReq(currentPort, p, () => {
            found = true;
            setDevicePort(currentPort);
            getDeviceInfo(currentPort, p);
            setProtocall(p);
          });
        } catch (error) {}
      }
    });
  };

  const textStyle = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  function discoverDeviceReq(port, proto, callback) {
    let url;
    if (device == 0) {
      url = `${proto}://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `${proto}://localhost:${port}/getDeviceInfo`;
    }

    if (device == 2) {
      url = `${proto}://localhost:${port}/rd/info`;
    }

    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          console.log(data1);
          if (
            data1.DeviceInfo?.additional_info?.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            //  setSnackbarState({ ...snackbarState, success: "", error: "device is not ready" });
          } else {
            // setSnackbarState({ ...snackbarState, success: "device is ready to use", error: "" })
            // alert('device is ready to use');
            callback();
          }
          // setDeviceInfo(data1);
        } else {
        }
      }
    };

    xhr.send();
  }

  const checkDevice = async () => {
    if (
      !deviceInfo ||
      !deviceInfo.DeviceInfo ||
      !deviceInfo.DeviceInfo.additional_info ||
      deviceInfo.DeviceInfo.additional_info.Param.length == 0
    ) {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
      });
      return false;
    } else return true;
  };

  const ekycBiometric = async (pidData) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantLoginId: user.ReferId,
        primaryKeyId: ekycState.primaryKeyId,
        encodeFPTxnId: ekycState.encodeFPTxnId,
        requestRemarks: "Mrchant onboarding request",
        cardnumberORUID: {
          nationalBankIdentificationNumber: null,
          indicatorforUID: "0",
          adhaarNumber: ekycState.aadhar,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/biometric.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      if (data.statusCode == 10000 || data.status) {
        cogoToast.success("E-KYC Successfull");
        setIsKycDone(true);
        setPageIdx(1);
        ekycServerUpdate();
        ekycServerUpdate2();
      } else {
        alert(data.message);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const ekycServerUpdate = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLCo-SP7zDi-UItOiNUMUIYQjRENG45tKVcI7QPfBoEqslegNz-_NVq5v0578HZkLhnAjqkzA2ffSqn7eRdyqaeF8LsgeOLD2cD8QCaK72cUsg8LTmkpWLn4bwOBSY9SfbLxOPB2WZ7bWXN1p-OzfciLOy_K6kVSkjDXeWz-MOUeYmGk22PLPssypmmdqPk_vo2m8gD-uql6hmJThZYoByPcXaS_hYJYOeQl-qArcGBDn01moGu1Gpw9ZIWQGkjmoQvK0hLS9FdsBOj2sK8s483-jSgSEt4vLskERTxapSlFeWvZpmVkEhRDK3HryOEJYpo8vXEpnj_HWFUY4vtG7LdJt220hzfIUXiWL06_r2aUOjEu3MfsI9y5UD09hb6Y7latEvVeqZ1fhN15dcDq7j0N2mAPYE8yDL7b-jdn3VctZnixzcOqel5A0ZPeXvDjtxAPsws_447T6TgaDfHZD9DIsNH8kjgEITM7qI52xgC9RUxBBY8wr0Ll5pKQzesFTqpdJ2i61Y5NvgsDMbL7XcDgw9eQPHvLf5zpatuOYw5zYYxXHq2O0PFtKo4zoGi9rn2VI0S0uPjCy4ynYQoWMXiGOsOCXaLV2N3dCrirdWeVUMUbRS8pY4FQbCZgnAsndFaoo30KAHHg9VBwSyIOckyo"
      );

      var raw = JSON.stringify({
        userid: COMPANY_CODE,
        agentid: user.ReferId,
        retailerid: COMPANY_RETAILER_COMPANY_ID,
        kycstatus: "Y",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/AEPS/Aepskycupdate`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      if (data.pipe1_kyc == "Y") {
        setIsKycDone(true);
        setPageIdx(1);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      // setLoading(false);
    }
  };

  const ekycServerUpdate2 = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        response: "Y",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/updatekycstatus.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        cogoToast.success(data.msg);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const sendOTPValidation = () => {
    if (ekycState.mobile == "") {
      setSnackbarState({
        ...snackbarState,
        error: "please enter mobile number",
      });
      // alert("please enter mobile number");
    } else if (ekycState.mobile.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter mobile number",
      });
      // alert("please enter valid mobile number");
    } else if (ekycState.aadhar.length != 12) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid aadhar number",
      });
      // alert("please enter valid aadhar number");
    } else if (ekycState.pan.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid pan number",
      });
      // alert("please enter valid pan number");
    } else {
      return true;
    }
    return false;
  };

  const sendOTPForEKyc = async () => {
    if (sendOTPValidation()) {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var json = JSON.stringify({
          superMerchantId: AEPS_SUPER_MERCHANT_ID,
          merchantLoginId: user.ReferId,
          transactionType: "EKY",
          mobileNumber: ekycState.mobile,
          aadharNumber: ekycState.aadhar,
          panNumber: ekycState.pan,
          matmSerialNumber: "",
          latitude: latitude,
          longitude: longitude,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: json,
          redirect: "follow",
        };

        // const res = await fetch(
        //   `${BASE_URL_AEPS}/sendotp.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        //   requestOptions
        // );

        const res = await fetch(
          `${BASE_URL_AEPS}/sendotp.php?device=12343535`,
          requestOptions
        );
        const raw2 = await res.text();
        const data = JSON.parse(raw2);
        if (data.status) {
          setEkycState({
            ...ekycState,
            primaryKeyId: data.data.primaryKeyId,
            encodeFPTxnId: data.data.encodeFPTxnId,
          });
          setOtpButtonText("Validate OTP");
          setOtpSend(true);
          setSnackbarState({
            ...snackbarState,
            success: "OTP send successful",
            error: "",
          });
        } else {
          setSnackbarState({
            ...snackbarState,
            success: "",
            error: data.message,
          });
        }
        setTransactionLoaderState(false);
      } catch (error) {
        setTransactionLoaderState(false);
        console.log("error", error);
      }
    }
  };

  const validateOTPForEKyc = async () => {
    // if (checkDevice()) {
    if (true) {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          superMerchantId: AEPS_SUPER_MERCHANT_ID,
          merchantLoginId: user.ReferId,
          otp: ekycState.otp,
          primaryKeyId: ekycState.primaryKeyId,
          encodeFPTxnId: ekycState.encodeFPTxnId,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        // const res = await fetch(
        //   `${BASE_URL_AEPS}/validateOTP.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        //   requestOptions
        // );

        const res = await fetch(
          `${BASE_URL_AEPS}/validateOTP.php?device=12343535`,
          requestOptions
        );
        const raw2 = await res.text();
        const data = JSON.parse(raw2);
        if (data.status) {
          // capture(ekycBiometric, true);
          setSnackbarState({
            ...snackbarState,
            error: "",
            success: "OTP validation successful",
          });
          setOtpValidated(true);
        } else {
          alert("Invalid OTP");
        }
        setTransactionLoaderState(false);
      } catch (error) {
        setTransactionLoaderState(false);
        console.log("error", error);
      }
    }
  };

  function getDeviceInfo(port, proto) {
    let url;
    if (device == 0) {
      url = `${proto}://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `${proto}://localhost:${port}/getDeviceInfo`;
    }
    if (device == 2) {
      url = `${proto}://localhost:${port}/rd/info`;
    }
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          if (
            data1.DeviceInfo?.additional_info?.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            setSnackbarState({
              ...snackbarState,
              success: "",
              error: "device is not ready",
            });
          } else {
            setSnackbarState({
              ...snackbarState,
              success: "device is ready to use",
              error: "",
            });
            // alert('device is ready to use');
          }
          setDeviceInfo(data1);
        } else {
          console.log(xhr.response);
        }
      }
    };

    xhr.send();
  }

  function morphoCapture(isKyc, callback) {
    var url = `${protocall}://localhost:${devicePort}/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/>' +
        "</PidOptions>";
    } else {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="" posh=""/>' +
        "</PidOptions>";
    }

    /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion

          if (test5 > 0) {
            // alert(xhr.responseText);
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            // alert("Captured Successfully");
            var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            setPidData(JSON.parse(result1));
            if (callback) callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  function startekCapture(isKyc, callback) {
    var url = `${protocall}://localhost:${devicePort}/rd/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/>' +
        "</PidOptions>";
    } else {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="" posh=""/>' +
        "</PidOptions>";
    }

    /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion

          if (test5 > 0) {
            // alert(xhr.responseText);
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            // alert("Captured Successfully");
            var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            setPidData(JSON.parse(result1));
            if (callback) callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  function mantraCapture(isKyc, callback) {
    var url = `${protocall}://localhost:${devicePort}/rd/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    } else {
      PIDOPTS =
        '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    }

    /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion
          var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
          setPidData(JSON.parse(result1));
          if (test5 > 0) {
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            alert(xhr.responseText);
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            // alert("Captured Successfully");
            var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            setPidData(JSON.parse(result1));
            if (callback) callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  const capture = (isKyc, callback) => {
    if (
      !deviceInfo ||
      !deviceInfo.DeviceInfo ||
      !deviceInfo.DeviceInfo.additional_info ||
      deviceInfo.DeviceInfo.additional_info.Param.length == 0
    ) {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    } else if (
      device == 0 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      mantraCapture(isKyc, callback);
    } else if (
      device == 1 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      morphoCapture(isKyc, callback);
    } else if (
      device == 2 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      startekCapture(isKyc, callback);
    } else {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    }
  };

  const getNewTransactionID = async () => {
    let id = "";
    try {
      setTransactionLoaderState(true);
      const raw = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=fetchnewtransactionid&userid=${user.ReferId}`,
        requestOptions
      );
      const body = await res.text();

      id = JSON.parse(body).id;
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
    return id;
  };

  const setPrintData = (res, bank, aadhar, amount, service, mobile, tid) => {
    let data = {
      userid: user.ReferId,
      bank: bank,
      device:
        device == 0 ? "Mantra MFS 100" : device == 1 ? "Morpho MSO 1300" : "",
      tid: tid,
      aadhar: aadhar,
      amount: amount,
      service: service,
      rrn: res.data ? res.data.bankRRN : "NA",
      stan: res.data ? res.data.fpTransactionId : "NA",
      time: res.data ? res.data.requestTransactionTime : "NA",
      mobile,
      mobile,
      status: res.status ? res.data.transactionStatus : res.message,
      balance: res.data ? res.data.balanceAmount : "NA",
      miniStatementStructureModel: res.data
        ? res.data.miniStatementStructureModel
        : null,
      miniOffusStatementStructureModel: res.data
        ? res.data.miniOffusStatementStructureModel
        : null,
      shopname: user.shopname,
      image: user.image,
    };
    sessionStorage.setItem("print", JSON.stringify(data));
  };

  const transactionUpdate = async (
    res,
    bank,
    aadhar,
    amount,
    service,
    mobile,
    deviceNo,
    txnId,
    status
  ) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic dGVzdHZpcnR1YWxhY2NvdW50OnRlc3R2aXJ0dWFsYWNjb3VudDExMjI="
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        rrn: res.data ? res.data.bankRRN : "",
        stanNo: res.data ? res.data.fpTransactionId : "",
        txnId: txnId,
        aepstxnId: txnId,
        action: "Credit",
        device:
          device == 0 ? "Mantra MFS 100" : device == 1 ? "Morpho MSO 1300" : "",
        status: status,
        txnStatus: res.status ? "Success" : res.message,
        bankName: bank,
        mobileNo: mobile,
        uId: `XXXXXXXX${aadhar.substr(8, 12)}`,
        authCode: "",
        deviceNo: deviceNo,
        balance: res.data ? res.data.balanceAmount : "0",
        agent_Id: user.ReferId,
        service: service,
        amount: amount,
        bumppy_agentcode: COMPANY_CODE,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://apitranxt.paynnow.com/api/AEPS/GetallAEPStransactions",
        requestOptions
      );
      const raw2 = await response.text();
      const data = JSON.parse(raw2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const balanceEnquiry = async (pidData) => {
    setBalanceEnquiryState({
      ...balanceEnquiryState,
      buttonText: "Submitting ...",
      isLoading: true,
    });
    try {
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const hash = createHash(user.ReferId);
      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTransactionId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: balanceEnquiryState.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        timestamp: new Date().toDateString(),
        transactionType: "BE",
        cardnumberORUID: {
          adhaarNumber: balanceEnquiryState.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: balanceEnquiryState.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore.toString().trim(),
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/balanceenquiry.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        requestOptions
      );
      const body = await res.text();
      const data = JSON.parse(body);

      if (data.status) {
        setIsAllowed(true);
        setBalanceEnquiryState({
          ...balanceEnquiryState,
          response: data,
          isAllowed: true,
        });
        balanceEnquirySuccessPopup(
          balanceEnquiryState.aadhar,
          data.data.balanceAmount,
          balanceEnquiryState.bank.bankName,
          tranxtID,
          balanceEnquiryState.mobile,
          print
        );
        transactionUpdate(
          data,
          balanceEnquiryState.bank.bankName,
          balanceEnquiryState.aadhar,
          0,
          "Balance Enquiry",
          balanceEnquiryState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "0"
        );
      } else {
        swal("Failed", data.message, "error");
        transactionUpdate(
          data,
          balanceEnquiryState.bank.bankName,
          balanceEnquiryState.aadhar,
          0,
          "Balance Enquiry",
          balanceEnquiryState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "1"
        );
      }
      setBalanceEnquiryState({
        ...balanceEnquiryState,
        res: data,
        buttonText: "Submit",
        isLoading: false,
      });
      setPrintData(
        data,
        balanceEnquiryState.bank.bankName,
        balanceEnquiryState.aadhar,
        0,
        "Balance Enquiry",
        balanceEnquiryState.mobile,
        tranxtID
      );
      // balanceEnquiryUpdate(data);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("Failed", "something went wrong", "error");
      setBalanceEnquiryState({
        ...balanceEnquiryState,
        buttonText: "Submit",
        isLoading: false,
      });
    }
  };
  const twoFactorAuthentication = async (pidData) => {
    try {
      localStorage.setItem("agentTwoFactorData", JSON.stringify(twoFactorAuth));
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      //
      const hash = createHash(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTranId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        //  languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: twoFactorAuth.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        //  timestamp: new Date().toDateString(),
        // transactionAmount: twoFactorAuth.amount,
        transactionType: "AUO",
        serviceType: "AEPS",
        cardnumberORUID: {
          adhaarNumber: twoFactorAuth.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: twoFactorAuth.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/twoFactorDaily.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
        requestOptions
      );
      const data = await res.json();
      if (data.status) {
        swal("Success", "Two Factor Authentication Successfull", "success");
        setAepsTwoFactorDone(true);
        setCwTwoFactorDone(true);
        twoFactorUpdate1();
        twoFactorCheckApi();
      } else {
        swal("Failed", "Two Factor Authentication Failed", "error");
      }
      // swal("Success", JSON.stringify(data), "success");
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", JSON.stringify(error), "error");
      console.log("error", error);
    }
  };
  const twoFactorAuthentication2 = async (pidData) => {
    try {
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      //
      const hash = createHash(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTranId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        //  languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: twoFactorAuth.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        //  timestamp: new Date().toDateString(),
        // transactionAmount: twoFactorAuth.amount,
        transactionType: "AUO",
        serviceType: "AP",
        cardnumberORUID: {
          adhaarNumber: twoFactorAuth.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: twoFactorAuth.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/twoFactorDaily.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
        requestOptions
      );
      const data = await res.json();
      if (data.status) {
        swal("Success", "Two Factor Authentication Successfull", "success");
        twoFactorCheckApi();
        twoFactorUpdate2();
        setApTwoFactorDone(true);
      } else {
        swal("Failed", "Two Factor Authentication Failed", "error");
      }
      // swal("Success", JSON.stringify(data), "success");
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", JSON.stringify(error), "error");
      console.log("error", error);
    }
  };
  const twoFactorAuthenticationAP = async (pidData) => {
    try {
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      //
      const hash = createHash(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTranId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        //  languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: twoFactorAuth.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        //  timestamp: new Date().toDateString(),
        // transactionAmount: twoFactorAuth.amount,
        transactionType: "AUO",
        serviceType: "AP",
        cardnumberORUID: {
          adhaarNumber: twoFactorAuth.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: twoFactorAuth.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/twofactor.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
        requestOptions
      );
      const data = await res.json();
      if (data.status) {
        swal(
          "Success",
          "Agent Bi Auth Done For Aadhaarpay , Please Capture Customer Biometric",
          "success"
        );
        setBiAuthDone(true);
      } else {
        swal("Failed", "Agent Bi Auth Failed For Aadhaarpay", "error");
      }
      // swal("Success", JSON.stringify(data), "success");
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", JSON.stringify(error), "error");
      console.log("error", error);
    }
  };
  const twoFactorAuthenticationCW = async (pidData) => {
    try {
      const agentTwoFactorData = JSON.parse(
        localStorage.getItem("agentTwoFactorData")
      );
      if (
        !agentTwoFactorData ||
        agentTwoFactorData == null ||
        agentTwoFactorData == undefined ||
        agentTwoFactorData == ""
      ) {
        swal("Failed", "Please Do Two Factor Authentication First", "error");
        return;
      }
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      //
      const hash = createHash(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTranId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        //  languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: agentTwoFactorData.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        //  timestamp: new Date().toDateString(),
        // transactionAmount: twoFactorAuth.amount,
        transactionType: "AUO",
        serviceType: "AEPS",
        cardnumberORUID: {
          adhaarNumber: agentTwoFactorData.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: agentTwoFactorData.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/twofactor.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
        requestOptions
      );
      const data = await res.json();
      if (data.status) {
        swal(
          "Success",
          "Agent Bi Auth Done For Cash Withdrawl , Please Capture Customer Biometric",
          "success"
        );
        setBiAuthDone(true);
      } else {
        swal("Failed", "Agent Bi Auth Failed For Cash Withdrawl", "error");
      }
      // swal("Success", JSON.stringify(data), "success");
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", JSON.stringify(error), "error");
      console.log("error", error);
    }
  };
  const cashWithdrawal = async (pidData) => {
    setCashWithdrawalState({
      ...cashWithdrawalState,
      buttonText: "Submitting ...",
      isLoading: true,
    });
    try {
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      const hash = createHash(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTranId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: cashWithdrawalState.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        timestamp: new Date().toDateString(),
        transactionAmount: cashWithdrawalState.amount,
        transactionType: "CW",
        cardnumberORUID: {
          adhaarNumber: cashWithdrawalState.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: cashWithdrawalState.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/withdrawal.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      if (data.status) {
        cashWithdrawlSuccessPopup(
          cashWithdrawalState.aadhar,
          cashWithdrawalState.amount,
          cashWithdrawalState.bank.bankName,
          tranxtID,
          cashWithdrawalState.mobile,
          data.data.balanceAmount,
          print,
          () => {
            capture(false, miniStatement);
          },
          "Cash Withdrawal"
        );
        transactionUpdate(
          data,
          cashWithdrawalState.bank.bankName,
          cashWithdrawalState.aadhar,
          cashWithdrawalState.amount,
          "Cash Withdrawl",
          cashWithdrawalState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "0"
        );
      } else {
        swal("Failed", data.message, "error");
        transactionUpdate(
          data,
          cashWithdrawalState.bank.bankName,
          cashWithdrawalState.aadhar,
          cashWithdrawalState.amount,
          "Cash Withdrawl",
          cashWithdrawalState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "1"
        );
      }
      setCashWithdrawalState({
        ...cashWithdrawalState,
        res: data,
        buttonText: "Submit",
        isLoading: false,
      });
      setPrintData(
        data,
        cashWithdrawalState.bank.bankName,
        cashWithdrawalState.aadhar,
        cashWithdrawalState.amount,
        "Cash Withdrawal",
        cashWithdrawalState.mobile,
        tranxtID
      );
      refreshWallet();
      // setBiAuthDone(false);
      // cashWithdrawalUpdate(data);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("Failed", "something went wrong", "error");
      setCashWithdrawalState({
        ...cashWithdrawalState,
        buttonText: "Submit",
        isLoading: false,
      });
    }
  };
  const miniStatement = async (pidData) => {
    setMiniStatementState({
      ...miniStatementState,
      isLoading: true,
      buttonText: "Submitting ...",
    });
    try {
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      const hash = createHash(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTranId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: miniStatementState.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        timestamp: new Date().toDateString(),
        transactionType: "MS",
        cardnumberORUID: {
          adhaarNumber: miniStatementState.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: miniStatementState.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/ministatement.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      if (data.status) {
        swal2(
          <React.Fragment>
            {data.data.miniStatementStructureModel && (
              <table
                style={{
                  width: "100%",
                  height: "40vh",
                  overflowY: "scroll",
                  border: "1px solid #979797",
                }}
              >
                <tr
                  style={{
                    width: "100%",
                    border: "1px solid #979797",
                    background: "none",
                  }}
                >
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    {"date"}
                  </td>
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    {"txnType"}
                  </td>
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    {"amount"}
                  </td>
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    {"narration"}
                  </td>
                </tr>
                {data.data.miniStatementStructureModel.map((state, i) => (
                  <tr
                    style={{
                      width: "100%",
                      border: "1px solid #979797",
                      background: "none",
                    }}
                    key={i}
                  >
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #979797",
                      }}
                    >
                      {state.date}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #979797",
                      }}
                    >
                      {state.txnType == "Cr" || state.txnType == "cr"
                        ? "Credit"
                        : "Debit"}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #979797",
                      }}
                    >
                      {state.amount}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #979797",
                      }}
                    >
                      {state.narration}
                    </td>
                  </tr>
                ))}
                <tr
                  style={{
                    width: "100%",
                    border: "1px solid #979797",
                    background: "none",
                  }}
                >
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    <div
                      onClick={() => {
                        //   print();
                      }}
                      //  href="#"
                      className="btn fw-bold btn-sm m-4"
                    >
                      {`Account Balance : ${data.data.balanceAmount}`}
                    </div>
                  </td>
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    <div
                      onClick={() => {
                        print();
                      }}
                      href="#"
                      className="btn fw-bold btn-sm m-4"
                    >
                      <img
                        src={require("./../../../assets/images/download.png")}
                        style={{
                          height: "1rem",
                          width: "1rem",
                          marginRight: "2px",
                        }}
                      />
                      {"Print Receipt"}
                    </div>
                  </td>
                </tr>
              </table>
            )}

            {data.data.miniOffusStatementStructureModel && (
              <table
                style={{
                  width: "100%",
                  height: "40vh",
                  overflowY: "scroll",
                  border: "1px solid #979797",
                }}
              >
                <tr
                  style={{
                    width: "100%",
                    border: "1px solid #979797",
                    background: "none",
                  }}
                >
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    {"Transactions"}
                  </td>
                </tr>
                {data.data.miniOffusStatementStructureModel.map((state, i) => (
                  <tr
                    style={{
                      width: "100%",
                      border: "1px solid #979797",
                      background: "none",
                    }}
                    key={i}
                  >
                    <td
                      style={{
                        width: "100%",
                        textAlign: "center",
                        border: "1px solid #979797",
                      }}
                    >
                      {state}
                    </td>
                  </tr>
                ))}
                <tr
                  style={{
                    width: "100%",
                    border: "1px solid #979797",
                    background: "none",
                  }}
                >
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    <div
                      onClick={() => {
                        //   print();
                      }}
                      //  href="#"
                      className="btn fw-bold btn-sm m-4"
                    >
                      {`Account Balance : ${data.data.balanceAmount}`}
                    </div>
                  </td>
                  <td
                    style={{ textAlign: "center", border: "1px solid #979797" }}
                  >
                    <div
                      onClick={() => {
                        print();
                      }}
                      href="#"
                      className="btn fw-bold btn-sm m-4"
                    >
                      <img
                        src={require("./../../../assets/images/download.png")}
                        style={{
                          height: "1rem",
                          width: "1rem",
                          marginRight: "2px",
                        }}
                      />
                      {"Print Receipt"}
                    </div>
                  </td>
                </tr>
              </table>
            )}
          </React.Fragment>
        );
        transactionUpdate(
          data,
          miniStatementState.bank.bankName,
          miniStatementState.aadhar,
          0,
          "Mini Statement",
          miniStatementState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "0"
        );
      } else {
        swal("Failed", data.message, "error");
        transactionUpdate(
          data,
          miniStatementState.bank.bankName,
          miniStatementState.aadhar,
          0,
          "Mini Statement",
          miniStatementState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "1"
        );
      }
      setMiniStatementState({
        ...miniStatementState,
        res: data,
        buttonText: "Submit",
        isLoading: false,
      });
      setPrintData(
        data,
        miniStatementState.bank.bankName,
        miniStatementState.aadhar,
        0,
        "Mini Statement",
        miniStatementState.mobile,
        tranxtID
      );
      // miniStatementUpdate(data);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      // console.log('error', error);
      setMiniStatementState({
        ...miniStatementState,
        isLoading: false,
        buttonText: "Submit",
      });
      swal("Failed", "something went wrong", "error");
    }
  };

  const adhaarPay = async (pidData) => {
    setAdhaarPayState({
      ...adhaarPayState,
      buttonText: "Submitting ...",
      isLoading: true,
    });
    try {
      setTransactionLoaderState(true);
      const tranxtID = await getNewTransactionID();
      const hash = createHash(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        superMerchantId: AEPS_SUPER_MERCHANT_ID,
        merchantTranId: tranxtID,
        merchantUserName: user.ReferId,
        merchantPin: hash,
        languageCode: "en",
        latitude: latitude,
        longitude: longitude,
        mobileNumber: adhaarPayState.mobile,
        paymentType: "B",
        requestRemarks: "TN300CA0006560",
        timestamp: new Date().toDateString(),
        transactionAmount: adhaarPayState.amount,
        transactionType: "M",
        cardnumberORUID: {
          adhaarNumber: adhaarPayState.aadhar,
          indicatorforUID: 0,
          nationalBankIdentificationNumber: adhaarPayState.bank.iinno,
        },
        captureResponse: {
          errCode: pidData.PidData.Resp._attributes.errCode,
          errInfo: pidData.PidData.Resp._attributes.errInfo,
          fCount: pidData.PidData.Resp._attributes.fCount,
          fType: pidData.PidData.Resp._attributes.fType,
          iCount: pidData.PidData.Resp._attributes.iCount,
          iType: pidData.PidData.Resp._attributes.iType,
          pCount: pidData.PidData.Resp._attributes.pCount,
          pType: pidData.PidData.Resp._attributes.pType,
          nmPoints: pidData.PidData.Resp._attributes.nmPoints,
          qScore: pidData.PidData.Resp._attributes.qScore,
          dpID: pidData.PidData.DeviceInfo._attributes.dpId,
          rdsID: pidData.PidData.DeviceInfo._attributes.rdsId,
          rdsVer: pidData.PidData.DeviceInfo._attributes.rdsVer,
          dc: pidData.PidData.DeviceInfo._attributes.dc,
          mi: pidData.PidData.DeviceInfo._attributes.mi,
          mc: pidData.PidData.DeviceInfo._attributes.mc,
          ci: pidData.PidData.Skey._attributes.ci,
          sessionKey: pidData.PidData.Skey._text,
          hmac: pidData.PidData.Hmac._text,
          PidDatatype: pidData.PidData.Data._attributes.type,
          Piddata: pidData.PidData.Data._text,
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_AEPS}/AadharPay.php?device=${deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      if (data.status) {
        cashWithdrawlSuccessPopup(
          adhaarPayState.aadhar,
          adhaarPayState.amount,
          adhaarPayState.bank.bankName,
          tranxtID,
          adhaarPayState.mobile,
          data.data.balanceAmount,
          print,
          ()=>{},
          "Aadhar Pay"
        );
        transactionUpdate(
          data,
          adhaarPayState.bank.bankName,
          adhaarPayState.aadhar,
          adhaarPayState.amount,
          "AadharPay",
          adhaarPayState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "0"
        );
      } else {
        swal("Failed", data.message, "error");
        transactionUpdate(
          data,
          adhaarPayState.bank.bankName,
          adhaarPayState.aadhar,
          adhaarPayState.amount,
          "AadharPay",
          adhaarPayState.mobile,
          deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value,
          tranxtID,
          "1"
        );
      }
      setAdhaarPayState({
        ...adhaarPayState,
        res: data,
        buttonText: "Submit",
        isLoading: false,
      });
      setPrintData(
        data,
        adhaarPayState.bank.bankName,
        adhaarPayState.aadhar,
        adhaarPayState.amount,
        "AdhaarPay",
        adhaarPayState.mobile,
        tranxtID
      );
      refreshWallet();
      // setBiAuthDone(false);
      // cashWithdrawalUpdate(data);
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      swal("Failed", "something went wrong", "error");
      setAdhaarPayState({
        ...adhaarPayState,
        buttonText: "Submit",
        isLoading: false,
      });
    }
  };

  const getAepsBankList = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        crossDomain: true,
      };

      const res = await fetch(`${BASE_URL_AEPS}/banklist.php`, requestOptions);
      const body = await res.text();
      const data = JSON.parse(body);
      setBankList(
        data.data.map((b) => {
          return { ...b, label: b.bankName, value: b.bankName };
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  };
  const CLIENT = "";
  const print = () => {
    const newWindow = window.open(
      `${CLIENT}/pages/aeps/print`,
      "",
      "height=600,width=800"
    );
    if (newWindow) newWindow.opener = null;
  };

  const balanceEnquirySubmit = () => {
    if (balanceEnquiryState.mobile.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid mobile number",
      });
    } else if (balanceEnquiryState.aadhar.length != 12) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid aadhar number number",
      });
    } else if (balanceEnquiryState.bank == null) {
      setSnackbarState({ ...snackbarState, error: "please enter your bank" });
    } else if (!balanceEnquiryState.consent) {
      setSnackbarState({
        ...snackbarState,
        error: "please agree Terms And Conditions",
      });
    } else if (pidData == null) {
      setSnackbarState({
        ...snackbarState,
        error: "please capture your biometric",
      });
    } else {
      balanceEnquiry(pidData);
    }
  };
  const twoFactorAuthSubmit = (type) => {
    if (twoFactorAuth.mobile.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid mobile number",
      });
    } else if (twoFactorAuth.aadhar.length != 12) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid aadhar number number",
      });
    } else if (twoFactorAuth.bank == null) {
      setSnackbarState({ ...snackbarState, error: "please enter your bank" });
    } else if (pidData == null) {
      setSnackbarState({
        ...snackbarState,
        error: "please capture your biometric",
      });
    } else {
      if (aepsTwoFactorDone) {
        twoFactorAuthentication2(pidData);
      } else {
        twoFactorAuthentication(pidData);
      }
    }
  };

  const twoFactorAuthSubmit2 = (type) => {
    if (twoFactorAuth.mobile.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid mobile number",
      });
    } else if (twoFactorAuth.aadhar.length != 12) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid aadhar number number",
      });
    } else if (twoFactorAuth.bank == null) {
      setSnackbarState({ ...snackbarState, error: "please enter your bank" });
    } else if (pidData == null) {
      setSnackbarState({
        ...snackbarState,
        error: "please capture your biometric",
      });
    } else {
      twoFactorAuthentication2(pidData);
    }
  };

  const cashWithdrawalSubmit = () => {
    if (cashWithdrawalState.mobile.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid mobile number",
      });
    } else if (cashWithdrawalState.aadhar.length != 12) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid aadhar number number",
      });
    } else if (cashWithdrawalState.bank == null) {
      setSnackbarState({ ...snackbarState, error: "please enter your bank" });
    } else if (!cashWithdrawalState.consent) {
      setSnackbarState({
        ...snackbarState,
        error: "please agree Terms And Conditions",
      });
    } else if (pidData == null) {
      setSnackbarState({
        ...snackbarState,
        error: "please capture your biometric",
      });
    } else if (cashWithdrawalState.amount == "") {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid amount",
      });
    } else if (Number(cashWithdrawalState.amount) % 50 != 0) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter amount in multiple of 50",
      });
    } else {
      if (biAuthDone) {
        cashWithdrawal(pidData);
      } else {
        twoFactorAuthenticationCW(pidData);
      }
    }
  };
  const miniStatementSubmit = () => {
    if (miniStatementState.mobile.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid mobile number",
      });
    } else if (miniStatementState.aadhar.length != 12) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid aadhar number number",
      });
    } else if (miniStatementState.bank == null) {
      setSnackbarState({ ...snackbarState, error: "please enter your bank" });
    } else if (!miniStatementState.consent) {
      setSnackbarState({
        ...snackbarState,
        error: "please agree Terms And Conditions",
      });
    } else if (pidData == null) {
      setSnackbarState({
        ...snackbarState,
        error: "please capture your biometric",
      });
    } else {
      miniStatement(pidData);
    }
  };
  const adhaarPaySubmit = () => {
    if (adhaarPayState.mobile.length != 10) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid mobile number",
      });
    } else if (adhaarPayState.aadhar.length != 12) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid aadhar number number",
      });
    } else if (adhaarPayState.bank == null) {
      setSnackbarState({ ...snackbarState, error: "please enter your bank" });
    } else if (!adhaarPayState.consent) {
      setSnackbarState({
        ...snackbarState,
        error: "please agree Terms And Conditions",
      });
    } else if (pidData == null) {
      setSnackbarState({
        ...snackbarState,
        error: "please capture your biometric",
      });
    } else if (adhaarPayState.amount == "") {
      setSnackbarState({
        ...snackbarState,
        error: "please enter valid amount",
      });
    } else if (Number(adhaarPayState.amount) % 50 != 0) {
      setSnackbarState({
        ...snackbarState,
        error: "please enter amount in multiple of 50",
      });
    } else {
      if (biAuthDone) {
        adhaarPay(pidData);
      } else {
        twoFactorAuthenticationAP(pidData);
      }
    }
  };

  const EkycSubmit = async () => {
    if (!otpValidated) {
      setSnackbarState({
        ...snackbarState,
        error: "please validate OTP",
      });
    } else if (pidData == null) {
      setSnackbarState({
        ...snackbarState,
        error: "please capture your biometric",
      });
    } else {
      ekycBiometric(pidData);
    }
  };

  useEffect(() => {
    getAepsBankList();
    if (credentials.pipe1_kyc == "Y") {
      setIsKycDone(true);
      setPageIdx(1);
    }
    // setPageIdx(1);
  }, []);

  useEffect(() => {
    setSnackbarState({ error: "", success: "" });
    // console.log(deviceInfo);
    if (device !== -1) discoverDevice();
  }, [device]);

  useEffect(() => {
    if (device !== -1) discoverDevice();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      //  showModal();
      // cashWithdrawlSuccessPopup("602949137215","150","Bank of Maharastra","12345678544567898643","9168805979")
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setIsAllowed(false);
    if (pageIdx == 1) {
      setBalanceEnquiryState({
        mobile: "",
        aadhar: "",
        bank: null,
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
        response: null,
        isAllowed: false,
      });
    } else if (pageIdx == 2) {
      setCashWithdrawalState({
        mobile: "",
        aadhar: "",
        bank: null,
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
      });
    } else if (pageIdx == 3) {
      setMiniStatementState({
        mobile: "",
        aadhar: "",
        bank: null,
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
      });
    } else if (pageIdx == 0) {
      setEkycState({
        mobile: "",
        aadhar: "",
        pan: "",
        email: "",
        device: "",
        otp: "",
        primaryKeyId: "",
        encodeFPTxnId: "",
        error: "",
        success: "",
      });
    } else if (pageIdx == 4) {
      setAdhaarPayState({
        mobile: "",
        aadhar: "",
        bank: null,
        amount: "",
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
      });
    }
    setSnackbarState({ error: "", success: "" });
    setPidData(null);
    getLocationCoordinates();
  }, [pageIdx]);

  // TEST
  // useEffect(() => {
  //   cashWithdrawlSuccessPopup(
  //     "602949137215",
  //     "100",
  //     "IDBI",
  //     "235235235235252",
  //     "9168805979",
  //     "0",
  //     print,
  //     () => {
  //       capture(false, miniStatement);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    const agentTwoFactorData = JSON.parse(
      localStorage.getItem("agentTwoFactorData")
    );
    if (
      !agentTwoFactorData ||
      agentTwoFactorData == null ||
      agentTwoFactorData == undefined ||
      agentTwoFactorData == ""
    ) {
      swal("Failed", "Please Do Two Factor Authentication First", "error");
      return;
    } else {
      setTwoFactorAuth(agentTwoFactorData);
    }
  }, [pageIdx]);

  const twoFactorUpdate = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/retailers/aeps_two_factor_status_update.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        // setPageIdx(1);
        setTwoFactorDone(true);
      } else {
        setPageIdx(6);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const twoFactorCheck = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/retailers/get_aeps_two_factor_status.aspx",
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        // setPageIdx(1);
        setTwoFactorDone(true);
      } else {
        setPageIdx(6);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const twoFactorUpdate1 = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        step: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/aeps/twoFactorUpdate`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        setCwTwoFactorDone(true);
        // swal("Success", data.msg, "success");
        //  clearForm();
      } else {
        //  swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const twoFactorUpdate2 = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        step: "2",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/aeps/twoFactorUpdate`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        twoFactorCheckApi();
        setApTwoFactorDone(true);
        // swal("Success", data.msg, "success");
      } else {
        //  swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const fetchUserKycData = async (agentId) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: user2.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        setAgentKycData(data.data[0]);
        console.log({
          aadhar: data.data[0]?.aadharcardno,
          mobile: user2.Mobile,
        });
        setTwoFactorAuth({
          ...twoFactorAuth,
          aadhar: data.data[0]?.aadharcardno,
          mobile: user2.Mobile,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const twoFactorCheckApi = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        step: "2",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/aeps/twoFactorCheck`,
        requestOptions
      );

      const data = await res.json();

      if (data.data.ap_status === "Y") {
        setApTwoFactorDone(true);
      }

      if (data.data.cw_status === "Y") {
        setCwTwoFactorDone(true);
      }

      if (data.data.ap_status === "N") {
        setApTwoFactorDone(false);
      }

      if (data.data.cw_status === "N") {
        setCwTwoFactorDone(false);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const getLocationCoordinates = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://bumppy.com/apibumppypay/v1/web/fingpay/getLatLong.php`,
        requestOptions
      );

      const data = await res.json();

      if (data.status == "00") {
        setLatitude(data.data.latitude);
        setLongitude(data.data.longitude);
      } else {
        const coords = await getLatLong();

        if (coords) {
          setLatitude(coords[0]);
          setLongitude(coords[1]);
        }
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refreshWallet();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchUserKycData();
    twoFactorCheck();
    setActiveTab(pageIdx);
    twoFactorCheckApi();
    getLocationCoordinates();
  }, []);

  return (
    <div className="p-4 bg-light">
      <div>
        <h3
          className="text-dark text-center d-flex justify-content-start p-3 font-weight-bold"
          style={{ fontFamily: "poppins" }}
        >
          {pageIdx === 0
            ? "Complete your E-KYC"
            : pageIdx === 1
            ? "Balance Enquiry"
            : pageIdx === 2
            ? "Cash Withdrawal"
            : pageIdx === 3
            ? "Mini Statement"
            : pageIdx === 4
            ? "Aadhaar pay"
            : pageIdx === 5
            ? "Reports"
            : pageIdx === 6
            ? "Two Fctor Authentication For Cash Withdrawl"
            : pageIdx === 7
            ? "Two Fctor Authentication For AadhaarPay"
            : pageIdx === 8
            ? "Terms and Conditions"
            : ""}
        </h3>
      </div>
      <div
        className="m-0 p-0 pt-4 row bg-white"
        style={{ borderRadius: "10px" }}
      >
        <div className="col-3 justify-content-start m-0 p-0 ">
          <div className="card m-0 p-0 border-white">
            <div className="card-body m-0 p-0 ">
              <div className=" tab1-header justify-content-between">
                <div className={`tab1-item ${pageIdx === 0 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      if (!isKycDone) {
                        setPageIdx(0);
                        handleTabClick(0);
                      }
                    }}
                    disabled={isKycDone}
                    className=" btn button text-primary"
                  >
                    E-KYC
                  </button>
                </div>

                <div className={`tab1-item ${pageIdx === 6 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(6);
                      handleTabClick(1);
                    }}
                    // disabled={twoFactorDone}
                    className=" btn button text-primary"
                  >
                    {cwTwoFactorDone ? (
                      <div className="">
                        <img
                          className="mr-2"
                          src={require("../../../assets/images/success_tick.png")}
                          style={{ width: `1rem`, height: "100%" }}
                        />
                        Two Factor
                      </div>
                    ) : (
                      <div className="">
                        <img
                          className="mr-2"
                          src={require("../../../assets/images/tick_circle_gray.png")}
                          style={{ width: `1rem`, height: "100%" }}
                        />
                        Two Factor
                      </div>
                    )}
                  </button>
                </div>

                <div className={`tab1-item ${pageIdx === 7 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(7);
                      handleTabClick(2);
                    }}
                    // disabled={twoFactorDone}
                    className=" btn button text-primary"
                  >
                    {apTwoFactorDone ? (
                      <div className="">
                        <img
                          className="mr-2"
                          src={require("../../../assets/images/success_tick.png")}
                          style={{ width: `1rem`, height: "100%" }}
                        />
                        Two Factor for Aadhaar Pay
                      </div>
                    ) : (
                      <div className="">
                        <img
                          className="mr-1 text-center"
                          src={require("../../../assets/images/tick_circle_gray.png")}
                          style={{ width: `1rem`, height: "100%" }}
                        />
                        Two Factor for Aadhaar Pay
                      </div>
                    )}
                  </button>
                </div>

                <div className={`tab1-item ${pageIdx === 1 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(1);
                      handleTabClick(3);
                    }}
                    disabled={!isKycDone || !cwTwoFactorDone}
                    className=" btn button text-primary"
                  >
                    Balance Enquiry
                  </button>
                </div>

                <div className={`tab1-item ${pageIdx === 2 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(2);
                      handleTabClick(4);
                    }}
                    disabled={!isKycDone || !cwTwoFactorDone}
                    className=" btn button text-primary"
                  >
                    Cash Withdrawal
                  </button>
                </div>

                <div className={`tab1-item ${pageIdx === 3 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(3);
                      handleTabClick(5);
                    }}
                    disabled={!isKycDone || !cwTwoFactorDone}
                    className=" btn button text-primary"
                  >
                    Mini Statement
                  </button>
                </div>

                <div className={`tab1-item ${pageIdx === 4 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(4);
                      handleTabClick(6);
                    }}
                    className=" btn button text-primary"
                    disabled={!isKycDone || !apTwoFactorDone}
                  >
                    Aadhaar Pay
                  </button>
                </div>
                <div className={`tab1-item ${pageIdx === 5 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(5);
                      handleTabClick(7);
                    }}
                    className=" btn button text-primary"
                    disabled={!isKycDone}
                  >
                    Reports
                  </button>
                </div>

                <div className={`tab1-item ${pageIdx === 8 ? "active" : ""}`}>
                  <button
                    onClick={() => {
                      setPageIdx(8);
                      handleTabClick(8);
                    }}
                    className=" btn button text-primary"
                    disabled={!isKycDone}
                  >
                    Terms And Conditions
                  </button>
                </div>
              </div>
              <div className="tab1-content">
                {pageIdx === 0 && <div></div>}
                {pageIdx === 1 && <div></div>}
                {pageIdx === 2 && <div></div>}
                {pageIdx === 3 && <div></div>}
                {pageIdx === 4 && <div></div>}
                {pageIdx === 5 && <div></div>}
                {pageIdx === 6 && <div></div>}
                {pageIdx === 7 && <div></div>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-9">
          <div>
            {/* <div className="d-flex justify-content-center mt-2">
            <button
              onClick={() => {
                if (!isKycDone) setPageIdx(0);
              }}
              style={{
                background: pageIdx === 0 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={isKycDone}
            >
              {" "}
              KYC
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(6)}
              style={{
                background: pageIdx === 6 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "15rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              // disabled={twoFactorDone}
            >
              {" "}
              Two Factor
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(7)}
              style={{
                background: pageIdx === 7 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "15rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              // disabled={twoFactorDone}
            >
              {" "}
              Two Factor for AadhaarPay
            </button>
          </div>

          <div className="d-flex justify-content-center mt-2">
            <button
              onClick={() => setPageIdx(1)}
              style={{
                background: pageIdx === 1 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "15rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone || !twoFactorDone}
            >
              {" "}
              Balance Enquiry
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(2)}
              style={{
                background: pageIdx === 2 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone || !twoFactorDone}
            >
              {" "}
              Cash Withdrawal
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(3)}
              style={{
                background: pageIdx === 3 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone || !twoFactorDone}
            >
              Mini Statement
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(4)}
              style={{
                background: pageIdx === 4 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className=" btn "
              disabled={!isKycDone || !twoFactorDone}
            >
              Adhaar Pay
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(5)}
              style={{
                background: pageIdx === 5 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone}
            >
              Reports
            </button>
          </div> */}
          </div>
          {pageIdx === 0 && (
            <React.Fragment>
              <div className=" bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-0  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Mobile No</strong>
                  </label>
                  <input
                    maxLength="10"
                    //  style={{ width: "154%" }}
                    placeholder="Ente Mobile Number"
                    type="text"
                    className="form-control  rounded-100"
                    value={ekycState.mobile}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setEkycState({ ...ekycState, mobile: e.target.value });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-0 col-4 p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Aadhaar No</strong>
                  </label>
                  <input
                    maxLength="12"
                    // style={{ width: "154%" }}
                    placeholder="Enter Aadhaar Number"
                    type="text"
                    className="form-control  rounded-100"
                    value={ekycState.aadhar}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setEkycState({ ...ekycState, aadhar: e.target.value });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-0 col-4 p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Pan Card Number</strong>
                  </label>
                  <input
                    // style={{ width: "154%" }}
                    placeholder="Enter Pan Card No"
                    type="text"
                    className="form-control  rounded-100"
                    value={ekycState.pan}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setEkycState({ ...ekycState, pan: e.target.value });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-0  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Email ID</strong>
                  </label>
                  <input
                    // style={{ width: "154%" }}
                    placeholder="Enter Email ID"
                    type="text"
                    className="form-control  rounded-100"
                    value={ekycState.email}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setEkycState({ ...ekycState, email: e.target.value });
                    }}
                  />
                </Col>
              </div>
              <div
                className="form-group rounded-0  mx-3 p-2 "
                style={textStyle}
              >
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100 pl-0 pr-1 "
                  style={textStyle}
                >
                  {otpSend && (
                    <input
                      //style={{ width: "150%" }}
                      placeholder="Enter OTP"
                      type="text"
                      className="form-control  rounded-100"
                      value={ekycState.otp}
                      onChange={(e) => {
                        setSnackbarState({
                          ...snackbarState,
                          error: "",
                          success: "",
                        });
                        setEkycState({ ...ekycState, otp: e.target.value });
                      }}
                    />
                  )}
                </Col>
                <button
                  style={{
                    background: "#277BC0",
                    color: "white",
                    width: "30%",
                  }}
                  className="btn btn-primary btn-block  "
                  onClick={() => {
                    if (!otpSend) {
                      sendOTPForEKyc();
                    } else {
                      validateOTPForEKyc();
                    }
                  }}
                >
                  {otpButtonText}
                </button>
              </div>

              {otpValidated && (
                <React.Fragment>
                  <Col
                    sm={8}
                    md={8}
                    lg={7}
                    className="form-group rounded-100   mx-2"
                    style={textStyle}
                  >
                    <div>
                      <label>
                        <strong>Choose Biometric Device</strong>
                      </label>
                      <Select
                        placeholder="Select Device"
                        className="rounded-100"
                        options={deviceOptions}
                        value={selectedDevice}
                        onChange={(e) => {
                          setSnackbarState({
                            ...snackbarState,
                            error: "",
                            success: "",
                          });
                          setTwoFactorAuth({
                            ...twoFactorAuth,
                            device: e,
                          });
                          setSelectedDevice(e);
                          localStorage.setItem("device", e.value);
                          setDevice(e.value);
                        }}
                      />
                    </div>
                  </Col>

                  <div
                    className="form-group rounded-0 col p-2 mx-3"
                    style={textStyle}
                  >
                    <button
                      style={{
                        background: "#277BC0",
                        color: "white",
                        width: "30%",
                      }}
                      className="btn text-primary  bg-white border-primary btn-block ml-2 "
                      onClick={() => {
                        capture(true);
                      }}
                    >
                      {"Capture Fingerprint"}
                    </button>
                  </div>

                  <div
                    className="form-group col-8 px-2 m-4"
                    style={{ fontSize: "12px" }}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                      />
                      <label className="form-check-label text-dark fw-bold">
                        {"* I Agree"}
                      </label>
                    </div>
                    <label className="form-check-label text-danger fw-bold">
                      <span className="mr-2">1. Agent Consent Clause T&C </span>
                      <span>2. Customer Consent Clause T&C</span>
                    </label>
                  </div>
                  <div
                    className="form-group rounded-0 col px-2 mx-3"
                    style={textStyle}
                  >
                    {/* <button
                      style={{ background: "#277BC0", color: "white" }}
                      className="btn fw-bold btn-sm m-1"
                    >
                      {"Cancel"}
                    </button> */}
                    <div style={{ width: "6px" }} />
                    <button
                      style={{
                        background: "#277BC0",
                        color: "white",
                        width: "30%",
                      }}
                      className="btn btn-primary btn-block ml-2 "
                      onClick={EkycSubmit}
                    >
                      {"Submit"}
                    </button>
                  </div>
                </React.Fragment>
              )}

              {snackbarState.success != "" && (
                <div className="alert alert-success my-2" role="alert">
                  {snackbarState.success}
                </div>
              )}
              {snackbarState.error != "" && (
                <div className="alert alert-danger my-2" role="alert">
                  {snackbarState.error}
                </div>
              )}
            </React.Fragment>
          )}
          {pageIdx === 1 && (
            <React.Fragment>
              <div className=" bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Mobile No</strong>
                  </label>
                  <input
                    // style={{ width: "154%" }}
                    placeholder="Enter Mobile Number"
                    type="text"
                    maxLength="10" // Limit input to 10 characters
                    required // Make it a required field
                    className="form-control rounded-100"
                    value={balanceEnquiryState.mobile}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setBalanceEnquiryState({
                        ...balanceEnquiryState,
                        mobile: e.target.value,
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        mobile: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100 p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Bank Name</strong>
                  </label>
                  <Select
                    className="rounded-100"
                    options={bankList}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setBalanceEnquiryState({
                        ...balanceEnquiryState,
                        bank: e,
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        bank: e,
                      });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Aadhaar No</strong>
                  </label>
                  <input
                    maxLength="12" // Limit input to 12 characters
                    required // Make it a required field
                    //   style={{ width: "154%" }}
                    placeholder="Enter Aadhaar Number"
                    type="text"
                    className="form-control rounded-100"
                    value={balanceEnquiryState.aadhar}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setBalanceEnquiryState({
                        ...balanceEnquiryState,
                        aadhar: e.target.value,
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        aadhar: e.target.value,
                      });
                    }}
                  />
                </Col>
              </div>
              <Col
                sm={8}
                md={8}
                lg={7}
                className="form-group rounded-100   mx-2"
                style={textStyle}
              >
                <div>
                  <label>
                    <strong>Choose Biometric Device</strong>
                  </label>
                  <Select
                    placeholder="Select Device"
                    className="rounded-100"
                    options={deviceOptions}
                    value={selectedDevice}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        device: e,
                      });
                      setSelectedDevice(e);
                      localStorage.setItem("device", e.value);
                      setDevice(e.value);
                    }}
                  />
                </div>
              </Col>
              <div
                className="form-group rounded-100 col p-2 mx-3"
                style={textStyle}
              >
                <div className="">
                  <button
                    style={{
                      background: "#277BC0",
                      color: "white",
                      width: "30%",
                    }}
                    className="btn text-primary  bg-white border-primary btn-block ml-2 "
                    onClick={() => {
                      capture(false);
                    }}
                  >
                    {"Capture Fingerprint"}
                  </button>
                </div>

                {balanceEnquiryState.res && (
                  <div className="col-8">
                    <div
                      onClick={() => {
                        if (balanceEnquiryState.res) {
                          print();
                        }
                      }}
                      className="btn fw-bold btn-sm m-4"
                    >
                      <img
                        src={require("./../../../assets/images/download.png")}
                        style={{
                          height: "1rem",
                          width: "1rem",
                          marginRight: "2px",
                        }}
                      />
                      {"Print Receipt"}
                    </div>
                  </div>
                )}
              </div>
              {snackbarState.success != "" && (
                <div className="alert alert-success my-2" role="alert">
                  {snackbarState.success}
                </div>
              )}
              {snackbarState.error != "" && (
                <div className="alert alert-danger my-2" role="alert">
                  {snackbarState.error}
                </div>
              )}

              <div
                className="form-group col-8 px-2 m-4"
                style={{ fontSize: "12px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    onChange={() => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setBalanceEnquiryState({
                        ...balanceEnquiryState,
                        consent: !balanceEnquiryState.consent,
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        consent: !cashWithdrawalState.consent,
                      });
                    }}
                  />
                  <label className="form-check-label text-dark fw-bold">
                    {"* I Agree"}
                  </label>
                </div>
                <label className="form-check-label text-danger fw-bold">
                  <span className="mr-2">1. Agent Consent Clause T&C </span>
                  <span>2. Customer Consent Clause T&C</span>
                </label>
              </div>

              <div
                className="form-group rounded-0 col px-2 mx-3"
                style={textStyle}
              >
                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                <div style={{ width: "6px" }} />
                <button
                  style={{
                    background: "#277BC0",
                    color: "white",
                    width: "30%",
                  }}
                  className="btn btn-primary btn-block ml-2 "
                  onClick={balanceEnquirySubmit}
                  disabled={balanceEnquiryState.isLoading}
                >
                  {balanceEnquiryState.buttonText}
                </button>
              </div>

              {/* CASH WITHDRAWAL */}
              <div className="m-3">
                {isAllowed && (
                  <React.Fragment>
                    <hr></hr>

                    <Col
                      sm={8}
                      md={8}
                      lg={7}
                      className="form-group rounded-100  p-2"
                      style={textStyle}
                    >
                      <strong>{`Cash Withdrawal Available Balance : ${
                        balanceEnquiryState.res
                          ? balanceEnquiryState.res.data
                            ? balanceEnquiryState.res.data.balanceAmount
                            : "0"
                          : "0"
                      }`}</strong>
                    </Col>
                    <Col
                      sm={8}
                      md={8}
                      lg={7}
                      className="form-group rounded-100  p-2"
                      style={textStyle}
                    >
                      <label>
                        <strong>Enter Amount</strong>
                      </label>
                      <input
                        placeholder="Enter Your Amount"
                        type="text"
                        className="form-control  rounded-100"
                        value={cashWithdrawalState.amount}
                        onChange={(e) => {
                          setCashWithdrawalState({
                            ...cashWithdrawalState,
                            amount: e.target.value,
                          });
                        }}
                      />
                      {Number(cashWithdrawalState.amount) % 50 == 0 ? null : (
                        <div className="alert alert-danger my-2" role="alert">
                          Invalid Transaction Amount please enter transaction
                          amount in multiple of 50
                        </div>
                      )}
                    </Col>

                    <div
                      className="form-group rounded-100 mx-2  "
                      style={textStyle}
                    >
                      <button
                        style={{
                          background: "#277BC0",
                          color: "white",
                          width: "30%",
                        }}
                        className="btn text-primary  bg-white border-primary btn-block  "
                        onClick={() => {
                          capture(false);
                        }}
                      >
                        {" "}
                        Capture Fingerprint
                      </button>
                    </div>

                    <div
                      className="form-group rounded-0 p-1 mx-1"
                      style={textStyle}
                    >
                      {/* <button
                      style={{ background: "#277BC0", color: "white" }}
                      className="btn fw-bold btn-sm m-1"
                    >
                      {" "}
                      Cancel
                    </button> */}
                      <div style={{ width: "6px" }} />
                      <button
                        style={{
                          background: "#277BC0",
                          color: "white",
                          width: "30%",
                        }}
                        className="btn btn-primary btn-block  "
                        onClick={cashWithdrawalSubmit}
                        disabled={cashWithdrawalState.isLoading}
                      >
                        {cashWithdrawalState.buttonText}
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
          {pageIdx === 2 && (
            <React.Fragment>
              <div className=" bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100 p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Mobile No</strong>
                  </label>
                  <input
                    maxLength="10"
                    // style={{ width: "154%" }}
                    placeholder="Enter Mobile Number"
                    type="text"
                    className="form-control rounded-100"
                    value={cashWithdrawalState.mobile}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        mobile: e.target.value,
                      });
                      setMiniStatementState({
                        ...miniStatementState,
                        mobile: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Bank Name</strong>
                  </label>

                  <Select
                    className="rounded-100"
                    options={bankList}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        bank: e,
                      });
                      setMiniStatementState({ ...miniStatementState, bank: e });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Aadhaar Number</strong>
                  </label>
                  <input
                    maxLength="12"
                    // style={{ width: "154%" }}
                    placeholder="Enter Aadhaar No"
                    type="text"
                    className="form-control rounded-100"
                    value={cashWithdrawalState.aadhar}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        aadhar: e.target.value,
                      });
                      setMiniStatementState({
                        ...miniStatementState,
                        aadhar: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Enter Amount</strong>
                  </label>
                  <input
                    //   style={{ width: "154%" }}
                    placeholder="Enter Amount"
                    type="text"
                    className="form-control rounded-100"
                    value={cashWithdrawalState.amount}
                    onChange={(e) => {
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        amount: e.target.value,
                      });
                    }}
                  />
                  {Number(cashWithdrawalState.amount) % 50 == 0 ? null : (
                    <div className="alert alert-danger my-2" role="alert">
                      Invalid Transaction Amount please enter transaction amount
                      in multiple of 50
                    </div>
                  )}
                </Col>
              </div>
              <Col
                sm={8}
                md={8}
                lg={7}
                className="form-group rounded-100   mx-2"
                style={textStyle}
              >
                <div>
                  <label>
                    <strong>Choose Biometric Device</strong>
                  </label>
                  <Select
                    placeholder="Select Device"
                    className="rounded-100"
                    options={deviceOptions}
                    value={selectedDevice}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        device: e,
                      });
                      setSelectedDevice(e);
                      localStorage.setItem("device", e.value);
                      setDevice(e.value);
                    }}
                  />
                </div>
              </Col>
              <div
                className="form-group rounded-0 col p-2 mx-3"
                style={textStyle}
              >
                <div className="">
                  <button
                    style={{
                      background: "#277BC0",
                      color: "white",
                      width: "30%",
                    }}
                    className="btn text-primary  bg-white border-primary btn-block ml-2 "
                    onClick={() => {
                      capture(false);
                    }}
                  >
                    {`Capture ${
                      biAuthDone ? "Customer " : "Agent "
                    }Fingerprint`}
                  </button>
                </div>
                {/* <div className="col-8">
              <div
                onClick={() => {
                  if (cashWithdrawalState.res) {
                    print();
                  }
                }}
                href="#"
                className="btn fw-bold btn-sm m-4"
              >
                <img
                  src={require("./../../../assets/images/download.png")}
                  style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
                />
                {"Print Receipt"}
              </div>
            </div> */}
              </div>
              {snackbarState.success !== "" && (
                <div className="alert alert-success my-2" role="alert">
                  {snackbarState.success}
                </div>
              )}
              {snackbarState.error != "" && (
                <div className="alert alert-danger my-2" role="alert">
                  {snackbarState.error}
                </div>
              )}

              <div
                className="form-group col-8 px-2 m-4"
                style={{ fontSize: "12px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    onChange={() => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setCashWithdrawalState({
                        ...cashWithdrawalState,
                        consent: !cashWithdrawalState.consent,
                      });
                    }}
                  />
                  <label className="form-check-label text-dark fw-bold">
                    {"* I Agree"}
                  </label>
                </div>
                <label className="form-check-label text-danger fw-bold">
                  <span className="mr-2">1. Agent Consent Clause T&C </span>
                  <span>2. Customer Consent Clause T&C</span>
                </label>
              </div>

              <div
                className="form-group rounded-0 col px-2 mx-3"
                style={textStyle}
              >
                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                <div style={{ width: "6px" }} />
                <button
                  style={{
                    background: "#277BC0",
                    color: "white",
                    width: "30%",
                  }}
                  className="btn btn-primary btn-block ml-2 "
                  onClick={cashWithdrawalSubmit}
                  disabled={cashWithdrawalState.isLoading}
                >
                  {cashWithdrawalState.buttonText}
                </button>
              </div>
            </React.Fragment>
          )}
          {pageIdx === 3 && (
            <React.Fragment>
              <div className=" bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Mobile No</strong>
                  </label>
                  <input
                    maxLength="10"
                    //  style={{ width: "154%" }}
                    placeholder="Enter Mobile Number"
                    type="text"
                    className="form-control  rounded-100"
                    value={miniStatementState.mobile}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setMiniStatementState({
                        ...miniStatementState,
                        mobile: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Bank Name</strong>
                  </label>

                  <Select
                    //style={{ width: "154%" }}
                    className="rounded-100"
                    options={bankList}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setMiniStatementState({ ...miniStatementState, bank: e });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Enter ADHAAR Number</strong>
                  </label>
                  <input
                    maxLength="12"
                    //style={{ width: "154%" }}
                    placeholder="Enter ADHAAR Number"
                    type="text"
                    className="form-control rounded-100"
                    value={miniStatementState.aadhar}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setMiniStatementState({
                        ...miniStatementState,
                        aadhar: e.target.value,
                      });
                    }}
                  />
                </Col>
              </div>
              <Col
                sm={8}
                md={8}
                lg={7}
                className="form-group rounded-100  mx-2"
                style={textStyle}
              >
                <label>
                  <strong>Choose Biometric Device</strong>
                </label>
                <Select
                  placeholder="Select Device"
                  className="rounded-100"
                  options={deviceOptions}
                  value={selectedDevice}
                  onChange={(e) => {
                    setSnackbarState({
                      ...snackbarState,
                      error: "",
                      success: "",
                    });
                    setTwoFactorAuth({
                      ...twoFactorAuth,
                      device: e,
                    });
                    setSelectedDevice(e);
                    localStorage.setItem("device", e.value);
                    setDevice(e.value);
                  }}
                />
              </Col>
              <div
                className="form-group rounded-0 col p-2 mx-3"
                style={textStyle}
              >
                <div className="">
                  <button
                    style={{
                      background: "#277BC0",
                      color: "white",
                      width: "30%",
                    }}
                    className="btn text-primary  bg-white border-primary btn-block ml-2 "
                    onClick={() => {
                      capture(false);
                    }}
                  >
                    {" "}
                    Capture Fingerprint
                  </button>
                </div>

                {miniStatementState.res && (
                  <div className="col-8">
                    <div
                      onClick={() => {
                        if (miniStatementState.res) {
                          print();
                        }
                      }}
                      className="btn fw-bold btn-sm m-4"
                    >
                      <img
                        src={require("./../../../assets/images/download.png")}
                        style={{
                          height: "1rem",
                          width: "1rem",
                          marginRight: "2px",
                        }}
                      />
                      {"Print Last Transactions"}
                    </div>
                  </div>
                )}
              </div>
              {snackbarState.success != "" && (
                <div className="alert alert-success my-2" role="alert">
                  {snackbarState.success}
                </div>
              )}
              {snackbarState.error != "" && (
                <div className="alert alert-danger my-2" role="alert">
                  {snackbarState.error}
                </div>
              )}
              <div
                className="form-group col-8 px-2 m-4"
                style={{ fontSize: "12px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    onChange={() => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setMiniStatementState({
                        ...miniStatementState,
                        consent: !miniStatementState.consent,
                      });
                    }}
                  />
                  <label className="form-check-label text-dark fw-bold">
                    {"* I Agree"}
                  </label>
                </div>
                <label className="form-check-label text-danger fw-bold">
                  <span className="mr-2">1. Agent Consent Clause T&C </span>
                  <span>2. Customer Consent Clause T&C</span>
                </label>
              </div>
              <div
                className="form-group rounded-0 col px-2 mx-3"
                style={textStyle}
              >
                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                <div style={{ width: "6px" }} />
                <button
                  style={{
                    background: "#277BC0",
                    color: "white",
                    width: "30%",
                  }}
                  className="btn btn-primary btn-block ml-2 "
                  onClick={miniStatementSubmit}
                  disabled={miniStatementState.isLoading}
                >
                  {miniStatementState.buttonText}
                </button>
              </div>
            </React.Fragment>
          )}
          {pageIdx === 4 && (
            <React.Fragment>
              <div className=" bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Mobile No</strong>
                  </label>
                  <input
                    maxLength="10"
                    //  style={{ width: "154%" }}
                    placeholder="Enter Mobile Number"
                    type="text"
                    className="form-control rounded-100"
                    value={adhaarPayState.mobile}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setAdhaarPayState({
                        ...adhaarPayState,
                        mobile: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Bank Name</strong>
                  </label>

                  <Select
                    // style={{ width: "154%" }}
                    className="rounded-100"
                    options={bankList}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setAdhaarPayState({ ...adhaarPayState, bank: e });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100 p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Aadhaar Number</strong>
                  </label>
                  <input
                    maxLength="12"
                    // style={{ width: "154%" }}
                    placeholder="Enter Aadhaar Number"
                    type="text"
                    className="form-control rounded-100"
                    value={adhaarPayState.aadhar}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setAdhaarPayState({
                        ...adhaarPayState,
                        aadhar: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Enter Amount</strong>
                  </label>
                  <input
                    // style={{ width: "154%" }}
                    placeholder="Enter Amount"
                    type="text"
                    className="form-control rounded-100"
                    value={adhaarPayState.amount}
                    onChange={(e) => {
                      setAdhaarPayState({
                        ...adhaarPayState,
                        amount: e.target.value,
                      });
                    }}
                  />
                  {Number(adhaarPayState.amount) % 50 == 0 ? null : (
                    <div className="alert alert-danger my-2" role="alert">
                      Invalid Transaction Amount please enter transaction amount
                      in multiple of 50
                    </div>
                  )}
                </Col>
              </div>

              <Col
                sm={8}
                md={8}
                lg={7}
                className="form-group rounded-100   mx-2"
                style={textStyle}
              >
                <label>
                  <strong>Choose Biometric Device</strong>
                </label>
                <Select
                  placeholder="Select Device"
                  className="rounded-100"
                  options={deviceOptions}
                  value={selectedDevice}
                  onChange={(e) => {
                    setSnackbarState({
                      ...snackbarState,
                      error: "",
                      success: "",
                    });
                    setTwoFactorAuth({
                      ...twoFactorAuth,
                      device: e,
                    });
                    setSelectedDevice(e);
                    localStorage.setItem("device", e.value);
                    setDevice(e.value);
                  }}
                />
              </Col>
              <div
                className="form-group rounded-0 col p-2 mx-3"
                style={textStyle}
              >
                <div className="">
                  <button
                    style={{
                      background: "#277BC0",
                      color: "white",
                      width: "30%",
                    }}
                    className="btn text-primary  bg-white border-primary btn-block ml-2 "
                    onClick={() => {
                      capture(false);
                    }}
                  >
                    {`Capture ${
                      biAuthDone ? "Customer " : "Agent "
                    }Fingerprint`}
                  </button>
                </div>

                {adhaarPayState.res && (
                  <div className="col-8">
                    <div
                      onClick={() => {
                        if (adhaarPayState.res) {
                          print();
                        }
                      }}
                      href="#"
                      className="btn fw-bold btn-sm m-4"
                    >
                      <img
                        src={require("./../../../assets/images/download.png")}
                        style={{
                          height: "1rem",
                          width: "1rem",
                          marginRight: "2px",
                        }}
                      />
                      {"Print Receipt"}
                    </div>
                  </div>
                )}
              </div>
              {snackbarState.success != "" && (
                <div className="alert alert-success my-2" role="alert">
                  {snackbarState.success}
                </div>
              )}
              {snackbarState.error != "" && (
                <div className="alert alert-danger my-2" role="alert">
                  {snackbarState.error}
                </div>
              )}

              <div
                className="form-group col-8 px-2 m-4"
                style={{ fontSize: "12px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    onChange={() => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setAdhaarPayState({
                        ...adhaarPayState,
                        consent: !adhaarPayState.consent,
                      });
                    }}
                  />
                  <label className="form-check-label text-dark fw-bold">
                    {"* I Agree"}
                  </label>
                </div>
                <label className="form-check-label text-danger fw-bold">
                  <span className="mr-2">1. Agent Consent Clause T&C </span>
                  <span>2. Customer Consent Clause T&C</span>
                </label>
              </div>

              <div
                className="form-group rounded-0 col px-2 mx-3"
                style={textStyle}
              >
                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                <div style={{ width: "6px" }} />
                <button
                  style={{
                    background: "#277BC0",
                    color: "white",
                    width: "30%",
                  }}
                  className="btn btn-primary btn-block ml-2 "
                  onClick={adhaarPaySubmit}
                  disabled={adhaarPayState.isLoading}
                >
                  {adhaarPayState.buttonText}
                </button>
              </div>
            </React.Fragment>
          )}
          {pageIdx === 5 && (
            <React.Fragment>
              <AEPSReconcileReportTable />
            </React.Fragment>
          )}

          {pageIdx === 6 && (
            <React.Fragment>
              <div className=" bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100   p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Mobile No.</strong>
                  </label>
                  <input
                    maxLength="10"
                    // style={{ width: "154%" }}
                    placeholder="Enter Mobile Number"
                    type="text"
                    className="form-control rounded-100"
                    value={twoFactorAuth.mobile}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        mobile: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Bank Name</strong>
                  </label>

                  <Select
                    //style={{ width: "154%" }}
                    className="rounded-100"
                    options={bankList}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({ ...twoFactorAuth, bank: e });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2"
                  style={textStyle}
                >
                  <label>
                    <strong>Aadhaar Number</strong>
                  </label>
                  <input
                    maxLength="12"
                    // style={{ width: "154%" }}
                    placeholder="Enter Aadhaar Number"
                    type="text"
                    className="form-control rounded-100"
                    value={twoFactorAuth.aadhar}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        aadhar: e.target.value,
                      });
                    }}
                  />
                </Col>
              </div>
              <Col
                sm={8}
                md={8}
                lg={7}
                className="form-group rounded-100  mx-2"
                style={textStyle}
              >
                <div>
                  <label>
                    <strong>Choose Biometric Device</strong>
                  </label>
                  <Select
                    placeholder="Select Device"
                    className="rounded-100"
                    options={deviceOptions}
                    value={selectedDevice}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        device: e,
                      });
                      setSelectedDevice(e);
                      localStorage.setItem("device", e.value);
                      setDevice(e.value);
                    }}
                  />
                </div>
              </Col>
              {aepsTwoFactorDone && (
                <div className="d-flex mt-2">
                  <div className="alert alert-success my-2" role="alert">
                    AEPS Two Factor Authentication Done For AEPS Services you
                    need to Do Two Factor Authentication for Aadhaar Pay service
                    Please Click on Capture Fingerprint Button
                  </div>
                </div>
              )}

              <div
                className="form-group rounded-0 col p-2 mx-3"
                style={textStyle}
              >
                <div className="">
                  <button
                    style={{
                      background: "#277BC0",
                      color: "white",
                      width: "30%",
                    }}
                    className="btn text-primary  bg-white border-primary btn-block ml-2 "
                    onClick={() => {
                      capture(false);
                    }}
                  >
                    {"Capture Fingerprint"}
                  </button>
                </div>
              </div>
              {snackbarState.success != "" && (
                <div className="alert alert-success my-2" role="alert">
                  {snackbarState.success}
                </div>
              )}
              {snackbarState.error != "" && (
                <div className="alert alert-danger my-2" role="alert">
                  {snackbarState.error}
                </div>
              )}

              <div
                className="form-group col-8 px-2 m-4"
                style={{ fontSize: "12px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    onChange={() => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        consent: !cashWithdrawalState.consent,
                      });
                    }}
                  />
                  <label className="form-check-label text-dark fw-bold">
                    {"* I Agree"}
                  </label>
                </div>
                <label className="form-check-label text-danger fw-bold">
                  <span className="mr-2">1. Agent Consent Clause T&C </span>
                  <span>2. Customer Consent Clause T&C</span>
                </label>
              </div>

              <div
                className="form-group rounded-0 col px-2 mx-3"
                style={textStyle}
              >
                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                <div style={{ width: "6px" }} />
                <button
                  style={{
                    background: "#277BC0",
                    color: "white",
                    width: "30%",
                  }}
                  className="btn btn-primary btn-block ml-2 "
                  onClick={twoFactorAuthSubmit}
                  disabled={twoFactorAuth.isLoading}
                >
                  {twoFactorAuth.buttonText}
                </button>
              </div>
            </React.Fragment>
          )}

          {pageIdx === 7 && (
            <React.Fragment>
              <div className="bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100 p-2 "
                  style={textStyle}
                >
                  <label>
                    <strong>Mobile No.</strong>
                  </label>
                  <input
                    maxLength="10"
                    // style={{ width: "154%" }}
                    placeholder="Enter Mobile Number"
                    type="text"
                    className="form-control rounded-100"
                    value={twoFactorAuth.mobile}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        mobile: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2 "
                  style={textStyle}
                >
                  <label>
                    <strong>Bank Name</strong>
                  </label>

                  <Select
                    // style={{ width: "154%" }}
                    className="rounded-100"
                    options={bankList}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({ ...twoFactorAuth, bank: e });
                    }}
                  />
                </Col>
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100  p-2 "
                  style={textStyle}
                >
                  <label>
                    <strong>Aadhaar Number</strong>
                  </label>
                  <input
                    maxLength="12"
                    // style={{ width: "154%" }}
                    placeholder="Enter Aadhaar Number"
                    type="text"
                    className="form-control rounded-100"
                    value={twoFactorAuth.aadhar}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        aadhar: e.target.value,
                      });
                    }}
                  />
                </Col>
              </div>
              <Col
                sm={8}
                md={8}
                lg={7}
                className="form-group rounded-100 ml-2"
                style={textStyle}
              >
                <div>
                  <label>
                    <strong>Choose Biometric Device</strong>
                  </label>
                  <Select
                    placeholder="Select Device"
                    className="rounded-100"
                    options={deviceOptions}
                    value={selectedDevice}
                    onChange={(e) => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        device: e,
                      });
                      setSelectedDevice(e);
                      localStorage.setItem("device", e.value);
                      setDevice(e.value);
                    }}
                  />
                </div>
              </Col>
              {aepsTwoFactorDone && (
                <div className="d-flex mt-2">
                  <div className="alert alert-success my-2" role="alert">
                    AEPS Two Factor Authentication Done For AEPS Services you
                    need to Do Two Factor Authentication for Aadhaar Pay service
                    Please Click on Capture Fingerprint Button
                  </div>
                </div>
              )}

              <div
                className="form-group rounded-0 col p-2 mx-3"
                style={textStyle}
              >
                <div className="" style={{ width: "30%" }}>
                  <button
                    style={{
                      background: "#277BC0",
                      color: "white",
                      width: "100%",
                    }}
                    className="btn border-primary bg-white text-primary "
                    onClick={() => {
                      capture(false);
                    }}
                  >
                    {"Capture Fingerprint"}
                  </button>
                </div>
              </div>
              {snackbarState.success !== "" && (
                <div className="alert alert-success my-2" role="alert">
                  {snackbarState.success}
                </div>
              )}
              {snackbarState.error !== "" && (
                <div className="alert alert-danger my-2" role="alert">
                  {snackbarState.error}
                </div>
              )}

              <div
                className="form-group col-8 px-2 m-4"
                style={{ fontSize: "12px" }}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    onChange={() => {
                      setSnackbarState({
                        ...snackbarState,
                        error: "",
                        success: "",
                      });
                      setTwoFactorAuth({
                        ...twoFactorAuth,
                        consent: !cashWithdrawalState.consent,
                      });
                    }}
                  />
                  <label className="form-check-label text-dark fw-bold">
                    {"* I Agree"}
                  </label>
                </div>
                <label className="form-check-label text-danger fw-bold">
                  <span className="mr-2">1. Agent Consent Clause T&C </span>
                  <span>2. Customer Consent Clause T&C</span>
                </label>
              </div>

              <div
                className="form-group rounded-0 col px-2 mx-3"
                style={textStyle}
              >
                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                <div style={{ width: "6px" }} />
                <button
                  style={{
                    background: "#277BC0",
                    color: "white",
                    width: "30%",
                  }}
                  className="btn btn-block"
                  onClick={twoFactorAuthSubmit2}
                  disabled={twoFactorAuth.isLoading}
                >
                  {twoFactorAuth.buttonText}
                </button>
              </div>
            </React.Fragment>
          )}

          {pageIdx === 8 && (
            <React.Fragment>
              <div className="bg-white m-3">
                <Col
                  sm={8}
                  md={8}
                  lg={7}
                  className="form-group rounded-100 p-2 "
                  style={textStyle}
                >
                  {termsUrl.map((url) => (
                    <div className=" tab1-header justify-content-between">
                      <div className={`tab1-item`}>
                        <button
                          onClick={() => {
                            openInNewTab(url.value);
                          }}
                          className=" btn button text-primary"
                        >
                          {url.label}
                        </button>
                      </div>
                    </div>
                  ))}
                </Col>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const SecureCall = () => {
  const { username, password } = basicAuth;
  const { user, Profileinfo } = useContext(GlobalContext);
  const [ok, setOk] = useState(false);
  const [loading, setLoading] = useState(true);
  const [credentials, setCredentials] = useState(true);

  const twoFactorCheck = async () => {
    try {
      const raw = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://apitranxt.bumppypay.com//recApiFinal/apiget.aspx?method=getkycstatus&userid=${user.ReferId}`,
        requestOptions
      );

      const data = await res.json();
      if (data.Id == "Y") {
        setOk(true);
        setCredentials({
          pipe1_kyc: data.f_ekyc_status,
          ReferId: user.ReferId,
          shopname: Profileinfo?.shopname,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const generateToken = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLCo-SP7zDi-UItOiNUMUIYQjRENG45tKVcI7QPfBoEqslegNz-_NVq5v0578HZkLhnAjqkzA2ffSqn7eRdyqaeF8LsgeOLD2cD8QCaK72cUsg8LTmkpWLn4bwOBSY9SfbLxOPB2WZ7bWXN1p-OzfciLOy_K6kVSkjDXeWz-MOUeYmGk22PLPssypmmdqPk_vo2m8gD-uql6hmJThZYoByPcXaS_hYJYOeQl-qArcGBDn01moGu1Gpw9ZIWQGkjmoQvK0hLS9FdsBOj2sK8s483-jSgSEt4vLskERTxapSlFeWvZpmVkEhRDK3HryOEJYpo8vXEpnj_HWFUY4vtG7LdJt220hzfIUXiWL06_r2aUOjEu3MfsI9y5UD09hb6Y7latEvVeqZ1fhN15dcDq7j0N2mAPYE8yDL7b-jdn3VctZnixzcOqel5A0ZPeXvDjtxAPsws_447T6TgaDfHZD9DIsNH8kjgEITM7qI52xgC9RUxBBY8wr0Ll5pKQzesFTqpdJ2i61Y5NvgsDMbL7XcDgw9eQPHvLf5zpatuOYw5zYYxXHq2O0PFtKo4zoGi9rn2VI0S0uPjCy4ynYQoWMXiGOsOCXaLV2N3dCrirdWeVUMUbRS8pY4FQbCZgnAsndFaoo30KAHHg9VBwSyIOckyo"
      );

      var raw = JSON.stringify({
        userid: COMPANY_CODE,
        agentid: user.ReferId,
        retailerid: COMPANY_RETAILER_COMPANY_ID,
        shopname: Profileinfo.shopname,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/AEPS/GenerateToken`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      if (data.status == "00") {
        setOk(true);
        setCredentials(data);
      } else {
        swal("Failed", "please check your onboarding status", "error");
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Profileinfo) twoFactorCheck();
    setLoading(true);
    setOk(false);
  }, [user, Profileinfo]);

  return loading ? (
    <Spinner />
  ) : ok ? (
    <App credentials={credentials} />
  ) : (
    <Spinner />
  );
};

const Spinner = () => {
  return (
    <div>
      <div className="spinner-wrapper">
        <div className="donut"></div>
      </div>
    </div>
  );
};

export default SecureCall;
