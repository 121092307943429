import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { writeFile, utils } from "xlsx";
import { useHistory } from "react-router";
import { BASE_URL_AGENT, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import { Link } from "react-router-dom";
import { useQuery } from "../../custom-hooks/useQuery";
import { decryptAES } from "../../utils/utilFuctions";

export const UserKycDetails = () => {
  const { user } = useContext(GlobalContext);
  const history = useHistory();
  let query = useQuery();
  const encryptedData = query.get("id");
  const userId = decryptAES(encryptedData);
  console.log(userId);
  console.log(encryptedData);

  



  return (
    <GlobalContext.Consumer>
      {(context) =>
        // context.user == null ? (
        //   <Redirect to="/" />
        // ) : 
        (
          <>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            </div>
            <div style={{ backgroundColor: COLOR_BACKGROUND_BODY }}>
              <div className="row ">
                <div className="col-12 grid-margin">
                  <div
                    className="border p-3"
                    style={{ backgroundColor: "#002970", color: "white" }}
                  >
                    <b> Admin DashBoard</b>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default UserKycDetails;
