import React, { useState, useEffect, useContext } from "react";
import '../api_partner/custom-tabs.css';
import { Form, Button } from "react-bootstrap";
import swal from "sweetalert";

const EsignDocument = () => {
    const [agentId, setAgentId] = useState("");

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    return (


        <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>

            <div style={{ background: "#fff" }}>
                {" "}
                <div
                    className="row justify-content-between m-0 px-4 py-2"
                    style={{ background: "" }}
                >
                    <h2
                        style={{
                            margin: "0",
                            fontWeight: "bolder",
                        }}
                    >
                        E Sign
                    </h2>
                    <button
                        className="btn btn-primary mx-4"
                        //  onClick={logoutDeveloperLogin}
                        style={{ width: "7rem" }}
                    >
                        Log Out
                    </button>
                </div>
            </div>
            <div>

                <div>

                    <div className="card m-0 p-0 pt-3">
                        <div className="card-body m-0 p-0">



                            <div className="">
                                <div defaultActiveKey="profile" className=" tab-header justify-content-between">
                                    <div
                                        className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        E Sign Doc Upload
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        All E Sign Docs
                                    </div>

                                </div>
                                <div className="tab-content">
                                    {activeTab === 0 && <div><EsignDocumentGenerate agentId={agentId} /></div>}
                                    {activeTab === 1 && <div><AllEsignDocument agentId={agentId} /></div>}

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </div>

    );
};

const EsignDocumentGenerate = () => {
    const [formData, setFormData] = useState({
        signerName: "",
        signerEmail: "",
        signerCity: "",
        purpose: "",
        pdfFile: null,
        numPages: null,
        file: null
    });

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleChange = (event) => {
        const { name, value, type, files } = event.target;

        setFormData((prev) => ({
            ...prev,
            [name]: type === "file" ? files[0] : value,
            numPages: null,
        }));

        if (type === "file" && files[0]) {
            countPages(files[0]);
        }
    };

    const countPages2 = (file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const arrayBuffer = reader.result;
            const uint8Array = new Uint8Array(arrayBuffer);
            const pdfData = new Uint8Array(uint8Array);

            if (
                pdfData[0] === 0x25 &&
                pdfData[1] === 0x50 &&
                pdfData[2] === 0x44 &&
                pdfData[3] === 0x46
            ) {
                const pageCountMatch = /\/Type\s*\/Page[^s]/g;
                const matches = String.fromCharCode
                    .apply(null, pdfData)
                    .match(pageCountMatch);

                const numPages = matches ? matches.length : 0;

                setFormData((prev) => ({ ...prev, numPages }));
            } else {
                console.error("Invalid PDF file format");
            }
        };

        reader.readAsArrayBuffer(file);
    };
    const countPages = (file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const arrayBuffer = reader.result;
            const uint8Array = new Uint8Array(arrayBuffer);

            if (
                uint8Array[0] === 0x25 &&
                uint8Array[1] === 0x50 &&
                uint8Array[2] === 0x44 &&
                uint8Array[3] === 0x46
            ) {
                const pageCountMatch = /\/Type\s*\/Page[^s]/g;

                // Convert Uint8Array to string
                let pdfString = '';
                for (let i = 0; i < uint8Array.length; i++) {
                    pdfString += String.fromCharCode(uint8Array[i]);
                }

                // Perform the match operation
                const matches = pdfString.match(pageCountMatch);
                const numPages = matches ? matches.length : 0;

                setFormData(prev => ({ ...prev, numPages }));
            } else {
                console.error("Invalid PDF file format");
            }
        };

        reader.readAsArrayBuffer(file);


    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.pdfFile) {
            convertToBiteArray(formData.pdfFile, (ByteArray) => {

                try {
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    var raw = JSON.stringify({
                        userid: "BR1004",
                        type: "A",
                        signer_name: formData.signerName,
                        signer_email: formData.signerEmail,
                        signer_city: formData.signerCity,
                        purpose: formData.purpose,
                        redirect: "https://bumppy.in/api_integration/agent_concern/v1/esignDocRedirect.php",
                        pages: formData.numPages,
                        organization: "BUMPPY MEDIA PVT LTD",
                        doc_data: ByteArray,
                        position: selectedOption
                    });

                    var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                    };

                    fetch(
                        `https://bumppy.in/api_integration/agent_concern/v1/uploadDocsEsign.php`,
                        requestOptions
                    ).then((res) => res.json()).then((data) => {
                        if (data?.status == "0") {
                            swal("Success", `https://bumppy.in/api_integration/agent_concern/v1/esignDoc.php?doc=${data?.pdf_name}`, "success");
                        }
                        else { swal("Error", "Something went wrong", "error"); }
                    }).catch((err) => {
                        console.log("error", err);
                    });
                } catch (error) { }
            })
        }


    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    return (
        <div className="m-5">
            <Form >
                <Form.Group controlId="signerName">
                    <Form.Label>Signer Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter signer name"
                        name="signerName"
                        value={formData.signerName}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="signerEmail">
                    <Form.Label>Signer Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter signer email"
                        name="signerEmail"
                        value={formData.signerEmail}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="signerCity">
                    <Form.Label>Signer City</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter signer city"
                        name="signerCity"
                        value={formData.signerCity}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="purpose">
                    <Form.Label>Purpose</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter purpose"
                        name="purpose"
                        value={formData.purpose}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="pdfFile">
                    <Form.Label>Upload PDF</Form.Label>
                    <Form.Control
                        type="file"
                        accept=".pdf"
                        name="pdfFile"
                        onChange={(e) => {
                            countPages(e.target.files[0])
                            setFormData({ ...formData, file: e.target.files[0], pdfFile: e.target.files[0] })
                        }}
                    />
                </Form.Group>

                {formData.numPages !== null && (
                    <p className="mb-2">Number of Pages in PDF: {formData.numPages}</p>
                )}

                <Form.Group >
                    <Form.Label>Position</Form.Label>
                    <div className="row">
                        <label className="">
                            <input
                                type="radio"
                                value="bootom_left"
                                checked={selectedOption === 'bootom_left'}
                                onChange={handleOptionChange}
                            />
                            Bottom Left
                        </label>
                        <div className="mx-1"></div>
                        <label>
                            <input
                                type="radio"
                                value="bottom_right"
                                checked={selectedOption === 'bottom_right'}
                                onChange={handleOptionChange}
                            />
                            Bottom Right
                        </label>
                    </div>
                </Form.Group>



                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form>
        </div>
    );
};

const AllEsignDocument = () => {
    const [data, setData] = useState([]);

    const getDocs = async (e) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(
                `https://bumppy.in/api_integration/agent_concern/v1/getAllEsignDocList.php`,
                requestOptions
            ).then((res) => res.json()).then((data) => {
                if (data?.status == "0") {
                    setData(data?.data)
                }
            }).catch((err) => {
                console.log("error", err);
            });
        } catch (error) { }


    };

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
           // setIsCopied(true);
           swal("Copied to clipboard", "Copied", "success");
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    };

    const openInNewTab = (url) => {

        const newWindow = window.open(
            url,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getDocs()
    }
        , [])

    return (
        <div className="m-0">
            <div className="m-0 p-0 card mx-3 center-block scroll-inner">
                <table
                    className="dashboard-table-style"
                    style={{ width: "100%", overflowX: "auto" }}
                >
                    <tr>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}> {"Sr"}</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Signer</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>organization</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>purpose</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Action</th>
                    </tr>
                    {data.map((e, ind) => (
                        <tr key={ind}>
                            <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
                            <td style={{ fontSize: "1rem" }}>
                                <div>
                                    <div>{e.signer_name}</div>
                                    <div>{e.signer_email}</div>
                                    <div>{e.signer_city}</div>
                                </div>
                            </td>
                            <td style={{ fontSize: "1rem" }}>{e.organization}</td>
                            <td style={{ fontSize: "1rem" }}>{e.purpose}</td>
                            <td style={{ fontSize: "1rem" }}>

                                <div className="row ">
                                    <button className="btn btn-sm btn-primary" onClick={() => openInNewTab(`${e.doc_url}`)}>View</button>
                                    <div className="mx-1"></div>
                                    <button className="btn btn-sm btn-primary" onClick={() => openInNewTab(`https://bumppy.in/api_integration/agent_concern/v1/esignDoc.php?doc=${e.doc_name}`)}>E Sign</button>
                                    <div className="mx-1"></div>
                                    <button className="btn btn-sm btn-primary" onClick={() => handleCopy(`https://bumppy.in/api_integration/agent_concern/v1/esignDoc.php?doc=${e.doc_name}`)}>Copy to Clipboard</button>

                                </div>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default EsignDocument;
