import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "tid",
    text: "Transaction ID",
  },
  {
    dataField: "username",
    text: "User",
  },
  {
    dataField: "rch_mobile",
    text: "Account Number",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_recharge_date",
    text: "Transaction Date",
  },
  {
    dataField: "dates",
    text: "Refund Date",
  },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const BBPSRefundReportTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user ,  setTransactionLoaderState} = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/refunds/get_bbps_refund_report_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  const refundDMTTransaction = async (tid) => {
    try {
     setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        trans_id: tid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/refunds/aepssettlement_refund.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }  setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  const refundVerification = (tid, amount, RetailerID) => {
    swal({
      title: "Are you sure?",
      text: `Refund amount of ${amount} to ${RetailerID}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        refundDMTTransaction(tid);
      }
    });
  };

  const showResponse = (response) => {
    swal2(
      <div className="d-flex overflow-auto">
        <strong>{JSON.stringify(response)}</strong>
      </div>
    );
  };

  useEffect(() => {
    getDMTReport();
  }, []);

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height:'20%',
   
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <>
  <div className="row m-0 p-0  pt-1 align-items-center">
              <div className="col-4 m-0 p-0">

               <div className="m-0 p-0 pl-2"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                  }}
                >
                  BBPS Refund Report
                </div>
                </div>

<div className="col-8 m-0 p-0 ">
<div className="row  m-0 p-0  justify-content-end">
        
                <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
        
              
        
        
        
        <div className="pr-1 pl-2 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

       
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
          <Button
              className="rounded-100 btn btn-lg btn-block "
             
              onClick={getDMTReport}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
        <div className="m-0 p-0 hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `Account_Ledger_Report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
              <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
             <div div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
      </div>




</div>

      </div>
































    
<hr className="m-0 p-0"/>     















    

      <div className="scroll-inner admin-fix-width">
        <table className="my-table transparent-table text-center border-bottom"
          style={{ width: "100%", overflowX: "auto" ,border: "0px" ,overflow: "auto", }}>
          <tr className="borderless-table text-center border-bottom">
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Sr"}</th>

            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Op ID"}</th>
            <th className="my-table transparent-table text-center border-bottom py-3 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Account"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Remitter"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Amount"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"A Profit"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Profit"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Balance"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Status"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Action"}</th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
              <td
                style={{ fontSize: "0.8rem" }}
                className={`${
                  e.rch_status_desc == "Success"
                    ? "bg-success"
                    : e.rch_status_desc == "Fail"
                    ? "bg-danger"
                    : "bg-warning"
                }`}
              >
                {" "}
                {ind + 1}
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.username}</span>
                  <span className="text-warning">{`TID : ${e.tid}`}</span>
                  <span>{e.rch_indate}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">{e.Name}</span>
                  <span>{e.rch_mobile}</span>
                  <span>{"Pool ID"}</span>
                  <span>{e.dmt_pool_id}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  {/* <span>{e.username}</span> */}
                  <span className="text-success">{"Remitter"}</span>
                  <span>{e.remitterno}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_amount}</span>
                  <span className="text-success">{e.ttypes}</span>
                  <span className="text-danger">{`Deduction : ${twoDecimalPlaces(
                    e.dmt_deduction
                  )}`}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  0.00
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  <span>{`C Com :${twoDecimalPlaces(e.rch_com_cnf)}`}</span>
                  <span>{`D Com :${twoDecimalPlaces(
                    e.rch_com_distributer
                  )}`}</span>
                  <span className="text-warning">{`Charges :${twoDecimalPlaces(
                    e.DmtCharges
                  )}`}</span>
                  <span className="text-success">{`Comm :${twoDecimalPlaces(
                    e.rch_com_retailer
                  )}`}</span>
                  <span className="text-danger">{`TDS :${twoDecimalPlaces(
                    e.tds
                  )}`}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <div className="d-flex flex-column align-items-center">
                  <span className="text-warning">{`Opening :${twoDecimalPlaces(
                    e.rch_before_balance
                  )}`}</span>
                  <span className="text-primary">{`Closing :${twoDecimalPlaces(
                    e.rch_after_balance
                  )}`}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                <span
                  className={`badge text-light ${
                    e.rch_status_desc == "Success"
                      ? "bg-success"
                      : e.rch_status_desc == "Fail"
                      ? "bg-danger"
                      : "bg-warning"
                  }`}
                  style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
                >
                  {`${e.rch_status_desc}`}
                </span>
              </td>

              <td className="text-center border-bottom" style={{ fontSize: 10 ,"fontFamily":"Poppins","fontSize":"16px","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <Button className="btn btn-sm btn-primary btn-block rounded-0 m-1">
                    {" "}
                    Rehit
                  </Button>
                  <Button className="btn btn-sm btn-success btn-block rounded-0 m-1">
                    {" "}
                    Status Update
                  </Button>
                  <Button
                    className="btn btn-sm btn-info btn-block rounded-0 m-1"
                    onClick={() => {
                      refundVerification(e.tid, e.rch_amount, e.username);
                    }}
                  >
                    {" "}
                    Manual Refund
                  </Button>
                  <Button
                    className="btn btn-sm btn-warning btn-block rounded-0 m-1"
                    onClick={() => showResponse(e.ApiResponse)}
                  >
                    {" "}
                    Full Bank Response
                  </Button>
                  <Button className="btn btn-sm btn-dark btn-block rounded-0 m-1">
                    {" "}
                    Feedback
                  </Button>
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default BBPSRefundReportTable;
