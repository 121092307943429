import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };

const ModuleTest = () => {
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [virtualAccount, setVirtualAccount] = useState(null);
  const [List, setList] = useState([]);
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const [accountCreated, setAccountCreated] = useState(
    localStorage.getItem("va-created") || false
  );
  const [TopupData, setTopupData] = useState({
    msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
    success: false,
  });
  const [recordLoading, setRecordLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [reports, setReports] = useState([]);

  return (
    <div style={{ padding: "2rem" }}>
      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <h2
          style={{
            margin: "0",
            background: "#3676D8",
            padding: "12px",
            borderTopLeftRadius: "22px",
            color: "#FFFFFF",
            borderTopRightRadius: "22px",
            fontWeight: "bolder",
          }}
        >
          Tariff
        </h2>
        <div className="card-body">
          <Tabs defaultActiveKey="View" id="uncontrolled-tab-example">
            <Tab eventKey="View" title="View" className="test-tab">
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  View Agent
                </div>

                <h2> View Tariff</h2>
              </div>
            </Tab>

            <Tab eventKey="Create" title="Create" className="test-tab">
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  Create Tariff
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default ModuleTest;
