import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { RaiseTicketModal } from "../util/RaiseTicketModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import JSONTable from "../../utils/JsonTable";
export const columns = [
    {
        dataField: "txn_id",
        text: "TXN ID",
    },
    {
        dataField: "bank_rrn",
        text: "Bank RRN",
    },
    {
        dataField: "tid",
        text: "REF",
    },
    {
        dataField: "rch_indate",
        text: "Date",
    },
    {
        dataField: "user_code",
        text: "User Code",
    },
    {
        dataField: "user_name",
        text: "User Name",
    },
    {
        dataField: "Name",
        text: "Bank Name",
    },
    {
        dataField: "rch_mobile",
        text: "Retailer Mobile",
    },
    {
        dataField: "ttyp",
        text: "Type",
    },
    {
        dataField: "rch_before_balance",
        text: "Opening Balance",
    },
    {
        dataField: "rch_amount",
        text: "Amount",
    },
    {
        dataField: "rch_after_balance",
        text: "Closing Balance",
    },
    {
        dataField: "rch_com_cnf",
        text: "Master Comm",
    },
    {
        dataField: "rch_com_distributer",
        text: "Dist Comm",
    },
    {
        dataField: "rch_com_retailer",
        text: "Retailer Comm",
    },
    {
        dataField: "sales_user_code",
        text: "Sales Code",
    },
    {
        dataField: "sales_user_name",
        text: "Sales Name",
    },
    {
        dataField: "sales_user_mobile",
        text: "Sales Mobile Number",
    },
    {
        dataField: "rch_status_desc",
        text: "Status",
    },
    {
        dataField: "shop_pin_code",
        text: "Pin Code",
    },
    {
        dataField: "shop_city",
        text: "City",
    },
    {
        dataField: "shop_state",
        text: "State",
    },
];

const servicesOption = [
    { value: 'Cash Withdrawal', label: 'Cash Withdrawal' },
    { value: 'Balance Enquiry', label: 'Balance Enquiry' },
    { value: 'Mini Statement', label: 'Mini Statement' },
    { value: 'Settlement', label: 'AEPS Settlement' },
    { value: 'UPI_SETTLEMENT', label: 'UPI Settlement' },
    { value: 'Aadhar Pay', label: 'Aadhar Pay' }
];

const AdminActivityLogsTable = () => {
    const REPORT_TYPE = "UserActivityLogs";
    const [pageAccess, setPageAccess] = useState({});
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [providerResponse, setProviderResponse] = useState({});
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(100);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    let fromDate = sessionStorage.getItem(`${REPORT_TYPE}_fromDate`)
        ? new Date(sessionStorage.getItem(`${REPORT_TYPE}_fromDate`))
        : new Date();
    let toDate = sessionStorage.getItem(`${REPORT_TYPE}_toDate`)
        ? new Date(sessionStorage.getItem(`${REPORT_TYPE}_toDate`))
        : new Date();
    const [startDate, setStartDate] = useState(fromDate);
    const [endDate, setEndDate] = useState(toDate);
    const { Profileinfo } = useContext(GlobalContext);
    console.log(data);

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const twoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).toString();
    };

    const getTransactionType = (t) => {
        switch (t) {
            case "CW":
                return "Cash Withdrawal";
            case "MS":
                return "Mini Statement";
            case "AP":
                return "Aadhaar Pay";
            case "M":
                return "Aadhaar Pay";
            case "BE":
                return "Balance Enquiry";
            case "AUO":
                return "2FA";
            default:
                return "";
        }
    };

    const getUserActivity = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            var raw = JSON.stringify({
                fromdate: dateFormatter(startDate),
                todate: dateFormatter(endDate),
                userid: user.pkid
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/Admin/getAdminUserActivityLogs`,
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode =="00" || data.data?.length > 0) {
                setData(data.data)
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const searchBarStyle = {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "0px",
        backgroundColor: "#fff",
        width: "35%", // Adjust the width as needed
        height: "20%",
    };
    const inputStyle = {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: "16px",
        marginLeft: "8px",
        width: "50%",
        fontFamily: "Poppins",
        fontWeight: "400",
    };
    const iconStyle = {
        color: "rgba(0, 0, 0, 0.5)",
    };

    const positionStyle = {
        position: "absolute",
        top: -27,
        left: 0,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };
    const positionStyle1 = {
        position: "absolute",
        top: -38,
        left: 0,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };

    const getPageAccess = (menuId) => {
        const sub_menus_access_info = JSON.parse(sessionStorage.getItem("sub_menus"));
        sub_menus_access_info.forEach((acc) => {
            if (acc.pk_menu_id == menuId) {
                setPageAccess(acc);
            }
        })
    }

    useEffect(() => {
        getUserActivity()
        getPageAccess(65);
    }, [])


    return (
        <>

            <div className="row m-0 p-0 mb-3">
                <div className="col-12 m-0 p-0">
                    <div className="row m-0 p-0">


                        <div className=" pr-1 pl-2" style={{ width: "15%" }}>
                            <Form.Group>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        sessionStorage.setItem(`${REPORT_TYPE}_fromDate`, date.toISOString());
                                    }}
                                    className="form-control rounded-100"
                                    placeholderText="Start Date"
                                />
                            </Form.Group>
                        </div>
                        <div className="px-1" style={{ width: "15%" }}>
                            <Form.Group>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        sessionStorage.setItem(`${REPORT_TYPE}_toDate`, date.toISOString());
                                    }}
                                    className="form-control  rounded-100"
                                    placeholderText="End Date"
                                />
                            </Form.Group>
                        </div>

                        <div className="px-2" style={{ width: "15%" }}>
                            <Form.Group>
                                <Button
                                    className="rounded-100 mt-4 btn btn-block btn-lg"
                                    onClick={getUserActivity}
                                >
                                    {"Search"}
                                </Button>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="row m-0 p-0">

                    </div>
                </div>
            </div>

            <hr />

            <div className="scroll-inner admin-fix-width ">
                <table className="my-table transparent-table text-center border-bottom ">
                    <tr className="borderless-table text-center border-bottom">
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            {" "}
                            {"Sr"}
                        </th>

                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            {" "}
                            {"User Code"}
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            {"User Name"}
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            {"Activity"}
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            {"time"}
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            {"ip address"}
                        </th>

                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className="border-bottom">
                            <td
                                className="text-center m-0 p-0 pr-2 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                {" "}
                                {ind + 1}
                            </td>
                            <td
                                className="text-center m-0 p-0 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                {" "}
                                <div className="d-flex flex-column align-items-center">
                                    <span>{`${e.admin_code}`}</span>
                                </div>
                            </td>
                            <td
                                className="text-center m-0 p-0 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                {" "}
                                <div className="col ">
                                    <div>{e.admin_name}</div>
                                </div>
                            </td>
                            <td
                                className="text-center m-0 p-0 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div className="d-flex flex-column align-items-center">
                                    <span>{e.description}</span>
                                </div>
                            </td>
                            <td
                                className="text-center m-0 p-0 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div className="d-flex flex-column align-items-center">
                                    <span>{e.date_time}</span>
                                </div>
                            </td>
                            <td
                                className="text-center m-0 p-0 pl-0 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div className="d-flex flex-column align-items-center">
                                    <span>{e?.ip_address}</span>
                                    {/* <span className="text-danger">{`Deduction : ${twoDecimalPlaces(
                    e.dmt_deduction
                  )}`}</span> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                    {data.length <= 0 && <NoDataFound />}
                </table>
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </>
    );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>
            <div className="d-flex btn-group">
                <select
                    className="rounded-0 form-select-sm"
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset}>{offset}</option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {"Previous"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}
                <Button variant="primary"> {page.toString()}</Button>
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {"Next"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
export const NoDataFound = () => {
    return (
        <tr className="row w-100 justify-content-center">
            <div className="d-flex flex-column align-items-center p-3">
                <img
                    src={require("../../../assets/images/no_data_found.png")}
                    style={{ height: "5rem", width: "auto" }}
                />
                <strong>No Data Found</strong>
            </div>
        </tr>
    );
};

export default AdminActivityLogsTable;
