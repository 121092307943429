import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";

const userTypes = [
    { label: "Salary", value: "Salary" },
    { label: "Bonus", value: "Bonus" },
    { label: "Travel Allowance", value: "Travel Allowance" },
    { label: "Advance", value: "Advance" },
];

const formattedMonths = [
    { label: "Jan", value: "Jan" },
    { label: "Feb", value: "Feb" },
    { label: "March", value: "March" },
    { label: "Apr", value: "Apr" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "Aug", value: "Aug" },
    { label: "Sept", value: "Sept" },
    { label: "Oct", value: "Oct" },
    { label: "Nov", value: "Nov" },
    { label: "Dec", value: "Dec" },
];

const AddSalaryDetails = () => {
    const initialState = {
        userType: "",
        fullName: "",
        mobile: "",
        email: "",
        shopname: "",
        dmtTariff: "",
        aepsTariff: "",
        bbpsTariff: "",
        rechargeTariff: "",
        cmsTariff: "",
        travelTariff: "",
        matmTariff: "",
        salesTeam: "",
        pincode: "",
        shopAddress: "",
        aadhaarNumber: "",
        panNumber: "",
        parentID: "1",
    };

    const [state, setState] = useState(initialState);
    const [userList, setUserList] = useState([]);
    const [parentList, setParentList] = useState([]);
    const [tariffs, setTariffs] = useState([]);
    const [formErrors, setFormErrors] = useState({ mobile: "" });
    const { user, setTransactionLoaderState } = useContext(GlobalContext);

    const validateForm = () => {
        if (
            state.userType == "" ||
            state.fullName == "" ||
            state.email == "" ||
            state.shopAddress == "" ||
            state.shopname == "" ||
            state.pincode == "" ||
            state.parentID == ""
        ) {
            swal("Invalid", "Please Fill Mandatory Fields", "error");
            return;
        }
        else if (state.mobile.length != 10) {
            swal("Invalid", "Please Enter Valid Mobile Number", "error");
            return;
        }
        else if (state.aadhaarNumber.length != 12) {
            swal("Invalid", "Please Enter Valid Aadhaar Number", "error");
            return;
        }
        else if (state.panNumber.length != 10) {
            swal("Invalid", "Please Enter Valid PAN Number", "error");
            return;
        }
        else if (state.pincode.length != 6) {
            swal("Invalid", "Please Enter Valid Pincode", "error");
            return;
        }

      //  createUser();
    };

    const clearForm = () => {
        setState(initialState);
    };

    const addSalaryDetails = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "mobile": state.mobile,
                "name": state.name,
                "accno": state.accno,
                "ifsc": state.ifsc,
                "type": "1",
                "amount": state.amount,
                "month": state.month.value,
                "category": state.category.value,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/addEmployeeSalaryDetails`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", "Salary Details Added Successfully", "success");
                setTransactionLoaderState(false);
            }

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
    }, []);

    return (
        <div style={{ padding: "1rem" }} className="bg-light ">
            <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
                <div className="card-body m-0 p-3">
                    <div>
                        <div
                            style={{
                                "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                , padding: "0px",
                                background: "#FFFFFF",
                                color: "#4267B2",
                                fontWeight: "bolder",
                                fontfamily: "Poppins !impotent",
                                fontSize: "28px",
                            }}
                        >
                            Add Salary Details
                        </div>

                        <div>
                            <div className="row p-3"
                                style={{
                                    fontfamily: "Poppins",
                                    fontsize: "24px",
                                    fontweight: "600",
                                    lineheight: "36px",
                                    letterspacing: "0em",
                                    textalign: "left",
                                    color: "#00000080",



                                }}

                            >
                                <h4>Personal Info</h4>


                            </div>





                            <div className="col-10 m-0 p-0">
                                <div className="row m-0 p-0">
                                    <div className="col-6 p-0 pr-2">
                                        <Form.Group >
                                            <Form.Label>
                                                <span style={{ color: "red" }}>*</span>
                                                <span style={{ color: "black" }}>Enter Name</span>

                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-100"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Name"
                                                value={state.name}
                                                onChange={(e) => {
                                                    setState({ ...state, name: e.target.value });
                                                }}

                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-6 p-0 pl-2 ">
                                        <Form.Group>
                                            <Form.Label>
                                                <span style={{ color: "red" }}>*</span>
                                                <span style={{ color: "black" }}>Enter Mobile</span>

                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                className="form-control rounded-100"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Mobile"
                                                maxLength="10"
                                                value={state.mobile}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 10) {
                                                        setFormErrors({
                                                            ...formErrors,
                                                            mobile: "you cant enter more than 10 digit",
                                                        });
                                                    } else {
                                                        setFormErrors({
                                                            ...formErrors,
                                                            mobile: "",
                                                        });
                                                        setState({ ...state, mobile: e.target.value });
                                                    }
                                                }}

                                            />
                                            <p style={{ color: "red" }}>{formErrors.mobile}</p>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>


                            <div className="col-10 m-0 p-0">
                                <div className="row m-0 p-0">
                                    <div className="col-6 p-0 pr-2 ">
                                        <Form.Group>
                                            <Form.Label>
                                                <span style={{ color: "red" }}>*</span>
                                                <span style={{ color: "black" }}>Enter Account Number</span>

                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                className="form-control rounded-100"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Account Number"
                                                value={state.accno}
                                                onChange={(e) => {
                                                    setState({ ...state, accno: e.target.value });
                                                }}

                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-6 p-0 pl-2 ">
                                        <Form.Group>
                                            <Form.Label>
                                                <span style={{ color: "red" }}>*</span>
                                                <span style={{ color: "black" }}>Enter IFSC Code</span>

                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-100"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter IFSC Code"
                                                value={state.ifsc}
                                                onChange={(e) => {
                                                    setState({ ...state, ifsc: e.target.value });
                                                }}

                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                        </div>


                        <div>
                            <div className="row p-3"
                                style={{
                                    fontfamily: "Poppins",
                                    fontsize: "48px",
                                    fontweight: "600",
                                    lineheight: "36px",
                                    letterspacing: "0em",
                                    textalign: "left",
                                    color: "#00000080",

                                }}

                            >

                                <h4>Salary Details</h4>

                            </div>

                            <div className="col-10 m-0 p-0">
                                < div className="row m-0 p-0">
                                    <div className="col-6 p-0 pr-2 ">
                                        <Form.Group className="rounded-100">
                                            <Form.Label>
                                                <span style={{ color: "red" }}>*</span>
                                                <span style={{ color: "black" }}>  Select Category</span>

                                            </Form.Label>
                                            <Select
                                                options={userTypes}
                                                value={state.category}
                                                onChange={(e) => {
                                                    setState({ ...state, category: e });
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>

                            <div className=" col-10 m-0 p-0">
                                <div className="row m-0 p-0">
                                    <div className="col-6 p-0 pr-2">
                                        <Form.Group>
                                            <Form.Label>
                                                <span style={{ color: "red" }}>*</span>
                                                <span style={{ color: "black" }}>Amount</span>

                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                maxLength="10"

                                                className="form-control rounded-100"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Amount"
                                                value={state.amount}
                                                onChange={(e) => {
                                                    setState({ ...state, amount: e.target.value });
                                                }}


                                            />
                                            <p style={{ color: "red" }}>{formErrors.pincode}</p>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-10 m-0 p-0">
                            < div className="row m-0 p-0">
                                <div className="col-6 p-0 pr-2 ">
                                    <Form.Group className="rounded-100">
                                        <Form.Label>
                                            <span style={{ color: "red" }}>*</span>
                                            <span style={{ color: "black" }}>  Select Month</span>

                                        </Form.Label>
                                        <Select
                                            options={formattedMonths}
                                            value={state.month}
                                            onChange={(e) => {
                                                setState({ ...state, month: e });
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-2 p-0 ml-2">
                                <Button
                                    className="rounded-50 btn btn-primary btn-lg btn-block"

                                    onClick={addSalaryDetails}
                                >
                                    {" "}
                                    Submit
                                </Button>
                            </div>
                            <div className="col-2 p-0 ml-2">
                                <Button
                                    className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"

                                    onClick={clearForm}
                                >
                                    {" "}
                                    Clear
                                </Button>
                            </div>
                            <div className="col p-0"></div>
                            <div className="col p-0"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const EmptyArrayHandler = () => {
    return (
        <div className="row">
            <div className="col-12">
                <span style={{ fontSize: "1.4rem" }}>No records Found</span>
            </div>
        </div>
    );
};
export default AddSalaryDetails;
