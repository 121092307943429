import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import { generateHashKey2 } from "../../utils/utilFuctions";
import AEPSReportTable from "./AEPSReconcileReportTable";

export const columns = [
  {
    dataField: "rch_mobile",
    text: "Mobile",
  },
  {
    dataField: "rch_bank_name",
    text: "Provider",
  },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "rch_transaction_id",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_after_balance",
    text: "After Balance",
  },
  {
    dataField: "rch_com_retailer",
    text: "Comm",
  },
  {
    dataField: "other_charges",
    text: "TDS",
  },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];
const RetailerAEPSReconcileReport = ({ agentId,mobile }) => {
  const [reports, setReports] = useState([]);
  const { user } = useContext(GlobalContext);

  const fetchRepoprts = async () => {
    try {
      const raw2 = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw2,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=transactionaeps&mobile=${user.ReferId}`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      setReports(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // fetchRepoprts();
  }, []);
  return (
          <div style={{ margin: "10px" }}>
            <div
              style={{
                display: "flex",
                background: "#30363e",
                color: "#FFF",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              <strong>AEPS History</strong>
            </div>
            <AEPSReportTable
              data={reports}
              columns={columns}
              agentId={agentId}
              mobile={mobile}
            />
          </div>
  );
};

export default RetailerAEPSReconcileReport;
