import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCtl8RZOMhAMJIPbtcvHw8eUAethzfI1I0",
  authDomain: "bumppy-sso.firebaseapp.com",
  projectId: "bumppy-sso",
  storageBucket: "bumppy-sso.appspot.com",
  messagingSenderId: "804804906206",
  appId: "1:804804906206:web:335f6e77dbd4385b8c464f",
  measurementId: "G-1CWZHCWKBD"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

// auth.settings.appVerificationDisabledForTesting = true;
