import React, { useState, useEffect, useContext } from "react";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";

const AllChargeBackCases = () => {
    const history = useHistory();
    const [pageAccess, setPageAccess] = useState({});
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [userId, setUserId] = useState("");

    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const ViewReport = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: "string",
                adminid: "string",
                fromDate: "string",
                toDate: "string",

            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/Payment/admin/getAllChargeBackCasesAdmin`,
                requestOptions
            );
            const data = await res.json();
            console.log("Merchant", data);
            if (data.status === "0") {
                setData(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    function getStatusBadgeClass(status) {
        switch (status) {
            case "SUCCESS":
                return "bg-success text-white";
            case "N":
                return "bg-danger text-white";
            default:
                return "bg-light text-dark";
        }
    }

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    const getPageAccess = (menuId) => {
        const sub_menus_access_info = JSON.parse(
            sessionStorage.getItem("sub_menus")
        );
        sub_menus_access_info.forEach((acc) => {
            if (acc.pk_menu_id == menuId) {
                setPageAccess(acc);
            }
        });
    };



    const userTypes = [
        { label: "Master Distributor", value: "4" },
        { label: "Distributor", value: "3" },
        { label: "Retailer", value: "2" },
        { label: "API Partner", value: "5" },
    ];
    const tariff = [{ label: "Bumppy", value: "3" }];
    const initialState = {
        UserID: "",
        TransactionID: "",
        Mobile: "",
        Name: "",
        bankref: "",
        Amount: "",
        Service: "",
        provider: '',
        Chargebackref: "",
        Chargebackdate: "",
        txnkdate:""

    };
    const [errors, setErrors] = useState(initialState);

    const [state, setState] = useState(initialState);
    const [userRoles, setUserRoles] = useState([]);
    const [parentList, setParentList] = useState([]);

    const { user, setTransactionLoaderState } = useContext(GlobalContext);

    const clearForm = () => {
        setState(initialState);
    };
    const [showModal, setShowModal] = useState(false);
    const OpenModal = () => {
        setShowModal(!showModal);
    };
    const validateForm = (e) => {
        e.preventDefault();
        console.log(" validate function");
        let errors = {};
        if (state.UserID.trim() == "") {
            errors.UserID = "*Please enter UserID";
        }
        if (state.TransactionID.trim() == "") {
            errors.TransactionID = "*Please enter Transaction ID";
        }
        if (state.Mobile.trim() == "") {
            errors.Mobile = "*Please enter Mobile";
        }
        if (state.Name.trim() == "") {
            errors.Name = "*Please enter Name";
        }
        if (state.bankref.trim() == "") {
            errors.bankref = "*Please enter bankref";
        }
        if (state.Amount.trim() == "") {
            errors.Amount = "*Please enter Amount";
        }

        if (state.Service.trim() == "") {
            errors.Service = "*Please select Service";
        }

        if (state.provider.trim() == "") {
            errors.provider = "*Please enter provider";
        }


        if (state.Chargebackref.trim() == "") {
            errors.Chargebackref = "*Please select Charge back ref";
        }

        if (state.Chargebackdate.trim() == "") {
            errors.Chargebackdate = "*Please select Charge back date";
        }
        if (state.txnkdate.trim() == "") {
            errors.txnkdate = "*Please select Transaction date ";
        }
        setErrors(errors);
        if (Object.keys(errors).length == 0) {
            Addchargeback();
            console.log("handle change button n ");
        }
    };
    const Addchargeback = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: state.UserID,
                txn_id: state.TransactionID,
                cust_name: state.Name,
                cust_mobile: state.Mobile,
                txn_amount: state.Amount,
                service: state.Service,
                provider: state.provider,
                bank_ref: state.bankref,
                chargeback_ref: state.Chargebackref,
                chargeback_date: state.Chargebackdate,
                txn_date:state.txnkdate,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/Payment/admin/insertNewChargbackCase`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", data.msg, "success");
                setShowModal(false);
                ViewReport();
            } else {
                swal("Failed", data.msg, "error");
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        ViewReport();
        getPageAccess(14);
    }, []);
    const serviceOptions = ["DMT", "PG", "AEPS", "BBPS", "AEPS settlement", "PG settlement", "E suvidha","QR"];

    return (
        <div>
            <div className="row mt-2">
                <h5 className="col-6"> Charge Back Report</h5>

                <div className="col ">
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control rounded-100"
                        placeholderText="Start Date"
                    />
                </div>
                <div className=" col">
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        className="form-control rounded-100"
                        placeholderText="End Date"
                    />
                </div>
                <div className=" col m-0 p-0">
                    <Button
                        className="rounded-100 btn btn-lg btn-block"
                        onClick={() => {
                            ViewReport();
                        }}
                        style={{ width: "8rem" }}
                    >
                        {"Search"}
                    </Button>
                </div>
                <div className="col m-0 p-0">
                    <Button style={{ height: "2.5rem" }} onClick={OpenModal}>
                        Add Charge Back
                    </Button>
                </div>
            </div>


            <hr className="m-0 p-0" />

            <div style={{ width: "100%" }}>
                <div style={{ alignItems: "center" }}>
                    <div style={{ flex: "1" }}></div>
                </div>
            </div>
            <div className=" scroll-inner admin-fix-width borderless-table ">
                <table
                    className="my-table transparent-table text-center border-bottom"
                    style={{
                        width: "100%",
                        overflowX: "auto",
                        border: "0px",
                        overflow: "auto",
                    }}
                >
                    <tr className="borderless-table text-center border-bottom">

                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Id
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Trans. Id
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Name
                        </th>

                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Mobile
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Trans. Amt
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Service
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            provider
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Bank. Ref
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            charge back
                        </th>

                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            In-Up date
                        </th>

                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            KYC
                        </th>
                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            User
                        </th>


                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Sales
                        </th>


                        <th
                            className="my-table transparent-table text-center border-bottom "
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                            }}
                        >
                            Status
                        </th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className="border-bottom">

                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.id}</div>
                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div> {e.txn_id}</div>
                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div> {e.cust_name}</div>
                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div> {e.cust_mobile}</div>
                            </td>

                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div> {twoDecimalPlaces(e.txn_amount)} </div>
                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div> {e.service}</div>

                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.provider}</div>
                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.bank_ref}</div>
                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.chargeback_ref}</div>
                                <div>{e.chargeback_date}</div>
                            </td>

                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.indate}</div>
                                <div>{e.update}</div>
                            </td>

                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.kyc}</div>
                            </td>
                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.user_code}</div>
                                <div>{e.user_name}</div>
                                <div>{e.user_mobile}</div>
                            </td>

                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>{e.sales_user_code}</div>
                                <div>{e.sales_user_name}</div>
                                <div>{e.sales_user_mobile}</div>
                            </td>


                            <td
                                className="text-center border-bottom"
                                style={{
                                    fontSize: "0.8rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >

                                {e.status.toString().toLowerCase() == "close" ? (
                                    <span
                                        style={{

                                            borderRadius: "5px",
                                            border: "1px solid",
                                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                                            color: 'rgba(43, 193, 85, 1)',
                                            bordercolor: 'rgba(43, 193, 85, 1)',

                                        }} className="px-2 py-2"
                                    >
                                        {`${e.status}`}
                                    </span>



                                ) : (
                                    <span
                                        className="m-0 p-0 px-2 py-2"
                                        style={{


                                            borderRadius: "5px",
                                            border: "1px solid",
                                            backgroundColor: 'rgba(255, 46, 46, 0.1)',
                                            color: 'rgba(255, 46, 46, 1)',
                                            bordercolor: 'rgba(255, 46, 46, 1)',

                                        }}

                                    >
                                        {`${e.status}`}
                                    </span>
                                )}

                                {/* <div className="badge badge-primary" >{e.status}</div> */}

                            </td>
                        </tr>
                    ))}
                </table>
            </div>
            <Modal show={showModal} onHide={OpenModal}>
                <Modal.Header closeButton>
                    <Modal.Title> Charge Back Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <div className="card-body m-0 p-1 px-2">

                            <div>

                                <div className="row ">
                                    <div className="col ">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`User ID`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter User ID"
                                                value={state.UserID}
                                                onChange={(e) => {
                                                    setState({ ...state, UserID: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>
                                        {errors.UserID && (
                                            <div className="text-danger">{errors.UserID}</div>
                                        )}
                                    </div>

                                    <div className="col">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Transaction ID`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Transaction ID"
                                                value={state.TransactionID}
                                                onChange={(e) => {
                                                    setState({ ...state, TransactionID: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>
                                        {errors.TransactionID && (
                                            <div className="text-danger">{errors.TransactionID}</div>
                                        )}
                                    </div>

                                </div>
                                <div className="row ">
                                    <div className="col ">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Customer Mobile`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                maxLength={10}
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Customer Mobile"
                                                value={state.Mobile}
                                                onChange={(e) => {
                                                    setState({ ...state, Mobile: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.Mobile && (
                                            <div className="text-danger">{errors.Mobile}</div>
                                        )}
                                    </div>

                                    <div className="col">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Customer Name`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Customer Name"
                                                value={state.Name}
                                                onChange={(e) => {
                                                    setState({ ...state, Name: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.Name && (
                                            <div className="text-danger">{errors.Name}</div>
                                        )}
                                    </div>

                                </div>

                                <div className="row ">
                                    <div className="col ">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {` Bank-ref `}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter bank-ref "
                                                value={state.bankref}
                                                onChange={(e) => {
                                                    setState({ ...state, bankref: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.bankref && (
                                            <div className="text-danger">{errors.bankref}</div>
                                        )}
                                    </div>

                                    <div className="col">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Amount`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Amount"
                                                value={state.Amount}
                                                onChange={(e) => {
                                                    setState({ ...state, Amount: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.Amount && (
                                            <div className="text-danger">{errors.Amount}</div>
                                        )}
                                    </div>

                                </div>

                                <div className="row ">
                                    <div className="col ">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Service`}
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="form-control rounded-lg"
                                                aria-label="Service"
                                                aria-describedby="basic-addon1"
                                                placeholder="Select Service"
                                                value={state.Service}
                                                onChange={(e) => {
                                                    setState({ ...state, Service: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            >
                                                <option value="">Select Service</option> 
                                                {serviceOptions.map((option, index) => (
                                                    <option key={index} value={option}>{option}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group> {errors.Service && (
                                            <div className="text-danger">{errors.Service}</div>
                                        )}
                                    </div>

                                    <div className="col">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`provider`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter provider"
                                                value={state.provider}
                                                onChange={(e) => {
                                                    setState({ ...state, provider: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.provider && (
                                            <div className="text-danger">{errors.provider}</div>
                                        )}
                                    </div>

                                </div>

                                <div className="row ">
                                    <div className="col ">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Charge back ref`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Charge back ref. "
                                                value={state.Chargebackref}
                                                onChange={(e) => {
                                                    setState({ ...state, Chargebackref: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.Chargebackref && (
                                            <div className="text-danger">{errors.Chargebackref}</div>
                                        )}
                                    </div>

                                    <div className="col">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Charge back date`}
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Charge back date"
                                                value={state.Chargebackdate}
                                                onChange={(e) => {
                                                    setState({ ...state, Chargebackdate: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.Chargebackdate && (
                                            <div className="text-danger">{errors.Chargebackdate}</div>
                                        )}
                                    </div>

                                </div>
                                <div className="row ">
                                  
                                    <div className="col-6">
                                        <Form.Group>
                                            <Form.Label className="text-dark">
                                                <span style={{ color: "red" }}>*</span>
                                                {`Transaction date`}
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                className="form-control rounded-lg"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter Transaction date"
                                                value={state.txnkdate}
                                                onChange={(e) => {
                                                    setState({ ...state, txnkdate: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>   {errors.txnkdate && (
                                            <div className="text-danger">{errors.txnkdate}</div>
                                        )}
                                    </div>

                                </div>
                                <div className="row ">



                                    <div className="col-3 ">
                                        <Button
                                            className="rounded-10 btn-block  "
                                            variant="primary"
                                            onClick={validateForm}
                                        >
                                            {" "}
                                            Submit
                                        </Button>
                                    </div>
                                    <div className="col-3 ">
                                        <Button
                                            className="rounded-10 btn-block border border-primary"
                                            variant="white"
                                            onClick={clearForm}
                                        >
                                            {" "}
                                            Clear
                                        </Button>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </div>
    );
};

export const Pagination = ({
    page,
    limit,
    callback,
    total,
    count,
    callback2,
}) => {
    console.table({ page, limit, callback });
    const selectStyleLeftBorder = {
        borderRadius: "0px",
        fontFamily: "Poppins",
        fontsize: "16px",
        borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
        borderRadius: "0px",
        borderLeft: "none", // Remove right side border
        borderTop: "none", // Remove top border
        borderBottom: "none", // Remove bottom border
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyleBorder = {
        border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyle = {
        border: "none", // Remove the border
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div style={{ fontFamily: "Poppins" }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>

            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">
                            {offset}
                        </option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}

                <Button className="bg-white text-primary " style={selectStyle}>
                    {" "}
                    {page.toString()}
                </Button>

                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default AllChargeBackCases;