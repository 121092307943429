import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Card, Dropdown, Modal } from "react-bootstrap";
import { useHistory, Redirect } from "react-router";
// import Dmt1Sidebar from "./DMT2Sidebar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import DMTExpressTable from "./DMT4Table";
import { GlobalContext } from "../../Context/GlobalState";
import { Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import Select from "react-select";
import swal from "sweetalert";
import { Tabs, Tab, Col, Row } from "react-bootstrap";
import {
  DmtSuccessPopup,
  bumppyKYCWarning,
  customerKYCFailedPopup,
  dmtTransactionFailedPopup,
  dmtTransactionSuccessPopup,
} from "../../utils/Models";
import {
  addRemitterValidation,
  decryptAES,
  generateHashKey2,
  onlyAlpha,
} from "../../utils/utilFuctions";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import DMT2SendMoneyModal from "./DMT4SendMoney";
import {
  addBeneficiaryAPI,
  addBeneficiarySendOTPAPI,
  checkRemitterAPI,
  createRemitterAPI,
  createRemitterOtpAPI,
  getBeneficiaryAPI,
} from "../../api/dmt/DmtApi2";

var convert = require("xml-js");

// import "./DMT2.css";
const { DMTExpressUrl, DMTWithKYCUrl, DMT1Url } = urls;
const columns = [
  {
    dataField: "recipient_name",
    text: "Name",
    sort: false,
  },
  {
    dataField: "bank",
    text: "Bank Name",
    sort: false,
  },
  {
    dataField: "account",
    text: "Account Details",
    sort: false,
  },
  // {
  //   dataField: "ifsc",
  //   text: "IFSC",
  //   sort: false,
  // },
  {
    dataField: "isVerifiedBenName",
    text: "Verify",
    sort: false,
  },
  {
    dataField: "beneficiaryStatus",
    text: "Status",
    sort: false,
  },
  // {
  //   dataField: "Mode",
  //   text: "Mode",
  //   sort: false,
  // },
];

const toastoptions = {
  hideAfter: 5,
  position: "top-center",
};
toast.configure();
const DMT4 = () => {
  const history = useHistory();
  const globalState = useContext(GlobalContext);
  const [number, setNumber] = useState("");
  const [remitterName, setRemmiterName] = useState("");
  const [found, setFound] = useState(true);
  const [remitter, setRemmiter] = useState(null);
  const [createRemitter, setCreateRemitter] = useState({
    name: "",
    mobile: "",
    dob: "",
    pidData: "",
    address: "",
    aadhaar: "",
    otpSent: false,
    bioCaptured: false,
    otp: "",
  });
  const [beneficiary, setBenificiary] = useState([]);
  const [beneficiaries, setBenificiaries] = useState([]);
  const [info, setInfo] = useState(null);
  const [details, setDetails] = useState({});
  const { user, setTransactionLoaderState } = globalState;
  const [formErrors, setFormErrors] = useState({ remitterNameErrors: "" });
  const [accNo, setAccNo] = useState("");

  //     { handleClose, show, amount, message, callback, status, date, transactionId }

  const [popupModalState, setPopupModalState] = useState({
    handleClose: false,
    show: false,
    amount: "",
    message: "",
    callback: () => {},
    status: "",
    date: "",
    transactionId: "",
  });

  const [sendMoneyModalState, setSendMoneyModelState] = useState({
    isOpen: false,
    details,
  });

  console.log(globalState);

  const successToast = (msg) => {
    cogoToast.success(msg, toastoptions);
  };
  const errorToast = (msg) => {
    cogoToast.error(msg, toastoptions);
  };
  // const success = (msg) => {
  //   cogoToast.success(msg, this.toastoptions);
  // };
  // const success = (msg) => {
  //   cogoToast.success(msg, this.toastoptions);
  // };
  useEffect(() => {
    if (number.length == 10) {
      checkRemitter();
      setAccNo("");
    }
    setFound(true);
  }, [number]);

  const checkRemitter = async () => {
    setTransactionLoaderState(true);
    try {
      const request = {
        userid: user.ReferId,
        customerMobile: number,
      };

      const res = await checkRemitterAPI(request);
      if (res.responseCode == "00") {
        setRemmiter(res.data);
        setRemmiterName(res.data.name);
        setTransactionLoaderState(false);
        getBeneficiary();
        setFound(true);
      } else {
        setTransactionLoaderState(false);
        setFound(false);
        cogoToast.warn("Customer details not found");
      }
    } catch (error) {
      swal("Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  const checkRemitter22 = async (remitterNumber) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "checkremitter");
    urlencoded.append("loginid", remitterNumber);
    urlencoded.append("mobile", remitterNumber);
    urlencoded.append("name", "N");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);
      const data = await res.json();
      if (data.status == "1") {
        return data;
      } else {
        return {};
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getBeneficiaryByAccNumber = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        accno: accNo,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_beneficiary_by_accno.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        setBenificiary([]);
        const modified = data.data
          .filter((e) => {
            return e.bn_remitter_no != "";
          })

          .map(async (rem) => {
            const dd = await checkRemitter22(rem.bn_remitter_no);
            return { ...rem, ...dd };
          });

        await Promise.all(modified).then((values) => {
          setBenificiaries([...values]);
        });
        setTransactionLoaderState(false);
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const getBeneficiary = async () => {
    setTransactionLoaderState(true);

    try {
      const request = {
        userid: user.ReferId,
        customerMobile: number,
      };

      const res = await getBeneficiaryAPI(request);
      if (res.responseCode == "00") {
        setInfo(res.data);
        setBenificiaries([]);
        setBenificiary(res.data);
        setTransactionLoaderState(false);
        cogoToast.success(res.msg);
      } else {
        setTransactionLoaderState(false);
        // error swal
        cogoToast.warn(res.msg);
        // swal('Failed', res.msg, 'error')
      }
    } catch (error) {
      setTransactionLoaderState(false);
    }

    return;
  };

  const CreateRemmiterOTP = async () => {
    const error = addRemitterValidation(remitterName);
    if (error.length > 0) {
      // Toast
      swal("Failed", error, "error");
      return;
    }
    setTransactionLoaderState(true);
    try {
      const request = {
        userid: user.ReferId,
        customerMobile: number,
        customerName: remitterName,
        aadhaarNumber: createRemitter.aadhaar,
        pidData: createRemitter.pidData,
      };

      const res = await createRemitterOtpAPI(request);
      if (res.responseCode == "00") {
        setCreateRemitter({ ...createRemitter, otpSent: true });
        swal("Success", "OTP Send successfully", "success");
      } else {
        // errorToast("Remmiter not Created");
        swal("Failed", res.responseMessage, "error");
      }
      checkRemitter();
      //getBeneficiary();
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  const CreateRemmiter = async () => {
    const error = addRemitterValidation(remitterName);
    if (error.length > 0) {
      // Toast
      swal("Failed", error, "error");
      return;
    }
    setTransactionLoaderState(true);
    try {
      const request = {
        userid: user.ReferId,
        customerMobile: number,
        customerName: remitterName,
        otp: createRemitter.otp,
      };

      const res = await createRemitterAPI(request);
      if (res.responseCode == "00") {
        swal("Success", "Customer Created successfully", "success");
      } else {
        // errorToast("Remmiter not Created");
        swal("Failed", "Customer not Created", "error");
      }
      checkRemitter();
      //getBeneficiary();
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    document
      .querySelector(".content-wrapper")
      .classList.remove("sidebar-icon-only");
    if (user.bp_ob == "N") {
      bumppyKYCWarning(() => {
        history.push("/retailer/dashboard");
      });
    }
  }, []);

  const refreshLimit = async () => {
    checkRemitter();
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // BIOMETRIC CODE
  const [pidData, setPidData] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [protocall, setProtocall] = useState("https");
  const [device, setDevice] = useState(-1);
  const [devicePort, setDevicePort] = useState("11100");
  const [snackbarState, setSnackbarState] = useState({
    error: "",
    success: "",
  });

  const mantraPorts = [
    "11100",
    "11101",
    "11102",
    "11103",
    "11104",
    "11105",
    "11106",
  ];

  const discoverDevice = () => {
    let found = false;

    const protocalls = ["http", "https"];
    // https
    protocalls.forEach((p) => {
      for (let i = 0; i < mantraPorts.length && !found; i++) {
        const currentPort = mantraPorts[i];
        try {
          discoverDeviceReq(currentPort, p, () => {
            found = true;
            setDevicePort(currentPort);
            getDeviceInfo(currentPort, p);
            setProtocall(p);
          });
        } catch (error) {}
      }
    });
  };

  const textStyle = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  function discoverDeviceReq(port, proto, callback) {
    let url;
    if (device == 0) {
      url = `${proto}://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `${proto}://localhost:${port}/getDeviceInfo`;
    }

    if (device == 2) {
      url = `${proto}://localhost:${port}/rd/info`;
    }

    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          console.log(data1);
          if (
            data1.DeviceInfo?.additional_info?.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            //  setSnackbarState({ ...snackbarState, success: "", error: "device is not ready" });
          } else {
            // setSnackbarState({ ...snackbarState, success: "device is ready to use", error: "" })
            // alert('device is ready to use');
            callback();
          }
          // setDeviceInfo(data1);
        } else {
        }
      }
    };

    xhr.send();
  }

  const checkDevice = async () => {
    if (
      !deviceInfo ||
      !deviceInfo.DeviceInfo ||
      !deviceInfo.DeviceInfo.additional_info ||
      deviceInfo.DeviceInfo.additional_info.Param.length == 0
    ) {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
      });
      return false;
    } else return true;
  };

  function getDeviceInfo(port, proto) {
    let url;
    if (device == 0) {
      url = `${proto}://localhost:${port}/rd/info`;
    }
    if (device == 1) {
      url = `${proto}://localhost:${port}/getDeviceInfo`;
    }
    if (device == 2) {
      url = `${proto}://localhost:${port}/rd/info`;
    }
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    //
    xhr.open("DEVICEINFO", url, true);

    xhr.onreadystatechange = function () {
      // if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;

        if (status == 200) {
          var result1 = convert.xml2json(xhr.responseText, {
            compact: true,
            spaces: 4,
          });
          const data1 = JSON.parse(result1);
          if (
            data1.DeviceInfo?.additional_info?.Param[0]._attributes.value == ""
          ) {
            // alert('device is not ready');
            setSnackbarState({
              ...snackbarState,
              success: "",
              error: "device is not ready",
            });
          } else {
            setSnackbarState({
              ...snackbarState,
              success: "device is ready to use",
              error: "",
            });
            // alert('device is ready to use');
          }
          setDeviceInfo(data1);
        } else {
          console.log(xhr.response);
        }
      }
    };

    xhr.send();
  }

  function morphoCapture(isKyc, callback) {
    var url = `${protocall}://localhost:${devicePort}/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=" posh=""/>' +
        "</PidOptions>";
    } else {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=" posh=""/>' +
        "</PidOptions>";
    }

    /*
        format=\"0\"     --> XML
        format=\"1\"     --> Protobuf
        */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion
          console.log("pid : ", test1);
          setPidData(test1);
          if (test5 > 0) {
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            if (callback) callback(test1);
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  function startekCapture(isKyc, callback) {
    var url = `${protocall}://localhost:${devicePort}/rd/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/>' +
        "</PidOptions>";
    } else {
      PIDOPTS =
        '<PidOptions ver="1.0">' +
        '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="" posh=""/>' +
        "</PidOptions>";
    }

    /*
        format=\"0\"     --> XML
        format=\"1\"     --> Protobuf
        */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion

          if (test5 > 0) {
            // alert(xhr.responseText);
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            //document.getElementById('text').value = xhr.responseText;
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            // alert("Captured Successfully");
            var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
            setPidData(JSON.parse(result1));
            if (callback) callback(JSON.parse(result1));
            //document.getElementById('text').value = "Captured Successfully";
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  function mantraCapture(isKyc, callback) {
    var url = `${protocall}://localhost:${devicePort}/rd/capture`;
    let PIDOPTS = "";
    if (isKyc) {
      PIDOPTS =
        '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    } else {
      PIDOPTS =
        '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    }

    /*
        format=\"0\"     --> XML
        format=\"1\"     --> Protobuf
        */
    var xhr;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      //IE browser
      xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else {
      //other browser
      xhr = new XMLHttpRequest();
    }

    xhr.open("CAPTURE", url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    xhr.setRequestHeader("Accept", "text/xml");

    xhr.onreadystatechange = function () {
      //if(xhr.readyState == 1 && count == 0){
      //	fakeCall();
      //}
      if (xhr.readyState == 4) {
        var status = xhr.status;
        //parser = new DOMParser();
        if (status == 200) {
          var test1 = xhr.responseText;
          var test2 = test1.search("errCode");
          var test6 = getPosition(test1, '"', 2);
          var test4 = test2 + 9;
          var test5 = test1.slice(test4, test6);
          // conversion
          console.log("pid : ", test1);
          var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
          setPidData(test1);
          if (test5 > 0) {
            setSnackbarState({
              ...snackbarState,
              error: xhr.responseText,
              success: "",
            });
            alert(xhr.responseText);
          } else {
            setSnackbarState({
              ...snackbarState,
              error: "",
              success: "Biometric captured successfully",
            });
            if (callback) callback(test1);
          }
        } else {
        }
      }
    };

    xhr.send(PIDOPTS);
  }

  const capture = (callback) => {
    if (
      !deviceInfo ||
      !deviceInfo.DeviceInfo ||
      !deviceInfo.DeviceInfo.additional_info ||
      deviceInfo.DeviceInfo.additional_info.Param.length == 0
    ) {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    } else if (
      device == 0 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      mantraCapture(true, callback);
    } else if (
      device == 1 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      morphoCapture(true, callback);
    } else if (
      device == 2 &&
      deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
    ) {
      setSnackbarState({ ...snackbarState, error: "", success: "" });
      startekCapture(true, callback);
    } else {
      // alert("Please choose device or check device is ready or not");
      setSnackbarState({
        ...snackbarState,
        error: "Please choose device or check device is ready or not",
        success: "",
      });
    }
  };

  useEffect(() => {
    setSnackbarState({ error: "", success: "" });
    // console.log(deviceInfo);
    if (device !== -1) discoverDevice();
  }, [device]);

  // BIOMETRIC CODE

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <div>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              {/* DmtSuccessPopup = ({ handleClose, show, amount, message, callback, status, date, transactionId })  */}
              <DmtSuccessPopup
                handleClose={popupModalState.handleClose}
                show={popupModalState.show}
                amount={popupModalState.amount}
                message={popupModalState.message}
                callback={popupModalState.callback}
                status={popupModalState.status}
                date={popupModalState.date}
                transactionId={popupModalState.transactionId}
              />
              <DMT2SendMoneyModal
                modalstate={sendMoneyModalState}
                callback={(details) => {
                  setPopupModalState({
                    handleClose: () => {
                      setPopupModalState({ ...popupModalState, show: false });
                    },
                    show: true,
                    amount: details.amount,
                    message: details.message,
                    callback: () => {},
                    status: details.status,
                    date: details.date,
                    transactionId: details.transactionId,
                  });
                }}
              />
              {/* <Dmt1Sidebar
                details={{
                  benificiary: details,
                  user: globalState.user,
                  successToast,
                  errorToast,
                  limit: remitter
                    ? remitter.routes
                      ? remitter.routes.length > 0
                        ? remitter.routes[0].limit
                        : null
                      : null
                    : null,
                  remitterNumber: number,
                }}
              /> */}
            </div>

            <div
              className="bg-light"
              style={{
                padding: "10px",
                textAlign: "center",
              }}
            >
              <div
                className="  p-2 bg-white"
                style={{ borderRadius: "0.5rem" }}
              >
                {remitter == null ? null : ( // </div> //   </strong> //     {`DMT  Available Limit: `} //   <strong className="text-start" style={{ color: "#000", fontSize: "1.5rem" }}> // <div className="p-2" style={{ background: "#ffff" }}>
                  <div className="d-flex justify-content-between p-2">
                    {/* <strong
                    style={{ color: "#000", fontSize: "1.5rem" }}
                  >
                    {`DMT Daily Limit: ${remitter.routes[0].dailylimit} , Monthy Limit: ${remitter.routes[0].monthlylimit}`}
                  </strong> 

                                        <Link to={`/retailer/IMPS?number=${number}`}>
                                            <button
                                                disabled={Number(remitter.routes[0].monthlylimit) >= 5000}
                                                className="btn btn-success"
                                            >
                                                Extend Your Limit
                                            </button>
                                        </Link>

                                        */}
                  </div>
                )}

                <div
                  className="tab-dmt-custom-pills-horizontal"
                  style={{ background: "#FFFFFF" }}
                >
                  <div className="row m-0 p-0">
                    <div className="w-100 tab-dmt-header justify-content-start  ">
                      <div
                        className={`tab-dmt-item ${
                          activeTab === 0 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(0)}
                      >
                        Find Remitter
                      </div>
                      <div
                        className={`tab-dmt-item ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(1)}
                      >
                        Add Beneficiary
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-dmt-content">
                {activeTab === 0 && (
                  <div>
                    <Tab.Pane eventKey="first">
                      <div className="row col-12">
                        <div className="col text-left px-0">
                          <span
                            style={{ fontWeight: "500", fontSize: "1.5rem" }}
                          >
                            Find or Create Customer
                          </span>
                        </div>
                        <div className="col">
                          <Modal2
                            successToast={successToast}
                            errorToast={errorToast}
                            remitter={remitter}
                            remitterNumber={number}
                            userId={globalState.user.ReferId}
                            refresh={() => {
                              getBeneficiary();
                            }}
                            remitterName={remitterName}
                          />
                        </div>
                      </div>

                      <div className="row my-0 py-0">
                        <div
                          className=" bg-white  col-3 p-1 m-0 pt-4 d-flex align-items-top"
                          style={{ borderRadius: "0.5rem" }}
                        >
                          <div className=" " style={{ width: "100%" }}>
                            <div
                              className="col-12 my-0 py-0"
                              style={{ width: "100%" }}
                            >
                              <Form.Group>
                                <Form.Control
                                  type="number"
                                  className="form-control rounded-100 "
                                  aria-label="Notes"
                                  aria-describedby="basic-addon1"
                                  placeholder="Enter Customer Mobile Number"
                                  onChange={(e) => {
                                    setFound(false);
                                    setRemmiter(null);
                                    setRemmiterName("");
                                    setBenificiary([]);
                                    if (e.target.value.length <= 10)
                                      setNumber(e.target.value);
                                  }}
                                  value={number}
                                  maxLength="10"
                                />
                              </Form.Group>
                            </div>
                            {remitter && (
                              <div className="col-12 my-4">
                                {/* <Form.Group >
                          <Form.Control
                            value={remitter.name}
                            type="text"
                            className="form-control rounded-100 "
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            disabled={true}
                           
                          />
                        </Form.Group> */}

                                <div
                                  className=""
                                  style={{
                                    backgroundColor: "#F1F7FB",
                                    border: "1px solid rgba(0,0,0,0.2)",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div
                                    className=" pt-2 text-start"
                                    style={{
                                      fontSize: "22px",
                                      textAlign: "left !important",
                                    }}
                                  >
                                    {remitter.name}
                                  </div>
                                  <hr />

                                  <div
                                    className="py-2"
                                    style={{ fontSize: "18px" }}
                                  >
                                    DMT Daily Limit : ₹ {remitter.walletbal}
                                  </div>
                                  <div
                                    className="py-2"
                                    style={{ fontSize: "18px" }}
                                  >
                                    Monthy Limit : ₹ {remitter.monthlyLimit}
                                  </div>
                                </div>
                              </div>
                            )}

                            {!found && number.length == 10 && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                <Form.Group>
                                  <Form.Control
                                    onChange={(e) => {
                                      if (onlyAlpha(e.target.value)) {
                                        // console.log("alpha");
                                        setFormErrors({
                                          ...formErrors,
                                          remitterNameErrors: "",
                                        });
                                      } else {
                                        //console.log("numeric");
                                        setFormErrors({
                                          ...formErrors,
                                          remitterNameErrors:
                                            "Customer Name Contains only Alphabates",
                                        });
                                        // alert("message");
                                      }
                                      setCreateRemitter({
                                        ...createRemitter,
                                        name: e.target.value,
                                      });
                                      setRemmiterName(e.target.value);
                                    }}
                                    value={remitterName}
                                    type="text"
                                    className="form-control rounded-0"
                                    aria-label="Notes"
                                    aria-describedby="basic-addon1"
                                    placeholder="Enter Customer Name"
                                  />
                                  <p
                                    style={{ color: "red" }}
                                    className="text-center"
                                  >
                                    {formErrors.remitterNameErrors}
                                  </p>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Control
                                    onChange={(e) => {
                                      setCreateRemitter({
                                        ...createRemitter,
                                        aadhaar: e.target.value,
                                      });
                                      // setRemmiterName(e.target.value);
                                    }}
                                    value={createRemitter.aadhaar}
                                    type="text"
                                    className="form-control rounded-0"
                                    aria-label="Notes"
                                    aria-describedby="basic-addon1"
                                    placeholder="Enter Customer Aadhaar Number"
                                  />
                                  <p
                                    style={{ color: "red" }}
                                    className="text-center"
                                  >
                                    {formErrors.aadhaar}
                                  </p>
                                </Form.Group>
                                {createRemitter.otpSent ? (
                                  <>
                                    <Form.Group>
                                      <Form.Control
                                        onChange={(e) => {
                                          setCreateRemitter({
                                            ...createRemitter,
                                            otp: e.target.value,
                                          });
                                        }}
                                        value={createRemitter.otp}
                                        type="text"
                                        className="form-control rounded-0"
                                        aria-label="Notes"
                                        aria-describedby="basic-addon1"
                                        placeholder="Enter OTP"
                                      />
                                      <p
                                        style={{ color: "red" }}
                                        className="text-center"
                                      >
                                        {formErrors.otp}
                                      </p>
                                    </Form.Group>

                                    <Button
                                      style={{
                                        backgroundColor: "#4a89dc",
                                        border: "none",
                                      }}
                                      className="rounded-0"
                                      onClick={CreateRemmiter}
                                    >
                                      {`Create Customer`}
                                    </Button>
                                  </>
                                ) : !createRemitter.bioCaptured ? (
                                  <>
                                    <div>
                                      <label>
                                        <strong>Choose Biometric Device</strong>
                                      </label>
                                      <select
                                        //style={{ width: "154%" }}
                                        className="form-control  rounded-100 border-secondary"
                                        onChange={(e) => {
                                          setSnackbarState({
                                            ...snackbarState,
                                            error: "",
                                            success: "",
                                          });
                                          setDevice(e.target.value);
                                        }}
                                      >
                                        <option>--Please Select--</option>
                                        <option value={1}>
                                          Morpho MSO 1300
                                        </option>
                                        <option value={0}>
                                          Mantra MFS 100
                                        </option>
                                        <option value={2}>
                                          Startek FM220U
                                        </option>
                                        <option disabled={true}>
                                          Precision
                                        </option>
                                      </select>
                                    </div>
                                    <Button
                                      style={{
                                        backgroundColor: "#4a89dc",
                                        border: "none",
                                      }}
                                      className="rounded-0"
                                      onClick={() => {
                                        if (checkDevice()) {
                                          setSnackbarState({
                                            ...snackbarState,
                                            error: "",
                                            success: "",
                                          });
                                          capture((data) => {
                                            setCreateRemitter({
                                              ...createRemitter,
                                              bioCaptured: true,
                                              pidData: data,
                                            });
                                          });
                                        }
                                      }}
                                    >
                                      {`Capture Fingerprint`}
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    style={{
                                      backgroundColor: "#4a89dc",
                                      border: "none",
                                    }}
                                    className="rounded-0"
                                    onClick={CreateRemmiterOTP}
                                  >
                                    {`Send OTP`}
                                  </Button>
                                )}

                                {snackbarState.success != "" && (
                                  <div
                                    className="alert alert-success my-2"
                                    role="alert"
                                  >
                                    {snackbarState.success}
                                  </div>
                                )}
                                {snackbarState.error != "" && (
                                  <div
                                    className="alert alert-danger my-2"
                                    role="alert"
                                  >
                                    {snackbarState.error}
                                  </div>
                                )}

                                <div
                                  className="card-inverse-warning row"
                                  style={{
                                    margin: "10px 0px",
                                    padding: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="col-12">
                                    {`Your number is not registered as`}
                                  </div>
                                  <div className="col-12">
                                    {`customer please create customer first`}
                                  </div>
                                </div>
                                {/* 
                                <Button
                                  style={{
                                    backgroundColor: "#4a89dc",
                                    border: "none",
                                  }}
                                  className="rounded-0"
                                  onClick={CreateRemmiter}
                                >
                                  {`Create Customer`}
                                </Button> */}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-9 ">
                          <div
                            className="  bg-white  p-2"
                            style={{ borderRadius: "0.5rem" }}
                          >
                            {beneficiary.length > 0 && (
                              <DMTExpressTable
                                successToast={successToast}
                                errorToast={errorToast}
                                data={beneficiary}
                                columns={columns}
                                remitterNumber={number}
                                userId={context.user.ReferId}
                                refresh={() => {
                                  getBeneficiary();
                                }}
                                setSidebarDetails={(detail) => {
                                  setDetails(detail);
                                  setSendMoneyModelState({
                                    setModelState: setSendMoneyModelState,
                                    refreshLimit: refreshLimit,
                                    isOpen: true,
                                    details: {
                                      benificiary: detail,
                                      user: globalState.user,
                                      successToast,
                                      errorToast,
                                      limit: remitter
                                        ? remitter.routes
                                          ? remitter.routes.length > 0
                                            ? remitter.routes[0].limit
                                            : null
                                          : null
                                        : null,
                                      remitterNumber: number,
                                      remitter: remitter,
                                    },
                                  });
                                }}
                                limit={
                                  remitter
                                    ? remitter.routes
                                      ? remitter.routes.length > 0
                                        ? remitter.routes[0].limit
                                        : null
                                      : null
                                    : null
                                }
                              />
                            )}

                            {beneficiaries.length > 0 && (
                              <RemitterList
                                beneficiaries={beneficiaries}
                                refresh={() => {
                                  getBeneficiaryByAccNumber();
                                }}
                              />
                            )}

                            {beneficiary.length == 0 &&
                              beneficiaries.length == 0 && <RecordNotFound />}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </div>
                )}
                {activeTab === 1 && (
                  <div>
                    <Tab.Pane eventKey="second"></Tab.Pane>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

function Modal2({
  remitterNumber,
  userId,
  refresh,
  remitter,
  errorToast,
  successToast,
  remitterName,
}) {
  const [mdShow, setMdShow] = useState(false);
  const [bank, setBank] = useState({});
  const [ifscCode, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [banks, setBanks] = useState([]);
  const [mobileNo, setMobileNo] = useState("");
  const [popupState, setPopupState] = useState(false);
  const [otpSent, setOtpSent] = useState(true);
  const [textOTP, setTextOTP] = useState("");
  const [formErrors, setFormErrors] = useState({
    beneficiaryName: "",
    ifsc: "",
    accountNumber: "",
    mobile: "",
  });
  const { setTransactionLoaderState, user } = useContext(GlobalContext);

  const getBanks = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const request = {
      userid: user.ReferId,
      service: "DMT",
    };
    const raw = JSON.stringify(request);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://apiservices.bumppypay.com/api/DMT/Bankit/Main/getBankListDmt",
        requestOptions
      );

      const data = await res.json();
      if (data.responseCode == "00") {
        const options = data.data.map((e) => {
          return { ...e, label: e.bankName, value: e.ifsc };
        });
        setBanks(options);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validate = () => {
    let errors = {};
    if (beneficiaryName.toString().trim().length < 3) {
      errors.beneficiaryName = "Beneficiary name atleast 3 character long`";
    }
    if (formErrors.beneficiaryName) {
      errors.beneficiaryName = formErrors.beneficiaryName;
    }
    if (accountNumber.toString().trim().length < 9) {
      errors.accountNumber = "please enter valid account number";
    }
    if (
      ifscCode.toString().trim().length < 10 ||
      ifscCode.toString().trim().length > 12
    ) {
      errors.ifsc = "please enter valid IFSC code";
    }
    if (mobileNo.toString().trim().length != 10) {
      errors.mobile = "please enter valid mobile number";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      sendOTPForAddBeneficiary();
    }
  };

  const sendOTPForAddBeneficiary = async () => {
    setTransactionLoaderState(true);

    try {
      const request = {
        remitterNumber: remitterNumber,
        beneName: beneficiaryName,
        beneNumber: mobileNo,
        ifscCode: ifscCode,
        accountNumber: accountNumber,
        bankId: bank.bankCode,
        userid: user.ReferId,
        bank: bank.bankName,
      };

      const res = await addBeneficiarySendOTPAPI(request);
      if (res.responseCode == "00") {
        setOtpSent(true);
        cogoToast.success(res.msg);
      } else {
        // error swal
        swal("Failed", res.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", "Error From Server", "error");
    }
  };
  const resendOTP = async () => {
    setOtpSent(false);
    sendOTPForAddBeneficiary();
  };
  const addBeneficiary = async () => {
    setTransactionLoaderState(true);

    try {
      const request = {
        userid: user.ReferId,
        bankId: bank.bankCode,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
        mobileNo: mobileNo,
        beneficiaryName: beneficiaryName,
        customerMobile: remitterNumber,
        customerName: remitterName,
      };

      const res = await addBeneficiaryAPI(request);
      if (res.responseCode == "00") {
        // cogoToast.success(res.msg)
        swal("Success", res.responseMessage, "success");
        refresh();
        setMdShow(false);
      } else {
        setTransactionLoaderState(false);
        // error swal
        swal("Failed", res.responseMessage, "error");
      }
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", "Error From Server", "error");
    }
  };
  const clearForm = () => {
    setIfscCode("");
    setAccountNumber("");
    setBeneficiaryName("");
    setMobileNo("");
    setOtpSent(false);
  };
  useEffect(() => {
    clearForm();
  }, [mdShow]);

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <React.Fragment>
      <Button
        variant="success"
        onClick={() => {
          if (remitter) {
            setMdShow(true);
          } else {
            customerKYCFailedPopup(
              "",
              "Failed",
              "Please Enter Valid Customer Number"
            );
            // errorToast("Please Enter Valid Remmiter");
          }
        }}
        className="btn float-right m-2"
      >
        Add Beneficiary
      </Button>
      <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
        style={{ overflowY: "hidden" }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Add Beneficiary</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ overflowY: "hidden" }} className="account">
          <div>
            <Form.Group>
              <Select
                options={banks}
                onChange={(e) => {
                  setBank(e);
                  setIfscCode(e.ifsc);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                value={ifscCode}
                onChange={(e) => {
                  setIfscCode(e.target.value);
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`IFSC Code`}
              />
              <p style={{ color: "red" }} className="text-center">
                {formErrors.ifsc}
              </p>
            </Form.Group>
          </div>

          <Form.Group style={{ flex: "1" }}>
            <Form.Control
              onChange={(e) => {
                setAccountNumber(e.target.value);
              }}
              type="number"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter Account Number`}
            />
            <p style={{ color: "red" }} className="text-center">
              {formErrors.accountNumber}
            </p>
          </Form.Group>
          <Form.Group>
            <Form.Control
              onChange={(e) => {
                if (onlyAlpha(e.target.value)) {
                  // console.log("alpha");
                  setFormErrors({ ...formErrors, beneficiaryName: "" });
                } else {
                  //console.log("numeric");
                  setFormErrors({
                    ...formErrors,
                    beneficiaryName:
                      "Beneficiary Name Contains only Alphabates",
                  });
                  // alert("message");
                }
                setBeneficiaryName(e.target.value);
              }}
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Enter Beneficiary Name`}
            />
            <p style={{ color: "red" }} className="text-center">
              {formErrors.beneficiaryName}
            </p>
          </Form.Group>

          <Form.Group>
            <Form.Control
              onChange={(e) => {
                setMobileNo(e.target.value);
              }}
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Beneficiary Mobile Number`}
              value={mobileNo}
            />
            <p style={{ color: "red" }} className="text-center">
              {formErrors.mobile}
            </p>
          </Form.Group>

          {true && (
            <div className="row m-0 p-0">
              <div className="col-5 d-flex justify-content-center m-0 p-0">
                <Button
                  className="rounded-100 mx-2"
                  variant="success"
                  onClick={addBeneficiary}
                >
                  {"Add Beneficiary"}
                </Button>
              </div>{" "}
              <div className="col-7 d-flex justify-content-center m-0 p-0">
                <Button
                  className="rounded-100 mx-2"
                  variant="success"
                  onClick={addBeneficiary}
                >
                  {"Add Beneficiary Without Verify"}
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

const RemitterList = ({ beneficiaries, refresh }) => {
  const [state, setState] = useState({
    amount: "",
    tPin: "",
    selectedBeneficiary: "-1",
  });

  const [charges, setCharges] = useState();

  const {
    user,
    wallet,
    setWalletBalance,
    refreshWallet,
    setTransactionLoaderState,
  } = useContext(GlobalContext);

  const sendMoney = async (benificiary) => {
    const limit = benificiary.routes[0].limit;

    let beneNumber = benificiary.bn_bene_mobile;

    if (state.tPin == "" || state.tPin.length < 6) {
      // errorToast("Please enter valid T Pin");
      swal("Invalid", "Please enter valid T Pin", "error");
      return;
    }
    if (state.amount < 100 || state.amount > 25000) {
      swal("Failed", "Please Enter amount in Range 100 to 25000", "error");
      return;
    }
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "sendmoney");
    urlencoded.append("beneficiaryid", benificiary.bn_acc_no);
    urlencoded.append("mobile", beneNumber);
    urlencoded.append("amount", state.amount + ".00");
    urlencoded.append("tid", "IMPS");
    urlencoded.append("uid", user.ReferId);
    urlencoded.append("type", "mobile");
    urlencoded.append("bank", benificiary.bn_ifsc_type);
    urlencoded.append("tpin", state.tPin);
    urlencoded.append("route", "0");
    urlencoded.append("accountno", benificiary.bn_acc_no);
    urlencoded.append("paytmlimit", limit);
    urlencoded.append("bankitlimit", limit);
    urlencoded.append("benfname", benificiary.bn_bene_name);
    urlencoded.append("customernumber", benificiary.bn_remitter_no);

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMT1Url, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status) {
        // successToast("Amount transfer successfull");
        dmtTransactionSuccessPopup(
          "",
          `${state.amount}.00`,
          "Amount transfer successfull"
        );
        refresh();
        setState({
          amount: "",
          tPin: "",
          selectedBeneficiary: "-1",
        });
      } else {
        dmtTransactionFailedPopup("", `${state.amount}.00`, data.message);
        // errorToast(data.message);
      }
      setTransactionLoaderState(false);
      refreshWallet();
    } catch (error) {
      setTransactionLoaderState(false);
      // errorToast("Amount transfer failed");
      dmtTransactionFailedPopup(
        "",
        `${state.amount}.00`,
        "Amount transfer failed"
      );
      console.log("error", error);
    }
  };

  const sendMoneyWithKYC = async (benificiary) => {
    const limit = benificiary.routes[0].limit;
    let beneNumber = benificiary.bn_bene_mobile;

    if (limit < 25000) {
      if (state.amount < 100 || state.amount > 25000) {
        swal("Failed", "Please Enter amount in Range 100 to 25000", "error");
        return;
      }
    }

    if (limit > 25000) {
      if (state.amount < 100 || state.amount > 49750) {
        swal("Failed", "Please Enter amount in Range 100 to 49750", "error");
        return;
      }
    }
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "sendmoney");
    urlencoded.append("beneficiaryid", benificiary.bn_acc_no);
    urlencoded.append("mobile", beneNumber);
    urlencoded.append("amount", state.amount + ".00");
    urlencoded.append("tid", "IMPS");
    urlencoded.append("uid", user.ReferId);
    urlencoded.append("type", "mobile");
    urlencoded.append("bank", benificiary.bn_ifsc_type);
    urlencoded.append("tpin", state.tPin);
    urlencoded.append("route", "0");
    urlencoded.append("accountno", benificiary.bn_acc_no);
    urlencoded.append("paytmlimit", limit);
    urlencoded.append("bankitlimit", limit);
    urlencoded.append("benfname", benificiary.bn_bene_name);
    urlencoded.append("customernumber", benificiary.bn_remitter_no);

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status) {
        dmtTransactionSuccessPopup(
          "",
          `${state.amount}.00`,
          "Amount transfer successfull"
        );
      } else {
        dmtTransactionFailedPopup("", `${state.amount}.00`, data.message);
      }
      setTransactionLoaderState(false);
      refreshWallet();
      clearForm();
    } catch (error) {
      setTransactionLoaderState(false);
      // errorToast("Amount transfer failed");
      dmtTransactionFailedPopup(
        "",
        `${state.amount}.00`,
        "Amount transfer failed"
      );
      console.log("error", error);
    }
  };

  const clearForm = () => {
    setState({
      amount: "",
      tPin: "",
      selectedBeneficiary: "-1",
    });
  };

  return (
    <table
      style={{
        width: "100%",
      }}
    >
      <tr
        style={{
          width: "100%",
        }}
      >
        <th style={{ fontSize: "0.9rem" }}> {"Remitter Name"}</th>
        <th style={{ fontSize: "0.9rem" }}> {"Remitter Number"}</th>
        <th style={{ fontSize: "0.9rem" }}> {"Daily Limit"}</th>
        <th style={{ fontSize: "0.9rem" }}> {"Monthly Limit"}</th>
        <th style={{ fontSize: "0.9rem" }}> {"Action"}</th>
      </tr>
      {beneficiaries.map((e, ind) => (
        <tr key={ind}>
          <td className="text-center" style={{ fontSize: ".8rem" }}>
            {e.name}
          </td>
          <td className="text-center" style={{ fontSize: ".8rem" }}>
            {e.bn_remitter_no}
          </td>
          <td className="text-center" style={{ fontSize: ".8rem" }}>
            {e.routes.length > 0 && e.routes[0].limit}
          </td>
          <td className="text-center" style={{ fontSize: ".8rem" }}>
            {e.routes.length > 0 && e.routes[0].monthlylimit}
          </td>
          <td style={{ fontSize: ".8rem" }}>
            <div className="d-flex justify-content-center">
              <Form.Group className="d-flex ml-auto" style={{}}>
                {state.selectedBeneficiary == ind && (
                  <React.Fragment>
                    <Form.Control
                      type="number"
                      className="form-control rounded-0 mr-1"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Amount"
                      onChange={(e) => {
                        setState({
                          ...state,
                          selectedBeneficiary: ind,
                          amount: e.target.value,
                        });

                        let c = Number(e.target.value) / 100;
                        if (Number(c) < 10) {
                          c = 10;
                        }
                        if (Number(c) > 250) {
                          c = 250;
                        }
                        if (Number(e.target.value) < 100) {
                          c = 0;
                        }

                        setCharges(c);
                      }}
                      value={state.amount}
                      maxLength="10"
                      style={{ maxWidth: "8rem" }}
                    />
                    <Form.Control
                      type="password"
                      className="form-control rounded-0 mr-1"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Tpin"
                      onChange={(e) => {
                        setState({
                          ...state,
                          selectedBeneficiary: ind,
                          tPin: e.target.value,
                        });
                      }}
                      value={state.tPin}
                      maxLength="6"
                      style={{ maxWidth: "8rem" }}
                    />
                    {charges > 0 && (
                      <span
                        style={{ color: "red", padding: "0px 1rem" }}
                      >{`Charges Rs ${charges}`}</span>
                    )}
                  </React.Fragment>
                )}

                <Button
                  size="sm"
                  style={{
                    backgroundColor: "#3bafda",
                    border: "none",
                    margin: "10px 0px",
                  }}
                  className="rounded-0"
                  onClick={() => {
                    if (state.selectedBeneficiary != ind) {
                      setState();
                      setState({
                        ...state,
                        selectedBeneficiary: ind,
                        amount: "",
                        tPin: "",
                      });
                    } else {
                      if (e.routes[0].kycstatus == "1") {
                        sendMoneyWithKYC(e);
                      } else {
                        sendMoney(e);
                      }
                    }
                  }}
                >
                  Send Money
                </Button>
              </Form.Group>
            </div>
          </td>
        </tr>
      ))}
    </table>
  );
};

export const RecordNotFound = ({ text }) => {
  return (
    <div className="row justify-content-center">
      <img
        src={require("../../../assets/images/no_record_found.png")}
        style={{ height: "5rem", width: "5rem" }}
      />
      <strong>{text}</strong>
    </div>
  );
};

export default DMT4;
