import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import swal2 from "@sweetalert/with-react";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { TicketCloseModel } from "./util/TicketCloseModel";
import TicketSidebar from "./TicketSidebar";

export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },

  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "rch_mobile",
    text: "Beneficiary",
  },
  {
    dataField: "utnno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const ViewTicketListTable = () => {
  const [TID, setTID] = useState("");
  const [TicketSts, setTicketSts] = useState("");
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [raiseTicketModal, setRaiseTicketModal] = useState(false);
  // const [ TicketCloseModel , setTicketCloseModel] = useState(false)
  const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  const [clickedImageURL, setClickedImageURL] = useState("");

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openModal = (details) => {
    setRaiseTicketDetails(details);
    setModalOpen(true);
  };
  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };


  const getTicketList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "userid": "",
        "adminid": "1",
        "fromdate": dateFormatter(startDate),
        "todate": dateFormatter(endDate)
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/support/getAllTicketListAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      } else {
        setData([]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };


  const [state, setState] = useState({
    pk_ticket_id: {},
    tkt_type: {},
    category: "",
    issue: "",
    user_id: "",
    status: "0",
    comment: "",
    tickettype: {},
  });

  const replyModalOpen = (data) => {
    setRaiseTicketDetails(data);
    setRaiseTicketModal(true);
  };

  useEffect(() => {
    getTicketList();
  }, []);


  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height: '20%',

  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily": "Poppins",
    "fontWeight": "400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };
  const positionStyle = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -28,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle2 = {
    position: "absolute",
    top: -54,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  return (
    <>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}
      <TicketCloseModel
        details={raiseTicketDetails}
        isModalOpen={raiseTicketModal}
        setModalOpen={(modalState) => {
          setRaiseTicketModal(modalState);
        }}
        refresh={getTicketList}
        user={user}
      />

      <TicketSidebar TicketId={TID} TicketSts={TicketSts} />


      <div className="row m-0 p-0  py-2 align-items-center">
        <div className="col-4 m-0 p-0">

          <div className="m-0 p-0 pl-3"
            style={{
              "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
              , padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
            Ticket List
          </div>
        </div>

        <div className="col-8 m-0 p-0 ">
          <div className="row  m-0 p-0 pr-2 justify-content-end">

            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>





            <div className="pr-1 pl-2 p-0 m-0" style={{ width: '18%', height: '20%', }}>
              <Form.Group className="p-0 m-0">

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
              <Form.Group className="p-0 m-0">

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>


            <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-100 btn btn-lg btn-block "

                  onClick={getTicketList}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
          </div>




        </div>

      </div>



      <hr className="m-0 p-0" />







      <div className="scroll-inner admin-fix-width">
        <table className="my-table transparent-table text-center border-bottom"
          style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}>
          <tr className="borderless-table text-center border-bottom">
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>

            <th className="my-table transparent-table text-center border-bottom py-3 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Name"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Code"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Service"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Description"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Remarks"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Open Date"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Close Date"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sales"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Attachment"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Status"}</th>
            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Action"}</th>
            {/* <th style={{ fontSize: "0.9rem" }}> {"Check Status"}</th> */}
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {ind + 1}</td>
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.user_name}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">{e.user_code}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.tkt_type}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.tkt_desc}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.tkt_adminlast_reply}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.tkt_open_date}</span>
                </div>
              </td>
              <td className="text-center border-bottom" style={{ fontSize: 10, "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                <div className="d-flex flex-column align-items-center">
                  <div>{e.tkt_close_date}</div>

                </div>
              </td>
              <td className="text-center  text-bold border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}><div>{e.sales_user_code}</div>
                <div>{e.sales_user_name}</div>
                <div>{e.sales_user_mobile}</div></td>

              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="row m-0 p-0">
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-0 p-0"
                    onClick={() => {
                      setClickedImageURL(e.attachment);
                    }}
                  >
                    {" "}
                    <div className="hover-container">
                      {/* Your content here */}
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Atttachment
                      </div>
                    </div>
                  </Button>
                </div>
              </td>
              <td>
                {e.tkt_status == 1 ? (
                  <button
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(255, 46, 46, 0.1)',
                      color: 'rgba(255, 46, 46, 1)',
                      bordercolor: 'rgba(255, 46, 46, 1)',

                    }}
                  >
                    Close
                  </button>
                ) : (
                  <button
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgb(222, 244, 237)',
                      color: 'rgba(43, 193, 85, 1)',
                      bordercolor: 'rgba(43, 193, 85, 1)',
                    }}
                  >
                    Open
                  </button>
                )}
              </td>

              <td
                className="text-center m-0 p-0 "
                style={{
                  width: "12rem",
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <div className="row  block">



                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-0 px-1 py-1"
                    >
                      {" "}
                      <div
                        onClick={() => {
                          setTID(e.id);
                          document
                            .querySelector(".content-wrapper")
                            .classList.toggle("sidebar-icon-only");
                        }}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Asign Sales Person.png")}
                          style={{ width: "" }}
                        />
                        {/* <div style={{"fontFamily":"Poppins",  color:'rgba(0, 0, 0, 1)', }} className="hover-text"><div  > Assign Sales</div><div >  Person</div></div> */}
                        <div
                          style={positionStyle2}
                          className="hover-text bg-dark text-center "
                        >
                          {" "}
                          Chat
                        </div>
                      </div>
                    </Button>
                    {e.tkt_status === 1 ? (
                      <button style={{ background: "#54B435", color: "white" }}>
                        Close
                      </button>
                    ) : (
                      <button
                        style={{
                          paddingLeft: "3px",
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid",
                          backgroundColor: 'rgba(255, 164, 27, 0.1)',
                          color: 'rgba(255, 164, 27, 1)',
                          bordercolor: 'rgba(255, 164, 27, 1)',

                        }}
                        onClick={() => {
                          replyModalOpen(e);
                        }}
                      >
                        Reply<i class="fa-sharp fa-solid fa-file-check"></i>
                      </button>
                    )}
                  </div>
                </div>
              </td>
              {/* <td>
                <button
                  style={{
                    background: "#19A7CE",
                    color: "white",
                    border: "none",
                  }}
                  onClick={() => {
                    checkStatus(e);
                  }}
                >
                  Check Status
                </button>
              </td> */}
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ViewTicketListTable;
