import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Col, Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ContextMenuTrigger } from "react-contextmenu";
import {
    BASE_URL_AEPS,
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT,
    BASE_URL_PAYNOW,
} from "../../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import { PRINT_BASE_URL, basicAuth } from "../../config";
import {
    generateHashKey2,
    onlyAlpha,
    onlyNumeric,
    validateIFSCCode2,
} from "../../utils/utilFuctions";
import { validateTPin } from "../../utils/Validations";

const AadhaarVerifyOTPScreen = ({mdShow, setMdShow}) => {
    const style = {
        backgroundColor: "#002970",
        color: "white",
    };
    const { username, password } = basicAuth;

    const [Bal, setBal] = useState("");
    const [inputState, setInputState] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", otpSent: false, otp: "", aadhaar_request_id: "", aadhaarVerified: false, custPan: "", aadhaarDetails: "" });
    const [formErrors, setFormErrors] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", });
    const [balanceToShow, setBalanceToShow] = useState("");

    const [aadhaarBack, setAadhaarBack] = useState(null);
    const [UserAcList, setUserAcList] = useState([]);
    const [BankObject, setBankObject] = useState("");
    const [isCardSubmit, setIsCardSubmit] = useState(false);
    const [isBankVerified, setIsBankVerified] = useState(false);
    const [bankVerificationResponse, setBankVerificationResponse] =
        useState(null);

    const {
        user,
        refreshWallet,
        wallet,
        setTransactionLoaderState,
        Profileinfo,
    } = useContext(GlobalContext);
    const [tpinShow, setTpinShow] = useState(false);

    // Final Settlement Start

    const InitialFinalSettlement = {
        Amount: "",
        TPin: "",
        Type: "",
        SelectAccount: "",
    };
    const [finalSettlement, setFinalSettlement] = useState(
        InitialFinalSettlement
    );
    const [IsSubmit, setIsSubmit] = useState(false);

    const openInNewTab = () => {
        const newWindow = window.open(
            `${PRINT_BASE_URL}/pages/concern_form`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputState({ ...inputState, [name]: value });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.creditCard) {
            errors.creditCard = "*Credit Card 4 digit is required!";
        }

        if (!values.aadhaarNo) {
            errors.aadhaarNo = "*Aadhaar Number is required!";
        }
        if (values.aadhaarNo.length != 12) {
            errors.aadhaarNo = "*Aadhaar Card number must be 12 digit long!";
        }
        if (!values.custMobile) {
            errors.custMobile = "*Customer mobile number is required!";
        }
        if (!values.custEmail) {
            errors.custEmail = "*Customer email is required!";
        }

        if (Object.keys(errors).length === 0) {
            saveCreditCard();
        }
        return errors;
    };

    // Final Settlement End

    // Add Bank Account Start

    const InitialAddBankValues = {
        Mobile: "",
        AccHolderName: "",
        BankAcNumber: "",
        SelectBank: "",
        IFSCcode: "",
    };
    const [AddBankValues, setAddBankValues] = useState(InitialAddBankValues);


    const saveCreditCard = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditCard": inputState.creditCard,
                "custMobile": inputState.custMobile,
                "custName": inputState.custName,
                "custEmail": inputState.custEmail,
                "custPan": inputState.custPan,
                "aadhaarNo": inputState.aadhaarNo,
                "aadhaarDetails": inputState.aadhaarDetails,
                "isVerified": "N",
                "formData": ""
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch(`${BASE_URL_APISERVICES}/api/Payment/credit-card/creditCardDetailsSave`, requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                // swal("Success", result.msg, 'success');

                /*

  const printInfo = JSON.parse(localStorage.getItem("printInfo"));
    const Profileinfo ={
    "id": "13",
    "parent_email": "topadmin@bumppy.com",
    "parent_name": "BUMPPY",
    "parent_mobile": "8130096177",
    "name": "Suhas Sanjay Suryavanshi",
    "email": "suhassuryavanshi5979@gmail.com",
    "shopname": "Suhas",
    "mobile": "9168805979",
    "doj": "12/3/2022 12:00:00 AM",
    "totalretailer": "0",
    "uscode": "BR1004"
}

                */

                localStorage.setItem("printInfo", JSON.stringify({  
                    agentID: user.ReferId,
                    agentName: Profileinfo.name,
                    shopName: Profileinfo.shopname,
                    customerName: inputState.custName,
                    productName: "Payment Gateway",
                    customerContactName: inputState.custMobile,
                    cardLast4Digits: inputState.creditCard,
                    aadhaarnumber: inputState.aadhaarNo,
                    panNumber: inputState.custPan,
                }));

                openInNewTab();
                setIsCardSubmit(true)
                // setInputState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", });
            } else {
                swal("Failed", result.msg, 'error');
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };

    const aadhaarVerificationSendOtp = async () => {

        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Cookie",
                ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0s7bi6I2oq4bBIN05pcn-22zDbEC2K0B-nviBgFvqOVktA2C3i9oiZosmjfVMRHl6yl4r7gQLI2ZVmyyizy0WMvnTrhuMiYZcm4mq1dFs3gDB12EfgpSmQlELsaMjdVtHd6afBouTY7NsGoGe-5C3qq8ltT0zaCLnxVWjHIaJqSwNpWvun0YiFGI5tQ4hUFCPE6ilxS4u2myz3VyS2eSrGtFcvaPilfGyORm_J7W558pqhOshhR8JXR-3q8miWrt1Ji5twUizGXy0CfYM8UbEY3gPvXkyqHttYp-8X3WxmtrickDNedeBLMSs9xK80SG1HgErLU1plIlnUnmfeuUnNfLOejtKfnSkIQrMnMvKvvz-LrgPPRcf5FQntJNVXzZmZayQ8dHefiQhcfhNBwVc52CfLIzVCRcv0gICybhfOg-mQitlKMh-dXkMlhB7SUfBJX5SAQMcAWfS8DM-LvQjN6NKbXnsdy4vo5Qn0eEESnDlyX_lSEzb2lBdDlGvmFY-J9wrCk_49rT7lq-aweV8CFa6qrEBUO3t6uNY9i4s4s_7uICtutJvtiMJOi24bxNTWB22eVhsuroLrVtIswVKXMA8lRiECbsjG8lcKUk0_LU1FQz7soVehwvZa3j0y8xZ2NJPzMdl04OMGCOCWl-Up"
            );

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Verify/aadharotpsend`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100" && data.result) {

                    setInputState({
                        ...inputState, otpSent: data.result.is_otp_sent,
                        aadhaar_request_id: data.request_id,
                    });
                } else {
                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    // let username = "Rajneesh@example.com", password = "Test@12345";

    const aadhaarVerification = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                request_id: inputState.aadhaar_request_id,
                otp: inputState.otp,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Verify/aadharotpverify`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, aadhaarVerified: true });
                    updateReviewKycDetails(5 , 'Y' , JSON.stringify(data.result));

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const updateReviewKycDetails = async (step, verification , details) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "userid": user.ReferId,
                "adminid": '1',
                "aadhaar": "",
                "pan": "string",
                "homeAddress": "string",
                "shopAddress": "string",
                "step": step,
                "p8": verification,
                "p9": details ,
                "p10": "string",
                "p11": "string"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/updateKycReviewDetailsAdmin`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", "Details Verified Successfully", "success");
                setTransactionLoaderState(false);
                setMdShow(false);
              //  getReviewKycDetails()
            } else {
                swal("Error", "Something went wrong", "error");
                setTransactionLoaderState(false);
            }

        } catch (error) {
            console.log(error);
        }
    };

    // const getReviewKycDetails = async (step, verification) => {
    //     try {
    //         var myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         var raw = JSON.stringify({
    //             "userid": userId,
    //             "adminid": user.pkid,
    //             "fromDate": "",
    //             "toDate": ""
    //         });

    //         var requestOptions = {
    //             method: "POST",
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: "follow",
    //         };

    //         const res = await fetch(
    //             `${BASE_URL_APISERVICES}/api/Payment/admin/getAllKycReviewReportAdmin`,
    //             requestOptions
    //         );
    //         const data = await res.json();
    //         if (data.status == "0") {
    //             setReviewData(data.data[0])
    //             setTransactionLoaderState(false);
    //         } else {
    //             swal("Error", "Something went wrong", "error");
    //             setTransactionLoaderState(false);
    //         }

    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    useEffect(() => {
        if (inputState.creditCard.length == 4 && inputState.aadhaarNo.length == 12) {
            //   checkCreditCard();
        }
    }, [inputState.aadhaarNo, inputState.creditCard]);


    const clearAddBankForm = () => {
        setAddBankValues({
            Mobile: "",
            AccHolderName: "",
            BankAcNumber: "",
            SelectBank: "",
            IFSCcode: "",
        });
        setIsBankVerified(false);
    };

    const clearSettlementForm = () => {
        setFinalSettlement(InitialFinalSettlement);
    };


    return (

        <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
    >
        <Modal.Header closeButton>
            <Modal.Title> Aadhaar OTP Verify</Modal.Title>
        </Modal.Header>

        <Modal.Body
            style={{ width: "100%", display: "block", overflowY: "auto" }}
            className="account"
        >
             <div
            className="row p-2 m-1 w-100"
            style={{
                //  background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
                borderRadius: "1rem",
                boxShadow: DROP_SHADOW,
            }}
        >
            <div className="col-12 row justify-content-between align-items-center py-0 px-2 m-0">
                <h5 className="text-center">Verify OTP</h5>
              

            </div>

            {!isCardSubmit && (
                <div className="row w-100">
                   
                    <Form.Group className="col-12 py-0 px-1'">
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Aadhaar No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Aadhaar No"
                            name="aadhaarNo"
                            className="rounded-0"
                            value={inputState.aadhaarNo}
                            onChange={handleChange}
                        />
                        <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        {!inputState.otpSent && (<Button onClick={aadhaarVerificationSendOtp} variant="success" className="btn-sm float-right rounded-0 m-2">
                            Verify
                        </Button>)}

                    </Form.Group>
                    {(inputState.otpSent && !inputState.aadhaarVerified) && (
                        <Form.Group className="col-12 py-0 px-1'">

                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter OTP`}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter OTP"
                                name="otp"
                                className="rounded-0"
                                value={inputState.otp}
                                onChange={handleChange}
                            />
                            <p style={{ color: "red" }}>{formErrors.otp}</p>

                            <Button onClick={aadhaarVerification} variant="success" className="btn-sm float-right rounded-0 m-2">
                                Validate
                            </Button>
                        </Form.Group>
                    )}

                 
                </div>
            )}


        </div>
        </Modal.Body>
    </Modal>
        
    
    );
};

const Success = ({ text }) => {
    return (
        <div className="card-inverse-success">
            <ContextMenuTrigger id="actionContextMenu">
                <div className="card-body" style={{ padding: "10px" }}>
                    <p className="card-text">{text}</p>
                </div>
            </ContextMenuTrigger>
        </div>
    );
};

export default AadhaarVerifyOTPScreen;
