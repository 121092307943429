import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { List } from "semantic-ui-react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router";
import { generateHashKey2 } from "../../utils/utilFuctions";
const MyProfile = () => {
  const [currentPasswordCheck, setCurrentPasswordCheck] = useState(false);
  const [currentTPinCheck, setCurrentTPinCheck] = useState(false);
  const [currentMPinCheck, setCurrentMPinCheck] = useState(false);
  const { Profileinfo, user } = useContext(GlobalContext);
  const [inputState, setInputState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    currentTPin: "",
    newTPin: "",
    confirmTPin: "",
    currentMPin: "",
    newMPin: "",
    confirmMPin: "",
  });
  console.log(Profileinfo);

  const submitCurrentPassword = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "PASSWORD",
      password: inputState.currentPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/checkpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          setCurrentPasswordCheck(true);
        } else {
          cogoToast.info("In Correct Password");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const changePassword = async () => {
    if (inputState.newPassword !== inputState.confirmPassword) {
      cogoToast.error("New Password and Confirm Password should same");
      return;
    }

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "PASSWORD",
      password: inputState.newPassword,
      currenttpin: inputState.currentPassword,
    });

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("Password Changed Successfully");
        } else {
          cogoToast.info("Password Changed Failed");
        }
      })
      .catch((error) => console.log("error", error));
    setCurrentPasswordCheck(false);
    setInputState({ ...inputState, currentPassword: "" });
  };

  const submitCurrentTPin = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "TPIN",
      password: inputState.currentTPin,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/checkpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          setCurrentTPinCheck(true);
        } else {
          cogoToast.info("In Correct T PIN");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const changeTPin = async () => {
    if (inputState.newTPin !== inputState.confirmTPin) {
      cogoToast.error("New T Pin and Confirm T Pin should same");
      return;
    }

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "TPIN",
      password: inputState.newTPin,
      currenttpin: inputState.currentTPin,
    });
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("T PIN Changed Successfully");
        } else {
          cogoToast.info("T PIN Changed Failed");
        }
      })
      .catch((error) => console.log("error", error));
    setCurrentTPinCheck(false);
    setInputState({ ...inputState, currentTPin: "" });
  };

  const requestTPin = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "TPIN",
      password: inputState.newTPin,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("T PIN Requested Successfully");
        } else {
          cogoToast.info("T PIN Requested Failed");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const requestMPin = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "MPIN",
      password: inputState.newMPin,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("M PIN Requested Successfully");
        } else {
          cogoToast.info("M PIN Requested Failed");
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      <Row className="p-4">
        <Col md={4} lg={4} sm={12} className="border">
          <div className="p-2" style={{ textAlign: "center" }}>
            <strong>Personal Details</strong>
          </div>
          <hr />
          <List as="ul">
            <List.Item as="li">
              <b style={{ color: "grey" }}> Name : {user.name}</b>
            </List.Item>
            <List.Item as="li">
              <b style={{ color: "grey" }}>Mobile : {user.mobile}</b>
            </List.Item>
            <List.Item as="li">
              <b style={{ color: "grey" }}>Role : {user.rolename}</b>
            </List.Item>
          </List>{" "}
        </Col>
      </Row>
    </div>
  );
};
export default MyProfile;
