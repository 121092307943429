import React, { useState, useEffect, useContext } from "react";
import ChaafoImage from "../../../assets/images/Chaafo.png";
import ChaafoLogo from "../../../assets/images/ChaafoLogo.png";
import ChaafoBanner from "../../../assets/images/carousel/chaafoBanner1.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";
import { use } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import cogoToast from "cogo-toast";
const Chaafo = () => {
  const [leads, setLeads] = useState([]);
  const history = useHistory();
  const { user } = useContext(GlobalContext);

  const handleRedirect = () => {
    history.push("/retailer/ChaafoForm");
  };

  const handleRedirect1 = () => {
    history.push("/retailer/ChaafoDocSubmit");
  };

  const handleRedirect2 = () => {
    history.push("/retailer/ChaafoViewDoc");
  };

  const steps = [
    { label: "Onboarding", status: "In Progress" },
    { label: "Document Submission", status: "Pending" },
    { label: "Document Verification", status: "Pending" },
    { label: "Website Live", status: "Pending" },
  ];


  

  const getStepperStep = (step) => {
    switch (step) {
      case "Onboarding":
        return 1;
      case "Document Submission":
        return 2;
      case "Verification":
        return 3;
      case "Live":
        return 4;
      default:
        return 1;
    }
  }

  const getAgentLeads = async () => {
    try {
      // setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
        product: "chaafo",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/User/getAgentLeads",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        // swal("Success", data.responseMessage, "success");
        cogoToast.success(data.responseMessage);
        setLeads(data.data.map((lead) => {
          return {
            ...lead, name: `${lead.first_name} ${lead.last_name}`,
            productName: lead.requirements,
            phone: lead.phone,
            onboardingDate: lead.created_on,
            currentStep: getStepperStep(lead.step),
          }
        }));
      } else {
        // swal("Failed", data.responseMessage, "error");
        cogoToast.error(data.responseMessage);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAgentLeads();
  }, []);

  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          padding: "20px",
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          marginBottom: "20px",
          backgroundColor: "#FFF5DC",
        }}
      >
        <div style={{ flex: 1, paddingRight: "20px" }}>
          <img
            src={ChaafoLogo}
            style={{
              height: "auto",
              width: "7rem",
              margin: "2px",
              marginBottom: "3px",
            }}
          />
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          >
            We create websites and mobile apps for restaurants. Restaurants have to pay ₹5000, and you earn ₹2000 as commission!
          </div>
          <h5><b>Benefits for Retailers</b></h5>
          <ul style={{ paddingLeft: "20px", marginBottom: "20px" }}>
            <li>Earn ₹2000 for every sale you make. </li>
            <li>Plus, get 1% commission on each order. </li>
          </ul>
          <div>
       
          </div>
          <h5><b>Benefits for Restaurants </b></h5>
          <ul>
            <li>Get a Customer website and mobile app designed just for your restaurant.</li>
            <li>Easily manage orders and deliveries with Manager APP.</li>
            <li>1 Month Free SEO and SMO Branding for each restaurant.</li>
            <li>Make ordering simple and trustworthy for your customers.</li>
          </ul>
          <div style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
              marginTop: "5px",
            }} > 
            For services provided, we charge a 22% commission on each order from the restaurant.</div>
          <div>Cost to Restaurant</div>
          <ul>
            <li>₹5000 + 18% Gst on-time setup cost</li>
            <li>22% + 18% gst per order commissionxs</li>
          </ul>
          <p>
              Refer to these links to explore our expertly crafted websites and see how we can transform your online presence!
            </p>
            <ul>
     <a href="https://kwalityveg.com/" target="_blank" rel="noopener noreferrer">
            kwalityveg.com
        </a>
  <div>
  <a href="https://handihighway.com/" target="_blank" rel="noopener noreferrer">
            handihighway.com
        </a>
  </div>
      
  
</ul>

          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleRedirect}
          >
            Get Started
          </button>{" "}
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <div style={{ backgroundColor: "#FFF5DC", borderRadius: "10px" }}>
            <img src={ChaafoBanner} style={{ height: "35rem", width: "auto" }} />
          </div>
        </div>
      </div>

      <h2
        style={{ fontSize: "20px", marginBottom: "10px", marginLeft: "1rem" }}
      ><b>  Leads</b>

      </h2>

      <div style={{ margin: "5px" }}>
        {leads.map((user, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #ddd",
              padding: "20px",
              marginBottom: "10px",
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <div>
              <div>{user.name} </div>
              {/* <hr/> */}
              <p style={{ margin: "5px 0" }}>
                <strong>Product Name:</strong> {user.productName}
              </p>
              <p style={{ margin: "5px 0" }}>
                <strong>Onboarding Date:</strong> {user.onboardingDate}
              </p>
            </div>
            <div>
          {getStepperStep(user.step) === 1 || getStepperStep(user.step) === 2 ? (
            <button
              className="btn btn-block bg-primary"
              onClick={handleRedirect1}
              style={{ color: "white", border: "none" }}
            >
              Submit Document
            </button>
          ) : (
            <button
              className="btn btn-block bg-secondary"
              onClick={handleRedirect2}
              style={{ color: "white", border: "none" }}
            >
              View Document
            </button>
          )}
        </div>
            <div style={{ flex: 1, marginLeft: "20px", maxWidth: "600px" }}>
              <Stepper steps={steps} currentStep={user.currentStep} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chaafo;

const Stepper = ({ steps, currentStep }) => {
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    const newWidth = (currentStep / (steps.length - 1)) * 100;
    setProgressWidth(newWidth);
  }, [currentStep, steps.length]);

  return (
    <div
      style={{
        padding: "20px",
        background: "#f9f9f9",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "30%",
          left: "5%",
          width: "90%",
          height: "3px",
          backgroundColor: "#ccc",
          zIndex: 0,
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "30%",
          left: "5%",
          width: `${progressWidth}%`,
          height: "3px",
          backgroundColor: "green",
          zIndex: 1,
          transition: "width 2s ease-in-out",
        }}
      ></div>

      {steps.map((step, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            zIndex: 2,
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor:
                currentStep > index
                  ? "green"
                  : currentStep === index
                    ? "#6a5af9"
                    : "#ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontWeight: "bold",
              fontSize: "1.2rem",
              zIndex: 3,
            }}
          >
            {currentStep > index ? "✔" : currentStep === index ? "🔒" : "⬤"}
          </div>

          <span
            style={{ marginTop: "10px", fontWeight: "200", fontSize: "50" }}
          >
            {step.label}
          </span>

          <span
            style={{
              marginTop: "5px",
              fontSize: "0.8rem",
              color:
                currentStep > index
                  ? "green"
                  : currentStep === index
                    ? "#6a5af9"
                    : "#999",
              fontWeight: "500",
            }}
          >
            {currentStep > index
              ? "Completed"
              : currentStep === index
                ? "In Progress"
                : "Pending"}
          </span>
        </div>
      ))}
    </div>
  );
};