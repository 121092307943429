import cogoToast from "cogo-toast";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Form, Col, Row, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import { basicAuth } from "../../config";

const NewDistributor = () => {
  const { username, password } = basicAuth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const New = localStorage.getItem("new_M");
  const history = useHistory();
  const [verification, setVerification] = useState({
    PAN: false,
    ADHAR: false,
    panDetails: null,
    adhaarDetails: null,
    otpSent: false,
    aadhaar_request_id: "",
  });

  const InitialValues = {
    FullName: "",
    EmailID: "",
    P_Address: "",
    ShopName: "",
    Pincode: "",
    ShopAddress: "",
    AdharNumber: "",
    PanCard: "",
    GST: "",
  };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const panVerification = async () => {
    if (formValues.PanCard.length !== 10) {
      setFormErrors({
        ...formErrors,
        PanCard: "please enter valid PAN Number",
      });
      return;
    }
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0Yaww-WYCOCvJffuiZpPitu_WiQA91ynv8SWBcvw62PODh9iQzXLtlFCOo8mOqIJX6Q705C-IdJyxyoQ_rP9RGjHOn-x7kWGvf_K9dKW9nJuCc-_7hgUrb0JFxKGTUfoahed7EjRN3ao-NP30pRPDIpjMuZV6z-OnzJXDXTzVJGDzriVmFjhvUUiun3QtWGLPp0dTn3FnntGxLgvVyYttkZ02mmswBa5jZdO5J8nNnNtRu1P5kQ5yy6FtH5GSRI4636WrIRXCunIJIHO1t460LCL7cLMUE5Zeh4CAOhyFtlwShOYGcOX0tcGKJkHPtSFiyvyLxE0DxWYHdbDMM2dL14zsrW4q6bF8-vBOWDLsk08DqyjSIk-xho8ckrJapc7AxXnBnr6h4w5C5DdBkpT5nDPkLKONOikYNoxyFHeOemS4kWF-ftawBtPquDL6Rn-PI6glkbJWBA527ph1xAotqFGOY9xO0y1qKL_UrZgFswOpuXf2e6oThqL9U77B1Kh6Cmh-Jp-ZYIMEvd6Z3tKoqy1NMb6pAI2nMWldcw6wz3dOu0l8b60fxuIz5yViOLD6ug_yVdbz0ZgCwuYdVdilG_ssDWurFzyBpWqH-kRukFrCnm7HyttZLFKN5TrFRz5VVgq0vzwAjd6FlOjsv3ol5"
      );

      var raw = JSON.stringify({
        verifydatavalue: formValues.PanCard,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Verify/panlite`,
        requestOptions
      );
      const data = await res.json();
      if (data.success) {
        if (data.response_code == "100") {
          setVerification({
            ...verification,
            PAN: true,
            panDetails: data.result,
          });
        } else {
          setVerification({
            ...verification,
            PAN: false,
            panDetails: data.response_message,
          });
          setFormErrors({ ...formErrors, PAN: data.response_message });
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const aadhaarVerificationSendOtp = async () => {
    if (!formValues.AdharNumber || formValues.AdharNumber.length !== 12) {
      setFormErrors({
        ...formErrors,
        AdharNumber: "please enter valid Aadhaar Number",
      });
      return;
    }
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0s7bi6I2oq4bBIN05pcn-22zDbEC2K0B-nviBgFvqOVktA2C3i9oiZosmjfVMRHl6yl4r7gQLI2ZVmyyizy0WMvnTrhuMiYZcm4mq1dFs3gDB12EfgpSmQlELsaMjdVtHd6afBouTY7NsGoGe-5C3qq8ltT0zaCLnxVWjHIaJqSwNpWvun0YiFGI5tQ4hUFCPE6ilxS4u2myz3VyS2eSrGtFcvaPilfGyORm_J7W558pqhOshhR8JXR-3q8miWrt1Ji5twUizGXy0CfYM8UbEY3gPvXkyqHttYp-8X3WxmtrickDNedeBLMSs9xK80SG1HgErLU1plIlnUnmfeuUnNfLOejtKfnSkIQrMnMvKvvz-LrgPPRcf5FQntJNVXzZmZayQ8dHefiQhcfhNBwVc52CfLIzVCRcv0gICybhfOg-mQitlKMh-dXkMlhB7SUfBJX5SAQMcAWfS8DM-LvQjN6NKbXnsdy4vo5Qn0eEESnDlyX_lSEzb2lBdDlGvmFY-J9wrCk_49rT7lq-aweV8CFa6qrEBUO3t6uNY9i4s4s_7uICtutJvtiMJOi24bxNTWB22eVhsuroLrVtIswVKXMA8lRiECbsjG8lcKUk0_LU1FQz7soVehwvZa3j0y8xZ2NJPzMdl04OMGCOCWl-Up"
      );

      var raw = JSON.stringify({
        verifydatavalue: formValues.AdharNumber,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Verify/aadharotpsend`,
        requestOptions
      );
      const data = await res.json();
      if (data.success) {
        if (data.response_code == "100" && data.result) {
          setVerification({
            ...verification,
            otpSent: data.result.is_otp_sent,
            aadhaar_request_id: data.request_id,
          });
        } else {
          setVerification({
            ...verification,
            ADHAR: false,
            adhaarDetails: data.response_message,
          });
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const aadhaarVerification = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        request_id: verification.aadhaar_request_id,
        otp: formValues.OTP,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Verify/aadharotpverify",
        requestOptions
      );
      const data = await res.json();
      if (data.success) {
        if (data.response_code == "100") {
          setVerification({
            ...verification,
            ADHAR: true,
            adhaarDetails: data.result,
          });
        } else {
          setVerification({
            ...verification,
            PAN: false,
            panDetails: data.response_message,
          });
          setFormErrors({ ...formErrors, PAN: data.response_message });
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const Createdistributor = () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      retailername: formValues.FullName,
      retaileremail: formValues.EmailID,
      retailershopaddress: formValues.ShopAddress,
      retailershopname: formValues.ShopName,
      retailershoppin: formValues.Pincode,
      retaileraadhar: formValues.AdharNumber,
      retailercretedby: user.pk_user_id,
      retailerpassword: New,
      retailertype: "3",
      retailerloginid: New,
      retailerpancard: formValues.PanCard,
      retailergst: "WEB",
      Tpin: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/create_distributor.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            cogoToast.success(result.msg);
            history.push("/master-distributor/dashboard");
          } else {
            cogoToast.error(result.msg);
          }
          setTransactionLoaderState(true);
        })
        .catch((err) => {
          console.log(err);
          setTransactionLoaderState(true);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      Createdistributor();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.FullName) {
      errors.FullName = "*Name is required!";
    }

    if (!values.EmailID) {
      errors.EmailID = "*Email is required!";
    } else if (!regex.test(values.EmailID)) {
      errors.EmailID = "*This is not a valid email format!";
    }

    if (!values.P_Address) {
      errors.P_Address = "*Personal address is required!";
    } else if (values.P_Address.length <= 10) {
      errors.P_Address = "*Please enter valid address!";
    }

    if (!values.ShopName) {
      errors.ShopName = "*Shop name is required!";
    }

    if (!values.Pincode) {
      errors.Pincode = "*Pincode is required!";
    } else if (values.Pincode.length != 6) {
      errors.Pincode = "*Please enter valid pincode!";
    }

    if (!values.ShopAddress) {
      errors.ShopAddress = "*Personal address is required!";
    } else if (values.ShopAddress.length <= 10) {
      errors.ShopAddress = "*Please enter valid address!";
    }

    if (!values.AdharNumber) {
      errors.AdharNumber = "*Adhar number is required!";
    } else if (values.AdharNumber.length != 12) {
      errors.AdharNumber = "*Enter valid Adhar number";
    } else if (values.AdharNumber === NaN) {
      errors.AdharNumber = "*Enter valid Adhar number";
    }

    if (!values.PanCard) {
      errors.PanCard = "*Pancard number is required!";
    } else if (values.PanCard.length != 10) {
      errors.PanCard = "*Enter valid Pancard number";
    }

    return errors;
  };
  return (
    <div className="p-4">
      <div className="mt-4 d-flex justify-content-start" style={{ textAlign: "center" }}>
        <span className="row  m-0 p-0 pr-2" style={{color: 'rgba(66, 103, 178, 1)',"fontFamily":"Poppins","fontSize":"24px","fontWeight":"600","lineHeight":"36px","letterSpacing":"0em","textAlign":"left"}}>

          Distributor No: {" "}
        </span>
        <span className="row  m-0 p-0" style={{color: 'rgba(0, 0, 0, 1)',"fontFamily":"Poppins","fontSize":"24px","fontWeight":"300","lineHeight":"36px","letterSpacing":"0em","textAlign":"left"}}>{New}</span>
       
      </div>
      <hr />

      <Row>
        <Col md={6}>
         
          <Form.Label >
            <span className="text-danger" >*</span>
            <span className="text-dark" >Enter Full Name</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Full Name"
            onChange={handleChange}
            name="FullName"
            value={formValues.FullName}
            style={{ borderRadius: "0px" }}
        />
          <p style={{ color: "red" }}>{formErrors.FullName}</p>
        </Col>
        <Col md={6}>
          
          <Form.Label >
            <span className="text-danger" >*</span>
            <span className="text-dark" >Enter Email ID </span>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            onChange={handleChange}
            name="EmailID"
            value={formValues.EmailID}
            style={{ borderRadius: "5px" }}
          />
          <p style={{ color: "red" }}>{formErrors.EmailID}</p>
        </Col>
        <Col md={6} className="mt-3">
         
          <Form.Label >
            <span className="text-danger" >*</span>
            <span className="text-dark" >Enter Personal Addresss </span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Address"
            onChange={handleChange}
            name="P_Address"
            value={formValues.P_Address}
            style={{ borderRadius: "5px" }}
          />
          <p style={{ color: "red" }}>{formErrors.P_Address}</p>
        </Col>
        <Col md={12} className="mt-3">
          <div style={{ color: "gray" }}>
            <h4>Shop Detail</h4>
          </div>
          <hr />
        </Col>
        <Col md={6}>
         

           
          <Form.Label >
            <span className="text-danger" >*</span>
            <span className="text-dark" >Enter Shopname </span>
          </Form.Label>
          
          <Form.Control
            type="text"
            placeholder="Shop Name"
            onChange={handleChange}
            name="ShopName"
            value={formValues.ShopName}
            style={{ borderRadius: "5px" }}
          />
          <p style={{ color: "red" }}>{formErrors.ShopName}</p>
        </Col>
        <Col md={6}>
       
          <Form.Label >
            <span className="text-danger" >*</span>
            <span className="text-dark" >Enter Pin Code </span>
          </Form.Label>
          
          <Form.Control
            type="number"
            placeholder="Shop Pincode"
            onChange={handleChange}
            name="Pincode"
            value={formValues.Pincode}
            style={{ borderRadius: "5px" }}
          />
          <p style={{ color: "red" }}>{formErrors.Pincode}</p>
        </Col>

       
        <br></br>
        <Col md={6} className="mt-2">
        <Col md={6} className="mt-2 p-0 m-0">
          <input
            type="checkbox"
            onChange={() => {
              setFormValues({
                ...formValues,
                ShopAddress: formValues.P_Address,
              });
            }}
          />
          <span className="text-dark pl-2">
            Same as above
          </span>
        </Col>
         
         

          <Form.Label >
            <span className="text-danger" >*</span>
            <span className="text-dark" >Enter Shop Address</span>
          </Form.Label>


          <Form.Control
            type="text"
            placeholder="Shop Address"
            onChange={handleChange}
            name="ShopAddress"
            value={formValues.ShopAddress}
            style={{ borderRadius: "5px" }}
          />
          <p style={{ color: "red" }}>{formErrors.ShopAddress}</p>
        </Col>

        <Col md={12} className="mt-3">
          <div style={{ color: "gray" }}>
           <h4>Document Detail</h4>
          </div>
          <hr />
        </Col>
        <div className="col-6">
          <Form.Label>
          <span className="text-danger" >*</span>
            <span className="text-dark" >Enter Aadhar Number </span>
        
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Aadhar Number"
            onChange={handleChange}
            name="AdharNumber"
            value={formValues.AdharNumber}
            style={{ borderRadius: "5px" }}
          />
          
          <p style={{ color: "red" }}>{formErrors.AdharNumber}</p>
        </div>
        {verification.otpSent && !verification.ADHAR && (
          <div
            className="col-1 justify-content-start align-items-center"
          >
            <div style={{ flex: "1" }}>
              <Form.Group>
                <Form.Label>
                <span className="text-danger" >*</span>
               <span className="text-dark" >  OTP:</span>
                 
               
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  name="OTP"
                  value={formValues.OTP}
                  onChange={handleChange}
                />
                <p style={{ color: "red" }}>{formErrors.OTP}</p>
              </Form.Group>
            </div>
            <Button className="m-0 mt-4 ml-4" onClick={aadhaarVerification}>
              {" "}
              Submit OTP
            </Button>
          </div>
        )}
        <div
          className="row mt-3 justify-content-start align-items-center"
        >
          {verification.ADHAR ? (
            <label
              style={{
                padding: "4px",
                //  borderRadius: "4px",
                minWidth: "4rem",
              }}
              className="badge"
            >
              <img
                src={require("../../../assets/images/success_tick.jpg")}
                style={{
                  height: "1rem",
                  width: "auto",
                  borderRadius: "1rem",
                  marginRight: ".2rem",
                }}
              />
              {"Verified"}
            </label>
          ) : !verification.otpSent ? (
            <Button className="mx-4" onClick={aadhaarVerificationSendOtp}>
              Send OTP
            </Button>
          ) : null}
        </div>

        <div className="col-6">
          <Form.Label >
          <span className="text-danger" >*</span>
               <span className="text-dark" >  Enter Pan Card Details</span>
          
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Pan card Number"
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[A-Za-z0-9]*$/; // matches alpha-numeric values only
              if (regex.test(value)) {
                setFormValues({ ...formValues, PanCard: value.toUpperCase() });
              }
            }}
            name="PanCard"
            value={formValues.PanCard}
            style={{ borderRadius: "5px", textTransform: "uppercase" }}
          />
          <p style={{ color: "red" }}>{formErrors.PanCard}</p>
        </div>
        <div className="row mt-3  align-self-center"
        >
          {verification.PAN ? (
            <label
              style={{
                padding: "4px",
                //  borderRadius: "4px",
                minWidth: "100%",
              }}
              className="badge"
            >
              <img
                src={require("../../../assets/images/success_tick.jpg")}
                style={{
                  height: "1rem",
                  width: "auto",
                  borderRadius: "1rem",
                  marginRight: ".2rem",
                }}
              />
              {"Verified"}
            </label>
          ) : (
            <Button className=" btn btn-block btn-lg  " onClick={panVerification}>
              <div className="px-3">Verify</div>
            </Button>
          )}
        </div>

        <div  className=" col-6  mt-4">
          <Form.Control
            type="number"
            name="GST"
            placeholder="GST Number (If Availbale)"
            onChange={handleChange}
            value={formValues.GST}
            style={{ borderRadius: "5px" }}
          />
          <p style={{ color: "red" }}>{formErrors.GST}</p>
        </div>
        <div className="col-5"></div>
        <div className=" col-2  mt-4">
          <Button
            type="button"
            className="btn btn-lg form-control btn-block "
            onClick={handleSubmit}
            style={{ borderRadius: "5px" }}
          >
            Submit
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default NewDistributor;
