import React, { useState, useEffect, useContext } from "react";
import '../../api_partner/custom-tabs.css';
import { Badge, Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../../retailer/Topup View/TopupView.css";

export const columns = [
    {
        dataField: "payment_indate",
        text: "Transaction Date",
    },
    {
        dataField: "tid",
        text: "Transaction ID",
    },
    {
        dataField: "orderid",
        text: "Order ID",
    },
    {
        dataField: "us_code",
        text: "User Code",
    },
    {
        dataField: "us_name",
        text: "User Name",
    },
    {
        dataField: "opening_bal",
        text: "Opening Balance",
    },
    {
        dataField: "load_amount",
        text: "Amount",
    },
    {
        dataField: "closing_bal",
        text: "Closing Balance",
    },

    {
        dataField: "chages",
        text: "chages",
    },
    {
        dataField: "gst",
        text: "GST",
    },
    {
        dataField: "mode",
        text: "Mode",
    },
    {
        dataField: "card",
        text: "Card",
    },
    {
        dataField: "route_name",
        text: "Route",
    },
    {
        dataField: "extra",
        text: "Tariff",
    },
    {
        dataField: "status",
        text: "Status",
    },
    {
        dataField: "sales_user_code",
        text: "Sales Code",
    },
    {
        dataField: "sales_user_name",
        text: "Sales Name",
    },
    {
        dataField: "sales_user_mobile",
        text: "Sales Mobile Number",
    },
];


const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
};
const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
};
const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
};

const positionStyle1 = {
    position: "absolute",
    top: -43,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
};
const positionStyle2 = {
    position: "absolute",
    top: -44,
    left: -12,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
};

const LienAmount = () => {
    const [agentId, setAgentId] = useState("");

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    return (


        <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>

            <div>

                <div>

                    <div className="card m-0 p-0 pt-3">
                        <div className="card-body m-0 p-0">



                            <div className="">
                                <div defaultActiveKey="profile" className=" tab-header justify-content-start">
                                    <div
                                        className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        Lien List
                                    </div>
                                    <div
                                        className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Lien Amount
                                    </div>

                                </div>
                                <div className="tab-content">
                                    {activeTab === 0 && <div><VendorTopupReport agentId={agentId} /></div>}
                                    {activeTab === 1 && <div><AddFundsAdmin agentId={agentId} /></div>}

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

        </div>

    );
};

const VendorTopupReport = () => {
    const [searchText, setSearchText] = useState("");
    const { user } = useContext(GlobalContext);
    const [formData, setFormData] = useState({
        signerName: "",
        signerEmail: "",
        signerCity: "",
        purpose: "",
        pdfFile: null,
        numPages: null,
        file: null
    });

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };


    const [state, setState] = useState({
        amount: "",
    });

    const [errors, setErrors] = useState({
        amount: "",
    });

    const [selectedOption, setSelectedOption] = useState('');

    const [selectedVendor, setSelectedVendor] = useState({});
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const OpenModal = () => {
        setShowModal(!showModal);
    };

    const getLienAmount = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userid": user.pkid,
            "adminId": user.pkid,
            fromdate: dateFormatter(startDate),
            todate: dateFormatter(endDate),
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            const res = await fetch("https://apiservices.bumppypay.com/api/Admin/lienAmountReport", requestOptions);

            const data = await res.json();
            if (data.data && data.data.length > 0) {
                setData(data.data);
            } else {
                swal("Failed", data.msg, "error");
            }
        } catch (error) {

        }


    }

    const vendorTopup = async (e) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                adminid: user.pkid,
                vendorid: selectedVendor.id,
                amount: state.amount
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(
                `${BASE_URL_APISERVICES}/api/Admin/adminVendorTopup`,
                requestOptions
            ).then((res) => res.json()).then((data) => {
                if (data?.status == "0") {
                    // setData(data?.data);
                    swal("Success", "Topup Success", "success");
                }
                else { swal("Error", "Something went wrong", "error"); }
            }).catch((err) => {
                console.log("error", err);
            });
        } catch (error) { }


    };
    const twoDecimalPlaces = (amount) => {
        return Number(amount).toFixed(2);
    }
    const clearForm = () => {
        setFormData({
            signerName: "",
            signerEmail: "",
            signerCity: "",
            purpose: "",
            pdfFile: null,
            numPages: null,
            file: null
        });
    }

    useEffect(() => {
        getLienAmount()
    }
        , [])


    return (
        <div className="m-0">

            <div className="row m-0 p-0 pt-1 align-items-top">
                <div className="col-3 m-0 p-0">
                    <div
                        className="m-0 p-0 pl-3 align-top"
                        style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            lineHeight: "48px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            padding: "0px",
                            background: "#FFFFFF",
                            color: "#4267B2",
                            fontWeight: "bolder",
                            fontfamily: "Poppins !impotent",
                            fontSize: "28px",
                        }}
                    >
                        Lien List
                    </div>
                </div>

                <div className="col-9 m-0 p-0 ">
                    <div className="row m-0 p-0 justify-content-end">
                        <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                            <FontAwesomeIcon
                                icon={faSearch}
                                style={iconStyle}
                                className="ml-2"
                            />
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Search"
                                aria-label="Search"
                                style={inputStyle}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                value={searchText}
                            />
                        </div>

                        <div
                            className="pr-1 pl-2 p-0 m-0"
                            style={{ width: "18%", height: "20%" }}
                        >
                            <Form.Group className="p-0 m-0">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="Start Date"
                                />
                            </Form.Group>
                        </div>
                        <div
                            className="px-1 p-0 m-0"
                            style={{ width: "18%", height: "20%" }}
                        >
                            <Form.Group className="p-0 m-0">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="End Date"
                                />
                            </Form.Group>
                        </div>

                        <div
                            className="pl-1 p-0 m-0"
                            style={{ width: "14%", height: "20%" }}
                        >
                            <Form.Group className="mt-0 p-0 m-0">
                                <Button
                                    className="rounded-50 btn btn-primary btn-lg btn-block "
                                    onClick={getLienAmount}
                                >
                                    {"Search"}
                                </Button>
                            </Form.Group>
                        </div>

                        <div
                            className="m-0 p-0 hover-container"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                ExportToExcel(
                                    `PG_Report_${now()}`,
                                    excelMapping(data, columns)
                                );
                            }}
                        >
                            <img
                                className="pl-1 p-0"
                                src={require("../../../assets/images/download Icon.png")}
                                style={{ width: `3.5rem`, height: "85%" }}
                            />
                            <div style={positionStyle1} className="hover-text bg-dark ">
                                Export to Excel
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <hr className="m-0 p-0" />

            <div className="m-0 p-0 card mx-3 center-block scroll-inner">
                <table className="dashboard-table-style" style={{ width: "100%", overflowX: "auto" }}>
                    <tr>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Sr</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>User Code</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>User Name</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>User Mobile</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275"  , width:"5rem"}}>Remark</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Amount</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Leaned By Name</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Leaned By Code</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Type</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Date</th>
                        <th className="text-center" style={{ fontSize: "0.9rem", background: "#f5f6f8", color: "#586275" }}>Update</th>
                    </tr>
                    {data.map((e, ind) => (
                        <tr key={ind}>
                            <td style={{ fontSize: "1rem" }}>{ind + 1}</td>
                            <td style={{ fontSize: "1rem" }}>{e.us_code}</td>
                            <td style={{ fontSize: "1rem" }}>{e.us_name}</td>
                            <td style={{ fontSize: "1rem" }}>{e.us_mobile}</td>
                            <td style={{ fontSize: "1rem", width:"5rem" }}>{e.remark}</td>
                            <td style={{ fontSize: "1rem" }}>{twoDecimalPlaces(e.amount)}</td>
                            <td style={{ fontSize: "1rem" }}>{e.leaned_by_name}</td>
                            <td style={{ fontSize: "1rem" }}>{e.leaned_by_code}</td>
                            <td className="text-center m-0 p-0 " style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>

<div className="m-0 p-0">
  {e.type == "REMOVE LIEN" ? (
    <span
      style={{

        borderRadius: "5px",
        border: "1px solid",
        backgroundColor: 'rgba(43, 193, 85, 0.1)',
        color: 'rgba(43, 193, 85, 1)',
        bordercolor: 'rgba(43, 193, 85, 1)',

      }} className="px-2 py-2"
    >
      {`${e.type}`}
    </span>
  ) : (
    <span
      className="m-0 p-0 px-2 py-2"
      style={{


        borderRadius: "5px",
        border: "1px solid",
        backgroundColor: 'rgba(255, 46, 46, 0.1)',
        color: 'rgba(255, 46, 46, 1)',
        bordercolor: 'rgba(255, 46, 46, 1)',

      }}

    >
      {`${e.type}`}
    </span>
  )}
</div>

</td>
                            <td style={{ fontSize: "1rem" }}>{e.indate}</td>
                            <td style={{ fontSize: "1rem" }}>{e.update}</td>
                        </tr>
                    ))}
                </table>

            </div>
        </div>
    );
};

const AddFundsAdmin = () => {
    const [ttype, setTtype] = useState([
        // { label: "Add Funds", value: "1" },
        { label: "Reverse Funds", value: "2" },
    ]);
    const [ttype2, setTtype2] = useState([
        { label: "Add Lean", value: "1" },
        { label: "Remove Lean", value: "2" },
    ]);
    const [state, setState] = useState({
        userCode: "",
        utrNo: "",
        amount: "",
        amount2: "",
        bank: null,
        date: "",
        paymode: "",
        ttype: "",
        ttype2: "",
        remaks: "",
        remaks2: "",
    });
    const [userInfo, setUserInfo] = useState(null);
    const [wallet, setWallet] = useState({});
    const [pageAccess, setPageAccess] = useState({});
    const { user, setTransactionLoaderState } = useContext(GlobalContext);

    const twoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).toString();
    };

    const searchUser = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                UserID: state.userCode,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT_API}/api/admin/get_user_details_create_load_request_admin.aspx`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                setUserInfo(data.data);
            } else {
                swal(
                    "User Not Found",
                    "Please Enter Valid ID Or Mobile number",
                    "error"
                );
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };
    const clearData = () => {
        setState({
            ttype: "",
            amount: "",
            remaks: "",
        });
        setUserInfo(null);
    };

    const validateForm = () => {
        console.log(state);
        if (state.ttype == "") {
            swal("Invalid", "Please Enter Valid Type", "error");
            return;
        } else if (state.amount == "" || state.remarks == "") {
            swal("Invalid", "Fill All Mandatory Fields", "error");
            return;
        }
        addFunds();
    };

    const addFunds = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: userInfo.us_code,
                adminid: user.pkid,
                type: state.ttype.value,
                amount: state.amount,
                remaks: state.remaks,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT_API}/api/admin/users/admin_fund_transfer.aspx`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", data.msg, "success");
                searchUser();
            } else {
                swal("Failed", data.msg, "error");
            }

            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", "Something Went Wrong", "error");
            console.log("error", error);
        }
    };

    const leanAmount = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userid": userInfo.us_code,
            "adminId": user.pkid,
            "amount": state.amount2,
            "remark": state.remaks2,
            "type": state.ttype2.value,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            const res = await fetch("https://apiservices.bumppypay.com/api/Admin/agentLeanAmount", requestOptions);

            const data = await res.json();
            if (data.responseCode == "00") {
                swal("Success", data.responseMessage, "success");
                getWalletInfo();
            } else {
                swal("Failed", data.responseMessage, "error");
            }
        } catch (error) {

        }


    }

    const getWalletInfo = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userid": state.userCode,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            const res = await fetch("https://apiservices.bumppypay.com/api/Payment/wallet/getWalletBalance", requestOptions);

            const data = await res.json();
            if (data.status == "0") {
                setWallet(data.data);
            } else {
                swal("Failed", data.msg, "error");
            }
        } catch (error) {

        }


    }

    const getPageAccess = (menuId) => {
        const sub_menus_access_info = JSON.parse(sessionStorage.getItem("sub_menus"));
        sub_menus_access_info.forEach((acc) => {
            if (acc.pk_menu_id == menuId) {
                setPageAccess(acc);
                if (acc.str_access_rights == "1") {
                    setTtype([...ttype, { label: "Add Funds", value: "1" }])
                }
            }
        })
    }

    useEffect(() => {
        getPageAccess(7);
    }, [])

    console.log(wallet);

    return (
        <GlobalContext.Consumer>
            {(context) => (
                <div style={{ padding: "1rem" }}>
                    <div style={{ borderRadius: "22px" }} className="card p-0">
                        <div className="card-body">
                            <div>
                                <div
                                    style={{
                                        padding: "12px",
                                        fontWeight: "bolder",
                                    }}
                                >
                                    Lien Amount
                                </div>

                                <div className="row">
                                    <div className="col-6 col-sm-4 col-md-3">
                                        <Form.Group>
                                            <Form.Label>
                                                <span style={{ color: "red" }}>*</span>
                                                {`Enter User Code Or Mobile Number`}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control"
                                                aria-label="Notes"
                                                aria-describedby="basic-addon1"
                                                placeholder="Enter User Code Or Mobile Number"
                                                value={state.userCode}
                                                onChange={(e) => {
                                                    setState({ ...state, userCode: e.target.value });
                                                }}
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-6 col-sm-4 col-md-3 d-flex">
                                        <Form.Group className="mt-4">
                                            <Button
                                                onClick={() => {
                                                    searchUser();
                                                    getWalletInfo();

                                                }}
                                                className="btn btn-primary rounded-0"
                                            >
                                                {" "}
                                                Search
                                            </Button>
                                        </Form.Group>
                                        <Form.Group className="mt-4 ml-2">
                                            <Button
                                                onClick={clearData}
                                                className="btn btn-danger rounded-0 ml-3"
                                            >
                                                {" "}
                                                Clear
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </div>

                                {userInfo && (
                                    <div
                                        className="card"
                                        style={{
                                            padding: "12px",
                                            fontWeight: "bolder",
                                        }}
                                    >
                                        <div className="row d-flex">
                                            <div className="col-6">
                                                <div>
                                                    {" "}
                                                    {`User : ${userInfo.us_name} [${userInfo.us_code}]`}
                                                </div>
                                                <div>
                                                    {" "}
                                                    {`User Mobile : ${userInfo.us_loginid}`}
                                                </div>
                                                <div> {`User Type : ${userInfo.usrtype}`}</div>

                                            </div>
                                            <div className="col-6">
                                                <div>
                                                    {" "}
                                                    {`User Balance : ${twoDecimalPlaces(
                                                        userInfo.usrbal
                                                    )}`}
                                                </div>
                                                <div>
                                                    {`Lean Amount : ${twoDecimalPlaces(wallet.lean_amount)}`}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )}


                                {userInfo && (
                                    <div className="row">
                                        <div className="col-6 col-sm-4 col-md-3">
                                            <Form.Group>
                                                <Form.Label>
                                                    <span style={{ color: "red" }}>*</span>
                                                    {`Enter Amount`}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Enter Amount"
                                                    value={state.amount2}
                                                    onChange={(e) => {
                                                        setState({ ...state, amount2: e.target.value });
                                                    }}
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3">
                                            <Form.Group>
                                                <Form.Label>
                                                    <span style={{ color: "red" }}>*</span>
                                                    {`Select Type`}
                                                </Form.Label>
                                                <Select
                                                    options={ttype2}
                                                    value={state.ttype2}
                                                    onChange={(e) => {
                                                        setState({ ...state, ttype2: e });
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-6 col-sm-4 col-md-3">
                                            <Form.Group>
                                                <Form.Label>
                                                    <span style={{ color: "red" }}>*</span>
                                                    {`Enter Remark`}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Enter Remark"
                                                    value={state.remaks2}
                                                    onChange={(e) => {
                                                        setState({ ...state, remaks2: e.target.value });
                                                    }}
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-6 col-sm-4 col-md-3">
                                            <Form.Group className="mt-4">
                                                <Button
                                                    onClick={leanAmount}
                                                    className="btn btn-primary rounded-0"
                                                >
                                                    Submit
                                                </Button>

                                            </Form.Group>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </GlobalContext.Consumer>
    );
};


export default LienAmount;