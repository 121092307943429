import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import { Form, Button } from "react-bootstrap";
import { ExportToExcel } from "../../utils/utilFuctions";
import swal from "@sweetalert/with-react";
import { Link } from "react-router-dom";
import { useQuery } from "../../custom-hooks/useQuery";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import { generateHashKey2 } from "../../utils/utilFuctions";

export const columns = [
    {
        dataField: "bankName",
        text: "Bank",
    },
    {
        dataField: "accountNo",
        text: "Account Number",
    },
    {
        dataField: "ifscCode",
        text: "IFSC Code",
    },
    {
        dataField: "transType",
        text: "Transaction Type",
    },
    {
        dataField: "charges",
        text: "charges",
    },
 
];

const AllBankDetailsReport = () => {
    const [banks,setBanks] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(10);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState([]);
 
    const [page, setPage] = useState(1);
    const { Profileinfo, setTransactionLoaderState } = useContext(GlobalContext);

    const query = useQuery();
    let paramId = query.get("id") ? query.get("id") : "1";
    console.log(paramId);

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);


 

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2('9168805979');
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(`${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`, requestOptions);
            const data = await res.json();
            setData(data);
            setTransactionLoaderState(false);
        } catch (error) {

            console.log(error);
            setTransactionLoaderState(false);
        }
    };

console.log(dataToDisplay);

    useEffect(() => {
        getBankDetails();
    }, [])

    return (
        <div>
            <div>
                <div className="row m-0 p-0  pt-1 align-items-center">
                    <div className="col-4 m-0 p-0">
                        <div className="m-0 p-0 pl-2"
                            style={{
                                "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                , padding: "0px",
                                background: "#FFFFFF",
                                color: "#4267B2",
                                fontWeight: "bolder",
                                fontfamily: "Poppins !impotent",
                                fontSize: "28px",
                            }}
                        >
                            All Bank Details
                        </div>
                    </div>

                    <div className="col-8 m-0 p-0 ">
                        <div className="row  m-0 p-0  justify-content-end">
                            <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
                            </div>
                            <div className="m-0 p-0 hover-container"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    ExportToExcel(`Bank_Detail_Table${now()}`, dataToDisplay);
                                }}
                            >  <img className="pl-1"
                                    src={require("../../../assets/images/download Icon.png")}
                                    style={{ width: `3.5rem`, height: '85%' }}
                                />
                                <div div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
                            </div>
                        </div>
                    </div>
                </div>
 <hr className="m-0 p-0" />
  <table style={{ width: "100%", backgroundColor: '#FFFFFF' }} className="my-table  borderless transparent-table">
                    <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Sr
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Bank
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                        Account Number
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                        IFSC Code
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                        Transaction Type
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                        Charge
                        </th>
                      
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className="my-table transparent-table border-bottom py-5" >
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>  {ind + 1}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.bankName}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.accountNo}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.ifscCode}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.transType}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.charges}</td>
                            </tr>
                    ))}
                </table>
                <Pagination
                    page={page}
                    limit={limit}
                    callback={(page) => {
                        setCurrentPage(page);
                    }}
                    count={dataToDisplay.length}
                    total={total}
                />
            </div>
        </div>
    );
};

export const Pagination = ({
    page,
    limit,
    callback,
    total,
    count,
    callback2,
}) => {
    console.table({ page, limit, callback });
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>
            <div className="d-flex btn-group">
                <select
                    className="rounded-0 form-select-sm"
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset}>{offset}</option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0 m-0"
                    >
                        {"Previous"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0 m-0"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}
                <Button variant="primary" className="rounded-0 m-0">
                    {" "}
                    {page.toString()}
                </Button>
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        variant="light"
                        className="rounded-0 m-0"
                    >
                        {"Next"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default AllBankDetailsReport;