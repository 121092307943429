import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import AllUserKYCTable from "./AllUserKYCTable";
import KYCDetailsSidebar from "./KYCDetailsSidebar";
const AllUserKYC = () => {
  const [details, setDetails] = useState({});
  return (
    <GlobalContext.Consumer>
      {(context) => (

        <div style={{ padding: "1rem" }} className="bg-light">
          <KYCDetailsSidebar details={details} />
          <div style={{ borderRadius: "10px" }} className="card mt-4 mx-2 p-0">
            <div className="card-body m-0 p-2">
              <div>
                <div className="px-3 pb-2"
                  style={{
                    "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                    , padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize: "32px",
                  }}
                >
                  All User KYC
                </div>

                <AllUserKYCTable setDetails={(e) => {
                  setDetails(e)
                  document
                    .querySelector(".content-wrapper")
                    .classList.toggle("sidebar-icon-only");
                }} />
              </div>
            </div>
          </div>
        </div>






      )}
    </GlobalContext.Consumer>
  );
};

export default AllUserKYC;
