import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import VirtualPaymentReportTable from "./VirtualPaymentReportTable";
const VirtualPaymentReport = () => {
    return (
        <GlobalContext.Consumer>
            {(context) => (

                <div style={{ padding: "1rem" }} className="bg-light ">
                    <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
                        <div className="card-body m-0 p-2">
                            <div>

                                <VirtualPaymentReportTable />
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </GlobalContext.Consumer>
    );
};

export default VirtualPaymentReport;