import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Card, Accordion, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { GlobalContext } from "../../Context/GlobalState";
import {
    BASE_URL_APISERVICES,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../../retailer/Topup View/TopupView.css";
export const columns = [
    {
        dataField: "payment_indate",
        text: "Transaction Date",
    },
    {
        dataField: "tid",
        text: "Transaction ID",
    },
    {
        dataField: "orderid",
        text: "Order ID",
    },
    {
        dataField: "us_code",
        text: "User Code",
    },
    {
        dataField: "us_name",
        text: "User Name",
    },
    {
        dataField: "opening_bal",
        text: "Opening Balance",
    },
    {
        dataField: "load_amount",
        text: "Amount",
    },
    {
        dataField: "closing_bal",
        text: "Closing Balance",
    },

    {
        dataField: "chages",
        text: "chages",
    },
    {
        dataField: "gst",
        text: "GST",
    },
    {
        dataField: "route_name",
        text: "Route",
    },
    {
        dataField: "status",
        text: "Status",
    },
    {
        dataField: "sales_user_code",
        text: "Sales Code",
    },
    {
        dataField: "sales_user_name",
        text: "Sales Name",
    },
    {
        dataField: "sales_user_mobile",
        text: "Sales Mobile Number",
    },
];
const tdstyle = {
    fontSize: "0.8rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const tdstyle2 = {
    width: "10%",
    fontSize: "0.8rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const thstyle = {
    color: "dark",
    backgroundColor: "white",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const GetAllBillTable = () => {
    const [amount, setAmount] = useState("");
    const [fetchBillModal, setFetchBillModal] = useState(false);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);
    const [state, setState] = useState({
        provider: "",
        consumer: "",
        mobileNumber: "",
        custparams: "",
    });
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [mobile, setMobile] = useState("");
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [apiResponseModal, setApiResponseModal] = useState(false);
    const [apiResponseDetails, setApiResponseDetails] = useState({});
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { Profileinfo } = useContext(GlobalContext);

    const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
    const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
    const [billDetails, setBillDetails] = useState(null);
    const [billPayResponse, setBillPayResponse] = useState(null);
    const [PayClicked, setPayClicked] = useState(false);
    const [CustP, setCustP] = useState([]);
    const [billerResponse, setBillerResponse] = useState({
        msg: "",
        success: "",
        error: [],
    });
    const [providers, setProviders] = useState([]);
    const [billFetched, setBillFetched] = useState(false);
    const [tpinShow, setTpinShow] = useState(false);

    const { username, password } = { username: "Rajneesh@example.com", password: "Test@12345" };


    console.log(data);

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    const openInNewTab = (url) => {

        const newWindow = window.open(
            url,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const twoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).toString();
    };

    const fetchBill = async (e) => {
        setBillerResponse({ msg: "", success: "", error: [] });
        setTransactionLoaderState(true);
        setFetchButtonDisable(true);
        setBillDetails(null);
        setBillPayResponse(null);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Cookie",
            ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A__uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn_-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
        );

        let { customer_params: params, biller_id: billerId, rch_mobile: mobile } = e;

        params = JSON.parse(params)

        var raw = JSON.stringify({
            userid: "BR1004",
            customerMobileNo: mobile,
            billDetails: {
                billerId: billerId,
                customerParams: params,
            },
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        try {
            const res = await fetch(
                `https://apiservices.bumppypay.com/api/BBPS/billFetchOnline`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "FAILURE") {
                swal("FAILURE", data.response.complianceReason, "error");
            } else {
                // swal("Success", "Successfull", "success");
            }
            if (
                data.respCode == "1" &&
                data.response &&
                data.response.billerResponse &&
                data.response.billerResponse.customerName &&
                data.response.billerResponse.customerName != ""
            ) {
                setBillerResponse({
                    ...billerResponse,
                    error: [],
                    msg: "",
                });
                setFetchBillModal(true);
                setBillDetails(data.response);
                setAmount(data.response.billerResponse.amount);
            } else if (
                data.respCode == "0" &&
                data.response &&
                data.response.errorList &&
                data.response.errorList.length > 0
            ) {
                setBillerResponse({
                    ...billerResponse,
                    error: data.response.errorList,
                    msg: "",
                });
            } else {
                setBillerResponse({
                    ...billerResponse,
                    error: [],
                    msg: "Something went wrong",
                });
            }
            setTransactionLoaderState(false);
        } catch (error) {
            console.log("error", error);
            setTransactionLoaderState(false);
        }
    };



    const getBBPSBills = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: "BR1004",
                adminid: "9",
                fromdate: dateFormatter(startDate),
                todate: dateFormatter(endDate),

            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/BBPS/getBBPSBills`,
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                // setData(data.data);
                setDataToDisplay(data.data)
            } else {
                setData([]);
            }

            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };


    const showResponse = (response) => {
        setApiResponseDetails(response);
        setApiResponseModal(true);
    };

    useEffect(() => {
        getBBPSBills();
    }, []);

    const searchBarStyle = {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "0px",
        backgroundColor: "#fff",
        width: "25%", // Adjust the width as needed
        height: "20%",
    };
    const inputStyle = {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: "16px",
        marginLeft: "8px",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: "400",
    };
    const iconStyle = {
        color: "rgba(0, 0, 0, 0.5)",
    };

    const positionStyle1 = {
        position: "absolute",
        top: -43,
        left: 0,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };
    const positionStyle2 = {
        position: "absolute",
        top: -44,
        left: -12,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };

    console.log(data);

    return (
        <>

            <FetchBill
                fetchBillModal={fetchBillModal}
                setFetchBillModal={setFetchBillModal}
                billDetails={billDetails}
                billerResponse={billerResponse}

                billPayResponse={billPayResponse}
                amount={amount}
            // config={categoryInfo[serviceSelected - 1]}
            />

            <div className="row m-0 p-0 pt-1 align-items-top">
                <div className="col-3 m-0 p-0">
                    <div
                        className="m-0 p-0 pl-3 align-top"
                        style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            lineHeight: "48px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            padding: "0px",
                            background: "#FFFFFF",
                            color: "#4267B2",
                            fontWeight: "bolder",
                            fontfamily: "Poppins !impotent",
                            fontSize: "28px",
                        }}
                    >
                        Bill Fetch Report
                    </div>
                </div>

                <div className="col-9 m-0 p-0 ">
                    <div className="row m-0 p-0 justify-content-end">
                        <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                            <FontAwesomeIcon
                                icon={faSearch}
                                style={iconStyle}
                                className="ml-2"
                            />
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Search"
                                aria-label="Search"
                                style={inputStyle}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                value={searchText}
                            />
                        </div>

                        <div
                            className="pr-1 pl-2 p-0 m-0"
                            style={{ width: "18%", height: "20%" }}
                        >
                            <Form.Group className="p-0 m-0">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="Start Date"
                                />
                            </Form.Group>
                        </div>
                        <div
                            className="px-1 p-0 m-0"
                            style={{ width: "18%", height: "20%" }}
                        >
                            <Form.Group className="p-0 m-0">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="End Date"
                                />
                            </Form.Group>
                        </div>

                        <div
                            className="pl-1 p-0 m-0"
                            style={{ width: "14%", height: "20%" }}
                        >
                            <Form.Group className="mt-0 p-0 m-0">
                                <Button
                                    className="rounded-50 btn btn-primary btn-lg btn-block "
                                    onClick={getBBPSBills}
                                >
                                    {"Search"}
                                </Button>
                            </Form.Group>
                        </div>

                        <div
                            className="m-0 p-0 hover-container"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                ExportToExcel(
                                    `PG_Report_${now()}`,
                                    excelMapping(data, columns)
                                );
                            }}
                        >
                            <img
                                className="pl-1 p-0"
                                src={require("../../../assets/images/download Icon.png")}
                                style={{ width: `3.5rem`, height: "85%" }}
                            />
                            <div style={positionStyle1} className="hover-text bg-dark ">
                                Export to Excel
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <hr className="m-0 p-0" />
            <div className=" scroll-inner admin-fix-width transparent-table">
                <table className=" table table-borderless text-dark ">
                    <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
                        <th
                            className=" transparent-table text-center border-bottom text-dark"
                            style={thstyle}
                        >
                            {" "}
                            {"Sr"}
                        </th>

                        <th
                            className=" transparent-table text-center border-bottom text-dark"
                            style={thstyle}
                        >
                            {" "}
                            {"Service Name"}
                        </th>

                        <th
                            className=" transparent-table text-center border-bottom text-dark"
                            style={thstyle}
                        >
                            {" "}
                            {"Mobile"}
                        </th>

                        <th
                            className=" transparent-table text-center border-bottom text-dark"
                            style={thstyle}
                        >
                            {" "}
                            {"Biller"}
                        </th>

                        <th
                            className=" transparent-table text-center border-bottom text-dark"
                            style={thstyle}
                        >
                            {" "}
                            {"Action"}
                        </th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className=" transparent-table border-bottom ">
                            <td className="align-middle" style={tdstyle}> {ind + 1}</td>
                            <td className="align-middle" style={tdstyle}>
                                <span>{e.rch_service}</span>
                            </td>
                            <td className="align-middle" style={tdstyle}>
                                {e.rch_mobile}
                            </td>
                            <td className="align-middle" style={tdstyle}>
                                <div>{e.biller_id}</div>
                                <div>{e.biller_name}</div>
                            </td>
                            <td className="align-middle" style={tdstyle}>

                                <div className="row m-0 my-auto p-0 justify-content-center" >


                                    <Button style={{
                                        // paddingLeft:"3px",
                                        // padding: "5px",
                                        borderRadius: "5px",
                                        border: "1px none",
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        color: 'rgba(255, 164, 27, 1)',
                                        bordercolor: 'rgba(255, 255, 255, 1)',
                                    }} className="btn btn-sm rounded-100 m-0 p-1">
                                        {" "}

                                        <div
                                            onClick={() => fetchBill(e)}
                                            className="hover-container border-0"

                                        >
                                            {/* Your content here */}
                                            <img
                                                className="border-0"
                                                src={require("../../../assets/images/Raise Ticket.png")}
                                                style={{ borderRadius: '0px', width: '2.1rem' }}
                                            />
                                            <div style={positionStyle2} className="hover-text bg-dark"><div >Bank</div><div > Response</div></div>
                                        </div>


                                    </Button>

                                </div>

                            </td>



                        </tr>
                    ))}
                    {data.length <= 0 && <NoDataFound />}
                </table>
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </>
    );
};

export const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });

    const selectStyleLeftBorder = {
        borderRadius: "0px",
        fontFamily: "Poppins",
        fontsize: "16px",
        borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
        borderRadius: "0px",
        borderLeft: "none", // Remove right side border
        borderTop: "none", // Remove top border
        borderBottom: "none", // Remove bottom border
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyleBorder = {
        border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyle = {
        border: "none", // Remove the border
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div style={{ fontFamily: "Poppins" }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>

            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">
                            {offset}
                        </option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}

                <Button className="bg-white text-primary " style={selectStyle}>
                    {" "}
                    {page.toString()}
                </Button>

                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
export const NoDataFound = () => {
    return (
        <tr className="row w-100 justify-content-center">
            <div className="d-flex flex-column align-items-center p-3">
                <img
                    src={require("../../../assets/images/no_data_found.png")}
                    style={{ height: "5rem", width: "auto" }}
                />
                <strong>No Data Found</strong>
            </div>
        </tr>
    );
};

function FetchBill({
    setFetchBillModal,
    fetchBillModal,
    payBill,
    billDetails,
    billPayResponse,
    amount,
}) {
    const { user } = useContext(GlobalContext);
    const openInNewTab = (data) => {
        localStorage.setItem("print", JSON.stringify({ ...data, paid: false }));
        const CLIENT = "";
        const newWindow = window.open(
            `${CLIENT}/pages/bbps/print`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };


    return (
        <div>
            <Modal
                show={fetchBillModal}
                onHide={() => setFetchBillModal(false)}
                aria-labelledby="example-modal-sizes-title-md"
            >
                <Modal.Header closeButton>
                    <Modal.Title> Bill Details</Modal.Title>
                </Modal.Header>

                <Modal.Body
                    style={{ width: "100%", display: "block" }}
                    className="account m-0 p-0"
                >
                    {billDetails && !billPayResponse && (
                        <Card style={{ margin: "10px", padding: "10px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <img
                                    src={require("../../../assets/images/BBPSSidebarLogo.png")}
                                    style={{
                                        width: "auto",
                                        height: "1.2rem",
                                        backgroundColor: "whitesmoke",
                                    }}
                                />
                                <img
                                    onClick={() => {
                                        openInNewTab(billDetails);
                                    }}
                                    style={{ height: "1.5rem" }}
                                    src={require("../../../assets/icons/print_1.png")}
                                />
                            </div>
                            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                                <tr style={{ background: "none", border: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Name
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billDetails.billerResponse.customerName}
                                    </td>
                                </tr>
                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Bill Number
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billDetails.billerResponse.billNumber}
                                    </td>
                                </tr>
                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Amount
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {amount}
                                    </td>
                                </tr>

                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Bill date
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billDetails.billerResponse.billDate}
                                    </td>
                                </tr>

                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Due Date.
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billDetails.billerResponse.dueDate}
                                    </td>
                                </tr>
                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Reference Id
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billDetails.refId}
                                    </td>
                                </tr>



                            </table>
                        </Card>
                    )}
                    {billDetails && billPayResponse && (
                        <Card style={{ margin: "10px", padding: "10px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <img
                                    src={require("../../../assets/images/BBPSSidebarLogo.png")}
                                    style={{
                                        width: "auto",
                                        height: "1.2rem",
                                        backgroundColor: "whitesmoke",
                                    }}
                                />
                                <img
                                    onClick={() => {
                                        openInNewTab(billDetails);
                                    }}
                                    style={{ height: "1.5rem" }}
                                    src={require("../../../assets/icons/print_1.png")}
                                />
                            </div>
                            <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                                <tr style={{ background: "none", border: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Name
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billDetails.billerResponse.customerName}
                                    </td>
                                </tr>

                                {billDetails.billerResponse.billNumber && (
                                    <tr style={{ background: "none" }}>
                                        <td
                                            style={{
                                                paddingLeft: "1rem",
                                                border: "none",
                                                background: "none",
                                                width: "50%",
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            {" "}
                                            Bill Number
                                        </td>
                                        <td
                                            style={{
                                                paddingLeft: "1rem",
                                                border: "none",
                                                background: "none",
                                                width: "50%",
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            {" "}
                                            {billDetails.billerResponse.billNumber}
                                        </td>
                                    </tr>
                                )}

                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Amount
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {amount}
                                    </td>
                                </tr>

                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Reference Id
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billPayResponse.refId}
                                    </td>
                                </tr>
                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        Transaction Reference Id
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        {billPayResponse.txnReferenceId}
                                    </td>
                                </tr>

                                <tr style={{ background: "none" }}>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {" "}
                                        Status
                                    </td>
                                    <td
                                        style={{
                                            paddingLeft: "1rem",
                                            border: "none",
                                            background: "none",
                                            width: "50%",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        <span className="badge badge-pill badge-success p-3">
                                            {billPayResponse.responseReason}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </Card>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}


export default GetAllBillTable;