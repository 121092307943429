import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dropdown,
  Form,
  Card,
  InputGroup,
  Col,
  Row,
  Nav,
  Tabs,
  Tab,
  Pagination,
} from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";
// import { Pagination } from "../../distributor/Manage Retailer/DistributorReport";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import cogoToast from "cogo-toast";
import { ContextMenuTrigger } from "react-contextmenu";

const columns = [
  {
    dataField: "wtw_reference_id",
    text: "Reference",
  },
  {
    dataField: "SenderID",
    text: "Sender",
  },
  {
    dataField: "ReceiverID",
    text: "Receiver",
  },
  {
    dataField: "wtw_amount",
    text: "Amount",
  },
  {
    dataField: "wtw_charges",
    text: "Charge",
  },
  {
    dataField: "wtw_remark",
    text: "Remark",
  },
  {
    dataField: "wtw_indate",
    text: "Date & Time",
  },
];

const WalletToWalletTransfer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [Wallet, setWallet] = useState("");
  const [WalletToWallet, setWalletToWallet] = useState("");
  const [checknumber, setchecknumber] = useState("");
  const [Amount, setAmount] = useState("");
  const [Pin, setPin] = useState("");
  const { user, refreshWallet, setTransactionLoaderState } =
    useContext(GlobalContext);
  const [Comment, setComment] = useState("");
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const [formErrors, setFormErrors] = useState({
    mobile: "",
  });
  const [tpinShow, setTpinShow] = useState(false);

  const validate = () => {
    let errors = {};
    if (checknumber.length != 10) {
      errors.mobile = "please enter valid mobile number";
    }
    if (Object.keys(errors).length === 0) {
      wallet();
    } else {
      setFormErrors(errors);
    }
  };

  const wallet = () => {
    setTransactionLoaderState(true);
    var raw = JSON.stringify({
      mobile: checknumber,
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_wallet_to_wallet_chk_number.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setWallet(result);
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const wallettowallet = () => {
    if (Number(Amount) < 100) {
      swal(
        "Transaction Failed",
        `please enter amount greater than Rs 100`,
        "error"
      );
      return;
    }
    setTransactionLoaderState(true);
    var raw = JSON.stringify({
      UserID: Wallet.pk_user_id,
      FkSId: user.pk_user_id,
      amount: Amount,
      comment: Comment,
      Tpin: Pin,
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_wallet_to_wallet_submit.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setWalletToWallet(result.data);
            clearForm();
            swal("Success", result.msg, "success");
            WalletToWalletReport();
          } else {
            swal("Failed", result.msg, "error");
          }
          setTransactionLoaderState(false);
          refreshWallet();
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const clearForm = () => {
    setAmount("");
    setWallet("");
    setPin("");
    setComment("");
  };

  const [WalletRepo, setWalletRepo] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const WalletToWalletReport = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_wallet_to_wallet_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0") {
            setWalletRepo(result.data);
          } else {
            cogoToast.info("No Data Found");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) { }
  };

  useEffect(() => {
    WalletToWalletReport();
  }, []);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 10;
    const endIdx = currentPage * 10 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((WalletRepo.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(WalletRepo.length);
    const filteredData = WalletRepo.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [WalletRepo]);
  console.log(pages);

  useEffect(() => {
    for (let key in WalletRepo[0]) {
      console.table(key, WalletRepo[0][key]);
    }
  }, [WalletRepo]);

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div
            style={{ backgroundColor: COLOR_BACKGROUND_BODY }}
            className="card"
          >
            <div
              className="tab-custom-pills-horizontal"

            >
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <Row className="m-0 p-0 ">
                  <Col md={6} sm={4} lg={12}>


                    <Nav
                      variant="pills"
                      className="nav-pills-custom-5 justify-content-start p-2 py-1 "
                      style={{
                        border: "none",
                        background: "#EEF0F1",
                        color: "#4267B2",
                        borderRadius: "10px",
                      }}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          className="d-flex align-items-center"
                        >
                          Wallet to Wallet Transfer
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          className="d-flex align-items-center"
                        >
                          Wallet to Wallet Report
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <div className="col-12 col-sm-12 col-6">
                    <Tab.Content className="tab-content-custom-pill">
                      <Tab.Pane eventKey="first">
                        <div className="card">
                          <Col className="col-md-6 col">
                            <InputGroup>
                              <Form.Group>
                                <Form.Control
                                  className=""
                                  name="Mobile number"
                                  placeholder="Enter Mobile Number"
                                  onChange={(e) => {
                                    setchecknumber(e.target.value);
                                  }}
                                  style={{ borderRadius: "0px" }}
                                />
                                <p style={{ color: "red" }}>
                                  {formErrors.mobile}
                                </p>
                              </Form.Group>

                              <div className="ml-3">
                                <Button
                                  type="button"
                                  className="btn"
                                  // variant="success"
                                  onClick={validate}
                                  style={{ borderRadius: ".4rem", border: "1px solid #00A552 ", background: "#eaf9ee ", color: "#00A552 " }}
                                >
                                  Check Number
                                </Button>
                              </div>
                            </InputGroup>
                            {Wallet?.status == 0 ? (
                              <div className=" mb-3">
                                {/* <div className="d-flex justify-content-center">
 <img
 src={Wallet.data}
 style={{ height: "8rem", width: "auto",borderRadius:'16rem',margin:'0px auto',border:'2px solid #00FF00' }}
 />
 </div> */}

                                <Form.Control
                                  className="mt-2 "
                                  name="Mobile no."
                                  disabled={true}
                                  value={Wallet.us_shop_name}
                                  style={{ borderRadius: "0px" }}
                                />
                                <Form.Control
                                  className="mt-3"
                                  name="Mobile no."
                                  placeholder="Enter amount"
                                  onChange={(e) => {
                                    setAmount(e.target.value);
                                  }}
                                  style={{ borderRadius: "0px" }}
                                />
                                <div className="input-group mt-3">
                                  <div className="input-group-prepend bg-transparent">
                                    <span className="input-group-text bg-transparent border-right-0 no-glow">
                                      <i className="mdi mdi-lock-outline text-primary"></i>
                                    </span>
                                  </div>
                                  <input
                                    autocomplete="new-password"
                                    autoCorrect="off"
                                    autoSave="new-password"
                                    type={tpinShow ? "text" : "password"}
                                    className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                                    id="exampleInputPassword"
                                    placeholder="Enter T-Pin"
                                    style={{ borderRadius: "0px" }}
                                    name="pin"
                                    onChange={(e) => {
                                      setPin(e.target.value);
                                    }}
                                  />
                                  <div className="input-group-prepend bg-transparent">
                                    <span
                                      className="input-group-text bg-transparent border-left-0 no-glow"
                                      onClick={showTPinClick}
                                      style={{ borderRadius: "4px" }}
                                    >
                                      <i
                                        className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                                          }`}
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                                <textarea
                                  className="mt-3"
                                  placeholder="Enter Remarks"
                                  onChange={(e) => {
                                    setComment(e.target.value);
                                  }}
                                  style={{
                                    padding: "5px",
                                    width: "100%",
                                    borderRadius: "0px",
                                  }}
                                ></textarea>

                                <div className={`card-inverse-success my-2`}>
                                  <ContextMenuTrigger id="actionContextMenu">
                                    <div className="card-body p-2">
                                      <p className="card-text">{"Rs 10 rupees charges will be deducted"}</p>
                                    </div>
                                  </ContextMenuTrigger>
                                </div>

                                <Button
                                  type="button"
                                  className="btn btn-md"
                                  variant="success"
                                  onClick={wallettowallet}
                                  style={{ borderRadius: ".4rem", border: "1px solid #00A552 ", background: "#eaf9ee ", color: "#00A552 " }}
                                >
                                  Submit
                                </Button>
                              </div>
                            ) : (
                              <p style={{ color: "red" }}>{Wallet?.msg}</p>
                            )}
                          </Col></div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Col md={12} className="card">
                          <div className="row align-items-center">
                            <Form.Group className="col-12 col-sm-6 col-md-3">
                              <Form.Label>From Date</Form.Label>
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control"
                                placeholderText="Start Date"
                                style={{ borderRadius: "0px" }}
                              />
                            </Form.Group>
                            <Form.Group className="col-12 col-sm-6 col-md-3">
                              <Form.Label>To Date</Form.Label>
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                className="form-control"
                                placeholderText="End Date"
                                style={{ borderRadius: "0px" }}
                              />
                            </Form.Group>
                            <Form.Group className="mt-4">
                              <Button
                                // className="rounded-0"
                                // variant="success"
                                onClick={WalletToWalletReport}
                                style={{ borderRadius: ".4rem", border: "1px solid #00A552 ", background: "#eaf9ee ", color: "#00A552 " }}

                              >
                                {"Search"}
                              </Button>
                            </Form.Group>

                            <div
                              className="col-12 col-sm-4 col mt-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                console.log(WalletRepo);
                                ExportToExcel(
                                  `Wallet_Transfer_Report_${now()}`,
                                  excelMapping(WalletRepo, columns)
                                );
                              }}
                            >
                              <img
                                src={require("../../../assets/images/excel.png")}
                                style={{ width: "1.6rem" }}
                              />
                              <strong>Export to Excel</strong>
                            </div>
                          </div>

                          <div className="dashboard-table-style table-responsive">
                            <table
                              style={{ width: "100%" }}
                              className="table border"
                            >
                              <tr>
                                {columns.map((heading) => (
                                  <th
                                    className="text-center"
                                    style={{ fontSize: "0.9rem" }}
                                  >
                                    {heading.text}
                                  </th>
                                ))}
                              </tr>
                              {dataToDisplay.length > 0 ? (
                                dataToDisplay.map((e, ind) => (
                                  <tr>
                                    <td style={{ fontSize: 12 }}>
                                      {e.wtw_reference_id}
                                    </td>
                                    <td style={{ fontSize: 12 }}>
                                      {e.SenderID}
                                    </td>
                                    <td style={{ fontSize: 12 }}>
                                      {e.ReceiverID}
                                    </td>
                                    <td style={{ fontSize: 12 }}>
                                      {e.wtw_amount}
                                    </td>
                                    <td style={{ fontSize: 12 }}>
                                      {e.wtw_charges}
                                    </td>
                                    <td style={{ fontSize: 12 }}>
                                      {e.wtw_remark}
                                    </td>
                                    <td style={{ fontSize: 12 }}>
                                      {e.wtw_indate}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colspan="13"
                                    className="text-align-center"
                                  >
                                    No Data Available
                                  </td>
                                </tr>
                              )}
                            </table>
                            <Pagination
                              page={currentPage}
                              limit={pages}
                              callback={(page) => {
                                setCurrentPage(page);
                              }}
                              count={dataToDisplay.length}
                              total={total}
                            />
                          </div>
                        </Col>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Row>
              </Tab.Container>
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};
export default WalletToWalletTransfer;