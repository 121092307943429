import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button } from "react-bootstrap";
import YESBankLogo from "../../../assets/images/yes_bank_Logo.png";
import bumppyLogo from "../../../assets/images/Bumppy_Payment_logo.svg";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { useQuery } from "../../custom-hooks/useQuery";


const MarcomDocAdminPrint = () => {
    const query = useQuery();
    const userid = query.get("userid");
    const pk_user_id = query.get("pk_user_id");
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [Profileinfo, setProfileinfo] = useState({});
    const [kycData, setKycData] = useState({});
    const [kycData2, setKycData2] = useState({});
    const [sessionId, setSessionId] = useState("");
    const [sessionId2, setSessionId2] = useState("");

    const printReceipt = () => {
        window.print();
    };

    useEffect(() => {
        if (kycData && kycData2 && Profileinfo && Object.keys(Profileinfo).length > 0 && Object.keys(kycData).length > 0 && Object.keys(kycData2).length > 0 && kycData2.extra!=null && kycData2.extra!="" && kycData2.extra!=undefined){

            printReceipt();
        }
           
    }, [kycData, kycData2, Profileinfo]);

    const GetProfile = () => {
        var raw = JSON.stringify({
            PdrID: pk_user_id,
        });

        var requestOptions = {
            method: "POST",
            body: raw,
            redirect: "follow",
        };

        try {
            fetch(
                `${BASE_URL_API_TRANXT_API}/api/master/master_dist_profile_details.aspx`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    setProfileinfo(result.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUserKycData = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/javascript");

            var raw = JSON.stringify({
                userid: pk_user_id,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == 0 && data.data.length > 0) {
                if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
                    // history.push("/kyc/user-kyc-view");
                }
                setKycData(data.data[0]);
                // setFormValues({
                //   ...formValues,
                //   PAN: data.data[0].pancardno,
                //   ADHAR: data.data[0].aadharcardno,
                //   GST: data.data[0].gstnumber,
                // });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const getReviewKycDetails = async (step, verification) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "userid": userid,
                "adminid": '1',
                "fromDate": "",
                "toDate": ""
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/getAllKycReviewReportAdmin`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                setKycData2(data.data[0])
                setTransactionLoaderState(false);
            } else {
                //  swal("Error", "Something went wrong", "error");
                setTransactionLoaderState(false);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const getsessionId = () => {
        const date = new Date(Profileinfo.doj);
        date.setDate(date.getDate() + 7);
        let session = `${date.getFullYear()}${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;

        setSessionId("SESSION_ID" + session);
        setSessionId2("" + session);

    }

    useEffect(() => {
        getReviewKycDetails();
        fetchUserKycData();
        GetProfile();
    }, []);

    useEffect(() => {
        if (Profileinfo) {
            getsessionId()
        }
    }, [Profileinfo]);

    // date format in dd/mmm/yyyy format
    const formatDate = (date) => {
        const d = new Date(date);
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day = d.getDate();
        const monthIndex = d.getMonth();
        const year = d.getFullYear();
        return `${day} ${monthNames[monthIndex]} ${year}`;
    };

    const openInNewTab = () => {
        localStorage.setItem("print", JSON.stringify({ ...Profileinfo }));
        const newWindow = window.open(
            `/MarcamForm/MarcamFormPrint`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };
    if (
        Profileinfo == null

    ) {
        return null;
    } else {
        return (
            <div>
                <div className="row w-100 justify-content-center p-0 m-0"      >
                    <div
                        className="card border-0 d-flex justify-content-center m-0"
                    >
                        <div className="row ml-3 mr-3 ">
                            <div className="col ">
                                <img style={{ height: "6vh", width: "auto" }} src={bumppyLogo} />
                            </div>
                            <div className="col d-flex justify-content-end">
                                <img style={{ height: "5vh", width: "auto" }} src={YESBankLogo} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center m-2 my-4">
                            {" "}
                            <h4>
                                <b>
                                    <u>Certificate of Business Correspondent Agent</u>
                                </b>
                            </h4>
                        </div>

                        <div
                            style={{
                                textAlign: "justify",
                                display: "flex",
                                flexWrap: "wrap",
                                marginLeft: "3rem",
                                marginRight: "3rem",
                            }}
                        >
                            <div style={{ flexBasis: "calc(100% - 1em)" }}>

                                This is to certify that Mr. / Ms. {Profileinfo?.name} having his / her place of operation at {kycData?.shopaddress} has been appointed as Business Correspondent Agent of YES BANK LTD.through BUMPPY MEDIA PVT LTD who is a Business Correspondent of the Bank. The BCA has been duly trained during Training Session {sessionId} held on {formatDate(Profileinfo?.doj)} and has been authorized to act as BCA of the Bank with effect from {formatDate(Profileinfo?.doj)}  until further notice."

                            </div>
                        </div>

                        <div className="mt-2">
                            <div className="d-flex justify-content-center m-2 my-4">
                                <div>
                                    <b style={{fontSize:'1.4rem'}}>
                                        Escalation Matrix for Business Correspondent Agent of YES BANK
                                        Ltd.
                                    </b>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex justify-content-center m-2">
                                    <table
                                        className="mx-5"
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        backgroundColor: "#86B6F6",
                                                        color: "black",
                                                        textAlign: "center",
                                                        border: "1px solid black",
                                                    }}
                                                >
                                                    Contact Points
                                                </th>
                                                <th
                                                    style={{
                                                        backgroundColor: "#86B6F6",
                                                        color: "black",
                                                        textAlign: "center",
                                                        border: "1px solid black",
                                                    }}
                                                >
                                                    Timing
                                                </th>
                                                <th
                                                    style={{
                                                        backgroundColor: "#86B6F6",
                                                        color: "black",
                                                        textAlign: "center",
                                                        border: "1px solid black",
                                                    }}
                                                >
                                                    Contact No
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{ textAlign: "center", border: "1px solid black" }}
                                                >
                                                    <div>Level I</div>
                                                    <div>BUMMPY MEDIA PVT LTD</div>
                                                    <div>support@bummpy.com</div>
                                                </td>
                                                <td
                                                    style={{ textAlign: "center", border: "1px solid black" }}
                                                >
                                                    24x7
                                                </td>
                                                <td style={{ textAlign: "center", border: "1px solid black" }}>{"8130096175"}</td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{ textAlign: "center", border: "1px solid black" }}
                                                >
                                                    <div>Level II</div>
                                                    <div>{"YES BANK Ltd."}</div>
                                                    <div>{"Customer Care"}</div>
                                                </td>
                                                <td
                                                    style={{ textAlign: "center", border: "1px solid black" }}
                                                >
                                                    {"10 am – 8 pm"}
                                                </td>
                                                <td
                                                    style={{ textAlign: "center", border: "1px solid black" }}
                                                >
                                                    {"1800-103-5485"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="my-4"
                            style={{
                                textAlign: "justify",
                                display: "flex",
                                flexWrap: "wrap",
                                marginLeft: "3rem",
                                marginRight: "3rem",
                            }}
                        >
                            Note: In case of further escalations please write to the Grievance
                            Redressal Officer at &nbsp; {" "}
                            <span
                                style={{
                                    color: "blue",
                                }}
                            >
                                head.grievanceredressal@yesbank.in
                            </span>{" "}
                            or call on 022-50795173. Details of the service offerings is available
                            with the Business Correspondent Agent and customers are advised to
                            refer the same before doing any banking transactions.
                        </div>
                        <div className="d-flex justify-content-center m-2 my-2">
                            <div>
                                <b  style={{fontSize:'1.4rem'}} >Service Charges</b>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center m-2 my-4">
                            <table className="mx-5" >
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                backgroundColor: "#86B6F6",
                                                color: "black",
                                                border: "1px solid black",
                                                textAlign: "center",
                                            }}
                                            colSpan="2"
                                        >
                                            DMT
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid black", textAlign: "center" }}>
                                            Transaction Amount
                                        </td>
                                        <td style={{ border: "1px solid black", textAlign: "center" }}>
                                            Charges (Rs.)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", textAlign: "center" }}>
                                            100 - 5000
                                        </td>
                                        <td style={{ border: "1px solid black", textAlign: "center" }}>
                                            1.2%
                                        </td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr>
                                        <th
                                            colSpan="2"
                                            style={{
                                                backgroundColor: "white",
                                                color: "black",
                                                border: "1px solid black",
                                                textAlign: "center",
                                            }}
                                            className="justify-content-center"
                                        >
                                            Minimum Transaction Fee is INR 12
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th
                                            colSpan="2"
                                            style={{
                                                backgroundColor: "#86B6F6",
                                                color: "black",
                                                border: "1px solid black",
                                                textAlign: "center",
                                            }}
                                        >
                                            AEPS & Micro ATM
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th
                                            colSpan="2"
                                            style={{
                                                backgroundColor: "white",
                                                color: "black",
                                                border: "1px solid black",
                                                textAlign: "center",
                                            }}
                                        >
                                            There are No Charges for AEPS and Micro ATM service.
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                        <div className="my-4"
                            style={{
                                textAlign: "justify",
                                display: "flex",
                                flexWrap: "wrap",
                                marginLeft: "3rem",
                                marginRight: "3rem",
                                marginTop: "1rem",
                            }}
                        >
                            <div><b>The customer is advised to kindly note the following: -</b></div>
                            <ol>
                                <li>
                                    BUMPPY MEDIA PVT LTD is only a Business Correspondent of YES BANK
                                    Limited. This outlet is a BC Agent of YES BANK Limited through BUMPPY MEDIA PVT LTD.
                                </li>
                                <li>
                                    The fees/charges mentioned above are inclusive of all taxes
                                    including GST.
                                </li>
                                <li>
                                    If the customer is charged in excess of the Fees/charges mentioned
                                    above, he/she should complain about the same on YES BANK Customer
                                    Care Number 1800-103-5485.
                                </li>
                                <li>
                                    The above-mentioned service charges are approved by YES BANK Ltd.
                                    and will remain in force until further notice.{" "}
                                </li>
                            </ol>
                        </div>

                        <div className="row d-flex justify-content-center " >
                            <div className="col">
                                <div>
                                    <div>
                                     <b>   BCA Training Session: {sessionId} </b>
                                    </div>
                                    <div>
                                      <b>  Poster Issue No: {userid.toString().slice(2, userid.length)} </b>
                                    </div>


                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <div>
                                      <b>  BCA Code: :{kycData2.extra}</b>
                                    </div>
                                    <div>
                                      <b>  BCA Certificate No:{userid.toString().slice(2, userid.length) + "" + sessionId2} </b>
                                    </div>


                                </div>
                            </div>
                        </div>

                        {/* <div className="row d-flex justify-content-center hide-on-print">
                            {" "}
                            <Button className="hide-on-print"
                                onClick={() => {
                                    printReceipt();
                                }}
                            >
                                {" "}
                                <i class="mdi mdi-printer"></i> Download Form
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }

};

export default MarcomDocAdminPrint;