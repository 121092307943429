

import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import swal from "sweetalert";
import Select from "react-select";
import DatePicker from "react-datepicker";

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

const ttype = [
  { label: "Add Funds", value: "1" },
  { label: "Reverse Funds", value: "2" },
];

const UserMapping = () => {
  const [state, setState] = useState({
    userCode: "",
    utrNo: "",
    amount: "",
    bank: null,
    date: "",
    paymode: "",
    ttype: "",
    remaks: "",
  });
  const [state2, setState2] = useState({
    userCode: "",
    utrNo: "",
    amount: "",
    bank: null,
    date: "",
    paymode: "",
    ttype: "",
    remaks: "",
  });
  const [userInfo, setUserInfo] = useState(null);
  const [userInfo2, setUserInfo2] = useState(null);
  const [banks, setBanks] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const searchUser = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: state.userCode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_user_details_create_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserInfo(data.data);
      } else {
        swal(
          "User Not Found",
          "Please Enter Valid ID Or Mobile number",
          "error"
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const searchUser2 = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: state2.userCode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_user_details_create_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserInfo2(data.data);
      } else {
        swal(
          "User Not Found",
          "Please Enter Valid ID Or Mobile number",
          "error"
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const userMappingSubmit = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: state.userCode,
        parentid: state2.userCode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/sales/user_map.aspx`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.data.desc, "success");
        clearData();
      } else {
        swal("Failed", "User Mapping Failed", "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("User Registration Failed", "Error From Server", "error");
    }
  };

  const clearData = () => {
    setState({
        userCode: "",
        utrNo: "",
        amount: "",
        bank: null,
        date: "",
        paymode: "",
        ttype: "",
        remaks: "",
    });
    setState({
        userCode: "",
        utrNo: "",
        amount: "",
        bank: null,
        date: "",
        paymode: "",
        ttype: "",
        remaks: "",
    });
    setUserInfo(null);
    setUserInfo2(null);
  };

  const validateForm = () => {
    console.log(state);
    if (!userInfo || !userInfo2) {
      swal("Invalid", "Please Enter Valid Information", "error");
      return;
    }
    userMappingSubmit();
  };

  useEffect(() => {
    // getBankDetails();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) => (
         <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>
           <div className="card mb-3 m-0 p-0">
           <div className="card-body m-0 p-0 pt-1 pb-1 px-4 ">
           <div className="row m-0 p-0  pb-3">
           <div className="col-2 m-0 p-0 mt-2">
           <div style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"32px",
                  }}
                >
                  User Mapping
                </div>
                </div>
                </div>

                <div className="row p-0 r-0">
                  <div className="col-5 p-0 m-0 pl-3 pr-2 ">
                    <Form.Group>
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <span style={{ color: "black" }}>Enter User Code Or Mobile Number</span>
                    
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User Code Or Mobile Number"
                        value={state.userCode}
                        onChange={(e) => {
                          setState({ ...state, userCode: e.target.value });
                        }}
                        style={{ borderRadius: "7px" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-2 p-0 m-0 px-2">
                    <Form.Group className="mt-4">
                      <Button
                        onClick={searchUser}
                        className="rounded-50 btn btn-primary btn-lg btn-block"
                      >
                        {" "}
                        Search
                      </Button>
                    </Form.Group>
                    </div>
                    <div className="col-2 p-0 m-0 px-2">
                    <Form.Group className="mt-4">
                      <Button
                        onClick={clearData}
                        className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
                      >
                        {" "}
                        Clear
                      </Button>
                    </Form.Group>
                  </div>
                </div>

                {userInfo && (
                  <div
                    style={{
                      padding: "12px",
                      background: "#3676D8",
                      color: "#FFF",
                      fontWeight: "bolder",
                      borderRadius:"7px",
                      "fontFamily":"Poppins",
                    }}
                  >
                    <div className="row d-flex">
                      <div className="col-5 ">
                        <strong>
                          {" "}
                          {`User : ${userInfo.us_name} [${userInfo.us_code}]`}
                        </strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User Mobile : ${userInfo.us_loginid}`}
                        </strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong> {`User Type : ${userInfo.usrtype}`}</strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User Balance : ${twoDecimalPlaces(
                            userInfo.usrbal
                          )}`}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}

                {userInfo && (
                  <div className="row m-0 p-0 py-4">
                    <div className="col-5 p-0 m-0  pr-2 ">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          <span style={{ color: "black" }}>Enter Parent Code Or Mobile Number</span>
                      
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Parent Code Or Mobile Number"
                          value={state2.userCode}
                          onChange={(e) => {
                            setState2({ ...state, userCode: e.target.value });
                          }}
                          style={{ borderRadius: "7px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-2 p-0 m-0 px-2">
                      <Form.Group className="mt-4">
                        <Button
                          onClick={searchUser2}
                          className="rounded-50 btn btn-primary btn-lg btn-block"
                        >
                          {" "}
                          Search
                        </Button>
                      </Form.Group>
                      </div>
                      <div className="col-2 p-0 m-0 px-2">
                      <Form.Group className="mt-4 ">
                        <Button
                          onClick={clearData}
                          className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
                        >
                          {" "}
                          Clear
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                )}
                {userInfo2 && (
                  <div
                    style={{
                      padding: "12px",
                      //background: "#3676D8",
                      color: "rgb(0,0,0)",
                     
                      border:"1px solid",
                      borderRadius:"7px",
                      "fontFamily":"Poppins",
                    }}
                  >
                    <div className="row d-flex">
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User : ${userInfo2.us_name} [${userInfo2.us_code}]`}
                        </strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User Mobile : ${userInfo2.us_loginid}`}
                        </strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong> {`User Type : ${userInfo2.usrtype}`}</strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User Balance : ${twoDecimalPlaces(
                            userInfo2.usrbal
                          )}`}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}
                {userInfo && userInfo2 && (
                  <div className="row py-2">
                    <div className="col-2 m-0 p-0  ">
                      <Form.Group className="mt-4 pl-2">
                        <Button
                          onClick={userMappingSubmit}
                          className="rounded-50 btn btn-primary btn-lg btn-block"
                        >
                          {" "}
                          Map User
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
            </div>
           </div>
        
      )}
    </GlobalContext.Consumer>
  );
};

export default UserMapping;

