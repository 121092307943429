import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";

const Epan = () => {
  const [token, setToken] = useState("");
  const { user } = useContext(GlobalContext);

  const getAccessToken = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        retailerID: user.ReferId,
        firmName: "BUMPPY",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Services/EPAN/authenticate",
        requestOptions
      );
      const data = await res.json();
      if (data.statusCode == 1) {
        setToken(data.data.token);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);
  console.log(token);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-5">
        <div
          style={{
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-7 p-md-5 p-sm-4">
              {" "}
              <h4 className="d-flex align-items-center">E-PAN Service</h4>
              <p
                className="d-flex align-items-center"
                style={{ color: "black", fontSize: "1rem" }}
              >
                E-PAN offers the convenience of easy and secure access to your
                PAN details online, making it simpler to carry out financial
                transactions and tax-related activities.
              </p>
              <h6>Apply now for E-PAN</h6>
              <form
                action="https://digipaydashboard.religaredigital.in/Login/Authenticate"
                method="Post"
              >
                <input type="hidden" name="authentication" value={token} />{" "}
                <button
                  className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                  type="submit"
                >
                  {" "}
                  Apply for E PAN
                </button>
              </form>
            </div>
            <div className="col-5 d-flex justify-content-center align-items-center">
              <img
                alt=""
                style={{ height: "15rem" }}
                src={require("../../../assets/images/epan.png")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CMS2 = () => {
  const [token, setToken] = useState("");
  const { user } = useContext(GlobalContext);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "", "height=600,width=800'");
    if (newWindow) newWindow.opener = null;
  };

  const getAccessToken = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " +
          Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString(
            "base64"
          )
      );
      var raw = JSON.stringify({
        userid: user.ReferId,
        device: "WEB",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/CMS/Bankit/Main/generateUrl",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        openInNewTab(data.data.cmsUrl);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // getAccessToken();
  }, []);
  console.log(token);
  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-4">
        <div
          style={{
            width: "100%",
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-7 p-md-5 p-sm-4">
              {" "}
              <h4 className="d-flex align-items-center">CMS Service</h4>
              <p
                className="d-flex align-items-center"
                style={{ color: "black", fontSize: "1rem" }}
              >
                Cash Management Services (CMS) Airtel Bank's Cash Management
                Services offers a full range of products and services to
                efficiently process your receivables and payables. We cater to
                all your cash management requirements to optimise your cash flow
                position and to facilitate effective management of your business
                operation.
              </p>
              <button
                className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                // type="submit"
                style={{
                  borderRadius: ".2rem",
                  fontWeight: "bold",
                  width: "10rem",
                  border: "none",
                  color: "#ffff",
                  fontSize: "1rem",
                  height: "2em",
                  display: "flex",
                }}
                onClick={() => {
                  getAccessToken();
                }}
              >
                {" "}
                Open CMS
              </button>
            </div>
            <div className="col-5 d-flex justify-content-center align-items-center">
              <img
                alt=""
                style={{ height: "20rem" }}
                src={require("../../../assets/images/epan.png")}
              />
            </div>
          </div>
          <div className="card m-md-4 m-sm-3 p-md-3 p-sm-2">
            <h5>Information</h5>
            <p>
              Cash Management Services (CMS) Airtel Bank's Cash Management
              Services offers a full range of products and services to
              efficiently process your receivables and payables. We cater to all
              your cash management requirements to optimise your cash flow
              position and to facilitate effective management of your business
              operation. Benefits of Cash Management Services Leverage of vast
              network of locations within India Wide range of CMS products
              Efficient logistics support and advanced technology support
              Ensuring timely deposit of collections and timely execution of
              disbursement requests
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CMS2;
