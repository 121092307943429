import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import LoadBankListTable from "./LoadBankListTable";
const LoadBankList = () => {
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "1rem" }} className="bg-light">
          <div style={{ borderRadius: "8x" }} className="card m-0 p-0 mt-4 mx-2m">
            <div className="card-body m-0 p-2">
              <div>
               

                <LoadBankListTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default LoadBankList;
