import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Redirect, useHistory } from "react-router";
import { BASE_URL_AGENT, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import { useQuery } from "../../custom-hooks/useQuery";
import { excelMapping, ExportToExcel, now } from "../../utils/utilFuctions";
import { offsetArr } from "../../utils/CommonConfig";

const columns = [
  // {
  //   dataField: "S_No",
  //   text: "S No.",
  //   sort: false,
  // },
  {
    dataField: "us_shop_name",
    text: "Shop Name",
    sort: false,
    color: "#000000",
  },
  {
    dataField: "us_code",
    text: "Retailer Code",
    sort: false,
    color: "#000000",
  },
  {
    dataField: "ledger_name",
    text: "Details",
    sort: false,
    color: "#000000",
  },
  {
    dataField: "pay_before_balance",
    text: "Opening Balance",
    sort: false,
    color: "#4B56D2",
  },
  {
    dataField: "pay_balance",
    text: "Amount",
    sort: false,
    color: "#000000",
  },
  {
    dataField: "pay_after_balance",
    text: "Closing Balance",
    sort: false,
    color: "#4B56D2",
  },

  {
    dataField: "pay_type",
    text: "Status",
    sort: false,
    color: "#000000",
  },
  {
    dataField: "pay_indate",
    text: "Date",
    sort: false,
    color: "#000000",
  },
];

export const RetailerLedgerReport = () => {
  const [Provider, setProvider] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { user } = useContext(GlobalContext);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const [offset, setOffset] = useState(10);

  // Search Params
  const query = useQuery();
  const RetailerID = query.get("id");
  console.log(RetailerID);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const history = useHistory();

  const validate = (values) => {
    const errors = {};
    if (!values.UserName) {
      errors.UserName = "*User name is required!";
    }
    return errors;
  };

  const { token, myAccountInfo } = useContext(GlobalContext);

  const getRetailerLedgerReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: RetailerID,
        limit: offset,
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_dist_retailer_ledger_report.aspx`,
        requestOptions
      );
      const data = await res.json();
      setDataToDisplay(data.data);
    } catch (error) { }
  };

  useEffect(() => {
    let filteredData = Provider.filter((bank) => {
      let b = false;
      if (Provider.length > 0) {
        for (let key in Provider[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = Provider.filter((bank) => {
      let b = false;
      if (Provider.length > 0) {
        for (let key in Provider[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 10;
    const endIdx = currentPage * 10 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount

  useEffect(() => {
    setPages(Math.floor((Provider.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(Provider.length);
    const filteredData = Provider.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [Provider]);

  useEffect(() => {
    getRetailerLedgerReport();
  }, [offset]);

  useEffect(() => {
    getRetailerLedgerReport();
  }, []);
  const positionStyle = {
    position: 'absolute',
    top: -40,
    left: 0,
    "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
    borderRadius: "5px",
  };
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <React.Fragment>
            {/* <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <DashboardSidebar details={sidebarState} />
            </div> */}

            <div style={{ backgroundColor: COLOR_BACKGROUND_BODY }}>
              <div className="pt-4 ">
                <div className="col-12 grid-margin">
                  <div className="card m-0 p-0">
                    <div className="row m-0 p-0  pt-2 px-1 align-items-center"  >
                      <div className="col-4 m-0 p-0">

                        <div className="m-0 p-0 pl-3"
                          style={{
                            "fontFamily": "Poppins", "fontSize": "24px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                            , padding: "0px",
                            background: "#FFFFFF",
                            color: "#4267B2",
                            fontWeight: "bolder",
                            fontfamily: "Poppins !impotent",
                            fontSize: "24px",
                          }}
                        >
                          {RetailerID} Retailer Ledger
                        </div>
                      </div>

                      <div className="col-8 m-0 p-0 ">
                        <div className="row mt-2 pr-2 m-0 p-0  justify-content-end">

                          <Form.Group style={{ marginLeft: " " }}>
                            {/* <Form.Label style={{ marginLeft: "5px" }}>
                          {" "}
                          Search in Table
                        </Form.Label> */}
                            <Form.Control
                              type="text"
                              className="form-control rounded-100"
                              aria-label="Notes"
                              aria-describedby="basic-addon1"
                              placeholder="Search in Table"
                              onChange={(e) => {
                                setSearchText(e.target.value);
                              }}
                              value={searchText}
                            />
                          </Form.Group>





                          <div className="pr-1 pl-2 p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group>
                              {/* <Form.Label> From Date </Form.Label> */}
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control  rounded-100"
                                placeholderText="Start Date"

                              />
                            </Form.Group>
                          </div>
                          <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group>
                              {/* <Form.Label> To Date </Form.Label> */}
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control  rounded-100"
                                placeholderText="Start Date"

                              />
                            </Form.Group>
                          </div>


                          <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
                            <Form.Group>
                              <Button
                                className="rounded-100 mt-4 btn btn-lg btn-block"

                                onClick={getRetailerLedgerReport}
                              >
                                {"Search"}
                              </Button>
                            </Form.Group>
                          </div>
                          <div className="m-0 p-0 hover-container"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ExportToExcel(`Profit_Report_${now()}`, dataToDisplay);
                            }}
                          >
                            <img className="pl-1"
                              src={require("../../../assets/images/download Icon.png")}
                              style={{ width: `3.5rem`, height: '85%' }}
                            />
                            <div style={positionStyle} className="hover-text bg-dark ">Export to Excel</div>
                          </div>
                        </div>



                      </div>

                    </div>



                    <div className="card-body p-0 m-0 ">

                      <div className="table-responsive m-0 p-0">
                        <table
                          style={{ width: "100%", overflowY: 'hidden', overflowX: 'hidden' }}
                          className="dashboard-table-style"
                        >
                          <tr>
                            <th
                              className="my-table transparent-table text-center border-bottom py-3" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem",  "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                            >
                              {" "}
                              {"Sr"}
                            </th>
                            {columns.map((heading) => (
                              <th
                                className="my-table transparent-table text-center border-bottom py-3" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem",  "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                              >
                                {heading.text}
                              </th>
                            ))}
                          </tr>
                          {dataToDisplay.map((e, ind) => (
                            <tr>
                              <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
                              {columns.map((cell) =>
                                cell.text == "User Name" ? (
                                  <td
                                    className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "0.8rem",  "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                                  >
                                    <strong>{e.userCode}</strong>
                                    <span>{e.UserName}</span>
                                  </td>
                                ) : cell.text == "Status" ? (
                                  e[cell.dataField] == "Debit" ? (
                                    <td
                                      className="my-table transparent-table text-center border-bottom mt-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem",  "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                                    >
                                      <label
                                        style={{

                                          width: "100%",
                                          borderRadius: "5px",
                                          border: "1px solid",
                                          backgroundColor: 'rgba(255, 46, 46, 0.1)',
                                          color: 'rgba(255, 46, 46, 1)',
                                          bordercolor: 'rgba(255, 46, 46, 1)',

                                        }} className="py-1  mb-0 pb-0"
                                      >
                                        {e[cell.dataField]}
                                      </label>{" "}
                                      <span> {e.UpdateDate}</span>
                                    </td>
                                  ) : e[cell.dataField] == "Credit" ? (
                                    <td
                                      style={{
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      {" "}
                                      <label
                                        style={{
                                          width: "100%",
                                          borderRadius: "5px",
                                          border: "1px solid",
                                          backgroundColor: 'rgba(43, 193, 85, 0.1)',
                                          color: 'rgba(43, 193, 85, 1)',
                                          bordercolor: 'rgba(43, 193, 85, 1)',

                                        }} className="py-1  mb-0 pb-0"
                                      >
                                        {e[cell.dataField]}
                                      </label>{" "}
                                      <span> {e.UpdateDate}</span>
                                    </td>
                                  ) : (
                                    <td
                                      className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "0.8rem",  "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                                    >
                                      <label
                                        style={{

                                          width: "100%",
                                          borderRadius: "5px",
                                          border: "1px solid",
                                          backgroundColor: 'rgba(232, 129, 36, 0.1)',
                                          color: 'rgba(232, 129, 36, 0.5)',
                                          bordercolor: '  rgba(232, 129, 36, 0.5)',

                                        }} className="py-1  mb-0 pb-0"
                                      >
                                        {e[cell.dataField]}
                                      </label>
                                      <span> {e.UpdateDate}</span>
                                    </td>
                                  )
                                ) : (
                                  <td
                                    className="transparent-table text-center border-bottom py-3" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem",  "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                                  >
                                    {e[cell.dataField]}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </table>
                        <Pagination
                          page={currentPage}
                          limit={pages}
                          callback={(page) => {
                            setCurrentPage(page);
                          }}
                          count={dataToDisplay.length}
                          total={total}
                          callback2={(offsetValue) => setOffset(offsetValue)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    </GlobalContext.Consumer>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex">
        <select
          className="form-control"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default RetailerLedgerReport;
