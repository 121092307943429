import React, { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import {
    Form,
    Dropdown,
    Button,
    Row,
    Col,
    Modal,
    Tab,
    Nav,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ContextMenuTrigger } from "react-contextmenu";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import { Redirect } from "react-router";
import {
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT,
    BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import Select from "react-select";
import QRCode from "qrcode";
import {
    CERTIFICATE_ADDRESS,
    CERTIFICATE_COMPANY_NAME,
    COMPANY_NAME,
    QR_TITLE_COMPANY_NAME,
} from "../../utils/StringConstants";
import {
    COLOR_QR_PLACEHOLDER,
    COLOR_QR_PLACEHOLDER_SECONDARY,
} from "../../utils/Themer";
import {
    generateHashKey2,
    inWords,
    twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import swal from "sweetalert";
import { useQuery } from "../../custom-hooks/useQuery";

const paymentModes = [
    { label: "Cash Deposite", value: "3" },
    { label: "UPI/IMPS", value: "2" },
    { label: "NEFT/RTGS", value: "5" },
    { label: "CDM", value: "9" },
    { label: "Fund Transfer", value: "8" },
    { label: "CHEQUE", value: "null" },
];
const months = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];
const dateFormatToDisplay = (date) => {
    if (date) {
        const d = new Date(date);

        let hours = d.getHours();
        let minutes = d.getMinutes();
        let ap = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes.toString().padStart(2, "0");
        let mergeTime = hours + ":" + minutes + " " + ap;
        return `${d.getDate()} ${months[d.getMonth()]
            } ${d.getFullYear()} ${mergeTime}`;
    } else {
        return "";
    }
};

const generateURL = ({ amount, name, upi }) => {
    return `upi://pay?pa=${upi}&pn=${name}&cu=INR`;
};

const loadCanvas = async (canvasEl, dataURL, canvasContextRef) => {
    const canvasContext = canvasEl.current.getContext("2d");
    const imageObj = new Image();

    canvasContextRef.current = canvasContext;
    imageObj.onload = function () {
        canvasContext.drawImage(this, 0, 0);
    };
    imageObj.src = dataURL;
};

const getQRImage = async ({ amount, name, upi }) => {
    try {
        const url = generateURL({ amount, name, upi });
        console.log(url);
        const qrCode = await QRCode.toDataURL(url, {
            type: "image/png",
            margin: 1,
            width: 300,
        });
        return qrCode;
    } catch (e) {
        console.log("API exception ", e);
        return "";
    }
};

const paintQR = async (canvasEl, canvasContextRef, { amount, name, upi }) => {
    const dataURL = await getQRImage({ amount, name, upi });
    await loadCanvas(canvasEl, dataURL, canvasContextRef);
};

const Topup = () => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [qrEnabled, setQrEnabled] = useState(false);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const [userId, setUserId] = useState("");
    const canvasContextRef = useRef(null);
    const [upiId, setUpiId] = useState("");
    const [name, setName] = useState("");
    const validate = () => {
        if (
            amount === "" ||
            transactionId === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const print = () => {
        const newWindow = window.open(`/pages/qr`, "", "height=800,width=600");
        if (newWindow) newWindow.opener = null;
    };

    const virtualAccountCreate = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("method", "virtualaccount_sidh");
            urlencoded.append("userid", user.ReferId);
            urlencoded.append("mobileno", user.Mobile);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiservices.aspx`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            if (data.sCode == 0) {
                localStorage.setItem("va-created", true);
                setAccountCreated(true);
                setEnableSmartCollectButtonState(true);
                getVirtualAccount();
            }
            window.location.reload();
        } catch (error) {
            console.log("error", error);
        }
    };
    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${transactionId}.jpeg&TransactionId=${transactionId}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const getVirtualAccount = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=get_virtualaccountBumppy&userid=${user.ReferId}`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            paintQR(canvasEl, canvasContextRef, {
                amount: 1,
                name: COMPANY_NAME,
                upi: data.va_qrurl,
            });
            if (data.status == 0 || data.status === "0") {
                setVirtualAccount(data);
            } else {
                setEnableSmartCollectButtonState(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {

                var raw = JSON.stringify({
                    "creditcard": "string",
                    "userid": "string",
                    "aadhaar": "string",
                    "p1": "string",
                    "imagename": "Suhastest",
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `https://apiservices.bumppypay.com/api/Payment/credit-card/pgConcernUpload`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };


    useEffect(() => {
        getBankDetails();
        getVirtualAccount();
    }, []);

    const getAccessInfo = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apitranxt.bumppypay.com/api/user/get_user_access_info.aspx",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                data.services.forEach((service) => {
                    if (service.pk_menu_id == "23") {
                        if (service.status == "Y") {
                            setQrEnabled(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAccessInfo();
    }, []);

    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />

                        <Modal
                            show={mdShow}
                            onHide={() => setMdShow(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                            size="lg"
                        >
                            {/* <Modal.Header closeButton>
                <Modal.Title> INDEMNITY BOND</Modal.Title>
              </Modal.Header> */}

                            <Modal.Body
                                style={{ width: "100%", display: "block" }}
                                className="account"
                            >
                                {Profileinfo && (
                                    <Row>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ textAlign: "center" }}>
                                                <h3>INDEMNITY BOND</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex"></div>

                                                <div className="row d-flex mt-2">
                                                    <div className="col-6">
                                                        <h5>
                                                            <strong>Date:- </strong>{" "}
                                                            {dateFormatToDisplay(submitTime)}
                                                        </h5>
                                                        <h5>
                                                            <strong>From:- </strong>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end">
                                                        <img
                                                            src={require("../../../assets/images/company/logo_square.png")}
                                                            style={{
                                                                width: "60%",
                                                                height: "auto",
                                                                marginLeft: "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <table
                                                    style={{ border: "1px solid black", width: "100%" }}
                                                >
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Merchant/Agent name</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.shopname}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>BC Agent Id</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {user.ReferId}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Registered Mobile number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.mobile}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>PAN number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            GDJPS5650A
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Request No.</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            18012023419638
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            Rs. {amount}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount (In Words)</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {inWords(Number(amount))}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Bank Account </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {selectedBank?.bankName} -:{" "}
                                                            {selectedBank?.accountNo}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Deposit Date</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {dateFormatToDisplay(date)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Payment Type</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {paymentMode.label}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Remark </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {remark}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <div className="my-4">
                                                    <strong>
                                                        To,<br></br>
                                                        The Board of Directors,<br></br>
                                                        {CERTIFICATE_COMPANY_NAME} <br></br>
                                                        <span>{CERTIFICATE_ADDRESS}</span>
                                                    </strong>
                                                </div>

                                                <div>
                                                    <p className="mb-2" style={{ fontSize: "16px" }}>
                                                        Dear Sir/Madam,
                                                    </p>

                                                    <p style={{ fontSize: "16px" }}>
                                                        I, <strong>{Profileinfo?.shopname}</strong>, as a
                                                        Merchant/Agent hereby undertake and explicitly agree
                                                        to indemnify {CERTIFICATE_COMPANY_NAME} towards the
                                                        following points:
                                                    </p>

                                                    <div className="p-4">
                                                        <ul style={{ fontSize: "16px" }}>
                                                            <li>
                                                                {CERTIFICATE_COMPANY_NAME} is providing us with
                                                                a platform as an enabler through which we can
                                                                transfer/receive/top up the money through
                                                                various methods likeUPI/ IMPS/ RTGS/ Payment
                                                                Gateway etc. from one person to another(P2P and
                                                                P2M) against a separate consideration;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for the
                                                                collection of KYC/ meeting the Statutory
                                                                requirements and other mandatory documents from
                                                                the sender or receiver or both and also the
                                                                reasons of such transactions;
                                                            </li>
                                                            <li>
                                                                I am responsible and abide to provide the KYC
                                                                and other mandatory documents and reasons of
                                                                each and every transactions with end customers
                                                                to the at {COMPANY_NAME}'s discretion;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for those
                                                                transactions which was wrongly debited or
                                                                credited by me to another party or any incorrect
                                                                entry/entries while using the platform;and
                                                            </li>
                                                            <li>
                                                                After obtaining a proper understanding of the
                                                                transaction patterns of this Company, I am
                                                                giving my consent to use this platform with all
                                                                the terms and conditions as provided by the{" "}
                                                                {CERTIFICATE_COMPANY_NAME} and also assuring
                                                                that every sender or receiver or both only after
                                                                giving their full consent using this platform
                                                                for transfer / receive / topup the money through
                                                                various methods like CASH / UPI / IMPS / NEFT /
                                                                RTGS / Payment Gateway etc.
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <p className="mt-4" style={{ fontSize: "16px" }}>
                                                        Thanking you,
                                                    </p>

                                                    <p style={{ fontSize: "16px", marginTop: "4rem" }}>
                                                        <strong>
                                                            {Profileinfo?.shopname}
                                                            <br></br>
                                                            (Merchant’s/Agent’s Name)<br></br>
                                                            {Profileinfo?.name}
                                                            <br></br>
                                                            Seal and Signature<br></br>
                                                        </strong>
                                                    </p>

                                                    <p className="mt-2" style={{ fontSize: "16px" }}>
                                                        <strong>
                                                            Timestamp : {dateFormatToDisplay(new Date())}
                                                        </strong>
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-6"></div>
                                                        <div className="col-12 col-md-6 d-flex justify-content-end">
                                                            <Button
                                                                variant="success"
                                                                className="rounded-0"
                                                                onClick={topupRequest}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="rounded-0 mx-2"
                                                                onClick={() => {
                                                                    setMdShow(false);
                                                                }}
                                                            >
                                                                Decline
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                            </Modal.Body>
                        </Modal>

                        <div style={{ padding: "0px" }} className="row p-4">
                            <Row>
                                <Col md={7} style={{}}>
                                    {/* first */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Notes"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Amount"
                                                        onChange={(e) => {
                                                            setAmount(e.target.value);
                                                        }}
                                                        style={{ borderRadius: "0px" }}
                                                        value={amount}
                                                    />
                                                </Form.Group>
                                                <strong style={{ color: "green" }}>
                                                    {inWords(Number(amount))}
                                                </strong>
                                            </div>

                                            <div className="col">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Notes"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Transaction ID"
                                                        onChange={(e) => {
                                                            setTransactionId(e.target.value);
                                                        }}
                                                        value={transactionId}
                                                        style={{ borderRadius: "0px" }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* second */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Select
                                                    options={banks}
                                                    onChange={(e) => {
                                                        setSelectedBank(e);
                                                    }}
                                                    value={selectedBank}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* third */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col grid-margin">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(date) => setDate(date)}
                                                    selected={date}
                                                    className="form-control"
                                                    placeholderText="Date of Deposit"
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </div>
                                            <div className="col">
                                                <Select
                                                    options={paymentModes}
                                                    onChange={(e) => {
                                                        setPaymentMode(e);
                                                    }}
                                                    value={paymentMode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* fourth */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Form.Group>
                                                    <textarea
                                                        placeholder="Remarks"
                                                        style={{
                                                            padding: "5px",
                                                            width: "100%",
                                                            borderRadius: "0px",
                                                        }}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                        value={remark}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* fifth */}
                                    <div className="container">
                                        <div style={{ padding: "10px" }} className="row">
                                            <div className="col grid-margin">
                                                <Form.Group>
                                                    <Form.Label>
                                                        <b>Slip Upload</b>
                                                        <span>(Optional)</span>
                                                    </Form.Label>
                                                    <Form.Label>
                                                        <span className="text-danger">
                                                            Image size should be less than 256 kb
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        accept=".jpeg"
                                                        type="file"
                                                        name="slip"
                                                        onChange={ImageUpload}
                                                        style={{ borderRadius: "0px" }}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col">
                                                <Form.Group>
                                                    <Invalid
                                                        text={TopupData.msg}
                                                        style={
                                                            TopupData.success
                                                                ? "card-inverse-success"
                                                                : "card-inverse-danger"
                                                        }
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* sixth */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col grid-margin"></div>
                                            <div className="col"></div>
                                            <div className="col">
                                                <Button
                                                    // onClick={topupRequest}
                                                    onClick={validate}
                                                    className="col"
                                                    variant="success"
                                                    style={{ borderRadius: "0px" }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col
                                    md={5}
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            margin: "0px 1rem",
                                            padding: "0px",
                                            borderRadius: "1rem",
                                            boxShadow: DROP_SHADOW,
                                        }}
                                    >
                                        <div
                                            style={{
                                                background: COLOR_QR_PLACEHOLDER,
                                                display: "flex",
                                                padding: "6px 1rem",
                                                borderTopRightRadius: "1rem",
                                                borderTopLeftRadius: "1rem",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ color: "white", fontWeight: "bolder" }}>
                                                {/* <h4 style={{ fontWeight: "bolder", margin: "0px" }}>
                          EARN MORE
                        </h4> */}
                                            </div>
                                            <div style={{ margin: "0px 2rem" }}>
                                                <img
                                                    className="p-1"
                                                    style={{ height: "3rem", width: "auto" }}
                                                    src={require("../../../assets/images/virtual_account/qr_logo_square.png")}
                                                />
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    sessionStorage.setItem(
                                                        "print",
                                                        JSON.stringify(virtualAccount)
                                                    );
                                                    print();
                                                }}
                                                variant=""
                                                className="rounded-0 ml-auto"
                                                size="sm"
                                                style={{
                                                    background: "#56BE89",
                                                    color: "white",
                                                    border: "none",
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                padding: "1rem",
                                                background: "#FFFFFF",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    style={{ height: "auto", width: "100%" }}
                                                    src={require("../../../assets/images/virtual_account/virtual_account_app_logo.png")}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    flex: "1",
                                                    background: "#FFFFFF",
                                                    boxShadow:
                                                        "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                                    padding: "6px",
                                                    borderRadius: "6px",
                                                }}
                                            >
                                                <img
                                                    style={{ height: "auto", width: "100%" }}
                                                    src={require("../../../assets/images/virtual_account/bhim_upi_logo.png")}
                                                />
                                                <canvas
                                                    style={{ width: "100%", height: "auto" }}
                                                    ref={canvasEl}
                                                    id="qrCanvas"
                                                    width="300"
                                                    height="300"
                                                    className="mx-auto"
                                                />
                                                <div>
                                                    <strong>PAY WITH ANY APP</strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                background: COLOR_QR_PLACEHOLDER_SECONDARY,
                                                color: "#FFFFFF",
                                                padding: "6px",
                                                borderBottomRightRadius: "1rem",
                                                borderBottomLeftRadius: "1rem",
                                            }}
                                        >
                                            <table style={{ width: "100%" }}>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        {" "}
                                                        <span style={{ fontSize: "1rem" }}>Shop Name:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <strong style={{ width: "50%", fontSize: "1rem" }}>
                                                            {Profileinfo?.shopname}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            Account No:
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }} className="pl-1">
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_account_no
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>IFSC Code:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_ifsc
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            Deposit Type:
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            {" "}
                                                            {"IMPS/NEFT/RTGS."}{" "}
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>UPI ID:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }} className="pl-1">
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_upiid
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    {!enableSmartCollectButtonState && (
                                        <React.Fragment>
                                            <div className="p-2 mt-2">
                                                <h5 style={{ color: "grey" }}>
                                                    To start Smart Collect feature please click on the
                                                    below button
                                                </h5>
                                            </div>
                                            <Button
                                                type="button"
                                                className="mt-2"
                                                style={{ borderRadius: "0px" }}
                                                disabled={enableSmartCollectButtonState}
                                                onClick={virtualAccountCreate}
                                            >
                                                Enable Smart Collect
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

const LoadRequest = () => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const canvasContextRef = useRef(null);
    const validate = () => {
        if (
            amount.toString().trim() === "" ||
            transactionId.toString().trim() === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            let newTid = transactionId;
            if (newTid.length < 8) {
                newTid = `000000${newTid}`;
            }
            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${newTid}.jpeg&TransactionId=${newTid}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {

                var raw = JSON.stringify({
                    "creditcard": "string",
                    "userid": "string",
                    "aadhaar": "string",
                    "p1": "string",
                    "imagename": "Suhastest",
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `https://apiservices.bumppypay.com/api/Payment/credit-card/pgConcernUpload`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        getBankDetails();
    }, []);
    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />

                        <Modal
                            show={mdShow}
                            onHide={() => setMdShow(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                            size="lg"
                        >
                            {/* <Modal.Header closeButton>
                <Modal.Title> INDEMNITY BOND</Modal.Title>
              </Modal.Header> */}

                            <Modal.Body
                                style={{ width: "100%", height: "100vh", display: "block" }}
                                className="account"
                            >
                                {Profileinfo && (
                                    <Row>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ textAlign: "center" }}>
                                                <h3>INDEMNITY BOND</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex"></div>

                                                <div className="row d-flex mt-2">
                                                    <div className="col-6">
                                                        <h5>
                                                            <strong>Date:- </strong>{" "}
                                                            {dateFormatToDisplay(submitTime)}
                                                        </h5>
                                                        <h5>
                                                            <strong>From:- </strong>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end">
                                                        <img
                                                            src={require("../../../assets/images/company/navbar_logo.png")}
                                                            style={{
                                                                width: "60%",
                                                                height: "auto",
                                                                marginLeft: "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <table
                                                    style={{ border: "1px solid black", width: "100%" }}
                                                >
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Merchant/Agent name</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.shopname}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>BC Agent Id</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {user.ReferId}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Registered Mobile number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.mobile}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>PAN number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            GDJPS5650A
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Request No.</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            18012023419638
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            Rs. {amount}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount (In Words)</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {inWords(Number(amount))}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Bank Account </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {selectedBank?.bankName} -:{" "}
                                                            {selectedBank?.accountNo}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Deposit Date</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {dateFormatToDisplay(date)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Payment Type</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {paymentMode.label}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Remark </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {remark}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <div className="my-4">
                                                    <strong>
                                                        To,<br></br>
                                                        The Board of Directors,<br></br>
                                                        {CERTIFICATE_COMPANY_NAME} <br></br>
                                                        <span>{CERTIFICATE_ADDRESS}</span>
                                                    </strong>
                                                </div>

                                                <div>
                                                    <p className="mb-2" style={{ fontSize: "16px" }}>
                                                        Dear Sir/Madam,
                                                    </p>

                                                    <p style={{ fontSize: "16px" }}>
                                                        I, <strong>{Profileinfo?.shopname}</strong>, as a
                                                        Merchant/Agent hereby undertake and explicitly agree
                                                        to indemnify {CERTIFICATE_COMPANY_NAME} towards the
                                                        following points:
                                                    </p>

                                                    <div className="p-4">
                                                        <ul style={{ fontSize: "16px" }}>
                                                            <li>
                                                                {CERTIFICATE_COMPANY_NAME} is providing us with
                                                                a platform as an enabler through which we can
                                                                transfer/receive/top up the money through
                                                                various methods likeUPI/ IMPS/ RTGS/ Payment
                                                                Gateway etc. from one person to another(P2P and
                                                                P2M) against a separate consideration;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for the
                                                                collection of KYC/ meeting the Statutory
                                                                requirements and other mandatory documents from
                                                                the sender or receiver or both and also the
                                                                reasons of such transactions;
                                                            </li>
                                                            <li>
                                                                I am responsible and abide to provide the KYC
                                                                and other mandatory documents and reasons of
                                                                each and every transactions with end customers
                                                                to the at {COMPANY_NAME}'s discretion;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for those
                                                                transactions which was wrongly debited or
                                                                credited by me to another party or any incorrect
                                                                entry/entries while using the platform;and
                                                            </li>
                                                            <li>
                                                                After obtaining a proper understanding of the
                                                                transaction patterns of this Company, I am
                                                                giving my consent to use this platform with all
                                                                the terms and conditions as provided by the{" "}
                                                                {CERTIFICATE_COMPANY_NAME} and also assuring
                                                                that every sender or receiver or both only after
                                                                giving their full consent using this platform
                                                                for transfer / receive / topup the money through
                                                                various methods like CASH / UPI / IMPS / NEFT /
                                                                RTGS / Payment Gateway etc.
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <p className="mt-4" style={{ fontSize: "16px" }}>
                                                        Thanking you,
                                                    </p>

                                                    <p style={{ fontSize: "16px", marginTop: "4rem" }}>
                                                        <strong>
                                                            {Profileinfo?.shopname}
                                                            <br></br>
                                                            (Merchant’s/Agent’s Name)<br></br>
                                                            {Profileinfo?.name}
                                                            <br></br>
                                                            Seal and Signature<br></br>
                                                        </strong>
                                                    </p>

                                                    <p className="mt-2" style={{ fontSize: "16px" }}>
                                                        <strong>
                                                            Timestamp : {dateFormatToDisplay(new Date())}
                                                        </strong>
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-6"></div>
                                                        <div className="col-12 col-md-6 d-flex justify-content-end">
                                                            <Button
                                                                variant="success"
                                                                className="rounded-0"
                                                                onClick={topupRequest}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="rounded-0 mx-2"
                                                                onClick={() => {
                                                                    setMdShow(false);
                                                                }}
                                                            >
                                                                Decline
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                            </Modal.Body>
                        </Modal>

                        <Row className="justify-content-center p-0">
                            <Col
                                md={12}
                                style={{
                                    textAlign: "center",
                                    maxWidth: "35rem",
                                }}
                                className="card"
                            >
                                {/* first */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Control
                                                    type="number"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Amount"
                                                    onChange={(e) => {
                                                        setAmount(e.target.value);
                                                    }}
                                                    style={{ borderRadius: "0px" }}
                                                    value={amount}
                                                />
                                            </Form.Group>
                                            <strong style={{ color: "green" }}>
                                                {inWords(Number(amount))}
                                            </strong>
                                        </div>

                                        <div className="col">
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Transaction ID"
                                                    onChange={(e) => {
                                                        setTransactionId(e.target.value);
                                                    }}
                                                    value={transactionId}
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* second */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Select
                                                options={banks}
                                                onChange={(e) => {
                                                    setSelectedBank(e);
                                                }}
                                                value={selectedBank}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* third */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col grid-margin">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => setDate(date)}
                                                selected={date}
                                                className="form-control"
                                                placeholderText="Date of Deposit"
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </div>
                                        <div className="col">
                                            <Select
                                                options={paymentModes}
                                                onChange={(e) => {
                                                    setPaymentMode(e);
                                                }}
                                                value={paymentMode}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* fourth */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <textarea
                                                    placeholder="Remarks"
                                                    style={{
                                                        padding: "5px",
                                                        width: "100%",
                                                        borderRadius: "0px",
                                                    }}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                    value={remark}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* fifth */}
                                <div className="container">
                                    <div style={{ padding: "10px" }} className="row">
                                        <div className="col grid-margin">
                                            <Form.Group>
                                                <Form.Label>
                                                    <b>Slip Upload</b>
                                                    <span>(Optional)</span>
                                                </Form.Label>
                                                <Form.Label>
                                                    <span className="text-danger">
                                                        Image size should be less than 256 kb
                                                    </span>
                                                </Form.Label>
                                                <Form.Control
                                                    accept=".jpeg"
                                                    type="file"
                                                    name="slip"
                                                    onChange={ImageUpload}
                                                    style={{ borderRadius: "0px" }}
                                                ></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col">
                                            <Form.Group>
                                                <Invalid
                                                    text={TopupData.msg}
                                                    style={
                                                        TopupData.success
                                                            ? "card-inverse-success"
                                                            : "card-inverse-danger"
                                                    }
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* sixth */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col grid-margin"></div>
                                        <div className="col"></div>
                                        <div className="col">
                                            <Button
                                                // onClick={topupRequest}
                                                onClick={validate}
                                                className="col"
                                                variant="success"
                                                style={{ borderRadius: "0px" }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

export const Invalid = ({ text, style }) => {
    console.log("hiii");
    return (
        <div className={`${style}`}>
            <ContextMenuTrigger id="actionContextMenu">
                <div className="card-body">
                    <p className="card-text">{text}</p>
                </div>
            </ContextMenuTrigger>
        </div>
    );
};

export const ImageUpload = () => {
    const { user, Profileinfo } = useContext(GlobalContext);
    const [qrEnabled, setQrEnabled] = useState(false);
    const [dcEnable, setDcEnabled] = useState(false);
    const [ccEnable, setCcEnabled] = useState(false);
    const [nbEnabled, setNbEnabled] = useState(false);
    const [pgEnabled, setPgEnabled] = useState(false);
    const [pg2Enabled, setPg2Enabled] = useState(false);
    const [pg3Enabled, setPg3Enabled] = useState(false);
    const [pg4Enabled, setPg4Enabled] = useState(false);
    const [pg5Enabled, setPg5Enabled] = useState(false);
    const query = useQuery();

    const getAccessInfo = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apitranxt.bumppypay.com/api/user/get_user_access_info.aspx",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                data.services.forEach((service) => {
                    if (service.pk_menu_id == "23" && service.status == "Y") {
                        setQrEnabled(true);
                    }
                    if (service.pk_menu_id == "27" && service.status == "Y") {
                        setCcEnabled(true);
                    }
                    if (service.pk_menu_id == "28" && service.status == "Y") {
                        setDcEnabled(true);
                    }
                    if (service.pk_menu_id == "29" && service.status == "Y") {
                        setNbEnabled(true);
                    }
                    if (service.pk_menu_id == "33" && service.status == "Y") {
                        setPgEnabled(true);
                    }
                    if (service.pk_menu_id == "36" && service.status == "Y") {
                        setPg2Enabled(true);
                    }

                    if (service.pk_menu_id == "40" && service.status == "Y") {
                        setPg3Enabled(true);
                    }
                    if (service.pk_menu_id == "42" && service.status == "Y") {
                        setPg4Enabled(true);
                    }
                    if (service.pk_menu_id == "48" && service.status == "Y") {
                        setPg5Enabled(true);
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAccessInfo();
    }, []);

    useEffect(() => {
        const orderid = query.get("orderid");
        const status = query.get("status");
        const amount = query.get("amount");
        if (status && status == "success") {
            swal("Success", `Payment Success for Amount ${amount} `, "success");

        } else if (status) {
            swal("Failed", `Payment Failed for Amount ${amount} `, "error");
        }
    }, []);

    return (
        <div
            style={{
                backgroundColor: " #F1F2F4",
                height: "100%",
                width: "100%"
            }}
        >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={12}>
                        <Tab.Content className="tab-content-vertical border-0">
                            <Tab.Pane eventKey="first">
                                <InstantLoad
                                    qrEnabled={qrEnabled}
                                    dcEnable={dcEnable}
                                    ccEnable={ccEnable}
                                    nbEnabled={nbEnabled}
                                    pgEnabled={pgEnabled}
                                    pg2Enabled={pg2Enabled}
                                    pg3Enabled={pg3Enabled}
                                    pg4Enabled={pg4Enabled}
                                    pg5Enabled={pg5Enabled}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

const InstantLoad = ({ qrEnabled, dcEnable, ccEnable, nbEnabled, pgEnabled, pg2Enabled, pg3Enabled, pg4Enabled, pg5Enabled }) => {
    const [mode, setMode] = useState("CC");
    return (
        <div className=""
            style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                border: "0px solid #4267B2",
                borderRadius: "10px",
                margin: "1rem",
                boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.1)'

            }}
        >
            <h3
                className="py-2 pt-3"
                style={{
                    marginLeft: "1.4rem",
                    color: "#4267B2",
                    fontFamily: "poppins",
                }}
            >
                <b>Add Funds</b>
            </h3>
            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first"
                className=""
            >
                <Row className="m-0 p-0 ">
                    <Col md={6} sm={4} lg={12}>
                        <Nav
                            variant="pills"
                            className="nav-pills-custom-5  justify-content-start p-2 py-1 "
                            style={{
                                border: "none",
                                background: "#EEF0F1",
                                color: "#4267B2",
                                borderRadius: "10px",



                            }}
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                    <img className="pr-1 mb-1 "
                                        src={require("../../../assets/images/UPI.svg")}
                                        style={{ width: `40%`, height: '40%' }}
                                    />
                                    UPI
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sixth">
                                    {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                    <img className="pr-1 mb-1"
                                        src={require("../../../assets/images/Load Request.svg")}
                                        style={{ width: `20%`, height: '%' }} />
                                    Load Request
                                </Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </Col>
                    <Col className="m-0 py-0" sm={12}>
                        <Tab.Content className="tab-content-vertical border-0">


                            <Tab.Pane eventKey="sixth">
                                <LoadRequest />
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};



export default Topup;
