import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel, inWords } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "Load_Id",
    text: "Load ID",
  },
  {
    dataField: "UTR_No",
    text: "REF",
  },
  {
    dataField: "user_code",
    text: "User Code",
  },
  {
    dataField: "user_name",
    text: "User Name",
  },
  {
    dataField: "user_mobile",
    text: "User Mobile",
  },
  {
    dataField: "Bank_Name",
    text: "Bank Name",
  },

  {
    dataField: "Paymode",
    text: "Payment Mode",
  },
  {
    dataField: "load_indate",
    text: "Request Date",
  },

  {
    dataField: "payment_date",
    text: "Deposite Date",
  },

  {
    dataField: "load_update",
    text: "Update Date",
  },

  {
    dataField: "due8",
    text: "Action By",
  },
  {
    dataField: "Amount",
    text: "Amount",
  },
  {
    dataField: "time",
    text: "Time",
  },
  {
    dataField: "STATUS",
    text: "Status",
  },
  {
    dataField: "Remark",
    text: "Remarks",
  },
];

const ViewLoadTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [slipUrl, setSlipUrl] = useState("");
  const [inputState, setInputState] = useState({ userType: "" });
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [loadDetails, setLoadDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slipModalOpen, setSlipModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const dateDiffrence = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const timeDifference = d1 - d2;

    let minutesDifference = timeDifference / (1000 * 60);
    minutesDifference = Math.ceil(minutesDifference);
    return minutesDifference + " minutes ago";
  }

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const openModal = (details) => {
    setLoadDetails(details);
    setModalOpen(true);
  };
  const openSlipModal = (url) => {
    setSlipUrl(url);
    setSlipModalOpen(true);
  };

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getViewLoadList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_view_load_requests_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data.map(e => {
        return { ...e, user_name: e.User_Details.toString().split("/")[0], user_code: e.User_Details.toString().split("/")[1], user_mobile: e.User_Details.toString().split("/")[2], time: e.STATUS == "Pending" ? dateDiffrence(new Date(), e.load_indate) : dateDiffrence(e.load_update, e.load_indate) }
      }));
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  useEffect(() => {
    getViewLoadList();
  }, []);

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height: '20%',

  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily": "Poppins",
    "fontWeight": "400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };

  console.log(data);



  return (

    <>
      <div className="row m-0 p-0  pt-1 align-items-center">
        <div className="col-4 m-0 p-0">

          <div className="m-0 p-0 pl-2"
            style={{
              "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
              , padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
            View Load
          </div>
        </div>

        <div className="col-8 m-0 p-0 ">
          <div className="row  m-0 p-0  justify-content-end">

            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>




            <div className="pr-1 pl-2 p-0 m-0" style={{ width: '18%', height: '20%', }}>
              <Form.Group className="p-0 m-0">

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
              <Form.Group className="p-0 m-0">

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>


            <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-50 btn btn-primary btn-lg btn-block "

                  onClick={getViewLoadList}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>

            <div className="m-0 p-0  hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `Load_Requests_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img className="pl-1 p-0"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: '85%' }}
              />
              <div div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark">Export to Excel</div>

            </div>
          </div>
        </div>

      </div>



      <hr className="m-0 p-0" />

      <ViewLoadModal
        details={loadDetails}
        isModalOpen={modalOpen}
        setModalOpen={(modalState) => {
          setModalOpen(modalState);
        }}
        refresh={getViewLoadList}
      />
      <ViewSlipModal
        details={slipUrl}
        isModalOpen={slipModalOpen}
        setModalOpen={(modalState) => {
          setSlipModalOpen(modalState);
        }}
      />




      <div className="my-table scroll-inner admin-fix-width transparent-table">
        <table style={{ width: "100%", backgroundColor: '#FFFFFF' }} className="my-table  borderless transparent-table">
          <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Sr"}</th>

            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"User Details"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Bank Name"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"REF"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Payment Mode"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Date"}</th>
            {/* <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Payment Date"}</th> */}
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Update Date"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Load ID"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Amount"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Status"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Remarks"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Admin Remarks"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Updated By"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Time"}</th>
            <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Action"}</th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="my-table transparent-table border-bottom py-5">
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  {ind + 1}
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div style={{ width: '200px', }} className="d-flex flex-column align-items-center  p-0 m-0">
                  <span >{`${e.User_Details}`}</span>
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span>{e.Bank_Name}</span>
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span >{e.UTR_No}</span>
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span >{e.Paymode}</span>
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <div style={{ width: '5rem', }} dangerouslySetInnerHTML={{ __html: e.load_indate }} />
                  <span className="text-success">{`Deposite Date : ${e.payment_date}`}</span>
                </div>
              </td>
              {/* <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-success">{e.payment_date}</span>
                </div>
              </td> */}

              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-success">{e.load_update}</span>
                </div>
              </td>


              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span>{`${e.Load_Id}`}</span>
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div style={{ width: '10rem', }} className="d-flex flex-column align-items-center text-center  p-0 m-0">
                  <span >
                    {`${twoDecimalPlaces(
                      e.Amount
                    )}`}
                  </span>
                  <span >{`${inWords(
                    Number(e.Amount)
                  )}`}</span>
                </div>
              </td>
              <td className="m-0 p-0" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div style={{ width: '170px', }} className="d-flex flex-column align-items-center  p-0 m-0">
                  {e.STATUS == "Pending" ? (
                    <span style={{
                      paddingLeft: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(254, 128, 36, 0.1)',
                      color: 'rgba(254, 128, 36, 1)',
                      bordercolor: 'rgba(254, 128, 36, 1)',

                    }}>
                      {`${e.STATUS}`}
                    </span>
                  ) : e.STATUS == "Rejected" ? (
                    <span style={{
                      paddingLeft: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(255, 46, 46, 0.1)',
                      color: 'rgba(255, 46, 46, 1)',
                      bordercolor: 'rgba(255, 46, 46, 1)',

                    }}>
                      {`${e.STATUS}`}
                    </span>
                  ) : (
                    <span style={{
                      paddingLeft: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(43, 193, 85, 0.1)',
                      color: 'rgba(43, 193, 85, 1)',
                      bordercolor: 'rgba(43, 193, 85, 1)',

                    }}>
                      {`${e.STATUS}`}
                    </span>
                  )}
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-dark">{`${e.Remark}`}</span>
                </div>
              </td>

              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-dark">{`${e.load_update_remark}`}</span>
                </div>
              </td>

              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-dark">{`${e.due8}`}</span>
                </div>
              </td>
              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-dark">{`${e.STATUS == "Pending" ? dateDiffrence(new Date(), e.load_indate) : dateDiffrence(e.load_update, e.load_indate)}`}</span>
                </div>
              </td>

              <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  {e.STATUS == "Pending" && (
                    <Button
                      style={{

                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        color: 'rgba(255, 164, 27, 1)',
                        bordercolor: 'rgba(255, 255, 255, 1)',
                      }} className="btn btn-sm  rounded-100 m-0 p-0 px-1 pb-1 hover-container"
                      onClick={() => {
                        openModal(e);
                      }}
                    >
                      {" "}
                      <img
                        src={require("../../../assets/images/Add Load.png")}
                        style={{ width: "" }}
                      />
                      {/* <span style={{"fontFamily":"Poppins",'fontsize': '20px',  color:'rgba(0, 0, 0, 1)', }} className="hover-text">  Add_Load</span> */}
                      <div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark "> Add Load</div>
                    </Button>
                  )}


                  <Button style={{

                    borderRadius: "5px",
                    border: "1px none",
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: 'rgba(255, 164, 27, 1)',
                    bordercolor: 'rgba(255, 255, 255, 1)',
                  }} className="btn btn-sm  rounded-100 m-0 p-0 px-1"
                  >
                    {" "}

                    <div
                      onClick={() => {
                        openSlipModal(
                          `${BASE_URL_API_TRANXT_API}/users_kyc_doc/img_${e.UTR_No}.jpeg`
                        );
                      }}
                      className="hover-container"

                    >

                      <img
                        src={require("../../../assets/images/View Slip.png")}
                        style={{ width: "" }}
                      />
                      {/* <span style={{"fontFamily":"Poppins",'fontsize': '20px',  color:'rgba(0, 0, 0, 1)', }} className="hover-text"> View_Slip</span> */}
                      <div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark mb-2 "> View Slip</div>


                    </div>


                  </Button>
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>



      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const ViewLoadModal = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    remark: "",
    status: "",
    amount: "0.00",
    loadId: "",
    utr: "",
    bankId: "",
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const actions = [
    { label: "Please Select", value: "-1" },
    { label: "Add Load on Credit", value: "4" },
    { label: "Reject Request", value: "3" },
  ];

  const acceptLoadRequest = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        loadId: state.loadId,
        amount: state.amount,
        remark: state.remark,
        status: state.status,
        adminid: user.pkid
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/adminLoadRequestApprove`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Load Request Accept Successfully", data.data?.message, "success");
      } else {
        swal("Load Request Accept Failed", data.data?.message, "error");
      }
      setModalOpen(false);
      refresh();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("Load Request Accept Failed", "Error From Server", "error");
    }
  };

  const acceptLoadRequest2 = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        loadId: state.loadId,
        amount: state.amount,
        remark: state.remark,
        status: state.status,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/accept_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Load Request Accept Successfully", data.data?.result, "success");
      } else {
        swal("Load Request Accept Failed", data.data?.result, "error");
      }
      setModalOpen(false);
      refresh();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("Load Request Accept Failed", "Error From Server", "error");
    }
  };

  const rejeectLoadRequest = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        loadId: state.loadId,
        amount: state.amount,
        remark: state.remark,
        status: state.status,
        utr: state.utr,
        bankId: state.bankId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/reject_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Load Request Reject Successfully", data.data?.result, "success");
      } else {
        swal("Load Request Reject Failed", data.data?.result, "error");
      }
      setModalOpen(false);
      refresh();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Load Request Reject Failed", "Error From Server", "error");
    }
  };
  const submit = () => {
    if (state.remark == "") {
      swal("Invalid", "Please Enter Valid Remark", "error");
      return;
    }
    if (state.status == "4") {
      acceptLoadRequest();
    } else if (state.status == "3") {
      rejeectLoadRequest();
    } else {
      swal("Invalid", "Please Select Valid Action", "error");
    }
  };
  console.log(details);
  useEffect(() => {
    if (details) {
      setState({
        ...state,
        amount: twoDecimalPlaces(details.Amount),
        loadId: details.Load_Id,
        utr: details.UTR_No,
        bankId: details.load_bankid,
      });
    }
  }, [details]);
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="lg"
    >
      <Modal.Body
        style={{ width: "100%", display: "block" }}
        className="account"
      >

        <div
          style={{
            padding: "12px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontWeight: "bolder",
            fontfamily: "Poppins",
            fontSize: "32px",
          }}
          className="d-flex justify-content-between"
        >
          <div>Add Load</div>
          <div
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </div>
        </div>


        <hr />

        {details && (
          <React.Fragment>
            <div className="row">
              <div className="col-6">Load ID</div>
              <div className="col-6">{details.Load_Id}</div>
            </div>
            <div className="row">
              <div className="col-6">Request Load Amount</div>
              <div className="col-6">{state.amount}</div>
            </div>
            <div className="row">
              <div className="col-6">Request Type</div>
              <div className="col-6">{details.Paymode}</div>
            </div>

            <hr />

            <div className="row">



              <div className="col-6">
                <div className="">Action</div>

                <select
                  style={{ width: "100%" }}
                  className="rounded-0 form-select mx-1 p-2  "
                  aria-label="offset"
                  onChange={(e) => {
                    setState({ ...state, status: e.target.value });
                  }}
                >
                  {actions.map((a) => (
                    <option value={a.value}>{a.label}</option>
                  ))}
                </select>
              </div>


              <div className="col-6">
                <div className="">Remarks</div>

                {" "}
                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Enter Remarks"
                    value={state.remark}
                    onChange={(e) => {
                      setState({ ...state, remark: e.target.value });
                    }}
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Group>
              </div>



            </div>

            <div className="row align-items-center">

              <div className="col-6">
                <div className="">Add Load</div>

                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Enter Amount"
                    value={state.amount}
                    onChange={(e) => {
                      setState({ ...state, amount: e.target.value });
                    }}
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Group>
                <strong className="p-1 text-success">
                  {inWords(Number(state.amount))}
                </strong>
              </div>


              <div className="col-6 align-items-center">
                <div className=""></div>

                {" "}
                <Button
                  className="rounded-50 btn btn-primary btn-lg btn-block mb-3"
                  onClick={submit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

const ViewSlipModal = ({ details: url, isModalOpen, setModalOpen }) => {
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="xl"
    >
      <Modal.Body
        style={{ height: "100%", display: "block" }}
        className="account"
      >
        <div
          style={{
            padding: "12px",
            background: "#3676D8",
            color: "#FFF",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong>View Slip</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        {url && (
          <React.Fragment>
            <div className="row">
              <img src={url} style={{ width: "50%", height: "auto" }} />
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewLoadTable;
