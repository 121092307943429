import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { set } from "react-hook-form";
export const columns = [
  {
    dataField: "UserCode",
    text: "User Code",
  },
  {
    dataField: "mobile",
    text: "Mobile",
  },
  {
    dataField: "user_name",
    text: "User Name",
  },
  {
    dataField: "Company",
    text: "Company",
  },
  {
    dataField: "AddLoad",
    text: "Load Amount",
  },
  {
    dataField: "OpenBal",
    text: "Opening Balance",
  },
  {
    dataField: "Balance",
    text: "Balance",
  },
];

const positionStyle = {
  position: "absolute",
  top: -28,
  left: 0,
  fontFamily: "Poppins",
  color: "rgba(255, 255,255, 1)",
  borderRadius: "0.2rem",
};
const positionStyle1 = {
  position: "absolute",
  top: -58,
  left: -15,
  fontFamily: "Poppins",
  color: "rgba(255, 255,255, 1)",
  borderRadius: "0.2rem",
};

const actions = [
  { label: "Select Action", value: "" },
  { label: "Approve", value: "1" },
  { label: "Reject", value: "2" },
];

const AllReviewKyc = () => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  let fromDate = sessionStorage.getItem("fromDate")
    ? new Date(sessionStorage.getItem("fromDate"))
    : new Date();
  let toDate = sessionStorage.getItem("toDate")
    ? new Date(sessionStorage.getItem("toDate"))
    : new Date();
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [inputState, setInputState] = useState({
    userType: "2",
  });
  const { Profileinfo } = useContext(GlobalContext);
  const [clickedImageURL, setClickedImageURL] = useState("");

  const handleImageClick = (imageUrl) => {
    setClickedImageURL(imageUrl);
  };

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getAllKycReviewReport = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: "",
        adminid: "",
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllKycReviewReportAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      } else {
        setData([]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllKycReviewReport();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  return (
    <>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}
      <div className="row m-0 p-0  pt-1 align-items-center">
        <div className="col-4 m-0 p-0">
          <div
            className="m-0 p-0 pl-3"
            style={{
              fontFamily: "Poppins",
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
           KYC Review Report
          </div>
        </div>

        <div className="col-8 m-0 p-0 ">
          <div className="row  m-0 p-0  justify-content-end">
            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div
              className="pr-1 pl-2 p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    sessionStorage.setItem("fromDate", date.toISOString());
                  }}
                  className="form-control rounded-100"
                  placeholder="Start Date"
                />
              </Form.Group>
            </div>
            <div
              className="px-1  p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    sessionStorage.setItem("toDate", date.toISOString());
                  }}
                  className="form-control rounded-100"
                  placeholder="End Date"
                />
              </Form.Group>
            </div>

            <div
              className="pl-1   p-0 m-0"
              style={{ width: "14%", height: "20%" }}
            >
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-100 mt-4 btn btn-lg btn-block"
                  onClick={getAllKycReviewReport}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
            <div
              className="m-0 p-0 hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `Balance_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div
                div
                style={{ color: "rgba(255, 255,255, 1)" }}
                className="hover-text bg-dark "
              >
                Export to Excel
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0 p-0" />
      <div className="scroll-inner admin-fix-width">
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
             <tr className="borderless-table text-center border-bottom">
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>

            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"User "}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Pan Verified"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Aadhar Verified"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Aadhar OTP Verified"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Home Address Verified"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Shop Address Verified"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Reviewed By"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Date"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sales Person"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Action"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                {ind + 1}
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div>{e.user_code}</div>
                <div>{e.user_name}</div>
                <div>{e.user_mobile}</div>
              </td>
             
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                   <div className="d-flex flex-column align-items-center">
                  {e.pan_verified == "Y" ? (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/success_tick.jpg")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Verified"}
                  </label>
                  ) : (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/cross.png")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Rejected"}
                  </label>
                  ) }
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                   <div className="d-flex flex-column align-items-center">
                  {e.aadhaar_verified == "Y" ? (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/success_tick.jpg")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Verified"}
                  </label>
                  ) : (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/cross.png")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Rejected"}
                  </label>
                  ) }
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                   <div className="d-flex flex-column align-items-center">
                  {e.aadhaar_otp_verified == "Y" ? (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/success_tick.jpg")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Verified"}
                  </label>
                  ) : (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/cross.png")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Rejected"}
                  </label>
                  ) }
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                   <div className="d-flex flex-column align-items-center">
                  {e.home_address_verified == "Y" ? (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/success_tick.jpg")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Verified"}
                  </label>
                  ) : (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/cross.png")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Rejected"}
                  </label>
                  ) }
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                   <div className="d-flex flex-column align-items-center">
                  {e.shop_address_verified == "Y" ? (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/success_tick.jpg")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Verified"}
                  </label>
                  ) : (
                    <label
                    style={{
                      padding: "4px",
                      //  borderRadius: "4px",
                      minWidth: "4rem",
                    }}
                    className="badge"
                  >
                    <img
                      src={require("../../../assets/images/cross.png")}
                      style={{
                        height: "1rem",
                        width: "auto",
                        borderRadius: "1rem",
                        marginRight: ".2rem",
                      }}
                    />
                    {"Rejected"}
                  </label>
                  ) }
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div>{e.reviewed_by}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div>{e.indate}</div>
                <div>{e.update}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div>{e.sales_user_code}</div>
                <div>{e.sales_user_name}</div>
                <div>{e.sales_user_mobile}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="row m-0 p-0">
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-0 p-0"
                    
                  >
                    {" "}
                    <div className="hover-container">
                    <a href={`/admin/review_kyc?id=${e.user_code}`}>
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      </a>
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Update Kyc Details
                      </div>
                    </div>
                  </Button>
           
                </div>
              </td>
            
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};


export default AllReviewKyc;