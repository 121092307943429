import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Button } from "react-bootstrap";
import RewardsTable from "./RewardsTable";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";

export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "rch_mobile",
    text: "Beneficiary",
  },
  {
    dataField: "utnno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];
export const dmtReport = [
  {
    mobile: "9168805979",
    beneficiary: "32905756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "800",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "4/9/2022 7:17:31 PM",
  },
  {
    mobile: "9057568979",
    beneficiary: "568464756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "1000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "13/2/2022 7:17:31 PM",
  },
  {
    mobile: "9168057568",
    beneficiary: "568907568464565",
    type: "IMPS",
    utrNo: "0",
    amount: "40000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "3/4/2022 7:17:31 PM",
  },
  {
    mobile: "9105756879",
    beneficiary: "684649075684646",
    type: "IMPS",
    utrNo: "0",
    amount: "2000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "pending",
    datetime: "23/5/2022 7:17:31 PM",
  },
];
const AdminRewardsScreen = () => {
  const { user } = useContext(GlobalContext);

  const [modalOpen, setModalOpen] = useState(false);

  const [userid, setUserId] = useState("");
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: userid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/PG/getAllRewards`,
        requestOptions
      )

        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0" && result.data) {

            setData(result.data);
          } else {
            setData([]);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <AddRewardModal details={{userid}} isModalOpen={modalOpen} setModalOpen={setModalOpen} refresh={fetchData} />
     <div style={{ padding: "1rem", backgroundColor: "#F2F6FC" }} className=" ">
      <div
        style={{ borderRadius: "10px" }}
        className="card mt-4 p-0 mx-2 border-light "
      >
        
        <div className="card-body m-0 p-2">
          <div>
            <div className="row">
              <div className="col-6 col-sm-4 col-md-3">
                <Form.Group>
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {`Enter User Code Or Mobile Number`}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Enter User Code Or Mobile Number"
                    value={userid}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Group>
              </div>

              <div className="col-6 col-sm-4 col-md-3 d-flex">
                <Form.Group className="mt-4">
                  <Button
                    onClick={() => {
                      fetchData();
                    }}
                    className="btn btn-primary rounded-0"
                  >
                    {" "}
                    Search
                  </Button>
                </Form.Group>
                <Form.Group className="mt-4 ml-2">
                  <Button
                    onClick={()=>{}}
                    className="btn btn-danger rounded-0 ml-3"
                  >
                    {" "}
                    Clear
                  </Button>
                </Form.Group>
              </div>
              <div className="col-6">
              <Form.Group className="mt-4">
                  <Button
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    className="btn btn-primary rounded-0"
                  >
                    {" "}
                    Add Reward
                  </Button>
                </Form.Group>
              </div>
            </div>

            <RewardsTable userid={userid} data={data} setData={setData} />
          </div>
        </div>
      </div>
    </div>
    </>
   
  );
};

const AddRewardModal = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const changes = [
    { label: "Cashback", value: "1" },
    { label: "Diwali Reward", value: "2" }
  ];
  const [state, setState] = useState({
    action: "",
    p1: "",
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const addRewards = async (amount , type) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1`);
      var raw = JSON.stringify({
        "adminId": "9",
        "rewardTitle": "You have been earned Rupees 10 Reward on Diwali",
        "rewardDescription": "You have been earned Rupees 10 Reward on Diwali",
        "amount": state.amount,
        "userid": details.userid,
        "rewardType": "2"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://apiservices.bumppypay.com/api/Admin/addRewardsAdmin`,
        requestOptions
      )

        .then((response) => response.json())
        .then((result) => {
          swal("Success", result.responseMessage, "success");
          refresh();
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (details) {
      setUserDetails(details);
    }
  }, [details]);

  console.log(state);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="md"
    >
      <Modal.Body style={{ width: "100%", display: "block" }}>
        <div
          style={{
            padding: "12px",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong> Add Rewards </strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div>
          <Form.Group className="p-0 m-0">
            <Select
              className="bg-light"
              options={changes}
              onChange={(e) => {
                setState({ ...state, action: e });
              }}
              value={state.action}
            />
          </Form.Group>
          <Form.Label>
            <span style={{ color: "red" }}>*</span>
            {` Enter Amount`}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={`Enter Reward Amount`}
            name="otp"
            className="rounded-0"
            value={state.amount}
            onChange={(e) => {
              setState({ ...state, amount: e.target.value });
            }}
          />
          <Button
            variant="success"
            className="m-1 rounded-0 btn-block"
            onClick={() => {
              addRewards(state.amount, state.action);
            }}
          >
            Add Rewards
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdminRewardsScreen;
