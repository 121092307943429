import React, { useState, useEffect, useContext } from "react";
// import AnlookImage from "../../../assets/images/AnlookImage.png";
import AnlookImage from "../../../assets/images/Anlook.png";
import AnlookLogo from "../../../assets/images/AnlookLogo (1).png";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { useHistory } from "react-router-dom"; 


const AnlookForm = () => {

  const history = useHistory(); 

  const goBack = () => {
    history.goBack(); 
  };

  const { user } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessName: "",
    businessWebsite: "",
    description: "",
    requirements: "anlook",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    // return;
    const { id, value, type, checked } = e.target;
    if (type === "checkbox") {
    //   setFormData({
    //     ...formData,
    //     requirements: { ...formData.requirements, [id]: checked },
    //   });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const refundVerification = () => {
    swal({
      title: "Confirmation Required",
      text: "5000 rupees will be deducted from your wallet. Do you want to proceed?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Decline",
          value: false,
          visible: true,
          className: "cancel-btn",
          closeModal: true,
        },
        confirm: {
          text: "Accept",
          value: true,
          visible: true,
          className: "accept-btn",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willProceed) => {
      if (willProceed) {
        submitAgentLeads();
      } else {
        console.log("Transaction declined.");
      }
    });
  };

  const validateForm = (e) => {
    if (e) e.preventDefault();
    let valid = true;
    let newErrors = {};

    // Validation checks
    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
      valid = false;
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
      valid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    }
    if (!formData.phone) {
      newErrors.phone = "Phone Number is required";
      valid = false;
    }
    if (!formData.businessName) {
      newErrors.businessName = "Business Name is required";
      valid = false;
    }
    if (!formData.businessWebsite) {
      newErrors.businessWebsite = "Business website is required";
      valid = false;
    }
    if (!formData.description) {
      newErrors.description = "description is required";
      valid = false;
    }
    if (!formData.requirements) {
      newErrors.requirements = "Choose the requirements";
      valid = false;
    }

    if (valid) {
      refundVerification();
    } else {
      setErrors(newErrors);
    }
  };

  // const handleSubmit = (e) => {
  // validateForm(e);
  // };

  const [showForm, setShowForm] = useState(false);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const currentStep = 1;

  const SubmitForm = () => {
    swal({
      title: "Are you sure?",
      // text: `Are you sure you want to delete this account?\n\nAccount Holder: ${bank_holder_name}\nBank Name: ${bank_name}\nAccount Number: ${account_number}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("hwlo");

        submitAgentLeads();
      }
    });
  };

  const submitAgentLeads = async () => {
    try {
      // setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        mobileNo: formData.phone,
        businessName: formData.businessName,
        website: formData.businessWebsite,
        description: formData.description,
        requirements: formData.requirements,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/User/submitAgentLeads",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        swal({
          title: "Success!",
          text: data.responseMessage,
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        swal({
          title: "Error!",
          text: data.responseMessage,
          icon: "error",
          confirmButtonText: "OK",
        });
      }

      console.log(data);
    } catch (error) {
      console.error("Error:", error);
      swal({
        title: "Error!",
        text: "Something went wrong. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div>
      <div className="card shadow m-3 p-4" style={{ borderRadius: "10px" }}>
        <div className="row">
          <div>
            <img src={AnlookLogo} style={{ height: "2rem", width: "auto" }} />{" "}
          </div>
        </div>
        <div className="row">
          <div
            className="col-7"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div>
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
              >
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="firstName"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Enter The First Name"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ced4da",
                      borderRadius: "5px",
                    }}
                  />
                  {errors.firstName && (
                    <div style={{ color: "red" }}>{errors.firstName}</div>
                  )}
                </div>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="lastName"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Enter The Last Name"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ced4da",
                      borderRadius: "5px",
                    }}
                  />
                  {errors.lastName && (
                    <div style={{ color: "red" }}>{errors.lastName}</div>
                  )}
                </div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label
                  htmlFor="email"
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter The Email Address"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ced4da",
                    borderRadius: "5px",
                  }}
                />
                {errors.email && (
                  <div style={{ color: "red" }}>{errors.email}</div>
                )}
              </div>

              <div
                style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
              >
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="phone"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Enter Your Phone Number"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ced4da",
                      borderRadius: "5px",
                    }}
                  />
                  {errors.phone && (
                    <div style={{ color: "red" }}>{errors.phone}</div>
                  )}
                </div>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="businessName"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Business Name
                  </label>
                  <input
                    type="text"
                    id="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                    placeholder="Enter Your Business Name"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ced4da",
                      borderRadius: "5px",
                    }}
                  />
                  {errors.businessName && (
                    <div style={{ color: "red" }}>{errors.businessName}</div>
                  )}
                </div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label
                  htmlFor="businessWebsite"
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  App Link / Business Website (OPTIONAL)
                </label>
                <input
                  type="text"
                  id="businessWebsite"
                  value={formData.businessWebsite}
                  onChange={handleChange}
                  placeholder="Enter App Link / Business Website"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ced4da",
                    borderRadius: "5px",
                  }}
                />
                {errors.businessWebsite && (
                  <div style={{ color: "red" }}>{errors.businessWebsite}</div>
                )}
              </div>

              <div style={{ marginBottom: "15px" }}>
                <label
                  htmlFor="description"
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Description
                </label>
                <input
                  type="text"
                  id="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter Your Description"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ced4da",
                    borderRadius: "5px",
                  }}
                />
                {errors.description && (
                  <div style={{ color: "red" }}>{errors.description}</div>
                )}
              </div>

              <label
                htmlFor="requirements"
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Requirements
              </label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    id="websiteApp"
                    checked={formData.requirements.websiteApp}
                    onChange={handleChange}
                    style={{ marginRight: "5px" }}
                  />
                  <label htmlFor="websiteApp"> Website/App</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="orderManagement"
                    checked={formData.requirements.orderManagement}
                    onChange={handleChange}
                    style={{ marginRight: "5px" }}
                  />
                  <label htmlFor="orderManagement"> Order Management</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="marketing"
                    checked={formData.requirements.marketing}
                    onChange={handleChange}
                    style={{ marginRight: "5px" }}
                  />
                  <label htmlFor="marketing"> Marketing</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="campaignManagement"
                    checked={formData.requirements.campaignManagement}
                    onChange={handleChange}
                    style={{ marginRight: "5px" }}
                  />
                  <label htmlFor="campaignManagement">
                    {" "}
                    Campaign Management
                  </label>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <button
                  onClick={() => {
                    validateForm();
                  }}
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Submit
                </button>
                <button
                  className="ml-2"
                  type="reset"
                  style={{
                    backgroundColor: "#6c757d",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Clear
                </button>
                <button
                className="ml-2"
                onClick={goBack} 
                style={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Back
              </button>
              </div>
            </div>
          </div>

          <div
            className="col-5"
            // style={{ padding: "20px" }}
          >
            <img
              src={AnlookImage}
              alt="Marketing"
              style={{
                width: "100%",
                // borderRadius: "10px"
              }}
            />
            <div
              style={{
                backgroundColor: "#F3F5F8",
                marginTop: "15px",
                padding: "15px",
                // borderRadius: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h5 style={{ fontWeight: "bold" }}>
                Effortless Marketing Made Simple!
              </h5>
              <h5 style={{ margin: "10px 0" }}>
                Maximize your WhatsApp and SMS business potential with our
                all-in-one solutions.
              </h5>
              <ul
                style={{
                  paddingLeft: "20px",
                  marginBottom: "20px",
                  fontSize: "1rem",
                }}
              >
                <li>Unlimited outreach with broadcasted offers</li>
                <li>WhatsApp catalogs and instant messaging</li>
                <li>24/7 support with multi-agent live chat</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnlookForm;