import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import AdminLedgerReport from "../admin-retailer-ledger/LedgerReport";
import EditUserDetails from "../edit-user-details/UserDetailsUserView";
import RetailerAEPSReconcileReport from "../retailer-aeps-reconcile-report/RetailerAEPSReconcileReport";
import RetailerAEPSReport from "../retailer-aeps-report/RetailerAEPSReport";
import RetailerBBPSReport from "../retailer-bbps-report/RetailerBBPSReport";
import RetailerDMTReport from "../retailer-dmt-report/RetailerDMTReport";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { Nav } from "react-bootstrap";
import "./custom-tabs.css";
import CmsReport from "../cms-report/CmsReport";
import UserCmsReport from "../UserCms/UserCmsReport";
// import WalletReportTable from "../wallet-to-wallet-report/WalletToWalletReportTable";
import WalletReportTable from "../wallet-to-wallet-report/WalletToWalletReportTable";
// import WalletToWallet from "../WalletToWallet/WalletToWallet";
// import WalletToWallet from "../WalletToWallet/WalletToWalllet";
import WalletToWallet from "../WalletToWallet/WalletToWallet";
import CommercialsSetup from "./CommercialsSetup";
// import CmsReport2 from "../CmsReport2/CmsReport2/CmsReport";

const UserView = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [agentId, setAgentId] = useState("");
  const [referId, setReferId] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [search, setSearch] = useState([]);
  const searchUser = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: agentId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_user_details_create_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        // setReferId(data.data.us_code);
        // setUserInfo(data.data);
      } else {
        swal(
          "User Not Found",
          "Please Enter Valid ID Or Mobile number",
          "error"
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const clearData = async () => {
    setAgentId("");
    setTransactionLoaderState(false);
  };

  // did mount
  useEffect(() => {
    // if (agentId && agentId.length > 4) searchUser();
  }, [agentId]);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [name, setName] = useState("");
  const [submittedData, setSubmittedData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedData({ name });
    setAgentId(name);
  };

  const handleClear = () => {
    setName("");

    setSubmittedData(null);
    setAgentId(null);
  };

  return (
    <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="card mb-3 m-0 p-0">
            <div className="card-body m-0 p-0 pt-1 pb-1 pl-3 pr-2 ">
              <div className="row m-0 p-0 pb-4 pt-3  ">
                <div className="col-2 m-0 p-0 mt-2">
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "32px",
                      fontWeight: "600",
                      lineHeight: "48px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      padding: "0px",
                      background: "#FFFFFF",
                      color: "#4267B2",
                      fontWeight: "bolder",
                      fontfamily: "Poppins !impotent",
                      fontSize: "32px",
                    }}
                  >
                    User View
                  </div>
                </div>

                <div className="col-10 m-0 p-0 pr-1">
                  <div className="row m-0 p-0 d-flex justify-content-end">
                    <div className="col-4 m-0 p-0  mx-2  mt-3 ">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User Code Or Mobile No"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ borderRadius: "5px" }}
                      />
                    </div>

                    <div className="col-2 p-0 m-0 mx-2  mt-3 ">
                      <Button
                        type="submit"
                        className="rounded-50 btn btn-primary btn-lg btn-block"
                      >
                        {" "}
                        Search
                      </Button>
                    </div>

                    <div className="col-2 p-0 m-0 mx-2  mt-3 ">
                      <Button
                        type="button"
                        onClick={handleClear}
                        className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
                      >
                        {" "}
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        {submittedData && (
          <div>
            <div className="card m-0 p-0 pt-3">
              <div className="card-body m-0 p-0">
                <div className="">
                  <div
                    defaultActiveKey="profile"
                    className=" tab-header justify-content-between"
                  >
                    <div
                      className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                      onClick={() => handleTabClick(0)}
                    >
                      Account Settings
                    </div>
                    <div
                      className={`tab-item ${activeTab === 8 ? "active" : ""}`}
                      onClick={() => handleTabClick(8)}
                    >
                      Commercials - Setup 
                    </div>
                    <div
                      className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                      onClick={() => handleTabClick(1)}
                    >
                      Ledger
                    </div>
                    <div
                      className={`tab-item ${activeTab === 2 ? "active" : ""}`}
                      onClick={() => handleTabClick(2)}
                    >
                      DMT Report
                    </div>

                    <div
                      className={`tab-item ${activeTab === 3 ? "active" : ""}`}
                      onClick={() => handleTabClick(3)}
                    >
                      BBPS Report
                    </div>
                    <div
                      className={`tab-item ${activeTab === 6 ? "active" : ""}`}
                      onClick={() => handleTabClick(6)}
                    >
                      CMS Report
                    </div>

                    <div
                      className={`tab-item ${activeTab === 7 ? "active" : ""}`}
                      onClick={() => handleTabClick(7)}
                    >
                      Wallet To Wallet
                    </div>

                    <div
                      className={`tab-item ${activeTab === 4 ? "active" : ""}`}
                      onClick={() => handleTabClick(4)}
                    >
                      AEPS Report
                    </div>

                    <div
                      className={`tab-item ${activeTab === 5 ? "active" : ""}`}
                      onClick={() => handleTabClick(5)}
                    >
                      AEPS Reconcile Report
                    </div>
                  </div>
                  <div className="tab-content">
                    {activeTab === 0 && (
                      <div>
                        {" "}
                        <EditUserDetails agentId={agentId} />{" "}
                      </div>
                    )}
                    {activeTab === 1 && (
                      <div>
                        <AdminLedgerReport agentId={agentId} />
                      </div>
                    )}
                    {activeTab === 2 && (
                      <div>
                        <RetailerDMTReport agentId={agentId} />
                      </div>
                    )}
                    {activeTab === 3 && (
                      <div>
                        {" "}
                        <RetailerBBPSReport agentId={agentId} />{" "}
                      </div>
                    )}
                    {activeTab === 4 && (
                      <div>
                        {" "}
                        <RetailerAEPSReport agentId={agentId} />
                      </div>
                    )}
                    {activeTab === 5 && (
                      <div>
                        {" "}
                        <RetailerAEPSReconcileReport
                          agentId={agentId}
                          //mobile={userInfo.us_loginid}
                        />
                      </div>
                    )}
                    {activeTab === 6 && (
                      <div>
                        {" "}
                        <UserCmsReport agentId={agentId} />
                      </div>
                    )}
                       {activeTab === 7 && (
                      <div>
                        {" "}
                        <WalletToWallet agentId={agentId} />
                      </div>
                    )}
                      {activeTab === 8 && (
                      <div>
                        {" "}
                        <CommercialsSetup agentId={agentId} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserView;