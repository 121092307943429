import React, { useState, useEffect, useContext } from "react";
import cogoToast from "cogo-toast";
import Select from "react-select";
import { GlobalContext } from "./../../Context/GlobalState";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import {
    BASE_URL_AEPS,
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT,
    BASE_URL_API_TRANXT_API,
    BASE_URL_DOC_KYC,
    BASE_URL_PAYNOW,
    COMPANY_CODE,
} from "./../../utils/Constants";
import { createHash, encryptAES128, generateHashKey2, uniqueID } from "./../../utils/utilFuctions";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { basicAuth } from "../../config";

import {
    balanceEnquirySuccessPopup,
    cashWithdrawlSuccessPopup,
    cashWithdrawlSuccessPopup2,
} from "./../../utils/Models";
import "./custom-tabs.css";
var convert = require("xml-js");
const App = ({ credentials }) => {
    const {
        refreshWallet,
        Profileinfo,
        setTransactionLoaderState,
        user: user2,
    } = useContext(GlobalContext);
    const { username, password } = basicAuth;
    const [protocall, setProtocall] = useState("https");
    const user = {
        ReferId: user2?.ReferId,
        shopname: user2?.shop_name,
        image: require("../../../assets/images/company/logo_square.png"),
    };
    const [pageIdx, setPageIdx] = useState(0);

    const [aepsTwoFactorDone, setAepsTwoFactorDone] = useState(false);
    const [isAllowed, setIsAllowed] = useState(false);
    const [twoFactorDone, setTwoFactorDone] = useState(true);
    const [agentKycData, setAgentKycData] = useState({});
    const [kycData, setKycData] = useState({});
    const [activeTab, setActiveTab] = useState("");
    const [sorTxntId, setSorTxntId] = useState("");
    const [biAuthDone, setBiAuthDone] = useState(true);

    const [apTwoFactorDone, setApTwoFactorDone] = useState(true);
    const cwTwoFactorDoneStored = localStorage.getItem("cwTwoFactorDoneStored");
    const [cwTwoFactorDone, setCwTwoFactorDone] = useState(cwTwoFactorDoneStored == "true");
    const [cwFingpayTwoFactorDone, setCwFingpayTwoFactorDone] = useState(false);

    const [ekycState, setEkycState] = useState({
        mobile: "",
        display_mobile: "",
        aadhar: "",
        display_aadhar: "",
        pan: "",
        email: "",
        device: "",
        otp: "",
        primaryKeyId: "",
        encodeFPTxnId: "",
        error: "",
        success: "",
    });
    const [balanceEnquiryState, setBalanceEnquiryState] = useState({
        mobile: "",
        display_mobile: "",
        aadhar: "",
        display_aadhar: "",
        bank: null,
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
        response: null,
        isAllowed: false,
    });
    const [cashWithdrawalState, setCashWithdrawalState] = useState({
        mobile: "",
        display_mobile: "",
        aadhar: "",
        display_aadhar: "",
        bank: null,
        amount: "",
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
    });

    const [twoFactorAuth, setTwoFactorAuth] = useState({
        mobile: "",
        display_mobile: "",
        aadhar: "",
        display_aadhar: "",
        bank: null,
        amount: "",
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
    });
    const [miniStatementState, setMiniStatementState] = useState({
        mobile: "",
        display_mobile: "",
        aadhar: "",
        display_aadhar: "",
        bank: null,
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
    });
    const [adhaarPayState, setAdhaarPayState] = useState({
        mobile: "",
        aadhar: "",
        bank: null,
        amount: "",
        device: "",
        res: null,
        error: "",
        success: "",
        consent: false,
        buttonText: "Submit",
        isLoading: false,
    });
    const [isKycDone, setIsKycDone] = useState(false);
    const [pidData, setPidData] = useState(null);
    const [bankList, setBankList] = useState([]);
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [device, setDevice] = useState(-1);
    const [otpButtonText, setOtpButtonText] = useState("Send OTP");
    const [otpSend, setOtpSend] = useState(false);
    const [otpValidated, setOtpValidated] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        error: "",
        success: "",
    });
    const [devicePort, setDevicePort] = useState("11100");

    const mantraPorts = [
        "11100",
        "11101",
        "11102",
        "11103",
        "11104",
        "11105",
        "11106",
    ];

    const getNewTransactionID = () => {
        return uniqueID()
    }

    const discoverDevice = () => {
        let found = false;

        const protocalls = ["http", "https"];
        // https
        protocalls.forEach((p) => {
            for (let i = 0; i < mantraPorts.length && !found; i++) {
                const currentPort = mantraPorts[i];
                try {
                    discoverDeviceReq(currentPort, p, () => {
                        found = true;
                        setDevicePort(currentPort);
                        getDeviceInfo(currentPort, p);
                        setProtocall(p);
                    });
                } catch (error) { }
            }
        });
    };

    const textStyle = { "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "left" }



    function discoverDeviceReq(port, proto, callback) {
        let url;
        if (device == 0) {
            url = `${proto}://localhost:${port}/rd/info`;
        }
        if (device == 1) {
            url = `${proto}://localhost:${port}/getDeviceInfo`;
        }

        if (device == 2) {
            url = `${proto}://localhost:${port}/rd/info`;
        }

        var xhr;
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer, return version number
            //IE browser
            xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
        } else {
            //other browser
            xhr = new XMLHttpRequest();
        }

        //
        xhr.open("DEVICEINFO", url, true);

        xhr.onreadystatechange = function () {
            // if(xhr.readyState == 1 && count == 0){
            //	fakeCall();
            //}
            if (xhr.readyState == 4) {
                var status = xhr.status;

                if (status == 200) {
                    var result1 = convert.xml2json(xhr.responseText, {
                        compact: true,
                        spaces: 4,
                    });
                    const data1 = JSON.parse(result1);
                    if (
                        data1.DeviceInfo?.additional_info?.Param[0]._attributes.value == ""
                    ) {
                        // alert('device is not ready');
                        //  setSnackbarState({ ...snackbarState, success: "", error: "device is not ready" });
                    } else {
                        // setSnackbarState({ ...snackbarState, success: "device is ready to use", error: "" })
                        // alert('device is ready to use');
                        callback();
                    }
                    // setDeviceInfo(data1);
                } else {
                }
            }
        };

        xhr.send();
    }

    const checkDevice = async () => {
        if (
            !deviceInfo ||
            !deviceInfo.DeviceInfo ||
            !deviceInfo.DeviceInfo.additional_info ||
            deviceInfo.DeviceInfo.additional_info.Param.length == 0
        ) {
            // alert("Please choose device or check device is ready or not");
            setSnackbarState({
                ...snackbarState,
                error: "Please choose device or check device is ready or not",
            });
            return false;
        } else return true;
    };


    function getDeviceInfo(port, proto) {
        let url;
        if (device == 0) {
            url = `${proto}://localhost:${port}/rd/info`;
        }
        if (device == 1) {
            url = `${proto}://localhost:${port}/getDeviceInfo`;
        }
        if (device == 2) {
            url = `${proto}://localhost:${port}/rd/info`;
        }
        var xhr;
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer, return version number
            //IE browser
            xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
        } else {
            //other browser
            xhr = new XMLHttpRequest();
        }

        //
        xhr.open("DEVICEINFO", url, true);

        xhr.onreadystatechange = function () {
            // if(xhr.readyState == 1 && count == 0){
            //	fakeCall();
            //}
            if (xhr.readyState == 4) {
                var status = xhr.status;

                if (status == 200) {
                    var result1 = convert.xml2json(xhr.responseText, {
                        compact: true,
                        spaces: 4,
                    });
                    const data1 = JSON.parse(result1);
                    if (
                        data1.DeviceInfo?.additional_info?.Param[0]._attributes.value == ""
                    ) {
                        // alert('device is not ready');
                        setSnackbarState({
                            ...snackbarState,
                            success: "",
                            error: "device is not ready",
                        });
                    } else {
                        setSnackbarState({
                            ...snackbarState,
                            success: "device is ready to use",
                            error: "",
                        });
                        // alert('device is ready to use');
                    }
                    setDeviceInfo(data1);
                } else {
                }
            }
        };

        xhr.send();
    }

    function morphoCapture(isKyc, callback) {
        var url = `${protocall}://localhost:${devicePort}/capture`;
        let PIDOPTS = "";
        if (isKyc) {
            PIDOPTS =
                '<PidOptions ver="1.0">' +
                '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/>' +
                "</PidOptions>";
        } else {
            PIDOPTS =
                '<PidOptions ver="1.0">' +
                '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="" posh=""/>' +
                "</PidOptions>";
        }

        /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
        var xhr;
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer, return version number
            //IE browser
            xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
        } else {
            //other browser
            xhr = new XMLHttpRequest();
        }

        xhr.open("CAPTURE", url, true);
        xhr.setRequestHeader("Content-Type", "text/xml");
        xhr.setRequestHeader("Accept", "text/xml");

        xhr.onreadystatechange = function () {
            //if(xhr.readyState == 1 && count == 0){
            //	fakeCall();
            //}
            if (xhr.readyState == 4) {
                var status = xhr.status;
                //parser = new DOMParser();
                if (status == 200) {
                    var test1 = xhr.responseText;
                    var test2 = test1.search("errCode");
                    var test6 = getPosition(test1, '"', 2);
                    var test4 = test2 + 9;
                    var test5 = test1.slice(test4, test6);
                    // conversion

                    if (test5 > 0) {
                        // alert(xhr.responseText);
                        setSnackbarState({
                            ...snackbarState,
                            error: xhr.responseText,
                            success: "",
                        });
                        //document.getElementById('text').value = xhr.responseText;
                    } else {
                        setSnackbarState({
                            ...snackbarState,
                            error: "",
                            success: "Biometric captured successfully",
                        });
                        // alert("Captured Successfully");
                        var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
                        setPidData(test1);
                        if (callback) callback(test1);
                        //document.getElementById('text').value = "Captured Successfully";
                    }
                } else {
                }
            }
        };

        xhr.send(PIDOPTS);
    }

    function startekCapture(isKyc, callback) {
        var url = `${protocall}://localhost:${devicePort}/rd/capture`;
        let PIDOPTS = "";
        if (isKyc) {
            PIDOPTS =
                '<PidOptions ver="1.0">' +
                '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" posh=""/>' +
                "</PidOptions>";
        } else {
            PIDOPTS =
                '<PidOptions ver="1.0">' +
                '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" otp="" wadh="" posh=""/>' +
                "</PidOptions>";
        }

        /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
        var xhr;
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer, return version number
            //IE browser
            xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
        } else {
            //other browser
            xhr = new XMLHttpRequest();
        }

        xhr.open("CAPTURE", url, true);
        xhr.setRequestHeader("Content-Type", "text/xml");
        xhr.setRequestHeader("Accept", "text/xml");

        xhr.onreadystatechange = function () {
            //if(xhr.readyState == 1 && count == 0){
            //	fakeCall();
            //}
            if (xhr.readyState == 4) {
                var status = xhr.status;
                //parser = new DOMParser();
                if (status == 200) {
                    var test1 = xhr.responseText;
                    var test2 = test1.search("errCode");
                    var test6 = getPosition(test1, '"', 2);
                    var test4 = test2 + 9;
                    var test5 = test1.slice(test4, test6);
                    // conversion

                    if (test5 > 0) {
                        // alert(xhr.responseText);
                        setSnackbarState({
                            ...snackbarState,
                            error: xhr.responseText,
                            success: "",
                        });
                        //document.getElementById('text').value = xhr.responseText;
                    } else {
                        setSnackbarState({
                            ...snackbarState,
                            error: "",
                            success: "Biometric captured successfully",
                        });
                        // alert("Captured Successfully");
                        var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
                        setPidData(test1);
                        if (callback) callback(test1);
                        //document.getElementById('text').value = "Captured Successfully";
                    }
                } else {
                }
            }
        };

        xhr.send(PIDOPTS);
    }

    function getPosition(string, subString, index) {
        return string.split(subString, index).join(subString).length;
    }

    function mantraCapture(isKyc, callback) {
        var url = `${protocall}://localhost:${devicePort}/rd/capture`;
        let PIDOPTS = "";
        if (isKyc) {
            PIDOPTS =
                '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
        } else {
            PIDOPTS =
                '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" wadh="" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
        }

        /*
            format=\"0\"     --> XML
            format=\"1\"     --> Protobuf
            */
        var xhr;
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer, return version number
            //IE browser
            xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
        } else {
            //other browser
            xhr = new XMLHttpRequest();
        }

        xhr.open("CAPTURE", url, true);
        xhr.setRequestHeader("Content-Type", "text/xml");
        xhr.setRequestHeader("Accept", "text/xml");

        xhr.onreadystatechange = function () {
            //if(xhr.readyState == 1 && count == 0){
            //	fakeCall();
            //}
            if (xhr.readyState == 4) {
                var status = xhr.status;
                //parser = new DOMParser();
                if (status == 200) {
                    var test1 = xhr.responseText;
                    var test2 = test1.search("errCode");
                    var test6 = getPosition(test1, '"', 2);
                    var test4 = test2 + 9;
                    var test5 = test1.slice(test4, test6);
                    // conversion
                    var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
                    setPidData(test1);
                    if (test5 > 0) {
                        setSnackbarState({
                            ...snackbarState,
                            error: xhr.responseText,
                            success: "",
                        });
                        alert(xhr.responseText);
                        //document.getElementById('text').value = xhr.responseText;
                    } else {
                        setSnackbarState({
                            ...snackbarState,
                            error: "",
                            success: "Biometric captured successfully",
                        });
                        // alert("Captured Successfully");
                        var result1 = convert.xml2json(test1, { compact: true, spaces: 4 });
                        setPidData(test1);
                        if (callback) callback(test1);
                        //document.getElementById('text').value = "Captured Successfully";
                    }
                } else {
                }
            }
        };

        xhr.send(PIDOPTS);
    }

    const capture = (isKyc, callback) => {
        if (
            !deviceInfo ||
            !deviceInfo.DeviceInfo ||
            !deviceInfo.DeviceInfo.additional_info ||
            deviceInfo.DeviceInfo.additional_info.Param.length == 0
        ) {
            // alert("Please choose device or check device is ready or not");
            setSnackbarState({
                ...snackbarState,
                error: "Please choose device or check device is ready or not",
                success: "",
            });
        } else if (
            device == 0 &&
            deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
        ) {
            setSnackbarState({ ...snackbarState, error: "", success: "" });
            mantraCapture(isKyc, callback);
        } else if (
            device == 1 &&
            deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
        ) {
            setSnackbarState({ ...snackbarState, error: "", success: "" });
            morphoCapture(isKyc, callback);
        } else if (
            device == 2 &&
            deviceInfo.DeviceInfo.additional_info.Param[0]._attributes.value != ""
        ) {
            setSnackbarState({ ...snackbarState, error: "", success: "" });
            startekCapture(isKyc, callback);
        } else {
            // alert("Please choose device or check device is ready or not");
            setSnackbarState({
                ...snackbarState,
                error: "Please choose device or check device is ready or not",
                success: "",
            });
        }
    };

    const setPrintData = (res, bank, aadhar, amount, service, mobile, tid) => {

        let data = {
            ...res,
            userid: user.ReferId,
            bank: bank,
            device:
                device == 0 ? "Mantra MFS 100" : device == 1 ? "Morpho MSO 1300" : "",
            tid: tid,
            aadhar: aadhar,
            amount: amount,
            service: service,
            mobile,
            shopname: user.shopname,
            image: user.image,
        };
        console.log({ res, bank, aadhar, amount, service, mobile, tid });
        sessionStorage.setItem("print", JSON.stringify(data));
    };


    const balanceEnquiry = async (pidData) => {
        setBalanceEnquiryState({
            ...balanceEnquiryState,
            buttonText: "Submitting ...",
            isLoading: true,
        });
        try {

            setTransactionLoaderState(true)
            const tranxtID = await getNewTransactionID();
            //
            const hash = createHash(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const reqData = {
                "userid": user.ReferId,
                "txnId": tranxtID,
                "orderId": tranxtID,
                "hashKey": "213235523",
                "agentId": "1153",
                "partnerId": "1789",
                "channel": "1",
                "aadharNumber": balanceEnquiryState.aadhar,
                "isVid": false,
                "txnType": "10",
                "bankIIN": balanceEnquiryState.bank.iin,
                "biometricData": pidData,
                "customerConsent": 1,
                "latitude": "19.201432",
                "longitude": "72.9524038",
                "appVersion": "1.0.1",
                "appId": "1",
                "agentName": Profileinfo.name,
                "agentAddress": agentKycData.shopaddress,
                "agentPincode": agentKycData.shoppincode,
                "agentDistrict": agentKycData.shopcity,
                "agentState": agentKycData.shopstate,
                "agentCountry": "IN",
                "deviceType": "MicroATM",
                "deviceMake": device == 1 ? "MORPHO" : "MANTRA",
                "deviceModel": device == 1 ? "MSO1300" : "MFS100",
                "deviceIp": "223.31.145.54",
                "customerName": Profileinfo.name,
                "customerMob": balanceEnquiryState.mobile
            }

            const tokenData = `${user.ReferId}|BE`;

            var raw = JSON.stringify(reqData);


            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/AEPS/yesbank/balanceEnquiry`,
                // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                setIsAllowed(true);
                setBalanceEnquiryState({
                    ...balanceEnquiryState,
                    response: data,
                    isAllowed: true,
                });
                balanceEnquirySuccessPopup(
                    balanceEnquiryState.aadhar,
                    data?.data?.balance,
                    balanceEnquiryState.bank.bankName,
                    tranxtID,
                    balanceEnquiryState.mobile,
                    print
                );

            } else {
                swal("Failed", data.responseMessage, "error");

            }
            setBalanceEnquiryState({
                ...balanceEnquiryState,
                res: data,
                buttonText: "Submit",
                isLoading: false,
            });
            setPrintData(
                data.data,
                balanceEnquiryState.bank.bankName,
                balanceEnquiryState.aadhar,
                0,
                "Balance Enquiry",
                balanceEnquiryState.mobile,
                tranxtID
            );
            // balanceEnquiryUpdate(data);
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
            swal("Failed", "something went wrong", "error");
            setBalanceEnquiryState({
                ...balanceEnquiryState,
                buttonText: "Submit",
                isLoading: false,
            });
        }
    };

    const twoFactorAuthenticationCheck = async () => {
        try {

            setTransactionLoaderState(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const reqData = {
                "userid": user.ReferId,
                "provider": "YES BANK"
            }
            var raw = JSON.stringify(reqData);


            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/AEPS/getTwoFactorStatus`,
                // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                setCwTwoFactorDone(true);
                setPageIdx(1)
            }
            setTransactionLoaderState(false)
        } catch (error) {
            setTransactionLoaderState(false)
            console.log("error", error);
        }
    };


    const twoFactorAuthentication = async (pidData) => {
        localStorage.setItem("agentTwoFactorData", JSON.stringify(twoFactorAuth));
        try {
            const agentTwoFactorData = JSON.parse(localStorage.getItem("agentTwoFactorData"));
            if (!agentTwoFactorData || agentTwoFactorData == null || agentTwoFactorData == undefined || agentTwoFactorData == "") {
                swal("Failed", "Please Do Two Factor Authentication First", "error");
                return;
            }
            setTransactionLoaderState(true)
            const tranxtID = await getNewTransactionID();
            //
            const hash = createHash(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const reqData = {
                "userid": user.ReferId,
                "txnId": tranxtID,
                "hashKey": "68e11af2ffa4",
                "agentId": "1153",
                "partnerId": "1789",
                "aadharNumber": agentTwoFactorData.aadhar,
                "isVid": false,
                "txnType": '10',
                "biometricData": pidData,
                "customerConsent": 1,
                "latitude": "19.201432",
                "longitude": "72.9524038",
                "appVersion": "1.0.1",
                "appId": "1",
                "agentName": Profileinfo.name,
                "agentAddress": agentKycData.shopaddress,
                "agentPincode": agentKycData.shoppincode,
                "agentDistrict": agentKycData.shopcity,
                "agentState": agentKycData.shopstate,
                "agentCountry": "IN",
                "deviceType": "MicroATM",
                "deviceMake": device == 1 ? "MORPHO" : "MANTRA",
                "deviceModel": device == 1 ? "MSO1300" : "MFS100",
                "deviceIp": "223.31.145.54"
            }

            const tokenData = `${user.ReferId}|2FA`;

            var raw = JSON.stringify(reqData);


            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/AEPS/yesbank/twoFactor`,
                // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                swal("Success", "Agent Daily Two Factor is Done", "success");
                //  setBiAuthDone(true);
                setCwTwoFactorDone(true);
                localStorage.setItem("cwTwoFactorDoneStored", 'true');
            } else {
                swal("Failed", data.responseMessage, "error");
            }
            // swal("Success", JSON.stringify(data), "success");
            setTransactionLoaderState(false)
        } catch (error) {
            setTransactionLoaderState(false)
            swal("Failed", JSON.stringify(error), "error");
            console.log("error", error);
        }
    };

    const cashWithdrawal = async (pidData) => {
        setCashWithdrawalState({
            ...cashWithdrawalState,
            buttonText: "Submitting ...",
            isLoading: true,
        });
        try {
            const agentTwoFactorData = JSON.parse(localStorage.getItem("agentTwoFactorData"));
            if (!agentTwoFactorData || agentTwoFactorData == null || agentTwoFactorData == undefined || agentTwoFactorData == "") {
                swal("Failed", "Please Do Two Factor Authentication First", "error");
                return;
            }
            setTransactionLoaderState(true)
            const tranxtID = await getNewTransactionID();
            //
            const hash = createHash(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const reqData = {
                "userid": user.ReferId,
                "txnId": tranxtID,
                "orderId": tranxtID,
                "sorTxnId": sorTxntId,
                "hashKey": "213235523",
                "agentId": "1153",
                "partnerId": "1789",
                "amount": cashWithdrawalState.amount,
                "channel": "1",
                "aadharNumber": cashWithdrawalState.aadhar,
                "isVid": false,
                "txnType": "32",
                "bankIIN": cashWithdrawalState.bank.iin,
                "biometricData": pidData,
                "customerConsent": 1,
                "latitude": "19.201432",
                "longitude": "72.9524038",
                "appVersion": "1.0.1",
                "appId": "1",
                "agentName": Profileinfo.name,
                "agentAddress": agentKycData.shopaddress,
                "agentPincode": agentKycData.shoppincode,
                "agentDistrict": agentKycData.shopcity,
                "agentState": agentKycData.shopstate,
                "agentCountry": "IN",
                "deviceType": "MicroATM",
                "deviceMake": device == 1 ? "MORPHO" : "MANTRA",
                "deviceModel": device == 1 ? "MSO1300" : "MFS100",
                "deviceIp": "223.31.145.54",
                "customerName": Profileinfo.name,
                "customerMob": cashWithdrawalState.mobile
            }

            const tokenData = `${user.ReferId}|CW`;

            var raw = JSON.stringify(reqData);


            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/AEPS/yesbank/cashWithdrawl`,
                // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                console.log(data);
                cashWithdrawlSuccessPopup2(
                    cashWithdrawalState.aadhar,
                    cashWithdrawalState.amount,
                    cashWithdrawalState.bank.bank_name,
                    data.data?.referenceNo,
                    cashWithdrawalState.mobile,
                    data?.data?.balanceAmount,
                    print,
                    () => {
                        capture(false, miniStatement);
                    }
                );
            } else {
                swal("Failed", data.responseMessage, "error");
            }
            setCashWithdrawalState({
                ...cashWithdrawalState,
                res: data,
                buttonText: "Submit",
                isLoading: false,
            });

            setPrintData(
                res.data,
                cashWithdrawalState.bank.bankName,
                cashWithdrawalState.aadhar,
                cashWithdrawalState.amount,
                "Cash Withdrawal",
                cashWithdrawalState.mobile,
                tranxtID
            );
            refreshWallet();
            setBiAuthDone(false);
            // cashWithdrawalUpdate(data);
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
            swal("Failed", "something went wrong", "error");
            setCashWithdrawalState({
                ...cashWithdrawalState,
                buttonText: "Submit",
                isLoading: false,
            });
        }
    };

    const createEncryptedRequestBody = (reqData, tokenData) => {

        const data = encryptAES128(JSON.stringify(reqData))
        const token = encryptAES128(tokenData)

        return { data, token }
    }


    const twoFactorAuthenticationCW = async (pidData) => {
        try {
            const agentTwoFactorData = JSON.parse(localStorage.getItem("agentTwoFactorData"));
            if (!agentTwoFactorData || agentTwoFactorData == null || agentTwoFactorData == undefined || agentTwoFactorData == "") {
                swal("Failed", "Please Do Two Factor Authentication First", "error");
                return;
            }
            setTransactionLoaderState(true)
            const tranxtID = await getNewTransactionID();
            //
            const hash = createHash(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const reqData = {
                "userid": user.ReferId,
                "txnId": tranxtID,
                "hashKey": "68e11af2ffa4",
                "agentId": "1153",
                "partnerId": "1789",
                "aadharNumber": agentTwoFactorData.aadhar,
                "isVid": false,
                "txnType": '32',
                "biometricData": pidData,
                "customerConsent": 1,
                "latitude": "19.201432",
                "longitude": "72.9524038",
                "appVersion": "1.0.1",
                "appId": "1",
                "agentName": Profileinfo.name,
                "agentAddress": agentKycData.shopaddress,
                "agentPincode": agentKycData.shoppincode,
                "agentDistrict": agentKycData.shopcity,
                "agentState": agentKycData.shopstate,
                "agentCountry": "IN",
                "deviceType": "MicroATM",
                "deviceMake": device == 1 ? "MORPHO" : "MANTRA",
                "deviceModel": device == 1 ? "MSO1300" : "MFS100",
                "deviceIp": "223.31.145.54"
            }

            const tokenData = `${user.ReferId}|2FA`;

            var raw = JSON.stringify(reqData);


            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/AEPS/yesbank/agentBiAuth`,
                // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                swal("Success", "Agent Bi Auth Done For Cash Withdrawl , Please Capture Customer Biometric", "success");
                setSorTxntId(data?.data?.sorTxnId)
                setBiAuthDone(true);
            } else {
                swal("Failed", data.responseMessage, "error");
            }
            // swal("Success", JSON.stringify(data), "success");
            setTransactionLoaderState(false)
        } catch (error) {
            setTransactionLoaderState(false)
            swal("Failed", JSON.stringify(error), "error");
            console.log("error", error);
        }
    };

    const printMiniStatement = (statement, balance) => {
        swal2(
            <React.Fragment>

                <table
                className="my-table transparent-table"
                    style={{
                        width: "100%",
                        height: "40vh",
                        overflowY: "scroll",

                        // border: "1px solid #979797",
                    }}
                >
                    <tr>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0px' }}>
                            <img src={require('../../../assets/images/company/logo_square.png')} style={{ width: "10rem", height: "auto" }} />
                            <img src={require("../../../assets/images/yes_bank_Logo.png")} style={{ width: "10rem", height: "auto" }} />
                        </div>
                    </tr>
                    {statement.map((d, i) => {
                        return (
                            <tr key={i} style={{
                                width: "100%",
                                // border: "1px solid #979797",
                                background: "none",
                            }}>
                                <td style={{ textAlign: "center", }}>{d}</td>
                            </tr>
                        );
                    }
                    )}
                    <tr style={{
                        width: "100%",
                        border: "none",
                        background: "none",
                    }}>
                        <td style={{ textAlign: "right", border: "none" }}>

                            <strong>
                                {`Balance : ${balance}`}
                            </strong>
                        </td>
                    </tr>
                </table>
            </React.Fragment>
        );
    }


    const miniStatement = async (pidData) => {

        try {

            setTransactionLoaderState(true)
            const tranxtID = await getNewTransactionID();
            //
            const hash = createHash(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const reqData = {
                "userid": user.ReferId,
                "txnId": tranxtID,
                "orderId": tranxtID,
                "hashKey": "213235523",
                "agentId": "1153",
                "partnerId": "1789",
                "channel": "1",
                "aadharNumber": miniStatementState.aadhar,
                "isVid": false,
                "txnType": "10",
                "bankIIN": miniStatementState.bank.iin,
                "biometricData": pidData,
                "customerConsent": 1,
                "latitude": "19.201432",
                "longitude": "72.9524038",
                "appVersion": "1.0.1",
                "appId": "1",
                "agentName": Profileinfo.name,
                "agentAddress": agentKycData.shopaddress,
                "agentPincode": agentKycData.shoppincode,
                "agentDistrict": agentKycData.shopcity,
                "agentState": agentKycData.shopstate,
                "agentCountry": "IN",
                "deviceType": "MicroATM",
                "deviceMake": device == 1 ? "MORPHO" : "MANTRA",
                "deviceModel": device == 1 ? "MSO1300" : "MFS100",
                "deviceIp": "223.31.145.54",
                "customerName": Profileinfo.name,
                "customerMob": miniStatementState.mobile
            }

            const tokenData = `${user.ReferId}|MS`;

            var raw = JSON.stringify(reqData);


            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/AEPS/yesbank/miniStatement`,
                // "https://bumppy.com/apibumppypay/v1/web/fingpay/twofactor.php?device=123456",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                if (data?.miniStatementFormat1?.length > 0) {
                    let ministate = data?.miniStatementFormat1?.filter(item => item.length > 2);

                    printMiniStatement(ministate.slice(0, -1), ministate.slice(-1));
                }

            } else {
                swal("Failed", data.responseMessage, "error");
            }
            setCashWithdrawalState({
                ...cashWithdrawalState,
                res: data,
                buttonText: "Submit",
                isLoading: false,
            });
            setPrintData(
                res.data,
                cashWithdrawalState.bank.bankName,
                cashWithdrawalState.aadhar,
                cashWithdrawalState.amount,
                "Cash Withdrawal",
                cashWithdrawalState.mobile,
                tranxtID
            );
            refreshWallet();
            setBiAuthDone(false);
            // cashWithdrawalUpdate(data);
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            // console.log('error', error);
            setMiniStatementState({
                ...miniStatementState,
                isLoading: false,
                buttonText: "Submit",
            });
            swal("Failed", "something went wrong", "error");
        }
    };

    const getAepsBankList = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            var raw = JSON.stringify({
                userid: user.ReferId
            });


            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(`https://apiservices.bumppypay.com/api/AEPS/yesbank/getBankList`, requestOptions);
            const body = await res.text();
            const data = JSON.parse(body);
            if (data.data && data.data.length > 0) {

                setBankList(
                    data.data.map((b) => {
                        return { ...b, label: `${b.bank_name} [${b.bank_code}]`, value: b.bankName };
                    })
                );
            }

        } catch (error) {
            console.log("error", error);
        }
    };


    const CLIENT = "";
    const print = () => {
        const newWindow = window.open(
            `${CLIENT}/pages/aeps/yes/print`,
            "",
            "height=600,width=800"
        );
        if (newWindow) newWindow.opener = null;
    };

    const balanceEnquirySubmit = () => {
        if (balanceEnquiryState.mobile.length != 10) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid mobile number",
            });
        } else if (balanceEnquiryState.aadhar.length != 12) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid aadhar number number",
            });
        } else if (balanceEnquiryState.bank == null) {
            setSnackbarState({ ...snackbarState, error: "please enter your bank" });
        } else if (!balanceEnquiryState.consent) {
            setSnackbarState({
                ...snackbarState,
                error: "please agree Terms And Conditions",
            });
        } else if (pidData == null) {
            setSnackbarState({
                ...snackbarState,
                error: "please capture your biometric",
            });
        } else {
            balanceEnquiry(pidData);
        }
    };
    const twoFactorAuthSubmit = (type) => {
        if (pidData == null) {
            setSnackbarState({
                ...snackbarState,
                error: "please capture your biometric",
            });
        } else {

            twoFactorAuthentication(pidData)


        }
    };

    const twoFactorAuthSubmit2 = (type) => {
        if (twoFactorAuth.mobile.length != 10) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid mobile number",
            });
        } else if (twoFactorAuth.aadhar.length != 12) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid aadhar number number",
            });
        } else if (twoFactorAuth.bank == null) {
            setSnackbarState({ ...snackbarState, error: "please enter your bank" });
        } else if (pidData == null) {
            setSnackbarState({
                ...snackbarState,
                error: "please capture your biometric",
            });
        } else {
        }
    };

    const cashWithdrawalSubmit = () => {
        if (cashWithdrawalState.mobile.length != 10) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid mobile number",
            });
        } else if (cashWithdrawalState.aadhar.length != 12) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid aadhar number number",
            });
        } else if (cashWithdrawalState.bank == null) {
            setSnackbarState({ ...snackbarState, error: "please enter your bank" });
        } else if (!cashWithdrawalState.consent) {
            setSnackbarState({
                ...snackbarState,
                error: "please agree Terms And Conditions",
            });
        } else if (pidData == null) {
            setSnackbarState({
                ...snackbarState,
                error: "please capture your biometric",
            });
        } else if (cashWithdrawalState.amount == "") {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid amount",
            });
        } else if (Number(cashWithdrawalState.amount) % 50 != 0) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter amount in multiple of 50",
            });
        } else {
            if (biAuthDone) {
                cashWithdrawal(pidData);
            } else {
                twoFactorAuthenticationCW(pidData);
            }

        }
    };
    const miniStatementSubmit = () => {
        if (miniStatementState.mobile.length != 10) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid mobile number",
            });
        } else if (miniStatementState.aadhar.length != 12) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid aadhar number number",
            });
        } else if (miniStatementState.bank == null) {
            setSnackbarState({ ...snackbarState, error: "please enter your bank" });
        } else if (!miniStatementState.consent) {
            setSnackbarState({
                ...snackbarState,
                error: "please agree Terms And Conditions",
            });
        } else if (pidData == null) {
            setSnackbarState({
                ...snackbarState,
                error: "please capture your biometric",
            });
        } else {
            miniStatement(pidData);
        }
    };
    const adhaarPaySubmit = () => {
        if (adhaarPayState.mobile.length != 10) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid mobile number",
            });
        } else if (adhaarPayState.aadhar.length != 12) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid aadhar number number",
            });
        } else if (adhaarPayState.bank == null) {
            setSnackbarState({ ...snackbarState, error: "please enter your bank" });
        } else if (!adhaarPayState.consent) {
            setSnackbarState({
                ...snackbarState,
                error: "please agree Terms And Conditions",
            });
        } else if (pidData == null) {
            setSnackbarState({
                ...snackbarState,
                error: "please capture your biometric",
            });
        } else if (adhaarPayState.amount == "") {
            setSnackbarState({
                ...snackbarState,
                error: "please enter valid amount",
            });
        } else if (Number(adhaarPayState.amount) % 50 != 0) {
            setSnackbarState({
                ...snackbarState,
                error: "please enter amount in multiple of 50",
            });
        } else {
            if (biAuthDone) {
                // adhaarPay(pidData);
            } else {
                // twoFactorAuthenticationAP(pidData);
            }

        }
    };

    const EkycSubmit = async () => {
        if (!otpValidated) {
            setSnackbarState({
                ...snackbarState,
                error: "please validate OTP",
            });
        } else if (pidData == null) {
            setSnackbarState({
                ...snackbarState,
                error: "please capture your biometric",
            });
        } else {
            //   ekycBiometric(pidData);
        }
    };

    function formatAadhaarNumber(aadharNumber) {
        const length = aadharNumber.length;
        if (length <= 4) {
            return aadharNumber; // Return as it is if length <= 4
        } else {
            // Mask all characters except the last 4 digits
            return '*'.repeat(length - 4) + aadharNumber.substring(length - 4);
        }
    }

    useEffect(() => {
        getAepsBankList();
        setIsKycDone(true);
        setPageIdx(6);
        twoFactorAuthenticationCheck();
    }, []);

    useEffect(() => {
        setSnackbarState({ error: "", success: "" });
        // console.log(deviceInfo);
        if (device !== -1) discoverDevice();
    }, [device]);

    useEffect(() => {
        const timer = setTimeout(() => {
            //  showModal();
           //  cashWithdrawlSuccessPopup2("602949137215","150","Bank of Maharastra","12345678544567898643","9168805979")
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setIsAllowed(false);
        if (pageIdx == 1) {
            setBalanceEnquiryState({
                mobile: "",
                aadhar: "",
                bank: null,
                device: "",
                res: null,
                error: "",
                success: "",
                consent: false,
                buttonText: "Submit",
                isLoading: false,
                response: null,
                isAllowed: false,
            });
        } else if (pageIdx == 2) {
            setCashWithdrawalState({
                mobile: "",
                aadhar: "",
                bank: null,
                device: "",
                res: null,
                error: "",
                success: "",
                consent: false,
                buttonText: "Submit",
                isLoading: false,
            });
        } else if (pageIdx == 3) {
            setMiniStatementState({
                mobile: "",
                aadhar: "",
                bank: null,
                device: "",
                res: null,
                error: "",
                success: "",
                consent: false,
                buttonText: "Submit",
                isLoading: false,
            });
        } else if (pageIdx == 0) {
            setEkycState({
                mobile: "",
                aadhar: "",
                pan: "",
                email: "",
                device: "",
                otp: "",
                primaryKeyId: "",
                encodeFPTxnId: "",
                error: "",
                success: "",
            });
        } else if (pageIdx == 4) {
            setAdhaarPayState({
                mobile: "",
                aadhar: "",
                bank: null,
                amount: "",
                device: "",
                res: null,
                error: "",
                success: "",
                consent: false,
                buttonText: "Submit",
                isLoading: false,
            });
        }
        setSnackbarState({ error: "", success: "" });
        setDevice(-1);
        setPidData(null);
    }, [pageIdx]);


    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {

        setActiveTab(pageIdx);

    }, []);

    let mini = [
        "240511DR UPI/DR/4132166000000120000",
        "240510DR UPI/DR/4131309000000005000",
        "240510DR UPI/DR/4497260000000100000",
        "240510DR UPI/DR/4131685000000016000",
        "240510DR UPI/DR/4131732000000003000",
        "85.00",
        ""
    ];

    // printMiniStatement(mini, 85.00);


    return (
        <div className="p-4 bg-light">
            <div>
                <h3 className="text-dark text-center d-flex justify-content-start p-3 font-weight-bold" style={{ fontFamily: "poppins" }}>
                    {pageIdx === 0
                        ? "Complete your E-KYC"
                        : pageIdx === 1
                            ? "Balance Enquiry"
                            : pageIdx === 2
                                ? "Cash Withdrawal"
                                : pageIdx === 3
                                    ? "Mini Statement"
                                    : pageIdx === 4
                                        ? "Aadhaar pay"
                                        : pageIdx === 5
                                            ? "Reports"
                                            : pageIdx === 6
                                                ? "Daily Two Fctor Authentication"
                                                : pageIdx === 7
                                                    ? "Two Fctor Authentication For AadhaarPay"
                                                    : ""}
                </h3>
            </div>
            <div
                className="m-0 p-0 pt-4 row bg-white"
                style={{ borderRadius: "10px" }}
            >
                <div className="col-3 justify-content-start m-0 p-0 ">
                    <div className="card m-0 p-0 border-white">
                        <div className="card-body m-0 p-0 ">
                            <div

                                className=" tab1-header justify-content-between"
                            >
                                <div
                                    className={`tab1-item ${pageIdx === 0 ? "active" : ""}`}
                                >
                                    <button
                                        onClick={() => {
                                            if (!isKycDone) {
                                                setPageIdx(0);
                                                handleTabClick(0);
                                            }
                                        }}
                                        disabled={isKycDone}
                                        className=" btn button text-primary"
                                    >
                                        E-KYC
                                    </button>

                                </div>

                                <div
                                    className={`tab1-item ${pageIdx === 6 ? "active" : ""}`}
                                >
                                    <button
                                        onClick={() => {
                                            setPageIdx(6);
                                            handleTabClick(1);
                                        }}
                                        // disabled={twoFactorDone}
                                        className=" btn button text-primary"
                                    >
                                        {cwTwoFactorDone ? (
                                            <div className="">

                                                <img className="mr-2"
                                                    src={require("../../../assets/images/success_tick.png")}
                                                    style={{ width: `1rem`, height: '100%' }}
                                                />

                                                Daily Two Factor</div>

                                        ) : (

                                            <div className="">

                                                <img className="mr-2"
                                                    src={require("../../../assets/images/tick_circle_gray.png")}
                                                    style={{ width: `1rem`, height: '100%' }}
                                                />

                                                Daily Two Factor</div>)
                                        }

                                    </button>
                                </div>

                                {/* <div
                                    className={`tab1-item ${pageIdx === 7 ? "active" : ""}`}
                                >
                                    <button
                                        onClick={() => {
                                            setPageIdx(7);
                                            handleTabClick(2);
                                        }}
                                        // disabled={twoFactorDone}
                                        className=" btn button text-primary"
                                    >

                                        {apTwoFactorDone ? (
                                            <div className="">

                                                <img className="mr-2"
                                                    src={require("../../../assets/images/success_tick.png")}
                                                    style={{ width: `1rem`, height: '100%' }}
                                                />

                                                Two Factor for Aadhaar Pay</div>

                                        ) : (

                                            <div className="">

                                                <img className="mr-1 text-center"
                                                    src={require("../../../assets/images/tick_circle_gray.png")}
                                                    style={{ width: `1rem`, height: '100%' }}
                                                />

                                                Two Factor for Aadhaar Pay</div>)
                                        }
                                    </button>
                                </div> */}

                                <div
                                    className={`tab1-item ${pageIdx === 1 ? "active" : ""}`}
                                >
                                    <button
                                        onClick={() => {
                                            setPageIdx(1);
                                            handleTabClick(3);
                                        }}
                                        disabled={!isKycDone || !cwTwoFactorDone}
                                        className=" btn button text-primary"
                                    >
                                        Balance Enquiry
                                    </button>

                                </div>

                                <div
                                    className={`tab1-item ${pageIdx === 2 ? "active" : ""}`}
                                >
                                    <button
                                        onClick={() => {
                                            setPageIdx(2);
                                            handleTabClick(4);
                                        }}
                                        disabled={!isKycDone || !cwTwoFactorDone}
                                        className=" btn button text-primary"
                                    >
                                        Cash Withdrawal
                                    </button>
                                </div>

                                <div
                                    className={`tab1-item ${pageIdx === 3 ? "active" : ""}`}
                                >

                                    <button
                                        onClick={() => {
                                            setPageIdx(3);
                                            handleTabClick(5);
                                        }}
                                        disabled={!isKycDone || !cwTwoFactorDone}
                                        className=" btn button text-primary"
                                    >
                                        Mini Statement
                                    </button>


                                </div>

                                {/* <div className={`tab1-item ${pageIdx === 4 ? "active" : ""}`}>
                                    <button
                                        onClick={() => {
                                            setPageIdx(4);
                                            handleTabClick(6);
                                        }}
                                        className=" btn button text-primary"
                                        disabled={!isKycDone || !apTwoFactorDone}
                                    >
                                        Aadhaar Pay
                                    </button>
                                </div>
                                <div className={`tab1-item ${pageIdx === 5 ? "active" : ""}`}>
                                    <button
                                        onClick={() => {
                                            setPageIdx(5);
                                            handleTabClick(7);
                                        }}
                                        className=" btn button text-primary"
                                        disabled={!isKycDone}
                                    >
                                        Reports
                                    </button>
                                </div> */}
                            </div>
                            <div className="tab1-content">
                                {pageIdx === 0 && <div></div>}
                                {pageIdx === 1 && <div></div>}
                                {pageIdx === 2 && <div></div>}
                                {pageIdx === 3 && <div></div>}
                                {pageIdx === 4 && <div></div>}
                                {pageIdx === 5 && <div></div>}
                                {pageIdx === 6 && <div></div>}
                                {pageIdx === 7 && <div></div>}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-9">
                    <div>
                        {/* <div className="d-flex justify-content-center mt-2">
            <button
              onClick={() => {
                if (!isKycDone) setPageIdx(0);
              }}
              style={{
                background: pageIdx === 0 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={isKycDone}
            >
              {" "}
              KYC
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(6)}
              style={{
                background: pageIdx === 6 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "15rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              // disabled={twoFactorDone}
            >
              {" "}
              Two Factor
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(7)}
              style={{
                background: pageIdx === 7 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "15rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              // disabled={twoFactorDone}
            >
              {" "}
              Two Factor for AadhaarPay
            </button>
          </div>

          <div className="d-flex justify-content-center mt-2">
            <button
              onClick={() => setPageIdx(1)}
              style={{
                background: pageIdx === 1 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "15rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone || !twoFactorDone}
            >
              {" "}
              Balance Enquiry
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(2)}
              style={{
                background: pageIdx === 2 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone || !twoFactorDone}
            >
              {" "}
              Cash Withdrawal
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(3)}
              style={{
                background: pageIdx === 3 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone || !twoFactorDone}
            >
              Mini Statement
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(4)}
              style={{
                background: pageIdx === 4 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className=" btn "
              disabled={!isKycDone || !twoFactorDone}
            >
              Adhaar Pay
            </button>
            <div style={{ width: "6px" }} />
            <button
              onClick={() => setPageIdx(5)}
              style={{
                background: pageIdx === 5 ? "#198754" : "#277BC0",
                color: "white",
                minWidth: "10rem",
              }}
              className="rounded-pill btn btn-sm mx-1 fw-bold"
              disabled={!isKycDone}
            >
              Reports
            </button>
          </div> */}
                    </div>
                    {pageIdx === 0 && (
                        <React.Fragment>
                            <div className=" bg-white m-3" >
                                <Col sm={8} md={8} lg={7} className="form-group rounded-0  p-2" style={textStyle}>
                                    <label>
                                        <strong>Mobile No</strong>
                                    </label>
                                    <input
                                        maxLength="10"
                                        //  style={{ width: "154%" }}
                                        placeholder="Ente Mobile Number"
                                        type="text"
                                        className="form-control  rounded-100"

                                        value={ekycState.mobile}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setEkycState({ ...ekycState, mobile: e.target.value });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-0 col-4 p-2" style={textStyle}>
                                    <label>
                                        <strong>Aadhaar No</strong>
                                    </label>
                                    <input
                                        maxLength="12"
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Aadhaar Number"
                                        type="text"
                                        className="form-control  rounded-100"
                                        value={ekycState.aadhar}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setEkycState({ ...ekycState, aadhar: e.target.value });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-0 col-4 p-2" style={textStyle}>
                                    <label>
                                        <strong>Pan Card Number</strong>
                                    </label>
                                    <input
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Pan Card No"
                                        type="text"
                                        className="form-control  rounded-100"
                                        value={ekycState.pan}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setEkycState({ ...ekycState, pan: e.target.value });
                                        }}
                                    />
                                </Col>


                                <Col sm={8} md={8} lg={7} className="form-group rounded-0  p-2" style={textStyle}>
                                    <label>
                                        <strong>Email ID</strong>
                                    </label>
                                    <input
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Email ID"
                                        type="text"
                                        className="form-control  rounded-100"
                                        value={ekycState.email}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setEkycState({ ...ekycState, email: e.target.value });
                                        }}
                                    />
                                </Col>




                            </div>
                            <div className="form-group rounded-0  mx-3 p-2 " style={textStyle}>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100 pl-0 pr-1 " style={textStyle}>
                                    {otpSend && (

                                        <input
                                            //style={{ width: "150%" }}
                                            placeholder="Enter OTP"
                                            type="text"
                                            className="form-control  rounded-100"
                                            value={ekycState.otp}
                                            onChange={(e) => {
                                                setSnackbarState({
                                                    ...snackbarState,
                                                    error: "",
                                                    success: "",
                                                });
                                                setEkycState({ ...ekycState, otp: e.target.value });
                                            }}
                                        />
                                    )}
                                </Col>
                                <button
                                    style={{ background: "#277BC0", color: "white", width: "30%" }}
                                    className="btn btn-primary btn-block  "
                                    onClick={() => {
                                        if (!otpSend) {
                                            //  sendOTPForEKyc();
                                        } else {
                                            //   validateOTPForEKyc();
                                        }
                                    }}
                                >
                                    {otpButtonText}
                                </button>
                            </div>


                            {otpValidated && (
                                <React.Fragment>
                                    <Col sm={8} md={8} lg={7} className="form-group rounded-100   mx-2" style={textStyle}>
                                        <div >
                                            <label>
                                                <strong>Choose Biometric Device</strong>
                                            </label>
                                            <select
                                                //style={{ width: "154%" }}
                                                className="form-control  rounded-100 border-secondary"
                                                onChange={(e) => {
                                                    setSnackbarState({
                                                        ...snackbarState,
                                                        error: "",
                                                        success: "",
                                                    });
                                                    setDevice(e.target.value);
                                                    setEkycState({
                                                        ...ekycState,
                                                        device: e.target.value,
                                                    });
                                                }}
                                            >
                                                <option>--Please Select--</option>
                                                <option value={1}>Morpho MSO 1300</option>
                                                <option value={0}>Mantra MFS 100</option>
                                                <option value={2}>Startek FM220U</option>
                                                <option disabled={true}>Precision</option>
                                            </select>
                                        </div>


                                    </Col>

                                    <div className="form-group rounded-0 col p-2 mx-3" style={textStyle}>

                                        <button
                                            style={{ background: "#277BC0", color: "white", width: "30%" }}
                                            className="btn text-primary  bg-white border-primary btn-block ml-2 "
                                            onClick={() => {
                                                capture(true);
                                            }}
                                        >
                                            {"Capture Fingerprint"}
                                        </button>
                                    </div>

                                    <div className="form-group col-8 px-2 m-4"
                                        style={{ fontSize: "12px" }}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="flexCheckChecked"
                                            />
                                            <label className="form-check-label text-dark fw-bold">
                                                {"* I Agree"}
                                            </label>
                                        </div>
                                        <label className="form-check-label text-danger fw-bold">
                                            <span className="mr-2">1. Agent Consent Clause T&C </span>
                                            <span>2. Customer Consent Clause T&C</span>
                                        </label>


                                    </div>
                                    <div className="form-group rounded-0 col px-2 mx-3" style={textStyle}>
                                        {/* <button
                      style={{ background: "#277BC0", color: "white" }}
                      className="btn fw-bold btn-sm m-1"
                    >
                      {"Cancel"}
                    </button> */}
                                        <div style={{ width: "6px" }} />
                                        <button
                                            style={{ background: "#277BC0", color: "white", width: "30%" }}
                                            className="btn btn-primary btn-block ml-2 "
                                            onClick={EkycSubmit}
                                        >
                                            {"Submit"}
                                        </button>
                                    </div>



                                </React.Fragment>
                            )}

                            {snackbarState.success != "" && (
                                <div className="alert alert-success my-2" role="alert">
                                    {snackbarState.success}
                                </div>
                            )}
                            {snackbarState.error != "" && (
                                <div className="alert alert-danger my-2" role="alert">
                                    {snackbarState.error}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {pageIdx === 1 && (
                        <React.Fragment>
                            <div className=" bg-white m-3">
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Mobile No</strong>
                                    </label>
                                    <input
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Mobile Number"
                                        type="text"
                                        maxLength="10" // Limit input to 10 characters

                                        required // Make it a required field
                                        className="form-control rounded-100"
                                        value={balanceEnquiryState.mobile}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setBalanceEnquiryState({
                                                ...balanceEnquiryState,
                                                mobile: e.target.value,
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                mobile: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100 p-2" style={textStyle}>
                                    <label>
                                        <strong>Bank Name</strong>
                                    </label>
                                    <Select
                                        className="rounded-100"
                                        options={bankList}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setBalanceEnquiryState({
                                                ...balanceEnquiryState,
                                                bank: e,
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                bank: e,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Aadhaar No</strong>
                                    </label>
                                    <input
                                        maxLength="14" // Limit input to 12 characters

                                        required // Make it a required field
                                        //   style={{ width: "154%" }}
                                        placeholder="Enter Aadhaar Number"
                                        type="text"
                                        className="form-control rounded-100"
                                        value={balanceEnquiryState.aadhar}
                                        onChange={(e) => {

                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setBalanceEnquiryState({
                                                ...balanceEnquiryState,
                                                aadhar: e.target.value,
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                aadhar: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>
                            </div>
                            <Col sm={8} md={8} lg={7} className="form-group rounded-100   mx-2" style={textStyle}>
                                <div >
                                    <label>
                                        <strong>Choose Biometric Device</strong>
                                    </label>
                                    <select
                                        className="form-control  rounded-100 border-secondary"
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setBalanceEnquiryState({
                                                ...balanceEnquiryState,
                                                device: e.target.value,
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                device: e.target.value,
                                            });
                                            setDevice(e.target.value);
                                        }}
                                    >
                                        <option>--Please Select--</option>
                                        <option value={1}>Morpho MSO 1300</option>
                                        <option value={0}>Mantra MFS 100</option>
                                        <option value={2}>Startek FM220U</option>
                                        <option value={3}>Precision</option>
                                    </select>
                                </div>






                            </Col>
                            <div className="form-group rounded-100 col p-2 mx-3" style={textStyle}>
                                <div className="">
                                    <button
                                        style={{ background: "#277BC0", color: "white", width: "30%" }}
                                        className="btn text-primary  bg-white border-primary btn-block ml-2 "
                                        onClick={() => {
                                            capture(false);
                                        }}
                                    >
                                        {"Capture Fingerprint"}
                                    </button>
                                </div>


                                {balanceEnquiryState.res && (
                                    <div className="col-8">
                                        <div
                                            onClick={() => {
                                                if (balanceEnquiryState.res) {
                                                    print();
                                                }
                                            }}
                                            className="btn fw-bold btn-sm m-4"
                                        >
                                            <img
                                                src={require("./../../../assets/images/download.png")}
                                                style={{
                                                    height: "1rem",
                                                    width: "1rem",
                                                    marginRight: "2px",
                                                }}
                                            />
                                            {"Print Receipt"}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {snackbarState.success != "" && (
                                <div className="alert alert-success my-2" role="alert">
                                    {snackbarState.success}
                                </div>
                            )}
                            {snackbarState.error != "" && (
                                <div className="alert alert-danger my-2" role="alert">
                                    {snackbarState.error}
                                </div>
                            )}

                            <div className="form-group col-8 px-2 m-4"
                                style={{ fontSize: "12px" }}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        onChange={() => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setBalanceEnquiryState({
                                                ...balanceEnquiryState,
                                                consent: !balanceEnquiryState.consent,
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                consent: !cashWithdrawalState.consent,
                                            });
                                        }}
                                    />
                                    <label className="form-check-label text-dark fw-bold">
                                        {"* I Agree"}
                                    </label>
                                </div>
                                <label className="form-check-label text-danger fw-bold">
                                    <span className="mr-2">1. Agent Consent Clause T&C </span>
                                    <span>2. Customer Consent Clause T&C</span>
                                </label>


                            </div>


                            <div className="form-group rounded-0 col px-2 mx-3" style={textStyle}>
                                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                                <div style={{ width: "6px" }} />
                                <button
                                    style={{ background: "#277BC0", color: "white", width: "30%" }}
                                    className="btn btn-primary btn-block ml-2 "
                                    onClick={balanceEnquirySubmit}
                                    disabled={balanceEnquiryState.isLoading}
                                >
                                    {balanceEnquiryState.buttonText}
                                </button>
                            </div>




                            {/* CASH WITHDRAWAL */}
                            <div className="m-3">
                                {isAllowed && (
                                    <React.Fragment>
                                        <hr></hr>

                                        <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                            <strong>{`Cash Withdrawal Available Balance : ${balanceEnquiryState.res
                                                ? balanceEnquiryState.res.data
                                                    ? balanceEnquiryState.res.data.balanceAmount
                                                    : "0"
                                                : "0"
                                                }`}</strong>
                                        </Col>
                                        <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                            <label>
                                                <strong>Enter Amount</strong>
                                            </label>
                                            <input
                                                placeholder="Enter Your Amount"
                                                type="text"
                                                className="form-control  rounded-100"
                                                value={cashWithdrawalState.amount}
                                                onChange={(e) => {
                                                    setCashWithdrawalState({
                                                        ...cashWithdrawalState,
                                                        amount: e.target.value,
                                                    });
                                                }}
                                            />
                                            {Number(cashWithdrawalState.amount) % 50 == 0 ? null : (
                                                <div className="alert alert-danger my-2" role="alert">
                                                    Invalid Transaction Amount please enter transaction
                                                    amount in multiple of 50
                                                </div>
                                            )}
                                        </Col>

                                        <div className="form-group rounded-100 mx-2  " style={textStyle}>
                                            <button
                                                style={{ background: "#277BC0", color: "white", width: "30%" }}
                                                className="btn text-primary  bg-white border-primary btn-block  "
                                                onClick={() => {
                                                    capture(false);
                                                }}
                                            >
                                                {" "}
                                                Capture Fingerprint
                                            </button>
                                        </div>

                                        <div className="form-group rounded-0 p-1 mx-1" style={textStyle}>
                                            {/* <button
                      style={{ background: "#277BC0", color: "white" }}
                      className="btn fw-bold btn-sm m-1"
                    >
                      {" "}
                      Cancel
                    </button> */}
                                            <div style={{ width: "6px" }} />
                                            <button
                                                style={{ background: "#277BC0", color: "white", width: "30%" }}
                                                className="btn btn-primary btn-block  "
                                                onClick={cashWithdrawalSubmit}
                                                disabled={cashWithdrawalState.isLoading}
                                            >
                                                {cashWithdrawalState.buttonText}
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                    {pageIdx === 2 && (
                        <React.Fragment>
                            <div className=" bg-white m-3">
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100 p-2" style={textStyle}>
                                    <label>
                                        <strong>Mobile No</strong>
                                    </label>
                                    <input
                                        maxLength="10"
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Mobile Number"
                                        type="text"
                                        className="form-control rounded-100"
                                        value={cashWithdrawalState.mobile}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                mobile: e.target.value,
                                            });
                                            setMiniStatementState({
                                                ...miniStatementState,
                                                mobile: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>

                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Bank Name</strong>
                                    </label>

                                    <Select
                                        className="rounded-100"
                                        options={bankList}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                bank: e,
                                            });
                                            setMiniStatementState({ ...miniStatementState, bank: e });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Aadhaar Number</strong>
                                    </label>
                                    <input
                                        maxLength="12"
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Aadhaar No"
                                        type="password"
                                        autocomplete="off"
                                        autoCorrect="off"
                                        aria-autocomplete="none"
                                        autoComplete="off"
                                        autoSave="off"
                                        className="form-control rounded-100"
                                        value={cashWithdrawalState.aadhar}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                aadhar: e.target.value,
                                            });
                                            setMiniStatementState({
                                                ...miniStatementState,
                                                aadhar: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>

                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Enter Amount</strong>
                                    </label>
                                    <input
                                        //   style={{ width: "154%" }}
                                        placeholder="Enter Amount"
                                        type="text"
                                        className="form-control rounded-100"
                                        value={cashWithdrawalState.amount}
                                        onChange={(e) => {
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                amount: e.target.value,
                                            });
                                        }}
                                    />
                                    {Number(cashWithdrawalState.amount) % 50 == 0 ? null : (
                                        <div className="alert alert-danger my-2" role="alert">
                                            Invalid Transaction Amount please enter transaction amount
                                            in multiple of 50
                                        </div>
                                    )}
                                </Col>
                            </div>
                            <Col sm={8} md={8} lg={7} className="form-group rounded-100   mx-2" style={textStyle}>
                                <div >
                                    <label>
                                        <strong>Choose Biometric Device</strong>
                                    </label>
                                    <select
                                        // style={{ width: "154%" }}
                                        className="form-control  rounded-100 border-secondary"
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                device: e.target.value,
                                            });
                                            setMiniStatementState({
                                                ...miniStatementState,
                                                device: e.target.value,
                                            });
                                            setDevice(e.target.value);
                                        }}
                                    >
                                        <option>--Please Select--</option>
                                        <option value={1}>Morpho MSO 1300</option>
                                        <option value={0}>Mantra MFS 100</option>
                                        <option value={2}>Startek FM220U</option>
                                        <option disabled={true}>Precision</option>
                                    </select>
                                </div>

                            </Col>
                            <div className="form-group rounded-0 col p-2 mx-3" style={textStyle}>
                                <div className="">
                                    <button
                                        style={{ background: "#277BC0", color: "white", width: "30%" }}
                                        className="btn text-primary  bg-white border-primary btn-block ml-2 "
                                        onClick={() => {
                                            capture(false);
                                        }}
                                    >
                                        {`Capture ${biAuthDone ? "Customer " : "Agent "}Fingerprint`}
                                    </button>
                                </div>
                                {/* <div className="col-8">
              <div
                onClick={() => {
                  if (cashWithdrawalState.res) {
                    print();
                  }
                }}
                href="#"
                className="btn fw-bold btn-sm m-4"
              >
                <img
                  src={require("./../../../assets/images/download.png")}
                  style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
                />
                {"Print Receipt"}
              </div>
            </div> */}
                            </div>
                            {snackbarState.success !== "" && (
                                <div className="alert alert-success my-2" role="alert">
                                    {snackbarState.success}
                                </div>
                            )}
                            {snackbarState.error != "" && (
                                <div className="alert alert-danger my-2" role="alert">
                                    {snackbarState.error}
                                </div>
                            )}

                            <div className="form-group col-8 px-2 m-4"
                                style={{ fontSize: "12px" }}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        onChange={() => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setCashWithdrawalState({
                                                ...cashWithdrawalState,
                                                consent: !cashWithdrawalState.consent,
                                            });
                                        }}
                                    />
                                    <label className="form-check-label text-dark fw-bold">
                                        {"* I Agree"}
                                    </label>
                                </div>
                                <label className="form-check-label text-danger fw-bold">
                                    <span className="mr-2">1. Agent Consent Clause T&C </span>
                                    <span>2. Customer Consent Clause T&C</span>
                                </label>


                            </div>

                            <div className="form-group rounded-0 col px-2 mx-3" style={textStyle}>
                                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                                <div style={{ width: "6px" }} />
                                <button
                                    style={{ background: "#277BC0", color: "white", width: "30%" }}
                                    className="btn btn-primary btn-block ml-2 "
                                    onClick={cashWithdrawalSubmit}
                                    disabled={cashWithdrawalState.isLoading}
                                >
                                    {cashWithdrawalState.buttonText}
                                </button>
                            </div>





                        </React.Fragment>
                    )}
                    {pageIdx === 3 && (
                        <React.Fragment>
                            <div className=" bg-white m-3">
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Mobile No</strong>
                                    </label>
                                    <input
                                        maxLength="10"
                                        //  style={{ width: "154%" }}
                                        placeholder="Enter Mobile Number"
                                        type="text"
                                        className="form-control  rounded-100"
                                        value={miniStatementState.mobile}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setMiniStatementState({
                                                ...miniStatementState,
                                                mobile: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>

                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Bank Name</strong>
                                    </label>

                                    <Select
                                        //style={{ width: "154%" }}
                                        className="rounded-100"
                                        options={bankList}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setMiniStatementState({ ...miniStatementState, bank: e });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Enter ADHAAR Number</strong>
                                    </label>
                                    <input
                                        maxLength="12"
                                        //style={{ width: "154%" }}
                                        placeholder="Enter ADHAAR Number"
                                        type="password"
                                        autocomplete="off"
                                        autoCorrect="off"
                                        aria-autocomplete="none"
                                        autoComplete="off"
                                        autoSave="off"
                                        className="form-control rounded-100"
                                        value={miniStatementState.aadhar}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setMiniStatementState({
                                                ...miniStatementState,
                                                aadhar: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>
                            </div>
                            <Col sm={8} md={8} lg={7} className="form-group rounded-100  mx-2" style={textStyle}>

                                <label>
                                    <strong>Choose Biometric Device</strong>
                                </label>
                                <select
                                    // style={{ width: "154%" }}
                                    className="form-control  rounded-100 border-secondary"
                                    onChange={(e) => {
                                        setSnackbarState({
                                            ...snackbarState,
                                            error: "",
                                            success: "",
                                        });
                                        setMiniStatementState({
                                            ...miniStatementState,
                                            device: e.target.value,
                                        });
                                        setDevice(e.target.value);
                                    }}
                                >
                                    <option>--Please Select--</option>
                                    <option value={1}>Morpho MSO 1300</option>
                                    <option value={0}>Mantra MFS 100</option>
                                    <option value={2}>Startek FM220U</option>
                                    <option disabled={true}>Precision</option>
                                </select>


                            </Col>
                            <div className="form-group rounded-0 col p-2 mx-3" style={textStyle}>
                                <div className="">
                                    <button
                                        style={{ background: "#277BC0", color: "white", width: "30%" }}
                                        className="btn text-primary  bg-white border-primary btn-block ml-2 "
                                        onClick={() => {
                                            capture(false);
                                        }}
                                    >
                                        {" "}
                                        Capture Fingerprint
                                    </button>
                                </div>

                                {miniStatementState.res && (
                                    <div className="col-8">
                                        <div
                                            onClick={() => {
                                                if (miniStatementState.res) {
                                                    print();
                                                }
                                            }}
                                            className="btn fw-bold btn-sm m-4"
                                        >
                                            <img
                                                src={require("./../../../assets/images/download.png")}
                                                style={{
                                                    height: "1rem",
                                                    width: "1rem",
                                                    marginRight: "2px",
                                                }}
                                            />
                                            {"Print Last Transactions"}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {snackbarState.success != "" && (
                                <div className="alert alert-success my-2" role="alert">
                                    {snackbarState.success}
                                </div>
                            )}
                            {snackbarState.error != "" && (
                                <div className="alert alert-danger my-2" role="alert">
                                    {snackbarState.error}
                                </div>
                            )}
                            <div className="form-group col-8 px-2 m-4"
                                style={{ fontSize: "12px" }}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        onChange={() => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setMiniStatementState({
                                                ...miniStatementState,
                                                consent: !miniStatementState.consent,
                                            });
                                        }}
                                    />
                                    <label className="form-check-label text-dark fw-bold">
                                        {"* I Agree"}
                                    </label>
                                </div>
                                <label className="form-check-label text-danger fw-bold">
                                    <span className="mr-2">1. Agent Consent Clause T&C </span>
                                    <span>2. Customer Consent Clause T&C</span>
                                </label>


                            </div>
                            <div className="form-group rounded-0 col px-2 mx-3" style={textStyle}>
                                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                                <div style={{ width: "6px" }} />
                                <button
                                    style={{ background: "#277BC0", color: "white", width: "30%" }}
                                    className="btn btn-primary btn-block ml-2 "
                                    onClick={miniStatementSubmit}
                                    disabled={miniStatementState.isLoading}
                                >
                                    {miniStatementState.buttonText}
                                </button>
                            </div>

                        </React.Fragment>
                    )}
                    {pageIdx === 4 && (
                        <React.Fragment>
                            <div className=" bg-white m-3">
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Mobile No</strong>
                                    </label>
                                    <input
                                        maxLength="10"
                                        //  style={{ width: "154%" }}
                                        placeholder="Enter Mobile Number"
                                        type="text"
                                        className="form-control rounded-100"
                                        value={adhaarPayState.mobile}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setAdhaarPayState({
                                                ...adhaarPayState,
                                                mobile: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>

                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Bank Name</strong>
                                    </label>

                                    <Select
                                        // style={{ width: "154%" }}
                                        className="rounded-100"
                                        options={bankList}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setAdhaarPayState({ ...adhaarPayState, bank: e });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100 p-2" style={textStyle}>
                                    <label>
                                        <strong>Aadhaar Number</strong>
                                    </label>
                                    <input
                                        maxLength="12"
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Aadhaar Number"
                                        type="password"
                                        autocomplete="off"
                                        autoCorrect="off"
                                        aria-autocomplete="none"
                                        autoComplete="off"
                                        autoSave="off"
                                        className="form-control rounded-100"
                                        value={adhaarPayState.aadhar}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setAdhaarPayState({
                                                ...adhaarPayState,
                                                aadhar: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>

                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2" style={textStyle}>
                                    <label>
                                        <strong>Enter Amount</strong>
                                    </label>
                                    <input
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Amount"
                                        type="text"
                                        className="form-control rounded-100"
                                        value={adhaarPayState.amount}
                                        onChange={(e) => {
                                            setAdhaarPayState({
                                                ...adhaarPayState,
                                                amount: e.target.value,
                                            });
                                        }}
                                    />
                                    {Number(adhaarPayState.amount) % 50 == 0 ? null : (
                                        <div className="alert alert-danger my-2" role="alert">
                                            Invalid Transaction Amount please enter transaction amount
                                            in multiple of 50
                                        </div>
                                    )}
                                </Col>
                            </div>

                            <Col sm={8} md={8} lg={7} className="form-group rounded-100   mx-2" style={textStyle}>

                                <label>
                                    <strong>Choose Biometric Device</strong>
                                </label>
                                <select
                                    //style={{ width: "150%" }}
                                    className="form-control  rounded-100 border-secondary"
                                    onChange={(e) => {
                                        setSnackbarState({
                                            ...snackbarState,
                                            error: "",
                                            success: "",
                                        });
                                        setAdhaarPayState({
                                            ...adhaarPayState,
                                            device: e.target.value,
                                        });
                                        setDevice(e.target.value);
                                    }}
                                >
                                    <option>--Please Select--</option>
                                    <option value={1}>Morpho MSO 1300</option>
                                    <option value={0}>Mantra MFS 100</option>
                                    <option value={2}>Startek FM220U</option>
                                    <option disabled={true}>Precision</option>
                                </select>


                            </Col>
                            <div className="form-group rounded-0 col p-2 mx-3" style={textStyle}>
                                <div className="">
                                    <button
                                        style={{ background: "#277BC0", color: "white", width: "30%" }}
                                        className="btn text-primary  bg-white border-primary btn-block ml-2 "
                                        onClick={() => {
                                            capture(false);
                                        }}
                                    >
                                        {`Capture ${biAuthDone ? "Customer " : "Agent "}Fingerprint`}
                                    </button>
                                </div>

                                {adhaarPayState.res && (
                                    <div className="col-8">
                                        <div
                                            onClick={() => {
                                                if (adhaarPayState.res) {
                                                    print();
                                                }
                                            }}
                                            href="#"
                                            className="btn fw-bold btn-sm m-4"
                                        >
                                            <img
                                                src={require("./../../../assets/images/download.png")}
                                                style={{
                                                    height: "1rem",
                                                    width: "1rem",
                                                    marginRight: "2px",
                                                }}
                                            />
                                            {"Print Receipt"}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {snackbarState.success != "" && (
                                <div className="alert alert-success my-2" role="alert">
                                    {snackbarState.success}
                                </div>
                            )}
                            {snackbarState.error != "" && (
                                <div className="alert alert-danger my-2" role="alert">
                                    {snackbarState.error}
                                </div>
                            )}

                            <div className="form-group col-8 px-2 m-4"
                                style={{ fontSize: "12px" }}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        onChange={() => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setAdhaarPayState({
                                                ...adhaarPayState,
                                                consent: !adhaarPayState.consent,
                                            });
                                        }}
                                    />
                                    <label className="form-check-label text-dark fw-bold">
                                        {"* I Agree"}
                                    </label>
                                </div>
                                <label className="form-check-label text-danger fw-bold">
                                    <span className="mr-2">1. Agent Consent Clause T&C </span>
                                    <span>2. Customer Consent Clause T&C</span>
                                </label>


                            </div>


                            <div className="form-group rounded-0 col px-2 mx-3" style={textStyle}>
                                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                                <div style={{ width: "6px" }} />
                                <button
                                    style={{ background: "#277BC0", color: "white", width: "30%" }}
                                    className="btn btn-primary btn-block ml-2 "
                                    onClick={adhaarPaySubmit}
                                    disabled={adhaarPayState.isLoading}
                                >
                                    {adhaarPayState.buttonText}
                                </button>
                            </div>

                        </React.Fragment>
                    )}
                    {pageIdx === 5 && (
                        <React.Fragment>
                        </React.Fragment>
                    )}

                    {pageIdx === 6 && (
                        <React.Fragment>

                            <Col sm={8} md={8} lg={7} className="form-group rounded-100  mx-2" style={textStyle}>
                                <div >
                                    <label>
                                        <strong>Choose Biometric Device</strong>
                                    </label>
                                    <select
                                        //style={{ width: "154%" }}
                                        className="form-control  rounded-100 border-secondary"
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setTwoFactorAuth({
                                                ...twoFactorAuth,
                                                device: e.target.value,
                                            });
                                            setDevice(e.target.value);
                                        }}
                                    >
                                        <option>--Please Select--</option>
                                        <option value={1}>Morpho MSO 1300</option>
                                        <option value={0}>Mantra MFS 100</option>
                                        <option value={2}>Startek FM220U</option>
                                        <option value={3}>Precision</option>
                                    </select>
                                </div>

                            </Col>
                            {aepsTwoFactorDone && (
                                <div className="d-flex mt-2">
                                    <div className="alert alert-success my-2" role="alert">
                                        AEPS Two Factor Authentication Done For AEPS Services you
                                        need to Do Two Factor Authentication for Aadhaar Pay service
                                        Please Click on Capture Fingerprint Button
                                    </div>
                                </div>
                            )}

                            <div className="form-group rounded-0 col p-2 mx-3" style={textStyle}>
                                <div className="">
                                    <button
                                        style={{ background: "#277BC0", color: "white", width: "30%" }}
                                        className="btn text-primary  bg-white border-primary btn-block ml-2 "
                                        onClick={() => {
                                            capture(false);
                                        }}
                                    >
                                        {"Capture Fingerprint"}
                                    </button>
                                </div>
                            </div>
                            {snackbarState.success != "" && (
                                <div className="alert alert-success my-2" role="alert">
                                    {snackbarState.success}
                                </div>
                            )}
                            {snackbarState.error != "" && (
                                <div className="alert alert-danger my-2" role="alert">
                                    {snackbarState.error}
                                </div>
                            )}


                            <div className="form-group col-8 px-2 m-4"
                                style={{ fontSize: "12px" }}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        onChange={() => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setTwoFactorAuth({
                                                ...twoFactorAuth,
                                                consent: !cashWithdrawalState.consent,
                                            });
                                        }}
                                    />
                                    <label className="form-check-label text-dark fw-bold">
                                        {"* I Agree"}
                                    </label>
                                </div>
                                <label className="form-check-label text-danger fw-bold">
                                    <span className="mr-2">1. Agent Consent Clause T&C </span>
                                    <span>2. Customer Consent Clause T&C</span>
                                </label>


                            </div>

                            <div className="form-group rounded-0 col px-2 mx-3" style={textStyle}>
                                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                                <div style={{ width: "6px" }} />
                                <button
                                    style={{ background: "#277BC0", color: "white", width: "30%" }}
                                    className="btn btn-primary btn-block ml-2 "
                                    onClick={twoFactorAuthSubmit}
                                    disabled={twoFactorAuth.isLoading}
                                >
                                    {twoFactorAuth.buttonText}
                                </button>
                            </div>



                        </React.Fragment>
                    )}

                    {pageIdx === 7 && (
                        <React.Fragment>
                            <div className="bg-white m-3">
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100 p-2 " style={textStyle}>
                                    <label>
                                        <strong>Mobile No.</strong>
                                    </label>
                                    <input
                                        maxLength="10"
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Mobile Number"
                                        type="text"
                                        className="form-control rounded-100"
                                        value={twoFactorAuth.mobile}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setTwoFactorAuth({
                                                ...twoFactorAuth,
                                                mobile: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>

                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2 " style={textStyle}>
                                    <label>
                                        <strong>Bank Name</strong>
                                    </label>

                                    <Select
                                        // style={{ width: "154%" }}
                                        className="rounded-100"
                                        options={bankList}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setTwoFactorAuth({ ...twoFactorAuth, bank: e });
                                        }}
                                    />
                                </Col>
                                <Col sm={8} md={8} lg={7} className="form-group rounded-100  p-2 " style={textStyle}>
                                    <label>
                                        <strong>Aadhaar Number</strong>
                                    </label>
                                    <input
                                        maxLength="12"
                                        // style={{ width: "154%" }}
                                        placeholder="Enter Aadhaar Number"
                                        type="password"
                                        autocomplete="off"
                                        autoCorrect="off"
                                        aria-autocomplete="none"
                                        autoComplete="off"
                                        autoSave="off"
                                        className="form-control rounded-100"
                                        value={twoFactorAuth.aadhar}
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setTwoFactorAuth({
                                                ...twoFactorAuth,
                                                aadhar: e.target.value,
                                            });
                                        }}
                                    />
                                </Col>
                            </div>
                            <Col sm={8} md={8} lg={7} className="form-group rounded-100 ml-2" style={textStyle}>
                                <div >
                                    <label>
                                        <strong>Choose Biometric Device</strong>
                                    </label>
                                    <select
                                        className="form-control  rounded-100 border-secondary"
                                        onChange={(e) => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setTwoFactorAuth({
                                                ...twoFactorAuth,
                                                device: e.target.value,
                                            });
                                            setDevice(e.target.value);
                                        }}
                                    >
                                        <option>--Please Select--</option>
                                        <option value={1}>Morpho MSO 1300</option>
                                        <option value={0}>Mantra MFS 100</option>
                                        <option value={2}>Startek FM220U</option>
                                        <option value={3}>Precision</option>
                                    </select>
                                </div>

                            </Col>
                            {aepsTwoFactorDone && (
                                <div className="d-flex mt-2">
                                    <div className="alert alert-success my-2" role="alert">
                                        AEPS Two Factor Authentication Done For AEPS Services you
                                        need to Do Two Factor Authentication for Aadhaar Pay service
                                        Please Click on Capture Fingerprint Button
                                    </div>
                                </div>
                            )}

                            <div className="form-group rounded-0 col p-2 mx-3" style={textStyle}>
                                <div className="" style={{ width: "30%" }}>
                                    <button
                                        style={{ background: "#277BC0", color: "white", width: "100%" }}
                                        className="btn border-primary bg-white text-primary "
                                        onClick={() => {
                                            capture(false);
                                        }}
                                    >
                                        {"Capture Fingerprint"}
                                    </button>
                                </div>
                            </div>
                            {snackbarState.success !== "" && (
                                <div className="alert alert-success my-2" role="alert">
                                    {snackbarState.success}
                                </div>
                            )}
                            {snackbarState.error !== "" && (
                                <div className="alert alert-danger my-2" role="alert">
                                    {snackbarState.error}
                                </div>
                            )}



                            <div className="form-group col-8 px-2 m-4"
                                style={{ fontSize: "12px" }}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        onChange={() => {
                                            setSnackbarState({
                                                ...snackbarState,
                                                error: "",
                                                success: "",
                                            });
                                            setTwoFactorAuth({
                                                ...twoFactorAuth,
                                                consent: !cashWithdrawalState.consent,
                                            });
                                        }}
                                    />
                                    <label className="form-check-label text-dark fw-bold">
                                        {"* I Agree"}
                                    </label>
                                </div>
                                <label className="form-check-label text-danger fw-bold">
                                    <span className="mr-2">1. Agent Consent Clause T&C </span>
                                    <span>2. Customer Consent Clause T&C</span>
                                </label>


                            </div>

                            <div className="form-group rounded-0 col px-2 mx-3" style={textStyle}>
                                {/* <button
                  style={{ background: "#277BC0", color: "white" }}
                  className="btn fw-bold btn-sm m-1"
                >
                  {" "}
                  Cancel
                </button> */}
                                <div style={{ width: "6px" }} />
                                <button
                                    style={{ background: "#277BC0", color: "white", width: "30%" }}
                                    className="btn btn-block"
                                    onClick={twoFactorAuthSubmit2}
                                    disabled={twoFactorAuth.isLoading}
                                >
                                    {twoFactorAuth.buttonText}
                                </button>
                            </div>

                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

const YesBankAEPS = () => {
    const { username, password } = basicAuth;
    const { user, Profileinfo } = useContext(GlobalContext);
    const [ok, setOk] = useState(true);
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState(true);

    return <App credentials={credentials} />;
};

const Spinner = () => {
    return (
        <div>
            <div className="spinner-wrapper">
                <div className="donut"></div>
            </div>
        </div>
    );
};

export default YesBankAEPS;
