import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { generateHashKey2, uniqueID2 } from "../utils/utilFuctions";
import Spinner from "./shared/Spinner";
import { ADMIN_SESSION_TIMER, checkSession } from "../utils/Session";
import { GlobalContext } from "../Context/GlobalState";
import AdminLogin from "./user-pages/AdminLogin";
import AdminLogin2 from "./user-pages/AdminLogin2";
import CreateUser from "./create-user/CreateUser";
import UserKycDetails from "./user-kyc-details/UserKycDetails";
import AllUserList from "./all-user-list/AllUserList";
// import DMTHistory from "./dmt-history/DMTHistory";
import DMTHistory from "./dmt-history-3/DMTHistory";
import AccountLedger from "./account-ledger/AccountLedger";
import ViewLoad from "./view-load/ViewLoad";
//import AllUserKYC from "./all-user-kyc/AllUserKYC";
import AllUserKYC from "./all-user-kyc-2/AllUserKYC";
import CreateLoadRequest from "./create-load-request/CreateLoadRequest";
// import AEPSReport from "./aeps-report/AEPSReport";
import AEPSReport from "./aeps-report-3/AEPSReport";
// import BBPSReport from "./bbps-report/BBPSReport";
import BBPSReport from "./bbps-report-2/BBPSReport";
import Customers from "./customers/Customers";
import SmartCollect from "./virtual/smartcollect";
import DMTRefundReport from "./dmt-refund-report/DMTRefundReport";
import AEPSRefundReport from "./aeps-refund-report/AEPSRefundReport";
import BBPSRefundReport from "./bbps-refund-report/BBPSRefundReport";
import TimeReport from "./time-report/TimeReport";
import BalanceReport from "./balance-report/BalanceReport";
import CnfUseReportMaster from "./cnf-use-report-master/CnfUseReportMaster";
import CnfUseReportDist from "./cnf-use-report-dist/CnfUseReportDist";
import CnfUseReportRet from "./cnf-use-report-ret/CnfUseReportRet";
import CreateAdminUser from "./users/CreateAdminUser";
import CreateTariff from "./create-tariff/CreateTariff";
import ViewTariff from "./view-tariff/ViewTariff";
import ViewTariffPlans from "./view_tariff_plans/ViewTariffPlans";
import ModuleTest from "./admin-module-test/Test";
import MyProfile from "./my profile/MyProfile";
import ViewTicketList from "./view-ticket-list/ViewTicketList";
import ViewLoanEnquiryList from "./loan-enquiry-list/ViewLoanEnquiryList";
import LoadBankList from "./load-bank-list/LoadBankList";
import SalesReport from "./sales_report/SalesReport";
import UserAccessUpdate from "./user-access-update/UserAccessUpdate";
import userNotification from "./user-notification/userNotification";
import NotificationList from "./Notification-List/NotificationList";
import masterTariff from "./master-tariff/masterTariff";
import AdminLedgerReport from "./admin-retailer-ledger/LedgerReport";
// import SettlementReport2 from "./settlement-report/SettlementReport2";
import SettlementReport2 from "./settlement-report-2/SettlementReport2";
import UserView from "./user-view/UserView";
import AddFundsAdmin from "./add-funds/AddFunds";
import Dashboard2 from "./dashboard/Dashboard2";
import UserMapping from "./user-mapping/UserMapping";
import ForgetPasswordAdmin from "./user-pages/ForgetPasswordAdmin";
import AEPSFingpayResponseList from "./aeps-fingpay-response-list/AEPSFingpayResponseList";
import PGReport from "./pg-report/PGReport";
import TwoFactorReport from "./two-factor-report/TwoFactorReport";
import AppVersionChange from "./app-version-change/AppVersionChange";
import DocKYCInitiate from "./doc-kyc-initiate/DocKycInitiate";
import AepsOnboardingReInitiate from "./aeps-onboarding-reinitiate/AepsOnboardingReInitiate";
import ODRequestDocUpload from "./od-request-doc-upload/ODRequestDocUpload";
import AllCreditRequests from "./all-credit-requests/AllCreditRequests";
import AllODRequestDocs from "./all-od-requests-docs/AllODRequestDocs";
import PgSettlementTable from "./pg-settlement-report/PGSettlementReport";
import AllTransactionsDownload from "./dashboard/AllTransactionsDownload";
import SmartCollectPaymentsTable from "./virtual/SmartCollectPaymentsTable";
import SmsConfiguration from "./users/NotificationTest";
import CreditCardBillPaymentReport from "./credit-card-bill-payment-report/CreditCardBillPaymentReport";
import ProcessSalary from "./process_salary/ProcessSalary";
import AddSalaryDetails from "./add_salary_details/AddSalaryDetails";
import AllServicesAdmin from "./services-access/ServicesActivate";
import FundHoldReport from "./fund_hold_report/FundHoldReport";
import BulkSalaryRecordAdd from "./process_salary/BulkSalaryRecordAdd";
import AllServicesReport from "./all-services-report/AllServicesReport";
import VirtualPaymentReport from "./virtual-payment-report/VirtualPaymentReport";
import WalletToWalletReport from "./wallet-to-wallet-report/WalletToWalletReport";
import PaymentGetwayReport from "./reports/payment-gateway-report/PaymentGatewayReport";
import SuperAdminReportDownload from "./super-admin-report/SuperAdminReport";
import ReviewKycForm from "./review-kyc/ReviewKycForm";
import AllReviewKyc from "./all-review-kyc/AllReviewKyc";
import AllCreditCardKycReport from "./all-credit-card-kyc/AllCreditCardKycReport";
import AllChargeBackCases from "./all-chargback-cases/AllChargeBackCases";
import MarcomDocAdmin from "./marcom-doc/MarcomDocAdmin";
import APITopupAdmin from "./api-topup/APITopup";
import AEPSLogsReport from "./aeps-logs/AEPSLogsReport";
import OrderApi from "./json-component/JsonMapper";
import UserActivity from "./user-activity/UserActivity";
import CreditCardTransactions from "./credit-card-transactions/CreditCardTransactions";
import CreditCardDetails from "./credit-card-details/CreditCardDetails";
import BillDetails from "./bill-details/BillDetails";
import PgCommercials from "./commercials/pg-commercials/PGCommercials";
import AdminActivityLogs from "./admin-activity/AdminActivityLogs";
import LienAmount from "./lien-amount/LienAmount";
import AllUserLedger from "./reports/all-user-ledger/AllUserLedger";
import QueueManagement from "./queue-management/QueueManagement";
import AdminDmt from "./DMT/AdminDmt";
import UserReviewDetails from "./review-report/UserReviewDetails";
import VendorPaymentReport from "./vendor-payment-report/VendorPaymentReport";
import AdminRewardsScreen from "./admin-rewards/RewardsScreen";
import CmsReport from "./cms-report/CmsReport";
import SuperAdmin from "./super-admin/SuperAdmin";
import SalesFieldReportTable from "./sales-field-report/SalesFieldReportTable";
import SaledFieldReport from "./sales-field-report/SalesFieldReport";
import ServiceRequestReport from "./service-requests/ServiceRequestReport";
import QrReport from "./QR-REPORT/QrReport";





const Error404 = lazy(() => import("./error-pages/Error404"));

const AppRoutes = () => {
  const { removeUserInfo, user } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (user.pkid != '9') {
      removeUserInfo();
      checkSession();
    }
  };

  const onActive = (event) => { };

  const onAction = (event) => { };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: ADMIN_SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });


  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Disable right-click
      // document.addEventListener("contextmenu", (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
        )
          return false;
      };
    }
  }, []);
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/admin/dashboard" component={Dashboard2} />
        {/* User */}
        <Route
          exact
          path="/admin/user-kyc-details"
          component={UserKycDetails}
        />
        <Route exact path="/admin/user-view" component={UserView} />
        <Route exact path="/admin/marcom-doc-view" component={MarcomDocAdmin} />
        <Route exact path="/admin/add-funds" component={AddFundsAdmin} />
        <Route exact path="/admin/api-topup" component={APITopupAdmin} />
        <Route
          exact
          path="/admin/create_load_request"
          component={CreateLoadRequest}
        />
        <Route exact path="/admin/create-user" component={CreateUser} />
        <Route exact path="/admin/all_user_kyc" component={AllUserKYC} />
        <Route exact path="/admin/view_load" component={ViewLoad} />
        <Route exact path="/admin/account_ledger" component={AccountLedger} />
        <Route exact path="/admin/all_user_list" component={AllUserList} />
        <Route exact path="/admin/review_kyc" component={ReviewKycForm} />
        <Route exact path="/admin/all_kyc_review" component={AllReviewKyc} />
        <Route exact path="/admin/all_credit_card_kyc" component={AllCreditCardKycReport} />
        <Route exact path="/admin/all_chargeback_cases" component={AllChargeBackCases} />
        <Route exact path="/admin/qr_report" component={QrReport} />
        
        
        
        
        <Route
          exact
          path="/admin/userNotification"
          component={userNotification}
        />
        <Route
          exact
          path="/admin/NotificationList"
          component={NotificationList}
        />
        <Route exact path="/admin/masterTariff" component={masterTariff} />
        {/* PANNOW */}
        <Route exact path="/admin/customers" component={Customers} />
        <Route exact path="/admin/virtual" component={SmartCollect} />
        {/* REPORT */}
        <Route
          exact
          path="/admin/settlement_report"
          component={SettlementReport2}
        />
        <Route exact path="/admin/super_admin" component={SuperAdmin} />
        <Route exact path="/admin/bbps_report" component={BBPSReport} />
        <Route exact path="/admin/cms_report" component={CmsReport} />
        <Route exact path="/admin/aeps_report" component={AEPSReport} />
        <Route exact path="/admin/dmt_history" component={DMTHistory} />
        <Route exact path="/admin/vendor_payments" component={VendorPaymentReport} />
        <Route exact path="/admin/time_report" component={TimeReport} />
        <Route exact path="/admin/balance_report" component={BalanceReport} />
        <Route exact path="/admin/pg_settlement_report" component={PgSettlementTable} />
        <Route exact path="/admin/fund_hold_report" component={FundHoldReport} />
        <Route exact path="/admin/credit_card_bill_payment_report" component={CreditCardBillPaymentReport} />
        <Route exact path="/admin/all_user_ledger" component={AllUserLedger} />
        <Route exact path="/admin/service_requests" component={ServiceRequestReport} />

       


        <Route exact path="/admin/agent_ledger" component={AdminLedgerReport} />
        <Route exact path="/admin/user-rewards" component={AdminRewardsScreen} />
        <Route exact path="/admin/process_salary" component={ProcessSalary} />
        <Route exact path="/admin/bulk_salary_add" component={BulkSalaryRecordAdd} />
        <Route exact path="/admin/add_salary_details" component={AddSalaryDetails} />

        <Route exact path="/admin/aeps_fingpay_reponse" component={AEPSFingpayResponseList} />
        <Route exact path="/admin/pg_report" component={PGReport} />
        <Route exact path="/admin/two_factor_report" component={TwoFactorReport} />
        <Route exact path="/admin/app_version_change" component={AppVersionChange} />
        <Route exact path="/admin/doc_kyc_initiate" component={DocKYCInitiate} />
        <Route exact path="/admin/aeps_onboarding_reinitiate" component={AepsOnboardingReInitiate} />
        <Route exact path="/admin/od_request_doc_submit" component={ODRequestDocUpload} />
        <Route exact path="/admin/credit_requests" component={AllCreditRequests} />
        <Route exact path="/admin/od_requests_docs" component={AllODRequestDocs} />
        <Route exact path="/admin/virtual_report" component={VirtualPaymentReport} />
        <Route exact path="/admin/wallet_to_wallet_report" component={WalletToWalletReport} />
        <Route exact path="/admin/payment-gateway-recon-report" component={PaymentGetwayReport} />
        
        <Route exact path="/admin/common_report_download" component={AllTransactionsDownload} />
        <Route exact path="/admin/admin_report_download" component={SuperAdminReportDownload} />
        <Route exact path="/admin/all_services_report" component={AllServicesReport} />
        <Route exact path="/admin/aeps_logs" component={AEPSLogsReport} />
        <Route exact path="/admin/user_activity" component={UserActivity} />
        <Route exact path="/admin/credit_card_transactions" component={CreditCardTransactions} />
        <Route exact path="/admin/credit_card_details" component={CreditCardDetails} />
        <Route exact path="/admin/get_all_bill_details" component={BillDetails} />
        <Route exact path="/admin/commercials/pg_commercials" component={PgCommercials} />
        <Route exact path="/admin/admin_activity" component={AdminActivityLogs} />
        <Route exact path="/admin/lien_amount" component={LienAmount} />
        <Route exact path="/admin/queue-management" component={QueueManagement} />
        <Route exact path="/admin/dmt" component={AdminDmt} />
        <Route exact path="/admin/user-review-details" component={UserReviewDetails} />

        

        
        
        
        <Route exact path="/admin/json_mapper" component={OrderApi} />
        
        <Route exact path="/admin/sms" component={SmsConfiguration} />





        {/* REFUND REPORT */}
        <Route
          exact
          path="/admin/dmt_refund_report"
          component={DMTRefundReport}
        />
        <Route
          exact
          path="/admin/aeps_refund_report"
          component={AEPSRefundReport}
        />
        <Route
          exact
          path="/admin/bbps_refund_report"
          component={BBPSRefundReport}
        />

        {/* CNF USE REPORT */}
        <Route
          exact
          path="/admin/cnf_use_report_master"
          component={CnfUseReportMaster}
        />
        <Route
          exact
          path="/admin/cnf_use_report_dist"
          component={CnfUseReportDist}
        />
        <Route
          exact
          path="/admin/cnf_use_report_ret"
          component={CnfUseReportRet}
        />

        {/* USERS */}
        <Route
          exact
          path="/admin/create_admin_user"
          component={CreateAdminUser}
        />
        <Route
          exact
          path="/admin/view_ticket_list"
          component={ViewTicketList}
        />
        <Route
          exact
          path="/admin/view_loan_enquiry_list"
          component={ViewLoanEnquiryList}
        />

        {/* BANK */}
        <Route exact path="/admin/load-bank-list" component={LoadBankList} />

        {/* Profile */}
        <Route exact path="/admin/my_profile" component={MyProfile} />

        {/* User Mapping */}
        <Route exact path="/admin/user_mapping" component={UserMapping} />

        {/* Tariff */}
        <Route exact path="/admin/create_tariff" component={CreateTariff} />
        <Route exact path="/admin/view_tariff" component={ViewTariff} />
        <Route
          exact
          path="/admin/view_tariff_plans"
          component={ViewTariffPlans}
        />

        {/* Sales Report */}
        <Route exact path="/admin/sales_dsr_report" component={SaledFieldReport} />
        <Route exact path="/admin/sales_report" component={SalesReport} />

        {/* User Access Update */}
        <Route
          exact
          path="/admin/user_access_manage"
          component={UserAccessUpdate}
        />

        <Route
          exact
          path="/admin/all_services"
          component={AllServicesAdmin}
        />

        {/* Tariff */}
        <Route exact path="/admin/test" component={ModuleTest} />

        <Route exact path="/admin" component={AdminLogin2} />
        <Route exact path="/admin/forget-password" component={ForgetPasswordAdmin} />
        <Route path="/error-pages/error-404" component={Error404} />

        <Redirect to="/admin" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
