import React, { useState, useEffect, useRef } from "react";

import CropComponent from "../../components/crop/CropComponent";


const ImageCropper = () => {
  const [croppedImage, setCroppedImage] = useState("");

  const [state, setState] = useState({
    name: "",
    content: "",
    error: "",
    success: "",
    image: "",
    buttonText: "Create",
  });
  const imageCropperToggler = useRef(null);
  const { name, content, error, success, image, buttonText } = state;
  const handleChange = (name) => (e) => {
    setState({
      ...state,
      [name]: e.target.value,
      error: "",
      success: "",
    });
  };

  return (
      <div className="row">
        <div className="col-12 col-sm-10 col-md-6 mx-auto">
          <h1>Create Category</h1>
          <br />
          <form >
            
           
            <div className="row mt-2">
              <button
                className="btn btn-outline-success rounded-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#imageCropper"
                aria-expanded="false"
                aria-controls="imageCropper"
                ref={imageCropperToggler}
              >
                Upload Image
              </button>
            </div>
            <div className="" id="imageCropper">
              <CropComponent
                classes=""
                aspect={16 / 9}
                callback={(blob) => {
                  if (blob) {
                    // try {
                    //   Resizer.imageFileResizer(
                    //     blob,
                    //     800,
                    //     450,
                    //     "JPEG",
                    //     50,
                    //     0,
                    //     (uri) => {
                    //       setCroppedImage(uri);
                    //     },
                    //     "base64",
                    //     200,
                    //     200
                    //   );
                    // } catch (err) {
                    // }
                  }
                }}
              />
            </div>
            <div className="row">
              <button className="btn btn-outline-primary rounded-0 my-1">
                {buttonText}
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default ImageCropper;
