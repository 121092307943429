import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import BBPSReportTable from "./BBPSReportTable";

export const columns = [
  {
    dataField: "rch_mobile",
    text: "Account No",
  },
  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "utnno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];

const BBPSReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "1" ? (
          <Redirect to="/" />
        ) : (
          <div style={{ backgroundColor:"#F1F2F4" , height:"100%" , width:"100%" }}>
            <div
                style={{
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
              }}
           ><h4 className="ml-2">
 <strong>BBPS History</strong>
           </h4>
           </div>
            <BBPSReportTable columns={columns} />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default BBPSReport;
