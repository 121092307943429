import React, { useState, useEffect , useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "accountNo",
    text: "Account Number",
    color: "green",
  },
  {
    dataField: "ifscCode",
    text: "IFSC Code",
    color: "#000",
  },
  {
    dataField: "upi",
    text: "UPI",
    color: "#000",
  },
  {
    dataField: "createdOn",
    text: "Created On",
    color: "#000",
  },
];

const SmartCollectVirtualAccountTable = ({ setDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const { user,setTransactionLoaderState } = useContext(GlobalContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualAccounts = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );
    console.log(accessToken);

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true)
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/VirtualInfo/GetAllVirtualAccount`,
        requestOptions
      );
      const data = await res.json();
      console.log(data.data);
      if (data.data && data.data.length > 0) {
        const dataLoad = data.data.map((acc) => {
          return {
            ...acc,
            upi: `${acc.accountNo}@yesbankltd`,
            date: new Date(acc.createdOn),
          };
        }); setTransactionLoaderState(false)
        dataLoad.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        console.log(dataLoad);
        setLedger(dataLoad);
        // setDataToDisplay(dataLoad);
      }
    } catch (error) { setTransactionLoaderState(false)}
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  // useEffect(() => {
  //   let filteredData = ledger.filter((bank) => {
  //     let b = false;
  //     if (ledger.length > 0) {
  //       for (let key in ledger[0]) {
  //         if (
  //           bank[key] &&
  //           bank[key].toString().toLowerCase().includes(searchText.toLowerCase())
  //         ) {
  //           b = true;
  //         }
  //       }
  //     }
  //     return b;
  //   });
  //   console.log(filteredData);
  //   const startIdx = (currentPage - 1) * 10;
  //   const endIdx = currentPage * 10 - 1;
  //   console.log({ startIdx, endIdx });
  //   filteredData = filteredData.filter((bank, idx) => {
  //     console.log(idx, idx <= endIdx && idx >= startIdx);
  //     return idx <= endIdx && idx >= startIdx;
  //   });
  //   setDataToDisplay(filteredData);
  //   console.log({ startIdx, endIdx });
  // }, [currentPage]);

  useEffect(() => {
    getAllVirtualAccounts();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualAccounts();
  }, []);
  console.log(ledger);

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '20%', // Adjust the width as needed
    height:'20%',
   
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0px" }}
        >
          <div className="daterange-picker">
            <Form.Group style={{ marginLeft: "10px" }}>
              <DatePicker  dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>

            <Form.Group>
              <span style={{ padding: "10px" }}> {" To "} </span>
            </Form.Group>
            <Form.Group style={{ marginLeft: "10px" }}>
              <DatePicker  dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <Form.Group style={{ marginLeft: "10px" }}>
            <Button
              className="rounded-0"
              variant="success"
              onClick={getAllVirtualAccounts}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div> */}
      </div>
      
          <div className="row m-0 p-0 ">
          <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2 pl-2">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
        
                <div className="m-0 p-0  hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `Virtual_accounts_Report_${dateFormatter()}`,
                excelMapping(dataToDisplay, columns)
              );
            }}
          >
              <img className="px-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
            <div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
          {/* <div className="col-3">
            <Form.Group style={{ maxWidth: "30rem", margin: "10px 0px" }}>
              <Form.Control
                type="text"
                className="form-control rounded-100 bg-light"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
          </div>
          <div className=" mt-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `Virtual_accounts_Report_${dateFormatter()}`,
                excelMapping(data, columns)
              );
            }}
          >
            
            <img
              src={require("../../../assets/images/excel.png")}
              style={{ width: "1.6rem" }}
            />
            <strong style={{ marginLeft: "4px" }}></strong>
          </div> */}
          </div>

          <hr className="m-0 p-0"/>
      <table className="my-table transparent-table text-center border-bottom"
          style={{ width: "100%", overflowX: "auto" ,border: "0px" ,overflow: "auto", }}>
        <tr className="borderless-table text-center border-bottom">
          <th  className="my-table transparent-table text-center border-bottom py-3 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Sr"}</th>
          {columns.map((heading) => (
            <th  className="my-table transparent-table text-center border-bottom py-3 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{heading.text}</th>
          ))}
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr className="border-bottom">
            <td className="text-center border-bottom" style={{ "fontFamily":"Poppins","fontSize":"0.8rem","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {ind + 1}</td>
            {columns.map((cell) =>
              cell.dataField == "status" ? (
                e[cell.dataField] == "success" ? (
                  <td className="text-center border-bottom" style={{ "fontFamily":"Poppins","fontSize":"0.8rem","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                    {" "}
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-success"
                    >
                      {"Success"}
                    </label>{" "}
                  </td>
                ) : (
                  <td
                  className="text-center border-bottom" style={{ "fontFamily":"Poppins","fontSize":"0.8rem","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}
                  >
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-warning"
                    >
                      {e[cell.dataField]}
                    </label>
                  </td>
                )
              ) : (
                <td className="text-center border-bottom" style={{ color: cell.color,  cursor: cell.dataField == "accountNo" ? "pointer" : "", "fontFamily":"Poppins","fontSize":"0.8rem","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}
                  onClick={() => {
                    if (cell.dataField == "accountNo") {
                      setDetails(e);
                    }
                  }}
                 
                >
                  {e[cell.dataField]}
                </td>
              )
            )}
          </tr>
        ))}
      </table>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          console.log(ledger);
          if (ledger.length > 0) setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default SmartCollectVirtualAccountTable;
