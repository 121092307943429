import React, { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import {
  Form,
  Dropdown,
  Button,
  Row,
  Col,
  Modal,
  Tab,
  Nav,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import {
  BASE_URL_APISERVICES,
} from "../../utils/Constants";
import swal from "sweetalert";

const QrCode = () => {
  const [providers, setProviders] = useState([]);
  const [formValues, setFormValues] = useState({
    apiKey: "",
    userName: "",
    Password: "",
    Token: "",
    URL: "",
    Type: "",
  });

  const getBankDetails = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: "1",
        type: "SMS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Notification/getAllNotificationProviders`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setProviders(data.data);
        setFormValues(data.data[0])
      } else {
        swal("Failed", "User Details Updation Failed", "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getBankDetails();
  },[])
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div
            className="card"
            style={{
              borderRadius: "2px",
            }}
          >
           
            <div className="row">
              <div className="col-2">API Key</div>
              <div className="col-4">
                <Form.Control
                  type="text"
                  className="form-control rounded-lg"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter API Key"
                  style={{ borderRadius: "0px" }}
                  value={formValues.provider_api_key}
                />
              </div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
            <div className="row">
              <div className="col-2">User Name</div>
              <div className="col-4">
                <Form.Control
                  type="text"
                  className="form-control rounded-lg"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  value={formValues.provider_username}
                  placeholder="Enter API Key"
                  style={{ borderRadius: "0px" }}
                />
              </div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
            <div className="row">
              <div className="col-2">Use Password</div>
              <div className="col-4">
                <Form.Control
                  type="text"
                  className="form-control rounded-lg"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter API Key"
                  style={{ borderRadius: "0px" }}
                  value={formValues.provider_password}
                />
              </div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
            <div className="row">
              <div className="col-2">User Token</div>
              <div className="col-4">
                <Form.Control
                  type="text"
                  className="form-control rounded-lg"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter API Key"
                  style={{ borderRadius: "0px" }}
                  value={formValues.provider_token}
                />
              </div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
            <div className="ml-2">
              Get Your Account on{" "}
              <span style={{ color: "blue" }}>{formValues.provider_url}</span>
            </div>
            <div className="row">
              <div className="col-2">
                <button
                  className="btn-block"
                  style={{
                    border: "none",
                    backgroundColor: "#D2DE32",
                    color: "white",
                    borderRadius: "0.2rem",
                  }}
                >
                  Save
                </button>
              </div>
              <div className="col-2">
                <button
                  className="btn-block"
                  style={{
                    border: "none",
                    backgroundColor: "#B4B4B3",
                    color: "black",
                    borderRadius: "0.2rem",
                  }}
                >
                  Send Text
                </button>
              </div>
              <div className="col-8"></div>
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

const SmsConfiguration = ({
  qrEnabled,
}) => {
  const [providers, setProviders] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: "1",
        type: "SMS",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Notification/getAllNotificationProviders`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        setProviders(data.data)
      } else {
        swal("Failed", "User Details Updation Failed", "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDMTReport();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#F1EFEF",
        height: "100%",
      }}
    >
      <h5 className="ml-3 mt-2">SMS Configuration</h5>
      <div className="card m-3">
        <h5>Merchant SMS Settings</h5>
        <h6>
          Disable SMS on Merchant?{" "}
          <input
            className="ml-1"
            type="checkbox"
            id="myCheckbox"
            name="myCheckbox"
            value="checked"
          />
        </h6>
        <h6>
          Use admin SMS credit to send SMS?
          <input
            className="ml-1"
            type="checkbox"
            id="myCheckbox"
            name="myCheckbox"
            value="checked"
          />
        </h6>
        <h5 className="mt-2">SMS Gateway to use when sending SMS </h5>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
          className="mt-3"
        >
          <Row className="m-0 p-0 ">
            <Col md={6} sm={4}>
              <Nav
                variant="pills"
                className="nav-pills-custom-9  justify-content-start p-1 mt-3"
                style={{
                  border: "none",
                  borderRadius: "10px",
                  width: "100vw",
                  marginLeft: "2rem",
                  height: "6.5vh",
                }}
              >
                <Nav.Item>
                  
                </Nav.Item>
                {providers.map((provider)=>(
                    <Nav.Item>
                  <Nav.Link eventKey="first">
                    <i className="mdi mdi-home-outline pr-2"></i>
                    {provider.provider_name}
                  </Nav.Link>
                </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col className="m-0 py-0" sm={12}>
              <Tab.Content className="tab-content-vertical border-0">
                <Tab.Pane eventKey="first">
                  <QrCode qrEnabled={qrEnabled} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default SmsConfiguration;