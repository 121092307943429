import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

const LoanUI = () => {
  const [LoanAmount, setLoanAmount] = useState(100000);
  const [Roi, setRoi] = useState(10);
  const [Months, setMonths] = useState(30);
  const [Interest, setInterest] = useState(0);
  const history = useHistory();

  const redirect = () => {
    history.push("/retailer/loanenquiry");
  };

  const doughnutPieData = {
    datasets: [
      {
        data: [LoanAmount, Interest],
        backgroundColor: ["green", "red"],
        borderColor: ["green", "red"],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Interest", "Amount"],
  };

  const doughnutPieOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const InterestAmount = () => {
    const interest = LoanAmount * (Roi / 100) * (Months / 12);
    const decint = interest.toFixed(2);
    setInterest(decint);
  };

  useEffect(() => {
    InterestAmount();
  }, [Roi, LoanAmount, Months]);

  return (
    <Container style={{ backgroundColor: "#FFFFFF55" }}>
      <Row>
        <Col md={12} lg={12} sm={12}>
          <h1>
            <strong>Make Life Easier</strong>
          </h1>
        </Col>
        <Col md={12} lg={12} sm={12}>
          <h5 className="mt-2" style={{ color: "#7743DB" }}>
            <strong>Get the financial help you need</strong>
          </h5>
        </Col>
      </Row>

      <Row>
        <Col md={8} lg={8} sm={12}>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="mt-2 "
          >
            <div className="p-2 " style={{ borderRadius: "10px" }}>
              <Button variant="outline-danger" onClick={redirect}>
                Loan Enquiry
              </Button>
            </div>

            <div className="p-2" style={{ borderRadius: "10px" }}>
              <Button variant="outline-danger">Home Loan</Button>
            </div>

            <div className="p-2" style={{ borderRadius: "10px" }}>
              <Button variant="outline-danger">Gold Loan</Button>
            </div>

            <div className="p-2" style={{ borderRadius: "10px" }}>
              <Button variant="outline-danger">Personal Loan</Button>
            </div>

            <div className="p-2" style={{ borderRadius: "10px" }}>
              <Button variant="outline-danger">Pay Later</Button>
            </div>
          </div>
        </Col>

        <Col md={4} lg={4} sm={12}>
          <div className="p-2 mb-2" style={{ borderRadius: "10px" }}>
            <div>
              {" "}
              <img
                style={{ width: "20vw", objectFit: "cover", height: "35vh" }}
                src="https://pwa-cdn.freecharge.in/pwa-static/pwa/images/loan/loan_banner.png"
              />
            </div>
          </div>
        </Col>
      </Row>

      <div
        className="row"
        style={{
          borderRadius: "10px",
          border: "none",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Col md={6} lg={6} sm={12} className="mt-4">
          <strong>EMI Calculator</strong>
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              variant="outline-danger"
              size="xl"
              style={{ borderRadius: "5px" }}
              className="p-2"
            >
              Personal Loan
            </Button>
            <Button
              variant="outline-danger"
              size="xl"
              style={{ borderRadius: "5px" }}
              className="p-2"
            >
              Auto Loan
            </Button>
            <Button
              variant="outline-danger"
              size="xl"
              style={{ borderRadius: "5px" }}
              className="p-2"
            >
              Home Loan
            </Button>
            <Button
              variant="outline-danger"
              size="xl"
              style={{ borderRadius: "5px" }}
              className="p-2"
            >
              Others
            </Button>
          </div>

          <div
            className="p-1 mt-4 mb-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label class="form-label" for="customRange1">
              <strong>Loan Amount:</strong>
            </label>
            <span
              className="p-1"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <strong> ₹ {LoanAmount} </strong>
            </span>
          </div>
          <div>
            <input
              type="range"
              min={10000}
              max={2500000}
              defaultValue={LoanAmount}
              onChange={(e) => setLoanAmount(e.target.value)}
              class="form-range"
              id="customRange1"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <small>10k</small>
            </span>
            <span>
              <small>25L</small>
            </span>
          </div>

          <div
            className="p-1 mt-4 mb-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label class="form-label" for="customRange2">
              <strong>Rate of Interest:</strong>
            </label>
            <span
              className="p-1"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <strong> % {Roi} </strong>
            </span>
          </div>
          <div>
            <input
              type="range"
              min={2.5}
              max={20}
              step={0.1}
              defaultValue={Roi}
              onChange={(e) => setRoi(e.target.value)}
              class="form-range"
              id="customRange2"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <small>2.5%</small>
            </span>
            <span>
              <small>20%</small>
            </span>
          </div>

          <div
            className="p-1 mt-4 mb-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label class="form-label" for="customRange1">
              <strong>
                Loan Tenure:
                <sup style={{ color: "green" }}>
                  <small>In Months</small>
                </sup>
              </strong>
            </label>
            <span
              className="p-1"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <strong>
                {" "}
                <i className="fa fa-calendar" /> {Months}{" "}
              </strong>
            </span>
          </div>
          <div>
            <input
              type="range"
              min={1}
              max={120}
              defaultValue={Months}
              onChange={(e) => setMonths(e.target.value)}
              class="form-range"
              id="customRange1"
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <small>1</small>
            </span>
            <span>
              <small>120</small>
            </span>
          </div>
        </Col>

        <Col md={6} lg={6} sm={12} className="mt-4">
          <div
            className="card"
            style={{
              border: "none",
              backgroundColor: "whitesmoke",
              borderRadius: "20px",
            }}
          >
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div>
                    <div>
                      <small>Loan EMI</small>
                    </div>
                    <h3>
                      <strong>
                        ₹ {(Number(LoanAmount) + Number(Interest)).toFixed(0)}
                      </strong>
                    </h3>
                  </div>

                  <div className="mt-4">
                    <div>
                      <small>Principal Amount</small>
                    </div>
                    <h5>
                      <strong>₹ {LoanAmount}</strong>
                    </h5>
                  </div>

                  <div className="mt-4">
                    <div>
                      <small>Interest Amount</small>
                    </div>
                    <h5>
                      <strong>₹ {Interest}</strong>
                    </h5>
                  </div>

                  <div className="mt-4">
                    <div>
                      <small>Total Amount Payable</small>
                    </div>
                    <h5 style={{minWidth:'13rem'}}>
                      <strong>
                        ₹ {Number(LoanAmount) + Number(Interest).toFixed(0)}
                      </strong>
                    </h5>
                  </div>
                </div>
                {/* <h4 className="card-title">Doughnut Chart</h4> */}
                <div>
                  <Doughnut
                    data={doughnutPieData}
                    options={doughnutPieOptions}
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="mt-3"
              >
                <h6 style={{ wordWrap: "break-word" }}>
                  Learn more about your interest schedules
                </h6>
                <Button variant="danger" style={{ borderRadius: "5px" }}>
                  View Details
                </Button>
              </div>

              <div className="mt-3">
                <p>
                  <small>
                    This is an indicative tool to help calculate loan EMI. Loan
                    category, maximum loan amount, loan tenure and rate of
                    interest may vary on Freecharge.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default LoanUI;
