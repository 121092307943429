import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
// import FingpayCms from "../../../assets/images/Fingpay 1.png";
import FingpayCms from "../../../assets/images/Fingpay 1.png"
import CryptoJS from "crypto-js";
import { createHash } from "../../utils/utilFuctions";

const CmsFingpay = () => {
    const [token, setToken] = useState("");
    const [data, setData] = useState("");
    const [data2, setData2] = useState("");
    const { user } = useContext(GlobalContext);

  const validate = (event) => {
  openInNewTab(
        `https://fpcorp.tapits.in/UberCMSBC/#/login?data=${data}&skey=${data2}`
      );
   };

  const openInNewTab = (data) => {
    const newWindow = window.open(data, "", "height=800,width=1000'");
    if (newWindow) newWindow.opener = null;
  };

  const getUrl = () => {
    const hash = createHash(user.ReferId);
    let loginrequestdata = {
      additionalParams: null,
      latitude: 13.0641367,
      longitude: 80.2480973,
      loginType: "2",
      supermerchantId: 1179,
      merchantId: user.ReferId,
      merchantPin: user.ReferId,
      mobileNumber: "9168805979",
      amount: "100",
      superMerchantSkey:
        "9db3f8c9dbe3fa2f0986ad8a17541d6671e2fba0a406753a1909d91164d3d5f4",
    };

    console.log(loginrequestdata);
    let parsedBase64Key = CryptoJS.enc.Base64.parse(
      loginrequestdata.superMerchantSkey
    );

    console.log(parsedBase64Key);
    const encryptInfo = window.btoa(
      CryptoJS.AES.encrypt(JSON.stringify(loginrequestdata), parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      })
    );
    setData(encryptInfo);
    setData2(loginrequestdata.superMerchantSkey);
  };

  useEffect(() => {
    getUrl();
}, []);
return (
    <>
      <div className="d-flex justify-content-center align-items-center p-4">
        <div className="card shadow "
       style={{
        width: "100%",
        background: "#D6E3F5",
        border: "2px solid #5097FF",
        borderRadius: "15px",
      }}
        >
          <div className="row m-0 p-0">
          <div className="col-5 d-flex justify-content-center align-items-center">
              <img
                alt=""
                style={{ height: "17rem" , width:"auto" , marginBottom:"1.5rem" }}
                src={FingpayCms}
              />
            </div>
            <div className="col-7 p-md-5 p-sm-4">
              {" "}
              <h4 className="d-flex align-items-center"
              style={{color:"#5097FF"}}>Fingpay CMS Service</h4>
              <p
                className="d-flex align-items-center"
                style={{ color: "black", fontSize: "1rem" }}
              >
                Cash Management Services (CMS) FingPay Bank's Cash Management
                Services offers a full range of products and services to
                efficiently process your receivables and payables. We cater to
                all your cash management requirements to optimise your cash flow
                position and to facilitate effective management of your business
                operation.
              </p>
              <button
                className="btn-sm  d-flex align-items-center justify-content-center mt-md-3"
                style={{
                  borderRadius: ".2rem",
                  fontWeight: "bold",
                  width: "10rem",
                  border: "none",
                  color: "#ffff",
                  fontSize: "1rem",
                  height: "2em",
                  display: "flex",
                  background:"#5097FF",

                }}
                onClick={() => {
                    validate();
                }}
              >
                {" "}
                Open CMS
              </button>
            </div>
           
          </div>
       
        </div>
      
      </div>
      <div className="d-flex justify-content-center align-items-center p-4">

      <div 
      className="card shadow "
       style={{
        width: "100%",
        borderRadius: "15px",
      }}>
        <div className="pl-3 pr-3">
            <h5 style={{color:"#0074D9"}}><b>Information</b></h5>
            <p>
              Cash Management Services (CMS) FingPay Bank's Cash Management
              Services offers a full range of products and services to
              efficiently process your receivables and payables. We cater to all
              your cash management requirements to optimise your cash flow
              position and to facilitate effective management of your business
              operation. Benefits of Cash Management Services Leverage of vast
              network of locations within India Wide range of CMS products
              Efficient logistics support and advanced technology support
              Ensuring timely deposit of collections and timely execution of
              disbursement requests
            </p>
            </div>
          </div>
          </div>
    </>
  );
};

export default CmsFingpay;

