import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { generateHashKey, getLatLong } from "../../utils/utilFuctions";


export const checkRemitterAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/fetchCustomer`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const createRemitterOtpAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/createCustomerOTP`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const createRemitterAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/createCustomer`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const getBeneficiaryAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/fetchBeneficiaries`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const sendMoneyOtpAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/fundTransferOtpV1`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const sendMoneyAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/fundTransferV1`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const sendMoneyAPI2 = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/YesBank/Main/sendMoney2`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const addBeneficiarySendOTPAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/YesBank/Main/addBeneficiarySendOTP`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const addBeneficiaryAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/addBeneficiary`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const verifyBeneficiaryAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/Bankit/Main/bankVerify`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const deactivateBeneficiaryAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/YesBank/Main/beneficiaryDeactivate`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};

export const activateBeneficiaryAPI = async (req) => {
    try {
        const location = await getLatLong();
        const hash = await generateHashKey(req.userid);
        req.latitude = location[0];
        req.longitude = location[1];
        req.device = "WEB";
        var myHeaders = new Headers();
        myHeaders.append("HASH_KEY", hash);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/DMT/YesBank/Main/beneficiaryActivate`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("An error occurred while checking remitter.");
    }
};
