import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dropdown,
  Form,
  Card,
  InputGroup,
  Nav,
  Tabs,
  Tab,
  Col,
  Row,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import "./MyCss.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "rch_operator_id",
    text: "Operator ",
  },
  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "tid",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "rch_com_retailer",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];

const BBPSReportTable = ({ agentId }) => {
  const [data, setData] = useState([]);
  const { user } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo?.shopname })
    );
    const newWindow = window.open(
      `/pages/bill_print?id=${data.tid}&type=Bill`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const fetchBBPSReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: agentId,
        mobile: "",
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        report_type: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_bbps_report_all.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setData(
            result.filter((e) => {
              return e.rch_service != "REC";
            })
          );
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };
  // did mount
  useEffect(() => {
    if (agentId && agentId.length > 4) fetchBBPSReport();
  }, [agentId]);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  
  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height:'20%',
   
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <>
      <div>

      <div className="row m-0 p-0  pt-1 align-items-center">
              <div className="col-4 m-0 p-0">

               <div className="m-0 p-0 pl-2"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                  }}
                >
                 BBPS History
                </div>
                </div>

{/* <div className="col-8 m-0 p-0 ">
<div className="row  m-0 p-0  justify-content-end">
        
                <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
        
              
        
        
        
        <div className="pr-1 pl-2 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

        
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
            <Button
              className="rounded-50 btn btn-primary btn-lg btn-block "
              
              onClick={fetchBBPSReport}            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
        <div className="m-0 p-0 hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `BBPS_Report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
              <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
             <div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
      </div>




</div> */}

      </div>

      <div
            className="tab-custom-pills-horizontal"
            style={{ background: "#FFFFFF" }}
          >
            <div className="row m-0 p-0">
            <div className=" col-4 tab-header justify-content-between  m-0 p-0">
              <div
                className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                onClick={() => handleTabClick(0)}
              >
                Online
              </div>
              <div
                className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                Hybrid
              </div>
            </div>
         
            </div>
            <div className="tab-content">
              {activeTab === 0 && (
                <div>
                  <Tab.Pane eventKey="first">
                  <div className="col-8 m-0 p-0 ">
                  <div className="row  m-0 p-0  justify-content-start">
        
                <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
        
              
        
        
        
        <div className="pr-1 pl-2 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

        
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
            <Button
              className="rounded-50 btn btn-primary btn-lg btn-block "
              
              onClick={fetchBBPSReport}            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
        <div className="m-0 p-0 hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `BBPS_Report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
              <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
             <div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
      </div>




</div>

                    <Col md={12} className="">
                     
                      <hr className="m-0 p-0"/>
                      <div className="scroll-inner">
                        <table
                         className="my-table transparent-table text-center border-bottom"
                         style={{ width: "100%", overflowX: "auto" ,border: "0px" ,overflow: "auto", }}
                        >
                          <tr className="borderless-table text-center border-bottom">
                            <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Sr"}</th>
                            {columns.map((heading) => (
                              <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                {heading.text}
                              </th>
                            ))}
                            <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Action"}</th>
                          </tr>
                          {dataToDisplay
                            .filter((e) => {
                              return (
                                e.ttyp &&
                                e.ttyp.toString().toLowerCase() == "online"
                              );
                            })
                            .map((e, ind) => (
                              <tr className="border-bottom">
                                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                  {" "}
                                  {ind + 1}
                                </td>
                                {columns.map((cell) =>
                                  cell.text == "Status" ? (
                                    e[cell.dataField] == "success" ||
                                    e[cell.dataField] == "Success" ? (
                                      <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                        {" "}
                                        <label
                                           style={{
                                            paddingLeft:"3px",
                                            paddingRight:"3px",
                                            padding: "5px",
                                            borderRadius: "5px",
                                            border: "1px solid",
                                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                                            color:'rgba(43, 193, 85, 1)',
                                            bordercolor: 'rgba(43, 193, 85, 1)',
                        
                                          }}
                                        >
                                          {"Success"}
                                        </label>{" "}
                                      </td>
                                    ) : (
                                      <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                        <label
                                          style={{
                                            minWidth: "4rem",
                                          }}
                                          className="badge badge-warning badge-pill p-2"
                                        >
                                          {e[cell.dataField]}
                                        </label>
                                      </td>
                                    )
                                  ) : (
                                    <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                      {e[cell.dataField]}
                                    </td>
                                  )
                                )}
                                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                  <div className="row d-flex justify-content-center">
                                    {" "}


                                    <Button 
                  style={{
                   
                    borderRadius: "5px",
                    border: "1px none",
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color:'rgba(255, 164, 27, 1)',
                    bordercolor: 'rgba(255, 255, 255, 1)',
                    "fontSize":"4px !important"
                  }}
                  className="btn rounded-100  m-0 p-0">
                    {" "}
                    <div 
                      className="hover-container"
                    
                      onClick={() => {
                        openInNewTab(e);
                      }}
                    >
                                         <img
                                    src={require("../../../assets/images/printer.png")}
                                    style={{ width: "" }}
                                    />
                       <div style={{"fontFamily":"Poppins",  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark text-center px-2 py-1 mb-2">Print</div>
                    </div>

                  
                  </Button>
                                   
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </table>
                      </div>

                      <Pagination
                        page={currentPage}
                        limit={pages}
                        callback={(page) => {
                          setCurrentPage(page);
                        }}
                        count={dataToDisplay.length}
                        total={total}
                        callback2={(offsetValue) => {
                          setOffset(offsetValue);
                        }}
                      />
                    </Col>
                  </Tab.Pane>
                </div>
              )}
              {activeTab === 1 && (
                <div>
                  <Tab.Pane eventKey="second">
                  <div className="col-8 m-0 p-0 ">
                  <div className="row  m-0 p-0  justify-content-start">
        
                <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
        
              
        
        
        
        <div className="pr-1 pl-2 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

        
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
            <Button
              className="rounded-50 btn btn-primary btn-lg btn-block "
              
              onClick={fetchBBPSReport}           >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
        <div className="m-0 p-0 hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `BBPS_Report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
              <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
             <div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
      </div>




</div>

                    <Col md={12} className="">
                      
                     <hr className="m-0 p-0"/>
                      <div className="scroll-inner">
                        <table
                         className="my-table transparent-table text-center border-bottom"
                         style={{ width: "100%", overflowX: "auto" ,border: "0px" ,overflow: "auto", }}
                        >
                          <tr  className="borderless-table text-center border-bottom">
                            <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Sr"}</th>
                            {columns.map((heading) => (
                              <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                {heading.text}
                              </th>
                            ))}
                            <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Action"}</th>
                          </tr>
                          {dataToDisplay
                            .filter((e) => {
                              return (
                                e.ttyp &&
                                e.ttyp.toString().toLowerCase() != "online"
                              );
                            })
                            .map((e, ind) => (
                              <tr  className="border-bottom">
                                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                  {" "}
                                  {ind + 1}
                                </td>
                                {columns.map((cell) =>
                                  cell.text == "Status" ? (
                                    e[cell.dataField] == "success" ||
                                    e[cell.dataField] == "Success" ? (
                                      <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                        {" "}
                                        <label
                                          style={{
                                            minWidth: "4rem",
                                          }}
                                          className="badge badge-success badge-pill p-2"
                                        >
                                          {"Success"}
                                        </label>{" "}
                                      </td>
                                    ) : (
                                      <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                        <label
                                          style={{
                                            minWidth: "4rem",
                                          }}
                                          className="badge badge-warning badge-pill p-2"
                                        >
                                          {e[cell.dataField]}
                                        </label>
                                      </td>
                                    )
                                  ) : (
                                    <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                                      {e[cell.dataField]}
                                    </td>
                                  )
                                )}
                                <td>
                                  <div className="row d-flex justify-content-center">
                                    {" "}
                                    <Button
                                      onClick={() => {
                                        openInNewTab(e);
                                      }}
                                    >
                                      {" "}
                                      <i class="mdi mdi-printer"></i>
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </table>
                      </div>

                      <Pagination
                        page={currentPage}
                        limit={pages}
                        callback={(page) => {
                          setCurrentPage(page);
                        }}
                        count={dataToDisplay.length}
                        total={total}
                        callback2={(offsetValue) => {
                          setOffset(offsetValue);
                        }}
                      />
                    </Col>
                  </Tab.Pane>
                </div>
              )}
            </div>
          </div>

       
      </div>
    </>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
   
    borderRadius:'0px',
    "fontFamily":"Poppins",
    'fontsize': '16px', 
    borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
    borderRadius:'0px',
    borderLeft: 'none', // Remove right side border
    borderTop: 'none', // Remove top border
    borderBottom: 'none', // Remove bottom border
    "fontFamily":"Poppins",
    'fontsize': '16px', 
   



  };
  const selectStyleBorder = {
    border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
    borderRadius:'8px',
    "fontFamily":"Poppins",
    'fontsize': '16px', 
  };
  const selectStyle = {
 
    border: 'none', // Remove the border
    "fontFamily":"Poppins",
    'fontsize': '16px', 

  };
  return (
    <div
    className="row"
    style={{
      display: "flex",
      alignItems: "end",
      width: "100%",
      margin: "10px 0px",
      justifyContent: "space-between",
      
    }}
  >
    <div style={{"fontFamily":"Poppins",}}>
      <strong>{`Showing ${count} of ${total} entries`}</strong>
    </div>
   
   
    <div className="d-flex btn-group p-1"    style={selectStyleBorder}>
      <select
       style={selectStyleLeftBorder}
        className=" d-flex justify-content-end "
        aria-label="offset"
        onChange={(e) => {
          callback2(e.target.value);
        }}
        
      >
        {offsetArr.map((offset) => (
          <option value={offset} className="d-flex justify-content-end">{offset}</option>
        ))}
        
      </select>
      {page - 2 > 0 ? (
        <Button
          onClick={() => {
            callback(page - 1);
          }}
        
          className="rounded-0 bg-white text-dark border-white"
          
        >
          {"<<"}
        </Button>
      ) : null}

      {page - 1 > 0 ? (
        <Button
          onClick={() => {
            callback(page - 1);
          }}
         
          className="rounded-0 bg-white text-dark border-white underline-text"
        >
          {" "}
          {(page - 1).toString()}
        </Button>
      ) : null}
     
     
     
      <Button className="bg-white text-primary "   style={selectStyle}> {page.toString()}</Button>
    
    
    
    
    {/* Next And Previous Button Code */}
      {page + 1 <= limit ? (
        <Button
          onClick={() => {
            callback(page + 1);
          }}
         
          className="rounded-0 bg-white text-dark border-white"
        >
          {">>"}
        </Button>
      ) : null}
    </div>
  </div>
  );
};

export default BBPSReportTable;
