import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, Redirect, useHistory } from "react-router-dom";
import cogoToast from "cogo-toast";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
const AdminLogin2 = () => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const { setUserInfo, setTransactionLoaderState } = useContext(GlobalContext);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = () => {
    setUsernameError("");
    setPasswordError("");
    setOtpError("");

    if (username.trim() === "") {
      setUsernameError("Username is required.");
      return;
    }

    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters.");
      return;
    }
    sendOtpToServer();
  };

  const sendOtpToServer = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        mobile: username,
        password: password,
        step: "1",
        otp: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/login`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setShowOtpField(true);
      } else {
        cogoToast.error(data.msg);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const validateOtpOnServer = async () => {
    setOtpError("");

    if (otp.trim() === "") {
      setOtpError("OTP is required.");
      return;
    }

    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        mobile: username,
        password: password,
        step: "2",
        otp: otp,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/login`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        cogoToast.success(data.msg);
        setUserInfo(data.data);
        history.push("/admin/dashboard");
      } else {
        cogoToast.error(data.msg);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        mobile: username,
        password: password,
        step: "",
        otp: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/login`,
        requestOptions
      );
      console.log(requestOptions);
      const data = await res.json();
      if (data.status === "0") {
        cogoToast.success(data.msg);
      } else {
        cogoToast.error(data.msg);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const handleForgotPassword = () => {};

  return (
    <>
      <div>
        <div className="row">
          <div
            className="col"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img
                alt=""
                style={{ height: "8em" }}
                src={require("../../../assets/images/login_new.png")}
              />
            </div>
          </div>

          <div className="col " style={{ background: "#fff" }}>
            <div
              className="col d-flex align-items-center d-flex justify-content-center"
              style={{ background: "#fff", minHeight: "100vh" }}
            >
              <div className="card d-flex justify-content-center ">
                <h4
                  className="d-flex justify-content-center px-4"
                  style={{ color: "#457dbd" }}
                >
                  <b>Login to BUMPPY PAYMENT ADMIN</b>
                </h4>
                <h6 className="d-flex justify-content-center">
                  India's Best Online Money Transfer Platform.
                </h6>
                <form className="mt-md-4 px-md-3" onSubmit={handleSubmit}>
                  <div className="form-group col-12 ">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      id="mobile"
                      className="form-control"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                    {usernameError && (
                      <p className="text-danger">{usernameError}</p>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="password">Password</label>
                    <div className="input-group">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-lock-outline text-primary"></i>
                      </span>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={handlePasswordChange}
                      />

                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={handleTogglePassword}
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                    </div>
                    {passwordError && (
                      <p className="text-danger">{passwordError}</p>
                    )}
                  </div>
                  <div className="form-group col-12">
                    {showOtpField && (
                      <>
                        <label htmlFor="otp">One-Time Password (OTP)</label>
                        <input
                          type="text"
                          id="otp"
                          className="form-control mb-md-3"
                          value={otp}
                          onChange={handleOtpChange}
                        />
                        {otpError && <p className="text-danger">{otpError}</p>}
                      </>
                    )}

                    {showOtpField && (
                      <div className="col-12 row p-0">
                        <div className="col-6"></div>
                        <div className="col-6">
                          {" "}
                          <button
                            type="button"
                            className="btn btn-success btn-block"
                            onClick={handleSignIn}
                          >
                            {"Resend OTP"}
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={
                          showOtpField ? validateOtpOnServer : handleSignIn
                        }
                      >
                        {showOtpField ? "Submit OTP" : "Sign In"}
                      </button>
                    </div>
                  </div>

                  <div className="text-center mt-2">
                    <Link to="/admin/forget-password">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={handleForgotPassword}
                      >
                        Forgot Password?
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex justify-content-center mx-md-2 pb-md-2 ">
              <label>
                Invisible reCAPTCHA by Google{" "}
                <a href="#">Privacy Policy & Terms of Use</a>
              </label>
            </div>
            <div className="d-flex justify-content-center mx-md-2 pb-md-2 ">
              <div className="row">
                <div className="col-4">
                  <a href="#">Legal</a>
                </div>
                <div className="col-4">
                  <a href="#">Privacy</a>
                </div>
                <div className="col-4">
                  <a href="#">security</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin2;
