import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
 excelMapping,
 ExportToExcel,
 twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import { getPaynnowTransactionStatus, getPaynnowTransactionStatus2 } from "../util/utility";
import { Modal } from "react-bootstrap";

export const columns = [

 {
 dataField: "rch_transaction_id",
 text: "TXN ID",
 },
 {
 dataField: "payu_id",
 text: "payu id",
 },
 {
 dataField: "rch_insert_date",
 text: "date",
 },
 {
 dataField: "user_code",
 text: "User Code",
 },
 {
 dataField: "user_name",
 text: "User Name",
 },
 {
 dataField: "rch_account_no",
 text: "Account Number",
 },
 {
 dataField: "rch_ifsc_code",
 text: "IFSC Code",
 },
 {
 dataField: "rch_before_balance",
 text: "Opening Balance",
 },
 {
 dataField: "rch_amount",
 text: "Amount",
 },
 {
 dataField: "rch_other_charges",
 text: "Charges",
 },
 {
 dataField: "rch_after_balance",
 text: "Closing Balance",
 },
 {
 dataField: "rch_com_cnf",
 text: "Master Comm",
 },
 {
 dataField: "rch_com_distributer",
 text: "Dist Comm",
 },
 {
 dataField: "rch_com_retailer",
 text: "Retailer Comm",
 },
 {
 dataField: "sales_user_code",
 text: "Sales Code",
 },
 {
 dataField: "sales_user_name",
 text: "Sales Name",
 },
 {
 dataField: "sales_user_mobile",
 text: "Sales Mobile Number",
 },
 {
 dataField: "status",
 text: "Status",
 },
];

const UserCmsReportTable = ({agentId}) => {
 const [pageAccess, setPageAccess] = useState({});
 const [data, setData] = useState([]);
 const [currentPage, setCurrentPage] = useState(1);
 const [searchText, setSearchText] = useState("");
 const [offset, setOffset] = useState(10);
 const [dataToDisplay, setDataToDisplay] = useState([]);
 const [pages, setPages] = useState(0);
 const [total, setTotal] = useState(0);
 const [startDate, setStartDate] = useState(new Date());
 const [endDate, setEndDate] = useState(new Date());
 const { user, setTransactionLoaderState } = useContext(GlobalContext);
 const [modalOpen2, setModalOpen2] = useState(false);
 const [billDetails, setBillDetails] = useState({});
 const [amount, setAmount] = useState("");

 const dateFormatter = (date) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };
 const now = (date = new Date()) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };
 const fetchVendorPaymentsReport = async () => {
 try {
 var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append(
 "Authorization",
 "Basic " + Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString("base64")
 );
 var raw = JSON.stringify({
 userid: agentId,
 adminId: user.pkid,
 fromDate: dateFormatter(startDate),
 toDate: dateFormatter(endDate),
 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
 `${BASE_URL_APISERVICES}/api/Admin/getCMSReport`,
 requestOptions
 )

 .then((response) => response.json())
 .then((result) => {
 if (result.responseCode == "00") {
 setData(result.data);
 } else {
 setData([]);
 }
 })
 .catch((error) => console.log("error", error));
 } catch (error) {
 console.log(error);
 }
 };

 const fetchData = () => {
 fetchVendorPaymentsReport(startDate, endDate);
 };

 const getTransactionStatus = async (tid) => {
 const data = await getPaynnowTransactionStatus(tid);
 if (data.responseCode == "00") {
 swal("Success", `Status : ${data.status}`, "success");
 } else {
 swal("Failed", `Status : ${data.status}`, "error");
 }
 }

 const getTransactionStatus2 = async (tid) => {
 const data = await getPaynnowTransactionStatus2(tid);
 if (data.responseCode == "00" && data.data.status.toString().toLowerCase() == "success") {
 let message = "";
 if (data.data.status) {
 message = `API Status : ${data.data.txnStatus} \n`;
 }
 if (data.data.responseMessage) {
 message += `${data.data.responseMessage}`;
 }
 if (data.data.channelReferenceNo) {
 message += ` with RRN [${data.data.channelReferenceNo}]`;
 }
 swal("Success", message, "success");
 // swal("Success", `Status : ${data.data.status} [${data.data.responseMessage}] with RRN [${data.data.channelReferenceNo}]`, "success");
 } else {
 swal("Failed", `Status : ${data.status}`, "error");
 }
 }


 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
 setTotal(filteredData.length);
 const startIdx = 0;
 const endIdx = offset - 1;
 filteredData = filteredData.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 setCurrentPage(1);
 }, [searchText]);

 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 console.log(filteredData);
 const startIdx = (currentPage - 1) * offset;
 const endIdx = currentPage * offset - 1;
 console.log({ startIdx, endIdx });
 filteredData = filteredData.filter((bank, idx) => {
 console.log(idx, idx <= endIdx && idx >= startIdx);
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 console.log({ startIdx, endIdx });
 }, [currentPage]);

 // did mount
 useEffect(() => {
 setPages(Math.floor((data.length + (offset - 1)) / offset));
 const startIdx = 0;
 const endIdx = offset - 1;
 setTotal(data.length);
 console.log(startIdx);
 console.log(data);
 const filteredData = data.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 console.log(filteredData);
 setDataToDisplay(filteredData);
 }, [data, offset]);


 const searchBarStyle = {
 display: 'flex',
 alignItems: 'center',
 border: '1px solid #ccc',
 borderRadius: '6px',
 padding: '0px',
 backgroundColor: '#fff',
 width: '15%', // Adjust the width as needed
 height: '20%',

 };
 const inputStyle = {
 border: 'none',
 outline: 'none',
 backgroundColor: 'transparent',
 fontSize: '16px',
 marginLeft: '8px',
 width: '100%',
 "fontFamily": "Poppins",
 "fontWeight": "400",
 };
 const iconStyle = {
 color: 'rgba(0, 0, 0, 0.5)',
 };

 const positionStyle = {
 position: 'absolute',
 top: -27,
 left: 0,
 "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
 };
 const positionStyle1 = {
 position: 'absolute',
 top: -38,
 left: 0,
 "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
 };

 const getPageAccess = (menuId) => {
 const sub_menus_access_info = JSON.parse(sessionStorage.getItem("sub_menus"));
 sub_menus_access_info.forEach((acc) => {
 if (acc.pk_menu_id == menuId) {
 setPageAccess(acc);
 }
 })
 }

 const setModalData = (billDetails) => {
 setBillDetails(billDetails);
 setModalOpen2(true);
 };


 useEffect(() => {
 fetchVendorPaymentsReport()
 getPageAccess(14);
 }, [])

 const openInNewTab = (data) => {
 localStorage.setItem(
 "print",
 JSON.stringify({ ...data, })
 // shopName: Profileinfo.shopname
 );
 const newWindow = window.open(
 `/pages/vendor_print2?id=${data.rch_transaction_id}&type=IMPS`,
 "",
 "height=600,width=800'"
 );
 };


 return (

 <>
 <UpdateTariffPlanModal
 isModalOpen={modalOpen2}
 billDetails={billDetails}
 amount={amount}
 setModalOpen2={(modalState) => {
 setModalOpen2(modalState);
 }}
 refresh={()=>{
 setModalOpen2(false);
 fetchVendorPaymentsReport();
 }}
 />

 <div>
 <div className="row m-0 p-0 pt-1 align-items-center">
 <div className="col-3 m-0 p-0">
 <div
 className="m-0 p-0 pl-3 align-top"
 style={{
 fontFamily: "Poppins",
 fontWeight: "600",
 lineHeight: "48px",
 letterSpacing: "0em",
 textAlign: "left",
 padding: "0px",
 background: "#FFFFFF",
 color: "#4267B2",
 fontWeight: "bolder",
 fontfamily: "Poppins !impotent",
 fontSize: "28px",
 }}
 >
 CMS Report
 </div>
 </div>

 <div className="col-9 m-0 p-0 ">
 <div className="row m-0 p-0 justify-content-end">
 <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
 <FontAwesomeIcon
 icon={faSearch}
 style={iconStyle}
 className="ml-2"
 />
 <input
 type="text"
 className="form-control "
 placeholder="Search"
 aria-label="Search"
 style={inputStyle}
 onChange={(e) => {
 setSearchText(e.target.value);
 }}
 value={searchText}
 />
 </div>

 <div
 className="pr-1 pl-2 p-0 m-0"
 style={{ width: "18%", height: "20%" }}
 >
 <Form.Group className="p-0 m-0">
 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={startDate}
 onChange={(date) => setStartDate(date)}
 className="form-control rounded-100"
 placeholderText="Start Date"
 />
 </Form.Group>
 </div>
 <div
 className="px-1 p-0 m-0"
 style={{ width: "18%", height: "20%" }}
 >
 <Form.Group className="p-0 m-0">
 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={endDate}
 onChange={(date) => setEndDate(date)}
 className="form-control rounded-100"
 placeholderText="End Date"
 />
 </Form.Group>
 </div>

 <div
 className="pl-1 p-0 m-0"
 style={{ width: "14%", height: "20%" }}
 >
 <Form.Group className="mt-0 p-0 m-0">
 <Button
 className=" btn btn-primary btn-lg btn-block "
 style={{
 background: "#4267B2",
 color: "white"
 }}
 onClick={fetchData}
 >
 {"Search"}
 </Button>
 </Form.Group>
 </div>

 <div
 className="m-0 p-0 hover-container"
 style={{ cursor: "pointer" }}
 onClick={() => {
 ExportToExcel(
 `VENDOR_PAYMENT_REPORT_${now()}`,
 excelMapping(data, columns)
 );
 }}
 >
 <img
 className="pl-1 p-0"
 src={require("../../../assets/images/download Icon.png")}
 style={{ width: `3.5rem`, height: "85%" }}
 />
 <div style={positionStyle1} className="hover-text bg-dark ">
 Export to Excel
 </div>
 </div>
 </div>
 </div>
 </div>

 <hr className="m-0 p-0" />

 <div style={{ width: "100%" }}>
 <div style={{ alignItems: "center" }}>
 <div style={{ flex: "1" }}></div>
 </div>
 </div>
 <div className=" scroll-inner admin-fix-width borderless-table ">
 <table
 className="my-table transparent-table text-center border-bottom"
 style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}
 >
 <tr className="borderless-table text-center border-bottom">
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Txnt ID
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 User
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Depositor Mobile
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Account
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Opening Balance
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Amount
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Closing Balance
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Charges
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Status
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Date
 </th>
 
 </tr>
 {dataToDisplay.map((e, ind) => (
 <tr className="border-bottom">
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 {" "}
 {ind + 1}
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.tid}</div>
 </td>

 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.user_code}</div>
 <div>{e.user_name}</div></td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.customer_mobile}</div>
 {/* <div>{e.rch_ifsc_code}</div> */}
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.accountNumber}</div>
 {/* <div>{e.rch_ifsc_code}</div> */}
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{twoDecimalPlaces(e.opening_bal)}</td>

 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{twoDecimalPlaces(e.amount)}</div>
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{twoDecimalPlaces(e.closing_bal)}</div>
 </td>
 <td className="text-center text-bold border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{twoDecimalPlaces(e.charges)}</td>
 
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 {e.status.toString().toLowerCase() == "success" ? (
 <label
 style={{
 paddingLeft: "3px",
 paddingRight: "3px",
 padding: "5px",
 borderRadius: "5px",
 border: "1px solid",
 backgroundColor: 'rgba(43, 193, 85, 0.1)',
 color: 'rgba(43, 193, 85, 1)',
 bordercolor: 'rgba(43, 193, 85, 1)',
 }}
 >
 {"Success"}
 </label>
 ) : (
 <label
 style={{
 paddingLeft: "3px",
 paddingRight: "3px",
 padding: "5px",
 borderRadius: "5px",
 border: "1px solid",
 backgroundColor: 'rgba(254, 128, 36, 0.1)',
 color: 'rgba(254, 128, 36, 1)',
 bordercolor: 'rgba(254, 128, 36, 1)',
 }}
 >
 {e.status}
 </label>
 )}
 </td> 
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.date}</td>
 
 
 </tr>
 ))}
 </table>
 </div>
 <Pagination
 page={currentPage}
 limit={pages}
 callback={(page) => {
 setCurrentPage(page);
 }}
 count={dataToDisplay.length}
 total={total}
 callback2={(offsetValue) => {
 setOffset(offsetValue);
 }}
 />
 </div>
 </>
 );
};

export const Pagination = ({
 page,
 limit,
 callback,
 total,
 count,
 callback2,
}) => {
 console.table({ page, limit, callback });
 const selectStyleLeftBorder = {

 borderRadius: '0px',
 "fontFamily": "Poppins",
 'fontsize': '16px',
 borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
 borderRadius: '0px',
 borderLeft: 'none', // Remove right side border
 borderTop: 'none', // Remove top border
 borderBottom: 'none', // Remove bottom border
 "fontFamily": "Poppins",
 'fontsize': '16px',




 };
 const selectStyleBorder = {
 border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
 borderRadius: '8px',
 "fontFamily": "Poppins",
 'fontsize': '16px',
 };
 const selectStyle = {

 border: 'none', // Remove the border
 "fontFamily": "Poppins",
 'fontsize': '16px',

 };
 return (
 <div
 className="row"
 style={{
 display: "flex",
 alignItems: "end",
 width: "100%",
 margin: "10px 0px",
 justifyContent: "space-between",

 }}
 >
 <div style={{ "fontFamily": "Poppins", }}>
 <strong>{`Showing ${count} of ${total} entries`}</strong>
 </div>


 <div className="d-flex btn-group p-1" style={selectStyleBorder}>
 <select
 style={selectStyleLeftBorder}
 className=" d-flex justify-content-end "
 aria-label="offset"
 onChange={(e) => {
 callback2(e.target.value);
 }}

 >
 {offsetArr.map((offset) => (
 <option value={offset} className="d-flex justify-content-end">{offset}</option>
 ))}

 </select>
 {page - 2 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}

 className="rounded-0 bg-white text-dark border-white"

 >
 {"<<"}
 </Button>
 ) : null}

 {page - 1 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}

 className="rounded-0 bg-white text-dark border-white underline-text"
 >
 {" "}
 {(page - 1).toString()}
 </Button>
 ) : null}



 <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




 {/* Next And Previous Button Code */}
 {page + 1 <= limit ? (
 <Button
 onClick={() => {
 callback(page + 1);
 }}

 className="rounded-0 bg-white text-dark border-white"
 >
 {">>"}
 </Button>
 ) : null}
 </div>
 </div>
 );
};

const UpdateTariffPlanModal = ({
 billDetails,
 isModalOpen,
 setModalOpen2,
 refresh
}) => {
 const twoDecimalPlaces = (value) => {
 return Number(value).toFixed(2).toString();
 };
 const [remark, setRemark] = useState("");
 const { user, setTransactionLoaderState } = useContext(GlobalContext);

 const handleRemarkChange = (e) => {
 setRemark(e.target.value);
 };


 console.log(remark);

 const handleConfirm = () => {
 console.log("Remark:", remark);
 vendorPaymentsRefund(billDetails.id);
 };

 const vendorPaymentsRefund = async (tid) => {
 try {
 setTransactionLoaderState(true);
 const myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");


 var raw = JSON.stringify({
 adminid: user.pkid,
 txnId: tid,
 remark: remark
 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
 `${BASE_URL_APISERVICES}/api/Admin/vendorPaymentRefundAdmin`,
 requestOptions
 );
 const data = await res.json();
 if (data.responseCode == "00") {
 swal("Success", data.responseMessage, "success");
 } else {
 swal("Failed", data.msg, "error");
 }

 refresh()

 setTransactionLoaderState(false);
 } catch (error) {
 setTransactionLoaderState(false);
 console.log("error", error);
 }
 };

 return (
 <Modal
 show={isModalOpen}
 onHide={() => setModalOpen2(false)}
 aria-labelledby="example-modal-sizes-title-md"
 size="s"
 >
 <Modal.Body
 style={{ width: "100%", display: "block", overflowY: 'hidden', height: "17rem" }}
 className="account"
 >
 <div
 style={{
 fontFamily: "Poppins",
 fontSize: "28px",
 fontWeight: "600",
 lineHeight: "48px",
 textAlign: "left",
 padding: "0px",
 background: "#FFFFFF",
 color: "#4267B2",
 }}
 className="d-flex justify-content-between pl-2"
 >
 <strong>Are you sure?</strong>
 <strong
 className="px-1 hover"
 onClick={() => {
 setModalOpen2(false);
 }}
 >
 <img
 src={require("../../../assets/images/Vector.png")}
 alt="Close"
 />
 </strong>
 </div>

 <div style={{ padding: "20px" }}>
 <p>Refund amount of {twoDecimalPlaces(billDetails.rch_amount)} to {billDetails.user_code} </p>
 <div className="form-group">
 <label htmlFor="remark">Remark</label>
 <input
 type="text"
 className="form-control"
 id="remark"
 placeholder="Enter your remark"
 value={remark}
 onChange={handleRemarkChange}
 />
 </div>
 <div className="d-flex justify-content-end">
 <button
 className="btn btn-danger mr-2"
 onClick={handleConfirm}
 >
 Confirm
 </button>
 <button
 className="btn btn-secondary"
 onClick={() => setModalOpen2(false)}
 >
 Cancel
 </button>
 </div>
 </div>
 </Modal.Body>
 </Modal>
 );
};

export default UserCmsReportTable;