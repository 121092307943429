import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
    excelMapping,
    ExportToExcel,
    formatDateDDMMYYHHMM,
    twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { BASE_URL_API_TRANXT, BASE_URL_APISERVICES } from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
    {
        dataField: "date_time",
        text: "Date & Time",
        color: "#000",
    },
    {
        dataField: "Narration",
        text: "Narration",
        color: "#000",
    },
    {
        dataField: "Reference",
        text: "Reference",
        color: "#000",
    },
    {
        dataField: "Opening_Balance",
        text: "Opening Balance",
        color: "#000",
    },
    {
        dataField: "trans_amount",
        text: "Amount",
        color: "#000",
    },

    {
        dataField: "Closing_Balance",
        text: "Closing Balance",
        color: "#000",
    },
    {
        dataField: "fee",
        text: "Fee",
        color: "#000",
    },
    {
        dataField: "commission",
        text: "Commission",
        color: "#000",
    },
    {
        dataField: "tds",
        text: "Tds",
        color: "#000",
    },
];

const EpanReportTable = ({ agentId }) => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { user } = useContext(GlobalContext);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const fetchLedgerReport = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                userid: "BR1050",
                adminid: "",
                fromdate: dateFormatter(startDate),
                todate: dateFormatter(endDate),
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/reports/getEpanReportRetailer`,
                requestOptions
            )

                .then((response) => response.json())
                .then((result) => {
                    if (result.status == "0" && result.data) {
                        setData(result.data);
                    } else {
                        setData([]);
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = () => {
        fetchLedgerReport(startDate, endDate);
    };

    const openInNewTab = () => {
        const newWindow = window.open(
            `${CLIENT}/pages/print?id=090422191784660&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    // did mount
    useEffect(() => {
        if (agentId && agentId.length > 4) fetchLedgerReport();
    }, [agentId]);




    const searchBarStyle = {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '6px',
        padding: '0px',
        backgroundColor: '#fff',
        width: '25%', // Adjust the width as needed
        height: '20%',

    };
    const inputStyle = {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        fontSize: '16px',
        marginLeft: '8px',
        width: '100%',
        "fontFamily": "Poppins",
        "fontWeight": "400",
    };
    const iconStyle = {
        color: 'rgba(0, 0, 0, 0.5)',
    };



    return (



        <div >

            <div className="row m-0 p-0  pt-1 align-items-center">
                <div className="col-4 m-0 p-0">

                    <div className="m-0 p-0 pl-2"
                        style={{
                            "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                            , padding: "0px",
                            background: "#FFFFFF",
                            color: "#4267B2",
                            fontWeight: "bolder",
                            fontfamily: "Poppins !impotent",
                            fontSize: "28px",
                        }}
                    >
                        Epan Report
                    </div>
                </div>

                <div className="col-8 m-0 p-0 ">
                    <div className="row  m-0 p-0  justify-content-end">

                        <div style={searchBarStyle} className=" m-0 p-0 pr-2">
                            <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Search"
                                aria-label="Search"
                                style={inputStyle}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                value={searchText}
                            />
                        </div>

                        <div className="pr-1 pl-2 p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group className="p-0 m-0">

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="Start Date"
                                />
                            </Form.Group>
                        </div>
                        <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group className="p-0 m-0">

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="End Date"
                                />
                            </Form.Group>
                        </div>


                        <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
                            <Form.Group className="mt-0 p-0 m-0">
                                <Button
                                    className=" rounded-100 btn btn-lg btn-block "

                                    onClick={fetchData}
                                >
                                    {"Search"}
                                </Button>
                            </Form.Group>
                        </div>
                        <div className="m-0 p-0 hover-container"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                ExportToExcel(
                                    `Ledger_Report_${now()}`,
                                    excelMapping(data, columns)
                                );
                            }}
                        >
                            <img className="pl-1"
                                src={require("../../../assets/images/download Icon.png")}
                                style={{ width: `3.5rem`, height: '85%' }}
                            />
                            <div div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
                        </div>
                    </div>




                </div>

            </div>

            <hr className="m-0 p-0" />


            <div style={{ width: "100%" }}>
                <div style={{ alignItems: "center" }}>
                    <div style={{ flex: "1" }}></div>
                </div>
            </div>
            <div className=" scroll-inner admin-fix-width borderless-table ">
                <table
                    className="my-table transparent-table text-center border-bottom"
                    style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}
                >
                    <tr className="borderless-table text-center border-bottom">
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            User ID
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            User
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Number
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Amount
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Order Id
                        </th>

                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Ack Number
                        </th>

                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            status
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            date
                        </th>

                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className="border-bottom">
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                {" "}
                                {ind + 1}
                            </td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                <div>{e.user_code}</div>
                                <div></div>
                            </td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.user_name}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.user_mobile}</td>

                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{twoDecimalPlaces(e.amount)}</td>
                            <td className="text-center text-success text-bold border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.order_id}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.ack_no}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.status}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.date}</td>
                        </tr>
                    ))}
                </table>
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </div>





    );
};

export const Pagination = ({
    page,
    limit,
    callback,
    total,
    count,
    callback2,
}) => {
    console.table({ page, limit, callback });
    const selectStyleLeftBorder = {

        borderRadius: '0px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
        borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
        borderRadius: '0px',
        borderLeft: 'none', // Remove right side border
        borderTop: 'none', // Remove top border
        borderBottom: 'none', // Remove bottom border
        "fontFamily": "Poppins",
        'fontsize': '16px',




    };
    const selectStyleBorder = {
        border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
        borderRadius: '8px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
    };
    const selectStyle = {

        border: 'none', // Remove the border
        "fontFamily": "Poppins",
        'fontsize': '16px',

    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",

            }}
        >
            <div style={{ "fontFamily": "Poppins", }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>


            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}

                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">{offset}</option>
                    ))}

                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"

                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}



                <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default EpanReportTable;