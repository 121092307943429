import React, { useState, useEffect, useContext } from "react";
import YESBankLogo from "../../assets/images/yes_bank_Logo.png";
import bumppyLogo from "../../assets/images/Bumppy_Payment_logo.svg"
import { Button, Row } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../utils/Constants";
import { CERTIFICATE_ADDRESS, CERTIFICATE_COMPANY_NAME, COMPANY_NAME } from "../utils/StringConstants";
import { dateFormatToDisplay, inWords } from "../utils/utilFuctions";
function IdentityPrint() {
    const { Profileinfo: Profileinfo2, user, setTransactionLoaderState } = useContext(GlobalContext);
    const [kycData, setKycData] = useState({});
    const [kycData2, setKycData2] = useState({});
    const [Profileinfo, setProfileinfo] = useState({});

    const [sessionId, setSessionId] = useState("");
    const [sessionId2, setSessionId2] = useState("");

    let details = JSON.parse(localStorage.getItem('print'))


    // date format in dd/mmm/yyyy format
    const formatDate = (date) => {
        const d = new Date(date);
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day = d.getDate();
        const monthIndex = d.getMonth();
        const year = d.getFullYear();
        return `${day} ${monthNames[monthIndex]} ${year}`;
    };


    const printReceipt = () => {
        window.print();
    };


    useEffect(() => {
    }, []);

    return (
        <div>
            <div className="row justify-content-center">
                <div
                    className="card m-1 p-2 d-flex justify-content-center"
                >
                    <Row>
                        <div style={{ width: "100%" }}>
                            <div style={{ textAlign: "center" }}>
                                <h3>INDEMNITY BOND</h3>
                            </div>
                            <div className="card-body">
                                <div className="d-flex"></div>

                                <div className="row d-flex mt-2">
                                    <div className="col-6">
                                        <h5>
                                            <strong>Date:- </strong>{" "}
                                            {details.submitTime}
                                        </h5>
                                        <h5>
                                            <strong>From:- </strong>
                                        </h5>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <img
                                            src={require("../../assets/images/company/navbar_logo.png")}
                                            style={{
                                                width: "60%",
                                                height: "auto",
                                                marginLeft: "auto",
                                            }}
                                        />
                                    </div>
                                </div>

                                <table
                                    style={{ border: "1px solid black", width: "100%" }}
                                >
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Merchant/Agent name</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {details.shopname}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Agent Id</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {details.ReferId}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Registered Mobile number</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {details.mobile}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>PAN number</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            AEUPW9908B
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Amount</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            Rs. {details.amount}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Amount (In Words)</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {inWords(Number(details.amount))}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Bank Account </strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {details?.bankName} -:{" "}
                                            {details?.accountNo}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Deposit Date</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {details.date}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Payment Type</strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {details.label}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "white" }}>
                                        <td
                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                        >
                                            <strong>Remark </strong>
                                        </td>
                                        <td
                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                        >
                                            {details.remark}
                                        </td>
                                    </tr>
                                </table>

                                <div className="my-2">
                                    <strong>
                                        To,<br></br>
                                        The Board of Directors,<br></br>
                                        {CERTIFICATE_COMPANY_NAME} <br></br>
                                        <span>{CERTIFICATE_ADDRESS}</span>
                                    </strong>
                                </div>

                                <div>
                                    <p className="mb-2" style={{ fontSize: "14px" }}>
                                        Dear Sir/Madam,
                                    </p>

                                    <p style={{ fontSize: "14px" }}>
                                        I, <strong>{details?.shopname}</strong>, as a
                                        Merchant/Agent hereby undertake and explicitly agree
                                        to indemnify {CERTIFICATE_COMPANY_NAME} towards the
                                        following points:
                                    </p>

                                    <div className="p-2">
                                        <ul style={{ fontSize: "14px" }}>
                                            <li>
                                                {CERTIFICATE_COMPANY_NAME} is providing us with
                                                a platform as an enabler through which we can
                                                transfer/receive/top up the money through
                                                various methods likeUPI/ IMPS/ RTGS/ Payment
                                                Gateway etc. from one person to another(P2P and
                                                P2M) against a separate consideration;
                                            </li>
                                            <li>
                                                I am wholly and solely responsible for the
                                                collection of KYC/ meeting the Statutory
                                                requirements and other mandatory documents from
                                                the sender or receiver or both and also the
                                                reasons of such transactions;
                                            </li>
                                            <li>
                                                I am responsible and abide to provide the KYC
                                                and other mandatory documents and reasons of
                                                each and every transactions with end customers
                                                to the at {COMPANY_NAME}'s discretion;
                                            </li>
                                            <li>
                                                I am wholly and solely responsible for those
                                                transactions which was wrongly debited or
                                                credited by me to another party or any incorrect
                                                entry/entries while using the platform;and
                                            </li>
                                            <li>
                                                After obtaining a proper understanding of the
                                                transaction patterns of this Company, I am
                                                giving my consent to use this platform with all
                                                the terms and conditions as provided by the{" "}
                                                {CERTIFICATE_COMPANY_NAME} and also assuring
                                                that every sender or receiver or both only after
                                                giving their full consent using this platform
                                                for transfer / receive / topup the money through
                                                various methods like CASH / UPI / IMPS / NEFT /
                                                RTGS / Payment Gateway etc.
                                            </li>
                                        </ul>
                                    </div>

                                    <p className="mt-4" style={{ fontSize: "16px" }}>
                                        Thanking you,
                                    </p>

                                    <p style={{ fontSize: "16px", marginTop: "4rem" }}>
                                        <img style={{height:"36px",width :"auto"}} src={require("../../assets/images/br1909.png")} />
                                       <br></br>
                                        <strong>
                                            {details?.shopname}
                                            <br></br>
                                            (Merchant’s/Agent’s Name)<br></br>
                                            {/* {details?.name}
                                            <br></br> */}
                                            Seal and Signature<br></br>
                                        </strong>
                                    </p>

                                    <p className="mt-2" style={{ fontSize: "16px" }}>
                                        <strong>
                                            Timestamp : {details.date}
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <div className="row d-flex justify-content-center">
                        {" "}
                        <Button
                        className="hide-on-print"
                            onClick={() => {
                                printReceipt();
                            }}
                        >
                            {" "}
                            <i class="mdi mdi-printer"></i> Download Form
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IdentityPrint