import { Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from 'react';
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import { offsetArr } from "../../utils/CommonConfig";


const DistributorReport = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  const Datehandler = (date) => {
    setStartDate(date);
  }
  const Datehandler1 = (date1) => {
    setStartDate1(date1);
  }

  return (
    <div  style={{backgroundColor:COLOR_BACKGROUND_BODY}}>
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="border p-3" style={{ backgroundColor: '#002970', color: 'white' }}><b>Distributor Use Report</b></div>
          <div className="card" >
            <Row>
              <Col md={3}>
                <Form.Label>From Date:</Form.Label>
                <DatePicker  dateFormat="dd/MM/yyyy" name="PreferredDepartureTime" value={startDate} selected={startDate} todayButton="Today"  onChange={Datehandler} className="form-control" style={{borderRadius:'0px'}}/>
              </Col>
              <Col md={3}>
                <Form.Label>To Date:</Form.Label>
                <DatePicker  dateFormat="dd/MM/yyyy" name="PreferredDepartureTime" value={startDate1} selected={startDate1} todayButton="Today" onChange={Datehandler1} className="form-control" style={{borderRadius:'0px'}}/>
              </Col>
              <Col md={2}>
                <Form.Label style={{ color: 'white' }}>.</Form.Label>
                <Button type="button" className="btn btn-lg form-control" variant="success" style={{borderRadius:'0px'}}>Search</Button>
              </Col>
            </Row>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th> SL.No.</th>
                      <th> Name</th>
                      <th> Balance </th>
                      <th> Total Sale</th>
                      <th> Recharge Sale</th>
                      <th> Recharge Comission</th>
                      <th> DMT</th>
                      <th> DMT Comission</th>
                      <th> AEPS</th>
                      <th> AEPS Comission</th>
                      <th> BBPS Sale</th>
                      <th> BBPS Comission</th>
                      <th> Net Sale</th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    <tr>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                  </tbody>
                </table>

                <div className="border p-3" style={{ backgroundColor: 'whitesmoke' }}>
                  <span>Showing 0 to 0 of 0 entries</span>
                  <span className="float-right"><Button type="button" variant="outline-primary" style={{borderRadius:'0px'}}>Previous</Button> <Button type="button" variant="outline-primary" style={{borderRadius:'0px'}}>Next</Button></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Pagination = ({ page, limit, callback, total, count, callback2 }) => {


  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default DistributorReport