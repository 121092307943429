import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
// import { CLIENT } from "../../config";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useQuery } from "../../custom-hooks/useQuery";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import '../user-access-update/ToggleSwitch.css';
export const columns = [
    {
        dataField: "service_name",
        text: "service title",
        color: "green",
    },
    {
        dataField: "createdOn",
        text: "created on",
        color: "#000",
    },
    {
        dataField: "status",
        text: "Status",
        color: "#000",
    },
    {
        dataField: "action",
        text: "Action",
        color: "#000",
    },
];

const AllServicesAdmin = ({ setDetails }) => {
    const query = useQuery();
    let userId = query.get("id") ? query.get("id") : "1";
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const { setTransactionLoaderState, user } = useContext(GlobalContext);
    const [total, setTotal] = useState(0);
    const [services, setServices] = useState([]);

    const [ledger, setLedger] = useState([]);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    const openInNewTab = () => {
        const newWindow = window.open(
            `/pages/print?id=090422191784660&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        let filteredData = ledger.filter((bank) => {
            let b = false;
            if (ledger.length > 0) {
                for (let key in ledger[0]) {
                    if (
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + 9) / 10));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = 9;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = ledger.filter((bank) => {
            let b = false;
            if (ledger.length > 0) {
                for (let key in ledger[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * 10;
        const endIdx = currentPage * 10 - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    useEffect(() => {
        // getAllTransactions();
    }, [currentPage]);

    useEffect(() => {
        setPages(Math.floor((ledger.length + 9) / 10));
        const startIdx = 0;
        const endIdx = 9;
        setTotal(ledger.length);
        const filteredData = ledger.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
    }, [ledger]);


    const getAllServices = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.pkid,
                "service": "N",
                "status": "N"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/getAllServices`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                setServices(data.data);
            }

            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const updateAccessInfo = async (service, checked) => {
        let servicesString = "";
        // services.forEach((e) => {
        //     if (e.status == "Y") {
        //         servicesString += e.pk_menu_id;
        //         servicesString += ",";
        //     }
        // });
        // if (servicesString.length > 0) {
        //     servicesString = servicesString.slice(0, -1);
        // }
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.pkid,
                "service": service.pk_menu_id,
                "status": checked ? "Y" : "N"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/changeServiceStatusAdmin`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                // setServices(data.services);
                swal("Success", data.data?.message, "success");
                getAllServices();
            } else {
                swal("Failed", data.msg, "error");
            } setTransactionLoaderState(false)
        } catch (error) {
            setTransactionLoaderState(false)
        }
    };

    // did mount
    useEffect(() => {
        getAllServices();
    }, []);
    return (
        <>
            <div style={{ padding: "0rem" }} className="bg-light">
                <div style={{ borderRadius: "10px" }} className="card m-4 p-0">
                    <div className="card-body m-2 p-0 pl-2 pr-2 ">
                        <div className="m-0 p-0">

                            <div className="row m-0 p-0 align-self-center">
                                <div className="col-8 p-0 m-0 mb-3 align-self-center">
                                    <div className="pl-5"
                                        style={{
                                            padding: "0px",
                                            background: "#FFFFFF",
                                            color: "#4267B2",
                                            fontWeight: "bolder",
                                            fontfamily: "Poppins",
                                            fontSize: "32px",
                                        }}
                                    >
                                        Services
                                    </div>
                                </div>
                                <div className="col-4 align-self-center p-0 m-0">
                                    <Form.Group
                                        style={{ maxWidth: "30rem", margin: "10px 0px 0px 3pxs" }}
                                    >
                                        <Form.Control
                                            type="text"
                                            className="form-control rounded-100 bg-light "
                                            aria-label="Notes"
                                            aria-describedby="basic-addon1"
                                            placeholder="Search"
                                            onChange={(e) => {
                                                setSearchText(e.target.value);
                                            }}
                                            value={searchText}
                                        />
                                    </Form.Group>

                                </div>
                            </div>



                            <hr className="m-0 p-1" />


                            <table style={{ width: "100%", backgroundColor: '#FFFFFF' }} className="my-table  borderless transparent-table">
                                <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                    <th className="my-table transparent-table text-center border-bottom pb-4" style={{ backgroundColor: '#FFFFFF', "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                                    >
                                        {" "}
                                        {"Sr"}
                                    </th>
                                    <th
                                        className="my-table transparent-table text-center border-bottom pb-4" style={{ width: '', backgroundColor: '#FFFFFF', "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                                    >
                                        Service
                                    </th>
                                    <th
                                        className="my-table transparent-table text-center border-bottom pb-4" style={{ width: '50px', backgroundColor: '#FFFFFF', "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
                                    >
                                        Status
                                    </th>

                                </tr>

                                {services.map((e, ind) => (
                                    <tr className="my-table transparent-table border-bottom py-0">
                                        <td
                                            className="m-0 p-2" style={{ backgroundColor: '#FFFFFF', "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "0px", "letterSpacing": "0em", "textAlign": "center" }}
                                        >
                                            {" "}
                                            {ind + 1}
                                        </td>
                                        <td
                                            className="m-0 p-2" style={{ backgroundColor: '#FFFFFF', "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "100", "lineHeight": "0px", "letterSpacing": "0em", "textAlign": "center" }}
                                        >
                                            {" "}
                                            {e.menu_title}
                                        </td>
                                        <td
                                            className="m-0 p-2" style={{ backgroundColor: '#FFFFFF', "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "400", "lineHeight": "0px", "letterSpacing": "0em", "textAlign": "center" }}
                                        >
                                            <BootstrapSwitchButton
                                                width={100}
                                                checked={e.status == "Y"}
                                                onstyle="primary"
                                                onChange={(checked) => {

                                                    updateAccessInfo(e,checked);
                                                }}
                                            />
                                            {/* < ToggleSwitch   onChange={(checked) => {
                          const copy = [...services];
                          copy[ind].status = checked ? "Y" : "N";
                          setServices(copy);
                          updateAccessInfo(copy);
                        }} /> */}

                                        </td>
                                    </tr>
                                ))}
                            </table>
                            <Pagination
                                page={currentPage}
                                limit={pages}
                                callback={(page) => {
                                    setCurrentPage(page);
                                }}
                                count={dataToDisplay.length}
                                total={total}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ToggleSwitch = () => {
    const [isOn, setIsOn] = useState(false);


    const handleToggle = () => {
        setIsOn((prevIsOn) => !prevIsOn);
    };

    return (

        <div className={`toggle-switch ${isOn ? 'on' : 'off'} `} onClick={handleToggle}>
            <div className="toggle-switch-inner">
                <span className="toggle-switch-text" >{isOn ? 'On' : 'Off'}</span>
            </div>
        </div>



    );
};


const Pagination = ({ page, limit, callback, total, count }) => {
    console.table({ page, limit, callback });
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>
            <div>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                    >
                        {"Previous"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}
                <Button variant="primary"> {page.toString()}</Button>
                <Button
                    onClick={() => {
                        callback(page + 1);
                    }}
                    variant="light"
                >
                    {"Next"}
                </Button>
            </div>
        </div>
    );
};

export default AllServicesAdmin;
