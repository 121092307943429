import React, { useState } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DistributorReportByid = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  const Datehandler = (date) => {
    setStartDate(date);
  }
  const Datehandler1 = (date1) => {
    setStartDate1(date1);
  }

  return (
    <>
      <div className="mt-4" style={{ textAlign: 'center', color: 'grey' }}><b><h5>Distributor:   (D1002)</h5></b></div>
      <div className='mt-3 p-2' style={{ textAlign: 'center' }}>
        <Row>
          <Col md={4}>
            <Form.Label>From Date:</Form.Label>
            <DatePicker  dateFormat="dd/MM/yyyy" name="PreferredDepartureTime" value={startDate} selected={startDate} todayButton="Today" dateFormat="yyyy/MM/dd" onChange={Datehandler} className="form-control" style={{borderRadius:'0px'}}/>
          </Col>
          <Col md={4}>
            <Form.Label>To Date:</Form.Label>
            <DatePicker  dateFormat="dd/MM/yyyy" name="PreferredDepartureTime" value={startDate1} selected={startDate1} todayButton="Today" dateFormat="yyyy/MM/dd" onChange={Datehandler1} className="form-control" style={{borderRadius:'0px'}}/>
          </Col>
          <Col md={2}>
            <Form.Label style={{ color: 'white' }}>.</Form.Label>
            <Button className='btn btn-sm form-control' style={{borderRadius:'0px'}}>Search</Button>
          </Col>
        </Row>
        <div className="ml-1 mt-2 mb-2 p-2" style={{ float: 'left' }}>
          <span style={{ borderRadius: '8px', color: 'white', backgroundColor: '#70ca63' }}><small><b>Total Added : 0</b></small></span>
          <span style={{ color: 'sky blue' }}><small> | </small></span>
          <span style={{ borderRadius: '8px', color: 'white', backgroundColor: '#e9573f' }}><small><b>Total Debit : 0</b></small></span>
        </div>

        <div className="table-responsive ml-1 p-2">
          <table className="table" style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th> SL No.</th>
                <th> Date </th>
                <th colSpan='3'> User </th>
                <th> Old Balance </th>
                <th> Fund </th>
                <th> New Balance </th>
                <th> Status </th>
                <th> Remark </th>
              </tr>
            </thead>
            <tbody >
              <tr>
                <td></td>
                <td></td>
                <td colSpan="3"><b> Total Amount </b></td>
                <td>  </td>
                <td> 3.00 </td>
                <td> </td>
                <td> </td>
                <td>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default DistributorReportByid