import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { generateHashKey2, uniqueID2 } from "../utils/utilFuctions";
import Spinner from "./shared/Spinner";
import Dashboard from "./dashboard/Dashboard";
import TopupView from "../retailer/Topup View/TopupView";
import RetailerLedgerReport from "./RetailerReport/RetailerLedgerReport";
//import Topup from "../retailer/topup/Topup";
import { TopupRequest } from "../retailer/topup/Topup2";
import VirtualReport from "../retailer/virtual report/VirtualReport";
import LedgerReport from "../retailer/Ledger/LedgerReport";
import { checkSession, SESSION_TIMER } from "../utils/Session";
import { GlobalContext } from "../Context/GlobalState";
import MyProfile from "../retailer/my profile/MyProfile";
import CreditRequest from "../retailer/credit-request/CreditRequest";
import FundReport from "./Report/FundReport";
import DashboardReport from "./DashboardReport";
import CreditFunds from "./Funds/CreditFunds";
import DebitFunds from "./Funds/DebitFunds";
const CommisionChart = lazy(() => import("./CommissionChart/CommisionChart"));

// Manage Distributor

const CreateRetailer = lazy(() => import("./Manage Retailer/CreateRetailer"));
const ViewRetailer = lazy(() => import("./Manage Retailer/ViewRetailer"));
const TopupHistory = lazy(() => import("./Manage Retailer/TopupHistory"));
const DistributorReport = lazy(() =>
  import("./Manage Retailer/DistributorReport")
);
const MobileNumber = lazy(() => import("./Manage Retailer/MobileNumber"));

// Funds

const FundReports = lazy(() => import("./Funds/FundReports"));
const FundStatement = lazy(() => import("./Funds/FundStatement"));

// Wallet
const BankDetails = lazy(() => import("./PaymineWallet/BankDetails"));
const WalletToWalletTransfer = lazy(() =>
  import("./PaymineWallet/WalletToWalletTransfer")
);

// Wallet to wallet transfer

const Ledger = lazy(() => import("./Report/Ledger"));
const OperatorWiseReport = lazy(() => import("./Report/RetailerReport"));
const ProfitReport = lazy(() => import("./Report/ProfitReport"));
const TransactionReport = lazy(() => import("./Report/TransactionReport"));
const RetailerReport = lazy(() => import("./Report/RetailerReport"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login2 = lazy(() => import("../user-pages/Login2"));

const AppRoutes = () => {
  const { removeUserInfo, user } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    removeUserInfo();
    checkSession();
  };

  const onActive = (event) => {};

  const onAction = (event) => {};

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const checkSession2 = async (sessionId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/check_session.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
      } else {
        removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const addSession = async () => {
    const sessionId = uniqueID2();
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        sessionid: sessionId,
        device: "WEB",
      });

      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/Profile/add_session.aspx",
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        sessionStorage.setItem("sessionId", sessionId);
      } else {
        removeUserInfo();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionId = sessionStorage.getItem("sessionId");
      if (sessionId) {
        checkSession2(sessionId);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const sessionId = sessionStorage.getItem("sessionId");
    // if (!sessionId) {
    addSession();
    //  }
  }, [user]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Disable right-click
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
        )
          return false;
      };
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/distributor/dashboard" component={Dashboard} />

        <Route exact path="/distributor/MyProfile" component={MyProfile} />
        <Route
          exact
          path="/distributor/CommisionChart"
          component={CommisionChart}
        />

        <Route
          exact
          path="/distributor/Manage Retailer/CreateRetailer"
          component={CreateRetailer}
        />
        <Route
          exact
          path="/distributor/Manage Retailer/ViewRetailer"
          component={ViewRetailer}
        />
        <Route
          exact
          path="/distributor/Manage Retailer/TopupHistory"
          component={TopupHistory}
        />
        <Route
          exact
          path="/distributor/Manage Retailer/DistributorReport"
          component={DistributorReport}
        />
        <Route
          exact
          path="/distributor/Manage Retailer/MobileNumber"
          component={MobileNumber}
        />
        <Route
          exact
          path="/distributor/view-retailer"
          component={DashboardReport}
        />

        <Route
          exact
          path="/distributor/Funds/FundReports"
          component={FundReports}
        />
        <Route
          exact
          path="/distributor/Funds/FundStatement"
          component={FundStatement}
        />

        <Route exact path="/distributor/CreditFunds" component={CreditFunds} />
        <Route exact path="/distributor/DebitFunds" component={DebitFunds} />

        <Route
          exact
          path="/distributor/PaymineWallet/TopupRequest"
          component={TopupRequest}
        />
        <Route
          exact
          path="/distributor/PaymineWallet/TopupView"
          component={TopupView}
        />
        <Route
          exact
          path="/distributor/PaymineWallet/BankDetails"
          component={BankDetails}
        />
        <Route
          exact
          path="/distributor/PaymineWallet/WalletToWalletTransfer"
          component={WalletToWalletTransfer}
        />

        <Route
          exact
          path="/distributor/Report/TransactionReport"
          component={TransactionReport}
        />
        <Route
          exact
          path="/distributor/Report/ProfitReport"
          component={ProfitReport}
        />
        <Route
          exact
          path="/distributor/Report/OperatorWiseReport"
          component={OperatorWiseReport}
        />
        <Route
          exact
          path="/distributor/Report/Ledger"
          component={LedgerReport}
        />
        <Route
          exact
          path="/distributor/Report/RetailerReport"
          component={RetailerReport}
        />

        <Route
          exact
          path="/distributor/Report/FundReport"
          component={FundReport}
        />

        <Route exact path="/distributor/topup_view" component={TopupView} />
        <Route exact path="/distributor/bank_details" component={BankDetails} />
        <Route
          exact
          path="/distributor/credit-request"
          component={CreditRequest}
        />

        {/* Retailer Report */}

        <Route
          exact
          path="/distributor/retailer_report"
          component={RetailerLedgerReport}
        />

        {/* virtual report */}

        <Route
          exact
          path="/distributor/virtual_report"
          component={VirtualReport}
        />

        <Route path="/user-pages/login-2" component={Login2} />

        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />

        <Redirect to="/user-pages/login-2" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
