import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  generateHashKey2,
} from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";

export const RaiseTicketModal1 = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    title: "",
    description: "",
    issue: "",
    content: "",
  });
  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    issue: "",
    content: "",
  });
  const issues = [
    {
      label: "Trasaction Failed but wallet deducted",
      value: "Trasaction Failed but wallet deducted",
    },
    {
      label: "Trasaction Success but wallet deducted",
      value: "Trasaction Success but wallet deducted",
    },
  ];

  const sendNotification = async () => {
    try {
      console.log(state);
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");

      var raw = JSON.stringify({
        UserID: details.user_code,
        title: state.title,
        desc: state.description,
        enabled: "Y",
        closable: "Y",
        content: state.content,
        priority: state.issue.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/add_user_notifications.aspx`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data);
      setModalOpen(false);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const raiseTicket = async () => {
    const { category, issue, tid, user_id } = state;
    const raw = "";
    const hash_key = await generateHashKey2(user_id);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${BASE_URL_API_TRANXT}/apiget.aspx?method=ticket&TransactionId=${tid}&TransactionType=${category}&MailMessage=${issue.value}&RetailorID=${user_id}`,
      requestOptions
    );
    const result = await res.json();

    swal("Success", "Ticket Raised Successfully", "success");
  };
  const clearForm = () => {
    setState({
      tid: "",
      category: "",
      issue: "",
      user_id: "",
    });
  };

  // const validateForm = () => {
  //   console.log(state);
  //   if (
  //     state.category == "" ||
  //     state.issue == "" ||
  //     state.tid == "" ||
  //     state.user_id == ""
  //   ) {
  //     swal("Invalid", "Please Fill Mandatory Fields", "error");
  //     return;
  //   }
  //   raiseTicket();
  // };

  const validate = () => {
    const errors = {};
    if (!state.title) {
      errors.title = "*Title is required!";
    }
    if (!state.description) {
      errors.description = "*Description is required!";
    }
    if (!state.content) {
      errors.content = "*content is required!";
    }
    if (!state.priority) {
      errors.priority = "*choose Priority!";
    }
    console.log(errors);
    console.log(state);
    if (Object.keys(errors).length == 0) {
      sendNotification();
    }
    setFormErrors(errors);
  };

  console.log(details);
  useEffect(() => {
    if (details) {
      setState({
        ...state,
        category: details.category,
        tid: details.tid,
        user_id: details.user_id,
      });
    }
  }, [details]);

  useEffect(() => {
    sendNotification();
  }, []);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="lg"
    >
      <Modal.Body>
        <div>
          <div className="ml-2">
            <b>User Code : {details && details.user_code}</b>{" "}
          </div>

          <strong
            className="px-1 hover d-flex justify-content-end"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 ">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Title
              </Form.Label>

              <input
                type="text"
                id="fname"
                name="fname"
                className="form-control"
                // aria-label="Notes"
                // aria-describedby="basic-addon1"
                placeholder="Enter Title"
                // disabled={true}
                // value={state.tid}
                onChange={(e) => {
                  setState({ ...state, title: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
              <p
                style={{
                  color: "red",
                }}
              >
                {formErrors.title}
              </p>
            </Form.Group>
          </div>

          <div className="col-12 col-sm-6 ">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Description
              </Form.Label>
              <input
                type="text"
                id="fname"
                name="fname"
                className="form-control"
                // type="text"
                // className="form-control"
                // aria-label="Notes"
                // aria-describedby="basic-addon1"
                placeholder="Enter Description"
                // disabled={true}
                // value={state.user_id}
                onChange={(e) => {
                  setState({ ...state, description: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
              <p
                style={{
                  color: "red",
                }}
              >
                {formErrors.description}
              </p>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 ">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Priority
              </Form.Label>
              <input
                type="text"
                id="fname"
                name="fname"
                className="form-control"
                // type="text"
                // className="form-control"
                // aria-label="Notes"
                // aria-describedby="basic-addon1"
                placeholder="Enter Priority"
                // disabled={true}
                // value={state.user_id}
                onChange={(e) => {
                  setState({ ...state, priority: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
              <p
                style={{
                  color: "red",
                }}
              >
                {formErrors.priority}
              </p>
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Content
              </Form.Label>
              <input
                id="fname"
                name="fname"
                type="text"
                className="form-control"
                // aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Content"
                // value={state.content}
                // disabled={true}
                onChange={(e) => {
                  setState({ ...state, content: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
              <p
                style={{
                  color: "red",
                }}
              >
                {formErrors.content}
              </p>
            </Form.Group>
          </div>
        </div>
        <div className="row mx-1">
          <Button className="rounded-0 btn-success" onClick={validate}>
            Send User Notification
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
