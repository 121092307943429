import React, { useState, useEffect } from "react";
import ShortCuts from "../../Shortcut_Links/ShortCuts";
import { Button, Form, ButtonGroup, Card } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import RechargeSidebar from "./RechargeSidebar";
import { urls } from "../../URLs";
import { basicAuth } from "../../config";
import { Redirect, useLocation } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";

const Recharge = () => {
  const { getCategoriesInfoUrl, getAllMobileRechargeOperatorsUrl } = urls;
  const [rechargeType, setRechargeType] = useState("Prepaid");
  const [caterories, setCaterories] = useState([]);
  const [operators, setOperators] = useState([]);
  const { username, password } = basicAuth;
  const location = useLocation();
  const getCategoriesInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5DkSBJqSus9CwQHMTRMCG6kpwTj9KCDYn3mJViXBfrjNOw_FbDge1bKSbp_GOiu5FEfaV98xtJZ4I9mPO-gHsPvLhm8RO9NrVVlUGdIzVacXkyAuxxEmxa3pUoQvoqSzPkL3gBHnLRsLaKap7A6yf7YmRnS_C-TBh6Exm8q8LWRe3x7xQoWIBwV_LSvp8J40051-aHermE5oOcovf-anv0XDyi0cuumf1tSiINbq1i9qMZVGCUCT_GH-dgw8Kvcf8Zr6ZVIzvAxB0p4lV--EjZ4T4FoPh5Z9ISD2lqqbpAeTKdc4TSye6YFyWMSEDX6jJyJAsnoMMuBd854Kqygtpq5evn9zc_FNrez5_I6buFk8ZaScnQdOFRnREWWZBnZPFFZ7OSwLu84Vgu89l1ZIPfV6nMrU0-E772t3qoK-af-3ntq-o47TXdYrX6wViEXiKbGmpFB83zoR5bO3oldOEAqWypozqftY_E3QPOktapp5zikFx04LK6bruw1fa4zcPLCtiKGQrnyAbn6eOkBaIgDoCPEZVZMm3HMvb0tMSenO7WwCi9EKLm0RAxynkL_azOre_2_HwJCCOlEjNJILzXbPHYcZTK-VcaEe6QgaGBWIguhOnzlVxjUbRZPTPlejadAX1PQTDjgshHbuYS-RJw"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const res = await fetch(getCategoriesInfoUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllOperators = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq6V3uK79RWBrEyPTWlfS1Qzjhr4NzYDI8biMoTl8PvcwDKuoY5QW1Nn55xdDXuCsyFVfCiy2CVRQeHaysVe2ofPMCmrvUCXFNWZ-0tWr5U-V_aZRLs6NbDAYeUKR0i0NAi9ZSppftoYff1TkQiXtKTOPEVOfO79-kkVYkfw2clbNLm7Rlsrmsb4zWUVzNikFEn9HPICqNMdU03VeK6joZZIjFW82wS1ANOU8bfmgbCtCdHGMZihpMaEbjjtm-75yFrvg5cOw38x51Ivj4GI_zc-kn6y3_0e5awDxMS2AzRN-VoUDhI3GGrR7q108ad8AH6EGQtBR6-TYUpoB4AnuSwbbWXiLDlAQTVMlbC51RSMAJL_luqhDhcJPjMHdpFgz7UzDy1vozGXhlxHTwx3vqU9IkVu65CqFf834_vZdHuWXEiRt2fHySVkBFPz_z3YlMvjzNdpaNxZhkkOxAqjyL3SFk4Id6Ms64o9td2X3ZkkMIyEjB-z4eQtTBZ5P23hZltULbf-GyhcqOw5_OWAzL8uD78rLvTWGCFQhl4IKCGfTQSkpDdxkhQNGaXIcFy45xobZvx20Zs0-X2DljZI-QGGH0rOaiglukgBXF0V05aoH_nCs25D2CQdgzu7ay-k_CA045E-6N0M07cTi_LDItHk"
    );

    var raw = JSON.stringify({
      userName: "string",
      categoryId: 16,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(getAllMobileRechargeOperatorsUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.data != null) setOperators(data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCategoriesInfo();
    getAllOperators();
  }, []);

  console.log(operators);
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <RechargeSidebar details={{ operators }} />
            </div>
            <div style={{backgroundColor:COLOR_BACKGROUND_BODY}}>
              <div className="row" style={{ padding: "0px" }}>
                <ShortCuts path={location.pathname} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "10px 10px 0px 10px",
                }}
              >
                <Button
                  variant="success"
                  className="rounded-0"
                  onClick={() => {
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                >
                  {"Make Recharge"}
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <Card style={{ flex: "1", margin: "10px", padding: "10px" }}>
                  <strong>{"Last Transaction Details"}</strong>
                </Card>
              </div>
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Recharge;
