import React, { Component } from 'react';

class JSONTable extends Component {
    renderTable() {
        const { data } = this.props;
        return (
            <div className="scroll-inner admin-fix-width">
                <table className="my-table transparent-table text-center border-bottom">
                    <tbody>
                        {Object.entries(data).map(([key, value], index) => (
                            <tr key={index}>
                                <td>{key}</td>
                                <td>{(typeof value === 'object' && value != null) ? this.renderNestedObject(value) : JSON.stringify(value)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    renderNestedObject(obj) {
        return (
            <table className="my-table transparent-table text-center border-bottom">
                <tbody>
                    {Object.entries(obj).map(([key, value], index) => (
                        <tr key={index}>
                            <td>{key}</td>
                            <td>{JSON.stringify(value)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div>
                {this.renderTable()}
            </div>
        );
    }
}

export default JSONTable;
