import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Dropdown } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import userAvtar from "../../../assets/images/user.png";
import pemoreLogo from "../../../assets/images/company/logo_square.png";
import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { urls } from "../../URLs";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_API_TRANXT,
  BASE_URL_DOC_KYC,
  BASE_URL_APISERVICES,
} from "../../utils/Constants";
import DocKycIcon from "../../../assets/svg/doc_kyc.svg";
import AgentKycIcon from "../../../assets/svg/agent_kyc.svg";
import AgentCertificateIcon from "../../../assets/svg/agent_certificate.svg";
import CommissionChartIcon from "../../../assets/svg/commission_chart.svg";
import HelpAndSupportIcon from "../../../assets/svg/help_and_support.svg";
import swal from "sweetalert";
import { generateHashKey2, twoDecimalPlaces } from "../../utils/utilFuctions";
import AadhaarVerifyOTPScreen from "../otp-verify/AadhaarVerifyOtp";

const links = [
  {
    device: "Startek FM220U",
    url: "https://drive.google.com/file/d/1IC_XIf_1_aHFRhoatAXgC9atKhAHyo2I/view?usp=drive_link",
  },
  {
    device: "Morpho MSO 1300",
    url: "https://drive.google.com/file/d/1WzJavAQlDXL9M2RTXP-GyGwQoDBedGRH/view?usp=drive_link",
  },
  {
    device: "Mantra MFS 100",
    url: "https://drive.google.com/file/d/1ufDLigWeffnP2Jh3lPCIzSaSL34zTQnO/view?usp=drive_link",
  },
];
const Navbar = () => {
  const history = useHistory();
  const {
    user,
    setWalletBalance,
    myAccountInfo,
    setProfileinfo,
    removeUserInfo,
    wallet,
    setUserInfo, setTransactionLoaderState
  } = useContext(GlobalContext);
  //   console.log(wBalance);
  //const [wallet, setWallet] = useState(null);
  const [profile, setProfile] = useState({});
  const [deviceDrivers, setDeviceDrivers] = useState([]);
  const [kycData, setKycData] = useState(null);
  const [paynnowAgentId, setPaynnowAgentId] = useState("");
  const [docKycStatus, setDocKycStatus] = useState("");
  const [verifiedKycData, setVerifiedKycData] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [leanAmount, setLeanAmount] = useState({});
  // mdShow
  const [mdShow, setMdShow] = useState(false);

  const switchToRetailer = () => {
    setUserInfo({ ...user, UserType: user.SwitchableType });
    if (user.SwitchableType == "3") {
      history.push("/distributor/dashboard");
    } else if (user.SwitchableType == "4") {
      history.push("/master-distributor/dashboard");
    }
  };

  const getWalletBalance = async () => {
    try {
      const raw = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getbalance_new&userid=${user.ReferId}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      console.log(data);
      setWalletBalance(data);
      //  setWallet(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getWalletInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "userid": user.ReferId,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const res = await fetch("https://apiservices.bumppypay.com/api/Payment/wallet/getWalletBalance", requestOptions);

      const data = await res.json();
      if (data.status == "0") {
        setLeanAmount(data.data.lean_amount)
        //  setWallet(data.data);
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {

    }


  }

  const verificationData = async (flag) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setVerifiedKycData(data.data);
        if (flag) {
          swal(
            "Reject",
            `Your Document KYC has been rejected for Reason : ${data.data.kyc_remark}`,
            "warning"
          ).then((ok) => {
            if (ok) {
              localStorage.setItem("kyc", JSON.stringify(user));
              history.push("/kyc/bussiness-details");
            }
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  const GetProfile = () => {
    var raw = JSON.stringify({
      PdrID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/master_dist_profile_details.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setProfileinfo(result.data);
          setProfile(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };



  const fetchUserKycData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: user.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == 0 && data.data.length > 0) {
        setDocKycStatus(data.data[0].kyc_status);
        if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
        } else if (data.data[0].kyc_status == "R") {
          verificationData(true);
        }
        setKycData(data.data[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getReviewKycDetails = async (step, verification) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "userid": user.ReferId,
        "adminid": '1',
        "fromDate": "",
        "toDate": ""
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllKycReviewReportAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.data.length > 0 && data.data[0].aadhaar_otp_verified == "N") {
          setMdShow(true);
        }
        setReviewData(data.data[0])
        setTransactionLoaderState(false);
      } else {
        swal("Error", "Something went wrong", "error");
        setTransactionLoaderState(false);
      }

    } catch (error) {
      console.log(error);
    }
  };

  console.log(mdShow);


  const getPaynnowAgentId = async () => {
    console.log("hiii...");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/get_paynnow_agentid.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.yesbank_kyc == "Y") {
          setPaynnowAgentId(data.agentid);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFile = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDownload = (fileUrl, aa) => {
    // Replace 'YOUR_GOOGLE_DRIVE_LINK' with your actual sharable link.
    // const fileUrl = 'YOUR_GOOGLE_DRIVE_LINK';

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("target", "_blank"); // Open in a new tab
    link.click();
  };

  const getDeviceDrivers = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/getWebDeviceDrivers`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setDeviceDrivers(data.data);
      } else {
        // setData([]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getWalletInfo();
    GetProfile();
    getWalletBalance();
    fetchUserKycData();
    getPaynnowAgentId();
    getDeviceDrivers();
    getReviewKycDetails();
  }, []);
  console.log(wallet);
  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null || state.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
              <Link className="navbar-brand brand-logo-mini" to="/">
                <img src={pemoreLogo} />
              </Link>
            </div>
            <AadhaarVerifyOTPScreen mdShow={mdShow} onHide={() => setMdShow(false)} setMdShow={(value) => setMdShow(value)} />

            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <button
                className="navbar-toggler align-self-center"
                type="button"
              // onClick={() =>
              //   document.body.classList.toggle("sidebar-icon-only")
              // }
              >
                <Link to="/retailer/dashboard">
                  <img
                    src={require("../../../assets/images/company/navbar_logo.png")}
                    style={{
                      height: "2.5rem",
                      paddingRight: "1rem",
                      width: "auto",
                    }}
                  />
                </Link>
              </button>

              <ul className="navbar-nav navbar-nav-right">
                {state.user == null || state.user.ReferId == "BUMPPYR1001-" ? null : (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        getWalletBalance();
                      }}
                    >
                      <div className="navbar-profile">
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "1.5rem",
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ paddingRight: "2px" }}>₹</span>
                          {` ${wallet
                            ? wallet.WalletBalance
                            : state.user.walletBalance
                            } `}
                          <span className="menu-icon">
                            <i className="mdi mdi-reload"></i>
                          </span>
                        </span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}

                {Number(leanAmount) >0  && (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                    >
                      <div className="navbar-profile">
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ paddingRight: "2px" }}>₹</span>
                          {`Lien : ${twoDecimalPlaces(leanAmount)} `}
                        </span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}

                {wallet && Number(wallet.todaySale) && (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                    >
                      <div className="navbar-profile">
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ paddingRight: "2px" }}>₹</span>
                          {`Loan : ${wallet
                            ? wallet.todaySale
                            : state.user.todaySale
                            } `}
                        </span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}

                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret"
                  >
                    <div className="navbar-profile">
                      <img
                        className="img-xs rounded-circle"
                        src={userAvtar}
                        alt="profile"
                        style={{ height: "3.4rem", width: "auto" }}
                      />
                      <p className="mb-0 d-none d-sm-block navbar-profile-name text-dark">
                        <Trans>
                          {state.user == null ? (
                            ""
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right"
                              >
                                <strong style={{ fontWeight: "800" }}>
                                  {state.user.ReferId}
                                </strong>
                                {` -${profile?.name}`}
                              </span>
                              <strong
                                style={{ fontWeight: "800" }}
                                className="text-right"
                              >
                                {`${profile ? profile?.shopname : ""}`}
                              </strong>
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right"
                              >
                                {`${profile ? profile?.mobile : ""}`}
                              </span>
                              <span
                                style={{ fontWeight: "800", fontSize: ".8rem" }}
                                className="text-right"
                              >
                                {`Retailer`}
                              </span>
                            </div>
                          )}
                        </Trans>
                      </p>
                      <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ minWidth: "20rem" }}
                    className="navbar-dropdown preview-list navbar-profile-dropdown-menu"
                  >

                    {state.user.ReferId !== "BUMPPYR1001" && (
                      <React.Fragment>
                        <Dropdown.Item
                          className="dropdown-item preview-item"
                          onClick={(evt) => {
                            evt.preventDefault();
                            history.push("/retailer/my_profile");
                          }}
                        >
                          <div className="preview-thumbnail">
                            <span
                              class="fa fa-gear ml-3"
                              style={{ color: "#666666" }}
                            ></span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>My Profile</Trans>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        {state.user.SwitchableType === "3" && (
                          <React.Fragment>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => {
                                evt.preventDefault();
                                switchToRetailer();
                              }}
                            >
                              <div className="preview-thumbnail">
                                <span
                                  class="fa fa-gear ml-3"
                                  style={{ color: "#666666" }}
                                ></span>
                              </div>
                              <div className="preview-item-content">
                                <Trans>Login as a Distributor</Trans>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                          </React.Fragment>
                        )}

                        {state.user.SwitchableType == "4" && (
                          <React.Fragment>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => {
                                evt.preventDefault();
                                switchToRetailer();
                              }}
                            >
                              <div className="preview-thumbnail">
                                <span
                                  className="fa fa-gear ml-3"
                                  style={{ color: "#666666" }}
                                ></span>
                              </div>
                              <div className="preview-item-content">
                                <Trans>Login as a Master Distributor</Trans>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                          </React.Fragment>
                        )}

                        <Dropdown.Item
                          onClick={(evt) => {
                            evt.preventDefault();
                            localStorage.setItem("kyc", JSON.stringify(user));
                            if (docKycStatus != "A")
                              history.push("/kyc/bussiness-details");
                          }}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-thumbnail">
                            <span className="menu-icon">
                              <img style={{ height: "1.2rem" }} src={DocKycIcon} />
                            </span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>Document KYC</Trans>
                          </div>
                          {docKycStatus == "A" ? (
                            <div className="badge badge-success badge-pill ml-auto">
                              Completed
                            </div>
                          ) : docKycStatus == "P" ? (
                            <div className="badge badge-warning badge-pill ml-auto">
                              Under Proccess
                            </div>
                          ) : docKycStatus == "R" ? (
                            <div className="badge badge-danger badge-pill ml-auto">
                              Rejected
                            </div>
                          ) : null}
                        </Dropdown.Item>
                        {/* <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={(evt) => {
                            evt.preventDefault();
                            if (!paynnowAgentId) history.push("/agent-kyc");
                          }}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-thumbnail">
                            <span className="menu-icon">
                              <img
                                style={{ height: "1.2rem" }}
                                src={AgentKycIcon}
                              />
                            </span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>Agent KYC </Trans>
                          </div>
                          {paynnowAgentId ? (
                            <div className="badge badge-success badge-pill ml-auto">
                              Completed
                            </div>
                          ) : (
                            <div className="badge badge-danger badge-pill ml-auto">
                              InComplete
                            </div>
                          )}
                        </Dropdown.Item> */}
                        <Dropdown.Divider />

                        <Dropdown.Item
                          onClick={(evt) => {
                            evt.preventDefault();
                            history.push("/retailer/certificate");
                          }}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-thumbnail">
                            <span className="menu-icon">
                              <img
                                style={{ height: "1.2rem" }}
                                src={AgentCertificateIcon}
                              />
                            </span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>Agent Certificate</Trans>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        <Dropdown.Item
                          onClick={(evt) => {
                            evt.preventDefault();
                            history.push("/retailer/marcomm");
                          }}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-thumbnail">
                            <span className="menu-icon">
                              <img
                                style={{ height: "1.2rem" }}
                                src={AgentCertificateIcon}
                              />
                            </span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>Marcom Doc</Trans>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        <Dropdown.Item
                          onClick={(evt) => {
                            evt.preventDefault();
                            history.push("/retailer/commission-chart");
                          }}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-thumbnail">
                            <span className="menu-icon">
                              <img
                                style={{ height: "1.2rem" }}
                                src={CommissionChartIcon}
                              />
                            </span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>Commission Chart</Trans>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        <Dropdown.Item
                          onClick={(evt) => {
                            evt.preventDefault();
                            history.push("/retailer/Help-And-Support");
                          }}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-thumbnail">
                            <span className="menu-icon">
                              <img
                                style={{ height: "1.2rem" }}
                                src={HelpAndSupportIcon}
                              />
                            </span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>Help & Support</Trans>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        <Dropdown.Item
                          onClick={(evt) => {
                            evt.preventDefault();
                            history.push("/retailer/services_request");
                          }}
                          className="dropdown-item preview-item"
                        >
                          <div className="preview-thumbnail">
                            <span className="menu-icon">
                              <img
                                style={{ height: "1.2rem" }}
                                src={HelpAndSupportIcon}
                              />
                            </span>
                          </div>
                          <div className="preview-item-content">
                            <Trans>Request Services</Trans>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />

                        {deviceDrivers.map((de) => (
                          <React.Fragment>
                            <Dropdown.Item
                              onClick={(evt) => {
                                evt.preventDefault();
                                handleDownload(de.download_url, de.driver_name);
                              }}
                              className="dropdown-item preview-item"
                            >
                              <div className="preview-thumbnail">
                                <span className="menu-icon">
                                  <img
                                    style={{ height: "1.2rem" }}
                                    src={HelpAndSupportIcon}
                                  />
                                </span>
                              </div>
                              <div className="preview-item-content mr-3">
                                <Trans> {de.driver_title}</Trans>
                              </div>
                              {de.version == 'New' ? (
                                <span
                                  style={{
                                    borderRadius: "5px",
                                    border: "1px solid",
                                    backgroundColor: "rgba(43, 193, 85, 0.1)",
                                    color: "rgba(43, 193, 85, 1)",
                                    bordercolor: "rgba(43, 193, 85, 1)",
                                  }}
                                  className="p-1 ml-auto"
                                >
                                  {`New`}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    padding: "5px",
                                    borderRadius: "5px",
                                    border: "1px solid",
                                    backgroundColor: "rgba(254, 128, 36, 0.1)",
                                    color: "rgba(254, 128, 36, 1)",
                                    bordercolor: "rgba(254, 128, 36, 1)",
                                  }}
                                  className="p-1 ml-auto"
                                >
                                  {`Old`}
                                </span>
                              )}

                            </Dropdown.Item>
                            <Dropdown.Divider />
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )}

                    {/* <Dropdown.Item
                      onClick={(evt) => {
                        evt.preventDefault();
                        downloadFile(
                          "https://download.mantratecapp.com/StaticDownload/MantraRDService_1.0.8.exe",
                          "Mantra Drivers"
                        );
                      }}
                      className="dropdown-item preview-item"
                    >
                      <div className="preview-thumbnail">
                        <span className="menu-icon">
                          <img
                            style={{ height: "1.2rem" }}
                            src={HelpAndSupportIcon}
                          />
                        </span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Download Mantra Drivers</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={(evt) => {
                        evt.preventDefault();
                        downloadFile(
                          "https://cdn.shopify.com/s/files/1/2363/5731/files/WindowsRDServiceV2.0.1.60_HTTPs-127.0.0.1.zip?v=1666877079",
                          "Morpho Drivers"
                        );
                      }}
                      className="dropdown-item preview-item"
                    >
                      <div className="preview-thumbnail">
                        <span className="menu-icon">
                          <img
                            style={{ height: "1.2rem" }}
                            src={HelpAndSupportIcon}
                          />
                        </span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Download Morpho Drivers</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider /> */}
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        removeUserInfo();
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-sign-out ml-3"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Logout</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>

              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                onClick={toggleOffcanvas}
              >
                <span className="mdi mdi-format-line-spacing"></span>
              </button>
            </div>
          </nav>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Navbar;
