import React, { useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';

const JsonTable = ({ data }) => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (key) => {
    setExpanded((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
          {value.map((item, index) => (
            <li key={index}>{renderValue(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return <JsonTable data={value} />;
    } else {
      return String(value);
    }
  };

  return (
    <Card>
      <Table borderless>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td>
                <Button
                  variant="link"
                  onClick={() => toggleExpand(key)}
                  style={{ paddingLeft: Object.keys(expanded).includes(key) ? '0' : '20px' }}
                >
                  {Object.keys(expanded).includes(key) ? '-' : '+'}
                </Button>
                {key}
              </td>
              <td>{renderValue(value)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {Object.entries(expanded).map(([key, value]) => (
        value && (
          <Card.Body key={key}>
            <JsonTable data={data[key]} />
          </Card.Body>
        )
      ))}
    </Card>
  );
};

export default JsonTable;

