import React, { useState, useEffect } from "react";
import ShortCuts from "../../Shortcut_Links/ShortCuts";
import { Button, Form, ButtonGroup, Card } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import MunicipalTaxSidebar from "./MunicipalTaxSidebar";
import { basicAuth } from "../../config";
import { Redirect, useLocation } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";

const MunicipalTax = () => {
  const { username, password } = basicAuth;
  const [providers, setProviders] = useState([]);
  const location = useLocation();
  console.log(location.pathname);
  const getAllProviders = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5nxn_jDhpPRlBHLmNVLCibYXbTK71aALOCGXbw4fHmGhYSemNPyrCo-W6rHg7K58J-En6cgJpZ_RlVeTHZjRO-FBKIs4zFAbZbeYrgCNKW34Wbm5lDeVV2H497JoGEWo0O4ar5aLanS1NTFH0LmOimscy41s2tPRyPfVtXiPSkgXialxacSHvLEna7HTGsDYgNPejg9kViVhahKHVV_dmsspRhxuUQ8yLOGeamszLCs_o-lMC9Lj3AHZaIyu-vfV4cZpESP9oj4s69Wl0Yc85c6o_NTuttKXDgIJrem_5W1KRitMel6aSEdsmqpEiuNho56pF8iIOkxL6bb9S6CjYK-dhGj14Q8ZZDzCDnxdHOTIU3iCx0i_qitJLvxhH5kSo9kigZlsxkVspvOtSp-NMDLPHeM3pOeeY5rY5pjLlNp2IUT9fKiJR1IRxvOHf4O93Gho2vl6J8QpS_5fsI0c2yN5o_UwssJ57vdkWxtUonmx_mDPb0bO6z48lcGvJiyp-aZx77HCulXFRaiyqJAD5mmjhDMf4KGYyjc-cK4j1VwJRLu_6pCN6YdfrY5B6sjmLnWo5rV4kTHNulkdJKAtekwySG6WI0l9wjyEVZNVmdioWaFXJ4p6gjNecN3lHJsEi7n24V6IXJ8zdm1Rz3jztK"
    );

    var raw = JSON.stringify({
      userName: "string",
      categoryId: 18,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Operator/GetAllOperators`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data != null) setProviders(data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  

  useEffect(() => {
    getAllProviders();
  }, []);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <MunicipalTaxSidebar details={{ providers }} />
            </div>
            <div style={{backgroundColor:COLOR_BACKGROUND_BODY}}>
              <div className="row" style={{ padding: "0px" }}>
                <ShortCuts path={location.pathname} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "10px 10px 0px 10px",
                }}
              >
                <Button
                  variant="success"
                  className="rounded-0"
                  onClick={() => {
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                >
                  {"Municipal Tax"}
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <Card style={{ flex: "1", margin: "10px", padding: "10px" }}>
                  <strong>{"Latest Bill Payments Details"}</strong>
                </Card>
              </div>
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default MunicipalTax;
