import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dropdown,
  Form,
  Card,
  InputGroup,
  Nav,
  Tabs,
  Tab,
  Col,
  Row,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";

export const columns = [
  {
    dataField: "rch_operator_id",
    text: "Operator ",
  },
  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "tid",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "rch_com_retailer",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];

const BBPSReportTable = () => {
  const [data, setData] = useState([]);
  const { user } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/bill_print?id=${data.tid}&type=Bill`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const fetchBBPSReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: user.ReferId,
        mobile: "",
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        report_type: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_bbps_report_all.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setData(
            result.filter((e) => {
              return e.rch_service != "REC";
            })
          );
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };
  useEffect(() => {
    fetchBBPSReport();
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>

      <div style={{ backgroundColor: "" }}>
        <div>
          <div
            className="tab-custom-pills-horizontal"
            style={{ background: "" }}
          >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={12} md={6}>
                  <Nav
                    variant="pills"
                    className="nav-pills-custom-4 justify-content-around p-1 "
                    style={{
                      border: "none",
                      background: "#EAF0F7",
                      borderRadius: "10px",
                      width: "14vw",
                      marginLeft: "2rem",
                      height: "6.5vh",
                    }}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        className="d-flex align-items-center"
                      >
                        Online
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        className="d-flex align-items-center"
                      >
                        Offline
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col xs={12}>
                  <Tab.Content className="tab-content-custom-pill">
                    <Tab.Pane eventKey="first">
                    <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
                      <div
            className="card"
            style={{
              border: "1px solid",
              width: "100%",
              borderRadius: "1rem",
              margin: "1rem",
            }}
          >
                          <div className="row">
                            <div className="col-12 col-sm-4 col-md-2">
                              <Form.Group >
                                <Form.Label >
                                  {" "}
                                  From Date{" "}
                                </Form.Label>
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  className="form-control"
                                  placeholderText="Start Date"
                                  style={{ borderRadius: "0px" }}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-4 col-md-2">
                              <Form.Group >
                                <Form.Label >
                                  {" "}
                                  To Date{" "}
                                </Form.Label>
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  className="form-control"
                                  placeholderText="End Date"
                                  style={{ borderRadius: "0px" }}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-4 col-md-2 mt-4">
                              <Form.Group >
                                <Button
                                  className="rounded-0"
                                  variant=""
                                  style={{
                                      background:"#56BE89",
                                      color:"white"
                                    }}
                                  onClick={fetchBBPSReport}
                                >
                                  {"Search"}
                                </Button>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-4 col-md-2">
                              <Form.Group >
                                <Form.Label>  Search in Table
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control rounded-0"
                                  aria-label="Notes"
                                  aria-describedby="basic-addon1"
                                  placeholder="Search in Table"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                  value={searchText}
                                />
                              </Form.Group>
                            </div>
                            <div className="col-12 col-sm-4 col mt-4">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  ExportToExcel(
                                    `BBPS_Report_${now()}`,
                                    excelMapping(data, columns)
                                  );
                                }}
                              >
                                <img
                                  src={require("../../../assets/images/excel.png")}   style={{ width: "1.6rem" }}/>
                                <strong style={{ marginLeft: "4px" }}>
                                  Export to Excel
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ----------------------------------------------------------------------- */}

                        {/* <div className="row 
  justify-content-center">
                                      {e.pay_type == 0 ? (
                                        <span className="flex-fill badge rounded-pill bg-danger p-2">
                                          Credit
                                        </span>
                                      ) : (
                                        <span class="badge rounded-pill bg-success">
                                          Debit
                                        </span>
                                      )}
                                    </div> */}

                      </div>
                      
                      <div
                          className="card mt-3 scroll-inner fix-width"
                          style={{
                            border: "1px solid",
                            width: "98%",
                            borderRadius: "1rem",
                            margin:"1rem"
                          }}
                        >
                          <table
                            style={{ width: "100%" }}
                            className="dashboard-table-style"
                          >
                            <tr>
                              <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
                              {columns.map((heading) => (
                                <th style={{ fontSize: "0.9rem" }}>
                                  {heading.text}
                                </th>
                              ))}
                              <th style={{ fontSize: "0.9rem" }}>
                                {" "}
                                {"Action"}
                              </th>
                            </tr>
                            {dataToDisplay
                              .filter((e) => {
                                return e.ttype == "Online";
                              })
                              .map((e, ind) => (
                                <tr>
                                  <td style={{ fontSize: "0.8rem" }}>
                                    {" "}
                                    {ind + 1}
                                  </td>
                                  {columns.map((cell) =>
                                    cell.text == "Status" ? (
                                      e[cell.dataField] == "success" ||
                                      e[cell.dataField] == "Success" ? (
                                        <td style={{ fontSize: "0.8rem" }}>
                                          {" "}
                                          <label
                                            style={{
                                              padding: "4px",
                                              borderRadius: "4px",
                                              minWidth: "4rem",
                                            }}
                                            className="badge badge-success"
                                          >
                                            {"Success"}
                                          </label>{" "}
                                        </td>
                                      ) : (
                                        <td style={{ fontSize: "0.8rem" }}>
                                          <label
                                            style={{
                                              padding: "4px",
                                              borderRadius: "4px",
                                              minWidth: "4rem",
                                            }}
                                            className="badge badge-warning"
                                          >
                                            {e[cell.dataField]}
                                          </label>
                                        </td>
                                      )
                                    ) : (
                                      <td style={{ fontSize: ".8rem" }}>
                                        {e[cell.dataField]}
                                      </td>
                                    )
                                  )}
                                  <td>
                                    <Button
                                      onClick={() => {
                                        openInNewTab(e);
                                      }}
                                      variant="danger"
                                      className="rounded-0"
                                    >
                                      {" "}
                                      Print
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </table>
                          <Pagination
                            page={currentPage}
                            limit={pages}
                            callback={(page) => {
                              setCurrentPage(page);
                            }}
                            count={dataToDisplay.length}
                            total={total}
                            callback2={(offsetValue) => {
                              setOffset(offsetValue);
                            }}
                          />
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="card"
                            style={{
                              border: "1px solid",
                              width: "100%",
                              borderRadius: "1rem",
                              margin: "1rem",
                            }}
                          >
                            <div className="row ">
                              <div className="col-12 col-sm-4 col-md-2">
                                <Form.Group >
                                  <Form.Label >
                                    {" "}
                                    From Date{" "}
                                  </Form.Label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control"
                                    placeholderText="Start Date"
                                    style={{ borderRadius: "0px" }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-12 col-sm-4 col-md-2 ">
                                <Form.Group >
                                  <Form.Label >
                                    {" "}
                                    To Date{" "}
                                  </Form.Label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="form-control"
                                    placeholderText="End Date"
                                    style={{ borderRadius: "0px" }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-12 col-sm-4 col-md-2 mt-4 ">
                                <Form.Group>
                                 
                                  <Button
                                    className="rounded-1"
                                    variant=""
                                    onClick={fetchBBPSReport}
                                    style={{
                                      background:"#56BE89",
                                      color:"white"
                                    }}
                                  >
                                    {"Search"}
                                  </Button>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-sm-4 col-md-2">
                                <Form.Group >
                                  <Form.Label style={{ marginLeft: "5px" }}>
                                    {" "}
                                    Search in Table
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className="form-control rounded-0"
                                    aria-label="Notes"
                                    aria-describedby="basic-addon1"
                                    placeholder="Search in Table"
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                    value={searchText}
                                  />
                                </Form.Group>
                              </div>

                              <div className="col-12 col-sm-4 col mt-4">
                                <div
                                  style={{ cursor: "pointer",}}
                                  onClick={() => {
                                    ExportToExcel(
                                      `BBPS_Report_${now()}`,
                                      excelMapping(data, columns)
                                    );
                                  }}
                                >
                                  <img
                                    src={require("../../../assets/images/excel.png")}
                                    style={{
                                      width: "1.6rem", }}
                                  />
                                  <strong style={{ marginLeft: "2px" }}>
                                    Export to Excel
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card mt-3 scroll-inner fix-width"
                          style={{
                            border: "1px solid",
                            width: "98%",
                            borderRadius: "1rem",
                            margin:'1rem'
                          }}
                        >
                          <table
                            style={{ width: "100%" }}
                            className="dashboard-table-style"
                          >
                            <tr>
                              <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
                              {columns.map((heading) => (
                                <th style={{ fontSize: "0.9rem" }}>
                                  {heading.text}
                                </th>
                              ))}
                              <th style={{ fontSize: "0.9rem" }}>
                                {" "}
                                {"Action"}
                              </th>
                            </tr>
                            {dataToDisplay
                              .filter((e) => {
                                return e.ttype != "Online";
                              })
                              .map((e, ind) => (
                                <tr>
                                  <td style={{ fontSize: "0.8rem" }}>
                                    {" "}
                                    {ind + 1}
                                  </td>
                                  {columns.map((cell) =>
                                    cell.text == "Status" ? (
                                      e[cell.dataField] == "success" ||
                                      e[cell.dataField] == "Success" ? (
                                        <td style={{ fontSize: "0.8rem" }}>
                                          {" "}
                                          <label
                                            style={{
                                              padding: "4px",
                                              borderRadius: "4px",
                                              minWidth: "4rem",
                                            }}
                                            className="badge badge-success"
                                          >
                                            {"Success"}
                                          </label>{" "}
                                        </td>
                                      ) : (
                                        <td style={{ fontSize: "0.8rem" }}>
                                          <label
                                            style={{
                                              padding: "4px",
                                              borderRadius: "4px",
                                              minWidth: "4rem",
                                            }}
                                            className="badge badge-warning"
                                          >
                                            {e[cell.dataField]}
                                          </label>
                                        </td>
                                      )
                                    ) : (
                                      <td style={{ fontSize: ".8rem" }}>
                                        {e[cell.dataField]}
                                      </td>
                                    )
                                  )}
                                  <td>
                                    <Button
                                      onClick={() => {
                                        openInNewTab(e);
                                      }}
                                      variant="danger"
                                      className="rounded-0"
                                    >
                                      {" "}
                                      Print
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </table>
                          <Pagination
                            page={currentPage}
                            limit={pages}
                            callback={(page) => {
                              setCurrentPage(page);
                            }}
                            count={dataToDisplay.length}
                            total={total}
                            callback2={(offsetValue) => {
                              setOffset(offsetValue);
                            }}
                          />
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
    </div>
  );
};

export default BBPSReportTable;
