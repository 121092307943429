import React, { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import ShortCuts from "../../Shortcut_Links/ShortCuts";
import { Redirect, useHistory } from "react-router";
import { Col } from "react-bootstrap";

const rchCategory = [
  // { image: "prepaid recharge", lable: "Prepaid Recharge", redirect: "/retailer/recharge" },
  { image: "dth", lable: "DTH Recharge", redirect: "/retailer/dth_recharge" },
  { image: "cable", lable: "Cable Tv", redirect: "/retailer/CableRecharge" },
  // { image: "metro recharge", lable: "Metro Recharge", redirect: "/retailer/metro" },
  { image: "fastag", lable: "FastTag", redirect: "/retailer/fastag" },
  {
    image: "prepaid recharge",
    lable: "Postpaid Bill Pay",
    redirect: "/retailer/MobilePostpaid",
  },
];
const utilityBills = [
  {
    image: "electricity",
    lable: "Electricity Bill",
    redirect: "/retailer/electricity_payment",
  },
  {
    image: "broadband",
    lable: "Broadband",
    redirect: "/retailer/BroadbandPostpaid",
  },
  {
    image: "landline",
    lable: "Landline",
    redirect: "/retailer/LandlinePostpaid",
  },
  {
    image: "water bill",
    lable: "Water Bill",
    redirect: "/retailer/water_bill_payment",
  },
  { image: "piped gas", lable: "Piped Gas", redirect: "/retailer/PipedGas" },
  {
    image: "gas cylinder",
    lable: "Gas Cylinder Booking",
    redirect: "/retailer/gas_payment",
  },
];
const financeAndTax = [
  {
    image: "insurance",
    lable: "General Insurance",
    redirect: "/retailer/Insurance",
  },
  {
    image: "insurance",
    lable: "Health Insurance",
    redirect: "/retailer/Health Insurance",
  },
  { image: "insurance", lable: "Life Insurance", redirect: "/retailer/Life" },
  {
    image: "loan repayment",
    lable: "Loan Repayment",
    redirect: "/retailer/LoanRepayment",
  },
  {
    image: "municipal tax & services",
    lable: "Municipal Services",
    redirect: "/retailer/Municipal",
  },
  {
    image: "municipal tax & services",
    lable: "Municipal Taxes",
    redirect: "/retailer/MunicipalTax",
  },
  { image: "housing", lable: "Housing Society", redirect: "/retailer/Housing" },
  { image: "hospital", lable: "Hospital", redirect: "/retailer/hospital" },
  {
    image: "club & society",
    lable: "Club And Association",
    redirect: "/retailer/club",
  },
];

const financeAndTax2 = [
  { image: "hospital", lable: "Hospital", redirect: "/retailer/hospital" },
  {
    image: "club & society",
    lable: "Club And Association",
    redirect: "/retailer/club",
  },
];

const other = [
  {
    image: "subscribe",
    lable: "Subscription Fee",
    redirect: "/retailer/Subscription",
  },
  {
    image: "education fee",
    lable: "Education Fee",
    redirect: "/retailer/EducationFees",
  },
  {
    image: "flight tickets",
    lable: "Flight Ticket",
    redirect: "/retailer/club",
  },
  { image: "hotels", lable: "Hotels", redirect: "/retailer/club" },
  { image: "bus tickets", lable: "Bus Ticket", redirect: "/retailer/club" },
  { image: "train tickets", lable: "Train Ticket", redirect: "/retailer/club" },
];
const shorts = [
  { image: "ic_recharge", lable: "Recharge", redirect: "/retailer/recharge" },
  { image: "ic_bank", lable: "DMT", redirect: "/retailer/recharge" },
  { image: "insurance", lable: "Life insurance", redirect: "/retailer/lic" },
  { image: "loan_repayment", lable: "Loan Enquiry", redirect: "/retailer/lic" },
  {
    image: "electricity_bulb",
    lable: "Electricity",
    redirect: "/retailer/lic",
  },
  { image: "utility", lable: "Utility", redirect: "/retailer/lic" },
  { image: "bill", lable: "Bill Payment", redirect: "/retailer/lic" },
];

export const BillPayment = () => {
  const cardRadius = "1rem";
  const { token, myAccountInfo } = useContext(GlobalContext);
  console.log(myAccountInfo);
  console.log(localStorage.getItem("accessToken"));
  console.log(token);
  const history = useHistory();
  const RedirectTo = (url) => {
    history.push(url);
  };
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <div className="bg-white-theme">
            <div className="row" style={{ padding: "0px" }}>
              <ShortCuts />
            </div>
            <div className="d-flex flex-row p-3 justify-content-center">
              <h5>
                <strong
                  style={{ color: "#002E94" }}
                  className="text-center txt-prime"
                >
                  Recharge Category
                </strong>
              </h5>
            </div>

            <div
              style={{ width: "100%" }}
              className="col-12 d-flex justify-content-center"
            >
              {rchCategory.slice(0, 7).map((s) => (
                <div
                  style={{ width: "100%", height: "6rem", cursor: "pointer" }}
                  className="col-2"
                  onClick={() => RedirectTo(s.redirect)}
                >
                  <div
                    style={{ borderRadius: cardRadius }}
                    className="card-hover card p-2 w-100 h-100 d-flex justify-content-center align-items-center my-drop"
                  >
                    <img
                      style={{ width: "2.5rem" }}
                      src={require(`../../../assets/icons/${s.image}.png`)}
                    />
                    <span style={{ fontSize: ".8rem" }} className="text-center">
                      {s.lable}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex flex-row p-3 justify-content-center">
              <h5>
                <strong
                  style={{ color: "#002E94" }}
                  className="text-center txt-prime"
                >
                  Utility Bills
                </strong>
              </h5>
            </div>

            <div
              style={{ width: "100%" }}
              className="col-12 d-flex justify-content-start"
            >
              {utilityBills.slice(0, 7).map((s) => (
                <div
                  style={{ width: "100%", height: "6rem", cursor: "pointer" }}
                  className="col-2"
                  onClick={() => RedirectTo(s.redirect)}
                >
                  <div
                    style={{ borderRadius: cardRadius }}
                    className="card-hover card p-2 w-100 h-100 d-flex justify-content-center align-items-center my-drop"
                  >
                    <img
                      style={{ width: "2.5rem" }}
                      src={require(`../../../assets/icons/${s.image}.png`)}
                    />
                    <span style={{ fontSize: ".8rem" }} className="text-center">
                      {s.lable}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex p-3 flex-row justify-content-center">
              <h5>
                <strong
                  style={{ color: "#002E94" }}
                  className="text-center txt-prime"
                >
                  Finance And Tax
                </strong>
              </h5>
            </div>

            <div
              style={{ width: "100%" }}
              className="col-12 d-flex justify-content-start"
            >
              {financeAndTax.slice(0, 7).map((s) => (
                <div
                  style={{ width: "100%", height: "6rem", cursor: "pointer" }}
                  className="col-2"
                  onClick={() => RedirectTo(s.redirect)}
                >
                  <div
                    style={{ borderRadius: cardRadius }}
                    className="card-hover card p-2 w-100 h-100 d-flex justify-content-center align-items-center my-drop"
                  >
                    <img
                      style={{ width: "35%" }}
                      src={require(`../../../assets/icons/${s.image}.png`)}
                    />
                    <span style={{ fontSize: ".8rem" }} className="text-center">
                      {s.lable}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {/* <div
              style={{ width: "100%" }}
              className="col-12 d-flex justify-content-start"
            >
              {financeAndTax.slice(6, 8).map((s) => (
                <div
                  style={{ width: "100%", height: "6rem", cursor: 'pointer' }}
                  className="col-2 mt-3"
                  onClick={() => RedirectTo(s.redirect)}
                >
                  <div
                    style={{ borderRadius:cardRadius }}
                    className="card-hover card p-2 w-100 h-100 d-flex justify-content-center align-items-center my-drop"
                  >
                    <img
                      style={{ width: "2.5rem", height: "auto", margin: "0 auto" }}
                      src={require(`../../../assets/icons/${s.image}.png`)}
                    />
                    <span style={{ fontSize: '.8rem' }} className="text-center">{s.lable}</span>
                  </div>
                </div>
              ))}
            </div> */}
            <div
              style={{ width: "100%", marginTop: "2rem" }}
              className="col-12 d-flex justify-content-center"
            >
              {financeAndTax2.slice(0, 3).map((s) => (
                <div
                  style={{ width: "100%", height: "6rem", cursor: "pointer" }}
                  className="col-2"
                  onClick={() => RedirectTo(s.redirect)}
                >
                  <div
                    style={{ borderRadius: cardRadius }}
                    className="card-hover card p-2 w-100 h-100 d-flex justify-content-center align-items-center my-drop"
                  >
                    <img
                      style={{ width: "2.5rem" }}
                      src={require(`../../../assets/icons/${s.image}.png`)}
                    />
                    <span style={{ fontSize: ".8rem" }} className="text-center">
                      {s.lable}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex flex-row p-3 justify-content-center">
              <h5>
                <strong
                  style={{ color: "#002E94" }}
                  className="text-center txt-prime"
                >
                  Other
                </strong>
              </h5>
            </div>

            <div
              style={{ width: "100%", marginBottom: "5rem" }}
              className="col-12 d-flex justify-content-start"
            >
              {other.slice(0, 7).map((s) => (
                <div
                  style={{ width: "100%", height: "6rem", cursor: "pointer" }}
                  className="col-2"
                  onClick={() => RedirectTo(s.redirect)}
                >
                  <div
                    style={{ borderRadius: cardRadius }}
                    className="card-hover card p-2 w-100 h-100 d-flex justify-content-center align-items-center my-drop"
                  >
                    <img
                      style={{ width: "2.5rem" }}
                      src={require(`../../../assets/icons/${s.image}.png`)}
                    />
                    <span style={{ fontSize: ".8rem" }} className="text-center">
                      {s.lable}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{ width: "100%" }}
              className="col-12 d-flex justify-content-center"
            >
              {shorts.slice(24, 30).map((s) => (
                <div
                  style={{ width: "100%", cursor: "pointer" }}
                  className="col-2"
                  onClick={() => RedirectTo(s.redirect)}
                >
                  <div className="card p-2 m-2">
                    <img
                      style={{ width: "35%", height: "auto", margin: "0 auto" }}
                      src={require(`../../../assets/images/bbps/${s.image}.png`)}
                    />
                    <strong className="text-center">{s.lable}</strong>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

const BalanceCard = ({ type, balance, classes }) => {
  return (
    <div className={`col-2`}>
      <div className={`card bg-success text-light p-2 ${classes}`}>
        <h4 className="m-0">
          <strong> {type} </strong>
        </h4>
        <strong>{balance}</strong>
      </div>
    </div>
  );
};

export default BillPayment;
