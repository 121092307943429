import React, { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import {
    Form,
    Dropdown,
    Button,
    Row,
    Col,
    Modal,
    Tab,
    Nav,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ContextMenuTrigger } from "react-contextmenu";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import { Redirect } from "react-router";

import Lottie from "lottie-react";

import Hurray2 from "../../../assets/animation/Hurray.json";
import {
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT,
    BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import Select from "react-select";
import QRCode from "qrcode";
import {
    CERTIFICATE_ADDRESS,
    CERTIFICATE_COMPANY_NAME,
    COMPANY_NAME,
    QR_TITLE_COMPANY_NAME,
} from "../../utils/StringConstants";
import {
    COLOR_QR_PLACEHOLDER,
    COLOR_QR_PLACEHOLDER_SECONDARY,
} from "../../utils/Themer";
import {
    generateHashKey2,
    inWords,
    twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import swal from "sweetalert";
import { useQuery } from "../../custom-hooks/useQuery";
import "./offer.css"

const paymentModes = [
    { label: "Cash Deposite", value: "3" },
    { label: "UPI/IMPS", value: "2" },
    { label: "NEFT/RTGS", value: "5" },
    { label: "CDM", value: "9" },
    { label: "Fund Transfer", value: "8" },
    { label: "CHEQUE", value: "null" },
];
const months = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];
const dateFormatToDisplay = (date) => {
    if (date) {
        const d = new Date(date);

        let hours = d.getHours();
        let minutes = d.getMinutes();
        let ap = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes.toString().padStart(2, "0");
        let mergeTime = hours + ":" + minutes + " " + ap;
        return `${d.getDate()} ${months[d.getMonth()]
            } ${d.getFullYear()} ${mergeTime}`;
    } else {
        return "";
    }
};

const generateURL = ({ amount, name, upi }) => {
    return `upi://pay?pa=${upi}&pn=${name}&cu=INR`;
};

const loadCanvas = async (canvasEl, dataURL, canvasContextRef) => {
    const canvasContext = canvasEl.current.getContext("2d");
    const imageObj = new Image();

    canvasContextRef.current = canvasContext;
    imageObj.onload = function () {
        canvasContext.drawImage(this, 0, 0);
    };
    imageObj.src = dataURL;
};

const getQRImage = async ({ amount, name, upi }) => {
    try {
        const url = generateURL({ amount, name, upi });
        console.log(url);
        const qrCode = await QRCode.toDataURL(url, {
            type: "image/png",
            margin: 1,
            width: 300,
        });
        return qrCode;
    } catch (e) {
        console.log("API exception ", e);
        return "";
    }
};

const paintQR = async (canvasEl, canvasContextRef, { amount, name, upi }) => {
    const dataURL = await getQRImage({ amount, name, upi });
    await loadCanvas(canvasEl, dataURL, canvasContextRef);
};

const Topup = () => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [qrEnabled, setQrEnabled] = useState(false);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const [userId, setUserId] = useState("");
    const canvasContextRef = useRef(null);
    const [upiId, setUpiId] = useState("");
    const [name, setName] = useState("");
    const validate = () => {
        if (
            amount === "" ||
            transactionId === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const print = () => {
        const newWindow = window.open(`/pages/qr`, "", "height=800,width=600");
        if (newWindow) newWindow.opener = null;
    };

    const virtualAccountCreate = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("method", "virtualaccount_sidh");
            urlencoded.append("userid", user.ReferId);
            urlencoded.append("mobileno", user.Mobile);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiservices.aspx`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            if (data.sCode == 0) {
                localStorage.setItem("va-created", true);
                setAccountCreated(true);
                setEnableSmartCollectButtonState(true);
                getVirtualAccount();
            }
            window.location.reload();
        } catch (error) {
            console.log("error", error);
        }
    };
    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${transactionId}.jpeg&TransactionId=${transactionId}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const getVirtualAccount = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=get_virtualaccountBumppy&userid=${user.ReferId}`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            paintQR(canvasEl, canvasContextRef, {
                amount: 1,
                name: COMPANY_NAME,
                upi: data.va_qrurl,
            });
            if (data.status == 0 || data.status === "0") {
                setVirtualAccount(data);
            } else {
                setEnableSmartCollectButtonState(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {
                var raw = JSON.stringify({
                    imagename: `img_${transactionId}`,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        getBankDetails();
        getVirtualAccount();
    }, []);

    const getAccessInfo = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apitranxt.bumppypay.com/api/user/get_user_access_info.aspx",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                data.services.forEach((service) => {
                    if (service.pk_menu_id == "23") {
                        if (service.status == "Y") {
                            setQrEnabled(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAccessInfo();
    }, []);

    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />

                        <Modal
                            show={mdShow}
                            onHide={() => setMdShow(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                            size="lg"
                        >
                            {/* <Modal.Header closeButton>
                <Modal.Title> INDEMNITY BOND</Modal.Title>
              </Modal.Header> */}

                            <Modal.Body
                                style={{ width: "100%", display: "block" }}
                                className="account"
                            >
                                {Profileinfo && (
                                    <Row>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ textAlign: "center" }}>
                                                <h3>INDEMNITY BOND</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex"></div>

                                                <div className="row d-flex mt-2">
                                                    <div className="col-6">
                                                        <h5>
                                                            <strong>Date:- </strong>{" "}
                                                            {dateFormatToDisplay(submitTime)}
                                                        </h5>
                                                        <h5>
                                                            <strong>From:- </strong>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end">
                                                        <img
                                                            src={require("../../../assets/images/company/logo_square.png")}
                                                            style={{
                                                                width: "60%",
                                                                height: "auto",
                                                                marginLeft: "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <table
                                                    style={{ border: "1px solid black", width: "100%" }}
                                                >
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Merchant/Agent name</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.shopname}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>BC Agent Id</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {user.ReferId}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Registered Mobile number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.mobile}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>PAN number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            GDJPS5650A
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Request No.</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            18012023419638
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            Rs. {amount}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount (In Words)</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {inWords(Number(amount))}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Bank Account </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {selectedBank?.bankName} -:{" "}
                                                            {selectedBank?.accountNo}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Deposit Date</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {dateFormatToDisplay(date)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Payment Type</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {paymentMode.label}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Remark </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {remark}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <div className="my-4">
                                                    <strong>
                                                        To,<br></br>
                                                        The Board of Directors,<br></br>
                                                        {CERTIFICATE_COMPANY_NAME} <br></br>
                                                        <span>{CERTIFICATE_ADDRESS}</span>
                                                    </strong>
                                                </div>

                                                <div>
                                                    <p className="mb-2" style={{ fontSize: "16px" }}>
                                                        Dear Sir/Madam,
                                                    </p>

                                                    <p style={{ fontSize: "16px" }}>
                                                        I, <strong>{Profileinfo?.shopname}</strong>, as a
                                                        Merchant/Agent hereby undertake and explicitly agree
                                                        to indemnify {CERTIFICATE_COMPANY_NAME} towards the
                                                        following points:
                                                    </p>

                                                    <div className="p-4">
                                                        <ul style={{ fontSize: "16px" }}>
                                                            <li>
                                                                {CERTIFICATE_COMPANY_NAME} is providing us with
                                                                a platform as an enabler through which we can
                                                                transfer/receive/top up the money through
                                                                various methods likeUPI/ IMPS/ RTGS/ Payment
                                                                Gateway etc. from one person to another(P2P and
                                                                P2M) against a separate consideration;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for the
                                                                collection of KYC/ meeting the Statutory
                                                                requirements and other mandatory documents from
                                                                the sender or receiver or both and also the
                                                                reasons of such transactions;
                                                            </li>
                                                            <li>
                                                                I am responsible and abide to provide the KYC
                                                                and other mandatory documents and reasons of
                                                                each and every transactions with end customers
                                                                to the at {COMPANY_NAME}'s discretion;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for those
                                                                transactions which was wrongly debited or
                                                                credited by me to another party or any incorrect
                                                                entry/entries while using the platform;and
                                                            </li>
                                                            <li>
                                                                After obtaining a proper understanding of the
                                                                transaction patterns of this Company, I am
                                                                giving my consent to use this platform with all
                                                                the terms and conditions as provided by the{" "}
                                                                {CERTIFICATE_COMPANY_NAME} and also assuring
                                                                that every sender or receiver or both only after
                                                                giving their full consent using this platform
                                                                for transfer / receive / topup the money through
                                                                various methods like CASH / UPI / IMPS / NEFT /
                                                                RTGS / Payment Gateway etc.
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <p className="mt-4" style={{ fontSize: "16px" }}>
                                                        Thanking you,
                                                    </p>

                                                    <p style={{ fontSize: "16px", marginTop: "4rem" }}>
                                                        <strong>
                                                            {Profileinfo?.shopname}
                                                            <br></br>
                                                            (Merchant’s/Agent’s Name)<br></br>
                                                            {Profileinfo?.name}
                                                            <br></br>
                                                            Seal and Signature<br></br>
                                                        </strong>
                                                    </p>

                                                    <p className="mt-2" style={{ fontSize: "16px" }}>
                                                        <strong>
                                                            Timestamp : {dateFormatToDisplay(new Date())}
                                                        </strong>
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-6"></div>
                                                        <div className="col-12 col-md-6 d-flex justify-content-end">
                                                            <Button
                                                                variant="success"
                                                                className="rounded-0"
                                                                onClick={topupRequest}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="rounded-0 mx-2"
                                                                onClick={() => {
                                                                    setMdShow(false);
                                                                }}
                                                            >
                                                                Decline
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                            </Modal.Body>
                        </Modal>

                        <div style={{ padding: "0px" }} className="row p-4">
                            <Row>
                                <Col md={7} style={{}}>
                                    {/* first */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Notes"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Amount"
                                                        onChange={(e) => {
                                                            setAmount(e.target.value);
                                                        }}
                                                        style={{ borderRadius: "0px" }}
                                                        value={amount}
                                                    />
                                                </Form.Group>
                                                <strong style={{ color: "green" }}>
                                                    {inWords(Number(amount))}
                                                </strong>
                                            </div>

                                            <div className="col">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Notes"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Transaction ID"
                                                        onChange={(e) => {
                                                            setTransactionId(e.target.value);
                                                        }}
                                                        value={transactionId}
                                                        style={{ borderRadius: "0px" }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* second */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Select
                                                    options={banks}
                                                    onChange={(e) => {
                                                        setSelectedBank(e);
                                                    }}
                                                    value={selectedBank}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* third */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col grid-margin">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(date) => setDate(date)}
                                                    selected={date}
                                                    className="form-control"
                                                    placeholderText="Date of Deposit"
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </div>
                                            <div className="col">
                                                <Select
                                                    options={paymentModes}
                                                    onChange={(e) => {
                                                        setPaymentMode(e);
                                                    }}
                                                    value={paymentMode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* fourth */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Form.Group>
                                                    <textarea
                                                        placeholder="Remarks"
                                                        style={{
                                                            padding: "5px",
                                                            width: "100%",
                                                            borderRadius: "0px",
                                                        }}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                        value={remark}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* fifth */}
                                    <div className="container">
                                        <div style={{ padding: "10px" }} className="row">
                                            <div className="col grid-margin">
                                                <Form.Group>
                                                    <Form.Label>
                                                        <b>Slip Upload</b>
                                                        <span>(Optional)</span>
                                                    </Form.Label>
                                                    <Form.Label>
                                                        <span className="text-danger">
                                                            Image size should be less than 256 kb
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        accept=".jpeg"
                                                        type="file"
                                                        name="slip"
                                                        onChange={ImageUpload}
                                                        style={{ borderRadius: "0px" }}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col">
                                                <Form.Group>
                                                    <Invalid
                                                        text={TopupData.msg}
                                                        style={
                                                            TopupData.success
                                                                ? "card-inverse-success"
                                                                : "card-inverse-danger"
                                                        }
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* sixth */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col grid-margin"></div>
                                            <div className="col"></div>
                                            <div className="col">
                                                <Button
                                                    // onClick={topupRequest}
                                                    onClick={validate}
                                                    className="col"
                                                    variant="success"
                                                    style={{ borderRadius: "0px" }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col
                                    md={5}
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            margin: "0px 1rem",
                                            padding: "0px",
                                            borderRadius: "1rem",
                                            boxShadow: DROP_SHADOW,
                                        }}
                                    >
                                        <div
                                            style={{
                                                background: COLOR_QR_PLACEHOLDER,
                                                display: "flex",
                                                padding: "6px 1rem",
                                                borderTopRightRadius: "1rem",
                                                borderTopLeftRadius: "1rem",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ color: "white", fontWeight: "bolder" }}>
                                                {/* <h4 style={{ fontWeight: "bolder", margin: "0px" }}>
                          EARN MORE
                        </h4> */}
                                            </div>
                                            <div style={{ margin: "0px 2rem" }}>
                                                <img
                                                    className="p-1"
                                                    style={{ height: "3rem", width: "auto" }}
                                                    src={require("../../../assets/images/virtual_account/qr_logo_square.png")}
                                                />
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    sessionStorage.setItem(
                                                        "print",
                                                        JSON.stringify(virtualAccount)
                                                    );
                                                    print();
                                                }}
                                                variant=""
                                                className="rounded-0 ml-auto"
                                                size="sm"
                                                style={{
                                                    background: "#56BE89",
                                                    color: "white",
                                                    border: "none",
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                padding: "1rem",
                                                background: "#FFFFFF",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    style={{ height: "auto", width: "100%" }}
                                                    src={require("../../../assets/images/virtual_account/virtual_account_app_logo.png")}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    flex: "1",
                                                    background: "#FFFFFF",
                                                    boxShadow:
                                                        "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                                    padding: "6px",
                                                    borderRadius: "6px",
                                                }}
                                            >
                                                <img
                                                    style={{ height: "auto", width: "100%" }}
                                                    src={require("../../../assets/images/virtual_account/bhim_upi_logo.png")}
                                                />
                                                <canvas
                                                    style={{ width: "100%", height: "auto" }}
                                                    ref={canvasEl}
                                                    id="qrCanvas"
                                                    width="300"
                                                    height="300"
                                                    className="mx-auto"
                                                />
                                                <div>
                                                    <strong>PAY WITH ANY APP</strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                background: COLOR_QR_PLACEHOLDER_SECONDARY,
                                                color: "#FFFFFF",
                                                padding: "6px",
                                                borderBottomRightRadius: "1rem",
                                                borderBottomLeftRadius: "1rem",
                                            }}
                                        >
                                            <table style={{ width: "100%" }}>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        {" "}
                                                        <span style={{ fontSize: "1rem" }}>Shop Name:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <strong style={{ width: "50%", fontSize: "1rem" }}>
                                                            {Profileinfo?.shopname}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            Account No:
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }} className="pl-1">
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_account_no
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>IFSC Code:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_ifsc
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            Deposit Type:
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            {" "}
                                                            {"IMPS/NEFT/RTGS."}{" "}
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>UPI ID:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }} className="pl-1">
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_upiid
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    {!enableSmartCollectButtonState && (
                                        <React.Fragment>
                                            <div className="p-2 mt-2">
                                                <h5 style={{ color: "grey" }}>
                                                    To start Smart Collect feature please click on the
                                                    below button
                                                </h5>
                                            </div>
                                            <Button
                                                type="button"
                                                className="mt-2"
                                                style={{ borderRadius: "0px" }}
                                                disabled={enableSmartCollectButtonState}
                                                onClick={virtualAccountCreate}
                                            >
                                                Enable Smart Collect
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

const QrCode = ({ qrEnabled }) => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const canvasContextRef = useRef(null);
    const [upiId, setUpiId] = useState("");
    const [name, setName] = useState("");
    const validate = () => {
        if (
            amount === "" ||
            transactionId === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const print = () => {
        const newWindow = window.open(`/pages/qr`, "", "height=800,width=600");
        if (newWindow) newWindow.opener = null;
    };

    const virtualAccountCreate = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("method", "virtualaccount_sidh");
            urlencoded.append("userid", user.ReferId);
            urlencoded.append("mobileno", user.Mobile);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiservices.aspx`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            if (data.sCode == 0) {
                localStorage.setItem("va-created", true);
                setAccountCreated(true);
                setEnableSmartCollectButtonState(true);
                getVirtualAccount();
            }
            window.location.reload();
        } catch (error) {
            console.log("error", error);
        }
    };
    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            var requestOptions = {
                method: "GET",
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${transactionId}.jpeg&TransactionId=${transactionId}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const getVirtualAccount = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=get_virtualaccountBumppy&userid=${user.ReferId}`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            paintQR(canvasEl, canvasContextRef, {
                amount: 1,
                name: COMPANY_NAME,
                upi: data.va_qrurl,
            });
            if (data.status == 0 || data.status === "0") {
                setVirtualAccount(data);
            } else {
                setEnableSmartCollectButtonState(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {
                var raw = JSON.stringify({
                    imagename: `img_${transactionId}`,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        getBankDetails();
        if (qrEnabled) getVirtualAccount();
    }, [qrEnabled]);
    console.log(selectedBank);
    console.log(paymentMode);
    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />
                        {qrEnabled ? (
                            <div className="row  py-1 mt-4">
                                <div className="col-7 ">
                                    <div
                                        className=" row card"
                                        style={{
                                            height: "45vh",
                                            backgroundColor: "#fffff",
                                            marginLeft: "3.5rem",
                                            marginRight: "2rem",
                                        }}
                                    >
                                        <div className="col-6">
                                            <div
                                                // md={12}
                                                style={
                                                    {
                                                        // textAlign: "center",
                                                        // maxWidth: "40rem",
                                                    }
                                                }
                                            >
                                                <div
                                                    className=""
                                                    style={
                                                        {
                                                            // margin: "0px 1rem",
                                                            // padding: "0px",
                                                            // borderRadius: "1rem",
                                                        }
                                                    }
                                                >
                                                    <div
                                                        style={
                                                            {
                                                                // padding: "1rem",
                                                                // background: "#FFFFFF",
                                                                // alignItems: "center",
                                                            }
                                                        }
                                                    >
                                                        <div>
                                                            <img
                                                                style={{
                                                                    height: "auto",
                                                                    width: "80%",
                                                                    marginLeft: "2rem",
                                                                    marginTop: "2rem",
                                                                }}
                                                                src={require("../../../assets/images/virtual_account/virtual_account_app_logo.png")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {!enableSmartCollectButtonState && (
                                                    <React.Fragment>
                                                        <div className="p-2 mt-2">
                                                            <h5 style={{ color: "grey" }}>
                                                                To start Smart Collect feature please click on
                                                                the below button
                                                            </h5>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            className="mt-2"
                                                            style={{ borderRadius: "0px" }}
                                                            disabled={enableSmartCollectButtonState}
                                                            onClick={virtualAccountCreate}
                                                        >
                                                            Enable Smart Collect
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="col-6  justify-content-end"

                                        // style={{
                                        //   flex: "1",
                                        //   background: "#FFFFFF",
                                        //   boxShadow:
                                        //     "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                        //   padding: "6px",
                                        //   borderRadius: "6px",
                                        // }}
                                        >
                                            <div className="card mt-3">
                                                <img
                                                    style={{
                                                        height: "auto",
                                                        width: "72%",
                                                        marginLeft: "2.5rem",
                                                    }}
                                                    src={require("../../../assets/images/virtual_account/bhim_upi_logo.png")}
                                                />
                                                <canvas
                                                    style={{
                                                        width: "72%",
                                                        height: "auto",
                                                        marginLeft: "2.5rem",
                                                    }}
                                                    ref={canvasEl}
                                                    id="qrCanvas"
                                                    width="300"
                                                    height="300"
                                                />
                                                <div style={{ marginLeft: "3rem" }}>
                                                    <strong>PAY WITH ANY APP</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div
                                        className="row d-flex "
                                        style={{
                                            background: "#F1F2F4",
                                            display: "flex",
                                            padding: "6px 1rem",
                                            borderTopRightRadius: "1rem",
                                            borderTopLeftRadius: "1rem",
                                            //marginTop: "2rem",
                                        }}
                                    >
                                        <div className="col-9 ">
                                            <img
                                                style={{ height: "3.5rem", width: "auto" }}
                                                src={require("../../../assets/images/company/navbar_logo.png")}
                                            />
                                        </div>
                                        <div className="col-3">
                                            {" "}
                                            <Button
                                                className=""
                                                onClick={() => {
                                                    sessionStorage.setItem(
                                                        "print",
                                                        JSON.stringify(virtualAccount)
                                                    );
                                                    print();
                                                }}
                                                variant=""
                                                style={{
                                                    //  height: "100%",
                                                    // borderRadius: "0.5rem",
                                                    // width: "7.2vw",
                                                    //background: "#56BE89",
                                                    //color: "white",
                                                }}
                                            >
                                                <div className="hover-container"
                                                    style={{ cursor: "pointer" }}  >
                                                    <img className="pl-1 "
                                                        src={require("../../../assets/images/download Icon.png")}
                                                        style={{ width: `3.5rem`, height: '85%' }}
                                                    />
                                                    <div div style={{ color: 'rgba(255, 255,255, 1)' }} className="hover-text bg-dark p-2">Download</div>
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-3"
                                        style={{
                                            background: "#FFFFFF",
                                            color: "black",
                                            padding: "6px",
                                            borderRadius: "1rem",
                                            height: "60%",
                                            width: "75%",
                                        }}
                                    >
                                        <table style={{ width: "100%" }}>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    {" "}
                                                    <span style={{ fontSize: "1rem" }}>Shop Name:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <strong style={{ width: "50%", fontSize: "1rem" }}>
                                                        {Profileinfo?.shopname}
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>Account No:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }} className="pl-1">
                                                        {" "}
                                                        {virtualAccount
                                                            ? virtualAccount.va_account_no
                                                            : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>IFSC Code:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        {" "}
                                                        {virtualAccount ? virtualAccount.va_ifsc : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        Deposit Type:
                                                    </span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        {" "}
                                                        {"IMPS/NEFT/RTGS."}{" "}
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>UPI ID:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }} className="pl-1">
                                                        {" "}
                                                        {virtualAccount ? virtualAccount.va_upiid : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <span>QR Disabled</span>
                        )}
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

const LoadRequest = () => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const canvasContextRef = useRef(null);
    const validate = () => {
        if (
            amount.toString().trim() === "" ||
            transactionId.toString().trim() === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            let newTid = transactionId;
            if (newTid.length < 8) {
                newTid = `000000${newTid}`;
            }
            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${newTid}.jpeg&TransactionId=${newTid}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {
                let imageName = `img_${transactionId}`;
                if (imageName.length < 13) {
                    imageName = `img_000000${transactionId}`
                }
                var raw = JSON.stringify({
                    imagename: imageName,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        getBankDetails();
    }, []);
    console.log(selectedBank);
    console.log(paymentMode);
    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />

                        <Modal
                            show={mdShow}
                            onHide={() => setMdShow(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                            size="lg"
                        >
                            {/* <Modal.Header closeButton>
                <Modal.Title> INDEMNITY BOND</Modal.Title>
              </Modal.Header> */}

                            <Modal.Body
                                style={{ width: "100%", height: "100vh", display: "block" }}
                                className="account"
                            >
                                {Profileinfo && (
                                    <Row>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ textAlign: "center" }}>
                                                <h3>INDEMNITY BOND</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex"></div>

                                                <div className="row d-flex mt-2">
                                                    <div className="col-6">
                                                        <h5>
                                                            <strong>Date:- </strong>{" "}
                                                            {dateFormatToDisplay(submitTime)}
                                                        </h5>
                                                        <h5>
                                                            <strong>From:- </strong>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end">
                                                        <img
                                                            src={require("../../../assets/images/company/navbar_logo.png")}
                                                            style={{
                                                                width: "60%",
                                                                height: "auto",
                                                                marginLeft: "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <table
                                                    style={{ border: "1px solid black", width: "100%" }}
                                                >
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Merchant/Agent name</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.shopname}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>BC Agent Id</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {user.ReferId}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Registered Mobile number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.mobile}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>PAN number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            GDJPS5650A
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Request No.</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            18012023419638
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            Rs. {amount}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount (In Words)</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {inWords(Number(amount))}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Bank Account </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {selectedBank?.bankName} -:{" "}
                                                            {selectedBank?.accountNo}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Deposit Date</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {dateFormatToDisplay(date)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Payment Type</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {paymentMode.label}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Remark </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {remark}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <div className="my-4">
                                                    <strong>
                                                        To,<br></br>
                                                        The Board of Directors,<br></br>
                                                        {CERTIFICATE_COMPANY_NAME} <br></br>
                                                        <span>{CERTIFICATE_ADDRESS}</span>
                                                    </strong>
                                                </div>

                                                <div>
                                                    <p className="mb-2" style={{ fontSize: "16px" }}>
                                                        Dear Sir/Madam,
                                                    </p>

                                                    <p style={{ fontSize: "16px" }}>
                                                        I, <strong>{Profileinfo?.shopname}</strong>, as a
                                                        Merchant/Agent hereby undertake and explicitly agree
                                                        to indemnify {CERTIFICATE_COMPANY_NAME} towards the
                                                        following points:
                                                    </p>

                                                    <div className="p-4">
                                                        <ul style={{ fontSize: "16px" }}>
                                                            <li>
                                                                {CERTIFICATE_COMPANY_NAME} is providing us with
                                                                a platform as an enabler through which we can
                                                                transfer/receive/top up the money through
                                                                various methods likeUPI/ IMPS/ RTGS/ Payment
                                                                Gateway etc. from one person to another(P2P and
                                                                P2M) against a separate consideration;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for the
                                                                collection of KYC/ meeting the Statutory
                                                                requirements and other mandatory documents from
                                                                the sender or receiver or both and also the
                                                                reasons of such transactions;
                                                            </li>
                                                            <li>
                                                                I am responsible and abide to provide the KYC
                                                                and other mandatory documents and reasons of
                                                                each and every transactions with end customers
                                                                to the at {COMPANY_NAME}'s discretion;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for those
                                                                transactions which was wrongly debited or
                                                                credited by me to another party or any incorrect
                                                                entry/entries while using the platform;and
                                                            </li>
                                                            <li>
                                                                After obtaining a proper understanding of the
                                                                transaction patterns of this Company, I am
                                                                giving my consent to use this platform with all
                                                                the terms and conditions as provided by the{" "}
                                                                {CERTIFICATE_COMPANY_NAME} and also assuring
                                                                that every sender or receiver or both only after
                                                                giving their full consent using this platform
                                                                for transfer / receive / topup the money through
                                                                various methods like CASH / UPI / IMPS / NEFT /
                                                                RTGS / Payment Gateway etc.
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <p className="mt-4" style={{ fontSize: "16px" }}>
                                                        Thanking you,
                                                    </p>

                                                    <p style={{ fontSize: "16px", marginTop: "4rem" }}>
                                                        <strong>
                                                            {Profileinfo?.shopname}
                                                            <br></br>
                                                            (Merchant’s/Agent’s Name)<br></br>
                                                            {Profileinfo?.name}
                                                            <br></br>
                                                            Seal and Signature<br></br>
                                                        </strong>
                                                    </p>

                                                    <p className="mt-2" style={{ fontSize: "16px" }}>
                                                        <strong>
                                                            Timestamp : {dateFormatToDisplay(new Date())}
                                                        </strong>
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-6"></div>
                                                        <div className="col-12 col-md-6 d-flex justify-content-end">
                                                            <Button
                                                                variant="success"
                                                                className="rounded-0"
                                                                onClick={topupRequest}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="rounded-0 mx-2"
                                                                onClick={() => {
                                                                    setMdShow(false);
                                                                }}
                                                            >
                                                                Decline
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                            </Modal.Body>
                        </Modal>

                        <Row className="justify-content-center p-0">
                            <Col
                                md={12}
                                style={{
                                    textAlign: "center",
                                    maxWidth: "35rem",
                                }}
                                className="card"
                            >
                                {/* first */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Control
                                                    type="number"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Amount"
                                                    onChange={(e) => {
                                                        setAmount(e.target.value);
                                                    }}
                                                    style={{ borderRadius: "0px" }}
                                                    value={amount}
                                                />
                                            </Form.Group>
                                            <strong style={{ color: "green" }}>
                                                {inWords(Number(amount))}
                                            </strong>
                                        </div>

                                        <div className="col">
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Transaction ID"
                                                    onChange={(e) => {
                                                        setTransactionId(e.target.value);
                                                    }}
                                                    value={transactionId}
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* second */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Select
                                                options={banks}
                                                onChange={(e) => {
                                                    setSelectedBank(e);
                                                }}
                                                value={selectedBank}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* third */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col grid-margin">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => setDate(date)}
                                                selected={date}
                                                className="form-control"
                                                placeholderText="Date of Deposit"
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </div>
                                        <div className="col">
                                            <Select
                                                options={paymentModes}
                                                onChange={(e) => {
                                                    setPaymentMode(e);
                                                }}
                                                value={paymentMode}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* fourth */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <textarea
                                                    placeholder="Remarks"
                                                    style={{
                                                        padding: "5px",
                                                        width: "100%",
                                                        borderRadius: "0px",
                                                    }}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                    value={remark}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* fifth */}
                                <div className="container">
                                    <div style={{ padding: "10px" }} className="row">
                                        <div className="col grid-margin">
                                            <Form.Group>
                                                <Form.Label>
                                                    <b>Slip Upload</b>
                                                    <span>(Optional)</span>
                                                </Form.Label>
                                                <Form.Label>
                                                    <span className="text-danger">
                                                        Image size should be less than 256 kb
                                                    </span>
                                                </Form.Label>
                                                <Form.Control
                                                    accept=".jpeg"
                                                    type="file"
                                                    name="slip"
                                                    onChange={ImageUpload}
                                                    style={{ borderRadius: "0px" }}
                                                ></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col">
                                            <Form.Group>
                                                <Invalid
                                                    text={TopupData.msg}
                                                    style={
                                                        TopupData.success
                                                            ? "card-inverse-success"
                                                            : "card-inverse-danger"
                                                    }
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* sixth */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col grid-margin"></div>
                                        <div className="col"></div>
                                        <div className="col">
                                            <Button
                                                // onClick={topupRequest}
                                                onClick={validate}
                                                className="col"
                                                variant="success"
                                                style={{ borderRadius: "0px" }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

export const Invalid = ({ text, style }) => {
    console.log("hiii");
    return (
        <div className={`${style}`}>
            <ContextMenuTrigger id="actionContextMenu">
                <div className="card-body">
                    <p className="card-text">{text}</p>
                </div>
            </ContextMenuTrigger>
        </div>
    );
};

// export const TopupRequest = () => {
//   return (
//     <Tab.Container id="left-tabs-example" defaultActiveKey="first">
//       <Row>
//         <div className="col-12">
//           <Nav
//             variant="pills"
//             className="nav-pills-custom justify-content-around p-1 "
//             style={{
//               borderRadius: "1rem",
//               padding: "1rem",
//               border: "1px solid #2F58CD",
//               background: "#FFF",
//             }}
//           >
//             <Nav.Item>
//               <Nav.Link eventKey="first" className="d-flex align-items-center">
//                 Virtual
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link eventKey="second" className="d-flex align-items-center">
//                 Payment Gateway
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link eventKey="third" className="d-flex align-items-center">
//                 Load Request
//               </Nav.Link>
//             </Nav.Item>
//           </Nav>
//         </div>
//         <Col xs={12} className="p-0">
//           <Tab.Content className="tab-content-custom-pill p-0">
//             <Tab.Pane eventKey="first">
//               <QrCode />
//             </Tab.Pane>
//             <Tab.Pane eventKey="second"></Tab.Pane>
//             <Tab.Pane eventKey="third">
//               <LoadRequest />
//             </Tab.Pane>
//           </Tab.Content>
//         </Col>
//       </Row>
//     </Tab.Container>
//   );
// };

export const TopupRequest = () => {
    const { user, Profileinfo } = useContext(GlobalContext);
    const [qrEnabled, setQrEnabled] = useState(false);
    const [dcEnable, setDcEnabled] = useState(false);
    const [ccEnable, setCcEnabled] = useState(false);
    const [nbEnabled, setNbEnabled] = useState(false);
    const [pgEnabled, setPgEnabled] = useState(false);
    const [pg2Enabled, setPg2Enabled] = useState(false);
    const [pg3Enabled, setPg3Enabled] = useState(false);
    const [pg4Enabled, setPg4Enabled] = useState(false);
    const [pg5Enabled, setPg5Enabled] = useState(false);
    const query = useQuery();

    const getAccessInfo = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apitranxt.bumppypay.com/api/user/get_user_access_info.aspx",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                data.services.forEach((service) => {
                    if (service.pk_menu_id == "23" && service.status == "Y") {
                        setQrEnabled(true);
                    }
                    if (service.pk_menu_id == "27" && service.status == "Y") {
                        setCcEnabled(true);
                    }
                    if (service.pk_menu_id == "28" && service.status == "Y") {
                        setDcEnabled(true);
                    }
                    if (service.pk_menu_id == "29" && service.status == "Y") {
                        setNbEnabled(true);
                    }
                    if (service.pk_menu_id == "33" && service.status == "Y") {
                        setPgEnabled(true);
                    }
                    if (service.pk_menu_id == "36" && service.status == "Y") {
                        setPg2Enabled(true);
                    }

                    if (service.pk_menu_id == "40" && service.status == "Y") {
                        setPg3Enabled(true);
                    }
                    if (service.pk_menu_id == "42" && service.status == "Y") {
                        setPg4Enabled(true);
                    }
                    if (service.pk_menu_id == "48" && service.status == "Y") {
                        setPg5Enabled(true);
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAccessInfo();
    }, []);

    useEffect(() => {
        const orderid = query.get("orderid");
        const status = query.get("status");
        const amount = query.get("amount");
        if (status && status == "success") {
            swal("Success", `Payment Success for Amount ${amount} `, "success");

        } else if (status) {
            swal("Failed", `Payment Failed for Amount ${amount} `, "error");
        }
    }, []);

    return (
        <div
            style={{
                backgroundColor: " #F1F2F4",
                height: "100%",
                width: "100%"
            }}
        >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={12}>
                        <Tab.Content className="tab-content-vertical border-0">
                            <Tab.Pane eventKey="first">
                                <InstantLoad
                                    qrEnabled={qrEnabled}
                                    dcEnable={dcEnable}
                                    ccEnable={ccEnable}
                                    nbEnabled={nbEnabled}
                                    pgEnabled={pgEnabled}
                                    pg2Enabled={pg2Enabled}
                                    pg3Enabled={pg3Enabled}
                                    pg4Enabled={pg4Enabled}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

const InstantLoad = ({ qrEnabled, dcEnable, ccEnable, nbEnabled, pgEnabled, pg2Enabled, pg3Enabled, pg4Enabled, pg5Enabled }) => {
    const [mode, setMode] = useState("CC");
    return (
        <div className=""
            style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                border: "0px solid #4267B2",
                borderRadius: "10px",
                margin: "1rem",
                boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.1)'

            }}
        >
            <h3
                className="py-2 pt-3"
                style={{
                    marginLeft: "1.4rem",
                    color: "#4267B2",
                    fontFamily: "poppins",
                }}
            >
                <b>Add Funds</b>
            </h3>
            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first"
                className=""
            >
                <Row className="m-0 p-0 ">
                    <Col md={6} sm={4} lg={12}>
                        <Nav
                            variant="pills"
                            className="nav-pills-custom-5  justify-content-start p-2 py-1 "
                            style={{
                                border: "none",
                                background: "#EEF0F1",
                                color: "#4267B2",
                                borderRadius: "10px",



                            }}
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                    <img className="pr-1 mb-1 "
                                        src={require("../../../assets/images/UPI.svg")}
                                        style={{ width: `40%`, height: '40%' }}
                                    />
                                    UPI
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sixth">
                                    {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                    <img className="pr-1 mb-1"
                                        src={require("../../../assets/images/Load Request.svg")}
                                        style={{ width: `20%`, height: '%' }} />
                                    Load Request
                                </Nav.Link>
                            </Nav.Item>
                            {ccEnable && (
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <div
                                            onClick={() => {
                                                setMode("CC");
                                            }}
                                        >
                                            <i className="mdi mdi-account-outline pr-2"></i>

                                            Credit Card
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {dcEnable && (
                                <Nav.Item>
                                    <Nav.Link eventKey="third">
                                        <div
                                            onClick={() => {
                                                setMode("DC");
                                            }}
                                        >
                                            <i className="mdi mdi-email-open-outline pr-2"></i>
                                            Debit Card
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {nbEnabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">
                                        <div
                                            onClick={() => {
                                                setMode("NB");
                                            }}
                                        >
                                            <i className="mdi mdi-email-open-outline pr-2"></i>
                                            Net Banking
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {pgEnabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `15%`, height: '%' }} />
                                            Payment Gateway
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg2Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="seventh">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `15%`, height: '%' }} />
                                            Payment Gateway II
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg3Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="eightth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `15%`, height: '%' }} />
                                            Payment Gateway III
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg4Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="ninth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `15%`, height: '%' }} />
                                            Payment Gateway IV
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg5Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="ninth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `15%`, height: '%' }} />
                                            Payment Gateway V
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Col>
                    <Col className="m-0 py-0" sm={12}>
                        <Tab.Content className="tab-content-vertical border-0">
                            <Tab.Pane eventKey="first">
                                <QrCode qrEnabled={qrEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <PaymentGateway mode={mode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <PaymentGateway mode={mode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <PaymentGateway mode={mode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <PaymentGateway mode={mode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                                <LoadRequest />
                            </Tab.Pane>
                            <Tab.Pane eventKey="seventh">
                                <PaymentGateway2 mode={mode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="eightth">
                                <PaymentGateway3 mode={mode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="ninth">
                                <PaymentGateway4 mode={mode} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tenth">
                                <PaymentGateway5 mode={mode} />
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

const PaymentGateway = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}

                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};

const PaymentGateway2 = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } =
        useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
        plan: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
        plan: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [plan, setPlan] = useState({});
    const [selectedPlan, setSelectedPlan] = useState(1);
    const [savings, setSavings] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    //
    const [modalAskOpen, setModalAskOpen] = useState(false);
    const openAskModal = () => {
        setModalAskOpen(true);
    };

    const closeAskModal = () => {
        setModalAskOpen(false);
    };

    //

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {
        setSelectedPlan(e);
    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }


    const handleCardSelection = (cardType) => {
        setSelectedPlan(cardType);
    };
    const openModal = () => {
        setModalIsOpen(true);
    };
    console.log(openModal, "opnpg");
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (selectedPlan < planOption.length) {
                doYouWantToSaveMoremoney();
            } else {
                openModal(true);
            }
        }
        return false;
    };

    const doYouWantToSaveMoremoney = () => {
        openModal(true)
    }

    const validate2 = (event) => {
        if (event) {
            event.preventDefault();
        }
        openInNewTab(`https://bumppy.in/pg2/request.php?userid=${user.ReferId
            }&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount
            }&mobile=${Profileinfo?.mobile
            }&email=suhassuryavanshi5979@gmail.com&type=T&
             cust_name=${Profileinfo?.shopname
            }&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan
            }`);
    };

    const openGateway = (id) => {
        openInNewTab(`https://bumppy.in/pg2/request.php?userid=${user.ReferId
            }&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount
            }&mobile=${Profileinfo?.mobile
            }&email=suhassuryavanshi5979@gmail.com&type=T&
     cust_name=${Profileinfo?.shopname
            }&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${id
            }`);
    }

    // useEffect(() => {
    //     const amt = Number(inputState.amount);
    //     let cc = 0;
    //     charges.forEach((channel) => {
    //         if (channel.mode == 'CC') {
    //             cc = channel.charge;
    //         }
    //     })
    //     console.log(cc);
    //     const calculatedCharge = (amt * cc) / 100;

    //     setCharges2(calculatedCharge);
    // }, [inputState.amount]);

    //For Selected Plan
    useEffect(() => {
        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == "CC") {
                    cc = channel.charge;
                }
            });
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        } else {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            });

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }
    }, [selectedPlan, inputState.amount]);

    useEffect(() => {
        let selectedPlanObj = {};
        planOption.forEach((plan) => {

            if (plan.id == selectedPlan) {
                const amt = Number(inputState.amount);
                selectedPlanObj = plan;
                setPlan(plan);
                let savings1 = amt * Number(plan.charge) / 100;
                setSavings(savings1)
            }
        });

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == "CC") {
                    cc = channel.charge;
                }
            });
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        } else {
            const amt = Number(inputState.amount);
            let cc = 0;
            if (charges.length > 0) {
                cc = charges[0].charge - selectedPlanObj.charge;
                const calculatedCharge = (amt * cc) / 100;
                setCharges2(calculatedCharge);
            }

            if (selectedPlanObj) {
                let savings1 = amt * Number(selectedPlanObj.charge) / 100;
                setSavings(savings1)
            }

        }
    }, [selectedPlan, modalIsOpen])


    const getPaymentGatewayCharges = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data);
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,
                        };
                    })
                );
                if (result.data.length > 0) {
                    setPlan(result.data[0]);
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const openInNewTab = (data) => {
        const newWindow = window.open(data, "", "height=800,width=1000'");
        if (newWindow) newWindow.opener = null;
    };

    console.log(selectedPlan);

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    const getSavings = (id) => {
        let amt = Number(inputState.amount);
        let planObj = {};
        planOption.forEach((plan) => {
            if (plan.id == id) {
                planObj = plan;
            }
        });
        let savings1 = amt * Number(planObj.charge) / 100;
        return (savings1).toFixed(3);
    };
    const getCharges = (id) => {
        if (charges.length > 0) {
            let planObj = {};
            planOption.forEach((plan) => {

                if (plan.id == id) {
                    planObj = plan;
                }
            });
            let amt = Number(inputState.amount);
            let cc = 0;
            cc = charges[0].charge - planObj.charge;
            const calculatedCharge = (amt * cc) / 100;
            return (calculatedCharge).toFixed(3);
        } else {
            return 0;
        }

    };

    console.log(plan);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",
            }}
        >
            <form
                // target="_blank"
                method="post"
                action={`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId
                    }&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount
                    }&mobile=${Profileinfo?.mobile
                    }&email=suhassuryavanshi5979@gmail.com&type=T&
                  cust_name=${Profileinfo?.shopname
                    }&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id
                    }`}
            //  onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>

                <div className="row py-0">
                    {selectedPlan && (
                        <Col md={12} className="d-flex flex-column pb-2 text-dark">
                            <strong>{`Total Amount Paid. :  ${twoDecimalPlaces(
                                Number(inputState.amount) + Number(getCharges(selectedPlan))
                            )}`}</strong>
                        </Col>
                    )}
                    {selectedPlan && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                getCharges(selectedPlan)
                            )}`}</strong>
                        </Col>
                    )}
                </div>

                <div className="row">
                    {planOption.map((plan) => (
                        <div className="col- p-0 mx-3">
                            <div style={{ borderWidth: "2px", scale: selectedPlan == plan.id ? "1.05" : "1.0" }} className={`offer ${selectedPlan == plan.id ? "offer-success" : "offer-warning"} offer-radius`} onClick={() => handleCardSelection(plan.id)}>
                                <div className="shape">
                                    <div className="shape-text">
                                        New
                                    </div>
                                </div>
                                <div className="offer-content p-2">
                                    <h3 className="lead">
                                        {plan.title}
                                    </h3>
                                    <p>
                                        Total Charges : {getCharges(plan.id)}
                                        <br />
                                        Saving: {getSavings(plan.id)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Col md={12} className="m-0 p-0">
                    <Button
                        onClick={validate}
                        type="submit"
                        className="btn btn-primary px-4"
                        variant=""
                        style={{ borderRadius: ".4rem", border: "1.5px solid rgba(43, 193, 85, 1)", background: "rgba(43, 193, 85, 0.1) ", color: "rgba(43, 193, 85, 1)" }}

                    >
                        {" "}
                        Proceed Next
                    </Button>
                </Col>
            </form>

            <Modal
                show={modalAskOpen}
                onHide={() => {
                    closeAskModal();
                }}
                aria-labelledby="example-modal-sizes-title-md"
            >
                <Modal.Header className="m-0 p-0 d-flex justify-content-end" style={{ border: "1px", background: "rgba(249, 249, 250, 1)" }}>
                    <Button style={{ background: "white", border: "1px" }}><img
                        alt=""
                        onClick={closeAskModal}
                        src={require("../../../assets/images/cross.png")}
                        style={{ height: "1rem" }}
                    /></Button>
                </Modal.Header>
                <Modal.Body
                    style={{ overflow: "hidden", height: "12rem", background: "rgba(249, 249, 250, 1)" }}
                    className="account"
                >
                    <Col sm={12} className="py-0 px-1">
                        <div className="m- d-flex justify-content-center" >
                            <span className="text-bold">
                                Do you want to continue with {plan.title} or Save Money?
                            </span>
                        </div>
                        <div className="row mt-5 mx-1">
                            <div className="col mr-1 p-0">
                                <Button
                                    style={{
                                        paddingLeft: "3px",
                                        paddingRight: "3px",
                                        padding: "5px",
                                        borderRadius: ".5rem",
                                        border: "1px solid",
                                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                                        color: "rgba(43, 193, 85, 1)",
                                        bordercolor: "rgba(43, 193, 85, 1)",
                                        height: "2.5rem",
                                    }}
                                >{`Continue with ${plan.title}`}</Button>
                            </div>
                            <div className="col ml-1 p-0">
                                <Button
                                    onClick={() => {
                                        openModal();
                                    }}
                                    style={{
                                        paddingLeft: "3px",
                                        paddingRight: "3px",
                                        padding: "5px",
                                        borderRadius: ".5rem",
                                        border: "1px solid",
                                        backgroundColor: "rgba(86, 190, 137, 1)",
                                        color: "white",
                                        bordercolor: "rgba(43, 193, 85, 1)",
                                        height: "2.5rem",
                                    }}
                                >Continue with Save Money</Button>
                            </div>
                        </div>
                    </Col>
                </Modal.Body>
            </Modal>
            <Modal
                show={modalIsOpen}
                onHide={() => {
                    closeModal();
                }}
                aria-labelledby="example-modal-sizes-title-md"
            >
                <Modal.Header className="m-0 p-0 d-flex justify-content-end" style={{ border: "1px", background: "rgba(249, 249, 250, 1)" }}>
                    <Button style={{ background: "white", border: "1px" }}><img
                        alt=""
                        onClick={closeModal}
                        src={require("../../../assets/images/cross.png")}
                        style={{ height: "1rem" }}
                    /></Button>
                </Modal.Header>
                <Modal.Body style={{ overflow: "scroll", height: selectedPlan == planOption.length ? "50vh" : "75vh" }} className="account">
                    <Col sm={12} className="py-0 px-1">
                        <div className="d-flex justify-content-center position-relative">

                            <Lottie
                                loop={true}
                                style={{ pointerEvents: "none", height: "20rem" }}
                                animationData={Hurray2}
                            />
                        </div>
                        <div className="position-absolute ">
                            <h2
                                style={{
                                    color: "rgba(255, 72, 72, 1)",
                                    marginTop: "-12rem",
                                    marginLeft: "11rem",
                                }}
                            >
                                Hurray
                            </h2>
                            <h5 style={{ color: "", marginLeft: "5rem" }}>
                                You Will Save Upto ₹ {getSavings(planOption.length)}
                            </h5>
                        </div>
                        {selectedPlan < planOption.length && (
                            <React.Fragment>
                                <div
                                    className="row mx-1"
                                    style={{ background: "rgba(248, 250, 252, 1)" }}
                                >
                                    <h6
                                        className=" d-flex justify-content-center col p-0 m-0"
                                        style={{ color: "rgba(88, 98, 117, 1)" }}
                                    >
                                        {" "}
                                        Funding Time
                                    </h6>
                                    <h6
                                        className=" d-flex justify-content-center col p-0 m-0"
                                        style={{ color: "rgba(88, 98, 117, 1)" }}
                                    >
                                        Money Saved
                                    </h6>
                                    <h6
                                        className=" d-flex justify-content-center col p-0 m-0"
                                        style={{ color: "rgba(88, 98, 117, 1)" }}
                                    >
                                        {" "}
                                        Action
                                    </h6>
                                </div>
                                {[...planOption].reverse().map((plan) => (
                                    <div
                                        className="row mx-1 mt-2"
                                        style={{
                                            border: "1px solid rgba(0, 0, 0, 0.1)",
                                            borderRadius: ".6rem",
                                        }}
                                    >
                                        <p
                                            className=" d-flex justify-content-center col p-0 m-0"
                                            style={{ color: "rgba(88, 98, 117, 1)", fontSize: ".8rem" }}
                                        >
                                            {" "}
                                            {`${plan.title} [Business day]`}
                                        </p>
                                        <p
                                            className=" d-flex justify-content-center col p-0 m-0"
                                            style={{ color: "rgba(88, 98, 117, 1)", fontSize: ".9rem" }}
                                        >
                                            {getSavings(plan.id)}
                                        </p>
                                        <p className=" d-flex justify-content-center col p-0 m-0">
                                            {" "}
                                            <Button
                                                onClick={() => {
                                                    openGateway(plan.id)
                                                }}
                                                style={{
                                                    paddingLeft: "3px",
                                                    paddingRight: "3px",
                                                    padding: "5px",
                                                    borderRadius: ".5rem",
                                                    border: "1px solid",
                                                    backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                    color: "rgba(43, 193, 85, 1)",
                                                    bordercolor: "rgba(43, 193, 85, 1)",
                                                    width: "8rem",
                                                }}
                                            >
                                                <span style={{ marginRight: "11px" }}>Pay Now</span>
                                                <img
                                                    alt=""
                                                    src={require("../../../assets/images/paynowarrow.png")}
                                                    style={{ height: "1.5rem" }}
                                                />
                                            </Button>
                                        </p>
                                    </div>
                                ))}

                            </React.Fragment>
                        )
                        }

                        {selectedPlan == planOption.length && (
                            <div className="row justify-content-center">
                                <Button
                                    onClick={validate2}
                                    type="submit"
                                    className="btn btn-primary px-4 w-100"
                                    variant=""
                                    style={{ borderRadius: ".4rem", border: "1.5px solid rgba(43, 193, 85, 1)", background: "rgba(43, 193, 85, 0.1) ", color: "rgba(43, 193, 85, 1)" }}

                                >
                                    {" "}
                                    Proceed Next
                                </Button>
                            </div>

                        )}



                    </Col>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const PaymentGateway2Save = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } =
        useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
        plan: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
        plan: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [plan, setPlan] = useState({});
    const [selectedPlan, setSelectedPlan] = useState(1);
    const [savings, setSavings] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {
        setSelectedPlan(e);
    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }


    const handleCardSelection = (cardType) => {
        setSelectedPlan(cardType);
    };
    const openModal = () => {
        setModalIsOpen(true);
    };
    console.log(openModal, "opnpg");
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (selectedPlan == 1) {
                doYouWantToSaveMoremoney();
            } else {
                openModal(true);
            }
        }
        return false;
    };

    const doYouWantToSaveMoremoney = () => {
        swal({
            title: "Do you want to save more money?",
            text: `Select T+1 Plan to save ${getSavings(2)} `,
            icon: "success",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                openModal(true);
            } else {
                validate2();
            }
        });
    }

    const validate2 = (event) => {
        if (event) {
            event.preventDefault();
        }
        openInNewTab(`https://bumppy.in/pg2/request.php?userid=${user.ReferId
            }&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount
            }&mobile=${Profileinfo?.mobile
            }&email=suhassuryavanshi5979@gmail.com&type=T&
             cust_name=${Profileinfo?.shopname
            }&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan
            }`);
    };

    // useEffect(() => {
    //     const amt = Number(inputState.amount);
    //     let cc = 0;
    //     charges.forEach((channel) => {
    //         if (channel.mode == 'CC') {
    //             cc = channel.charge;
    //         }
    //     })
    //     console.log(cc);
    //     const calculatedCharge = (amt * cc) / 100;

    //     setCharges2(calculatedCharge);
    // }, [inputState.amount]);

    //For Selected Plan
    useEffect(() => {
        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == "CC") {
                    cc = channel.charge;
                }
            });
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        } else {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            });

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }
    }, [selectedPlan, inputState.amount]);

    useEffect(() => {
        let selectedPlanObj = {};
        planOption.forEach((plan) => {

            if (plan.id == selectedPlan) {
                const amt = Number(inputState.amount);
                selectedPlanObj = plan;
                setPlan(plan);
                let savings1 = amt * Number(plan.charge) / 100;
                setSavings(savings1)
            }
        });

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == "CC") {
                    cc = channel.charge;
                }
            });
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        } else {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id == selectedPlan) {
                    cc = channel.charge;
                }
            });
            const calculatedCharge = (amt * cc) / 100;

            if (selectedPlanObj) {
                let savings1 = amt * Number(selectedPlanObj.charge) / 100;
                setSavings(savings1)
            }
            setCharges2(calculatedCharge);
        }
    }, [selectedPlan, modalIsOpen])


    const getPaymentGatewayCharges = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data);
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,
                        };
                    })
                );
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const openInNewTab = (data) => {
        const newWindow = window.open(data, "", "height=800,width=1000'");
        if (newWindow) newWindow.opener = null;
    };

    console.log(selectedPlan);

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    const getSavings = (id) => {
        let amt = Number(inputState.amount);
        let planObj = {};
        planOption.forEach((plan) => {
            if (plan.id == id) {
                planObj = plan;
            }
        });
        let savings1 = (amt * Number(planObj.charge)).toPrecision(5) / 100;
        console.log(savings1, "savings");
        return (savings1);
    };
    const getCharges = (id) => {
        let amt = Number(inputState.amount);
        let cc = 0;
        charges.forEach((channel) => {
            if (channel.fund_settlement_id == id) {
                cc = channel.charge;
            }
        });
        const calculatedCharge = (amt * cc).toPrecision(5) / 100;

        console.log(calculatedCharge, "charges");
        return (calculatedCharge);
    };

    console.log(plan);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",
            }}
        >
            <form
                // target="_blank"
                method="post"
                action={`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId
                    }&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount
                    }&mobile=${Profileinfo?.mobile
                    }&email=suhassuryavanshi5979@gmail.com&type=T&
                  cust_name=${Profileinfo?.shopname
                    }&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id
                    }`}
            //  onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>

                <div className="row">
                    {selectedPlan && (
                        <Col md={12} className="d-flex flex-column pb-2 text-dark">
                            <strong>{`Total Amount Paid. :  ${twoDecimalPlaces(
                                twoDecimalPlaces(Number(inputState.amount) + getCharges(selectedPlan))
                            )}`}</strong>
                        </Col>
                    )}
                    {selectedPlan && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                getCharges(selectedPlan)
                            )}`}</strong>
                        </Col>
                    )}
                </div>

                <div className="row">
                    <div className="col-3 p-1 m-3">
                        <div style={{ borderWidth: "2px", scale: selectedPlan == 1 ? "1.1" : "1.0" }} className={`offer ${selectedPlan == 1 ? "offer-success" : "offer-warning"} offer-radius`} onClick={() => handleCardSelection(1)}>
                            <div className="shape">
                                <div className="shape-text">
                                    New
                                </div>
                            </div>
                            <div className="offer-content p-2">
                                <h3 className="lead">
                                    Instant
                                </h3>
                                <p>
                                    Total Charges : {getCharges(1)}
                                    <br />
                                    Saving: {getSavings(1)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 p-1 m-3">
                        <div style={{ borderWidth: "2px", scale: selectedPlan == 2 ? "1.1" : "1.0" }} className={`offer ${selectedPlan == 2 ? "offer-success" : "offer-warning"} offer-radius`} onClick={() => handleCardSelection(2)}>
                            <div className="shape">
                                <div className="shape-text">
                                    New
                                </div>
                            </div>
                            <div className="offer-content p-2">
                                <h3 className="lead">
                                    T+1
                                </h3>
                                <p>
                                    Total Charges : {getCharges(2)}
                                    <br />
                                    Saving: {getSavings(2)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 m-0 p-1">
                        <div
                            className={`col m-0 p-1`}
                            onClick={() => handleCardSelection(1)}
                            style={{
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                borderRadius: "5px",
                                border: "2px solid",
                                backgroundColor: selectedPlan === 1 ? "rgba(43, 193, 85, 0.1)" : "rgba(254, 128, 36, 0.1)",
                                color: selectedPlan === 1 ? "rgba(43, 193, 85, 1)" : "rgba(254, 128, 36, 1)",
                                borderColor: selectedPlan === 1 ? "rgba(43, 193, 85, 1)" : "rgba(254, 128, 36, 1)",
                            }}
                        >
                            <div className="row m-0 p-0">
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    Settlement Period:
                                </p>
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    Instant
                                </p>
                            </div>
                            <div className="row m-0 p-0">
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    Total Charges :
                                </p>
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    {getCharges(1)}
                                </p>
                            </div>
                            <div className="row m-0 p-0">
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    Saving:
                                </p>
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    ₹ {getSavings(1)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 m-0 p-1">
                        <div
                            className={`col m-0 p-1`}
                            onClick={() => handleCardSelection(2)}
                            style={{
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                borderRadius: "5px",
                                border: "2px solid",
                                backgroundColor: selectedPlan === 2 ? "rgba(43, 193, 85, 0.1)" : "rgba(254, 128, 36, 0.1)",
                                color: selectedPlan === 2 ? "rgba(43, 193, 85, 1)" : "rgba(254, 128, 36, 1)",
                                borderColor: selectedPlan === 2 ? "rgba(43, 193, 85, 1)" : "rgba(254, 128, 36, 1)",
                            }}
                        >
                            <div className="row m-0 p-0">
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    Settlement Period:
                                </p>
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    T+1 Working Days
                                </p>
                            </div>
                            <div className="row m-0 p-0">
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    Total Charges :
                                </p>
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    {getCharges(2)}
                                </p>
                            </div>
                            <div className="row m-0 p-0">
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    Saving:
                                </p>
                                <p
                                    className="col m-0 p-0"
                                    style={{ color: "#e67e22", fontSize: ".9rem" }}
                                >
                                    ₹ {getSavings(2)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Col md={12} className="m-0 p-0">
                    <Button
                        onClick={validate}
                        type="submit"
                        className="btn btn-primary px-4"
                        variant=""
                        style={{ borderRadius: ".4rem", border: "1.5px solid rgba(43, 193, 85, 1)", background: "rgba(43, 193, 85, 0.1) ", color: "rgba(43, 193, 85, 1)" }}

                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>

            <Modal
                show={modalIsOpen}
                onHide={() => {
                    closeModal()
                }}
                aria-labelledby="example-modal-sizes-title-md"
            >

                <Modal.Body
                    style={{ width: "100%", height: "30rem", overflow: "hidden" }}
                    className="account"
                >
                    <div className="row justify-content-end m-0 p-0">
                        <i style={{ cursor: 'pointer', height: '2rem', width: '2rem', fontSize: '2rem' }} onClick={closeModal} className="mdi mdi-close" />
                    </div>
                    <Col sm={12} className="py-0 px-1">
                        <div className="d-flex justify-content-center">
                            <img
                                alt=""
                                src={require("../../../assets/images/pg_gif.gif")}
                                style={{ height: "17rem" }}
                            />
                        </div>
                        <div className="row justify-content-center">
                            <h2 className="text-center">Hurray You Saved {getSavings(2)}</h2>
                        </div>
                        <Button
                            onClick={validate2}
                            className="btn btn-primary w-100"
                            variant=""
                            style={{ borderRadius: ".4rem", border: "1.5px solid rgba(43, 193, 85, 1)", background: "rgba(43, 193, 85, 0.1) ", color: "rgba(43, 193, 85, 1)" }}

                        >
                            {" "}
                            Pay Now
                        </Button>
                    </Col>
                </Modal.Body>
            </Modal>
        </div>
    );
};



// PAYTM PG
const PaymentGateway3 = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/paytmpg/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}

                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};


// PAYTM PG Router

const PaymentGateway5 = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/payment_gateway/paytm/router/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}

                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};

// PAYOMATRIX PG
const PaymentGateway4 = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/payment_gateway/payomatix/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}

                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};

export default Topup;
