import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { offsetArr } from "../../utils/CommonConfig";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import swal from "sweetalert";
import { generateHashKey2 } from "../../utils/utilFuctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
const contentStyles = {
  width: "100%",
};
const tdstyle = {
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};
const thstyle = {
  color: "dark",
  backgroundColor: "white",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};
const VendorPaymentSystem = () => {
  const [data, setData] = useState([{}]);
  const [mdShow, setMdShow] = useState(false);

  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [pages, setPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [balance, setBalance] = useState({});
  // const [merchantName, setmerchantName] = useState("");
  // const [merchantMobile, setmerchantMobile] = useState("");
  // const [merchantNameError, setmerchantNameError] = useState("");
  // const [merchantMobileError, setmerchantMobileError] = useState("");
  const [toggle, setToggle] = useState(false);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [merchantName, setmerchantName] = useState("");
  const [merchantNameError, setmerchantNameError] = useState("");

  const [merchantMobile, setmerchantMobile] = useState("");
  const [merchantMobileError, setmerchantMobileError] = useState("");

  const [otp, setOtp] = useState(""); // Stores entered OTP
  const [otpSent, setOtpSent] = useState(false); // Controls OTP input field visibility

  const [resendOtpEnable, setResendOtpEnable] = useState(false); // Controls resend OTP logic
  const [seconds, setSeconds] = useState(60); // Countdown timer for OTP resend

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const validation_addMerchant = () => {
    if (!merchantName && !merchantName) {
      setmerchantNameError("Please enter name ");
      setmerchantMobileError("Please enter mobile number ");
      return;
    }
    if (!merchantName) {
      setmerchantNameError("Please enter name ");
      return;
    }
    if (!merchantMobile) {
      setmerchantMobileError("Please enter mobile number ");
      return;
    }
    AddMerchant();
  };

  const clear_addMerchantForm = () => {
    setmerchantNameError("");
    setmerchantMobileError("");
    setmerchantName("");
    setmerchantMobile("");
  };

  //Get Merchant list for Table
  const getMerchantList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();

      const hash_key = await generateHashKey2(user.ReferId);
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/merchant/getMerchantList`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  // Add New Merchant (New Vendor group)
  const AddMerchant = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      const hash_key = await generateHashKey2(user.ReferId);
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: merchantMobile,
        merchantName: merchantName,
        device: "NA",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/merchant/addMerchant`,
        requestOptions
      );
      const result = await res.json();
      if (result.status == "0") {
        swal("Success", `Vendor Group Added Successfully`, "success");
        getMerchantList();
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", `Something went wrong`, "error");
    }
  };

  //Get Wallet Balance of user
  const GetBalance = async () => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/wallet/getWalletBalance`,
        requestOptions
      );
      const result = await res.json();
      if (result.status == "0") {
        setBalance(result.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", `Something went wrong`, "error");
    }
  };

  useEffect(() => {
    clear_addMerchantForm();
    getMerchantList();
    GetBalance();
  }, []);

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%",
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const loginWithOtp = async (callback) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobileNumber: merchantMobile,
        vendorName: merchantName,
        device: "WEB",
        latitude: "13.85685",
        longitude: "72.09756",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Vendor/v1.0/addVendor`,
        requestOptions
      );
      const data = await res.json();

      if (data.responseCode === "00") {
        setOtpSent(true);
        setResendOtpEnable(false);
        setSeconds(60);
        swal("Success", data.responseMessage, "success");
      } else {
        swal("Failed ", data.responseMessage, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);

      swal(
        "Error",
        "Network error. Please check your connection and try again!",
        "error"
      );
    }
  };

  const addVendorResendOtp = async (callback) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobileNumber: merchantMobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Vendor/v1.0/addVendorResendOtp`,
        requestOptions
      );
      const data = await res.json();

      if (data.responseMessage === "00") {
        setOtpSent(true);
        setResendOtpEnable(false);
        setSeconds(60);
        swal("Success", data.responseMessage, "success");
      } else {
        swal("Failed", data.responseMessage, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);


      swal(
        "Error",
        "Network error. Please check your connection and try again!",
        "error"
      );
    }
  };

  const addVendorVerify = async () => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobileNumber: merchantMobile,
        otp: otp,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Vendor/v1.0/addVendorVerify`,
        requestOptions
      );
      const result = await res.json();
      if (result.responseCode == "00") {
        swal("Success", `Vendor Group Added Successfully`, "success");
        getMerchantList();
        setMdShow(false);
      }else{
        swal("Failed", data.responseMessage, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", `Something went wrong`, "error");
      setMdShow(false);
    }
  };
  return (
    <div className="bg-light p-2" style={{ fontFamily: "poppins" }}>
      <div style={contentStyles} className="scrollable-content">
        <div className="">
          <div
            className="p-4 m-3 bg-white "
            style={{
              borderRadius: "10px",
            }}
          >
            <div className="text-dark py-0 px-2 m-0 ">
              <div className="d-flex justify-content-between">
                <div
                  className="text-dark py-3"
                  style={{ fontSize: "22px", fontWeight: "bold" }}
                >
                  Get started
                </div>
                <div>
                  {" "}
                  <h4
                    className="text-center py-3"
                    style={{ fontWeight: "bold" }}
                  >
                    Wallet Balance : ₹ {twoDecimalPlaces(balance.wallet_bal)}
                  </h4>
                </div>
              </div>
              <div className="px-3">
                <div style={{ fontSize: "16px" }}>
                  {" "}
                  ⦿ What are Vendor Payment
                </div>
                <div
                  className="py-2 px-4"
                  style={{ fontSize: "14px", width: "50%" }}
                >
                  Auto-pull details from invoices. Schedule and pay invoices.
                  Automate and simplify TDS payments.
                </div>
                <div className="mx-4 my-2">
                  <Button
                    className="btn-sm p-2 "
                    style={{
                      borderRadius: "px",
                      borderColor: "rgba(86, 190, 137, 1)",
                      backgroundColor: " rgba(86, 190, 137, 1)",
                      color: "rgba(255, 255, 255, 1)",
                    }}
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                  >
                    Let's get Started {">"}
                  </Button>
                </div>
                {toggle && (
                  <>
                    <div
                      style={{ fontSize: "18px" }}
                      className="text-success py-2 px-4"
                    >
                      Congratulations! Vendor payment has been successfully
                      enabled.{" "}
                    </div>
                  </>
                )}
                <div className="py-2" style={{ fontSize: "16px" }}>
                  {" "}
                  ⦿ Add Invoices in multiple ways
                </div>
                <div className="py-2" style={{ fontSize: "16px" }}>
                  {" "}
                  ⦿ Set-up TDS Payment
                </div>
              </div>
            </div>
          </div>

          <div
            className=" p-4 m-3"
            style={{
              background: "#ffffff",
              borderRadius: "10px",
            }}
          >
            <div className="row justify-content-between align-items-center py-0 px-2 m-0">
              <div
                className="col-7 text-start"
                style={{ fontWeight: "bold", fontSize: "26px" }}
              >
                Vendor Group List
              </div>

              <div style={searchBarStyle} className="col-3 p-0 bg-light">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={iconStyle}
                  className="ml-2"
                />
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
              </div>

              <div className="text-center col-2">
                <Button
                  onClick={() => {
                    setMdShow(true);
                  }}
                  className="btn-sm float-right m-3 p-2"
                  style={{
                    borderRadius: "px",
                    borderColor: "rgba(86, 190, 137, 1)",
                    backgroundColor: " rgba(86, 190, 137, 1)",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  Add VendorGroup
                </Button>
              </div>
              <Modal
                show={mdShow}
                onHide={() => {
                  setMdShow(false);
                  clear_addMerchantForm();
                }}
                aria-labelledby="example-modal-sizes-title-sm"
                size="md"
              >
                <Modal.Header closeButton>
                  <Modal.Title> Create Vendor Group</Modal.Title>
                </Modal.Header>

                {/* <Modal.Body
 style={{
 height: "250px",
 display: "block",
 overflowY: "auto",
 }}
 className="account"
 >
 <div className="px-4">
 <Form.Group className="m-0 p-0">
 <Form.Label>
 <span
 style={{ color: "black" }}
 >{` Vendor Group Name`}</span>
 </Form.Label>
 <Form.Control
 type="text"
 name="name"
 placeholder="Enter Vendor Group Name"
 style={{ borderRadius: "4px" }}
 onChange={(e) => {
 setmerchantName(e.target.value);
 setmerchantNameError("");
 }}
 value={merchantName}
 />
 <p className="m-0 p-0" style={{ color: "red" }}>
 {merchantNameError}
 </p>
 </Form.Group>

 <Form.Group className="m-0 p-0 mt-2">
 <Form.Label>
 <span style={{ color: "black" }}>
 {" "}
 {` Enter Mobile No`}
 </span>
 </Form.Label>

 <Form.Control
 type="number"
 name="mobile"
 placeholder="Enter Mobile No"
 style={{ borderRadius: "4px" }}
 onChange={(e) => {
 setmerchantMobile(e.target.value);
 setmerchantMobileError("");
 }}
 value={merchantMobile}
 />
 <p className="m-0 p-0" style={{ color: "red" }}>
 {merchantMobileError}
 </p>
 </Form.Group>
 <div className="d-flex justify-content-center mt-2">
 <Button
 className="btn-lg px-5 "
 style={{
 border: "0px solid #56BE89",
 backgroundColor: " #56BE89",
 color: "#ffffff",
 }}
 onClick={validation_addMerchant}
 >
 Add Vendor Group
 </Button>
 </div>
 </div>
 </Modal.Body> */}

                <Modal.Body
                  style={{
                    height: "250px",
                    display: "block",
                    overflowY: "auto",
                  }}
                  className="account"
                >
                  <div className="px-4">
                    <Form.Group className="m-0 p-0">
                      <Form.Label>
                        <span style={{ color: "black" }}>
                          Vendor Group Name
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Vendor Group Name"
                        style={{ borderRadius: "4px" }}
                        onChange={(e) => {
                          setmerchantName(e.target.value);
                          setmerchantNameError("");
                        }}
                        value={merchantName}
                      />
                      <p className="m-0 p-0" style={{ color: "red" }}>
                        {merchantNameError}
                      </p>
                    </Form.Group>

                    <Form.Group className="m-0 p-0 mt-2">
                      <Form.Label>
                        <span style={{ color: "black" }}>Enter Mobile No</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="mobile"
                        placeholder="Enter Mobile No"
                        style={{ borderRadius: "4px" }}
                        onChange={(e) => {
                          setmerchantMobile(e.target.value);
                          setmerchantMobileError("");
                        }}
                        value={merchantMobile}
                      />
                      <p className="m-0 p-0" style={{ color: "red" }}>
                        {merchantMobileError}
                      </p>
                    </Form.Group>

                    {!otpSent && (
                      <div className="d-flex justify-content-center mt-2">
                        <Button
                          className="btn-lg px-5"
                          style={{
                            border: "0px solid #56BE89",
                            backgroundColor: "#56BE89",
                            color: "#ffffff",
                          }}
                          onClick={loginWithOtp}
                        >
                          Send OTP
                        </Button>
                      </div>
                    )}

                    {otpSent && (
                      <>
                        <Form.Group className="m-0 p-0 mt-2">
                          <Form.Label>
                            <span style={{ color: "black" }}>Enter OTP</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="otp"
                            placeholder="Enter OTP"
                            style={{ borderRadius: "4px" }}
                            onChange={(e) => setOtp(e.target.value)}
                            value={otp}
                          />
                        </Form.Group>

                        <div className="d-flex justify-content-center mt-3">
                          <Button
                            className="btn-lg px-5"
                            style={{
                              border: "0px solid #56BE89",
                              backgroundColor: "#56BE89",
                              color: "#ffffff",
                            }}
                            onClick={addVendorVerify}
                          >
                            Add Vendor
                          </Button>
                          <Button
                            className="btn-lg px-5 ml-1"
                            style={{
                              border: "0px solid #56BE89",
                              backgroundColor: "#FFCF50",
                              color: "#ffffff",
                            }}
                            onClick={addVendorResendOtp}
                          >
                            Resend OTP
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </Modal.Body>
              </Modal>

              <div className="scroll-inner admin-fix-width pt-4">
                <table
                  style={{ width: "100%" }}
                  className="table table-borderless"
                >
                  <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Sr"}
                    </th>

                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Vendor Group Name"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Mobile Number"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Status"}
                    </th>
                  </tr>
                  {dataToDisplay.map((e, ind) => (
                    <tr className=" transparent-table border-bottom ">
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        {ind + 1}
                      </td>
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <span>{`${e.merchant_name}`}</span>
                        </div>
                      </td>
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <span>{e.merchant_mobile}</span>
                        </div>
                      </td>

                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <Link
                            to={`/retailer/vendor-accounts?mobile=${e.merchant_mobile}&name=${e.merchant_name}`}
                          >
                            <Button
                              style={{
                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                borderColor: "rgba(43, 193, 85, 1)",
                                color: "rgba(43, 193, 85, 1)",
                              }}
                            >
                              Proceed {">"}
                            </Button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>

              <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                  setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                  setOffset(offsetValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export default VendorPaymentSystem;
