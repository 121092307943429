export const statement = [
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 03:32:47",
        "referenceTransactionNo": "YES0N4309505940400                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38163807001DC",
        "transactionAmount": 9999.06,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38163807001DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 609334.87
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 03:32:47",
        "referenceTransactionNo": "YES0N4309505938500                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38163806051DC",
        "transactionAmount": 4198.11,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38163806051DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 613532.98
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 03:32:47",
        "referenceTransactionNo": "YES0N4309505938200                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38163808371DC",
        "transactionAmount": 3800,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38163808371DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 617332.98
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 03:32:47",
        "referenceTransactionNo": "YES0N4309505939300                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38163810731DC",
        "transactionAmount": 1400,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38163810731DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 618732.98
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 03:32:47",
        "referenceTransactionNo": "YES0N4309505939900                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38163815741DC",
        "transactionAmount": 5499.06,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38163815741DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 624232.04
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 05:32:41",
        "referenceTransactionNo": "YES0N4309507146000                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38163884861DC",
        "transactionAmount": 10000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38163884861DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 634232.04
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 08:38:24",
        "referenceTransactionNo": "YBP4309104774539                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430908959547/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430908959547                       ",
        "txnLiteral": "IPM",
        "runningBalance": 1134173.04
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 08:38:24",
        "referenceTransactionNo": "YBP4309104774540                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430908959554/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430908959554                       ",
        "txnLiteral": "IPM",
        "runningBalance": 1634114.04
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 08:38:25",
        "referenceTransactionNo": "YBP4309104774434                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430908959527/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430908959527                       ",
        "txnLiteral": "IPM",
        "runningBalance": 2134055.04
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 08:38:25",
        "referenceTransactionNo": "YBP4309104774435                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430908959551/AXIS BANK",
        "transactionAmount": 245280.04,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430908959551                       ",
        "txnLiteral": "IPM",
        "runningBalance": 2379335.08
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 08:38:26",
        "referenceTransactionNo": "YBP4309104774586                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430908959594/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430908959594                       ",
        "txnLiteral": "IPM",
        "runningBalance": 2879276.08
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 08:38:29",
        "referenceTransactionNo": "YBP4309104774481                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430908959548/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430908959548                       ",
        "txnLiteral": "IPM",
        "runningBalance": 3379217.08
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 08:38:29",
        "referenceTransactionNo": "YBP4309104774480                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430908959530/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430908959530                       ",
        "txnLiteral": "IPM",
        "runningBalance": 3879158.08
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 10:01:50",
        "referenceTransactionNo": "YES0N4309510227700                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38164514551DC",
        "transactionAmount": 20192.45,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38164514551DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 3899350.53
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 10:31:34",
        "referenceTransactionNo": "YES0N4309510570700                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38164668101DC",
        "transactionAmount": 27497.17,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38164668101DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 3926847.7
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 10:32:49",
        "referenceTransactionNo": "YES0N4309511079800                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38164923611DC",
        "transactionAmount": 16591.5,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38164923611DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 3943439.2
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 11:31:44",
        "referenceTransactionNo": "YES0N4309511955900                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38165284031DC",
        "transactionAmount": 29194.34,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38165284031DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 3972633.54
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 11:31:48",
        "referenceTransactionNo": "YES0N4309511984900                      ",
        "narrative": "NEFT CR-ICIC0000393-TAPITS TECHNOLOGIES PRIVATE LIMITED-BUMPPY MEDIA PRIVATE LIMITED-CMS4622189317",
        "transactionAmount": 17038.23,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "CMS4622189317                           ",
        "txnLiteral": "IPM",
        "runningBalance": 3989671.77
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 11:31:48",
        "referenceTransactionNo": "YES0N4309511985300                      ",
        "narrative": "NEFT CR-ICIC0000393-TAPITS TECHNOLOGIES PRIVATE LIMITED-BUMPPY MEDIA PRIVATE LIMITED-CMS4622795772",
        "transactionAmount": 8958.35,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "CMS4622795772                           ",
        "txnLiteral": "IPM",
        "runningBalance": 3998630.12
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 11:39:35",
        "referenceTransactionNo": "YBP4309104928535                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430911708780/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430911708780                       ",
        "txnLiteral": "IPM",
        "runningBalance": 4498571.12
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 11:39:36",
        "referenceTransactionNo": "YBP4309104928393                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430911709742/AXIS BANK",
        "transactionAmount": 481459.18,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430911709742                       ",
        "txnLiteral": "IPM",
        "runningBalance": 4980030.3
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 12:01:55",
        "referenceTransactionNo": "YES0N4309512569600                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38165776901DC",
        "transactionAmount": 18644.34,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38165776901DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 4998674.64
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 12:25:39",
        "referenceTransactionNo": "1387420241104000300918640               ",
        "narrative": "IMPS/NA/XXXX4893/RRN:430912735947/00000000006846107629/HDFC BANK/HDFC/REMETOBENEINFO",
        "transactionAmount": 100000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "430912735947                            ",
        "txnLiteral": "MSD",
        "runningBalance": 4898674.64
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 12:31:34",
        "referenceTransactionNo": "YES0N4309512931100                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38166090861DC",
        "transactionAmount": 21993.39,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38166090861DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 4920668.03
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 12:32:54",
        "referenceTransactionNo": "YES0N4309513407300                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38166470601DC",
        "transactionAmount": 36842.45,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38166470601DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 4957510.48
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 13:12:28",
        "referenceTransactionNo": "YBP4309104996469                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430913195790/AXIS BANK",
        "transactionAmount": 478756.49,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430913195790                       ",
        "txnLiteral": "IPM",
        "runningBalance": 5436266.97
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 13:31:45",
        "referenceTransactionNo": "YES0N4309514095200                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38166939161DC",
        "transactionAmount": 90146.22,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38166939161DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 5526413.19
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 14:02:09",
        "referenceTransactionNo": "YES0N4309514919600                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38167526821DC",
        "transactionAmount": 51844.34,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38167526821DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 5578257.53
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 14:24:51",
        "referenceTransactionNo": "YBP4309105041285                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430914563920/AXIS BANK",
        "transactionAmount": 299404.65,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430914563920                       ",
        "txnLiteral": "IPM",
        "runningBalance": 5877662.18
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 14:31:39",
        "referenceTransactionNo": "YES0N4309515267400                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38167853251DC",
        "transactionAmount": 51094.34,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38167853251DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 5928756.52
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 14:33:08",
        "referenceTransactionNo": "YES0N4309515830900                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38168289071DC",
        "transactionAmount": 25248.11,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38168289071DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 5954004.63
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 14:47:40",
        "referenceTransactionNo": "YBP4309105054891                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430914673645/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430914673645                       ",
        "txnLiteral": "IPM",
        "runningBalance": 6453945.63
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 14:47:41",
        "referenceTransactionNo": "YBP4309105054892                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430914673656/AXIS BANK",
        "transactionAmount": 102525.9,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430914673656                       ",
        "txnLiteral": "IPM",
        "runningBalance": 6556471.53
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 15:14:38",
        "referenceTransactionNo": "2414920241104000200876852               ",
        "narrative": "FUNDS TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110415CBS006847 DWMD33944197",
        "transactionAmount": 75000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "DWMD33944197                            ",
        "txnLiteral": "MSD",
        "runningBalance": 6481471.53
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 15:15:56",
        "referenceTransactionNo": "YBP4309105072792                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430915806466/AXIS BANK",
        "transactionAmount": 124265.33,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430915806466                       ",
        "txnLiteral": "IPM",
        "runningBalance": 6605736.86
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 15:15:56",
        "referenceTransactionNo": "YBP4309105072793                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430915806464/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430915806464                       ",
        "txnLiteral": "IPM",
        "runningBalance": 7105677.86
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 15:15:58",
        "referenceTransactionNo": "YBP4309105072866                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430915806542/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430915806542                       ",
        "txnLiteral": "IPM",
        "runningBalance": 7605618.86
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 15:22:11",
        "referenceTransactionNo": "YESEO4309204541100                      ",
        "narrative": "RTGS-YESBR12024110400007392-SAPD0017796846501158-SUMIT ARORA UNSECURE-UNSEC LOAN PAID",
        "transactionAmount": 500000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "YESBR12024110400007392                  ",
        "txnLiteral": "OPI",
        "runningBalance": 7105618.86
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 15:31:46",
        "referenceTransactionNo": "YES0N4309517506100                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38168814421DC",
        "transactionAmount": 18844.34,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38168814421DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 7124463.2
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:02:02",
        "referenceTransactionNo": "YES0N4309518462200                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38169414331DC",
        "transactionAmount": 15596.22,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38169414331DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 7140059.42
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:02:14",
        "referenceTransactionNo": "YBP4309105101704                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430916028513/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430916028513                       ",
        "txnLiteral": "IPM",
        "runningBalance": 7640000.42
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:02:15",
        "referenceTransactionNo": "YBP4309105101647                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430916027513/AXIS BANK",
        "transactionAmount": 176205.19,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430916027513                       ",
        "txnLiteral": "IPM",
        "runningBalance": 7816205.61
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:02:15",
        "referenceTransactionNo": "YBP4309105101596                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430916028539/AXIS BANK",
        "transactionAmount": 499941,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430916028539                       ",
        "txnLiteral": "IPM",
        "runningBalance": 8316146.61
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 16:02:24",
        "referenceTransactionNo": "2414920241104000200927850               ",
        "narrative": "FUNDS TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110416CBS007448 DWMD33944805",
        "transactionAmount": 75000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "DWMD33944805                            ",
        "txnLiteral": "MSD",
        "runningBalance": 8241146.61
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 16:04:43",
        "referenceTransactionNo": "2414920241104000200933303               ",
        "narrative": "FUNDS TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110416CBS007484 DWMD33944829",
        "transactionAmount": 75000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "DWMD33944829                            ",
        "txnLiteral": "MSD",
        "runningBalance": 8166146.61
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 16:06:41",
        "referenceTransactionNo": "2414920241104000200936205               ",
        "narrative": "FUNDS TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110416CBS007509 DWMD33944863",
        "transactionAmount": 75000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "DWMD33944863                            ",
        "txnLiteral": "MSD",
        "runningBalance": 8091146.61
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:28:57",
        "referenceTransactionNo": "YBP4309105111434                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430916158522/AXIS BANK",
        "transactionAmount": 265426.67,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430916158522                       ",
        "txnLiteral": "IPM",
        "runningBalance": 8356573.28
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:32:19",
        "referenceTransactionNo": "YES0N4309519099000                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38169771451DC",
        "transactionAmount": 21450,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38169771451DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 8378023.28
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:35:07",
        "referenceTransactionNo": "YES0N4309520149600                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38170266821DC",
        "transactionAmount": 28149.06,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38170266821DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 8406172.34
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 16:44:54",
        "referenceTransactionNo": "YBP4309105117341                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430916240265/AXIS BANK",
        "transactionAmount": 230043.85,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430916240265                       ",
        "txnLiteral": "IPM",
        "runningBalance": 8636216.19
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 17:31:42",
        "referenceTransactionNo": "YES0N4309521213600                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38170837931DC",
        "transactionAmount": 24198.11,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38170837931DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 8660414.3
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 17:43:51",
        "referenceTransactionNo": "YBP4309105140436                        ",
        "narrative": "IMPS/RAZORPAYSOFTWAREPR/XXX6002/RRN:430917569589/AXIS BANK",
        "transactionAmount": 417715.7,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "IMPSI430917569589                       ",
        "txnLiteral": "IPM",
        "runningBalance": 9078130.0
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 18:05:20",
        "referenceTransactionNo": "YES0N4309523065400                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38171824861DC",
        "transactionAmount": 23297.17,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38171824861DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9101427.17
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 18:06:03",
        "referenceTransactionNo": "YES0N4309523332400                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38172062971DC",
        "transactionAmount": 19149.06,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38172062971DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9120576.23
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 18:34:31",
        "referenceTransactionNo": "YES0N4309524240500                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38172590261DC",
        "transactionAmount": 3800,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38172590261DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9124376.23
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 19:31:44",
        "referenceTransactionNo": "YES0N4309525053700                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38173221181DC",
        "transactionAmount": 7000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38173221181DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9131376.23
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 20:01:52",
        "referenceTransactionNo": "YES0N4309525619100                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38173732971DC",
        "transactionAmount": 5999.06,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38173732971DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9137375.29
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 20:09:44",
        "referenceTransactionNo": "2414920241104000201215751               ",
        "narrative": "FUNDS TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110420CBS011291 DWMD33948657",
        "transactionAmount": 75000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "DWMD33948657                            ",
        "txnLiteral": "MSD",
        "runningBalance": 9062375.29
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 20:31:34",
        "referenceTransactionNo": "YES0N4309525883300                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38173972251DC",
        "transactionAmount": 7245.28,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38173972251DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9069620.57
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 20:32:19",
        "referenceTransactionNo": "YES0N4309526199500                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38174207681DC",
        "transactionAmount": 18999.06,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38174207681DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9088619.63
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 21:31:52",
        "referenceTransactionNo": "YES0N4309526568500                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38174455521DC",
        "transactionAmount": 8049.06,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38174455521DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9096668.69
    },
    {
        "transactionStatus": null,
        "drcrFlag": "C",
        "transactionDate": "2024-11-04 22:01:47",
        "referenceTransactionNo": "YES0N4309526848600                      ",
        "narrative": "NEFT CR-ICIC0SF0002-TAPITS TECHNOLOGIES-BUMPPYMEDIAPRIVATELIMITED-38174719191DC",
        "transactionAmount": 1500,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "38174719191DC                           ",
        "txnLiteral": "IPM",
        "runningBalance": 9098168.69
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 22:20:18",
        "referenceTransactionNo": "2414920241104000201338893               ",
        "narrative": "FUNDS TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110422CBS012856 DWMD33950211",
        "transactionAmount": 75000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "DWMD33950211                            ",
        "txnLiteral": "MSD",
        "runningBalance": 9023168.69
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 22:48:45",
        "referenceTransactionNo": "2414920241104000201364155               ",
        "narrative": "821066/YES BANK/BUMPPY MEDIA PR/DOMESTIC FUNDS TRANSFER",
        "transactionAmount": 2000000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "EXCHUSER00000000000000000000006847648130",
        "txnLiteral": "FTD",
        "runningBalance": 7023168.69
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 22:48:59",
        "referenceTransactionNo": "2414920241104000201364343               ",
        "narrative": "821067/YES BANK/BUMPPY MEDIA PR/DOMESTIC FUNDS TRANSFER",
        "transactionAmount": 1999999,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "EXCHUSER00000000000000000000006847648504",
        "txnLiteral": "FTD",
        "runningBalance": 5023169.69
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 22:49:09",
        "referenceTransactionNo": "2414920241104000201364432               ",
        "narrative": "821068/YES BANK/BUMPPY MEDIA PR/DOMESTIC FUNDS TRANSFER",
        "transactionAmount": 1999998,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "EXCHUSER00000000000000000000006847648801",
        "txnLiteral": "FTD",
        "runningBalance": 3023171.69
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 23:40:25",
        "referenceTransactionNo": "2414920241104000201429992               ",
        "narrative": "FUNDS TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110423CBS013269 DWMD33950635",
        "transactionAmount": 75000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "DWMD33950635                            ",
        "txnLiteral": "MSD",
        "runningBalance": 2948171.69
    },
    {
        "transactionStatus": null,
        "drcrFlag": "D",
        "transactionDate": "2024-11-04 23:47:25",
        "referenceTransactionNo": "241492024110400020244321               ",
        "narrative": "CASH WITHDRAWAL TRF TO XX2645/YES PAY TRF TO 9540379702/Y24110423CBS013269 CWD33950635",
        "transactionAmount": 1500000,
        "transactionCode": null,
        "transactionBranch": "777",
        "depositNo": "0",
        "fcrPostingDate": "20241104",
        "txnCCY": "INR",
        "chequeNo": "0",
        "userReferenceNumber": "CWD33950635                            ",
        "txnLiteral": "MSD",
        "runningBalance": 1448171.69
    }
];