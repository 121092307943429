import React, { useEffect, useState, Component } from "react";
import { Tabs, Tab, Col, Row, Nav, Form, Button } from "react-bootstrap";
import SettlementsTable from "./SettlementsTable";
import Select from "react-select";

const tranxData = [
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
  {
    amount: 1000,
    ref: "Pay_LdshsLRYUDjfgSVjS",
    time: "19 Hours Ago",
    status: "Refunded",
  },
];

const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";
export class Settlements extends Component {
  render() {
    return (
      <div className="mx-2 my-4">
        <Tabs defaultActiveKey="Overview" id="uncontrolled-tab-example">
          <Tab eventKey="Overview" title="Overview">
            <React.Fragment>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div
                    className="card m-0 py-1 px-2"
                    style={{ background: "#F5FAF6", minHeight: "6rem" }}
                  >
                    <span>Current Balance</span>
                    <span>₹ 0.09</span>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div
                    className="card m-0 py-1 px-2"
                    style={{ background: "#FDF3E7", minHeight: "6rem" }}
                  >
                    <span>Current Balance</span>
                    <span>₹ 0.09</span>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div
                    className="card m-0 py-1 px-2"
                    style={{ background: "#F7EAF5", minHeight: "6rem" }}
                  >
                    <span>Current Balance</span>
                    <span>₹ 0.09</span>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div
                    className="card m-0 py-1 px-2"
                    style={{ background: "#F0F6FB", minHeight: "6rem" }}
                  >
                    <span>Current Balance</span>
                    <span>₹ 0.09</span>
                  </div>
                </div>
              </div>
              <div
                className="tab-custom-pills-horizontal mt-3"
                style={{ background: "#EDF7FF" }}
              >
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col xs={6}>
                      <Nav
                        variant="pills"
                        className="nav-pills-custom justify-content-around p-1 "
                        style={{
                          borderRadius: "1rem",
                          padding: "1rem",
                          border: "1px solid #2F58CD",
                          background: "#FFF",
                        }}
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                            className="d-flex align-items-center"
                          >
                            Settlements
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            className="d-flex align-items-center"
                          >
                            Ondemand Settlements
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>

                    <Col xs={6}>
                      <div
                        class="btn-group my-2"
                        role="group"
                        aria-label="Basic outlined"
                      >
                        <button type="button" class="btn btn-outline-grey">
                          Need Help? Take a Tour
                        </button>
                        <button type="button" class="btn btn-outline-grey">
                          Documentaion
                        </button>
                      </div>
                      <div></div>
                      <div></div>
                    </Col>

                    <Col xs={12} className="p-0">
                      <Tab.Content className="tab-content-custom-pill">
                        <Tab.Pane eventKey="first">
                          <DMTReports />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <DMTReports />{" "}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <DMTReports />{" "}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </React.Fragment>
          </Tab>

          <Tab eventKey="Batch Refunds" title="0 Minutes Ago"></Tab>

          <Tab eventKey="Orders" title="Refresh"></Tab>
        </Tabs>
      </div>
    );
  }
}

const DMTReports = () => {
  let basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  const { username, password } = basicAuth;

  const [moneyTransferState, setMoneyTransferState] = useState({
    customername: "",
    customerMob: "",
    amount: "",
    bankaccount: "",
    bankifsccode: "",
    transactionType: "IMPS",
    emailid: "",
  });
  const [moneyTransferErrors, setMoneyTransferErrors] = useState({
    customername: "",
    customerMob: "",
    amount: "",
    bankaccount: "",
    bankifsccode: "",
    transactionType: "",
    emailid: "",
  });
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [virtualAccount, setVirtualAccount] = useState(null);
  const [List, setList] = useState([]);
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(null);
  // MODAL States
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    // getLevelsData();
  }, []);
  return (
    <>
      <div
        className="row m-3 p-3"
        style={{
          background: "#FFFFFF",
          boxShadow: dropShadow,
          borderRadius: "1rem",
        }}
      >
        <div className="col-12 col-md-4 p-1">
          <Form.Group>
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {`Settlement ID`}
            </Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Enter Customer Identifier ID"
              value={moneyTransferState.customername}
              onChange={(e) => {
                setMoneyTransferState({
                  ...moneyTransferState,
                  customername: e.target.value,
                });
              }}
              style={{}}
            />
            <p style={{ color: "red" }}>{moneyTransferErrors.customername}</p>
          </Form.Group>
        </div>

        <div className="col-12 col-md-4 p-1">
          <Form.Group>
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {`Count`}
            </Form.Label>
            <Select options={[]} />
            <p style={{ color: "red" }}>{moneyTransferErrors.amount}</p>
          </Form.Group>
        </div>
        <div className="col-12 col-md-4 p-1 pt-4">
          <button className="btn btn-primary">Search</button>
          <button className="btn btn-danger">Clear</button>
        </div>
      </div>
      <div
        className="row w-100 m-3 p-3"
        style={{
          background: "#FFFFFF",
          boxShadow: dropShadow,
          borderRadius: "1rem",
        }}
      >
        <div className="scroll-inner dashboard-fix-width">
          <table style={{ width: "100%" }} className="dashboard-table-style">
            <tr>
              <th className="text-center">Customer Identifier ID </th>
              <th className="text-center">Account Desciption </th>
              <th className="text-center">Amount Paid </th>
              <th className="text-center">Created At </th>
              <th className="text-center">Status</th>
            </tr>
            {tranxData.map((e, ind) => (
              <tr>
                <td style={{ fontSize: "0.8rem" }}>
                  {" "}
                  <div className="d-flex flex-column align-items-center text-center">
                    <span className="text-center">{e.amount}</span>
                  </div>
                </td>
                <td style={{ fontSize: "0.8rem" }}>
                  <div className="d-flex flex-column align-items-center">
                    <span>{e.ref}</span>
                  </div>
                </td>
                <td style={{ fontSize: "0.8rem" }}>
                  <div className="d-flex flex-column align-items-center">
                    <span>{e.ref}</span>
                  </div>
                </td>
                <td style={{ fontSize: "0.8rem" }}>
                  <div className="d-flex flex-column align-items-center">
                    <span>{e.time}</span>
                  </div>
                </td>
                <td style={{ fontSize: "0.8rem" }}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="badge badge-success badge-pill px-2 py-1">
                      {e.status}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};

export default Settlements;
