import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { BASE_URL_APISERVICES, BASE_URL_PAYNOW } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import "../dashboard/MyTable.css";
export const columns = [
    {
        dataField: "tid",
        text: " Txnt ID",
        color: "#000",
    },
    {
        dataField: "rrn_no",
        text: "RRN No.",

        color: "#000",
    },
    {
        dataField: "service",
        text: "Service",
        color: "#000",
    },
    {
        dataField: "user_type",
        text: "User type",

        color: "#000",
    },
    {
        dataField: "user_code",
        text: "Retailer Code",

        color: "#000",
    },
    {
        dataField: "retailer_name",
        text: "Retailer Name",

        color: "#000",
    },
    {
        dataField: "retailer_number",
        text: "Retailer Number",
        color: "#000",
    },
    {
        dataField: "onboarding_date",
        text: "Onboarding Date",
        color: "red",
    },
    {
        dataField: "kyc_status",
        text: "Kyc Status",
        color: "green",
    },
    {
        dataField: "dist_name",
        text: "Distributor Name",
        color: "#000",
    },
    {
        dataField: "dist_code",
        text: "Distributor Code",
        color: "#000",
    },
    {
        dataField: "dist_mobile",
        text: "Distributor Mobile",
        color: "#000",
    },

    {
        dataField: "cnf_code",
        text: "Master Distributor Code",
        color: "#000",
    },

    {
        dataField: "cnf_name",
        text: "Master Distributor Name",
        color: "#000",
    },

    {
        dataField: "cnf_mobile",
        text: "Master Distributor Mobile",
        color: "#000",
    },

    {
        dataField: "sales_user_code",
        text: "Sales Person Code",
        color: "#000",
    },

    {
        dataField: "sales_user_name",
        text: "Sales Person Name",
        color: "#000",
    },
    {
        dataField: "sales_user_mobile",
        text: "Sales Person Mobile",
        color: "#000",
    },
    {
        dataField: "sales_user_city",
        text: "Sales Person City",
        color: "#000",
    },
    {
        dataField: "transaction_amount",
        text: "Transaction Amount",
        color: "#000",
    },
    {
        dataField: "retailer_opening_balance",
        text: "Retailer Opening balance",
        color: "#000",
    },
    {
        dataField: "retailer_closing_balance",
        text: "Retailer Closing Balance",
        color: "#000",
    },
    {
        dataField: "retailer_comm",
        text: "Retailer Commission",
        color: "#000",
    },
    {
        dataField: "retailer_percentage_comm",
        text: "Retailer % Commission",
        color: "#000",
    },
    {
        dataField: "retailer_tds",
        text: "Retailer TDS",
        color: "#000",
    },
    {
        dataField: "retailer_sgst",
        text: "Retailer SGST",
        color: "#000",
    },
    {
        dataField: "retailer_cgst",
        text: "Retailer CGST",
        color: "#000",
    },
    {
        dataField: "dist_comm",
        text: "Distributor Commission",
        color: "#000",
    },
    {
        dataField: "dist_percentage_comm",
        text: "Distributor % Commission",
        color: "#000",
    },
    {
        dataField: "dist_tds",
        text: "Dsitributor TDS",
        color: "#000",
    },
    {
        dataField: "cnf_comm",
        text: "Master Distributor Commission",
        color: "#000",
    },
    {
        dataField: "cnf_percentage_comm",
        text: "Master Distributor % Commission",
        color: "#000",
    },
    {
        dataField: "cnf_tds",
        text: "Master Distributor TDS",
        color: "#000",
    },
    {
        dataField: "bumppy_charges",
        text: "Bumppy Charges",
        color: "#000",
    },

    {
        dataField: "status",
        text: "status",
        color: "#000",
    },



    {
        dataField: "date",
        text: "Date",
        color: "#000",
    },
    {
        dataField: "Plan",
        text: "plan",
        color: "#000",
    },
];
const tdstyle = {
    fontSize: "0.8rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const tdstyle2 = {
    width: "5%",
    fontSize: "0.8rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const thstyle = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};
const statusFilterTypes = [
    { label: "Settlement Completed", value: "SettlementCompleted" },
    { label: "Settlement In Process", value: "SettlementInProcess" },
    { label: "Failed", value: "FAILED" },
    { label: "Pending", value: "Pending" },
    { label: "Accepted", value: "Accepted" },
];

const AllServicesReport = ({ setDetails }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [offset, setOffset] = useState(50);
    const [data, setData] = useState([]);
    const [singleDate, setSingleDate] = useState(new Date());
    const [statusType, setStatusType] = useState(null);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const dateFormatter2 = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const [selectedService, setSelectedService] = useState({ value: "", label: "Select Service" });

    const [serviceOptions, setServiceOptions] = useState([]);
    const fetchAllServices = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                agentId: "",
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const response = await fetch(
                "https://bumppy.in/bumppypay/getAllReportServices.php",
                requestOptions
            );

            const data = await response.json();

            if (data.status === "00") {
                //   setData(data.data);

                setServiceOptions(data.data.map((service) => {
                    return { label: service.report_alias, value: service.report_alias }
                }));
            } else {
                console.error("API request failed:", data.msg);
            }
        } catch (error) {
            console.error(error);
        }
    };



    const getAllVirtualPayments = async () => {

        if (selectedService.value == "")
            return;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "fromdate": dateFormatter2(startDate),
            "todate": dateFormatter2(endDate),
            "service": selectedService.value,

            //  "service": "selectedService", 
        });
        console.log("API Request Data:", raw);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        try {
            setTransactionLoaderState(true);
            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/getAllServicesReportAdmin`,
                requestOptions
            );
            const data = await res.json();
            console.log(data);

            if (data.status === "0") {
                setData(data.data);
            }

            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.error(error);
        }
    };


    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    const filter = () => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                if (bank && statusType && bank.status.toString() == statusType.value) {
                    b = true;
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        getAllVirtualPayments();
        setCurrentPage(1);
    };

    // did mount
    useEffect(() => {
        getAllVirtualPayments();
        fetchAllServices();
    }, []);

    const searchBarStyle = {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "0px",
        backgroundColor: "#fff",
        width: "25%", // Adjust the width as needed
        height: "20%",
    };
    const inputStyle = {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: "16px",
        marginLeft: "8px",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: "400",
    };
    const iconStyle = {
        color: "rgba(0, 0, 0, 0.5)",
    };
    return (
        <div className="py-2 " style={{ backgroundColor: "rgb(238, 238, 238)" }}>

            <div className="card m-0 p-0">
                <div className="row m-0 py-4 px-4   align-items-center justify-content-end">
                    <div
                        className="pr-1 pl-2 p-0 m-0"
                        style={{ width: "18%", height: "20%" }}
                    >
                        <Select
                            options={serviceOptions}
                            onChange={(e) => {
                                setSelectedService(e)
                            }}
                            value={selectedService}

                        />

                    </div>


                    <div
                        className="pr-1 pl-2 p-0 m-0"
                        style={{ width: "18%", height: "20%" }}
                    >
                        <Form.Group className="p-0 m-0">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control rounded-100"
                                placeholderText="Start Date"
                            />
                        </Form.Group>
                    </div>
                    <div
                        className="px-1  p-0 m-0"
                        style={{ width: "18%", height: "20%" }}
                    >
                        <Form.Group className="p-0 m-0">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                className="form-control rounded-100"
                                placeholderText="End Date"
                            />
                        </Form.Group>
                    </div>
                    <div
                        className="px-1   p-0 m-0"
                        style={{ width: "14%", height: "20%" }}
                    >
                        <Form.Group className="mt-0 p-0 m-0">
                            <Button
                                className="rounded-100 mt-4 btn btn-lg btn-block"
                                onClick={filter}
                            >
                                {"Search"}
                            </Button>
                        </Form.Group>
                    </div>
                    <div
                        className="m-0 p-0  hover-container"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            ExportToExcel(
                                `Report_${dateFormatter()}`,
                                excelMapping(data, columns)
                            );
                        }}
                    >
                        <img
                            className="pl-1 pt-1"
                            src={require("../../../assets/images/download Icon.png")}
                            style={{ width: `3.0rem`, height: "70%" }}
                        />
                        <div
                            div
                            style={{ color: "rgba(255, 255,255, 1)" }}
                            className="hover-text bg-dark "
                        >
                            Export to Excel
                        </div>
                    </div>
                </div>

                <hr className="m-0 p-0" />

                {data.length === 0 && (
                    <div className="d-flex justify-content-center">
                        <img
                            src={require("../../../assets/animation/coming_soon.gif")}
                            style={{ height: "auto", width: "50vh" }}
                        />
                    </div>
                )}
                {data.length > 0 && (
                    <div className="mx-2">
                        <div className=" table-responsive">
                            <table className="my-table transparent-table text-center border-bottom ">
                                <tr className="borderless-table text-center border-bottom">

                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Sr."}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Txnt ID/RRN No."}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Services"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"User Type"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Retailer Name"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Retailer Balance"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Transaction Amount"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Retailer Commission"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Retailer TDS/SGST/CGST"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Distributor Commission"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Distributor TDS"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Master Distributor Commission"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Master Distributor TDS"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Bumppy Charges"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Plans"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Status"}
                                    </th>
                                    <th className="my-table transparent-table text-center border-bottom " style={thstyle}>
                                        {" "}
                                        {"Date"}
                                    </th>


                                </tr>



                                {dataToDisplay.map((e, ind) => (
                                    <tr className="border-bottom">
                                        <td
                                            className="my-custom-table-userID p-2 m-0 align-middle text-wrap"
                                            style={tdstyle}
                                        >
                                            {" "}
                                            {ind + 1}
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>Txnt:{`${e.tid}`}</div>
                                                <div>RRN:{`${e.rrn_no}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.service}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.user_type}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.user_code}`}</div>
                                                <div>{`${e.retailer_name}`}</div>
                                                <div>{`${e.retailer_number}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>Opening:{`${e.retailer_opening_balance}`}</div>
                                                <div>Closing:{`${e.retailer_closing_balance}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.transaction_amount}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.retailer_comm}`}</div>
                                                <div>{`% Commission : ${e.retailer_percentage_comm}`}%</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>TDS:{`${e.retailer_tds}`}</div>
                                                <div>SGST:{`${e.retailer_sgst}`}</div>
                                                <div>CGST:{`${e.retailer_cgst}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.dist_comm}`}</div>
                                                <div> {`% Commission : ${e.dist_percentage_comm}`}%</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.dist_tds}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.cnf_comm}`}</div>
                                                <div></div>
                                                <div>{`% Commission: ${e.cnf_percentage_comm}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.cnf_tds}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.bumppy_charges}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.plan}`}</div>
                                            </div>
                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}

                                            <div className="d-flex ">
                                                {e.status == "Success" ? (
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            paddingLeft: "0px",
                                                            padding: "5px",
                                                            borderRadius: "5px",
                                                            border: "1px solid",
                                                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                            color: "rgba(43, 193, 85, 1)",
                                                            bordercolor: "rgba(43, 193, 85, 1)",
                                                        }}



                                                    >
                                                        {`${e.status}`}
                                                    </span>
                                                ) : e.status == "Fail" ? (
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            paddingLeft: "3px",
                                                            padding: "5px",
                                                            borderRadius: "5px",
                                                            border: "1px solid",
                                                            backgroundColor: "rgba(255, 46, 46, 0.1)",
                                                            color: "rgba(255, 46, 46, 1)",
                                                            bordercolor: "rgba(255, 46, 46, 1)",
                                                        }}
                                                    >
                                                        {`${e.status}`}
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            paddingLeft: "3px",
                                                            padding: "5px",
                                                            borderRadius: "5px",
                                                            border: "1px solid",
                                                            backgroundColor: "rgba(254, 128, 36, 0.1)",
                                                            color: "rgba(254, 128, 36, 1)",
                                                            bordercolor: "rgba(254, 128, 36, 1)",
                                                        }}
                                                    >
                                                        {`${e.status}`}
                                                    </span>
                                                )}

                                            </div>


                                        </td>
                                        <td style={tdstyle} className="my-custom-table-userID p-2 m-0 align-middle text-wrap">
                                            {" "}
                                            <div >
                                                <div>{`${e.date}`}</div>
                                            </div>
                                        </td>


                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>
                )}
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </div>
    );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });
    const selectStyleLeftBorder = {

        borderRadius: '0px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
        borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
        borderRadius: '0px',
        borderLeft: 'none', // Remove right side border
        borderTop: 'none', // Remove top border
        borderBottom: 'none', // Remove bottom border
        "fontFamily": "Poppins",
        'fontsize': '16px',




    };
    const selectStyleBorder = {
        border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
        borderRadius: '8px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
        backgroundColor: "#FFFFFF",
    };
    const selectStyle = {

        border: 'none', // Remove the border
        "fontFamily": "Poppins",
        'fontsize': '16px',

    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",

            }}
        >
            <div style={{ "fontFamily": "Poppins", }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>


            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}

                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">{offset}</option>
                    ))}

                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"

                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}



                <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default AllServicesReport;