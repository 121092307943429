import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ContextMenuTrigger } from "react-contextmenu";
import { urls } from "../../URLs";
import { offsetArr } from "../../utils/CommonConfig";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";

import { basicAuth } from "../../config";
import {
  formatDateDDMMYYHHMM,
  generateHashKey2,
  onlyAlpha,
} from "../../utils/utilFuctions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DmtSuccessPopup } from "../../utils/Models";

const contentStyles = {
  width: "100%", // Make the content cover the full horizontal width
  /* Additional inline styles for your content */
};

const tdstyle = {
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const thstyle = {
  color: "dark",
  backgroundColor: "white",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const VendorAccountList = () => {
  const { DMTWithKYCUrl } = urls;

  const [data, setData] = useState([]);
  const { username, password } = basicAuth;

  const [accountDetails, setAccountDetails] = useState({});

  const [bankList, setBankList] = useState([]);
  const [UserAcList, setUserAcList] = useState([]);

  const [mdShow, setMdShow] = useState(false);
  const [isBankVerified, setIsBankVerified] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [pages, setPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [slipModalOpen, setSlipModalOpen] = useState(false);

  const [bankVerificationResponse, setBankVerificationResponse] =
    useState(null);

  const location = useLocation();
  const mobNumVendorGrp = new URLSearchParams(location.search).get("mobile");
  const nameVendorGrp = new URLSearchParams(location.search).get("name");
  const {
    user,
    refreshWallet,

    setTransactionLoaderState,
  } = useContext(GlobalContext);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  // Add Bank Account Start
  const GetBal = async () => {
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getbalance_new&userid=${user.ReferId}`,
        requestOptions
      );
      const result = await res.json();
      if (Number(result.AEPSBalance) >= Number(result.WalletBalance)) {
        // setBalanceToShow(result.WalletBalance);
      } else {
        // setBalanceToShow(result.AEPSBalance);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const InitialAddBankValues = {
    Mobile: "",
    AccHolderName: "",
    BankAcNumber: "",
    SelectBank: "",
    IFSCcode: "",
    bankid: "",
  };
  const [AddBankValues, setAddBankValues] = useState(InitialAddBankValues);
  const [AddBankValuesErrors, setAddBankValuesErrors] = useState({});
  const [Submit, setSubmit] = useState(false);

  const AddBank = async () => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        // userid: user.ReferId,
        // customerName: AddBankValues.AccHolderName,
        // accNo: AddBankValues.BankAcNumber,
        // ifsc: AddBankValues.IFSCcode,
        // bankName: AddBankValues.SelectBank,

        userid: user.ReferId,
        mobile: mobNumVendorGrp,
        bankAccNo: AddBankValues.BankAcNumber,
        ifsc: AddBankValues.IFSCcode,
        bankName: AddBankValues.SelectBank,
        bankId: AddBankValues.bankid,
        beneName: AddBankValues.AccHolderName,
        beneMobile: AddBankValues.Mobile,
        device: "Web",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/merchant/addMerchantAccount",
        requestOptions
      );
      const result = await res.json();
      if (result.status == "0") {
        refreshWallet();
        getMerchantAccountList();
        cogoToast.success(result.msg);
        swal("Successfull", `Bank Account added successfully`, "success").then(
          (isPressed) => {
            setMdShow(false);
          }
        );
        // login id daldio url me
      } else {
        swal("Failed", result.msg, "error");
      }
      // GetBankList();
      GetUserBankAcList();
      GetBal();
      clearAddBankForm();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", `Something went wrong`, "error");
      // console.log(error);
    }
  };

  const handleAddBankValues = (e) => {
    const { name, value } = e.target;
    setAddBankValues({ ...AddBankValues, [name]: value });
  };
  const clearAddBankForm = () => {
    setAddBankValues({
      Mobile: "",
      AccHolderName: "",
      BankAcNumber: "",
      SelectBank: "",
      IFSCcode: "",
    });
    setIsBankVerified(false);
  };
  const handleSubmit1 = (e) => {
    e.preventDefault();
    setAddBankValuesErrors(validate1(AddBankValues));
    console.log(AddBankValuesErrors);
    console.log(AddBankValues);
    setSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(AddBankValuesErrors).length === 0 && Submit) {
      AddBank();
    }
  }, [AddBankValuesErrors]);

  const validate1 = (values) => {
    const errors = {};
    if (!values.AccHolderName) {
      errors.AccHolderName = "*Account Holder Name is required!";
    }
    if (!values.BankAcNumber) {
      errors.BankAcNumber = "*Bank Account Number is required!";
    }
    if (!values.SelectBank) {
      errors.SelectBank = "*Name of Bank is required";
    }
    if (!values.IFSCcode) {
      errors.IFSCcode = "*IFSC code is required!";
    } else if (values.IFSCcode.length != 11) {
      errors.IFSCcode = "*Enter valid IFSC code!";
    }
    if (!values.Mobile) {
      errors.Mobile = "*Mobile number is required!";
    } else if (values.Mobile.length != 10) {
      errors.Mobile = "*Please enter valid mobile number";
    }

    return errors;
  };

  const getBanks = async () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "banklistnew2");
    urlencoded.append("beneficiaryid", "111");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);

      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data !== null && data.data !== null) {
        const options = data.data.bankList.map((e) => {
          return { ...e, label: e.bankName, value: e.ifsc, bankid: e.bankCode };
        });
        setBankList(options);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const GetUserBankAcList = async () => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        customerName: AddBankValues.AccHolderName,
        accNo: AddBankValues.BankAcNumber,
        ifsc: AddBankValues.IFSCcode,
        bankName: AddBankValues.SelectBank,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/pg/getAllPGSettlementAccounts",
        requestOptions
      );
      const result = await res.json();
      if (result.status == "0") {
        // cogoToast.success(result.msg);
        setUserAcList(
          result.data.map((bank) => {
            return {
              ...bank,
              value: `${bank.user_name} | ${bank.user_account} | ${bank.ifsc} | ${bank.bank_name}`,
              label: `${bank.user_name} | ${bank.user_account} | ${bank.ifsc} | ${bank.bank_name}`,
              isDisabled: bank.is_verified == "Disable",
            };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", `Something went wrong`, "error");
      // console.log(error);
    }
  };

  const bankAccountVerificationValidation = () => {
    const errors = {};
    if (!AddBankValues.AccHolderName) {
      errors.AccHolderName = "*Account Holder Name is required!";
    }
    if (!AddBankValues.BankAcNumber) {
      errors.BankAcNumber = "*Bank Account Number is required!";
    }
    if (!AddBankValues.SelectBank) {
      errors.SelectBank = "*Name of Bank is required";
    }
    if (!AddBankValues.IFSCcode) {
      errors.IFSCcode = "*IFSC code is required!";
    } else if (AddBankValues.IFSCcode.length != 11) {
      errors.IFSCcode = "*Enter valid IFSC code!";
    }
    if (!AddBankValues.Mobile) {
      errors.Mobile = "*Mobile number is required!";
    } else if (AddBankValues.Mobile.length != 10) {
      errors.Mobile = "*Please enter valid mobile number";
    }
    setAddBankValuesErrors(errors);
    if (Object.keys(errors).length === 0) {
      BankAccountVerification();
    }
  };

  const BankAccountVerification = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y3BseX5c2e1_He5DAelsa1n0ZTz4RkJywd-zilehzdosBM7VEEhVjKiDEi97xIhAI5XSsrl1nBtQ6jGMvs2wYBI_CkeFAnkQ9NGlPD462psrNHF8SVYdadk_JYObPPbljkuseI1g22cd6NEcoklLwj1Nq9op2JmcJHcHZiErKvioNEIn11KNglzBry8eAAYUageBP4c934mbr-kjpPyWF8lUPPuki1XIhBKMXTwj0pcUqwPMAIkzCh4l6rGH8BscN-6A_Ge9CzqrqCxhPSz_pOZfsPJHevYk49yC2sDCdOxerLOkFMtfnVFSLldoEue_HHokFJOKhSpvRsU_4wWsTnLOd16bwFte7uEQX3dFxi10njb2aPzTBMKNb1zt3gzRzxT0qvTC81N-5Wa8mIwC9jwD8DYzHPk1y0T0k0s9Sov8MQouCWbgZOJxDU4NuaWbH-CEMNquMq6ug6BoTYKve0ItoSIl-dnt-WKhtnbYj4lMdNBKRWUmYiVu-pxSx_Xg3gcij7mzbWsgFO1rTXgp8KlqNyJqFk_zOVQ5WW4DsPANP9DOKg5Ut8R9Ih0JwIF_tR0TK9pp3iHUIifwiEhiDU72VP6iF2btn6_wLKczsySGW5SfrpqbibuA-6vvYhEi9EpxohhVwo0pRmB-yhYoMAb"
      );

      var raw = JSON.stringify({
        account_number: AddBankValues.BankAcNumber,
        ifsc: AddBankValues.IFSCcode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Verify/BankaccountVerify`,
        requestOptions
      );
      const data = await res.json();
      if (data.success && data.response_code == "100") {
        console.log(data.result);
        setIsBankVerified(true);
        setBankVerificationResponse(data.result);
        setAddBankValues({
          ...AddBankValues,
          AccHolderName: data.result?.beneficiary_name,
        });
      } else {
        swal("Failed", `Bank not verified`, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Failed", `Error From Server`, "error");
      setTransactionLoaderState(false);
    }
  };

  //Get Merchant account list for Table
  const getMerchantAccountList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();

      const hash_key = await generateHashKey2(user.ReferId);
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: mobNumVendorGrp,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/merchant/getMerchantAccount`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    // clear_addMerchantForm();
    getMerchantAccountList();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  useEffect(() => {
    getBanks();
  }, []);

  console.log(AddBankValues);
  return (
    <div className="bg-light p-2" style={{ fontFamily: "poppins" }}>
      <PayModel
        details={accountDetails}
        isModalOpen={slipModalOpen}
        setModalOpen={(modalState) => {
          setSlipModalOpen(modalState);
        }}
      />
      <div style={contentStyles} className="scrollable-content">
        <div className="">
          <div
            className="p-4 m-3"
            style={{
              background: "#ffffff",
              borderRadius: "10px",
            }}
          >
            <div className="col-12 row justify-content-between align-items-center py-0 px-2 m-0">
              <h4 className="text-center" style={{ fontWeight: "bold" }}>
                Vendor Group Name : {nameVendorGrp}
              </h4>
              <div className="text-center">
                <Button
                  onClick={() => {
                    setMdShow(true);
                  }}
                  className="btn-sm float-right m-3 p-2"
                  style={{
                    borderRadius: "10px",
                    borderColor: "rgba(86, 190, 137, 1)",
                    backgroundColor: " rgba(86, 190, 137, 1)",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  Add Vendor
                </Button>
              </div>
              <Modal
                show={mdShow}
                onHide={() => setMdShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Vendor </Modal.Title>
                </Modal.Header>

                <Modal.Body
                  style={{
                    display: "block",
                    overflowY: "auto",
                  }}
                  className="account"
                >
                  <div className=" row py-0 px-2">
                    <Form.Group className="col-12 col-sm-6 col-md-6 py-0 px-2">
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <span
                          style={{ color: "black" }}
                        >{` Enter Mobile No`}</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="Mobile"
                        placeholder="Enter Mobile No."
                        style={{ borderRadius: "4px" }}
                        onChange={handleAddBankValues}
                        value={AddBankValues.Mobile}
                      />
                      <p style={{ color: "red" }}>
                        {AddBankValuesErrors.Mobile}
                      </p>
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-6 col-md-6 py-0 px-2">
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <span style={{ color: "black" }}>
                          {" "}
                          {` Account Holder Name`}
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="AccHolderName"
                        placeholder="Enter Account Holder Name"
                        style={{ borderRadius: "4px" }}
                        disabled={isBankVerified}
                        onChange={(e) => {
                          if (onlyAlpha(e.target.value)) {
                            // console.log("alpha");
                            setAddBankValuesErrors({
                              ...AddBankValuesErrors,
                              AccHolderName: "",
                            });
                          } else {
                            //console.log("numeric");
                            setAddBankValuesErrors({
                              ...AddBankValuesErrors,
                              AccHolderName:
                                "Beneficiary Name Contains only Alphabates",
                            });
                            // alert("message");
                          }
                          setAddBankValues({
                            ...AddBankValues,
                            AccHolderName: e.target.value,
                          });
                        }}
                        value={AddBankValues.AccHolderName}
                      />
                      <p style={{ color: "red" }}>
                        {AddBankValuesErrors.AccHolderName}
                      </p>
                    </Form.Group>
                    <Form.Group className="col-12 col-sm-6 col-md-6 py-0 px-2">
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <span style={{ color: "black" }}>
                          {" "}
                          {` Enter Bank Account No`}
                        </span>
                      </Form.Label>

                      <Form.Control
                        type="number"
                        name="BankAcNumber"
                        placeholder="Enter Bank Account No"
                        style={{ borderRadius: "4px" }}
                        onChange={handleAddBankValues}
                        value={AddBankValues.BankAcNumber}
                      />
                      <p style={{ color: "red" }}>
                        {AddBankValuesErrors.BankAcNumber}
                      </p>
                    </Form.Group>
                    <Form.Group className="col-12 col-sm-6 col-md-6 py-0 px-2">
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <span style={{ color: "black" }}>
                          {" "}
                          {` Select Bank`}
                        </span>
                      </Form.Label>

                      <Select
                        options={bankList}
                        onChange={(e) => {
                          console.log(e);
                          setAddBankValues({
                            ...AddBankValues,
                            SelectBank: e.bankName,
                            bankid: e.bankid,
                            IFSCcode : e.ifsc
                          });
                        }}
                      />
                      <p style={{ color: "red" }}>
                        {AddBankValuesErrors.SelectBank}
                      </p>
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-6 col-md-6 py-0 px-2">
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        <span style={{ color: "black" }}>
                          {" "}
                          {` Enter Bank IFSC Code`}
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank IFSC Code"
                        style={{ borderRadius: "4px" }}
                        // onChange={handleAddBankValues}
                        onChange={(e) => {
                          console.log(/^[a-zA-Z0-9]+$/.test(e.target.value));
                          if (
                            AddBankValues.IFSCcode == "" ||
                            /^[a-zA-Z0-9]+$/.test(e.target.value)
                          ) {
                            // console.log("alpha");
                            setAddBankValuesErrors({
                              ...AddBankValuesErrors,
                              IFSCcode: "",
                            });
                          } else {
                            // console.log("numeric");
                            setAddBankValuesErrors({
                              ...AddBankValuesErrors,
                              IFSCcode: "IFSC code only Alphanumeric values",
                            });
                            // alert("message");
                          }
                          setAddBankValues({
                            ...AddBankValues,
                            IFSCcode: e.target.value.toString().toUpperCase(),
                          });
                        }}
                        name="IFSCcode"
                        value={AddBankValues.IFSCcode}
                        maxLength={11}
                      />
                      <p style={{ color: "red" }}>
                        {AddBankValuesErrors.IFSCcode}
                      </p>
                    </Form.Group>
                    {isBankVerified && (
                      <Success
                        text={`Bank Account Verified \n Account Holder Name : ${bankVerificationResponse?.beneficiary_name}`}
                      />
                    )}
                    <div className="col-12 col-sm-6 col-md-6 py-0 px-2 mt-4">
                      {isBankVerified && (
                        <Button
                          className="btn-lg px-5 float-left "
                          style={{
                            border: "4px solid #56BE89",
                            backgroundColor: " #56BE89",
                            color: "#ffffff",
                          }}
                          onClick={handleSubmit1}
                        >
                          Add Bank Account
                        </Button>
                      )}
                      {!isBankVerified && (
                        <div>
                          <Button
                            className="btn-lg px-5 float-left "
                            style={{
                              border: "0px solid #56BE89",
                              backgroundColor: " #56BE89",
                              color: "#ffffff",
                            }}
                            onClick={bankAccountVerificationValidation}
                          >
                            Verify Account
                          </Button>
                          <Button
                            className="btn-lg px-5 float-left ml-1 "
                            style={{
                              border: "0px solid #56BE89",
                              backgroundColor: " #FFA725",
                              color: "#ffffff",
                            }}
                            onClick={handleSubmit1}
                          >
                            Add Account
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>

          <div
            className=" p-4 m-3"
            style={{
              background: "#ffffff",
              borderRadius: "10px",
            }}
          >
            <div className="col-12 row justify-content-between align-items-center py-0 px-2 m-0">
              <h4 className="text-start" style={{ fontWeight: "bold" }}>
                Vendors
              </h4>

              <div style={searchBarStyle} className=" m-2 p-0 bg-light">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={iconStyle}
                  className="ml-2"
                />
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
              </div>

              <div className="scroll-inner admin-fix-width">
                <table
                  style={{ width: "100%" }}
                  className="table table-borderless"
                >
                  <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Sr"}
                    </th>

                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Account Holder Name"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Mobile Number"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Account No"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"IFSC Code"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Status"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Action"}
                    </th>
                  </tr>
                  {dataToDisplay.map((e, ind) => (
                    <tr className=" transparent-table border-bottom ">
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        {ind + 1}
                      </td>
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <span className="">{e.bn_bene_name}</span>
                        </div>
                      </td>
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <span>{e.bn_bene_mobile}</span>
                        </div>
                      </td>
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <span>{e.bn_acc_no}</span>
                        </div>
                      </td>
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <span>{e.bn_bank_name}</span>
                        </div>
                      </td>
                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <span>
                            {e.bn_verify_status == "0" ? (
                              <>
                                <img
                                  src={require("../../../assets/images/Close-circle.png")}
                                  alt="Not Verified"
                                  style={{ width: "1rem", height: "1rem" }}
                                />
                                <span className="pl-2">Not Verified</span>
                              </>
                            ) : (
                              <>
                                <img
                                  src={require("../../../assets/images/Tick-circle.png")}
                                  alt="Verified"
                                  style={{ width: "1rem", height: "1rem" }}
                                />
                                <span className="pl-2">Verified</span>
                              </>
                            )}
                          </span>
                        </div>
                      </td>

                      <td className="align-middle" style={tdstyle}>
                        {" "}
                        <div className="d-flex flex-column align-items-center">
                          <Button
                            style={{
                              backgroundColor: "rgba(43, 193, 85, 0.1)",
                              borderColor: "rgba(43, 193, 85, 1)",
                              color: "rgba(43, 193, 85, 1)",
                            }}
                            onClick={() => {
                              setSlipModalOpen(true);
                              setAccountDetails(e);
                            }}
                          >
                            Pay Now {">"}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>

              <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                  setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                  setOffset(offsetValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const PayModel = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const [tpinShow, setTpinShow] = useState(false);
  const [Pin, setPin] = useState("");
  const [amount, setAmount] = useState("");
  const [PinError, setPinError] = useState("");
  const [amountError, setAmountError] = useState("");
  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  const [popupModalState, setPopupModalState] = useState({
    handleClose: false,
    show: false,
    amount: "",
    message: "",
    callback: () => {},
    status: "",
    date: "",
    transactionId: "",
  });

  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const location = useLocation();
  const mobNumVendorGrp = new URLSearchParams(location.search).get("mobile");

  const submit = () => {
    if (!amount && !Pin) {
      setAmountError("Please enter amount");
      setPinError("Please enter pin");
      return;
    }
    if (!amount) {
      setAmountError("Please enter amount");

      return;
    }
    if (!Pin) {
      setPinError("Please enter pin");
      return;
    }
    pay();
  };

  // Pay API
  const pay = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      const hash_key = await generateHashKey2(user.ReferId);
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        amount: amount,
        mobile: mobNumVendorGrp,
        tpin: Pin,
        bankid: details.id,
        device: "WEB",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log(raw);
      // return;
      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/merchant/merchantPaymentPay`,
        requestOptions
      );
      const result = await res.json();
      if (result.status == "0") {
        setPopupModalState({
          handleClose: () => {
            setPopupModalState({ ...popupModalState, show: false });
          },
          show: true,
          amount: amount,
          message: result.responseMessage,
          callback: () => {},
          status: "success",
          date: formatDateDDMMYYHHMM(new Date()),
          transactionId: result.tid,
        });

        // swal("Success", `Transection Successful`, "success");
        setModalOpen(false);
      } else {
        // swal("Failed",result.responseMessage, "error");
        setPopupModalState({
          handleClose: () => {
            setPopupModalState({ ...popupModalState, show: false });
          },
          show: true,
          amount: amount,
          message: result.responseMessage,
          callback: () => {},
          status: "failed",
          date: formatDateDDMMYYHHMM(new Date()),
          transactionId: result.tid,
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", `Something went wrong`, "error");
    }
  };
  console.log(details);
  useEffect(() => {
    console.log("Received details:", details);
  }, [details]);

  return (
    <>
      <DmtSuccessPopup
        handleClose={popupModalState.handleClose}
        show={popupModalState.show}
        amount={popupModalState.amount}
        message={popupModalState.message}
        callback={popupModalState.callback}
        status={popupModalState.status}
        date={popupModalState.date}
        transactionId={popupModalState.transactionId}
      />
      <Modal
        show={isModalOpen}
        onHide={() => {
          setModalOpen(false);
          setAmount("");
          setPin("");
          setAmountError("");
          setPinError("");
        }}
        aria-labelledby="example-modal-sizes-title-md"
        size="md"
      >
        <Modal.Body
          style={{
            width: "100%",
            display: "block",
            fontFamily: "poppins",
            overflowY: "hidden",
          }}
          className="account"
        >
          <div
            style={{
              padding: "12px",
              fontSize: "22px",
              color: "#FFF",
              fontWeight: "bolder",
            }}
            className="d-flex justify-content-between text-dark"
          >
            <strong> Account Details</strong>
            <strong
              className="px-1 hover"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {" "}
              x
            </strong>
          </div>
          <hr className="m-0 p-0" />
          <React.Fragment>
            <div className="row">
              <div className="col-6">Name</div>
              <div className="col-6">{details.bn_bene_name}</div>
            </div>
            <div className="row">
              <div className="col-6">Mobile Number</div>
              <div className="col-6">{details.bn_bene_mobile}</div>
            </div>
            <div className="row">
              <div className="col-6">Account No</div>
              <div className="col-6">{details.bn_acc_no}</div>
            </div>
            <div className="row">
              <div className="col-6">IFSC Code</div>
              <div className="col-6">{details.bn_bene_name}</div>
            </div>

            <hr className="m-0 p-2" />

            <div className="px-5 pb-0 mb-0">
              <div>
                {" "}
                <span style={{ color: "red" }}>*</span>Amount
              </div>
              <div>
                <Form.Group>
                  <Form.Control
                    type="text"
                    className="form-control p-4 mb-0"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setAmountError("");
                    }}
                    style={{ borderRadius: "5px" }}
                  />
                </Form.Group>
              </div>
              <p className="m-0 p-0" style={{ color: "red" }}>
                {amountError}
              </p>

              <div>
                {" "}
                <span style={{ color: "red" }}>*</span>T-Pin
              </div>
              <div className="input-group ">
                <div className="input-group-prepend bg-transparent">
                  <span className="input-group-text bg-transparent border-right-0 no-glow">
                    <i className="mdi mdi-lock-outline text-primary"></i>
                  </span>
                </div>
                <input
                  autocomplete="new-password"
                  autoCorrect="off"
                  autoSave="new-password"
                  type={tpinShow ? "text" : "password"}
                  className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                  id="exampleInputPassword"
                  placeholder="Enter T-Pin"
                  style={{ borderRadius: "0px" }}
                  name="pin"
                  onChange={(e) => {
                    setPin(e.target.value);
                    setPinError("");
                  }}
                />
                <div className="input-group-prepend bg-transparent">
                  <span
                    className="input-group-text bg-transparent border-left-0 no-glow"
                    onClick={showTPinClick}
                    style={{ borderRadius: "4px" }}
                  >
                    <i
                      className={`mdi text-primary ${
                        tpinShow ? "mdi-eye" : "mdi-eye-off"
                      }`}
                    ></i>
                  </span>
                </div>
              </div>
              <p className="m-0 p-0" style={{ color: "red" }}>
                {PinError}
              </p>
              <div className="d-flex justify-content-center py-4">
                {" "}
                <Button
                  className="p-2"
                  style={{
                    borderRadius: "10px",
                    borderColor: "rgba(86, 190, 137, 1)",
                    backgroundColor: " rgba(86, 190, 137, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    width: "200px",
                  }}
                  onClick={submit}
                >
                  Pay
                </Button>
              </div>
            </div>
          </React.Fragment>
        </Modal.Body>
      </Modal>
    </>
  );
};
const Success = ({ text }) => {
  return (
    <div className="card-inverse-success">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body" style={{ padding: "10px" }}>
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};

export default VendorAccountList;
