import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Tabs,
  Tab,
  Form,
  Dropdown,
  Button,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import swal from "sweetalert";
import { GlobalContext } from "../../../Context/GlobalState";
import { BASE_URL_APISERVICES } from "../../../utils/Constants";

export const TicketCloseModel = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh
}) => {
  const initialState = {
    userType: "",
    fullName: "",
    mobile: "",
    password: "",
    role: "",
  };

  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const clearForm = () => {
    setState(initialState);
  };
  const [state, setState] = useState({
    tid: "",
    category: "",
    issue: "",
    user_id: "",
    status: '0',
    comment: "",
    ticket: {}
  });

  const issues = [
    {
      label: "Mark as close",
      value: "1",
    },
    {
      label: "Mark as open",
      value: "0",
    },
  ];
  const validateForm = () => {
    console.log(state);
    if (
      state.category == "" ||
      state.issue == "" ||
      state.tid == "" ||
      state.user_id == ""
    ) {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
  };
  useEffect(() => {
    if (details) {
      setState({
        ...state,
        ticket: details,
      });
    }
  }, [details]);
  const TicketReply = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({

        "userid": user.pkid,
        "replyBy": "ADMIN",
        "ticketId": state.ticket.id,
        "assignedTo": "1",
        "status": state.status.value,
        "remarks": state.comment,
        "replyMessage": state.comment,
        "type": "0"

      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/support/ticketChatReplyAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
       // TicketResponse();
       setModalOpen(false);
       swal("Success", "Ticket Updated Successfully", "success");
       refresh()
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }


  };

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="lg"
    >
      <Modal.Body>
        <div>
          <strong
            className="px-1 hover d-flex justify-content-end"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
          <div
            style={{
              padding: "12px",
              background: "#3676D8",
              color: "#FFF",
              fontWeight: "bolder",
            }}
          >
            User Code : {details && details.pk_ticket_id}
            {details && details.fk_user_id}
          </div>

          <div className="row">
            <div className="col ">
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {`Enter Comment`}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter Comment"
                  value={state.fullName}
                  onChange={(e) => {
                    setState({ ...state, comment: e.target.value });
                  }}
                  style={{ borderRadius: "0px" }}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {`Select`}
                </Form.Label>
                <Select
                  options={issues}
                  value={state.status}
                  onChange={(e) => {
                    setState({ ...state, status: e });
                  }}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col px-0">
              <Button
                className="rounded btn-block"
                variant="success"
                onClick={TicketReply}
              >
                {" "}
                Submit
              </Button>
            </div>
            <div className="col">
              <Button
                className="rounded btn-block"
                variant="danger"
                onClick={clearForm}
              >
                {" "}
                Clear
              </Button>
            </div>
            <div className="col p-0"></div>
            <div className="col p-0"></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
