import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import DMTReportTable from "./DMTReportTable";

export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "rch_mobile",
    text: "Beneficiary",
  },
  {
    dataField: "utnno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];
export const dmtReport = [
  {
    mobile: "9168805979",
    beneficiary: "32905756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "800",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "4/9/2022 7:17:31 PM",
  },
  {
    mobile: "9057568979",
    beneficiary: "568464756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "1000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "13/2/2022 7:17:31 PM",
  },
  {
    mobile: "9168057568",
    beneficiary: "568907568464565",
    type: "IMPS",
    utrNo: "0",
    amount: "40000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "3/4/2022 7:17:31 PM",
  },
  {
    mobile: "9105756879",
    beneficiary: "684649075684646",
    type: "IMPS",
    utrNo: "0",
    amount: "2000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "pending",
    datetime: "23/5/2022 7:17:31 PM",
  },
];
const RetailerDMTReport = ({agentId}) => {
  const [reports, setReports] = useState([]);
  const { user } = useContext(GlobalContext);

  const fetchReport = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=dmttransaction&mobile=${user.ReferId}`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      setReports(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchReport();
  }, []);
  return (
    <div style={{ margin: "10px" }}>
      
      <DMTReportTable data={reports} columns={columns} agentId={agentId} />
    </div>
  );
};

export default RetailerDMTReport;
