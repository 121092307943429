import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { RaiseTicketModal } from "../util/RaiseTicketModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";
import { getPaynnowTransactionStatus } from "../util/utility";
export const columns = [
 {
 dataField: "tid",
 text: "TxnId",
 },
 {
 dataField: "referenceId",
 text: "ReferenceId",
 },
 {
 dataField: "sorTxnId",
 text: "sorTxnId",
 },
 {
 dataField: "user_code",
 text: "User Code",
 },
 {
 dataField: "user_mobile",
 text: "User Mobile",
 },
 {
 dataField: "rch_service",
 text: "Type",
 },
 {
 dataField: "remitterno",
 text: "Remitter No",
 },
 {
 dataField: "rch_mobile",
 text: "Beneficiary",
 },
 {
 dataField: "utrno",
 text: "UTR NO",
 },
 {
 dataField: "rch_amount",
 text: "Amount",
 },
 {
 dataField: "DmtCharges",
 text: "Fee",
 },
 {
 dataField: "dmt_comission",
 text: "Comm",
 },
 {
 dataField: "tds",
 text: "TDS",
 },
 {
 dataField: "ReturnAmt",
 text: "Net Profit",
 },

 // {
 // dataField: "rch_after_balance",
 // text: "After Balance",
 // },
 {
 dataField: "rch_status_desc",
 text: "Status",
 },
 {
 dataField: "sales_user_code",
 text: "Sales Code",
 },
 {
 dataField: "sales_user_name",
 text: "Sales Name",
 },
 {
 dataField: "sales_user_mobile",
 text: "Sales Mobile Number",
 },
 {
 dataField: "dmt_transfer_date",
 text: "Date&Time",
 },
 {
 dataField: "shop_pin_code",
 text: "Pin Code",
 },
 {
 dataField: "shop_city",
 text: "City",
 },
 {
 dataField: "shop_state",
 text: "State",
 },
];
const DMTHistoryTable = () => {
 const [pageAccess, setPageAccess] = useState({});
 const [data, setData] = useState([]);
 const [salesPersons, setSalesPersons] = useState(null);
 const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
 const [summary, setSummary] = useState([]);
 const { user, setTransactionLoaderState } = useContext(GlobalContext);
 const [currentPage, setCurrentPage] = useState(1);
 const [searchText, setSearchText] = useState("");
 const [status, setStatus] = useState("");
 const [raiseTicketModal, setRaiseTicketModal] = useState(false);
 const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
 const [changeStatusModal, setChangeStatusModal] = useState(false);
 const [changeStatusDetails, setChangeStatusDetails] = useState({});
 const [offset, setOffset] = useState(10);
 const [dataToDisplay, setDataToDisplay] = useState([]);
 const [pages, setPages] = useState(0);
 const [total, setTotal] = useState(0);
 let fromDate = sessionStorage.getItem("fromDate")
 ? new Date(sessionStorage.getItem("fromDate"))
 : new Date();
 let toDate = sessionStorage.getItem("toDate")
 ? new Date(sessionStorage.getItem("toDate"))
 : new Date();
 const [startDate, setStartDate] = useState(new Date());
 const [endDate, setEndDate] = useState(new Date());
 const { Profileinfo } = useContext(GlobalContext);

 const [billDetails, setBillDetails] = useState({});
 const [modalOpen2, setModalOpen2] = useState(false);
 const [amount, setAmount] = useState("");

 const [selectedRowData, setSelectedRowData] = useState(null);
 const [isSideSectionVisible, setIsSideSectionVisible] = useState(false);
 const handleRowClick = (rowData) => {
 setSelectedRowData(rowData);
 setIsSideSectionVisible(true);
 };

 const handleClose = () => {
 setIsSideSectionVisible(false);
 };

 console.log(data);

 const now = (date = new Date()) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };

 const openInNewTab = (data) => {
 localStorage.setItem(
 "print",
 JSON.stringify({ ...data, shopName: Profileinfo.shopname })
 );
 const newWindow = window.open(
 `/pages/print?id=${data.tid}&type=DMT`,
 "",
 "height=600,width=800'"
 );
 if (newWindow) newWindow.opener = null;
 };

 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
 setTotal(filteredData.length);
 const startIdx = 0;
 const endIdx = offset - 1;
 filteredData = filteredData.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 setCurrentPage(1);
 }, [searchText]);

 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 console.log(filteredData);
 const startIdx = (currentPage - 1) * offset;
 const endIdx = currentPage * offset - 1;
 console.log({ startIdx, endIdx });
 filteredData = filteredData.filter((bank, idx) => {
 console.log(idx, idx <= endIdx && idx >= startIdx);
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 console.log({ startIdx, endIdx });
 }, [currentPage]);

 // did mount
 useEffect(() => {
 setPages(Math.floor((data.length + (offset - 1)) / offset));
 const startIdx = 0;
 const endIdx = offset - 1;
 setTotal(data.length);
 console.log(startIdx);
 console.log(data);
 const filteredData = data.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 console.log(filteredData);
 setDataToDisplay(filteredData);
 }, [data, offset]);

 function getDatePreviousMonth(date = new Date()) {
 date.setMonth(date.getMonth() - 1);
 return date;
 }
 const dateFormatter = (date) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };
 const twoDecimalPlaces = (value) => {
 return Number(value).toFixed(2).toString();
 };

 const getDMTReport = async () => {
 try {
 setTransactionLoaderState(true);
 var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");

 var raw = JSON.stringify({
 fromdate: dateFormatter(startDate),
 todate: dateFormatter(endDate),
 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
 `${BASE_URL_API_TRANXT_API}/api/admin/get_dmt_report_admin.aspx`,
 requestOptions
 );
 const data = await res.json();
 // groupByPoolIDData(data.data);
 /// const
 setData(
 data.data.map((e) => {
 return {
 ...e,
 user_code: e.username.split("/")[0],
 user_mobile: e.username.split("/")[1],
 shop_pin_code: e.gggd.split("|")[0],
 shop_city: e.gggd.split("|")[1],
 shop_state: e.gggd.split("|")[2],
 sorTxnId: e.gggd.split("|")[3],
 referenceId: e.gggd.split("|")[4],
 };
 })
 );
 const sales = [];
 const sales_persons = [{ label: "All Sales Person", value: "" }];
 data.data.forEach((ee) => {
 if (!sales.includes(ee.sales_user_code) && ee.sales_user_code != "") {
 sales_persons.push({
 label: `${ee.sales_user_code} ${ee.sales_user_name}`,
 value: ee.sales_user_code,
 });
 sales.push(ee.sales_user_code);
 }
 });

 setSalesPersons(sales_persons);
 if (data.data2) setSummary(data.data2);
 setTransactionLoaderState(false);
 } catch (error) {
 setTransactionLoaderState(false);
 console.log("error", error);
 }
 };

 const refundDMTTransaction = async (tid) => {
 try {
 setTransactionLoaderState(true);
 var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");

 var raw = JSON.stringify({
 trans_id: tid,
 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
 `${BASE_URL_API_TRANXT_API}/api/admin/refunds/dmttrans_refund.aspx`,
 requestOptions
 );
 const data = await res.json();
 if (data.status == "0") {
 swal("Success", "Transaction Refunded Successfully", "success");
 getDMTReport();
 } else {
 swal("Failed", data.msg, "error");
 }
 setTransactionLoaderState(false);
 } catch (error) {
 console.log("error", error);
 setTransactionLoaderState(false);
 }
 };
 const refundVerification = (tid, amount, RetailerID) => {
 swal({
 title: "Are you sure?",
 text: `Refund amount of ${amount} to ${RetailerID}`,
 icon: "warning",
 buttons: true,
 dangerMode: true,
 }).then((willDelete) => {
 if (willDelete) {
 refundDMTTransaction(tid);
 }
 });
 };

 const showResponse = (response) => {
 swal2(
 <div className="d-flex overflow-auto">
 <strong>{JSON.stringify(response)}</strong>
 </div>
 );
 };

 const raiseTicketShow = (data) => {
 setRaiseTicketDetails({
 tid: data.tid,
 category: "DMT",
 user_id: data.username.toString().split("/")[0],
 });
 setRaiseTicketModal(true);
 };

 const changeStatusShow = (data) => {
 setChangeStatusDetails({
 tid: data.ttyp,
 service: "DMT",
 });
 setChangeStatusModal(true);
 };

 const getTransactionStatus = async (tid) => {
 const data = await getPaynnowTransactionStatus(tid);
 if (data.responseCode == "00") {
 swal("Success", `Status : ${data.status}`, "success");
 } else {
 swal("Failed", `Status : ${data.status}`, "error");
 }
 }


 useEffect(() => {
 getDMTReport();
 }, []);

 useEffect(() => {
 if (selectedSalesPerson) {
 if (selectedSalesPerson.value == "") {
 setDataToDisplay(data);
 } else {
 const filteredData = data.filter((ee) => {
 return ee.sales_user_code == selectedSalesPerson.value;
 });
 setDataToDisplay(filteredData);
 }
 }
 }, [selectedSalesPerson]);

 const searchBarStyle = {
 display: 'flex',
 alignItems: 'center',
 border: '1px solid #ccc',
 borderRadius: '6px',
 padding: '0px',
 backgroundColor: '#fff',
 width: '35%', // Adjust the width as needed
 height: '20%',

 };
 const inputStyle = {
 border: 'none',
 outline: 'none',
 backgroundColor: 'transparent',
 fontSize: '16px',
 marginLeft: '8px',
 width: '100%',
 "fontFamily": "Poppins",
 "fontWeight": "400",
 };
 const iconStyle = {
 color: 'rgba(0, 0, 0, 0.5)',
 };

 const positionStyle = {
 position: 'absolute',
 top: -27,
 left: 0,
 "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
 };
 const positionStyle1 = {
 position: 'absolute',
 top: -38,
 left: 0,
 "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
 };

 const getPageAccess = (menuId) => {
 const sub_menus_access_info = JSON.parse(sessionStorage.getItem("sub_menus"));
 sub_menus_access_info.forEach((acc) => {
 if (acc.pk_menu_id == menuId) {
 setPageAccess(acc);
 }
 })
 }

 useEffect(() => {
 getPageAccess(14);
 }, [])

 const setModalData = (billDetails) => {
 setBillDetails(billDetails);
 setModalOpen2(true);
};


 return (
 <>

<UpdateTariffPlanModal
 isModalOpen={modalOpen2}
 billDetails={billDetails}
 amount={amount}
 setModalOpen2={(modalState) => {
 setModalOpen2(modalState);
 }}
 refresh={()=>{
 setModalOpen2(false);
 getDMTReport();
 }}
 />

 <RaiseTicketModal
 details={raiseTicketDetails}
 isModalOpen={raiseTicketModal}
 setModalOpen={(modalState) => {
 setRaiseTicketModal(modalState);
 }}
 refresh={() => { }}
 />

 <ChangeStatusModal
 details={changeStatusDetails}
 isModalOpen={changeStatusModal}
 setModalOpen={(modalState) => {
 setChangeStatusModal(modalState);
 }}
 refresh={getDMTReport}
 />
 <div className="row m-0 p-0 mb-3">
 <div className="col-6 m-0 p-0">
 <div className="row m-0 p-0">
 <div className="col-6 m-0 p-0 pl-2 pr-2">
 <DashboardCardKYC
 label={"Amount"}
 label1={"Success"}
 data1={twoDecimalPlaces(summary.SuccessRecharge)}
 label2={"Pending"}
 data2={twoDecimalPlaces(summary.PendingRecharge)}
 label3={"Fail"}
 data3={twoDecimalPlaces(summary.FailRecharge)}
 />
 </div>
 <div className="col-6 m-0 p-0 pl-2 pr-3">
 <DashboardCardKYC
 label={"Count"}
 label1={"Success"}
 data1={summary.Success}
 label2={"Pending"}
 data2={summary.Pending}
 label3={"Fail"}
 data3={summary.Fail}
 />
 </div>
 </div>
 </div>

 <div className="col-6 m-0 p-0">
 <div className="row m-0 p-0">

 <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
 <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
 <input
 type="text"
 className="form-control "
 placeholder="Search"
 aria-label="Search"
 style={inputStyle}
 onChange={(e) => {
 setSearchText(e.target.value);
 }}
 value={searchText}
 />
 </div>




 <div className=" pr-1 pl-2" style={{ width: '25%', }}>
 <Form.Group>
 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={startDate}
 onChange={(date) => {
 setStartDate(date);
 sessionStorage.setItem("fromDate", date.toISOString());
 }}
 className="form-control rounded-100"
 placeholderText="Start Date"
 />
 </Form.Group>
 </div>
 <div className="px-1" style={{ width: '25%', }}>
 <Form.Group>
 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={endDate}
 onChange={(date) => {
 setEndDate(date);
 sessionStorage.setItem("toDate", date.toISOString());
 }}
 className="form-control rounded-100"
 placeholderText="End Date"
 />
 </Form.Group>
 </div>
 <div className="hover-container"
 style={{ cursor: "pointer" }}
 onClick={() => {
 ExportToExcel(
 `DMT_Report_${now()}`,
 excelMapping(data, columns)
 );
 }}
 >
 <img className="pl-1 "
 src={require("../../../assets/images/download Icon.png")}
 style={{ width: `3.5rem`, height: '85%' }}
 />
 <div div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
 </div>
 </div>

 <div className="row m-0 p-0">
 <div className="" style={{ width: '35%', }}>
 <Form.Group>
 <Select
 options={salesPersons}
 onChange={(e) => {
 setSelectedSalesPerson(e);
 }}
 />

 </Form.Group>
 </div>
 <div className="px-2" style={{ width: '25%', }}>
 <Form.Group>
 <Button
 className="rounded-100 mt-4 btn btn-block btn-lg"
 onClick={getDMTReport}
 >
 {"Search"}
 </Button>
 </Form.Group>
 </div>
 </div>
 </div>
 </div>

 <hr />

 <div className="scroll-inner admin-fix-width">
 <table
 className="my-table transparent-table text-center border-bottom "
 >
 <tr className="borderless-table text-center border-bottom">
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Op ID"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Account"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Remitter"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Amount"}</th>
 {/* <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"A Profit"}</th> */}
 {/* <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Profit"}</th> */}
 {/* <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Balance"}</th> */}
 <th className="my-table transparent-table text-center border-bottom m-0 p-0 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Status"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sales Person"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Action"}</th>
 </tr>
 {dataToDisplay.map((e, ind) => (
 <tr 
 className="border-bottom clickable-row"
 > 
 <td
 className="text-center m-0 p-0 pr-3 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 onClick={() => handleRowClick(e)}
 >
 {" "}
 {ind + 1}
 </td>
 <td className="text-center m-0 p-0 pr-2 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 onClick={() => handleRowClick(e)}>
 {" "}
 <div className="d-flex flex-column align-items-center" style={{ maxWidth: '150px' }}>
 <div>{e.username}</div>
 {/* <div >{`TID :`}</div>
 <div >{`${e.tid}`}</div>
 <div>{e.dmt_transfer_date}</div> */}
 </div>
 </td>
 <td className="text-center m-0 p-0 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 onClick={() => handleRowClick(e)}
 >
 {" "}
 <div style={{ maxWidth: '150px' }} className="d-flex flex-column align-items-center text-center">
 <span className="text-center">{e.Name}</span>
 {/* <span>{e.rch_mobile}</span> */}
 {/* <span>{"Pool ID"}</span>
 <span>{e.dmt_pool_id}</span> */}
 </div>
 </td>
 <td className="text-center m-0 p-0 pr-2 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 onClick={() => handleRowClick(e)}>
 <div className="d-flex flex-column align-items-center">
 {/* <span>{e.username}</span> */}
 {/* <span >{"Remitter"}</span> */}
 <span>{e.remitterno}</span>
 </div>
 </td>
 <td className="text-center m-0 p-0 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 onClick={() => handleRowClick(e)}>
 <div className="d-flex flex-column align-items-center">
 <div>{e.rch_amount}</div>
 {/* <div >{e.ttypes}</div>
 <div >{`Deduction :`}</div>
 <div >{` ${twoDecimalPlaces(
 e.dmt_deduction
 )}`}</div> */}
 </div>
 </td>
 {/* <td className="text-center m-0 p-0 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div className="d-flex flex-column align-items-center">
 0.00
 </div>
 </td> */}
 {/* <td className="text-center m-0 p-0 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div className="d-flex flex-column align-items-center">
 <span>{`C Com :${twoDecimalPlaces(e.rch_com_cnf)}`}</span>
 <span>{`D Com :${twoDecimalPlaces(
 e.rch_com_distributer
 )}`}</span>
 <span >{`Charges :${twoDecimalPlaces(
 e.DmtCharges
 )}`}</span>
 <span >{`Comm :${twoDecimalPlaces(
 e.rch_com_retailer
 )}`}</span>
 <span >{`TDS :${twoDecimalPlaces(
 e.tds
 )}`}</span>
 </div>
 </td> */}
 {/* <td className="text-center m-0 p-0 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div className="d-flex flex-column align-items-center">
 <div >{`Opening :`}</div>
 <div >{`${twoDecimalPlaces(
 e.rch_before_balance
 )}`}</div>

 <div >{`Closing :`}</div>
 <div >{`${twoDecimalPlaces(
 e.rch_after_balance
 )}`}</div>
 </div>
 </td> */}
 <td className="text-center m-0 p-0 " style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 onClick={() => handleRowClick(e)}>

 <div className="m-0 p-0">
 {e.rch_status_desc == "Success" ? (
 <span
 style={{

 borderRadius: "5px",
 border: "1px solid",
 backgroundColor: 'rgba(43, 193, 85, 0.1)',
 color: 'rgba(43, 193, 85, 1)',
 bordercolor: 'rgba(43, 193, 85, 1)',

 }} className="px-2 py-2"
 >
 {`${e.rch_status_desc}`}
 </span>
 ) : (
 <span
 className="m-0 p-0 px-2 py-2"
 style={{


 borderRadius: "5px",
 border: "1px solid",
 backgroundColor: 'rgba(255, 46, 46, 0.1)',
 color: 'rgba(255, 46, 46, 1)',
 bordercolor: 'rgba(255, 46, 46, 1)',

 }}

 >
 {`${e.rch_status_desc}`}
 </span>
 )}
 </div>

 </td>

 <td className="text-center m-0 p-0 pr-0 py-1 pd-2" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 onClick={() => handleRowClick(e)}>
 {" "}
 <div style={{ maxWidth: '150px' }} className="d-flex flex-column align-items-center">
 <span>{`${e.sales_user_name}`}</span>
 <span>{`${e.sales_user_code}`}</span>
 {/* <span>{`${e.sales_user_mobile}`}</span> */}
 </div>
 </td>


 <td style={{ fontSize: "0.8rem" , alignItems:"center"}} className="m-0 p-0 pl-2 text-center">

 {pageAccess.str_access_rights == 1 && (

 <div
 className="text-center m-0 p-0"
 style={{
 fontFamily: "Poppins",
 fontSize: "0.8rem",
 fontWeight: "400",
 lineHeight: "24px",
 letterSpacing: "0em",
 textAlign: "center",
 }}
 >

 <div className="row m-0 p-0" >

 {e.rch_status_desc == "Refund" ? (
 <span>
 <Button
 style={{
 borderRadius: "9px",
 border: "1px none",
 backgroundColor: ' rgb(216,216,216)',
 color: ' rgb(216,216,216)',
 bordercolor: ' rgb(216,216,216)',
 "fontSize": "4px !important"
 }}
 className="btn rounded-100 m-0 p-1">
 {" "}
 <div
 className="hover-container"

 > <img src={require("../../../assets/images/Refund.png")} style={{ width: "2.1rem" }} />
 {/* <div style={positionStyle} className="hover-text bg-dark py-1 ">Refund</div> */}
 </div>
 </Button>

 </span>
 ) : (
 <span>
 <Button
 style={{
 borderRadius: "5px",
 border: "1px none",
 backgroundColor: 'rgba(255, 255, 255, 1)',
 color: 'rgba(255, 164, 27, 1)',
 bordercolor: 'rgba(255, 255, 255, 1)',
 "fontSize": "4px !important"
 }}
 className="btn rounded-100 m-0 p-1">
 {" "}
 <div
 className="hover-container"
 // onClick={() => {
 // refundVerification(e.tid, e.rch_amount, e.username);
 // }}
 onClick={() => {
 setModalData({ id: e.tid, rch_amount: e.rch_amount, user_code: e.user_code });
 }}
 disabled={
 e.rch_status_desc == "Refund" ||
 e.rch_status_desc == "Refunded"
 }
 > <img src={require("../../../assets/images/Refund.png")} style={{ width: "2.1rem" }} />
 <div style={positionStyle} className="hover-text bg-dark py-1 ">Refund</div>
 </div>
 </Button>
 </span>
 )}







 <Button style={{
 // paddingLeft:"3px",
 // padding: "5px",
 borderRadius: "5px",
 border: "1px none",
 backgroundColor: 'rgba(255, 255, 255, 1)',
 color: 'rgba(255, 164, 27, 1)',
 bordercolor: 'rgba(255, 255, 255, 1)',
 }} className="btn btn-sm rounded-100 m-0 p-1">
 {" "}

 <div
 onClick={() => showResponse(e.ApiResponse)}
 className="hover-container"

 >
 {/* Your content here */}
 <img
 src={require("../../../assets/images/Bank Response.png")}
 style={{ width: "2.1rem" }}
 />
 <div style={positionStyle1} className="hover-text bg-dark"><div >Bank</div><div > Response</div></div>
 </div>


 </Button>

 <Button style={{
 // paddingLeft:"3px",
 // padding: "5px",
 borderRadius: "5px",
 border: "1px none",
 backgroundColor: 'rgba(255, 255, 255, 1)',
 color: 'rgba(255, 164, 27, 1)',
 bordercolor: 'rgba(255, 255, 255, 1)',

 }}
 className="btn btn-sm rounded-100 m-0 p-1"
 onClick={() => {
 raiseTicketShow(e);
 }}
 >
 {" "}

 <div
 className="hover-container"

 >
 {/* Your content here */}
 <img
 src={require("../../../assets/images/Raise Ticket.png")}
 style={{ width: "2.1rem" }}
 />

 <div style={positionStyle1} className="hover-text bg-dark"> Raise Ticket</div>
 </div>

 </Button>

 <Button style={{
 // paddingLeft:"3px",
 // padding: "5px",
 borderRadius: "5px",
 border: "1px none",
 backgroundColor: 'rgba(255, 255, 255, 1)',
 color: 'rgba(255, 164, 27, 1)',
 bordercolor: 'rgba(255, 255, 255, 1)',

 }}
 className="btn btn-sm rounded-100 m-0 p-1"
 onClick={() => {
 changeStatusShow(e);
 }}
 >
 {" "}

 <div
 className="hover-container"

 >
 {/* Your content here */}
 <img
 src={require("../../../assets/images/Change Status (1).png")}
 style={{ width: "2.1rem" }}
 />

 <div style={positionStyle1} className="hover-text bg-dark"> Change Status</div>
 </div>

 </Button>

 <Button style={{
 borderRadius: "5px",
 border: "1px none",
 backgroundColor: 'rgba(255, 255, 255, 1)',
 color: 'rgba(255, 164, 27, 1)',
 bordercolor: 'rgba(255, 255, 255, 1)',

 }}
 className="btn btn-sm rounded-100 m-0 p-0"
 onClick={() => {
 if (e.rch_amount >= 25000)
 getTransactionStatus(e.tid)
 else swal("Failed", "Only E Suvidha transaction status will be shown", "error");
 }}
 >
 {" "}

 <div
 className="hover-container"

 >
 {/* Your content here */}
 <img
 src={require("../../../assets/images/Raise Ticket.png")}
 style={{ width: "2.1rem" }}
 />

 <div style={positionStyle1} className="hover-text bg-dark"> Transaction Status </div>
 </div>

 </Button>
 </div>
 </div>

 )}

 </td>
 </tr>
 ))}
 </table>
 {data.length <= 0 && <NoDataFound />}
 </div>
 <div
 className={`side-section ${isSideSectionVisible ? "visible" : ""}`}
 // style={{display:"flex" , flexDirection:"column-reverse"}}
 >
 <button className="close-button" onClick={handleClose}>
 &times;
 </button>
 <h5 className=""><b>Details</b></h5>

 {selectedRowData && (
 <div 
 style={{
 maxHeight: "80%", 
 overflowY: "auto", 
 padding: "5px",
 }}>
 <table >
 <tbody>
 <tr className="border-bottom border-top" >
 <td style={{ fontSize: "12px", border: "none" }}
 >User ID :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.username}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >TXN ID :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.tid}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Status :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.rch_status_desc}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Date :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.dmt_transfer_date}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Account :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.Name}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Number :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.rch_mobile}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Pool Id :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.dmt_pool_id}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Remitter Name :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.remitterno}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Amount :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.rch_amount}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Service :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.ttypes}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >DMT Deduction :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.dmt_deduction}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >MD Commission :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{twoDecimalPlaces(selectedRowData.rch_com_cnf)}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Distributor Commission :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{twoDecimalPlaces(selectedRowData.rch_com_distributer)}</td>
 </tr>
 
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Retailer Commission:</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{twoDecimalPlaces(selectedRowData.rch_com_retailer)}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Charges :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{twoDecimalPlaces(selectedRowData.DmtCharges)}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >TDS :</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{twoDecimalPlaces(selectedRowData.tds)}</td>
 </tr>
 
 
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Opening Bal:</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.rch_before_balance}</td>
 </tr>
 
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Closing Bal:</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.rch_after_balance}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Sales Person Name:</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.sales_user_name}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Sales Person Number:</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.sales_user_mobile}</td>
 </tr>
 <tr className="border-bottom">
 <td
 style={{ fontSize: "12px", border: "none" }}
 >Sales User Code:</td>
 <td
 style={{ fontSize: "12px", border: "none" }}
 >{selectedRowData.sales_user_code}</td>
 </tr>
 

 
 </tbody>
 </table>
 </div>
 )}

 </div>
 <Pagination
 page={currentPage}
 limit={pages}
 callback={(page) => {
 setCurrentPage(page);
 }}
 count={dataToDisplay.length}
 total={total}
 callback2={(offsetValue) => {
 setOffset(offsetValue);
 }}
 />
 </>
 );
};

export const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
 console.table({ page, limit, callback });
 return (
 <div
 className="row"
 style={{
 display: "flex",
 alignItems: "end",
 width: "100%",
 margin: "10px 0px",
 justifyContent: "space-between",
 }}
 >
 <div>
 <strong>{`Showing ${count} of ${total} entries`}</strong>
 </div>
 <div className="d-flex btn-group">
 <select
 className="rounded-0 form-select-sm"
 aria-label="offset"
 onChange={(e) => {
 callback2(e.target.value);
 }}
 >
 {offsetArr.map((offset) => (
 <option value={offset}>{offset}</option>
 ))}
 </select>
 {page - 2 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}
 variant="light"
 className="rounded-0"
 >
 {"Previous"}
 </Button>
 ) : null}

 {page - 1 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}
 variant="light"
 className="rounded-0"
 >
 {" "}
 {(page - 1).toString()}
 </Button>
 ) : null}
 <Button variant="primary"> {page.toString()}</Button>
 {page + 1 <= limit ? (
 <Button
 onClick={() => {
 callback(page + 1);
 }}
 variant="light"
 className="rounded-0"
 >
 {"Next"}
 </Button>
 ) : null}
 </div>
 </div>
 );
};
export const NoDataFound = () => {
 return (
 <tr className="row w-100 justify-content-center">
 <div className="d-flex flex-column align-items-center p-3">
 <img
 src={require("../../../assets/images/no_data_found.png")}
 style={{ height: "5rem", width: "auto" }}
 />
 <strong>No Data Found</strong>
 </div>
 </tr>
 );
};

const DashboardCardKYC = ({
 label,

 data1,
 data2,
 data3,
}) => {
 return (
 <>
 <div className="card m-0 p-0 px-3 py-2 ">
 <h4 className="card-title p-0 text-dark fw-bold m-0 ">{label}</h4>

 <hr />

 <div className="row p-0 m-0 align-items-center py-1">
 <div className="col p-0 m-0">
 <SquareColoredIcon
 color="#0ACF97"
 iconName="heart-fill"
 text="Success"
 />
 </div>
 <div className="col p-0 m-0 justify-content-end">{data1}</div>
 </div>

 <div className="row p-0 m-0 align-items-center py-1">
 <div className="col p-0 m-0">
 <SquareColoredIcon
 color="#FFBC00"
 iconName="heart-fill"
 text="Pending"
 />
 </div>
 <div className="col p-0 m-0">
 {data2}
 {/* <ProgressBar value={data2} colour={"bg-warning"} /> */}
 </div>
 </div>

 <div className="row p-0 m-0 align-items-center py-1">
 <div className="col p-0 m-0">
 <SquareColoredIcon
 color="#FA5C7C"
 iconName="heart-fill"
 text="Fail"
 />
 </div>
 <div className="col p-0 m-0 md-1">
 {data3}
 {/* <ProgressBar value={data3} colour={"bg-danger"} /> */}
 </div>
 </div>
 </div>
 </>
 );
};

const SquareColoredIcon = ({ color, iconName, text }) => {
 const iconStyle = {
 backgroundColor: color,
 width: "11px",
 height: "11px",
 display: "flex",
 justifyContent: "between",
 alignItems: "center",
 borderRadius: "2px",
 marginright: "2px",
 };

 return (
 <div className=" mr-2" style={iconStyle}>
 <div className="mr-2">
 <i
 className={`bi-${iconName}`}
 style={{ color: "white", fontSize: "24px" }}
 ></i>
 </div>

 <div className="ml-2 " style={{ fontSize: "15px" }}>
 {text}
 </div>
 </div>
 );
};

const ChangeStatusModal = ({
 details,
 isModalOpen,
 setModalOpen,
 refresh,
}) => {
 const { user } = useContext(GlobalContext)
 const twoDecimalPlaces = (value) => {
 return Number(value).toFixed(2).toString();
 };
 const [state, setState] = useState({
 tid: "",
 category: "",
 issue: "",
 user_id: "",
 });
 const [newStatus, setNewStatus] = useState('');
 const [remarks, setRemarks] = useState('');

 const issues = [
 {
 label: "Success",
 value: "SUCCESS",
 },
 {
 label: "Failed",
 value: "FAILED",
 },
 {
 label: "Pending",
 value: "PENDING",
 },
 ];

 const changestatus = async () => {
 if (!details) {
 swal("Failed", "Something Went Wrong", "error");
 return;
 }
 console.log(details);
 try {

 var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");

 var raw = JSON.stringify({
 tid: details.tid,
 service: details.service,
 statusValue: newStatus.value,
 adminId: user.pkid,
 remark: remarks,

 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
 `${BASE_URL_APISERVICES}/api/Payment/admin/changeTransactionStatusAdmin`,
 requestOptions
 );
 const data = await res.json();
 if (data.status == "0") {
 swal("Success", "Status Changed", "success");
 setModalOpen(false);
 refresh()
 clearForm();
 } else {
 swal("Failed", "Status Not Changed", "error");
 }

 } catch (error) {
 console.log("error", error);

 }
 };
 const clearForm = () => {
 setNewStatus({});
 setRemarks("")
 };

 const validateForm = () => {
 console.log(state);
 if (
 newStatus == "" ||
 remarks == ""

 ) {
 swal("Invalid", "Please Fill Mandatory Fields", "error");
 return;
 }
 changestatus();
 };

 return (
 <Modal
 show={isModalOpen}
 onHide={() => setModalOpen(false)}
 aria-labelledby="example-modal-sizes-title-md"
 size="md"
 >
 <Modal.Body>
 <div
 style={{
 padding: "12px",
 background: "#FFFFFF",
 color: "#4267B2",
 fontSize: "28px",
 fontFamily: "poppins"
 }}
 className="d-flex justify-content-between"
 >
 Change Status
 <div
 className="px-1 hover"
 onClick={() => {
 setModalOpen(false);
 }}
 >
 {" "}
 X
 </div>
 </div>
 <div className="row">
 <div className="col-12 col-sm-12 col-md-12">
 <Form.Group>
 <Form.Label>
 {" "}
 <span style={{ color: "red" }}>*</span>Select New Status
 </Form.Label>
 <Select
 options={issues}
 value={newStatus}
 onChange={(e) => {
 setNewStatus(e);
 }}
 />
 </Form.Group>
 </div>

 <div className="col-12 col-sm-12 col-md-12">
 <Form.Group>
 <Form.Label>
 {" "}
 <span style={{ color: "red" }}>*</span>RRN
 </Form.Label>
 <Form.Control
 type="text"
 className="form-control"
 aria-label="Notes"
 aria-describedby="basic-addon1"
 placeholder="RRN"
 value={remarks}

 onChange={(e) => {
 setState({ ...state, retailer_commission: e.target.value });
 setRemarks(e.target.value);
 }}
 style={{ borderRadius: "5px" }}
 />
 </Form.Group>
 </div>


 </div>
 <div className="row d-flex justify-content-center">
 <Button className="rounded-100 btn-primary" onClick={validateForm}>
 Change Status
 </Button>
 </div>
 </Modal.Body>
 </Modal>
 );
};

const UpdateTariffPlanModal = ({
 billDetails,
 isModalOpen,
 setModalOpen2,
 refresh
}) => {
 const twoDecimalPlaces = (value) => {
 return Number(value).toFixed(2).toString();
 };
 const [remark, setRemark] = useState("");
 const { user, setTransactionLoaderState } = useContext(GlobalContext);

 const handleRemarkChange = (e) => {
 setRemark(e.target.value);
 };


 console.log(remark);

 const handleConfirm = () => {
 console.log("Remark:", remark);
 vendorPaymentsRefund();
 };

 const vendorPaymentsRefund = async () => {
 try {
 setTransactionLoaderState(true);
 const myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append("Authorization", "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

 var raw = JSON.stringify({
 adminId: user.pkid,
 transactionId: billDetails.id,
 remarks: remark
 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
 `${BASE_URL_APISERVICES}/api/Admin/dmtTransactionRefund`,
 requestOptions
 );
 const data = await res.json();
 if (data.responseCode == "00") {
 swal("Success", data.responseMessage, "success");
 } else {
 swal("Failed", data.responseMessage, "error");
 }

 refresh()

 setTransactionLoaderState(false);
 } catch (error) {
 setTransactionLoaderState(false);
 console.log("error", error);
 }
 };

 return (
 <Modal
 show={isModalOpen}
 onHide={() => setModalOpen2(false)}
 aria-labelledby="example-modal-sizes-title-md"
 size="s"
 >
 <Modal.Body
 style={{ width: "100%", display: "block", overflowY: 'hidden', height: "17rem" }}
 className="account"
 >
 <div
 style={{
 fontFamily: "Poppins",
 fontSize: "28px",
 fontWeight: "600",
 lineHeight: "48px",
 textAlign: "left",
 padding: "0px",
 background: "#FFFFFF",
 color: "#4267B2",
 }}
 className="d-flex justify-content-between pl-2"
 >
 <strong>Are you sure?</strong>
 <strong
 className="px-1 hover"
 onClick={() => {
 setModalOpen2(false);
 }}
 >
 <img
 src={require("../../../assets/images/Vector.png")}
 alt="Close"
 />
 </strong>
 </div>

 <div style={{ padding: "20px" }}>
 <p>Refund amount of {twoDecimalPlaces(billDetails.rch_amount)} to {billDetails.user_code} </p>
 <div className="form-group">
 <label htmlFor="remark">Remark</label>
 <input
 type="text"
 className="form-control"
 id="remark"
 placeholder="Enter your remark"
 value={remark}
 onChange={handleRemarkChange}
 />
 </div>
 <div className="d-flex justify-content-end">
 <button
 className="btn btn-danger mr-2"
 onClick={handleConfirm}
 >
 Confirm
 </button>
 <button
 className="btn btn-secondary"
 onClick={() => setModalOpen2(false)}
 >
 Cancel
 </button>
 </div>
 </div>
 </Modal.Body>
 </Modal>
 );
};

export default DMTHistoryTable;
