import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./app/retailer/App";
import DistributorApp from "./app/distributor/App";
import MasterDistributorApp from "./app/master-distributor/App";
import AdminApp from "./app/admin/App";
import "./i18n";
import "./index.css";
import "./custom.scss";
import { GlobalProvider, GlobalContext } from "./app/Context/GlobalState";
import PrintSlip from "./app/print/PrintSlip";
import Login2 from "./app/user-pages/Login2";
import BusinessDetails from "./app/KYC/BusinessDetails";
import PersonalDetails from "./app/KYC/PersonalDetails";
import DocumentDetails from "./app/KYC/DocumentDetails";
import NomineeDetails from "./app/KYC/NomineeDetails";
import DocumentUpload from "./app/KYC/DocumentUpload";
import UserKycView from "./app/KYC/UserKycView";
import PrivacyPolicy from "./app/company/PrivacyPolicy";
import TermsAndCondition from "./app/company/TermsAndCondition";
import AccountDeletionRequest from "./app/company/AccountDeletionRequest";
import BBPSPrintSlip from "./app/print/BBPSPrint";
import QRPrint from "./app/print/QRPrint";
import CustomerKyc from "./app/retailer/CKYC/CustomerKyc";
import EKYC from "./app/retailer/Agent EKYC/EKYC";
import AEPSPrint from "./app/print/AEPSPrint";
import BBPSPrint3 from "./app/print/BBPSPrint3";
import BBPSPrint2 from "./app/print/BBPSPrint2";
import PrintSlip2 from "./app/print/PrintSlip2";
import AEPSReconcilePrint from "./app/print/AEPSReconcilePrint";
import CertificatePrint from "./app/print/CertificatePrint";
import ConsumerApp from "./app/consumer/App";
import IndemnityBond from "./app/retailer/virtual report/IdentityBond";
import TransactionLoader from "./app/utils/TransactionLoader";
import ForgetPassword from "./app/user-pages/ForgetPassword";
import BiometricKYC from "./app/KYC/BiometricKYC";
import Register from "./app/user-pages/Register";
import PaymentGatewayResponse from "./app/print/PaymentGatewayResponse";
import SendOtp from "./app/utils/firebase/SendOtp";
import Login5 from "./app/user-pages/Login5";
import Register2 from "./app/user-pages/Register2";
import ForgetPassword2 from "./app/user-pages/ForgetPassword2";
import Test from "./app/user-pages/Test";
import PGSlipPrint from "./app/print/PGSlipPrint";
import Developer from "./app/api_partner/Developer2";
import MarcomFormPrint from "./app/print/MarcomDocPrint";
import BillPrint4 from "./app/print/BillPrint4";
import ConcernFormPrint from "./app/retailer/credit-card-kyc/ConcernForm";
import EsignDocument from "./app/company/EsignDocument";
import MarcomDocAdmin from "./app/admin/marcom-doc/MarcomDocAdmin";
import MarcomDocAdminPrint from "./app/admin/marcom-doc/MarcomDocAdminPrint";
import IdentityPrint from "./app/print/IdemnityPrint";
import Test2 from "./app/user-pages/Test2";
import AEPSPrint2 from "./app/print/AEPSPrint2";
import AccountStatementPrint from "./app/print/AccountStatementPrint";
import CMSPrintSlip from "./app/print/CMSPrintSlip";
import cmsRedirect from "./app/print/cmsRedirect";
import AEPSPrint3 from "./app/print/AepsPrint3";
import AccountStatement2 from "./app/print/AccountStatement2";
import CmsRedirect2 from "./app/print/cmsRedirect2";

ReactDOM.render(
  <BrowserRouter>
    <GlobalProvider>
      <GlobalContext.Consumer>
        {(context) => (
          <TransactionLoader
            text="Please Wait..."
            isActive={context.transactionLoader}
          >
            <Switch>
            <Route path={`/pages/cms/print`} component={CMSPrintSlip} />
              <Route path={`/pages/cms`} component={cmsRedirect} />
              <Route path={`/pages/cms2`} component={CmsRedirect2} />
              <Route path={`/pages/payment_gateway_response`} component={PaymentGatewayResponse} />
              <Route path={`/pages/indemnity_bond`} component={IndemnityBond} />
              <Route path={`/pages/certificate`} component={CertificatePrint} />
              <Route path={`/pages/print`} component={PrintSlip} />
              <Route path={`/pages/aeps_print`} component={PrintSlip2} />
              <Route path={`/pages/aeps_reconcile_print`} component={AEPSReconcilePrint} />
              <Route path={`/pages/recharge_print`} component={BBPSPrint2} />
              <Route path={`/pages/bill_print`} component={BBPSPrint3} />
              <Route path={`/pages/bill-print`} component={BillPrint4} />
              <Route path={`/pages/idemnity`} component={IdentityPrint} />
              <Route path={`/pages/qr`} component={QRPrint} />
              <Route path={`/pages/bbps/print`} component={BBPSPrintSlip} />
              <Route path={`/pages/aeps/print`} component={AEPSPrint} />
              <Route path={`/pages/aeps/print2`} component={AEPSPrint3} />
            
              <Route path={`/pages/aeps/yes/print`} component={AEPSPrint2} />
              <Route path={`/pages/account_statement_print`} component={AccountStatementPrint} />

              <Route path={`/pages/pg/Print`} component={PGSlipPrint} />
              <Route path={`/pages/concern_form`} component={ConcernFormPrint} />
              
              <Route path={`/MarcamForm/MarcamFormPrint`} component={MarcomFormPrint} />
              <Route path={`/marcom/marcom-doc-print`} component={MarcomDocAdminPrint} />
              <Route path={`/doc/e-sign`} component={EsignDocument} />
              
              
              <Route path="/admin" component={AdminApp} />
              <Route path="/user" component={ConsumerApp} />
              <Route path="/retailer" component={App} />
              <Route path="/customer-kyc" component={CustomerKyc} />
              <Route path="/agent-kyc" component={EKYC} />
              <Route path="/distributor" component={DistributorApp} />
              <Route exact path="/developer" component={Developer} />
              <Route
                path="/master-distributor"
                component={MasterDistributorApp}
              />
              <Route
                path="/kyc/bussiness-details"
                component={BusinessDetails}
              />
              <Route path="/kyc/personal-details" component={PersonalDetails} />
              <Route path="/kyc/document-details" component={DocumentDetails} />
              <Route path="/kyc/nominee-details" component={NomineeDetails} />
              <Route path="/kyc/document-upload" component={DocumentUpload} />
              <Route path="/kyc/biometric" component={BiometricKYC} />
              <Route path="/kyc/user-kyc-view" component={UserKycView} />
              <Route path="/privacypolicy" component={PrivacyPolicy} />
              <Route path="/account-deletion" component={AccountDeletionRequest} />
              <Route
                path="/terms-and-conditions"
                component={TermsAndCondition}
              />
              <Route exact path="/forget-password" component={ForgetPassword2} />
              <Route path="/register" component={Register2} />
              <Route path="/otp" component={SendOtp} />

              <Route path="/test" component={Test} />
              <Route path="/test2" component={Test2} />
              <Route path="/accountstatement2" component={AccountStatement2} />
              <Route path="/" component={Login5} />
            </Switch>
          </TransactionLoader>
        )}
      </GlobalContext.Consumer>
    </GlobalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
