import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Nav, Tabs, Tab, Form, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import {
  twoDecimalPlaces,
  excelMapping,
  ExportToExcel,
  getDiviceInfo,
  dateFormatter,
  formatDateDDMMYYHHMM,
  now,
} from "../../utils/utilFuctions";
import DatePicker from "react-datepicker";
import { offsetArr } from "../../utils/CommonConfig";

const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

const columns = [
  {
    dataField: "tid",
    text: "Transaction Id ",
  },
  {
    dataField: "service",
    text: "Service",
  },
  {
    dataField: "opening_bal",
    text: "Opening Balance",
  },
  {
    dataField: "amount",
    text: "Transaction Amount",
  },
  {
    dataField: "closing_bal",
    text: "Closing Balance",
  },
  {
    dataField: "Status",
    text: "Status",
  },
  {
    dataField: "date",
    text: "Date",
  },
];

const Transactions = () => {
  useEffect(() => {}, []);

  return (
    <div className="card tab-custom-pills-horizontal m-3 p-0 py-1">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="p-0 m-0">
          <Col md={6} sm={12}>
            <Nav
              variant="pills"
              className="nav-pills-custom justify-content-start p-1 "
              style={{
                border: "1px solid #2F58CD",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="d-flex align-items-center"
                >
                  Payments
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  className="d-flex align-items-center"
                >
                  Refunds
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  className="d-flex align-items-center"
                >
                  Pendings
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  eventKey="fourth"
                  className="d-flex align-items-center"
                >
                  Orders
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>

          <Col xs={12} className="p-0">
            <Tab.Content className="tab-content-custom-pill pb-0">
              <Tab.Pane eventKey="first">
                <TransactionReports />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <RefundReports />{" "}
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <PendingTransactions />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

const TransactionReports = () => {
  const { user } = useContext(GlobalContext);
  let basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  const { username, password } = basicAuth;

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  // MODAL States

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const getTransactionReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_dashboard_transaction_report.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getTransactionReport();
  }, []);
  return (
    <>
      <div className="card m-0 p-0">
        <div className="card-body p-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="row px-4 align-items-center">
              <Form.Group>
                <Form.Label>{`Search`}</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-0"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
              </Form.Group>
              <Form.Group className="mx-2">
                <Form.Label>{`From Date`}</Form.Label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-0 border-primary"
                  placeholderText="Start Date"
                />
              </Form.Group>
              <Form.Group className="mx-2">
                <Form.Label>{`To Date`}</Form.Label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-0 border-primary"
                  placeholderText="Start Date"
                />
              </Form.Group>
              <Form.Group className="mt-4">
                <Button
                  className="rounded-0"
                  variant="success"
                  onClick={() => {}}
                >
                  {"Search"}
                </Button>
              </Form.Group>
              <div
                className="ml-5"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ExportToExcel(
                    `Payment_report_${now()}`,
                    excelMapping(data, columns)
                  );
                }}
              >
                <img
                  src={require("../../../assets/images/excel.png")}
                  style={{ width: "1.6rem" }}
                />
                <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
              </div>
            </div>
          </div>

          <div
            className="row w-100 m-0 p-0"
            style={{
              background: "#FFFFFF",
            }}
          >
            <div className="scroll-inner dashboard-fix-width">
              <table
                style={{ width: "100%" }}
                className="dashboard-table-style"
              >
                <tr>
                  <th className="text-center">Transaction Id </th>
                  <th className="text-center">Service </th>
                  <th className="text-center">Opening Balance</th>
                  <th className="text-center">Transaction Amount</th>
                  <th className="text-center">Closing Balance</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Date</th>
                </tr>
                {dataToDisplay.map((e, ind) => (
                  <tr>
                    <td style={{ fontSize: "0.8rem" }}>
                      {" "}
                      <div className="d-flex flex-column align-items-center text-center">
                        <span className="text-center">{e.tid}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{e.service}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{twoDecimalPlaces(e.opening_bal)}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{twoDecimalPlaces(e.amount)}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{twoDecimalPlaces(e.closing_bal)}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span
                          className={`badge badge-${
                            e.Status == "Success" ? "success" : "warning"
                          } badge-pill p-2`}
                        >
                          {e.Status}
                        </span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span className="">{formatDateDDMMYYHHMM(e.date)}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>

            <Pagination
              page={currentPage}
              limit={pages}
              callback={(page) => {
                setCurrentPage(page);
              }}
              count={dataToDisplay.length}
              total={total}
              callback2={(offsetValue) => {
                setOffset(offsetValue);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const RefundReports = () => {
  const { user } = useContext(GlobalContext);
  let basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  const { username, password } = basicAuth;

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  // MODAL States

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const getTransactionReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        fromdate: "2022-01-01",
        todate: "2023-04-04",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_dashboard_transaction_report.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(
          data.data.filter((e) => {
            return e.Status == "Refunded";
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getTransactionReport();
  }, []);
  return (
    <>
      <div className="card m-0 p-0">
        <div className="card-body m-0 p-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="row px-4 align-items-center">
              <Form.Group>
                <Form.Label>{`Search`}</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-0"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
              </Form.Group>
              <Form.Group className="mx-2">
                <Form.Label>{`From Date`}</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-0 border-primary"
                  placeholderText="Start Date"
                  dateFormat="dd/MM/yyyy"
                />
              </Form.Group>
              <Form.Group className="mx-2">
                <Form.Label>{`To Date`}</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-0 border-primary"
                  placeholderText="End Date"
                  dateFormat="dd/MM/yyyy"
                />
              </Form.Group>
              <Form.Group className="mt-4">
                <Button
                  className="rounded-0"
                  variant="success"
                  onClick={() => {}}
                >
                  {"Search"}
                </Button>
              </Form.Group>
              <div
                className="ml-5"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // ExportToExcel(
                  //   `Virtual_report_${now()}`,
                  //   excelMapping(data, columns)
                  // );
                }}
              >
                <img
                  src={require("../../../assets/images/excel.png")}
                  style={{ width: "1.6rem" }}
                />
                <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
              </div>
            </div>
          </div>

          <div
            className="row w-100 m-0"
            style={{
              background: "#FFFFFF",
            }}
          >
            <div className="scroll-inner dashboard-fix-width">
              <table
                style={{ width: "100%" }}
                className="dashboard-table-style"
              >
                <tr>
                  <th className="text-center">Transaction Id </th>
                  <th className="text-center">Service </th>
                  <th className="text-center">Opening Balance</th>
                  <th className="text-center">Transactin Amount</th>
                  <th className="text-center">Closing Balance</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Date</th>
                </tr>
                {dataToDisplay.map((e, ind) => (
                  <tr>
                    <td style={{ fontSize: "0.8rem" }}>
                      {" "}
                      <div className="d-flex flex-column align-items-center text-center">
                        <span className="text-center">{e.tid}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{e.service}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{twoDecimalPlaces(e.opening_bal)}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{twoDecimalPlaces(e.amount)}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span>{twoDecimalPlaces(e.closing_bal)}</span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span
                          className={`badge badge-${
                            e.Status == "Success" ? "success" : "warning"
                          } badge-pill p-2 text-light`}
                        >
                          {e.Status}
                        </span>
                      </div>
                    </td>
                    <td style={{ fontSize: "0.8rem" }}>
                      <div className="d-flex flex-column align-items-center">
                        <span className="">{e.date}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>

            <Pagination
              page={currentPage}
              limit={pages}
              callback={(page) => {
                setCurrentPage(page);
              }}
              count={dataToDisplay.length}
              total={total}
              callback2={(offsetValue) => {
                setOffset(offsetValue);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PendingTransactions = () => {
  const { user } = useContext(GlobalContext)
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  // MODAL States

  const getAllVirtualPayments = async (agentid) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ=="
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        agentcode: agentid,
        pageIndex: 0,
        pageSize: 100,
        fromDate: "2022-01-03T19:24:22.349Z",
        toDate: "2023-10-03T19:24:22.349Z",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Report/GetPendingTransactions`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        setData(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendOTP = async (details) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("dmt", "refundOtp");
      urlencoded.append("rrn", details.sorTxnId);
      urlencoded.append("mobileno", details.customerMob);
      urlencoded.append("orderId", details.orderId);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      const res = await fetch(
        "https://api.bumppypay.com/DmtApiMulti.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status) {
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getStatusBadge = (status) => {
    if (status && status.toString().toLowerCase() == "failed") {
      return (
        <span className="badge badge-danger badge-pill p-2">{status}</span>
      );
    } else if (
      status &&
      status.toString().toLowerCase() == "settlement completed"
    ) {
      return (
        <span className="badge badge-success badge-pill p-2"> {status}</span>
      );
    } else if (
      status &&
      (status.toString().toLowerCase() == "accepted" ||
        (status && status.toString().toLowerCase() == "received"))
    ) {
      return (
        <span className="badge badge-warning badge-pill p-2"> {status}</span>
      );
    } else {
      return <span className="badge badge-info badge-pill p-2"> {status}</span>;
    }
  };


  const getPaynnowAgentId = async () => {

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/get_paynnow_agentid.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.yesbank_kyc == "Y") {
          getAllVirtualPayments(data.agentid);
        }
      }
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  useEffect(() => {
    getPaynnowAgentId();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12 stretch-card">
        <div className="card m-0 p-0">
          <div className="card-body m-0 p-0">
            <div className="tab-custom-pills-horizontal">
              <div className="d-flex w-100  m-0 row justify-content-between align-items-center">
                <div>
                  <h3> Transactions</h3>
                </div>
                <div>
                  <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
                    <Form.Control
                      type="text"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      value={searchText}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row w-100 p-0 m-0">
                <div className="scroll-inner dashboard-fix-width">
                  <table
                    style={{ width: "100%" }}
                    className="dashboard-table-style"
                  >
                    <tr>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Sr"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Ref"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Name"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Account Number"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Amount"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        {"Date and Time"}
                      </th>
                      <th
                        className="text-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {"Action"}
                      </th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                      <tr>
                        <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
                        <td style={{ fontSize: "0.8rem" }}>
                          {" "}
                          <div className="d-flex flex-column align-items-center text-center">
                            <span className="text-center">{e.txnId}</span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            <span>{e.beneName}</span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            <span>{e.beneAccountNo}</span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            <span>{e.amount}</span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex flex-column align-items-center">
                            <span>{formatDateDDMMYYHHMM(e.createdOn)}</span>
                          </div>
                        </td>
                        <td style={{ fontSize: "0.8rem" }}>
                          <div className="d-flex align-items-center justify-content-center">
                            <button className="btn btn-sm btn-primary" onClick={()=>{
                              sendOTP(e)
                            }}>
                              Send OTP
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <Pagination
                  page={currentPage}
                  limit={pages}
                  callback={(page) => {
                    setCurrentPage(page);
                  }}
                  count={dataToDisplay.length}
                  total={total}
                  callback2={(offsetValue) => {
                    setOffset(offsetValue);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex">
        <select
          className="form-control"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default Transactions;
