import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  generateHashKey2,
} from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";

export const RaiseTicketModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    tid: "",
    category: "",
    issue: "",
    user_id: "",
  });
  const issues = [
    {
      label: "Trasaction Failed but wallet deducted",
      value: "Trasaction Failed but wallet deducted",
    },
    {
      label: "Trasaction Success but wallet deducted",
      value: "Trasaction Success but wallet deducted",
    },
  ];

  const raiseTicket = async () => {
    const { category, issue, tid, user_id } = state;
    const raw = "";
    const hash_key = await generateHashKey2(user_id);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${BASE_URL_API_TRANXT}/apiget.aspx?method=ticket&TransactionId=${tid}&TransactionType=${category}&MailMessage=${issue.value}&RetailorID=${user_id}`,
      requestOptions
    );
    const result = await res.json();

    swal("Success", "Ticket Raised Successfully", "success");
  };
  const clearForm = () => {
    setState({
      tid: "",
      category: "",
      issue: "",
      user_id: "",
    });
  };

  const validateForm = () => {
    console.log(state);
    if (
      state.category == "" ||
      state.issue == "" ||
      state.tid == "" ||
      state.user_id == ""
    ) {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    raiseTicket();
  };
  console.log(details);
  useEffect(() => {
    if (details) {
      setState({
        ...state,
        category: details.category,
        tid: details.tid,
        user_id: details.user_id,
      });
    }
  }, [details]);
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="lg"
    >
      <Modal.Body>
        <div
          style={{
            padding: "12px",
            background: "#3676D8",
            color: "#FFF",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong> Raise Ticket</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Transaction ID
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Transaction ID"
                disabled={true}
                value={state.tid}
                onChange={(e) => {
                  //  setState({ ...state, from_amount: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>User
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter User Id"
                disabled={true}
                value={state.user_id}
                onChange={(e) => {
                  // setState({ ...state, to_amount: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Category
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Category"
                value={state.category}
                disabled={true}
                onChange={(e) => {
                  //  setState({ ...state, retailer_commission: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col-12 col-sm-6 col-md-4">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Issue
              </Form.Label>
              <Select
                options={issues}
                value={state.issue}
                onChange={(e) => {
                  setState({ ...state, issue: e });
                }}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mx-1">
          <Button className="rounded-0 btn-success" onClick={validateForm}>
            Raise Ticket
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
