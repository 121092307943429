import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
 ExportToExcel,
 excelMapping,
 formatDateDDMMYYHHMM,
 generateHashKey2,
} from "../../utils/utilFuctions";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export const columns = [
 {
 dataField: "date_time",
 text: "Date & Time",
 color: "#000",
 },
 {
 dataField: "Narration",
 text: "Narration",
 color: "#000",
 },
 {
 dataField: "Reference",
 text: "Reference",
 color: "#000",
 },
 {
 dataField: "Opening_Balance",
 text: "Opening Balance",
 color: "#000",
 },
 {
 dataField: "Credit",
 text: "Credit",
 color: "#000",
 },
 {
 dataField: "Debit",
 text: "Debit",
 color: "#000",
 },
 {
 dataField: "Closing_Balance",
 text: "Closing Balance",
 color: "#000",
 },
 {
 dataField: "fee",
 text: "Fee",
 color: "#000",
 },
 {
 dataField: "commission",
 text: "Commission",
 color: "#000",
 },
 {
 dataField: "tds",
 text: "Tds",
 color: "#000",
 },
];

const LedgerReportTable = () => {
 const [data, setData] = useState([]);
 const [currentPage, setCurrentPage] = useState(1);
 const [searchText, setSearchText] = useState("");
 const [offset, setOffset] = useState(10);
 const [dataToDisplay, setDataToDisplay] = useState([]);
 const [pages, setPages] = useState(0);
 const [total, setTotal] = useState(0);
 const [startDate, setStartDate] = useState(new Date());
 const [endDate, setEndDate] = useState(new Date());
 const { user } = useContext(GlobalContext);
 console.log(user);

 function getDatePreviousMonth(date = new Date()) {
 date.setMonth(date.getMonth() - 1);
 return date;
 }
 const dateFormatter = (date) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };
 const now = (date = new Date()) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };
 const fetchLedgerReport = async (
 startDate = new Date(),
 endDate = new Date()
 ) => {
 const start = dateFormatter(startDate);
 const end = dateFormatter(endDate);
 try {
 const raw = "";
 const hash_key = await generateHashKey2(user.ReferId);
 var myHeaders = new Headers();
 myHeaders.append("HASH_KEY", hash_key);
 myHeaders.append("Content-Type", "application/json");
 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
 `${BASE_URL_API_TRANXT}/apiget.aspx?method=new_getLedgerReport&fromdate=${start}&todate=${end}&lipaddress=0&Status=0&UserID=${user.ReferId}`,
 requestOptions
 );
 const data = await res.json();
 setData(data);
 } catch (error) {
 console.log("error", error);
 }
 };

 const fetchData = () => {
 fetchLedgerReport(startDate, endDate);
 };

 const openInNewTab = () => {
 const newWindow = window.open(
 `${CLIENT}/pages/print?id=090422191784660&type=DMT`,
 "",
 "height=600,width=800'"
 );
 if (newWindow) newWindow.opener = null;
 };

 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
 setTotal(filteredData.length);
 const startIdx = 0;
 const endIdx = offset - 1;
 filteredData = filteredData.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 setCurrentPage(1);
 }, [searchText]);

 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 console.log(filteredData);
 const startIdx = (currentPage - 1) * offset;
 const endIdx = currentPage * offset - 1;
 console.log({ startIdx, endIdx });
 filteredData = filteredData.filter((bank, idx) => {
 console.log(idx, idx <= endIdx && idx >= startIdx);
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 console.log({ startIdx, endIdx });
 }, [currentPage]);

 // did mount
 useEffect(() => {
 setPages(Math.floor((data.length + (offset - 1)) / offset));
 const startIdx = 0;
 const endIdx = offset - 1;
 setTotal(data.length);
 console.log(startIdx);
 console.log(data);
 const filteredData = data.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 console.log(filteredData);
 setDataToDisplay(filteredData);
 }, [data, offset]);

 // did mount
 useEffect(() => {
 fetchLedgerReport();
 }, []);

 const searchBarStyle = {
 display: 'flex',
 alignItems: 'center',
 border: '1px solid #ccc',
 borderRadius: '6px',
 padding: '0px',
 backgroundColor: '#fff',
 width: '15%', 
 height: '20%',

 };
 const inputStyle = {
 border: 'none',
 outline: 'none',
 backgroundColor: 'transparent',
 fontSize: '16px',
 marginLeft: '8px',
 width: '100%',
 "fontFamily": "Poppins",
 "fontWeight": "400",
 };
 const iconStyle = {
 color: 'rgba(0, 0, 0, 0.5)',
 };

 const positionStyle = {
 position: 'absolute',
 top: -27,
 left: 0,
 "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
 };
 const positionStyle1 = {
 position: 'absolute',
 top: -38,
 left: 0,
 "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
 };
 return (
 <>
 <div
 style={{
 background: " white",
 height: "Auto",
 width: "100%",
 borderRadius: "13px",
 boxShadow: DROP_SHADOW,
 marginTop: "15px",
 }}
 >

 <div className="row m-0 p-0 pt-1 align-items-center">
 <div className="col-3 m-0 p-0">
 <div
 className="m-0 p-0 pl-3 align-top"
 style={{
 fontFamily: "Poppins",
 fontWeight: "600",
 lineHeight: "48px",
 letterSpacing: "0em",
 textAlign: "left",
 padding: "0px",
 background: "#FFFFFF",
 color: "#4267B2",
 fontWeight: "bolder",
 fontfamily: "Poppins !impotent",
 fontSize: "28px",
 }}
 >
 Ledger History
 </div>
 </div>

 <div className="col-9 m-0 p-0 ">
 <div className="row m-0 p-0 justify-content-end">
 <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
 <FontAwesomeIcon
 icon={faSearch}
 style={iconStyle}
 className="ml-2"
 />
 <input
 type="text"
 className="form-control "
 placeholder="Search"
 aria-label="Search"
 style={inputStyle}
 onChange={(e) => {
 setSearchText(e.target.value);
 }}
 value={searchText}
 />
 </div>

 <div
 className="pr-1 pl-2 p-0 m-0"
 style={{ width: "18%", height: "20%" }}
 >
 <Form.Group className="p-0 m-0">
 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={startDate}
 onChange={(date) => setStartDate(date)}
 className="form-control rounded-100"
 placeholderText="Start Date"
 />
 </Form.Group>
 </div>
 <div
 className="px-1 p-0 m-0"
 style={{ width: "18%", height: "20%" }}
 >
 <Form.Group className="p-0 m-0">
 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={endDate}
 onChange={(date) => setEndDate(date)}
 className="form-control rounded-100"
 placeholderText="End Date"
 />
 </Form.Group>
 </div>

 <div
 className="pl-1 p-0 m-0"
 style={{ width: "14%", height: "20%" }}
 >
 <Form.Group className="mt-0 p-0 m-0">
 <Button
 className=" btn btn-primary btn-lg btn-block "
 style={{
 background: "#4267B2",
 color: "white"
 }}
 onClick={fetchData}
 >
 {"Search"}
 </Button>
 </Form.Group>
 </div>

 <div
 className="m-0 p-0 hover-container"
 style={{ cursor: "pointer" }}
 onClick={() => {
 ExportToExcel(
 `Ledger_Report_${now()}`,
 excelMapping(dataToDisplay, columns)
 );
 }}
 >
 <img
 className="pl-1 p-0"
 src={require("../../../assets/images/download Icon.png")}
 style={{ width: `3.5rem`, height: "85%" }}
 />
 <div style={positionStyle1} className="hover-text bg-dark ">
 Export to Excel
 </div>
 </div>
 </div>
 </div>
 </div>

 <div style={{ width: "100%" }}>
 <div style={{ alignItems: "center" }}>
 <div style={{ flex: "1" }}></div>
 </div>
 </div>
 <div className="scroll-inner ">
 <table
 className="dashboard-table-style"
 style={{ width: "100%", backgroundColor: "white" }}
 >
 <tr>
 <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Date & Time
 </th>
 <th
 className="text-left"
 style={{ fontSize: "0.9rem", width: "10px !important" }}
 >
 Narration
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Reference
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Opening Balance
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Credit
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Debit
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Fee
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Commission
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Tds
 </th>
 <th className="text-center" style={{ fontSize: "0.9rem" }}>
 Closing Balance
 </th>
 </tr>
 {dataToDisplay.map((e, ind) => (
 <tr>
 <td style={{ fontSize: "0.8rem", backgroundColor: "white" }}>
 {" "}
 {ind + 1}
 </td>
 <td style={{ fontSize: ".8rem" }}>
 {formatDateDDMMYYHHMM(e.date_time)}
 </td>
 <td
 className="text-left"
 style={{ fontSize: ".8rem", width: "25px" }}
 >
 {e.Narration}
 </td>
 <td style={{ fontSize: ".8rem" }}>{e.Reference}</td>
 <td style={{ fontSize: ".8rem" }}>{e.Opening_Balance}</td>
 <td style={{ fontSize: ".8rem" }}>
 <div className="m-0 p-0">
 <span
 style={{
 color: 'rgba(43, 193, 85, 1)',
 }} className="px-2 py-2"
 >
 {`${e.Credit}`}
 </span>
 </div>
 </td>
 <td style={{ fontSize: ".8rem" }}>
 <div className="m-0 p-0">
 
 <span
 className="m-0 p-0 px-2 py-2"
 style={{
 color: 'rgba(255, 46, 46, 1)',
 }}
 >
 {`${e.Debit}`}
 </span>
 </div>
 </td>
 <td style={{ fontSize: ".8rem" }}>{e.fee}</td>
 <td style={{ fontSize: ".8rem" }}>{e.commission}</td>
 <td style={{ fontSize: ".8rem" }}>{e.tds}</td>
 <td style={{ fontSize: ".8rem" }}>{e.Closing_Balance}</td>
 </tr>
 ))}
 </table>
 </div>
 <Pagination
 page={currentPage}
 limit={pages}
 callback={(page) => {
 setCurrentPage(page);
 }}
 count={dataToDisplay.length}
 total={total}
 callback2={(offsetValue) => {
 setOffset(offsetValue);
 }}
 />
 </div>
 </>
 );
};

export const Pagination = ({
 page,
 limit,
 callback,
 total,
 count,
 callback2,
}) => {
 console.table({ page, limit, callback });
 return (
 <div
 className="row"
 style={{
 display: "flex",
 alignItems: "end",
 width: "100%",
 margin: "10px 0px",
 justifyContent: "space-between",
 }}
 >
 <div
 className="d-flex"
 style={{
 border: "1px solid",

 marginTop: " 5px",
 }}
 >
 <select
 className="form-control"
 aria-label="offset"
 onChange={(e) => {
 callback2(e.target.value);
 }}
 >
 {offsetArr.map((offset) => (
 <option value={offset}>{offset}</option>
 ))}
 </select>
 {page - 2 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}
 variant="light"
 >
 {"Previous"}
 </Button>
 ) : null}

 {page - 1 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}
 variant="light"
 >
 {" "}
 {(page - 1).toString()}
 </Button>
 ) : null}
 <Button variant="primary m-0 " style={{ borderRadius: "0px" }}>
 {" "}
 {page.toString()}
 </Button>
 {page + 1 <= limit ? (
 <Button
 onClick={() => {
 callback(page + 1);
 }}
 variant="light"
 >
 {"Next"}
 </Button>
 ) : null}
 </div>
 <div>
 <strong>{`Showing ${count} of ${total} entries`}</strong>
 </div>
 </div>
 );
};

export default LedgerReportTable;