import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  formatDateDDMMYYHHMM,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_APISERVICES,
} from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const columns = [
  {
    dataField: "rch_transaction_id",
    text: "TXN ID",
  },
  // {
  //   dataField: "bank_rrn",
  //   text: "Bank RRN",
  // },
  // {
  //   dataField: "tid",
  //   text: "REF",
  // },
  {
    dataField: "rch_indate",
    text: "Date",
  },
  {
    dataField: "user_code",
    text: "User Code",
  },
  {
    dataField: "user_name",
    text: "User Name",
  },
  {
    dataField: "rch_account_no",
    text: "Account Number",
  },
  {
    dataField: "rch_ifsc_code",
    text: "IFSC Code",
  },
  {
    dataField: "rch_before_balance",
    text: "Opening Balance",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_after_balance",
    text: "Closing Balance",
  },
  {
    dataField: "rch_com_cnf",
    text: "Master Comm",
  },
  {
    dataField: "rch_com_distributer",
    text: "Dist Comm",
  },
  {
    dataField: "rch_com_retailer",
    text: "Retailer Comm",
  },
  {
    dataField: "sales_user_code",
    text: "Sales Code",
  },
  {
    dataField: "sales_user_name",
    text: "Sales Name",
  },
  {
    dataField: "sales_user_mobile",
    text: "Sales Mobile Number",
  },
  {
    dataField: "status",
    text: "Status",
  },
  // {
  //   dataField: "shop_pin_code",
  //   text: "Pin Code",
  // },
  // {
  //   dataField: "shop_city",
  //   text: "City",
  // },
  // {
  //   dataField: "shop_state",
  //   text: "State",
  // },
];

const BulkSalaryRecordAdd = () => {
    const history = useHistory();
  const [pageAccess, setPageAccess] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const fetchPGSettlementReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        id: "string",
        status: "string",
        type: "string",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllEmployeeSalaryRecords`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const proccessSalary = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        id: id,
        status: "Y",
        type: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/proccessEmployeeSalary`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Salary Processed Successfully", "", "success");
        fetchPGSettlementReport();
        //  setData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refundVerification = (id, amount, name) => {
    swal({
      title: "Are you sure?",
      text: `Salary of  ${amount} will be credited to ${name}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        proccessSalary(id);
      }
    });
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `${CLIENT}/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "35%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const positionStyle = {
    position: "absolute",
    top: -27,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const getPageAccess = (menuId) => {
    const sub_menus_access_info = JSON.parse(
      sessionStorage.getItem("sub_menus")
    );
    sub_menus_access_info.forEach((acc) => {
      if (acc.pk_menu_id == menuId) {
        setPageAccess(acc);
      }
    });
  };

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    employeeId: "",
    month: "",
    category: "",
    amount: "",
  });
  const months = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const categories = ["Salary", "Bonus", "Expenses"];
  const openModal = (rowData) => {
    setSelectedRowData(rowData);
    setShowModal(true);
    setFormData({
      employeeId: rowData.employee_name,
      month: rowData.month,
      amount: parseFloat(rowData.amount).toFixed(2),
      category: rowData.category,
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const EDITSALARY = async () => {
    try {
      if (!selectedRowData || !selectedRowData.id) {
        console.error("ID is not available.");
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: selectedRowData.id,
        employeeId: "12",
        month: formData.month,
        category: formData.category,
        amount: formData.amount,
        status: "N",
        step: "2",
        adminId: "9",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/addEmployeeSalaryRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        setShowModal(false);
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  const [selectedIds, setSelectedIds] = useState([]);
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);
  const handleCheckboxChange = (id) => {
    const updatedSelectedIds = [...selectedIds];
    const index = updatedSelectedIds.indexOf(id);
  
    if (index === -1) {
      updatedSelectedIds.push(id);
    } else {
      updatedSelectedIds.splice(index, 1);
    }
  
    setSelectedIds(updatedSelectedIds);
  };
  const handleSelectAllChange = () => {
    const allIds = data.map((e) => e.id);
    const updatedSelectedIds = selectedIds.length === allIds.length ? [] : allIds;
    setSelectedIds(updatedSelectedIds);
  };
  const handleProcessAllClick = () => {
    if (selectedIds.length === 0) {
     swal("Please select at least one item to process.", "", "warning");
    } else {
      setShowProcessAllModal(true);
    }
  };
  
  useEffect(() => {
    fetchPGSettlementReport();
    getPageAccess(14);
  }, []);

  

  return (
    <div>
      <div className="row mt-3 p-0  pt-1 align-items-center">
        <div className="col-8 m-0 p-0 ">
          <div className="row  m-0 p-0  justify-content-end">
            <div style={searchBarStyle} className=" m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div
              className="m-0 p-0 hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `Credit_card_bill_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1"
                alt=""
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div
                div
                style={{ color: "rgba(255, 255,255, 1)" }}
                className="hover-text bg-dark "
              >
                Export to Excel
              </div>
            </div>
            <div className="">
            {" "}
            <Link to="/admin/process_salary">
              <Button  style={{ height: "2.5rem" }}
               onClick={() => {
                    history.push("/admin/process_salary");
                    // toggleOffcanvas();
                  }}>Back</Button>
            </Link>
            <Link to="/admin/process_salary">
              <Button  style={{ height: "2.5rem" }}
               onClick={() => {
                    history.push("/admin/PGREPORT");
                    // toggleOffcanvas();
                  }}>PGREPORT</Button>
            </Link>
          </div>
          </div>

          
        </div>
      </div>
      <div className="m-2 d-flex justify-content-end">
            <Button onClick={handleProcessAllClick}>Process All</Button></div>  
      <hr className="m-0 p-0" />

      <div style={{ width: "100%" }}>
        <div style={{ alignItems: "center" }}>
          <div style={{ flex: "1" }}></div>
        </div>
      </div>
      <div className=" scroll-inner admin-fix-width borderless-table ">
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
          <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={handleSelectAllChange}
                      checked={selectedIds.length === data.length}
                    /></th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Mobile Number
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Account Number
            </th>

            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Amount
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Month
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              date
            </th>
           

            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Status
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
            <td
                      className="text-center border-bottom"
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(e.id)}
                        checked={selectedIds.includes(e.id)}
                      />
                    </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                {ind + 1}
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.employee_name}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.employee_mobile}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.acc_no}</div>
                <div>{e.ifsc_code}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {twoDecimalPlaces(e.amount)}
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.month}</div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>{e.insert_date}</div>
              </td>
             
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {e.status.toString() == "Y" ? (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(43, 193, 85, 0.1)",
                      color: "rgba(43, 193, 85, 1)",
                      bordercolor: "rgba(43, 193, 85, 1)",
                    }}
                  >
                    {"Processed"}
                  </label>
                ) : (
                  <label
                    style={{
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: "rgba(254, 128, 36, 0.1)",
                      color: "rgba(254, 128, 36, 1)",
                      bordercolor: "rgba(254, 128, 36, 1)",
                    }}
                  >
                    {"Pending"}
                  </label>
                )}
              </td>{" "}
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {e.rch_recharge_date}
              </td>
              <td
                className="text-center m-0 p-0 pr-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                {pageAccess.str_access_rights == 1 && (
                  <div className="row m-0 p-0 ">
                    {e.status == "N" && (
                      <Button
                        style={{
                          // paddingLeft:"3px",
                          // padding: "5px",
                          borderRadius: "5px",
                          border: "1px none",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          color: "rgba(255, 164, 27, 1)",
                          bordercolor: "rgba(255, 255, 255, 1)",
                        }}
                        className="btn btn-sm  rounded-100 m-0 p-0"
                        onClick={() => {
                          refundVerification(e.id, e.amount, e.employee_name);
                        }}
                      >
                        {" "}
                        <div className="hover-container">
                          {/* Your content here */}
                          <img
                            src={require("../../../assets/images/Raise Ticket.png")}
                            style={{ width: "" }}
                          />

                          <div
                            style={positionStyle1}
                            className="hover-text bg-dark"
                          >
                            {" "}
                            Proccess Salary
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </table>
        
      </div>
      <Modal show={showProcessAllModal} onHide={() => setShowProcessAllModal(false)}>
          <Modal.Header closeButton>
              <Modal.Title>Select </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
              <Form.Group className="col" controlId="monthDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {" Select Month"}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="month"
                      value={formData.month}
                      onChange={handleChange}
                    >
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col" controlId="categoryDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {` Select category`}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
              </Form>
            </Modal.Body>
         </Modal>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default BulkSalaryRecordAdd;
