import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

const InvoiceScreen = () => {
  return (
    <Container className="d-class1  mt-4">
      <Row>
        <Col lg={4} md={4} sm={4}>
          <img
          src={require('../../../assets/images/invoice.png')}
            alt="image"
            style={{ width: "90%", height: "auto" }}
          />
        </Col>
        <Col lg={8} md={8} sm={8}>
          <div className="mt-4">
            <h2 style={{ color: "#3A98B9", fontFamily: "Poppins" }}>
              <strong>Invoices</strong>
            </h2>
            <h3 style={{ fontFamily: "Poppins" }}>
              Create & send GST Complaint & International invoice your customers
              can pay online instanly.
            </h3>
            <div
              className="d-flex py-4"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="d-flex "
                style={{ flexDirection: "column", fontFamily: "Poppins" }}
              >
                <strong className="mb-3">GST Complaint</strong>
                <strong className="mb-3">Partial Payments</strong>
                <strong>Download Option</strong>
              </div>
              <div
                className="d-flex"
                style={{ alignSelf: "flex-end", fontFamily: "Poppins" }}
              >
                <Button>GET Started</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InvoiceScreen;
