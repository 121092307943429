import React, { useState } from "react";
import BBPSReportTable from "./BBPSReportTable";
import './MyCss.css'; 
const RetailerBBPSReport = ({agentId}) => {
  return (
    <div style={{ margin: "10px" }}>
 
      <BBPSReportTable agentId={agentId} />
    </div>
  );
};

export default RetailerBBPSReport;
