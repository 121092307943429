import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },

  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "remitterno",
    text: "Remitter No",
  },
  {
    dataField: "rch_mobile",
    text: "Beneficiary",
  },
  {
    dataField: "utnno",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "dmt_comission",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];
const ps = [
  { label: "ICICI", value: "4" },
  { label: "YES BANK", value: "3" },
];

const tariffType = [
  { label: "Fixed", value: "F" },
  { label: "Percentage", value: "P" },
];

const ViewTariffPlansTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user , setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [loadDetails, setLoadDetails] = useState(null);
  const [editTariff, setEditTariff] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        service: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_tariff_plans.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.error("error", error);
    }
  };

  useEffect(() => {
    getDMTReport();
  }, []);



  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '100%', // Adjust the width as needed
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };
  return (
    <>

     <div className="row align-items-center m-0 p-0 pb-1 ">
              <div className="col-7 m-0 p-0 ">

              <div
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                   

                  }}
                >
                View Tariff Plan
                </div>

               


      <CreateTariffPlanModal
        details={loadDetails}
        isModalOpen={modalOpen}
        setModalOpen={(modalState) => {
          setModalOpen(modalState);
        }}
        refresh={getDMTReport}
      />
      <UpdateTariffPlanModal
        details={editTariff}
        isModalOpen={modalOpen2}
        setModalOpen={(modalState) => {
          setModalOpen2(modalState);
        }}
        refresh={getDMTReport}
      />

      </div>     
  
      <div className="col-5 m-0 p-0">
        <div className="row col-12 justify-content-end m-0 p-0 ">
      <div className="col-8 p-0 m-0 pl-3 pr-2">
                <div style={searchBarStyle} className="bg-light">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control pl-2"
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
      </div>

      <div className="col-4 m-0 p-0 "  style={{paddingLeft:"25px",}}>
       
          <Button style={{backgroundColor:"#0074D9",}}
            onClick={() => {
              setModalOpen(true);
            }}
            className="rounded-50 btn btn-primary btn-lg btn-block "
          >
            Create Tariff Plan
          </Button>
       
      </div>
      </div>
      </div>
      </div>
      <hr className="m-0 p-0 " / >
<div className="m-0 p-0">      

</div>
      <div className="my-table scroll-inner admin-fix-width transparent-table">
        <table  className="my-table transparent-table text-center border-bottom justify-content-around ">
          <tr  className="justify-content-around borderless-table text-center border-bottom">
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Sr"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Tariff Code"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"From"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"To"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> <div> {`Retailer `}</div> <div> {` Commision`}</div> </th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> <div> {`Distributor `}</div> <div> {`Commision`}</div> </th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> <div> {`Master `}</div> <div> {` Commision`}</div> </th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> <div> {`Admin  `}</div> <div> {` Commision`}</div> </th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Action"}</th>
            
          </tr>
         
          {dataToDisplay.map((e, ind) => (
            
            <tr  className="my-table transparent-table border-bottom py-5">
              
              <td  className="text-center" style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}> 
              {" "}
              <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
              <span>{ind + 1}</span>
              </div>
              </td>

              <td className="text-center" style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3">
                  <span>{`${e.tariff_name} [${e.tariff_code}]`}</span>
                </div>
              </td>
              
              <td className="text-center" style={{ "fontFamily":"Poppins",fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3">
                  <span>{e.from_amt}</span>
                </div>
              </td>

              <td className="text-center" style={{ "fontFamily":"Poppins",fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3">
                  <span className="text-center">{e.to_amt}</span>
                </div>
              </td>

              <td className="text-center" style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3">
                  <span>{e.tf_ret_comm}</span>
                </div>
              </td>

              <td className="text-center" style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3">
                  <span>{e.tf_dist_comm}</span>
                </div>
              </td>

              <td className="text-center" style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3">
                  <span>{e.tf_cnf_comm}</span>
                </div>
              </td>

              <td className="text-center" style={{ "fontFamily":"Poppins",fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3">
                  <span>{e.tf_admin_comm}</span>
                </div>
              </td>

              <td style={{ "fontFamily":"Poppins", fontSize: "0.8rem" }} className="d-flex flex-column align-items-center py-3">
                <div className="d-flex flex-row align-items-center font-weight-normal">
                  <span
                    className="hover-container text-primary hover-2 p-1 rounded-2"
                    onClick={() => {
                      setEditTariff(e);
                      setModalOpen2(true);
                    }}
                  >
                     <img
                   src={require("../../../assets/images/Edit Details.png")}
                   style={{ width: "" }}
                   />
                   <span style={{"fontFamily":"Poppins",'fontsize': '20px',  color:'rgba(0, 0, 0, 1)', }} className="hover-text pl-3">Edit</span>
                    {/* <i className="mdi mdi-pencil"></i> */}
                  </span>
                 
                 
                 
                  {/* <span
                    className=" hover-container text-primary hover-2 p-1 rounded-2 font-weight-normal"
                    onClick={() => {
                      // setEditTariff(e);
                      //  setModalOpen2(true);
                    }}
                  >
                     <img
                   src={require("../../../assets/images/Delete.png")}
                   style={{ width: "" }}
                   />
                   <span style={{"fontFamily":"Poppins",'fontsize': '20px',  color:'rgba(0, 0, 0, 1)', }} className="hover-text pl-2">Delete</span>
                   
                  </span> */}



                  
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const CreateTariffPlanModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    tariff_id: "",
    service_id: "",
    provider_id: "",
    route_id: "",
    from_amount: "",
    to_amount: "",
    retailer_commission: "",
    distributor_commission: "",
    cnf_commission: "",
    admin_commission: "",
    company_charges: "",
    other_charges: "",
    tf_type: "",
  });

  const [services, setServices] = useState([]);
  const { user , setTransactionLoaderState } = useContext(GlobalContext);
  const [routes, setRoutes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const actions = [
    { label: "Please Select", value: "-1" },
    { label: "Add Load on Credit", value: "4" },
    { label: "Reject Request", value: "3" },
  ];

  const createTariff = async () => {
    const {
      tariff_id,
      service_id,
      provider_id,
      route_id,
      from_amount,
      to_amount,
      retailer_commission,
      distributor_commission,
      cnf_commission,
      admin_commission,
      company_charges,
      other_charges,
      tf_type,
    } = state;
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        tariff_id: tariff_id.value,
        service_id: service_id.value,
        provider_id: provider_id.value,
        route_id: route_id.value,
        from_amount: from_amount,
        to_amount: to_amount,
        retailer_commission: retailer_commission,
        distributor_commission: distributor_commission,
        cnf_commission: cnf_commission,
        admin_commission: admin_commission,
        company_charges: company_charges,
        other_charges: other_charges,
        tf_type: tf_type.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/create_tariff_plan.aspx`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("Tariff Created Successful", data.msg, "success");
        setModalOpen(false);
        refresh();
      } else {
        swal("Tariff Creation Failed", data.msg, "error");
      }
      clearForm();
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
      swal("Tariff Creation Failed", "Error From Server", "error");
    }
  };
  const clearForm = () => {
    setState({
      tariff_id: "",
      service_id: "",
      provider_id: "",
      route_id: "",
      from_amount: "",
      to_amount: "",
      retailer_commission: "",
      distributor_commission: "",
      cnf_commission: "",
      admin_commission: "",
      company_charges: "",
      other_charges: "",
      tf_type: "",
    });
  };

  const getServicesList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_services_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setServices(
          data.data.map((role) => {
            return {
              label: `${role.st_name}`,
              value: role.pk_service_id,
            };
          })
        );
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  const getRoutesList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_api_routes_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setRoutes(
          data.data.map((role) => {
            return {
              label: `${role.route_name}`,
              value: role.pk_route_id,
            };
          })
        );
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  const getTariffsList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_tariff.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setTariffs(
          data.data.map((role) => {
            return {
              label: `${role.tariff_name} [${role.tariff_code}]`,
              value: role.pk_tariff_id,
            };
          })
        );
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  const validateForm = () => {
    console.log(state);
    if (
      state.admin_commission == "" ||
      state.cnf_commission == "" ||
      state.distributor_commission == "" ||
      state.retailer_commission == "" ||
      state.company_charges == "" ||
      state.from_amount == "" ||
      state.to_amount == "" ||
      state.tariff_id == "" ||
      state.route_id == "" ||
      state.provider_id == "" ||
      state.service_id == "" ||
      state.tf_type == "" ||
      state.other_charges == ""
    ) {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    createTariff();
  };
  console.log(details);
  useEffect(() => {
    getServicesList();
    getTariffsList();
    getRoutesList();
  }, []);
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby=""
      size="lg"

      style={{}}
    >
      <Modal.Body
        style={{  display: "block" ,height:"100%",overflowY: 'hidden'}}
        className="account"
      >
        <div
        style={{
          "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
          }}
          className="d-flex justify-content-between"
        >
          <div> Create Tariff Plan</div>
          <div
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            <img
                                    src={require("../../../assets/images/Vector.png")}
                                    style={{ width: "" }}
                                    />
          </div>
        </div>
          
          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Tariff
              </Form.Label>
              <Select
                options={tariffs}
                value={state.tariff_id}
                onChange={(e) => {
                  setState({ ...state, tariff_id: e });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Service
              </Form.Label>
              <Select
                options={services}
                value={state.service_id}
                onChange={(e) => {
                  setState({ ...state, service_id: e });
                }}
              />
            </Form.Group>
          </div>
          </div>

          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Provider
              </Form.Label>
              <Select
                options={ps}
                value={state.provider_id}
                onChange={(e) => {
                  setState({ ...state, provider_id: e });
                }}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Route
              </Form.Label>
              <Select
                options={routes}
                value={state.route_id}
                onChange={(e) => {
                  setState({ ...state, route_id: e });
                }}
              />
            </Form.Group>
          </div>

          </div>

          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>From Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter From Amount"
                value={state.from_amount}
                onChange={(e) => {
                  setState({ ...state, from_amount: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>To Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter To Amount"
                value={state.to_amount}
                onChange={(e) => {
                  setState({ ...state, to_amount: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          </div>

          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Retailer Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Retailer Commission"
                value={state.retailer_commission}
                onChange={(e) => {
                  setState({ ...state, retailer_commission: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Distributor Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Distributor Commission"
                value={state.distributor_commission}
                onChange={(e) => {
                  setState({
                    ...state,
                    distributor_commission: e.target.value,
                  });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          </div>

          <div className="row">

          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Master Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Master Commission"
                value={state.cnf_commission}
                onChange={(e) => {
                  setState({ ...state, cnf_commission: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Admin Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Admin Commission"
                value={state.admin_commission}
                onChange={(e) => {
                  setState({ ...state, admin_commission: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          </div>

          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Company Charges
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Company Charges"
                value={state.company_charges}
                onChange={(e) => {
                  setState({ ...state, company_charges: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Other Charges
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Other Charges"
                value={state.other_charges}
                onChange={(e) => {
                  setState({ ...state, other_charges: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          </div>
          <div className="row align-items-center">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Tariff Plan Type
              </Form.Label>
              <Select
                options={tariffType}
                value={state.tf_type}
                onChange={(e) => {
                  setState({ ...state, tf_type: e });
                }}
              />
            </Form.Group>
          </div>
        
        <div className="col-3">
          <Button style={{backgroundColor:"#0074D9"}} className="rounded-100 btn btn-primary btn-lg btn-block" onClick={validateForm}>
            Submit
          </Button>
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const UpdateTariffPlanModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    pk_tariff_plan_id: "",
    tariff_id: "",
    service_id: "",
    provider_id: "",
    route_id: "",
    from_amount: "",
    to_amount: "",
    retailer_commission: "",
    distributor_commission: "",
    cnf_commission: "",
    admin_commission: "",
    company_charges: "",
    other_charges: "",
    tf_type: "",
  });

  const [services, setServices] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [currentTariff, setCurrentTariff] = useState(null);
  const { user , setTransactionLoaderState } = useContext(GlobalContext);
  const actions = [
    { label: "Please Select", value: "-1" },
    { label: "Add Load on Credit", value: "4" },
    { label: "Reject Request", value: "3" },
  ];

  const updateTariff = async () => {
    const {
      tariff_id,
      service_id,
      provider_id,
      route_id,
      from_amount,
      to_amount,
      retailer_commission,
      distributor_commission,
      cnf_commission,
      admin_commission,
      company_charges,
      other_charges,
      tf_type,
      pk_tariff_plan_id,
    } = state;
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        tariff_id: tariff_id.value,
        service_id: service_id.value,
        provider_id: provider_id.value,
        route_id: route_id.value,
        from_amount: from_amount,
        to_amount: to_amount,
        retailer_commission: retailer_commission,
        distributor_commission: distributor_commission,
        cnf_commission: cnf_commission,
        admin_commission: admin_commission,
        company_charges: company_charges,
        other_charges: other_charges,
        tf_type: tf_type.value,
        tariff_plan_id: pk_tariff_plan_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/update_tariff_plan.aspx`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("Tariff Updated Successful", data.msg, "success");
        clearForm();
        setModalOpen(false);
      } else {
        swal("Tariff Updated Failed", data.msg, "error");
      }
      clearForm();
      refresh();
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
      swal("Tariff Updation Failed", "Error From Server", "error");
    }
  };
  const clearForm = () => {
    setState({
      tariff_id: "",
      service_id: "",
      provider_id: "",
      route_id: "",
      from_amount: "",
      to_amount: "",
      retailer_commission: "",
      distributor_commission: "",
      cnf_commission: "",
      admin_commission: "",
      company_charges: "",
      other_charges: "",
      tf_type: "",
    });
  };

  const getServicesList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_services_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setServices(
          data.data.map((role) => {
            return {
              label: `${role.st_name}`,
              value: role.pk_service_id,
            };
          })
        );

        if (details) {
          const service = data.data.find((e) => {
            return details.fk_service_id == e.pk_service_id;
          });
          console.log(service);
          setState({
            ...state,
            service_id: {
              label: `${service.st_name}`,
              value: service.pk_service_id,
            },
          });
        }
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  const getRoutesList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_api_routes_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setRoutes(
          data.data.map((role) => {
            return {
              label: `${role.route_name}`,
              value: role.pk_route_id,
            };
          })
        );
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  const getTariffsList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_tariff.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setTariffs(
          data.data.map((role) => {
            return {
              label: `${role.tariff_name}`,
              value: role.pk_tariff_id,
            };
          })
        );
      } setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  const validateForm = () => {
    console.log(state);
    if (
      state.admin_commission == "" ||
      state.cnf_commission == "" ||
      state.distributor_commission == "" ||
      state.retailer_commission == "" ||
      state.company_charges == "" ||
      state.from_amount == "" ||
      state.to_amount == "" ||
      state.tariff_id == "" ||
      state.route_id == "" ||
      state.provider_id == "" ||
      state.service_id == "" ||
      state.other_charges == ""
    ) {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    updateTariff();
  };
  useEffect(() => {
    getServicesList();
    getTariffsList();
    getRoutesList();
  }, []);
  useEffect(() => {
    if (details) {
      setCurrentTariff(details);
      setState({
        ...state,
        tariff_id: details.fk_tariff_id,
        service_id: details.fk_service_id,
        provider_id: details.fk_provider_id,
        route_id: details.fk_route_id,
        from_amount: details.from_amt,
        to_amount: details.to_amt,
        retailer_commission: details.tf_ret_comm,
        distributor_commission: details.tf_dist_comm,
        cnf_commission: details.tf_cnf_comm,
        admin_commission: details.tf_admin_comm,
        company_charges: details.api_company_charges,
        other_charges: details.other_charges,
        tf_type: details.tf_type,
        pk_tariff_plan_id: details.pk_tariff_plan_id,
      });
    }
  }, [details]);
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="xl"
    >
      <Modal.Body
        style={{ width: "100%", display: "block" ,overflowY: 'hidden'}}
        className="account"
      >
        <div
          style={{
            "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
            ,padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontWeight: "bolder",
            fontfamily: "Poppins !impotent",
            fontSize:"28px",
           
          }}
          className="d-flex justify-content-between pl-2"
        >
          <strong> Update Tariff Plan</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            <img
                                    src={require("../../../assets/images/Vector.png")}
                                    style={{ width: "" }}
                                    />
          </strong>
        </div>
        <div className="row">
          {/***
            
               <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Tariff
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Select Tariff"
                value={state.tariff_id}
                disabled={true}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Service
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Select Service"
                value={state.service_id}
                disabled={true}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Provider
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Select Provider"
                value={state.provider_id}
                disabled={true}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Route
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Select Route"
                value={state.route_id}
                disabled={true}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
        
        

            
             * ****/}

          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>From Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter From Amount"
                value={state.from_amount}
                onChange={(e) => {
                  setState({ ...state, from_amount: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>To Amount
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter To Amount"
                value={state.to_amount}
                onChange={(e) => {
                  setState({ ...state, to_amount: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Retailer Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Retailer Commission"
                value={state.retailer_commission}
                onChange={(e) => {
                  setState({ ...state, retailer_commission: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Distributor Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Distributor Commission"
                value={state.distributor_commission}
                onChange={(e) => {
                  setState({
                    ...state,
                    distributor_commission: e.target.value,
                  });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Master Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Master Commission"
                value={state.cnf_commission}
                onChange={(e) => {
                  setState({ ...state, cnf_commission: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Admin Commission
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Admin Commission"
                value={state.admin_commission}
                onChange={(e) => {
                  setState({ ...state, admin_commission: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Company Charges
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Company Charges"
                value={state.company_charges}
                onChange={(e) => {
                  setState({ ...state, company_charges: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Other Charges
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Other Charges"
                value={state.other_charges}
                onChange={(e) => {
                  setState({ ...state, other_charges: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Tariff Plan Type
              </Form.Label>
              <Select
                options={tariffType}
                value={state.tf_type}
                onChange={(e) => {
                  setState({ ...state, tf_type: e });
                }}
              />
            </Form.Group>
          </div> */}
        </div>
        <div className="row ">
        <div className="col-2 ">
          <Button className="rounded-100 btn btn-primary btn-block" onClick={validateForm}>
          Update Tariff Plan
          </Button>
        </div>
        </div>
        
      </Modal.Body>
    </Modal>
  );
};

export default ViewTariffPlansTable;
