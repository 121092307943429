import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { urls } from "../../URLs";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { REPORT_PAGE_RECORD_COUNT } from "../../config";
const { DMTWithKYCUrl } = urls;
const DMTTable = ({
  successToast,
  errorToast,
  data,
  columns,
  remitterNumber,
  userId,
  refresh,
  setSidebarDetails,
  limit,
}) => {
  const recordPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [idx, setIdx] = useState(-1);
  const { user, refreshWallet, setTransactionLoaderState } =
    useContext(GlobalContext);
  console.log(userId);
  const deleteBenificiary = async (benId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "delete");
    urlencoded.append("remitter", remitterNumber);
    urlencoded.append("beneid", benId);
    urlencoded.append("uscode", userId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);

      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status == 0) {
        successToast("Beneficiary deleted successfully");
        refresh();
      } else {
        errorToast("Beneficiary not deleted");
      }
    } catch (error) {
      errorToast("something went wrong.");
    }
  };

  const verify = (bene) => {
    swal({
      title: "Are you sure?",
      text: "3 Rupees deducted from your Account",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // setBenificiary(bene);
        verifyBeneficiary(bene);
      }
    });
  };

  const verifyBeneficiary = async (benificiary) => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "verifyaccountnew");
    urlencoded.append("beneficiaryid", benificiary.account);
    urlencoded.append("mobile", benificiary.recipient_mobile);
    urlencoded.append("amount", "1.0");
    urlencoded.append("tid", "IMPS");
    urlencoded.append("uid", user.ReferId);
    urlencoded.append("type", "mobile");
    urlencoded.append("bank", benificiary.ifsc);
    urlencoded.append("tpin", "");
    urlencoded.append("route", "0");
    urlencoded.append("accountno", benificiary.account);
    urlencoded.append("paytmlimit", limit);
    urlencoded.append("bankitlimit", limit);
    urlencoded.append("benfname", benificiary.recipient_name.toString().replace("&",""));
    urlencoded.append("customernumber", remitterNumber);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    // console.log({benificiary,remitterNumber,limit,user});
    // return;
    //setBenificiary(null);
    try {
      const res = await fetch(DMTWithKYCUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status == 0 || data.status == "00") {
        //  successToast(data.message);
        swal("Success", data.message, "success");
      } else {
        swal("Failed", data.message, "error");
      }
      setTransactionLoaderState(false);
      refreshWallet();
      refresh();
    } catch (error) {
      setTransactionLoaderState(false);
      //errorToast("Amount transfer failed");
      swal("Failed", "Error From Server", "error");
      console.log("error", error);
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(
      Math.floor((filteredData.length + (recordPerPage - 1)) / recordPerPage)
    );
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = recordPerPage - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    const startIdx = (currentPage - 1) * recordPerPage;
    const endIdx = currentPage * recordPerPage - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (recordPerPage - 1)) / recordPerPage));
    const startIdx = 0;
    const endIdx = recordPerPage - 1;
    setTotal(data.length);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, []);

  useEffect(() => {
    setPages(Math.floor((data.length + (recordPerPage - 1)) / recordPerPage));
    const startIdx = 0;
    const endIdx = recordPerPage - 1;
    setTotal(data.length);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [data]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
        </Form.Group>
      </div>
      <table
        style={{
          width: "100%",
        }}
      >
        <tr
          style={{
            width: "100%",
          }}
        >
          {columns.map((heading, ind) => (
            <th key={ind} style={{ fontSize: "0.9rem" }}>
              {heading.text}
            </th>
          ))}
          <th style={{ fontSize: "0.9rem" }}> {"Action"}</th>
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr
            style={{
              backgroundColor: `${ind === idx ? "green" : ""}`,
              color: `${ind === idx ? "white" : ""}`,
            }}
          >
            {columns.map((cell, ind) =>
              cell.dataField == "isVerifiedBenName" ? (
                e[cell.dataField] == "1" ? (
                  <td key={ind} style={{ fontSize: "0.8rem" }}>
                    <label
                      style={{
                        padding: "4px",
                        //  borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge"
                    >
                      <img
                        src={require("../../../assets/images/success_tick.jpg")}
                        style={{
                          height: "1rem",
                          width: "auto",
                          borderRadius: "1rem",
                          marginRight: ".2rem",
                        }}
                      />
                      {"Verified"}
                    </label>
                  </td>
                ) : (
                  <td key={ind} style={{ fontSize: "0.8rem" }}>
                    <Button
                      onClick={() => {
                        verify(e);
                      }}
                      size="sm"
                      variant="success"
                      className="mx-auto rounded-0"
                    >
                      {" "}
                      Verify
                    </Button>
                  </td>
                )
              ) : (
                <td key={ind} style={{ fontSize: ".8rem" }}>
                  {e[cell.dataField]}
                </td>
              )
            )}
            <td>
              <div>
                <Button
                  size="sm"
                  style={{
                    backgroundColor: "#3bafda",
                    border: "none",
                    margin: "10px 0px",
                  }}
                  className="rounded-0"
                  onClick={() => {
                    setIdx(ind);
                    setSidebarDetails(e);
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                >
                  IMPS / NEFT
                </Button>
                {/* <Button
                  size="sm"
                  style={{ backgroundColor: "#4a89dc", border: "none" }}
                  className="rounded-0"
                >
                  {" "}
                  NEFT
                </Button> */}
                {/* <Button
                  size="sm"
                  onClick={() => {
                    deleteBenificiary(e.recipient_id);
                  }}
                  style={{ backgroundColor: "red", border: "none" }}
                  className="rounded-0"
                >
                  {"DELETE"}
                </Button> */}
              </div>
            </td>
          </tr>
        ))}
      </table>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default DMTTable;
