import React, { useState, useEffect, useContext } from "react";
import AnlookImage from "../../../assets/images/AnlookImage.png";
import GoSeoImage from "../../../assets/images/carousel/GoSeoBanner1.png";
import GoSeoLogo from "../../../assets/images/GOSEO.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";
import { use } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import cogoToast from "cogo-toast";

const GoSeo = () => {
  const [leads, setLeads] = useState([]);
  const history = useHistory();
  const { user } = useContext(GlobalContext);

  const handleRedirect = () => {
    history.push("/retailer/GoSeoForm");
  };

  const handleRedirect1 = () => {
    history.push("/retailer/GoSeoDocSubmit");
  };

  const handleRedirect2 = () => {
    history.push("/retailer/GoSeoViewDoc");
  };

  const steps = [
    { label: "Onboarding", status: "In Progress" },
    { label: "Document Submission", status: "Pending" },
    { label: "Document Verification", status: "Pending" },
    { label: "Website Live", status: "Pending" },
  ];

  // const currentStep = 1;

  const getStepperStep = (step) => {
    switch (step) {
      case "Onboarding":
        return 1;
      case "Document Submission":
        return 2;
      case "Verification":
        return 3;
      case "Live":
        return 4;
      default:
        return 1;
    }
  }



  const getAgentLeads = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        userid: user.ReferId,
        product: "goseo",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/User/getAgentLeads",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        cogoToast.success(data.responseMessage);
        setLeads(data.data.map((lead) => {
          return {
            ...lead, name: `${lead.first_name} ${lead.last_name}`,
            productName: lead.requirements,
            phone: lead.phone,
            onboardingDate: lead.created_on,
            currentStep: getStepperStep(lead.step),
          }
        }));
      } else {
        cogoToast.error(data.responseMessage);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAgentLeads();
  }, []);

  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          padding: "20px",
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          marginBottom: "20px",
          backgroundColor: "#E5F0FF",
        }}
      >
        <div style={{ flex: 1, paddingRight: "20px" }}>
          <img
            src={GoSeoLogo}
            style={{
              height: "auto",
              width: "7rem",
              margin: "2px",
              marginBottom: "3px",
            }}
          />
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          >
            Go SEO is a platform for digital marketing, helping businesses grow and reach more customers online.
          </div>
          <h5><b>Benefits for Retailers</b></h5>
          <ul style={{ paddingLeft: "20px", marginBottom: "20px" }}>
            <li>Retailers will earn ₹200 for every customer onboarding.</li>
            <li>There is no cost to clients right now, so it’s easy to make sales.</li>
            <li>No limits on how much you can earn—the more you sell, the more you make.</li>
            <li>Easy process to join and start earning.</li>
          </ul>
          <h5><b>Benefits for Clients</b></h5>
          <ul style={{ paddingLeft: "20px", marginBottom: "20px" }}>
            <li>Free Onboarding</li>
            <li>Start SEO and SMO free.</li>
            <li>Use tools that help your website rank higher in Google.</li>
            <li>No need to hire expensive digital marketing experts—you can do it yourself with Go SEO.</li>
            <li>Our team will teach you how to use the tools and get the best results.</li>
            <li>Save money and time with our tools.</li>
          </ul>
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleRedirect}
          >
            Get Started
          </button>{" "}
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <div style={{ backgroundColor: "#E5F0FF", borderRadius: "10px" }}>
            <img src={GoSeoImage} style={{ height: "30rem", width: "auto" }} />
          </div>
        </div>
      </div>

      <h2
        style={{ fontSize: "20px", marginBottom: "10px", marginLeft: "1rem" }}
      ><b> Leads</b>

      </h2>

      <div style={{ margin: "5px" }}>
        {leads.map((user, index) => (
          <div key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #ddd",
              padding: "20px",
              marginBottom: "10px",
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <div>
              <div>{user.name} </div>
              {/* <hr/> */}
              <p style={{ margin: "5px 0" }}>
                <strong>Product Name:</strong> {user.productName}
              </p>
              <p style={{ margin: "5px 0" }}>
                <strong>Onboarding Date:</strong> {user.onboardingDate}
              </p>
            </div>
            <div>
          {getStepperStep(user.step) === 1 || getStepperStep(user.step) === 2 ? (
            <button
              className="btn btn-block bg-primary"
              onClick={handleRedirect1}
              style={{ color: "white", border: "none" }}
            >
              Submit Document
            </button>
          ) : (
            <button
              className="btn btn-block bg-secondary"
              onClick={handleRedirect2}
              style={{ color: "white", border: "none" }}
            >
              View Document
            </button>
          )}
        </div>

            <div style={{ flex: 1, marginLeft: "20px", maxWidth: "600px" }}>
              <Stepper steps={steps} currentStep={user.currentStep} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoSeo;

const Stepper = ({ steps, currentStep }) => {
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    const newWidth = (currentStep / (steps.length - 1)) * 100;
    setProgressWidth(newWidth);
  }, [currentStep, steps.length]);

  return (
    <div
      style={{
        padding: "20px",
        background: "#f9f9f9",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "30%",
          left: "5%",
          width: "90%",
          height: "3px",
          backgroundColor: "#ccc",
          zIndex: 0,
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "30%",
          left: "5%",
          width: `${progressWidth}%`,
          height: "3px",
          backgroundColor: "green",
          zIndex: 1,
          transition: "width 2s ease-in-out",
        }}
      ></div>

      {steps.map((step, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            zIndex: 2,
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor:
                currentStep > index
                  ? "green"
                  : currentStep === index
                    ? "#6a5af9"
                    : "#ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontWeight: "bold",
              fontSize: "1.2rem",
              zIndex: 3,
            }}
          >
            {currentStep > index ? "✔" : currentStep === index ? "🔒" : "⬤"}
          </div>

          <span
            style={{ marginTop: "10px", fontWeight: "200", fontSize: "50" }}
          >
            {step.label}
          </span>

          <span
            style={{
              marginTop: "5px",
              fontSize: "0.8rem",
              color:
                currentStep > index
                  ? "green"
                  : currentStep === index
                    ? "#6a5af9"
                    : "#999",
              fontWeight: "500",
            }}
          >
            {currentStep > index
              ? "Completed"
              : currentStep === index
                ? "In Progress"
                : "Pending"}
          </span>
        </div>
      ))}
    </div>
  );
};