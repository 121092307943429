import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import ChatContent from "./ChatContent";


const Sidebar22 = {
    position: "fixed",
    right: "0",
    width: "0px",
    height: "600px",
    overflow: "scroll",
    background: "#FFF",
};

const TicketSidebar = (props) => {
    console.log(props.TicketId, props.TicketSts);
    
    return (
        <nav
            className="side-sheet sidebar Sidebar23"
            id="sidebar2"
            style={Sidebar22}
        >

            <div
                style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
                className="row"
            >
                <ChatContent Ticket={props.TicketId} Status={props.TicketSts}/>

            </div>
        </nav>
    );
};

export default withRouter(TicketSidebar);
