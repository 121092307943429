import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card, Table } from "react-bootstrap";
import { urls } from "../../URLs";
import { GlobalContext } from "../../Context/GlobalState";
import {
    dmtTransactionFailedPopup,
    dmtTransactionSuccessPopup,
} from "../../utils/Models";
import swal from "sweetalert";
import JsonTable from "../json-component/JsonTable";

const detailFields = [
    { text: "USER NAME", datafield: "user_name" },
    { text: "USER CODE", datafield: "user_code" },
    { text: "TXN ID", datafield: "tid" },
    { text: "TXN ID", datafield: "txn_id" },
    { text: "RRN", datafield: "bank_rrn" },
    { text: "ACCNO", datafield: "accNo" },
    { text: "IFSC", datafield: "ifsc" },
    { text: "TXN MODE", datafield: "txn_mode" },
    { text: "DATE TIME", datafield: "dates" },
    { text: "MOBILE", datafield: "rch_mobile" },

    { text: "SERVICE", datafield: "ttyp" },
    { text: "STATUS DESC", datafield: "rch_status_desc" },

    { text: "NAME", datafield: "Name" },

    { text: "OPENING BALANCE", datafield: "rch_before_balance" },
    { text: "TRANSACTION ID", datafield: "rch_transaction_id" },
    { text: "OPERATOR ID", datafield: "rch_operator_id" },
    { text: "FK PROVIDER ID", datafield: "fk_provider_id" },

    { text: "INDATE", datafield: "rch_indate" },
    { text: "API_MESSAGE", datafield: "rch_api_message" },
    { text: "COMPANY", datafield: "Company" },

    { text: "TRANSACTION AMOUNT", datafield: "rch_amount" },
    { text: "CHARGES", datafield: "charges" },
    { text: "GST", datafield: "gst" },
    { text: "RETAILER COM", datafield: "rch_com_retailer" },
    { text: "DISTRIBUTER COM", datafield: "rch_com_distributer" },
    { text: "MASTER COM", datafield: "rch_com_cnf" },
    { text: "CLOSING ALANCE", datafield: "rch_after_balance" },

    { text: "SALES USER CODE", datafield: "sales_user_code" },
    { text: "SALES USER NAME", datafield: "sales_user_name" },
    { text: "SALES USER MOBILE", datafield: "sales_user_mobile" },



    { text: "SHOP PIN CODE", datafield: "shop_pin_code" },
    { text: "SHOP CITY", datafield: "shop_city" },
    { text: "SHOP STATE", datafield: "shop_state" },



];

const Sidebar22 = {
    position: "fixed",
    right: "0",
    width: "0px",
    height: "60%",
    overflowY: "scroll",
    overflowX: "hidden",
    background: "#FFF",
};

const TransactionDetailsSidebar = ({ details }) => {

    const renderValue = (value) => {
        if (Array.isArray(value)) {
            return (
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                    {value.map((item, index) => (
                        <li key={index}>{renderValue(item)}</li>
                    ))}
                </ul>
            );
        } else if (typeof value === 'object' && value !== null) {
            return <JsonTable data={value} />;
        } else {
            return String(value);
        }
    };


    return (
        <nav
            className="side-sheet sidebar Sidebar23"
            id="sidebar2"
            style={Sidebar22}
        >
            <div
                style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" , position:'sticky' }}
                className="row"
            >
                <div
                    style={{ paddingLeft: "5px", display: "flex", alignItems: "center" }}
                    className="col"
                >
                    <strong style={{ color: "#1b68ca" }}>
                        Transaction Details
                    </strong>
                </div>
                <div style={{ paddingRight: "0px" }} className="col">
                    <span
                        style={{
                            fontSize: "2rem",
                            display: "flex",
                            color: "#b2b2b3",
                            justifyContent: "end",
                            ariaHidden: "true",
                            paddingRight: "4px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            document
                                .querySelector(".content-wrapper")
                                .classList.toggle("sidebar-icon-only");
                        }}
                    >
                        &times;
                    </span>
                </div>
            </div>
            <Dropdown.Divider />
            <Card >
                <Table borderless>
                    <tbody>
                        {detailFields.map((e) => (
                            details[e.datafield] &&
                            <tr key={e.text}>
                                <td>
                                    {e.text}
                                </td>
                                <td>{details[e.datafield]}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card>
        </nav>
    );
};

export default withRouter(TransactionDetailsSidebar);
