import React from "react";

import { GlobalContext } from "../../Context/GlobalState";
import CreditCardDetailsTable from "./CreditCardDetailsTable";

const CreditCardDetails = () => {
  return (
    <GlobalContext.Consumer>
      {(context) => (
         <div style={{ padding: "1rem",backgroundColor:"#F2F6FC", }} >
         <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 border-white ">
           <div className="card-body m-0 p-2">
             <div>
                <CreditCardDetailsTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default CreditCardDetails;