import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import LedgerIcon from "../../../assets/svg/sidebar/ledger.svg";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";

const style = {
  color: "#FFF",
};
const activeStyle = {
  color: "#FFF",
  fontWeight: "bold",
};

class Sidebar extends Component {
  state = {services: [], verifiedKycData: null };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
     // this.accessInfo();
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
      { path: "/editors", state: "editorsMenuOpen" },
      { path: "/reports", state: "reportsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  accessInfo() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const user = JSON.parse(sessionStorage.getItem("user"));

    var raw = JSON.stringify({
      userid: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/user/get_user_access_info.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ ...this.state, services: result.services });
      })
      .catch((error) => console.log("error", error));
  }

  verificationData() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const user = JSON.parse(sessionStorage.getItem("user"));

      var raw = JSON.stringify({
        userid: user.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data.aspx`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status == "0") {
            this.setState({ ...this.state, verifiedKycData: data.data });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {}
  }

  isServiceActive(idx) {
    return (
      this.state.services &&
      this.state.services.length > idx &&
      this.state.services[idx].status == "Y"
    );
  }

  render() {
    console.log(this.state);
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {/* <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <strong>BUMPPY</strong>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
        </div> */}
        <ul className="nav">
          <li
            style={
              (this.isPathActive("/dashboard") ? activeStyle : style,
              { marginTop: "10px" })
            }
            className={
              this.isPathActive("/dashboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>
          {/* 
          <li
            className={
              this.isPathActive("/reports")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("reportsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-history"></i>
              </span>
              <span className="menu-title">
                <Trans>Account History</Trans>
              </span>
              <i className="menu-arrow" style={{fontSize:'1.9rem'}}></i>
            </div>
            <Collapse in={this.state.reportsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/reports/DMT-reports")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/reports/DMT-reports"
                    >
                      <Trans>DMT reports</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/reports/AEPS-reports")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/reports/AEPS-reports"
                    >
                      <Trans>AEPS reports</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/reports/BBPS-reports")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/reports/BBPS-reports"
                    >
                      <Trans>BBPS reports</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/reports/aadharpay-reports")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/reports/aadharpay-reports"
                    >
                      <Trans>
                        Adhaar Pay reports
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/reports/cms-reports")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/reports/cms-reports"
                    >
                      <Trans>CMS reports</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/reports/micro-atm-reports")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/reports/micro-atm-reports"
                    >
                      <Trans>Micro ATM reports</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li> */}

          {/* <li
            style={this.isPathActive("/user/settlements") ? activeStyle : style}
            className={
              this.isPathActive("/user/settlements")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/settlements">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">Settlements</span>
            </Link>
          </li> */}

          <li
            style={
              this.isPathActive("/user/transactions") ? activeStyle : style
            }
            className={
              this.isPathActive("/user/transactions")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/transactions">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Transaction</Trans>
              </span>
            </Link>
          </li>

          <li
            style={
              this.isPathActive("/user/money-transfer") ? activeStyle : style
            }
            className={
              this.isPathActive("/user/money-transfer")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/money-transfer">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Money Transfer</Trans>
              </span>
            </Link>
          </li>

          {/* <li
            style={this.isPathActive("/user/customers") ? activeStyle : style}
            className={
              this.isPathActive("/user/customers")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/customers">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">Customers</span>
            </Link>
          </li> */}

          {/* <li
            style={
              this.isPathActive("/user/virtual-payments")
                ? activeStyle
                : style
            }
            className={
              this.isPathActive("/user/virtual-payments")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/virtual-payments">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">Virtual Payment</span>
            </Link>
          </li> */}
          <li
            style={this.isPathActive("/user/dmt") ? activeStyle : style}
            className={
              this.isPathActive("/user/dmt")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/dmt">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">DMT</span>
            </Link>
          </li>
          <li
            style={
              this.isPathActive("/user/offline_bill_payment")
                ? activeStyle
                : style
            }
            className={
              this.isPathActive("/user/offline_bill_payment")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/offline_bill_payment">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">Hybrid BBPS</span>
            </Link>
          </li>
          <li
            style={this.isPathActive("/user/bbps") ? activeStyle : style}
            className={
              this.isPathActive("/user/bbps")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/bbps">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">BBPS</span>
            </Link>
          </li>
          {/* topup */}
          <li
            style={this.isPathActive("/user/aeps") ? activeStyle : style}
            className={
              this.isPathActive("/user/aeps")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/aeps">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">AEPS</span>
            </Link>
          </li>

          {/* <li
            style={this.isPathActive("/user/flight") ? activeStyle : style}
            className={
              this.isPathActive("/user/flight")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/flight">
              <span className="menu-icon">
                <i className="mdi mdi-cash-multiple"></i>
              </span>
              <span className="menu-title">Flight Booking</span>
            </Link>
          </li> */}

          <li
            style={
              this.isPathActive("/user/topup_request") ? activeStyle : style
            }
            className={
              this.isPathActive("/user/topup_request")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/topup_request">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Topup Request</Trans>
              </span>
            </Link>
          </li>

          <li
            style={this.isPathActive("/user/topup_view") ? activeStyle : style}
            className={
              this.isPathActive("/user/topup_view")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/topup_view">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Topup View</Trans>
              </span>
            </Link>
          </li>

          <li
            style={
              this.isPathActive("/user/virtual_report") ? activeStyle : style
            }
            className={
              this.isPathActive("/user/virtual_report")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/virtual_report">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Virtual Report</Trans>
              </span>
            </Link>
          </li>

          <li
            style={
              this.isPathActive("/user/wallet_to_wallet_transfer")
                ? activeStyle
                : style
            }
            className={
              this.isPathActive("/user/wallet_to_wallet_transfer")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/wallet_to_wallet_transfer">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Wallet To Wallet</Trans>
              </span>
            </Link>
          </li>

          <li
            style={
              this.isPathActive("/user/bbps_history") ? activeStyle : style
            }
            className={
              this.isPathActive("/user/bbps_history")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/bbps_history">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>BBPS History</Trans>
              </span>
            </Link>
          </li>
          <li
            style={
              this.isPathActive("/user/recharge_history") ? activeStyle : style
            }
            className={
              this.isPathActive("/user/recharge_history")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/recharge_history">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Recharge History</Trans>
              </span>
            </Link>
          </li>

          <li
            style={this.isPathActive("/user/ledger") ? activeStyle : style}
            className={
              this.isPathActive("/user/ledger")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link
              className={`nav-link ${
                this.isServiceActive(19) ? "" : "text-muted"
              }`}
              to={
                this.isServiceActive(19) ? "/user/ledger" : this.props.location
              }
            >
              <span className="menu-icon">
                <img style={{ height: "1.2rem" }} src={LedgerIcon} />
              </span>
              <span className="menu-title">Ledger</span>
            </Link>
          </li>

          {/* <li
            style={this.isPathActive("/user/qrcode") ? activeStyle : style}
            className={
              this.isPathActive("/user/qrcode")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/qrcode">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>QR Code</Trans>
              </span>
            </Link>
          </li> */}

          {/* <li
            style={
              this.isPathActive("/user/subscription") ? activeStyle : style
            }
            className={
              this.isPathActive("/user/subscription")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/subscription">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Subscription</Trans>
              </span>
            </Link>
          </li> */}
          {/* <li
            style={this.isPathActive("/user/invoices") ? activeStyle : style}
            className={
              this.isPathActive("/user/invoices")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/invoices">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Invoices</Trans>
              </span>
            </Link>
          </li> */}
          {/* <li
            style={this.isPathActive("/user/offers") ? activeStyle : style}
            className={
              this.isPathActive("/user/offers")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/user/offers">
              <span className="menu-icon">
                <i className="mdi mdi-account"></i>
              </span>
              <span className="menu-title">
                <Trans>Offers</Trans>
              </span>
            </Link>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.accessInfo();
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
