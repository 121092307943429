import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import userAvtar from "../../../assets/images/user.png";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";

const ViewRetailer = () => {
    return (
        <div style={{backgroundColor:COLOR_BACKGROUND_BODY}}>
            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="border p-3" style={{ backgroundColor: '#002970', color: 'white' }}><b> View Retailer</b></div>
                    <div className="card" >
                        <Row>
                            <Col md={3}>
                                <Form.Label style={{ color: 'grey' }}><b>By Retailer ID :</b></Form.Label>
                                <Form.Control type="text" name="Retailer id" style={{borderRadius:'0px'}}/>
                            </Col>
                            <Col md={3}>
                                <Form.Label style={{ color: 'grey' }}><b>Mobile Number</b></Form.Label>
                                <Form.Control type='number' name="Mobile no."  style={{borderRadius:'0px'}}/>
                            </Col>
                            <Col md={2}>
                                <Form.Label style={{ color: 'white' }}>.</Form.Label>
                                <Button type="button" className="btn btn-sm form-control" variant="success" style={{borderRadius:'0px'}}>Search</Button>
                            </Col>
                            <Col md={2}>
                                <Form.Label style={{ color: 'white' }}>.</Form.Label>
                                <Button type="button" className="btn btn-sm form-control" style={{borderRadius:'0px'}}>Reset</Button>
                            </Col>
                        </Row>
                        <div className="card-body" >
                            <div className="table-responsive" >
                                <table className="table" style={{textAlign:'center'}}>
                                    <thead>
                                        <tr>
                                            <th> SL No.</th>
                                            <th> Shop Name </th>
                                            <th> Retailer ID </th>
                                            <th> Mobile </th>
                                            <th> Balance </th>
                                            <th> Last Bal Update </th>
                                            <th> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr >
                                            <td style={{textAlign:'center'}}> 1.</td>
                                            <td>
                                                <img
                                                    src={userAvtar}
                                                    alt="face"
                                                />
                                                <span className="pl-2">Henry Klein</span>
                                            </td>
                                            <td> 02312 </td>
                                            <td> $14,500 </td>
                                            <td> Dashboard </td>
                                            <td> Credit card </td>
                                            <td>
                                                <div className="badge">
                                                    <Button type="button" className="btn btn-sm" value='KYC' variant="info">KYC</Button><span> </span>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> 2.</td>
                                            <td>
                                                <img
                                                    src={userAvtar}
                                                    alt="face"
                                                />
                                                <span className="pl-2">Estella Bryan</span>
                                            </td>
                                            <td> 02312 </td>
                                            <td> $14,500 </td>
                                            <td> Website </td>
                                            <td> Cash on delivered </td>
                                            <td>
                                                <div className="badge">
                                                    <span> <Button type="button" className="btn btn-sm" variant="info"> KYC</Button></span>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> 3.</td>
                                            <td>
                                                <img
                                                    src={userAvtar}
                                                    alt="face"
                                                />
                                                <span className="pl-2">Lucy Abbott</span>
                                            </td>
                                            <td> 02312 </td>
                                            <td> $14,500 </td>
                                            <td> App design </td>
                                            <td> Credit card </td>
                                            <td>
                                                <div className="badge">
                                                    <span> <Button type="button" className="btn btn-sm" variant="info"> KYC</Button></span>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> 4.</td>
                                            <td>
                                                <img
                                                    src={userAvtar}
                                                    alt="face"
                                                />
                                                <span className="pl-2">Peter Gill</span>
                                            </td>
                                            <td> 02312 </td>
                                            <td> $14,500 </td>
                                            <td> Development </td>
                                            <td> Online Payment </td>
                                            <td>
                                                <div className="badge">
                                                    <span> <Button type="button" className="btn btn-sm" variant="info"> KYC</Button></span>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> 5.</td>
                                            <td>
                                                <img
                                                    src={userAvtar}
                                                    alt="face"
                                                />
                                                <span className="pl-2">Sallie Reyes</span>
                                            </td>
                                            <td> 02312 </td>
                                            <td> $14,500 </td>
                                            <td> Website </td>
                                            <td> Credit card </td>
                                            <td>
                                                <div className="badge">
                                                    <span> <Button type="button" className="btn btn-sm" variant="info"> KYC</Button></span>

                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <td>

                                            </td>
                                            <td>  </td>
                                            <td><b> Total Bal. </b></td>
                                            <td> 3.00 </td>
                                            <td> </td>
                                            <td> </td>
                                            <td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewRetailer