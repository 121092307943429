import React, { useContext } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW } from "../../utils/CommonConfig";

const AepsScreen = () => {
  const history = useHistory();
  const { setUserInfo, user } = useContext(GlobalContext);
  return (
    <div style={{
      background:"#F1F2F4",
      height:"100%",
      width:"100%"
    }}>
    <div className="card ml-5 mr-5 mt-0" style={{
        background: "#FFFFFF",
        boxShadow: DROP_SHADOW,
        borderRadius: "1rem",
        width:"95%",
        margin:"2rem"
      }}>
  <Row 
    >
     <Col lg={4} md={4} sm={4}>
        <img
          src={require("../../../assets/images/aeps-card.png")}
          alt="image"
          style={{ width: "70%", height: "auto" }}
        />
      </Col>
      <Col lg={8} md={8} sm={8}>
        <div className="">
          <h2 style={{ color: "#0074D9", fontFamily: "Poppins" }}>
            <strong>Aadhaar Enabled Payment System (AEPS)</strong>
          </h2>
          <h3 style={{ fontFamily: "Poppins" }}>
            AEPS is a biometric authentication system that enables user to
            withdraw cash, balance enquiry and check mini statement
          </h3>
          <div className="row">
            <div className="col-6">
            <ol>
           <h5><li>biometric authentication</li></h5> 
           <h5><li>Instant Kyc Approved</li></h5> 
           <h5><li>Mini Statement</li></h5> 
            </ol>
            </div>
            <div className="col-6">
        <ol>
         <h5><li>Realtime settlement</li></h5> 
         <h5><li>Transaction Report</li></h5> 
         <h5><li>balance enquiry</li></h5>
        </ol>
             </div>
          </div>
   
        </div>
      
      </Col>

     
    </Row>
    <hr/>
        <div
            className="row">
          <div className="col-6">
          <h5 className="p-0 ml-2">
              To use this service please upgrade your plan
            </h5>
          </div>
      <div className="col-3"></div>
<div className="col-3">
<Button
              className="rounded-3 btn-block"
              onClick={() => {
                localStorage.setItem("kyc", JSON.stringify(user));
                history.push("/kyc/bussiness-details");
              }}
            >
              Upgrade
            </Button>
</div>
           
          </div>
    </div>
    </div>
  
  );
};

export default AepsScreen;
