import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ExportToExcel, inWords } from "../../utils/utilFuctions";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import cogoToast from "cogo-toast";
import swal from "sweetalert";

const AEPSReconcileReportTable = ({ columns, agentId, mobile }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo, user, setTransactionLoaderState } =
    useContext(GlobalContext);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  function isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const fetchAEPSReport = async () => {
    try {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        `https://bumppy.com/apibumppypay/v1/web/fingpay/recon_report.php?agentid=${agentId}&fromDate=${dateFormatter(
          startDate
        )}&toDate=${dateFormatter(endDate)}`,
        requestOptions
      );
      const data = await res.json();
      if (Array.isArray(data) && data.length > 0) {
        setData(
          data.map((re) => {
            if (!isJSONString(re.fullresponse)) {
              return {
                ...re,
                fullresponse: re.fullresponse,
                status: "Undefined",
                desc: "log not found",
                amount: 0.0,
                date: "NA",
                service: "NA",
              };
            } else {
              let fr = JSON.parse(re.fullresponse);

              //  console.log(fr);
              let status = fr?.status ? "Success" : "Failed ",
                desc = fr?.message,
                amount = fr?.data?.transactionAmount,
                date = fr?.data?.requestTransactionTime;
              return {
                ...re,
                fullresponse: isJSONString(re.fullresponse)
                  ? JSON.parse(re.fullresponse)
                  : re.fullresponse,
                status,
                desc,
                amount,
                date,
                service: fr?.data?.transactionType,
              };
            }
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const aepsCallbackReHit = async (
    rrn,
    aadhaar,
    bankName,
    amount,
    balance = 0
  ) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        rrn: rrn,
        stanNo: null,
        txnId: null,
        aepstxnId: null,
        action: "Credit",
        device: "2424",
        status: "0",
        txnStatus: "Success",
        bankName: bankName,
        mobileNo: mobile,
        uId: aadhaar,
        authCode: "",
        deviceNo: "1212",
        balance: balance,
        Agent_Id: agentId,
        Service: "Cash Withdrawl",
        Amount: amount,
        Bumppy_agentcode: 1053,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/webhook/aeps_paynnowresponse.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.Status == "0") {
        swal("Success", "Succesfull Rehit", "success");
      } else {
        swal("Failed", "Failed to Rehit", "error");
      }
    } catch (error) {}
  };

  const rehitVerification = (rrn, aadhaar, bankName, amount, balance = 0) => {
    swal({
      title: "Are you sure?",
      text: `Rehit for amount of ${amount} to ${agentId}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        aepsCallbackReHit(rrn, aadhaar, bankName, amount, balance, mobile);
      }
    });
  };

  const setPrintData = (res, bank, aadhar, amount, service, mobile, tid) => {
    let data = {
      userid: user.ReferId,
      bank: bank,
      device: 0 == 0 ? "Mantra MFS 100" : 1 == 1 ? "Morpho MSO 1300" : "",
      tid: tid,
      aadhar: aadhar,
      amount: amount,
      service: service,
      rrn: res?.data ? res?.data?.bankRRN : "NA",
      stan: res?.data ? res?.data?.fpTransactionId : "NA",
      time: res?.data ? res?.data?.requestTransactionTime : "NA",
      mobile,
      mobile,
      status: res?.status ? res?.data?.transactionStatus : res?.message,
      balance: res?.data ? res?.data?.balanceAmount : "NA",
      miniStatementStructureModel: res?.data
        ? res?.data?.miniStatementStructureModel
        : null,
      miniOffusStatementStructureModel: res?.data
        ? res?.data?.miniOffusStatementStructureModel
        : null,
      shopname: Profileinfo?.shopname,
      image: user?.image,
    };
    sessionStorage.setItem("print", JSON.stringify(data));
    localStorage.setItem("print", JSON.stringify(data));
  };

  const getTransactionType = (t) => {
    switch (t) {
      case "CW":
        return "Cash Withdrawal";
      case "MS":
        return "Mini Statement";
      case "AP":
        return "Aadhaar Pay";
      case "BE":
        return "Balance Enquiry";
      default:
        return "";
    }
  };

  const openInNewTab = (data) => {
    const fr = data.fullresponse;
    console.log(data);
    setPrintData(
      fr,
      data.a_bankname,
      data.a_aadharno,
      data.amount,
      getTransactionType(data.service),
      "",
      data.fingpayTransactionId
    );
    const newWindow = window.open(
      `/pages/aeps/print?id=${data.tid}&type=AEPS`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  // did mount
  useEffect(() => {
    if (agentId && agentId.length > 4) fetchAEPSReport();
  }, [agentId]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0px" }}
        ></div>
      </div>
      <div className="px-2" style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="row align-items-center" style={{ flex: "1" }}>
            <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>

            <div className="col-3 m-1">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col-3 m-1">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            {/* <button
              style={{ background: "#277BC0", color: "white" }}
              className="btn fw-bold btn-sm m-1"
            >
              {" "}
              Cancel
            </button> */}
            <button
              style={{ background: "#277BC0", color: "white" }}
              className="btn fw-bold btn-sm m-1"
              onClick={fetchAEPSReport}
            >
              {" "}
              Submit
            </button>
          </div>
          <div
            className="pr-5"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(`AEPS_Report_${now()}`, dataToDisplay);
            }}
          >
            <img
              src={require("../../../assets/images/excel.png")}
              style={{ width: "1.6rem" }}
            />
            <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
          </div>
        </div>
      </div>
      <div className="scroll-inner">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          <tr>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              {" "}
              {"Sr"}
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Transaction ID{" "}
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Aadhaar Number{" "}
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Agent Id{" "}
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Bank Name{" "}
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Service Type{" "}
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Amount{" "}
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Status
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Date
            </th>
            <th className="text-center" style={{ fontSize: "0.9rem" }}>
              Action
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
              <td style={{ fontSize: "0.8rem" }}> {e.merchantTransactionId}</td>
              <td style={{ fontSize: "0.8rem" }}> {e.a_aadharno}</td>
              <td style={{ fontSize: "0.8rem" }}> {e.a_agentid}</td>
              <td style={{ fontSize: "0.8rem" }}> {e.a_bankname}</td>
              <td style={{ fontSize: "0.8rem" }}> {e.service}</td>
              <td style={{ fontSize: "0.8rem" }}> {e.amount}</td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <span
                  className={`badge badge-${
                    e.status == "Success" ? "success" : "danger"
                  } badge-pill p-2`}
                >
                  {e.status}
                </span>
              </td>
              <td style={{ fontSize: "0.8rem" }}> {e.date}</td>

              <td>
                <div className="col m-2">
                  <Button
                    onClick={() => {
                      openInNewTab(e);
                    }}
                    variant="danger"
                    className="rounded-0"
                  >
                    {"Print"}
                  </Button>
                </div>
                {e.status === "Success" &&
                  (user.pkid == 1 ||
                    user.pkid == 3 ||
                    user.pkid == 8 ||
                    user.pkid == 9 ||
                    user.pkid == 6 ||
                    user.pkid == 13 ||
                    user.pkid == 12 ||
                    user.pkid == 22) && (
                    <div className="col m-2">
                      <Button
                        onClick={() => {
                          rehitVerification(
                            e.fullresponse.data.bankRRN,
                            e.a_aadharno,
                            e.a_bankname,
                            e.amount
                          );
                        }}
                        variant="info"
                        className="rounded-0"
                        //  disabled={user.pkid != 9}
                      >
                        {" "}
                        Rehit
                      </Button>
                    </div>
                  )}
              </td>
            </tr>
          ))}
        </table>
      </div>

      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex">
        <select
          className="form-control"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default AEPSReconcileReportTable;
