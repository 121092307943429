import React, { useEffect, useState } from "react";
import swal2 from "@sweetalert/with-react";
import swal from "sweetalert";
import Lottie from "lottie-react";
import { Modal, Button, Image, Row, Col } from 'react-bootstrap';
import successPopupGif from "./../../assets/animation/SuccessPopup.json";
import failedPopupGif from "./../../assets/animation/FailedPopup.json";

export const balanceEnquirySuccessPopup = (
  aadhar,
  balance,
  bank,
  tranxtID,
  mobile,
  callback
) => {
  swal2(
    <React.Fragment>
      <table
        style={{
          width: "100%",
          height: "40vh",
          overflowY: "scroll",
          border: "0px solid #979797",
        }}
      >
        <tr
          style={{
            width: "100%",
            border: "0px solid #000",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "center",
              border: "0px solid #979797",
            }}
          >
            <img
              style={{ height: "2rem", width: "2rem" }}
              src={require("../../assets/images/success_tick.jpg")}
            />
            <span>Transaction Success</span>
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
              width: "50%",
              background: "none",
            }}
          >
            <div onClick={callback} className="btn fw-bold btn-sm btn-success">
              <img
                src={require("./../../assets/images/download.png")}
                style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
              />
              {"Print Receipt"}
            </div>
          </td>
        </tr>

        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {" Aadhar Number :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {`XXXXXXXX${aadhar.substr(8, 12)}`}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {"Transaction ID :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {tranxtID}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {"Bank Name :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {bank}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {" Mobile Number :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {mobile}
          </td>
        </tr>
        <tr
          style={{
            width: "100%",
            border: "1px solid #EEEEEE",
            margin: "8px 0px",
            background: "none",
          }}
        >
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "end",
              border: "0px solid #979797",
            }}
          >
            {"Balance :"}
          </td>
          <td
            style={{
              padding: "5px",
              width: "50%",
              textAlign: "start",
              border: "0px solid #979797",
            }}
          >
            {`Rs ${balance}`}
          </td>
        </tr>
      </table>
    </React.Fragment>
  );
};

export const cashWithdrawlSuccessPopup = (
  aadhar,
  amount,
  bank,
  tranxtID,
  mobile,
  balance,
  callback,
  callback2,
  service
) => {
  console.table({
    aadhar,
    amount,
    bank,
    tranxtID,
    mobile,
    balance,
    callback,
    callback2,
  });
  console.log(callback2);
  swal2(
    <React.Fragment>
      <div>
        <table
          style={{
            width: "100%",
            height: "40vh",
            overflowY: "scroll",
            border: "0px solid #979797",
          }}
        >
          <tr
            style={{
              width: "100%",
              border: "0px solid #000",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "center",
                border: "0px solid #979797",
              }}
            >
              <img
                style={{ height: "2rem", width: "2rem" }}
                src={require("../../assets/images/success_tick.jpg")}
              />
              <span>Transaction Success</span>
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
                width: "50%",
                background: "none",
              }}
            >
              <div
                onClick={callback}
                className="btn fw-bold btn-sm btn-success"
              >
                <img
                  src={require("./../../assets/images/download.png")}
                  style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
                />
                {"Print Receipt"}
              </div>
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {" Service :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {`${service}`}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {" Aadhar Number :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {`XXXXXXXX${aadhar.substr(8, 12)}`}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Transaction ID :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {tranxtID}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Bank Name :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {bank}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {" Mobile Number :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {mobile}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Transaction Amount :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {`Rs ${amount}`}
            </td>
          </tr>
        </table>

        <button className="btn btn-success" onClick={callback2}>
          {" "}
          Mini Statement
        </button>
      </div>
    </React.Fragment>
  );
};

export const cashWithdrawlSuccessPopup2 = (
  aadhar,
  amount,
  bank,
  tranxtID,
  mobile,
  balance,
  callback,
  callback2
) => {
  console.table({
    aadhar,
    amount,
    bank,
    tranxtID,
    mobile,
    balance,
    callback,
    callback2,
  });

  swal2(
    <React.Fragment>
      <div>
        <table
          className="my-table transparent-table"
          style={{
            //  width: "30px",
            // height: "40vh",
            overflowY: "scroll",
            // border: "0px solid #979797",
          }}
        >
          <tr>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0px', width: "100%" }}>
              <img src={require('../../assets/images/company/logo_square.png')} style={{ width: "6.5rem", height: "auto" }} />
              {/* <img src={require("../../assets/images/yes_bank_Logo.png")} style={{ width: "6.5rem", height: "auto" }} /> */}
            </div>
          </tr>
          <tr
            style={{
              width: "100%",
              border: "0px solid #000",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                // textAlign: "center",
                border: "0px solid #979797",
              }}
            >
              <img
                style={{ height: "2rem", width: "2rem" }}
                src={require("../../assets/images/success_tick.jpg")}
              />
              <span> Success</span>
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "end",
                border: "0px solid #979797",
                width: "50%",
                background: "none",
              }}
            >
              <div
                onClick={callback}
                className="btn fw-bold btn-sm btn-success"
                style={{ borderRadius: ".4rem", border: "1px solid #00A552 ", background: "#eaf9ee ", color: "#00A552 " }}
              >
                <img
                  src={require("./../../assets/images/download.png")}
                  style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
                />
                {"Print Receipt"}
              </div>
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              // border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                // textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {" Aadhar Number :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {`XXXXXXXX${aadhar.substr(8, 12)}`}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              // border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                //  textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Transaction ID :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {tranxtID}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              //   border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                //  textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Bank Name :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {bank}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              //  border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                // textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {" Mobile Number :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {mobile}
            </td>
          </tr>
          <tr
            style={{
              width: "100%",
              // border: "1px solid #EEEEEE",
              margin: "8px 0px",
              background: "none",
            }}
          >
            <td
              style={{
                padding: "5px",
                width: "50%",
                // textAlign: "end",
                border: "0px solid #979797",
              }}
            >
              {"Transaction Amount :"}
            </td>
            <td
              style={{
                padding: "5px",
                width: "50%",
                textAlign: "start",
                border: "0px solid #979797",
              }}
            >
              {`Rs ${amount}`}
            </td>
          </tr>
        </table>

        <button style={{ borderRadius: ".4rem", border: "1px solid #00A552 ", background: "#eaf9ee ", color: "#00A552 " }} className="btn btn-success" onClick={callback2}>
          {" "}
          Mini Statement
        </button>
      </div>
    </React.Fragment>
  );
};

export const dmtTransactionSuccessPopup2 = (tranxtID, amount, message) => {
  swal2(
    <React.Fragment>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <img
            style={{ height: "3rem", width: "auto" }}
            src={require("../../assets/images/success_tick.jpg")}
          />
          <h3>{message}</h3>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4>{`Transaction ID: ${tranxtID}`}</h4>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h4>{`Transaction Amount: ${amount}`}</h4>
        </div>
      </div>
    </React.Fragment>
  );
};

export const dmtTransactionSuccessPopup = (tranxtID, amount, message) => {
  swal(message, `Transaction Amount ${amount}`, "success");
};

export const dmtTransactionFailedPopup = (tranxtID, amount, message) => {
  swal(message, `Transaction Amount ${amount}`, "error");
};

export const customerKYCSuccessPopup = (tranxtID, data, message) => {
  swal(message, data, "success");
};

export const customerKYCFailedPopup = (tranxtID, data, message) => {
  swal(message, data, "error");
};

export const bumppyKYCWarning = (callback) => {
  swal({
    title: "Warning",
    text: "Do KYC First for Enable DMT",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      callback();
    }
  });
};

export const bbpsRechargeSuccessPopup = (tranxtID, amount, message) => {
  swal(message, `Recharge Amount ${amount}`, "success");
};

export const bbpsRechargeFailedPopup = (tranxtID, amount, message) => {
  swal(message, `Recharge Amount ${amount}`, "error");
};

export const bbpsBillSuccessPopup = (tranxtID, amount, message) => {
  swal(message, `Bill Amount ${amount}`, "success");
};

export const bbpsBillFailedPopup = (tranxtID, amount, message) => {
  swal(message, `Bill Amount ${amount}`, "error");
};

export const successPopup = (message, message2) => {
  swal(message, message2, "success");
};

export const failedPopup = (message, message2) => {
  swal(message, message2, "error");
};

export const dmtSuccessPopup = (amount, message, callback) => {
  swal2(
    <React.Fragment>
      <div>
        <div className="row">
          {/* <img
            style={{ height: "2rem", width: "2rem" }}
            src={require("../../assets/images/success_tick.jpg")}
          /> */}
          {/* <span>Transaction Success</span> */}
          <div
            onClick={callback}
            className="btn ml-auto fw-bold btn-sm btn-success"
          >
            <img
              src={require("./../../assets/images/download.png")}
              style={{ height: "1rem", width: "1rem", marginRight: "2px" }}
            />
            {"Print Receipt"}
          </div>
        </div>
        <div className="row text-center justify-content-center">
          <img
            className="mx-auto p-3"
            src={require("../../assets/images/thanks.png")}
            style={{ height: "auto", width: "40%" }}
          />
        </div>

        <div className="row text-center justify-content-center">
          <span style={{ fontSize: "1.5rem" }}> {`${message}`}</span>
        </div>
        <div className="row text-center justify-content-center">
          <span style={{ fontSize: "1.5rem" }}>
            {" "}
            {`Transaction Amount : Rs ${amount}`}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export const DmtSuccessPopup = ({ handleClose, show, amount, message, callback, status, date, transactionId }) => {
  const [active, setActive] = useState(false)

  return (
    <div >
      <Modal show={show} onHide={handleClose} className="justify-content-center d-flex align-items-center" >
      <Lottie
          className="mx-auto p-1"
          animationData={status == 'success' ? successPopupGif : failedPopupGif}
          loop={false}
          style={status == 'success' ? { height: "auto", width: "40%" } : { width: '60%' }}
        />
        <Modal.Header className="row text-center justify-content-center fw-bold" style={{ border: 'none' }}>
          <Modal.Title
            style={{
              height: "3rem",
              padding: '0 30px',
              fontSize: '30px',
              color: status == 'success' ? '#6cc1a1' : '#FF4D4D',
              justifyContent: 'center',
              fontFamily: 'poppins'
            }}
          >{status}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="col text-center justify-content-center" style={{ fontFamily: 'poppins', margin: 0, padding: 0 }}>
          <p>{`Transaction Amount : ₹ ${amount}`}</p>
          <p>{`Transaction ID : ${transactionId}`}</p>
          <p>{`Date & Time : ${date}`}</p>
          <p>{`Message : ${message}`}</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center align-items-center ' style={{ margin: '0 10px 10px 10px', padding: '10px 30px', gap: 10 }}>
          <Button
            variant={"outline-success d-flex justify-content-center"}
            onClick={() => {
              callback();
              setActive(true)
            }}
            style={{ backgroundColor: active ? '#6cc1a1' : '', color: '#6cc1a1', borderColor: '#6cc1a1', flex: 1, borderRadius: 8, }}

            onMouseEnter={(e) => {
              e.target.style.backgroundColor = active ? '#6cc1a1' : '#a6dbc3';
              e.target.style.color = 'white';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = active ? '#6cc1a1' : 'initial';
              e.target.style.color = active ? 'white' : '#6cc1a1';
            }}
          >
            Print
          </Button>

          <Button
            variant={"outline-success d-flex justify-content-center"}
            onClick={handleClose}
            style={{ color: '#6cc1a1', borderColor: '#6cc1a1', flex: 1, borderRadius: 8, }}

            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#a6dbc3';
              e.target.style.color = 'white'
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'initial';
              e.target.style.color = '#6cc1a1'
            }}
          >
            Home
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const BillPaymentSuccessPopup = ({ handleClose, show, amount, message, callback, status, date, transactionId, operatorName, serviceName }) => {
  const [active, setActive] = useState(false)

  return (
    <>
      <Modal show={show} onHide={handleClose} className="justify-content-center d-flex align-items-center" >
        <div className="d-flex p-3" style={{ fontWeight: 'bold', justifyContent: 'space-between' }}>
          <span className='fw-bold'>Bill Details</span>
          {/* <Image
            src={require("./../../assets/images/bbps/bbps_logo1.png")}
            style={{ height: "auto", width: "20%", maxWidth: '450px' }}
          /> */}
        </div>
        <Lottie
          className="mx-auto p-1"
          animationData={status == 'success' ? successPopupGif : failedPopupGif}
          loop={false}
          style={status == 'success' ? { height: "auto", width: "40%" } : { width: '60%' }}
        />
        <Modal.Header className="row text-center justify-content-center " style={{ margin: 10, fontWeight: 'bold' }}>
          <Modal.Title
            style={{
              fontSize: '20px',
              justifyContent: 'center',
              fontFamily: 'poppins'

            }}
          >
            {`Transaction Amount : ₹ ${amount}`}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="col text-center d-flex" style={{ fontFamily: 'poppins', padding: 0, justifyContent: 'space-between' }}>
          <ul style={{ flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', width: '100%', fontSize: '12px' }}>
            <li className="grid-example" style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
              <span style={{ flex: 1, display: 'flex' }}>Transaction ID</span>
              <span style={{ flex: 1, display: 'flex' }}>{transactionId || 'null'}</span>
            </li>
            <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
              <span style={{ flex: 1, display: 'flex' }}>Message </span>
              <span style={{ flex: 1, display: 'flex', textAlign: "left" }}>{message || 'null'}</span>
            </li>
            <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
              <span style={{ flex: 1, display: 'flex' }}>Bill Date</span>
              <span style={{ flex: 1, display: 'flex' }}>{date || 'null'}</span>
            </li>
            <li style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <span style={{ flex: 1, display: 'flex' }}>Service Name</span>
              <span style={{ flex: 1, display: 'flex' }}>₹ {serviceName || 'null'}</span>
            </li>
            <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex', flexWrap: 'wrap' }}>
              <span style={{ flex: 1, display: 'flex' }}>Operator Name</span>
              <span style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>{operatorName || '-'}</span>
            </li>
            <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
              <span style={{ flex: 1, display: 'flex' }}>Status</span>
              <span style={{ flex: 1, display: 'flex' }}>{status || 'null'}</span>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center align-items-center ' style={{ margin: '0 10px 10px 10px', padding: '10px 30px', gap: 10 }}>
          <Button
            variant={"outline-success d-flex justify-content-center"}
            onClick={() => {
              callback();
              setActive(true)
            }}
            style={{ backgroundColor: active ? '#6cc1a1' : '', color: '#6cc1a1', borderColor: '#6cc1a1', flex: 1, borderRadius: 8, }}

            onMouseEnter={(e) => {
              e.target.style.backgroundColor = active ? '#6cc1a1' : '#a6dbc3';
              e.target.style.color = 'white';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = active ? '#6cc1a1' : 'initial';
              e.target.style.color = active ? 'white' : '#6cc1a1';
            }}
          >
            Print
          </Button>
          <Button
            variant={"outline-success d-flex justify-content-center"}
            onClick={handleClose}
            style={{ color: '#6cc1a1', borderColor: '#6cc1a1', flex: 1, borderRadius: 8, }}

            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#a6dbc3';
              e.target.style.color = 'white'
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = 'initial';
              e.target.style.color = '#6cc1a1'
            }}
          >
            Home
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const AEPSSettlementPopup = ({ handleClose, show, amount, message, callback, status, date, services, transactionId, transactionMode, accountNo, bankName, userId, dateNTime }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose} className="justify-content-center d-flex align-items-center" >
        <div className="d-flex flex-column p-3" style={{ maxWidth: '450px' }}>
          <Lottie
            className="mx-auto p-1 d-flex"
            animationData={status == 'success' ? successPopupGif : failedPopupGif}
            loop={false}
            style={status == 'success' ? { width: "60%" } : { width: "90%" }}
          />
          <Modal.Header className="row text-center justify-content-center " style={{ margin: 10, fontWeight: 'bold' }} >
            <Modal.Title
              style={{
                fontSize: '20px',
                justifyContent: 'center',
                fontFamily: 'poppins'
              }}
            >
              {`Transaction Amount : ₹ ${amount}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="col text-center d-flex" style={{ fontFamily: 'poppins', padding: 0, justifyContent: 'space-between', }}>
            <ul style={{ flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', width: '100%', fontSize: '12px' }}>
              <li className="grid-example" style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
                <span style={{ flex: 1, display: 'flex' }}>Services</span>
                <span style={{ flex: 1, display: 'flex' }}>{services || 'null'}</span>
              </li>
              <li className="grid-example" style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
                <span style={{ flex: 1, display: 'flex' }}>Transaction ID</span>
                <span style={{ flex: 1, display: 'flex' }}>{transactionId || 'null'}</span>
              </li>
              <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
                <span style={{ flex: 1, display: 'flex' }}>Message </span>
                <span style={{ flex: 1, display: 'flex', textAlign: "left" }}>{message || 'null'}</span>
              </li>
              <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
                <span style={{ flex: 1, display: 'flex' }}>TransactionMode</span>
                <span style={{ flex: 1, display: 'flex' }}>{transactionMode || 'null'}</span>
              </li>
              <li style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <span style={{ flex: 1, display: 'flex' }}>AccountNo</span>
                <span style={{ flex: 1, display: 'flex' }}>{accountNo || 'null'}</span>
              </li>
              <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex', flexWrap: 'wrap' }}>
                <span style={{ flex: 1, display: 'flex' }}>BankName</span>
                <span style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>{bankName || 'null'}</span>
              </li>
              <li style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
                <span style={{ flex: 1, display: 'flex' }}>UserId</span>
                <span style={{ flex: 1, display: 'flex' }}>{userId || 'null'}</span>
              </li>
              <li className="grid-example" style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
                <span style={{ flex: 1, display: 'flex' }}> Date & Time</span>
                <span style={{ flex: 1, display: 'flex' }}>{dateNTime || 'null'}</span>
              </li>
              <li className="grid-example" style={{ display: 'flex', justifyContent: 'flex-start', display: 'flex' }}>
                <span style={{ flex: 1, display: 'flex' }}> Status</span>
                <span style={{ flex: 1, display: 'flex' }}>{status || 'null'}</span>
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-center align-items-center ' style={{ margin: '0 10px 10px 10px', padding: '10px 30px', gap: 10 }}>
            <Button
              variant={"outline-success d-flex justify-content-center"}
              onClick={handleClose}
              style={{ color: '#6cc1a1', borderColor: '#6cc1a1', flex: 1, borderRadius: 8, }}

              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#a6dbc3';
                e.target.style.color = 'white'
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'initial';
                e.target.style.color = '#6cc1a1'
              }}
            >
              Home
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

