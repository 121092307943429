import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button, Image, Card } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import { useHistory } from "react-router";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import { uniqueID2 } from "../../utils/utilFuctions";
import { generateHashKey2 } from "../../utils/utilFuctions";
import DatePicker from "react-datepicker";
import { ContextMenuTrigger } from "react-contextmenu";
import { GlobalContext } from "../../Context/GlobalState";
import { basicAuth } from "../../config";
import { validatePanCard } from "../../utils/utilFuctions";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { set } from "react-hook-form";
import UserLien from "../UserLien/UserLien";
import AepsOnboarding from "../user-view/AepsOnboarding";
import CommercialsSetup from "../user-view/CommercialsSetup";

const EditUserDetails = ({ agentId, us_code , usr_mobile }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  const [clickedImageURL, setClickedImageURL] = useState("");
  const [personalDetails, setPersonalDetails] = useState({});
  const [aepsSettlementAccounts, setAepsSettlementAccounts] = useState([]);
  const [pgSettlementAccounts, setPgSettlementAccounts] = useState([]);

  const ProfileDetails = async () => {
    try {
      // setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );
      console.log("test1");

      var raw = JSON.stringify({
        UserID: agentId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Admin/getUserViewProfileDetails",
        requestOptions
      );
      const data = await res.json();

      if (data.responseCode === "00") {
        setPersonalDetails(data.data);
        setAepsSettlementAccounts(data.aepsSettlementBanks);
        // setPgSettlementAccounts(data.pgSettlementBanks);
        console.log("Data mapped successfully");
      } else {
        swal(
          "User Not Found",
          "Please Enter Valid ID Or Mobile number",
          "error"
        );
      }
      // setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    ProfileDetails();
  }, [agentId , usr_mobile]);

  return (
    <div className="px-4" style={{ fontFamily: "auto" }}>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}

<div
  style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "20px",
  }}
>
  <div
    defaultActiveKey="profile"
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "200px",
      border: "none",
      padding: "10px",
      borderRadius: "5px",
      backgroundColor: "#fff",
    }}
  >
    <div
      style={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: activeTab === 0 ? "#d3d3d3" : "#ffffff",
        color: "black",
        marginBottom: "5px",
        borderRadius: "3px",
        textAlign: "center",
        width: "100%",
        border: "none",
      }}
      onClick={() => handleTabClick(0)}
    >
      Personal Information
    </div>
    <div
      style={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: activeTab === 1 ? "#d3d3d3" : "#ffffff",
        color: "black",
        marginBottom: "5px",
        borderRadius: "3px",
        textAlign: "center",
        width: "100%",
        border: "none",
      }}
      onClick={() => handleTabClick(1)}
    >
      Network Information
    </div>
    <div
      style={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: activeTab === 2 ? "#d3d3d3" : "#ffffff",
        color: "black",
        marginBottom: "5px",
        borderRadius: "3px",
        textAlign: "center",
        width: "100%",
        border: "none",
      }}
      onClick={() => handleTabClick(2)}
    >
      Bank Information
    </div>
    <div
      style={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: activeTab === 3 ? "#d3d3d3" : "#ffffff",
        color: "black",
        marginBottom: "5px",
        borderRadius: "3px",
        textAlign: "center",
        width: "100%",
        border: "none",
      }}
      onClick={() => handleTabClick(3)}
    >
      Document Images
    </div>
    <div
      style={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: activeTab === 4 ? "#d3d3d3" : "#ffffff",
        color: "black",
        marginBottom: "5px",
        borderRadius: "3px",
        textAlign: "center",
        width: "100%",
        border: "none",
      }}
      onClick={() => handleTabClick(4)}
    >
      Lein Amount
    </div>
    <div
      style={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: activeTab === 5 ? "#d3d3d3" : "#ffffff",
        color: "black",
        marginBottom: "5px",
        borderRadius: "3px",
        textAlign: "center",
        width: "100%",
        border: "none",
      }}
      onClick={() => handleTabClick(5)}
    >
  Aeps On - Boarding
    </div>
    <div
      style={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: activeTab === 6 ? "#d3d3d3" : "#ffffff",
        color: "black",
        marginBottom: "5px",
        borderRadius: "3px",
        textAlign: "center",
        width: "100%",
        border: "none",
      }}
      onClick={() => handleTabClick(6)}
    >
  Commercials - Setup
    </div>
  </div>

  <div style={{ flex: 1 }}>
    {activeTab === 0 && (
      <div>
        <PersonalInfo details={personalDetails} />
      </div>
    )}
    {activeTab === 1 && (
      <div>
        <NetworkInfo details={personalDetails} />
      </div>
    )}
    {activeTab === 2 && (
      <div>
        <BankInfo
          details={{
            aepsSettlementAccounts,
            pgSettlementAccounts,
            personalDetails,
          }}
          agentId={ agentId}
          refresh ={ProfileDetails}
        />
      </div>
    )}
    {activeTab === 3 && (
      <div>
        <ImagesData details={personalDetails} />
      </div>
    )}
     {activeTab === 4 && (
      <div>
        <UserLien details={personalDetails} />
      </div>
    )}
       {activeTab === 5 && (
      <div>
        <AepsOnboarding   agentId={ agentId} details={personalDetails} />
      </div>
    )}
       {activeTab === 6 && (
      <div>
        <CommercialsSetup   agentId={ agentId} details={personalDetails} />
      </div>
    )}
  </div>
</div>


    </div>
  );
};

export default EditUserDetails;

const PersonalInfo = ({ details, agentId , usr_mobile }) => {
  const [formValues, setFormValues] = useState(details);

  const { username, password } = basicAuth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();

  const [State, setState] = useState("");
  const [City, setCity] = useState("");

  const [uploadedDocs, setUploadedDoc] = useState({
    profile: false,
    POI: false,
    POA_Front: false,
    POA_Back: false,
    sign: false,
    gstdoc: false,
  });
  const [clickedImageURL, setClickedImageURL] = useState("");
  const userId = JSON.parse(localStorage.getItem("kyc"));

  const Datehandler = (date) => {
    setFormValues({ ...formValues, DOB: date });
  };

  const imagePath = `${BASE_URL_API_TRANXT_API}/users_kyc_doc`;

  const dateToStr = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

  const pincodeData = (pincode, type) => {
    console.log({ pincode, type });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Pincode: pincode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_API_TRANXT_API}/api/kyc/pindata.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.length > 0) {
            setCity(result[0].city);
            setState(result[0].state);
            if (type == "1") {
              setFormValues({
                ...formValues,
                City: result[0].city,
                State: result[0].state,
              });
            } else if (type == "2") {
              setFormValues({
                ...formValues,
                ShopCity: result[0].city,
                ShopState: result[0].state,
              });
            }
          }
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (formValues.Pincode?.length == 6) {
      pincodeData(formValues.Pincode, "1");
    }
  }, [formValues.Pincode]);

  useEffect(() => {
    if (formValues.ShopPin?.length == 6) {
      pincodeData(formValues.ShopPin, "2");
    }
  }, [formValues.ShopPin]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {

    setFormValues(details);
  }, [details]);

  // useEffect(() => {
  //   setFormValues({
  //     ...details,
  //     level_1_parent_code: details?.level_1_parent.toString().split("|")[0],
  //     level_1_parent_name: details?.level_1_parent.toString().split("|")[1],
  //     level_1_parent_mobile: details?.level_1_parent.toString().split("|")[2],
  //     level_2_parent_code: details?.level_2_parent.toString().split("|")[0],
  //     level_2_parent_name: details?.level_2_parent.toString().split("|")[1],
  //     level_2_parent_mobile: details?.level_2_parent.toString().split("|")[2],
  //     sales_code: details?.level_1_parent.toString().split("|")[3],
  //     sales_name: details?.level_1_parent.toString().split("|")[4],
  //     sales_mobile: details?.level_1_parent.toString().split("|")[5],
  //     // user_type: details?.level_1_parent.toString().split("|")[6],
  //   });
  // }, [details]);
  const positionStyle1 = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const requestTPin = async (usr_mobile) => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(usr_mobile);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        mobileno: usr_mobile,
        reset_type: "TPIN",
        password: usr_mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        swal("Success", "T PIN Requested Successfully", "success");
      } else {
        swal("Failed", "T PIN Requested Failed", "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const requestMPin = async (usr_mobile) => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(usr_mobile);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        mobileno: usr_mobile,
        reset_type: "MPIN",
        password: usr_mobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        swal("Success", "M PIN Requested Successfully", "success");
      } else {
        swal("Failed", "M PIN Requested Failed", "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  return (
    <div className="px-4" style={{ fontFamily: "auto" }}>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}
      {formValues.usr_mobile ? (
        <div>

        <Row className="d-flex justify-content-between align-items-center">
        {/* Profile text */}
        <Col>
          <h4 className="text-primary"><b>My Profile</b></h4>
        </Col>
        <Row>
            <Col md={6} lg={6} sm={12}>
              <Form.Label className="text-primary">
                <b>{formValues?.level_1_parent?.toString()?.split("|")[6]}  </b>
             
              </Form.Label>
            </Col>
          </Row>
        {/* Buttons */}
        <Col className="d-flex justify-content-end">
          <div className="d-flex gap-3">
                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                        fontSize: "4px !important",
                      }}
                      className="btn rounded-100  m-0 p-1"
                    >
                      {" "}
                      <div
                      onClick={() => requestTPin(agentId)}
                      >
                        <div className="hover-container">
                          <img
                            src={require("../../../assets/images/Reset T-PIN.png")}
                            style={{ width: "" }}
                          />

                          {/* <div className="hover-text">Reset T-PIN</div> */}
                          <div
                            style={positionStyle1}
                            className="hover-text bg-dark "
                          >
                            Reset T-PIN
                          </div>
                        </div>
                      </div>
                      {/* Rehit */}
                    </Button>

                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                    >
                      {" "}
                      <div
                        onClick={() => requestMPin(agentId)}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Reset M-PIN.png")}
                          style={{ width: "" }}
                        />
                        {/* <div style={{"fontFamily":"Poppins",  color:'rgba(248, 204, 44, 1)', }} className="hover-text">Reset M-PIN</div> */}
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Reset M-PIN
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                    >
                      {" "}
                      <div className="hover-container">
                        <a href={`/admin/user_access_manage?id`}>
                          <img
                            src={require("../../../assets/images/Update Access.png")}
                            alt="Clickable Image"
                          />
                        </a>
                        {/* <div div style={{ "fontFamily":"Poppins", color:'rgba(135, 135, 240, 1)', }} className="hover-text">Update Access</div> */}
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Update Access
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      // onClick={() => {
                      //   setDetails(e);
                      //   setModalOpen3(true);
                      // }}
                    >
                      {" "}
                      <div className="hover-container">
                        <img
                          src={require("../../../assets/images/Edit Details.png")}
                          alt="Clickable Image"
                        />
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Edit Details
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      // onClick={() => {
                      //   setDetails(e);
                      //   setModalOpen2(true);
                      // }}
                    >
                      {" "}
                      <div className="hover-container">
                        <img
                          src={require("../../../assets/images/Update Access.png")}
                          alt="Clickable Image"
                        />
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Update Tariff
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-1"
                      // onClick={() => {
                      //   openInNewTab(
                      //     `/marcom/marcom-doc-print?userid=${e.uCode}&pk_user_id=${e.id}`
                      //   );
                      // }}
                    >
                      {" "}
                      <div className="hover-container">
                        <div>
                          <img
                            src={require("../../../assets/images/printer.png")}
                            alt="Clickable Image"
                          />
                        </div>
                        {/* <div div style={{ "fontFamily":"Poppins", color:'rgba(135, 135, 240, 1)', }} className="hover-text">Update Access</div> */}
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          Dowload Marcom
                        </div>
                      </div>
                    </Button>
                 

                         </div>
        </Col>
      </Row>
     
      <Row>
 


   
      <Col md={12} className="mb-4">
        <div className="profile-section">
          <div className="d-flex justify-content-between align-items-center">
            <h5><b>Personal Information</b></h5>
            <button className="btn btn-link text-primary">Edit</button>
          </div>
          <hr />
          <Row>
            <Col md={6}>
              <p><b>First Name:</b> {formValues.usr_first_name}</p>
            </Col>
            <Col md={6}>
              <p><b>Last Name:</b> {formValues.usr_last_name}</p>
            </Col>
            <Col md={6}>
              <p><b>Email Address:</b> {formValues.usr_email}</p>
            </Col>
            <Col md={6}>
              <p><b>Phone:</b> {formValues.usr_mobile}</p>
            </Col>
            <Col md={6}>
              <p><b>Gender:</b> {formValues.gender}</p>
            </Col>
            <Col md={6}>
              <p><b>Date of Birth:</b> {formValues.userdob}</p>
            </Col>
          </Row>
        </div>
      </Col>
   
      <Col md={12} className="mb-4">
        <div className="profile-section">
          <div className="d-flex justify-content-between align-items-center">
            <h5><b>Address</b></h5>
            <button className="btn btn-link text-primary">Edit</button>
          </div>
          <hr />
          <Row>
            <Col md={6}>
              <p><b>Country:</b> {formValues.usr_country}</p>
            </Col>
            <Col md={6}>
              <p><b>City/State:</b> {formValues.usr_city}, {formValues.usr_state}</p>
            </Col>
            <Col md={6}>
              <p><b>Postal Code:</b> {formValues.usr_pin_code}</p>
            </Col>
          </Row>
        </div>
      </Col>
   
      <Col md={12} className="mb-4">
        <div className="profile-section">
          <div className="d-flex justify-content-between align-items-center">
            <h5><b>Shop Details</b></h5>
            <button className="btn btn-link text-primary">Edit</button>
          </div>
          <hr />
          <Row>
            <Col md={6}>
              <p><b>Shop Name:</b> {formValues.shop_name}</p>
            </Col>
            <Col md={6}>
              <p><b>Shop Address:</b> {formValues.shop_address}</p>
            </Col>
            <Col md={6}>
              <p><b>City/State:</b> {formValues.shop_city}, {formValues.shop_state}</p>
            </Col>
            <Col md={6}>
              <p><b>Pincode:</b> {formValues.shop_pin_code}</p>
            </Col>
          </Row>
        </div>
      </Col>
   
      <Col md={12}>
        <div className="profile-section">
          <div className="d-flex justify-content-between align-items-center">
            <h5><b>Additional Details</b></h5>
          </div>
          <hr />
          <Row>
            <Col md={4}>
              <p><b>Pan Card Number:</b> {formValues.pan_card_no}</p>
            </Col>
            <Col md={4}>
              <p><b>Aadhar Card Number:</b> {formValues.aadhar_card_no}</p>
            </Col>
            <Col md={4}>
              <p><b>GST Number:</b> {formValues.gst_number || "Not Provided"}</p>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
    </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

const NetworkInfo = ({ details, agentId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const { username, password } = basicAuth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [dob, setDob] = useState(new Date());
  const [kycData, setKycData] = useState({});
  const [WalletBal, setWalletBal] = useState({});
  const [userDetails, setUserDetails] = useState({});
  // const [agentId, setAgentId] = useState("");
  const [State_Code, setStateCode] = useState([]);
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [verifiedKycData, setVerifiedKycData] = useState(null);
  const [formValues, setFormValues] = useState({
    mainBal: "",
    AepsBal: "",
    PgBal: "",
    LeinBal: "",
    FirstName: "",
    LastName: "",
    Gender: "",
    DOB: new Date(),
    Mobile: "",
    Email: "",
    Address: "",
    Pincode: "",
    City: "",
    State: "",
    ShopAddress: "",
    ShopPin: "",
    ShopCity: "",
    ShopState: "",
    AdharFront: "",
    AdharBack: "",
    GstDoc: "",
    Userimage: "",
    UserSign: "",
    UserImageWithAdhar: "",
    ShopImage: "",
    ShopName: "",
    Address: "",
    Pincode: "",
    pancardno: "",
    aadharcardno: "",
    gstnumber: "",
    OTP: "",
  });

  const [verification, setVerification] = useState({
    PAN: false,
    ADHAR: false,
    panDetails: null,
    adhaarDetails: null,
    otpSent: false,
    aadhaar_request_id: "",
  });

  const [uploadedDocs, setUploadedDoc] = useState({
    profile: false,
    POI: false,
    POA_Front: false,
    POA_Back: false,
    sign: false,
    gstdoc: false,
  });
  const [clickedImageURL, setClickedImageURL] = useState("");
  const userId = JSON.parse(localStorage.getItem("kyc"));

  const fetchUserKycData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
          // history.push("/kyc/user-kyc-view");
        }
        setKycData(data.data[0]);
        // setFormValues({
        // ...formValues,
        // PAN: data.data[0].pancardno,
        // ADHAR: data.data[0].aadharcardno,
        // GST: data.data[0].gstnumber,
        // });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const Datehandler = (date) => {
    setFormValues({ ...formValues, DOB: date });
    const inputDate = new Date(date);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
    if (inputDate <= eighteenYearsAgo) {
    } else {
      setFormErrors({
        ...formErrors,
        DOB: "*Please enter Date before 18 years",
      });
    }
    setDob(date);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const imagePath = `${BASE_URL_API_TRANXT_API}/users_kyc_doc`;

  const dateToStr = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

  const verificationData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: agentId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data2.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        let selfiWithDoc = "",
          selfiWithEmployee = "";

        if (data.data.relation.includes("|")) {
          selfiWithDoc = data.data.relation.split("|")[0];
          selfiWithEmployee = data.data.relation.split("|")[1];
        }
        let imguser = "";
        if (data.data.imguser != "") {
          imguser = `${imagePath}/${data.data.imguser}.jpeg?v=${uniqueID2()}`;
        }
        let imgpancard = "";
        if (data.data.imgpancard != "") {
          imgpancard = `${imagePath}/${
            data.data.imgpancard
          }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharfront = "";
        if (data.data.imgaadharfront != "") {
          imgaadharfront = `${imagePath}/${
            data.data.imgaadharfront
          }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharback = "";
        if (data.data.imgaadharback != "") {
          imgaadharback = `${imagePath}/${
            data.data.imgaadharback
          }.jpeg?v=${uniqueID2()}`;
        }
        let signature = "";
        if (data.data.signature != "") {
          signature = `${imagePath}/${
            data.data.signature
          }.jpeg?v=${uniqueID2()}`;
        }

        let gstdoc = "";
        if (data.data.gstdoc != "") {
          gstdoc = `${imagePath}/${data.data.gstdoc}.jpeg?v=${uniqueID2()}`;
        }

        if (data.data.relation.includes("|")) {
          selfiWithDoc = `${imagePath}/${
            data.data.relation.split("|")[0]
          }.jpeg?v=${uniqueID2()}`;
          selfiWithEmployee = `${imagePath}/${
            data.data.relation.split("|")[1]
          }.jpeg?v=${uniqueID2()}`;
        }

        setUserDetails(data.data);

        setKycData({
          ...kycData,
          imguser,
          imgpancard,
          imgaadharfront,
          imgaadharback,
          signature,
          gstdoc,
          selfiWithDoc,
          selfiWithEmployee,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const verificationData2 = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: agentId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data2.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        let selfiWithDoc = "",
          selfiWithEmployee = "";

        if (data.data.relation.includes("|")) {
          selfiWithDoc = data.data.relation.split("|")[0];
          selfiWithEmployee = data.data.relation.split("|")[1];
        }
        let imguser = "";
        if (data.data.imguser != "") {
          imguser = `${imagePath}/${data.data.imguser}.jpeg?v=${uniqueID2()}`;
        }
        let imgpancard = "";
        if (data.data.imgpancard != "") {
          imgpancard = `${imagePath}/${
            data.data.imgpancard
          }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharfront = "";
        if (data.data.imgaadharfront != "") {
          imgaadharfront = `${imagePath}/${
            data.data.imgaadharfront
          }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharback = "";
        if (data.data.imgaadharback != "") {
          imgaadharback = `${imagePath}/${
            data.data.imgaadharback
          }.jpeg?v=${uniqueID2()}`;
        }
        let signature = "";
        if (data.data.signature != "") {
          signature = `${imagePath}/${
            data.data.signature
          }.jpeg?v=${uniqueID2()}`;
        }

        let gstdoc = "";
        if (data.data.gstdoc != "") {
          gstdoc = `${imagePath}/${data.data.gstdoc}.jpeg?v=${uniqueID2()}`;
        }

        if (data.data.relation.includes("|")) {
          selfiWithDoc = `${imagePath}/${
            data.data.relation.split("|")[0]
          }.jpeg?v=${uniqueID2()}`;
          selfiWithEmployee = `${imagePath}/${
            data.data.relation.split("|")[1]
          }.jpeg?v=${uniqueID2()}`;
        }

        setKycData({
          ...kycData,
          imguser,
          imgpancard,
          imgaadharfront,
          imgaadharback,
          signature,
          gstdoc,
          selfiWithDoc,
          selfiWithEmployee,
        });

        setUserDetails(data.data);

        setFormValues({
          ...formValues,
          ShopName: data.data.shopname,
          FirstName: data.data.userfname,
          LastName: data.data.userlname,
          Gender: data.data.gender,
          DOB: new Date(data.data.dateofbirth),
          Mobile: data.data.usermobile,
          Email: data.data.emailid,
          Address: data.data.useraddress,
          ShopAddress: data.data.shopaddress,
          Pincode: data.data.statecode,
          ShopPin: data.data.shoppincode,
          ShopCity: data.data.shopcity,
          ShopState: data.data.shopstate,
          City: data.data.usercity,
          State: data.data.userstate,
          pancardno: data.data?.pancardno,
          aadharcardno: data.data?.aadharcardno,
          gstnumber: data.data?.gstnumber,
        });

        setUploadedDoc({
          profile: data.data.imguser ? true : false,
          POI: data.data.imgpancard ? true : false,
          POA_Front: data.data.imgaadharfront ? true : false,
          POA_Back: data.data.imgaadharback ? true : false,
          sign: data.data.signature ? true : false,
          gstdoc: data.data.gstdoc ? true : false,
        });

        setVerifiedKycData(data.data);
        setVerification({
          ...verification,
          PAN: data.data.pan_verified == "Y",
          panDetails: data.data.pan_verified_name,
          ADHAR: data.data.aadhaar_verified == "Y",
          adhaarDetails: data.data,
        });
      } else {
        setFormValues({
          FirstName: "",
          LastName: "",
          Gender: "",
          DOB: new Date(),
          Mobile: "",
          Email: "",
          Address: "",
          Pincode: "",
          City: "",
          State: "",
          ShopAddress: "",
          ShopPin: "",
          ShopCity: "",
          ShopState: "",
          AdharFront: "",
          AdharBack: "",
          GstDoc: "",
          Userimage: "",
          UserSign: "",
          UserImageWithAdhar: "",
          ShopImage: "",
          ShopName: "",
          Address: "",
          Pincode: "",
          pancardno: "",
          aadharcardno: "",
          gstnumber: "",
          OTP: "",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const insertVerifiedKycData = async (
    pan_verified,
    aadhaar_verified,
    pan_verified_name,
    aadhaar_verified_dob,
    aadhaar_verified_address,
    shop_and_home_address_check
  ) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
        pan_verified,
        aadhaar_verified,
        pan_verified_name,
        aadhaar_verified_dob,
        aadhaar_verified_address,
        shop_and_home_address_check,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/insert_verified_kyc_data.aspx`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };

  const pincodeData = (pincode, type) => {
    console.log({ pincode, type });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Pincode: pincode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_API_TRANXT_API}/api/kyc/pindata.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.length > 0) {
            setCity(result[0].city);
            setState(result[0].state);
            if (type == "1") {
              setFormValues({
                ...formValues,
                City: result[0].city,
                State: result[0].state,
              });
            } else if (type == "2") {
              setFormValues({
                ...formValues,
                ShopCity: result[0].city,
                ShopState: result[0].state,
              });
            }
          }
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (agentId && agentId.length > 4) {
      verificationData2();
    }
  }, [agentId]);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const MN = sessionStorage.getItem("MN");

  const convertToBiteArray = (file, callback) => {
    const reader = new FileReader();
    const fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        const arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        var binary = "";
        var len = array.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(array[i]);
        }
        callback(window.btoa(binary));
      }
    };
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // Doc();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!uploadedDocs.POI) {
      errors.PAN = "*Pan is required!";
    }
    if (!uploadedDocs.POA_Front) {
      errors.AdharFront = "*Adhar Front is required";
    }
    if (!uploadedDocs.POA_Back) {
      errors.AdharBack = "*Adhar Back is required!";
    }
    if (!uploadedDocs.profile) {
      errors.Userimage = "*User image is required";
    }
    if (!uploadedDocs.sign) {
      errors.UserSign = "*User Sign is required";
    }
    return errors;
  };

  useEffect(() => {
    if (formValues.Pincode?.length == 6) {
      pincodeData(formValues.Pincode, "1");
    }
  }, [formValues.Pincode]);

  useEffect(() => {
    if (formValues.ShopPin?.length == 6) {
      pincodeData(formValues.ShopPin, "2");
    }
  }, [formValues.ShopPin]);

  const getWalletBalance = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: agentId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/wallet/getWalletBalance",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setWalletBal({
          mainBal: data.data?.wallet_bal || "",
          AepsBal: data.data?.aeps_settlement_bal || "",
          PgBal: data.data?.pg_settlement_bal || "",
          LeinBal: data.data?.lean_amount || "",
        });
      }
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [name]: value,
  //   });
  // };

  useEffect(() => {
    setFormValues({
      ...details,
      level_1_parent_code: details?.level_1_parent.toString().split("|")[0],
      level_1_parent_name: details?.level_1_parent.toString().split("|")[1],
      level_1_parent_mobile: details?.level_1_parent.toString().split("|")[2],
      level_2_parent_code: details?.level_2_parent.toString().split("|")[0],
      level_2_parent_name: details?.level_2_parent.toString().split("|")[1],
      level_2_parent_mobile: details?.level_2_parent.toString().split("|")[2],
      sales_code: details?.level_1_parent.toString().split("|")[3],
      sales_name: details?.level_1_parent.toString().split("|")[4],
      sales_mobile: details?.level_1_parent.toString().split("|")[5],
    });
  }, [details]);

  console.log("formValues", formValues);

  return (
    <div className="px-2 py-0" style={{ fontFamily: "auto" }}>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}
      <Row>
        <Row>
          <Col md={6} lg={6} sm={12} className="mt-2">
            <Card className="p-2">
              <Form.Label className="text-primary">
                <b> Level 1 Parent</b>
              </Form.Label>
              <Form.Label>
                <b> Code : {formValues.level_1_parent_code}</b>
              </Form.Label>
              <Form.Label>
                <b> Name : {formValues.level_1_parent_name}</b>
              </Form.Label>
              <Form.Label>
                <b> Mobile : {formValues.level_1_parent_mobile}</b>
              </Form.Label>
            </Card>
          </Col>
          <Col md={6} lg={6} sm={12} className="mt-2">
            <Card className="p-2">
              <Form.Label className="text-primary">
                <b> Level 2 Parent</b>
              </Form.Label>
              <Form.Label>
                <b> Code : {formValues.level_2_parent_code}</b>
              </Form.Label>
              <Form.Label>
                <b> Name : {formValues.level_2_parent_name}</b>
              </Form.Label>
              <Form.Label>
                <b> Mobile : {formValues.level_3_parent_mobile}</b>
              </Form.Label>
            </Card>
          </Col>

          <Col md={6} lg={6} sm={12} className="mt-2">
            <Card className="p-2">
              <Form.Label className="text-primary">
                <b> Sales Person</b>
              </Form.Label>
              <Form.Label>
                <b> Code : {formValues.sales_code}</b>
              </Form.Label>
              <Form.Label>
                <b> Name : {formValues.sales_name}</b>
              </Form.Label>
              <Form.Label>
                <b> Mobile : {formValues.sales_mobile}</b>
              </Form.Label>
            </Card>
          </Col>
        </Row>
        <Row></Row>
      </Row>
    </div>
  );
};

const BankInfo = ({ details, agentId , refresh }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const [personalDetails, setPersonalDetails] = useState({});
  const [aepsSettlementAccounts, setAepsSettlementAccounts] = useState([]);
  const [pgSettlementAccounts, setPgSettlementAccounts] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [dob, setDob] = useState(new Date());
  const [kycData, setKycData] = useState({});
  const [WalletBal, setWalletBal] = useState({});
  const [userDetails, setUserDetails] = useState({});
  // const [agentId, setAgentId] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [State_Code, setStateCode] = useState([]);
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [verifiedKycData, setVerifiedKycData] = useState(null);
  const [formValues, setFormValues] = useState({
    mainBal: "",
    AepsBal: "",
    PgBal: "",
    LeinBal: "",
    FirstName: "",
    LastName: "",
    Gender: "",
    DOB: new Date(),
    Mobile: "",
    Email: "",
    Address: "",
    Pincode: "",
    City: "",
    State: "",
    ShopAddress: "",
    ShopPin: "",
    ShopCity: "",
    ShopState: "",
    AdharFront: "",
    AdharBack: "",
    GstDoc: "",
    Userimage: "",
    UserSign: "",
    UserImageWithAdhar: "",
    ShopImage: "",
    ShopName: "",
    Address: "",
    Pincode: "",
    pancardno: "",
    aadharcardno: "",
    gstnumber: "",
    OTP: "",
  });
  const [searchText, setSearchText] = useState("");
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const [verification, setVerification] = useState({
    PAN: false,
    ADHAR: false,
    panDetails: null,
    adhaarDetails: null,
    otpSent: false,
    aadhaar_request_id: "",
  });

  const [uploadedDocs, setUploadedDoc] = useState({
    profile: false,
    POI: false,
    POA_Front: false,
    POA_Back: false,
    sign: false,
    gstdoc: false,
  });
  const [clickedImageURL, setClickedImageURL] = useState("");
  const userId = JSON.parse(localStorage.getItem("kyc"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const imagePath = `${BASE_URL_API_TRANXT_API}/users_kyc_doc`;

  const dateToStr = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

  const verificationData2 = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: agentId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data2.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        let selfiWithDoc = "",
          selfiWithEmployee = "";

        if (data.data.relation.includes("|")) {
          selfiWithDoc = data.data.relation.split("|")[0];
          selfiWithEmployee = data.data.relation.split("|")[1];
        }
        let imguser = "";
        if (data.data.imguser != "") {
          imguser = `${imagePath}/${data.data.imguser}.jpeg?v=${uniqueID2()}`;
        }
        let imgpancard = "";
        if (data.data.imgpancard != "") {
          imgpancard = `${imagePath}/${
            data.data.imgpancard
          }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharfront = "";
        if (data.data.imgaadharfront != "") {
          imgaadharfront = `${imagePath}/${
            data.data.imgaadharfront
          }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharback = "";
        if (data.data.imgaadharback != "") {
          imgaadharback = `${imagePath}/${
            data.data.imgaadharback
          }.jpeg?v=${uniqueID2()}`;
        }
        let signature = "";
        if (data.data.signature != "") {
          signature = `${imagePath}/${
            data.data.signature
          }.jpeg?v=${uniqueID2()}`;
        }

        let gstdoc = "";
        if (data.data.gstdoc != "") {
          gstdoc = `${imagePath}/${data.data.gstdoc}.jpeg?v=${uniqueID2()}`;
        }

        if (data.data.relation.includes("|")) {
          selfiWithDoc = `${imagePath}/${
            data.data.relation.split("|")[0]
          }.jpeg?v=${uniqueID2()}`;
          selfiWithEmployee = `${imagePath}/${
            data.data.relation.split("|")[1]
          }.jpeg?v=${uniqueID2()}`;
        }

        setKycData({
          ...kycData,
          imguser,
          imgpancard,
          imgaadharfront,
          imgaadharback,
          signature,
          gstdoc,
          selfiWithDoc,
          selfiWithEmployee,
        });

        setUserDetails(data.data);

        setFormValues({
          ...formValues,
          ShopName: data.data.shopname,
          FirstName: data.data.userfname,
          LastName: data.data.userlname,
          Gender: data.data.gender,
          DOB: new Date(data.data.dateofbirth),
          Mobile: data.data.usermobile,
          Email: data.data.emailid,
          Address: data.data.useraddress,
          ShopAddress: data.data.shopaddress,
          Pincode: data.data.statecode,
          ShopPin: data.data.shoppincode,
          ShopCity: data.data.shopcity,
          ShopState: data.data.shopstate,
          City: data.data.usercity,
          State: data.data.userstate,
          pancardno: data.data?.pancardno,
          aadharcardno: data.data?.aadharcardno,
          gstnumber: data.data?.gstnumber,
        });

        setUploadedDoc({
          profile: data.data.imguser ? true : false,
          POI: data.data.imgpancard ? true : false,
          POA_Front: data.data.imgaadharfront ? true : false,
          POA_Back: data.data.imgaadharback ? true : false,
          sign: data.data.signature ? true : false,
          gstdoc: data.data.gstdoc ? true : false,
        });

        setVerifiedKycData(data.data);
        setVerification({
          ...verification,
          PAN: data.data.pan_verified == "Y",
          panDetails: data.data.pan_verified_name,
          ADHAR: data.data.aadhaar_verified == "Y",
          adhaarDetails: data.data,
        });
      } else {
        setFormValues({
          FirstName: "",
          LastName: "",
          Gender: "",
          DOB: new Date(),
          Mobile: "",
          Email: "",
          Address: "",
          Pincode: "",
          City: "",
          State: "",
          ShopAddress: "",
          ShopPin: "",
          ShopCity: "",
          ShopState: "",
          AdharFront: "",
          AdharBack: "",
          GstDoc: "",
          Userimage: "",
          UserSign: "",
          UserImageWithAdhar: "",
          ShopImage: "",
          ShopName: "",
          Address: "",
          Pincode: "",
          pancardno: "",
          aadharcardno: "",
          gstnumber: "",
          OTP: "",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const pincodeData = (pincode, type) => {
    console.log({ pincode, type });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Pincode: pincode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_API_TRANXT_API}/api/kyc/pindata.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.length > 0) {
            setCity(result[0].city);
            setState(result[0].state);
            if (type == "1") {
              setFormValues({
                ...formValues,
                City: result[0].city,
                State: result[0].state,
              });
            } else if (type == "2") {
              setFormValues({
                ...formValues,
                ShopCity: result[0].city,
                ShopState: result[0].state,
              });
            }
          }
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (agentId && agentId.length > 4) {
      verificationData2();
    }
  }, [agentId]);

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const MN = sessionStorage.getItem("MN");

  useEffect(() => {
    if (formValues.Pincode?.length == 6) {
      pincodeData(formValues.Pincode, "1");
    }
  }, [formValues.Pincode]);

  useEffect(() => {
    if (formValues.ShopPin?.length == 6) {
      pincodeData(formValues.ShopPin, "2");
    }
  }, [formValues.ShopPin]);

  const getWalletBalance = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: agentId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/wallet/getWalletBalance",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setWalletBal({
          mainBal: data.data?.wallet_bal || "",
          AepsBal: data.data?.aeps_settlement_bal || "",
          PgBal: data.data?.pg_settlement_bal || "",
          LeinBal: data.data?.lean_amount || "",
        });
      }
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  };

  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [name]: value,
  //   });
  // };

  useEffect(() => {
    setPersonalDetails(details.personalDetails);
    setAepsSettlementAccounts(details.aepsSettlementAccounts);
    setPgSettlementAccounts(details.pgSettlementAccounts);
  }, [
    details.aepsSettlementAccounts,
    details.pgSettlementAccounts,
    details.personalDetails,
  ]);

  const refundVerification = (bank_holder_name, bank_name, account_number , id) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure you want to delete this account?\n\nAccount Holder: ${bank_holder_name}\nBank Name: ${bank_name}\nAccount Number: ${account_number}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("hwlo");
       
        userDetailsUpdate(id);
      }
    });
  };

  const userDetailsUpdate = async (id) => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

      var raw = JSON.stringify({
        userid: agentId,
        adminId: user.pkid,
        action: "AEPS_ACCOUNT_DELETE",
        p1: id,
        p2: "string",
        p3: "string",
        p4: "string"
            });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
"https://apiservices.bumppypay.com/api/Admin/userDetailsUpdate",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMessage, "success");
        refresh();
      } else {
        swal("Failed", data.responseMessage, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  return (
    <div className="p-4" style={{ fontFamily: "auto" }}>
      <div>
   
        <Row>
  <Col md={3} lg={3} sm={6} className="mt-2">
    <Card className="p-2">
      <Form.Label className="text-primary">
        <b>Main Account</b>
      </Form.Label>
      <Form.Label>
        <b>Balance:</b> {personalDetails.user_balance}
      </Form.Label>
    </Card>
  </Col>

  <Col md={3} lg={3} sm={6} className="mt-2">
    <Card className="p-2">
      <Form.Label className="text-primary">
        <b>AEPS Account</b>
      </Form.Label>
      <Form.Label>
        <b>Balance:</b> {personalDetails.user_aeps_balance}
      </Form.Label>
    </Card>
  </Col>

  <Col md={3} lg={3} sm={6} className="mt-2">
    <Card className="p-2">
      <Form.Label className="text-primary">
        <b>PG Account</b>
      </Form.Label>
      <Form.Label>
        <b>Balance:</b> {personalDetails.user_pg_balance}
      </Form.Label>
    </Card>
  </Col>

  <Col md={3} lg={3} sm={6} className="mt-2">
    <Card className="p-2">
      <Form.Label className="text-primary">
        <b>Lein Amount</b>
      </Form.Label>
      <Form.Label>
        <b>Balance:</b> {personalDetails.user_lean_balance}
      </Form.Label>
    </Card>
  </Col>
</Row>


        <div>
          <div>
            <div>
              <div className="row m-0 p-0  pt-1 align-items-center">
                <div className="col-4 m-0 p-0">
                  <div
                    className="m-0 p-0 pl-2"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "32px",
                      fontWeight: "600",
                      lineHeight: "48px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      padding: "0px",
                      background: "#FFFFFF",
                      color: "#4267B2",
                      fontWeight: "bolder",
                      fontfamily: "Poppins !impotent",
                      fontSize: "28px",
                    }}
                  >
                    AEPS Settlement Banks
                  </div>
                </div>

                <div className="col-8 m-0 p-0 "></div>
              </div>
              <hr className="m-0 p-0" />
            </div>
          </div>

          <div className="scroll-inner ">
            <table
              className="my-table transparent-table text-center border-bottom"
              style={{
                width: "100%",
                overflowX: "auto",
                border: "0px",
                overflow: "auto",
              }}
            >
              <tr className="borderless-table text-center border-bottom">
                <th
                  className="my-table transparent-table text-center border-bottom py-3"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"ID"}
                </th>

                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Bank Name"}
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Bank Account Number"}
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"IFSC Code"}
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Bank Holder Name"}
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Action"}
                </th>
              </tr>
              {aepsSettlementAccounts.map((e, ind) => (
                <tr className="border-bottom">
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.id}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.bank_name}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.account_number}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.ifsc_code}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <div>{e.bank_holder_name}</div>
                  </td>
                  <td
                    className="text-center border-bottom"
                    style={{
                      fontSize: "0.8rem",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <button
                    className="btn btn-block bg-danger "
                    style={{border:"none" , color:"white" , borderRadius:"15px"}}
                    onClick={() => {
                      refundVerification(
                        e.bank_holder_name,
                        e.bank_name ,
                        e.account_number,
                        e.id
                      );
                    }}
                    >Delete </button>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>

        <div>
          <div>
            <div>
              <div className="row m-0 p-0  pt-1 align-items-center">
                <div className="col-4 m-0 p-0">
                  <div
                    className="m-0 p-0 pl-2"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "32px",
                      fontWeight: "600",
                      lineHeight: "48px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      padding: "0px",
                      background: "#FFFFFF",
                      color: "#4267B2",
                      fontWeight: "bolder",
                      fontfamily: "Poppins !impotent",
                      fontSize: "28px",
                    }}
                  >
                    PG Settlement Banks
                  </div>
                </div>

                <div className="col-8 m-0 p-0 "></div>
              </div>
              <hr className="m-0 p-0" />
            </div>
          </div>

          <div className="scroll-inner ">
            <table
              className="my-table transparent-table text-center border-bottom"
              style={{
                width: "100%",
                overflowX: "auto",
                border: "0px",
                overflow: "auto",
              }}
            >
              <tr className="borderless-table text-center border-bottom">
                <th
                  className="my-table transparent-table text-center border-bottom py-3"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Sr"}
                </th>

                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Action"}
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Action"}
                </th>
                <th
                  className="my-table transparent-table text-center border-bottom "
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {"Action"}
                </th>
              </tr>
              {details.pgSettlementAccounts.map((e, ind) => (
                <tr className="border-bottom"></tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const ImagesData = ({ details, agentId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const { username, password } = basicAuth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [dob, setDob] = useState(new Date());
  const [kycData, setKycData] = useState({});
  const [WalletBal, setWalletBal] = useState({});
  const [userDetails, setUserDetails] = useState({});
  // const [agentId, setAgentId] = useState("");
  const [State_Code, setStateCode] = useState([]);
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [verifiedKycData, setVerifiedKycData] = useState(null);
  const [formValues, setFormValues] = useState(details);

  const [verification, setVerification] = useState({
    PAN: false,
    ADHAR: false,
    panDetails: null,
    adhaarDetails: null,
    otpSent: false,
    aadhaar_request_id: "",
  });

  const [uploadedDocs, setUploadedDoc] = useState({
    profile: false,
    POI: false,
    POA_Front: false,
    POA_Back: false,
    sign: false,
    gstdoc: false,
  });
  const [clickedImageURL, setClickedImageURL] = useState("");
  const userId = JSON.parse(localStorage.getItem("kyc"));

  const imagePath = `${BASE_URL_API_TRANXT_API}/users_kyc_doc`;

  const dateToStr = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

  const updateUserDetails = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fkuserid: agentId,
        shopname: formValues.ShopName,
        shopaddress: formValues.ShopAddress,
        shoppincode: formValues.ShopPin,
        shopcity: formValues.ShopCity,
        shopstate: formValues.ShopState,
        userfname: formValues.FirstName,
        userlname: formValues.LastName,
        usermobile: formValues.Mobile,
        useraddress: formValues.Address,
        userpincode: formValues.Pincode,
        usercity: formValues.City,
        userstate: formValues.State,
        emailid: formValues.Email,
        statecode: formValues.StateCode,
        gender: formValues.Gender,
        dateofbirth: dateToStr(formValues.DOB),
        pancardno: formValues.pancardno,
        aadharcardno: formValues.aadharcardno,
        gstnumber: formValues.gstnumber,
        nomineename: "",
        relation: "",
        nomineeaadhar: "",
        //
        imgpancard: `${formValues.Mobile}_POI`,
        imgaadharfront: `${formValues.Mobile}_POA_Front`,
        imgaadharback: `${formValues.Mobile}_POA_Back`,
        gstdoc: `${formValues.Mobile}_gst`,
        imguser: `${formValues.Mobile}_profile`,
        signature: `${formValues.Mobile}_sign`,
        //
        nomineeage: "",
        passport: "",
        adminId: user.pkid,
        userName: "",
        userMobile: formValues.Mobile,
        ipaddress: "192.168.1.1",
        location: "Noida",
        userFirstName: formValues.FirstName,
        userLastName: formValues.LastName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/admin/users/admin_user_details_edit.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", "User Details Updated Successfully", "success");
      } else {
        swal("Failed", "User Details Updation Failed", "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const MN = sessionStorage.getItem("MN");

  const convertToBiteArray = (file, callback) => {
    const reader = new FileReader();
    const fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        const arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        var binary = "";
        var len = array.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(array[i]);
        }
        callback(window.btoa(binary));
      }
    };
  };

  const ImageUpload = (e) => {
    console.log(userDetails);
    if (!userDetails.usermobile) {
      return;
    }
    let MN = userDetails.usermobile;
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    const type = e.target.id;

    if (e.target.files.length > 0) {
      if (e.target.files[0].size > 256000) {
        swal("Invalid", "Image Size should be less than 256 kb", "error");
        return;
      }
      convertToBiteArray(e.target.files[0], (ByteArray) => {
        var raw = JSON.stringify({
          imagename: `${MN}_${type}`,
          imagebytedata: ByteArray,
        });

        var requestOptions = {
          method: "POST",
          body: raw,
          redirect: "follow",
        };

        try {
          fetch(
            `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status == "0") {
                setUploadedDoc({ ...uploadedDocs, [type]: true });
                cogoToast.success(result.msg);
                // verificationData();
              } else {
                cogoToast.error(result.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  useEffect(() => {
    setKycData(details);
  }, [details]);

  return (
    <div className="p-4" style={{ fontFamily: "auto" }}>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}

      <Row>
        <Row className="col-12">
          <Col className="col-12 col-sm-6 col-md-4 text-left">
            <Form.Label className="text-left">
              <b className="text-left">Passport Size Photo</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.passport_pic);
              }}
              src={kycData.passport_pic}
            ></Image>
            <Form.Control
              accept=".jpg,.png"
              type="file"
              name="Userimage"
              value={formValues.Userimage}
              id="profile"
              onChange={ImageUpload}
              sty
              className="mt-3 mx-auto text-center row justify-content-center m-0 p-0"
            />
            <p style={{ color: "red" }}>{formErrors.Userimage}</p>
          </Col>

          <Col className="col-12 col-sm-6 col-md-4 text-left">
            <Form.Label>
              <b>Pan Card</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>

            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.img_pan_card);
              }}
              src={kycData.img_pan_card}
            ></Image>

            <Form.Control
              className="mt-3"
              accept=".jpg,.png"
              type="file"
              name="PAN"
              value={formValues.PAN}
              id="POI"
              onChange={ImageUpload}
            />
            <p style={{ color: "red" }}>{formErrors.PAN}</p>
          </Col>

          <Col className="col-12 col-sm-6 col-md-4 text-left">
            <Form.Label>
              <b>Adhar Card Front Side</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.img_aadhar_back);
              }}
              src={kycData.img_aadhar_back}
            ></Image>
            <Form.Control
              className="mt-3"
              accept=".jpg,.png"
              type="file"
              name="AdharFront"
              value={formValues.AdharFront}
              id="POA_Front"
              onChange={ImageUpload}
            />
            <p style={{ color: "red" }}>{formErrors.AdharFront}</p>
          </Col>
        </Row>

        <Row className="mt-2 col-12">
          <Col className="col-12 col-sm-6 col-md-4 text-left">
            <Form.Label>
              <b>Adhar Card Back Side</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.img_aadhar_back);
              }}
              src={kycData.img_aadhar_back}
            ></Image>
            <Form.Control
              className="mt-3"
              accept=".jpg,.png"
              type="file"
              name="AdharBack"
              value={formValues.AdharBack}
              id="POA_Back"
              onChange={ImageUpload}
            />
            <p style={{ color: "red" }}>{formErrors.AdharBack}</p>
          </Col>
          <Col className="col-12 col-sm-6 col-md-4 text-left">
            <Form.Label>
              <b>Signature</b>
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.signature_pic);
              }}
              src={kycData.signature_pic}
            ></Image>
            <Form.Control
              className="mt-3"
              accept=".jpg,.png"
              type="file"
              name="UserSign"
              value={formValues.UserSign}
              id="sign"
              onChange={ImageUpload}
            />
            <p style={{ color: "red" }}>{formErrors.UserSign}</p>
          </Col>

          <Col md={3} lg={3} sm={6} className="text-left">
            <Form.Label>
              <b>Shop Image</b>
            </Form.Label>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.gstdoc);
              }}
              src={kycData.gstdoc}
            ></Image>
            <Form.Control
              className="mt-3"
              accept=".jpg,.png"
              type="file"
              name="gst"
              value={formValues.ShopImage}
              id="gst"
              onChange={ImageUpload}
            />
          </Col>

          <Col className="col-12 col-sm-6 col-md-4 text-left">
            <Form.Label>
              <b>LIVE PHOTO WITH DOCUMENTS </b>
            </Form.Label>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.selfi_with_doc);
              }}
              src={kycData.selfi_with_doc}
            ></Image>
            <Form.Control
              accept=".jpg,.png"
              type="file"
              name="selfiWithDoc"
              value={formValues.selfiWithDoc}
              id="selfiWithDoc"
              onChange={ImageUpload}
            />
          </Col>
          <Col className="col-12 col-sm-6 col-md-4 text-left">
            <Form.Label>
              <b>AGENT WITH EMPLOYEE PHOTO</b>
            </Form.Label>
            <Image
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "30vh",
                maxWidth: "30vw",
              }}
              onClick={() => {
                setClickedImageURL(kycData.selfi_with_employee);
              }}
              src={kycData.selfi_with_employee}
            ></Image>
            <Form.Control
              accept=".jpg,.png"
              type="file"
              name="selfiWithEmployee"
              value={formValues.selfiWithEmployee}
              id="selfiWithEmployee"
              onChange={ImageUpload}
            />
          </Col>
        </Row>
        <Col md={3} lg={3} sm={6} className="mt-4">
          <Button
            className="btn-block"
            variant="success"
            onClick={updateUserDetails}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  );
};