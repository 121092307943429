import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../../retailer/Topup View/TopupView.css";
import JSONTable from "../../utils/JsonTable";
import { or } from "ip";
import { get } from "jquery";
export const columns = [
  {
    dataField: "payment_indate",
    text: "Transaction Date",
  },
  {
    dataField: "tid",
    text: "Transaction ID",
  },
  {
    dataField: "orderid",
    text: "Order ID",
  },
  {
    dataField: "us_code",
    text: "User Code",
  },
  {
    dataField: "us_name",
    text: "User Name",
  },
  {
    dataField: "opening_bal",
    text: "Opening Balance",
  },
  {
    dataField: "load_amount",
    text: "Amount",
  },
  {
    dataField: "closing_bal",
    text: "Closing Balance",
  },

  {
    dataField: "chages",
    text: "chages",
  },
  {
    dataField: "gst",
    text: "GST",
  },
  {
    dataField: "mode",
    text: "Mode",
  },
  {
    dataField: "card",
    text: "Card",
  },
  {
    dataField: "route_name",
    text: "Route",
  },
  {
    dataField: "extra",
    text: "Tariff",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "sales_user_code",
    text: "Sales Code",
  },
  {
    dataField: "sales_user_name",
    text: "Sales Name",
  },
  {
    dataField: "sales_user_mobile",
    text: "Sales Mobile Number",
  },
];
const tdstyle = {
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const tdstyle2 = {
  width: "10%",
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const thstyle = {
  color: "dark",
  backgroundColor: "white",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const PGReportTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [apiResponseModal, setApiResponseModal] = useState(false);
  const [transactionDetailsModal, setTransactionDetailsModal] = useState(false);
  const [apiResponseDetails, setApiResponseDetails] = useState({});
  const [transactionDetails, setTransactionDetails] = useState({});
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (url) => {

    const newWindow = window.open(
      url,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  function isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const decodeResponseMessage1 = (inputString) => {
    const keyValuePairs = inputString.split('|');
    const jsonResult = {};
    keyValuePairs.forEach(pair => {
      const [key, value] = pair.split('=');
      jsonResult[key] = value;
    });
    return jsonResult;
  }

  const decodeResponseMessage = (inputString) => {
    return isJSONString(inputString) ? JSON.parse(inputString) : decodeResponseMessage1(inputString);
  }

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: "",
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        mode: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/reports/getAllPGReport`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data.map((e) => ({
        ...e,
        sales_user_code: e.pg_name.split("|")[1],
        sales_user_name: e.pg_name.split("|")[2],
        sales_user_mobile: e.pg_name.split("|")[3],
        mode: e.mode.split("|")[0],
        card: e.mode.split("|")[1],
        api_response: e.message == "NA" ? { message: "NO Response" } : decodeResponseMessage(e.message)
      })));
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const claimTransaction = async (orderid) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "userid": "BR1300",
        "orderid": orderid,
        "status": "OK",
        "custName": user.pkid,
        "cardNo": "",
        "aadhaarNo": ""
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/PG/transactionClaim`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        getDMTReport();
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const claimVerification = (tid, amount, RetailerID) => {
    swal({
      title: "Are you sure?",
      text: `Claim amount of ${amount} to ${RetailerID}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        claimTransaction(tid);
      }
    });
  };

  const refundDMTTransaction = async (tid) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        trans_id: tid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/refunds/aepssettlement_refund.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const refundVerification = (tid, amount, RetailerID) => {
    swal({
      title: "Are you sure?",
      text: `Refund amount of ${amount} to ${RetailerID}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        refundDMTTransaction(tid);
      }
    });
  };

  const showResponse = (response) => {
    setApiResponseDetails(response);
    setApiResponseModal(true);
  };

  useEffect(() => {
    getDMTReport();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const positionStyle1 = {
    position: "absolute",
    top: -43,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle2 = {
    position: "absolute",
    top: -44,
    left: -12,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  console.log(data);

  return (
    <>

      <ApiResponseModal
        details={apiResponseDetails}
        isModalOpen={apiResponseModal}
        setModalOpen={(modalState) => {
          setApiResponseModal(modalState);
        }}
        refresh={() => { }}
      />

      <TransactionDetailsModal
        details={transactionDetails}
        isModalOpen={transactionDetailsModal}
        setModalOpen={(modalState) => {
          setTransactionDetailsModal(modalState);
        }}
        refresh={() => { }}
      />

      <div className="row m-0 p-0 pt-1 align-items-top">
        <div className="col-3 m-0 p-0">
          <div
            className="m-0 p-0 pl-3 align-top"
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
            PG Report
          </div>
        </div>

        <div className="col-9 m-0 p-0 ">
          <div className="row m-0 p-0 justify-content-end">
            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div
              className="pr-1 pl-2 p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div
              className="px-1 p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>

            <div
              className="pl-1 p-0 m-0"
              style={{ width: "14%", height: "20%" }}
            >
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-50 btn btn-primary btn-lg btn-block "
                  onClick={getDMTReport}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>

            <div
              className="m-0 p-0 hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `PG_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1 p-0"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div style={positionStyle1} className="hover-text bg-dark ">
                Export to Excel
              </div>
            </div>
          </div>
        </div>
      </div>


      <hr className="m-0 p-0" />
      <div className=" scroll-inner admin-fix-width transparent-table">
        <table className=" table table-borderless text-dark ">
          <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Sr"}
            </th>

            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Order ID"}
            </th>

            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"User"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Date"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Opening"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Amount"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Charges"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Closing"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Card"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Mode"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Route"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Tariff"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Device"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Sales Person"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Status"}
            </th>
            <th
              className=" transparent-table text-center border-bottom text-dark"
              style={thstyle}
            >
              {" "}
              {"Action"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className=" transparent-table border-bottom ">
              <td className="align-middle" style={tdstyle}> {ind + 1}</td>
              <td className="align-middle" style={tdstyle}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.orderid}</span>
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.us_code}</span>
                  <span >{`${e.us_name}`}</span>
                  <span>{e.us_mobile}</span>
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">{e.payment_indate}</span>
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  <span>{twoDecimalPlaces(e.opening_bal)}</span>
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.amount}</span>
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  <span> {`Charges : ${twoDecimalPlaces(e.chages)}`}</span>
                  <span> {`GST : ${twoDecimalPlaces(e.gst)}`}</span>

                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  <span >{`${twoDecimalPlaces(
                    e.closing_bal
                  )}`}</span>
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  {e.card}
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  {e.mode}
                </div>
              </td>

              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  {e.route_name}
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  {e.extra}
                </div>
              </td>
              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center">
                  {e.device}
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }} className="m-0 p-0">
                {" "}
                <div
                  className="text-center m-0 p-0"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "0.8rem",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{`${e.sales_user_name}`}</div>
                  <div>{`${e.sales_user_code}`}</div>
                  <div>{`${e.sales_user_mobile}`}</div>
                </div>
              </td>

              <td className="align-middle" style={tdstyle}>
                <div className="d-flex flex-column align-items-center m-0" >
                  {(e.status == "Pending" || e.status == "Processing" || e.status == "InComplete" || e.status == "Initiated") ? (
                    <span
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(254, 128, 36, 0.1)",
                        color: "rgba(254, 128, 36, 1)",
                        bordercolor: "rgba(254, 128, 36, 1)",
                      }}
                      className="p-2"
                    >
                      {`${e.status}`}
                    </span>
                  ) : e.status === "Failed" || e.status === "Failed" || e.status == "Suspected" ? (
                    <span
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(255, 0, 0, 0.1)",
                        color: " rgba(255, 0, 0, 1)",
                        bordercolor: " rgba(255, 0, 0, 1)",
                      }} className="p-2"
                    >
                      {`${e.status}`}
                    </span>
                  ) : (
                    <span
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                      }} className="p-2"
                    >
                      {`${e.status}`}
                    </span>
                  )}
                </div>








                {/* <span
 className={`badge text-light ${
 e.status == "Success"
 ? "bg-success"
 : e.status == "Failed"
 ? "bg-danger"
 : "bg-warning"
 }`}
 style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
 >
 {`${e.status}`}
 </span> */}
              </td>


              <td className="align-middle" style={tdstyle}>

                <div className="row m-0 my-auto p-0 justify-content-center" >
                  <Button style={{
                    // paddingLeft:"3px",
                    // padding: "5px",
                    borderRadius: "5px",
                    border: "1px none",
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: 'rgba(255, 164, 27, 1)',
                    bordercolor: 'rgba(255, 255, 255, 1)',
                  }} className="btn btn-sm rounded-100 m-0 p-1">
                    {" "}

                    <div
                      onClick={() => {
                        const pdfUrl = `https://bumppy.in/api_integration/agent_concern/v1/uploads/signed_docs/${e.orderid}.pdf`;

                        openInNewTab(pdfUrl);
                      }}
                      className="hover-container border-0"

                    >
                      {/* Your content here */}
                      <img
                        className="border-0"
                        src={require("../../../assets/images/doc_view.png")}
                        style={{ borderRadius: '0px', width: '2.1rem' }}
                      />
                      <div style={positionStyle2} className="hover-text bg-dark">Doc</div>
                    </div>


                  </Button>

                  {e.status.toString().toLowerCase() == "suspected" && (

                    <Button style={{
                      // paddingLeft:"3px",
                      // padding: "5px",
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      color: 'rgba(255, 164, 27, 1)',
                      bordercolor: 'rgba(255, 255, 255, 1)',
                    }} className="btn btn-sm rounded-100 m-0 p-1">
                      {" "}

                      <div
                        onClick={() => {
                          setTransactionDetails(e);
                          setTransactionDetailsModal(true);
                        }}
                        className="hover-container border-0"

                      >
                        {/* Your content here */}
                        <img
                          className="border-0"
                          src={require("../../../assets/images/Add.png")}
                          style={{ borderRadius: '0px', width: '2.1rem' }}
                        />
                        <div style={positionStyle2} className="hover-text bg-dark"><div >Transaction</div><div > Release</div></div>
                      </div>


                    </Button>

                  )}


                  {e.status.toString().toLowerCase() != "claimed" && (
                    <Button style={{
                      // paddingLeft:"3px",
                      // padding: "5px",
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      color: 'rgba(255, 164, 27, 1)',
                      bordercolor: 'rgba(255, 255, 255, 1)',
                    }} className="btn btn-sm rounded-100 m-0 p-1">
                      {" "}

                      <div
                        onClick={() => {
                          claimVerification(e.orderid, e.amount, e.us_code)
                        }}
                        className="hover-container border-0"

                      >
                        {/* Your content here */}
                        <img
                          className="border-0"
                          src={require("../../../assets/images/Rehit.png")}
                          style={{ borderRadius: '0px', width: '2.1rem' }}
                        />
                        <div style={positionStyle2} className="hover-text bg-dark"><div >Claim</div><div > Transaction</div></div>
                      </div>


                    </Button>
                  )}



                  <Button style={{
                    // paddingLeft:"3px",
                    // padding: "5px",
                    borderRadius: "5px",
                    border: "1px none",
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: 'rgba(255, 164, 27, 1)',
                    bordercolor: 'rgba(255, 255, 255, 1)',
                  }} className="btn btn-sm rounded-100 m-0 p-1">
                    {" "}

                    <div
                      onClick={() => showResponse(e.api_response)}
                      className="hover-container border-0"

                    >
                      {/* Your content here */}
                      <img
                        className="border-0"
                        src={require("../../../assets/images/Bank Response.png")}
                        style={{ borderRadius: '0px', width: '2.1rem' }}
                      />
                      <div style={positionStyle2} className="hover-text bg-dark"><div >Bank</div><div > Response</div></div>
                    </div>


                  </Button>

                </div>

              </td>



            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

export const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });

  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export const NoDataFound = () => {
  return (
    <tr className="row w-100 justify-content-center">
      <div className="d-flex flex-column align-items-center p-3">
        <img
          src={require("../../../assets/images/no_data_found.png")}
          style={{ height: "5rem", width: "auto" }}
        />
        <strong>No Data Found</strong>
      </div>
    </tr>
  );
};

export const ApiResponseModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  console.log(details);
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      <Modal.Body>
        <div
          style={{
            padding: "12px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontSize: "28px",
            fontFamily: "poppins",
            overflow: "hidden !important"
          }}
          className="d-flex justify-content-between"
        >
          API Response
          <div
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </div>
        </div>
        <div className="row">
          <JSONTable data={details} />

        </div>
      </Modal.Body>
    </Modal>
  );
};

export const TransactionDetailsModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {

  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [selectedMode, setSelectedMode] = useState("");
  const [cardDigit, setCardDigit] = useState("");
  const modes = [{
    label: "Wallet",
    value: "WALLET",
  },
  {
    label: "Card",
    value: "Card",
  }
  ];

  const releasePGTransaction = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "adminId": user.pkid,
        "orderid": details.orderid,
        "card": selectedMode.value == "Card" ? cardDigit : selectedMode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/PG/transactionRelease`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMessage, "success");
      } else {
        swal("Failed", data.responseMessage, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      size="md"
    >
      <Modal.Body>
        <div
          style={{
            padding: "12px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontSize: "28px",
            fontFamily: "poppins",
            overflow: "hidden !important"
          }}
          className="d-flex justify-content-between"
        >
          Release Transaction
          <div
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0 m-0 pl-3 pr-2 ">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                <span style={{ color: "black" }}>Enter Card Digit </span>

              </Form.Label>
              <Select options={modes}
                onChange={(e) => {
                  setSelectedMode(e);
                }}
                value={selectedMode}
              />

            </Form.Group>
          </div>


          {selectedMode.value == "Card" && (
            <div className="col-12 p-0 m-0 pl-3 pr-2 ">
              <Form.Group>
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  <span style={{ color: "black" }}>Enter Card Digit </span>

                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter Card Digit"
                  value={cardDigit}
                  onChange={(e) => {
                    setCardDigit(e.target.value);
                  }}
                  style={{ borderRadius: "7px" }}
                />
              </Form.Group>
            </div>
          )}

        </div>

        <div className="row p-0 r-0">


          <div className="col-6 p-0 m-0 px-2">
            <Form.Group className="mt-4">
              <Button
                onClick={releasePGTransaction}
                className="rounded-50 btn btn-primary btn-lg btn-block"
              >
                {" "}
                Release
              </Button>
            </Form.Group>
          </div>
          <div className="col-6 p-0 m-0 px-2">
            <Form.Group className="mt-4">
              <Button
                onClick={() => {

                }}
                className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
              >
                {" "}
                Clear
              </Button>
            </Form.Group>
          </div>
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default PGReportTable;