import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
 excelMapping,
 ExportToExcel,
 twoDecimalPlaces,
} from "../../utils/utilFuctions";import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export const columns = [

 {
 dataField: "tid",
 text: "TXN ID",
 },

 {
 dataField: "account_no",
 text: "Account No",
 },
 {
 dataField: "ifsc_code",
 text: " IFSC CODE",
 },
 {
 dataField: "rrn",
 text: " UTR",
 },
 {
 dataField: "opening_bal",
 text: "Opening Balance",
 },
 {
 dataField: "amount",
 text: "Amount",
 },
 {
 dataField: "closing_bal",
 text: "Closing Balance",
 },
 {
 dataField: "charges",
 text: "Charges",
 },
 {
 dataField: "status",
 text: "Status",
 },
 {
 dataField: "date",
 text: "Date",
 },
 
];

const AepsSettlementReportTable = ({ columns, agentId }) => {
 const [currentPage, setCurrentPage] = useState(1);
 const [data, setData] = useState([]);
 const [searchText, setSearchText] = useState("");
 const [offset, setOffset] = useState(10);
 const [dataToDisplay, setDataToDisplay] = useState([]);
 const [pages, setPages] = useState(0);
 const [total, setTotal] = useState(0);
 const [startDate, setStartDate] = useState(new Date());
 const [endDate, setEndDate] = useState(new Date());
 const { Profileinfo, user } = useContext(GlobalContext);

 const now = (date = new Date()) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };


 function getDatePreviousMonth(date = new Date()) {
 date.setMonth(date.getMonth() - 1);
 return date;
 }
 const dateFormatter = (date) => {
 return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
 }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
 };

 const fetchAEPSReport = async () => {
 try {
 const myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

 var raw = JSON.stringify({
 userid: agentId,
 fromDate: dateFormatter(startDate),
 toDate: dateFormatter(endDate),
 adminId: "",
 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 fetch(
 "https://apiservices.bumppypay.com/api/Admin/getSettlementReport",
 requestOptions
 )
 .then((response) => response.json())
 .then((result) => {
 if (result.responseCode == "00") {
 setData(result.data);
 } else {
 setData([]);
 }
 })
 .catch((error) => console.log("error", error));
} catch (error) {
 console.log(error);
}
};
 // shopName: Profileinfo.shopname
 // const openInNewTab = (data) => {
 // localStorage.setItem(
 // "print",
 // JSON.stringify({ ...data, })
 // );
 // const newWindow = window.open(
 // `/pages/aeps_print?id=${data.tid}&type=AEPS`,
 // "",
 // "height=600,width=800'"
 // );
 // if (newWindow) newWindow.opener = null;
 // };

 const setPrintData = (res, bank, aadhar, amount, service, mobile, tid) => {
 let data = {
 userid: user.ReferId,
 bank: bank,
 device: 0 == 0 ? "Mantra MFS 100" : 1 == 1 ? "Morpho MSO 1300" : "",
 tid: tid,
 aadhar: aadhar,
 amount: amount,
 service: service,
 rrn: res?.data ? res?.data?.bankRRN : "NA",
 stan: res?.data ? res?.data?.fpTransactionId : "NA",
 time: res?.data ? res?.data?.requestTransactionTime : "NA",
 mobile,
 mobile,
 status: res?.status ? res?.data?.transactionStatus : res?.message,
 balance: res?.data ? res?.data?.balanceAmount : "NA",
 miniStatementStructureModel: res?.data
 ? res?.data?.miniStatementStructureModel
 : null,
 miniOffusStatementStructureModel: res?.data
 ? res?.data?.miniOffusStatementStructureModel
 : null,
 shopname: Profileinfo?.shopname,
 image: user?.image,
 };
 sessionStorage.setItem("print", JSON.stringify(data));
 localStorage.setItem("print", JSON.stringify(data));
 };

 const getTransactionType = (t) => {
 switch (t) {
 case "CW":
 return "Cash Withdrawal";
 case "MS":
 return "Mini Statement";
 case "AP":
 return "Aadhaar Pay";
 case "BE":
 return "Balance Enquiry";
 default:
 return "";
 }
 };

 const openInNewTab = (data) => {
 const fr = data.fullresponse;
 console.log(data);
 setPrintData(
 fr,
 data.a_bankname,
 data.a_aadharno,
 data.amount,
 getTransactionType(data.service),
 "",
 data.fingpayTransactionId
 );
 const newWindow = window.open(
 `/pages/aeps/print?id=${data.tid}&type=AEPS`,
 "",
 "height=600,width=800'"
 );
 if (newWindow) newWindow.opener = null;
 };

 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
 setTotal(filteredData.length);
 const startIdx = 0;
 const endIdx = offset - 1;
 filteredData = filteredData.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 setCurrentPage(1);
 }, [searchText]);

 useEffect(() => {
 let filteredData = data.filter((bank) => {
 let b = false;
 if (data.length > 0) {
 for (let key in data[0]) {
 if (
 bank[key] &&
 bank[key]
 .toString()
 .toLowerCase()
 .includes(searchText.toString().toLowerCase())
 ) {
 b = true;
 }
 }
 }
 return b;
 });
 console.log(filteredData);
 const startIdx = (currentPage - 1) * offset;
 const endIdx = currentPage * offset - 1;
 console.log({ startIdx, endIdx });
 filteredData = filteredData.filter((bank, idx) => {
 console.log(idx, idx <= endIdx && idx >= startIdx);
 return idx <= endIdx && idx >= startIdx;
 });
 setDataToDisplay(filteredData);
 console.log({ startIdx, endIdx });
 }, [currentPage]);

 // did mount
 useEffect(() => {
 setPages(Math.floor((data.length + (offset - 1)) / offset));
 const startIdx = 0;
 const endIdx = offset - 1;
 setTotal(data.length);
 console.log(startIdx);
 console.log(data);
 const filteredData = data.filter((bank, idx) => {
 return idx <= endIdx && idx >= startIdx;
 });
 console.log(filteredData);
 setDataToDisplay(filteredData);
 }, [data, offset]);

 // did mount
 useEffect(() => {
 if (agentId && agentId.length > 4) fetchAEPSReport();
 }, [agentId]);

 const searchBarStyle = {
 display: 'flex',
 alignItems: 'center',
 border: '1px solid #ccc',
 borderRadius: '6px',
 padding: '0px',
 backgroundColor: '#fff',
 width: '25%', // Adjust the width as needed
 height: '20%',

 };
 const inputStyle = {
 border: 'none',
 outline: 'none',
 backgroundColor: 'transparent',
 fontSize: '16px',
 marginLeft: '8px',
 width: '100%',
 "fontFamily": "Poppins",
 "fontWeight": "400",
 };
 const iconStyle = {
 color: 'rgba(0, 0, 0, 0.5)',
 };


 return (
 <>
 <div>
 <div>
 <div>

 <div className="row m-0 p-0 pt-1 align-items-center">
 <div className="col-4 m-0 p-0">

 <div className="m-0 p-0 pl-2"
 style={{
 "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
 , padding: "0px",
 background: "#FFFFFF",
 color: "#4267B2",
 fontWeight: "bolder",
 fontfamily: "Poppins !impotent",
 fontSize: "28px",
 }}
 >
 AEPS Settlement Report
 </div>
 </div>

 <div className="col-8 m-0 p-0 ">
 <div className="row m-0 p-0 justify-content-end">

 <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
 <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
 <input
 type="text"
 className="form-control "
 placeholder="Search"
 aria-label="Search"
 style={inputStyle}
 onChange={(e) => {
 setSearchText(e.target.value);
 }}
 value={searchText}
 />
 </div>





 <div className="pr-1 pl-2 p-0 m-0" style={{ width: '18%', height: '20%', }}>
 <Form.Group className="p-0 m-0">

 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={startDate}
 onChange={(date) => setStartDate(date)}
 className="form-control rounded-100"
 placeholderText="Start Date"
 />
 </Form.Group>
 </div>
 <div className="px-1 p-0 m-0" style={{ width: '18%', height: '20%', }}>
 <Form.Group className="p-0 m-0">

 <DatePicker
 dateFormat="dd/MM/yyyy"
 selected={endDate}
 onChange={(date) => setEndDate(date)}
 className="form-control rounded-100"
 placeholderText="End Date"
 />
 </Form.Group>
 </div>


 <div className="pl-1 p-0 m-0" style={{ width: '14%', height: '20%', }}>
 <Form.Group className="mt-0 p-0 m-0">
 <Button
 className="rounded-50 btn btn-primary btn-lg btn-block "

 onClick={fetchAEPSReport}
 >
 {"Search"}
 </Button>
 </Form.Group>
 </div>
 <div className="m-0 p-0 hover-container"
 style={{ cursor: "pointer" }}
 onClick={() => {
 // ExportToExcel(`AEPS_Report_${now()}`, dataToDisplay);
 ExportToExcel(
 `AEPS_Report_${now()}`,
 excelMapping(dataToDisplay, columns)
 );
 }}
 >
 <img className="pl-1"
 src={require("../../../assets/images/download Icon.png")}
 style={{ width: `3.5rem`, height: '85%' }}
 />
 <div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
 </div>
 </div>




 </div>

 </div>

 <hr className="m-0 p-0" />


 </div>
 </div>

 <div className="scroll-inner ">
 <table
 className="my-table transparent-table text-center border-bottom"
 style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}
 >
 <tr className="borderless-table text-center border-bottom"
 >
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Txnt ID
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Account
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 UTR
 </th>
 
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Opening Balance
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Amount
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Closing Balance
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Charges
 </th>
 

 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Status
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Date
 </th>

 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Action
 </th>

 </tr>
 {dataToDisplay.map((e, ind) => (
 <tr className="border-bottom " >
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }} >
 {" "}
 {ind + 1}
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }} >
 <div>{e.tid}</div>
 </td>

 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 >
 <div>{e.account_no}</div>
 <div>{e.ifsc_code}</div></td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }} >
 <div>{e.rrn}</div>
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }} >
 <div>{twoDecimalPlaces(e.opening_bal)}</div>
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{twoDecimalPlaces(e.amount)}</td>

 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }} >
 <div>{twoDecimalPlaces(e.closing_bal)}</div>
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{twoDecimalPlaces(e.charges)}</div>
 </td>
 
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}
 >
 {e.status == "SUCCESS" ? (
 <label
 style={{
 paddingLeft: "3px",
 paddingRight: "3px",
 padding: "5px",
 borderRadius: "5px",
 border: "1px solid",
 backgroundColor: 'rgba(43, 193, 85, 0.1)',
 color: 'rgba(43, 193, 85, 1)',
 bordercolor: 'rgba(43, 193, 85, 1)',
 }}
 >
 {"Success"}
 </label>
 ) : (
 <label
 style={{
 paddingLeft: "3px",
 paddingRight: "3px",
 padding: "5px",
 borderRadius: "5px",
 border: "1px solid",
 backgroundColor: 'rgba(254, 128, 36, 0.1)',
 color: 'rgba(254, 128, 36, 1)',
 bordercolor: 'rgba(254, 128, 36, 1)',
 }}
 >
 {e.status}
 </label>
 )}
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }} >{e.date}</td>

 <td className="text-center border-bottom p-0" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div className="row d-flex justify-content-center">
 {" "}

 <Button
 style={{

 borderRadius: "5px",
 border: "1px none",
 backgroundColor: 'rgba(255, 255, 255, 1)',
 color: 'rgba(255, 164, 27, 1)',
 bordercolor: 'rgba(255, 255, 255, 1)',
 "fontSize": "4px !important"
 }}
 className="btn rounded-100 m-0 p-0">
 {" "}
 <div
 className="hover-container"

 onClick={() => {
 openInNewTab(e);
 }}
 >
 <img
 src={require("../../../assets/images/printer.png")}
 style={{ width: "" }}
 />
 {/* <div style={{ color:'rgba(232, 129, 36, 1)', }} className="hover-text pl-2"></div>
 <div style={{"fontFamily":"Poppins", color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Print</div> */}
 <div style={{ "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark text-center px-2 py-1 mb-2">Print</div>
 </div>


 </Button>

 <div style={{ width: "4px" }} />
 </div>
 </td>

 </tr>
 ))}
 </table>
 </div>

 <Pagination
 page={currentPage}
 limit={pages}
 callback={(page) => {
 setCurrentPage(page);
 }}
 count={dataToDisplay.length}
 total={total}
 callback2={(offsetValue) => {
 setOffset(offsetValue);
 }}
 />
 </div>
 </>
 );
};

export const Pagination = ({
 page,
 limit,
 callback,
 total,
 count,
 callback2,
}) => {
 console.table({ page, limit, callback });
 const selectStyleLeftBorder = {

 borderRadius: '0px',
 "fontFamily": "Poppins",
 'fontsize': '16px',
 borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
 borderRadius: '0px',
 borderLeft: 'none', // Remove right side border
 borderTop: 'none', // Remove top border
 borderBottom: 'none', // Remove bottom border
 "fontFamily": "Poppins",
 'fontsize': '16px',




 };
 const selectStyleBorder = {
 border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
 borderRadius: '8px',
 "fontFamily": "Poppins",
 'fontsize': '16px',
 };
 const selectStyle = {

 border: 'none', // Remove the border
 "fontFamily": "Poppins",
 'fontsize': '16px',

 };
 return (
 <div
 className="row"
 style={{
 display: "flex",
 alignItems: "end",
 width: "100%",
 margin: "10px 0px",
 justifyContent: "space-between",

 }}
 >
 <div style={{ "fontFamily": "Poppins", }}>
 <strong>{`Showing ${count} of ${total} entries`}</strong>
 </div>


 <div className="d-flex btn-group p-1" style={selectStyleBorder}>
 <select
 style={selectStyleLeftBorder}
 className=" d-flex justify-content-end "
 aria-label="offset"
 onChange={(e) => {
 callback2(e.target.value);
 }}

 >
 {offsetArr.map((offset) => (
 <option value={offset} className="d-flex justify-content-end">{offset}</option>
 ))}

 </select>
 {page - 2 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}

 className="rounded-0 bg-white text-dark border-white"

 >
 {"<<"}
 </Button>
 ) : null}

 {page - 1 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}

 className="rounded-0 bg-white text-dark border-white underline-text"
 >
 {" "}
 {(page - 1).toString()}
 </Button>
 ) : null}



 <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




 {/* Next And Previous Button Code */}
 {page + 1 <= limit ? (
 <Button
 onClick={() => {
 callback(page + 1);
 }}

 className="rounded-0 bg-white text-dark border-white"
 >
 {">>"}
 </Button>
 ) : null}
 </div>
 </div>
 );
};

export default AepsSettlementReportTable;