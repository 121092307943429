import React, { useEffect, useState } from "react";
import { useQuery } from "../custom-hooks/useQuery";
import { twoDecimalPlaces } from "../utils/utilFuctions";

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function inWords(num) {
  let n;
  if ((num = num.toString()).length > 9) return "overflow";
  n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      "only "
      : "";
  return capitalizeFirstLetter(str);
}

const PrintSlip = () => {
  let printData = localStorage.getItem("print");
  const [txnData, setTxnData] = useState([]);
  const [total, setTotal] = useState(0);
  if (printData) {
    printData = JSON.parse(printData);
  } else {
    printData = {};
  }
  let query = useQuery();
  const receiptNo = query.get("id");
  const Mode = query.get("type");
  const printReceipt = () => {
    window.print();
  };
  const NOTE =
    "This is Computer Generated receipt & does not require physical signature. Charges minimum Rs.12 or 1.2%.";

  const [data, setData] = useState({});

  const GetData = () => {
    setData({
      shopName: printData.shopName ? printData.shopName : "N/A",
      // mobileNo: printData.rch_mobile,
      mode: Mode,
      bankName: printData.fk_provider_id,
      remitterNumber: printData.remitterno,
      BeneficiaryName: printData.benfname,
      AccountNo: printData.rch_mobile,
      orderNo: printData.tid,
      date: printData.rch_indate,
      UtrNo: printData.utnno,
      Status: printData.rch_status_desc,
      amountPaid: printData.rch_amount,
      InWords: `${inWords(Number(printData.rch_amount))}`,
    });
  };

  const getPrintData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        tid: receiptNo,
        type: "DMT",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/user/get_receipt_data.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setTxnData(data.data2);
        data.data2.map((e) => {
          setTotal((prev) => prev + Number(e.rch_amount));
        }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPrintData();
    GetData();
  }, [Mode]);

  return (
    <>
      <div
        style={{
          margin: "5vw",
          border: "1px solid #000",
          borderRadius: "5px",
          padding: "1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <table style={{ fontSize: ".6rem" }}>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Receipt No :
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {receiptNo}
                </td>
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Date :
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.date}
                </td>
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Mode
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.mode}
                </td>
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Remitter Number
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.remitterNumber}
                </td>
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Shop Name
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.shopName}
                </td>
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                {/* <td
                style={{
                  fontSize: ".9rem",
                  padding: "0px 12px 0px 0px",
                  border: "none",
                }}
              >
                Mobile Number
              </td>
              <td
                style={{
                  fontSize: ".9rem",
                  padding: "0px 12px 0px 0px",
                  border: "none",
                }}
              >
                {data.mobileNo}
              </td> */}
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Bank Name
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.bankName}
                </td>
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Beneficiary Name
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {data.BeneficiaryName}
                </td>
              </tr>
              <tr
                style={{ padding: "0px", border: "none", background: "none" }}
              >
                <td
                  style={{
                    border: "1px solid #000",
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  Account No
                </td>
                <td
                  style={{
                    fontSize: ".9rem",
                    padding: "0px 12px 0px 0px",
                    border: "none",
                  }}
                >
                  {" "}
                  {data.AccountNo}
                </td>
              </tr>
            </table>
          </div>
          <div>
            {" "}
            <img
              src={require("../../assets/images/company/logo_square.png")}
              style={{ width: "10rem", margin: "1rem 0px" }}
            />{" "}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}></div>

        <div style={{ display: "flex" }}>
          <table style={{ width: "100%", fontSize: "0.9rem" }}>
            <tr style={{ padding: "0px", border: "none", background: "none" }}>
              <td style={{ fontSize: ".9rem", border: "2px solid #05569A" }}>
                {" "}
                Order No.
              </td>
              <td style={{ fontSize: ".9rem", border: "2px solid #05569A" }}>
                {" "}
                UTR No.
              </td>
              <td style={{ fontSize: ".9rem", border: "2px solid #05569A" }}>
                {" "}
                Status
              </td>
              <td style={{ fontSize: ".9rem", border: "2px solid #05569A" }}>
                {" "}
                Amount Paid (₹)
              </td>
            </tr>
            {txnData.map((e, i) => (
              <tr
                style={{
                  padding: "0px",
                  border: "2px solid #05569A",
                  background: "none",
                }}
              >
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {i + 1}{" "}
                </td>
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {" "}
                  {e.dmt_utr_no}
                </td>
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {e.Status}
                </td>
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {twoDecimalPlaces(e.rch_amount)}
                </td>
              </tr>
            ))}

            {txnData.length > 0 && (
              <tr
                style={{
                  padding: "0px",
                  border: "2px solid #05569A",
                  background: "none",
                }}
              >
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {" "}
                </td>
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {" "}
                </td>
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {"Total"}
                </td>
                <td style={{ fontSize: ".9rem", border: "2px solid #05569A", fontWeight: '600' }}>
                  {twoDecimalPlaces(total)}
                </td>
              </tr>
            )}
            {/* <tr style={{ padding: "0px", border: "none", background: "none" }}>
              <td style={{ fontSize: ".9rem" }}>{data.orderNo} </td>
              <td style={{ fontSize: ".9rem" }}> {data.dmt_utr_no}</td>
              <td style={{ fontSize: ".9rem" }}>{data.Status}</td>
              <td style={{ fontSize: ".9rem" }}>{data.rch_amount} </td>
            </tr> */}
            {/* <tr style={{ padding: "0px", border: "none", background: "none" }}>
            <td style={{ fontSize: ".9rem" }}> </td>
            <td style={{ fontSize: ".9rem" }}></td>
            <td style={{ fontSize: ".9rem" }}></td>
            <td style={{ fontSize: ".9rem" }}> {data.amountPaid}</td>
          </tr> */}
          </table>
        </div>
        <div style={{ display: "flex", fontSize: ".8rem" }}>
          {`Amount In words : `}

          <strong>{inWords(Number(total))}</strong>
        </div>
        <div style={{ display: "flex", fontSize: ".8rem" }}>
          <strong> {`NOTE : `}</strong>
          {NOTE}
        </div>
        <div style={{ display: "flex", padding: "1rem 0rem" }}>
          <div style={{ flex: "1" }} />
          <button
            style={{ marginLeft: "auto" }}
            className="hide-on-print"
            onClick={printReceipt}
          >
            {"Print"}
          </button>
        </div>
      </div>
    </>
  );
};

export default PrintSlip;
