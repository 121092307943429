import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { BASE_URL_APISERVICES, BASE_URL_PAYNOW } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import "../dashboard/MyTable.css";
import swal from "sweetalert";
export const columns = [
    {
        dataField: "tid",
        text: " Txnt ID",

        color: "#000",
    },
    {
        dataField: "rrn_no",
        text: "RRN No.",

        color: "#000",
    },
    {
        dataField: "service",
        text: "Service",
        color: "#000",
    },
    {
        dataField: "user_type",
        text: "User type",

        color: "#000",
    },
    {
        dataField: "user_code",
        text: "Retailer Code",

        color: "#000",
    },
    {
        dataField: "retailer_name",
        text: "Retailer Name",

        color: "#000",
    },
    {
        dataField: "retailer_number",
        text: "Retailer Number",
        color: "#000",
    },
    {
        dataField: "onboarding_date",
        text: "Onboarding Date",
        color: "red",
    },
    {
        dataField: "kyc_status",
        text: "Kyc Status",
        color: "green",
    },
    {
        dataField: "dist_name",
        text: "Distributor Name",
        color: "#000",
    },
    {
        dataField: "dist_code",
        text: "Distributor Code",
        color: "#000",
    },
    {
        dataField: "dist_mobile",
        text: "Distributor Mobile",
        color: "#000",
    },

    {
        dataField: "cnf_code",
        text: "Master Distributor Code",
        color: "#000",
    },

    {
        dataField: "cnf_name",
        text: "Master Distributor Name",
        color: "#000",
    },

    {
        dataField: "cnf_mobile",
        text: "Master Distributor Mobile",
        color: "#000",
    },

    {
        dataField: "sales_user_code",
        text: "Sales Person Code",
        color: "#000",
    },

    {
        dataField: "sales_user_name",
        text: "Sales Person Name",
        color: "#000",
    },
    {
        dataField: "sales_user_mobile",
        text: "Sales Person Mobile",
        color: "#000",
    },
    {
        dataField: "sales_user_city",
        text: "Sales Person City",
        color: "#000",
    },
    {
        dataField: "transaction_amount",
        text: "Transaction Amount",
        color: "#000",
    },
    {
        dataField: "retailer_opening_balance",
        text: "Retailer Opening balance",
        color: "#000",
    },
    {
        dataField: "retailer_closing_balance",
        text: "Retailer Closing Balance",
        color: "#000",
    },
    {
        dataField: "retailer_comm",
        text: "Retailer Commission",
        color: "#000",
    },
    {
        dataField: "retailer_percentage_comm",
        text: "Retailer % Commission",
        color: "#000",
    },
    {
        dataField: "retailer_tds",
        text: "Retailer TDS",
        color: "#000",
    },
    {
        dataField: "retailer_sgst",
        text: "Retailer SGST",
        color: "#000",
    },
    {
        dataField: "retailer_cgst",
        text: "Retailer CGST",
        color: "#000",
    },
    {
        dataField: "dist_comm",
        text: "Distributor Commission",
        color: "#000",
    },
    {
        dataField: "dist_percentage_comm",
        text: "Distributor % Commission",
        color: "#000",
    },
    {
        dataField: "dist_tds",
        text: "Dsitributor TDS",
        color: "#000",
    },
    {
        dataField: "cnf_comm",
        text: "Master Distributor Commission",
        color: "#000",
    },
    {
        dataField: "cnf_percentage_comm",
        text: "Master Distributor % Commission",
        color: "#000",
    },
    {
        dataField: "cnf_tds",
        text: "Master Distributor TDS",
        color: "#000",
    },
    {
        dataField: "bumppy_charges",
        text: "Bumppy Charges",
        color: "#000",
    },

    {
        dataField: "status",
        text: "status",
        color: "#000",
    },



    {
        dataField: "date",
        text: "Date",
        color: "#000",
    },
];

const servicesOptions = [
    { value: "AEPS", label: "AEPS" },
    { value: "BBPS", label: "BBPS" },
    //  { value: "RECHARGE", label: "RECHARGE" },
    { value: "DMT", label: "DMT" },
    { value: "PG", label: "PG" },
    { value: "PG_SETTLEMENT", label: "PG_SETTLEMENT" }
]

const SuperAdminReportDownload = ({ setDetails }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [offset, setOffset] = useState(10);
    const [data, setData] = useState([]);
    const [service, setService] = useState({ value: "", label: "Select Service" });
    const [statusType, setStatusType] = useState(null);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const dateFormatter2 = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    function isJSONString(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }
    const getAllVirtualPayments = async () => {
        if (service.value == "") {
            swal("Please Select Service", "Error", "error");
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({

            "fromDate": dateFormatter2(startDate),
            "toDate": dateFormatter2(endDate),
            "service": service.value,

        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        try {
            setTransactionLoaderState(true);
            const res = await fetch(
                `http://apiuat.remittpe.com/api/Payment/admin/adminSuperAdminReportV1`,
                requestOptions
            );
            const data = await res.json();
            console.log(data);
            if (data.status == "0") {

                ExportToExcel(
                    `Common_Report_${dateFormatter()}`,
                    data.data
                );
            } else {
                swal("No Data Found", "Error From Server", "error");
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
        }
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    const filter = () => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                if (bank && statusType && bank.status.toString() == statusType.value) {
                    b = true;
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        // getAllVirtualPayments();
        setCurrentPage(1);
    };

    // did mount
    useEffect(() => {
        // getAllVirtualPayments();
    }, []);

    const searchBarStyle = {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "0px",
        backgroundColor: "#fff",
        width: "25%", // Adjust the width as needed
        height: "20%",
    };
    const inputStyle = {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: "16px",
        marginLeft: "8px",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: "400",
    };
    const iconStyle = {
        color: "rgba(0, 0, 0, 0.5)",
    };
    return (
        <div className="py-2 " style={{ backgroundColor: "rgb(238, 238, 238)" }}>
            <div className="card m-0 p-0">

                <div className="row m-0 py-4 px-4   align-items-center justify-content-start">
                    <div className="px-3 pb-2"
                        style={{
                            "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                            , padding: "0px",
                            background: "#FFFFFF",
                            color: "#4267B2",
                            fontWeight: "bolder",
                            fontfamily: "Poppins !impotent",
                            fontSize: "32px",
                        }}
                    >
                        Admin Report
                    </div>
                    <div
                        className="pr-1 pl-2 p-0 m-0"
                        style={{ width: "18%", height: "20%" }}
                    >
                        <Form.Group className="p-0 m-0">
                            <Select options={servicesOptions} value={service} onChange={(e) => setService(e)} />
                        </Form.Group>
                    </div>
                    <div
                        className="pr-1 pl-2 p-0 m-0"
                        style={{ width: "18%", height: "20%" }}
                    >
                        <Form.Group className="p-0 m-0">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control rounded-100"
                                placeholderText="Start Date"
                            />
                        </Form.Group>
                    </div>
                    <div
                        className="px-1  p-0 m-0"
                        style={{ width: "18%", height: "20%" }}
                    >
                        <Form.Group className="p-0 m-0">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                className="form-control rounded-100"
                                placeholderText="End Date"
                            />
                        </Form.Group>
                    </div>
                    <div
                        className="m-0 p-0  hover-container"
                        style={{ cursor: "pointer" }}
                        onClick={getAllVirtualPayments}
                    >
                        <img
                            className="pl-1 pt-1"
                            src={require("../../../assets/images/download Icon.png")}
                            style={{ width: `3.0rem`, height: "70%" }}
                        />
                        <div
                            div
                            style={{ color: "rgba(255, 255,255, 1)" }}
                            className="hover-text bg-dark "
                        >
                            Export to Excel
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });
    const selectStyleLeftBorder = {

        borderRadius: '0px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
        borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
        borderRadius: '0px',
        borderLeft: 'none', // Remove right side border
        borderTop: 'none', // Remove top border
        borderBottom: 'none', // Remove bottom border
        "fontFamily": "Poppins",
        'fontsize': '16px',




    };
    const selectStyleBorder = {
        border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
        borderRadius: '8px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
        backgroundColor: "#FFFFFF",
    };
    const selectStyle = {

        border: 'none', // Remove the border
        "fontFamily": "Poppins",
        'fontsize': '16px',

    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",

            }}
        >
            <div style={{ "fontFamily": "Poppins", }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>


            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}

                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">{offset}</option>
                    ))}

                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"

                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}



                <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default SuperAdminReportDownload;