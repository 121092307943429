import { BASE_URL_APISERVICES } from "../../utils/Constants";


export const updateUserDetails = async (req) => {
    try {

        let username = "rajneesh@bumppy.com";
        let password = "Test@12345";

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
          );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `${BASE_URL_APISERVICES}/api/Admin/userDetailsUpdate`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("User Update Failed.");
    }
};

export const aepsLog = async (req) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(req);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const res = await fetch(
            `https://bumppy.com/apibumppypay/v1/web/fingpay/reconReportV2.php`,
            requestOptions
        );
        return await res.json();

    } catch (error) {
        console.error({error})
        throw new Error("Failed.");
    }
};
