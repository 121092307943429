import React, { useContext, useState } from "react";
import { urls } from "../URLs";
import { BASE_URL_API_TRANXT_API } from "../utils/Constants";
import { useHistory } from "react-router";
import { ContextMenuTrigger } from "react-contextmenu";
import swal from "sweetalert";
import cogoToast from "cogo-toast";
import { GlobalContext } from "../Context/GlobalState";

export default function ForgetPassword() {
  const history = useHistory();
  const { login: loginUrl } = urls;
  const [userName, setUserName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [forgetSuccess, setForgetSuccess] = useState(false);
  const [textOtp, setTextOtp] = useState("");
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [formErrors1, setFormErrors1] = useState({ mobile: "" });
  const [formErrors3, setFormErrors3] = useState({ otp: "" });
  const [formErrors2, setFormErrors2] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [passShow, setPassShow] = useState(false);
  const [passShow2, setPassShow2] = useState(false);
  // const [userType, setUserType] = useState("");

  const goTOLoginScreen = () => history.push("/");

  const sendOTP = async () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: userName,
      otpvalue: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/otpsendone.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        cogoToast.success(data.msg.toString());
        setOtpSent(true);
      } else {
        cogoToast.success(data.msg.toString());
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const validateOTP = async () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: userName,
      otpvalue: textOtp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/otpsendtwo.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        // swal("Success", data.msg, "success");
        //  changePassword();
        setOtpValidated(true);
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const changePassword = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        mobileno: userName,
        reset_type: "PASSWORD",
        password: newPassword,
        currenttpin: '',
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        setNewPassword("");
        setConfirmPassword("");
        setForgetSuccess(true);
        swal("Success", `password changed successfully`, "success");
      } else {
        swal("Failed", "Password Changed Failed", "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const validate1 = () => {
    let errors = {};
    if (userName.length != 10) {
      errors.mobile = "please enter valid mobile number";
    }
    setFormErrors1(errors);
    if (Object.keys(errors).length === 0) {
      sendOTP();
    } else {
      setFormErrors1(errors);
    }
  };
  const validate2 = () => {
    let errors = {};
    if (newPassword.length < 8) {
      errors.newPassword = "password should be atleast 8 character long";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = "New Password and Confirm Password should same";
    }
    setFormErrors2(errors);
    if (Object.keys(errors).length === 0) {
      changePassword();
    } else {
      setFormErrors2(errors);
    }
  };
  const validate3 = () => {
    let errors = {};
    if (textOtp.length < 6) {
      errors.otp = "please enter valid otp";
    }
    setFormErrors3(errors);
    if (Object.keys(errors).length === 0) {
      validateOTP();
    } else {
      setFormErrors3(errors);
    }
  };
  const showNewPassword = () => {
    setPassShow(!passShow);
  };
  const showConfirmPassword = () => {
    setPassShow2(!passShow2);
  };
  return (
    <div
      className="row justify-content-center align-items-center my-auto mx-auto mt-5"
      style={{
        height: "100vh",
        background: "linear-gradient(to right, #EBEEF1, #EBEEF1)",
      }}
    >
      <div className="col-lg-4 col-md-8 col-sm-10 mx-auto">
        {forgetSuccess && <ForgetSuccess history={history} />}

        {!forgetSuccess && (
          <div className="card text-left p-4">
            <h4> Forget Password</h4>

            {!otpSent && (
              <React.Fragment>
                <div className="form-group">
                  <label>Mobile Number</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control form-control-lg no-glow"
                      id="exampleInputEmail"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => {
                        setError("");
                        setUserName(e.target.value);
                      }}
                    />
                  </div>
                  <p className="text-danger text-left p-0">
                    {formErrors1.mobile}
                  </p>
                </div>
                <div className="row">
                  <button
                    style={{ flex: "1" }}
                    className="btn btn-success flex-1 rounded-0"
                    onClick={validate1}
                  >
                    Send OTP
                  </button>
                </div>
              </React.Fragment>
            )}

            {otpSent && !otpValidated && (
              <React.Fragment>
                <div className="form-group">
                  <label> OTP</label>
                  <div className="input-group">
                    <input
                      type="password"
                      className="form-control form-control-lg no-glow"
                      id="exampleInputPassword"
                      placeholder="Enter OTP"
                      onChange={(e) => {
                        setError("");
                        setTextOtp(e.target.value);
                      }}
                      value={textOtp}
                    />
                  </div>
                  <p className="text-danger text-left p-0">{formErrors3.otp}</p>
                </div>
                <div className="row">
                  <button
                    style={{ flex: "1" }}
                    className="btn btn-success rounded-0"
                    onClick={validate3}
                  >
                    Validate OTP
                  </button>
                </div>
              </React.Fragment>
            )}
            {otpValidated && (
              <React.Fragment>
                <div className="form-group">
                  <label> New Password</label>
                  <div className="input-group">
                    <div className="input-group-prepend bg-transparent">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-lock-outline text-primary"></i>
                      </span>
                    </div>
                    <input
                      type={passShow ? "text" : "password"}
                      className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                      id="exampleInputPassword"
                      placeholder="new password"
                      onChange={(e) => {
                        setError("");
                        setNewPassword(e.target.value);
                      }}
                      value={newPassword}
                    />
                    <div className="input-group-prepend bg-transparent">
                      <span
                        className="input-group-text bg-transparent border-left-0 no-glow"
                        onClick={showNewPassword}
                      >
                        <i
                          className={`mdi text-primary ${
                            passShow ? "mdi-eye" : "mdi-eye-off"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <p className="text-danger text-left p-0">
                    {formErrors2.newPassword}
                  </p>
                </div>

                <div className="form-group">
                  <label> Confirm Password</label>
                  <div className="input-group">
                    <div className="input-group-prepend bg-transparent">
                      <span className="input-group-text bg-transparent border-right-0 no-glow">
                        <i className="mdi mdi-lock-outline text-primary"></i>
                      </span>
                    </div>
                    <input
                      type={passShow2 ? "text" : "password"}
                      className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                      id="exampleInputPassword"
                      placeholder="confirm password"
                      onChange={(e) => {
                        setError("");
                        setConfirmPassword(e.target.value);
                      }}
                      value={confirmPassword}
                    />
                    <div className="input-group-prepend bg-transparent">
                      <span
                        className="input-group-text bg-transparent border-left-0 no-glow"
                        onClick={showConfirmPassword}
                      >
                        <i
                          className={`mdi text-primary ${
                            passShow2 ? "mdi-eye" : "mdi-eye-off"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <p className="text-danger text-left p-0">
                    {formErrors2.confirmPassword}
                  </p>
                </div>
                <div className="row">
                  <button
                    style={{ flex: "1" }}
                    className="btn btn-success rounded-0"
                    onClick={validate2}
                  >
                    Reset Password
                  </button>
                </div>
              </React.Fragment>
            )}
            <div className="row">
              <button
                style={{ flex: "1" }}
                className="btn btn-outline-primary rounded-0"
                onClick={goTOLoginScreen}
              >
                <span className="mdi mdi-arrow-left"> </span>
                Back To Login
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const ForgetSuccess = ({ history }) => {
  const goTOLoginScreen = () => history.push("/");
  return (
    <div className="card text-left p-4">
      <div className="row flex-column align-items-center">
        <img
          className="my-2"
          src={require("../../assets/images/success_tick.png")}
          style={{ height: "4rem", width: "auto" }}
        />
        <span style={{ fontWeight: "700" }} className="my-2">
          Password Changed Successfully
        </span>
        <span className="my-2">Please login to your account</span>
        <button
          style={{ flex: "1" }}
          className="btn btn-primary btn-block rounded-0 my-2"
          onClick={goTOLoginScreen}
        >
          Login Now
        </button>
      </div>
    </div>
  );
};
