import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import PGSettlementReportTable from "./ProcessSalaryTable";
import { Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { twoDecimalPlaces } from "../../utils/utilFuctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "react-hook-form";
const ProcessSalary = () => {

  const { user } = useContext(GlobalContext);
  const [showModal, setShowModal] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [bulkState, setBulkState] = useState({ month: "", category: "" });
  const [showProcessAllModal, setShowProcessAllModal] = useState(false);

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    employeeId: "",
    month: "",
    category: "",
    amount: "",
  });
  const months = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },

  ];
  const categories = ["Salary", "Bonus", "Expenses"];

  const openModal = () => setShowModal(true);
  const openBulkModal = () => setShowBulkModal(true);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const addSalaryRecord = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: "N",
        employeeId: "12",
        month: formData.month,
        category: formData.category,
        amount: formData.amount,
        status: "N",
        step: "1",
        adminId: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/addEmployeeSalaryRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        setShowModal(false);
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getPreviousMonthSalary = (id) => {
    let salary = 0;
    employeesData.forEach((employee) => {
      if (employee.id == id) {
        salary = employee.previous_salary;
      }
    });

    return salary;
  }

  console.log(selectedIds);

  const addAllEmployeesSalaryRecord = () => {
    selectedIds.forEach(async (id) => {
      addSalaryRecordBulk(id)
    })
  }

  const addSalaryRecordBulk = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: "N",
        employeeId: id,
        month: bulkState.month,
        category: bulkState.category,
        amount: getPreviousMonthSalary(id),
        status: "N",
        step: "1",
        adminId: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/addEmployeeSalaryRecord`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        setShowModal(false);
        setShowProcessAllModal(false);
        setShowBulkModal(false)
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const GetAllEmployeeAdmin = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        adminId: user.pkid,
        sector: "it",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllSalaryEmployeeAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setEmployeesData(data.data);

      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPGSettlementReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "id": user.pkid,
        "status": "string",
        "type": "string"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllEmployeeSalaryRecords`,
        requestOptions
      )
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckboxChange = (id) => {
    const updatedSelectedIds = [...selectedIds];
    const index = updatedSelectedIds.indexOf(id);

    if (index === -1) {
      updatedSelectedIds.push(id);
    } else {
      updatedSelectedIds.splice(index, 1);
    }

    setSelectedIds(updatedSelectedIds);
  };
  const handleSelectAllChange = () => {
    const allIds = employeesData.map((e) => e.id);
    const updatedSelectedIds = selectedIds.length === allIds.length ? [] : allIds;
    setSelectedIds(updatedSelectedIds);
  };
  const handleProcessAllClick = () => {
    if (selectedIds.length === 0) {
      swal("Please select at least one item to process.", "", "warning");
    } else {
      setShowProcessAllModal(true);
    }
  };

  const proccessSalary = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "id": id,
        "status": "Y",
        "type": "1"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/proccessEmployeeSalary`,
        requestOptions
      )
      const data = await res.json();
      if (data.status == "0") {
        swal("Salary Processed Successfully", "", "success");
        fetchPGSettlementReport();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetAllEmployeeAdmin();
    fetchPGSettlementReport();
  }, [])
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "0.5rem" }} className="bg-light">
          <div
            style={{ borderRadius: "10px" }}
            className="card  m-0 mt-4 ml-1 mr-1 p-0"
          >
            <div className="card-body m-0 p-2">
              <div className="row">
                <div
                  className="col px-3 pb-2"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "48px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize: "32px",
                  }}
                >
                  Process Salary
                </div>
                <div className="col d-flex justify-content-end">
                  <Button style={{ height: "2.5rem" }} onClick={openModal}>
                    Add Salary
                  </Button>
                  <div className="ml-1"></div>
                  <Button style={{ height: "2.5rem" }} onClick={openBulkModal}>
                    Bulk Add Salary
                  </Button>

                  <div className="mx-1">
                    <Link to="/admin/bulk_salary_add">
                      <Button style={{ height: "2.5rem" }}   >
                        Bulk Salary Table
                      </Button></Link>
                  </div>
                </div>
              </div>

              <PGSettlementReportTable />
            </div>
          </div>

          <Modal show={showBulkModal} onHide={() => setShowBulkModal(false)}>
            <Modal.Header className="row m-0 " closeButton>
              <Modal.Title className="col">Add Bulk Salary</Modal.Title>
              <div className="col d-flex justify-content-end">
                <Button onClick={handleProcessAllClick}>Process All</Button></div>
            </Modal.Header>
            <Modal.Body>
              <table
                className="my-table transparent-table text-center border-bottom"
                style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}
              >
                <tr className="borderless-table text-center border-bottom">
                  <th
                    className="my-table transparent-table text-center border-bottom "
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={handleSelectAllChange}
                      checked={selectedIds.length === data.length}
                    />
                  </th><th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
                  <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                    Name
                  </th>


                  <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                    Amount
                  </th>


                </tr>
                {employeesData.map((e, ind) => (
                  <tr className="border-bottom">
                    <td
                      className="text-center border-bottom"
                      style={{
                        fontSize: "0.8rem",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(e.id)}
                        checked={selectedIds.includes(e.id)}
                      />
                    </td>
                    <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                      {" "}
                      {ind + 1}
                    </td>
                    <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                      <div>{e.employee_name}</div>
                    </td>

                    <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{twoDecimalPlaces(e.previous_salary)}</td>




                  </tr>
                ))}
              </table>
            </Modal.Body>
          </Modal>

          <Modal show={showProcessAllModal} onHide={() => setShowProcessAllModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Select </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="col" controlId="monthDropdown">
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {" Select Month"}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="month"
                    value={bulkState.month}
                    onChange={(e) => {
                      setBulkState({ ...bulkState, month: e.target.value })
                    }}
                  >
                    {months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="col" controlId="categoryDropdown">
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {` Select category`}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    value={bulkState.category}
                    onChange={(e) => {
                      setBulkState({ ...bulkState, category: e.target.value })
                    }}
                  >
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="col" controlId="categoryDropdown">
                  <Button onClick={addAllEmployeesSalaryRecord} >
                    Process All
                  </Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>


          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Salary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="row">
                  <Form.Group className="col" controlId="employeeDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {` Select Employee`}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="employeeId"
                      value={formData.employeeId}
                      onChange={handleChange}
                    >
                      {employeesData.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {employee.employee_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col" controlId="monthDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {" Select Month"}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="month"
                      value={formData.month}
                      onChange={handleChange}
                    >
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col" controlId="categoryDropdown">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {` Select category`}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col" controlId="amountInput">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="text"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      placeholder="Enter Amount"
                    />
                  </Form.Group>
                </div>

                <Button variant="success" onClick={addSalaryRecord}>
                  Add Salary Record
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default ProcessSalary;
