import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { basicAuth } from "../../../config";
import Select from "react-select";
import cogoToast from "cogo-toast";
import { BASE_URL_PAYNOW } from "../../../utils/Constants";
import { OFFLINE_TEXT, ONLINE_TEXT } from "../../../utils/StringConstants";
import { PRINT_ICON_PATH, BBPS_LOGO_PATH } from "../../../utils/Assets";
import { Divider } from "semantic-ui-react";
import { uniqueID } from "../../../utils/utilFuctions";
import { GlobalContext } from "../../../Context/GlobalState";
import { BillerResponse } from "../../../utils/BillerResponse";
import {
  bbpsBillFailedPopup,
  bbpsBillSuccessPopup,
} from "../../../utils/Models";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const OfflineElectricitySidebar = ({ details }) => {
  const { user, refreshWallet, checkWalletBeforeBBPSBillPay, Profileinfo } =
    useContext(GlobalContext);
  console.log(user);
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  const [providers, setProviders] = useState([]);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [PayClicked, setPayClicked] = useState(false);
  const [CustP, setCustP] = useState([]);
  const [consumerNo, setConsumerNo] = useState("");
  const [offlineBillDetails, setOfflineBillDetails] = useState(null);
  const [billerResponse, setBillerResponse] = useState({
    msg: "",
    success: "",
    error: "",
  });
  const [formErrors, setFormErrors] = useState({
    provider: "",
    consumer: "",
  });
  const [tpinShow, setTpinShow] = useState(false);

  console.log(billDetails);
  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify(data));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const validate = (callback) => {
    let errors = {};
    if (state.provider == "") {
      errors.provider = "please select valid provider";
    }
    if (consumerNo.toString().trim() == "") {
      errors.consumer = "please enter valid consumer number";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      callback();
    } else {
      setFormErrors(errors);
    }
  };
  const payBillClickHandler = () => {
    validate(pay);
  };
  const fetchBillClickHandler = () => {
    validate(fetchBill);
  };

  const pay = () => {
    if (billDetails.billerResponse && billDetails.billerResponse.amount) {
      setPayClicked(true);
      checkWalletBeforeBBPSBillPay(
        billDetails.billerResponse.amount,
        () => {
          payBill();
        },
        () => {
          setPayClicked(false);
        }
      );
    } else {
    }
  };

  const payBill = async () => {
    if (billDetails.billerResponse && billDetails.billerResponse.amount) {
      setPayClicked(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLBfoOkVkY05kLzokPEjB2gYPZGX2IFI8p3H0PEzF-p7wNZjiZfnXRdvZdIMIyaC1lYuwdjzFmdperUM0s9TM94lKMbwmIV0Pb59n7l7GoS9_79dTq5PWPZ0y3VdPKnyVo03tMA9YcwFLWgiuSXqENgj4_89begK4a8D2ZtoerNgjvoUB42YQrPaqK03_hQ_bQ5gokfp-WDObRNe2E6tchKD-li8J2lJqv_mk2nggZqHG3Ifugxw76p8N8_zupiGMhMnAbh4f96POibRScoZR7TL21UYKn9whd8OfiXzt9qT0HP2wraqsSCBvhjGeeocfJXi7LJ-AQGVnlLK2F15MNC5DxCRMnYP85B6qX3UoJ0IxXTcwJarMuClTLT0C_cYOkLDz7NF-DcFx4FtokyCvN6Jz4ZvTjOjnB9Coz75_GGjwHJNP3Un0RS87YFiDCssQYLLc2_rhS0BcGQz0Ex-oqnYRflsTI0guSTVj65oieVS1U9nE1RovsN6rUECWw4OMao1iRrVEgg77JZsYWRnGSuPXG2s4H8W5LvTjKoCA71m0wTV10FRhRHRa4-jrxHjv7nU9bzfvHNalmofvZgmIZuMzU2fTr35aYeg9E2ReWTIg6iq60FYeFZCnf3ce_N_MAWxHo11U0OWyt3uy9HEdHU0"
      );

      var raw = JSON.stringify({
        consumer_no: consumerNo,
        amount: billDetails.billerResponse.amount,
        operatorcode: state.provider.code,
        ebill_type: state.provider.biller_type,
        unique_id: uniqueID(),
        mobile_no: Profileinfo.mobile,
        retailer_id: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const res = await fetch(
          `${BASE_URL_PAYNOW}/api/Biller/BillPayOffline`,
          requestOptions
        );
        const data = await res.json();
        console.log(data);
        if (data.status == "00") {
          bbpsBillSuccessPopup(
            "",
            billDetails.billerResponse.amount,
            data.responseMSG
          );
        } else {
          bbpsBillFailedPopup(
            "",
            billDetails.billerResponse.amount,
            data.responseMSG ? data.responseMSG : "Failed"
          );
        }
        setPayClicked(false);
        refreshWallet();
      } catch (error) {
        bbpsBillFailedPopup("", billDetails.billerResponse.amount, "Failed");
        setPayClicked(false);
        refreshWallet();
      }
    } else {
      cogoToast.info("Bill not Found");
    }
  };
  console.log(billDetails);

  const fetchBill = async () => {
    setBillDetails(null);
    setFetchButtonText("Fetching bill ...");
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLC_1TlMl8CCKRbhja1vIHH4qb3lf5EJ9TYJYhxx9HzZwFh8bwvqTLT20kiANNSX2zPiCnoTbIVw4U_pJSRPTxokNgWrAfkySgA3FaA3pgiWpduBH4o_kwc1Ktx9_wgcDUYFrpoVVhpoVpoVuyu9bRnURZhZqKeh04eSZwdlFY3_J7eQNvNOp0VxxWl3Q3h35GyhlIFm9F72tKO7s6lbzxFCvvBQC8rSlDsw3vBnPGY02Kssv2VQC0FO3cUd6WqqlkOHUqh4IfXcTGg_rfpcAw5jY_YIIcObc3RnuUUBi8r0zV6fj7KoCU4dseTw1Cx5m4mFCK_ijZ9TJ8JtXyJ1WRZh2cgCU2anaqrzfqDOaFpn6UCWgNdJCwWlRIEzlQ774k9jKrOEMw3Ij1Kn8K8-5T0XyUtT2WP4JZuispQWCB9rKtMaWaZK_qm5MBpGpzYIUNyOoZRu53wb400pM42MEWKVPgImHYmZkYWx5yOw5umC_6c0f-Rjmlm_3sS9gjsRukecC_s5yTIEk1yoInGKpWqMdlwKBchE_cyT11mEAJBE0NFVflKKOzEXcnBDaWNsRd2MvQyZwOmsTm5S0dFXPltD4_L_WmChB1S3V-FYVW3EQ0fhYMVGlvpCTZIk0xUDecc_H-GlXCVCOSOf-gyOkt3a"
      );

      var raw = JSON.stringify({
        consumer_no: consumerNo,
        operatorcode: state.provider.code,
        ebill_type: state.provider.biller_type,
        unique_id: uniqueID(),
        mobile_no: Profileinfo.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillFetchOffline`,
        requestOptions
      );
      const data = await res.json();
      setOfflineBillDetails(data);
      if (data.rdata && data.rdata.length > 0) {
        const rdata = data.rdata[0];
        if (rdata.customerName && rdata.customerName != "") {
          const details = {
            refId: "",
            billerResponse: {
              customerName: rdata.customerName,
              billNumber: "",
              amount: rdata.billamount,
              billDate: rdata.billdate,
              dueDate: rdata.duedate,
              desc: rdata.desc,
            },
          };
          setBillDetails(details);
        } else {
          setBillerResponse({
            ...billerResponse,
            success: "",
            error: rdata.desc,
            msg: rdata.desc,
          });
        }
      } else {
        setBillerResponse({
          ...billerResponse,
          success: "",
          error: "Something went wrong",
          msg: "Something went wrong",
        });
      }
      setFetchButtonText("Fetch bill");
    } catch (error) {
      setFetchButtonText("Fetch bill");
      console.log("error", error);
    }
  };

  useEffect(() => {
    setProviders(
      details.providers.map((pro) => {
        return { ...pro, value: pro.biller_name, label: pro.biller_name };
      })
    );
  }, [details]);
  useEffect(() => {
    document
      .querySelector(".content-wrapper")
      .classList.remove("sidebar-icon-only");
  }, []);
  
  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  return (
    <nav
      className="side-sheet sidebar Sidebar23"
      id="sidebar2"
      style={Sidebar22}
    >
      <div
        style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
        className="row"
      >
        <div
          style={{ padding: "6px", display: "flex", alignItems: "center" }}
          className="col"
        >
          <strong style={{ color: "#1b68ca" }}>
            {"Electricity Bill Payment"}
          </strong>
        </div>
        <div style={{ paddingRight: "0px" }} className="col">
          <span
            style={{
              fontSize: "2rem",
              display: "flex",
              color: "#b2b2b3",
              justifyContent: "end",
              ariaHidden: "true",
              paddingRight: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only");
            }}
          >
            &times;
          </span>
        </div>
      </div>
      <Dropdown.Divider />

      <Card style={{ margin: "10px", padding: "10px" }}>
        <Form.Group style={{ flex: "1" }}>
          {/* <Form.Label>{`Select Provider`}</Form.Label> */}
          <Select
            options={providers}
            placeholder={`Select Provider`}
            onChange={(e) => {
              setState({
                ...state,
                provider: e,
              });
            }}
          />
           <p style={{ color: "red" }} className="">
            {formErrors.provider}
          </p>
        </Form.Group>
        <Form.Group style={{ flex: "1" }}>
          <Form.Label>
            {" "}
            <span style={{ color: "red" }}>*</span> {`consumer number`}
          </Form.Label>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter Consumer Number"
            onChange={(e) => {
              setConsumerNo(e.target.value);
            }}
          />
           <p style={{ color: "red" }} className="">
            {formErrors.consumer}
          </p>
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="rounded-0"
            style={{ margin: "0px 10px", minWidth: "11rem" }}
            variant="primary"
            onClick={fetchBillClickHandler}
            disabled={fetchButtonDisable}
          >
            {fetchButtonText}
          </Button>
        </div>
      </Card>
      {billDetails && (
        <Card style={{ margin: "10px", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src={BBPS_LOGO_PATH}
              style={{
                width: "auto",
                height: "1.2rem",
                backgroundColor: "whitesmoke",
              }}
            />
            <img
              onClick={() => {
                openInNewTab(billDetails);
              }}
              style={{ height: "1.5rem" }}
              src={PRINT_ICON_PATH}
            />
          </div>
          <table style={{ border: "none", fontFamily: "-webkit-body" }}>
            <tr style={{ background: "none", border: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Name
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.customerName
                  ? billDetails.billerResponse.customerName
                  : "NA"}
              </td>
            </tr>
            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Bill Number
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.billNumber
                  ? billDetails.billerResponse.billNumber
                  : "NA"}
              </td>
            </tr>
            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Amount
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.amount
                  ? billDetails.billerResponse.amount
                  : "NA"}
              </td>
            </tr>
            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Bill date
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.billDate
                  ? billDetails.billerResponse.billDate
                  : "NA"}
              </td>
            </tr>

            <tr style={{ background: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Due Date.
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.billerResponse.dueDate
                  ? billDetails.billerResponse.dueDate
                  : "NA"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                Reference Id
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                {billDetails.refId ? billDetails.refId : "NA"}
              </td>
            </tr>
            <tr style={{ background: "none", border: "none" }}>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {"Description "}
              </td>
              <td
                style={{
                  paddingLeft: "1rem",
                  border: "none",
                  background: "none",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                {billDetails.billerResponse.desc
                  ? billDetails.billerResponse.desc
                  : "NA"}
              </td>
            </tr>
          </table>

          {/* <div>
            <Button
              className="rounded-0"
              variant="outline-info"
              style={{ float: "right", margin: "0px 8px" }}
            >
              {ONLINE_TEXT}{" "}
            </Button>
            <Button
              className="rounded-0"
              variant="outline-info"
              style={{ float: "right" }}
            >
              {" "}
              {OFFLINE_TEXT}
            </Button>
          </div>
        */}
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "5rem",
            }}
          >
            {PayClicked ? (
              <Button className="rounded-0" variant="success">
                {" "}
                Paying ...
              </Button>
            ) : (
              <Button className="rounded-0" variant="success" onClick={payBillClickHandler}>
                {" "}
                Pay Bill
              </Button>
            )}
          </div>
        </Card>
      )}
      {billerResponse.error != "" && (
        <BillerResponse msg={billerResponse.msg} />
      )}
    </nav>
  );
};

export default withRouter(OfflineElectricitySidebar);
