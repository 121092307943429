import React, { useState } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import LedgerReportTable from "./LedgerReportTable";
const LedgerReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div style={{ margin: "10px" , backgroundColor:"#F1F2F4" , height:"100%" , width:"100%" }}>
            <div
                style={{
                display: "flex",
               padding: "10px",
                justifyContent: "space-between",
              }}>
              <h4>
              <strong>Ledger History</strong>
              </h4>
               </div>
            <LedgerReportTable />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default LedgerReport;
