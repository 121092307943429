import React, { useState, useContext, useEffect } from "react";
import VirtualReportTable from "../virtual report/VirtualReportTable";
import { writeFile, utils } from "xlsx";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";

const VirtualReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div style={{ backgroundColor:"#F1F2F4" , height:"100%" , width:"100%" }}>
            <div
                style={{
                display: "flex",
               padding: "10px",
                justifyContent: "space-between",
              }}>
              <h4 className="ml-2">

              <strong>Virtual Report</strong>
              </h4>
            </div>
            <VirtualReportTable />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default VirtualReport;
