import React from "react";
import { Col, Container, Row, Button, Table } from "react-bootstrap";

const Offers = () => {
  return (
    <div>
      <Container className="d-class1 p-2 my-4">
        <Row>
          <Col lg={4} md={4} sm={4}>
            <Button className="btn-tttt">Offers</Button>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="d-flex">
              <Button className="btn-tttt">+Create New Offer</Button>
              <Button className="btn-tttt">+Create No Cost EMI</Button>
            </div>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="d-flex">
              <Button className="btn-fffff border">
                Need help?Take a Tour
              </Button>
              <Button className="btn-fffff  border">Documentation</Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="d-class1 p-3">
        <Table responsive style={{ fontFamily: "Poppins" }}>
          <thead style={{ background: "#fffff" }}>
            <tr style={{ background: "#fffff" }}>
              <th className="th-ffff">
                <b>OFFER ID</b>
              </th>
              <th className="th-ffff">
                <b>Title</b>
              </th>
              <th className="th-ffff">
                <b>Promotion</b>
              </th>
              <th className="th-ffff">
                <b>Payment Method</b>
              </th>
              <th className="th-ffff">
                <b>Start On</b>
              </th>
              <th className="th-ffff">
                <b>Start On</b>
              </th>
              <th className="th-ffff">
                <b>Status</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-rrrr">Offer-1234</td>
              <td className="td-rrrr">Get upto 1000</td>
              <td className="td-rrrr">Discount & Cashback</td>
              <td className="td-rrrr">Paylater</td>
              <td className="td-rrrr">19/03/2023(10:00)</td>
              <td className="td-rrrr">19/03/2023(10:00)</td>
              <td className="td-rrrr">
                <small>
                  <Button className="btn-ttt">Enabled</Button>
                </small>
              </td>
            </tr>
            <tr>
              <td className="td-rrrr">Offer-1234</td>
              <td className="td-rrrr">Get upto 1000</td>
              <td className="td-rrrr">Discount & Cashback</td>
              <td className="td-rrrr">Paylater</td>
              <td className="td-rrrr">19/03/2023(10:00)</td>
              <td className="td-rrrr">19/03/2023(10:00)</td>
              <td className="td-rrrr">
                <small>
                  <Button className="btn-ttt">Enabled</Button>
                </small>
              </td>
            </tr>

            <tr>
              <td className="td-rrrr">Offer-1234</td>
              <td className="td-rrrr">Get upto 1000</td>
              <td className="td-rrrr">Discount & Cashback</td>
              <td className="td-rrrr">Paylater</td>
              <td className="td-rrrr">19/03/2023(10:00)</td>
              <td className="td-rrrr">19/03/2023(10:00)</td>
              <td className="td-rrrr">
                <small>
                  <Button className="btn-ttt">Enabled</Button>
                </small>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default Offers;
