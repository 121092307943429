import React from 'react'
import { Col, Container, Row,Button } from 'react-bootstrap'

const Subscription = () => {
  return (
    <div className="m-0 p-0 mx-5">
      <Row>
        <Col lg={4} md={4} sm={4}>
          <img
            src={require('../../../assets/images/price.png')}
            alt='image'
            style={{ width: '90%', height: 'auto' }}
          />
        </Col>
        <Col lg={8} md={8} sm={8}>
          <div className='mt-4'>
            <h2 style={{ color: '#3A98B9', fontFamily: 'Poppins' }}>
              <strong>Subscription</strong>
            </h2>
            <h3 style={{ fontFamily: 'Poppins' }}>
              Collect Recurring payments from customer with Bumppy payment
              subscription APIs
            </h3>
            <div
              className='d-flex py-4'
              style={{ justifyContent: 'space-between' }}
            >
              <div
                className='d-flex '
                style={{ flexDirection: 'column', fontFamily: 'Poppins' }}
              >
                <strong className='mb-3'>*% Reduction in charges</strong>
                <strong className='mb-3'>24% Lower Collection cost</strong>
                <strong>Upto 1.7x increases in CLTV</strong>
              </div>
              <div
                className='d-flex'
                style={{ alignSelf: 'flex-end', fontFamily: 'Poppins' }}
              >
                <Button>GET Started</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <h2 className='p-3'>
          <b>Plans</b>
        </h2>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={12} className="mt-2">
          <div className='d-class1' style={{height:"60vh"}}>
            <div
              className='d-flex'
              style={{ flexDirection: 'column', alignItems: 'center',backgroundColor:"#B54098",borderTopRightRadius:"20px",borderTopLeftRadius:"20px"}}
            >
              <h2 className='mt-3' style={{color:"#ffff"}}>
                <b>Basic</b>
              </h2>
              <h6 style={{ color:"#ffff" }}>
                Get Your Business Up & Running
              </h6>
            </div>
            <div
              className='d-flex mt-4'
              style={{ flexDirection: 'column', alignItems: 'center' }}
            >
              <h6>Domestic Money Transfer</h6>
              <h6>AEPS</h6>
              <h6>BBPS</h6>
              <h6>LIC</h6>
            </div>
          </div>
          <div className='d-flex justify-content-center mt-3'>
          <Button className='btn-price' style={{backgroundColor:"#B54098"}}>Choose The Plan</Button>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} className="mt-2">
          <div className='d-class1' style={{height:"60vh"}}>
            <div
              className='d-flex'
              style={{ flexDirection: 'column', alignItems: 'center',backgroundColor:"#28BD98",borderTopRightRadius:"20px",borderTopLeftRadius:"20px" }}
            >
              <h2 className='mt-3' style={{color:"#ffff"}}>
                <b>Advanced</b>
              </h2>
              <h6 style={{ color:"#ffff" }}>
                Get Your Business Up & Running
              </h6>
            </div>
            <div
              className='d-flex mt-4'
              style={{ flexDirection: 'column', alignItems: 'center' }}
            >
              <h6>Domestic Money Transfer</h6>
              <h6>AEPS</h6>
              <h6>BBPS</h6>
              <h6>LIC</h6>
              <h6>CMS</h6>
              <h6>REPORTING</h6>
              
            </div>
          </div>
          <div className='d-flex justify-content-center mt-3'>
          <Button className='btn-price' style={{backgroundColor:"#28BD98"}}>Choose The Plan</Button>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} className="mt-2">
          <div className='d-class1' style={{height:"60vh"}}>
            <div
              className='d-flex'
              style={{ flexDirection: 'column', alignItems: 'center',backgroundColor:"#F69222",borderTopRightRadius:"20px",borderTopLeftRadius:"20px" }}
            >
              <h2 className='mt-3' style={{color:"#ffff"}}>
                <b>Premium</b>
              </h2>
              <h6 style={{ color:"#ffff" }}>
                Get Your Business Up & Running
              </h6>
            </div>
            <div
              className='d-flex mt-4'
              style={{ flexDirection: 'column', alignItems: 'center' }}
            >
              <h6>Domestic Money Transfer</h6>
              <h6>DMT EXPRESS</h6>
              <h6>dMT WITH KYC</h6>
              <h6>LIC</h6>
              <h6>CMS</h6>
              <h6>AEPS</h6>
              <h6>BBPS</h6>
              <h6>REPORTING</h6>
            </div>
          </div>
          <div className='d-flex justify-content-center mt-3'>
          <Button className='btn-price' style={{backgroundColor:"#F69222"}}>Choose The Plan</Button>
          </div>
        </Col>
      </Row>
  </div>
  )
}

export default Subscription
