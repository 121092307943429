import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { urls } from "../../URLs";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import { REPORT_PAGE_RECORD_COUNT } from "../../config";
import { generateHashKey2 } from "../../utils/utilFuctions";
import { activateBeneficiaryAPI, deactivateBeneficiaryAPI, verifyBeneficiaryAPI } from "../../api/dmt/DmtApi";
const { DMTWithKYCUrl } = urls;
const DMT4Table = ({
    successToast,
    errorToast,
    data,
    columns,
    remitterNumber,
    userId,
    refresh,
    setSidebarDetails,
    limit,
}) => {
    const recordPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [idx, setIdx] = useState(-1);
    const { user, refreshWallet, setTransactionLoaderState } =
        useContext(GlobalContext);

    const deactivateBenificiary = async (benificiary) => {

        setTransactionLoaderState(true);

        try {
            const request = {
                "remitterNumber": remitterNumber,
                "userid": user.ReferId,
                "ifscCode": benificiary.ifsc,
                "accountNumber": benificiary.account

            }

            const res = await deactivateBeneficiaryAPI(request);
            if (res.status == "0") {
                // cogoToast.success(res.msg)
                swal("Success", res.msg, "success");
            } else {
                // error swal
                swal('Failed', res.msg, 'error')
            }
            setTransactionLoaderState(false);
            refresh();
        } catch (error) {
            setTransactionLoaderState(false);
            swal('Failed', "Error From Server", 'error')
        }
    };

    const activateBenificiary = async (benificiary) => {

        setTransactionLoaderState(true);

        try {
            const request = {
                "remitterNumber": remitterNumber,
                "userid": user.ReferId,
                "ifscCode": benificiary.ifsc,
                "accountNumber": benificiary.account

            }

            const res = await activateBeneficiaryAPI(request);
            if (res.status == "0") {
                // cogoToast.success(res.msg)
                swal("Success", res.msg, "success");
            } else {
                // error swal
                swal('Failed', res.msg, 'error')
            }
            setTransactionLoaderState(false);
            refresh();
        } catch (error) {
            setTransactionLoaderState(false);
            swal('Failed', "Error From Server", 'error')
        }
    };

    const deactivateBenificiaryConfirmation = (bene) => {
        swal({
            title: "Are you sure?",
            text: `You want to deactive this beneficiary ${bene.account}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (bene.beneficiaryStatus == "1") {
                    deactivateBenificiary(bene)
                } else {
                    activateBenificiary(bene)
                }

            }
        });
    };


    const verify = (bene) => {
        swal({
            title: "Are you sure?",
            text: "3 Rupees deducted from your Account",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                // setBenificiary(bene);
                verifyBeneficiary(bene);
            }
        });
    };

    const verifyBeneficiary = async (benificiary) => {
        setTransactionLoaderState(true);

        try {
            const request = {
                "beneMobile": benificiary.recipient_mobile,
                "userid": user.ReferId,
                "ifsc": benificiary.ifsc,
                "accountno": benificiary.account,
                "paytmlimit": limit,
                "bankitlimit": limit,
                "beneName": benificiary.recipient_name.toString().replace("&", ""),
                "remitterNumber": remitterNumber
            }

            const res = await verifyBeneficiaryAPI(request);
            if (res.status == "0") {
                // cogoToast.success(res.msg)
                swal("Success", res.msg, "success");
            } else {
                // error swal
                swal('Failed', res.msg, 'error')
            }
            setTransactionLoaderState(false);
            refreshWallet();
            refresh();
        } catch (error) {
            setTransactionLoaderState(false);
            swal('Failed', "Error From Server", 'error')
        }

    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(
            Math.floor((filteredData.length + (recordPerPage - 1)) / recordPerPage)
        );
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = recordPerPage - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        const startIdx = (currentPage - 1) * recordPerPage;
        const endIdx = currentPage * recordPerPage - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (recordPerPage - 1)) / recordPerPage));
        const startIdx = 0;
        const endIdx = recordPerPage - 1;
        setTotal(data.length);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
    }, []);

    useEffect(() => {
        setPages(Math.floor((data.length + (recordPerPage - 1)) / recordPerPage));
        const startIdx = 0;
        const endIdx = recordPerPage - 1;
        setTotal(data.length);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
    }, [data]);
    return (
        <>

            <style>
                {`
    .odd-row {
      background-color: #ffffff;
      /* Light gray color for odd rows */
    }

    .even-row {
     /* White color for even rows */
     
      background-color: rgba(30, 139, 195,0.03   );
    }
    .table {
      border: none !important; /* Remove border from the table */
    }
    
    .table th,
    .table td {
      border: none !important; /* Remove border from table cells */
    }
  `}
            </style>
            <div className="row my-0 py-0">
                <div className="col-12 p-0 m-0 ">
                    <div className="my-0 py-0 d-flex justify-content-end " style={{ width: "100%" }}>
                        <div className="col-6 my-0 py-0" style={{ width: "100%" }}>
                            <Form.Group className="">
                                <Form.Control
                                    type="text"
                                    className="form-control rounded-100"
                                    aria-label="Notes"
                                    aria-describedby="basic-addon1"
                                    placeholder="Search in table"
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                    value={searchText}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-0 m-2  " style={{ backgroundColor: "rgba(30, 139, 195,0.03   )", border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
                <table
                    className=" table-striped "
                    style={{
                        width: "100%", borderCollapse: "collapse"
                    }}
                >
                    <tr
                        className=""
                        style={{
                            width: "100%", border: "none"
                        }}
                    >
                        <th className=" text-dark  py-3" style={{ backgroundColor: "rgba(30, 139, 195,0.03   )", fontSize: "0.9rem", borderBottom: "1px solid #000", border: "none" }}>
                            {"Sr"}
                        </th>
                        <th className=" text-dark  py-3" style={{ backgroundColor: "rgba(30, 139, 195,0.03   )", fontSize: "0.9rem", borderBottom: "1px solid #000", border: "none" }}>
                            {"Name"}
                        </th>
                        <th className=" text-dark  py-3" style={{ backgroundColor: "rgba(30, 139, 195,0.03   )", fontSize: "0.9rem", borderBottom: "1px solid #000", border: "none" }}>
                            {"Bank Name"}
                        </th>
                        <th className=" text-dark  py-3" style={{ backgroundColor: "rgba(30, 139, 195,0.03   )", fontSize: "0.9rem", borderBottom: "1px solid #000", border: "none" }}>
                            {"Account Details"}
                        </th>

                        <th className=" text-dark  py-3" style={{ backgroundColor: "rgba(30, 139, 195,0.03   )", fontSize: "0.9rem", borderBottom: "1px solid #000", border: "none" }}>
                            {"Status"}
                        </th>

                        <th className=" text-dark  py-3" style={{ backgroundColor: "rgba(30, 139, 195,0.03   )", fontSize: "0.9rem", borderBottom: "1px solid #000", border: "none" }}>
                            {"Action"}
                        </th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr
                            key={ind} className={ind % 2 === 0 ? "odd-row" : "even-row"}
                            style={{
                                backgroundColor: `${ind === idx ? "" : ""}`,
                                color: `${ind === idx ? "" : ""}`,

                            }}
                        >
                            <td key={ind} style={{ fontSize: ".8rem", borderBottom: "1px solid #000", border: "none" }}>
                                {ind}
                            </td>
                            <td key={ind} style={{ fontSize: ".8rem", borderBottom: "1px solid #000", border: "none" }}>
                                {e.recipientName}
                            </td>
                            <td key={ind} style={{ fontSize: ".8rem", borderBottom: "1px solid #000", border: "none" }}>
                                {e.bankName}
                            </td>
                            <td key={ind} style={{ fontSize: ".8rem", borderBottom: "1px solid #000", border: "none" }}>
                                <div className="text-center">
                                    {e.accountNumber}<br />
                                    {e.ifscCode}
                                </div>
                            </td>

                            {e.isVerified == "0" ? (
                                e.isVerified == "0" ? (
                                    <td key={ind} className="text-center" style={{ fontSize: "0.8rem", borderBottom: "1px solid #000", border: "none", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                        <label
                                            style={{
                                                padding: "4px",
                                                //  borderRadius: "4px",
                                                minWidth: "4rem",
                                            }}
                                            className="badge"
                                        >
                                            <img
                                                src={require("../../../assets/images/success_tick.jpg")}
                                                style={{
                                                    height: "1rem",
                                                    width: "auto",
                                                    borderRadius: "1rem",
                                                    marginRight: ".2rem",
                                                }}
                                            />
                                            {"Verified"}
                                        </label>
                                    </td>
                                ) : (
                                    <td key={ind} className="text-center" style={{ fontSize: "0.8rem", borderBottom: "1px solid #000", border: "none", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                        <Button
                                            onClick={() => {
                                                verify(e);
                                            }}
                                            size="sm"

                                            className=""
                                            style={{
                                                border: "0px solid rgba(43, 193, 85, 0.5)",
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                color: "rgba(43, 193, 85, 1)",
                                                fontWeight: "600"


                                            }}
                                        >
                                            {" "}
                                            Verify
                                        </Button>
                                    </td>
                                )
                            ) : (
                                <td key={ind} className="text-center" style={{ fontSize: "0.8rem", borderBottom: "1px solid #000", border: "none", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    <label
                                        style={{
                                            padding: "4px",
                                            //  borderRadius: "4px",
                                            minWidth: "4rem",
                                        }}
                                        className="badge"
                                    >
                                        <img
                                            src={require("../../../assets/images/Close-circle.png")}
                                            style={{
                                                height: "1rem",
                                                width: "auto",
                                                borderRadius: "1rem",
                                                marginRight: ".2rem",
                                            }}
                                        />
                                        {"Deactivated"}
                                    </label>
                                </td>
                            )}

                            <td style={{ fontSize: ".8rem", borderBottom: "1px solid #000", border: "none" }}>
                                <div className="d-flex justify-content-between">
                                    {e.isVerified == "0" && (
                                        <Button
                                            size="sm"
                                            style={{
                                                border: "0px solid rgba(43, 193, 85, 0.5)",
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                color: "rgba(43, 193, 85, 1)",
                                                fontWeight: "600",
                                                zIndex: "1"

                                            }}
                                            className=""
                                            onClick={() => {
                                                setIdx(ind);
                                                setSidebarDetails(e);
                                                // document
                                                //   .querySelector(".content-wrapper")
                                                //   .classList.toggle("sidebar-icon-only");
                                            }}
                                        >
                                            IMPS
                                        </Button>
                                    )}

                                    {e.beneficiaryStatus == "1" && (
                                        <Button
                                            size="sm"
                                            style={{
                                                border: "0px solid rgba(43, 193, 85, 0.5)",
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                color: "rgba(43, 193, 85, 1)",
                                                fontWeight: "600",
                                                zIndex: "1"

                                            }}
                                            className=""
                                            disabled
                                            onClick={() => {
                                                setIdx(ind);
                                                setSidebarDetails(e);
                                                // document
                                                //   .querySelector(".content-wrapper")
                                                //   .classList.toggle("sidebar-icon-only");
                                            }}
                                        >
                                            NEFT
                                        </Button>
                                    )}



                                    {e.beneficiaryStatus == "1" ? (
                                        <Button
                                            size="sm"
                                            style={{

                                                border: "0px solid rgba(220, 56, 56, 1)",
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(220, 56, 56, 0.1)",
                                                color: "rgba(220, 56, 56, 1)",
                                                fontWeight: "600",
                                                zIndex: "1"
                                            }}
                                            className=""
                                            onClick={() => {
                                                deactivateBenificiaryConfirmation(e)
                                            }}

                                        >
                                            {e.beneficiaryStatus == "1" ? "Deactivate" : "Activate"}

                                        </Button>
                                    ) : (
                                        <Button
                                            size="sm"
                                            style={{
                                                border: "0px solid rgba(43, 193, 85, 0.5)",
                                                borderRadius: "5px",
                                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                color: "rgba(43, 193, 85, 1)",
                                                fontWeight: "600",
                                                zIndex: "1"
                                            }}
                                            className=""
                                            onClick={() => {
                                                deactivateBenificiaryConfirmation(e)
                                            }}

                                        >
                                            {e.beneficiaryStatus == "1" ? "Deactivate" : "Activate"}

                                        </Button>
                                    )}

                                </div>
                            </td>

                        </tr>
                    ))}
                </table>
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
            />
        </>
    );
};

const Pagination = ({ page, limit, callback, total, count }) => {
    console.table({ page, limit, callback });
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "5px 0px",
                justifyContent: "space-between",
            }}
        >

            <div>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>
            <div>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                    >
                        {"Previous"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}
                <Button variant="primary"> {page.toString()}</Button>
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        variant="light"
                    >
                        {"Next"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};


export default DMT4Table;