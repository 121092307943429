import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button, Dropdown, Form, Card, InputGroup } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import { writeFile, utils } from "xlsx";
import { GlobalContext } from "../../Context/GlobalState";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router";
import { Pagination } from "../../distributor/Manage Retailer/DistributorReport";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";

const WalletToWalletTransfer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [Wallet, setWallet] = useState("");
  const [WalletToWallet, setWalletToWallet] = useState("");
  const [checknumber, setchecknumber] = useState("");
  const [Amount, setAmount] = useState("");
  const [Pin, setPin] = useState("");
  const { user } = useContext(GlobalContext);
  const [Comment, setComment] = useState("");
  const whiteStyle = { background: "white" };
  const greyStyle = { background: "#e7eaf6" };

  const clear = () => {
    setAmount("");
    setWallet("");
    setComment("");
    setPin("");
  };

  const wallet = () => {
    var raw = JSON.stringify({
      mobile: checknumber,
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_wallet_to_wallet_chk_number.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setWallet(result);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const wallettowallet = () => {
    var raw = JSON.stringify({
      UserID: Wallet.pk_user_id,
      FkSId: user.pk_user_id,
      amount: Amount,
      comment: Comment,
      Tpin: Pin,
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_wallet_to_wallet_submit.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setWalletToWallet(result.data);
            clear();
            cogoToast.success(result.msg);
            WalletToWalletReport();
          } else {
            cogoToast.error(result.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [WalletRepo, setWalletRepo] = useState([]);

  const WalletToWalletReport = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_wallet_to_wallet_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.data) setWalletRepo(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };

  useEffect(() => {
    WalletToWalletReport();
  }, []);

  const columns = [
    {
      dataField: "Reference",
      text: "Reference",
    },
    {
      dataField: "Sender",
      text: "Sender",
    },
    {
      dataField: "Receiver",
      text: "Receiver",
    },
    {
      dataField: "Amount",
      text: "Amount",
    },
    {
      dataField: "Charge",
      text: "Charge",
    },
    {
      dataField: "Remark",
      text: "Remark",
    },
    {
      dataField: "Date",
      text: "Date & Time",
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const ExportToExcel = (fileName, Provider) => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(Provider);
    utils.book_append_sheet(wb, ws, "sheet");
    writeFile(wb, `${fileName}.xlsx`);
  };

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 10;
    const endIdx = currentPage * 10 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((WalletRepo.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(WalletRepo.length);
    const filteredData = WalletRepo.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [WalletRepo]);
  console.log(pages);

  useEffect(() => {
    for (let key in WalletRepo[0]) {
      console.table(key, WalletRepo[0][key]);
    }
  }, [WalletRepo]);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
        
          <div style={{backgroundColor:"#F1F2F4", 
            height:"100%"}}>
            <h4 className="ml-4 mt-2"><b>Wallet To Wallet</b></h4>
                <div className="mt-3 ml-1">
                 
                  <div className="card" style={{
  border:"1px solid",
  width:"98%",
  borderRadius:"1rem",
  margin:"1rem"
 
}}>

                      <InputGroup>
                      <div className="row">
                      <div className="col ">
                      <Form.Label ><span>Enter Mobile Number </span> </Form.Label>
                        <Form.Control
                          className="form-control rounded-0                                           "
                          name="Mobile number"
                          placeholder="Enter Mobile Number"
                          onChange={(e) => {
                            setchecknumber(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        /></div>
                        <div className="col" style={{
                          marginTop:"2rem"
                        }}>
                          <Button
                            className="rounded-0 "
                            variant=""
                            onClick={wallet}
                            style={{backgroundColor:"#56BE89" , color:"white"}}
                          >
                            Check Number
                          </Button>
                          {/* <Button
                            type="button"
                            className="btn btn-sm"
                            variant="success"
                            onClick={wallet}
                            style={{ borderRadius: "0px" }}
                          >
                            Check Number
                          </Button> */}
                        </div>
                       
                        </div>
                      </InputGroup>
                      {Wallet?.status == 0 ? (
                        <div className="col-6">
                          <Form.Control
                            className="mt-3 "
                            name="Mobile no."
                            placeholder="Enter amount"
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <Form.Control
                            className="col-6"
                            name="pin"
                            placeholder="Enter T-pin"
                            onChange={(e) => {
                              setPin(e.target.value);
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <textarea
                            className="col-6"
                            placeholder="Enter Remarks"
                            onChange={(e) => {
                              setComment(e.target.value);
                            }}
                            style={{
                              padding: "5px",
                              width: "100%",
                              borderRadius: "0px",
                            }}
                          ></textarea>

                          <Button
                            type="button"
                            className="btn btn-md"
                            variant="success"
                            onClick={wallettowallet}
                            style={{ borderRadius: "0px" }}
                          >
                            Submit
                          </Button>
                        </div>
                      ) : (
                        <p style={{ color: "red" }}>{Wallet?.msg}</p>
                      )}
                 
                    </div>
                    <div className="row card m-3 mt-3" style={{
  border:"1px solid",
  width:"98%",
  borderRadius:"1rem",
 
}}>
                      <div className="table-responsive">
                        <table
                          style={{ width: "100%" }}
                          className="table border"
                        >
                          <tr>
                            {columns.map((heading) => (
                              <th style={{ fontSize: "0.9rem" }}>
                                {heading.text}
                              </th>
                            ))}
                          </tr>
                          {dataToDisplay.length > 0 ? (
                            dataToDisplay.map((e, ind) => (
                              <tr>
                                <td style={{ fontSize: 10 }}>
                                  {e.wtw_reference_id}
                                </td>
                                <td style={{ fontSize: 10 }}>{e.SenderID}</td>
                                <td style={{ fontSize: 10 }}>{e.ReceiverID}</td>
                                <td style={{ fontSize: 10 }}>{e.wtw_amount}</td>
                                <td style={{ fontSize: 10 }}>
                                  {e.wtw_charges}
                                </td>
                                <td style={{ fontSize: 10 }}>{e.wtw_remark}</td>
                                <td style={{ fontSize: 10 }}>{e.wtw_indate}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="13" className="text-align-center">
                                No Data Available
                              </td>
                            </tr>
                          )}
                        </table>
                        <Pagination
                          page={currentPage}
                          limit={pages}
                          callback={(page) => {
                            setCurrentPage(page);
                          }}
                          count={dataToDisplay.length}
                          total={total}
                        />
                      </div>
                    </div>
                 
                </div>
              </div>
           
        
        )
      }
    </GlobalContext.Consumer>
  );
};
export default WalletToWalletTransfer;
