import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { dateFormatter, excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";

import { offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import './AllCard.css'; 

const positionStyle1 = {
    position: "absolute",
    top: -58,
    left: -15,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
    borderRadius: "0.2rem",
};

const tdstyle = {
    fontSize: "0.8rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
}
const thstyle = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
}
export const columns = [
    {
        dataField: "user_code",
        text: "User ID",
    },
    {
        dataField: "user_name",
        text: "Name",
    },
    {
        dataField: "user_mobile",
        text: "Mobile",
    },
    {
        dataField: "card_type",
        text: "Card Type",
    },
    {
        dataField: "network",
        text: "Network",
    },
    {
        dataField: "orderid",
        text: "Order Id",
    },

    {
        dataField: "rch_amount",
        text: "Amount",
    },

    {
        dataField: "indate",
        text: "Date",
    },

];

const userTypes = [
    { label: "Master Distributor", value: "4" },
    { label: "Distributor", value: "3" },
    { label: "Retailer", value: "2" },
    { label: "API Partner", value: "5" },
];

const CreditCardTransactionsTable = () => {
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [mobile, setMobile] = useState("");
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { Profileinfo } = useContext(GlobalContext);
    console.log(data);

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/print?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };




    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);


    const AllCreditCardTransactionsAPI = async (e) => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: "",
                card: "",
                fromdate: dateFormatter(startDate),
                todate: dateFormatter(endDate)

            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/PG/getAllCreditCardTransactions",
                requestOptions
            );
            const data = await res.json();
            setData(data.data);
            if (data.data2) setSummary(data.data2);
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };
    useEffect(() => {
        AllCreditCardTransactionsAPI();
    }, []);

    // const activationVerification = (e) => {
    //   swal({
    //     title: "Are you sure?",
    //     text: `You want to ${e.status=="ACTIVE"?"Deactivate":"Activate"} Card with last with ${e.creditcard}`,
    //     icon: "warning",
    //     buttons: true,
    //     dangerMode: true,
    //   }).then((willDelete) => {
    //     if (willDelete) {
    //       deactivateAPI(e);
    //     }
    //   });
    // };





    const searchBarStyle = {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "0px",
        backgroundColor: "#fff",
        width: "25%", // Adjust the width as needed
        height: "20%",
    };
    const inputStyle = {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: "16px",
        marginLeft: "8px",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: "400",
    };
    const iconStyle = {
        color: "rgba(0, 0, 0, 0.5)",
    };
    const [clickedImageURL, setClickedImageURL] = useState("");

    const handleImageClick = (imageUrl) => {
        setClickedImageURL(imageUrl);
    };
    return (
        <div >
            {clickedImageURL && (
                <div className="image-overlays shadow" onClick={() => setClickedImageURL("")}>
                    <img src={clickedImageURL} alt="Clicked" />
                </div>
            )}
            <div className="row m-0 p-0  pt-1 align-items-center">
                <div className="col-4 m-0 p-0">
                    <div
                        className="m-0 p-0 pl-2"
                        style={{
                            fontFamily: "Poppins",

                            fontWeight: "600",
                            lineHeight: "48px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            padding: "0px",
                            background: "#FFFFFF",
                            color: "#4267B2",
                            fontWeight: "bolder",
                            fontfamily: "Poppins !impotent",
                            fontSize: "28px",
                        }}
                    >
                        All Credit Card Transactions
                    </div>
                </div>

                <div className="col-8 m-0 p-0 ">
                    <div className="row  m-0 p-0  justify-content-end">
                        <div style={searchBarStyle} className="bg-light m-0 p-0 ">
                            <FontAwesomeIcon
                                icon={faSearch}
                                style={iconStyle}
                                className="ml-2"
                            />
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Search"
                                aria-label="Search"
                                style={inputStyle}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                value={searchText}
                            />
                        </div>

                        <div
                            className=" pl-2 p-0 m-0"
                            style={{ width: "18%", height: "20%" }}
                        >
                            <Form.Group className="p-0 m-0">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="Start Date"
                                />
                            </Form.Group>
                        </div>
                        <div
                            className="pr-1 pl-2  p-0 m-0"
                            style={{ width: "18%", height: "20%" }}
                        >
                            <Form.Group className="p-0 m-0">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="End Date"
                                />
                            </Form.Group>
                        </div>

                        <div
                            className="pl-1   p-0 m-0"
                            style={{ width: "14%", height: "20%" }}
                        >
                            <Form.Group className="mt-0 p-0 m-0">
                                <Button
                                    className="rounded-100 btn btn-lg btn-block "

                                    onClick={AllCreditCardTransactionsAPI}
                                >
                                    {"Search"}
                                </Button>
                            </Form.Group>
                        </div>
                        <div
                            className="m-0 p-0 hover-container"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                ExportToExcel(
                                    `All_Credit_Card_Transactions_Report${now()}`,
                                    excelMapping(data, columns)
                                );
                            }}
                        >
                            <img
                                className="pl-1"
                                src={require("../../../assets/images/download Icon.png")}
                                style={{ width: `3.5rem`, height: "85%" }}
                            />
                            <div
                                style={{ color: "rgba(255, 255,255, 1)" }}
                                className="hover-text bg-dark "
                            >
                                Export to Excel
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="m-0 p-0" />



            <div className="scroll-inner admin-fix-width">
                <table className="my-table transparent-table text-center border-bottom"
                    style={{
                        width: "100%",
                        overflowX: "auto",
                        border: "0px",
                        overflow: "auto",
                    }}>
                    <tr className="borderless-table text-center border-bottom">
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Sr"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Order ID"}</th>

                        <th className="my-table transparent-table text-center border-bottom py-3 " style={thstyle}> {"User ID"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Name"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Mobile"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Card Type"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Network"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Card Number"}</th>

                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Amount"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={thstyle}> {"Date"}</th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className="border-bottom">
                            <td
                                style={tdstyle}
                                className="text-center border-bottom py-3"
                            >
                                {" "}
                                {ind + 1}
                            </td>
                            <td className="text-center border-bottom" style={tdstyle}>
                                <div >
                                    <span>{e.orderid}</span>
                                </div>
                            </td>
                            <td className="text-center border-bottom" style={tdstyle}>
                                {" "}
                                <div >
                                    <div>{e.user_code}</div>

                                    {/* <div>{e.}</div>
                  
                  <div>{e.cust_email}</div> */}

                                </div>
                            </td>
                            <td className="text-center border-bottom" style={tdstyle}>
                                {" "}
                                <div className="d-flex flex-column align-items-center text-center">
                                    <span className="text-center">{e.user_name}</span>
                                </div>
                            </td>
                            <td className="text-center  border-bottom " style={tdstyle}>
                                <div >
                                    <span className="text-center">{e.user_mobile}</span>

                                </div>
                            </td>
                            <td className="text-center border-bottom" style={tdstyle}>
                                <div >
                                    <span>{e.card_type}</span>
                                </div>
                            </td>
                            <td className="text-center border-bottom" style={tdstyle}>
                                <div >
                                    <span>{e.network}</span>
                                </div>
                            </td>

                            <td className="text-center border-bottom bg-black-css" style={tdstyle}>
                                <div >
                                    <span>{e.card}</span>
                                </div>
                            </td>

                            <td className="text-center border-bottom bg-black-css" style={tdstyle}>
                                <div >
                                    <span>{e.rch_amount}</span>
                                </div>
                            </td>

                            <td className="text-center border-bottom" style={tdstyle}>
                                <div >

                                    <span>{e.indate}</span>

                                </div>
                            </td>
                            {/* <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="row m-0 p-0">
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-2 p-2"
                    onClick={() => {
                      setClickedImageURL(e.aadhaar_front);
                    }}
                  >
                    {" "}
                    <div className="hover-container">
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Aadhaar Front
                      </div>
                    </div>
                  </Button>
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-2 p-2"
                    onClick={() => {
                      setClickedImageURL(e.aadhaar_back);
                    }}
                  >
                    {" "}
                    <div className="hover-container">
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Aadhaar Back
                      </div>
                    </div>
                  </Button>
                 
                </div>
              </td> */}

                            {/* <td   className="text-center border-bottom" style={tdstyle}>
            <div>
               {   e.status == "ACTIVE" ? (
                   
                    <span
                    onClick={() => {
                      activationVerification(e);
                    }}
                    style={{
                     
                     
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(255, 46, 46, 0.1)',
                      color:'rgba(255, 46, 46, 1)',
                      bordercolor: 'rgba(255, 46, 46, 1)',
  
                    }}className="px-2 py-2 cursor-pointer"
                    >
                    Deactivate

                    </span>
                  ) : (
                    <span
                    onClick={() => {
                      activationVerification(e);

                    }}
                    className="m-0 p-0 px-3 py-2 cursor-pointer"
                    style={{
                     
                  
  

                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(43, 193, 85, 0.1)',
                      color:'rgba(43, 193, 85, 1)',
                      bordercolor: 'rgba(43, 193, 85, 1)',
                    }} 
                  
                    >
                     Activate
                    </span>
                    )}
                
               
               
               </div>
              </td> */}
                        </tr>
                    ))}
                    {data.length <= 0 && <NoDataFound />}
                </table>
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </div>
    );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });
    const selectStyleLeftBorder = {
        borderRadius: "0px",
        fontFamily: "Poppins",
        fontsize: "16px",
        borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
        borderRadius: "0px",
        borderLeft: "none", // Remove right side border
        borderTop: "none", // Remove top border
        borderBottom: "none", // Remove bottom border
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyleBorder = {
        border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyle = {
        border: "none", // Remove the border
        fontFamily: "Poppins",
        fontsize: "16px",
    };

    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div style={{ fontFamily: "Poppins" }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>

            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">{offset}</option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}

                <Button className="bg-white text-primary " style={selectStyle}>
                    {" "}
                    {page.toString()}
                </Button>

                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export const NoDataFound = () => {
    return (
        <tr className="row w-100 justify-content-center">
            <div className="d-flex flex-column align-items-center p-3">
                <img
                    src={require("../../../assets/images/no_data_found.png")}
                    style={{ height: "5rem", width: "auto" }}
                />
                <strong>No Data Found</strong>
            </div>
        </tr>
    );
};

export default CreditCardTransactionsTable;