import React, { useEffect, useState } from "react";
import { useQuery } from "../custom-hooks/useQuery";
import { twoDecimalPlaces } from "../utils/utilFuctions";

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function inWords(num) {
  let n;
  if ((num = num.toString()).length > 9) return "overflow";
  n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
      : "";
  return capitalizeFirstLetter(str);
}

const CMSPrintSlip = () => {
  let printData = localStorage.getItem("print");
  const [txnData, setTxnData] = useState([]);
  const [total, setTotal] = useState(0);
  if (printData) {
    printData = JSON.parse(printData);
  } else {
    printData = {};
  }
  let query = useQuery();
  const receiptNo = query.get("id");
  const Mode = query.get("type");

  const NOTE =
    "This is Computer Generated receipt & does not require physical signature. Charges minimum Rs.12 or 1.2%.";

  const [data, setData] = useState({});

  const GetData = () => {
    /*
    {
    "id": "39",
    "user_code": "BR1004",
    "user_mobile": "9168805979",
    "user_name": "Suhas Sanjay Suryavanshi",
    "service": "CMS",
    "customer_mobile": "BR1004",
    "opening_bal": "140.340904",
    "amount": "100.000000",
    "closing_bal": "40.405904",
    "status": "BR1004",
    "tid": "040125113234082",
    "payu_id": "0H3FC42514B3227",
    "rch_payu_id": "0H3FC42514B3227",
    "updated_at": "1/4/2025 11:32:59 AM",
    "date": "1/4/2025 11:32:59 AM",
    "biller": "BR1004",
    "provider": "BANKIT",
    "accountNumber": "BR1004-0H3FC42514B3227",
    "consumer_number": "BR1004",
    "charges": "0.000000",
    "rch_com_retailer": "0.065000",
    "rch_com_distributer": "0.000000",
    "rch_com_cnf": "0.000000",
    "sales_user_code": "B1008",
    "sales_user_mobile": "8130096177",
    "sales_user_name": "Company"
}

*/
    setData({
      shopname: printData.shopname ? printData.shopname : "N/A",
      agentId: printData.agentId ? printData.agentId : "N/A",
      // mobileNo: printData.rch_mobile,
      service: printData.service,
      provider: printData.provider,
      user_mobile: printData.user_mobile,
      user_name: printData.user_name,
      accountNumber: printData.accountNumber,
      orderNo: printData.tid,
      date: printData.date,
      providerId: printData.payu_id,
      status: printData.status,
      amount: printData.amount,
      appId: printData.appId,
      date: printData.date,
      amountInWords: `${inWords(Number(printData.amount))}`,
    });
  };

  const getPrintData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        tid: receiptNo,
        type: "DMT",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/user/get_receipt_data.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setTxnData(data.data2);
        data.data2.map((e) => {
          setTotal((prev) => prev + Number(e.rch_amount));
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const printReceipt = () => {
    window.print();
  };

  useEffect(() => {
    getPrintData();
    GetData();
  }, [Mode]);

  return (
    <>
      <div
        style={{
          // width: "100%",
          margin: "5vw",
          border: "1px solid #000",
          padding: "1rem",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          fontFamily: "'Arial', sans-serif",
          fontSize: "14px",
          lineHeight: "1.6",
          backgroundColor: "#fff",
          height: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "5px",
          }}
        >
          <img
            src={require("../../assets/images/company/logo_square.png")}
            style={{ width: "10rem", height: "auto" }}
          />
          <img
            src={
              data.provider == "FINGPAY"
                ? require("../../assets/images/company/fingpay.png")
                : require("../../assets/images/company/airtel_payments_bank.png")
            }
            style={{ width: "10rem", height: "auto" }}
          />
        </div>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "green",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span style={{ color: "#fff", fontSize: "24px" }}>✔</span>
            </div>
            <h2 style={{ color: "green", fontSize: "18px", margin: "0" }}>
              Payment received successfully!
            </h2>
          </div>
          <p style={{ fontSize: "12px", marginTop: "5px" }}>
            For Biller txn id - <b>{data.providerId}</b> and Partner txn id -{" "}
            <b>{data.orderNo}</b>
          </p>
        </div>

        <hr />

        {/* Transaction Summary */}
        <h3
          style={{ color: "#3c3b6e", fontSize: "16px", marginBottom: "15px" }}
        >
          Transaction Summary
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "48%" }}>
            <p>
              <p>
                <strong>Shopname:</strong> {data.shopname}
              </p>
            
              <strong> Company Name :</strong> {data.accountNumber}
            </p>
            <p>
              <strong>Provider:</strong> {data.provider}
            </p>
            <p>
              <strong> Txn ID:</strong> {data.rch_payu_id}
            </p>
            <p>
              <strong>Biller Txn ID:</strong> {data.providerId}
            </p>
            <p>
              <strong>Amount Paid:</strong>{" "}
              <span style={{ color: "green", fontWeight: "bold" }}>
                ₹ {data.amount}
              </span>
            </p>
          </div>
          <div style={{ width: "48%" }}>
          <p>
                <strong>Paid By :</strong> {data.agentId}
              </p>
            <p>
              <strong>Application Id:</strong> {data.appId}
            </p>
            <p>
              <strong>Partner Txn ID:</strong> {data.orderNo}
            </p>
            
            <p>
              <strong>Depositor Mobile Number:</strong> {data.user_mobile}
            </p>
            <p>
              <strong>Deposite Date :</strong> {data.date}
            </p>
          </div>
        </div>
        <h3
          style={{ color: "#3c3b6e", fontSize: "16px", marginBottom: "15px" }}
        >
          <strong> In Words : {data.amountInWords} </strong>
        </h3>
        <div style={{ display: "flex", padding: "1rem 0rem" }}>
          <div style={{ flex: "1" }} />
          <button
            style={{ marginLeft: "auto" }}
            className="hide-on-print"
            onClick={printReceipt}
          >
            {"Print"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CMSPrintSlip;
