import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import { GlobalContext } from "../../../Context/GlobalState";
import { offsetArr } from "../../../utils/CommonConfig";
import { Form, Button } from "react-bootstrap";
import { ExportToExcel, excelMapping } from "../../../utils/utilFuctions";

import { useQuery } from "../../../custom-hooks/useQuery";

export const columns = [
    {
        dataField: "user_code",
        text: "User Code",
    },
    {
        dataField: "pay_log_type",
        text: "Service Type",
    },
    {
        dataField: "pay_user_type",
        text: "User Type",
    },
    {
        dataField: "opening",
        text: "Opening Bal",
    },
    {
        dataField: "credit",
        text: "Credit",
    },
    {
        dataField: "debit",
        text: "Debit",
    },

    {
        dataField: "closing",
        text: " Closing Bal",
    },
    {
        dataField: "pay_reference",
        text: "Reference",
    },

    {
        dataField: "pay_user_comment",
        text: "Narration",
    },
    {
        dataField: "pay_charges",
        text: "Charges",
    },
    {
        dataField: "commission",
        text: "Commission",
    },
    {
        dataField: "gst",
        text: "GST",
    },
    {
        dataField: "tdsvalue",
        text: "TDS",
    },
    {
        dataField: "pay_indate",
        text: "Date",
    },

];

const AllUserLedgerTable = () => {
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(10);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [page, setPage] = useState(1);
    const { Profileinfo, setTransactionLoaderState } = useContext(GlobalContext);

    const query = useQuery();
    let paramId = query.get("id") ? query.get("id") : "1";
    console.log(paramId);

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }

    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    const getAllLedgerReport = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            var raw = JSON.stringify({
                userid: "BR1004",
                fromDate: dateFormatter(startDate),
                toDate: dateFormatter(endDate),
                adminId: "9",
                type: "1",
                offset: "100",
                pageIdx: "0"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Admin/getAllLedgerReport",
                requestOptions
            );

            const data = await res.json();
            if (data.responseCode == "00" && data.data) {
                setData(data.data);
            } else {
                setData([]);
            }
            setTransactionLoaderState(false);
        } catch (error) {

            console.log(error);
            setTransactionLoaderState(false);
        }
    };

    useEffect(() => {
        getAllLedgerReport();
    }, []);

    return (
        <div>
            <div>
                <div className="row m-0 p-0  pt-1 align-items-center">
                    <div className="col-4 m-0 p-0">
                        <div className="m-0 p-0 pl-2"
                            style={{
                                "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                , padding: "0px",
                                background: "#FFFFFF",
                                color: "#4267B2",
                                fontWeight: "bolder",
                                fontfamily: "Poppins !impotent",
                                fontSize: "28px",
                            }}
                        >
                            All User Ledger Report
                        </div>
                    </div>

                    <div className="col-8 m-0 p-0 ">
                        <div className="row  m-0 p-0  justify-content-end">
                            <div className="pr-1 p-0 m-0" style={{ width: '18%', height: '20%', }}>
                                <Form.Group className="p-0 m-0">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className="form-control rounded-100"
                                        placeholderText="Start Date"
                                    />
                                </Form.Group>
                            </div>
                            <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
                                <Form.Group className="p-0 m-0">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        className="form-control rounded-100"
                                        placeholderText="End Date"
                                    />
                                </Form.Group>
                            </div>

                            <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
                                <Form.Group className="mt-0 p-0 m-0">
                                    <Button
                                        className="rounded-100 btn btn-lg btn-block "
                                        onClick={getAllLedgerReport}
                                    > {"Search"}
                                    </Button>
                                </Form.Group>
                            </div>
                            <div className="m-0 p-0 hover-container"
                                style={{ cursor: "pointer" }}


                                onClick={() => {
                                    ExportToExcel(`All_User_Ledger${now()}`,excelMapping(data,columns) );
                                }}
                            >
                                <img className="pl-1"
                                    src={require("../../../../assets/images/download Icon.png")}
                                    style={{ width: `3.5rem`, height: '85%' }}
                                />
                                <div div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
                            </div>
                        </div>
                    </div>
                </div>


                <hr className="m-0 p-0" />

                <table style={{ width: "100%", backgroundColor: '#FFFFFF' }} className="my-table  borderless transparent-table">
                    <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Sr
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            User
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", width: "3rem" }}>
                            Type
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Opening Bal
                        </th>

                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Credit
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Debit
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Closing Bal
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Reference
                        </th>
                    
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center", width: "4rem" }}>
                            User Comment
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Charges
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Commission
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            GST
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            TDS value
                        </th>
                        <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Date
                        </th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className="my-table transparent-table border-bottom py-5" >
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>  {ind + 1}</td>
                            <td
                                className="text-center m-0 p-0 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div className="d-flex flex-column align-items-center">
                                    <span style={{ maxWidth: "120px" }}>{e.user_code}</span>
                                    <span>{e.user_name}</span>
                                    <span>{e.user_mobile}</span>
                                </div>
                            </td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.pay_log_type}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.opening}</td>

                            <td className="text-center border-bottom text-success" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                {e.credit}</td>
                            <td className="text-center border-bottom text-danger" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                {e.debit} </td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.closing}</td>

                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.pay_reference}</td>

                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.pay_user_comment}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.pay_charges}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.commission}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.gst}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.tdsvalue}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.pay_indate}</td>
                        </tr>
                    ))}
                </table>
                <Pagination
                    page={page}
                    limit={limit}
                    callback={(page) => {
                        setPage(page);
                    }}
                    callback2={(offset) => {
                        setOffset(offset);
                    }}
                    count={dataToDisplay.length}
                    total={total}
                />
            </div>
        </div>
    );
};

export const Pagination = ({
    page,
    limit,
    callback,
    total,
    count,
    callback2,
}) => {
    console.table({ page, limit, callback });
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>
            <div className="d-flex btn-group">
                <select
                    className="rounded-0 form-select-sm"
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset}>{offset}</option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0 m-0"
                    >
                        {"Previous"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0 m-0"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}
                <Button variant="primary" className="rounded-0 m-0">
                    {" "}
                    {page.toString()}
                </Button>
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        variant="light"
                        className="rounded-0 m-0"
                    >
                        {"Next"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default AllUserLedgerTable;