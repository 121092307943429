import React, { useState, useEffect, useContext } from "react";
import YESBankLogo from "../../assets/images/yes_bank_Logo.png";
import bumppyLogo from "../../assets/images/Bumppy_Payment_logo.svg"
import { Button } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT_API } from "../utils/Constants";
function MarcomFormPrint() {
    const { Profileinfo :Profileinfo2, user , setTransactionLoaderState } = useContext(GlobalContext);
    const [kycData, setKycData] = useState({});
    const [kycData2, setKycData2] = useState({});
    const [Profileinfo , setProfileinfo] = useState({});

    const [sessionId, setSessionId] = useState("");
    const [sessionId2, setSessionId2] = useState("");

    const fetchUserKycData = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/javascript");

            var raw = JSON.stringify({
                userid: user.pk_user_id,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == 0 && data.data.length > 0) {
                if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
                    // history.push("/kyc/user-kyc-view");
                }
                setKycData(data.data[0]);
                // setFormValues({
                //   ...formValues,
                //   PAN: data.data[0].pancardno,
                //   ADHAR: data.data[0].aadharcardno,
                //   GST: data.data[0].gstnumber,
                // });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const getReviewKycDetails = async (step, verification) => {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
            "userid": user.ReferId,
            "adminid": '1',
            "fromDate": "",
            "toDate": ""
          });
    
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
    
          const res = await fetch(
            `${BASE_URL_APISERVICES}/api/Payment/admin/getAllKycReviewReportAdmin`,
            requestOptions
          );
          const data = await res.json();
          if (data.status == "0") {
            setKycData2(data.data[0])
            setTransactionLoaderState(false);
          } else {
          //  swal("Error", "Something went wrong", "error");
            setTransactionLoaderState(false);
          }
    
        } catch (error) {
          console.log(error);
        }
      };

    const GetProfile = () => {
        var raw = JSON.stringify({
          PdrID: user.pk_user_id,
        });
    
        var requestOptions = {
          method: "POST",
          body: raw,
          redirect: "follow",
        };
    
        try {
          fetch(
            `${BASE_URL_API_TRANXT_API}/api/master/master_dist_profile_details.aspx`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
                setProfileinfo(result.data);
             // setProfile(result.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      };

    const getsessionId = () => {
        const date = new Date(Profileinfo.doj);
        date.setDate(date.getDate() + 7);
        let session = `${date.getFullYear()}${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;

        setSessionId("SESSION_ID" + session);
        setSessionId2("" + session);

    }

    useEffect(() => {
        fetchUserKycData()
        GetProfile()
    }, []);

    useEffect(() => {
        if (Profileinfo) {
            getsessionId()
        }
    }, [Profileinfo]);

    // date format in dd/mmm/yyyy format
    const formatDate = (date) => {
        const d = new Date(date);
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day = d.getDate();
        const monthIndex = d.getMonth();
        const year = d.getFullYear();
        return `${day} ${monthNames[monthIndex]} ${year}`;
    };


    const printReceipt = () => {
        window.print();
    };


    useEffect(() => {
        getReviewKycDetails()
        fetchUserKycData()
    }, []);

    console.log("kycData", kycData);

    console.log("Profileinfo", Profileinfo);

    return (
        <div>
        <div className="row justify-content-center">
            <div
                className="card m-3 p-2 d-flex justify-content-center shadow"
                style={{ width: "80%" }}
            >
                <div className="row ml-3 mr-3 ">
                    <div className="col ">
                        <img style={{ height: "6vh", width: "auto" }} src={bumppyLogo} />
                    </div>
                    <div className="col d-flex justify-content-end">
                        <img style={{ height: "5vh", width: "auto" }} src={YESBankLogo} />
                    </div>
                </div>
                <div className="d-flex justify-content-center m-2">
                    {" "}
                    <h4>
                        <b>
                            <u>Certificate of Business Correspondent Agent</u>
                        </b>
                    </h4>
                </div>

                <div
                    style={{
                        textAlign: "justify",
                        display: "flex",
                        flexWrap: "wrap",
                        marginLeft: "6rem",
                        marginRight: "6rem",
                    }}
                >
                    <div style={{ flexBasis: "calc(100% - 1em)" }}>

                        This is to certify that Mr. / Ms. {Profileinfo?.name} having his / her place of operation at {kycData?.shopaddress} has been appointed as Business Correspondent Agent of YES BANK LTD.through BUMPPY MEDIA PVT LTD who is a Business Correspondent of the Bank. The BCA has been duly trained during Training Session {sessionId} held on {formatDate(Profileinfo?.doj)} and has been authorized to act as BCA of the Bank with effect from {formatDate(Profileinfo?.doj)}  until further notice."

                    </div>
                </div>

                <div>
                    <div className="d-flex justify-content-center m-2">
                        <div>
                            <b>
                                Escalation Matrix for Business Correspondent Agent of YES BANK
                                Ltd.
                            </b>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-center m-2">
                        <table
                            style={{
                                width: "80%",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            backgroundColor: "#86B6F6",
                                            color: "black",
                                            textAlign: "center",
                                            border: "1px solid black",
                                        }}
                                    >
                                        Contact Points
                                    </th>
                                    <th
                                        style={{
                                            backgroundColor: "#86B6F6",
                                            color: "black",
                                            textAlign: "center",
                                            border: "1px solid black",
                                        }}
                                    >
                                        Timing
                                    </th>
                                    <th
                                        style={{
                                            backgroundColor: "#86B6F6",
                                            color: "black",
                                            textAlign: "center",
                                            border: "1px solid black",
                                        }}
                                    >
                                        Contact No
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td
                                        style={{ textAlign: "center", border: "1px solid black" }}
                                    >
                                        <div>Level I</div>
                                        <div>BUMMPY MEDIA PVT LTD</div>
                                        <div>support@bummpy.com</div>
                                    </td>
                                    <td
                                        style={{ textAlign: "center", border: "1px solid black" }}
                                    >
                                        24x7
                                    </td>
                                    <td     style={{ textAlign: "center", border: "1px solid black" }}>{"8130096175"}</td>
                                </tr>
                                <tr>
                                    <td
                                        style={{ textAlign: "center", border: "1px solid black" }}
                                    >
                                        <div>Level II</div>
                                        <div>{"YES BANK Ltd."}</div>
                                        <div>{"Customer Care"}</div>
                                    </td>
                                    <td
                                        style={{ textAlign: "center", border: "1px solid black" }}
                                    >
                                        {"10 am – 8 pm"}
                                    </td>
                                    <td
                                        style={{ textAlign: "center", border: "1px solid black" }}
                                    >
                                        {"1800-103-5485"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: "justify",
                        display: "flex",
                        flexWrap: "wrap",
                        marginLeft: "6rem",
                        marginRight: "6rem",
                    }}
                >
                    Note: In case of further escalations please write to the Grievance
                    Redressal Officer at{" "}
                    <span
                        style={{
                            color: "blue",
                        }}
                    >
                        head.grievanceredressal@yesbank.in
                    </span>{" "}
                    or call on 022-50795173. Details of the service offerings is available
                    with the Business Correspondent Agent and customers are advised to
                    refer the same before doing any banking transactions.
                </div>
                <div className="d-flex justify-content-center m-2">
                    <div>
                        <b>Service Charges</b>
                    </div>
                </div>
                <div className="d-flex justify-content-center m-2">
                <table style={{ width: "80%" }}>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    backgroundColor: "#86B6F6",
                                    color: "black",
                                    border: "1px solid black",
                                    textAlign: "center",
                                }}
                                colSpan="2"
                            >
                                DMT
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ border: "1px solid black", textAlign: "center" }}>
                                Transaction Amount
                            </td>
                            <td style={{ border: "1px solid black", textAlign: "center" }}>
                                Charges (Rs.)
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: "1px solid black", textAlign: "center" }}>
                                100 - 5000
                            </td>
                            <td style={{ border: "1px solid black", textAlign: "center" }}>
                                1.2%
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th
                                colSpan="2"
                                style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "1px solid black",
                                    textAlign: "center",
                                }}
                                className="justify-content-center"
                            >
                                Minimum Transaction Fee is INR 12
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th
                                colSpan="2"
                                style={{
                                    backgroundColor: "#86B6F6",
                                    color: "black",
                                    border: "1px solid black",
                                    textAlign: "center",
                                }}
                            >
                                AEPS & Micro ATM
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th
                                colSpan="2"
                                style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "1px solid black",
                                    textAlign: "center",
                                }}
                            >
                                There are No Charges for AEPS and Micro ATM service.
                            </th>
                        </tr>
                    </thead>
                </table>
                </div>

                <div
                    style={{
                        textAlign: "justify",
                        display: "flex",
                        flexWrap: "wrap",
                        marginLeft: "6rem",
                        marginRight: "6rem",
                    }}
                >
                    <div><b>The customer is advised to kindly note the following: -</b></div>
                    <ol>
                        <li>
                            BUMPPY MEDIA PVT LTD is only a Business Correspondent of YES BANK
                            Limited. This outlet is a BC Agent of YES BANK Limited through BUMPPY MEDIA PVT LTD.
                        </li>
                        <li>
                            The fees/charges mentioned above are inclusive of all taxes
                            including GST.
                        </li>
                        <li>
                            If the customer is charged in excess of the Fees/charges mentioned
                            above, he/she should complain about the same on YES BANK Customer
                            Care Number 1800-103-5485.
                        </li>
                        <li>
                            The above-mentioned service charges are approved by YES BANK Ltd.
                            and will remain in force until further notice.{" "}
                        </li>
                    </ol>
                </div>

                <div className="row d-flex justify-content-center " style={{ width: "80%", marginLeft: "5rem" }}>
                    <div className="col">
                        <div>
                            <div>
                                BCA Training Session: {sessionId}
                            </div>
                            <div>
                                Poster Issue No: {user.ReferId.toString().slice(2, user.ReferId.length)}
                            </div>


                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <div>
                                BCA Code: :{kycData2.extra}
                            </div>
                            <div>
                                BCA Certificate No:{user.ReferId.toString().slice(2, user.ReferId.length) + "" + sessionId2} 
                            </div>


                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center">
                    {" "}
                    <Button
                        onClick={() => {
                            printReceipt();
                        }}
                    >
                        {" "}
                        <i class="mdi mdi-printer"></i> Download Form
                    </Button>
                </div>
            </div>
        </div>
    </div>
    );
}

export default MarcomFormPrint