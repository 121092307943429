import React, { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import {
    Form,
    Dropdown,
    Button,
    Row,
    Col,
    Modal,
    Tab,
    Nav,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ContextMenuTrigger } from "react-contextmenu";
import Resizer from "react-image-file-resizer";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import {
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT,
    BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import Select from "react-select";
import QRCode from "qrcode";
import {
    CERTIFICATE_ADDRESS,
    CERTIFICATE_COMPANY_NAME,
    COMPANY_NAME,
    QR_TITLE_COMPANY_NAME,
} from "../../utils/StringConstants";
import {
    COLOR_QR_PLACEHOLDER,
    COLOR_QR_PLACEHOLDER_SECONDARY,
} from "../../utils/Themer";
import {
    generateHashKey2,
    inWords,
    twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import swal from "sweetalert";
import { useQuery } from "../../custom-hooks/useQuery";
import cogoToast from "cogo-toast";
import { basicAuth } from "../../config";
import CropComponent from "../../utils/crop/CropComponent";


const paymentGateways = [
    { id: "5", gateway_name: "PAYTM PG ROUTER", settlement_type: "1", icon: "https://www.bumppy.in/payment_gateway/config/uploads/icons/paytm.png" },
    { id: "6", gateway_name: "Razorpay Instant", settlement_type: "1", icon: "https://www.bumppy.in/payment_gateway/config/uploads/icons/razorpay.png" },
    { id: "7", gateway_name: "PAYTM PG ROUTER T+1", settlement_type: "2", icon: "https://www.bumppy.in/payment_gateway/config/uploads/icons/paytm.png" },
    { id: "8", gateway_name: "PhonePe", settlement_type: "2", icon: "https://www.bumppy.in/payment_gateway/config/uploads/icons/phonepe.png" },
    { id: "6", gateway_name: "Razorpay T+1", settlement_type: "2", icon: "https://www.bumppy.in/payment_gateway/config/uploads/icons/razorpay.png" },
]

const paymentModes = [
    { label: "Cash Deposite", value: "3" },
    { label: "UPI/IMPS", value: "2" },
    { label: "NEFT/RTGS", value: "5" },
    { label: "CDM", value: "9" },
    { label: "Fund Transfer", value: "8" },
    { label: "CHEQUE", value: "null" },
];
const months = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];
const dateFormatToDisplay = (date) => {
    if (date) {
        const d = new Date(date);

        let hours = d.getHours();
        let minutes = d.getMinutes();
        let ap = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes.toString().padStart(2, "0");
        let mergeTime = hours + ":" + minutes + " " + ap;
        return `${d.getDate()} ${months[d.getMonth()]
            } ${d.getFullYear()} ${mergeTime}`;
    } else {
        return "";
    }
};

const generateURL = ({ amount, name, upi }) => {
    return `upi://pay?pa=${upi}&pn=${name}&cu=INR`;
};

const loadCanvas = async (canvasEl, dataURL, canvasContextRef) => {
    const canvasContext = canvasEl.current.getContext("2d");
    const imageObj = new Image();

    canvasContextRef.current = canvasContext;
    imageObj.onload = function () {
        canvasContext.drawImage(this, 0, 0);
    };
    imageObj.src = dataURL;
};

const getQRImage = async ({ amount, name, upi }) => {
    try {
        const url = generateURL({ amount, name, upi });
        console.log(url);
        const qrCode = await QRCode.toDataURL(url, {
            type: "image/png",
            margin: 1,
            width: 300,
        });
        return qrCode;
    } catch (e) {
        console.log("API exception ", e);
        return "";
    }
};

const paintQR = async (canvasEl, canvasContextRef, { amount, name, upi }) => {
    const dataURL = await getQRImage({ amount, name, upi });
    await loadCanvas(canvasEl, dataURL, canvasContextRef);
};


//

const paintQR2 = async (canvasEl, canvasContextRef, { amount, name, upi }) => {
    const dataURL = await getQRImage2({ amount, name, upi });
    await loadCanvas(canvasEl, dataURL, canvasContextRef);
};

const getQRImage2 = async ({ amount, name, upi }) => {
    try {

        const qrCode = await QRCode.toDataURL(upi, {
            type: "image/png",
            margin: 1,
            width: 300,
        });
        return qrCode;
    } catch (e) {
        console.log("API exception ", e);
        return "";
    }
};

const Topup = () => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    const [qrEnabled, setQrEnabled] = useState(false);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const [userId, setUserId] = useState("");
    const canvasContextRef = useRef(null);
    const [upiId, setUpiId] = useState("");
    const [name, setName] = useState("");
    const validate = () => {
        if (
            amount === "" ||
            transactionId === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const print = () => {
        const newWindow = window.open(`/pages/qr`, "", "height=800,width=600");
        if (newWindow) newWindow.opener = null;
    };

    const virtualAccountCreate = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("method", "virtualaccount_sidh");
            urlencoded.append("userid", user.ReferId);
            urlencoded.append("mobileno", user.Mobile);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiservices.aspx`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            if (data.sCode == 0) {
                localStorage.setItem("va-created", true);
                setAccountCreated(true);
                setEnableSmartCollectButtonState(true);
                getVirtualAccount();
            }
            window.location.reload();
        } catch (error) {
            console.log("error", error);
        }
    };
    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${transactionId}.jpeg&TransactionId=${transactionId}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const getVirtualAccount = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=get_virtualaccountBumppy&userid=${user.ReferId}`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            paintQR(canvasEl, canvasContextRef, {
                amount: 1,
                name: COMPANY_NAME,
                upi: data.va_qrurl,
            });
            if (data.status == 0 || data.status === "0") {
                setVirtualAccount(data);
            } else {
                setEnableSmartCollectButtonState(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {
                var raw = JSON.stringify({
                    imagename: `img_${transactionId}`,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        getBankDetails();
        getVirtualAccount();
    }, []);

    const getAccessInfo = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apitranxt.bumppypay.com/api/user/get_user_access_info.aspx",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                data.services.forEach((service) => {
                    if (service.pk_menu_id == "23") {
                        if (service.status == "Y") {
                            setQrEnabled(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAccessInfo();
    }, []);

    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />

                        <Modal
                            show={mdShow}
                            onHide={() => setMdShow(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                            size="lg"
                        >
                            {/* <Modal.Header closeButton>
                <Modal.Title> INDEMNITY BOND</Modal.Title>
              </Modal.Header> */}

                            <Modal.Body
                                style={{ width: "100%", display: "block" }}
                                className="account"
                            >
                                {Profileinfo && (
                                    <Row>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ textAlign: "center" }}>
                                                <h3>INDEMNITY BOND</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex"></div>

                                                <div className="row d-flex mt-2">
                                                    <div className="col-6">
                                                        <h5>
                                                            <strong>Date:- </strong>{" "}
                                                            {dateFormatToDisplay(submitTime)}
                                                        </h5>
                                                        <h5>
                                                            <strong>From:- </strong>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end">
                                                        <img
                                                            src={require("../../../assets/images/company/logo_square.png")}
                                                            style={{
                                                                width: "60%",
                                                                height: "auto",
                                                                marginLeft: "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <table
                                                    style={{ border: "1px solid black", width: "100%" }}
                                                >
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Merchant/Agent name</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.shopname}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>BC Agent Id</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {user.ReferId}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Registered Mobile number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.mobile}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>PAN number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            GDJPS5650A
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Request No.</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            18012023419638
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            Rs. {amount}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount (In Words)</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {inWords(Number(amount))}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Bank Account </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {selectedBank?.bankName} -:{" "}
                                                            {selectedBank?.accountNo}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Deposit Date</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {dateFormatToDisplay(date)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Payment Type</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {paymentMode.label}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Remark </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {remark}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <div className="my-4">
                                                    <strong>
                                                        To,<br></br>
                                                        The Board of Directors,<br></br>
                                                        {CERTIFICATE_COMPANY_NAME} <br></br>
                                                        <span>{CERTIFICATE_ADDRESS}</span>
                                                    </strong>
                                                </div>

                                                <div>
                                                    <p className="mb-2" style={{ fontSize: "16px" }}>
                                                        Dear Sir/Madam,
                                                    </p>

                                                    <p style={{ fontSize: "16px" }}>
                                                        I, <strong>{Profileinfo?.shopname}</strong>, as a
                                                        Merchant/Agent hereby undertake and explicitly agree
                                                        to indemnify {CERTIFICATE_COMPANY_NAME} towards the
                                                        following points:
                                                    </p>

                                                    <div className="p-4">
                                                        <ul style={{ fontSize: "16px" }}>
                                                            <li>
                                                                {CERTIFICATE_COMPANY_NAME} is providing us with
                                                                a platform as an enabler through which we can
                                                                transfer/receive/top up the money through
                                                                various methods likeUPI/ IMPS/ RTGS/ Payment
                                                                Gateway etc. from one person to another(P2P and
                                                                P2M) against a separate consideration;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for the
                                                                collection of KYC/ meeting the Statutory
                                                                requirements and other mandatory documents from
                                                                the sender or receiver or both and also the
                                                                reasons of such transactions;
                                                            </li>
                                                            <li>
                                                                I am responsible and abide to provide the KYC
                                                                and other mandatory documents and reasons of
                                                                each and every transactions with end customers
                                                                to the at {COMPANY_NAME}'s discretion;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for those
                                                                transactions which was wrongly debited or
                                                                credited by me to another party or any incorrect
                                                                entry/entries while using the platform;and
                                                            </li>
                                                            <li>
                                                                After obtaining a proper understanding of the
                                                                transaction patterns of this Company, I am
                                                                giving my consent to use this platform with all
                                                                the terms and conditions as provided by the{" "}
                                                                {CERTIFICATE_COMPANY_NAME} and also assuring
                                                                that every sender or receiver or both only after
                                                                giving their full consent using this platform
                                                                for transfer / receive / topup the money through
                                                                various methods like CASH / UPI / IMPS / NEFT /
                                                                RTGS / Payment Gateway etc.
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <p className="mt-4" style={{ fontSize: "16px" }}>
                                                        Thanking you,
                                                    </p>

                                                    <p style={{ fontSize: "16px", marginTop: "4rem" }}>
                                                        <strong>
                                                            {Profileinfo?.shopname}
                                                            <br></br>
                                                            (Merchant’s/Agent’s Name)<br></br>
                                                            {Profileinfo?.name}
                                                            <br></br>
                                                            Seal and Signature<br></br>
                                                        </strong>
                                                    </p>

                                                    <p className="mt-2" style={{ fontSize: "16px" }}>
                                                        <strong>
                                                            Timestamp : {dateFormatToDisplay(new Date())}
                                                        </strong>
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-6"></div>
                                                        <div className="col-12 col-md-6 d-flex justify-content-end">
                                                            <Button
                                                                variant="success"
                                                                className="rounded-0"
                                                                onClick={topupRequest}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="rounded-0 mx-2"
                                                                onClick={() => {
                                                                    setMdShow(false);
                                                                }}
                                                            >
                                                                Decline
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                            </Modal.Body>
                        </Modal>

                        <div style={{ padding: "0px" }} className="row p-4">
                            <Row>
                                <Col md={7} style={{}}>
                                    {/* first */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Notes"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Amount"
                                                        onChange={(e) => {
                                                            setAmount(e.target.value);
                                                        }}
                                                        style={{ borderRadius: "0px" }}
                                                        value={amount}
                                                    />
                                                </Form.Group>
                                                <strong style={{ color: "green" }}>
                                                    {inWords(Number(amount))}
                                                </strong>
                                            </div>

                                            <div className="col">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        aria-label="Notes"
                                                        aria-describedby="basic-addon1"
                                                        placeholder="Transaction ID"
                                                        onChange={(e) => {
                                                            setTransactionId(e.target.value);
                                                        }}
                                                        value={transactionId}
                                                        style={{ borderRadius: "0px" }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* second */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Select
                                                    options={banks}
                                                    onChange={(e) => {
                                                        setSelectedBank(e);
                                                    }}
                                                    value={selectedBank}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* third */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col grid-margin">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(date) => setDate(date)}
                                                    selected={date}
                                                    className="form-control"
                                                    placeholderText="Date of Deposit"
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </div>
                                            <div className="col">
                                                <Select
                                                    options={paymentModes}
                                                    onChange={(e) => {
                                                        setPaymentMode(e);
                                                    }}
                                                    value={paymentMode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* fourth */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <Form.Group>
                                                    <textarea
                                                        placeholder="Remarks"
                                                        style={{
                                                            padding: "5px",
                                                            width: "100%",
                                                            borderRadius: "0px",
                                                        }}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                        value={remark}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* fifth */}
                                    <div className="container">
                                        <div style={{ padding: "10px" }} className="row">
                                            <div className="col grid-margin">
                                                <Form.Group>
                                                    <Form.Label>
                                                        <b>Slip Upload</b>
                                                        <span>(Optional)</span>
                                                    </Form.Label>
                                                    <Form.Label>
                                                        <span className="text-danger">
                                                            Image size should be less than 256 kb
                                                        </span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        accept=".jpeg"
                                                        type="file"
                                                        name="slip"
                                                        onChange={ImageUpload}
                                                        style={{ borderRadius: "0px" }}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col">
                                                <Form.Group>
                                                    <Invalid
                                                        text={TopupData.msg}
                                                        style={
                                                            TopupData.success
                                                                ? "card-inverse-success"
                                                                : "card-inverse-danger"
                                                        }
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* sixth */}
                                    <div className="container">
                                        <div className="row">
                                            <div className="col grid-margin"></div>
                                            <div className="col"></div>
                                            <div className="col">
                                                <Button
                                                    // onClick={topupRequest}
                                                    onClick={validate}
                                                    className="col"
                                                    variant="success"
                                                    style={{ borderRadius: "0px" }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col
                                    md={5}
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            margin: "0px 1rem",
                                            padding: "0px",
                                            borderRadius: "1rem",
                                            boxShadow: DROP_SHADOW,
                                        }}
                                    >
                                        <div
                                            style={{
                                                background: COLOR_QR_PLACEHOLDER,
                                                display: "flex",
                                                padding: "6px 1rem",
                                                borderTopRightRadius: "1rem",
                                                borderTopLeftRadius: "1rem",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ color: "white", fontWeight: "bolder" }}>
                                                {/* <h4 style={{ fontWeight: "bolder", margin: "0px" }}>
                          EARN MORE
                        </h4> */}
                                            </div>
                                            <div style={{ margin: "0px 2rem" }}>
                                                <img
                                                    className="p-1"
                                                    style={{ height: "3rem", width: "auto" }}
                                                    src={require("../../../assets/images/virtual_account/qr_logo_square.png")}
                                                />
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    sessionStorage.setItem(
                                                        "print",
                                                        JSON.stringify(virtualAccount)
                                                    );
                                                    print();
                                                }}
                                                variant=""
                                                className="rounded-0 ml-auto"
                                                size="sm"
                                                style={{
                                                    background: "#56BE89",
                                                    color: "white",
                                                    border: "none",
                                                }}
                                            >
                                                Download
                                            </Button>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                padding: "1rem",
                                                background: "#FFFFFF",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    style={{ height: "auto", width: "100%" }}
                                                    src={require("../../../assets/images/virtual_account/virtual_account_app_logo.png")}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    flex: "1",
                                                    background: "#FFFFFF",
                                                    boxShadow:
                                                        "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                                    padding: "6px",
                                                    borderRadius: "6px",
                                                }}
                                            >
                                                <img
                                                    style={{ height: "auto", width: "100%" }}
                                                    src={require("../../../assets/images/virtual_account/bhim_upi_logo.png")}
                                                />
                                                <canvas
                                                    style={{ width: "100%", height: "auto" }}
                                                    ref={canvasEl}
                                                    id="qrCanvas"
                                                    width="300"
                                                    height="300"
                                                    className="mx-auto"
                                                />
                                                <div>
                                                    <strong>PAY WITH ANY APP</strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                background: COLOR_QR_PLACEHOLDER_SECONDARY,
                                                color: "#FFFFFF",
                                                padding: "6px",
                                                borderBottomRightRadius: "1rem",
                                                borderBottomLeftRadius: "1rem",
                                            }}
                                        >
                                            <table style={{ width: "100%" }}>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        {" "}
                                                        <span style={{ fontSize: "1rem" }}>Shop Name:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <strong style={{ width: "50%", fontSize: "1rem" }}>
                                                            {Profileinfo?.shopname}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            Account No:
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }} className="pl-1">
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_account_no
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>IFSC Code:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_ifsc
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            Deposit Type:
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>
                                                            {" "}
                                                            {"IMPS/NEFT/RTGS."}{" "}
                                                        </span>
                                                    </td>
                                                </tr>

                                                <tr style={{ background: "none", width: "100%" }}>
                                                    <td
                                                        style={{
                                                            width: "30%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }}>UPI ID:</span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "70%",
                                                            background: "none",
                                                            padding: "3px",
                                                            border: "none",
                                                        }}
                                                    >
                                                        <span style={{ fontSize: "1rem" }} className="pl-1">
                                                            {" "}
                                                            {virtualAccount
                                                                ? virtualAccount.va_upiid
                                                                : ""}{" "}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    {!enableSmartCollectButtonState && (
                                        <React.Fragment>
                                            <div className="p-2 mt-2">
                                                <h5 style={{ color: "grey" }}>
                                                    To start Smart Collect feature please click on the
                                                    below button
                                                </h5>
                                            </div>
                                            <Button
                                                type="button"
                                                className="mt-2"
                                                style={{ borderRadius: "0px" }}
                                                disabled={enableSmartCollectButtonState}
                                                onClick={virtualAccountCreate}
                                            >
                                                Enable Smart Collect
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

const QrCode = ({ qrEnabled }) => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const canvasContextRef = useRef(null);
    const [upiId, setUpiId] = useState("");
    const [name, setName] = useState("");
    const validate = () => {
        if (
            amount === "" ||
            transactionId === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const print = () => {
        const newWindow = window.open(`/pages/qr`, "", "height=800,width=600");
        if (newWindow) newWindow.opener = null;
    };

    const virtualAccountCreate = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("method", "virtualaccount_sidh");
            urlencoded.append("userid", user.ReferId);
            urlencoded.append("mobileno", user.Mobile);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiservices.aspx`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            if (data.sCode == 0) {
                localStorage.setItem("va-created", true);
                setAccountCreated(true);
                setEnableSmartCollectButtonState(true);
                getVirtualAccount();
            }
            window.location.reload();
        } catch (error) {
            console.log("error", error);
        }
    };
    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            var requestOptions = {
                method: "GET",
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${transactionId}.jpeg&TransactionId=${transactionId}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const getVirtualAccount = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=get_virtualaccountBumppy&userid=${user.ReferId}`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            paintQR(canvasEl, canvasContextRef, {
                amount: 1,
                name: COMPANY_NAME,
                upi: data.va_qrurl,
            });
            if (data.status == 0 || data.status === "0") {
                setVirtualAccount(data);
            } else {
                setEnableSmartCollectButtonState(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {
                var raw = JSON.stringify({
                    imagename: `img_${transactionId}`,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        getBankDetails();
        if (qrEnabled) getVirtualAccount();
    }, [qrEnabled]);
    console.log(selectedBank);
    console.log(paymentMode);
    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />
                        {qrEnabled ? (
                            <div className="row  py-1 mt-4">
                                <div className="col-7 ">
                                    <div
                                        className=" row card"
                                        style={{
                                            height: "45vh",
                                            backgroundColor: "#fffff",
                                            marginLeft: "3.5rem",
                                            marginRight: "2rem",
                                        }}
                                    >
                                        <div className="col-6">
                                            <div
                                                // md={12}
                                                style={
                                                    {
                                                        // textAlign: "center",
                                                        // maxWidth: "40rem",
                                                    }
                                                }
                                            >
                                                <div
                                                    className=""
                                                    style={
                                                        {
                                                            // margin: "0px 1rem",
                                                            // padding: "0px",
                                                            // borderRadius: "1rem",
                                                        }
                                                    }
                                                >
                                                    <div
                                                        style={
                                                            {
                                                                // padding: "1rem",
                                                                // background: "#FFFFFF",
                                                                // alignItems: "center",
                                                            }
                                                        }
                                                    >
                                                        <div>
                                                            <img
                                                                style={{
                                                                    height: "auto",
                                                                    width: "80%",
                                                                    marginLeft: "2rem",
                                                                    marginTop: "2rem",
                                                                }}
                                                                src={require("../../../assets/images/virtual_account/virtual_account_app_logo.png")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {!enableSmartCollectButtonState && (
                                                    <React.Fragment>
                                                        <div className="p-2 mt-2">
                                                            <h5 style={{ color: "grey" }}>
                                                                To start Smart Collect feature please click on
                                                                the below button
                                                            </h5>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            className="mt-2"
                                                            style={{ borderRadius: "0px" }}
                                                            disabled={enableSmartCollectButtonState}
                                                            onClick={virtualAccountCreate}
                                                        >
                                                            Enable Smart Collect
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="col-6  justify-content-end"

                                        // style={{
                                        //   flex: "1",
                                        //   background: "#FFFFFF",
                                        //   boxShadow:
                                        //     "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                        //   padding: "6px",
                                        //   borderRadius: "6px",
                                        // }}
                                        >
                                            <div className="card mt-3">
                                                <img
                                                    style={{
                                                        height: "auto",
                                                        width: "72%",
                                                        marginLeft: "2.5rem",
                                                    }}
                                                    src={require("../../../assets/images/virtual_account/bhim_upi_logo.png")}
                                                />
                                                <canvas
                                                    style={{
                                                        width: "72%",
                                                        height: "auto",
                                                        marginLeft: "2.5rem",
                                                    }}
                                                    ref={canvasEl}
                                                    id="qrCanvas"
                                                    width="300"
                                                    height="300"
                                                />
                                                <div style={{ marginLeft: "3rem" }}>
                                                    <strong>PAY WITH ANY APP</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div
                                        className="row d-flex "
                                        style={{
                                            background: "#F1F2F4",
                                            display: "flex",
                                            padding: "6px 1rem",
                                            borderTopRightRadius: "1rem",
                                            borderTopLeftRadius: "1rem",
                                            //marginTop: "2rem",
                                        }}
                                    >
                                        <div className="col-9 ">
                                            <img
                                                style={{ height: "3.5rem", width: "auto" }}
                                                src={require("../../../assets/images/company/navbar_logo.png")}
                                            />
                                        </div>
                                        <div className="col-3">
                                            {" "}
                                            <Button
                                                className=""
                                                onClick={() => {
                                                    sessionStorage.setItem(
                                                        "print",
                                                        JSON.stringify(virtualAccount)
                                                    );
                                                    print();
                                                }}
                                                variant=""
                                                style={{
                                                    //  height: "100%",
                                                    // borderRadius: "0.5rem",
                                                    // width: "7.2vw",
                                                    //background: "#56BE89",
                                                    //color: "white",
                                                }}
                                            >
                                                <div className="hover-container"
                                                    style={{ cursor: "pointer" }}  >
                                                    <img className="pl-1 "
                                                        src={require("../../../assets/images/download Icon.png")}
                                                        style={{ width: `3.5rem`, height: '85%' }}
                                                    />
                                                    <div div style={{ color: 'rgba(255, 255,255, 1)' }} className="hover-text bg-dark p-2">Download</div>
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-3"
                                        style={{
                                            background: "#FFFFFF",
                                            color: "black",
                                            padding: "6px",
                                            borderRadius: "1rem",
                                            height: "60%",
                                            width: "75%",
                                        }}
                                    >
                                        <table style={{ width: "100%" }}>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    {" "}
                                                    <span style={{ fontSize: "1rem" }}>Shop Name:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <strong style={{ width: "50%", fontSize: "1rem" }}>
                                                        {Profileinfo?.shopname}
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>Account No:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }} className="pl-1">
                                                        {" "}
                                                        {virtualAccount
                                                            ? virtualAccount.va_account_no
                                                            : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>IFSC Code:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        {" "}
                                                        {virtualAccount ? virtualAccount.va_ifsc : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        Deposit Type:
                                                    </span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        {" "}
                                                        {"IMPS/NEFT/RTGS."}{" "}
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>UPI ID:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }} className="pl-1">
                                                        {" "}
                                                        {virtualAccount ? virtualAccount.va_upiid : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <span>QR Disabled</span>
                        )}
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

const AuBankQrCode = ({ qrEnabled }) => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const canvasContextRef = useRef(null);
    const [upiId, setUpiId] = useState("");
    const [name, setName] = useState("");
    const validate = () => {
        if (
            amount === "" ||
            transactionId === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const print = () => {
        const newWindow = window.open(`/pages/qr`, "", "height=800,width=600");
        if (newWindow) newWindow.opener = null;
    };

    const virtualAccountCreate = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString("base64")
            );
            const raw = JSON.stringify({
                userid: user.ReferId,
            })

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/Virtual/generateQrCodeAuBank`,
                requestOptions
            );
            const data = await res.json();

            if (data.responseCode == "00") {
                localStorage.setItem("va-created", true);
                setAccountCreated(true);
                setEnableSmartCollectButtonState(true);
                getVirtualAccount();
            }
            window.location.reload();
        } catch (error) {
            console.log("error", error);
        }
    };
    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };


    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const getVirtualAccount = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString("base64")
            );
            const raw = JSON.stringify({
                userid: user.ReferId,
            })
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://apiservices.bumppypay.com/api/Virtual/getQrCodeAuBank`,
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                paintQR2(canvasEl, canvasContextRef, {
                    amount: 1,
                    name: COMPANY_NAME,
                    upi: data.data[0].qrString,
                });
            }

            if (data.responseCode == 0 || data.responseCode === "0") {
                setVirtualAccount(data.data[0]);
            } else {
                setEnableSmartCollectButtonState(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    useEffect(() => {
        getBankDetails();
        if (qrEnabled) getVirtualAccount();
    }, [qrEnabled]);
    console.log(selectedBank);
    console.log(paymentMode);
    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />
                        {qrEnabled ? (
                            <div className="row  py-1 mt-4">
                                <div className="col-7 ">
                                    <div
                                        className=" row card"
                                        style={{
                                            height: "45vh",
                                            backgroundColor: "#fffff",
                                            marginLeft: "3.5rem",
                                            marginRight: "2rem",
                                        }}
                                    >
                                        <div className="col-6">
                                            <div
                                                // md={12}
                                                style={
                                                    {
                                                        // textAlign: "center",
                                                        // maxWidth: "40rem",
                                                    }
                                                }
                                            >
                                                <div
                                                    className=""
                                                    style={
                                                        {
                                                            // margin: "0px 1rem",
                                                            // padding: "0px",
                                                            // borderRadius: "1rem",
                                                        }
                                                    }
                                                >
                                                    <div
                                                        style={
                                                            {
                                                                // padding: "1rem",
                                                                // background: "#FFFFFF",
                                                                // alignItems: "center",
                                                            }
                                                        }
                                                    >
                                                        <div>
                                                            <img
                                                                style={{
                                                                    height: "auto",
                                                                    width: "80%",
                                                                    marginLeft: "2rem",
                                                                    marginTop: "2rem",
                                                                }}
                                                                src={require("../../../assets/images/virtual_account/virtual_account_app_logo.png")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {!enableSmartCollectButtonState && (
                                                    <React.Fragment>
                                                        <div className="p-2 mt-2">
                                                            <h5 style={{ color: "grey" }}>
                                                                To start Smart Collect feature please click on
                                                                the below button
                                                            </h5>
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            className="mt-2"
                                                            style={{ borderRadius: "0px" }}
                                                            disabled={enableSmartCollectButtonState}
                                                            onClick={virtualAccountCreate}
                                                        >
                                                            Enable Smart Collect
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="col-6  justify-content-end"

                                        // style={{
                                        //   flex: "1",
                                        //   background: "#FFFFFF",
                                        //   boxShadow:
                                        //     "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                        //   padding: "6px",
                                        //   borderRadius: "6px",
                                        // }}
                                        >
                                            <div className="card mt-3">
                                                <img
                                                    style={{
                                                        height: "auto",
                                                        width: "72%",
                                                        marginLeft: "2.5rem",
                                                    }}
                                                    src={require("../../../assets/images/virtual_account/bhim_upi_logo.png")}
                                                />
                                                <canvas
                                                    style={{
                                                        width: "72%",
                                                        height: "auto",
                                                        marginLeft: "2.5rem",
                                                    }}
                                                    ref={canvasEl}
                                                    id="qrCanvas"
                                                    width="300"
                                                    height="300"
                                                />
                                                <div style={{ marginLeft: "3rem" }}>
                                                    <strong>PAY WITH ANY APP</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div
                                        className="row d-flex "
                                        style={{
                                            background: "#F1F2F4",
                                            display: "flex",
                                            padding: "6px 1rem",
                                            borderTopRightRadius: "1rem",
                                            borderTopLeftRadius: "1rem",
                                            //marginTop: "2rem",
                                        }}
                                    >
                                        <div className="col-9 ">
                                            <img
                                                style={{ height: "3.5rem", width: "auto" }}
                                                src={require("../../../assets/images/company/navbar_logo.png")}
                                            />
                                        </div>
                                        <div className="col-3">
                                            {" "}
                                            <Button
                                                className=""
                                                onClick={() => {
                                                    sessionStorage.setItem(
                                                        "print",
                                                        JSON.stringify(virtualAccount)
                                                    );
                                                    print();
                                                }}
                                                variant=""
                                                style={{
                                                    //  height: "100%",
                                                    // borderRadius: "0.5rem",
                                                    // width: "7.2vw",
                                                    //background: "#56BE89",
                                                    //color: "white",
                                                }}
                                            >
                                                <div className="hover-container"
                                                    style={{ cursor: "pointer" }}  >
                                                    <img className="pl-1 "
                                                        src={require("../../../assets/images/download Icon.png")}
                                                        style={{ width: `3.5rem`, height: '85%' }}
                                                    />
                                                    <div div style={{ color: 'rgba(255, 255,255, 1)' }} className="hover-text bg-dark p-2">Download</div>
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-3"
                                        style={{
                                            background: "#FFFFFF",
                                            color: "black",
                                            padding: "6px",
                                            borderRadius: "1rem",
                                            height: "60%",
                                            width: "75%",
                                        }}
                                    >
                                        <table style={{ width: "100%" }}>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    {" "}
                                                    <span style={{ fontSize: "1rem" }}>Shop Name:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <strong style={{ width: "50%", fontSize: "1rem" }}>
                                                        {Profileinfo?.shopname}
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>Account No:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }} className="pl-1">
                                                        {" "}
                                                        {virtualAccount
                                                            ? virtualAccount.va_account_no
                                                            : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>IFSC Code:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        {" "}
                                                        {virtualAccount ? virtualAccount.va_ifsc : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        Deposit Type:
                                                    </span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>
                                                        {" "}
                                                        {"IMPS/NEFT/RTGS."}{" "}
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr style={{ background: "none", width: "100%" }}>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }}>UPI ID:</span>
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        background: "none",
                                                        padding: "3px",
                                                        border: "none",
                                                    }}
                                                >
                                                    <span style={{ fontSize: "1rem" }} className="pl-1">
                                                        {" "}
                                                        {virtualAccount ? virtualAccount.va_upiid : ""}{" "}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <span>QR Disabled</span>
                        )}
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

const LoadRequest = () => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [paymentMode, setPaymentMode] = useState("Select Mode");
    const [remark, setRemark] = useState("");
    const [bank, setBank] = useState("Select Bank");
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/indemnity_bond?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const canvasContextRef = useRef(null);
    const validate = () => {
        if (
            amount.toString().trim() === "" ||
            transactionId.toString().trim() === "" ||
            selectedBank == null ||
            paymentMode == "Select Mode"
        ) {
            swal("Invalid", "Please fill all mandatory fields", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        setSubmitTime(new Date());
        setMdShow(true);
    };

    const dateToStr = (date) =>
        `${date.getFullYear()}-${date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }-${date.getDate()}`;

    const clearForm = () => {
        setAmount("");
        setTransactionId("");
        setSelectedBank({});
        setDate(new Date());
        setPaymentMode({});
        setRemark("");
    };

    const topupRequest = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            let newTid = transactionId;
            if (newTid.length < 8) {
                newTid = `000000${newTid}`;
            }
            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=createload&UserID=${user.ReferId
                }&amount=${amount}&ImagePath=slipupload/img_${newTid}.jpeg&TransactionId=${newTid}&BankName=${selectedBank.bankId
                }&Description=${remark}&PayMode=${paymentMode.value}&date=${dateToStr(
                    date
                )}`,
                requestOptions
            );
            const data = await res.json();
            if (data.Id == "Y") {
                swal("Success", data.Result, "success");
            } else {
                swal("Topup Failed", data.Result, "error");
            }
            setMdShow(false);

            setTopupData({
                ...TopupData,
                msg: `Remark: ${data.Result}`,
                success: true,
            });
            clearForm();
        } catch (error) {
            console.log("error", error);
            setTopupData({
                ...TopupData,
                msg: `Remark: Something went wrong`,
                success: false,
            });
        }
    };

    const getBankDetails = async () => {
        try {
            const raw = "";
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_API_TRANXT}/apiget.aspx?method=getloadbank&service=aaa`,
                requestOptions
            );
            const raw2 = await res.text();
            const data = JSON.parse(raw2);
            console.log(data);
            setBanks(
                data.map((bank) => {
                    return {
                        ...bank,
                        value: `${bank.bankName} | ${bank.ifscCode}`,
                        label: `${bank.bankName} | ${bank.ifscCode}`,
                    };
                })
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {
                let imageName = `img_${transactionId}`;
                if (imageName.length < 13) {
                    imageName = `img_000000${transactionId}`
                }
                var raw = JSON.stringify({
                    imagename: imageName,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                swal("Upload Failed", "Image upload successfully", "error");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        getBankDetails();
    }, []);
    console.log(selectedBank);
    console.log(paymentMode);
    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />

                        <Modal
                            show={mdShow}
                            onHide={() => setMdShow(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                            size="lg"
                        >
                            {/* <Modal.Header closeButton>
                <Modal.Title> INDEMNITY BOND</Modal.Title>
              </Modal.Header> */}

                            <Modal.Body
                                style={{ width: "100%", height: "100vh", display: "block" }}
                                className="account"
                            >
                                {Profileinfo && (
                                    <Row>
                                        <div style={{ width: "100%" }}>
                                            <div style={{ textAlign: "center" }}>
                                                <h3>INDEMNITY BOND</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex"></div>

                                                <div className="row d-flex mt-2">
                                                    <div className="col-6">
                                                        <h5>
                                                            <strong>Date:- </strong>{" "}
                                                            {dateFormatToDisplay(submitTime)}
                                                        </h5>
                                                        <h5>
                                                            <strong>From:- </strong>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end">
                                                        <img
                                                            src={require("../../../assets/images/company/navbar_logo.png")}
                                                            style={{
                                                                width: "60%",
                                                                height: "auto",
                                                                marginLeft: "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <table
                                                    style={{ border: "1px solid black", width: "100%" }}
                                                >
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Merchant/Agent name</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.shopname}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>BC Agent Id</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {user.ReferId}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Registered Mobile number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {Profileinfo.mobile}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>PAN number</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            GDJPS5650A
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Request No.</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            18012023419638
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            Rs. {amount}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Amount (In Words)</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {inWords(Number(amount))}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Bank Account </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {selectedBank?.bankName} -:{" "}
                                                            {selectedBank?.accountNo}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Deposit Date</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {dateFormatToDisplay(date)}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Payment Type</strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {paymentMode.label}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "white" }}>
                                                        <td
                                                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                                                        >
                                                            <strong>Remark </strong>
                                                        </td>
                                                        <td
                                                            style={{ fontSize: ".9rem", fontWeight: "bold" }}
                                                        >
                                                            {remark}
                                                        </td>
                                                    </tr>
                                                </table>

                                                <div className="my-4">
                                                    <strong>
                                                        To,<br></br>
                                                        The Board of Directors,<br></br>
                                                        {CERTIFICATE_COMPANY_NAME} <br></br>
                                                        <span>{CERTIFICATE_ADDRESS}</span>
                                                    </strong>
                                                </div>

                                                <div>
                                                    <p className="mb-2" style={{ fontSize: "16px" }}>
                                                        Dear Sir/Madam,
                                                    </p>

                                                    <p style={{ fontSize: "16px" }}>
                                                        I, <strong>{Profileinfo?.shopname}</strong>, as a
                                                        Merchant/Agent hereby undertake and explicitly agree
                                                        to indemnify {CERTIFICATE_COMPANY_NAME} towards the
                                                        following points:
                                                    </p>

                                                    <div className="p-4">
                                                        <ul style={{ fontSize: "16px" }}>
                                                            <li>
                                                                {CERTIFICATE_COMPANY_NAME} is providing us with
                                                                a platform as an enabler through which we can
                                                                transfer/receive/top up the money through
                                                                various methods likeUPI/ IMPS/ RTGS/ Payment
                                                                Gateway etc. from one person to another(P2P and
                                                                P2M) against a separate consideration;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for the
                                                                collection of KYC/ meeting the Statutory
                                                                requirements and other mandatory documents from
                                                                the sender or receiver or both and also the
                                                                reasons of such transactions;
                                                            </li>
                                                            <li>
                                                                I am responsible and abide to provide the KYC
                                                                and other mandatory documents and reasons of
                                                                each and every transactions with end customers
                                                                to the at {COMPANY_NAME}'s discretion;
                                                            </li>
                                                            <li>
                                                                I am wholly and solely responsible for those
                                                                transactions which was wrongly debited or
                                                                credited by me to another party or any incorrect
                                                                entry/entries while using the platform;and
                                                            </li>
                                                            <li>
                                                                After obtaining a proper understanding of the
                                                                transaction patterns of this Company, I am
                                                                giving my consent to use this platform with all
                                                                the terms and conditions as provided by the{" "}
                                                                {CERTIFICATE_COMPANY_NAME} and also assuring
                                                                that every sender or receiver or both only after
                                                                giving their full consent using this platform
                                                                for transfer / receive / topup the money through
                                                                various methods like CASH / UPI / IMPS / NEFT /
                                                                RTGS / Payment Gateway etc.
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <p className="mt-4" style={{ fontSize: "16px" }}>
                                                        Thanking you,
                                                    </p>

                                                    <p style={{ fontSize: "16px", marginTop: "4rem" }}>
                                                        <strong>
                                                            {Profileinfo?.shopname}
                                                            <br></br>
                                                            (Merchant’s/Agent’s Name)<br></br>
                                                            {Profileinfo?.name}
                                                            <br></br>
                                                            Seal and Signature<br></br>
                                                        </strong>
                                                    </p>

                                                    <p className="mt-2" style={{ fontSize: "16px" }}>
                                                        <strong>
                                                            Timestamp : {dateFormatToDisplay(new Date())}
                                                        </strong>
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-6"></div>
                                                        <div className="col-12 col-md-6 d-flex justify-content-end">
                                                            <Button
                                                                variant="success"
                                                                className="rounded-0"
                                                                onClick={topupRequest}
                                                            >
                                                                Accept
                                                            </Button>
                                                            <Button
                                                                variant="danger"
                                                                className="rounded-0 mx-2"
                                                                onClick={() => {
                                                                    setMdShow(false);
                                                                }}
                                                            >
                                                                Decline
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                            </Modal.Body>
                        </Modal>

                        <Row className="justify-content-center p-0">
                            <Col
                                md={12}
                                style={{
                                    textAlign: "center",
                                    maxWidth: "35rem",
                                }}
                                className="card"
                            >
                                {/* first */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Control
                                                    type="number"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Amount"
                                                    onChange={(e) => {
                                                        setAmount(e.target.value);
                                                    }}
                                                    style={{ borderRadius: "0px" }}
                                                    value={amount}
                                                />
                                            </Form.Group>
                                            <strong style={{ color: "green" }}>
                                                {inWords(Number(amount))}
                                            </strong>
                                        </div>

                                        <div className="col">
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Transaction ID"
                                                    onChange={(e) => {
                                                        setTransactionId(e.target.value);
                                                    }}
                                                    value={transactionId}
                                                    style={{ borderRadius: "0px" }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* second */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Select
                                                options={banks}
                                                onChange={(e) => {
                                                    setSelectedBank(e);
                                                }}
                                                value={selectedBank}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* third */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col grid-margin">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => setDate(date)}
                                                selected={date}
                                                className="form-control"
                                                placeholderText="Date of Deposit"
                                                style={{ borderRadius: "0px" }}
                                            />
                                        </div>
                                        <div className="col">
                                            <Select
                                                options={paymentModes}
                                                onChange={(e) => {
                                                    setPaymentMode(e);
                                                }}
                                                value={paymentMode}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* fourth */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <textarea
                                                    placeholder="Remarks"
                                                    style={{
                                                        padding: "5px",
                                                        width: "100%",
                                                        borderRadius: "0px",
                                                    }}
                                                    onChange={(e) => setRemark(e.target.value)}
                                                    value={remark}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* fifth */}
                                <div className="container">
                                    <div style={{ padding: "10px" }} className="row">
                                        <div className="col grid-margin">
                                            <Form.Group>
                                                <Form.Label>
                                                    <b>Slip Upload</b>
                                                    <span>(Optional)</span>
                                                </Form.Label>
                                                <Form.Label>
                                                    <span className="text-danger">
                                                        Image size should be less than 256 kb
                                                    </span>
                                                </Form.Label>
                                                <Form.Control
                                                    accept=".jpeg"
                                                    type="file"
                                                    name="slip"
                                                    onChange={ImageUpload}
                                                    style={{ borderRadius: "0px" }}
                                                ></Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col">
                                            <Form.Group>
                                                <Invalid
                                                    text={TopupData.msg}
                                                    style={
                                                        TopupData.success
                                                            ? "card-inverse-success"
                                                            : "card-inverse-danger"
                                                    }
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                {/* sixth */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col grid-margin"></div>
                                        <div className="col"></div>
                                        <div className="col">
                                            <Button
                                                // onClick={topupRequest}
                                                onClick={validate}
                                                className="col"
                                                variant="success"
                                                style={{ borderRadius: "0px" }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

const AddMoney = () => {
    const { topup: topupUrl } = urls;
    const [date, setDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [data, setData] = useState("");
  
    const [virtualAccount, setVirtualAccount] = useState(null);
    const [List, setList] = useState([]);
    const [File, setFile] = useState("");
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(null);
    // Modal States
    const [mdShow, setMdShow] = useState(false);
    const [submitTime, setSubmitTime] = useState(new Date());

    //
    const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
        useState(true);
    const [accountCreated, setAccountCreated] = useState(
        localStorage.getItem("va-created") || false
    );
    const [TopupData, setTopupData] = useState({
        msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
        success: false,
    });
    const { user, Profileinfo } = useContext(GlobalContext);

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            data,
            "",
            "height=600,width=800"
        );
        if (newWindow) newWindow.opener = null;
    };

    // QR Code
    const canvasEl = useRef(null);
    const canvasContextRef = useRef(null);
    const validate = () => {
        if (
            amount.toString().trim() === "" 
        ) {
            swal("Invalid", "Please enter Amount", "error");
            // toast.error("Please Enter Amount");
            return;
        }
        generateUpiIntent();
    };



    const clearForm = () => {
        setAmount("");
     
    };

    const generateUpiIntent = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            var raw = JSON.stringify({
                userid: user.ReferId,
                amount: amount,
                customerMobile: Profileinfo.mobile,
                customerName: Profileinfo.name
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Virtual/v1.0/generateUpiIntent`,
                requestOptions
            )

            .then((response) => response.json())
            .then((result) => {
                if (result.responseCode === "00") {
                    setData(result.data);
                    
                    if (result.data?.qrString) {
                        openInNewTab(result.data.qrString, "_blank");
                    }
                } else {
                    setData([]);
                }
            })
                .catch((error) => console.log("error", error));
        } catch (error) {
            console.log(error);
        }
    };

  

    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <div className="topup">
                        <ToastContainer
                            autoClose={3000}
                            hideProgressBar
                            draggableDirection="y"
                        />

                  

                        <Row className="justify-content-center p-0">
                            <Col
                                md={12}
                                style={{
                                    textAlign: "center",
                                    maxWidth: "35rem",
                                }}
                                className="card"
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group>
                                                <Form.Control
                                                    type="number"
                                                    className="form-control"
                                                    aria-label="Notes"
                                                    aria-describedby="basic-addon1"
                                                    placeholder="Amount"
                                                    onChange={(e) => {
                                                        setAmount(e.target.value);
                                                    }}
                                                    style={{ borderRadius: "0px" }}
                                                    value={amount}
                                                />
                                            </Form.Group>
                                            <strong style={{ color: "green" }}>
                                                {inWords(Number(amount))}
                                            </strong>
                                        </div>

                                    
                                    </div>
                                </div>
                             
                                <div className="container">
                                    <div className="row">
                                        <div className="col grid-margin"></div>
                                        <div className="col"></div>
                                        <div className="col">
                                            <Button
                                                onClick={validate}
                                                className="col"
                                                variant="success"
                                                style={{ borderRadius: "0px" }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
        </GlobalContext.Consumer>
    );
};

export const Invalid = ({ text, style }) => {
    console.log("hiii");
    return (
        <div className={`${style}`}>
            <ContextMenuTrigger id="actionContextMenu">
                <div className="card-body">
                    <p className="card-text">{text}</p>
                </div>
            </ContextMenuTrigger>
        </div>
    );
};

// export const TopupRequest = () => {
//   return (
//     <Tab.Container id="left-tabs-example" defaultActiveKey="first">
//       <Row>
//         <div className="col-12">
//           <Nav
//             variant="pills"
//             className="nav-pills-custom justify-content-around p-1 "
//             style={{
//               borderRadius: "1rem",
//               padding: "1rem",
//               border: "1px solid #2F58CD",
//               background: "#FFF",
//             }}
//           >
//             <Nav.Item>
//               <Nav.Link eventKey="first" className="d-flex align-items-center">
//                 Virtual
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link eventKey="second" className="d-flex align-items-center">
//                 Payment Gateway
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link eventKey="third" className="d-flex align-items-center">
//                 Load Request
//               </Nav.Link>
//             </Nav.Item>
//           </Nav>
//         </div>
//         <Col xs={12} className="p-0">
//           <Tab.Content className="tab-content-custom-pill p-0">
//             <Tab.Pane eventKey="first">
//               <QrCode />
//             </Tab.Pane>
//             <Tab.Pane eventKey="second"></Tab.Pane>
//             <Tab.Pane eventKey="third">
//               <LoadRequest />
//             </Tab.Pane>
//           </Tab.Content>
//         </Col>
//       </Row>
//     </Tab.Container>
//   );
// };

export const TopupRequest = () => {
    const { user, Profileinfo } = useContext(GlobalContext);
    const [qrEnabled, setQrEnabled] = useState(false);
    const [dcEnable, setDcEnabled] = useState(false);
    const [ccEnable, setCcEnabled] = useState(false);
    const [nbEnabled, setNbEnabled] = useState(false);
    const [pgEnabled, setPgEnabled] = useState(false);
    const [pg2Enabled, setPg2Enabled] = useState(false);
    const [pg3Enabled, setPg3Enabled] = useState(false);
    const [pg4Enabled, setPg4Enabled] = useState(false);
    const [pg5Enabled, setPg5Enabled] = useState(false);
    const [pg6Enabled, setPg6Enabled] = useState(false);
    const [pg8Enabled, setPg8Enabled] = useState(false);
    const [pg9Enabled, setPg9Enabled] = useState(false);
    const [auBankQrEnabled, setAuBankQrEnabled] = useState(false);
    const [pgAccess, setPgAccess] = useState({
        pg9: false
    });
    const [creditCardKycEnabled, setCreditCardKycEnabled] = useState(false);
    const query = useQuery();

    const getAccessInfo = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apitranxt.bumppypay.com/api/user/get_user_access_info.aspx",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                data.services.forEach((service) => {
                    if (service.pk_menu_id == "23" && service.status == "Y") {
                        setQrEnabled(true);
                    }
                    if (service.pk_menu_id == "27" && service.status == "Y") {
                        setCcEnabled(true);
                    }
                    if (service.pk_menu_id == "28" && service.status == "Y") {
                        setDcEnabled(true);
                    }
                    if (service.pk_menu_id == "29" && service.status == "Y") {
                        setNbEnabled(true);
                    }
                    if (service.pk_menu_id == "33" && service.status == "Y") {
                        setPgEnabled(true);
                    }
                    if (service.pk_menu_id == "36" && service.status == "Y") {
                        setPg2Enabled(true);
                    }

                    if (service.pk_menu_id == "40" && service.status == "Y") {
                        setPg3Enabled(true);
                    }
                    if (service.pk_menu_id == "42" && service.status == "Y") {
                        setPg4Enabled(true);
                    }
                    if (service.pk_menu_id == "48" && service.status == "Y") {
                        setPg5Enabled(true);
                    }
                    if (service.pk_menu_id == "53" && service.status == "Y") {
                        setCreditCardKycEnabled(true);
                    }
                    if (service.pk_menu_id == "54" && service.status == "Y") {
                        setPg6Enabled(true);
                    }

                    if (service.pk_menu_id == "51" && service.status == "Y") {
                        setPg9Enabled(true);
                    }

                    if (service.pk_menu_id == "56" && service.status == "Y") {
                        setPg8Enabled(true);
                    }

                    if (service.pk_menu_id == "62" && service.status == "Y") {
                        setPgAccess({
                            ...pgAccess,
                            pg9: true
                        });
                    }

                    if (service.pk_menu_id == "64" && service.status == "Y") {
                        setAuBankQrEnabled(true);
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAccessInfo();
    }, []);

    useEffect(() => {
        const orderid = query.get("orderid");
        const status = query.get("status");
        const amount = query.get("amount");
        if (status && status == "success") {
            swal("Success", `Payment Success for Amount ${amount} `, "success");

        } else if (status) {
            swal("Failed", `Payment Failed for Amount ${amount} `, "error");
        }
    }, []);

    return (
        <div
            style={{
                backgroundColor: " #F1F2F4",
                height: "100%",
                width: "100%"
            }}
        >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={12}>
                        <Tab.Content className="tab-content-vertical border-0">
                            <Tab.Pane eventKey="first">
                                <InstantLoad
                                    qrEnabled={qrEnabled}
                                    dcEnable={dcEnable}
                                    ccEnable={ccEnable}
                                    nbEnabled={nbEnabled}
                                    pgEnabled={pgEnabled}
                                    pg2Enabled={pg2Enabled}
                                    pg3Enabled={pg3Enabled}
                                    pg4Enabled={pg4Enabled}
                                    pg5Enabled={pg5Enabled}
                                    pg6Enabled={pg6Enabled}
                                    pg8Enabled={pg8Enabled}
                                    pg9Enabled={pg9Enabled}
                                    auBankQrEnabled={auBankQrEnabled}
                                    pg10Enabled={pgAccess.pg9}
                                    creditCardKycEnabled={creditCardKycEnabled}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

const InstantLoad = ({ qrEnabled, dcEnable, ccEnable, nbEnabled, pgEnabled, pg2Enabled, pg3Enabled, pg4Enabled, pg5Enabled, pg6Enabled, pg8Enabled, creditCardKycEnabled, pg9Enabled, pg10Enabled, auBankQrEnabled }) => {
    const [mode, setMode] = useState("CC");
    return (
        <div className=""
            style={{
                backgroundColor: "#FFFFFF",
                height: "100%",
                border: "0px solid #4267B2",
                borderRadius: "10px",
                margin: "1rem",
                boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.1)'

            }}
        >
            <h3
                className="py-2 pt-3"
                style={{
                    marginLeft: "1.4rem",
                    color: "#4267B2",
                    fontFamily: "poppins",
                }}
            >
                <b>Add Funds</b>
            </h3>
            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first"
                className=""
            >
                <Row className="m-0 p-0 ">
                    <Col md={12} sm={6}>
                        <Nav
                            variant="pills"
                            className="nav-pills-custom-5  justify-content-start p-2 py-1 row w-100"
                            style={{
                                border: "none",
                                background: "#EEF0F1",
                                color: "#4267B2",
                                borderRadius: "10px",



                            }}
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                    <img className="pr-1 mb-1 "
                                        src={require("../../../assets/images/UPI.svg")}
                                        style={{ width: `2rem`, height: '%' }}
                                    />
                                    UPI
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Twenty">
                                    {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                    <img className="pr-1 mb-1 "
                                        src={require("../../../assets/images/UPI.svg")}
                                        style={{ width: `2rem`, height: '%' }}
                                    />
                                   Add Money
                                </Nav.Link>
                            </Nav.Item>
                            {
                                auBankQrEnabled && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="fifteenth">
                                            {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1 "
                                                src={require("../../../assets/images/UPI.svg")}
                                                style={{ width: `2rem`, height: '%' }}
                                            />
                                            QR Collection
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            }
                            <Nav.Item>
                                <Nav.Link eventKey="sixth">
                                    {/* <i className="mdi mdi-home-outline pr-2"></i> */}
                                    <img className="pr-1 mb-1"
                                        src={require("../../../assets/images/Load Request.svg")}
                                        style={{ width: `3rem`, height: '%' }} />
                                    Load Request
                                </Nav.Link>
                            </Nav.Item>
                            {ccEnable && (
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <div
                                            onClick={() => {
                                                setMode("CC");
                                            }}
                                        >
                                            <i className="mdi mdi-account-outline pr-2"></i>

                                            Credit Card
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {dcEnable && (
                                <Nav.Item>
                                    <Nav.Link eventKey="third">
                                        <div
                                            onClick={() => {
                                                setMode("DC");
                                            }}
                                        >
                                            <i className="mdi mdi-email-open-outline pr-2"></i>
                                            Debit Card
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {nbEnabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">
                                        <div
                                            onClick={() => {
                                                setMode("NB");
                                            }}
                                        >
                                            <i className="mdi mdi-email-open-outline pr-2"></i>
                                            Net Banking
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {pgEnabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `3rem`, height: '%' }} />
                                            Payment Gateway
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg2Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="seventh">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `3rem`, height: '%' }} />
                                            PG II
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg3Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="eightth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `3rem`, height: '%' }} />
                                            PG III
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg4Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="ninth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                src={require("../../../assets/images/Payment Gateway.svg")}
                                                style={{ width: `3rem`, height: '%' }} />
                                            PG IV
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {pg5Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="tenth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                // src={require("../../../assets/images/Payment Gateway.svg")}
                                                src={paymentGateways[0].icon}
                                                style={{ width: `2rem`, height: '%' }} />
                                            PG V (Instant)
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg6Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="eleventh">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                // src={require("../../../assets/images/Payment Gateway.svg")}
                                                src={paymentGateways[2].icon}
                                                style={{ width: `3rem`, height: '%' }} />
                                            PG VII (Paytm T+1)
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}


                            {pg8Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="twelveth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                // src={require("../../../assets/images/Payment Gateway.svg")}
                                                src={paymentGateways[3].icon}
                                                style={{ width: `3rem`, height: '%' }} />
                                            PG VIII (Phonepe PG)
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg9Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="thirteenth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                //   src={require("../../../assets/images/Payment Gateway.svg")}
                                                src={paymentGateways[1].icon}
                                                style={{ width: `3rem`, height: '%' }} />
                                            Razorpay (Instant)
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {pg10Enabled && (
                                <Nav.Item>
                                    <Nav.Link eventKey="fourteenth">
                                        <div
                                            onClick={() => {
                                                setMode("PG");
                                            }}
                                        >
                                            {/* <i className="mdi mdi-email-open-outline pr-2"></i> */}
                                            <img className="pr-1 mb-1"
                                                //   src={require("../../../assets/images/Payment Gateway.svg")}
                                                src={paymentGateways[4].icon}
                                                style={{ width: `3rem`, height: '%' }} />
                                            Razorpay [T+1]
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                        </Nav>
                    </Col>
                    <Col className="m-0 py-0" sm={12}>
                        <Tab.Content className="tab-content-vertical border-0">
                            <Tab.Pane eventKey="first">
                                <QrCode qrEnabled={qrEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Twenty">
                                <AddMoney />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <PaymentGateway mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <PaymentGateway mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <PaymentGateway mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <PaymentGateway mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                                <LoadRequest />
                            </Tab.Pane>
                            <Tab.Pane eventKey="seventh">
                                <PaymentGateway2 mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="eightth">
                                <PaymentGateway3 mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="ninth">
                                <PaymentGateway4 mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tenth">
                                <PaymentGatewayGeneric config={paymentGateways[0]} mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="eleventh">
                                <PaymentGatewayGeneric config={paymentGateways[2]} mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="twelveth">
                                <PaymentGatewayGeneric mode={mode} config={paymentGateways[2]} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="thirteenth">
                                <PaymentGatewayGeneric config={paymentGateways[1]} mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="fourteenth">
                                <PaymentGatewayGeneric config={paymentGateways[4]} mode={mode} creditCardKycEnabled={creditCardKycEnabled} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="fifteenth">
                                <AuBankQrCode qrEnabled={auBankQrEnabled} />
                            </Tab.Pane>



                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};

const PaymentGateway = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}

                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};

const PaymentGateway2 = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
        plan: ""
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
        plan: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (!selectedPlan) {
            errors.plan = "*Please select a plan";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            // form.submit();
            openInNewTab(`https://bumppy.in/pg2/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
           cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`)
        }
        return false;
    };

    // useEffect(() => {
    //     const amt = Number(inputState.amount);
    //     let cc = 0;
    //     charges.forEach((channel) => {
    //         if (channel.mode == 'CC') {
    //             cc = channel.charge;
    //         }
    //     })
    //     console.log(cc);
    //     const calculatedCharge = (amt * cc) / 100;

    //     setCharges2(calculatedCharge);
    // }, [inputState.amount]);


    //For Selected Plan
    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);


    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };


    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };




    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    console.log(selectedPlan);


    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);



    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                // target="_blank"
                method="post"
                action={`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
                cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>


                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>




                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};

// PAYTM PG
const PaymentGateway3 = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/paytmpg/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}

                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};

// PAYTM PG Router

const PaymentGateway5 = ({ mode, creditCardKycEnabled }) => {
    const history = useHistory();
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [creditCardKycs, setCreditCardKycs] = useState([]);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        cust_email: "",
        cust_mobile: "",
        cust_name: "",
        amount: "",
        creditCard: "",
        aadhaarNo: "", aadhaarFront: "", aadhaarBack: "", cust_name_editable: true
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({ id: '1', title: 'Default Plan' });
    const [isKycDone, setIsKycDone] = useState(false);

    /*
    {
    "line_1": "Ghagoan Satara ",
    "line_2": "",
    "street_name": "Ghogaon",
    "zip": "415111",
    "city": "SATARA",
    "state": "Maharashtra",
    "country": "INDIA",
    "full": "Ghagoan Satara Ghogaon B.O, SATARA, Maharashtra, - 415111"
}
*/

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.cust_email) {
            errors.email = "*Email field required";
        }
        if (!inputState.cust_mobile) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.cust_mobile.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.cust_email);
        if (err) {
            errors.email = err;
        }
        console.log(errors);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const aadhaarVerificationpro = async () => {
        const { username, password } = basicAuth;
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Verify/aadharverifypro",
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, cust_pan: data.result.pan_number, cust_name: data.result.user_full_name, cust_name_editable: false, cust_city: data.result.user_address.city, });
                    // setVerification({
                    //     ...verification,
                    //     ADHAR: true,
                    //     adhaarDetails: data.result,
                    // });

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    const getCreditCards = async () => {
        try {

            //  setCreditCardKycs([]);
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/api_integration/agent_concern/v1/getCreditCardDetails.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                const dummy = {
                    "pk_card_id": "",
                    "userid": "",
                    "creditcard": "",
                    "aadhaar": "",
                    "cust_name": "",
                    "cust_mobile": "",
                    "cust_email": "",
                    "aadhaar_front": "",
                    "aadhaar_back": "",
                    "verified": "N",
                    "indata": "", value: "", label: "New Card"
                }
                //    setInputState({ ...inputState, EmailID: result.data.customer_email, MOBILE_NO: result.data.customer_mobile, custName: result.data.customer_name });
                // const array = [dummy];
                const remoteValues = result.data.map((card) => {
                    return {
                        ...card,
                        value: `${card.pk_card_id} `,
                        label: `${card.cust_name} [ XXXX-XXXX-${card.aadhaar.toString().slice(-4)}]`,
                    };
                })
                setCreditCardKycs([dummy, ...remoteValues]);

                //   swal("Success", result.msg, 'success');
                setIsKycDone(true)
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {

                setInputState({
                    ...inputState, kyc: {
                        "pk_card_id": "",
                        "userid": "",
                        "creditcard": "",
                        "aadhaar": "",
                        "cust_name": "",
                        "cust_mobile": "",
                        "cust_email": "",
                        "aadhaar_front": "",
                        "aadhaar_back": "",
                        "verified": "N",
                        "indata": "", value: "", label: "New Card"
                    }
                })

                setCreditCardKycs([])

                setIsKycDone(false)
                cogoToast.error(result.msg, { position: 'top-center' });

            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };


    useEffect(() => {
        if (inputState.creditCard.length == 4) {
            getCreditCards();
        }
    }, [inputState.creditCard]);

    useEffect(() => {
        if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
            aadhaarVerificationpro();
        }
    }, [inputState.aadhaarNo]);

    useEffect(() => {
        setInputState({ ...inputState, EmailID: "", MOBILE_NO: "", custName: "" });
    }, [creditCardKycs]);

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/api_integration/agent_concern/v1/uploadAadhaarDocs.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'aadhaar_front') {
                                setInputState({ ...inputState, aadhaarFront: result.image_url });
                            }
                            if (type == 'aadhaar_back') {
                                setInputState({ ...inputState, aadhaarBack: result.image_url });
                            }

                            swal(
                                "Upload Successfully",
                                "Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Image upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };


    const readImage = (file, type) => {
        if (type == "aadhaarFront") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_front');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "aadhaarBack") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_back');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //   setAadhaarBack(reader.result);
                    // localStorage.setItem("aadhaarBack", reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    }

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    console.log(inputState);

    return (
        <div
            className="row card py-4"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                //  action={`https://bumppy.in/payment_gateway/paytm/router/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
                // cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}

                action={`https://bumppy.in/api_integration/agent_concern/v1/paymentGateway.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&mobile=${inputState.cust_mobile}&email=${inputState.cust_email}&type=T&plan=${selectedPlan?.id}&callback=https://dashboard.bumppy.com/retailer/topup_request&load_amount=${Math.ceil(Number(inputState.amount))}&cust_name=${inputState.cust_name}&card_no=${inputState.creditCard}&card_cvv=&card_exp_month=&card_exp_year=&route=PAYMENT_WEB&plan=1&aadhaar_front=${inputState.aadhaarFront}&aadhaar_back=${inputState.aadhaarBack}&aadhaar_no=${inputState.aadhaarNo}&pan_no=${inputState.cust_pan}&agent_name=${Profileinfo?.name}&shop_name=${Profileinfo?.shopname}&agent_mobile=${Profileinfo?.mobile}&agent_email=${Profileinfo?.email}&cust_city=${inputState.cust_city}&pg_pipe=5`}
            >

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Credit Card Last 4 Digits`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="creditCard"
                            placeholder="Enter Credit Card last 4 digits"
                            onChange={handleChange}
                            value={inputState.creditCard}
                        />
                        <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                    </Form.Group>
                </Col>
                {(inputState.creditCard && inputState.creditCard.length == 4 && isKycDone) ? (
                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Select KYC Details`}
                            </Form.Label>

                            <Select
                                options={creditCardKycs}
                                value={inputState.kyc}
                                onChange={(e) => setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan })}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                    </Col>
                ) : null}

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Aadhaar Number`}
                        </Form.Label>

                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="aadhaarNo"
                            placeholder="Enter Aadhaar Number"
                            onChange={handleChange}
                            value={inputState.aadhaarNo}
                        />
                        <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="cust_mobile"
                            placeholder="Enter Mobile No."
                            onChange={handleChange}
                            value={inputState.cust_mobile}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_mobile}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="text"
                            name="cust_email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_email}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_email}</p>
                    </Form.Group>
                </Col>


                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Customer Name`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? !inputState.cust_name_editable : true}
                            type="text"
                            name="cust_name"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_name}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_name}</p>
                    </Form.Group>
                </Col>


                <React.Fragment>
                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Amount`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="amount"
                                placeholder="Enter Amount"
                                onChange={handleChange}
                                value={inputState.amount}
                            />
                            <p style={{ color: "red" }}>{formErrors.amount}</p>
                        </Form.Group>
                    </Col>

                    {/* <Col md={12}>
                            <Form.Group style={{ width: "60%" }}>
                                <Form.Label>
                                    <span style={{ color: "red" }}>*</span>
                                    <span style={{ color: "gray" }}> {` Select Plan`}</span>
                                </Form.Label>
                                <Select
                                    onChange={handleChange2}
                                    options={planOption}

                                />

                                <p style={{ color: "red" }}>{formErrors.plan}</p>

                            </Form.Group>
                        </Col> */}

                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Control
                                type="hidden"
                                name="TXN_AMOUNT"
                                placeholder="Enter Amount"
                                value={
                                    Number(inputState.amount) + Number(charges) + Number(charges2)
                                }
                            />
                        </Form.Group>
                    </Col>

                    <div className="row">
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarFront && (
                                <img
                                    src={inputState.aadhaarFront}
                                    alt="aadhaarFront"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Front Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarFront && (

                                    <div className={`${inputState.aadhaarFront ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        console.log(blob);
                                                        readImage(blob, "aadhaarFront");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarFront", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarBack && (
                                <img
                                    src={inputState.aadhaarBack}
                                    alt="aadhaarBack"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Back Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarBack && (

                                    <div className={`${inputState.aadhaarBack ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        readImage(blob, "aadhaarBack");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarBack", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                    </div>

                    { }





                    {selectedPlan && selectedPlan.description && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`${selectedPlan.description}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}

                    {charges != 0 && (
                        <Col md={12} className="d-flex flex-column pb-2">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                charges2
                            )}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}
                    <Col md={12}>
                        <Button
                            type="submit"
                            className="btn btn-primary"
                            variant=""
                            style={{
                                width: " 10vw",
                                background: "#56BE89",
                                color: "white",
                                border: "none",
                            }}
                        >
                            {" "}
                            Submit
                        </Button>
                    </Col>
                </React.Fragment>

            </form>
        </div>
    );
};

const PaymentGatewayGeneric = ({ mode, creditCardKycEnabled, config }) => {
    const history = useHistory();
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [creditCardKycs, setCreditCardKycs] = useState([]);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        cust_email: "",
        cust_mobile: "",
        cust_name: "",
        amount: "",
        creditCard: "",
        aadhaarNo: "", aadhaarFront: "", aadhaarBack: "", cust_name_editable: true, card_status: "ACTIVE", creditCardFront: "", creditCardBack: "", cardId: ""
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [billAmounts, setBillAmounts] = useState([]);
    const [billSelector, setBillSelector] = useState(true);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedAmountCard, setSelectedAmountCard] = useState(-1);
    const [selectedAmountCard1, setSelectedAmountCard1] = useState(0);
    const [isKYCDone, setIsKYCDone] = useState("N");
    const [selectedPlan, setSelectedPlan] = useState({ id: config.settlement_type, title: 'Default Plan' });
    const [isKycDone, setIsKycDone] = useState(false);
    const [aadhaarVerifyClicked, setAadhaarVerifyClicked] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = async (event) => {
        const form = event.target;
        event.preventDefault();
        console.log(event);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (!inputState.cust_email) {
            errors.email = "*Email field required";
        }
        if (!inputState.cust_mobile) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.cust_mobile.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.cust_email);
        if (err) {
            errors.email = err;
        }
        // const res = await transactionAmountCheck(inputState.amount, config.id);
        // if (res != "") {
        //     errors.amount = res;
        // }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            form.submit();

        }
        return false;
    };

    useEffect(() => {
        let cc = 0;
        if (!selectedPlan) {
            const amt = Number(inputState.amount);

            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            // setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);

            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            // setCharges2(calculatedCharge);
            // generateAmounts();
        }

        generateAmounts();

        let ccc = Number(inputState.amount) * Number(cc) / 100;
        if (selectedAmountCard1) {
            ccc = Number(selectedAmountCard1) * Number(cc) / 100;
        }

        ccc = Number(selectedAmountCard1) * Number(cc) / 100;

        setCharges2(ccc);

    }, [selectedPlan, inputState.amount]);


    const getKycStatus = async (card, amount, gatewayId) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/PG/getKycStatus",
                requestOptions
            );

            const data = await res.json();
            if (data.responseCode == "00") {
                if (data.data.status == "Y") {
                    setIsKYCDone("Y");
                    setInputState({ ...inputState, kyc: { value: "New Card", label: "New Card" } });
                    allowedAmounts("5979", "", config.id)
                }
            }
            // setTax(data.data);
        } catch (error) {
            console.log({ error });
        }
    };

    const allowedAmounts = async (card, amount, gatewayId) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
                card_digit: card,
                "amount": amount,
                "pgParner": gatewayId
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/BBPS/getSavedBillDetails",
                requestOptions
            );

            const data = await res.json();
            if (data.responseCode == "00") {
                setBillAmounts(data.data.map((bill) => {
                    return { ...bill, value: twoDecimalPlaces(bill.bill_amount), label: twoDecimalPlaces(bill.bill_amount) }
                }))
            }
            // setTax(data.data);
        } catch (error) {
            console.log({ error });
        }
    };

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const aadhaarVerificationpro = async () => {
        const { username, password } = basicAuth;
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Verify/aadharverifypro",
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, cust_pan: data.result.pan_number, cust_name: data.result.user_full_name, cust_name_editable: false, cust_city: data.result.user_address.city, });
                    // setVerification({
                    //     ...verification,
                    //     ADHAR: true,
                    //     adhaarDetails: data.result,
                    // });

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const getCreditCards = async () => {
        try {

            //  setCreditCardKycs([]);
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/api_integration/agent_concern/v1/getCreditCardDetails.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                const dummy = {
                    "pk_card_id": "",
                    "userid": "",
                    "creditcard": "",
                    "aadhaar": "",
                    "cust_name": "",
                    "cust_mobile": "",
                    "cust_email": "",
                    "aadhaar_front": "",
                    "aadhaar_back": "",
                    "verified": "N",
                    "indata": "", value: "", label: "New Card"
                }
                //    setInputState({ ...inputState, EmailID: result.data.customer_email, MOBILE_NO: result.data.customer_mobile, custName: result.data.customer_name });
                // const array = [dummy];
                const remoteValues = result.data.map((card) => {
                    return {
                        ...card,
                        value: `${card.pk_card_id} `,
                        label: `${card.cust_name} [ XXXX-XXXX-${card.aadhaar.toString().slice(-4)}]`,
                    };
                })
                setCreditCardKycs([dummy, ...remoteValues]);

                //   swal("Success", result.msg, 'success');
                setIsKycDone(true)
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {

                setInputState({
                    ...inputState, kyc: {
                        "pk_card_id": "",
                        "userid": "",
                        "creditcard": "",
                        "aadhaar": "",
                        "cust_name": "",
                        "cust_mobile": "",
                        "cust_email": "",
                        "aadhaar_front": "",
                        "aadhaar_back": "",
                        "verified": "N",
                        "indata": "", value: "", label: "New Card"
                    }
                })

                setCreditCardKycs([])

                setIsKycDone(false)
                cogoToast.error(result.msg, { position: 'top-center' });

            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };

    const getCreditCardStatus = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "cardId": inputState.cardId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/payment_gateway/config/v1/getCreditCardStatus.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                let e = result.data;
                setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan, card_status: e.status, cardId: e.pk_card_id, creditCardFront: e.card_front_image, creditCardBack: e.card_back_image })
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {
                cogoToast.error(result.msg, { position: 'top-center' });

            }

            setTransactionLoaderState(false);

        } catch (error) {
            setTransactionLoaderState(false);

            swal("Failed", `Something went wrong`, "error");

        }
    };

    const twoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).toString();
    };


    useEffect(() => {
        if (inputState.creditCard.length == 4) {
            getCreditCards();
        }
    }, [inputState.creditCard]);

    // useEffect(() => {
    //     if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
    //         aadhaarVerificationpro();
    //     }
    // }, [inputState.aadhaarNo]);

    useEffect(() => {
        setInputState({ ...inputState, EmailID: "", MOBILE_NO: "", custName: "" });
    }, [creditCardKycs]);

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/api_integration/agent_concern/v1/uploadAadhaarDocs.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'aadhaar_front') {
                                setInputState({ ...inputState, aadhaarFront: result.image_url });
                            }
                            if (type == 'aadhaar_back') {
                                setInputState({ ...inputState, aadhaarBack: result.image_url });
                            }

                            swal(
                                "Upload Successfully",
                                "Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Image upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };

    const ImageUpload2 = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "cardId": inputState.cardId,
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/payment_gateway/config/v1/uploadCreditCardImages.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'CREDIT_CARD_BACK') {
                                setInputState({ ...inputState, creditCardBack: result.image_url });
                            }
                            if (type == 'CREDIT_CARD_FRONT') {
                                setInputState({ ...inputState, creditCardFront: result.image_url });
                            }
                            getCreditCardStatus();
                            swal(
                                "Upload Successfully",
                                "Credit Card Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Credit CardImage upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Credit Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };


    const readImage = (file, type) => {
        if (type == "aadhaarFront") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_front');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "aadhaarBack") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_back');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //   setAadhaarBack(reader.result);
                    // localStorage.setItem("aadhaarBack", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "CREDIT_CARD_BACK") {
            //   const file = event.target.files[0];

            ImageUpload2(file, 'CREDIT_CARD_BACK');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "CREDIT_CARD_FRONT") {
            //   const file = event.target.files[0];

            ImageUpload2(file, 'CREDIT_CARD_FRONT');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    }

    const verifyAadhaar = () => {
        if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
            setAadhaarVerifyClicked(true);
        }

    }
    useEffect(() => {
        if (aadhaarVerifyClicked) {
            aadhaarVerificationpro();
        }
    }, [aadhaarVerifyClicked]);

    // random amounts 
    const [randomAmounts, setRandomAmounts] = useState([]);

    const getRandomAmount = (baseAmount, isLess, maxLimit) => {
        if (baseAmount >= 200000) {
            baseAmount = 199950;
        }
        let min = baseAmount - 50;
        let max = baseAmount + 50;

        if (max > maxLimit) max = maxLimit;

        let amount;

        do {
            amount = Math.floor(Math.random() * (max - min + 1)) + min;
        } while (amount % 10 === 0 || (isLess ? amount >= baseAmount : amount <= baseAmount));

        return amount;
    };
    const generateAmounts = () => {
        const maxAmount = 200000;
        let amount = inputState.amount;

        if (amount >= 200000) {
            amount = 199950;
        }

        let lessAmounts = [];
        let greaterAmounts = [];

        const baseAmount = parseInt(amount);

        for (let i = 0; i < 4; i++) {
            lessAmounts.push(getRandomAmount(baseAmount, true, maxAmount));
            if (baseAmount < maxAmount) {
                greaterAmounts.push(getRandomAmount(baseAmount, false, maxAmount));
            }
        }

        if (baseAmount < maxAmount) {
            setRandomAmounts([...lessAmounts, ...greaterAmounts]);
        } else {
            setRandomAmounts(lessAmounts);
        }
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
        // allowedAmounts();
        getKycStatus();
    }, []);

    console.log(inputState);

    return (
        <div
            className="row card py-4"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                method="post"

                onSubmit={validate}

                action={`https://bumppy.in/api_integration/agent_concern/v1/paymentGateway.php?userid=${user.ReferId}&amount=${Math.ceil(Number(selectedAmountCard1))}&mobile=${inputState.cust_mobile}&email=${inputState.cust_email}&type=T&plan=${selectedPlan?.id}&callback=https://dashboard.bumppy.com/retailer/topup_request&load_amount=${Math.ceil(Number(selectedAmountCard1))}&cust_name=${inputState.cust_name}&card_no=${inputState.creditCard}&card_cvv=&card_exp_month=&card_exp_year=&route=PAYMENT_WEB&plan=${config.settlement_type}&aadhaar_front=${inputState.aadhaarFront}&aadhaar_back=${inputState.aadhaarBack}&aadhaar_no=${inputState.aadhaarNo}&pan_no=${inputState.cust_pan}&agent_name=${Profileinfo?.name}&shop_name=${Profileinfo?.shopname}&agent_mobile=${Profileinfo?.mobile}&agent_email=${Profileinfo?.email}&cust_city=${inputState.cust_city}&pg_pipe=${config.id}&is_kyc_done=${isKYCDone}`}


            >

                {isKYCDone == "N" && (
                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Credit Card Last 4 Digits`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="creditCard"
                                placeholder="Enter Credit Card last 4 digits"
                                onChange={(e) => {
                                    setInputState({ ...inputState, creditCard: e.target.value });
                                    let creditCardDigits = e.target.value;
                                    if (creditCardDigits.length == 4) {
                                        allowedAmounts(creditCardDigits, "", config.id)
                                    }

                                }}
                                value={inputState.creditCard}
                            />
                            <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                        </Form.Group>
                    </Col>
                )}


                {(inputState.creditCard && inputState.creditCard.length == 4 && isKycDone) ? (
                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Select KYC Details`}
                            </Form.Label>

                            <Select
                                options={creditCardKycs}
                                value={inputState.kyc}
                                onChange={(e) => {
                                    allowedAmounts(e.creditcard, "", config.id)
                                    setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan, card_status: e.status, cardId: e.pk_card_id, creditCardFront: e.card_front_image, creditCardBack: e.card_back_image });

                                }}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                    </Col>
                ) : null}

                {isKYCDone == "N" && (

                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Aadhaar Number`}
                            </Form.Label>

                            <Form.Control
                                disabled={aadhaarVerifyClicked ? true : inputState.kyc?.label == "New Card" ? false : true}
                                type="number"
                                name="aadhaarNo"
                                placeholder="Enter Aadhaar Number"
                                onChange={handleChange}
                                value={inputState.aadhaarNo}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                        {inputState.aadhaarNo.length == 12 && (
                            <Button
                                className="btn btn-primary"
                                variant=""
                                style={{
                                    width: " 10vw",
                                    background: "#56BE89",
                                    color: "white",
                                    border: "none",
                                }}
                                disabled={aadhaarVerifyClicked}
                                onClick={verifyAadhaar}
                            >
                                {" "}
                                Verify
                            </Button>
                        )}

                    </Col>
                )}


                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="cust_mobile"
                            placeholder="Enter Mobile No."
                            onChange={handleChange}
                            value={inputState.cust_mobile}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_mobile}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="text"
                            name="cust_email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_email}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_email}</p>
                    </Form.Group>
                </Col>


                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Customer Name`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? !inputState.cust_name_editable : true}
                            type="text"
                            name="cust_name"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_name}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_name}</p>
                    </Form.Group>
                </Col>


                <React.Fragment>
                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Amount`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="amount"
                                //disabled={billSelector}
                                placeholder="Enter Amount"
                                onChange={handleChange}
                                value={inputState.amount}
                            />
                            <p style={{ color: "red" }}>{formErrors.amount}</p>
                        </Form.Group>
                    </Col>



                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Control
                                type="hidden"
                                name="TXN_AMOUNT"
                                placeholder="Enter Amount"
                                value={
                                    Number(inputState.amount) + Number(charges) + Number(charges2)
                                }
                            />
                        </Form.Group>
                    </Col>



                    {inputState.amount > 100 && (
                        <div style={{ display: 'flex', justifyContent: 'start', margin: '20px' }}>
                            {randomAmounts.map((amt, index) => (
                                <div
                                    key={index}
                                    style={{
                                        border: selectedAmountCard == index ? '#f9f9f9' : '2px solid blue',
                                        padding: '6px 12px',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        textAlign: 'center',
                                        marginRight: '20px',
                                        background: selectedAmountCard == index ? 'blue' : '#f9f9f9',
                                        color: selectedAmountCard == index ? '#f9f9f9' : 'blue',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setSelectedAmountCard1(amt);
                                        setSelectedAmountCard(index);
                                    }}
                                >
                                    {amt}
                                </div>
                            ))}

                        </div>
                    )}
                    {/* AADHAAR IMAGES UPLOAD */}
                    {isKYCDone == "N" && (
                        <div className="row">
                            <div className="col-5 my-0">
                                {inputState.aadhaarFront && (
                                    <React.Fragment>
                                        <img
                                            src={inputState.aadhaarFront}
                                            alt="aadhaarFront"
                                            style={{ width: "100%", maxHeight: "25vh" }}
                                        />
                                        <div className="p-1">
                                            <Button className="btn-success" onClick={() => {
                                                setInputState({ ...inputState, aadhaarFront: "" })
                                            }} >
                                                Re Upload Aadhaar Front
                                            </Button>
                                        </div>

                                    </React.Fragment>

                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Aadhaar Front Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.aadhaarFront && (

                                        <div className={`${inputState.aadhaarFront ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            console.log(blob);
                                                            readImage(blob, "aadhaarFront");
                                                            // Resizer.imageFileResizer(
                                                            //     blob,
                                                            //     800,
                                                            //     450,
                                                            //     "JPEG",
                                                            //     50,
                                                            //     0,
                                                            //     (uri) => {
                                                            //         setCroppedImage(uri);
                                                            //     },
                                                            //     "base64",
                                                            //     200,
                                                            //     200
                                                            // );
                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-5 my-0">
                                {inputState.aadhaarBack && (
                                    <React.Fragment>
                                        <img
                                            src={inputState.aadhaarBack}
                                            alt="aadhaarBack"
                                            style={{ width: "100%", maxHeight: "25vh" }}
                                        />
                                        <div className="p-1">
                                            <Button className="btn-success" onClick={() => {
                                                setInputState({ ...inputState, aadhaarBack: "" })
                                            }} >
                                                Re Upload Aadhaar Back
                                            </Button>
                                        </div>

                                    </React.Fragment>

                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Aadhaar Back Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.aadhaarBack && (

                                        <div className={`${inputState.aadhaarBack ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            readImage(blob, "aadhaarBack");
                                                            // Resizer.imageFileResizer(
                                                            //     blob,
                                                            //     800,
                                                            //     450,
                                                            //     "JPEG",
                                                            //     50,
                                                            //     0,
                                                            //     (uri) => {
                                                            //         setCroppedImage(uri);
                                                            //     },
                                                            //     "base64",
                                                            //     200,
                                                            //     200
                                                            // );
                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    )}


                    {/* CREDIT CARD IMAGES UPLOAD */}
                    {inputState.cardId != "" && inputState.card_status == "DEACTIVATE" && (
                        <div className="row">
                            <div className="col-12 card-inverse-danger my-2">
                                <span> Credit Card Has Been deactivated please upload card images to Activate Card</span>
                            </div>

                            <div className="col-5 my-0">
                                {inputState.creditCardFront && (
                                    <img
                                        src={inputState.creditCardFront}
                                        alt="creditcardfront"
                                        style={{ width: "100%", maxHeight: "25vh" }}
                                    />
                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Credit Card Front Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.creditCardFront && (

                                        <div className={`${inputState.creditCardFront ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            console.log(blob);
                                                            readImage(blob, "CREDIT_CARD_FRONT");
                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-5 my-0">
                                {inputState.creditCardBack && (
                                    <img
                                        src={inputState.creditCardBack}
                                        alt="creditcardback"
                                        style={{ width: "100%", maxHeight: "25vh" }}
                                    />
                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Credit Card Back Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.creditCardBack && (

                                        <div className={`${inputState.creditCardBack ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            readImage(blob, "CREDIT_CARD_BACK");

                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        </div>

                    )}

                    {selectedPlan && selectedPlan.description && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`${selectedPlan.description}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}

                    {charges != 0 && (
                        <Col md={12} className="d-flex flex-column pb-2">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                charges2
                            )}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong className="card-inverse-warning p-1 my-1 rounded-4">{`In Case of Business Credit Card  2.8% Changes including GST  will be applicable`}</strong>
                        <strong className="card-inverse-warning p-1 my-1 rounded-4">{`In Case of Rupay Credit Card  2% Changes including GST  will be applicable`}</strong>
                        <strong className="card-inverse-warning p-1 my-1 rounded-4">{`In Case of Net Banking  2.36% Changes including GST  will be applicable`}</strong>
                    </Col>

                    {inputState.card_status != "DEACTIVATE" && (
                        <Col className="mb-5" md={12}>
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                variant=""
                                style={{
                                    width: " 10vw",
                                    background: "#56BE89",
                                    color: "white",
                                    border: "none",
                                }}
                            >
                                {" "}
                                Submit
                            </Button>
                        </Col>
                    )}

                </React.Fragment>

            </form>
        </div>
    );
};

// PG Generic
const PaymentGatewayGenericSave = ({ mode, creditCardKycEnabled, config }) => {
    const history = useHistory();
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [creditCardKycs, setCreditCardKycs] = useState([]);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        cust_email: "",
        cust_mobile: "",
        cust_name: "",
        amount: "",
        creditCard: "",
        aadhaarNo: "", aadhaarFront: "", aadhaarBack: "", cust_name_editable: true, card_status: "ACTIVE", creditCardFront: "", creditCardBack: "", cardId: ""
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [billAmounts, setBillAmounts] = useState([]);
    const [billSelector, setBillSelector] = useState(true);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [isKYCDone, setIsKYCDone] = useState("N");
    const [selectedPlan, setSelectedPlan] = useState({ id: config.settlement_type, title: 'Default Plan' });
    const [isKycDone, setIsKycDone] = useState(false);
    const [aadhaarVerifyClicked, setAadhaarVerifyClicked] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = async (event) => {
        const form = event.target;
        event.preventDefault();
        console.log(event);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (!inputState.cust_email) {
            errors.email = "*Email field required";
        }
        if (!inputState.cust_mobile) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.cust_mobile.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.cust_email);
        if (err) {
            errors.email = err;
        }
        // const res = await transactionAmountCheck(inputState.amount, config.id);
        // if (res != "") {
        //     errors.amount = res;
        // }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            form.submit();

        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);


    const getKycStatus = async (card, amount, gatewayId) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/PG/getKycStatus",
                requestOptions
            );

            const data = await res.json();
            if (data.responseCode == "00") {
                if (data.data.status == "Y") {
                    setIsKYCDone("Y");
                    setInputState({ ...inputState, kyc: { value: "New Card", label: "New Card" } });
                    allowedAmounts("5979", "", config.id)
                }
            }
            // setTax(data.data);
        } catch (error) {
            console.log({ error });
        }
    };

    const allowedAmounts = async (card, amount, gatewayId) => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
                card_digit: card,
                "amount": amount,
                "pgParner": gatewayId
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/BBPS/getSavedBillDetails",
                requestOptions
            );

            const data = await res.json();
            if (data.responseCode == "00") {
                setBillAmounts(data.data.map((bill) => {
                    return { ...bill, value: twoDecimalPlaces(bill.bill_amount), label: twoDecimalPlaces(bill.bill_amount) }
                }))
            }
            // setTax(data.data);
        } catch (error) {
            console.log({ error });
        }
    };

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const aadhaarVerificationpro = async () => {
        const { username, password } = basicAuth;
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Verify/aadharverifypro",
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, cust_pan: data.result.pan_number, cust_name: data.result.user_full_name, cust_name_editable: false, cust_city: data.result.user_address.city, });
                    // setVerification({
                    //     ...verification,
                    //     ADHAR: true,
                    //     adhaarDetails: data.result,
                    // });

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const getCreditCards = async () => {
        try {

            //  setCreditCardKycs([]);
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/api_integration/agent_concern/v1/getCreditCardDetails.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                const dummy = {
                    "pk_card_id": "",
                    "userid": "",
                    "creditcard": "",
                    "aadhaar": "",
                    "cust_name": "",
                    "cust_mobile": "",
                    "cust_email": "",
                    "aadhaar_front": "",
                    "aadhaar_back": "",
                    "verified": "N",
                    "indata": "", value: "", label: "New Card"
                }
                //    setInputState({ ...inputState, EmailID: result.data.customer_email, MOBILE_NO: result.data.customer_mobile, custName: result.data.customer_name });
                // const array = [dummy];
                const remoteValues = result.data.map((card) => {
                    return {
                        ...card,
                        value: `${card.pk_card_id} `,
                        label: `${card.cust_name} [ XXXX-XXXX-${card.aadhaar.toString().slice(-4)}]`,
                    };
                })
                setCreditCardKycs([dummy, ...remoteValues]);

                //   swal("Success", result.msg, 'success');
                setIsKycDone(true)
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {

                setInputState({
                    ...inputState, kyc: {
                        "pk_card_id": "",
                        "userid": "",
                        "creditcard": "",
                        "aadhaar": "",
                        "cust_name": "",
                        "cust_mobile": "",
                        "cust_email": "",
                        "aadhaar_front": "",
                        "aadhaar_back": "",
                        "verified": "N",
                        "indata": "", value: "", label: "New Card"
                    }
                })

                setCreditCardKycs([])

                setIsKycDone(false)
                cogoToast.error(result.msg, { position: 'top-center' });

            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };

    const getCreditCardStatus = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "cardId": inputState.cardId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/payment_gateway/config/v1/getCreditCardStatus.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                let e = result.data;
                setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan, card_status: e.status, cardId: e.pk_card_id, creditCardFront: e.card_front_image, creditCardBack: e.card_back_image })
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {
                cogoToast.error(result.msg, { position: 'top-center' });

            }

            setTransactionLoaderState(false);

        } catch (error) {
            setTransactionLoaderState(false);

            swal("Failed", `Something went wrong`, "error");

        }
    };

    const twoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).toString();
    };


    useEffect(() => {
        if (inputState.creditCard.length == 4) {
            getCreditCards();
        }
    }, [inputState.creditCard]);

    // useEffect(() => {
    //     if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
    //         aadhaarVerificationpro();
    //     }
    // }, [inputState.aadhaarNo]);

    useEffect(() => {
        setInputState({ ...inputState, EmailID: "", MOBILE_NO: "", custName: "" });
    }, [creditCardKycs]);

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/api_integration/agent_concern/v1/uploadAadhaarDocs.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'aadhaar_front') {
                                setInputState({ ...inputState, aadhaarFront: result.image_url });
                            }
                            if (type == 'aadhaar_back') {
                                setInputState({ ...inputState, aadhaarBack: result.image_url });
                            }

                            swal(
                                "Upload Successfully",
                                "Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Image upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };

    const ImageUpload2 = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "cardId": inputState.cardId,
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/payment_gateway/config/v1/uploadCreditCardImages.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'CREDIT_CARD_BACK') {
                                setInputState({ ...inputState, creditCardBack: result.image_url });
                            }
                            if (type == 'CREDIT_CARD_FRONT') {
                                setInputState({ ...inputState, creditCardFront: result.image_url });
                            }
                            getCreditCardStatus();
                            swal(
                                "Upload Successfully",
                                "Credit Card Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Credit CardImage upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Credit Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };


    const readImage = (file, type) => {
        if (type == "aadhaarFront") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_front');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "aadhaarBack") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_back');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //   setAadhaarBack(reader.result);
                    // localStorage.setItem("aadhaarBack", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "CREDIT_CARD_BACK") {
            //   const file = event.target.files[0];

            ImageUpload2(file, 'CREDIT_CARD_BACK');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "CREDIT_CARD_FRONT") {
            //   const file = event.target.files[0];

            ImageUpload2(file, 'CREDIT_CARD_FRONT');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    }

    const verifyAadhaar = () => {
        if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
            setAadhaarVerifyClicked(true);
        }

    }
    useEffect(() => {
        if (aadhaarVerifyClicked) {
            aadhaarVerificationpro();
        }
    }, [aadhaarVerifyClicked]);

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
        // allowedAmounts();
        getKycStatus();
    }, []);

    console.log(inputState);

    return (
        <div
            className="row card py-4"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                method="post"

                onSubmit={validate}

                action={`https://bumppy.in/api_integration/agent_concern/v1/paymentGateway.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&mobile=${inputState.cust_mobile}&email=${inputState.cust_email}&type=T&plan=${selectedPlan?.id}&callback=https://dashboard.bumppy.com/retailer/topup_request&load_amount=${Math.ceil(Number(inputState.amount))}&cust_name=${inputState.cust_name}&card_no=${inputState.creditCard}&card_cvv=&card_exp_month=&card_exp_year=&route=PAYMENT_WEB&plan=${config.settlement_type}&aadhaar_front=${inputState.aadhaarFront}&aadhaar_back=${inputState.aadhaarBack}&aadhaar_no=${inputState.aadhaarNo}&pan_no=${inputState.cust_pan}&agent_name=${Profileinfo?.name}&shop_name=${Profileinfo?.shopname}&agent_mobile=${Profileinfo?.mobile}&agent_email=${Profileinfo?.email}&cust_city=${inputState.cust_city}&pg_pipe=${config.id}&is_kyc_done=${isKYCDone}`}


            >

                {isKYCDone == "N" && (
                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Credit Card Last 4 Digits`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="creditCard"
                                placeholder="Enter Credit Card last 4 digits"
                                onChange={(e) => {
                                    setInputState({ ...inputState, creditCard: e.target.value });
                                    let creditCardDigits = e.target.value;
                                    if (creditCardDigits.length == 4) {
                                        allowedAmounts(creditCardDigits, "", config.id)
                                    }

                                }}
                                value={inputState.creditCard}
                            />
                            <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                        </Form.Group>
                    </Col>
                )}


                {(inputState.creditCard && inputState.creditCard.length == 4 && isKycDone) ? (
                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Select KYC Details`}
                            </Form.Label>

                            <Select
                                options={creditCardKycs}
                                value={inputState.kyc}
                                onChange={(e) => {
                                    allowedAmounts(e.creditcard, "", config.id)
                                    setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan, card_status: e.status, cardId: e.pk_card_id, creditCardFront: e.card_front_image, creditCardBack: e.card_back_image });

                                }}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                    </Col>
                ) : null}

                {isKYCDone == "N" && (

                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Aadhaar Number`}
                            </Form.Label>

                            <Form.Control
                                disabled={aadhaarVerifyClicked ? true : inputState.kyc?.label == "New Card" ? false : true}
                                type="number"
                                name="aadhaarNo"
                                placeholder="Enter Aadhaar Number"
                                onChange={handleChange}
                                value={inputState.aadhaarNo}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                        {inputState.aadhaarNo.length == 12 && (
                            <Button
                                className="btn btn-primary"
                                variant=""
                                style={{
                                    width: " 10vw",
                                    background: "#56BE89",
                                    color: "white",
                                    border: "none",
                                }}
                                disabled={aadhaarVerifyClicked}
                                onClick={verifyAadhaar}
                            >
                                {" "}
                                Verify
                            </Button>
                        )}

                    </Col>
                )}


                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="cust_mobile"
                            placeholder="Enter Mobile No."
                            onChange={handleChange}
                            value={inputState.cust_mobile}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_mobile}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="text"
                            name="cust_email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_email}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_email}</p>
                    </Form.Group>
                </Col>


                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Customer Name`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? !inputState.cust_name_editable : true}
                            type="text"
                            name="cust_name"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_name}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_name}</p>
                    </Form.Group>
                </Col>


                <React.Fragment>
                    {/* <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Amount`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="amount"
                                disabled={billSelector}
                                placeholder="Enter Amount"
                                onChange={handleChange}
                                value={inputState.amount}
                            />
                            <p style={{ color: "red" }}>{formErrors.amount}</p>
                        </Form.Group>
                    </Col> */}



                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Control
                                type="hidden"
                                name="TXN_AMOUNT"
                                placeholder="Enter Amount"
                                value={
                                    Number(inputState.amount) + Number(charges) + Number(charges2)
                                }
                            />
                        </Form.Group>
                    </Col>

                    {billSelector && (
                        <Col className="col-12 col-md-6" >
                            <Form.Group>
                                <Form.Label>
                                    <span style={{ color: "red" }}>*</span>
                                    {` Select Amount`}
                                </Form.Label>

                                <Select
                                    options={billAmounts}
                                    onChange={(e) => {
                                        setInputState({ ...inputState, amount: e.value })
                                    }}
                                />
                                <p style={{ color: "red" }}>{formErrors.amount}</p>
                            </Form.Group>
                        </Col>
                    )}
                    {/* AADHAAR IMAGES UPLOAD */}
                    {isKYCDone == "N" && (
                        <div className="row">
                            <div className="col-5 my-0">
                                {inputState.aadhaarFront && (
                                    <React.Fragment>
                                        <img
                                            src={inputState.aadhaarFront}
                                            alt="aadhaarFront"
                                            style={{ width: "100%", maxHeight: "25vh" }}
                                        />
                                        <div className="p-1">
                                            <Button className="btn-success" onClick={() => {
                                                setInputState({ ...inputState, aadhaarFront: "" })
                                            }} >
                                                Re Upload Aadhaar Front
                                            </Button>
                                        </div>

                                    </React.Fragment>

                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Aadhaar Front Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.aadhaarFront && (

                                        <div className={`${inputState.aadhaarFront ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            console.log(blob);
                                                            readImage(blob, "aadhaarFront");
                                                            // Resizer.imageFileResizer(
                                                            //     blob,
                                                            //     800,
                                                            //     450,
                                                            //     "JPEG",
                                                            //     50,
                                                            //     0,
                                                            //     (uri) => {
                                                            //         setCroppedImage(uri);
                                                            //     },
                                                            //     "base64",
                                                            //     200,
                                                            //     200
                                                            // );
                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-5 my-0">
                                {inputState.aadhaarBack && (
                                    <React.Fragment>
                                        <img
                                            src={inputState.aadhaarBack}
                                            alt="aadhaarBack"
                                            style={{ width: "100%", maxHeight: "25vh" }}
                                        />
                                        <div className="p-1">
                                            <Button className="btn-success" onClick={() => {
                                                setInputState({ ...inputState, aadhaarBack: "" })
                                            }} >
                                                Re Upload Aadhaar Back
                                            </Button>
                                        </div>

                                    </React.Fragment>

                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Aadhaar Back Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.aadhaarBack && (

                                        <div className={`${inputState.aadhaarBack ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            readImage(blob, "aadhaarBack");
                                                            // Resizer.imageFileResizer(
                                                            //     blob,
                                                            //     800,
                                                            //     450,
                                                            //     "JPEG",
                                                            //     50,
                                                            //     0,
                                                            //     (uri) => {
                                                            //         setCroppedImage(uri);
                                                            //     },
                                                            //     "base64",
                                                            //     200,
                                                            //     200
                                                            // );
                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    )}


                    {/* CREDIT CARD IMAGES UPLOAD */}
                    {inputState.cardId != "" && inputState.card_status == "DEACTIVATE" && (
                        <div className="row">
                            <div className="col-12 card-inverse-danger my-2">
                                <span> Credit Card Has Been deactivated please upload card images to Activate Card</span>
                            </div>

                            <div className="col-5 my-0">
                                {inputState.creditCardFront && (
                                    <img
                                        src={inputState.creditCardFront}
                                        alt="creditcardfront"
                                        style={{ width: "100%", maxHeight: "25vh" }}
                                    />
                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Credit Card Front Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.creditCardFront && (

                                        <div className={`${inputState.creditCardFront ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            console.log(blob);
                                                            readImage(blob, "CREDIT_CARD_FRONT");
                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col-5 my-0">
                                {inputState.creditCardBack && (
                                    <img
                                        src={inputState.creditCardBack}
                                        alt="creditcardback"
                                        style={{ width: "100%", maxHeight: "25vh" }}
                                    />
                                )}

                                <Form.Group>
                                    <Form.Label>
                                        <b>* Credit Card Back Upload</b>
                                    </Form.Label>
                                    <br />
                                    {!inputState.creditCardBack && (

                                        <div className={`${inputState.creditCardBack ? "collapse" : ""}`} id="imageCropper">
                                            <CropComponent
                                                classes=""
                                                aspect={16 / 9}
                                                callback={(blob) => {
                                                    if (blob) {
                                                        try {
                                                            readImage(blob, "CREDIT_CARD_BACK");

                                                        } catch (err) {
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        </div>

                    )}

                    {selectedPlan && selectedPlan.description && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`${selectedPlan.description}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}

                    {charges != 0 && (
                        <Col md={12} className="d-flex flex-column pb-2">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                charges2
                            )}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong className="card-inverse-warning p-1 my-1 rounded-4">{`In Case of Business Credit Card  2.8% Changes including GST  will be applicable`}</strong>
                        <strong className="card-inverse-warning p-1 my-1 rounded-4">{`In Case of Rupay Credit Card  2% Changes including GST  will be applicable`}</strong>
                        <strong className="card-inverse-warning p-1 my-1 rounded-4">{`In Case of Net Banking  2.36% Changes including GST  will be applicable`}</strong>
                    </Col>

                    {inputState.card_status != "DEACTIVATE" && (
                        <Col className="mb-5" md={12}>
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                variant=""
                                style={{
                                    width: " 10vw",
                                    background: "#56BE89",
                                    color: "white",
                                    border: "none",
                                }}
                            >
                                {" "}
                                Submit
                            </Button>
                        </Col>
                    )}

                </React.Fragment>

            </form>
        </div>
    );
};

// PAYOMATRIX PG
const PaymentGateway4 = ({ mode }) => {
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [getWayWindow, setGetWayWindow] = useState(null);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/payment_gateway/payomatix/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="MOBILE_NO"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.MOBILE_NO}
                        />
                        <p style={{ color: "red" }}>{formErrors.mobile}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="EmailID"
                            placeholder="Enter Email"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.EmailID}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Amount`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="amount"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.amount}
                        />
                        <p style={{ color: "red" }}>{formErrors.amount}</p>
                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group style={{ width: "60%" }}>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            <span style={{ color: "gray" }}> {` Select Plan`}</span>
                        </Form.Label>
                        <Select
                            onChange={handleChange2}
                            options={planOption}

                        />

                        <p style={{ color: "red" }}>{formErrors.plan}</p>

                    </Form.Group>
                </Col>

                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="TXN_AMOUNT"
                            placeholder="Enter Amount"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            value={
                                Number(inputState.amount) + Number(charges) + Number(charges2)
                            }
                        />
                    </Form.Group>
                </Col>
                {selectedPlan && (
                    <Col md={12} className="d-flex flex-column pb-2 text-danger">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`${selectedPlan.description}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}

                {charges != 0 && (
                    <Col md={12} className="d-flex flex-column pb-2">
                        {/* <strong>{`All Charges including GST* `}</strong> */}
                        <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                            charges2
                        )}`}</strong>
                        {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                    </Col>
                )}
                <Col md={12}>
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        variant=""
                        style={{
                            width: " 10vw",
                            background: "#56BE89",
                            color: "white",
                            border: "none",
                        }}
                    >
                        {" "}
                        Pay Now
                    </Button>
                </Col>
            </form>
        </div>
    );
};

const PaymentGateway6Save = ({ mode, creditCardKycEnabled }) => {
    const history = useHistory();
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [creditCardKycs, setCreditCardKycs] = useState([]);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        EmailID: "",
        MOBILE_NO: "",
        amount: "",
        creditCard: "",
        aadhaarNo: ""
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({ id: '2', title: 'Default Plan' });
    const [isKycDone, setIsKycDone] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.EmailID) {
            errors.email = "*Email field required";
        }
        if (!inputState.MOBILE_NO) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.MOBILE_NO.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.EmailID);
        if (err) {
            errors.email = err;
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    const getCreditCards = async () => {
        try {

            //  setCreditCardKycs([]);
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://apiservices.bumppypay.com/api/Payment/credit-card/getVerifiedCreditCardDetails", requestOptions);
            const result = await res.json();
            if (result.status == "0") {

                /*
                {
    "id": "3",
    "credit_card_digit": "5979",
    "customer_name": "SUHAS SANJAY SURYAVANSHI",
    "customer_mobile": "9168805979",
    "customer_email": "suhas.suryavanshi@bumppy.com",
    "aadhaar_no": "602949137215",
    "aadhaar_details": "{\"pan_number\":\"KWNPS3415Q\",\"user_full_name\":\"SUHAS SANJAY SURYAVANSHI\",\"user_full_name_split\":[\"SUHAS\",\"SANJAY\",\"SURYAVANSHI\"],\"masked_aadhaar\":\"XXXXXXXX7215\",\"user_address\":{\"line_1\":\"Ghagoan Satara \",\"line_2\":\"\",\"street_name\":\"Ghogaon\",\"zip\":\"415111\",\"city\":\"SATARA\",\"state\":\"Maharashtra\",\"country\":\"INDIA\",\"full\":\"Ghagoan Satara Ghogaon B.O, SATARA, Maharashtra, - 415111\"},\"user_gender\":\"M\",\"user_dob\":\"08-06-2000\",\"aadhaar_linked_status\":true,\"pan_type\":\"Person\"}",
    "verified": "Y",
    "indate": "2/14/2024 7:15:01 PM",
    "extra": ""
}
*/

                //    setInputState({ ...inputState, EmailID: result.data.customer_email, MOBILE_NO: result.data.customer_mobile, custName: result.data.customer_name });

                setCreditCardKycs(result.data.map((card) => {
                    return {
                        ...card,
                        value: `${card.id} `,
                        label: `${card.customer_name} [ XXXX-XXXX-${card.aadhaar_no.toString().slice(-4)}]`,
                    };
                }));

                //   swal("Success", result.msg, 'success');
                setIsKycDone(true)
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {

                setCreditCardKycs([])

                setIsKycDone(false)
                cogoToast.error(result.msg, { position: 'top-center' });

            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };


    useEffect(() => {
        if (inputState.creditCard.length == 4) {
            getCreditCards();
        }
    }, [inputState.creditCard]);

    useEffect(() => {
        setInputState({ ...inputState, EmailID: "", MOBILE_NO: "", custName: "" });
    }, [creditCardKycs]);

    console.log(inputState);

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    return (
        <div
            className="row card   mt-4 pt-3"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                action={`https://bumppy.in/payment_gateway/paytm/router/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
        cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}
            >
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="CHANNEL_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={mode}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Retailer_ID"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={user.ReferId}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Control
                            type="hidden"
                            name="Callbackurl"
                            placeholder="Enter Mobile No."
                            style={{ borderRadius: "0px" }}
                            value={"https://dashboard.bumppy.com/retailer/topup_request"}
                        />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Credit Card Last 4 Digits`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="creditCard"
                            placeholder="Enter Credit Card last 4 digits"
                            style={{ borderRadius: "0px", width: "42vw" }}
                            onChange={handleChange}
                            value={inputState.creditCard}
                        />
                        <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                    </Form.Group>
                </Col>
                {(inputState.creditCard && inputState.creditCard.length == 4 && isKycDone) ? (
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Select KYC Details`}
                            </Form.Label>

                            <Select
                                options={creditCardKycs}
                                value={inputState.kyc}
                                onChange={(e) => setInputState({ ...inputState, aadhaarNo: e.aadhaar_no, EmailID: e.customer_email, MOBILE_NO: e.customer_mobile, custName: e.customer_name, kyc: e })}
                            />

                            {/* <Form.Control
             type="number"
             name="aadhaarNo"
             placeholder="Enter Aadhaar Number"
             style={{ borderRadius: "0px", width: "42vw" }}
             onChange={handleChange}
             value={inputState.aadhaarNo}
         /> */}
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                    </Col>
                ) :
                    (inputState.creditCard && inputState.creditCard.length == 4 && !isKycDone && inputState.MOBILE_NO == "") ?
                        (
                            <Col md={12}>
                                <Button
                                    type="submit"
                                    className="btn btn-primary"
                                    variant=""
                                    style={{
                                        //   width: " 10vw",
                                        background: "#56BE89",
                                        color: "white",
                                        border: "none",
                                    }}
                                    onClick={() => {
                                        localStorage.setItem("creditCardFourDigit", inputState.creditCard);
                                        history.push("/retailer/credit-card-kyc");
                                    }}
                                >
                                    {" "}
                                    Do Credit Card Kyc
                                </Button>
                            </Col>
                        ) : null}


                {inputState.MOBILE_NO && (
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Mobile No`}
                            </Form.Label>
                            <Form.Control
                                disabled
                                type="number"
                                name="MOBILE_NO"
                                placeholder="Enter Mobile No."
                                style={{ borderRadius: "0px", width: "42vw" }}
                                onChange={handleChange}
                                value={inputState.MOBILE_NO}
                            />
                            <p style={{ color: "red" }}>{formErrors.mobile}</p>
                        </Form.Group>
                    </Col>
                )}
                {inputState.EmailID && (
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Email`}
                            </Form.Label>
                            <Form.Control
                                disabled
                                type="text"
                                name="EmailID"
                                placeholder="Enter Email"
                                style={{ borderRadius: "0px", width: "42vw" }}
                                onChange={handleChange}
                                value={inputState.EmailID}
                            />
                            <p style={{ color: "red" }}>{formErrors.email}</p>
                        </Form.Group>
                    </Col>
                )}

                {inputState.custName && (
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Customer Name`}
                            </Form.Label>
                            <Form.Control
                                disabled
                                type="text"
                                name="custName"
                                placeholder="Enter Email"
                                style={{ borderRadius: "0px", width: "42vw" }}
                                onChange={handleChange}
                                value={inputState.custName}
                            />
                            <p style={{ color: "red" }}>{formErrors.custName}</p>
                        </Form.Group>
                    </Col>
                )}




                {inputState.MOBILE_NO && (
                    <React.Fragment>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>
                                    <span style={{ color: "red" }}>*</span>
                                    {` Enter Amount`}
                                </Form.Label>

                                <Form.Control
                                    type="number"
                                    name="amount"
                                    placeholder="Enter Amount"
                                    style={{ borderRadius: "0px", width: "42vw" }}
                                    onChange={handleChange}
                                    value={inputState.amount}
                                />
                                <p style={{ color: "red" }}>{formErrors.amount}</p>
                            </Form.Group>
                        </Col>

                        {/* <Col md={12}>
                            <Form.Group style={{ width: "60%" }}>
                                <Form.Label>
                                    <span style={{ color: "red" }}>*</span>
                                    <span style={{ color: "gray" }}> {` Select Plan`}</span>
                                </Form.Label>
                                <Select
                                    onChange={handleChange2}
                                    options={planOption}

                                />

                                <p style={{ color: "red" }}>{formErrors.plan}</p>

                            </Form.Group>
                        </Col> */}

                        <Col md={12}>
                            <Form.Group>
                                <Form.Control
                                    type="hidden"
                                    name="TXN_AMOUNT"
                                    placeholder="Enter Amount"
                                    style={{ borderRadius: "0px", width: "42vw" }}
                                    value={
                                        Number(inputState.amount) + Number(charges) + Number(charges2)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        {selectedPlan && selectedPlan.description && (
                            <Col md={12} className="d-flex flex-column pb-2 text-danger">
                                {/* <strong>{`All Charges including GST* `}</strong> */}
                                <strong>{`${selectedPlan.description}`}</strong>
                                {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                            </Col>
                        )}

                        {charges != 0 && (
                            <Col md={12} className="d-flex flex-column pb-2">
                                {/* <strong>{`All Charges including GST* `}</strong> */}
                                <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                    charges2
                                )}`}</strong>
                                {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                            </Col>
                        )}
                        <Col md={12}>
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                variant=""
                                style={{
                                    width: " 10vw",
                                    background: "#56BE89",
                                    color: "white",
                                    border: "none",
                                }}
                            >
                                {" "}
                                Pay Now
                            </Button>
                        </Col>
                    </React.Fragment>

                )}



            </form>
        </div>
    );
};

const PaymentGateway6 = ({ mode, creditCardKycEnabled }) => {
    const history = useHistory();
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [creditCardKycs, setCreditCardKycs] = useState([]);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        cust_email: "",
        cust_mobile: "",
        cust_name: "",
        amount: "",
        creditCard: "",
        aadhaarNo: "", aadhaarFront: "", aadhaarBack: "", cust_name_editable: true
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({ id: '2', title: 'Default Plan' });
    const [isKycDone, setIsKycDone] = useState(false);

    /*
    {
    "line_1": "Ghagoan Satara ",
    "line_2": "",
    "street_name": "Ghogaon",
    "zip": "415111",
    "city": "SATARA",
    "state": "Maharashtra",
    "country": "INDIA",
    "full": "Ghagoan Satara Ghogaon B.O, SATARA, Maharashtra, - 415111"
}
*/

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.cust_email) {
            errors.email = "*Email field required";
        }
        if (!inputState.cust_mobile) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.cust_mobile.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.cust_email);
        if (err) {
            errors.email = err;
        }
        console.log(errors);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const aadhaarVerificationpro = async () => {
        const { username, password } = basicAuth;
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Verify/aadharverifypro",
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, cust_pan: data.result.pan_number, cust_name: data.result.user_full_name, cust_name_editable: false, cust_city: data.result.user_address.city, });
                    // setVerification({
                    //     ...verification,
                    //     ADHAR: true,
                    //     adhaarDetails: data.result,
                    // });

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    const getCreditCards = async () => {
        try {

            //  setCreditCardKycs([]);
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/api_integration/agent_concern/v1/getCreditCardDetails.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {

                /*
                {
                        "pk_card_id": "2",
                        "userid": "BR1004",
                        "creditcard": "5979",
                        "aadhaar": "602949137215",
                        "cust_name": "Suhas Suryavanshi",
                        "cust_mobile": "9168805979",
                        "cust_email": "test@example.com",
                        "aadhaar_front": "https://bumppy.in/api_integration/agent_concern/v1/uploads/img_BR1004_5979_602949137215.png",
                        "aadhaar_back": "https://bumppy.in/api_integration/agent_concern/v1/uploads/img_BR1004_5979_602949137215.png",
                        "verified": "N",
                        "indata": "2024-03-03 20:50:52"
                    }
                 */
                const dummy = {
                    "pk_card_id": "",
                    "userid": "",
                    "creditcard": "",
                    "aadhaar": "",
                    "cust_name": "",
                    "cust_mobile": "",
                    "cust_email": "",
                    "aadhaar_front": "",
                    "aadhaar_back": "",
                    "verified": "N",
                    "indata": "", value: "", label: "New Card"
                }
                //    setInputState({ ...inputState, EmailID: result.data.customer_email, MOBILE_NO: result.data.customer_mobile, custName: result.data.customer_name });
                // const array = [dummy];
                const remoteValues = result.data.map((card) => {
                    return {
                        ...card,
                        value: `${card.pk_card_id} `,
                        label: `${card.cust_name} [ XXXX-XXXX-${card.aadhaar.toString().slice(-4)}]`,
                    };
                })
                setCreditCardKycs([dummy, ...remoteValues]);

                //   swal("Success", result.msg, 'success');
                setIsKycDone(true)
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {

                setInputState({
                    ...inputState, kyc: {
                        "pk_card_id": "",
                        "userid": "",
                        "creditcard": "",
                        "aadhaar": "",
                        "cust_name": "",
                        "cust_mobile": "",
                        "cust_email": "",
                        "aadhaar_front": "",
                        "aadhaar_back": "",
                        "verified": "N",
                        "indata": "", value: "", label: "New Card"
                    }
                })

                setCreditCardKycs([])

                setIsKycDone(false)
                cogoToast.error(result.msg, { position: 'top-center' });

            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };


    useEffect(() => {
        if (inputState.creditCard.length == 4) {
            getCreditCards();
        }
    }, [inputState.creditCard]);

    useEffect(() => {
        if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
            aadhaarVerificationpro();
        }
    }, [inputState.aadhaarNo]);

    useEffect(() => {
        setInputState({ ...inputState, EmailID: "", MOBILE_NO: "", custName: "" });
    }, [creditCardKycs]);

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/api_integration/agent_concern/v1/uploadAadhaarDocs.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'aadhaar_front') {
                                setInputState({ ...inputState, aadhaarFront: result.image_url });
                            }
                            if (type == 'aadhaar_back') {
                                setInputState({ ...inputState, aadhaarBack: result.image_url });
                            }

                            swal(
                                "Upload Successfully",
                                "Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Image upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };


    const readImage = (file, type) => {
        if (type == "aadhaarFront") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_front');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "aadhaarBack") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_back');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //   setAadhaarBack(reader.result);
                    // localStorage.setItem("aadhaarBack", reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    }

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    console.log(inputState);

    return (
        <div
            className="row card py-4"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                //  action={`https://bumppy.in/payment_gateway/paytm/router/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
                // cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}

                action={`https://bumppy.in/api_integration/agent_concern/v1/paymentGateway.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&mobile=${inputState.cust_mobile}&email=${inputState.cust_email}&type=T&plan=${selectedPlan?.id}&callback=https://dashboard.bumppy.com/retailer/topup_request&load_amount=${Math.ceil(Number(inputState.amount))}&cust_name=${inputState.cust_name}&card_no=${inputState.creditCard}&card_cvv=&card_exp_month=&card_exp_year=&route=PAYMENT_WEB&aadhaar_front=${inputState.aadhaarFront}&aadhaar_back=${inputState.aadhaarBack}&aadhaar_no=${inputState.aadhaarNo}&pan_no=${inputState.cust_pan}&agent_name=${Profileinfo?.name}&shop_name=${Profileinfo?.shopname}&agent_mobile=${Profileinfo?.mobile}&agent_email=${Profileinfo?.email}&cust_city=${inputState.cust_city}&pg_pipe=7`}
            >

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Credit Card Last 4 Digits`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="creditCard"
                            placeholder="Enter Credit Card last 4 digits"
                            onChange={handleChange}
                            value={inputState.creditCard}
                        />
                        <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                    </Form.Group>
                </Col>
                {(inputState.creditCard && inputState.creditCard.length == 4 && isKycDone) ? (
                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Select KYC Details`}
                            </Form.Label>

                            <Select
                                options={creditCardKycs}
                                value={inputState.kyc}
                                onChange={(e) => setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan })}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                    </Col>
                ) : null}

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Aadhaar Number`}
                        </Form.Label>

                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="aadhaarNo"
                            placeholder="Enter Aadhaar Number"
                            onChange={handleChange}
                            value={inputState.aadhaarNo}
                        />
                        <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="cust_mobile"
                            placeholder="Enter Mobile No."
                            onChange={handleChange}
                            value={inputState.cust_mobile}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_mobile}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="text"
                            name="cust_email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_email}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_email}</p>
                    </Form.Group>
                </Col>


                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Customer Name`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? !inputState.cust_name_editable : true}
                            type="text"
                            name="cust_name"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_name}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_name}</p>
                    </Form.Group>
                </Col>


                <React.Fragment>
                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Amount`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="amount"
                                placeholder="Enter Amount"
                                onChange={handleChange}
                                value={inputState.amount}
                            />
                            <p style={{ color: "red" }}>{formErrors.amount}</p>
                        </Form.Group>
                    </Col>

                    {/* <Col md={12}>
                            <Form.Group style={{ width: "60%" }}>
                                <Form.Label>
                                    <span style={{ color: "red" }}>*</span>
                                    <span style={{ color: "gray" }}> {` Select Plan`}</span>
                                </Form.Label>
                                <Select
                                    onChange={handleChange2}
                                    options={planOption}

                                />

                                <p style={{ color: "red" }}>{formErrors.plan}</p>

                            </Form.Group>
                        </Col> */}

                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Control
                                type="hidden"
                                name="TXN_AMOUNT"
                                placeholder="Enter Amount"
                                value={
                                    Number(inputState.amount) + Number(charges) + Number(charges2)
                                }
                            />
                        </Form.Group>
                    </Col>

                    <div className="row">
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarFront && (
                                <img
                                    src={inputState.aadhaarFront}
                                    alt="aadhaarFront"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Front Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarFront && (

                                    <div className={`${inputState.aadhaarFront ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        console.log(blob);
                                                        readImage(blob, "aadhaarFront");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarFront", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarBack && (
                                <img
                                    src={inputState.aadhaarBack}
                                    alt="aadhaarBack"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Back Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarBack && (

                                    <div className={`${inputState.aadhaarBack ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        readImage(blob, "aadhaarBack");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarBack", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                    </div>

                    { }





                    {selectedPlan && selectedPlan.description && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`${selectedPlan.description}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}

                    {charges != 0 && (
                        <Col md={12} className="d-flex flex-column pb-2">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                charges2
                            )}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}
                    <Col md={12}>
                        <Button
                            type="submit"
                            className="btn btn-primary"
                            variant=""
                            style={{
                                width: " 10vw",
                                background: "#56BE89",
                                color: "white",
                                border: "none",
                            }}
                        >
                            {" "}
                            Submit
                        </Button>
                    </Col>
                </React.Fragment>

            </form>
        </div>
    );
};

const PaymentGateway8 = ({ mode, creditCardKycEnabled }) => {
    const history = useHistory();
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [creditCardKycs, setCreditCardKycs] = useState([]);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        cust_email: "",
        cust_mobile: "",
        cust_name: "",
        amount: "",
        creditCard: "",
        aadhaarNo: "", aadhaarFront: "", aadhaarBack: "", cust_name_editable: true
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({ id: '1', title: 'Default Plan' });
    const [isKycDone, setIsKycDone] = useState(false);

    /*
    {
    "line_1": "Ghagoan Satara ",
    "line_2": "",
    "street_name": "Ghogaon",
    "zip": "415111",
    "city": "SATARA",
    "state": "Maharashtra",
    "country": "INDIA",
    "full": "Ghagoan Satara Ghogaon B.O, SATARA, Maharashtra, - 415111"
}
*/

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.cust_email) {
            errors.email = "*Email field required";
        }
        if (!inputState.cust_mobile) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.cust_mobile.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.cust_email);
        if (err) {
            errors.email = err;
        }
        console.log(errors);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const aadhaarVerificationpro = async () => {
        const { username, password } = basicAuth;
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Verify/aadharverifypro",
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, cust_pan: data.result.pan_number, cust_name: data.result.user_full_name, cust_name_editable: false, cust_city: data.result.user_address.city, });
                    // setVerification({
                    //     ...verification,
                    //     ADHAR: true,
                    //     adhaarDetails: data.result,
                    // });

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    const getCreditCards = async () => {
        try {

            //  setCreditCardKycs([]);
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/api_integration/agent_concern/v1/getCreditCardDetails.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {

                /*
                {
                        "pk_card_id": "2",
                        "userid": "BR1004",
                        "creditcard": "5979",
                        "aadhaar": "602949137215",
                        "cust_name": "Suhas Suryavanshi",
                        "cust_mobile": "9168805979",
                        "cust_email": "test@example.com",
                        "aadhaar_front": "https://bumppy.in/api_integration/agent_concern/v1/uploads/img_BR1004_5979_602949137215.png",
                        "aadhaar_back": "https://bumppy.in/api_integration/agent_concern/v1/uploads/img_BR1004_5979_602949137215.png",
                        "verified": "N",
                        "indata": "2024-03-03 20:50:52"
                    }
                 */
                const dummy = {
                    "pk_card_id": "",
                    "userid": "",
                    "creditcard": "",
                    "aadhaar": "",
                    "cust_name": "",
                    "cust_mobile": "",
                    "cust_email": "",
                    "aadhaar_front": "",
                    "aadhaar_back": "",
                    "verified": "N",
                    "indata": "", value: "", label: "New Card"
                }
                //    setInputState({ ...inputState, EmailID: result.data.customer_email, MOBILE_NO: result.data.customer_mobile, custName: result.data.customer_name });
                // const array = [dummy];
                const remoteValues = result.data.map((card) => {
                    return {
                        ...card,
                        value: `${card.pk_card_id} `,
                        label: `${card.cust_name} [ XXXX-XXXX-${card.aadhaar.toString().slice(-4)}]`,
                    };
                })
                setCreditCardKycs([dummy, ...remoteValues]);

                //   swal("Success", result.msg, 'success');
                setIsKycDone(true)
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {

                setInputState({
                    ...inputState, kyc: {
                        "pk_card_id": "",
                        "userid": "",
                        "creditcard": "",
                        "aadhaar": "",
                        "cust_name": "",
                        "cust_mobile": "",
                        "cust_email": "",
                        "aadhaar_front": "",
                        "aadhaar_back": "",
                        "verified": "N",
                        "indata": "", value: "", label: "New Card"
                    }
                })

                setCreditCardKycs([])

                setIsKycDone(false)
                cogoToast.error(result.msg, { position: 'top-center' });

            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };


    useEffect(() => {
        if (inputState.creditCard.length == 4) {
            getCreditCards();
        }
    }, [inputState.creditCard]);

    useEffect(() => {
        if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
            aadhaarVerificationpro();
        }
    }, [inputState.aadhaarNo]);

    useEffect(() => {
        setInputState({ ...inputState, EmailID: "", MOBILE_NO: "", custName: "" });
    }, [creditCardKycs]);

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/api_integration/agent_concern/v1/uploadAadhaarDocs.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'aadhaar_front') {
                                setInputState({ ...inputState, aadhaarFront: result.image_url });
                            }
                            if (type == 'aadhaar_back') {
                                setInputState({ ...inputState, aadhaarBack: result.image_url });
                            }

                            swal(
                                "Upload Successfully",
                                "Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Image upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };


    const readImage = (file, type) => {
        if (type == "aadhaarFront") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_front');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "aadhaarBack") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_back');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //   setAadhaarBack(reader.result);
                    // localStorage.setItem("aadhaarBack", reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    }

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    console.log(inputState);

    return (
        <div
            className="row card py-4"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                //  action={`https://bumppy.in/payment_gateway/paytm/router/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
                // cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}

                action={`https://bumppy.in/api_integration/agent_concern/v1/paymentGateway.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&mobile=${inputState.cust_mobile}&email=${inputState.cust_email}&type=T&plan=${selectedPlan?.id}&callback=https://dashboard.bumppy.com/retailer/topup_request&load_amount=${Math.ceil(Number(inputState.amount))}&cust_name=${inputState.cust_name}&card_no=${inputState.creditCard}&card_cvv=&card_exp_month=&card_exp_year=&route=PAYMENT_WEB&aadhaar_front=${inputState.aadhaarFront}&aadhaar_back=${inputState.aadhaarBack}&aadhaar_no=${inputState.aadhaarNo}&pan_no=${inputState.cust_pan}&agent_name=${Profileinfo?.name}&shop_name=${Profileinfo?.shopname}&agent_mobile=${Profileinfo?.mobile}&agent_email=${Profileinfo?.email}&cust_city=${inputState.cust_city}&pg_pipe=8`}
            >

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Credit Card Last 4 Digits`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="creditCard"
                            placeholder="Enter Credit Card last 4 digits"
                            onChange={handleChange}
                            value={inputState.creditCard}
                        />
                        <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                    </Form.Group>
                </Col>
                {(inputState.creditCard && inputState.creditCard.length == 4 && isKycDone) ? (
                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Select KYC Details`}
                            </Form.Label>

                            <Select
                                options={creditCardKycs}
                                value={inputState.kyc}
                                onChange={(e) => setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan })}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                    </Col>
                ) : null}

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Aadhaar Number`}
                        </Form.Label>

                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="aadhaarNo"
                            placeholder="Enter Aadhaar Number"
                            onChange={handleChange}
                            value={inputState.aadhaarNo}
                        />
                        <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="cust_mobile"
                            placeholder="Enter Mobile No."
                            onChange={handleChange}
                            value={inputState.cust_mobile}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_mobile}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="text"
                            name="cust_email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_email}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_email}</p>
                    </Form.Group>
                </Col>


                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Customer Name`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? !inputState.cust_name_editable : true}
                            type="text"
                            name="cust_name"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_name}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_name}</p>
                    </Form.Group>
                </Col>


                <React.Fragment>
                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Amount`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="amount"
                                placeholder="Enter Amount"
                                onChange={handleChange}
                                value={inputState.amount}
                            />
                            <p style={{ color: "red" }}>{formErrors.amount}</p>
                        </Form.Group>
                    </Col>

                    {/* <Col md={12}>
                            <Form.Group style={{ width: "60%" }}>
                                <Form.Label>
                                    <span style={{ color: "red" }}>*</span>
                                    <span style={{ color: "gray" }}> {` Select Plan`}</span>
                                </Form.Label>
                                <Select
                                    onChange={handleChange2}
                                    options={planOption}

                                />

                                <p style={{ color: "red" }}>{formErrors.plan}</p>

                            </Form.Group>
                        </Col> */}

                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Control
                                type="hidden"
                                name="TXN_AMOUNT"
                                placeholder="Enter Amount"
                                value={
                                    Number(inputState.amount) + Number(charges) + Number(charges2)
                                }
                            />
                        </Form.Group>
                    </Col>

                    <div className="row">
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarFront && (
                                <img
                                    src={inputState.aadhaarFront}
                                    alt="aadhaarFront"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Front Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarFront && (

                                    <div className={`${inputState.aadhaarFront ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        console.log(blob);
                                                        readImage(blob, "aadhaarFront");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarFront", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarBack && (
                                <img
                                    src={inputState.aadhaarBack}
                                    alt="aadhaarBack"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Back Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarBack && (

                                    <div className={`${inputState.aadhaarBack ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        readImage(blob, "aadhaarBack");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarBack", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                    </div>

                    { }





                    {selectedPlan && selectedPlan.description && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`${selectedPlan.description}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}

                    {charges != 0 && (
                        <Col md={12} className="d-flex flex-column pb-2">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                charges2
                            )}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}
                    <Col md={12}>
                        <Button
                            type="submit"
                            className="btn btn-primary"
                            variant=""
                            style={{
                                width: " 10vw",
                                background: "#56BE89",
                                color: "white",
                                border: "none",
                            }}
                        >
                            {" "}
                            Submit
                        </Button>
                    </Col>
                </React.Fragment>

            </form>
        </div>
    );
};

const PaymentGateway9 = ({ mode, creditCardKycEnabled }) => {
    const history = useHistory();
    const { user, setTransactionLoaderState, Profileinfo } = useContext(GlobalContext);
    const [creditCardKycs, setCreditCardKycs] = useState([]);
    const [inputState, setInputState] = useState({
        TXN_AMOUNT: "",
        cust_email: "",
        cust_mobile: "",
        cust_name: "",
        amount: "",
        creditCard: "",
        aadhaarNo: "", aadhaarFront: "", aadhaarBack: "", cust_name_editable: true
    });
    const [formErrors, setFormErrors] = useState({
        amount: "",
        email: "",
        mobile: "",
        amount: "",
    });
    const [charges, setCharges] = useState([]);
    const [charges2, setCharges2] = useState(0);
    const [planOption, setPlanOption] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({ id: '1', title: 'Default Plan' });
    const [isKycDone, setIsKycDone] = useState(false);

    /*
    {
    "line_1": "Ghagoan Satara ",
    "line_2": "",
    "street_name": "Ghogaon",
    "zip": "415111",
    "city": "SATARA",
    "state": "Maharashtra",
    "country": "INDIA",
    "full": "Ghagoan Satara Ghogaon B.O, SATARA, Maharashtra, - 415111"
}
*/

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 0 && value.charAt(value.length - 1) == " ") {
            let errors = {};
            errors.email = "*Please enter a valid input without spaces";
            setFormErrors(errors);
        } else {
            setInputState({ ...inputState, [name]: value });
        }
    };
    const handleChange2 = (e) => {

        setSelectedPlan(e);

    };
    // email validator
    function validateEmail(email) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return "";
        } else {
            return "Please enter valid email address";
        }
    }
    const validate = (event) => {
        event.preventDefault();
        console.log(inputState);
        let errors = {};
        if (!inputState.amount) {
            errors.amount = "*Amount field required";
        }
        if (Number(inputState.amount) < 10) {
            errors.amount = "*Please enter amount greater than 10";
        }
        if (Number(inputState.amount) % 10 == 0) {
            errors.amount = "*Amount Should not be multiple of 10";
        }
        if (!inputState.cust_email) {
            errors.email = "*Email field required";
        }
        if (!inputState.cust_mobile) {
            errors.mobile = "*Mobile field required";
        }
        if (inputState.cust_mobile.length != 10) {
            errors.mobile = "*Please enter valid mobile number";
        }
        let err = validateEmail(inputState.cust_email);
        if (err) {
            errors.email = err;
        }
        console.log(errors);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const form = event.target;
            form.submit();
            //   openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${Profileinfo?.mobile}&email=suhassuryavanshi5979@gmail.com&type=T&
            //   cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB`)
        }
        return false;
    };

    useEffect(() => {

        if (!selectedPlan) {
            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.mode == 'CC') {
                    cc = channel.charge;
                }
            })
            console.log(cc);
            const calculatedCharge = (amt * cc) / 100;

            setCharges2(calculatedCharge);
        }
        else {

            const amt = Number(inputState.amount);
            let cc = 0;
            charges.forEach((channel) => {
                if (channel.fund_settlement_id === selectedPlan.id) {
                    cc = channel.charge;
                }
            })

            const calculatedCharge = (amt * cc) / 100;
            setCharges2(calculatedCharge);
        }

    }, [selectedPlan, inputState.amount]);

    const getPaymentGatewayCharges = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/pg/getPaymentGatewayCharges",
                requestOptions
            );

            const data = await res.json();
            if (data.status == "0") {
                setCharges(data.data)
            }
            // setTax(data.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllFundSettlementPlans = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Payment/fund/getAllFundSettlementPlans",
                requestOptions
            );
            const result = await res.json();
            if (result.status == "0") {
                //  setPlanOption(result.data);

                setPlanOption(
                    result.data.map((plan) => {
                        return {
                            ...plan,
                            value: `${plan.id} `,
                            label: `${plan.title}`,

                        };
                    })
                );


            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
        }
    };

    const aadhaarVerificationpro = async () => {
        const { username, password } = basicAuth;
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Verify/aadharverifypro",
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, cust_pan: data.result.pan_number, cust_name: data.result.user_full_name, cust_name_editable: false, cust_city: data.result.user_address.city, });
                    // setVerification({
                    //     ...verification,
                    //     ADHAR: true,
                    //     adhaarDetails: data.result,
                    // });

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };


    const openInNewTab = (data) => {
        const newWindow = window.open(
            data,
            "",
            "height=800,width=1000'"
        );
        if (newWindow) newWindow.opener = null;
    };

    const getCreditCards = async () => {
        try {

            //  setCreditCardKycs([]);
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch("https://bumppy.in/api_integration/agent_concern/v1/getCreditCardDetails.php", requestOptions);
            const result = await res.json();
            if (result.status == "0") {

                /*
                {
                        "pk_card_id": "2",
                        "userid": "BR1004",
                        "creditcard": "5979",
                        "aadhaar": "602949137215",
                        "cust_name": "Suhas Suryavanshi",
                        "cust_mobile": "9168805979",
                        "cust_email": "test@example.com",
                        "aadhaar_front": "https://bumppy.in/api_integration/agent_concern/v1/uploads/img_BR1004_5979_602949137215.png",
                        "aadhaar_back": "https://bumppy.in/api_integration/agent_concern/v1/uploads/img_BR1004_5979_602949137215.png",
                        "verified": "N",
                        "indata": "2024-03-03 20:50:52"
                    }
                 */
                const dummy = {
                    "pk_card_id": "",
                    "userid": "",
                    "creditcard": "",
                    "aadhaar": "",
                    "cust_name": "",
                    "cust_mobile": "",
                    "cust_email": "",
                    "aadhaar_front": "",
                    "aadhaar_back": "",
                    "verified": "N",
                    "indata": "", value: "", label: "New Card"
                }
                //    setInputState({ ...inputState, EmailID: result.data.customer_email, MOBILE_NO: result.data.customer_mobile, custName: result.data.customer_name });
                // const array = [dummy];
                const remoteValues = result.data.map((card) => {
                    return {
                        ...card,
                        value: `${card.pk_card_id} `,
                        label: `${card.cust_name} [ XXXX-XXXX-${card.aadhaar.toString().slice(-4)}]`,
                    };
                })
                setCreditCardKycs([dummy, ...remoteValues]);

                //   swal("Success", result.msg, 'success');
                setIsKycDone(true)
                cogoToast.success(result.msg, { position: 'top-center' });
            } else {

                setInputState({
                    ...inputState, kyc: {
                        "pk_card_id": "",
                        "userid": "",
                        "creditcard": "",
                        "aadhaar": "",
                        "cust_name": "",
                        "cust_mobile": "",
                        "cust_email": "",
                        "aadhaar_front": "",
                        "aadhaar_back": "",
                        "verified": "N",
                        "indata": "", value: "", label: "New Card"
                    }
                })

                setCreditCardKycs([])

                setIsKycDone(false)
                cogoToast.error(result.msg, { position: 'top-center' });

            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };


    useEffect(() => {
        if (inputState.creditCard.length == 4) {
            getCreditCards();
        }
    }, [inputState.creditCard]);

    useEffect(() => {
        if (inputState.aadhaarNo.length == 12 && inputState.cust_name == "") {
            aadhaarVerificationpro();
        }
    }, [inputState.aadhaarNo]);

    useEffect(() => {
        setInputState({ ...inputState, EmailID: "", MOBILE_NO: "", custName: "" });
    }, [creditCardKycs]);

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (file, type) => {
        // if (file.size > 256000) {
        //     swal("Invalid", "Image Size should be less than 256 kb", "error");
        //     return;
        // }
        convertToBiteArray(file, (ByteArray) => {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "aadhaar": inputState.aadhaarNo,
                "creditcard": inputState.creditCard,
                "type": type,
                "image_data": ByteArray
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            try {
                fetch(
                    `https://bumppy.in/api_integration/agent_concern/v1/uploadAadhaarDocs.php`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.status == "0") {
                            if (type == 'aadhaar_front') {
                                setInputState({ ...inputState, aadhaarFront: result.image_url });
                            }
                            if (type == 'aadhaar_back') {
                                setInputState({ ...inputState, aadhaarBack: result.image_url });
                            }

                            swal(
                                "Upload Successfully",
                                "Image upload successfully",
                                "success"
                            );
                        } else {
                            //  swal("Upload Failed", "Image upload successfully", "error");
                            swal(
                                "Upload Failed",
                                "Image upload Failed",
                                "error"
                            );
                        }
                        // history.push("/retailer/topup_request");
                    })
                    .catch((err) => {
                        swal(
                            "Upload Failed",
                            "Image upload Failed | Error From Server",
                            "error"
                        );
                    });
            } catch (error) {
                console.log(error);
            }
        });
    };


    const readImage = (file, type) => {
        if (type == "aadhaarFront") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_front');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //  setAadhaarFront(reader.result);
                    //  localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "aadhaarBack") {
            //   const file = event.target.files[0];

            ImageUpload(file, 'aadhaar_back');

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    //   setAadhaarBack(reader.result);
                    // localStorage.setItem("aadhaarBack", reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    }

    useEffect(() => {
        getPaymentGatewayCharges();
        getAllFundSettlementPlans();
    }, []);

    console.log(inputState);

    return (
        <div
            className="row card py-4"
            style={{
                // width: "78vw",
                border: "solid 1px",
                borderRadius: "10px",
                //  height: "43vh",
                margin: "1rem",

            }}
        >
            <form
                //  target="_blank"
                method="post"
                //  action={`https://bumppy.in/payment_gateway/paytm/router/v1/request.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&load_amount=${inputState.amount}&mobile=${inputState.MOBILE_NO}&email=${inputState.EmailID}&type=T&
                // cust_name=${Profileinfo?.shopname}&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${selectedPlan?.id}`}
                onSubmit={validate}

                action={`https://bumppy.in/api_integration/agent_concern/v1/paymentGateway.php?userid=${user.ReferId}&amount=${Math.ceil(Number(inputState.amount))}&mobile=${inputState.cust_mobile}&email=${inputState.cust_email}&type=T&plan=${selectedPlan?.id}&callback=https://dashboard.bumppy.com/retailer/topup_request&load_amount=${Math.ceil(Number(inputState.amount))}&cust_name=${inputState.cust_name}&card_no=${inputState.creditCard}&card_cvv=&card_exp_month=&card_exp_year=&route=PAYMENT_WEB&aadhaar_front=${inputState.aadhaarFront}&aadhaar_back=${inputState.aadhaarBack}&aadhaar_no=${inputState.aadhaarNo}&pan_no=${inputState.cust_pan}&agent_name=${Profileinfo?.name}&shop_name=${Profileinfo?.shopname}&agent_mobile=${Profileinfo?.mobile}&agent_email=${Profileinfo?.email}&cust_city=${inputState.cust_city}&pg_pipe=6`}
            >

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Credit Card Last 4 Digits`}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="creditCard"
                            placeholder="Enter Credit Card last 4 digits"
                            onChange={handleChange}
                            value={inputState.creditCard}
                        />
                        <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                    </Form.Group>
                </Col>
                {(inputState.creditCard && inputState.creditCard.length == 4 && isKycDone) ? (
                    <Col className="col-12 col-md-6">
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Select KYC Details`}
                            </Form.Label>

                            <Select
                                options={creditCardKycs}
                                value={inputState.kyc}
                                onChange={(e) => setInputState({ ...inputState, aadhaarNo: e.aadhaar, cust_email: e.cust_email, cust_mobile: e.cust_mobile, cust_name: e.cust_name, kyc: e, aadhaarFront: e.aadhaar_front, aadhaarBack: e.aadhaar_back, cust_pan: e.cust_pan })}
                            />
                            <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        </Form.Group>
                    </Col>
                ) : null}

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Aadhaar Number`}
                        </Form.Label>

                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="aadhaarNo"
                            placeholder="Enter Aadhaar Number"
                            onChange={handleChange}
                            value={inputState.aadhaarNo}
                        />
                        <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6">
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Mobile No`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="number"
                            name="cust_mobile"
                            placeholder="Enter Mobile No."
                            onChange={handleChange}
                            value={inputState.cust_mobile}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_mobile}</p>
                    </Form.Group>
                </Col>

                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Email`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? false : true}
                            type="text"
                            name="cust_email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_email}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_email}</p>
                    </Form.Group>
                </Col>


                <Col className="col-12 col-md-6" >
                    <Form.Group>
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Customer Name`}
                        </Form.Label>
                        <Form.Control
                            disabled={inputState.kyc?.label == "New Card" ? !inputState.cust_name_editable : true}
                            type="text"
                            name="cust_name"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={inputState.cust_name}
                        />
                        <p style={{ color: "red" }}>{formErrors.cust_name}</p>
                    </Form.Group>
                </Col>


                <React.Fragment>
                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter Amount`}
                            </Form.Label>

                            <Form.Control
                                type="number"
                                name="amount"
                                placeholder="Enter Amount"
                                onChange={handleChange}
                                value={inputState.amount}
                            />
                            <p style={{ color: "red" }}>{formErrors.amount}</p>
                        </Form.Group>
                    </Col>

                    {/* <Col md={12}>
                            <Form.Group style={{ width: "60%" }}>
                                <Form.Label>
                                    <span style={{ color: "red" }}>*</span>
                                    <span style={{ color: "gray" }}> {` Select Plan`}</span>
                                </Form.Label>
                                <Select
                                    onChange={handleChange2}
                                    options={planOption}

                                />

                                <p style={{ color: "red" }}>{formErrors.plan}</p>

                            </Form.Group>
                        </Col> */}

                    <Col className="col-12 col-md-6" >
                        <Form.Group>
                            <Form.Control
                                type="hidden"
                                name="TXN_AMOUNT"
                                placeholder="Enter Amount"
                                value={
                                    Number(inputState.amount) + Number(charges) + Number(charges2)
                                }
                            />
                        </Form.Group>
                    </Col>

                    <div className="row">
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarFront && (
                                <img
                                    src={inputState.aadhaarFront}
                                    alt="aadhaarFront"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Front Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarFront && (

                                    <div className={`${inputState.aadhaarFront ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        console.log(blob);
                                                        readImage(blob, "aadhaarFront");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarFront", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                        <div className="col-5 grid-margin">
                            {inputState.aadhaarBack && (
                                <img
                                    src={inputState.aadhaarBack}
                                    alt="aadhaarBack"
                                    style={{ width: "100%", maxHeight: "25vh" }}
                                />
                            )}

                            <Form.Group>
                                <Form.Label>
                                    <b>* Aadhaar Back Upload</b>
                                </Form.Label>
                                <br />
                                {!inputState.aadhaarBack && (

                                    <div className={`${inputState.aadhaarBack ? "collapse" : ""}`} id="imageCropper">
                                        <CropComponent
                                            classes=""
                                            aspect={16 / 9}
                                            callback={(blob) => {
                                                if (blob) {
                                                    try {
                                                        readImage(blob, "aadhaarBack");
                                                        // Resizer.imageFileResizer(
                                                        //     blob,
                                                        //     800,
                                                        //     450,
                                                        //     "JPEG",
                                                        //     50,
                                                        //     0,
                                                        //     (uri) => {
                                                        //         setCroppedImage(uri);
                                                        //     },
                                                        //     "base64",
                                                        //     200,
                                                        //     200
                                                        // );
                                                    } catch (err) {
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <Form.Label>
                                    <span className="text-danger">
                                        Image size should be less than 256 kb
                                    </span>
                                </Form.Label>
                                <Form.Control
                                    accept=".jpeg"
                                    type="file"
                                    name="slip"
                                    onChange={(e) => {
                                        readImage("aadhaarBack", e)
                                    }}
                                    style={{ borderRadius: "0px" }}
                                ></Form.Control> */}
                            </Form.Group>
                        </div>
                    </div>

                    { }





                    {selectedPlan && selectedPlan.description && (
                        <Col md={12} className="d-flex flex-column pb-2 text-danger">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`${selectedPlan.description}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}

                    {charges != 0 && (
                        <Col md={12} className="d-flex flex-column pb-2">
                            {/* <strong>{`All Charges including GST* `}</strong> */}
                            <strong>{`Gateway Charges + GST :  ${twoDecimalPlaces(
                                charges2
                            )}`}</strong>
                            {/* <strong>{`Total :  ${twoDecimalPlaces(
                            Number(inputState.amount) + Number(charges) + Number(charges2)
                        )}`}</strong> */}
                        </Col>
                    )}
                    <Col md={12}>
                        <Button
                            type="submit"
                            className="btn btn-primary"
                            variant=""
                            style={{
                                width: " 10vw",
                                background: "#56BE89",
                                color: "white",
                                border: "none",
                            }}
                        >
                            {" "}
                            Submit
                        </Button>
                    </Col>
                </React.Fragment>

            </form>
        </div>
    );
};

export default Topup;
