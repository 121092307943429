import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";

// import WalletReportTable from "./WalletToWalletReportTable";
import WalletToWalletTable from "./WalletToWalletTable";
const WalletToWallet = ({agentId}) => {
    return (
        <GlobalContext.Consumer>
            {(context) => (

                <div style={{ padding: "1rem" }} className="bg-light ">
                    <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
                        <div className="card-body m-0 p-2">
                            <div>

                                <WalletToWalletTable  agentId={agentId}/>
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </GlobalContext.Consumer>
    );
};

export default WalletToWallet;