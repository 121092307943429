import React, { useState, useEffect } from "react";
import ShortCuts from "../../Shortcut_Links/ShortCuts";
import { Button, Card } from "react-bootstrap";
import GasPaymentSidebar from "./GasPaymentSidebar";
import { basicAuth } from "../../config";
import { Redirect, useLocation } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
const GasBillPay = () => {
  const { username, password } = basicAuth;
  const [operators, setOperators] = useState([]);
  const location = useLocation();
  const getAllOperators = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5A9m7fMnAMLYvVd5fgVtmy2bBp3r0GIpHRdDeia7KGY_3XZbwomnm-HeRj44tqEZDjAicpoO9Bs2L7ARGVU6xu8Jp-jIL8_MNVANUWtLY6bbtf4GsN2C1YKo2u8pBzQ2thipUaoS8ylb51lHctedHBZIp-PCXwrUjLfOopbcx3JdXVJEnT098SsgQRLUKttYfpYSTLj_90DN08kBR2MRLjaiTJLglJb3q-vVA1Ylc6h-OLjNmNZonqUQXA7xOX6DZkyxAAQycblnM8hWRIgRCtUbcOXKP7qtGKnn8mRYFm08p8VN15DVLXMklic2r3dqzbuBjnmEhCTIpLaeC9C3aNXpcMT23-wj3Me1_hus9gbObMlGoz2tLmnWt9oHIqIvZmk4a4cW3mmVYbem7jyNYLym_CShBVgYocQZIYo4bkgveYwZVVXSeUYsBiMUUDKBSq1wWZ4b9vzru0Xy3LhmxxcFUlT6AxtLx4_TQfBrwgre1_GiTT6UahqQvMJ3XDmFZ98w03Re9KlGot3pJ_w_xOTj0A2tIJtCIBKi2VcSOOhLREWLzdfZASrzNTbw0fA4IO10EYgukdFoup8HiYhv7rfZEStyxODyGDaAUT0rgkOJr8H5hhPAej7FfjHs_5Mhs1DDe_nAYBSHC1PkXuyafR"
    );

    var raw = JSON.stringify({
      userName: "string",
      categoryId: 15,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Operator/GetAllOperators`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data) {
        setOperators(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // did mount
  useEffect(() => {
    getAllOperators();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <GasPaymentSidebar details={{ operators }} />
            </div>
            <div style={{backgroundColor:COLOR_BACKGROUND_BODY}}>
              <div className="row" style={{ padding: "0px" }}>
                <ShortCuts path={location.pathname} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "10px 10px 0px 10px",
                }}
              >
                <Button
                  variant="success"
                  className="rounded-0"
                  onClick={() => {
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                >
                  {"Pay Gas Bill"}
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <Card style={{ flex: "1", margin: "10px", padding: "10px" }}>
                  <strong>{"Latest Bill Payments Details"}</strong>
                </Card>
              </div>
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default GasBillPay;
