import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Card, Accordion, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL_APISERVICES, BASE_URL_PAYNOW } from "../../utils/Constants";
import Cable from "../../../assets/svg/cable.svg";
import Electricity from "../../../assets/svg/electricity.svg";
import Dth from "../../../assets/svg/dth.svg";
import PrepaidRecharge from "../../../assets/svg/prepaid_recharge.svg";
import WaterBill from "../../../assets/svg/water_bill.svg";
import PipedGas from "../../../assets/svg/piped_gas.svg";
import Broadband from "../../../assets/svg/broadband.svg";
import BusTicketIcon from "../../../assets/svg/bus_ticket.svg";
import EducationIcon from "../../../assets/svg/education.svg";
import FastagIcon from "../../../assets/svg/fastag.svg";
import FlightTicketIcon from "../../../assets/svg/flight_ticket.svg";
import GasCylinderIcon from "../../../assets/svg/gas_cylinder.svg";
import HospitalIcon from "../../../assets/svg/hospital.svg";
import CreditCardIcon from "../../../assets/svg/credit_card.svg";
import HousingSocietyIcon from "../../../assets/svg/housing_society.svg";
import InsuranceIcon from "../../../assets/svg/insurance.svg";
import LandlineIcon from "../../../assets/svg/landline.svg";
import LoanIcon from "../../../assets/svg/load_repayment.svg";
import MuncipalTaxIcon from "../../../assets/svg/muncipal_tax.svg";
import TrainTicketIcon from "../../../assets/svg/train_ticket.svg";

// Services icon
import Cable_2 from "../../../assets/svg/cable_2.svg";
import Electricity_2 from "../../../assets/svg/electricity_2.svg";
import Dth_2 from "../../../assets/svg/dth_2.svg";
import PrepaidRecharge_2 from "../../../assets/svg/prepaid_recharge_2.svg";
import WaterBill_2 from "../../../assets/svg/water_bill_2.svg";
import PipedGas_2 from "../../../assets/svg/piped_gas_2.svg";
import Broadband_2 from "../../../assets/svg/broadband_2.svg";
import BusTicketIcon_2 from "../../../assets/svg/bus_ticket_2.svg";
import EducationIcon_2 from "../../../assets/svg/education_2.svg";
import FastagIcon_2 from "../../../assets/svg/fastag_2.svg";
import FlightTicketIcon_2 from "../../../assets/svg/flight_ticket_2.svg";
import GasCylinderIcon_2 from "../../../assets/svg/gas_cylinder_2.svg";

import { BillerResponse } from "../../utils/BillerResponse";
import { Redirect } from "react-router";
import {
  generateHashKey,
  generateHashKey2,
  getLatLong,
  regexValidation,
} from "../../utils/utilFuctions";
import { validateTPin } from "../../utils/Validations";

const dropShadow = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const categoryInfo = [
  {
    categoryId: 1,
    categoryName: "Broadband Postpaid",
    amountEditable: false,
  },
  {
    categoryId: 2,
    categoryName: "Cable TV",
    amountEditable: false,
  },
  {
    categoryId: 3,
    categoryName: "DTH",
    amountEditable: false,
  },
  {
    categoryId: 4,
    categoryName: "Education Fees",
    amountEditable: false,
  },
  {
    categoryId: 5,
    categoryName: "Electricity",
    amountEditable: false,
  },
  {
    categoryId: 6,
    categoryName: "Fastag",
    amountEditable: true,
  },
  {
    categoryId: 7,
    categoryName: "Gas",
    amountEditable: false,
  },
  {
    categoryId: 8,
    categoryName: "Health Insurance",
    amountEditable: false,
  },
  {
    categoryId: 9,
    categoryName: "Hospital",
    amountEditable: false,
  },
  {
    categoryId: 10,
    categoryName: "Housing Society",
    amountEditable: false,
  },
  {
    categoryId: 11,
    categoryName: "Insurance",
    amountEditable: false,
  },
  {
    categoryId: 12,
    categoryName: "Landline Postpaid",
    amountEditable: false,
  },
  {
    categoryId: 13,
    categoryName: "Life Insurance",
    amountEditable: false,
  },
  {
    categoryId: 14,
    categoryName: "Loan Repayment",
    amountEditable: false,
  },
  {
    categoryId: 15,
    categoryName: "LPG Gas",
    amountEditable: false,
  },
  {
    categoryId: 16,
    categoryName: "Mobile Postpaid",
    amountEditable: false,
  },
  {
    categoryId: 17,
    categoryName: "Municipal Services",
    amountEditable: false,
  },
  {
    categoryId: 18,
    categoryName: "Municipal Taxes",
    amountEditable: false,
  },
  {
    categoryId: 19,
    categoryName: "Subscription",
    amountEditable: false,
  },
  {
    categoryId: 20,
    categoryName: "Water",
    amountEditable: false,
  },
  {
    categoryId: 21,
    categoryName: "Club and Accossiation",
    amountEditable: false,
  },
  {
    categoryId: 22,
    categoryName: "Credit Card Bill Payment",
    amountEditable: true,
  },
];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

export const BbpsScreen = ({ discription }) => {
  let basicAuth = JSON.parse(sessionStorage.getItem("basic_auth"));
  const [formErrors, setFormErrors] = useState({});
  const [fetchBillModal, setFetchBillModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [Tpin, setTpin] = useState("");
  const [serviceSelected, setServiceSelected] = useState(5);
  const {
    token,
    myAccountInfo,
    user,
    setTransactionLoaderState,
    checkWalletBeforeBBPSBillPay,
    Profileinfo,
  } = useContext(GlobalContext);
  const [dashboardInfo, setDashboardInfo] = useState({});
  if (!basicAuth) {
    basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  }
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  // const [providers, setProviders] = useState([]);
  const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
  const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [billPayResponse, setBillPayResponse] = useState(null);
  const [PayClicked, setPayClicked] = useState(false);
  const [CustP, setCustP] = useState([]);
  const [billerResponse, setBillerResponse] = useState({
    msg: "",
    success: "",
    error: [],
  });
  const [providers, setProviders] = useState([]);
  const [billFetched, setBillFetched] = useState(false);
  const [tpinShow, setTpinShow] = useState(false);
  // get all operators
  const getAllProviders = async () => {
    setTransactionLoaderState(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5nxn_jDhpPRlBHLmNVLCibYXbTK71aALOCGXbw4fHmGhYSemNPyrCo-W6rHg7K58J-En6cgJpZ_RlVeTHZjRO-FBKIs4zFAbZbeYrgCNKW34Wbm5lDeVV2H497JoGEWo0O4ar5aLanS1NTFH0LmOimscy41s2tPRyPfVtXiPSkgXialxacSHvLEna7HTGsDYgNPejg9kViVhahKHVV_dmsspRhxuUQ8yLOGeamszLCs_o-lMC9Lj3AHZaIyu-vfV4cZpESP9oj4s69Wl0Yc85c6o_NTuttKXDgIJrem_5W1KRitMel6aSEdsmqpEiuNho56pF8iIOkxL6bb9S6CjYK-dhGj14Q8ZZDzCDnxdHOTIU3iCx0i_qitJLvxhH5kSo9kigZlsxkVspvOtSp-NMDLPHeM3pOeeY5rY5pjLlNp2IUT9fKiJR1IRxvOHf4O93Gho2vl6J8QpS_5fsI0c2yN5o_UwssJ57vdkWxtUonmx_mDPb0bO6z48lcGvJiyp-aZx77HCulXFRaiyqJAD5mmjhDMf4KGYyjc-cK4j1VwJRLu_6pCN6YdfrY5B6sjmLnWo5rV4kTHNulkdJKAtekwySG6WI0l9wjyEVZNVmdioWaFXJ4p6gjNecN3lHJsEi7n24V6IXJ8zdm1Rz3jztK"
    );

    var raw = JSON.stringify({
      userid: user.ReferId,
      categoryId: serviceSelected,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/bbps/getAllOnlineOperators`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.data != null) {
        setProviders(
          data.data.map((pro) => {
            return { ...pro, value: pro.billerName, label: pro.billerName };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const walletCheck = () => {
    validateTPin(
      Profileinfo.mobile,
      Tpin,
      () => {
        payBill("");
      },
      () => { }
    );
  };

  const openInNewTab = (data) => {
    const newWindow = window.open(
      data,
      "",
      "height=800,width=1000'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const finalBillPay = (mode) => {
    if (mode == "wallet") {

      checkWalletBeforeBBPSBillPay(
        amount,
        () => {
          walletCheck();
        },
        () => { }
      );
    } else {

      const pgUrl = `https://bumppy.in/api_integration/agent_concern/v1/paymentGateway.php?userid=${user.ReferId}&amount=${Math.ceil(Number(amount))}&mobile=${9168805979}&email=${"test@gmail.com"}&type=T&plan=${1}&callback=https://dashboard.bumppy.com/retailer/topup_request&load_amount=${Math.ceil(Number(amount))}&cust_name=${"Suhas"}&card_no=${5979}&card_cvv=&card_exp_month=&card_exp_year=&route=PAYMENT_WEB&plan=${1}&aadhaar_front=${"https://bumppy.in/api_integration/agent_concern/v1/uploads/aadhaar_docs/img_BR1004_5979_602949137215.png"}&aadhaar_back=${"https://bumppy.in/api_integration/agent_concern/v1/uploads/aadhaar_docs/img_BR1004_5979_602949137215.png"}&aadhaar_no=${"602949137215"}&pan_no=${"KWNPS3415Q"}&agent_name=${Profileinfo?.name}&shop_name=${Profileinfo?.shopname}&agent_mobile=${Profileinfo?.mobile}&agent_email=${Profileinfo?.email}&cust_city=${"Noida"}&pg_pipe=${6}&is_kyc_done=Y`

      openInNewTab(pgUrl);


//       openInNewTab(`https://bumppy.in/pg/bumppypg3.php?userid=${user.ReferId
//         }&amount=${Math.ceil(Number(amount))}&load_amount=${amount
//         }&mobile=${Profileinfo?.mobile
//         }&email=suhassuryavanshi5979@gmail.com&type=T&
// cust_name=${Profileinfo?.shopname
//         }&card_no=&card_cvv=&card_exp_month=&card_exp_year=&callback=https://dashboard.bumppy.com/retailer/topup_request&route=PAYMENT_WEB&plan=${1
//         }`);
    }
  };
  const payBill2 = async () => {
    setBillerResponse({ msg: "", success: "", error: [] });
    const biller = state.provider;
    setTransactionLoaderState(true);
    setPayClicked(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq7E_u804ASEqPvM_yW_G_4eXqt18Wn9I-n8I3WtQtS1k63fuzYkZ3II2cGaUfAh06-OButEn8YXvZ_OKTOUg7gP0eyR30BQ_8eI347G7RyBVkL94bbcqn4rlloSevdhRK_itdZf7Cy1pikoJc95VBZtpNVRo8GfCCremQfVWnDGdC-uhQO4_iWArZNQ52pD6yFCxKrKQMZoDpLPBgU_RqwND6lpbx4PqGA89-SxvMRcqNeUppHj8jYHVcp1CnK1n1ncVhEtsR0luOqY1IHgz_J-pRI-lUsGJUDsgFNCww0eb7Mlk8LWwiu4qgYXpPYLlBZf0K7b2mTMgNpH25rMS4-VahYcpxG4zXe1vKHCEHeR7MjVXiAwNh_mdR2GXlgtM9FZ3bOSHjwCYuB6J6uzOd4gNgQcUWzkM1TJ_Fcq04jq6pdB2Ne-aN8XkGNAxnrVQ4SPVwVh-NkRSZYXpdS0XG2r658waXxYLVif7Sw40A-M3V1tLMqWwNSu2rMg5NFhDevaKKiJXcuqHXdrjRSOWLNgkPHS5lmjY11bV0QenW9H7Ahv3xbre32MmYrHlkX7HxKXtlS-uTURS1gQT0_bwPBt3webYhgPJMO8wuIP1HCTlsD98IkmuLeiQ5sSuaylpGJuo22sseXh04qbA3aj_8uo"
    );

    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });

    var raw = JSON.stringify({
      agent_id: user.ReferId,
      amount: amount,
      mobileNo: user.Mobile,
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
      refId: billDetails?.refId,
      paymode: "Cash",
      isDirectPay: state.provider.fetchRequirement != "MANDATORY",
      agent_id: "1002",
      retailer_id: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillPay`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "FAILURE") {
        swal(
          "FAILURE",
          data.response.complianceReason == null
            ? "Error while processing your transaction, please try again"
            : data.response.complianceReason,
          "error"
        );
      } else {
        setBillPayResponse(data.response);
        swal("Success", "Successfull", "success");
        setCustP([]);
        setAmount("");
      }

      setPayClicked(false);
      setTransactionLoaderState(false);
      // cogoToast.info(data.status);
    } catch (error) {
      setPayClicked(false);
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const payBill3 = async () => {
    setBillerResponse({ msg: "", success: "", error: [] });
    const biller = state.provider;
    setPayClicked(true);
    const hash = generateHashKey(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash);
    myHeaders.append("Content-Type", "application/json");

    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });

    var raw = JSON.stringify({
      agent_id: user.ReferId,
      amount: amount,
      mobileNo: user.Mobile,
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
      refId: billDetails?.refId,
      paymode: "Cash",
      isDirectPay: state.provider.fetchRequirement != "MANDATORY",
      agent_id: "1002",
      retailer_id: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      // const res = await fetch(
      //   `${BASE_URL_PAYNOW}/api/Biller/BillPayOffline`,
      //   requestOptions
      // );
      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/services/bbps/BillPayOnline.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "FAILURE") {
        swal(
          "FAILURE",
          data.response.complianceReason == null
            ? "Error while processing your transaction, please try again"
            : data.response.complianceReason,
          "error"
        );
      } else {
        setBillPayResponse(data.response);
        swal("Success", "Successfull", "success");
        setCustP([]);
        setAmount("");
      }

      setPayClicked(false);
      setTransactionLoaderState(false);
      // cogoToast.info(data.status);
    } catch (error) {
      setPayClicked(false);
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const payBill = async () => {
    try {
      setTransactionLoaderState(true);
      setBillerResponse({ msg: "", success: "", error: [] });
      const biller = state.provider;
      setPayClicked(true);
      const coords = await getLatLong();
      const hash = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString("base64")
      );

      let params = [];

      CustP.forEach((param) => {
        if (param.value && param.value != "") {
          params.push({
            name: param.paramName,
            value: param.value,
          });
        }
      });

      var raw = JSON.stringify({
        agent_id: user.ReferId,
        userid: user.ReferId,
        amount: amount,
        mobileNo: user.Mobile,
        billDetails: {
          billerId: biller.billerId,
          customerParams: params,
        },
        refId: billDetails?.refId,
        paymode: "Cash",
        "splitPay": "No",
        "offusPay": "Yes",
        "payMode": "Cash",
        isDirectPay: state.provider.fetchRequirement != "MANDATORY",
        agent_id: user.ReferId,
        retailer_id: user.ReferId,

        agent_devices: [
          {
            "name": "INITIATING_CHANNEL",
            "value": "BSC"
          },
          {
            "name": "POSTAL_CODE",
            "value": "201301"
          },
          {
            "name": "TERMINAL_ID",
            "value": "123456"
          },
          {
            "name": "MOBILE",
            "value": user.Mobile
          },
          {
            "name": "GEOCODE",
            "value": "12.9667,77.5667"
          }
        ]
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/BBPS/billPayOnlineWithAgentId`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "SUCCESS") {
        swal("Success", data.responseMdg, "success");
      } else {
        let errorMessage = "Something went wrong";
        if (data.response) {
          if (data.response.complianceReason) {
            errorMessage = data.response.complianceReason;
          }
        } else if (data.responseMdg) {
          errorMessage = data.responseMdg;
        }
        swal("Failed", errorMessage, "error");
      }
      setTransactionLoaderState(false);
      clearForm();
      setFetchBillModal(false);
    } catch (error) {
      setTransactionLoaderState(false);
      let errorMessage = "Error From Server";
      swal("Failed", errorMessage, "error");
    }
  };

  const fetchBill2 = async () => {
    setBillerResponse({ msg: "", success: "", error: [] });
    setTransactionLoaderState(true);
    const biller = state.provider;
    if (biller.length == 0) {
      return;
    }
    setFetchButtonDisable(true);
    setBillDetails(null);
    setBillPayResponse(null);
    setFetchButtonText("Fetching bill ...");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A__uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn_-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
    );

    console.log(biller);
    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });

    var raw = JSON.stringify({
      customerMobileNo: user.Mobile,
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Biller/BillFetch`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "FAILURE") {
        swal("FAILURE", data.response.complianceReason, "error");
      } else {
        // swal("Success", "Successfull", "success");
      }
      if (
        data.respCode == "1" &&
        data.response &&
        data.response.billerResponse &&
        data.response.billerResponse.customerName &&
        data.response.billerResponse.customerName != ""
      ) {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "",
        });
        setFetchBillModal(true);
        setBillDetails(data.response);
        setAmount(data.response.billerResponse.amount);
      } else if (
        data.respCode == "0" &&
        data.response &&
        data.response.errorList &&
        data.response.errorList.length > 0
      ) {
        setBillerResponse({
          ...billerResponse,
          error: data.response.errorList,
          msg: "",
        });
      } else {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "Something went wrong",
        });
      }
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    }
  };

  const fetchBill = async () => {
    setBillerResponse({ msg: "", success: "", error: [] });
    setTransactionLoaderState(true);
    const biller = state.provider;
    if (biller.length == 0) {
      return;
    }
    setFetchButtonDisable(true);
    setBillDetails(null);
    setBillPayResponse(null);
    setFetchButtonText("Fetching bill ...");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from(username + ":" + password).toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A__uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn_-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
    );

    console.log(biller);
    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });

    var raw = JSON.stringify({
      userid: user.ReferId,
      customerMobileNo: user.Mobile,
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `https://apiservices.bumppypay.com/api/BBPS/billFetchOnline`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "FAILURE") {
        swal("FAILURE", data.response.complianceReason, "error");
      } else {
        // swal("Success", "Successfull", "success");
      }
      if (
        data.respCode == "1" &&
        data.response &&
        data.response.billerResponse &&
        data.response.billerResponse.customerName &&
        data.response.billerResponse.customerName != ""
      ) {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "",
        });
        setFetchBillModal(true);
        setBillDetails(data.response);
        setAmount(data.response.billerResponse.amount);
      } else if (
        data.respCode == "0" &&
        data.response &&
        data.response.errorList &&
        data.response.errorList.length > 0
      ) {
        setBillerResponse({
          ...billerResponse,
          error: data.response.errorList,
          msg: "",
        });
      } else {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "Something went wrong",
        });
      }
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    }
  };

  const fetchBillWithAgentId = async () => {
    setBillerResponse({ msg: "", success: "", error: [] });
    setTransactionLoaderState(true);
    const biller = state.provider;
    if (biller.length == 0) {
      return;
    }
    setFetchButtonDisable(true);
    setBillDetails(null);
    setBillPayResponse(null);
    setFetchButtonText("Fetching bill ...");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic " + Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString("base64")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A__uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn_-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
    );

    console.log(biller);
    let params = [];
    CustP.forEach((param) => {
      if (param.value && param.value != "") {
        params.push({
          name: param.paramName,
          value: param.value,
        });
      }
    });

    var raw = JSON.stringify({
      userid: user.ReferId,
      customerMobileNo: user.Mobile,
      billDetails: {
        billerId: biller.billerId,
        customerParams: params,
      },
      agent_devices: [
        {
          "name": "INITIATING_CHANNEL",
          "value": "BSC"
        },
        {
          "name": "POSTAL_CODE",
          "value": "201301"
        },
        {
          "name": "TERMINAL_ID",
          "value": "123456"
        },
        {
          "name": "MOBILE",
          "value": user.Mobile
        },
        {
          "name": "GEOCODE",
          "value": "12.9667,77.5667"
        }
      ]
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        `https://apiservices.bumppypay.com/api/BBPS/billFetchOnlineWithAgentId`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "FAILURE") {
        swal("FAILURE", data.response.complianceReason, "error");
      } else {
        // swal("Success", "Successfull", "success");
      }
      if (
        data.respCode == "1" &&
        data.response &&
        data.response.billerResponse &&
        data.response.billerResponse.customerName &&
        data.response.billerResponse.customerName != ""
      ) {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "",
        });
        setFetchBillModal(true);
        setBillDetails(data.response);
        setAmount(data.response.billerResponse.amount);
      } else if (
        data.respCode == "0" &&
        data.response &&
        data.response.errorList &&
        data.response.errorList.length > 0
      ) {
        setBillerResponse({
          ...billerResponse,
          error: data.response.errorList,
          msg: "",
        });
      } else {
        setBillerResponse({
          ...billerResponse,
          error: [],
          msg: "Something went wrong",
        });
      }
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setFetchButtonDisable(false);
      setFetchButtonText("Fetch bill");
    }
  };

  const validate = (callback) => {
    let errors = {};
    CustP.forEach((param) => {
      if (param.optional != "true") {
        if (param.minLength && param.value.length < Number(param.minLength)) {
          errors[
            param.paramName
          ] = `${param.paramName} atleast ${param.minLength} long`;
        } else if (
          param.maxLength &&
          param.value.length > Number(param.maxLength)
        ) {
          errors[
            param.paramName
          ] = `${param.paramName} atmost ${param.maxLength} long`;
        } else if (!regexValidation(param.value, param.regex)) {
          errors[param.paramName] = `Please enter valid ${param.paramName}`;
        }
      }
    });
    if (
      state.provider.fetchRequirement !== "MANDATORY" &&
      (amount.toString().trim() == "" || Number(amount) == 0)
    ) {
      errors.directPayAmount = "Please Enter Valid Amount";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      callback();
    } else {
      setFormErrors(errors);
    }
  };

  useEffect(() => {
    if (state.provider) {
      setCustP(
        state.provider.customerParams.map((param, i) => {
          return { ...param, id: i, value: "" };
        })
      );
    }
    // console.log(CustP);
  }, [state]);

  const clearForm = () => {
    setState({ ...state, provider: "" });
    setBillDetails(null);
    setCustP([]);
    setAmount("");
    setBillerResponse({ msg: "", success: "", error: [] });
    //  getAllProviders();
    setBillPayResponse(null);
    setTpin("");
  };

  useEffect(() => {
    setState({ ...state, provider: "" });
    setBillDetails(null);
    setCustP([]);
    setAmount("");
    setBillerResponse({ msg: "", success: "", error: [] });
    getAllProviders();
    setBillPayResponse(null);
  }, [serviceSelected]);
  console.log(amount);


  return (
    <div className="bg-white-theme">
      <FetchBill
        fetchBillModal={fetchBillModal}
        setFetchBillModal={setFetchBillModal}
        billDetails={billDetails}
        billerResponse={billerResponse}
        callback={(mode) => {
          finalBillPay(mode);
        }}
        billPayResponse={billPayResponse}
        setAmount={setAmount}
        amount={amount}
        setTpin={setTpin}
        Tpin={Tpin}
        config={categoryInfo[serviceSelected - 1]}
      />
      <div
        style={{
          background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
          borderRadius: "1rem",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="m-3 rouded-3 p-4"
      >
        <div className="row">
          <h3 className="text-dark">Bill Pay And Recharge</h3>
        </div>
        <div
          className="row rouded-3 justify-content-start m-0 rounded-3"
          style={{ background: "#EAF0F7" }}
        >
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(5);
            }}
            image={Electricity}
            title={"Electricity"}
            selected={serviceSelected == 5}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(16);
            }}
            image={PrepaidRecharge}
            title={"Postpaid Bill Pay"}
            selected={serviceSelected == 16}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(3);
            }}
            image={Dth}
            title={"DTH"}
            selected={serviceSelected == 3}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(2);
            }}
            image={Cable}
            title={"Cable Recharge"}
            selected={serviceSelected == 2}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(20);
            }}
            image={WaterBill}
            title={"Water Bill"}
            selected={serviceSelected == 20}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(7);
            }}
            image={PipedGas}
            title={"Piped Gas"}
            selected={serviceSelected == 7}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(1);
            }}
            image={Broadband}
            title={"Broadband"}
            selected={serviceSelected == 1}
          />
          {/* <BBPSIconCard
                onClick={() => {
                  setServiceSelected(16);
                }}
                image={BusTicketIcon}
                title={"Bus Ticket"}
                selected={serviceSelected == 16}
              /> */}
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(4);
            }}
            image={EducationIcon}
            title={"Education"}
            selected={serviceSelected == 4}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(6);
            }}
            image={FastagIcon}
            title={"Fastag Recharge"}
            selected={serviceSelected == 6}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(15);
            }}
            image={GasCylinderIcon}
            title={"Gas Cylinder"}
            selected={serviceSelected == 15}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(9);
            }}
            image={HospitalIcon}
            title={"Hospital"}
            selected={serviceSelected == 9}
          />
 
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(10);
            }}
            image={HousingSocietyIcon}
            title={"Housing Society"}
            selected={serviceSelected == 10}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(11);
            }}
            image={InsuranceIcon}
            title={"Insurance"}
            selected={serviceSelected == 11}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(12);
            }}
            image={LandlineIcon}
            title={"Landline"}
            selected={serviceSelected == 12}
          />
          <BBPSIconCard
            onClick={() => {
              setServiceSelected(18);
            }}
            image={MuncipalTaxIcon}
            title={"Municipal Tax"}
            selected={serviceSelected == 18}
          />

          <BBPSIconCard
            onClick={() => {
              setServiceSelected(22);
            }}
            image={CreditCardIcon}
            title={"Credit Card Bill Payment"}
            selected={serviceSelected == 22}
          />

          <BBPSIconCard
            onClick={() => {
              setServiceSelected(14);
            }}
            image={CreditCardIcon}
            title={"Loan Repayment"}
            selected={serviceSelected == 14}
          />
        </div>

        <div
          className="row rouded-3 p-4 mx-0 my-2"
          style={{ background: "#FFFFFF", borderRadius: "1rem" }}
        >
          <Form.Group className="col-6 p-1 m-0 px-0">
            <Form.Label>{`Select ${categoryInfo[serviceSelected - 1].categoryName
              } Provider`}</Form.Label>
            <Select
              options={providers}
              value={state.provider}
              placeholder={`Select Provider`}
              onChange={(e) => {
                setState({
                  ...state,
                  provider: e,
                });
              }}
            />
          </Form.Group>
          {CustP.map((cust, i) => (
            <Form.Group key={cust.id} className="col-6 p-1 m-0 px-0">
              {cust.optional == "true" ? (
                <Form.Label>{`${cust.paramName} (optional)`}</Form.Label>
              ) : (
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>
                  {` ${cust.paramName}`}
                </Form.Label>
              )}
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter ${cust.paramName}`}
                onChange={(e) => {
                  setBillerResponse({
                    ...billerResponse,
                    error: [],
                    msg: "",
                  });
                  const copy = [...CustP];
                  copy.forEach((ele) => {
                    if (ele.id == cust.id) {
                      ele.value = e.target.value;
                    }
                  });
                  setCustP(copy);
                }}
                value={cust.value}
              />
              <p className="text-danger text-left p-0">
                {formErrors[cust.paramName]}
              </p>
            </Form.Group>
          ))}

          <div className="col-12 p-0 mr-2 ml-1 ">
            {state.provider &&
              (state.provider.fetchRequirement === "MANDATORY" ? (
                <React.Fragment>
                  <button
                    className="btn btn-success mt-4"
                    onClick={() => {
                      validate(fetchBillWithAgentId);
                    }}
                  >
                    {" "}
                    Fetch Bill
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Form.Group className="col-6 p-1 m-0 px-0">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {` Amount`}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder={`Enter Amount`}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                    <p className="text-danger text-left p-0">
                      {formErrors.directPayAmount}
                    </p>
                  </Form.Group>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      validate(payBill);
                    }}
                  >
                    {" "}
                    Pay Bill
                  </button>
                </React.Fragment>
              ))}
          </div>

          {billerResponse.msg && <BillerResponse msg={billerResponse.msg} />}
          {billerResponse.error.length > 0 &&
            billerResponse.error.map((err, key) => (
              <BillerResponse msg={err.errorDtl} />
            ))}
        </div>
      </div>

      {discription && (
        <div
          style={{
            background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
            borderRadius: "1rem",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
          className="m-3 rouded-3 p-4"
        >
          <div className="row">
            <div className="col-12 col-md-7">
              <h4>Bharat Bill Payment System &nbsp;( &nbsp;BBPS &nbsp;)</h4>
              <span>
                It is an Integrated online platform which is being developed by
                the National Payment corporation of India for all kind of Bill
                Payments
              </span>
              <div className="row">
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(5);
                  }}
                  image={Electricity_2}
                  title={"Electricity"}
                  selected={serviceSelected == 5}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(16);
                  }}
                  image={PrepaidRecharge_2}
                  title={"Prepaid Recharge"}
                  selected={serviceSelected == 16}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(2);
                  }}
                  image={Dth_2}
                  title={"DTH"}
                  selected={serviceSelected == 2}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(3);
                  }}
                  image={Cable_2}
                  title={"Cable Recharge"}
                  selected={serviceSelected == 3}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(20);
                  }}
                  image={WaterBill_2}
                  title={"Water Bill"}
                  selected={serviceSelected == 20}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(7);
                  }}
                  image={PipedGas_2}
                  title={"Piped Gas"}
                  selected={serviceSelected == 7}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(1);
                  }}
                  image={Broadband_2}
                  title={"Broadband"}
                  selected={serviceSelected == 1}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(4);
                  }}
                  image={EducationIcon_2}
                  title={"Education"}
                  selected={serviceSelected == 4}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(6);
                  }}
                  image={FastagIcon_2}
                  title={"Fastag Recharge"}
                  selected={serviceSelected == 6}
                />
                <BBPSServiceCard
                  onClick={() => {
                    setServiceSelected(20);
                  }}
                  image={GasCylinderIcon_2}
                  title={"Gas Cylinder"}
                  selected={serviceSelected == 20}
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <img
                src={require("../../../assets/images/dashboard/Group126@2x.png")}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BBPSIconCard = ({ image, title, selected, onClick }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
      onClick={onClick}
    >
      <div
        style={{
          borderRadius: "3rem",
          border: selected ? "2px solid #3C79F5" : "2px solid #FFF",
          background: "#FFF",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{ height: "2rem", width: "2rem", color: "#EC5665" }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
          fontWeight: selected ? "700" : "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

const BBPSServiceCard = ({ image, title }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
    >
      <div
        style={{
          borderRadius: "3rem",
          background: "#C42F47",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{
            height: "2rem",
            width: "2rem",
            color: "#EC5665",
            fill: "#FFF",
            stroke: "#FFF",
          }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

function FetchBill({
  setFetchBillModal,
  fetchBillModal,
  payBill,
  billDetails,
  billerResponse,
  callback,
  billPayResponse,
  setAmount,
  amount,
  setTpin,
  Tpin,
  config,
}) {
  const { user } = useContext(GlobalContext);
  const [tpinShow, setTpinShow] = useState(false);
  const [PayClicked, setPayClicked] = useState(false);
  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify({ ...data, paid: false }));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  const [selectedOption, setSelectedOption] = useState('wallet');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };



  return (
    <div>
      <Modal
        show={fetchBillModal}
        onHide={() => setFetchBillModal(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Bill Details</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ width: "100%", display: "block" }}
          className="account m-0 p-0"
        >
          {billDetails && !billPayResponse && (
            <Card style={{ margin: "10px", padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={require("../../../assets/images/BBPSSidebarLogo.png")}
                  style={{
                    width: "auto",
                    height: "1.2rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <img
                  onClick={() => {
                    openInNewTab(billDetails);
                  }}
                  style={{ height: "1.5rem" }}
                  src={require("../../../assets/icons/print_1.png")}
                />
              </div>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {amount}
                  </td>
                </tr>

                {/* Additional Info */}
                {billDetails.additionalInfo &&
                  billDetails.additionalInfo.length > 0 &&
                  billDetails.additionalInfo.map((info) => (
                    <tr style={{ background: "none" }}>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.name}
                      </td>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.value}
                      </td>
                    </tr>
                  ))}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill date
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billDate}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Due Date.
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.dueDate}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.refId}
                  </td>
                </tr>

                {config.amountEditable && (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      Amount
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      <Form.Control
                        type="number"
                        className="form-control rounded-0"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder={`Enter Amount`}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                        value={amount}
                      />
                    </td>
                  </tr>
                )}

                {user.ReferId == "BR1004" ? (<tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <label style={{ fontSize: "1.2rem" }}>
                      <div className="mr-1"></div>
                      <input
                        type="radio"
                        value="wallet"
                        checked={selectedOption === 'wallet'}
                        onChange={handleOptionChange}
                      />
                      Pay via Wallet
                    </label>

                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <label style={{ fontSize: "1.2rem" }}>
                      <div className="mr-1"></div>
                      <input
                        type="radio"
                        value="pg1"
                        checked={selectedOption === 'pg1'}
                        onChange={handleOptionChange}
                      />
                      Pay via PG1
                    </label>
                  </td>
                </tr>
                ) : null}


                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    T Pin
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <div className="input-group">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent border-right-0 no-glow">
                          <i className="mdi mdi-lock-outline text-primary"></i>
                        </span>
                      </div>
                      <input
                        autocomplete="new-password"
                        autoCorrect="off"
                        autoSave="new-password"
                        type={tpinShow ? "text" : "password"}
                        className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                        id="exampleInputPassword"
                        placeholder={`Enter T Pin`}
                        onChange={(e) => {
                          setTpin(e.target.value);
                        }}
                        value={Tpin}
                      />
                      <div className="input-group-prepend bg-transparent">
                        <span
                          className="input-group-text bg-transparent border-left-0 no-glow"
                          onClick={showTPinClick}
                          style={{ borderRadius: "4px" }}
                        >
                          <i
                            className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                              }`}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {PayClicked ? (
                  <Button className="rounded-0" variant="success">
                    {" "}
                    Paying ...
                  </Button>
                ) : (
                  <Button
                    className="rounded-0"
                    variant="success"
                    onClick={() => {
                      callback(selectedOption)
                    }}
                  >
                    {" "}
                    Pay Bill
                  </Button>
                )}
              </div>
            </Card>
          )}
          {billDetails && billPayResponse && (
            <Card style={{ margin: "10px", padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={require("../../../assets/images/BBPSSidebarLogo.png")}
                  style={{
                    width: "auto",
                    height: "1.2rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <img
                  onClick={() => {
                    openInNewTab(billDetails);
                  }}
                  style={{ height: "1.5rem" }}
                  src={require("../../../assets/icons/print_1.png")}
                />
              </div>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>

                {billDetails.billerResponse.billNumber && (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      Bill Number
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      {billDetails.billerResponse.billNumber}
                    </td>
                  </tr>
                )}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {amount}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billPayResponse.refId}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    Transaction Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billPayResponse.txnReferenceId}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Status
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <span className="badge badge-pill badge-success p-3">
                      {billPayResponse.responseReason}
                    </span>
                  </td>
                </tr>
              </table>
            </Card>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

function FetchBillSave({
  setFetchBillModal,
  fetchBillModal,
  payBill,
  billDetails,
  billerResponse,
  callback,
  billPayResponse,
  setAmount,
  amount,
  setTpin,
  Tpin,
  config,
}) {
  const [tpinShow, setTpinShow] = useState(false);
  const [PayClicked, setPayClicked] = useState(false);
  const openInNewTab = (data) => {
    localStorage.setItem("print", JSON.stringify({ ...data, paid: false }));
    const CLIENT = "";
    const newWindow = window.open(
      `${CLIENT}/pages/bbps/print`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  console.log(config);

  return (
    <div>
      <Modal
        show={fetchBillModal}
        onHide={() => setFetchBillModal(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Bill Details</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ width: "100%", display: "block" }}
          className="account m-0 p-0"
        >
          {billDetails && !billPayResponse && (
            <Card style={{ margin: "10px", padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={require("../../../assets/images/BBPSSidebarLogo.png")}
                  style={{
                    width: "auto",
                    height: "1.2rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <img
                  onClick={() => {
                    openInNewTab(billDetails);
                  }}
                  style={{ height: "1.5rem" }}
                  src={require("../../../assets/icons/print_1.png")}
                />
              </div>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill Number
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billNumber}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {amount}
                  </td>
                </tr>

                {/* Additional Info */}
                {billDetails.additionalInfo &&
                  billDetails.additionalInfo.length > 0 &&
                  billDetails.additionalInfo.map((info) => (
                    <tr style={{ background: "none" }}>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.name}
                      </td>
                      <td
                        style={{
                          paddingLeft: "1rem",
                          border: "none",
                          background: "none",
                          width: "50%",
                          fontSize: "0.8rem",
                        }}
                      >
                        {info.value}
                      </td>
                    </tr>
                  ))}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bill date
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.billDate}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Due Date.
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.dueDate}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.refId}
                  </td>
                </tr>

                {config.amountEditable && (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      Amount
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      <Form.Control
                        type="number"
                        className="form-control rounded-0"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder={`Enter Amount`}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                        value={amount}
                      />
                    </td>
                  </tr>
                )}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    T Pin
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <div className="input-group">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent border-right-0 no-glow">
                          <i className="mdi mdi-lock-outline text-primary"></i>
                        </span>
                      </div>
                      <input
                        autocomplete="new-password"
                        autoCorrect="off"
                        autoSave="new-password"
                        type={tpinShow ? "text" : "password"}
                        className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                        id="exampleInputPassword"
                        placeholder={`Enter T Pin`}
                        onChange={(e) => {
                          setTpin(e.target.value);
                        }}
                        value={Tpin}
                      />
                      <div className="input-group-prepend bg-transparent">
                        <span
                          className="input-group-text bg-transparent border-left-0 no-glow"
                          onClick={showTPinClick}
                          style={{ borderRadius: "4px" }}
                        >
                          <i
                            className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                              }`}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {PayClicked ? (
                  <Button className="rounded-0" variant="success">
                    {" "}
                    Paying ...
                  </Button>
                ) : (
                  <Button
                    className="rounded-0"
                    variant="success"
                    onClick={callback}
                  >
                    {" "}
                    Pay Bill
                  </Button>
                )}
              </div>
            </Card>
          )}
          {billDetails && billPayResponse && (
            <Card style={{ margin: "10px", padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={require("../../../assets/images/BBPSSidebarLogo.png")}
                  style={{
                    width: "auto",
                    height: "1.2rem",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <img
                  onClick={() => {
                    openInNewTab(billDetails);
                  }}
                  style={{ height: "1.5rem" }}
                  src={require("../../../assets/icons/print_1.png")}
                />
              </div>
              <table style={{ border: "none", fontFamily: "-webkit-body" }}>
                <tr style={{ background: "none", border: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Name
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billDetails.billerResponse.customerName}
                  </td>
                </tr>

                {billDetails.billerResponse.billNumber && (
                  <tr style={{ background: "none" }}>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      Bill Number
                    </td>
                    <td
                      style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: "50%",
                        fontSize: "0.8rem",
                      }}
                    >
                      {" "}
                      {billDetails.billerResponse.billNumber}
                    </td>
                  </tr>
                )}

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Amount
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {amount}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billPayResponse.refId}
                  </td>
                </tr>
                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    Transaction Reference Id
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    {billPayResponse.txnReferenceId}
                  </td>
                </tr>

                <tr style={{ background: "none" }}>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Status
                  </td>
                  <td
                    style={{
                      paddingLeft: "1rem",
                      border: "none",
                      background: "none",
                      width: "50%",
                      fontSize: "0.8rem",
                    }}
                  >
                    <span className="badge badge-pill badge-success p-3">
                      {billPayResponse.responseReason}
                    </span>
                  </td>
                </tr>
              </table>
            </Card>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default BbpsScreen;
