import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "../custom-hooks/useQuery";

const CmsRedirect2 = () => {

    const query = useQuery();
    let userId = query.get("id") ? query.get("id") : "1";

  const openInNewTab = (data) => {
    const newWindow = window.open(data, "", "height=800,width=1000'");
    if (newWindow) newWindow.opener = null;
  };

  const getAccessToken = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " +
          Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString(
            "base64"
          )
      );
      var raw = JSON.stringify({
        userid: userId,
        device: "WEB",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/CMS/Bankit/Main/generateUrl",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        openInNewTab(data.data.cmsUrl);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-4">
        <div
          style={{
            width: "100%",
            background: "white",
          }}
        >
          <div className="row">
            <div className="col-7 p-md-5 p-sm-4">
              {" "}
              <h4 className="d-flex align-items-center">CMS Service</h4>
              <p
                className="d-flex align-items-center"
                style={{ color: "black", fontSize: "1rem" }}
              >
                Cash Management Services (CMS) Airtel Bank's Cash Management
                Services offers a full range of products and services to
                efficiently process your receivables and payables. We cater to
                all your cash management requirements to optimise your cash flow
                position and to facilitate effective management of your business
                operation.
              </p>
              <button
                className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                // type="submit"
                style={{
                  borderRadius: ".2rem",
                  fontWeight: "bold",
                  width: "10rem",
                  border: "none",
                  color: "#ffff",
                  fontSize: "1rem",
                  height: "2em",
                  display: "flex",
                }}
                onClick={() => {
                  getAccessToken();
                }}
              >
                {" "}
                Open CMS
              </button>
            </div>
          </div>
          <div className="card m-md-4 m-sm-3 p-md-3 p-sm-2">
            <h5>Information</h5>
            <p>
              Cash Management Services (CMS) Airtel Bank's Cash Management
              Services offers a full range of products and services to
              efficiently process your receivables and payables. We cater to all
              your cash management requirements to optimise your cash flow
              position and to facilitate effective management of your business
              operation. Benefits of Cash Management Services Leverage of vast
              network of locations within India Wide range of CMS products
              Efficient logistics support and advanced technology support
              Ensuring timely deposit of collections and timely execution of
              disbursement requests
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CmsRedirect2;
