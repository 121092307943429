import React, { useState, useEffect, useContext } from "react";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { Button, Form } from "react-bootstrap";
import { Row, Col, Modal, Tabs, Tab, Container, Nav } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import { TextArea } from "semantic-ui-react";
//import { IssueModal } from "../AEPS/HelpAndSupport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../Topup View/TopupView.css";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import {
  formatDateDDMMYYHHMM,
  generateHashKey2,
  uniqueID,
} from "../../utils/utilFuctions";
export const columns = [
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
  {
    dataField: "rch_operator_id",
    text: "Operator ",
  },
  {
    dataField: "Name",
    text: "Mobile ",
  },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "tid",
    text: "UTR NO",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "rch_com_retailer",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  
];

const tdstyle = {
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const tdstyle2 = {
  width: "10%",
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const thstyle = {
  color: "dark",
  backgroundColor: "white",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const RechargeReportTable = () => {
  const [IssueModal, setIssueModal] = useState(false);
  const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
  const [data, setData] = useState([]);
  const { user } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  const [showHelpFormmodal, setShowHelpFormmodal] = useState(false);
  const hideHelpFormmodal = () => setShowHelpFormmodal(false);
  console.log(data);

  const { setTransactionLoaderState } = useContext(GlobalContext);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/recharge_print?id=${data.tid}&type=Recharge`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const getTicketList = async () => {
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true)
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getticketlist&userid=${user.ReferId}`,
        requestOptions
      );
      const data = await res.json();
      setTransactionLoaderState(false)
      //setDataToDisplay(data);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false)
    }
  };
  const openModal = (url) => {
    setIssueModal(true);
  };

  useEffect(() => {
    getTicketList();
  }, []);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getRechargeReport = () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: user.ReferId,
        mobile: "",
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        report_type: "REC",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_bbps_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (Array.isArray(result)) setData(result);
          setTransactionLoaderState(false)
        })
        .catch((error) => console.log("error", error));
 
    } catch (error) { setTransactionLoaderState(false)}
  };
  const raiseTicketShow = (data) => {
    setRaiseTicketDetails({
      tid: data.tid,
      category: "Recharge",
      user_id: user.ReferId,
    });
    //setIssueModal(true);
  };
  useEffect(() => {
    getRechargeReport();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const positionStyle1 = {
    position: "absolute",
    top: -43,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle2 = {
    position: "absolute",
    top: -44,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  return (
    <>
      {/* <IssueModal
         details={raiseTicketDetails}
          isModalOpen={IssueModal}
          setModalOpen={(modalState) => {
            setIssueModal(modalState);
          }}
          refresh={() => {}}
        /> */}

      <div className="row m-0 p-0 pt-1 align-items-top">
        <div className="col-3 m-0 p-0">
          <div
            className="m-0 p-0 pl-3 align-top"
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
            Recharge Report
          </div>
        </div>

        <div className="col-9 m-0 p-0 ">
          <div className="row  m-0 p-0  justify-content-end">
            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div
              className="pr-1 pl-2 p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div
              className="px-1  p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>

            <div
              className="pl-1   p-0 m-0"
              style={{ width: "14%", height: "20%" }}
            >
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-50 btn btn-primary btn-lg btn-block "
                  onClick={getRechargeReport}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>

            <div
              className="m-0 p-0  hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `Recharge_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1 p-0"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div style={positionStyle1} className="hover-text bg-dark ">
                Export to Excel
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="m-0 p-0" />

      <div className=" scroll-inner admin-fix-width transparent-table">
        <table className=" table table-borderless text-dark ">
          <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
            <th
              className=" transparent-table text-center border-bottom  text-dark"
              style={thstyle}
            >
              {" "}
              {"Sr"}
            </th>
            {columns.map((heading) => (
              <th
                className=" transparent-table text-center border-bottom  text-dark"
                style={thstyle}
              >
                {heading.text}
              </th>
            ))}
            <th
              className=" transparent-table text-center border-bottom  text-dark"
              style={thstyle}
            >
              {" "}
              {"Action"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className=" transparent-table border-bottom ">
              <td className="align-middle" style={tdstyle}>
                {" "}
                {ind + 1}
              </td>
              {columns.map((cell) =>
                cell.text == "Status" ? (
                  e[cell.dataField] == "success" ||
                  e[cell.dataField] == "Success" ? (
                    <td className="align-middle" style={tdstyle}>
                      {" "}
                      <div className="d-flex flex-column align-items-center  m-0">
                        <span
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px solid",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            bordercolor: "rgba(43, 193, 85, 1)",
                          }}
                          className="p-0 px-2 py-1"
                        >
                          {"Success"}
                        </span>
                      </div>{" "}
                    </td>
                  ) : e[cell.dataField] == "fail" ||
                    e[cell.dataField] == "Fail" ? (
                    <td className="align-middle" style={tdstyle}>
                      {" "}
                      <div className="d-flex flex-column align-items-center  m-0">
                        <span
                          style={{
                            width: "100%",
                            // padding:"8px",
                            // paddingLeft:"23px",
                            // paddingRight:"23px",
                            borderRadius: "5px",
                            border: "1px solid",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                            color: " rgba(255, 0, 0, 1)",
                            bordercolor: " rgba(255, 0, 0, 1)",
                          }}
                          className="p-0 px-2 py-1"
                        >
                          {e[cell.dataField]}
                        </span>
                      </div>{" "}
                    </td>
                  ) : (
                    <td className="align-middle" style={tdstyle}>
                      <div className="d-flex flex-column align-items-center  m-0">
                        <span
                          style={{
                            width: "100%",

                            borderRadius: "5px",
                            border: "1px solid",
                            backgroundColor: "rgba(254, 128, 36, 0.1)",
                            color: "rgba(254, 128, 36, 1)",
                            bordercolor: "rgba(254, 128, 36, 1)",
                          }}
                          className="p-0 px-2 py-1"
                        >
                          {e[cell.dataField]}
                        </span>
                      </div>
                    </td>
                  )
                ) : (
                  <td className="align-middle" style={tdstyle}>
                    {e[cell.dataField]}
                  </td>
                )
              )}
              <td style={{ width: "12%" }} className="align-middle">
                <div className="d-flex row  align-items-center justify-content-center p-0 m-0">
                  {" "}
                  <Button
                    style={{
                      paddingLeft: "3px",
                      padding: "5px",
                      borderRadius: "0px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="  p-1 "
                    onClick={() => {
                      openInNewTab(e);
                    }}
                  >
                    {" "}
                    <div className="hover-container ">
                      {/* Your content here */}
                      <img
                        src={require("../../../assets/images/printer.png")}
                        style={{
                          width: `100%`,
                          height: "100%",
                          borderRadius: "0px",
                        }}
                      />
                      <div
                        style={positionStyle2}
                        className="hover-text bg-dark "
                      >
                        <div> Print Slip </div>
                      </div>
                    </div>
                  </Button>
                  <Button
                    style={{
                      paddingLeft: "3px",
                      padding: "5px",
                      borderRadius: "0px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="p-1"
                    onClick={() => setShowHelpFormmodal(true)}
                  >
                    {" "}
                    <div className="hover-container ">
                      {/* Your content here */}
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{
                          width: `100%`,
                          height: "100%",
                          borderRadius: "0px",
                        }}
                      />
                      <div
                        style={positionStyle2}
                        className="hover-text bg-dark "
                      >
                        <div>Raise Ticket</div>
                      </div>
                    </div>
                  </Button>
                  {/* <Button
                      onClick={() => {
                        openInNewTab(e);
                      }}
                    >
                      {" "}
                      <i class="mdi mdi-printer"></i>
                    </Button>



                    <div style={{ width: "4px" }} />
                    
                    
                    
                    <Button
                      style={{ marginLeft: "2px !important" }}
                      onClick={() => {
                        raiseTicketShow(e);
                      }}
                    >
                      <i class="mdi mdi-ticket"></i>
                    </Button> */}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>

      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });

  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default RechargeReportTable;
