import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";

export const RaiseTicketModel9 = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const [data, setData] = useState(null);
  const [optionTariff, setOptionTariff] = useState([]);
  const [type, setType] = useState("");
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    title: "",
    description: "",
  });
  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
  });

  const validate = () => {
    const errors = {};
    if (!state.title) {
      errors.title = "*Select Type!";
    }
    if (!state.description) {
      errors.description = "*Tariff is required!";
    }

    console.log(errors);
    console.log(state);
    if (Object.keys(errors).length == 0) {
      RaiseTicketModel9();
    }
    setFormErrors(errors);
  };

  console.log(details);

  const AllTariff = async () => {
    try {
      setTransactionLoaderState(true);

      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/sales/get_tariff_plans_sales.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    AllTariff();
  }, []);

  useEffect(() => {
    if (type != "" && data != null) {
      if (type == "DMT") {
        setOptionTariff(
          data.dmt.map((e) => {
            return { label: e.name, value: e.id };
          })
        );
      }
      if (type == "AEPS") {
        setOptionTariff(
          data.aeps.map((e) => {
            return { label: e.name, value: e.id };
          })
        );
      }
      if (type == "BBPS") {
        setOptionTariff(
          data.bbps.map((e) => {
            return { label: e.name, value: e.id };
          })
        );
      }
    }
  }, [type]);

  const AssignTariff = async() => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      var raw = JSON.stringify({
        UserID: details.userId,
        TariffId: selectedTariff.value,
        TariffType: type,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/master_allow_tariff_plan.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        setModalOpen(false);
        refresh();
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    AssignTariff();
  }, []);

  console.log(selectedTariff);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="lg"
    >
      <Modal.Body>
        <div>
          <strong
            className="px-1 hover d-flex justify-content-end"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 ">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Type
              </Form.Label>
              <select
                onChange={(e) => {
                  setType(e.target.value);
                }}
                className="form-control border"
                defaultSelectvalue="DMT"
                name="fname"
                id="fname"
              >
                <option>SelectType</option>
                <option value="DMT">DMT</option>
                <option value="AEPS">AEPS</option>
                <option value="BBPS">BBPS</option>
              </select>
              <p
                style={{
                  color: "red",
                }}
              >
                {" "}
                {formErrors.title}{" "}
              </p>
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 ">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Tariff
              </Form.Label>
              <Select
                options={optionTariff}
                value={selectedTariff}
                onChange={(e) => {
                  setSelectedTariff(e);
                }}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mx-1">
          <Button className="rounded-0 btn-success" onClick={AssignTariff}>
            Assign Tariff
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
