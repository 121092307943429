import React, { useContext } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW } from "../../utils/CommonConfig";

const DmtScreen = () => {
  const history = useHistory();
  const { setUserInfo, user } = useContext(GlobalContext);
  return (
    <div style={{
      background:"#F1F2F4",
      height:"100%",
      width:"100%"
    }}>
    <div className="card ml-5 mr-5 mt-0" style={{
        background: "#FFFFFF",
        boxShadow: DROP_SHADOW,
        borderRadius: "1rem",
        width:"95%",
        margin:"2rem"
      }}>
      <Row >
        <Col lg={4} md={4} sm={4}>
          <img
            src={require("../../../assets/images/invoice.png")}
            alt="image"
            style={{ width: "70%", height: "auto" }}
          />
        </Col>
        <Col lg={8} md={8} sm={8}>
          <div className="">
            <h2 style={{ color: "#0074D9", fontFamily: "Poppins"  }}>
              <strong>Domestic Money Transfer (DMT)</strong>
            </h2>
            <h3 style={{ fontFamily: "Poppins" }}>
              A Unique product that cn be used to send money instantly to any
              bank's account holder within india. The cash to account Fund
              transfer will now be made easy with IPPB DMT Services.
            </h3>
            <div
              className="d-flex py-4"
              style={{ justifyContent: "space-between" }}
            >
              <div
                className="d-flex "
                style={{ flexDirection: "column", fontFamily: "Poppins" }}
              >
              <ol>
               <h5><li>Send money instantly</li></h5> 
               <h5><li>Secure transection</li></h5> 
              </ol>
                
              </div>
              
            </div>
          </div>
        </Col>
      </Row>
      <hr/>
      <div
            className="row">
          <div className="col-6">
          <h5 className="p-0 ml-2">
          To use this service please Complete Your Document - KYC
            </h5>
          </div>
      <div className="col-3"></div>
<div className="col-3">
                <Button
                  onClick={() => {
                    localStorage.setItem("kyc", JSON.stringify(user));
                    history.push("/kyc/bussiness-details");
                  }}
                  className="rounded-3 btn-block"
                >
                  GET Started
                </Button>
              </div>
              </div>
      </div>
      </div>
  );
};

export default DmtScreen;
