import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  ExportToExcel,
  excelMapping,
  formatDateDDMMYYHHMM,
  generateHashKey2,
} from "../../utils/utilFuctions";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";

export const columns = [
  {
    dataField: "date_time",
    text: "Date & Time",
    color: "#000",
  },
  {
    dataField: "Narration",
    text: "Narration",
    color: "#000",
  },
  {
    dataField: "Reference",
    text: "Reference",
    color: "#000",
  },
  {
    dataField: "Opening_Balance",
    text: "Opening Balance",
    color: "#000",
  },
  {
    dataField: "trans_amount",
    text: "Amount",
    color: "#000",
  },

  {
    dataField: "Closing_Balance",
    text: "Closing Balance",
    color: "#000",
  },
  {
    dataField: "fee",
    text: "Fee",
    color: "#000",
  },
  {
    dataField: "commission",
    text: "Commission",
    color: "#000",
  },
  {
    dataField: "tds",
    text: "Tds",
    color: "#000",
  },
];

const LedgerReportTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { user } = useContext(GlobalContext);
  console.log(user);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const fetchLedgerReport = async (
    startDate = getDatePreviousMonth(),
    endDate = new Date()
  ) => {
    const start = dateFormatter(startDate);
    const end = dateFormatter(endDate);
    try {
      const raw = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=new_getLedgerReport&fromdate=${start}&todate=${end}&lipaddress=0&Status=0&UserID=${user.ReferId}`,
        requestOptions
      );
      const data = await res.json();
      setData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchData = () => {
    fetchLedgerReport(startDate, endDate);
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `${CLIENT}/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  // did mount
  useEffect(() => {
    fetchLedgerReport();
  }, []);
  return (
    <div>
      <div >
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="card" style={{
  border:"1px solid",
  width:"100%",
  borderRadius:"1rem",}}>
            <div className="row p-1 m-1">
             
              <div className="col-12 col-sm-4 col-md-2 px-1">
                <Form.Group style={{ marginTop: "4px" }}>
                  <Form.Label> From Date </Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control"
                    placeholderText="Start Date"
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-4 col-md-2 px-1">
                <Form.Group style={{ marginTop: "4px" }}>
                  <Form.Label> To Date </Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="form-control"
                    placeholderText="End Date"
                    style={{ borderRadius: "0px" }}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-4 col-md-2 px-1 mt-4 pt-1">
                <Form.Group>
                  <Button
                    className=" rounded-0"
                    variant=""
                    onClick={fetchData}
                    style={{
                      backgroundColor:"#56BE89",
                      color:"white",
                      width:"10vw"
                    }}
                  >
                    {"Search"}
                  </Button>
                </Form.Group>
              </div>
             
            </div>
            <div className="row">
            <div className="col-12 col-sm-4 col-md-2 ">
                <Form.Group>
                  <Form.Label style={{ marginTop: "4px" }}>
                    {" "}
                    Search in Table
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control rounded-0"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-sm-4 col mt-4 pt-1">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    ExportToExcel(
                      `Ledger_Report_${now()}`,
                      excelMapping(data, columns)
                    );
                  }}
                >
                  <img
                    src={require("../../../assets/images/excel.png")}
                    style={{ width: "1.6rem" }}
                  />
                  <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
                </div>
              </div>
            </div>
            {/* <Form.Group style={{ marginLeft: "10px" }}>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="By Remitter Mob No"
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px" }}>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="By Account No"
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px" }}>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"   
              aria-describedby="basic-addon1"
              placeholder="By Transaction ID"
            />
          </Form.Group> */}
          </div>
        </div>
        {/* <div style={{ width: "100%" }}>
          <div style={{ alignItems: "center" }}>
            <div style={{ flex: "1" }}></div>
          </div>
        </div> */}
       
      </div>
      <div  className="card mt-3 scroll-inner fix-width"
  style={{
  border:"1px solid",
  width:"100%",
  borderRadius:"1rem",
 
}}>
          <table
            className="dashboard-table-style"
            style={{ width: "100%", backgroundColor: "white" }}
          >
            <tr>
              <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Date & Time
              </th>
              <th
                className="text-left"
                style={{ fontSize: "0.9rem", width: "10px !important" }}
              >
                Narration
              </th>
              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Reference
              </th>
              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Opening Balance
              </th>
              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Amount
              </th>

              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Fee
              </th>
              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Commission
              </th>
              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Tds
              </th>
              <th className="text-center" style={{ fontSize: "0.9rem" }}>
                Closing Balance
              </th>
            </tr>
            {dataToDisplay.map((e, ind) => (
              <tr>
                <td style={{ fontSize: "0.8rem", backgroundColor: "white" }}>
                  {" "}
                  {ind + 1}
                </td>
                <td style={{ fontSize: ".8rem" }}>
                  {formatDateDDMMYYHHMM(e.date_time)}
                </td>
                <td className="text-left" style={{ fontSize: ".8rem",width:'25px' }}>
                  {e.Narration}
                </td>
                <td style={{ fontSize: ".8rem" }}>{e.Reference}</td>
                <td style={{ fontSize: ".8rem" }}>{e.Opening_Balance}</td>
                <td style={{ fontSize: ".8rem" }}>{e.trans_amount}</td>

                <td style={{ fontSize: ".8rem" }}>{e.fee}</td>
                <td style={{ fontSize: ".8rem" }}>{e.commission}</td>
                <td style={{ fontSize: ".8rem" }}>{e.tds}</td>
                <td style={{ fontSize: ".8rem" }}>{e.Closing_Balance}</td>
              </tr>
            ))}
          </table>
       
        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page) => {
            setCurrentPage(page);
          }}
          count={dataToDisplay.length}
          total={total}
          callback2={(offsetValue) => {
            setOffset(offsetValue);
          }}
        />
         </div>
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
       <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div
        className="d-flex"
        style={{
          border: "1px solid",

          marginTop: " 5px",
        }}
      >
        <select
          className="form-control"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant=" m-0 " style={{ borderRadius: "0px" }}>
          {" "}
          {page.toString()}
        </Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
   
    </div>
  );
};

export default LedgerReportTable;
