import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card, Modal } from "react-bootstrap";
import { urls } from "../../URLs";
import { GlobalContext } from "../../Context/GlobalState";
import {
  dmtSuccessPopup,
  dmtTransactionFailedPopup,
  dmtTransactionSuccessPopup,
} from "../../utils/Models";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import {
  formatDateDDMMYYHHMM,
  generateHashKey2,
} from "../../utils/utilFuctions";
import { sendMoneyAPI, sendMoneyOtpAPI } from "../../api/dmt/DmtApi2";
import { set } from "react-hook-form";

const { DMT1Url } = urls;

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const DMT4SendMoneyModal = ({ modalstate, callback }) => {
  const {
    wallet,
    setWalletBalance,
    refreshWallet,
    setTransactionLoaderState,
    Profileinfo,
  } = useContext(GlobalContext);

  const { details, isOpen, setModelState, refreshLimit } = modalstate;

  const { remitter } = details;
  const [amountError, setAmountError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const tPinLength = 6;
  const [amount, setAmount] = useState(0);
  const [tPin, setTPin] = useState("");
  const { benificiary, user, successToast, errorToast, remitterNumber } =
    details;
  const [charges, setCharges] = useState(0);
  const [tpinShow, setTpinShow] = useState(false);

  const [sendMoneyDetails, setSendMoneyDetails] = useState({
    otpSent: false,
    otp: "",
  });

  const close = () => {
    setTPin("");
    setModelState({ ...modalstate, isOpen: false });
  };

  console.log("remitter", remitter);

  const sendMoneyOTP = async () => {
    let dailyAvailableTxntCount = 0;
    let monthlyAvailableTxntCount = 0;
    let limit = 0;
    if (remitter) {
      dailyAvailableTxntCount = remitter?.walletbal;
      monthlyAvailableTxntCount = remitter?.monthlyLimit;
      limit = Math.min(dailyAvailableTxntCount, monthlyAvailableTxntCount);
    }
    const reqLimit = Math.ceil(Number(amount) / 5000);
    let beneNumber = benificiary.mobileNo;
    if (beneNumber == null || beneNumber == "") {
      beneNumber = remitterNumber;
    }
    if (tPin == "" || tPin.length < tPinLength) {
      errorToast("Please enter valid T Pin");
      return;
    }
    if (Number(amount) < 100 || Number(amount) > 25000) {
      swal("Failed", "Please Enter amount in Range 100 to 25000", "error");
      return;
    }
    if (Number(amount) > limit) {
      swal(
        "Failed",
        `You have exceeded your daily limit. Your available limit is ${remitter?.walletbal}`,
        "error"
      );
      return;
    }

    if (Number(dailyAvailableTxntCount) < reqLimit) {
      swal(
        "Failed",
        `You have exceeded your daily transaction count limit.`,
        "error"
      );
      return;
    }
    if (Number(monthlyAvailableTxntCount) < reqLimit) {
      swal(
        "Failed",
        `You have exceeded your monthly transaction count limit.`,
        "error"
      );
      return;
    }

    setIsLoading(true);
    setTransactionLoaderState(true);

    let accNo = benificiary.accountNumber;

    if (accNo.toString().length < 10) {
      accNo = "0" + accNo;
    }

    setTransactionLoaderState(true);

    try {
      const request = {
        userid: user.ReferId,
        customerName: remitter?.name,
        customerMobile: remitterNumber,
      };

      const res = await sendMoneyOtpAPI(request);
      if (res.responseCode == "00") {
        setSendMoneyDetails({ otpSent: true });
      } else {
        setModelState({ ...modalstate, isOpen: false });
        // dmtTransactionFailedPopup("", `${amount}.00`, res.msg);
        callback({
          amount: amount,
          message: res.responseMessage,
          status: "failed",
          date: formatDateDDMMYYHHMM(new Date()),
          transactionId: res?.data?.tid,
        });
      }
      setIsLoading(false);
      setTransactionLoaderState(false);
      refreshWallet();
    } catch (error) {
      setIsLoading(false);
      setTransactionLoaderState(false);
      errorToast("Amount transfer failed");
      dmtTransactionFailedPopup("", `${amount}.00`, "Amount transfer failed");
      console.log("error", error);
    }
  };

  const sendMoney = async () => {
    let dailyAvailableTxntCount = 0;
    let monthlyAvailableTxntCount = 0;
    let limit = 0;
    if (remitter) {
      dailyAvailableTxntCount = remitter?.walletbal;
      monthlyAvailableTxntCount = remitter?.monthlyLimit;
      limit = Math.min(dailyAvailableTxntCount, monthlyAvailableTxntCount);
    }
    const reqLimit = Math.ceil(Number(amount) / 5000);
    let beneNumber = benificiary.mobileNo;
    if (beneNumber == null || beneNumber == "") {
      beneNumber = remitterNumber;
    }
    if (tPin == "" || tPin.length < tPinLength) {
      errorToast("Please enter valid T Pin");
      return;
    }
    if (Number(amount) < 100 || Number(amount) > 25000) {
      swal("Failed", "Please Enter amount in Range 100 to 25000", "error");
      return;
    }
    if (Number(amount) > limit) {
      swal(
        "Failed",
        `You have exceeded your daily limit. Your available limit is ${details.limit}`,
        "error"
      );
      return;
    }

    if (Number(dailyAvailableTxntCount) < reqLimit) {
      swal(
        "Failed",
        `You have exceeded your daily transaction count limit.`,
        "error"
      );
      return;
    }
    if (Number(monthlyAvailableTxntCount) < reqLimit) {
      swal(
        "Failed",
        `You have exceeded your monthly transaction count limit.`,
        "error"
      );
      return;
    }

    setIsLoading(true);
    setTransactionLoaderState(true);

    let accNo = benificiary.accountNumber;

    if (accNo.toString().length < 10) {
      accNo = "0" + accNo;
    }

    setTransactionLoaderState(true);

    try {
      const request = {
        userid: user.ReferId,
        beneficiaryId: benificiary.recipientId,
        customerMobile: remitterNumber,
        beneficiaryMobile: benificiary.mobileNo,
        ifscCode: benificiary.ifscCode,
        accountNumber: benificiary.accountNumber,
        beneficiaryName: benificiary.recipientName,
        amount: amount,
        otp: sendMoneyDetails.otp,
        tpin: tPin,
      };

      const res = await sendMoneyAPI(request);
      if (res.responseCode == "00") {
        setModelState({ ...modalstate, isOpen: false });
        refreshLimit();

        callback({
          amount: amount,
          message: "Amount transfer successfull",
          status: "success",
          date: formatDateDDMMYYHHMM(new Date()),
          transactionId: res?.data?.tid,
        });
      } else if (res.responseCode == "02" || res.responseCode == "01") {
        // swal("Processing", res.msg, "warning");
        setModelState({ ...modalstate, isOpen: false });
        refreshLimit();
        callback({
          amount: amount,
          message: res.responseMessage,
          status: "pending",
          date: formatDateDDMMYYHHMM(new Date()),
          transactionId: res?.data?.tid,
        });
      } else {
        setModelState({ ...modalstate, isOpen: false });
        // dmtTransactionFailedPopup("", `${amount}.00`, res.msg);
        callback({
          amount: amount,
          message: res.responseMessage,
          status: "failed",
          date: formatDateDDMMYYHHMM(new Date()),
          transactionId: res?.data?.tid,
        });
      }

      setSendMoneyDetails({ otpSent: false, otp: "" });
      setIsLoading(false);
      setTransactionLoaderState(false);
      refreshWallet();
    } catch (error) {
      setIsLoading(false);
      setTransactionLoaderState(false);
      errorToast("Amount transfer failed");
      dmtTransactionFailedPopup("", `${amount}.00`, "Amount transfer failed");
      console.log("error", error);
    }
  };
  useEffect(() => {
    console.log("Sidebar changes");
    setAmount(0);
    setCharges(0);
    setTPin("");
  }, [details, isOpen]);

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT / IMPS`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const printReceipt = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let remitterMobile = "";
    // if (mobile.length > 9) {
    //   remitterMobile = mobile;
    // }

    var raw = JSON.stringify({
      UserID: user.ReferId,
      fromdate: dateFormatter(),
      todate: dateFormatter(),
      mobile: remitterMobile,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_dmt_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0" && result.data) {
            //setData(result.data);
            if (result.data.length > 0) {
              openInNewTab(result.data[0]);
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  // dmtSuccessPopup("0.00", "Amount transfer successfull", printReceipt);

  return (
    <Modal
      show={isOpen}
      onHide={() => setModelState({ ...modalstate, isOpen: false })}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <Modal.Header closeButton>
        <Modal.Title> Send Money</Modal.Title>
      </Modal.Header>
      {/* <SweetAlert
      show={popupState}
      title="Are you sure?"
      text="Rs 3 deducted from your Account"
      type="warning"
      showCancelButton
      onConfirm={() => {
        addBeneficiaryVerify();
        setPopupState(false);
      }}
    /> */}

      {isOpen && (
        <Modal.Body
          style={{ width: "100%", display: "block" }}
          className="account"
        >
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div style={{ display: "flex" }}>
              <span>Domestic Money Transfer Limit</span>
              <strong style={{ color: "green", margin: "0 0 0 1rem" }}>
                {details.limit}{" "}
              </strong>
            </div>
          </Card>
          <Card style={{ margin: "10px", padding: "10px" }}>
            <Form.Group>
              <Form.Label>Beneficiary Name</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Beneficiary Name"
                value={benificiary.recipientName}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Beneficiary A/C</Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Beneficiary A/C"
                value={benificiary.accountNumber}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="AMOUNT"
                style={{ display: "flex", alignItems: "center" }}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  const amt = Number(e.target.value);
                  if (amt < 100 || amt > 25000) {
                    setAmountError(
                      "Kindly enter an amount within the specified range of 100 to 25000"
                    );
                  } else {
                    setAmountError("");
                  }

                  let c = (Number(e.target.value) * 1.2) / 100;
                  if (Number(c) < 10) {
                    c = 12;
                  }
                  if (Number(c) > 250) {
                    c = 250;
                  }
                  setCharges(c);
                }}
              />
            </Form.Group>
            <div className="row justify-content-between">
              {amountError && (
                <span
                  style={{ color: "red", padding: "0px 0px 1rem 0px" }}
                >{`${amountError}`}</span>
              )}

              {charges > 0 && (
                <span
                  style={{ color: "red", padding: "0px 0px 1rem 0px" }}
                >{`Charges Rs ${charges}`}</span>
              )}
            </div>
            <Form.Group>
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <span className="input-group-text bg-transparent border-right-0 no-glow">
                    <i className="mdi mdi-lock-outline text-primary"></i>
                  </span>
                </div>
                <input
                  autocomplete="new-password"
                  autoCorrect="off"
                  autoSave="new-password"
                  type={tpinShow ? "text" : "password"}
                  className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                  id="tpin"
                  placeholder="T Pin"
                  value={tPin}
                  onChange={(e) => {
                    setTPin(e.target.value);
                  }}
                />

                <div className="input-group-prepend bg-transparent">
                  <span
                    className="input-group-text bg-transparent border-left-0 no-glow"
                    onClick={showTPinClick}
                    style={{ borderRadius: "4px" }}
                  >
                    <i
                      className={`mdi text-primary ${
                        tpinShow ? "mdi-eye" : "mdi-eye-off"
                      }`}
                    ></i>
                  </span>
                </div>
              </div>
            </Form.Group>
            {sendMoneyDetails.otpSent && (
              <Form.Group>
                <Form.Label>Enter OTP</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control rounded-0"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Enter OTP"
                  style={{ display: "flex", alignItems: "center" }}
                  value={sendMoneyDetails.otp}
                  onChange={(e) => {
                    setSendMoneyDetails({
                      ...sendMoneyDetails,
                      otp: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {sendMoneyDetails.otpSent ? (
                <Button
                  className="rounded-0"
                  style={{ margin: "0px 10px", flex: "1" }}
                  variant="primary"
                  onClick={sendMoney}
                  disabled={isLoading}
                >
                  {isLoading ? "Transaction Processing.." : "Transfer Now"}
                </Button>
              ) : (
                <Button
                  className="rounded-0"
                  style={{ margin: "0px 10px", flex: "1" }}
                  variant="primary"
                  onClick={sendMoneyOTP}
                  disabled={isLoading}
                >
                  {isLoading ? "OTP Processing.." : "Send OTP"}
                </Button>
              )}

              <Button
                onClick={close}
                className="rounded-0"
                style={{ margin: "0px 10px", flex: "1" }}
                variant="success"
              >
                {" "}
                Cancel
              </Button>
            </div>
          </Card>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default DMT4SendMoneyModal;
