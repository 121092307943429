import React, { useEffect, useState, useContext } from "react";
import Faq from "react-faq-component";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  Tabs,
  Tab,
  Container,
  Nav,
} from "react-bootstrap";
import { writeFile, utils } from "xlsx";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import TicketSidebar from "./TicketSidebar";
import { Redirect } from "react-router";
import swal from "sweetalert";
import { generateHashKey2 } from "../../utils/utilFuctions";

const data = {
  title: "FAQ (How it works)",

  rows: [
    {
      title: "What is AEPS?",
      content: `Aadhaar Enabled Payment System (AEPS) is a payment service that allows a bank customer
             to use Aadhaar as his/her identity to access his/her Aadhaar enabled bank account and perform
             basic banking transactions like balance enquiry, cash withdrawal, remittances through a Business Correspondent.`,
    },
    {
      title: "What is DMT?",
      content: `Domestic Money Transfer (DMT) is a unique product that can be
                 used to send money instantly to any Bank's account holder within India.`,
    },
    {
      title: "What is BBPS?",
      content: `What is BBPS and how it works?
            Bharat Bill Payment System (BBPS) is a RBI mandated system which offers integrated
             and interoperable bill payment services to customers across geographies with certainty,
              reliability and safety of transactions. `,
    },
  ],
};

const styles = {
  // bgColor: "white",
  titleTextColor: "black",
  rowTitleColor: "black",
  // rowBorderColor:'black',
  // marginBottom:'12px',
  // marginTop:'12px',
  // borderRadius:'10px'
  // rowContentColor: 'grey',
  // arrowColor: "red",
  // bgColor: 'aliceblue',
  // titleTextColor: 'blue',
  // titleTextSize: '48px',
  // rowTitleColor: 'blue',
  // rowTitleTextSize: 'medium',
  // rowContentColor: 'grey',
  // rowContentTextSize: '16px',
  // rowContentPaddingTop: '10px',
  // rowContentPaddingBottom: '10px',
  // rowContentPaddingLeft: '50px',
  // rowContentPaddingRight: '150px',
  arrowColor: "rgb(0, 63, 125)",
  // transitionDuration: "1s",
  // timingFunc: "ease"
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

const fqSection = {};

const HelpAndSupport = () => {
  const [Search, setSearch] = useState("");
  const [SearchData, setSearchData] = useState(data);
  const [rows, setRows] = useState([]);
  const [TID, setTID] = useState("");
  const [TicketSts, setTicketSts] = useState("");

  const [showHelpFormmodal, setShowHelpFormmodal] = useState(false);
  const hideHelpFormmodal = () => setShowHelpFormmodal(false);

  const SearchFilter = () => {
    const SearchFilteredData = SearchData.rows.filter((Sdata) => {
      return (
        Sdata.content.toLowerCase().includes(Search.toLowerCase()) ||
        Sdata.title.toLowerCase().includes(Search.toLowerCase())
      );
    });
    if (SearchFilteredData.length > 0) {
      setRows({
        title: "FAQ (How it works)",
        rows: SearchFilteredData,
      });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [WalletRepo, setWalletRepo] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [fqSection, setFqSection] = useState({
    title: "FAQ (How it works)",
    rows: [],
  });

  const { user } = useContext(GlobalContext);

  const GetTicketData = async () => {
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${BASE_URL_API_TRANXT}/apiget.aspx?method=getticketlist&userid=${user.ReferId}`,
      requestOptions
    );
    const result = await res.json();
    if (result) {
      setDataToDisplay(result);
    }
  };

  useEffect(() => {
    GetTicketData();
  }, []);

  const columns = [
    {
      dataField: "tkt_trans_id",
      text: "Ticket Id ",
      color: "green",
    },
    {
      dataField: "tkt_type",
      text: "Transaction Type",
      color: "#000",
    },
    // {
    //     dataField: "Category",
    //     text: "Category",
    //     color: "black",
    // },
    {
      dataField: "tkt_title",
      text: "Ticket Title",
      color: "black",
    },
    {
      dataField: "tkt_id",
      text: "Transaction Id",
      color: "black",
    },
    {
      dataField: "tkt_desc",
      text: "Description",
      color: "red",
    },

    {
      dataField: "tkt_status",
      text: "Status",
      color: "#000",
    },
    {
      dataField: "tkt_open_date",
      text: "Open Date",
      color: "black",
    },
    {
      dataField: "tkt_close_date",
      text: "Close Date",
      color: "black",
    },

    // {
    //     dataField: "Accept/Rejected",
    //     text: "Accept/Rejected",
    //     color: "#000",
    // },
  ];

  const ExportToExcel = (fileName, Provider) => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(Provider);
    utils.book_append_sheet(wb, ws, "sheet");
    writeFile(wb, `${fileName}.xlsx`);
  };

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 10;
    const endIdx = currentPage * 10 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((WalletRepo.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(WalletRepo.length);
    const filteredData = WalletRepo.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [WalletRepo]);
  console.log(pages);

  useEffect(() => {
    for (let key in WalletRepo[0]) {
      console.table(key, WalletRepo[0][key]);
    }
  }, [WalletRepo]);

  useEffect(() => {
    SearchFilter();
  }, [Search]);

  const getFqSection = () => {
    try {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/users/get_all_faq_list.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0") {
            const modified = result.data.map((e) => {
              return { ...e, title: e.question, content: e.answer };
            });
            setFqSection({
              title: "FAQ (How it works)",
              rows: modified,
            });
          }
        })

        .catch((error) => console.log("error", error));
    } catch (error) {}
  };
  useEffect(() => {
    getFqSection();
  }, []);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div>
            <div
              style={{ backgroundColor: "rgb(0,63,125)", textAlign: "center" }}
              className="p-2"
            >
              <h4 style={{ color: "white" }}>
                <strong>Help Center</strong>
              </h4>
            </div>
            <TicketSidebar TicketId={TID} TicketSts={TicketSts} />
            <IssueModal show={showHelpFormmodal} onHide={hideHelpFormmodal} />

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Container>
                <Row>
                  <div className="col-12 col-sm-8">
                    <Nav
                      variant="pills"
                      className="nav-pills-custom justify-content-around p-1 "
                      style={{
                        borderRadius: "1rem",
                        padding: "1rem",
                        border: "1px solid #2F58CD",
                        background: "#FFF",
                      }}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          className="d-flex align-items-center"
                        >
                          FAQ's
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          className="d-flex align-items-center"
                        >
                          Ticket
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Col className="col-12 col-sm-4">
                    <Button
                      onClick={() => setShowHelpFormmodal(true)}
                      className="mb-2"
                    >
                      Raise Ticket
                    </Button>
                  </Col>

                  <Col xs={12} className="p-0">
                    <Tab.Content className="tab-content-custom-pill p-0">
                      <Tab.Pane eventKey="first">
                        <Row>
                          <Col md={12} sm={12}>
                            <div className="mt-4 p-1">
                              <Form.Control
                                className="mb-2"
                                placeholder="Search FAQ's"
                                onChange={(e) => setSearch(e.target.value)}
                              />
                              <Faq
                                data={fqSection}
                                styles={styles}
                                config={config}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Col lg={12} md={12} sm={12}>
                          <div
                            className="center-block scroll-inner"
                            style={{
                              width: "100% !important",
                              overflowX: "scroll",
                            }}
                          >
                            <table
                              className="table dashboard-table-style"
                              style={{ width: "100%", overflowX: "auto" }}
                            >
                              <tr>
                                {columns.map((heading) => (
                                  <th
                                    className="text-center"
                                    style={{ fontSize: "0.9rem" }}
                                  >
                                    {heading.text}
                                  </th>
                                ))}
                              </tr>
                              {dataToDisplay.length > 0 ? (
                                dataToDisplay.map((e, ind) => (
                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{
                                        fontSize: "0.8rem",
                                        color: "#03C988",
                                      }}
                                    >
                                      {e.tkt_id}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {e.tkt_type}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {e.tkt_title}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      {e.tkt_trans_id}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{
                                        fontSize: "0.8rem",
                                        color: "red",
                                      }}
                                    >
                                      {e.tkt_desc}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      <div className="row">
                                        {e.tkt_status == 0 ? (
                                          <span className="flex-fill badge rounded-pill bg-danger p-2">
                                            Open
                                          </span>
                                        ) : (
                                          <span class="badge rounded-pill bg-success">
                                            close
                                          </span>
                                        )}
                                      </div>
                                    </td>
                                    <td style={{ fontSize: "0.8rem" }}>
                                      {e.tkt_open_date}
                                    </td>
                                    <td style={{ fontSize: "0.8rem" }}>
                                      {e.tkt_close_date}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10}>No data available</td>
                                </tr>
                              )}
                            </table>
                          </div>
                        </Col>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Container>
            </Tab.Container>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

function IssueModal(props) {
  const [Id, setId] = useState("");
  const [Issue, setIssue] = useState("");
  const [Category, setCategory] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [services, setServices] = useState([]);

  const [FormValues, setFormValues] = useState({
    Id: "",
    Issue: "",
    Category: "",
  });
  const [FormErrors, setFormErrors] = useState({});
  const { user } = useContext(GlobalContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...FormValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(FormValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(FormErrors).length === 0 && isSubmit) {
      Ticket();
    }
  }, [FormErrors]);

  const validate = (values) => {
    const errors = {};

    if (!Id) {
      errors.Id = "*Id is required!";
    }
    if (!Issue) {
      errors.Issue = "*Please enter the issue";
    }
    if (!Category) {
      errors.Category = "*Category is required!";
    }

    return errors;
  };
  const Ticket = async () => {
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      `${BASE_URL_API_TRANXT}/apiget.aspx?method=ticket&TransactionId=${Id}&TransactionType=${Category}&MailMessage=${Issue}&RetailorID=${user.ReferId}`,
      requestOptions
    );
    const result = await res.json();

    if (result.Id == "Y") {
      swal(
        "Success",
        result.Result ? result.Result : "Ticket Raised Successfully",
        "success"
      );
      props.onHide();
    } else {
      swal("Failed", result.Result ? result.Result : "Failed", "error");
    }
  };

  const getServices = () => {
    try {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_services_admin.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0") {
            setServices(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };
  useEffect(() => {
    getServices();
  }, []);

  return (
    <Modal show={props.show} onHide={props.onHide} centered size="md">
      <Modal.Header closeButton={true}>
        <Modal.Title as="h5" id="delete-address">
          Raise Ticket
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <React.Fragment>
          <Col>
            <Form.Control
              placeholder="Enter your transaction ID"
              className="mt-2"
              onChange={(e) => setId(e.target.value)}
            />
            <p className="text-danger text-left p-0">{FormErrors.Id}</p>
            <Form.Control
              as="select"
              placeholder="Enter the category of issue"
              className="mt-2"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option defaultValue>Select Transaction Category</option>
              {services.map((e) => (
                <option>{e.st_name}</option>
              ))}
            </Form.Control>
            <p className="text-danger text-left p-0">{FormErrors.Category}</p>
            {/* <Form.Control as="select" className='mt-2'>
              <option defaultValue>Select Your Reason</option>
              <option>asdfghj</option>
              <option>dfgm</option>
              <option>asdfg</option>
            </Form.Control> */}

            <Form.Control
              as="textarea"
              placeholder="Enter your issue"
              className="mt-2"
              onChange={(e) => setIssue(e.target.value)}
            />
            <p className="text-danger text-left p-0">{FormErrors.Issue}</p>
            <Button
              variant="outline-info"
              className="form-control m-0 mt-2"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Col>
        </React.Fragment>
      </Modal.Body>
    </Modal>
  );
}

export default HelpAndSupport;
