import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import userAvtar from "../../../assets/images/user.png";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import { Redirect } from "react-router";
import {
  BASE_URL_AGENT,
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { COMPANY_NAME_NAVBAR } from "../../utils/StringConstants";
import { generateHashKey2 } from "../../utils/utilFuctions";

const Navbar = () => {
  const history = useHistory();
  const {
    user,
    setWalletBalance,
    myAccountInfo,
    setProfileinfo,
    removeUserInfo,
    setUserInfo,
    wallet,
  } = useContext(GlobalContext);
  //   console.log(wBalance);
  //const [wallet, setWallet] = useState(null);
  const [profile, setProfile] = useState({});
  const [kycData, setKycData] = useState(null);
  const [docKycStatus, setDocKycStatus] = useState("");

  const getWalletBalance = async () => {
    try {
      const raw = "";
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=getbalance_new&userid=${user.ReferId}`,
        requestOptions
      );
      const raw2 = await res.text();
      const data = JSON.parse(raw2);
      console.log(data);
      setWalletBalance(data);
      //  setWallet(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const switchToRetailer = () => {
    setUserInfo({ ...user, UserType: "2", SwitchableType: "4" });
    history.push("/retailer/dashboard");
  };

  const GetProfile = () => {
    var raw = JSON.stringify({
      PdrID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/master_dist_profile_details.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setProfileinfo(result.data);
          setProfile(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserKycData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: user.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == 0 && data.data.length > 0) {
        setDocKycStatus(data.data[0].kyc_status);
        setKycData(data.data[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchUserKycData();
    GetProfile();
    getWalletBalance();
  }, []);
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  const redirect = () => {
    if (user.ReferId) {
      history.push("/master-distributor/Profile/MyProfile");
    }
  };

  const redirect2 = () => {
    history.push("/master-distributor/InsidePages/CommissionChart");
  };

  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null || state.user.UserType !== "4" ? (
          <Redirect to="/" />
        ) : (
          <nav className="navbar p-0 fixed-top d-flex flex-row">
            <ToastContainer
              autoClose={3000}
              hideProgressBar
              draggableDirection="y"
            />
            <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
              <Link className="navbar-brand brand-logo-mini" to="/">
                <span style={{ fontSize: "2rem", fontWeight: "600" }}>B</span>
              </Link>
            </div>

            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <button
                className="navbar-toggler align-self-center"
                type="button"
              // onClick={() =>
              //   document.body.classList.toggle("sidebar-icon-only")
              // }
              >
                <Link href="/">
                  <img
                    src={require("../../../assets/images/company/navbar_logo.png")}
                    style={{
                      height: "2.5rem",
                      paddingRight: "1rem",
                      width: "auto",
                    }}
                  />
                </Link>
              </button>

              <ul className="navbar-nav navbar-nav-right">
                {state.user == null ? null : (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        getWalletBalance();
                      }}
                    >
                      <div className="navbar-profile ">
                        <a
                          style={{
                            fontWeight: "600",
                            fontSize: "1.5rem",
                            flexDirection: "row",
                          }}
                        >
                          <a style={{ paddingRight: "2px" }}>₹</a>
                          {`INR ${wallet
                              ? wallet.WalletBalance
                              : state.user.walletBalance
                            } `}
                        </a>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}
                {wallet && Number(wallet.todaySale) && (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                    >
                      <div className="navbar-profile">
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ paddingRight: "2px" }}>₹</span>
                          {`Loan Amount: ${wallet
                            ? wallet.todaySale
                            : state.user.todaySale
                            } `}
                        </span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}
                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret"
                  >
                    <div className="navbar-profile">
                      <img
                        className="img-xs rounded-circle"
                        src={userAvtar}
                        alt="profile"
                      />
                      <p className="mb-0 d-none d-sm-block navbar-profile-name">
                        <Trans>
                          {state.user == null ? (
                            ""
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right text-dark"
                              >
                                <strong style={{ fontWeight: "800" }}>
                                  {state.user.ReferId}
                                </strong>
                                {` -${profile.name}`}
                              </span>
                              <strong
                                style={{ fontWeight: "800" }}
                                className="text-right text-dark"
                              >
                                {`${profile ? profile.shopname : ""}`}
                              </strong>
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right text-dark"
                              >
                                {`${profile ? profile.mobile : ""}`}
                              </span>
                              <span
                                style={{ fontWeight: "800", fontSize: ".8rem" }}
                                className="text-right"
                              >
                                {`Master Distributor`}
                              </span>
                            </div>
                          )}
                        </Trans>
                      </p>
                      <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        redirect();
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          className="fa fa-gear"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <p className="preview-subject mb-1 text-dark">
                          <Trans>My Profile</Trans>
                        </p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(evt) => {
                        evt.preventDefault();
                        localStorage.setItem("kyc", JSON.stringify(user));
                        sessionStorage.setItem("kyc", JSON.stringify(user));
                        if (docKycStatus != "A")
                          history.push("/kyc/bussiness-details");
                      }}
                      className="dropdown-item preview-item"
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-gear"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Document KYC</Trans>
                      </div>
                      {docKycStatus == "A" ? (
                        <div className="badge badge-success badge-pill ml-auto">
                          Completed
                        </div>
                      ) : docKycStatus == "P" ? (
                        <div className="badge badge-warning badge-pill ml-auto">
                          Under Proccess
                        </div>
                      ) : docKycStatus == "R" ? (
                        <div className="badge badge-danger badge-pill ml-auto">
                          Rejected
                        </div>
                      ) : null}
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        switchToRetailer();
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          className="fa fa-gear"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <p className="preview-subject mb-1 text-dark">
                          <Trans>Login as a Retailer</Trans>
                        </p>
                      </div>
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <div className="preview-thumbnail">
                        <span
                          className="fa fa-bell"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <p
                          className="preview-subject mb-1 text-dark"
                          onClick={redirect2}
                        >
                          <Trans>Commission Chart </Trans>
                        </p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider /> */}
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        removeUserInfo();
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          className="fa fa-sign-out"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <p className="preview-subject mb-1 text-dark">
                          <Trans>Logout</Trans>
                        </p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                onClick={toggleOffcanvas}
              >
                <span className="mdi mdi-format-line-spacing"></span>
              </button>
            </div>
          </nav>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Navbar;
