import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { urls } from "../../URLs";
import { GlobalContext } from "../../Context/GlobalState";
import {
  dmtTransactionFailedPopup,
  dmtTransactionSuccessPopup,
} from "../../utils/Models";
import swal from "sweetalert";

const { DMT1Url } = urls;

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const DMTSidebar = ({ details }) => {
  const { wallet, setWalletBalance, refreshWallet, setTransactionLoaderState } =
    useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const tPinLength = 6;
  const [amount, setAmount] = useState(0);
  const [tPin, setTPin] = useState("");
  const { benificiary, user, successToast, errorToast, limit, remitterNumber } =
    details;
  const [charges, setCharges] = useState(0);
  const [tpinShow, setTpinShow] = useState(false);

  const close = () => {
    setTPin("");
    document
      .querySelector(".content-wrapper")
      .classList.toggle("sidebar-icon-only");
  };
  const sendMoney = async () => {
    let beneNumber = benificiary.recipient_mobile;
    if (beneNumber == null || beneNumber == "") {
      beneNumber = remitterNumber;
    }
    if (tPin == "" || tPin.length < tPinLength) {
      errorToast("Please enter valid T Pin");
      return;
    }
    if (amount < 100 || amount > 25000) {
      swal("Failed", "Please Enter amount in Range 100 to 25000", "error");
      return;
    }
    setIsLoading(true);
    setTransactionLoaderState(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "sendmoney");
    urlencoded.append("beneficiaryid", benificiary.account);
    urlencoded.append("mobile", beneNumber);
    urlencoded.append("amount", amount + ".00");
    urlencoded.append("tid", "IMPS");
    urlencoded.append("uid", user.ReferId);
    urlencoded.append("type", "mobile");
    urlencoded.append("bank", benificiary.ifsc);
    urlencoded.append("tpin", tPin);
    urlencoded.append("route", "0");
    urlencoded.append("accountno", benificiary.account);
    urlencoded.append("paytmlimit", limit);
    urlencoded.append("bankitlimit", limit);
    urlencoded.append("benfname", benificiary.recipient_name.toString().replace("&",""));
    urlencoded.append("customernumber", remitterNumber);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    try {
      const res = await fetch(DMT1Url, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status) {
        // successToast("Amount transfer successfull");
        dmtTransactionSuccessPopup(
          "",
          `${amount}.00`,
          "Amount transfer successfull"
        );
      } else {
        dmtTransactionFailedPopup("", `${amount}.00`, data.message);
        // errorToast(data.message);
      }
      setIsLoading(false);
      setTransactionLoaderState(false);
      refreshWallet();
      document
        .querySelector(".content-wrapper")
        .classList.toggle("sidebar-icon-only");

      console.log(data);
    } catch (error) {
      setIsLoading(false);
      setTransactionLoaderState(false);
      errorToast("Amount transfer failed");
      dmtTransactionFailedPopup("", `${amount}.00`, "Amount transfer failed");
      console.log("error", error);
    }
  };
  useEffect(() => {
    console.log("Sidebar changes");
    setAmount(0);
    setCharges(0);
    setTPin("");
  }, [details]);
  
  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  return (
    <nav
      className="side-sheet sidebar Sidebar23"
      id="sidebar2"
      style={Sidebar22}
    >
      <div
        style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
        className="row"
      >
        <div
          style={{ paddingLeft: "5px", display: "flex", alignItems: "center" }}
          className="col"
        >
          <strong style={{ color: "#1b68ca" }}>
            DMT Transfer To Beneficiary
          </strong>
        </div>
        <div style={{ paddingRight: "0px" }} className="col">
          <span
            style={{
              fontSize: "2rem",
              display: "flex",
              color: "#b2b2b3",
              justifyContent: "end",
              ariaHidden: "true",
              paddingRight: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only");
            }}
          >
            &times;
          </span>
        </div>
      </div>
      <Dropdown.Divider />
      <Card style={{ margin: "10px", padding: "10px" }}>
        <div style={{ display: "flex" }}>
          <span>Domestic Money Transfer Limit</span>
          <strong style={{ color: "green", margin: "0 0 0 1rem" }}>
            {details.limit}{" "}
          </strong>
        </div>
      </Card>
      <Card style={{ margin: "10px", padding: "10px" }}>
        <Form.Group>
          <Form.Label>Beneficiary Name</Form.Label>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Beneficiary Name"
            value={benificiary.recipient_name}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Beneficiary A/C</Form.Label>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Beneficiary A/C"
            value={benificiary.account}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="text"
            className="form-control rounded-0"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="AMOUNT"
            style={{ display: "flex", alignItems: "center" }}
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
              let c = Number(e.target.value) / 100;
              if (Number(c) < 10) {
                c = 10;
              }
              if (Number(c) > 250) {
                c = 250;
              }
              setCharges(c);
            }}
          />
        </Form.Group>
        {charges > 0 && (
          <span
            style={{ color: "red", padding: "0px 0px 1rem 0px" }}
          >{`Charges Rs ${charges}`}</span>
        )}
        <Form.Group>
          <div className="input-group">
            <div className="input-group-prepend bg-transparent">
              <span className="input-group-text bg-transparent border-right-0 no-glow">
                <i className="mdi mdi-lock-outline text-primary"></i>
              </span>
            </div>
            <input
             autocomplete="new-password"
             autoCorrect="off"
             autoSave="new-password"
              type={tpinShow ? "text" : "password"}
              className="form-control form-control-lg border-left-0 border-right-0 no-glow"
              id="tpin"
              placeholder="T Pin"
              value={tPin}
              onChange={(e) => {
                setTPin(e.target.value);
              }}
            />
            <div className="input-group-prepend bg-transparent">
              <span
                className="input-group-text bg-transparent border-left-0 no-glow"
                onClick={showTPinClick}
                style={{ borderRadius: "4px" }}
              >
                <i
                  className={`mdi text-primary ${
                    tpinShow ? "mdi-eye" : "mdi-eye-off"
                  }`}
                ></i>
              </span>
            </div>
          </div>
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="rounded-0"
            style={{ margin: "0px 10px", flex: "1" }}
            variant="primary"
            onClick={sendMoney}
            disabled={isLoading}
          >
            {isLoading ? "Transaction Processing.." : "Transfer Now"}
          </Button>
          <Button
            onClick={close}
            className="rounded-0"
            style={{ margin: "0px 10px", flex: "1" }}
            variant="success"
          >
            {" "}
            Cancel
          </Button>
        </div>
      </Card>
    </nav>
  );
};

export default withRouter(DMTSidebar);
