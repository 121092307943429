import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert";


const GoSeoDocSubmit = () => {
    const [uploadedUrls, setUploadedUrls] = useState({
        pan: '',
        aadhar: '',
        gst: ''
    }); 
        const [images, setImages] = useState({
        pan: '',
        aadhar: '',
        gst: '',
    });
    const [aadharPreview, setAadharPreview] = useState(null);
    const [panPreview, setPanPreview] = useState(null);
    const [gstPreview, setGstPreview] = useState(null);

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
    
        if (file) {
            const reader = new FileReader();
    
            reader.onload = () => {
                const base64String = reader.result;
    
                setImages((prevImages) => ({ ...prevImages, [type]: base64String }));

    
                const raw = JSON.stringify({
                    agentId: "BR1004", 
                    mobile: "9168805979", 
                    type: type.toUpperCase(), 
                    image_data: base64String, 
                });
    
                fetch("https://bumppy.in/bumppypay/uploadLeadDocs.php", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: raw,
                })
                .then((response) => response.json())
                .then((data) => {
                    console.log(`${type} API response:`, data);
                    if (data.status === "00") {
                        setUploadedUrls((prevUrls) => ({
                            ...prevUrls,
                            [type]: data.url 
                        }));
                        swal("Success", `${type} file uploaded successfully!`, "success");
                    } else {
                        swal("Error", `Failed to upload ${type} file. Please try again.`, "error");
                    }
                })
                .catch((error) => {
                    console.error(`Error uploading ${type} file:`, error);
                    swal("Error", `Failed to upload ${type} file. Please try again.`, "error");
                });
        };

        reader.onerror = (error) => {
            console.error(`Error reading ${type} file:`, error);
            swal(`Error reading the ${type} file. Please try again.`);
        };

        reader.readAsDataURL(file);
    }
};

    const submitAgentLeadsDocs = async (event) => {
        event.preventDefault();

        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
    
          var raw = JSON.stringify({
            userid: "BR1004",
            mobileNo: "9168805979",
            panImage: uploadedUrls.pan,
            aadhaarImage: uploadedUrls.aadhar,
            gstImage: uploadedUrls.gst
          });
    
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
    
          const res = await fetch(
            "https://apiservices.bumppypay.com/api/User/submitAgentLeadsDocs",
            requestOptions
          );
          const data = await res.json();
          if (data.responseCode == "00") {
            swal("Success", "User Details Updated Successfully", "success");
          } else {
            swal("Failed", "User Details Updation Failed", "error");
          }
        } catch (error) {
            console.error("Error submitting form:", error);
            swal("Error", "Form submission failed. Please try again.", "error");
          }
      };


      const handleClear = () => {
        setImages({ aadhar: null, pan: null, gst: null });
      };

    const handleBack = () => {
        window.history.back();
    };

    const formContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
        padding: '20px',
    };

    const formStyle = {
        background: 'white',
        padding: '40px',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '1000px',
        height: '600px',
        textAlign: 'center',
    };

    const headingStyle = {
        marginBottom: '20px',
        fontSize: '1.5rem',
        color: '#333',
    };

    const formRowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    };

    const formGroupStyle = {
        flex: '1',
        marginRight: '10px',
        textAlign: 'left',
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '8px',
        fontSize: '0.9rem',
        color: '#555',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        fontSize: '0.9rem',
        border: '1px solid #ddd',
        borderRadius: '4px',
    };

    const previewStyle = {
        marginTop: '10px',
        maxWidth: '100%',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '5px',
        backgroundColor: '#f7f7f7',
    };

    const buttonStyle = {
        backgroundColor: '#007BFF',
        color: 'white',
        padding: '10px 15px',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        margin: '5px',
        transition: 'background-color 0.3s ease',
    };

    const buttonStyle1 = {
        backgroundColor: 'grey',
        color: 'white',
        padding: '10px 15px',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        margin: '5px',
        transition: 'background-color 0.3s ease',
    };

    const buttonStyle2 = {
        backgroundColor: 'red',
        color: 'white',
        padding: '10px 15px',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        margin: '5px',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: '#0056b3',
    };
    const buttonHoverStyle1 = {
        backgroundColor: '#243642',
    };
    const buttonHoverStyle2 = {
        backgroundColor: '#C62E2E',
    };

  return (
 <>
         <div style={formContainerStyle}>
      <form style={formStyle} onSubmit={submitAgentLeadsDocs}>
        <h2 style={headingStyle}>Document Upload</h2>

        <div style={formRowStyle}>
          <div style={formGroupStyle}>
            <label style={labelStyle}>Aadhar Card</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, "aadhar")}
              required
              style={inputStyle}
            />
            {images.aadhar && <img src={images.aadhar} alt="Aadhar Preview" style={previewStyle} />}
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>PAN Card</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, "pan")}
              required
              style={inputStyle}
            />
            {images.pan && <img src={images.pan} alt="PAN Preview" style={previewStyle} />}
          </div>

          <div style={formGroupStyle}>
            <label style={labelStyle}>GST Certificate (Optional)</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, "gst")}
              style={inputStyle}
            />
            {images.gst && <img src={images.gst} alt="GST Preview" style={previewStyle} />}
          </div>
        </div>

        <div>
          <button
            type="submit"
            style={buttonStyle}
            onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
          >
            Submit
          </button>
          <button
            type="button"
            style={buttonStyle1}
            onClick={handleClear}
            onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle1.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = buttonStyle1.backgroundColor)}
          >
            Clear
          </button>
          <button
            type="button"
            style={buttonStyle2}
            onClick={handleBack}
            onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle2.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = buttonStyle2.backgroundColor)}
          >
            Back
          </button>
        </div>
      </form>
    </div>
        </>
  );
};

export default GoSeoDocSubmit;
