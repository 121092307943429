import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { offsetArr } from "../../utils/CommonConfig";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import swal from "sweetalert";
import { generateHashKey2 } from "../../utils/utilFuctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
const contentStyles = {
  width: "100%",
};
const tdstyle = {
  fontSize: "0.8rem",
  fontFamily: "Poppins",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};
const thstyle = {
  color: "dark",
  backgroundColor: "white",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};
const RetailerBusiness = () => {
  const [data, setData] = useState([{}]);
  const [mdShow, setMdShow] = useState(false);

//   const [dataToDisplay, setDataToDisplay] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [total, setTotal] = useState(0);
//   const [offset, setOffset] = useState(10);
  const [pages, setPages] = useState(0);
  const [searchText, setSearchText] = useState("");
//   const [balance, setBalance] = useState({});
  const [merchantName, setmerchantName] = useState("");
  const [merchantMobile, setmerchantMobile] = useState("");
  const [merchantNameError, setmerchantNameError] = useState("");
  const [merchantMobileError, setmerchantMobileError] = useState("");
  const [toggle, setToggle] = useState(false);
  const {
    user,
    setTransactionLoaderState,
  } = useContext(GlobalContext);


  const [balance, setBalance] = useState({
    totalBusiness: 0,
    totalCharges: 0,
    totalCommission: 0,
  });
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);


  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
//   useEffect(() => {
//     setPages(Math.floor((data.length + (offset - 1)) / offset));
//     const startIdx = 0;
//     const endIdx = offset - 1;
//     setTotal(data.length);
//     console.log(startIdx);
//     console.log(data);
//     const filteredData = data.filter((bank, idx) => {
//       return idx <= endIdx && idx >= startIdx;
//     });
//     console.log(filteredData);
//     setDataToDisplay(filteredData);
//   }, [data, offset]);

  const getAllDashboardChartDataAdmin = async () => {
    try {
      setTransactionLoaderState(true);

      const hash_key = await generateHashKey2(user.ReferId);
   
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

      var raw = JSON.stringify({
        userid: user.ReferId,
        "interval": "1"

      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/User/getDashboardData`,
        requestOptions
      );
      const data = await res.json();   
      if (data.responseCode === "00") {
        const { totalBusiness, totalCharges, totalCommission } = data.data;
        const services = data.services; 
        setBalance({
          totalBusiness: totalBusiness || 0,
          totalCharges: totalCharges || 0,
          totalCommission: totalCommission || 0,
        });
        setDataToDisplay(services || []);
      }
    } catch (error) {
      console.log("API Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
    


  useEffect(() => {
    getAllDashboardChartDataAdmin();
  }, []);

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%",
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  console.log("Data for Table:", dataToDisplay);


  return (
    <div className="bg-light p-2" style={{ fontFamily: "poppins" }}>
      <div style={contentStyles} className="scrollable-content">
        <div className="">
            <div className="row">
                <div className="col">
                <div
            className="p-4 m-3 bg-white "
            style={{
              borderRadius: "10px",
            }}
          >
            <div className="text-dark py-0 px-2 m-0 ">
              <div className="d-flex justify-content-between">
              
                <div>
                  {" "}
                  <h4 className="text-center py-3" style={{ fontWeight: "bold" }}>
  Total Business: ₹ {twoDecimalPlaces(balance.totalBusiness)}
</h4>
                </div>
              </div>
          
            </div>
          </div>
                </div>
                <div className="col">
                <div
            className="p-4 m-3 bg-white "
            style={{
              borderRadius: "10px",
            }}
          >
            <div className="text-dark py-0 px-2 m-0 ">
              <div className="d-flex justify-content-between">
              
                <div>
                <h4 className="text-center py-3" style={{ fontWeight: "bold" }}>
  Total Charges: ₹ {twoDecimalPlaces(balance.totalCharges)}
</h4>
                </div>
              </div>
          
            </div>
          </div>
                </div>
                <div className="col">
                <div
            className="p-4 m-3 bg-white "
            style={{
              borderRadius: "10px",
            }}
          >
            <div className="text-dark py-0 px-2 m-0 ">
              <div className="d-flex justify-content-between">
              
                <div>
                <h4 className="text-center py-3" style={{ fontWeight: "bold" }}>
  Total Profit/Comm: ₹ {twoDecimalPlaces(balance.totalCommission)}
</h4>
                </div>
              </div>
          
            </div>
          </div>
                </div>
           
            </div>
       

          <div
            className=" p-4 m-3"
            style={{
              background: "#ffffff",
              borderRadius: "10px",
            }}
          >
            <div className="row justify-content-between align-items-center py-0 px-2 m-0">
              <div
                className="col-7 text-start"
                style={{ fontWeight: "bold", fontSize: "26px" }}
              >
                Business Report
              </div>

            

              <div className="scroll-inner admin-fix-width pt-4">
                <table
                  style={{ width: "100%" }}
                  className="table table-borderless"
                >
                  <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
                    <th
                      className=" transparent-table text-center border-bottom  text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Sr"}
                    </th>

                    <th
                      className=" transparent-table text-center border-bottom  text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Service"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom  text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Number of Transaction"}
                    </th>
                    <th
                      className=" transparent-table text-center border-bottom  text-dark"
                      style={thstyle}
                    >
                      {" "}
                      {"Amount"}
                    </th>
                  </tr>
                  {dataToDisplay.map((e, ind) => (
  <tr className="transparent-table border-bottom" key={e.id || ind}>
    <td className="align-middle" style={tdstyle}>
      {ind + 1}
    </td>
    <td className="align-middle" style={tdstyle}>
      <div className="d-flex flex-column align-items-center">
        <span>{e.serviceName}</span>
      </div>
    </td>
    <td className="align-middle" style={tdstyle}>
      <div className="d-flex flex-column align-items-center">
        <span>{e.totalCount}</span>
      </div>
    </td>
    <td className="align-middle" style={tdstyle}>
      <div className="d-flex flex-column align-items-center">
        <span>{twoDecimalPlaces(e.totalAmount)}</span>
      </div>
    </td>
  </tr>
))}

                </table>
              </div>

              <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                  setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                  setOffset(offsetValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export default RetailerBusiness;