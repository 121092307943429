import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import BBPSReportTable from "./BBPSReportTable";
const BBPSReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) => (
       <div style={{ padding: "0.5rem" }} className="bg-light">
       <div style={{ borderRadius: "10px" }} className="card  m-0 mt-4 ml-1 mr-1 p-0">
         <div className="card-body m-0 p-2">
           <div>
              <div className="px-3 pb-2"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"32px",
                  }}
                >
                  BBPS Report
                </div>

                <BBPSReportTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default BBPSReport;
