import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { List } from "semantic-ui-react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router";
import { generateHashKey2, onlyNumeric } from "../../utils/utilFuctions";
const MyProfile = () => {
  const [currentPasswordCheck, setCurrentPasswordCheck] = useState(false);
  const [currentTPinCheck, setCurrentTPinCheck] = useState(false);
  const [currentMPinCheck, setCurrentMPinCheck] = useState(false);
  const [tpinShow, setTpinShow] = useState(false);
  const { Profileinfo, user } = useContext(GlobalContext);
  const [inputState, setInputState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    currentTPin: "",
    newTPin: "",
    confirmTPin: "",
    currentMPin: "",
    newMPin: "",
    confirmMPin: "",
  });
  const [formErrors, setFormErrors] = useState({
    newTpin: "",
    confirmTpin: "",
  });

  const submitCurrentPassword = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "PASSWORD",
      password: inputState.currentPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/checkpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          setCurrentPasswordCheck(true);
        } else {
          cogoToast.info("In Correct Password");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const changePassword = async () => {
    if (inputState.newPassword !== inputState.confirmPassword) {
      cogoToast.error("New Password and Confirm Password should same");
      return;
    }

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "PASSWORD",
      password: inputState.newPassword,
      currenttpin: inputState.currentPassword,
    });

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("Password Changed Successfully");
        } else {
          cogoToast.info("Password Changed Failed");
        }
        reset();
      })
      .catch((error) => console.log("error", error));
    setCurrentPasswordCheck(false);
    setInputState({ ...inputState, currentPassword: "" });
  };

  const submitCurrentTPin = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "TPIN",
      password: inputState.currentTPin,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/checkpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          setCurrentTPinCheck(true);
        } else {
          cogoToast.info("In Correct T PIN");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const changeTPin = async () => {
    if (inputState.newTPin.length !== 6) {
      setFormErrors({
        ...formErrors,
        newTpin: "T Pin should be 6 Digit long",
      });
      cogoToast.error("T Pin should be 6 Digit long");
      return;
    }
    if (inputState.newTPin !== inputState.confirmTPin) {
      setFormErrors({
        ...formErrors,
        confirmTpin: "New T Pin and Confirm T Pin should same",
      });
      cogoToast.error("New T Pin and Confirm T Pin should same");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "TPIN",
      password: inputState.newTPin,
      currenttpin: inputState.currentTPin,
    });

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/updatepwd.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("T PIN Changed Successfully");
        } else {
          cogoToast.info("T PIN Changed Failed");
        }
        reset();
      })
      .catch((error) => console.log("error", error));
    setCurrentTPinCheck(false);
    setInputState({ ...inputState, currentTPin: "" });
  };

  const requestTPin = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "TPIN",
      password: inputState.newTPin,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("T PIN Requested Successfully");
        } else {
          cogoToast.info("T PIN Requested Failed");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const requestMPin = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobileno: Profileinfo?.mobile,
      reset_type: "MPIN",
      password: inputState.newMPin,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/Profile/resetpassword.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "00") {
          cogoToast.success("M PIN Requested Successfully");
        } else {
          cogoToast.info("M PIN Requested Failed");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const reset = () => {
    setInputState({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      currentTPin: "",
      newTPin: "",
      confirmTPin: "",
      currentMPin: "",
      newMPin: "",
      confirmMPin: "",
    });
  };
  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null ? (
          <Redirect to="/" />
        ) : (
          <div>
            <Row className="p-4">
              <Col md={4} lg={4} sm={12} className="border">
                <div className="p-2" style={{ textAlign: "center" }}>
                  <strong>Personal Details</strong>
                </div>
                <hr />
                <List as="ul">
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      User ID: {state.Profileinfo?.uscode}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Mobile: {state.Profileinfo?.mobile}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Firm: {state.Profileinfo?.name}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      E-Mail: {state.Profileinfo?.email}
                    </b>
                  </List.Item>
                </List>

                <hr />
                <div className="p-2" style={{ textAlign: "center" }}>
                  <strong>Parent Details</strong>
                </div>
                <hr />
                <List as="ul">
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Firm: {state.Profileinfo?.parent_name}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Mobile: {state.Profileinfo?.parent_mobile}
                    </b>
                  </List.Item>
                  <List.Item as="li">
                    <b style={{ color: "grey" }}>
                      Email: {state.Profileinfo?.parent_email}
                    </b>
                  </List.Item>
                </List>
              </Col>

              <Col md={8} lg={8} sm={12} className="p-3 border">
                <div>
                  <b>Personal details and Change T-PIN</b>
                  <hr />

                  <Row>
                    <Col md={6} lg={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <strong>Profile</strong>
                      </div>

                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          className="m-1 rounded-0 form-control form-control-sm"
                          type="text"
                          value={state.Profileinfo?.name}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          className="m-1 rounded-0 form-control form-control-sm"
                          type="text"
                          value={state.Profileinfo?.email}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Shop Name</Form.Label>
                        <Form.Control
                          className="m-1 rounded-0 form-control form-control-sm"
                          type="text"
                          value={state.Profileinfo?.shopname}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                      <div style={{ textAlign: "center" }}>
                        <strong>Password</strong>
                      </div>

                      <Form.Group>
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          placeholder="current password"
                          type="password"
                          className="m-1 rounded-0 form-control form-control-sm"
                          onChange={(e) =>
                            setInputState({
                              ...inputState,
                              currentPassword: e.target.value,
                            })
                          }
                        />
                        {currentPasswordCheck && (
                          <div>
                            <Form.Control
                              placeholder="new password"
                              type="password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              onChange={(e) =>
                                setInputState({
                                  ...inputState,
                                  newPassword: e.target.value,
                                })
                              }
                            />
                            <Form.Control
                              placeholder="confirm password"
                              type="password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              onChange={(e) =>
                                setInputState({
                                  ...inputState,
                                  confirmPassword: e.target.value,
                                })
                              }
                            />
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={changePassword}
                            >
                              Submit New Password
                            </Button>
                          </div>
                        )}

                        {!currentPasswordCheck && (
                          <div>
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={submitCurrentPassword}
                            >
                              Submit
                            </Button>
                            {/* 
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={submitCurrentMPin}
                            >
                              Request Password
                            </Button> */}
                          </div>
                        )}
                      </Form.Group>

                      <div style={{ textAlign: "center" }}>
                        <strong>T-PIN</strong>
                      </div>
                      <Form.Group>
                        <div className="input-group">
                          <div className="input-group-prepend bg-transparent">
                            <span className="input-group-text bg-transparent border-right-0 no-glow">
                              <i className="mdi mdi-lock-outline text-primary"></i>
                            </span>
                          </div>
                          <input
                            type={tpinShow ? "text" : "password"}
                            className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                            id="exampleInputPassword"
                            placeholder="Current T Pin"
                            onChange={(e) =>
                              setInputState({
                                ...inputState,
                                currentTPin: e.target.value,
                              })
                            }
                          />
                          <div className="input-group-prepend bg-transparent">
                            <span
                              className="input-group-text bg-transparent border-left-0 no-glow"
                              onClick={showTPinClick}
                              style={{ borderRadius: "4px" }}
                            >
                              <i
                                className={`mdi text-primary ${
                                  tpinShow ? "mdi-eye" : "mdi-eye-off"
                                }`}
                              ></i>
                            </span>
                          </div>
                        </div>

                        {currentTPinCheck && (
                          <div>
                            <Form.Control
                              placeholder="new T Pin"
                              type="password"
                              autocomplete="new-password"
                              autoCorrect="off"
                              autoSave="new-password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              value={inputState.newTPin}
                              onChange={(e) => {
                                if (
                                  e.target.value != "" &&
                                  !onlyNumeric(e.target.value)
                                ) {
                                  setFormErrors({
                                    ...formErrors,
                                    newTpin: "T Pin should contain only digit",
                                  });
                                } else if (e.target.value.length > 6) {
                                  setFormErrors({
                                    ...formErrors,
                                    newTpin:
                                      "T Pin doesn't contains more than 6 digit",
                                  });
                                } else {
                                  setInputState({
                                    ...inputState,
                                    newTPin: e.target.value,
                                  });
                                  setFormErrors({
                                    ...formErrors,
                                    newTpin: "",
                                  });
                                }
                              }}
                            />
                            <p style={{ color: "red" }}>{formErrors.newTpin}</p>

                            <Form.Control
                              placeholder="confirm T Pin"
                              type="password"
                              autocomplete="new-password"
                              autoCorrect="off"
                              autoSave="new-password"
                              className="m-1 rounded-0 form-control form-control-sm"
                              value={inputState.confirmTPin}
                              onChange={(e) =>
                                setInputState({
                                  ...inputState,
                                  confirmTPin: e.target.value,
                                })
                              }
                            />
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={changeTPin}
                            >
                              Change TPIN
                            </Button>
                          </div>
                        )}
                        {!currentTPinCheck && (
                          <div>
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={submitCurrentTPin}
                            >
                              Submit
                            </Button>
                            <Button
                              variant="success"
                              className="m-1 rounded-0 btn btn-sm"
                              onClick={requestTPin}
                            >
                              Request T PIN
                            </Button>
                          </div>
                        )}
                      </Form.Group>

                      <div style={{ textAlign: "center" }}>
                        <strong>M-PIN</strong>
                      </div>
                      <Form.Group>
                        <Button
                          variant="success"
                          className="m-1 rounded-0 btn btn-sm"
                          onClick={requestMPin}
                        >
                          Request M PIN
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};
export default MyProfile;
