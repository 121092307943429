import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { RaiseTicketModal } from "../util/RaiseTicketModal";

export const columns = [
  {
    dataField: "rch_recharge_date",
    text: "Date",
  },
  {
    dataField: "tid",
    text: "TID",
  },
  {
    dataField: "rch_transaction_id",
    text: "Ref ID",
  },
  {
    dataField: "user_code",
    text: "User Code",
  },
  {
    dataField: "user_mobile",
    text: "User Mobile",
  },
  {
    dataField: "rch_mobile",
    text: "Retailer Mobile",
  },
  {
    dataField: "rch_before_balance",
    text: "Opening Bal",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_after_balance",
    text: "Closing Bal",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_cnf",
    text: "Master Comm",
  },
  {
    dataField: "rch_com_distributer",
    text: "Dist Comm",
  },
  {
    dataField: "rch_com_retailer",
    text: "Retailer Comm",
  },

  {
    dataField: "bill_mode",
    text: "Mode",
  },
  {
    dataField: "sales_user_code",
    text: "Sales Code",
  },
  {
    dataField: "sales_user_name",
    text: "Sales Name",
  },
  {
    dataField: "sales_user_mobile",
    text: "Sales Mobile Number",
  },
  {
    dataField: "shop_pin_code",
    text: "Pin Code",
  },
  {
    dataField: "shop_city",
    text: "City",
  },
  {
    dataField: "shop_state",
    text: "State",
  },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
];

const BBPSReportTable = () => {
  const [data, setData] = useState([]);
  const [salesPersons, setSalesPersons] = useState(null);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [raiseTicketModal, setRaiseTicketModal] = useState(false);
  const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/bbps/get_bbps_report_admin.aspx`,
        requestOptions
      );
      const data = await res.json();

      setData(
        data.data.map((e) => {
          return {
            ...e,
            user_code: e.username.split("/")[0],
            user_mobile: e.username.split("/")[1],
            shop_pin_code: e.fk_api_id.split("|")[1],
            shop_city: e.fk_api_id.split("|")[2],
            shop_state: e.fk_api_id.split("|")[3],
          };
        })
      );
      const sales = [];
      const sales_persons = [{ label: "All Sales Person", value: "" }];
      data.data.forEach((ee) => {
        if (!sales.includes(ee.sales_user_code) && ee.sales_user_code != "") {
          sales_persons.push({
            label: `${ee.sales_user_code} ${ee.sales_user_name}`,
            value: ee.sales_user_code,
          });
          sales.push(ee.sales_user_code);
        }
      });

      setSalesPersons(sales_persons);

      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const refundAEPSTransaction = async (tid) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        trans_id: tid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/refunds/bbpstrans_refund.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const refundVerification = (tid, amount, RetailerID) => {
    swal({
      title: "Are you sure?",
      text: `Refund amount of ${amount} to ${RetailerID}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        refundAEPSTransaction(tid);
      }
    });
  };
  const showResponse = (response) => {
    swal2(
      <div className="d-flex overflow-auto">
        <strong>{JSON.stringify(response)}</strong>
      </div>
    );
  };

  const raiseTicketShow = (data) => {
    setRaiseTicketDetails({
      tid: data.tid,
      category: "BBPS",
      user_id: data.username.toString().split("/")[0],
    });
    setRaiseTicketModal(true);
  };

  useEffect(() => {
    getDMTReport();
  }, []);

  useEffect(() => {
    if (selectedSalesPerson) {
      if (selectedSalesPerson.value == "") {
        setDataToDisplay(data);
      } else {
        const filteredData = data.filter((ee) => {
          return ee.sales_user_code == selectedSalesPerson.value;
        });
        setDataToDisplay(filteredData);
      }
    }
  }, [selectedSalesPerson]);
  return (
    <>
      <RaiseTicketModal
        details={raiseTicketDetails}
        isModalOpen={raiseTicketModal}
        setModalOpen={(modalState) => {
          setRaiseTicketModal(modalState);
        }}
        refresh={() => {}}
      />
      <div className="row">
        <Col md={2} sm={12}>
          <Form.Group>
            <Form.Label>From Date</Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control border-primary rounded-0"
              placeholderText="Start Date"
            />
          </Form.Group>
        </Col>
        <Col md={2} sm={12}>
          <Form.Group>
            <Form.Label>To Date</Form.Label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control border-primary rounded-0"
              placeholderText="End Date"
            />
          </Form.Group>
        </Col>
        <Col md={2} sm={6}>
          <Form.Group>
            <Form.Label>
              {/* <span style={{ color: "red" }}>*</span> */}
              {`Sales Person`}
            </Form.Label>
            <Select
              options={salesPersons}
              onChange={(e) => {
                setSelectedSalesPerson(e);
              }}
            />
          </Form.Group>
        </Col>

        <div className="col-3 p-0 mr-2 mt-4">
          <Form.Group>
            <Button
              className="rounded-0 mt-4"
              variant="success"
              onClick={getDMTReport}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
      </div>

      <div className="row d-flex">
        <div className="col">
          <span
            className="badge bg-success text-light"
            style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
          >
            {`Success Count : ${0}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-warning text-light"
            style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
          >
            {`Pending : ${summary.Pending}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-danger text-light"
            style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
          >
            {`Fail : ${summary.Fail}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-success text-light"
            style={{
              padding: ".8rem 2rem",
              borderRadius: "4rem",
              width: " 11.5rem",
            }}
          >
            {`Success : ${twoDecimalPlaces(summary.SuccessRecharge)}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-warning text-light"
            style={{
              padding: ".8rem 2rem",
              borderRadius: "4rem",
              width: " 11.5rem",
            }}
          >
            {`Pending : ${twoDecimalPlaces(summary.PendingRecharge)}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-danger text-light"
            style={{
              padding: ".8rem 2rem",
              borderRadius: "4rem",
              width: " 9.6rem",
            }}
          >
            {`Fail : ${twoDecimalPlaces(summary.FailRecharge)}`}
          </span>
        </div>
      </div>

      <div className="row d-flex"></div>

      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>
            <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `BBPS_Report_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
            <img
              src={require("../../../assets/images/excel.png")}
              style={{ width: "1.6rem" }}
            />
            <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
          </div>
        </div>
      </div>

      <div className="scroll-inner admin-fix-width">
        <table style={{ width: "100%" }} className="admin-table-style">
          <tr className="text-center">
            <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>

            <th style={{ fontSize: "0.9rem" }}> {"Op ID"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Mobile No"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Retailer"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Amount"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Status"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Profit"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Balance"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Mode"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Sales Person"}</th>
            <th style={{ fontSize: "0.9rem" }}> {"Status"}</th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr>
              <td
                style={{ fontSize: "0.8rem" }}
                className={`${
                  e.rch_status_desc == "Success"
                    ? "bg-success"
                    : e.rch_status_desc == "Fail"
                    ? "bg-danger"
                    : "bg-warning"
                }`}
              >
                {" "}
                {ind + 1}
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span className="text-warning">{`TID : ${e.tid}`}</span>
                  <span>{e.rch_indate}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_mobile}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.username}</span>
                  <span>{e.remitterno}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{twoDecimalPlaces(e.rch_amount)}</span>
                  <span className="text-success">{e.ttypes}</span>
                  {/* <span className="text-danger">{`Deduction : ${twoDecimalPlaces(
                    e.dmt_deduction
                  )}`}</span> */}
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  {e.rch_status_desc == "Success" ? (
                    <span
                      className="badge bg-success text-light"
                      style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
                    >
                      {`${e.rch_status_desc}`}
                    </span>
                  ) : (
                    <span
                      className="badge bg-warning text-light"
                      style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
                    >
                      {`${e.rch_status_desc}`}
                    </span>
                  )}
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{`C Com :${twoDecimalPlaces(e.rch_com_cnf)}`}</span>
                  <span>{`D Com :${twoDecimalPlaces(
                    e.rch_com_distributer
                  )}`}</span>
                  {/* <span className="text-warning">{`Charges :${twoDecimalPlaces(
                    e.DmtCharges
                  )}`}</span> */}
                  <span className="text-success">{`Comm :${twoDecimalPlaces(
                    e.rch_com_retailer
                  )}`}</span>
                  {/* <span className="text-danger">{`TDS :${twoDecimalPlaces(
                    e.tds
                  )}`}</span> */}
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span className="text-warning">{`Opening :${twoDecimalPlaces(
                    e.rch_before_balance
                  )}`}</span>
                  <span className="text-primary">{`Closing :${twoDecimalPlaces(
                    e.rch_after_balance
                  )}`}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{`${e.bill_mode}`}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{`${e.sales_user_name}`}</span>
                  <span>{`${e.sales_user_code}`}</span>
                  <span>{`${e.sales_user_mobile}`}</span>
                </div>
              </td>

              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center">
                  {/* <Button className="btn btn-sm btn-primary btn-block rounded-0 m-1">
                    {" "}
                    Rehit
                  </Button>
                  <Button className="btn btn-sm btn-success btn-block rounded-0 m-1">
                    {" "}
                    Status Update
                  </Button> */}
                  <div className="mb-1">
                    <Button
                      className="btn btn-sm btn-info btn-block rounded-0 m-1"
                      style={{ width: "9.3rem" }}
                      onClick={() => {
                        refundVerification(
                          e.tid,
                          twoDecimalPlaces(e.rch_amount),
                          e.username
                        );
                      }}
                      disabled={
                        e.rch_status_desc == "Refund" ||
                        e.rch_status_desc == "Refunded"
                      }
                    >
                      {" "}
                      Refund
                    </Button>
                  </div>
                  <div className="mt-1 mb-1">
                    <Button
                      className="btn btn-sm btn-warning btn-block rounded-0 m-1"
                      onClick={() => {
                        showResponse(e.rch_api_message);
                      }}
                    >
                      {" "}
                      Full Bank Response
                    </Button>
                  </div>
                  <div className="mt-1">
                    <Button
                      className="btn btn-sm btn-dark btn-block rounded-0 m-1"
                      style={{ width: "9.3rem" }}
                      onClick={() => {
                        raiseTicketShow(e);
                      }}
                    >
                      {" "}
                      Raise Ticket
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </table>
        {data.length <= 0 && <NoDataFound />}
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const NoDataFound = () => {
  return (
    <tr className="row w-100 justify-content-center">
      <div className="d-flex flex-column align-items-center p-3">
        <img
          src={require("../../../assets/images/no_data_found.png")}
          style={{ height: "5rem", width: "auto" }}
        />
        <strong>No Data Found</strong>
      </div>
    </tr>
  );
};

export default BBPSReportTable;
