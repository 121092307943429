import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";
import { BASE_URL_API_TRANXT_API } from "../utils/Constants";
import { COMPANY_NAME_NAVBAR } from "../utils/StringConstants";
import cogoToast from "cogo-toast";
import { GlobalContext } from "../Context/GlobalState";
import { Stepper as Stepper2 } from "./Stepper";
import { ADMIN_SESSION_TIMER } from "../utils/Session";
import { useIdleTimer } from "react-idle-timer";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const PersonalDetails = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { setUserInfo, user, setTransactionLoaderState } =
    useContext(GlobalContext);
  const history = useHistory();
  const [kycData, setKycData] = useState({});
  const userId = JSON.parse(localStorage.getItem("kyc"));
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [verifiedKycData, setVerifiedKycData] = useState(null);
  const [formValues, setFormValues] = useState({
    FirstName: "",
    LastName: "",
    Gender: "",
    DOB: new Date(),
    Mobile: "",
    Email: "",
    Address: "",
    Pincode: "",
    City: "",
    State: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [State_Code, setStateCode] = useState([]);
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const steps = localStorage.getItem("steps");
  const kyc_by = sessionStorage.getItem("kyc_by")

  const fetchUserKycData = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data.status == 0 && data.data.length > 0) {
        if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
          if (kyc_by && kyc_by == "admin") {
          } else {
            //  history.push("/kyc/user-kyc-view");
          }
        }
        if (data.status != 1) {
          setKycData({ ...data.data[0], kyc_edit_disabled: data.data[0].relation == "N" });
          // setFormValues({
          //   ...formValues,
          //   FirstName: data.data[0].userfname,
          //   LastName: data.data[0].userlname,
          //   Gender: data.data[0].gender,
          //   DOB: new Date(data.data[0].dateofbirth),
          //   Mobile: data.data[0].usermobile,
          //   Email: data.data[0].emailid,
          //   // Address: data.data[0].useraddress,
          //   //  Pincode: data.data[0].userpincode,
          //   City: data.data[0].usercity,
          //   State: data.data[0].userstate,
          // });
          // setStartDate(new Date(data.data[0].dateofbirth));
        }
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const verificationData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setVerifiedKycData(data.data);
        setCheckBoxState(data.data.shop_and_home_address_check == "Y");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const insertVerifiedKycData = async (
    pan_verified,
    aadhaar_verified,
    pan_verified_name,
    aadhaar_verified_dob,
    aadhaar_verified_address,
    shop_and_home_address_check
  ) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
        pan_verified,
        aadhaar_verified,
        pan_verified_name,
        aadhaar_verified_dob,
        aadhaar_verified_address,
        shop_and_home_address_check,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/insert_verified_kyc_data.aspx`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } catch (error) { }
  };

  const toggleHomeAddress = async (status) => {
    if (verifiedKycData) {
      insertVerifiedKycData(
        verifiedKycData.pan_verified,
        verifiedKycData.aadhaar_verified,
        verifiedKycData.pan_verified_name,
        verifiedKycData.aadhaar_verified_dob,
        verifiedKycData.aadhaar_verified_address,
        status
      );
    }
  };

  useEffect(() => {
    verificationData();
    fetchUserKycData();
  }, []);
  useEffect(() => {
    if (checkBoxState) {
      if (formValues.FirstName == "") {
        let date = new Date();
        if (kycData.dateofbirth != "") {
          date = new Date(kycData.dateofbirth);
        }
        setFormValues({
          ...formValues,
          FirstName: kycData.userfname,
          LastName: kycData.userlname,
          Gender: kycData.gender,
          DOB: date,
          Mobile: kycData.usermobile,
          Email: kycData.emailid,
          // Address: kycData.useraddress,
          //  Pincode: kycData.userpincode,
          City: kycData.usercity,
          State: kycData.userstate,
          Address: kycData.shopaddress,
          Pincode: kycData.shoppincode,
        });
        setStartDate(date);
      }
    } else {
      let date = new Date();
      if (kycData.dateofbirth != "") {
        date = new Date(kycData.dateofbirth);
      }
      if (formValues.FirstName == "") {
        setFormValues({
          ...formValues,
          FirstName: kycData.userfname,
          LastName: kycData.userlname,
          Gender: kycData.gender,
          DOB: date,
          Mobile: kycData.usermobile,
          Email: kycData.emailid,
          Address: kycData.useraddress,
          Pincode: kycData.userpincode,
          City: kycData.usercity,
          State: kycData.userstate,
          Address: kycData.useraddress,
          Pincode: kycData.userpincode,
        });
        setStartDate(date);
      }
    }
  }, [kycData]);

  const Datehandler = (date) => {
    setFormValues({ ...formValues, DOB: date });
    const inputDate = new Date(date);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
    if (inputDate <= eighteenYearsAgo) {
    } else {
      setFormErrors({
        ...formErrors,
        DOB: "*Please enter Date before 18 years",
      });
    }
    setStartDate(date);
  };
  const redirect = () => {
    history.push("/kyc/document-details");
  };

  const Back = () => {
    history.push("/kyc/bussiness-details");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const dateToStr = (date) =>
    `${date.getFullYear()}-${date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    console.log(formValues);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      Personal();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.FirstName) {
      errors.FirstName = "*First Name is required!";
    }
    if (!values.LastName) {
      errors.LastName = "*Last Name is required!";
    }
    if (!values.Gender) {
      errors.Gender = "*Gender is required!";
    }
    if (!values.DOB) {
      errors.DOB = "*Date of birth is required!";
    }
    if (!values.Mobile) {
      errors.Mobile = "*Mobile number is required!";
    } else if (values.Mobile.length != 10) {
      errors.Mobile = "*Enter valid Mobile number";
    }
    if (!values.Email) {
      errors.Email = "*Email is required!";
    } else if (!regex.test(values.Email)) {
      errors.Email = "*This is not a valid email format!";
    }
    if (!values.Address) {
      errors.Address = "*Address is required!";
    } else if (values.Address.length <= 10) {
      errors.Address = "*Please enter valid address!";
    }
    if (!values.Pincode) {
      errors.Pincode = "*Pincode is required!";
    } else if (values.Pincode.length != 6) {
      errors.Pincode = "*Please enter valid pincode!";
    }
    const inputDate = new Date(formValues.DOB);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
    if (inputDate <= eighteenYearsAgo) {
    } else {
      errors.DOB = "*Please enter Date before 18 years";
    }

    return errors;
  };

  const Personal = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      steps: "2",
      userfname: formValues.FirstName,
      userlname: formValues.LastName,
      usermobile: formValues.Mobile,
      useraddress: formValues.Address,
      userpincode: formValues.Pincode,
      usercity: City,
      userstate: State,
      emailid: formValues.Email,
      statecode: State_Code.length > 0 ? State_Code.statecode : "",
      gender: formValues.Gender,
      dateofbirth: dateToStr(startDate),
      fkuserid: userId.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_API_TRANXT_API}/api/kyc/submitkyc.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            redirect();
            sessionStorage.setItem("MN", formValues.Mobile);
            localStorage.setItem("steps", 2);
            cogoToast.info(result.msg);
          } else {
            cogoToast.info(result.msg);
          }
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const StateCode = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Pincode: formValues.Pincode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      fetch(`${BASE_URL_API_TRANXT_API}/api/kyc/pindata.aspx`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.length > 0) {
            if (checkBoxState || State_Code.length == 0) setStateCode(result);
            setCity(result[0].city);
            setState(result[0].state);
            setFormValues({
              ...formValues,
              City: result[0].city,
              State: result[0].state,
            });

            console.log("Hiiiii");
          }
          setTransactionLoaderState(false);
        })
        .catch((err) => {
          setTransactionLoaderState(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formValues.Pincode.length == 6) {
      StateCode();
    }
  }, [formValues.Pincode]);

  useEffect(() => {
    console.log({ checkBoxState, verifiedKycData });
    if (checkBoxState) {
      if (kycData.shopaddress) {
        setFormValues({
          ...formValues,
          Address: kycData.shopaddress,
          Pincode: kycData.shoppincode,
        });
      } else {
        cogoToast.warn("Shop address Not Found");
      }
      if (verifiedKycData) toggleHomeAddress("Y");
    } else {
      setFormValues({
        ...formValues,
        Address: "",
        Pincode: "",
        usercity: "",
        userstate: "",
      });
      setStateCode([]);
      if (verifiedKycData) toggleHomeAddress("N");
    }
  }, [checkBoxState]);



  const { removeUserInfo } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    removeUserInfo();
  };

  const onActive = (event) => { };

  const onAction = (event) => { };

  const {
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: ADMIN_SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  return (
    <GlobalContext.Consumer>
      {(context) => (
        context.user == null ? <Redirect to="/user/dashboard" /> :
          (<div className="p-3" style={{ fontFamily: "auto" }}>
            <div
              className="card"
              style={{ backgroundColor: "aliceblue", textAlign: "center" }}
            >
              {/* <Stepper activeStep={steps} style={{ padding: "15px" }}>
            <Step label="Business Details" />
            <Step label="Personal Details" />
            <Step label="Document Details" />
            <Step label="Document Upload" />
            <Step label="Biometric KYC" />
          </Stepper> */}
              <Stepper2 step={2} />
              <div
                className="card-body m-3 mt-0 pt-0"
                style={{
                  borderRadius: "1rem",
                  boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                }}
              >
                <Row>
                  <small>
                    <p className="mb-2" style={{ color: "#5bdb5b" }}>
                      Business details updated successfully
                    </p>
                  </small>
                  <Col md={12} lg={12} sm={12} className="mb-3">
                    <h4>
                      <b>Personal Details</b>
                    </h4>
                  </Col>

                  <Col md={3} lg={3} sm={6}>
                    <Form.Label>
                      <b>First Name: </b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={kycData.kyc_edit_disabled}
                      type="text"
                      placeholder="Enter your first name here"
                      name="FirstName"
                      value={formValues.FirstName}
                      onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.FirstName}</p>
                  </Col>
                  <Col md={3} lg={3} sm={6}>
                    <Form.Label>
                      <b>Last Name: </b>
                    </Form.Label>
                    <Form.Control
                      disabled={kycData.kyc_edit_disabled}
                      type="text"
                      placeholder="Enter your Last name here"
                      name="LastName"
                      value={formValues.LastName}
                      onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.LastName}</p>
                  </Col>
                  <Col md={3} lg={3} sm={6}>
                    <Form.Label>
                      <b>Select Gender: </b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <div style={{ justifyContent: "space-evenly", display: "flex" }}>
                      <div>
                        <input
                          disabled={kycData.kyc_edit_disabled}
                          type="radio"
                          id="Male"
                          name="Gender"
                          value="Male"
                          checked={formValues.Gender === "Male"}
                          onChange={handleChange}
                        />
                        <label htmlFor="Male"> Male</label>
                      </div>

                      <div>
                        <input
                          disabled={kycData.kyc_edit_disabled}
                          type="radio"
                          id="Female"
                          style={{ marginLeft: "10px" }}
                          name="Gender"
                          value="Female"
                          checked={formValues.Gender === "Female"}
                          onChange={handleChange}
                        />
                        <label htmlFor="Female"> Female</label>
                      </div>

                      <div>
                        <input
                          disabled={kycData.kyc_edit_disabled}
                          type="radio"
                          id="Other"
                          style={{ marginLeft: "10px" }}
                          name="Gender"
                          value="Other"
                          checked={formValues.Gender === "Other"}
                          onChange={handleChange}
                        />
                        <label htmlFor="Other"> Other</label>
                      </div>
                      <p style={{ color: "red" }}>{formErrors.Gender}</p>
                    </div>
                  </Col>

                  <Col md={3} lg={3} sm={6}>
                    <Form.Label>
                      <b>Date of Birth:</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <DatePicker
                      disabled={kycData.kyc_edit_disabled}
                      dateFormat="dd/MM/yyyy"
                      value={formValues.DOB}
                      selected={formValues.DOB}
                      todayButton="Today"
                      onChange={Datehandler}
                      className="form-control"
                    />
                    <p style={{ color: "red" }}>{formErrors.DOB}</p>
                  </Col>

                  <Col md={6} lg={6} sm={12} className="mt-2">
                    <Form.Label>
                      <b>Mobile No. :</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={kycData.kyc_edit_disabled}
                      type="number"
                      placeholder="Enter mobile no."
                      name="Mobile"
                      value={formValues.Mobile}
                      onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.Mobile}</p>
                  </Col>
                  <Col md={6} lg={6} sm={12} className="mt-2">
                    <Form.Label>
                      <b>Email:</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={kycData.kyc_edit_disabled}
                      type="text"
                      placeholder="Enter email id"
                      name="Email"
                      value={formValues.Email}
                      onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.Email}</p>
                  </Col>

                  <Col md={12} lg={12} sm={12}>
                    <div className="mt-2">
                      <div style={{ textAlign: "center" }}>
                        <input
                          disabled={kycData.kyc_edit_disabled}
                          type="checkbox"
                          id="Same"
                          onClick={() => {
                            setCheckBoxState(!checkBoxState);
                          }}
                          checked={checkBoxState}
                        />
                        <label htmlFor="Same" style={{ color: "red" }}>
                          <small>
                            {" "}
                            Check this Box if your shop and home address is same
                          </small>
                        </label>
                      </div>
                      <h4>
                        <hr />
                        <b>Home Address</b>
                        <hr />
                      </h4>
                    </div>
                  </Col>

                  <Col md={6} lg={6} sm={12} className="mt-2">
                    <Form.Label>
                      <b>Address:</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={kycData.kyc_edit_disabled}
                      as="textarea"
                      placeholder="Enter complete address here"
                      name="Address"
                      value={formValues.Address}
                      onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.Address}</p>
                  </Col>
                  <Col md={6} lg={6} sm={12} className="mt-2">
                    <Form.Label>
                      <b>Pincode:</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={kycData.kyc_edit_disabled}
                      type="number"
                      placeholder="Enter pincode here"
                      name="Pincode"
                      value={formValues.Pincode}
                      onChange={handleChange}
                    />
                    <p style={{ color: "red" }}>{formErrors.Pincode}</p>
                  </Col>
                  <Col md={6} lg={6} sm={12} className="mt-2">
                    <Form.Label>
                      <b>City:</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter city"
                      name="City"
                      disabled={kycData.kyc_edit_disabled ? true : State_Code.length > 0 ? false : true}
                      value={formValues.City}
                    />
                  </Col>
                  <Col md={6} lg={6} sm={12} className="mt-2">
                    <Form.Label>
                      <b>State:</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter state"
                      name="State"
                      disabled={kycData.kyc_edit_disabled ? true : State_Code.length > 0 ? false : true}
                      value={formValues.State}
                    />
                  </Col>

                  <Col md={6} lg={6} sm={12}>
                    <small>
                      <p className="mt-3" style={{ color: "red" }}>
                        All Fields(*) marked are mandatory
                      </p>
                    </small>
                  </Col>

                  <Col md={3} lg={3} sm={6} className="mt-4">
                    <Form.Group>
                      <Button className="btn-block" variant="info" onClick={Back}>
                        Back
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col md={3} lg={3} sm={6} className="mt-4">
                    <Form.Group>
                      <Button
                        className="btn-block"
                        variant="success"
                        onClick={handleSubmit}
                      >
                        Next
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </div>)
      )}
    </GlobalContext.Consumer>



  );
};

export default PersonalDetails;
