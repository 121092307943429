
import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API, BASE_URL_APISERVICES } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { RaiseTicketModal1 } from "../util/RaiseTicketModel1";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "tid",
    text: "REF",
  },

  {
    dataField: "pk_notification_id",
    text: "pk_notification_id",
  },
  {
    dataField: "fk_user_id",
    text: "fk_user_id",
  },
  {
    dataField: "notification_title",
    text: "notification_title",
  },
  {
    dataField: "notification_description",
    text: "notification_description",
  },
  {
    dataField: "enabled",
    text: "enabled",
  },
  {
    dataField: "closable",
    text: "closable",
  },
  {
    dataField: "content",
    text: "content",
  },
  {
    dataField: "shop",
    text: "shop",
  },
  {
    dataField: "content",
    text: "content",
  },
];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const AllCreditCardKycReport = () => {
  const [data, setData] = useState([]);
  const [clickedPDFURL, setClickedPDFURL] = useState("");
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [raiseTicketModal1, setRaiseTicketModal1] = useState(false);
  const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  const [clickedImageURL, setClickedImageURL] = useState("");

  function checkImage(url) {
    var img = new Image();
    img.onload = function () {
      console.log('Image loaded successfully.');
      // Perform actions when image is successfully loaded
    };
    img.onerror = function () {
      console.log('Error loading image.');
      // Perform actions when there is an error loading the image
    };
    img.src = url;
  }
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: "",
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        creditCard: "",
        aadhaarNo: ""
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/admin/getAllCreditCardKycReport`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };



  //   const refundDMTTransaction = async (tid) => {
  //     try {
  //       setTransactionLoaderState(true);
  //       var myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");

  //       var raw = JSON.stringify({
  //         trans_id: tid,
  //       });

  //       var requestOptions = {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow",
  //       };

  //       const res = await fetch(
  //         `${BASE_URL_API_TRANXT_API}/api/admin/refunds/dmttrans_refund.aspx`,
  //         requestOptions
  //       );
  //       const data = await res.json();
  //       if (data.status == "0") {
  //         swal("Success", data.msg, "success");
  //       } else {
  //         swal("Failed", data.msg, "error");
  //       }
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };
  //   const refundVerification = (tid, amount, RetailerID) => {
  //     swal({
  //       title: "Are you sure?",
  //       text: `Refund amount of ${amount} to ${RetailerID}`,
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         refundDMTTransaction(tid);
  //       }
  //     });
  //   };

  //   const showResponse = (response) => {
  //     swal2(
  //       <div className="d-flex overflow-auto">
  //         <strong>{JSON.stringify(response)}</strong>
  //       </div>
  //     );
  //   };

  //   const raiseTicketShow = (data) => {
  //     setRaiseTicketDetails({
  //       tid: data.tid,
  //       category: "DMT",
  //       user_id: data.username.toString().split("/")[0],
  //     });
  //     setRaiseTicketModal1(true);
  //   };

  useEffect(() => {
    getDMTReport();
  }, []);

  const positionStyle1 = {
    position: 'absolute',
    top: -38,
    left: 0,
    "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
  };

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '20%', // Adjust the width as needed
    height: '20%',

  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily": "Poppins",
    "fontWeight": "400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };

  const openInNewTab = (url) => {

    const newWindow = window.open(
      url,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img
            src={clickedImageURL}
            alt="Clicked"
            onError={() => {
              console.log('Failed to load JPEG image, switching to PDF.');
              setClickedImageURL(null); // Reset clickedImageURL
              const pdfUrl = clickedImageURL.toString().replace('jpeg', 'pdf');
              openInNewTab(pdfUrl); // Set PDF URL
            }}
          />
        </div>
      )}



      <div style={{ padding: "1rem" }} className="bg-light ">
        <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
          <div className="card-body m-0 p-2">
            <div>

              <  div className="row m-0 p-0  pt-1 align-items-center">
                <div className="col-3 m-0 p-0">

                  <div className="m-0 p-0 pl-2"
                    style={{
                      "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                      , padding: "0px",
                      background: "#FFFFFF",
                      color: "#4267B2",
                      fontWeight: "bolder",
                      fontfamily: "Poppins !impotent",
                      fontSize: "28px",
                    }}
                  >
                    Credit Card Kyc Report
                  </div>
                </div>

                <div className="col-9 m-0 p-0 ">
                  <div className="row  m-0 p-0 justify-content-end">

                    <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                      <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Search"
                        aria-label="Search"
                        style={inputStyle}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        value={searchText}
                      />
                    </div>





                    <div className="pr-1 pl-2 p-0 m-0" style={{ width: '12%', height: '20%', }}>
                      <Form.Group className="p-0 m-0">

                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control rounded-100"
                          placeholderText="Start Date"
                        />
                      </Form.Group>
                    </div>
                    <div className="px-1  p-0 m-0" style={{ width: '12%', height: '20%', }}>
                      <Form.Group className="p-0 m-0">

                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="form-control rounded-100"
                          placeholderText="End Date"
                        />
                      </Form.Group>
                    </div>


                    <div className="pl-1   p-0 m-0" style={{ width: '12%', height: '20%', }}>
                      <Form.Group className="mt-0 p-0 m-0">
                        <Button
                          className="rounded-50 btn btn-primary btn-lg btn-block "

                        onClick={getDMTReport}
                        >
                          {"Search"}
                        </Button>
                      </Form.Group>
                    </div>
                    <div className="m-0 p-0 hover-container "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        ExportToExcel(
                          `Credit_Card_Kyc_Table${now()}`,
                          excelMapping(data, columns)
                        );
                      }}
                    >
                      <img className="pl-1"
                        src={require("../../../assets/images/download Icon.png")}
                        style={{ width: `3.5rem`, height: '85%' }}
                      />
                      <div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
                    </div>
                  </div>




                </div>
              </div>

              <>


                <hr className="m-0 p-0  " />

                <div className="scroll-inner admin-fix-width borderless-table ">
                  <table
                    className="my-table transparent-table text-center "
                    style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}
                  >
                    <tr className="borderless-table border-bottom text-center ">
                      <th className="my-table transparent-table py-3 text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark' }}> {"Sr"}</th>

                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"Card Digit"}</th>
                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"User"}</th>
                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"Castumer"}</th>
                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"Doc Detail"}</th>
                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"Sales Person"}</th>
                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"Status"}</th>
                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"Date"}</th>
                      <th className="my-table transparent-table text-center " style={{ fontSize: "0.9rem", border: "none", color: 'dark', "fontFamily": "Poppins", }}> {"Action"}</th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                      <tr className="my-table transparent-table border-bottom py-5 text-center">
                        <td style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          {" "}
                          <div className="d-flex flex-column align-items-center py-3 p-0 m-0  text-center">
                            {ind + 1}
                          </div>

                        </td>

                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>{e.credit_card_digit}</td>
                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          <div>{e.user_code}</div>
                          <div>{e.user_name}</div>
                          <div>{e.user_mobile}</div>
                        </td>
                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          <div>{e.customer_name}</div>
                          <div>{e.customer_mobile}</div>
                          <div>{e.customer_email}</div>
                        </td>
                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          <div>{e.aadhaar_no}</div>
                          <div>{e.customer_pan}</div>
                        </td>
                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          <div>{e.sales_user_code}</div>
                          <div>{e.sales_user_name}</div>
                          <div>{e.sales_user_mobile}</div>
                        </td>
                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          <div className="m-0 p-0">
                            {e.status == "Success" ? (
                              <span
                                className="m-0 p-0 px-2 py-2"
                                style={{


                                  borderRadius: "5px",
                                  border: "1px solid",
                                  backgroundColor: 'rgba(255, 46, 46, 0.1)',
                                  color: 'rgba(255, 46, 46, 1)',
                                  bordercolor: 'rgba(255, 46, 46, 1)',

                                }}

                              >
                                {`${e.status}`}
                              </span>

                            ) : (
                              <span
                                style={{

                                  borderRadius: "5px",
                                  border: "1px solid",
                                  backgroundColor: 'rgba(43, 193, 85, 0.1)',
                                  color: 'rgba(43, 193, 85, 1)',
                                  bordercolor: 'rgba(43, 193, 85, 1)',

                                }} className="px-2 py-2"
                              >
                                {`${e.status}`}
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          <div>{e.initiatedOn}</div>
                          <div>{e.updatedOn}</div>
                        </td>
                        <td className="text-center" style={{ fontSize: "0.8rem", "fontFamily": "Poppins", }}>
                          <Button
                            style={{
                              borderRadius: "5px",
                              border: "1px none",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              color: "rgba(255, 164, 27, 1)",
                              bordercolor: "rgba(255, 255, 255, 1)",
                            }}
                            className="btn btn-sm  rounded-100 m-0 p-0"
                            onClick={() => {
                              setClickedImageURL(e.document);
                            }}
                          >
                            {" "}
                            <div className="hover-container">
                              {/* Your content here */}
                              <img
                                src={require("../../../assets/images/Raise Ticket.png")}
                                style={{ width: "" }}
                              />
                              <div
                                style={positionStyle1}
                                className="hover-text bg-dark"
                              >
                                {" "}
                                Agreement 1
                              </div>
                            </div>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                  {data.length <= 0 && <NoDataFound />}
                </div>
                <Pagination
                  page={currentPage}
                  limit={pages}
                  callback={(page) => {
                    setCurrentPage(page);
                  }}
                  count={dataToDisplay.length}
                  total={total}
                  callback2={(offsetValue) => {
                    setOffset(offsetValue);
                  }}
                />
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (

          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export const NoDataFound = () => {
  return (
    <tr className="row w-100 justify-content-center">
      <div className="d-flex flex-column align-items-center p-3">
        <img
          src={require("../../../assets/images/no_data_found.png")}
          style={{ height: "5rem", width: "auto" }}
        />
        <strong>No Data Found</strong>
      </div>
    </tr>
  );
};

export default AllCreditCardKycReport;