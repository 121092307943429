import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import './hoverprint.css'; 
import {
  excelMapping,
  ExportToExcel,
  formatDateDDMMYYHHMM,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const DMTReportTable = ({ columns,agentId }) => {
  const [data, setData] = useState([]);
  const { user } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data,  })
      // shopName: Profileinfo.shopname
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT / IMPS`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getDMTReport = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let remitterMobile = "";
    if (mobile.length > 9) {
      remitterMobile = mobile;
    }

    var raw = JSON.stringify({
      UserID: agentId,
      fromdate: dateFormatter(startDate),
      todate: dateFormatter(endDate),
      mobile: remitterMobile,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_dmt_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0" && result.data) {
            setData(result.data);
          } else {
            setData([]);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  // did mount
  useEffect(() => {
    if (agentId && agentId.length > 4) getDMTReport();
  }, [agentId]);

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height:'20%',
   
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <>
      <div
        style={{
         
        }}
      >
        <div
          style={{
            
          }}
        >
          <div
            style={{
              
            }}
          >

<div className="row m-0 p-0  py-2 align-items-center">
              <div className="col-4 m-0 p-0">

               <div className="m-0 p-0 pl-3"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                  }}
                >
                 DMT Report
                </div>
                </div>

<div className="col-8 m-0 p-0 ">
<div className="row  m-0 p-0  justify-content-end">
        
                <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>
        
               
        
        
        
        <div className=" pl-2 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="pr-1 pl-2  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

      
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
          <Button
                    className="rounded-100 btn btn-lg btn-block"
                   
                    onClick={getDMTReport}
                  >
                    {"Search"}
                  </Button>
          </Form.Group>
        </div>
        <div className="m-0 p-0 hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              //  ExportToExcel(`AEPS_Report_${now()}`, dataToDisplay);
                ExportToExcel(
                  `DMT_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
          >
              <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
            <div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
      </div>




</div>

      </div>

      <hr className="m-0 p-0"/>























            </div>
          </div>
        </div>

        <div className="my-table scroll-inner admin-fix-width transparent-table">
          <table style={{  width: "100%" ,backgroundColor: '#FFFFFF'}} className="my-table  borderless transparent-table">
            <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
              <th className="my-table transparent-table text-center border-bottom py-3"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}> {"Sr"}</th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>REF</th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>
                Remitter No
              </th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>
                Beneficiary
              </th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>
                UTR NO
              </th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>
                Amount
              </th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>Fee</th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>Comm</th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>TDS</th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>Status</th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}>Date&Time</th>
              <th className="my-table transparent-table text-center border-bottom"style={{"fontFamily":"Poppins", backgroundColor: '#FFFFFF',}}> {"Action"}</th>
            </tr>
            {dataToDisplay.map((e, ind) => (
              <tr className="border-bottom">
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {ind + 1}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.tid}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.remitterno}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                  <div className="d-flex flex-column align-items-center text-center">
                    <span>{e.benfname}</span>
                    <span >{e.rch_mobile}</span>
                    <span>{e.fk_provider_id}</span>
                  </div>
                </td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.utnno}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.rch_amount}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.rch_com_retailer}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.dmt_comission}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{twoDecimalPlaces(e.tds_retailer)}</td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                  {e.rch_status_desc.toString().toLowerCase() == "success" ? (
                    <label
                    style={{
                      paddingLeft:"3px",
                      paddingRight:"3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(43, 193, 85, 0.1)',
                      color:'rgba(43, 193, 85, 1)',
                      bordercolor: 'rgba(43, 193, 85, 1)',
  
                    }}
                    >
                      {"Success"}
                    </label>
                  ) : (
                    <label
                    style={{
                      paddingLeft:"3px",
                      paddingRight:"3px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid",
                      backgroundColor: 'rgba(254, 128, 36, 0.1)',
                      color:'rgba(254, 128, 36, 1)',
                      bordercolor: 'rgba(254, 128, 36, 1)',
  
                    }}
                    >
                      {e.rch_status_desc}
                    </label>
                  )}
                </td>
                <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                  {e.rch_indate}
                </td>
                <td className="text-center border-bottom p-0 m-0" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
                  <div className="row d-flex justify-content-center p-0 m-0">
                    {" "}
                    <div className="col-6 p-0 m-0 ">
                    <Button 
                  style={{
                   
                    borderRadius: "5px",
                    border: "1px none",
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color:'rgba(255, 164, 27, 1)',
                    bordercolor: 'rgba(255, 255, 255, 1)',
                    "fontSize":"4px !important"
                  }}
                  className="btn rounded-100  m-0 p-0 hover-container">
                    {" "}
                    <div 
                  
                    
                      onClick={() => {
                        openInNewTab(e);
                      }}
                    >
                                         <img
                                    src={require("../../../assets/images/printer.png")}
                                    style={{ width: "" }}
                                    />
                      {/* <div style={{  color:'rgba(232, 129, 36, 1)', }} className="hover-text pl-2">Print</div> */}
                      <div style={{"fontFamily":"Poppins",  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark text-center px-2 py-1 mb-2">Print</div>
                    </div>

                  
                  </Button>






                   
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>

        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page) => {
            setCurrentPage(page);
          }}
          count={dataToDisplay.length}
          total={total}
          callback2={(offsetValue) => {
            setOffset(offsetValue);
          }}
        />
     
    </>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
   
    borderRadius:'0px',
    "fontFamily":"Poppins",
    'fontsize': '16px', 
    borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
    borderRadius:'0px',
    borderLeft: 'none', // Remove right side border
    borderTop: 'none', // Remove top border
    borderBottom: 'none', // Remove bottom border
    "fontFamily":"Poppins",
    'fontsize': '16px', 
   



  };
  const selectStyleBorder = {
    border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
    borderRadius:'8px',
    "fontFamily":"Poppins",
    'fontsize': '16px', 
  };
  const selectStyle = {
 
    border: 'none', // Remove the border
    "fontFamily":"Poppins",
    'fontsize': '16px', 

  };
  return (
    <div
    className="row"
    style={{
      display: "flex",
      alignItems: "end",
      width: "100%",
      margin: "10px 0px",
      justifyContent: "space-between",
      
    }}
  >
    <div style={{"fontFamily":"Poppins",}}>
      <strong>{`Showing ${count} of ${total} entries`}</strong>
    </div>
   
   
    <div className="d-flex btn-group p-1"    style={selectStyleBorder}>
      <select
       style={selectStyleLeftBorder}
        className=" d-flex justify-content-end "
        aria-label="offset"
        onChange={(e) => {
          callback2(e.target.value);
        }}
        
      >
        {offsetArr.map((offset) => (
          <option value={offset} className="d-flex justify-content-end">{offset}</option>
        ))}
        
      </select>
      {page - 2 > 0 ? (
        <Button
          onClick={() => {
            callback(page - 1);
          }}
        
          className="rounded-0 bg-white text-dark border-white"
          
        >
          {"<<"}
        </Button>
      ) : null}

      {page - 1 > 0 ? (
        <Button
          onClick={() => {
            callback(page - 1);
          }}
         
          className="rounded-0 bg-white text-dark border-white underline-text"
        >
          {" "}
          {(page - 1).toString()}
        </Button>
      ) : null}
     
     
     
      <Button className="bg-white text-primary "   style={selectStyle}> {page.toString()}</Button>
    
    
    
    
    {/* Next And Previous Button Code */}
      {page + 1 <= limit ? (
        <Button
          onClick={() => {
            callback(page + 1);
          }}
         
          className="rounded-0 bg-white text-dark border-white"
        >
          {">>"}
        </Button>
      ) : null}
    </div>
  </div>
  );
};

export default DMTReportTable;
