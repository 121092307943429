import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Line, Doughnut } from "react-chartjs-2";
import "c3/c3.css";
import { Redirect } from "react-router";
import swal from "sweetalert";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import { DmtChart, LineChart } from "../../consumer/dashboard/Dashboard";
import {
  addRemitterValidation,
  dateFormatter,
  formatDateDDMMYYHHMM,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { DROP_SHADOW, MONTHS } from "../../utils/CommonConfig";
import { useHistory } from "react-router";
import DMTIcon from "../../../assets/svg/dmt_nav.svg";
import AEPSIcon from "../../../assets/svg/aeps_nav.svg";
import SettlementIcon from "../../../assets/svg/settlement_nav.svg";
import FlightIcon from "../../../assets/svg/flight_nav.svg";
import ReportIcon from "../../../assets/svg/report_nav.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";

// Services icon
import Cable_2 from "../../../assets/svg/cable_2.svg";
import Electricity_2 from "../../../assets/svg/electricity_2.svg";
import Dth_2 from "../../../assets/svg/dth_2.svg";
import PrepaidRecharge_2 from "../../../assets/svg/prepaid_recharge_2.svg";
import WaterBill_2 from "../../../assets/svg/water_bill_2.svg";
import PipedGas_2 from "../../../assets/svg/piped_gas_2.svg";
import Broadband_2 from "../../../assets/svg/broadband_2.svg";
import BusTicketIcon_2 from "../../../assets/svg/bus_ticket_2.svg";
import EducationIcon_2 from "../../../assets/svg/education_2.svg";
import FastagIcon_2 from "../../../assets/svg/fastag_2.svg";
import FlightTicketIcon_2 from "../../../assets/svg/flight_ticket_2.svg";
import GasCylinderIcon_2 from "../../../assets/svg/gas_cylinder_2.svg";

import Cable from "../../../assets/svg/cable.svg";
import Aeps from "../../../assets/svg/aeps.svg";
import Settlement from "../../../assets/svg/settlement.svg";
import cms from "../../../assets/svg/cms.svg";
// import bbps from "../../../assets/svg/offline_bbps.svg";
import Wallet from "../../../assets/svg/wallet.svg";
import analytics from "../../../assets/icons/ic_bbps_report.png";
import dmt from "../../../assets/svg/dmt.svg";
import pg from "../../../assets/svg/micro_atm.svg";
import BbpsOffline from "../../../assets/svg/postpaid_recharge.svg";
import analytics2 from "../../../assets/svg/sales.svg";
import VendorPayment from "../../../assets/svg/transactions.svg";
import bbps from "../../../assets/svg/plans.svg";
import Select from "react-select";
export const dropShadow = DROP_SHADOW;

const services = [
  { name: "DMT" },
  { name: "AEPS" },
  { name: "BBPS" },
  { name: "BBPS Offline" },
  { name: "CMS" },
];

const navbarServices = [
  { title: "BBPS", image: DMTIcon, url: "/retailer/bill_payment" },
  { title: "Recharge", image: AEPSIcon, url: "/retailer/offline/recharge" },
  //   {
  //     title: "Settlement",
  //     image: SettlementIcon,
  //     url: "/retailer/aeps-settlement",
  //   },
  //   { title: "Flight", image: FlightIcon, url: "/retailer/flight" },
  { title: "Transactions", image: ReportIcon, url: "/retailer/transactions" },
];

export const Dashboard3 = () => {
  const history = useHistory();
  let basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  const { username, password } = basicAuth;
  const [lineChartData, setLineChartData] = useState({
    dmt: [],
    aeps: [],
    bbps: [],
    settlement: [],
    dmtTotal: 0,
    aepsTotal: 0,
    bbpsTotal: 0,
    settlementTotal: 0,
  });
  const [lineChartData2, setLineChartData2] = useState({
    dmt: [],
    aeps: [],
    bbps: [],
    settlement: [],
  });
  const [filterState, setFilterState] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [filterState2, setFilterState2] = useState("1");
  const { token, myAccountInfo, user  , setTransactionLoaderState} = useContext(GlobalContext);
  const [serviceSelected, setServiceSelected] = useState(5);
  const accountOptions = [
    { value: "1", label: "Today" },
    { value: "7", label: "Weekly" },
    { value: "30", label: "Monthly" },
  ];

  const [dailyData, setDailyData] = useState({
    dmtTotal: 0.0,
    dmtCount: 0.0,
    aepsTotal: 0.0,
    aepsCount: 0.0,
    bbpsTotal: 0.0,
    bbpsCount: 0.0,
  });

  const [businessData, setBusinessData] = useState({});

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getUserBusiness = async (interval) => {
    try {
      setTransactionLoaderState(true)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

      var raw = JSON.stringify({
        userid: user.ReferId,
        interval: interval

      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/User/v1.0/getUserBusiness",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00" && data.data) {
        setBusinessData(data.data);
        setTransactionLoaderState(false)
      } else {
        console.log("Unexpected Response:", data);
        setTransactionLoaderState(false)

      }
    } catch (error) {
      console.log("API Error:", error);
      setTransactionLoaderState(false)

    }
  };


  useEffect(() => {
    getUserBusiness();
  }, []);

  const getLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_daily.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        const { aeps, dmt, bbps, settlement } = data;
        let dmtTotal = 0,
          aepsTotal = 0,
          bbpsTotal = 0,
          settlementTotal = 0;
        dmt.forEach((tranx) => {
          dmtTotal += Number(tranx.amount);
        });
        aeps.forEach((tranx) => {
          aepsTotal += Number(tranx.amount);
        });
        bbps.forEach((tranx) => {
          bbpsTotal += Number(tranx.amount);
        });
        settlement.forEach((tranx) => {
          settlementTotal += Number(tranx.amount);
        });

        setLineChartData({
          aeps,
          bbps,
          dmt,
          settlement,
          dmtTotal,
          aepsTotal,
          bbpsTotal,
          settlementTotal,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getHourlyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        date: dateFormatter(new Date()),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_hourly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
        const { aeps, dmt, bbps, settlement } = data;
        let dmtTotal = 0,
          dmtCount = 0,
          aepsTotal = 0,
          aepsCount = 0,
          bbpsTotal = 0,
          bbpsCount = 0;
        dmt.forEach((tranx) => {
          dmtTotal += Number(tranx.amount);
          dmtCount += Number(tranx.count);
        });
        aeps.forEach((tranx) => {
          aepsTotal += Number(tranx.amount);
          aepsCount += Number(tranx.count);
        });
        bbps.forEach((tranx) => {
          bbpsTotal += Number(tranx.amount);
          bbpsCount += Number(tranx.count);
        });
        setDailyData({
          dmtTotal,
          dmtCount,
          aepsTotal,
          aepsCount,
          bbpsTotal,
          bbpsCount,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getDailyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_daily.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getWeeklyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_weekly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getMonthlyLineChartData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_line_chart_data_monthly.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setLineChartData2({
          ...lineChartData2,
          dmt: data.dmt,
          aeps: data.aeps,
          bbps: data.bbps,
          settlement: data.settlement,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserNotifications = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/get_user_notifications.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setNotifications(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFilterLabel = () => {
    if (filterState == 0) {
      return "Daily";
    } else if (filterState == 1) {
      return "Weekly";
    } else if (filterState == 2) {
      return "Monthly";
    } else if (filterState == 3) {
      return "Annual";
    }
    return "";
  };

  useEffect(() => {
    if (filterState == 0) {
      getHourlyLineChartData();
    } else if (filterState == 1) {
      getDailyLineChartData();
    } else if (filterState == 2) {
      getWeeklyLineChartData();
    } else if (filterState == 3) {
      getMonthlyLineChartData();
    }
  }, [filterState]);

  useEffect(() => {
    getUserNotifications();
    getLineChartData();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div className="bg-white-theme p-0">
            <div className="row m-0 p-2">
              {notifications.map((notification) => (
                <NotificationBanner notification={notification} />
              ))}
            </div>

            <div className="row d-flex justify-content-end align-items-center">
      <div className="col-8"></div>
      <div className="col-3 m-2 p-2">
        <Form.Group>
          <Form.Label>
            <span style={{ color: "red" }}>*</span>
            {` Select Duration`}
          </Form.Label>
          <Select options={accountOptions } onChange={(e)=>{
              getUserBusiness(e.value)
          }} />
        </Form.Group>
      </div>
    </div>

            <div
              className="row m-2 p-2"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              {[
                { title: "Total Business", value: businessData.totalBusiness },
                { title: "Total Charges", value: businessData.totalCharges },
                { title: "Total Profit", value: businessData.totalProfit },
                { title: "Refund", value: businessData.refund },
              ].map((item, index) => (
                <div
                  key={index}
                  className="col"
                  style={{
                    flex: "1",
                    margin: "10px",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow =
                      "0 8px 20px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow =
                      "0 4px 12px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: "10px",
                    }}
                  >
                    {item.title}
                  </h4>
                  <p
                    style={{
                      fontSize: "1.2rem",
                      margin: "0",
                      color: item.title === "Total Charges" ? "red" : "#4CAF50",
                      fontWeight: "600",
                    }}
                  >
                    ₹ {twoDecimalPlaces(item.value)}
                  </p>
                </div>
              ))}
            </div>

            <ServicesNavbar />

            <div
              className="row m-2 p-2"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              {[
                { title: "AEPS", value: businessData.aeps },
                {
                  title: "AEPS Settlement",
                  value: businessData.aeps_settlement,
                },
                { title: "PG", value: businessData.pg },
                { title: "PG Settlement", value: businessData.pg_settlement },
              ].map((item, index) => (
                <div
                  key={index}
                  className="col"
                  style={{
                    flex: "1",
                    margin: "10px",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow =
                      "0 8px 20px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow =
                      "0 4px 12px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: "10px",
                    }}
                  >
                    {item.title}
                  </h4>
                  <p
                    style={{
                      fontSize: "1.2rem",
                      color: "#4CAF50",
                      margin: "0",
                    }}
                  >
                    ₹ {twoDecimalPlaces(item.value)}
                  </p>
                </div>
              ))}
            </div>

            <div
              className="row m-2 p-2"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "20px",
              }}
            >
              {[
                { title: "DMT", value: businessData.dmt },
                { title: "BBPS", value: businessData.bbps },
                {
                  title: "Vendor Payments",
                  value: businessData.vendor_payments,
                },
                { title: "CMS", value: businessData.cms },
              ].map((item, index) => (
                <div
                  key={index}
                  className="col"
                  style={{
                    flex: "1",
                    margin: "10px",
                    padding: "20px",
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow =
                      "0 8px 20px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow =
                      "0 4px 12px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: "10px",
                    }}
                  >
                    {item.title}
                  </h4>
                  <p
                    style={{
                      fontSize: "1.2rem",
                      color: "#4CAF50",
                      margin: "0",
                    }}
                  >
                    ₹ {twoDecimalPlaces(item.value)}
                  </p>
                </div>
              ))}
            </div>

            <div className="row m-0">
              <DashboardWalletCard />
              <DashboardEarnCard />
              <DashboardPredictCard />
              {/* <DashboardProsperCard /> */}
            </div>

            {/* {lineChartData.dmt.length > 0 && (
              <div
                className="row m-2 mx-4"
                style={{
                  boxShadow: dropShadow,
                  background: "#FFF",
                  borderRadius: "1rem",
                }}
              >
                <DmtChart
                  title={"DMT"}
                  amount={lineChartData.dmtTotal}
                  data={lineChartData.dmt}
                  dailyCount={dailyData.dmtCount}
                  dailyAmount={dailyData.dmtTotal}
                />
                <div className="col-12 col-md-8 col-sm-6">
                  <DMTReports />
                </div>
              </div>
            )} */}

            {/* {lineChartData.aeps.length > 0 && (
              <div
                className="row m-2 mx-4"
                style={{
                  boxShadow: dropShadow,
                  background: "#FFF",
                  borderRadius: "1rem",
                }}
              >
                <DmtChart
                  title={"AEPS"}
                  amount={lineChartData.aepsTotal}
                  data={lineChartData.aeps}
                  dailyCount={dailyData.aepsCount}
                  dailyAmount={dailyData.aepsTotal}
                />
                <div className="col-12 col-md-8 col-sm-6">
                  <AEPSReports />
                </div>
              </div>
            )} */}
            {lineChartData.bbps.length > 0 && (
              <div
                className="row m-2 mx-4"
                style={{
                  boxShadow: dropShadow,
                  background: "#FFF",
                  borderRadius: "1rem",
                }}
              >
                <DmtChart
                  title={"BBPS"}
                  amount={lineChartData.bbpsTotal}
                  data={lineChartData.bbps}
                  dailyCount={dailyData.bbpsCount}
                  dailyAmount={dailyData.bbpsTotal}
                />
                <div className="col-12 col-md-8 col-sm-6">
                  <BBPSReports />
                </div>
              </div>
            )}

            <div
              className="m-1 mx-4 p-1"
              style={{
                boxShadow: dropShadow,
                borderRadius: "1rem",
                background: "#FFF",
              }}
            >
              <div className="col-12 d-flex align-items-center">
                <div className="col-12 col-md-4 d-flex flex-column p-0">
                  <strong>{getFilterLabel()}</strong>
                  {/* <strong>₹ 0 (204.17%)</strong>
                  <span> Compared to 01 Feb 2023 - 28 Feb 2023</span> */}
                </div>
                <div className="col-12 col-md-8 p-0 d-flex">
                  <div className="col-12">
                    <div className="d-flex justify-content-end my-2">
                      <div
                        className="btn-group btn-group-sm"
                        role="group"
                        aria-label="Basic outlined"
                      >
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 0
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(0);
                          }}
                        >
                          Daily
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 1
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(1);
                          }}
                        >
                          Weekly
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 2
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(2);
                          }}
                        >
                          Monthly
                        </button>
                        <button
                          type="button"
                          className={`btn ${
                            filterState == 3
                              ? "btn-outline-primary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => {
                            setFilterState(3);
                          }}
                        >
                          Annual
                        </button>
                      </div>
                      <div
                        className="rounded-5 px-2 py-0 mx-1"
                        style={{
                          border: "1px solid #DDDDDD",
                          borderRadius: "5px",
                        }}
                      >
                        <i className="mdi mdi-menu text-primary"></i>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            padding: "6px",
                            background: "transparent",
                            fontSize: ".8rem",
                          }}
                          onChange={(e) => {
                            setFilterState2(e.target.value);
                          }}
                        >
                          <option value={"1"}>By Total Transactions</option>
                          <option value={"2"}>By Total Volume</option>
                        </select>
                      </div>

                      {/* <div
                        className="rounded-2 px-2 py-1 ml-1 align-items-center"
                        style={{
                          border: "1px solid #DDDDDD",
                        }}
                      >
                        <i className="mdi mdi-download text-primary"></i>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <LineChart
                aeps={[]}
                dmt={[]}
                bbps={lineChartData2.bbps}
                settlement={[]}
                filter={filterState2}
              />
            </div>

            {/* <div
              className="row m-2"
              style={{
                boxShadow: dropShadow,
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <div className="col-12 col-md-6">
                <div
                  className="card border-0"
                  style={{ background: "#EDF7FF", overflow: "hidden" }}
                >
                  <DoughnutChart />
                </div>
              </div>
              <div className="col-12 col-md-6 m-0">
                <div
                  className="card"
                  style={{ background: "#EDF7FF", overflow: "hidden" }}
                >
                  <h5>Recent Activity</h5>
                  <RecentActivity />
                </div>
              </div>
            </div> */}
            {/* <Transactions /> */}
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export const DoughnutChart = () => {
  const doughnutPieData = {
    datasets: [
      {
        data: [30, 40, 30],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Web", "Android", "Both"],
  };

  const doughnutPieOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div className="row m-0 p-1">
      <div className="d-flex justify-content-between align-items-center w-100">
        <h5>Traffic split on platforms</h5>
        <div className="d-flex">
          <div
            className="rounded-2 px-2 py-0"
            style={{ border: "1px solid #DDDDDD" }}
          >
            <i className="mdi mdi-menu text-primary"></i>
            <select
              style={{
                border: "none",
                outline: "none",
                padding: "6px",
                background: "transparent",
                fontSize: ".8rem",
              }}
            >
              <option>By Total transaction</option>
              <option>By Total Volume</option>
            </select>
          </div>

          {/* <div
            className="rounded-2 px-2 py-1 ml-1 align-items-center"
            style={{ border: "1px solid #DDDDDD" }}
          >
            <i className="mdi mdi-download text-primary"></i>
          </div> */}
        </div>
      </div>
      <div className="col-12 m-0 p-0">
        <Doughnut data={doughnutPieData} options={doughnutPieOptions} />
      </div>
      <div className="col-12 d-flex justify-content-center">
        <div
          className="btn-group my-2"
          role="group"
          aria-label="Basic outlined"
        >
          <button type="button" className="btn btn-outline-primary">
            100%
          </button>
          <button type="button" className="btn btn-outline-primary">
            Android
          </button>
          <button type="button" className="btn btn-outline-primary">
            288
          </button>
        </div>
      </div>
    </div>
  );
};

export const DashboardWalletCard = () => {
  const { user } = useContext(GlobalContext);
  const [earning, setEarning] = useState({
    dmt: "0.00",
    aeps: "0.00",
    bbps: "0.00",
    settlement: "0.00",
  });
  const [daily, setDaily] = useState([]);
  const [weekly, setWeekly] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [annualy, setAnnualy] = useState([]);
  const [filter, setFilter] = useState(0);
  const [walletMovement, setWalletMovement] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  const getEarnings = async (startDate, endDate) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_wallet_movement.aspx`,
        requestOptions
      );
      const data = await res.json();
      //  if (data.status == "0") {
      fillData(data);
      //   }
    } catch (error) {
      console.log("error", error);
    }
  };

  const dateFormatter2 = (date) => {
    const d = new Date(date);
    console.log(d);
    return `${d.getDate()} ${MONTHS[d.getMonth()]}`;
  };
  useEffect(() => {
    if (filter == 0) {
      getEarnings(new Date(), new Date());
    } else if (filter == 1) {
      let d = new Date();
      d.setDate(d.getDate() - 7);
      getEarnings(d, new Date());
    } else if (filter == 2) {
      getEarnings(getDatePreviousMonth(), new Date());
    } else if (filter == 3) {
      getEarnings(new Date(new Date().getFullYear(), 0, 1), new Date());
    }
  }, [filter]);
  const fillData = (data) => {
    if (filter == 0) {
      setWalletMovement(
        data.daily
          .map((d) => {
            let hh = Number(d.hour);
            let tt = `${hh} AM`;
            if (hh == 12) {
              tt = `${hh} AM`;
            } else if (hh > 12) {
              tt = `${Number(hh) - 12} AM`;
            }
            return { ...d, time: d.hour };
          })
          .reverse()
      );
    } else if (filter == 1) {
      setWalletMovement(
        data.weekly
          .map((d) => {
            return { ...d, time: dateFormatter2(d.date) };
          })
          .reverse()
      );
    } else if (filter == 2) {
      setWalletMovement(
        data.monthly
          .map((d) => {
            return { ...d, time: `${d.week} Week` };
          })
          .reverse()
      );
    } else if (filter == 3) {
      setWalletMovement(
        data.annualy
          .map((d) => {
            return { ...d, time: MONTHS[d.month] };
          })
          .reverse()
      );
    }
  };
  console.log(walletMovement);
  return (
    <div className="col-md-4 col-sm-6 col-12 grid-margin p-0">
      <div
        style={{
          boxShadow: dropShadow,
          borderRadius: "1rem",
          background: "#FFFFFF",
        }}
        className="p-2 m-0 h-100"
      >
        <div className="row m-0 p-0 w-100">
          <div className="row col-12 align-items-center m-0 p-0">
            <div className="col-6 p-0">
              {" "}
              <span
                className="card-title text-dark text-bold"
                style={{ fontSize: "1.2rem", fontWeight: "800" }}
              >
                {"Wallet"}
              </span>
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              <select
                className="border-4"
                style={{
                  border: "1px solid #EEEEEE",
                  outline: "none",
                  padding: "6px",
                  background: "transparent",
                  fontSize: "0.8rem",
                }}
                value={""}
                onChange={(e) => {
                  // setFilter1(e.target.value);
                }}
              >
                <option value={1}>Bumppy Wallet</option>
                <option value={2} disabled={true}>
                  Credit Card
                </option>
                <option value={2} disabled={true}>
                  Net Banking
                </option>
                <option value={2} disabled={true}>
                  Visa
                </option>
              </select>
            </div>
          </div>

          <WalletMovement
            title="Money movement"
            amount={user.walletBalance}
            data={walletMovement}
            setFilter={(f) => {
              setFilter(f);
            }}
            filter={filter}
          />
        </div>
      </div>
    </div>
  );
};

export const DashboardPredictCard = () => {
  const history = useHistory();
  const [inputState, setInputState] = useState({
    recharge: 5000,
    pan: 5,
    dmt: 50000,
    irctc: 5,
    airline: 5,
    bus: 5,
    aeps: 10000,
    atm: 10000,
  });
  const [calculate, setCalculate] = useState({
    recharge: 0.0,
    pan: 0.0,
    dmt: 0.0,
    irctc: 0.0,
    airline: 0.0,
    bus: 0.0,
    aeps: 0.0,
    atm: 0.0,
    daily: 0.0,
    monthly: 0.0,
  });

  const aepsCalculate = (value) => {
    if (100 <= value && value <= 499) {
      return 0.25;
    } else if (500 <= value && value <= 999) {
      return 1;
    } else if (1000 <= value && value <= 1999) {
      return 3;
    } else if (2000 <= value && value <= 2999) {
      return 4;
    } else if (3000 <= value && value <= 4999) {
      return 7;
    } else if (5000 <= value && value <= 9999) {
      return 10;
    } else if (10000 <= value) {
      var mul = Math.floor(value / 10000);

      return mul * 10 + aepsCalculate(value % 10000);
    }
    return 0;
  };

  const mAtmCalculate = (value) => {
    if (100 <= value && value <= 499) {
      return 0.25;
    } else if (500 <= value && value <= 999) {
      return 1;
    } else if (1000 <= value && value <= 1999) {
      return 3;
    } else if (2000 <= value && value <= 2999) {
      return 4;
    } else if (3000 <= value && value <= 4999) {
      return 7;
    } else if (5000 <= value && value <= 10000) {
      return 10;
    } else if (10001 <= value) {
      var mul = Math.floor(value / 10000);

      return mul * 10 + mAtmCalculate(value % 10000);
    }
    return 0;
  };

  const rechargeCalculate = (value) => {
    return value / 25;
  };

  const dmtCalculate = (value) => {
    if (value == 0) {
      return 0;
    } else if (value <= 1000) {
      return 10;
    } else if (1000 <= value && value <= 25000) {
      return value / 200;
    } else if (25000 <= value) {
      return 250;
    }
  };

  const panCalculate = (value) => {
    return value * 5;
  };

  const calculateEarning = () => {
    let income =
      rechargeCalculate(Number(inputState.recharge)) +
      panCalculate(Number(inputState.pan)) +
      dmtCalculate(Number(inputState.dmt)) +
      Number(inputState.irctc) * 10 +
      Number(inputState.airline) * 200 +
      Number(inputState.bus) * 10 +
      aepsCalculate(Number(inputState.aeps)) +
      mAtmCalculate(Number(inputState.atm));
    setCalculate({ ...calculate, daily: income, monthly: income * 30 });
  };

  useEffect(() => {
    let income =
      rechargeCalculate(Number(inputState.recharge)) +
      panCalculate(Number(inputState.pan)) +
      dmtCalculate(Number(inputState.dmt)) +
      Number(inputState.irctc) * 10 +
      Number(inputState.airline) * 200 +
      Number(inputState.bus) * 10 +
      aepsCalculate(Number(inputState.aeps)) +
      mAtmCalculate(Number(inputState.atm));
    setCalculate({
      recharge: rechargeCalculate(Number(inputState.recharge)),
      pan: panCalculate(Number(inputState.pan)),
      dmt: dmtCalculate(Number(inputState.dmt)),
      irctc: Number(inputState.irctc) * 10,
      airline: Number(inputState.airline) * 200,
      bus: Number(inputState.bus) * 10,
      aeps: aepsCalculate(Number(inputState.aeps)),
      atm: mAtmCalculate(Number(inputState.atm)),
      daily: income,
      monthly: income * 30,
    });
  }, [inputState]);
  const clear = () => {
    setCalculate({
      recharge: 0.0,
      pan: 0.0,
      dmt: 0.0,
      irctc: 0.0,
      airline: 0.0,
      bus: 0.0,
      aeps: 0.0,
      atm: 0.0,
    });
    setInputState({
      recharge: "",
      pan: "",
      dmt: "",
      irctc: "",
      airline: "",
      bus: "",
      aeps: "",
      atm: "",
      daily: 0.0,
      monthly: 0.0,
    });
  };
  return (
    <div className="col-md-4 col-sm-6 col-12 grid-margin p-0">
      <div
        style={{
          boxShadow: dropShadow,
          borderRadius: "1rem",
          background: "#FFFFFF",
        }}
        className="p-2 m-0 h-100"
      >
        <div className="row m-0 p-0 w-100 h-100">
          <div className="row col-12 align-items-center m-0 p-0">
            <div className="col-12 col-md-6 p-0">
              {" "}
              <span
                className="card-title text-dark text-bold"
                style={{ fontSize: "1.2rem", fontWeight: "800" }}
              >
                {"Predict"}
              </span>
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  history.push("/retailer/income-calculator");
                }}
              >
                Income Calculator
              </button>
            </div>
          </div>

          <div className="row col-12 m-0 my-1 border align-items-center">
            <div className="col-4 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {"BBPS"}
              </span>
            </div>
            <div className="col-4 m-0 p-0 px-2">
              <input
                type="number"
                className="form-control form-control-sm"
                value={inputState.dmt}
                onChange={(e) => {
                  setInputState({ ...inputState, dmt: e.target.value });
                }}
              />
            </div>
            <div className="col-4 m-0 p-0 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {calculate.dmt}
              </span>
            </div>
          </div>

          {/* <div className="row col-12 m-0 my-1 border align-items-center">
            <div className="col-4 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {"AEPS"}
              </span>
            </div>
            <div className="col-4 m-0 p-0 px-2">
              <input
                type="number"
                className="form-control form-control-sm"
                value={inputState.aeps}
                onChange={(e) => {
                  setInputState({ ...inputState, aeps: e.target.value });
                }}
              />
            </div>
            <div className="col-4 m-0 p-0 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {calculate.aeps}
              </span>
            </div>
          </div> */}

          <div className="row col-12 m-0 my-1 border align-items-center">
            <div className="col-4 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {"Recharge"}
              </span>
            </div>
            <div className="col-4 m-0 p-0 px-2">
              <input
                type="number"
                className="form-control form-control-sm"
                value={inputState.recharge}
                onChange={(e) => {
                  setInputState({
                    ...inputState,
                    recharge: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-4 m-0 p-0 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {calculate.recharge}
              </span>
            </div>
          </div>

          {/* <div className="row col-12 m-0 my-1 border align-items-center">
            <div className="col-4 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {"Flight Ticket"}
              </span>
            </div>
            <div className="col-4 col-4 m-0 p-0 px-2">
              <input
                type="number"
                className="form-control form-control-sm"
                value={inputState.airline}
                onChange={(e) => {
                  setInputState({ ...inputState, airline: e.target.value });
                }}
              />
            </div>
            <div className="col-4 col-4 m-0 p-0 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                {calculate.airline}
              </span>
            </div>
          </div> */}

          <div className="row col-12 m-0 my-1 border align-items-center">
            <span style={{ fontSize: ".8rem", fontWeight: "700" }}>
              Potential Income :
            </span>
            <span
              style={{ fontSize: ".8rem" }}
              className="text-success px-2"
            >{`${
              calculate.aeps +
              calculate.dmt +
              calculate.airline +
              calculate.recharge
            }`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DashboardEarnCard = () => {
  const { user } = useContext(GlobalContext);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const [earning, setEarning] = useState({
    dmt: "0.00",
    aeps: "0.00",
    bbps: "0.00",
    settlement: "0.00",
  });

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  const getEarnings = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/dashboard/get_dashboard_earning.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setEarning(data.earning);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const calculateTotal = () => {
    let total = 0;
    if (earning.dmt) {
      total += Number(earning.dmt);
    }
    if (earning.aeps) {
      total += Number(earning.aeps);
    }
    if (earning.bbps) {
      total += Number(earning.bbps);
    }
    if (earning.settlement) {
      total += Number(earning.settlement);
    }
    return total;
  };
  useEffect(() => {
    getEarnings();
  }, [startDate, endDate]);
  return (
    <div className="col-md-4 col-sm-6 col-12 grid-margin p-0 px-md-2">
      <div
        style={{
          boxShadow: dropShadow,
          borderRadius: "1rem",
          background: "#FFFFFF",
        }}
        className="p-2 m-0 h-100"
      >
        <div className="row m-0 p-0 w-100">
          <div className="row col-12 align-items-center m-0 p-0">
            <div className="col-12 col-md-4 p-0">
              {" "}
              <span
                className="card-title text-dark text-bold"
                style={{ fontSize: "1.2rem", fontWeight: "800" }}
              >
                {"Earning"}
              </span>
            </div>
            <div className="col-12 col-md-8 px-0 d-flex justify-content-end">
              <Form.Group className="col-6 p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-0 border-end-0"
                  placeholderText="From Date"
                  style={{ fontSize: ".8rem" }}
                />
              </Form.Group>
              <Form.Group className="col-6 p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control rounded-0 border-start-0"
                  placeholderText="To Date"
                  style={{ fontSize: ".8rem" }}
                />
              </Form.Group>
            </div>
          </div>
          {/* <div className="row col-12 w-100 m-0 p-0">
            {services.map((service) => (
              <div className="col-12 col-md-4 form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  {service.name}
                </label>
              </div>
            ))}
          </div> */}
          {/* 
          <div className="row col-12 m-0 my-1 border">
            <div className="col-6 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                ₹ {"DMT"}
              </span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-success">
                ₹ {earning.dmt ? earning.dmt : "0.00"}
              </span>
            </div>
          </div> */}

          {/* <div className="row col-12 m-0 my-1 border">
            <div className="col-6 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                ₹ {"AEPS"}
              </span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-success">
                ₹ {earning.aeps ? earning.aeps : "0.00"}
              </span>
            </div>
          </div> */}

          <div className="row col-12 m-0 my-1 border">
            <div className="col-6 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                ₹ {"BBPS"}
              </span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-success">
                ₹ {earning.bbps ? earning.bbps : "0.00"}
              </span>
            </div>
          </div>

          <div className="row col-12 m-0 my-1 border">
            <div className="col-6 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                ₹ {"Recharge"}
              </span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-success">
                ₹ {earning.bbps ? earning.bbps : "0.00"}
              </span>
            </div>
          </div>

          {/* <div className="row col-12 m-0 my-1 border">
            <div className="col-6 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                ₹ {"Settlement"}
              </span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-success">
                ₹ {earning.settlement ? earning.settlement : "0.00"}
              </span>
            </div>
          </div> */}

          <div className="row col-12 m-0 my-1 border">
            <div className="col-6 m-0 p-0">
              <span style={{ fontSize: ".8rem" }} className="text-dark">
                ₹ {"Total Earning "}
              </span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <span style={{ fontSize: ".8rem" }} className="text-success">
                ₹ {`${twoDecimalPlaces(calculateTotal())}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DashboardProsperCard = () => {
  return (
    <div className="col-md-4 col-sm-6 col-12 grid-margin">
      <div
        style={{ boxShadow: dropShadow, borderRadius: "1rem" }}
        className="p-2 m-0 h-100"
      >
        <div className="row m-0 p-0 w-100">
          <div className="row col-12 align-items-center m-0 p-0">
            <div className="col-4 p-0">
              {" "}
              <span
                className="card-title text-dark text-bold"
                style={{ fontSize: "1.2rem", fontWeight: "800" }}
              >
                {"Prosper"}
              </span>
            </div>
            <div className="col-8 px-2 d-flex justify-content-end">
              <select
                className="border-4"
                style={{
                  border: "1px solid #EEEEEE",
                  outline: "none",
                  padding: "6px",
                  background: "transparent",
                  fontSize: ".8rem",
                }}
                value={""}
                onChange={(e) => {
                  // setFilter1(e.target.value);
                }}
              >
                <option value={1}>Bumppy Wallet</option>
                <option value={2}>Credit Card</option>
                <option value={2}>Net Banking</option>
                <option value={2}>Visa</option>
              </select>
            </div>
          </div>

          <div className="row col-12 m-0">
            <div className="col-12 m-0 p-0">Transaction Amount</div>
            <div className="col-12 col-sm-8 py-1 px-0">
              <span className="text-success">₹ {"0"}</span>
              {/* <span style={{ fontSize: ".7rem" }}>Last Week</span> */}
            </div>
            {/* <div className="col-12 col-sm-4 d-flex justify-content-end m-0 p-0">
            <i
              className="mdi mdi-arrow-right py-1 px-2"
              style={{ boxShadow: dropShadow, borderRadius: "1rem" }}
            ></i>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DMTReports = () => {
  const { user } = useContext(GlobalContext);

  // Last 5 Transactions
  const [dmtTransactions, setDmtTransactions] = useState([]);

  const Last5DMT = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setDmtTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Last5DMT();
  }, []);
  return (
    <div
      className="row w-100 h-100 p-0 m-0 border"
      style={{
        background: "#FFF",
      }}
    >
      <div className="scroll-inner dashboard-fix-width">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          <tr>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Amount
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Transaction ID
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Date & Time
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Status
            </th>
          </tr>
          {dmtTransactions.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">
                    {twoDecimalPlaces(e.rch_amount)}
                  </span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_transaction_id}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{formatDateDDMMYYHHMM(e.rch_indate)}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  {e.rch_status == "Y" ? (
                    <span className="badge badge-success badge-pill p-2">
                      Success
                    </span>
                  ) : (
                    <span className="badge badge-warning badge-pill px-2 py-1">
                      {e.rch_status_desc}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export const AEPSReports = () => {
  const { user } = useContext(GlobalContext);

  // Last 5 Transactions
  const [AEPSTransactions, setAEPSTransactions] = useState([]);

  const Last5AEPS = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_aeps_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setAEPSTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Last5AEPS();
  }, []);
  return (
    <div
      className="row w-100 p-0 m-0"
      style={{
        boxShadow: dropShadow,
        borderRadius: "1rem",
        background: "#FFF",
      }}
    >
      <div className="scroll-inner dashboard-fix-width">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          <tr>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Amount
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Transaction ID
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Date & Time
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Status
            </th>
          </tr>
          {AEPSTransactions.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">
                    {twoDecimalPlaces(e.rch_amount)}
                  </span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_transaction_id}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{formatDateDDMMYYHHMM(e.rch_indate)}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  {e.rch_status == "Y" ? (
                    <span className="badge badge-success badge-pill p-2">
                      Success
                    </span>
                  ) : (
                    <span className="badge badge-warning badge-pill px-2 py-1">
                      {e.rch_status_desc}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
export const BBPSReports = () => {
  const { user } = useContext(GlobalContext);

  // Last 5 Transactions
  const [bbpsTransactions, setBBPSTransactions] = useState([]);

  const Last5BBPS = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_last_five_bbps_transactions.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setBBPSTransactions(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Last5BBPS();
  }, []);
  return (
    <div
      className="row w-100 p-0 m-0"
      style={{
        boxShadow: dropShadow,
        borderRadius: "1rem",
        background: "#FFF",
      }}
    >
      <div className="scroll-inner dashboard-fix-width">
        <table style={{ width: "100%" }} className="dashboard-table-style">
          <tr>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Amount
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Transaction ID
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Date & Time
            </th>
            <th className="text-center" style={{ fontSize: "0.8rem" }}>
              {" "}
              Status
            </th>
          </tr>
          {bbpsTransactions.map((e, ind) => (
            <tr>
              <td style={{ fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">
                    {twoDecimalPlaces(e.rch_amount)}
                  </span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{e.rch_transaction_id}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  <span>{formatDateDDMMYYHHMM(e.rch_indate)}</span>
                </div>
              </td>
              <td style={{ fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center">
                  {e.rch_status == "Y" ? (
                    <span className="badge badge-success badge-pill p-2">
                      Success
                    </span>
                  ) : (
                    <span className="badge badge-warning badge-pill px-2 py-1">
                      {e.rch_status_desc}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export const WalletMovement = ({
  title,
  amount,
  data: dataX,
  setFilter,
  filter,
}) => {
  const [label, setLabel] = useState([]);
  const [dataToPlot, setDataToPlot] = useState([]);
  const [filter1, setFilter1] = useState(2);
  const data = {
    labels: label,
    datasets: [
      {
        label: "Transactions",
        data: dataToPlot,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
          },
          gridLines: {
            color: "rgba(204, 204, 204,0.1)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgba(204, 204, 204,0.1)",
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  useEffect(() => {
    console.log(dataX);
    const ll = dataX.map((e) => e.time);
    const dd = dataX.map((e) => e.wallet);
    setLabel(ll);
    setDataToPlot(dd);
  }, [filter1, dataX]);

  return (
    <div className="card col-12 grid-margin m-0 my-1 p-0">
      <div style={{}} className="p-2 m-0">
        <div className="row m-0 p-0 w-100">
          <div className="row col-12 align-items-center m-0 p-0">
            <div className="col-6 p-0">
              {" "}
              <span
                className="card-title text-dark text-bold"
                style={{ fontSize: "1rem", fontWeight: "800" }}
              >
                {title}
              </span>
            </div>
            <div className="col-6 px-0 d-flex justify-content-end">
              <select
                className="border-4"
                style={{
                  border: "1px solid #EEEEEE",
                  outline: "none",
                  padding: "6px",
                  background: "transparent",
                  fontSize: ".8rem",
                }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <option value={0}>Daily</option>
                <option value={1}>Weekly</option>
                <option value={2}>Monthly</option>
                <option value={3}>Annualy</option>
              </select>
            </div>
          </div>

          <div className="row"></div>

          <Line data={data} options={options} />

          <div className="row col-12 m-0">
            <div className="col-12 m-0 p-0">
              <span style={{ fontSize: ".8rem", fontWeight: "700" }}>
                {" "}
                Wallet Balance :{" "}
              </span>
              <span style={{ fontSize: ".8rem" }} className="text-success">
                ₹ {amount ? amount : "0"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const NotificationBanner = ({ notification }) => {
  const priority0 = "linear-gradient(to right, #EE8B06 , #FFC200)",
    priority2 = "linear-gradient(to right, #FE5F75 , #FC9842)",
    priority1 = "linear-gradient(to right, #0E8C2E , #4CB434)";
  const [open, setOpen] = useState(true);
  if (!open) return null;
  const getBackgroundColor = (priority) => {
    switch (priority) {
      case "0": {
        return priority2;
      }
      case "1": {
        return priority0;
      }
      case "2": {
        return priority1;
      }
    }
  };
  return (
    <div
      className="row m-1 p-0 w-100"
      style={{ boxShadow: dropShadow, background: "#FFFFFF" }}
    >
      <div
        className="col-md-2 col-sm-3  d-flex align-items-center p-3"
        style={{ background: getBackgroundColor(notification.priority) }}
      >
        <span className="text-light" style={{ fontWeight: "700" }}>
          {notification.notification_title}
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center col-md-10 col-sm-9">
        <span style={{ fontSize: "1rem" }} className="text-dark w-100">
          <marquee behavior="scroll" direction="left" className="text-dark">
            {notification.notification_description}
          </marquee>
        </span>
        <span
          style={{ fontSize: "1.5rem", cursor: "pointer" }}
          className="mdi mdi-close"
          onClick={() => {
            if (notification.closable == "Y") {
              setOpen(false);
            } else {
              swal("Warning", "You Can't close this Notification", "warning");
            }
          }}
        ></span>
      </div>
    </div>
  );
};

export const NotificationBanner2 = () => {
  const [open, setOpen] = useState(true);
  if (!open) return null;
  return (
    <div
      className="row m-1 p-0 w-100"
      style={{ boxShadow: dropShadow, background: "#FFFFFF" }}
    >
      <div
        className="col-md-2 col-sm-3  d-flex align-items-center p-3"
        style={{ background: "linear-gradient(to right, #0E8C2E , #4CB434)" }}
      >
        <span className="text-light" style={{ fontWeight: "700" }}>
          Upgrade Your Plan
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center col-md-10 col-sm-9">
        <span style={{ fontSize: ".8rem" }} className="text-muted">
          For more services upgrade your plan
          <a href="#">Click here to see pricing plans </a> .
        </span>
        <span
          style={{ fontSize: "1.5rem", cursor: "pointer" }}
          className="mdi mdi-close"
          onClick={() => {
            setOpen(false);
          }}
        ></span>
      </div>
    </div>
  );
};

export const NotificationBanner3 = () => {
  const [open, setOpen] = useState(true);
  if (!open) return null;
  return (
    <div
      className="row m-1 p-0 w-100"
      style={{ boxShadow: dropShadow, background: "#FFFFFF" }}
    >
      <div
        className="col-md-2 col-sm-3  d-flex align-items-center p-3"
        style={{ background: "linear-gradient(to right, #EE8B06 , #FFC200)" }}
      >
        <span className="text-light" style={{ fontWeight: "700" }}>
          Enable International Payments
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center col-md-10 col-sm-9">
        <span style={{ fontSize: ".8rem" }} className="text-muted">
          Your customers are trying to pay via international cards. Enable
          International payments or link your PayPal account today to not miss
          these opportunities.<a href="#">View international methods </a>
        </span>
        <span
          style={{ fontSize: "1.5rem", cursor: "pointer" }}
          className="mdi mdi-close"
          onClick={() => {
            setOpen(false);
          }}
        ></span>
      </div>
    </div>
  );
};

export const ServicesNavbar = () => {
  return (
    <div className="row justify-content-center m-0 p-0 py-4">
      {/* {navbarServices.map((e) => (
        <ServiceNavCard details={e} />
      ))} */}
      {/* {navbarServices.map((e) => ( */}
      <ServiceNavCard />
      {/* ))} */}
    </div>
  );
};
const BalanceCard = ({ data, provider_name, balance, acc }) => {
  return (
    <div className=" col-sm-3 col-md-3 col-lg-3  ">
      <div className="card  m-0 p-0">
        <div className="row m-0 p-2">
          <div className="col-12 d-flex text-secondary">
            <div className="" style={{ fontFamily: "Poppins" }}>
              {provider_name}
            </div>
          </div>
          <div className="col-12 d-flex text-dark">
            <div className="" style={{ fontFamily: "Poppins" }}>
              ₹ {balance}
            </div>
          </div>
          <div className="col-12 d-flex text-dark">
            <div className="" style={{ fontFamily: "Poppins" }}>
              {`Acccount No: ${acc}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceNavCard = ({ details }) => {
  //   const history = useHistory();
  const [serviceSelected, setServiceSelected] = useState(5);
  const [services, setServices] = useState([]);

  // Main Code
  const accessInfo = (idx) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const user = JSON.parse(sessionStorage.getItem("user"));

    var raw = JSON.stringify({
      userid: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/user/get_user_access_info.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setServices(result.services);
      })
      .catch((error) => console.log("error", error));
  };

  const isServiceActive = (idx) => {
    let active = false;
    if (services) {
      services.forEach((menu) => {
        if (menu.pk_menu_id == idx && menu.status == "Y") {
          active = true;
        }
      });
    }
    return active;
  };

  useEffect(() => {
    accessInfo();
  }, []);

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "0",
        padding: "0",
      }}
    >
      <div className="row justify-content-center w-100 ">
        {isServiceActive(67) && (
          <div className="col-6 col-sm-4 col-md-3 col-lg-2 ">
            <Link
              to="/retailer/dmt3"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div
                className="d-flex flex-column align-items-center p-3"
                style={{
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  background: "#FFF",
                  borderRadius: "1rem",
                }}
              >
                <img
                  src={dmt}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    marginBottom: "10px",
                  }}
                />
                <span>DMT</span>
              </div>
            </Link>
          </div>
        )}
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/aeps"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={Aeps}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>AEPS</span>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/aeps-settlement"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={Settlement}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>Settlement</span>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/vendor-payment"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={VendorPayment}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>Vendor Payment</span>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/cms"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={cms}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>CMS</span>
            </div>
          </Link>
        </div>
      </div>

      {/* Row 2 */}
      <div className="row justify-content-center w-100">
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/bill_payment"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={bbps}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>BBPS</span>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/offline_bill_payment"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={BbpsOffline}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>BBPS Offline</span>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/topup_request"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={pg}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>PG</span>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/dashboard"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={analytics2}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>Analytics</span>
            </div>
          </Link>
        </div>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 grid-margin">
          <Link
            to="/retailer/wallet_to_wallet_transfer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              className="d-flex flex-column align-items-center p-3"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                background: "#FFF",
                borderRadius: "1rem",
              }}
            >
              <img
                src={Wallet}
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <span>Wallet to Wallet</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const BBPSServiceCard = ({ image, title }) => {
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
    >
      <div
        style={{
          borderRadius: "3rem",
          background: "#C42F47",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
        }}
      >
        <img
          src={image}
          style={{
            height: "2rem",
            width: "2rem",
            color: "#EC5665",
            fill: "#FFF",
            stroke: "#FFF",
          }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};

export default Dashboard3;
