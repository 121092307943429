import React, { useState, useEffect } from "react";
import ShortCuts from "../Offline Shortcuts/ShortCuts";
import { Button, Card } from "react-bootstrap";
import CableSidebar from "./OfflineCableSidebar";
import { basicAuth } from "../../../config";
import { Redirect, useLocation } from "react-router";
import { GlobalContext } from "../../../Context/GlobalState";
import { BASE_URL_PAYNOW } from "../../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../../utils/Themer";

const OfflineCable = () => {
  const { username, password } = basicAuth;
  const [providers, setProviders] = useState([]);
  const location = useLocation();

  const getAllProviders = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLDeudbzWuuuGKwhiSsa75bUtX_NqwCaj_v4o_hj4oq9t2cr4CA5MVcuNkvX4mpyg54H4S0w3SoSQAIpD0xsTsF30dEOh3UNorm1OTqZUAALf9maVz0uehddtUJFuzniN9qu9RJuKC_2AT8zdbdrxjTpr0Wl2TTwOjqhJhhfJEYxexBisigKKzH8JQ5jYsat6DqofW5DCOS3jgKAPc6Xlz9cxt7gv1QzfkjONGG97fMJy-roetHLJC3ZHeckzq12FZoG7biAgSxL-crQOu056sRyI57fSPtPMdQxjZx-oPpocqKE69utlorNhTrVLULgbWq8pxcBXqkxm8CjGqX9G9Ml7EKXJ9dEE_nRZsufrhgWIXimbxjgHXVP6joIZJSEi1jCpb_EWDzEV8MOsbza5HNgc2A5wAHakm6BPCT3QqFit3KF0MP35HbuehToEJW0DVrXrUyz9ZsbS4ndq96ncud0pxfTHoFBFJaUAWl5ylrRKmZ1La38FafnjGBPtNcJqNjgT2xmEJXbobUIL60eZzmgWL8H2f6yllunvIO0n30EJyFVOELJojx0WQGrYWFfc8lXzzreQuz9XZwLAukacbhZ-ciW3i2rSx1H9yGQkHHnQvoxX18K4DwJCikRGJq-BSZBFzSYj0pICNQot2rj1qTT"
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Operator/GetAllOfflineOperators`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data) {
        setProviders(
          data.data.filter((pro) => {
            return pro.category_id == 5;
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllProviders();
  }, []);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <CableSidebar details={{ providers }} />
            </div>
            <div style={{backgroundColor:COLOR_BACKGROUND_BODY}}>
              <div className="row" style={{ padding: "0px" }}>
                <ShortCuts path={location.pathname} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "10px 10px 0px 10px",
                }}
              >
                <Button
                  variant="success"
                  className="rounded-0"
                  onClick={() => {
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                >
                  {"Pay Cable Bill"}
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <Card style={{ flex: "1", margin: "10px", padding: "10px" }}>
                  <strong>{"Latest Bill Payments Details"}</strong>
                </Card>
              </div>
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default OfflineCable;
