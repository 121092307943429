import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormLabel,
  Nav,
  NavItem,
  Row,
  TabContainer,
  TabContent,
  TabPane,
  FormGroup,
  NavLink,
  Tab,
} from "react-bootstrap";
import { DROP_SHADOW } from "../../utils/CommonConfig";

const QRcode = () => {
  return (
    <div
      className="m-3 p-2"
      style={{
        background: "#FFFFFF",
        boxShadow: DROP_SHADOW,
        borderRadius: "1rem",
      }}
    >
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="p-0 m-0">
          <Col xs={12} md={6} className="p-0 m-0">
            <Nav
              variant="pills"
              className="nav-pills-custom justify-content-around p-1"
              style={{
                borderRadius: "1rem",
                padding: "1rem",
                border: "1px solid #2F58CD",
                background: "#FFF",
              }}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="d-flex align-items-center"
                >
                  QR CODES
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  className="d-flex align-items-center"
                >
                  Payments
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} className="p-0">
            <Tab.Content className="tab-content-custom-pill p-0">
              <Tab.Pane eventKey="first">
                <Form>
                  <Row>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormLabel>QR Codes Status</FormLabel>
                        <FormControl as="select" name="Anytime">
                          <option disabled selected value="T00:00:00">
                            All Status
                          </option>
                          <option value="T08:00:00">Morning</option>
                          <option value="T19:00:00">Evening</option>
                          <option value="T14:00:00">Afternoon</option>
                          <option value="T01:00:00">Night</option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormLabel>QR Codes ID</FormLabel>
                        <FormControl type="text"></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormLabel>QR Name</FormLabel>
                        <FormControl type="text"></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormGroup className="">
                          <FormLabel>Customer Name</FormLabel>
                          <FormControl type="text"></FormControl>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormLabel>Customer Email</FormLabel>
                        <FormControl type="text"></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormLabel>Customer Contact</FormLabel>
                        <FormControl type="text"></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormLabel>Notes</FormLabel>
                        <FormControl type="text"></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="mt-5">
                      <Button className="btn-primary-ffff p-2 m-2">
                        Search
                      </Button>
                      <Button className="btn-primary-ffffff p-2">Clear</Button>
                    </Col>
                  </Row>
                </Form>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Form>
                  <Row>
                    <Col lg={4} md={4} sm={4} className="">
                      <FormGroup className="">
                        <FormLabel>Notes</FormLabel>
                        <FormControl type="text"></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={4} className="mt-5">
                      <Button className="btn-primary-ffff p-2 m-2">
                        Search
                      </Button>
                      <Button className="btn-primary-ffffff p-2">Clear</Button>
                    </Col>
                  </Row>
                </Form>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default QRcode;
