import React from "react";

import { GlobalContext } from "../../Context/GlobalState";

import TwoFactorReportTable from "./TwoFactorReportTable";

const TwoFactorReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "1rem" }}>
          <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
            <div className="card-body">
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  Two Factor Report
                </div>

                <TwoFactorReportTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default TwoFactorReport;
