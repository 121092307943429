import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Col, Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { ContextMenuTrigger } from "react-contextmenu";
import {
    BASE_URL_AEPS,
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT,
    BASE_URL_PAYNOW,
} from "../../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import { DROP_SHADOW } from "../../utils/CommonConfig";
import { PRINT_BASE_URL, basicAuth } from "../../config";
import {
    generateHashKey2,
    onlyAlpha,
    onlyNumeric,
    validateIFSCCode2,
} from "../../utils/utilFuctions";
import { validateTPin } from "../../utils/Validations";
import { useHistory } from "react-router-dom";

const CreditCardKYC = () => {
    const history = useHistory();
    const { username, password } = basicAuth;

    const [Bal, setBal] = useState("");
    const [inputState, setInputState] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", otpSent: false, otp: "", aadhaar_request_id: "", aadhaarVerified: false, custPan: "", aadhaarDetails: "" });
    const [formErrors, setFormErrors] = useState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", });
    const [balanceToShow, setBalanceToShow] = useState("");

    const [aadhaarFront, setAadhaarFront] = useState(null);
    const [aadhaarBack, setAadhaarBack] = useState(null);
    const [UserAcList, setUserAcList] = useState([]);
    const [BankObject, setBankObject] = useState("");
    const [isCardSubmit, setIsCardSubmit] = useState(false);
    const [isBankVerified, setIsBankVerified] = useState(false);
    const [bankVerificationResponse, setBankVerificationResponse] =
        useState(null);

    const {
        user,
        refreshWallet,
        wallet,
        setTransactionLoaderState,
        Profileinfo,
    } = useContext(GlobalContext);
    const [tpinShow, setTpinShow] = useState(false);

    // Final Settlement Start

    const InitialFinalSettlement = {
        Amount: "",
        TPin: "",
        Type: "",
        SelectAccount: "",
    };
    const [finalSettlement, setFinalSettlement] = useState(
        InitialFinalSettlement
    );
    const [finalSettlementErrors, setfinalSettlementErrors] = useState({});
    const [IsSubmit, setIsSubmit] = useState(false);
    const [bankname, setbankname] = useState("");
    const [Name, setName] = useState("");

    const transferVarification = (amount, account_number, account_holder_name) => {
        swal({
            title: "Are you sure?",
            text: `Transfer of Amount ${amount} to ${account_holder_name} with account number ${account_number}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
            }
        });
    };

    const openInNewTab = () => {
        const newWindow = window.open(
            `${PRINT_BASE_URL}/pages/concern_form`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputState({ ...inputState, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(inputState));
        setIsSubmit(true);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.creditCard) {
            errors.creditCard = "*Credit Card 4 digit is required!";
        }

        if (!values.aadhaarNo) {
            errors.aadhaarNo = "*Aadhaar Number is required!";
        }
        if (values.aadhaarNo.length != 12) {
            errors.aadhaarNo = "*Aadhaar Card number must be 12 digit long!";
        }
        if (!values.custMobile) {
            errors.custMobile = "*Customer mobile number is required!";
        }
        if (!values.custEmail) {
            errors.custEmail = "*Customer email is required!";
        }

        if (Object.keys(errors).length === 0) {
            saveCreditCard();
        }
        return errors;
    };

    // Final Settlement End

    // Add Bank Account Start

    const InitialAddBankValues = {
        Mobile: "",
        AccHolderName: "",
        BankAcNumber: "",
        SelectBank: "",
        IFSCcode: "",
    };
    const [AddBankValues, setAddBankValues] = useState(InitialAddBankValues);


    // Add Bank Account End

    const convertToBiteArray = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                var binary = "";
                var len = array.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(array[i]);
                }
                callback(window.btoa(binary));
            }
        };
    };

    const ImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 256000) {
                swal("Invalid", "Image Size should be less than 256 kb", "error");
                return;
            }
            convertToBiteArray(e.target.files[0], (ByteArray) => {

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "creditcard": inputState.creditCard,
                    "userid": user.ReferId,
                    "aadhaar": inputState.aadhaarNo,
                    "p1": inputState.custPan,
                    "imagename": `${user.ReferId}_${inputState.creditCard}_${inputState.aadhaarNo}`,
                    imagebytedata: ByteArray,
                });

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                try {
                    fetch(
                        `${BASE_URL_APISERVICES}/api/Payment/credit-card/pgConcernUpload`,
                        requestOptions
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.status == "0") {
                                swal(
                                    "Upload Successfully",
                                    "Image upload successfully",
                                    "success"
                                );
                            } else {
                                //  swal("Upload Failed", "Image upload successfully", "error");
                                swal(
                                    "Upload Failed",
                                    "Image upload Failed",
                                    "error"
                                );
                            }
                            history.push("/retailer/topup_request");
                        })
                        .catch((err) => {
                            swal(
                                "Upload Failed",
                                "Image upload Failed | Error From Server",
                                "error"
                            );
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }
    };

    const saveCreditCard = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditCard": inputState.creditCard,
                "custMobile": inputState.custMobile,
                "custName": inputState.custName,
                "custEmail": inputState.custEmail,
                "custPan": inputState.custPan,
                "aadhaarNo": inputState.aadhaarNo,
                "aadhaarDetails": inputState.aadhaarDetails,
                "isVerified": "N",
                "formData": ""
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch(`${BASE_URL_APISERVICES}/api/Payment/credit-card/creditCardDetailsSave`, requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                // swal("Success", result.msg, 'success');


                localStorage.setItem("printInfo", JSON.stringify({  
                    agentID: user.ReferId,
                    agentName: Profileinfo.name,
                    shopName: Profileinfo.shopname,
                    customerName: inputState.custName,
                    productName: "Payment Gateway",
                    customerContactName: inputState.custMobile,
                    cardLast4Digits: inputState.creditCard,
                    aadhaarnumber: inputState.aadhaarNo,
                    panNumber: inputState.custPan,
                }));

                openInNewTab();
                setIsCardSubmit(true)
                // setInputState({ creditCard: "", aadhaarNo: "", custMobile: "", custEmail: "", custName: "", });
            } else {
                swal("Failed", result.msg, 'error');
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };

    const checkCreditCard = async () => {
        try {
            setTransactionLoaderState(true);
            const hash_key = await generateHashKey2(user.ReferId);
            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userid": user.ReferId,
                "creditcard": inputState.creditCard,
                "aadhaar": inputState.aadhaarNo,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const res = await fetch(`${BASE_URL_APISERVICES}/api/Payment/credit-card/checkCreditCardVerified`, requestOptions);
            const result = await res.json();
            if (result.status == "0") {
                if(result.data.step == "2"){
                    setIsCardSubmit(true);
                }else  if(result.data.step == "3"){
                    swal("Success", "Credit Card already verified", 'success');
                    history.push("/retailer/topup_request");
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            swal("Failed", `Something went wrong`, "error");
            // console.log(error);
        }
    };

    const aadhaarVerificationSendOtp = async () => {

        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Cookie",
                ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y0s7bi6I2oq4bBIN05pcn-22zDbEC2K0B-nviBgFvqOVktA2C3i9oiZosmjfVMRHl6yl4r7gQLI2ZVmyyizy0WMvnTrhuMiYZcm4mq1dFs3gDB12EfgpSmQlELsaMjdVtHd6afBouTY7NsGoGe-5C3qq8ltT0zaCLnxVWjHIaJqSwNpWvun0YiFGI5tQ4hUFCPE6ilxS4u2myz3VyS2eSrGtFcvaPilfGyORm_J7W558pqhOshhR8JXR-3q8miWrt1Ji5twUizGXy0CfYM8UbEY3gPvXkyqHttYp-8X3WxmtrickDNedeBLMSs9xK80SG1HgErLU1plIlnUnmfeuUnNfLOejtKfnSkIQrMnMvKvvz-LrgPPRcf5FQntJNVXzZmZayQ8dHefiQhcfhNBwVc52CfLIzVCRcv0gICybhfOg-mQitlKMh-dXkMlhB7SUfBJX5SAQMcAWfS8DM-LvQjN6NKbXnsdy4vo5Qn0eEESnDlyX_lSEzb2lBdDlGvmFY-J9wrCk_49rT7lq-aweV8CFa6qrEBUO3t6uNY9i4s4s_7uICtutJvtiMJOi24bxNTWB22eVhsuroLrVtIswVKXMA8lRiECbsjG8lcKUk0_LU1FQz7soVehwvZa3j0y8xZ2NJPzMdl04OMGCOCWl-Up"
            );

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Verify/aadharotpsend`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100" && data.result) {

                    setInputState({
                        ...inputState, otpSent: data.result.is_otp_sent,
                        aadhaar_request_id: data.request_id,
                    });
                } else {
                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    // let username = "Rajneesh@example.com", password = "Test@12345";

    const aadhaarVerification = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                request_id: inputState.aadhaar_request_id,
                otp: inputState.otp,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Verify/aadharotpverify`,
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, aadhaarVerified: true });
                    aadhaarVerificationpro();

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const aadhaarVerificationpro = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                verifydatavalue: inputState.aadhaarNo
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Verify/aadharverifypro",
                requestOptions
            );
            const data = await res.json();
            if (data.success) {
                if (data.response_code == "100") {
                    setInputState({ ...inputState, aadhaarDetails: JSON.stringify(data.result), custPan: data.result.pan_number, custName: data.result.user_full_name, aadhaarVerified: true });
                    // setVerification({
                    //     ...verification,
                    //     ADHAR: true,
                    //     adhaarDetails: data.result,
                    // });

                } else {

                    setFormErrors({ ...formErrors, aadhaarNo: data.response_message });
                }
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    useEffect(() => {
        const creditCardFourDigit = localStorage.getItem("creditCardFourDigit");
        setInputState({ ...inputState, creditCard: creditCardFourDigit });
    }, []);

    useEffect(() => {
        if (inputState.creditCard.length == 4 && inputState.aadhaarNo.length == 12) {
               checkCreditCard();
        }
    }, [inputState.aadhaarNo, inputState.creditCard]);


    const clearAddBankForm = () => {
        setAddBankValues({
            Mobile: "",
            AccHolderName: "",
            BankAcNumber: "",
            SelectBank: "",
            IFSCcode: "",
        });
        setIsBankVerified(false);
    };

    const clearSettlementForm = () => {
        setFinalSettlement(InitialFinalSettlement);
    };

    const readImage = (type, event) => {
        if (type == "aadhaarFront") {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    setAadhaarFront(reader.result);
                    localStorage.setItem("aadhaarFront", reader.result);
                };

                reader.readAsDataURL(file);
            }
        } else if (type == "aadhaarBack") {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    setAadhaarBack(reader.result);
                    localStorage.setItem("aadhaarBack", reader.result);
                };

                reader.readAsDataURL(file);
            }
        }
    }

    return (
        <div
            className="row p-2 m-3"
            style={{
                //  background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
                borderRadius: "1rem",
                boxShadow: DROP_SHADOW,
            }}
        >
            <div className="col-12 row justify-content-between align-items-center py-0 px-2 m-0">
                <h5 className="text-center">Credit Card KYC</h5>
                {/* <div className="text-center">
                    <Button
                        variant="success"
                        onClick={() => {
                            setMdShow(true);
                        }}
                        className="btn-sm float-right rounded-0 m-2"
                    >
                        Add Bank Account
                    </Button>
                </div> */}

            </div>

            {isCardSubmit && (
                <div className="row">
                    {/* <h6 className="text-center">Upload Concern Form</h6> */}
                    <div className="col-7 grid-margin">
                        <Form.Group>
                            <Form.Label>
                                <b>* Concern Form Upload</b>
                            </Form.Label>
                            <br />
                            <Form.Label>
                                <span className="text-danger">
                                    Image size should be less than 256 kb
                                </span>
                            </Form.Label>
                            <Form.Control
                                accept=".jpeg,.pdf"
                                type="file"
                                name="slip"
                                onChange={ImageUpload}
                                style={{ borderRadius: "0px" }}
                            ></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            )}

            {!isCardSubmit && (
                <div className="row">
                    <Form.Group className="col-7 py-0 px-1'">
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Last 4 Digit of Card`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Last 4 Digit of Card"
                            name="creditCard"
                            className="rounded-0"
                            value={inputState.creditCard}
                            onChange={handleChange}
                        />
                        <p style={{ color: "red" }}>{formErrors.creditCard}</p>
                    </Form.Group>
                    <Form.Group className="col-7 py-0 px-1'">
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Aadhaar No`}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Aadhaar No"
                            name="aadhaarNo"
                            className="rounded-0"
                            value={inputState.aadhaarNo}
                            onChange={handleChange}
                        />
                        <p style={{ color: "red" }}>{formErrors.aadhaarNo}</p>
                        {!inputState.otpSent && (<Button onClick={aadhaarVerificationSendOtp} variant="success" className="btn-sm float-right rounded-0 m-2">
                            Verify
                        </Button>)}

                    </Form.Group>
                    {(inputState.otpSent && !inputState.aadhaarVerified) && (
                        <Form.Group className="col-7 py-0 px-1'">

                            <Form.Label>
                                <span style={{ color: "red" }}>*</span>
                                {` Enter OTP`}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter OTP"
                                name="otp"
                                className="rounded-0"
                                value={inputState.otp}
                                onChange={handleChange}
                            />
                            <p style={{ color: "red" }}>{formErrors.otp}</p>

                            <Button onClick={aadhaarVerification} variant="success" className="btn-sm float-right rounded-0 m-2">
                                Validate
                            </Button>
                        </Form.Group>
                    )}

                    <Form.Group className="col-7 py-0 px-1'">
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Customer Name`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Customer Name"
                            name="aadhaarNo"
                            className="rounded-0"
                            value={inputState.custName}
                        //   onChange={handleChange}
                        />
                        <p style={{ color: "red" }}>{formErrors.custName}</p>
                    </Form.Group>
                    <Form.Group className="col-7 py-0 px-1'">
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Customer Mobile`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Customer Mobile"
                            name="custMobile"
                            className="rounded-0"
                            value={inputState.custMobile}
                            onChange={handleChange}
                        />
                        <p style={{ color: "red" }}>{formErrors.custMobile}</p>
                    </Form.Group>
                    <Form.Group className="col-7 py-0 px-1'">
                        <Form.Label>
                            <span style={{ color: "red" }}>*</span>
                            {` Enter Customer Email`}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Customer Email"
                            name="custEmail"
                            className="rounded-0"
                            value={inputState.custEmail}
                            onChange={handleChange}
                        />
                        <p style={{ color: "red" }}>{formErrors.custEmail}</p>
                    </Form.Group>
                    <div className="col-7 grid-margin">
                        <Form.Group>
                            <Form.Label>
                                <b>* Aadhaar Front Upload</b>
                            </Form.Label>
                            <br />
                            <Form.Label>
                                <span className="text-danger">
                                    Image size should be less than 256 kb
                                </span>
                            </Form.Label>
                            <Form.Control
                                accept=".jpeg"
                                type="file"
                                name="slip"
                                onChange={(e) => {
                                    readImage("aadhaarFront", e)
                                }}
                                style={{ borderRadius: "0px" }}
                            ></Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-7 grid-margin">
                        <Form.Group>
                            <Form.Label>
                                <b>* Aadhaar Back Upload</b>
                            </Form.Label>
                            <br />
                            <Form.Label>
                                <span className="text-danger">
                                    Image size should be less than 256 kb
                                </span>
                            </Form.Label>
                            <Form.Control
                                accept=".jpeg"
                                type="file"
                                name="slip"
                                onChange={(e) => {
                                    readImage("aadhaarBack", e)
                                }}
                                style={{ borderRadius: "0px" }}
                            ></Form.Control>
                        </Form.Group>
                    </div>
                    {/* <img src={aadhaarFront} alt="aadhaarFront" />
                <img src={aadhaarBack} alt="aadhaarFront" /> */}
                    <div className="mt-2 col-7 py-0 px-1 mt-4">
                        <Button
                            style={{ borderRadius: "0px" }}
                            variant="success"
                            onClick={handleSubmit}
                        >
                            Save Credit Card
                        </Button>
                    </div>
                </div>
            )}


        </div>
    );
};

const Success = ({ text }) => {
    return (
        <div className="card-inverse-success">
            <ContextMenuTrigger id="actionContextMenu">
                <div className="card-body" style={{ padding: "10px" }}>
                    <p className="card-text">{text}</p>
                </div>
            </ContextMenuTrigger>
        </div>
    );
};

export default CreditCardKYC;
