import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../../utils/utilFuctions";
import { GlobalContext } from "../../../Context/GlobalState";
import {
    BASE_URL_APISERVICES,
    BASE_URL_API_TRANXT_API,
} from "../../../utils/Constants";
import { offsetArr } from "../../../utils/CommonConfig";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import JSONTable from "../../../utils/JsonTable";
export const columns = [
    {
        dataField: "payment_indate",
        text: "Transaction Date",
    },
    {
        dataField: "tid",
        text: "Transaction ID",
    },
    {
        dataField: "orderid",
        text: "Order ID",
    },
    {
        dataField: "us_code",
        text: "User Code",
    },
    {
        dataField: "us_name",
        text: "User Name",
    },
    {
        dataField: "opening_bal",
        text: "Opening Balance",
    },
    {
        dataField: "load_amount",
        text: "Amount",
    },
    {
        dataField: "closing_bal",
        text: "Closing Balance",
    },

    {
        dataField: "chages",
        text: "chages",
    },
    {
        dataField: "gst",
        text: "GST",
    },
    {
        dataField: "route_name",
        text: "Route",
    },
    {
        dataField: "status",
        text: "Status",
    },
    {
        dataField: "sales_user_code",
        text: "Sales Code",
    },
    {
        dataField: "sales_user_name",
        text: "Sales Name",
    },
    {
        dataField: "sales_user_mobile",
        text: "Sales Mobile Number",
    },
];
const tdstyle = {
    fontSize: "0.8rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const tdstyle2 = {
    width: "10%",
    fontSize: "0.8rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const thstyle = {
    color: "dark",
    backgroundColor: "white",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
};

const userTypes = [
    { label: "Master Distributor", value: "4" },
    { label: "Distributor", value: "3" },
    { label: "Retailer", value: "2" },
    { label: "API Partner", value: "5" },
];

const PaymentGetwayReport = () => {
    const [userInfo, setUserInfo] = useState("");
    const [agentId, setAgentId] = useState("");
    const [referId, setReferId] = useState("");
    const [submittedData, setSubmittedData] = useState(null);
    const [name, setName] = useState('');
    const [addModal, setAddModal] = useState(false);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [mobile, setMobile] = useState("");
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [apiResponseModal, setApiResponseModal] = useState(false);
    const [apiResponseDetails, setApiResponseDetails] = useState({});
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { Profileinfo } = useContext(GlobalContext);
    console.log(data);

    const handleClear = () => {
        // Clear form fields and submitted data
        setName('');

        setSubmittedData(null);
        setAgentId("");
    }

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/print?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    const getPgRehit = async (msg) => {
        const data = await getPgRehitStatus(msg);
        if (data.msg == "Success") {
            swal("Success", `Status : ${data.status}`, "success");
        } else {
            swal("Failed", `Status : ${data.status}`, "error");
        }
    }


    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const twoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).toString();
    };

    function isJSONString(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    const decodeResponseMessage1 = (inputString) => {
        const keyValuePairs = inputString.split("|");
        const jsonResult = {};
        keyValuePairs.forEach((pair) => {
            const [key, value] = pair.split("=");
            jsonResult[key] = value;
        });
        return jsonResult;
    };

    const decodeResponseMessage = (inputString) => {
        return isJSONString(inputString)
            ? JSON.parse(inputString)
            : decodeResponseMessage1(inputString);
    };

    const getDMTReport = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: agentId,
                fromDate: dateFormatter(startDate),
                toDate: dateFormatter(endDate),
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://bumppy.in/pg/bumppyPaymentGatewayReport.php",
                requestOptions
            );
            const data = await res.json();
            if (data.status == "00") {
                setData(data.data);
            } else {
                swal(
                    "Record Not Found",
                    "Please Enter Valid ID Or Mobile number",
                    "error"
                );
            }
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };


    const showResponse = (response) => {
        setApiResponseDetails(response);
        setApiResponseModal(true);
    };

    const getPgRehitStatus = async (refid) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ==");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cookie", ".AspNetCore.Identity.Application=CfDJ8DmgTTCaRqRGqptPGoMyPwU9pj_KGHglf4IfD-ST2XmTL0nXXUTVUDlKKotXwTHn46d_FRWgH18Zh9VS6l40bmLDETjq8CgwWfVQkDjLPmp78eBlKrOIr0QpbAsCctWnCevEBaJt-lShonzhBlxjyr8hviEqczClLUoUicup5a2HeVfathdJixtv_cKGxazZUHbDKgx2dymn-kAVVoQxswjnD7xObeWVLjB8E8xSXw15EI8TcH3e668C5VlyVNFNUtJUPS90l7Li_2PDqH6pisVr3ydBL5PtyEEguwQQSsKhnRtgfs9OEuN2iWd7wlJv4C-ZaJfXvPiz-I3fSSb0g8L8cH7twb1yjGFXb8gFvYiwa909E9KtX-fh74KkSSjhb6biCgAAYqk1jsh5QzOfdfRsWjGOERC_WJ9jlfOx-MPZLED-hhqi2deWuPfX7_HAzV69cyFlQBLE9pJrcbBerZfpBtLVIqYvOo5BimvsrYLMNMX0Fd5jogs-LLSr1-piG_ohELhcGPbDreaQisEmd9uOfo5_MBtAIBCRwWV5ovKNw9-gGH7TxBSmGen5DZGmJ83e2TFwbLABkZ3u3aHF3A7ndLKiwTuzk1YwTbbKzbUrFVnbSFvWZLwTonH4cUQycU-DcD_Pn1pQ3OQP1AOGMwahL4UDAHg4ltIKod8DuQLm");

            var raw = JSON.stringify({
                status: "Y",
                refrence: "string",
                orderid: "string",
                token: "string",
                mode: "string",
                partner: "string",
                hash: "string",
                apiResponse: "string"
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            const res = await
                fetch("https://apiservices.bumppypay.com/api/Payment/pg/transactionPGUpdatePipe2", requestOptions);
            const data = await res.json();
            return data;
        } catch (error) {
            return { responseCode: "01", responseMsg: "Something went wrong", status: "Something went wrong" }
        }
    }

    const searchBarStyle = {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "6px",
        padding: "0px",
        backgroundColor: "#fff",
        width: "25%", // Adjust the width as needed
        height: "20%",
    };
    const inputStyle = {
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        fontSize: "16px",
        marginLeft: "8px",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: "400",
    };
    const iconStyle = {
        color: "rgba(0, 0, 0, 0.5)",
    };

    const positionStyle1 = {
        position: "absolute",
        top: -43,
        left: 0,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };
    const positionStyle2 = {
        position: "absolute",
        top: -44,
        left: -12,
        fontFamily: "Poppins",
        color: "rgba(255, 255,255, 1)",
    };

    console.log(data);

    return (
        <>
            <Modal
                show={addModal}
                onHide={() => setAddModal(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "#190482" }}>
                        <b>Response</b>{" "}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body
                    style={{
                        width: "100%",
                        height: "50%",
                        display: "block",
                        overflowY: "auto",
                    }}
                    className="account"
                >
                    <div className="row">
                        <div className="col">
                            <div>
                                <Form.Label for="orderID">Order ID:</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="orderID"
                                    name="orderID"
                                    readonly
                                />
                            </div>
                            <div>
                                <Form.Label for="txnAmount">Transaction Amount:</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="txnAmount"
                                    name="txnAmount"
                                    readonly
                                />
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                </Modal.Body>
            </Modal>

            <div>

                <div className="card mb-3 m-0 p-0"
                >
                    <div className="card-body m-0 p-0 pt-1 pb-1 pl-3 pr-2 ">
                        <div className="row m-0 p-0 pb-4 pt-3  ">
                            <div className="col-12 m-0 p-0 mt-2">
                                <div
                                    style={{
                                        "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                        , padding: "0px",
                                        background: "#FFFFFF",
                                        color: "#4267B2",
                                        fontWeight: "bolder",
                                        fontfamily: "Poppins !impotent",
                                        fontSize: "32px",
                                    }}
                                >
                                    Payment Getway Report
                                </div>
                            </div>

                            <div className="col-12 m-0 p-0 pr-1">
                                <div className="row m-0 p-0 d-flex justify-content-end" >
                                    <div className="col-2 m-0 p-0  mx-2  mt-3 ">


                                        <input
                                            type="text"
                                            className="form-control"
                                            aria-label="Notes"
                                            aria-describedby="basic-addon1"
                                            placeholder="Enter User Code Or Mobile No"
                                            value={agentId}
                                            onChange={(e) => setAgentId(e.target.value)}
                                            style={{ borderRadius: "5px" }}
                                        />

                                    </div>

                                    <div className="col-2 pr-1 pl-2" >
                                        <Form.Group>
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                //    sessionStorage.setItem("fromDate", date.toISOString());
                                                }}
                                                className="form-control rounded-100"
                                                placeholderText="Start Date"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-2 px-1">
                                        <Form.Group>
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                  //  sessionStorage.setItem("toDate", date.toISOString());
                                                }}
                                                className="form-control  rounded-100"
                                                placeholderText="End Date"
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-2 p-0 m-0 mx-2  mt-3 ">
                                        <Button
                                            type="submit"
                                            className="rounded-50 btn btn-primary btn-lg btn-block"
                                            onClick={getDMTReport}
                                        >
                                            {" "}
                                            Search
                                        </Button>
                                    </div>

                                    <div className="col-2 p-0 m-0 mx-2  mt-3 ">
                                        <Button
                                            type="button" onClick={handleClear}

                                            className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
                                        >
                                            {" "}
                                            Clear
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div className="">
                            <div className=" scroll-inner admin-fix-width transparent-table">
                                <table className=" table table-borderless text-dark ">
                                    <tr className="justify-content-around borderless-table text-center border-bottom text-dark">
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Sr"}
                                        </th>
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Payment ID"}
                                        </th>
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Order ID"}
                                        </th>
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"User ID"}
                                        </th>
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Amount"}
                                        </th>

                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Date & Time"}
                                        </th>
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Status"}
                                        </th>
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Ref No"}
                                        </th>
                                        <th
                                            className=" transparent-table text-center border-bottom text-dark"
                                            style={thstyle}
                                        >
                                            {" "}
                                            {"Action"}
                                        </th>
                                    </tr>
                                    {dataToDisplay.map((e, ind) => (
                                        <tr className=" transparent-table border-bottom ">
                                            <td className="align-middle" style={tdstyle}>
                                                {" "}
                                                {ind + 1}
                                            </td>
                                            <td className="align-middle" style={tdstyle}>
                                                {" "}
                                                <div className="d-flex flex-column align-items-center text-center">
                                                    <span className="text-center">{e.payment_id}</span>
                                                </div>
                                            </td>
                                            <td className="align-middle" style={tdstyle}>
                                                {" "}
                                                <div className="d-flex flex-column align-items-center text-center">
                                                    <span className="text-center">{e.orderid}</span>
                                                </div>
                                            </td>
                                            <td className="align-middle" style={tdstyle}>
                                                {" "}
                                                <span>{e.userid}</span>
                                            </td>

                                            <td className="align-middle" style={tdstyle}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <span>{e.amount}</span>
                                                </div>
                                            </td>
                                            <td className="align-middle" style={tdstyle}>
                                                <div className="d-flex flex-column align-items-center">
                                                    <span> {e.datetime}</span>
                                                </div>
                                            </td>
                                            <td className="align-middle" style={tdstyle}>
                                                <div className="d-flex flex-column align-items-center m-0">
                                                    {e.status == "Pending" ? (
                                                        <span
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "5px",
                                                                border: "1px solid",
                                                                backgroundColor: "rgba(254, 128, 36, 0.1)",
                                                                color: "rgba(254, 128, 36, 1)",
                                                                bordercolor: "rgba(254, 128, 36, 1)",
                                                            }}
                                                            className="p-2"
                                                        >
                                                            Pending
                                                        </span>
                                                    ) : e.status === "Failed" || e.status === "Failed" ? (
                                                        <span
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "5px",
                                                                border: "1px solid",
                                                                backgroundColor: "rgba(255, 0, 0, 0.1)",
                                                                color: " rgba(255, 0, 0, 1)",
                                                                bordercolor: " rgba(255, 0, 0, 1)",
                                                            }}
                                                            className="p-2"
                                                        >
                                                            Failed
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                width: "100%",
                                                                borderRadius: "5px",
                                                                border: "1px solid",
                                                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                                color: "rgba(43, 193, 85, 1)",
                                                                bordercolor: "rgba(43, 193, 85, 1)",
                                                            }}
                                                            className="p-2"
                                                        >
                                                            Success
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="align-middle" style={tdstyle}>
                                                <div className="d-flex flex-column align-items-center"  >
                                                    {e.refno == " " ? (
                                                        "NA") : (
                                                        <span >
                                                            {`${e.refno}`}
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="align-middle" style={tdstyle}>
                                                <div className="row m-0 my-auto p-0 justify-content-center">
                                                    <Button
                                                        style={{
                                                            // paddingLeft:"3px",
                                                            // padding: "5px",
                                                            borderRadius: "5px",
                                                            border: "1px none",
                                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                                            color: "rgba(255, 164, 27, 1)",
                                                            bordercolor: "rgba(255, 255, 255, 1)",
                                                        }}
                                                        className="btn btn-sm rounded-100 m-0 p-1"
                                                    >
                                                        {" "}
                                                        <div
                                                            onClick={() => setAddModal(true)}
                                                            className="hover-container border-0"
                                                        >
                                                            {/* Your content here */}
                                                            <img
                                                                className="border-0"
                                                                src={require("../../../../assets/images/Bank Response.png")}
                                                                style={{ borderRadius: "0px" }}
                                                            />
                                                            <div
                                                                style={positionStyle2}
                                                                className="hover-text bg-dark"
                                                            >
                                                                <div>Bank</div>
                                                                <div> Response</div>
                                                            </div>
                                                        </div>
                                                    </Button>

                                                    <Button
                                                        style={{
                                                            // paddingLeft:"3px",
                                                            // padding: "5px",
                                                            borderRadius: "5px",
                                                            border: "1px none",
                                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                                            color: "rgba(255, 164, 27, 1)",
                                                            bordercolor: "rgba(255, 255, 255, 1)",
                                                        }}
                                                        className="btn btn-sm rounded-100 m-0 p-1"
                                                        onClick={() => {
                                                            if (e.rch_amount >= 25000)
                                                                getPgRehit(e.tid)
                                                            else swal("Failed", "Status is failed", "error");
                                                        }}
                                                    >
                                                        {" "}
                                                        <div

                                                            className="hover-container border-0"
                                                        >
                                                            {/* Your content here */}
                                                            <img
                                                                src={require("../../../../assets/images/Raise Ticket.png")}
                                                                style={{ width: "" }}
                                                            />
                                                            <div
                                                                style={positionStyle2}
                                                                className="hover-text bg-dark"
                                                            >
                                                                <div>Bank</div>
                                                                <div> Response</div>
                                                            </div>
                                                        </div>
                                                    </Button>

                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {data.length <= 0 && <NoDataFound />}
                                </table>
                            </div>
                            <Pagination
                                page={currentPage}
                                limit={pages}
                                callback={(page) => {
                                    setCurrentPage(page);
                                }}
                                count={dataToDisplay.length}
                                total={total}
                                callback2={(offsetValue) => {
                                    setOffset(offsetValue);
                                }}
                            />
                        </div>
            </div>


        </>
    );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });

    const selectStyleLeftBorder = {
        borderRadius: "0px",
        fontFamily: "Poppins",
        fontsize: "16px",
        borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
        borderRadius: "0px",
        borderLeft: "none", // Remove right side border
        borderTop: "none", // Remove top border
        borderBottom: "none", // Remove bottom border
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyleBorder = {
        border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    const selectStyle = {
        border: "none", // Remove the border
        fontFamily: "Poppins",
        fontsize: "16px",
    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div style={{ fontFamily: "Poppins" }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>

            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">
                            {offset}
                        </option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}

                <Button className="bg-white text-primary " style={selectStyle}>
                    {" "}
                    {page.toString()}
                </Button>

                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
export const NoDataFound = () => {
    return (
        <tr className="row w-100 justify-content-center">
            <div className="d-flex flex-column align-items-center p-3">
                <img
                    src={require("../../../../assets/images/no_data_found.png")}
                    style={{ height: "5rem", width: "auto" }}
                />
                <strong>No Data Found</strong>
            </div>
        </tr>
    );
};

const ApiResponseModal = ({ details, isModalOpen, setModalOpen, refresh }) => {
    console.log(details);
    return (
        <Modal
            show={isModalOpen}
            onHide={() => setModalOpen(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            size="lg"
        >
            <Modal.Body>
                <div
                    style={{
                        padding: "12px",
                        background: "#FFFFFF",
                        color: "#4267B2",
                        fontSize: "28px",
                        fontFamily: "poppins",
                    }}
                    className="d-flex justify-content-between"
                >
                    API Response
                    <div
                        className="px-1 hover"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        {" "}
                        X
                    </div>
                </div>
                <div className="row">
                    <JSONTable data={details} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PaymentGetwayReport;