import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import {
//   excelMapping,
//   ExportToExcel,
//   formatDateDDMMYYHHMM,
// } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import Select from "react-select";
import {
  Tabs,
  Tab,
  Form,
  Dropdown,
  Button,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
// import { Pagination } from "../../distributor/Manage Retailer/DistributorReport";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";


import swal from "@sweetalert/with-react";
import { Link } from "react-router-dom";
import { useQuery } from "../../custom-hooks/useQuery";

export const columns = [
  {
    dataField: "fk_user_id",
    text: "user id",
  },
  {
    dataField: "us_shop_name",
    text: "Shop namer",
  },
  {
    dataField: "pay_balance",
    text: "Balance",
  },
  {
    dataField: "pay_before_balance",
    text: " before balance",
  },
  {
    dataField: "pay_after_balance",
    text: "after balance",
  },
  {
    dataField: "pay_type",
    text: "Type",
  },
  {
    dataField: "pay_indate",
    text: "Date & Time",
  },
];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const SalesReportTable = () => {
  const [searchText, setSearchText] = useState("");
  const [summary, setSummary] = useState([]);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const [userType, setUserType] = useState("2");
  const [page, setPage] = useState(1);
  const { Profileinfo , setTransactionLoaderState } = useContext(GlobalContext);

  const query = useQuery();
  let paramId = query.get("id") ? query.get("id") : "1";
  console.log(paramId);
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };


  const dashFilter = [
    { label: "Retailer", value: "2" },
    { label: "Distributor", value: "3" },
    { label: "Master Distributor", value: "4" },
  ];

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const SalesReport = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserID: "B1008",
      usertype: "4",
      fromdate: dateFormatter(startDate),
      todate: dateFormatter(endDate),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BASE_URL_API_TRANXT_API}/api/sales/get_sales_person_business.aspx`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "0" && result.data) {
          setData(result.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

 

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const showResponse = (response) => {
    swal(
      <div className="d-flex overflow-auto">
        <strong>{JSON.stringify(response)}</strong>
      </div>
    );
  };

  const getSalesReport = async () => {
     try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
        UserID: paramId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/reports/get_cnf_use_report_master_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSalesReport();
  }, []);
  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '25%', // Adjust the width as needed
    height:'20%',
   
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };



  return (
    <div>
    <div>
      {/* <h5 className="ml-2">Sales Reporting</h5> */}


      <div className="row m-0 p-0  pt-1 align-items-center">
              <div className="col-4 m-0 p-0">

               <div className="m-0 p-0 pl-2"
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",
                  }}
                >
                  Sales Report
                </div>
                </div>

<div className="col-8 m-0 p-0 ">
<div className="row  m-0 p-0  justify-content-end">
        
           
        
                
        
        
        
        <div className="pr-1 p-0 m-0"  style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
           
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control rounded-100"
              placeholderText="Start Date"
            />
          </Form.Group>
        </div>
        <div className="px-1  p-0 m-0" style={{width: '18%',  height:'20%',}}>
          <Form.Group className="p-0 m-0">
          
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="form-control rounded-100"
              placeholderText="End Date"
            />
          </Form.Group>
        </div>

        
        <div className="pl-1   p-0 m-0" style={{width: '14%',  height:'20%',}}>
          <Form.Group className="mt-0 p-0 m-0">
          <Button
              className="rounded-100 btn btn-lg btn-block "
             
              onClick={SalesReport}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
        <div className="m-0 p-0 hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(`AEPS_Report_${now()}`, dataToDisplay);
            }}
          >
              <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3.5rem`, height: '85%' }}
            />
             <div div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
          </div>
      </div>




</div>

      </div>


      <hr className="m-0 p-0"/>

      <table style={{  width: "100%" ,backgroundColor: '#FFFFFF'}} className="my-table  borderless transparent-table">
        <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            Sr
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            User ID
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            Shop name
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            Login id
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            Before Balance
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            After Balance
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            Balance
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            Type
          </th>
          <th className="my-table transparent-table text-center border-bottom py-4 " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
            Date
          </th>
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr className="my-table transparent-table border-bottom py-5" >
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>  {ind + 1}</td>
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>
              <div className="d-flex flex-column align-items-center">
                <Link
                  to={`/admin/cnf_use_report_dist?id=${
                    e.fk_user_id
                  }&from_date=${startDate.toISOString()}&to_date=${endDate.toISOString()}`}
                >
                  <div>{`${e.us_shop_name}-`}</div>
                  <div>{`${e.pay_indate}`}</div>
                </Link>
              </div>
            </td>
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.us_shop_name}</td>
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.us_loginid}</td>
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.pay_before_balance}</td>
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.pay_after_balance}</td>
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.pay_balance}</td>
           
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.pay_type}</td>
            <td className="text-center border-bottom" style={{ fontSize: '0.8rem' ,"fontFamily":"Poppins","fontWeight":"400","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}>{e.pay_indate}</td>
          </tr>
        ))}
      </table>
      <Pagination
        page={page}
        limit={limit}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </div>
  </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0 m-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0 m-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary" className="rounded-0 m-0">
          {" "}
          {page.toString()}
        </Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0 m-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default SalesReportTable;
