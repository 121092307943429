import React, { useContext } from "react";
const AEPSPrint2 = () => {
    // console.log(st);
    let printData = JSON.parse(sessionStorage.getItem("print"));
    if (!printData) {
        printData = { };
    }
    console.log(printData);
    const printReceipt = () => {
        window.print();
    };
    const NOTE =
        "This is Computer Generated receipt and does not require physical signature.";

    return (
        <div
            style={{
                margin: "5vw",
                padding: "1rem",
            }}
        >
            <div style={{ display: "flex", marginBottom: "10px" }}>
                <div style={{ flex: "1" }} />
                <button
                    style={{ marginLeft: "auto" }}
                    className="hide-on-print"
                    onClick={printReceipt}
                >
                    {"Print"}
                </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '5px' }}>
                <img src={require('../../assets/images/company/logo_square.png')} style={{ width: "10rem", height: "auto" }} />
                <img src={require("../../assets/images/yes_bank_Logo.png")} style={{ width: "10rem", height: "auto" }} />
            </div>
            <h5 style={{ border: "1px solid #979797", padding: "8px" }}>
                {" "}
                Agent Details
            </h5>

            <table style={{ fontSize: ".6rem", width: "100%", marginBottom: "8px" }}>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Agent Id :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.userid}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Shop Name :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.shopname}
                    </td>
                </tr>
            </table>
            <h5 style={{ border: "1px solid #979797", padding: "8px" }}>
                {" "}
                Customer Details
            </h5>
            <table style={{ fontSize: ".6rem", width: "100%", marginBottom: "8px" }}>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Transaction Id :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.sorTxnId}
                    </td>
                </tr>
                <tr
          style={{
            padding: "0px",
            border: "1px solid #979797",
            background: "none",
          }}
        >
          <td
            style={{
              fontSize: ".9rem",
              padding: "8px",
              border: "1px solid #979797",
              width: "50%",
              fontWeight: "bold",
            }}
          >
            Transaction Type :
          </td>
          <td
            style={{
              fontSize: ".9rem",
              padding: "8px",
              border: "1px solid #979797",
              width: "50%",
              fontWeight: "bold",
            }}
          >
            {printData.rch_service}
          </td>
        </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Customer Aadhaar Number :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.aadhar ? `${printData?.aadharNo}` : "NA"}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Amount :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.txnAmount}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Service :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.service}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        RRN :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.referenceNo}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        STAN :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.hmac}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Transaction Date Time :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.transactionDate}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Customer Bank Name :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.bank? printData?.bank : "NA"}
                    </td>
                </tr>

                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Device Name :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.device? printData?.device : "NA"}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Sender Mobile Number :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.mobile? printData?.mobile : "NA"}
                    </td>
                </tr>

                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Status :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.txnErrorMsg}
                    </td>
                </tr>
                <tr
                    style={{
                        padding: "0px",
                        border: "1px solid #979797",
                        background: "none",
                    }}
                >
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        Customer Available Balance :
                    </td>
                    <td
                        style={{
                            fontSize: ".9rem",
                            padding: "8px",
                            border: "1px solid #979797",
                            width: "50%",
                            fontWeight: "bold",
                        }}
                    >
                        {printData?.balance}
                    </td>
                </tr>
            </table>

            {(printData?.miniOffusStatementStructureModel || printData?.miniStatementStructureModel) && (
                <h5 style={{ border: "1px solid #979797", padding: "8px" }}>
                    {"Mini Statement"}
                </h5>
            )}

            {printData?.miniStatementStructureModel && (
                <table style={{ width: "100%" }}>
                    {printData?.miniStatementStructureModel.map((statement, i) => (
                        <tr
                            key={i}
                            style={{
                                padding: "0px",
                                border: "1px solid #979797",
                                background: "none",
                            }}
                        >
                            <td
                                style={{
                                    fontSize: ".9rem",
                                    padding: "8px",
                                    border: "1px solid #979797",
                                    width: "25%",
                                    fontWeight: "bold",
                                }}
                            >
                                {statement.date}
                            </td>
                            <td
                                style={{
                                    fontSize: ".9rem",
                                    padding: "8px",
                                    border: "1px solid #979797",
                                    width: "25%",
                                    fontWeight: "bold",
                                }}
                            >
                                {statement.txnType}
                            </td>
                            <td
                                style={{
                                    fontSize: ".9rem",
                                    padding: "8px",
                                    border: "1px solid #979797",
                                    width: "25%",
                                    fontWeight: "bold",
                                }}
                            >
                                {statement.amount}
                            </td>
                            <td
                                style={{
                                    fontSize: ".9rem",
                                    padding: "8px",
                                    border: "1px solid #979797",
                                    width: "25%",
                                    fontWeight: "bold",
                                }}
                            >
                                {statement.narration}
                            </td>
                        </tr>
                    ))}
                </table>
            )}

            {printData?.miniOffusStatementStructureModel && (
                <table style={{ width: "100%" }}>
                    {printData?.miniOffusStatementStructureModel.map((statement, i) => (
                        <tr
                            key={i}
                            style={{
                                padding: "0px",
                                border: "1px solid #979797",
                                background: "none",
                            }}
                        >
                            <td
                                style={{
                                    fontSize: ".9rem",
                                    padding: "8px",
                                    border: "1px solid #979797",
                                    width: "100%",
                                    fontWeight: "bold",
                                }}
                            >
                                {statement}
                            </td>
                        </tr>
                    ))}
                </table>
            )}
            <h6 style={{ border: "0px solid #979797", padding: "8px" }}>
                {NOTE}
            </h6>
        </div>
    );
};

export default AEPSPrint2;
