import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const CreateUser = () => {
  const initialState = {
    userType: "",
    fullName: "",
    mobile: "",
    email: "",
    shopname: "",
    dmtTariff: "",
    aepsTariff: "",
    bbpsTariff: "",
    rechargeTariff: "",
    cmsTariff: "",
    travelTariff: "",
    matmTariff: "",
    salesTeam: "",
    pincode: "",
    shopAddress: "",
    aadhaarNumber: "",
    panNumber: "",
    parentID: "1",
  };

  const [state, setState] = useState(initialState);
  const [userList, setUserList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [formErrors, setFormErrors] = useState({ mobile: "" });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const validateForm = () => {
    if (
      state.userType == "" ||
      state.fullName == "" ||
      state.email == "" ||
      state.shopAddress == "" ||
      state.shopname == "" ||
      state.pincode == "" ||
      state.parentID == ""
    ) {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    } 
    else if (state.mobile.length != 10) {
      swal("Invalid", "Please Enter Valid Mobile Number", "error");
      return;
    } 
    else if (state.aadhaarNumber.length != 12)
     {
      swal("Invalid", "Please Enter Valid Aadhaar Number", "error");
      return;
    }
     else if (state.panNumber.length != 10) 
     {
      swal("Invalid", "Please Enter Valid PAN Number", "error");
      return;
    }
    else if (state.pincode.length != 6) 
    {
     swal("Invalid", "Please Enter Valid Pincode", "error");
     return;
   }

    createUser();
  };

  const clearForm = () => {
    setState(initialState);
  };

  const createUser = async () => {
    try {
      setTransactionLoaderState(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user_type: state.userType.value,
        user_parentid: state.parentID.value,
        FullName: state.fullName,
        Email: state.email,
        Address: state.shopAddress,
        ShopName: state.shopname,
        Mobile: state.mobile,
        Aadhaar: state.aadhaarNumber,
        PanCard: state.panNumber,
        TarifID: "16",
        dmttariff: state.dmtTariff.value,
        aepstariff: state.aepsTariff.value,
        bbpstariff: state.bbpsTariff.value,
        ShopAddress: state.shopAddress,
        pincode: state.pincode,
        salesteamcode: "1",
        salesteamasmcode: "1",
        retailergst: "",
        adminid: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/users/create_user.aspx`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("User Registration Successful", data.msg, "success");
        clearForm();
      } else {
        swal("User Registration Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      swal("User Registration Failed", "Error From Server", "error");
    }
  };

  const getUserList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        usertype: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_all_user_list_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserList(
          data.data.map((user) => {
            return {
              ...user,
              label: `${user.uCode} - ${user.uName}`,
              value: user.id,
            };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const getTariffs = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        service: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/tariff/get_all_tariff.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setTariffs(
          data.data.map((e) => {
            return {
              ...e,
              label: e.tariff_name,
              value: e.pk_tariff_id,
            };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserList();
    getTariffs();
  }, []);

  return (
    <div style={{ padding: "1rem" }} className="bg-light ">
      <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
        <div className="card-body m-0 p-3">
          <div>
            <div
              style={{
                "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                ,padding: "0px",
                background: "#FFFFFF",
                color: "#4267B2",
                fontWeight: "bolder",
                fontfamily: "Poppins !impotent",
                fontSize:"28px",
              }}
            >
              Create Agent
            </div>

            <div>
              <div className="row p-3"
              style={{
                fontfamily: "Poppins",
                fontsize: "24px",
                fontweight: "600",
                lineheight: "36px",
                letterspacing: "0em",
                textalign: "left",
                color: "#00000080",



              }}
              
              >
                  <h4>Personal Info</h4>

                
                </div>



              <div className="col-10 m-0 p-0">
              < div className="row m-0 p-0">
                <div className="col-6 p-0 pr-2 ">
                  <Form.Group className="rounded-100">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>  Select User Type</span>
                    
                    </Form.Label>
                    <Select
                      options={userTypes}
                      value={state.userType}
                      onChange={(e) => {
                        setState({ ...state, userType: e });
                        setParentList(
                          userList.filter((parent) => {
                            if (e.value == 4) {
                              return (
                                parent.us_type ==
                                (Number(e.value) + 2).toString()
                              );
                            } else {
                              return (
                                parent.us_type ==
                                (Number(e.value) + 1).toString()
                              );
                            }
                          })
                        );
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col-6 p-0 pl-2 ">
                  <Form.Group  className="rounded-100">
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Select Parent</span>
                      
                    </Form.Label>
                    <Select
                      options={parentList}
                      value={state.parentID}
                      onChange={(e) => {
                        setState({ ...state, parentID: e });
                      }}
                    />
                  </Form.Group>
                </div>
                </div>
                </div>
                
                <div className="col-10 m-0 p-0">
                <div className="row m-0 p-0">
                <div className="col-6 p-0 pr-2">
                  <Form.Group >
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Enter Full Name</span>
                      
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Full Name"
                      value={state.fullName}
                      onChange={(e) => {
                        setState({ ...state, fullName: e.target.value });
                      }}
                     
                    />
                  </Form.Group>
                </div>

                <div className="col-6 p-0 pl-2 ">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Enter Mobile</span>
                      
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Mobile"
                      maxLength="10"
                      value={state.mobile}
                      onChange={(e) => {
                        if (e.target.value.length > 10) {
                          setFormErrors({
                            ...formErrors,
                            mobile: "you cant enter more than 10 digit",
                          });
                        } else {
                          setFormErrors({
                            ...formErrors,
                            mobile: "",
                          });
                          setState({ ...state, mobile: e.target.value });
                        }
                      }}
                     
                    />
                    <p style={{ color: "red" }}>{formErrors.mobile}</p>
                  </Form.Group>
                </div>
                </div>
                </div>


              <div className="col-10 m-0 p-0">
              <div className="row m-0 p-0">
                <div className="col-6 p-0 pr-2 ">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Enter Email ID</span>
                      
                    </Form.Label>
                    <Form.Control
                      type="email"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Email ID"
                      value={state.email}
                      onChange={(e) => {
                        setState({ ...state, email: e.target.value });
                      }}
                     
                    />
                  </Form.Group>
                </div>

                <div className="col-6 p-0 pl-2 ">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Enter Shop Name</span>
                     
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Shop Name"
                      value={state.shopname}
                      onChange={(e) => {
                        setState({ ...state, shopname: e.target.value });
                      }}
                   
                    />
                  </Form.Group>
                </div>
              </div>
              </div>
            </div>

            <div>
              
            
            <div className="row p-3"
              style={{
                fontfamily: "Poppins",
                fontsize: "24px",
                fontweight: "600",
                lineheight: "36px",
                letterspacing: "0em",
                textalign: "left",
                color: "#00000080",



              }}
              
              >
                <h4>Attach Tariff Info</h4>

              
                </div>

              <div className=" col-10 m-0 p-0">
                <div className="row m-0 p-0">
                <div className="col-4 p-0  m-0 pr-2 ">
                  <Form.Group >
                    <Form.Label>

                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>DMT Tariff</span>
                      
                    </Form.Label>
                    <Select
                   
                      options={tariffs}
                      value={state.dmtTariff}
                      onChange={(e) => {
                        setState({ ...state, dmtTariff: e });
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-4 p-0 m-0 pr-2 pl-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>AEPS Tariff</span>
                      
                    </Form.Label>
                    <Select
                      options={tariffs}
                      value={state.aepsTariff}
                      onChange={(e) => {
                        setState({ ...state, aepsTariff: e });
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-4 p-0  m-0 pl-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}> BBPS Tariff</span>
                   
                    </Form.Label>
                    <Select
                      options={tariffs}
                      value={state.bbpsTariff}
                      onChange={(e) => {
                        setState({ ...state, bbpsTariff: e });
                      }}
                    />
                  </Form.Group>
                </div>
                </div>
                </div>





                <div className=" col-10 m-0 p-0">
                <div className="row">
                <div className="col-4 p-0 pr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Recharge Tariff</span>
                     
                    </Form.Label>
                    <Select
                      options={tariffs}
                      value={state.rechargeTariff}
                      onChange={(e) => {
                        setState({ ...state, rechargeTariff: e });
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-4 p-0 pr-2 pl-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>CMS Tariff</span>
                      
                    </Form.Label>
                    <Select
                      options={tariffs}
                      value={state.cmsTariff}
                      onChange={(e) => {
                        setState({ ...state, cmsTariff: e });
                      }}
                    />
                  </Form.Group>
                </div>
                
                <div className="col-4 p-0 pl-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}> Travel Tariff</span>
                    
                    </Form.Label>
                    <Select
                      options={tariffs}
                      value={state.travelTariff}
                      onChange={(e) => {
                        setState({ ...state, travelTariff: e });
                      }}
                    />
                  </Form.Group>
                </div>
                </div>
                </div>




                <div className=" col-10 m-0 p-0">
                <div className="row">
                <div className="col-4 p-0 pr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>MATM Tariff</span>
                      
                    </Form.Label>
                    <Select
                      options={tariffs}
                      value={state.matmTariffs}
                      onChange={(e) => {
                        setState({ ...state, matmTariff: e });
                      }}
                    />
                  </Form.Group>
                </div>
              
          
                <div className="col-4 p-0 pl-2 pr-2 ">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Onboarded By</span>
                      
                    </Form.Label>
                    <Select
                      options={userTypes}
                      value={state.salesTeam}
                      onChange={(e) => {
                        setState({ ...state, salesTeam: e });
                      }}
                    />
                  </Form.Group>
                </div>
          
              </div>
            </div>
            </div>


            <div>
             <div className="row p-3"
              style={{
                fontfamily: "Poppins",
                fontsize: "48px",
                fontweight: "600",
                lineheight: "36px",
                letterspacing: "0em",
                textalign: "left",
                color: "#00000080",

              }}
              
              >
                
                <h4>Address & Document Info</h4>
              
                </div>
              
              <div className=" col-10 m-0 p-0">
              <div className="row m-0 p-0">
                <div className="col-6 p-0 pr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Pin Code</span>
                    
                    </Form.Label>
                    <Form.Control
                      type="number" 
                      maxLength="10"

                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Pin Code"
                      value={state.pincode}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          setFormErrors({
                            ...formErrors,
                            pincode: "You can't enter more than 6 digit",
                          });
                        } else {
                          setFormErrors({
                            ...formErrors,
                            pincode: "",
                          });
                          setState({ ...state, pincode: e.target.value });
                        }
                      }}
                    
                     
                    />
                     <p style={{ color: "red" }}>{formErrors.pincode}</p>
                  </Form.Group>
                </div>

                <div className="col-6 p-0 pl-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Shop Address</span>
                     
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Shop Address"
                      value={state.shopAddress}
                      onChange={(e) => {
                        setState({ ...state, shopAddress: e.target.value });
                      }}
                    
                    />
                  </Form.Group>
                </div>
                </div>
                </div>
                </div>

                <div className=" col-10 m-0 p-0">
                <div className="row m-0 p-0">
                <div className="col-6 p-0  pr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}>Aadhar Number</span>
                     
                    </Form.Label>
                    <Form.Control
                      type="number" 
                      maxLength="12"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Aadhar Number"
                      value={state.aadhaarNumber}

                      onChange={(e) => {
                        if (e.target.value.length > 12) {
                          setFormErrors({
                            ...formErrors,
                            aadhaarNumber: "You can't enter more than 12 digit",
                          });
                        } else {
                          setFormErrors({
                            ...formErrors,
                            aadhaarNumber: "",
                          });
                          setState({ ...state,aadhaarNumber: e.target.value });
                        }
                      }}





                    
                    /><p style={{ color: "red" }}>{formErrors.aadhaarNumber}</p>
                  </Form.Group>
                </div>

                <div className="col-6 p-0 pl-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      <span style={{ color: "black" }}> Pancard Number</span>
                     
                    </Form.Label>
                    <Form.Control
                     type="text" 
                      maxLength="10"
                      className="form-control rounded-100"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Pancard Number"

                      onChange={(e) => {
                        if (e.target.value.length > 10) {
                          setFormErrors({
                            ...formErrors,
                            panNumber: "you cant enter more than 10 digit",
                          });
                        } else {
                          setFormErrors({
                            ...formErrors,
                            panNumber: "",
                          });
                          setState({ ...state, panNumber: e.target.value });
                        }
                      }}


                       value={state.panNumber}
                      // onChange={(e) => {
                      //   setState({ ...state, panNumber: e.target.value });
                      // }}
                     
                    />
                    <p style={{ color: "red" }}>{formErrors.panNumber}</p>
                  </Form.Group>
                </div>
                </div>
                </div>
             
            </div>
            <div className="">
              <div className="row">
                <div className="col-2 p-0 ml-2">
                  <Button
                    className="rounded-50 btn btn-primary btn-lg btn-block"
                    
                    onClick={validateForm}
                  >
                    {" "}
                    Submit
                  </Button>
                </div>
                <div className="col-2 p-0 ml-2">
                  <Button
                    className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
                   
                    onClick={clearForm}
                  >
                    {" "}
                    Clear
                  </Button>
                </div>
                <div className="col p-0"></div>
                <div className="col p-0"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default CreateUser;
