import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { GlobalContext } from "../../Context/GlobalState";
import { offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router";
import { Dropdown, Card } from "react-bootstrap";
import Select from "react-select";
import { validateTPin } from "../../utils/Validations";

export const columns = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "type",
    text: "Type",
  },
  {
    dataField: "sender_user_code",
    text: "Sender",
  },
  {
    dataField: "sender_user_name",
    text: "Sender Name",
  },
  {
    dataField: "receiver_user_code",
    text: "Receiver",
  },
  {
    dataField: "receiver_user_name",
    text: "Receiver Name",
  },
  {
    dataField: "sales_user_code",
    text: "Sales Person",
  },
  {
    dataField: "rch_before_balance",
    text: "Opening Balance",
  },
  {
    dataField: "amount",
    text: "Amount",
  },
  {
    dataField: "rch_after_balance",
    text: "Closing Balance",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "indate",
    text: "Date",
  },
];

const DebitFund = () => {
  const [pageAccess, setPageAccess] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [billDetails, setBillDetails] = useState({});
  const [amount, setAmount] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getAddReverseFundReport = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        adminid: "",
        type: "",
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/v1.0/getAddReverseFundReport`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.responseCode == "00") {
            setData(result.data);
          } else {
            setData([]);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "15%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const positionStyle = {
    position: "absolute",
    top: -27,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  useEffect(() => {
    getAddReverseFundReport();
  }, []);

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data })
      // shopName: Profileinfo.shopname
    );
    const newWindow = window.open(
      `/pages/print/cmsSlip?id=${data.tid}`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <div>
        <div className="card m-0 p-0 pt-3">
          <div className="card-body m-0 p-0">
            <div className="">
              <div
                defaultActiveKey="profile"
                className=" tab-header justify-content-start"
              >
                <div
                  className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                  onClick={() => handleTabClick(0)}
                >
                  Reverse Fund
                </div>
                <div
                  className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => handleTabClick(1)}
                >
                  Fund Record
                </div>
              </div>
              <div className="tab-content">
                {activeTab === 0 && (
                  <div>
                    <ReverseFund />
                  </div>
                )}
                {activeTab === 1 && (
                  <div>
                    <ReverseFundReport />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const ReverseFund = (
  SendotpAdd,
  details,
  isModalOpen,
  setModalOpen,
  selectedData,
  refresh
) => {
  const [tpinShow, setTpinShow] = useState(false);
  // const [state, setState] = useState({});
  const [Pin, setPin] = useState("");
  const [msg, setMsg] = useState("");
  const [AddBlance, setAddBlance] = useState("");
  const [Ptype, setPtype] = useState("2");
  const [Amount, setAmount] = useState("");
  const [Remark, setRemark] = useState("");
  const [Reverse, setReverse] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [otp, setOtp] = useState("");
  const [Comment, setComment] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpText, setOtpText] = useState("");
  const { user, refreshWallet, Profileinfo, setTransactionLoaderState } =
    useContext(GlobalContext);
  const [userRoles, setUserRoles] = useState([]);
  const [retailer, setRetailer] = useState({});
  const [showOtpFlow, setShowOtpFlow] = useState(false);
  const [reverseBalanceOtp, setReverseBalanceOtp] = useState(null);
  const [state, setState] = useState({
    service: null,
    retailerMobile: "",
    reverse_balance_otp: "",
  });

  const clearForm = () => {
    setPin("");
    setMsg("");
    setComment("");
    setAmount("");
    setAddBlance("");
  };

  useEffect(() => {
    setState(details);
    console.log(details);
  }, [details]);

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const tpinValidation = async () => {
    validateTPin(
      Profileinfo.mobile,
      Pin,
      () => {
        console.log("Call addblance");
        addblance();
      },
      () => {
        clearForm();
        document
          .querySelector(".content-wrapper")
          .classList.toggle("sidebar-icon-only");
      }
    );
  };

  const addblance2 = () => {
    setTransactionLoaderState(true);

    var raw = JSON.stringify({
      retpkid: retailer.pk_user_id,
      distripkid: user.pk_user_id,
      amount: Amount,
      comment: Remark,
      Tpin: Pin,
      paytype: "2",
    });
    console.log(raw);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/api_master_dist_reverse_balance.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setAddBlance(result);
          if (result.status == "0") {
            cogoToast.success(result.msg);
          } else {
            cogoToast.error(result.msg);
          }

          refreshWallet();
          refresh();
          clearForm();
          document
            .querySelector(".content-wrapper")
            .classList.toggle("sidebar-icon-only");
          setOtpSent(false);
          setOtpValidated(false);
          setTransactionLoaderState(false);
          setModalOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
      clearForm();
    }
  };

  const addblance = () => {
    var raw = JSON.stringify({
      retpkid: retailer.pk_user_id,
      distripkid: user.pk_user_id,
      amount: Amount,
      comment: Remark,
      Tpin: Pin,
      paytype: "2",
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };
    console.log(raw);
    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/api_master_dist_reverse_balance.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setReverse(result);
          cogoToast.info(result.msg);
          refreshWallet();
          document
            .querySelector(".content-wrapper")
            .classList.toggle("sidebar-icon-only");
          setOtpSent(false);
          setOtpValidated(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  const sendOtp = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      const raw = JSON.stringify({
        mobile: state.retailerMobile,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        `https://apiservices.bumppypay.com/api/Sales/salesMobileVerifySendOtp`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMessage, "success");
        setOtpSent(true);
      } else {
        swal("Failed", data.responseMessage, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", "Something Went Wrong", "error");
      console.log("error", error);
    }
  };

  const fetchRetailerDetails = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        mobile: state.retailerMobile,
        userid: user.ReferId,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `https://apiservices.bumppypay.com/api/Payment/getUserConfigSettings`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setState((prevState) => ({
          ...prevState,
          reverse_balance_otp: data.data.reverse_balance_otp,
        }));
      } else {
        swal("error", "Error fetching retailer details", "error");
      }
    } catch (error) {
      swal("error", "API error, please try again", "error");
    }
  };

  const VerifyOtp = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      const raw = JSON.stringify({
        mobile: state.retailerMobile,
        otp: otp,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        `https://apiservices.bumppypay.com/api/Sales/salesMobileVerifyVerifyOtp`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        if (Ptype && Amount) {
          tpinValidation();
        }
        swal("Success", data.responseMessage, "success");
      } else {
        swal("Failed", data.responseMessage, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", "Something Went Wrong", "error");
      console.log("error", error);
    }
  };

  const validateOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: state.retailerMobile,
        otp: otpText,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/validateOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setOtpValidated(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getUserList = async () => {
    try {
      setTransactionLoaderState(true);
      const raw = JSON.stringify({
        UserID: "8",
      });

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/distributor/dist_get_provider_list.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserRoles(
          data.data.map((role) => {
            return {
              label: `${role.us_code} - ${role.us_shop_name}`,
              value: role.us_code,
              ...role,
            };
          })
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div
            className="d-flex justify-content-center align-items-start"
            style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
          >
            <div
              className="card p-4"
              style={{
                maxWidth: "700px",
                width: "100%",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                borderRadius: "8px",
                backgroundColor: "white",
                marginTop: "5rem",
              }}
            >
              <h4
                className="text-center"
                style={{
                  color: "rgba(66, 103, 178, 1)",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Debit Fund
              </h4>

              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Retailer ID:
                </Form.Label>
                <Select
                  className="rounded-lg"
                  options={userRoles}
                  value={state.service}
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      service: e,
                      retailerMobile: e.us_loginid,
                    }));
                    setRetailer(e);
                    fetchRetailerDetails(e.us_loginid);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Old Balance:
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Old Balance"
                  style={{ borderRadius: "0px" }}
                  value={retailer.userbalance}
                  name="Name"
                  readOnly
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Reverse Balance:
                </Form.Label>
                <Form.Control
                  onChange={(e) => setAmount(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Enter New Amount"
                  style={{ borderRadius: "4px", opacity: 0.8 }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Retailer Mobile No :
                </Form.Label>
                <Form.Control
                  value={state.retailerMobile}
                  type="text"
                  className="form-control"
                  placeholder="Enter Mobile No"
                  style={{ borderRadius: "4px", opacity: 0.8 }}
                  onChange={(e) =>
                    setState({ ...state, retailerMobile: e.target.value })
                  }
                  readOnly
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Remarks:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Enter Remarks"
                  className="form-control"
                  style={{ borderRadius: "4px", opacity: 0.8 }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Enter Pin:
                </Form.Label>
                <div className="input-group">
                  <input
                    type={tpinShow ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter T Pin"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setTpinShow(!tpinShow)}
                  >
                    <i
                      className={`mdi text-primary ${
                        tpinShow ? "mdi-eye" : "mdi-eye-off"
                      }`}
                    ></i>
                  </button>
                </div>
              </Form.Group>

              <p className="text-danger text-center">{msg}</p>

              {/* Conditional OTP Flow */}
              {state.reverse_balance_otp === "Y" ? (
                otpSent ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label
                        style={{ fontSize: "16px", fontFamily: "Poppins" }}
                      >
                        Enter OTP:
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => setOtp(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder="Enter OTP"
                        style={{ borderRadius: "4px", opacity: 0.8 }}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="btn btn-primary"
                        style={{ minWidth: "100px", borderRadius: "4px" }}
                        onClick={VerifyOtp}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Button
                      className="btn btn-primary"
                      style={{ minWidth: "100px", borderRadius: "4px" }}
                      onClick={sendOtp}
                    >
                      Send OTP
                    </Button>
                  </div>
                )
              ) : (
                <div className="d-flex justify-content-center">
                  <Button
                    className="btn btn-primary"
                    style={{ minWidth: "100px", borderRadius: "4px" }}
                    onClick={(e) =>
                      Ptype && Amount
                        ? tpinValidation()
                        : setMsg("*Please fill all fields")
                    }
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

const ReverseFundReport = () => {
  const [pageAccess, setPageAccess] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [billDetails, setBillDetails] = useState({});
  const [amount, setAmount] = useState("");

  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getAddReverseFundReport = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        adminid: "",
        type: "Debit",
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/v1.0/getAddReverseFundReport`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.responseCode == "00") {
            setData(result.data);
          } else {
            setData([]);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "15%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const positionStyle = {
    position: "absolute",
    top: -27,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  useEffect(() => {
    getAddReverseFundReport();
  }, []);

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data })
      // shopName: Profileinfo.shopname
    );
    const newWindow = window.open(
      `/pages/print/cmsSlip?id=${data.tid}`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div>
        <div className="row m-0 p-0 pt-1 align-items-center">
          <div className="col-3 m-0 p-0">
            <div
              className="m-0 p-0 pl-3 align-top"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0em",
                textAlign: "left",
                padding: "0px",
                background: "#FFFFFF",
                color: "#4267B2",
                fontWeight: "bolder",
                fontfamily: "Poppins !impotent",
                fontSize: "28px",
              }}
            >
              Reverse Fund Report
            </div>
          </div>

          <div className="col-9 m-0 p-0 ">
            <div className="row m-0 p-0 justify-content-end">
              <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={iconStyle}
                  className="ml-2"
                />
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
              </div>

              <div
                className="pr-1 pl-2 p-0 m-0"
                style={{ width: "18%", height: "20%" }}
              >
                <Form.Group className="p-0 m-0">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control rounded-100"
                    placeholderText="Start Date"
                  />
                </Form.Group>
              </div>
              <div
                className="px-1 p-0 m-0"
                style={{ width: "18%", height: "20%" }}
              >
                <Form.Group className="p-0 m-0">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="form-control rounded-100"
                    placeholderText="End Date"
                  />
                </Form.Group>
              </div>

              <div
                className="pl-1 p-0 m-0"
                style={{ width: "14%", height: "20%" }}
              >
                <Form.Group className="mt-0 p-0 m-0">
                  <Button
                    className=" btn btn-primary btn-lg btn-block "
                    style={{
                      background: "#4267B2",
                      color: "white",
                    }}
                    onClick={getAddReverseFundReport}
                  >
                    {"Search"}
                  </Button>
                </Form.Group>
              </div>

              <div
                className="m-0 p-0 hover-container"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ExportToExcel(
                    `ADD_REVERSE_FUND_REPORT_${now()}`,
                    excelMapping(data, columns)
                  );
                }}
              >
                <img
                  className="pl-1 p-0"
                  src={require("../../../assets/images/download Icon.png")}
                  style={{ width: `3.5rem`, height: "85%" }}
                />
                <div style={positionStyle1} className="hover-text bg-dark ">
                  Export to Excel
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="m-0 p-0" />

        <div style={{ width: "100%" }}>
          <div style={{ alignItems: "center" }}>
            <div style={{ flex: "1" }}></div>
          </div>
        </div>
        <div className=" scroll-inner admin-fix-width borderless-table ">
          <table
            className="my-table transparent-table text-center border-bottom"
            style={{
              width: "100%",
              overflowX: "auto",
              border: "0px",
              overflow: "auto",
            }}
          >
            <tr className="borderless-table text-center border-bottom">
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                {"Sr"}
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                ID
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Type
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Sender
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Sender Name
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Receiver
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Receiver Name
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Opening Balance
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Amount
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Closing Balance
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Sales Person
              </th>

              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Status
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Date
              </th>
            </tr>
            {dataToDisplay.map((e, ind) => (
              <tr className="border-bottom">
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {ind + 1}
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.id}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.type}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.sender_user_code}</div>
                  <div>{e.sender_user_mobile}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.sender_user_name}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.receiver_user_code}</div>
                  <div>{e.receiver_user_mobile}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.receiver_user_name}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {twoDecimalPlaces(e.rch_before_balance)}
                </td>

                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{twoDecimalPlaces(e.amount)}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{twoDecimalPlaces(e.rch_after_balance)}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.sales_user_code}</div>
                  <div>{e.sales_user_name}</div>
                  <div>{e.sales_user_mobile}</div>
                </td>

                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {e.status.toString().toLowerCase() == "success" ? (
                    <label
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                      }}
                    >
                      {"Success"}
                    </label>
                  ) : (
                    <label
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(254, 128, 36, 0.1)",
                        color: "rgba(254, 128, 36, 1)",
                        bordercolor: "rgba(254, 128, 36, 1)",
                      }}
                    >
                      {e.status}
                    </label>
                  )}
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {e.indate}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page) => {
            setCurrentPage(page);
          }}
          count={dataToDisplay.length}
          total={total}
          callback2={(offsetValue) => {
            setOffset(offsetValue);
          }}
        />
      </div>
    </>
  );
};

export default DebitFund;
