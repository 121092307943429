import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Card, Dropdown, Modal } from "react-bootstrap";
import Dmt1Sidebar from "./Dmt1Sidebar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import DMT1Table from "./DMT1Table";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";
import cogoToast from "cogo-toast";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import swal from "sweetalert";
import { RecordNotFound } from "../DMT/DMT";
import { generateHashKey2, onlyAlpha } from "../../utils/utilFuctions";
import { useQuery } from "../../custom-hooks/useQuery";
import DMTBankitSendMoneyModal from "./DMTBankitSendMoneyModal";
const { DMTBankitUrl } = urls;
const columns = [
  {
    dataField: "recipient_name",
    text: "Name",
    sort: false,
  },
  {
    dataField: "bank",
    text: "Bank Name",
    sort: false,
  },
  {
    dataField: "account",
    text: "Account Number",
    sort: false,
  },
  {
    dataField: "ifsc",
    text: "IFSC",
    sort: false,
  },
  {
    dataField: "Mode",
    text: "Mode",
    sort: false,
  },
];

const toastoptions = {
  hideAfter: 5,
  position: "top-center",
};
toast.configure();
const DMTBankit = () => {
  // Search Params
  const query = useQuery();
  let remitterNumberFromParams = query.get("number") ? query.get("number") : "";
  const globalState = useContext(GlobalContext);
  const [number, setNumber] = useState("");
  const [remitterName, setRemmiterName] = useState("");
  const [found, setFound] = useState(true);
  const [remitter, setRemmiter] = useState(null);
  const [beneficiary, setBenificiary] = useState([]);
  const [info, setInfo] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [otpTyped, setOTPTyped] = useState("");
  const [details, setDetails] = useState({});
  const { setTransactionLoaderState,user } = globalState;
  const [formErrors, setFormErrors] = useState({ remitterNameErrors: "" });

  const [sendMoneyModalState, setSendMoneyModelState] = useState({
    isOpen: false,
    details,
  });

  const successToast = (msg) => {
    cogoToast.success(msg, toastoptions);
  };
  const errorToast = (msg) => {
    cogoToast.error(msg, toastoptions);
  };
  // const success = (msg) => {
  //   cogoToast.success(msg, this.toastoptions);
  // };
  // const success = (msg) => {
  //   cogoToast.success(msg, this.toastoptions);
  // };
  useEffect(() => {
    if (number.length == 10) {
      checkRemitter();
    }
    setFound(true);
    setOtpSent(false);
  }, [number]);
  const checkRemitter = async () => {
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "checkremitter");
    urlencoded.append("loginid", number);
    urlencoded.append("mobile", number);
    urlencoded.append("name", "N");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTBankitUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status == "1") {
        setFound(true);
        setRemmiter(data);
        setRemmiterName(data.name);
        getBeneficiary();
      } else {
        setRemmiterName("");
        setFound(false);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const getBeneficiary = async () => {
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "getbeneficiary");
    urlencoded.append("mobile", number);
    urlencoded.append("userId", number);
    urlencoded.append("route", "0");
    urlencoded.append("amount", "0");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTBankitUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      setInfo(data);
      setBenificiary(data.data.recipient_list);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const sendOTPForCreateRemmiter = async () => {
    if (remitterName === "") {
      // Toast
      errorToast("please enter remmiter name");
      return;
    }
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "checkremitter");
    urlencoded.append("loginid", number);
    urlencoded.append("mobile", number);
    urlencoded.append("name", remitterName);

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTBankitUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      console.log(data);
      if (data.status == "2") {
        successToast("OTP sent successfully");
        setOtpSent(true);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      errorToast("OTP not sent, something went wrong");
      console.log("error", error);
    }
  };
  const submitOTP = async () => {
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "verifyremitter");
    urlencoded.append("mobileno", number);
    urlencoded.append("name", remitterName);
    urlencoded.append("otp", otpTyped);
    urlencoded.append("route", "0");
    urlencoded.append("userid", number);

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTBankitUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      checkRemitter();
      setNumber("");
      setRemmiterName("");
      setOTPTyped("");
      successToast("Remmiter created successfully");
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      errorToast("something went wrong");
      console.log("error", error);
    }
  };

  const refreshLimit = async () => {
    checkRemitter();
  };

  useEffect(() => {
    setNumber(remitterNumberFromParams);
  }, [remitterNumberFromParams]);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <DMTBankitSendMoneyModal modalstate={sendMoneyModalState} />

              <Dmt1Sidebar
                details={{
                  benificiary: details,
                  user: globalState.user,
                  successToast,
                  errorToast,
                  limit: remitter
                    ? remitter.routes
                      ? remitter.routes.length > 0
                        ? remitter.routes[0].limit
                        : null
                      : null
                    : null,
                }}
              />
            </div>
            <div
              style={{
                padding: "10px",
                textAlign: "center",
              }}
            >
              {remitter == null ? (
                <div style={{ background: "#30363e", marginBottom: "10px" }}>
                  <strong style={{ color: "#FFF", fontSize: "1.5rem" }}>
                    {`Domestic Money Transfer -Available Limit: `}
                  </strong>
                </div>
              ) : (
                <div style={{ background: "#30363e", marginBottom: "10px" }}>
                  <strong style={{ color: "#FFF", fontSize: "1.5rem" }}>
                    {`Domestic Money Transfer -Available Limit: ${remitter.routes[0].limit}`}
                  </strong>
                </div>
              )}

              <div className="row">
                <div className="col text-left">
                  <span style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                    Find or Create Customer
                  </span>
                </div>
                <div className="col">
                  <Modal2
                    successToast={successToast}
                    errorToast={errorToast}
                    remitter={remitter}
                    remitterNumber={number}
                    userId={globalState.user.ReferId}
                    refresh={() => {
                      getBeneficiary();
                    }}
                  />
                </div>
              </div>

              {/* <div style={{ display: "flex" }}>
          <Button
            className="rounded-0"
            style={{ margin: "6px 0px 6px auto" }}
            variant="success"
            onClick={() =>
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only")
            }
          >
            Transfer to Beneficiary
          </Button>
        </div> */}
              <div style={{ width: "40vw", margin: "10px 0px" }}>
                <div style={{ display: "flex" }}>
                  <Form.Group style={{ flex: "1" }}>
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Customer Mobile Number"
                      onChange={(e) => {
                        setFound(false);
                        setRemmiter(null);
                        setRemmiterName("");
                        setBenificiary([]);
                        if (e.target.value.length <= 10)
                          setNumber(e.target.value);
                      }}
                      value={number}
                      maxLength="10"
                    />
                  </Form.Group>
                  <Form.Group style={{ flex: "1" }}>
                    <Form.Control
                      onChange={(e) => {
                        //   setRemmiterName(e.target.value);

                        if (onlyAlpha(e.target.value)) {
                          // console.log("alpha");
                          setFormErrors({
                            ...formErrors,
                            remitterNameErrors: "",
                          });
                        } else {
                          //console.log("numeric");
                          setFormErrors({
                            ...formErrors,
                            remitterNameErrors:
                              "Customer Name Contains only Alphabates",
                          });
                          // alert("message");
                        }
                        setRemmiterName(e.target.value);
                      }}
                      value={remitterName}
                      style={{ marginLeft: "10px" }}
                      type="text"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Customer Name"
                    />
                    <p style={{ color: "red" }} className="text-center">
                      {formErrors.remitterNameErrors}
                    </p>
                  </Form.Group>
                </div>
                {otpSent ? (
                  <Form.Group style={{ flex: "1" }}>
                    <Form.Control
                      type="number"
                      className="form-control rounded-0"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter OTP"
                      onChange={(e) => {
                        setOTPTyped(e.target.value);
                      }}
                      value={otpTyped}
                      maxLength="10"
                    />
                  </Form.Group>
                ) : null}
                {!found && number.length == 10 && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      style={{
                        backgroundColor: "#3bafda",
                        border: "none",
                        margin: "10px 0px",
                      }}
                      className="rounded-0"
                    >
                      Find Another Number
                    </Button>
                    <Button
                      style={{ backgroundColor: "#4a89dc", border: "none" }}
                      className="rounded-0"
                      onClick={() => {
                        if (otpSent) {
                          submitOTP();
                        } else {
                          sendOTPForCreateRemmiter();
                        }
                      }}
                    >
                      {`${otpSent ? "Submit" : "Send"} OTP`}
                    </Button>
                  </div>
                )}
              </div>

              {beneficiary.length > 0 && (
                <DMT1Table
                  successToast={successToast}
                  errorToast={errorToast}
                  data={beneficiary}
                  columns={columns}
                  remitterNumber={number}
                  userId={context.user.ReferId}
                  refresh={() => {
                    getBeneficiary();
                  }}
                  setSidebarDetails={(detail) => {
                    setDetails(detail);
                    setSendMoneyModelState({
                      setModelState: setSendMoneyModelState,
                      refreshLimit: refreshLimit,
                      isOpen: true,
                      details: {
                        benificiary: detail,
                        user: globalState.user,
                        successToast,
                        errorToast,
                        limit: remitter
                          ? remitter.routes
                            ? remitter.routes.length > 0
                              ? remitter.routes[0].limit
                              : null
                            : null
                          : null,
                        remitterNumber: number,
                      },
                    });
                  }}
                />
              )}

              {beneficiary.length == 0 && <RecordNotFound />}
            </div>
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

function Modal2({
  remitterNumber,
  userId,
  refresh,
  remitter,
  errorToast,
  successToast,
}) {
  const [mdShow, setMdShow] = useState(false);
  const [bank, setBank] = useState(null);
  const [ifscCode, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [banks, setBanks] = useState([]);
  const [mobileNo, setMobileNo] = useState("");
  const [formErrors, setFormErrors] = useState({ beneficiaryName: "" });
  const [popupState, setPopupState] = useState(false);
  const {user} = useContext(GlobalContext)

  const validate = (callback) => {
    let errors = {};
    if (beneficiaryName.toString().trim().length < 3) {
      errors.beneficiaryName = "Beneficiary name atleast 3 character long`";
    }
    if (formErrors.beneficiaryName) {
      errors.beneficiaryName = formErrors.beneficiaryName;
    }
    if (accountNumber.toString().trim().length < 9) {
      errors.accountNumber = "please enter valid account number";
    }
    if (
      ifscCode.toString().trim().length < 10 ||
      ifscCode.toString().trim().length > 12
    ) {
      errors.ifsc = "please enter valid IFSC code";
    }
    if (mobileNo.toString().trim().length != 10) {
      errors.mobile = "please enter valid mobile number";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      callback();
    }
  };

  const getBanks = async () => {

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "banklistnew2");
    urlencoded.append("beneficiaryid", "111");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTBankitUrl, requestOptions);

      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data !== null && data.data !== null) {
        const options = data.data.bankList.map((e) => {
          return { ...e, label: e.bankName, value: e.ifsc };
        });
        setBanks(options);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getBanks();
  }, []);

  const addBeneficiary = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "addbeneficiary");
    urlencoded.append("ifsctype", "IMPS");
    urlencoded.append("remitterNumber", remitterNumber);
    urlencoded.append("beneName", beneficiaryName);
    urlencoded.append("beneNumber", mobileNo);
    urlencoded.append("ifscCode", ifscCode);
    urlencoded.append("accountNumber", accountNumber);
    urlencoded.append("bankId", bank.bankCode);
    urlencoded.append("userid", userId);
    urlencoded.append("IsVerify", "0");
    urlencoded.append("bank", bank.bankName);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    try {
      const res = await fetch(DMTBankitUrl, requestOptions);

      const raw = await res.text();
      const data = JSON.parse(raw);
      successToast("beneficiary added successfully");
      refresh();
      setMdShow(false);
    } catch (error) {
      errorToast("something went wrong");
      console.log("error", error);
    }
  };

  const verify = (bene) => {
    swal({
      title: "Are you sure?",
      text: "3 Rupees deducted from your Account",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // setBenificiary(bene);
        addBeneficiaryVerify();
      }
    });
  };

  const addBeneficiaryVerify = async () => {

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "addbeneficiary");
    urlencoded.append("ifsctype", "IMPS");
    urlencoded.append("remitterNumber", remitterNumber);
    urlencoded.append("beneName", beneficiaryName);
    urlencoded.append("beneNumber", mobileNo);
    urlencoded.append("ifscCode", ifscCode);
    urlencoded.append("accountNumber", accountNumber);
    urlencoded.append("bankId", bank.bankCode);
    urlencoded.append("userid", userId);
    urlencoded.append("IsVerify", "1");
    urlencoded.append("bank", bank.bankName);

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTBankitUrl, requestOptions);

      const raw = await res.text();

      const data = JSON.parse(raw);

      refresh();
      setMdShow(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const clearForm = () => {
    setIfscCode("");
    setAccountNumber("");
    setBeneficiaryName("");
    setMobileNo("");
    // setOtpSent(false);
  };
  useEffect(() => {
    clearForm();
  }, [mdShow]);

  return (
    <React.Fragment>
      <Button
        variant="success"
        onClick={() => {
          if (remitter) {
            setMdShow(true);
          } else {
            errorToast("Please Enter Valid Customer Number");
          }
        }}
        className="btn float-right m-2"
      >
        Add Beneficiary
      </Button>
      <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Add Beneficiary</Modal.Title>
        </Modal.Header>
        <SweetAlert
          show={popupState}
          title="Are you sure?"
          text="Rs 3 deducted from your Account"
          type="warning"
          showCancelButton
          onConfirm={(ok) => {
            setPopupState(false);
            addBeneficiaryVerify();
          }}
        />

        <Modal.Body className="account">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Group style={{ flex: "1" }}>
              <Select
                options={banks}
                onChange={(e) => {
                  setBank(e);
                  setIfscCode(e.ifsc);
                }}
              />
              {/* <Dropdown style={{ width: "100%" }}>
                <Dropdown.Toggle
                  style={{ flex: "1", textAlign: "left" }}
                  variant="light"
                  id="dropdown-basic"
                >
                  {bank === null ? "Select Bank" : bank.bankName}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: "100%" }}>
                  {banks.map((bank) => (
                    <Dropdown.Item
                      key={bank.bankCode}
                      onClick={() => {
                        setBank(bank);
                        setIfscCode(bank.ifsc);
                      }}
                    >
                      {bank.bankName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}
            </Form.Group>
            <Form.Group style={{ flex: "1" }}>
              <Form.Control
                value={ifscCode}
                onChange={(e) => {
                  setIfscCode(e.target.value);
                  if (
                    e.target.value.toString().trim().length < 10 ||
                    e.target.value.toString().trim().length > 12
                  ) {
                    setFormErrors({
                      ...formErrors,
                      ifsc: "please enter valid IFSC code",
                    });
                  }
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`IFSC Code`}
              />
              <p style={{ color: "red" }} className="text-center">
                {formErrors.ifsc}
              </p>
            </Form.Group>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Group style={{ flex: "1", paddingRight: "10px" }}>
              <Form.Control
                onChange={(e) => {
                  setAccountNumber(e.target.value);
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Account Number`}
              />
              <p style={{ color: "red" }} className="text-center">
                {formErrors.accountNumber}
              </p>
            </Form.Group>
            <Form.Group style={{ flex: "1" }}>
              <Form.Control
                onChange={(e) => {
                  if (onlyAlpha(e.target.value)) {
                    // console.log("alpha");
                    setFormErrors({ ...formErrors, beneficiaryName: "" });
                  } else {
                    //console.log("numeric");
                    setFormErrors({
                      ...formErrors,
                      beneficiaryName:
                        "Beneficiary Name Contains only Alphabates",
                    });
                    // alert("message");
                  }
                  setBeneficiaryName(e.target.value);
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Beneficiary Name`}
              />
              <p style={{ color: "red" }} className="text-center">
                {formErrors.beneficiaryName}
              </p>
            </Form.Group>
          </div>
          <Form.Group style={{ flex: "1", paddingRight: "10px" }}>
            <Form.Control
              onChange={(e) => {
                setMobileNo(e.target.value);
              }}
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder={`Beneficiary Mobile Number`}
              value={mobileNo}
            />
            <p style={{ color: "red" }} className="text-center">
              {formErrors.mobile}
            </p>
          </Form.Group>
          <div style={{ display: "flex" }}>
            <Button
              className="rounded-0"
              style={{ flex: "1", margin: "0px 12px" }}
              variant="success"
              onClick={() => {
                validate(verify);
              }}
            >
              {"Add With Verify"}
            </Button>
            {/* <Button
              className="rounded-0"
              style={{ flex: "1", margin: "0px 12px" }}
              variant="danger"
              onClick={() => {
                validate(addBeneficiary);
              }}
            >
              {"Add Without Verify"}
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default DMTBankit;
