import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card, Modal } from "react-bootstrap";
import { urls } from "../../URLs";
import { GlobalContext } from "../../Context/GlobalState";
import swal from "sweetalert";
import { generateHashKey2 } from "../../utils/utilFuctions";

const { DMTBankitUrl } = urls;

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const DMTBankitSendMoneyModal = ({ modalstate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [amountError, setAmountError] = useState("");
  const { setTransactionLoaderState, refreshWallet } =
    useContext(GlobalContext);
  const { details, isOpen, setModelState, refreshLimit } = modalstate;

  const tPinLength = 6;
  const [amount, setAmount] = useState(0);
  const [tPin, setTPin] = useState("");
  const { benificiary, user, successToast, errorToast, limit } = details;
  const [charges, setCharges] = useState(0);

  const sendMoney = async () => {
    if (amount == "" || Number(amount) < 100 || Number(amount) > 25000) {
      errorToast("Please Enter amount in Range 100 to 25000");
      return;
    } else if (tPin == "" || tPin.length < tPinLength) {
      errorToast("Please enter valid T Pin");
      return;
    }
    if (Number(amount) > limit) {
      swal(
        "Failed",
        `You have exceeded your daily limit. Your available limit is ${details.limit}`,
        "error"
      );
      return;
    }
    setTransactionLoaderState(true);


    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "sendmoney");
    urlencoded.append("beneficiaryid", benificiary.recipient_id);
    urlencoded.append("mobile", benificiary.recipient_mobile);
    urlencoded.append("amount", amount);
    urlencoded.append("tid", "IMPS");
    urlencoded.append("uid", user.ReferId);
    urlencoded.append("type", "mobile");
    urlencoded.append("bank", benificiary.bank);
    urlencoded.append("tpin", tPin);
    urlencoded.append("route", "0");
    urlencoded.append("accountno", benificiary.account);
    urlencoded.append("paytmlimit", "0");
    urlencoded.append("bankitlimit", "0");

    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTBankitUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status) {
        successToast("Amount transfer successfull");
        setModelState({ ...modalstate, isOpen: false });
        refreshLimit();
      } else {
        errorToast(data.message ? data.message : "Amount Transfer Failed");
      }
      setTransactionLoaderState(false);
      refreshWallet();

      refreshWallet();
    } catch (error) {
      setTransactionLoaderState(false);
      errorToast("Amount transfer failed");
      console.log("error", error);
    }
  };
  useEffect(() => {
    setAmount(0);
    setCharges(0);
    setTPin("");
  }, [details, isOpen]);

  const close = () => {
    setTPin("");
    setModelState({ ...modalstate, isOpen: false });
    // document
    //   .querySelector(".content-wrapper")
    //   .classList.toggle("sidebar-icon-only");
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => setModelState({ ...modalstate, isOpen: false })}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <Modal.Header closeButton>
        <Modal.Title> Send Money</Modal.Title>
      </Modal.Header>
      {/* <SweetAlert
    show={popupState}
    title="Are you sure?"
    text="Rs 3 deducted from your Account"
    type="warning"
    showCancelButton
    onConfirm={() => {
      addBeneficiaryVerify();
      setPopupState(false);
    }}
  /> */}

      {isOpen && (
        <Modal.Body
          style={{ width: "100%", display: "block" }}
          className="account"
        >
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div style={{ display: "flex" }}>
              <span>Domestic Money Transfer Limit</span>
              <strong style={{ color: "green", margin: "0 0 0 1rem" }}>
                {details.limit}{" "}
              </strong>
            </div>
          </Card>
          <Card style={{ margin: "10px", padding: "10px" }}>
            <Form.Group>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Beneficiary Name"
                value={benificiary.recipient_name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Beneficiary A/C"
                value={benificiary.account}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="AMOUNT"
                style={{ display: "flex", alignItems: "center" }}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  const amt = Number(e.target.value);
                  if (amt < 100 || amt > 25000) {
                    setAmountError(
                      "Kindly enter an amount within the specified range of 100 to 25000"
                    );
                  } else {
                    setAmountError("");
                  }

                  let c = Number(e.target.value) / 100;
                  if (Number(c) < 10) {
                    c = 10;
                  }
                  if (Number(c) > 250) {
                    c = 250;
                  }

                  setCharges(c);
                }}
              />
            </Form.Group>
            <div className="row justify-content-between">
              {amountError && (
                <span
                  style={{ color: "red", padding: "0px 0px 1rem 0px" }}
                >{`${amountError}`}</span>
              )}

              {charges > 0 && (
                <span
                  style={{ color: "red", padding: "0px 0px 1rem 0px" }}
                >{`Charges Rs ${charges}`}</span>
              )}
            </div>
            {/* {charges > 0 && (
          <span style={{ color: "red" }}>{`Charges Rs ${charges}`}</span>
        )} */}
            <Form.Group>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter T-Pin"
                onChange={(e) => {
                  setTPin(e.target.value);
                }}
              />
            </Form.Group>

            <div className="row">
              <div className="col p-1">
                <Button
                  className="rounded-0 btn-block"
                  variant="primary"
                  onClick={sendMoney}
                  disabled={isLoading}
                >
                  {isLoading ? "Transaction Processing.." : "Transfer Now"}
                </Button>
              </div>
              <div className="col p-1">
                <Button
                  className="rounded-0 btn-block"
                  variant="success"
                  onClick={close}
                >
                  {" "}
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default DMTBankitSendMoneyModal;
