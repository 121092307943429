import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card, Table } from "react-bootstrap";
import { urls } from "../../URLs";
import { GlobalContext } from "../../Context/GlobalState";
import {
    dmtTransactionFailedPopup,
    dmtTransactionSuccessPopup,
} from "../../utils/Models";
import swal from "sweetalert";
import JsonTable from "../json-component/JsonTable";

const badgeConfig = [
    { value: "Under Process", badge: "warning" },
    { value: "Re Submitted", badge: "primary" },
    { value: "Reject", badge: "error" },
    { value: "Pending", badge: "warning" },
    { value: "Approved", badge: "success" },
]

const detailFields = [
    { text: "USER NAME", datafield: "usr_first_name" },
    { text: "USER CODE", datafield: "ucode" },
    { text: "USER TYPE", datafield: "us_type" },
    { text: "KYC STATUS", datafield: "sstatus", badge: true },
    { text: "SHOP NAME", datafield: "shop_name" },
    { text: "SHOP ADDRESS", datafield: "shopadd" },
    { text: "MOBILE", datafield: "usr_mobile" },
    { text: "EMAIL", datafield: "usr_email" },
    { text: "INDATE", datafield: "indate" },
    { text: "GENDER", datafield: "gender" },
    { text: "Date Of Birth", datafield: "userdob" },
    { text: "PAN CARD NO", datafield: "pan_card_no" },
    { text: "AADHAAR CARD NO", datafield: "aadhar_card_no" },
    { text: "GST NUMBER", datafield: "gst_number" },
    { text: "KYC REMARK", datafield: "kyc_remark" },

    { text: "US PARENT NAME", datafield: "usParent_name" },
    { text: "US PARENT CODE", datafield: "usParent_code" },
    { text: "US PARENT MOBILE NO", datafield: "usParent_loginid" },
    { text: "US PARENTLVL2 NAME", datafield: "usParentlvl2_name" },
    { text: "US PARENTLVL2 CODE", datafield: "usParentlvl2_code" },
    { text: "US PARENTLVL2 MOBILE NO", datafield: "usParentlvl2_loginid" },
    { text: "US PARENTLVL3 NAME", datafield: "usParentlvl3_name" },
    { text: "US PARENTLVL3 CODE", datafield: "usParentlvl3_code" },
    { text: "US PARENTLVL3 MOBILE NO", datafield: "usParentlvl3_loginid" },
    { text: "JOINING DATE", datafield: "joiningDate" },
    { text: "SHOP PIN CODE", datafield: "shop_pin_code" },
    { text: "SHOP CITY", datafield: "shop_city" },
    { text: "SHOP STATE", datafield: "shop_state" },
    { text: "SALES USER CODE", datafield: "sales_user_code" },
    { text: "SALES USER NAME", datafield: "sales_user_name" },
    { text: "SALES USER MOBILE", datafield: "sales_user_mobile" }
];


const Sidebar22 = {
    position: "fixed",
    right: "0",
    width: "0px",
    height: "60%",
    overflowY: "scroll",
    overflowX: "hidden",
    background: "#FFF",
};

const KYCDetailsSidebar = ({ details }) => {

    const renderValue = (value) => {
        if (Array.isArray(value)) {
            return (
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                    {value.map((item, index) => (
                        <li key={index}>{renderValue(item)}</li>
                    ))}
                </ul>
            );
        } else if (typeof value === 'object' && value !== null) {
            return <JsonTable data={value} />;
        } else {
            return String(value);
        }
    };

    console.log(details);


    console.log(badgeConfig.find(item => item.value == details['sstatus']));


    return (
        <nav
            className="side-sheet sidebar Sidebar23"
            id="sidebar2"
            style={Sidebar22}
        >
            <div
                style={{ padding: "0px", marginLeft: "10px", marginRight: "10px", position: 'sticky' }}
                className="row"
            >
                <div
                    style={{ paddingLeft: "5px", display: "flex", alignItems: "center" }}
                    className="col"
                >
                    <strong style={{ color: "#1b68ca" }}>
                        KYC Details
                    </strong>
                </div>
                <div style={{ paddingRight: "0px" }} className="col">
                    <span
                        style={{
                            fontSize: "2rem",
                            display: "flex",
                            color: "#b2b2b3",
                            justifyContent: "end",
                            ariaHidden: "true",
                            paddingRight: "4px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            document
                                .querySelector(".content-wrapper")
                                .classList.toggle("sidebar-icon-only");
                        }}
                    >
                        &times;
                    </span>
                </div>
            </div>
            <Dropdown.Divider />
            <Card >
                <Table borderless>
                    <tbody>
                        {detailFields.map((e) => (
                            details[e.datafield] && (
                                e.badge ? (<tr key={e.text}>
                                    <td>
                                        {e.text}
                                    </td>
                                    <td>
                                        <span className={`badge badge-${badgeConfig.find(item => item.value == details[e.datafield])?.badge}`} > {details[e.datafield]}</span>
                                    </td>
                                </tr>) : (
                                    <tr key={e.text}>
                                        <td>
                                            {e.text}
                                        </td>
                                        <td>{details[e.datafield]}</td>
                                    </tr>
                                )
                            )

                        ))}
                    </tbody>
                </Table>
            </Card>
        </nav>
    );
};

export default withRouter(KYCDetailsSidebar);
