export const accountdata = [
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,048,371.11CR",
        "balanceIndicator": "2,048,371.11CR",
        "extendedNarrator": "IMPS-API/507114500445/MR ERSAD ALI/SBIN0000001/1990/Utility",
        "uuidNumber": "507114500445"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,073,371.11CR",
        "balanceIndicator": "2,073,371.11CR",
        "extendedNarrator": "IMPS-API/507114500335/KAJAL/BARB0TILDEL/8056/Utility",
        "uuidNumber": "507114500335"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,075,371.11CR",
        "balanceIndicator": "2,075,371.11CR",
        "extendedNarrator": "IMPS-API/507114499452/Sajidur haman/BKID0004214/2530/Utility",
        "uuidNumber": "507114499452"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:34",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "15,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,075,846.11CR",
        "balanceIndicator": "2,075,846.11CR",
        "extendedNarrator": "IMPS-API/507114499602/KUSUMA WO SUNDER LAL/BARB0NEHRUP/4319/Utility",
        "uuidNumber": "507114499602"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,090,846.11CR",
        "balanceIndicator": "2,090,846.11CR",
        "extendedNarrator": "IMPS-API/507114498993/Mr SANJAY  CHOWDHURY/SBIN0009209/1182/Utility",
        "uuidNumber": "507114498993"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "300.00DR",
        "amountIndicator": "DR",
        "balance": "2,096,846.11CR",
        "balanceIndicator": "2,096,846.11CR",
        "extendedNarrator": "IMPS-API/507114498441/Sachin Kumar Prasad/ICIC0000265/0301/Utility",
        "uuidNumber": "507114498441"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,097,146.11CR",
        "balanceIndicator": "2,097,146.11CR",
        "extendedNarrator": "IMPS-API/507114498442/SAHEB ALI/IDIB000C149/7489/Utility",
        "uuidNumber": "507114498442"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:23:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "700.00DR",
        "amountIndicator": "DR",
        "balance": "2,097,646.11CR",
        "balanceIndicator": "2,097,646.11CR",
        "extendedNarrator": "IMPS-API/507114498553/shaikh kareem/AIRP0000001/3877/Utility",
        "uuidNumber": "507114498553"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,098,346.11CR",
        "balanceIndicator": "2,098,346.11CR",
        "extendedNarrator": "IMPS-API/507114498055/Mrs CHINJA BAI CHADHAR/SBIN0009209/2502/Utility",
        "uuidNumber": "507114498055"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,800.00DR",
        "amountIndicator": "DR",
        "balance": "2,100,346.11CR",
        "balanceIndicator": "2,100,346.11CR",
        "extendedNarrator": "IMPS-API/507114498032/Tabssum Begam/AIRP0000001/6526/Utility",
        "uuidNumber": "507114498032"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,102,146.11CR",
        "balanceIndicator": "2,102,146.11CR",
        "extendedNarrator": "IMPS-API/507114497962/ANGNOO/BARB0TILDEL/1559/Utility",
        "uuidNumber": "507114497962"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,103,146.11CR",
        "balanceIndicator": "2,103,146.11CR",
        "extendedNarrator": "IMPS-API/507114498005/Mrs RAM  KUVAR/SBIN0009209/7219/Utility",
        "uuidNumber": "507114498005"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,115,146.11CR",
        "balanceIndicator": "2,115,146.11CR",
        "extendedNarrator": "IMPS-API/507114497577/BANDNA KUMARI/SBIN0RRVCGB/8296/Utility",
        "uuidNumber": "507114497577"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,115,918.11CR",
        "balanceIndicator": "2,115,918.11CR",
        "extendedNarrator": "IMPS-API/507114497403/AKBAR ALI/SBIN0000001/3653/Utility",
        "uuidNumber": "507114497403"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,117,918.11CR",
        "balanceIndicator": "2,117,918.11CR",
        "extendedNarrator": "IMPS-API/507114497503/Anil Ahirwar/SBIN0009183/2760/Utility",
        "uuidNumber": "507114497503"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:22:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,118,690.11CR",
        "balanceIndicator": "2,118,690.11CR",
        "extendedNarrator": "IMPS-API/507114496637/MR SUDEEP RAJ/SBIN0000001/1973/Utility",
        "uuidNumber": "507114496637"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:21:45",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "29,999.00DR",
        "amountIndicator": "DR",
        "balance": "2,125,690.11CR",
        "balanceIndicator": "2,125,690.11CR",
        "extendedNarrator": "IMPS-API/507114495445/rahul/BARB0COLABA/4630/Utility",
        "uuidNumber": "507114495445"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:21:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "300.00DR",
        "amountIndicator": "DR",
        "balance": "2,155,689.11CR",
        "balanceIndicator": "2,155,689.11CR",
        "extendedNarrator": "IMPS-API/507114495264/Anoj kumar/HDFC0000839/3984/Utility",
        "uuidNumber": "507114495264"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:21:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "300.00DR",
        "amountIndicator": "DR",
        "balance": "2,155,989.11CR",
        "balanceIndicator": "2,155,989.11CR",
        "extendedNarrator": "IMPS-API/507114495243/Bayjajanti Brahma/SBIN0013090/8260/Utility",
        "uuidNumber": "507114495243"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:21:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "30,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,156,289.11CR",
        "balanceIndicator": "2,156,289.11CR",
        "extendedNarrator": "IMPS-API/507114494128/rahul/BARB0COLABA/4630/Utility",
        "uuidNumber": "507114494128"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:20:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,186,289.11CR",
        "balanceIndicator": "2,186,289.11CR",
        "extendedNarrator": "IMPS-API/507114492611/DARA SHANTHI/UBIN0812463/2328/Utility",
        "uuidNumber": "507114492611"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:20:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,187,061.11CR",
        "balanceIndicator": "2,187,061.11CR",
        "extendedNarrator": "IMPS-API/507114492380/Vicky jashwal/KKBK0004599/3126/Utility",
        "uuidNumber": "507114492380"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:20:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "22,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,187,833.11CR",
        "balanceIndicator": "2,187,833.11CR",
        "extendedNarrator": "IMPS-API/507114491158/MRS GUNJANKUMARI KUMARI/SBIN0000001/2827/Utility",
        "uuidNumber": "507114491158"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:20:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,209,833.11CR",
        "balanceIndicator": "2,209,833.11CR",
        "extendedNarrator": "IMPS-API/507114491173/Mahalingam subramanian/CNRB0001551/7982/Utility",
        "uuidNumber": "507114491173"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:19:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,210,118.11CR",
        "balanceIndicator": "2,210,118.11CR",
        "extendedNarrator": "IMPS-API/507114489636/Sulochna mishra/KKBK0000220/5288/Utility",
        "uuidNumber": "507114489636"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:19:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,121.00DR",
        "amountIndicator": "DR",
        "balance": "2,210,890.11CR",
        "balanceIndicator": "2,210,890.11CR",
        "extendedNarrator": "IMPS-API/507114488111/TITLI GOSWAMI/IDIB000R585/3288/Utility",
        "uuidNumber": "507114488111"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "14,450.00DR",
        "amountIndicator": "DR",
        "balance": "2,215,011.11CR",
        "balanceIndicator": "2,215,011.11CR",
        "extendedNarrator": "IMPS-API/507114487042/Mr SONU  SAINI/SBIN0009209/2718/Utility",
        "uuidNumber": "507114487042"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,229,461.11CR",
        "balanceIndicator": "2,229,461.11CR",
        "extendedNarrator": "IMPS-API/507114487079/Ramchandra taterao garudi/SBIN0020301/1701/Utility",
        "uuidNumber": "507114487079"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,230,411.11CR",
        "balanceIndicator": "2,230,411.11CR",
        "extendedNarrator": "IMPS-API/507114486879/Balaji khatal/AIRP0000001/8362/Utility",
        "uuidNumber": "507114486879"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,121.00DR",
        "amountIndicator": "DR",
        "balance": "2,230,886.11CR",
        "balanceIndicator": "2,230,886.11CR",
        "extendedNarrator": "IMPS-API/507114486843/PUSHPA LODHI/PUNB0059700/7314/Utility",
        "uuidNumber": "507114486843"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,235,007.11CR",
        "balanceIndicator": "2,235,007.11CR",
        "extendedNarrator": "IMPS-API/507114486842/CHANDAN MAHTO/SBIN0006352/4573/Utility",
        "uuidNumber": "507114486842"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,235,779.11CR",
        "balanceIndicator": "2,235,779.11CR",
        "extendedNarrator": "IMPS-API/507114486975/Jitendra gouda/SBIN0004624/1803/Utility",
        "uuidNumber": "507114486975"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,236,254.11CR",
        "balanceIndicator": "2,236,254.11CR",
        "extendedNarrator": "IMPS-API/507114486386/RAJIYA WO MD HARUN/CBIN0280658/4240/Utility",
        "uuidNumber": "507114486386"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,238,754.11CR",
        "balanceIndicator": "2,238,754.11CR",
        "extendedNarrator": "IMPS-API/507114486608/MAMTA DEVI W O SANTOSH KUMAR RAY/BARB0NEHRUP/3668/Utility",
        "uuidNumber": "507114486608"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "24,999.00DR",
        "amountIndicator": "DR",
        "balance": "2,251,754.11CR",
        "balanceIndicator": "2,251,754.11CR",
        "extendedNarrator": "IMPS-API/507114486354/PARUL ASSOCIATES/SBIN0009209/8918/Utility",
        "uuidNumber": "507114486354"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,276,753.11CR",
        "balanceIndicator": "2,276,753.11CR",
        "extendedNarrator": "IMPS-API/507114486253/YOGESH YOGESH/YESB0000095/0550/Utility",
        "uuidNumber": "507114486253"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "680.00DR",
        "amountIndicator": "DR",
        "balance": "2,281,753.11CR",
        "balanceIndicator": "2,281,753.11CR",
        "extendedNarrator": "IMPS-API/507114486031/KONDETI KUMAR/SBIN0001805/5830/Utility",
        "uuidNumber": "507114486031"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,714.00DR",
        "amountIndicator": "DR",
        "balance": "2,282,433.11CR",
        "balanceIndicator": "2,282,433.11CR",
        "extendedNarrator": "IMPS-API/507114486166/VINAY C S/UBIN0931471/3901/Utility",
        "uuidNumber": "507114486166"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,290,147.11CR",
        "balanceIndicator": "2,290,147.11CR",
        "extendedNarrator": "IMPS-API/507114485926/SAROJ MAJHI/HDFC0002693/1006/Utility",
        "uuidNumber": "507114485926"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:18:00",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,292,647.11CR",
        "balanceIndicator": "2,292,647.11CR",
        "extendedNarrator": "IMPS-API/507114485649/RAVINATH  SO RAM LAKHAN/BARB0TILDEL/4041/Utility",
        "uuidNumber": "507114485649"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,301,647.11CR",
        "balanceIndicator": "2,301,647.11CR",
        "extendedNarrator": "IMPS-API/507114484694/Ankita Singh/BARB0KHAGAX/2909/Utility",
        "uuidNumber": "507114484694"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,302,419.11CR",
        "balanceIndicator": "2,302,419.11CR",
        "extendedNarrator": "IMPS-API/507114484233/Shyam Subba/BKID0005636/0495/Utility",
        "uuidNumber": "507114484233"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,303,369.11CR",
        "balanceIndicator": "2,303,369.11CR",
        "extendedNarrator": "IMPS-API/507114484206/ASHUTOSH NANAVARE/KKBK0000647/6024/Utility",
        "uuidNumber": "507114484206"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,303,869.11CR",
        "balanceIndicator": "2,303,869.11CR",
        "extendedNarrator": "IMPS-API/507114484420/SURESH BAIRWA/SBIN0032073/8953/Utility",
        "uuidNumber": "507114484420"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,304,369.11CR",
        "balanceIndicator": "2,304,369.11CR",
        "extendedNarrator": "IMPS-API/507114484340/DAMA MUNDA/BKID0005480/3358/Utility",
        "uuidNumber": "507114484340"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,600.00DR",
        "amountIndicator": "DR",
        "balance": "2,308,369.11CR",
        "balanceIndicator": "2,308,369.11CR",
        "extendedNarrator": "IMPS-API/507114484284/Miss CHANCHAL BAI  SAINI/SBIN0009209/6025/Utility",
        "uuidNumber": "507114484284"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:22",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,600.00DR",
        "amountIndicator": "DR",
        "balance": "2,321,969.11CR",
        "balanceIndicator": "2,321,969.11CR",
        "extendedNarrator": "IMPS-API/507114484082/ABHAY JHA/KKBK0005033/5235/Utility",
        "uuidNumber": "507114484082"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:17:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,324,569.11CR",
        "balanceIndicator": "2,324,569.11CR",
        "extendedNarrator": "IMPS-API/507114483215/APARNA KARMAKAR/SBIN0009401/7406/Utility",
        "uuidNumber": "507114483215"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "2,329,319.11CR",
        "balanceIndicator": "2,329,319.11CR",
        "extendedNarrator": "IMPS-API/507114482870/SANJAY SO HANUMAN SINGH/BARB0BUPGBX/7782/Utility",
        "uuidNumber": "507114482870"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,330,320.11CR",
        "balanceIndicator": "2,330,320.11CR",
        "extendedNarrator": "IMPS-API/507114482685/SAJJAD ALI/PUNB0063200/5841/Utility",
        "uuidNumber": "507114482685"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:46",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,335,320.11CR",
        "balanceIndicator": "2,335,320.11CR",
        "extendedNarrator": "IMPS-API/507114482522/Faizan  Khan/FINO0009003/4686/Utility",
        "uuidNumber": "507114482522"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,336,820.11CR",
        "balanceIndicator": "2,336,820.11CR",
        "extendedNarrator": "IMPS-API/507114482402/Mrs LILAVATI  DEVI/SBIN0009209/9453/Utility",
        "uuidNumber": "507114482402"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,342,820.11CR",
        "balanceIndicator": "2,342,820.11CR",
        "extendedNarrator": "IMPS-API/507114482344/sharma ji patel/AIRP0000001/4462/Utility",
        "uuidNumber": "507114482344"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,342,920.11CR",
        "balanceIndicator": "2,342,920.11CR",
        "extendedNarrator": "IMPS-API/507114482131/Sujit Muchi/PUNB0077520/6572/Utility",
        "uuidNumber": "507114482131"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,343,205.11CR",
        "balanceIndicator": "2,343,205.11CR",
        "extendedNarrator": "IMPS-API/507114481263/MALAPOLU NEELIMA/CBIN0284734/2943/Utility",
        "uuidNumber": "507114481263"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,343,490.11CR",
        "balanceIndicator": "2,343,490.11CR",
        "extendedNarrator": "IMPS-API/507114481123/Mr PRAFUL  KUMAR/SBIN0000001/5478/Utility",
        "uuidNumber": "507114481123"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,345,990.11CR",
        "balanceIndicator": "2,345,990.11CR",
        "extendedNarrator": "IMPS-API/507114481034/Mustakim/AIRP0000001/4109/Utility",
        "uuidNumber": "507114481034"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:16:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,348,840.11CR",
        "balanceIndicator": "2,348,840.11CR",
        "extendedNarrator": "IMPS-API/507114481022/NIKHIL ASHOK RANA/MSCI0082042/0094/Utility",
        "uuidNumber": "507114481022"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:15:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,349,125.11CR",
        "balanceIndicator": "2,349,125.11CR",
        "extendedNarrator": "IMPS-API/507114479729/Wrishav rabha/SBIN0003030/4858/Utility",
        "uuidNumber": "507114479729"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:15:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,349,410.11CR",
        "balanceIndicator": "2,349,410.11CR",
        "extendedNarrator": "IMPS-API/507114479554/Amin Shaikh/SBIN0008858/9635/Utility",
        "uuidNumber": "507114479554"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:15:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,350,360.11CR",
        "balanceIndicator": "2,350,360.11CR",
        "extendedNarrator": "IMPS-API/507114478626/LALCHAND KAMAT/BARB0NEHRUP/0272/Utility",
        "uuidNumber": "507114478626"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:15:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,353,860.11CR",
        "balanceIndicator": "2,353,860.11CR",
        "extendedNarrator": "IMPS-API/507114478579/PIYUSH/CBIN0280658/9954/Utility",
        "uuidNumber": "507114478579"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:15:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,354,860.11CR",
        "balanceIndicator": "2,354,860.11CR",
        "extendedNarrator": "IMPS-API/507114478418/MUSHABHAI HARUNBHAI SUMBHANIYA/SGBA0000160/9111/Utility",
        "uuidNumber": "507114478418"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:15:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,355,145.11CR",
        "balanceIndicator": "2,355,145.11CR",
        "extendedNarrator": "IMPS-API/507114478185/Bijoychandra/AIRP0000001/7765/Utility",
        "uuidNumber": "507114478185"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:15:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,355,620.11CR",
        "balanceIndicator": "2,355,620.11CR",
        "extendedNarrator": "IMPS-API/507114478341/jyoti sharma/SBIN0016786/8911/Utility",
        "uuidNumber": "507114478341"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,356,392.11CR",
        "balanceIndicator": "2,356,392.11CR",
        "extendedNarrator": "IMPS-API/507114477279/Ashish humane/IBKL0001047/7159/Utility",
        "uuidNumber": "507114477279"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "772.00DR",
        "amountIndicator": "DR",
        "balance": "2,356,492.11CR",
        "balanceIndicator": "2,356,492.11CR",
        "extendedNarrator": "IMPS-API/507114477278/Nitish Yadav/UTIB0000022/3458/Utility",
        "uuidNumber": "507114477278"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,357,264.11CR",
        "balanceIndicator": "2,357,264.11CR",
        "extendedNarrator": "IMPS-API/507114477213/VINOD SING/UTIB0000150/9718/Utility",
        "uuidNumber": "507114477213"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,357,549.11CR",
        "balanceIndicator": "2,357,549.11CR",
        "extendedNarrator": "IMPS-API/507114477078/iftikharuddeen/SBIN0011946/7726/Utility",
        "uuidNumber": "507114477078"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:34",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "8,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,357,649.11CR",
        "balanceIndicator": "2,357,649.11CR",
        "extendedNarrator": "IMPS-API/507114476921/Mr HUKAM CHAND  SAINI/SBIN0009209/3505/Utility",
        "uuidNumber": "507114476921"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,366,149.11CR",
        "balanceIndicator": "2,366,149.11CR",
        "extendedNarrator": "IMPS-API/507114476301/Achal  Kumar/KKBK0000958/9898/Utility",
        "uuidNumber": "507114476301"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,369,149.11CR",
        "balanceIndicator": "2,369,149.11CR",
        "extendedNarrator": "IMPS-API/507114476265/RAJESH KUMAR SHARMA/IBKL0000057/3170/Utility",
        "uuidNumber": "507114476265"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,800.00DR",
        "amountIndicator": "DR",
        "balance": "2,371,649.11CR",
        "balanceIndicator": "2,371,649.11CR",
        "extendedNarrator": "IMPS-API/507114476087/RAMAVTAR RAMAVTAR/UJVN0002306/3009/Utility",
        "uuidNumber": "507114476087"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,373,449.11CR",
        "balanceIndicator": "2,373,449.11CR",
        "extendedNarrator": "IMPS-API/507114475868/SHATRUGHAN SHATRUGHAN/UBIN0571334/7287/Utility",
        "uuidNumber": "507114475868"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:14:02",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "15,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,375,449.11CR",
        "balanceIndicator": "2,375,449.11CR",
        "extendedNarrator": "IMPS-API/507114475651/SAHIN PARVEEN/IDIB000S028/9078/Utility",
        "uuidNumber": "507114475651"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:13:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,390,449.11CR",
        "balanceIndicator": "2,390,449.11CR",
        "extendedNarrator": "IMPS-API/507114475048/Khatoona WO Ramzan/IDIB000L003/9114/Utility",
        "uuidNumber": "507114475048"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:13:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,393,449.11CR",
        "balanceIndicator": "2,393,449.11CR",
        "extendedNarrator": "IMPS-API/507114474698/SUSHANTA SEN/AIRP0000001/2088/Utility",
        "uuidNumber": "507114474698"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:13:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,413,449.11CR",
        "balanceIndicator": "2,413,449.11CR",
        "extendedNarrator": "IMPS-API/507114474489/ALOK KUMAR/PUNB0224400/9306/Utility",
        "uuidNumber": "507114474489"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:13:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "11,900.00DR",
        "amountIndicator": "DR",
        "balance": "2,414,949.11CR",
        "balanceIndicator": "2,414,949.11CR",
        "extendedNarrator": "IMPS-API/507114474357/Mr MAHENDRA KUMAR  SAINI/SBIN0009209/7562/Utility",
        "uuidNumber": "507114474357"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:13:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,426,849.11CR",
        "balanceIndicator": "2,426,849.11CR",
        "extendedNarrator": "IMPS-API/507114474338/ASHUTOSH NANAVARE/KKBK0000647/6024/Utility",
        "uuidNumber": "507114474338"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:13:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "800.00DR",
        "amountIndicator": "DR",
        "balance": "2,427,349.11CR",
        "balanceIndicator": "2,427,349.11CR",
        "extendedNarrator": "IMPS-API/507114474036/SATHISH REDDY/KKBK0000560/4783/Utility",
        "uuidNumber": "507114474036"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:13:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,428,149.11CR",
        "balanceIndicator": "2,428,149.11CR",
        "extendedNarrator": "IMPS-API/507114473721/Jitesh R kinri/BARB0RAKHOL/7224/Utility",
        "uuidNumber": "507114473721"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,499.00DR",
        "amountIndicator": "DR",
        "balance": "2,428,249.11CR",
        "balanceIndicator": "2,428,249.11CR",
        "extendedNarrator": "IMPS-API/507114473183/BINITA DEVI/PUNB0063200/1080/Utility",
        "uuidNumber": "507114473183"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,429,748.11CR",
        "balanceIndicator": "2,429,748.11CR",
        "extendedNarrator": "IMPS-API/507114472702/DEEPAK ROY/FDRL0001006/3268/Utility",
        "uuidNumber": "507114472702"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,431,248.11CR",
        "balanceIndicator": "2,431,248.11CR",
        "extendedNarrator": "IMPS-API/507114472453/AMIT YADAV/BDBL0001118/3230/Utility",
        "uuidNumber": "507114472453"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,441,248.11CR",
        "balanceIndicator": "2,441,248.11CR",
        "extendedNarrator": "IMPS-API/507114472449/Ravibhai shravanbhai shinajiya/SBIN0060306/9495/Utility",
        "uuidNumber": "507114472449"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,441,533.11CR",
        "balanceIndicator": "2,441,533.11CR",
        "extendedNarrator": "IMPS-API/507114471741/Firasat/BARB0FORTUN/2009/Utility",
        "uuidNumber": "507114471741"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "17,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,451,533.11CR",
        "balanceIndicator": "2,451,533.11CR",
        "extendedNarrator": "IMPS-API/507114471595/Mr SURESH  SAINI/SBIN0009209/0931/Utility",
        "uuidNumber": "507114471595"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,469,383.11CR",
        "balanceIndicator": "2,469,383.11CR",
        "extendedNarrator": "IMPS-API/507114471448/KUNAL MONDAL/SBIN0005920/8196/Utility",
        "uuidNumber": "507114471448"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "18,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,470,383.11CR",
        "balanceIndicator": "2,470,383.11CR",
        "extendedNarrator": "IMPS-API/507114471288/ANKIT ANKIT/AIRP0000001/7438/Utility",
        "uuidNumber": "507114471288"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,488,383.11CR",
        "balanceIndicator": "2,488,383.11CR",
        "extendedNarrator": "IMPS-API/507114471218/surgyan singh meena/BARB0KOTRIX/4570/Utility",
        "uuidNumber": "507114471218"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,489,333.11CR",
        "balanceIndicator": "2,489,333.11CR",
        "extendedNarrator": "IMPS-API/507114471035/shilpa salve/KKBK0PNSB01/1876/Utility",
        "uuidNumber": "507114471035"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:12:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,489,833.11CR",
        "balanceIndicator": "2,489,833.11CR",
        "extendedNarrator": "IMPS-API/507114471024/SADAN takri/SBIN0000101/3475/Utility",
        "uuidNumber": "507114471024"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,490,118.11CR",
        "balanceIndicator": "2,490,118.11CR",
        "extendedNarrator": "IMPS-API/507114469988/warish/AIRP0000001/2786/Utility",
        "uuidNumber": "507114469988"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,490,218.11CR",
        "balanceIndicator": "2,490,218.11CR",
        "extendedNarrator": "IMPS-API/507114469971/RAKESH MAHTO/PUNB0063200/4857/Utility",
        "uuidNumber": "507114469971"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,495,218.11CR",
        "balanceIndicator": "2,495,218.11CR",
        "extendedNarrator": "IMPS-API/507114470121/VIVEK KUMAR MEENA/SBIN0004628/0115/Utility",
        "uuidNumber": "507114470121"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,496,168.11CR",
        "balanceIndicator": "2,496,168.11CR",
        "extendedNarrator": "IMPS-API/507114469941/suman/SBIN0002691/6343/Utility",
        "uuidNumber": "507114469941"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,669.00DR",
        "amountIndicator": "DR",
        "balance": "2,497,168.11CR",
        "balanceIndicator": "2,497,168.11CR",
        "extendedNarrator": "IMPS-API/507114469618/BOHRA PIYUSH/FDRL0007778/2442/Utility",
        "uuidNumber": "507114469618"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,510,837.11CR",
        "balanceIndicator": "2,510,837.11CR",
        "extendedNarrator": "IMPS-API/507114469379/Mrs Niramala Devi/SBIN0000001/3902/Utility",
        "uuidNumber": "507114469379"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:22",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,512,837.11CR",
        "balanceIndicator": "2,512,837.11CR",
        "extendedNarrator": "IMPS-API/507114469271/PENTYALA KRISHNA/SBIN0002704/8585/Utility",
        "uuidNumber": "507114469271"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,515,837.11CR",
        "balanceIndicator": "2,515,837.11CR",
        "extendedNarrator": "IMPS-API/507114468948/ASHUTOSH KUMAR/JIOP0000001/4305/Utility",
        "uuidNumber": "507114468948"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,516,337.11CR",
        "balanceIndicator": "2,516,337.11CR",
        "extendedNarrator": "IMPS-API/507114468730/MANISH BANSAL/ICIC0001704/3853/Utility",
        "uuidNumber": "507114468730"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "120.00DR",
        "amountIndicator": "DR",
        "balance": "2,526,337.11CR",
        "balanceIndicator": "2,526,337.11CR",
        "extendedNarrator": "IMPS-API/507114468294/mahipal Bhabhor/SBIN0031910/0648/Utility",
        "uuidNumber": "507114468294"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,526,457.11CR",
        "balanceIndicator": "2,526,457.11CR",
        "extendedNarrator": "IMPS-API/507114468494/ragi sudheer/SBIN0020973/1170/Utility",
        "uuidNumber": "507114468494"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:11:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,527,457.11CR",
        "balanceIndicator": "2,527,457.11CR",
        "extendedNarrator": "IMPS-API/507114468467/rajkumar pramanik/SBIN0016942/4071/Utility",
        "uuidNumber": "507114468467"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,600.00DR",
        "amountIndicator": "DR",
        "balance": "2,528,457.11CR",
        "balanceIndicator": "2,528,457.11CR",
        "extendedNarrator": "IMPS-API/507114467476/LAXMI SAINI/PUNB0063200/2624/Utility",
        "uuidNumber": "507114467476"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,700.00DR",
        "amountIndicator": "DR",
        "balance": "2,542,057.11CR",
        "balanceIndicator": "2,542,057.11CR",
        "extendedNarrator": "IMPS-API/507114466942/MANSI MEENA/HDFC0009049/6248/Utility",
        "uuidNumber": "507114466942"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,543,757.11CR",
        "balanceIndicator": "2,543,757.11CR",
        "extendedNarrator": "IMPS-API/507114466819/Pardeep/BARB0DBFBAD/1737/Utility",
        "uuidNumber": "507114466819"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,544,042.11CR",
        "balanceIndicator": "2,544,042.11CR",
        "extendedNarrator": "IMPS-API/507114466811/Tarun/UBIN0574821/5497/Utility",
        "uuidNumber": "507114466811"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,544,327.11CR",
        "balanceIndicator": "2,544,327.11CR",
        "extendedNarrator": "IMPS-API/507114466757/Ghnsyam rathod/IPOS0000001/6850/Utility",
        "uuidNumber": "507114466757"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,544,427.11CR",
        "balanceIndicator": "2,544,427.11CR",
        "extendedNarrator": "IMPS-API/507114466067/REENA/CNRB0004195/1613/Utility",
        "uuidNumber": "507114466067"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,546,427.11CR",
        "balanceIndicator": "2,546,427.11CR",
        "extendedNarrator": "IMPS-API/507114465315/MRS MITHALESH KUMARI/SBIN0000001/8147/Utility",
        "uuidNumber": "507114465315"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,546,927.11CR",
        "balanceIndicator": "2,546,927.11CR",
        "extendedNarrator": "IMPS-API/507114465278/AMIT SURYAWANSHI/SBIN0002828/3135/Utility",
        "uuidNumber": "507114465278"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,547,027.11CR",
        "balanceIndicator": "2,547,027.11CR",
        "extendedNarrator": "IMPS-API/507114465050/CHAVDA PARESH/SBIN0010951/7506/Utility",
        "uuidNumber": "507114465050"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,548,027.11CR",
        "balanceIndicator": "2,548,027.11CR",
        "extendedNarrator": "IMPS-API/507114465048/Tripati santa/IPOS0000001/7001/Utility",
        "uuidNumber": "507114465048"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,548,312.11CR",
        "balanceIndicator": "2,548,312.11CR",
        "extendedNarrator": "IMPS-API/507114465018/DHARMENDRA SINGH/UTIB0000450/4650/Utility",
        "uuidNumber": "507114465018"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,549,812.11CR",
        "balanceIndicator": "2,549,812.11CR",
        "extendedNarrator": "IMPS-API/507114465199/Nadimkhan lalkhan ajlani/HDFC0003114/8891/Utility",
        "uuidNumber": "507114465199"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:10:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,550,287.11CR",
        "balanceIndicator": "2,550,287.11CR",
        "extendedNarrator": "IMPS-API/507114465195/sonudevi/BARB0ANUPGA/0994/Utility",
        "uuidNumber": "507114465195"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:09:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,551,287.11CR",
        "balanceIndicator": "2,551,287.11CR",
        "extendedNarrator": "IMPS-API/507114464896/SHUBHAM SHUBHAM/PUNB0277800/0757/Utility",
        "uuidNumber": "507114464896"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:09:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,555,287.11CR",
        "balanceIndicator": "2,555,287.11CR",
        "extendedNarrator": "IMPS-API/507114463919/Sofiqul Islam/AIRP0000001/8177/Utility",
        "uuidNumber": "507114463919"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:09:23",
        "description": "CREDIT VIA INTERNAL",
        "amount": "4,300.00CR",
        "amountIndicator": "CR",
        "balance": "2,555,787.11CR",
        "balanceIndicator": "2,555,787.11CR",
        "extendedNarrator": "IMPS-API/REV/507114463334/PRASANTH KAKULA/IDIB0005142/3113",
        "uuidNumber": "507114463334"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:09:22",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,300.00DR",
        "amountIndicator": "DR",
        "balance": "2,551,487.11CR",
        "balanceIndicator": "2,551,487.11CR",
        "extendedNarrator": "IMPS-API/507114463334/PRASANTH KAKULA/IDIB0005142/3113/Utility",
        "uuidNumber": "507114463334"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:09:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "14,025.00DR",
        "amountIndicator": "DR",
        "balance": "2,555,787.11CR",
        "balanceIndicator": "2,555,787.11CR",
        "extendedNarrator": "IMPS-API/507114463272/HEMANT SAINI/UBIN0551775/1464/Utility",
        "uuidNumber": "507114463272"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:09:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,569,812.11CR",
        "balanceIndicator": "2,569,812.11CR",
        "extendedNarrator": "IMPS-API/507114463063/AAKRISHT SHARMA/UTIB0002129/9189/Utility",
        "uuidNumber": "507114463063"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:56",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,571,812.11CR",
        "balanceIndicator": "2,571,812.11CR",
        "extendedNarrator": "IMPS-API/507114462157/MR KAMAL GHOSH/SBIN0000001/8152/Utility",
        "uuidNumber": "507114462157"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,300.00DR",
        "amountIndicator": "DR",
        "balance": "2,583,812.11CR",
        "balanceIndicator": "2,583,812.11CR",
        "extendedNarrator": "IMPS-API/507114461881/santosh/PUNB0063200/3931/Utility",
        "uuidNumber": "507114461881"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,587,112.11CR",
        "balanceIndicator": "2,587,112.11CR",
        "extendedNarrator": "IMPS-API/507114461282/DINESH DINESH/PUNB0219600/8279/Utility",
        "uuidNumber": "507114461282"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,587,612.11CR",
        "balanceIndicator": "2,587,612.11CR",
        "extendedNarrator": "IMPS-API/507114461356/Akib afzal shaikh/BARB0KOSAMB/1625/Utility",
        "uuidNumber": "507114461356"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:30",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,588,562.11CR",
        "balanceIndicator": "2,588,562.11CR",
        "extendedNarrator": "IMPS-API/507114460957/JAAN MOHAMMAD/NSPB0000002/6377/Utility",
        "uuidNumber": "507114460957"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,590,562.11CR",
        "balanceIndicator": "2,590,562.11CR",
        "extendedNarrator": "IMPS-API/507114459886/Patel mitesh Kumar/UBIN0544914/0510/Utility",
        "uuidNumber": "507114459886"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,595,412.11CR",
        "balanceIndicator": "2,595,412.11CR",
        "extendedNarrator": "IMPS-API/507114459730/Divity Srinivas/SBIN0020086/8314/Utility",
        "uuidNumber": "507114459730"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,595,887.11CR",
        "balanceIndicator": "2,595,887.11CR",
        "extendedNarrator": "IMPS-API/507114459728/Nitin Kale/MAHB0000612/7434/Utility",
        "uuidNumber": "507114459728"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,596,362.11CR",
        "balanceIndicator": "2,596,362.11CR",
        "extendedNarrator": "IMPS-API/507114459813/Roshan/BARB0RVDESA/4270/Utility",
        "uuidNumber": "507114459813"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:08:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "105.00DR",
        "amountIndicator": "DR",
        "balance": "2,597,862.11CR",
        "balanceIndicator": "2,597,862.11CR",
        "extendedNarrator": "IMPS-API/507114459811/Chandan Gond/FINO0000001/3162/Utility",
        "uuidNumber": "507114459811"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,300.00DR",
        "amountIndicator": "DR",
        "balance": "2,597,967.11CR",
        "balanceIndicator": "2,597,967.11CR",
        "extendedNarrator": "IMPS-API/507114459282/GUNJAN NAGPAL/DCBL0000026/5668/Utility",
        "uuidNumber": "507114459282"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,600,267.11CR",
        "balanceIndicator": "2,600,267.11CR",
        "extendedNarrator": "IMPS-API/507114459214/MANDA KOMAL/KKBK0007676/3864/Utility",
        "uuidNumber": "507114459214"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "14,450.00DR",
        "amountIndicator": "DR",
        "balance": "2,600,767.11CR",
        "balanceIndicator": "2,600,767.11CR",
        "extendedNarrator": "IMPS-API/507114458921/SURENDRA KUMAR SAINI/UTIB0000589/3959/Utility",
        "uuidNumber": "507114458921"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,615,217.11CR",
        "balanceIndicator": "2,615,217.11CR",
        "extendedNarrator": "IMPS-API/507114458882/VENNILA CHANDRAN/BKID0008008/9308/Utility",
        "uuidNumber": "507114458882"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,679.45DR",
        "amountIndicator": "DR",
        "balance": "2,615,717.11CR",
        "balanceIndicator": "2,615,717.11CR",
        "extendedNarrator": "IMPS-API/507114458597/Gadde Laya/HDFC0001637/6710/Utility",
        "uuidNumber": "507114458597"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,617,396.56CR",
        "balanceIndicator": "2,617,396.56CR",
        "extendedNarrator": "IMPS-API/507114458466/Imlikumla/IPOS0000001/0878/Utility",
        "uuidNumber": "507114458466"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,622,146.56CR",
        "balanceIndicator": "2,622,146.56CR",
        "extendedNarrator": "IMPS-API/507114458594/Felix/CNRB0000835/9824/Utility",
        "uuidNumber": "507114458594"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,300.00DR",
        "amountIndicator": "DR",
        "balance": "2,622,246.56CR",
        "balanceIndicator": "2,622,246.56CR",
        "extendedNarrator": "IMPS-API/507114458570/Mr ESARUL  ESARUL/SBIN0009209/7372/Utility",
        "uuidNumber": "507114458570"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,625,546.56CR",
        "balanceIndicator": "2,625,546.56CR",
        "extendedNarrator": "IMPS-API/507114457859/AMLESH KUMARI/CBIN0R10001/6818/Utility",
        "uuidNumber": "507114457859"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,628,546.56CR",
        "balanceIndicator": "2,628,546.56CR",
        "extendedNarrator": "IMPS-API/507114457609/SURAJ LAL/SBIN0RRUTGB/2311/Utility",
        "uuidNumber": "507114457609"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,648,546.56CR",
        "balanceIndicator": "2,648,546.56CR",
        "extendedNarrator": "IMPS-API/507114457463/TANNERU BHAVANI SHANKER/KKBK0007535/5743/Utility",
        "uuidNumber": "507114457463"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,649,046.56CR",
        "balanceIndicator": "2,649,046.56CR",
        "extendedNarrator": "IMPS-API/507114457240/AAVULA SAI/SBIN0015780/4485/Utility",
        "uuidNumber": "507114457240"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,100.00DR",
        "amountIndicator": "DR",
        "balance": "2,649,546.56CR",
        "balanceIndicator": "2,649,546.56CR",
        "extendedNarrator": "IMPS-API/507114457139/kaluram thakur/SBIN0031809/4494/Utility",
        "uuidNumber": "507114457139"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:07:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,650,646.56CR",
        "balanceIndicator": "2,650,646.56CR",
        "extendedNarrator": "IMPS-API/507114456921/RANA OWAIS/SBIN0000573/7052/Utility",
        "uuidNumber": "507114456921"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "16,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,652,146.56CR",
        "balanceIndicator": "2,652,146.56CR",
        "extendedNarrator": "IMPS-API/507114456415/deepak/SBIN0009209/6701/Utility",
        "uuidNumber": "507114456415"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:32",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,668,646.56CR",
        "balanceIndicator": "2,668,646.56CR",
        "extendedNarrator": "IMPS-API/507114455586/Chinta Devi/BARB0TILDEL/6603/Utility",
        "uuidNumber": "507114455586"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,678,646.56CR",
        "balanceIndicator": "2,678,646.56CR",
        "extendedNarrator": "IMPS-API/507114455293/CHANDRABHAN SINGH/IBKL0001368/7105/Utility",
        "uuidNumber": "507114455293"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,679,646.56CR",
        "balanceIndicator": "2,679,646.56CR",
        "extendedNarrator": "IMPS-API/507114455406/SARLANA TARUN/SBIN0021254/9668/Utility",
        "uuidNumber": "507114455406"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,680,646.56CR",
        "balanceIndicator": "2,680,646.56CR",
        "extendedNarrator": "IMPS-API/507114455344/YASHWANTH K B/SBIN0008039/1295/Utility",
        "uuidNumber": "507114455344"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,681,146.56CR",
        "balanceIndicator": "2,681,146.56CR",
        "extendedNarrator": "IMPS-API/507114455039/VENKATESAN VENKAT/CNRB0001492/6556/Utility",
        "uuidNumber": "507114455039"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,681,646.56CR",
        "balanceIndicator": "2,681,646.56CR",
        "extendedNarrator": "IMPS-API/507114455077/PUSHPENDRA R/BARB0BARDHA/2291/Utility",
        "uuidNumber": "507114455077"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,690,646.56CR",
        "balanceIndicator": "2,690,646.56CR",
        "extendedNarrator": "IMPS-API/507114454733/SONIYA SHARMA/PUNB0084000/8546/Utility",
        "uuidNumber": "507114454733"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,691,646.56CR",
        "balanceIndicator": "2,691,646.56CR",
        "extendedNarrator": "IMPS-API/507114454569/N ANUP CHOWDARY/SCBL0036025/3004/Utility",
        "uuidNumber": "507114454569"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:07",
        "description": "CREDIT VIA INTERNAL",
        "amount": "950.00CR",
        "amountIndicator": "CR",
        "balance": "2,693,646.56CR",
        "balanceIndicator": "2,693,646.56CR",
        "extendedNarrator": "IMPS-API/REV/507114454507/Axis Bank/UTIB0000757/1122",
        "uuidNumber": "507114454507"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,692,696.56CR",
        "balanceIndicator": "2,692,696.56CR",
        "extendedNarrator": "IMPS-API/507114454507/Axis Bank/UTIB0000757/1122/Utility",
        "uuidNumber": "507114454507"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,693,646.56CR",
        "balanceIndicator": "2,693,646.56CR",
        "extendedNarrator": "IMPS-API/507114454506/Anil Kumar shaw/SBIN0003614/1306/Utility",
        "uuidNumber": "507114454506"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,694,596.56CR",
        "balanceIndicator": "2,694,596.56CR",
        "extendedNarrator": "IMPS-API/507114454384/PARMAR KAUSHIK GOVINDBHAI/CNRB0017091/1743/Utility",
        "uuidNumber": "507114454384"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:06:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,695,596.56CR",
        "balanceIndicator": "2,695,596.56CR",
        "extendedNarrator": "IMPS-API/507114454249/Sachin Subhash salve/PUNB0132410/0604/Utility",
        "uuidNumber": "507114454249"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,697,096.56CR",
        "balanceIndicator": "2,697,096.56CR",
        "extendedNarrator": "IMPS-API/507114453718/GOPI SAINI/UTIB0000589/5451/Utility",
        "uuidNumber": "507114453718"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,709,846.56CR",
        "balanceIndicator": "2,709,846.56CR",
        "extendedNarrator": "IMPS-API/507114452916/DHORIYA DHIRUBHAI/IPOS0000001/1361/Utility",
        "uuidNumber": "507114452916"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,711,846.56CR",
        "balanceIndicator": "2,711,846.56CR",
        "extendedNarrator": "IMPS-API/507114452886/Parvat singh lodha/AIRP0000001/0988/Utility",
        "uuidNumber": "507114452886"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,712,131.56CR",
        "balanceIndicator": "2,712,131.56CR",
        "extendedNarrator": "IMPS-API/507114452883/Sbi/SBIN0000526/7286/Utility",
        "uuidNumber": "507114452883"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,712,606.56CR",
        "balanceIndicator": "2,712,606.56CR",
        "extendedNarrator": "IMPS-API/507114452937/SHAIK SHARIF/UBIN0935387/8639/Utility",
        "uuidNumber": "507114452937"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,716,606.56CR",
        "balanceIndicator": "2,716,606.56CR",
        "extendedNarrator": "IMPS-API/507114452664/VIJAY KUMAR SO RAM BRIKASH/PUNB0063200/2061/Utility",
        "uuidNumber": "507114452664"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,100.00DR",
        "amountIndicator": "DR",
        "balance": "2,726,606.56CR",
        "balanceIndicator": "2,726,606.56CR",
        "extendedNarrator": "IMPS-API/507114452418/MOHAMMED SOFIAN/KKBK0007478/2335/Utility",
        "uuidNumber": "507114452418"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,727,706.56CR",
        "balanceIndicator": "2,727,706.56CR",
        "extendedNarrator": "IMPS-API/507114452288/ASRAF ALI MONDAL/SBIN0000001/4912/Utility",
        "uuidNumber": "507114452288"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,731,706.56CR",
        "balanceIndicator": "2,731,706.56CR",
        "extendedNarrator": "IMPS-API/507114451826/SHAKIR ALI/KKBK0000958/6437/Utility",
        "uuidNumber": "507114451826"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,738,706.56CR",
        "balanceIndicator": "2,738,706.56CR",
        "extendedNarrator": "IMPS-API/507114451369/ANIL KUMAR VERMA/SBIN0001428/4846/Utility",
        "uuidNumber": "507114451369"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,743,706.56CR",
        "balanceIndicator": "2,743,706.56CR",
        "extendedNarrator": "IMPS-API/507114451293/MR SUNIL SUNIL/SBIN0000001/1441/Utility",
        "uuidNumber": "507114451293"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:05:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,900.00DR",
        "amountIndicator": "DR",
        "balance": "2,750,706.56CR",
        "balanceIndicator": "2,750,706.56CR",
        "extendedNarrator": "IMPS-API/507114451466/prajapati sangitaben chandrakantbhau/SBIN0003859/5527/Utility",
        "uuidNumber": "507114451466"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,050.00DR",
        "amountIndicator": "DR",
        "balance": "2,752,606.56CR",
        "balanceIndicator": "2,752,606.56CR",
        "extendedNarrator": "IMPS-API/507114450964/ASARAT ALI/SBIN0012380/7603/Utility",
        "uuidNumber": "507114450964"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,753,656.56CR",
        "balanceIndicator": "2,753,656.56CR",
        "extendedNarrator": "IMPS-API/507114450697/JOGANATH  PAL/IDIB000S028/0289/Utility",
        "uuidNumber": "507114450697"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,754,656.56CR",
        "balanceIndicator": "2,754,656.56CR",
        "extendedNarrator": "IMPS-API/507114450445/NISHANT /KKBK0000958/5496/Utility",
        "uuidNumber": "507114450445"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,873.00DR",
        "amountIndicator": "DR",
        "balance": "2,767,406.56CR",
        "balanceIndicator": "2,767,406.56CR",
        "extendedNarrator": "IMPS-API/507114450245/Priyanka Priyadarshini/SBIN0004856/0228/Utility",
        "uuidNumber": "507114450245"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:34",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,769,279.56CR",
        "balanceIndicator": "2,769,279.56CR",
        "extendedNarrator": "IMPS-API/507114450210/Ramesh Singh Bisht/KKBK0000958/6036/Utility",
        "uuidNumber": "507114450210"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,771,279.56CR",
        "balanceIndicator": "2,771,279.56CR",
        "extendedNarrator": "IMPS-API/507114449508/KEVAL DEVI/PUNB0063200/2011/Utility",
        "uuidNumber": "507114449508"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,773,279.56CR",
        "balanceIndicator": "2,773,279.56CR",
        "extendedNarrator": "IMPS-API/507114449454/KOUDE SRIKANTH/SBIN0011992/9082/Utility",
        "uuidNumber": "507114449454"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,773,779.56CR",
        "balanceIndicator": "2,773,779.56CR",
        "extendedNarrator": "IMPS-API/507114449228/RAJU MEENA/HDFC0009115/0082/Utility",
        "uuidNumber": "507114449228"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "300.00DR",
        "amountIndicator": "DR",
        "balance": "2,774,279.56CR",
        "balanceIndicator": "2,774,279.56CR",
        "extendedNarrator": "IMPS-API/507114448979/chote lal/SBIN0002296/8707/Utility",
        "uuidNumber": "507114448979"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,774,579.56CR",
        "balanceIndicator": "2,774,579.56CR",
        "extendedNarrator": "IMPS-API/507114448863/Yudhishthir sahu/PUNB0018810/0504/Utility",
        "uuidNumber": "507114448863"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:04:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,774,679.56CR",
        "balanceIndicator": "2,774,679.56CR",
        "extendedNarrator": "IMPS-API/507114448860/Pradeep kumar lakhwani/ICIC0000235/4222/Utility",
        "uuidNumber": "507114448860"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,774,779.56CR",
        "balanceIndicator": "2,774,779.56CR",
        "extendedNarrator": "IMPS-API/507114447815/ASHISH YADAV/KKBK0000181/9860/Utility",
        "uuidNumber": "507114447815"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "14,025.00DR",
        "amountIndicator": "DR",
        "balance": "2,799,779.56CR",
        "balanceIndicator": "2,799,779.56CR",
        "extendedNarrator": "IMPS-API/507114447813/MOHAN LAL SAINI/HDFC0001443/5345/Utility",
        "uuidNumber": "507114447813"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,813,804.56CR",
        "balanceIndicator": "2,813,804.56CR",
        "extendedNarrator": "IMPS-API/507114447756/Yudhishthir sahu/PUNB0018810/0504/Utility",
        "uuidNumber": "507114447756"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,813,904.56CR",
        "balanceIndicator": "2,813,904.56CR",
        "extendedNarrator": "IMPS-API/507114447754/Pradeep kumar lakhwani/ICIC0000235/4222/Utility",
        "uuidNumber": "507114447754"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:31",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,814,004.56CR",
        "balanceIndicator": "2,814,004.56CR",
        "extendedNarrator": "IMPS-API/507114447558/MAHABUB ALAM/PSIB0000001/6755/Utility",
        "uuidNumber": "507114447558"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "2,819,004.56CR",
        "balanceIndicator": "2,819,004.56CR",
        "extendedNarrator": "IMPS-API/507114447289/Pooja Devi/BARB0TILDEL/4753/Utility",
        "uuidNumber": "507114447289"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,820,005.56CR",
        "balanceIndicator": "2,820,005.56CR",
        "extendedNarrator": "IMPS-API/507114447236/RAJ KUMARI DO CHHATHAI/PUNB0063200/9244/Utility",
        "uuidNumber": "507114447236"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,830,005.56CR",
        "balanceIndicator": "2,830,005.56CR",
        "extendedNarrator": "IMPS-API/507114446584/MS KANCHAN KUMARI/CBIN0281107/6591/Utility",
        "uuidNumber": "507114446584"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,835,005.56CR",
        "balanceIndicator": "2,835,005.56CR",
        "extendedNarrator": "IMPS-API/507114446416/SHIVAM MADDHESHIYA/UBIN0550451/0729/Utility",
        "uuidNumber": "507114446416"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,840,005.56CR",
        "balanceIndicator": "2,840,005.56CR",
        "extendedNarrator": "IMPS-API/507114446388/Sandeep Kumar Chaudhary/SBIN0008318/5775/Utility",
        "uuidNumber": "507114446388"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,843,005.56CR",
        "balanceIndicator": "2,843,005.56CR",
        "extendedNarrator": "IMPS-API/507114446451/Jignesh rajubhai thakor/SBIN0060182/0353/Utility",
        "uuidNumber": "507114446451"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:03:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,700.00DR",
        "amountIndicator": "DR",
        "balance": "2,843,105.56CR",
        "balanceIndicator": "2,843,105.56CR",
        "extendedNarrator": "IMPS-API/507114446346/SWAPNIL KASTURE/HDFC0001115/2038/Utility",
        "uuidNumber": "507114446346"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:02:58",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,845,805.56CR",
        "balanceIndicator": "2,845,805.56CR",
        "extendedNarrator": "IMPS-API/507114446103/YASH MODI/SCBL0036052/8660/Utility",
        "uuidNumber": "507114446103"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:02:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,846,305.56CR",
        "balanceIndicator": "2,846,305.56CR",
        "extendedNarrator": "IMPS-API/507114444843/DILIP SEVARIYA/SBIN0031999/0223/Utility",
        "uuidNumber": "507114444843"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:02:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,846,805.56CR",
        "balanceIndicator": "2,846,805.56CR",
        "extendedNarrator": "IMPS-API/507114444605/MANJULA V/ICIC0003436/1546/Utility",
        "uuidNumber": "507114444605"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:02:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,800.00DR",
        "amountIndicator": "DR",
        "balance": "2,848,805.56CR",
        "balanceIndicator": "2,848,805.56CR",
        "extendedNarrator": "IMPS-API/507114444494/LEELAMMA JOSEPH/CNRB0005636/0118/Utility",
        "uuidNumber": "507114444494"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:02:04",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "22,100.00DR",
        "amountIndicator": "DR",
        "balance": "2,850,605.56CR",
        "balanceIndicator": "2,850,605.56CR",
        "extendedNarrator": "IMPS-API/507114443974/RAGHUVIR SAINI/HDFC0001443/6582/Utility",
        "uuidNumber": "507114443974"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,872,705.56CR",
        "balanceIndicator": "2,872,705.56CR",
        "extendedNarrator": "IMPS-API/507114443616/Mrs HA  SIMA/SBIN0009209/7649/Utility",
        "uuidNumber": "507114443616"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "40,437.00DR",
        "amountIndicator": "DR",
        "balance": "2,874,705.56CR",
        "balanceIndicator": "2,874,705.56CR",
        "extendedNarrator": "IMPS-API/507114443017/MEHANDI HUSAIN/SBIN0006727/5802/Utility",
        "uuidNumber": "507114443017"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,915,142.56CR",
        "balanceIndicator": "2,915,142.56CR",
        "extendedNarrator": "IMPS-API/507114442797/BATAGERI UDAYAKUMAR/SBIN0011278/7611/Utility",
        "uuidNumber": "507114442797"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,880.00DR",
        "amountIndicator": "DR",
        "balance": "2,915,642.56CR",
        "balanceIndicator": "2,915,642.56CR",
        "extendedNarrator": "IMPS-API/507114442764/Mahendra omprakash naval/KKBK0000960/7305/Utility",
        "uuidNumber": "507114442764"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "2,918,522.56CR",
        "balanceIndicator": "2,918,522.56CR",
        "extendedNarrator": "IMPS-API/507114442685/Mahendra omprakash naval/KKBK0000960/7305/Utility",
        "uuidNumber": "507114442685"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,923,322.56CR",
        "balanceIndicator": "2,923,322.56CR",
        "extendedNarrator": "IMPS-API/507114442690/Mohammed Sabir/CNRB0000149/7557/Utility",
        "uuidNumber": "507114442690"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,928,072.56CR",
        "balanceIndicator": "2,928,072.56CR",
        "extendedNarrator": "IMPS-API/507114442682/MR PARTHKUMAR SATISHBHAI PATEL/SDCB0000072/0201/Utility",
        "uuidNumber": "507114442682"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,928,547.56CR",
        "balanceIndicator": "2,928,547.56CR",
        "extendedNarrator": "IMPS-API/507114442604/Mr Sarafat  Ali/CBIN0280658/1055/Utility",
        "uuidNumber": "507114442604"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,930,047.56CR",
        "balanceIndicator": "2,930,047.56CR",
        "extendedNarrator": "IMPS-API/507114441516/SumanMal/AIRP0000001/9488/Utility",
        "uuidNumber": "507114441516"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "30,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,930,147.56CR",
        "balanceIndicator": "2,930,147.56CR",
        "extendedNarrator": "IMPS-API/507114441492/amit dull/CNRB0004195/4094/Utility",
        "uuidNumber": "507114441492"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,960,147.56CR",
        "balanceIndicator": "2,960,147.56CR",
        "extendedNarrator": "IMPS-API/507114441464/SAIRAJ SUNIL GAIKWAD/MAHB0000294/3204/Utility",
        "uuidNumber": "507114441464"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,960,647.56CR",
        "balanceIndicator": "2,960,647.56CR",
        "extendedNarrator": "IMPS-API/507114441240/Nilesh uikey/FINO0001446/5946/Utility",
        "uuidNumber": "507114441240"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "2,960,747.56CR",
        "balanceIndicator": "2,960,747.56CR",
        "extendedNarrator": "IMPS-API/507114441194/Mahendra omprakash naval/KKBK0000960/7305/Utility",
        "uuidNumber": "507114441194"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,965,547.56CR",
        "balanceIndicator": "2,965,547.56CR",
        "extendedNarrator": "IMPS-API/507114441103/Arti/PUNB0MBGB06/6690/Utility",
        "uuidNumber": "507114441103"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "15,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,968,047.56CR",
        "balanceIndicator": "2,968,047.56CR",
        "extendedNarrator": "IMPS-API/507114441047/URMILA DEVI/BKID0000150/5761/Utility",
        "uuidNumber": "507114441047"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:01:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,983,047.56CR",
        "balanceIndicator": "2,983,047.56CR",
        "extendedNarrator": "IMPS-API/507114441030/KANT KUMAR KANHAIYA/ICIC0006259/6025/Utility",
        "uuidNumber": "507114441030"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,100.00DR",
        "amountIndicator": "DR",
        "balance": "2,987,047.56CR",
        "balanceIndicator": "2,987,047.56CR",
        "extendedNarrator": "IMPS-API/507114440881/DHILIP SASIKUMAR/CNRB0004374/5052/Utility",
        "uuidNumber": "507114440881"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:56",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,992,147.56CR",
        "balanceIndicator": "2,992,147.56CR",
        "extendedNarrator": "IMPS-API/507114440772/AJMIRA KUMAR/SBIN0020165/1884/Utility",
        "uuidNumber": "507114440772"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,992,647.56CR",
        "balanceIndicator": "2,992,647.56CR",
        "extendedNarrator": "IMPS-API/507114440642/YOGESH JADHAV/KKBK0001790/1296/Utility",
        "uuidNumber": "507114440642"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,993,147.56CR",
        "balanceIndicator": "2,993,147.56CR",
        "extendedNarrator": "IMPS-API/507114440561/MAMTA DEVI/BKID0000150/5683/Utility",
        "uuidNumber": "507114440561"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,003,147.56CR",
        "balanceIndicator": "3,003,147.56CR",
        "extendedNarrator": "IMPS-API/507114440552/SHINDE DADAJI/SBIN0000472/2883/Utility",
        "uuidNumber": "507114440552"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "3,004,647.56CR",
        "balanceIndicator": "3,004,647.56CR",
        "extendedNarrator": "IMPS-API/507114440283/MOHDAKRAM SO ABBAS ALI/BARB0BUPGBX/0549/Utility",
        "uuidNumber": "507114440283"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:44",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,005,648.56CR",
        "balanceIndicator": "3,005,648.56CR",
        "extendedNarrator": "IMPS-API/507114440138/Mr GHOTAY  SAH/CBIN0280658/9704/Utility",
        "uuidNumber": "507114440138"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "39,999.00DR",
        "amountIndicator": "DR",
        "balance": "3,025,648.56CR",
        "balanceIndicator": "3,025,648.56CR",
        "extendedNarrator": "IMPS-API/507114439779/amit dull/CNRB0004195/4094/Utility",
        "uuidNumber": "507114439779"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:36",
        "description": "CREDIT VIA INTERNAL",
        "amount": "285.00CR",
        "amountIndicator": "CR",
        "balance": "3,065,647.56CR",
        "balanceIndicator": "3,065,647.56CR",
        "extendedNarrator": "IMPS-API/REV/507114439651/LALAJI BHAI ISHVARJI THAKOR/UBIN0538761/3530",
        "uuidNumber": "507114439651"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,065,362.56CR",
        "balanceIndicator": "3,065,362.56CR",
        "extendedNarrator": "IMPS-API/507114439652/Varsha er/CNRB0002772/5899/Utility",
        "uuidNumber": "507114439652"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,066,312.56CR",
        "balanceIndicator": "3,066,312.56CR",
        "extendedNarrator": "IMPS-API/507114439651/LALAJI BHAI ISHVARJI THAKOR/UBIN0538761/3530/Utility",
        "uuidNumber": "507114439651"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,066,597.56CR",
        "balanceIndicator": "3,066,597.56CR",
        "extendedNarrator": "IMPS-API/507114438603/CHETAN SINGH/PUNB0110310/1058/Utility",
        "uuidNumber": "507114438603"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,068,597.56CR",
        "balanceIndicator": "3,068,597.56CR",
        "extendedNarrator": "IMPS-API/507114438343/KRISHNA PANCHAL/HDFC0005261/4640/Utility",
        "uuidNumber": "507114438343"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,069,097.56CR",
        "balanceIndicator": "3,069,097.56CR",
        "extendedNarrator": "IMPS-API/507114438250/Manoj sahu/UBIN0547522/9942/Utility",
        "uuidNumber": "507114438250"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,642.00DR",
        "amountIndicator": "DR",
        "balance": "3,069,572.56CR",
        "balanceIndicator": "3,069,572.56CR",
        "extendedNarrator": "IMPS-API/507114438212/NASREEN SAIBOLE/UBIN0543942/5968/Utility",
        "uuidNumber": "507114438212"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,073,214.56CR",
        "balanceIndicator": "3,073,214.56CR",
        "extendedNarrator": "IMPS-API/507114438127/mukesh Rahangdale/BARB0PRANAG/5055/Utility",
        "uuidNumber": "507114438127"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:04",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,073,314.56CR",
        "balanceIndicator": "3,073,314.56CR",
        "extendedNarrator": "IMPS-API/507114438095/ANKIT KUMAR/UBIN0570605/2988/Utility",
        "uuidNumber": "507114438095"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "40,001.00DR",
        "amountIndicator": "DR",
        "balance": "3,080,314.56CR",
        "balanceIndicator": "3,080,314.56CR",
        "extendedNarrator": "IMPS-API/507114438066/amit dull/CNRB0004195/4094/Utility",
        "uuidNumber": "507114438066"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "14:00:00",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "900.00DR",
        "amountIndicator": "DR",
        "balance": "3,120,315.56CR",
        "balanceIndicator": "3,120,315.56CR",
        "extendedNarrator": "IMPS-API/507113437803/SRIMANTA PATASANI/SBIN0013569/9233/Utility",
        "uuidNumber": "507113437803"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "200.00DR",
        "amountIndicator": "DR",
        "balance": "3,121,215.56CR",
        "balanceIndicator": "3,121,215.56CR",
        "extendedNarrator": "IMPS-API/507113437240/ranjeetha/CNRB0013127/0703/Utility",
        "uuidNumber": "507113437240"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,121,415.56CR",
        "balanceIndicator": "3,121,415.56CR",
        "extendedNarrator": "IMPS-API/507113437238/Gaurav kumar/PUNB0080500/3844/Utility",
        "uuidNumber": "507113437238"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,122,365.56CR",
        "balanceIndicator": "3,122,365.56CR",
        "extendedNarrator": "IMPS-API/507113437216/VINOD naran bahi Bhambhi/HDFC0000903/5211/Utility",
        "uuidNumber": "507113437216"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,122,465.56CR",
        "balanceIndicator": "3,122,465.56CR",
        "extendedNarrator": "IMPS-API/507113436925/Ashish humane/IBKL0001047/7159/Utility",
        "uuidNumber": "507113436925"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:30",
        "description": "CREDIT VIA INTERNAL",
        "amount": "600,000.00CR",
        "amountIndicator": "CR",
        "balance": "3,122,565.56CR",
        "balanceIndicator": "3,122,565.56CR",
        "extendedNarrator": "RTGS/SBINR12025031278254036/BUMPPY MEDIAPRIVATE LIMITED        H 140 SECTOR 63/SBIN0013218",
        "uuidNumber": null
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "40,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,522,565.56CR",
        "balanceIndicator": "2,522,565.56CR",
        "extendedNarrator": "IMPS-API/507113436584/amit dull/CNRB0004195/4094/Utility",
        "uuidNumber": "507113436584"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,920.00DR",
        "amountIndicator": "DR",
        "balance": "2,562,565.56CR",
        "balanceIndicator": "2,562,565.56CR",
        "extendedNarrator": "IMPS-API/507113436553/NEHA BHATNAGAR/BDBL0001118/7930/Utility",
        "uuidNumber": "507113436553"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,946.00DR",
        "amountIndicator": "DR",
        "balance": "2,572,485.56CR",
        "balanceIndicator": "2,572,485.56CR",
        "extendedNarrator": "IMPS-API/507113436502/PRASANNA PERUMAL/SBIN0000758/6399/Utility",
        "uuidNumber": "507113436502"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,321.00DR",
        "amountIndicator": "DR",
        "balance": "2,583,431.56CR",
        "balanceIndicator": "2,583,431.56CR",
        "extendedNarrator": "IMPS-API/507113436217/RAHUL RAHUL/CNRB0002718/9531/Utility",
        "uuidNumber": "507113436217"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,202.00DR",
        "amountIndicator": "DR",
        "balance": "2,584,752.56CR",
        "balanceIndicator": "2,584,752.56CR",
        "extendedNarrator": "IMPS-API/507113436144/ABHISHEK VERMA/KKBK0000197/0721/Utility",
        "uuidNumber": "507113436144"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,083.00DR",
        "amountIndicator": "DR",
        "balance": "2,585,954.56CR",
        "balanceIndicator": "2,585,954.56CR",
        "extendedNarrator": "IMPS-API/507113436292/SAIKUMAR KADIRI GAJULA/ICIC0007386/0853/Utility",
        "uuidNumber": "507113436292"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,587,037.56CR",
        "balanceIndicator": "2,587,037.56CR",
        "extendedNarrator": "IMPS-API/507113436002/KAMAL G RAJ/CNRB0011901/6013/Utility",
        "uuidNumber": "507113436002"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,587,537.56CR",
        "balanceIndicator": "2,587,537.56CR",
        "extendedNarrator": "IMPS-API/507113435938/BHIMA GHOLVE/SBIN0005300/6551/Utility",
        "uuidNumber": "507113435938"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:07",
        "description": "CREDIT VIA INTERNAL",
        "amount": "700.00CR",
        "amountIndicator": "CR",
        "balance": "2,589,537.56CR",
        "balanceIndicator": "2,589,537.56CR",
        "extendedNarrator": "IMPS-API/REV/507113435662/PRASANTH KAKULA/IDIB0005142/3113",
        "uuidNumber": "507113435662"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "700.00DR",
        "amountIndicator": "DR",
        "balance": "2,588,837.56CR",
        "balanceIndicator": "2,588,837.56CR",
        "extendedNarrator": "IMPS-API/507113435662/PRASANTH KAKULA/IDIB0005142/3113/Utility",
        "uuidNumber": "507113435662"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,589,537.56CR",
        "balanceIndicator": "2,589,537.56CR",
        "extendedNarrator": "IMPS-API/507113435633/Shibnath kumar/SBIN0012396/1287/Utility",
        "uuidNumber": "507113435633"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,590,012.56CR",
        "balanceIndicator": "2,590,012.56CR",
        "extendedNarrator": "IMPS-API/507113435632/Pradip/SBIN0011003/2673/Utility",
        "uuidNumber": "507113435632"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "480.00DR",
        "amountIndicator": "DR",
        "balance": "2,590,112.56CR",
        "balanceIndicator": "2,590,112.56CR",
        "extendedNarrator": "IMPS-API/507113435881/Naorem Shanti/UTIB0003071/6468/Utility",
        "uuidNumber": "507113435881"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:59:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,590,592.56CR",
        "balanceIndicator": "2,590,592.56CR",
        "extendedNarrator": "IMPS-API/507113435863/DAKSH MEHROTRA/KKBK0000181/7349/Utility",
        "uuidNumber": "507113435863"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,591,092.56CR",
        "balanceIndicator": "2,591,092.56CR",
        "extendedNarrator": "IMPS-API/507113435119/UTPAL GARU/PUNB0244200/6769/Utility",
        "uuidNumber": "507113435119"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "375.00DR",
        "amountIndicator": "DR",
        "balance": "2,593,092.56CR",
        "balanceIndicator": "2,593,092.56CR",
        "extendedNarrator": "IMPS-API/507113434729/Hadiya qaamr/SBIN0010251/5212/Utility",
        "uuidNumber": "507113434729"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,593,467.56CR",
        "balanceIndicator": "2,593,467.56CR",
        "extendedNarrator": "IMPS-API/507113434725/Dipanjan Das/SBIN0006828/1100/Utility",
        "uuidNumber": "507113434725"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "600.00DR",
        "amountIndicator": "DR",
        "balance": "2,594,417.56CR",
        "balanceIndicator": "2,594,417.56CR",
        "extendedNarrator": "IMPS-API/507113434345/PRAMOD DIVAKAR/PUNB0126700/7879/Utility",
        "uuidNumber": "507113434345"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:22",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "501.00DR",
        "amountIndicator": "DR",
        "balance": "2,595,017.56CR",
        "balanceIndicator": "2,595,017.56CR",
        "extendedNarrator": "IMPS-API/507113434290/VINOTH RAJENDRAN/SBIN0012931/2198/Utility",
        "uuidNumber": "507113434290"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,595,518.56CR",
        "balanceIndicator": "2,595,518.56CR",
        "extendedNarrator": "IMPS-API/507113434173/SATHISH REDDY/KARB0000957/4901/Utility",
        "uuidNumber": "507113434173"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "15,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,596,468.56CR",
        "balanceIndicator": "2,596,468.56CR",
        "extendedNarrator": "IMPS-API/507113434024/Mrs MEENA  BISHT/SBIN0009209/1083/Utility",
        "uuidNumber": "507113434024"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,611,468.56CR",
        "balanceIndicator": "2,611,468.56CR",
        "extendedNarrator": "IMPS-API/507113433907/DNYANESHWAR PANDHARE/IPOS0000001/5736/Utility",
        "uuidNumber": "507113433907"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,612,468.56CR",
        "balanceIndicator": "2,612,468.56CR",
        "extendedNarrator": "IMPS-API/507113433999/Mr KEERAN  DEVI/SBIN0009209/1783/Utility",
        "uuidNumber": "507113433999"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:13",
        "description": "CREDIT VIA INTERNAL",
        "amount": "500.00CR",
        "amountIndicator": "CR",
        "balance": "2,622,468.56CR",
        "balanceIndicator": "2,622,468.56CR",
        "extendedNarrator": "IMPS-API/REV/507113433780/PRITESH VASAVA/AUBL0002111/0108",
        "uuidNumber": "507113433780"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,621,968.56CR",
        "balanceIndicator": "2,621,968.56CR",
        "extendedNarrator": "IMPS-API/507113433780/PRITESH VASAVA/AUBL0002111/0108/Utility",
        "uuidNumber": "507113433780"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:06",
        "description": "CREDIT VIA INTERNAL",
        "amount": "100.00CR",
        "amountIndicator": "CR",
        "balance": "2,622,468.56CR",
        "balanceIndicator": "2,622,468.56CR",
        "extendedNarrator": "IMPS-API/REV/507113433678/Somnaath sardar/PUNB0130520/6294",
        "uuidNumber": "507113433678"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,622,368.56CR",
        "balanceIndicator": "2,622,368.56CR",
        "extendedNarrator": "IMPS-API/507113433678/Somnaath sardar/PUNB0130520/6294/Utility",
        "uuidNumber": "507113433678"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "375.00DR",
        "amountIndicator": "DR",
        "balance": "2,622,468.56CR",
        "balanceIndicator": "2,622,468.56CR",
        "extendedNarrator": "IMPS-API/507113433677/Hadiya qaamr/SBIN0010251/5212/Utility",
        "uuidNumber": "507113433677"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:58:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,622,843.56CR",
        "balanceIndicator": "2,622,843.56CR",
        "extendedNarrator": "IMPS-API/507113433519/Sbi/SBIN0060323/1783/Utility",
        "uuidNumber": "507113433519"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:56",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,622,943.56CR",
        "balanceIndicator": "2,622,943.56CR",
        "extendedNarrator": "IMPS-API/507113433301/irfan/CBIN0280658/5478/Utility",
        "uuidNumber": "507113433301"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:55",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,623,943.56CR",
        "balanceIndicator": "2,623,943.56CR",
        "extendedNarrator": "IMPS-API/507113433269/Madhab/KKBK0000958/8349/Utility",
        "uuidNumber": "507113433269"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,626,943.56CR",
        "balanceIndicator": "2,626,943.56CR",
        "extendedNarrator": "IMPS-API/507113432573/Mr NURSVA  /SBIN0009209/2570/Utility",
        "uuidNumber": "507113432573"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,628,943.56CR",
        "balanceIndicator": "2,628,943.56CR",
        "extendedNarrator": "IMPS-API/507113432409/NAVANEETH KUMAR/CNRB0016130/3378/Utility",
        "uuidNumber": "507113432409"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "105.00DR",
        "amountIndicator": "DR",
        "balance": "2,632,943.56CR",
        "balanceIndicator": "2,632,943.56CR",
        "extendedNarrator": "IMPS-API/507113432477/Thakor Ashok Kumar somtaji/GSCB0MSN001/2465/Utility",
        "uuidNumber": "507113432477"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,633,048.56CR",
        "balanceIndicator": "2,633,048.56CR",
        "extendedNarrator": "IMPS-API/507113432392/SAMIR BEHERA/SBIN0004738/5931/Utility",
        "uuidNumber": "507113432392"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "540.00DR",
        "amountIndicator": "DR",
        "balance": "2,633,148.56CR",
        "balanceIndicator": "2,633,148.56CR",
        "extendedNarrator": "IMPS-API/507113432369/ANNAMALAI GOVINDHASAMY/HDFC0000351/3872/Utility",
        "uuidNumber": "507113432369"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,633,688.56CR",
        "balanceIndicator": "2,633,688.56CR",
        "extendedNarrator": "IMPS-API/507113432365/RAJKISHOR /KKBK0000958/7433/Utility",
        "uuidNumber": "507113432365"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,634,688.56CR",
        "balanceIndicator": "2,634,688.56CR",
        "extendedNarrator": "IMPS-API/507113432311/ABHISHEK MEEND/IPOS0000001/9300/Utility",
        "uuidNumber": "507113432311"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,636,188.56CR",
        "balanceIndicator": "2,636,188.56CR",
        "extendedNarrator": "IMPS-API/507113431929/SOMASHEKAR D/KARB0000133/4401/Utility",
        "uuidNumber": "507113431929"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,100.00DR",
        "amountIndicator": "DR",
        "balance": "2,639,188.56CR",
        "balanceIndicator": "2,639,188.56CR",
        "extendedNarrator": "IMPS-API/507113431889/MRIGANKA MAHANTA/SBIN0005783/8782/Utility",
        "uuidNumber": "507113431889"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:57:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,640,288.56CR",
        "balanceIndicator": "2,640,288.56CR",
        "extendedNarrator": "IMPS-API/507113431648/SUNIL KUMAR/CNRB0000002/3227/Utility",
        "uuidNumber": "507113431648"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "8,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,644,288.56CR",
        "balanceIndicator": "2,644,288.56CR",
        "extendedNarrator": "IMPS-API/507113430391/ASIF MUSHTAQ/YESB0000090/2070/Utility",
        "uuidNumber": "507113430391"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:46",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,652,288.56CR",
        "balanceIndicator": "2,652,288.56CR",
        "extendedNarrator": "IMPS-API/507113430540/RINKU RINKU/SBIN0005665/5743/Utility",
        "uuidNumber": "507113430540"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,664,288.56CR",
        "balanceIndicator": "2,664,288.56CR",
        "extendedNarrator": "IMPS-API/507113430472/NILESH AMBHORE/MAHB0000737/6080/Utility",
        "uuidNumber": "507113430472"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "200.00DR",
        "amountIndicator": "DR",
        "balance": "2,665,288.56CR",
        "balanceIndicator": "2,665,288.56CR",
        "extendedNarrator": "IMPS-API/507113430051/Rakesh kumar/HDFC0000642/9140/Utility",
        "uuidNumber": "507113430051"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,665,488.56CR",
        "balanceIndicator": "2,665,488.56CR",
        "extendedNarrator": "IMPS-API/507113429853/VINOD SING/UTIB0000150/9718/Utility",
        "uuidNumber": "507113429853"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:31",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,666,438.56CR",
        "balanceIndicator": "2,666,438.56CR",
        "extendedNarrator": "IMPS-API/507113429462/MRS USHA DEVI/SBIN0000001/9561/Utility",
        "uuidNumber": "507113429462"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,673,938.56CR",
        "balanceIndicator": "2,673,938.56CR",
        "extendedNarrator": "IMPS-API/507113429540/KAMLAVATI WO JAGDEESH/BARB0BUPGBX/0305/Utility",
        "uuidNumber": "507113429540"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,682,938.56CR",
        "balanceIndicator": "2,682,938.56CR",
        "extendedNarrator": "IMPS-API/507113429101/SUNITA DEVI/PUNB0244200/8464/Utility",
        "uuidNumber": "507113429101"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,687,938.56CR",
        "balanceIndicator": "2,687,938.56CR",
        "extendedNarrator": "IMPS-API/507113428719/SHIVANAND PAVADASHETTI/ICIC0006718/2752/Utility",
        "uuidNumber": "507113428719"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,699,938.56CR",
        "balanceIndicator": "2,699,938.56CR",
        "extendedNarrator": "IMPS-API/507113428416/Arup Patra/CNRB0003201/7552/Utility",
        "uuidNumber": "507113428416"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:56:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,700,223.56CR",
        "balanceIndicator": "2,700,223.56CR",
        "extendedNarrator": "IMPS-API/507113428377/JITENDRA KUMAR/BKID0004992/3016/Utility",
        "uuidNumber": "507113428377"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,700,723.56CR",
        "balanceIndicator": "2,700,723.56CR",
        "extendedNarrator": "IMPS-API/507113427588/SHIKHA/BKID0008400/3911/Utility",
        "uuidNumber": "507113427588"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,704,723.56CR",
        "balanceIndicator": "2,704,723.56CR",
        "extendedNarrator": "IMPS-API/507113427053/sonu/CBIN0280202/7128/Utility",
        "uuidNumber": "507113427053"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,704,823.56CR",
        "balanceIndicator": "2,704,823.56CR",
        "extendedNarrator": "IMPS-API/507113427136/Konda reddy/FINO0009001/8761/Utility",
        "uuidNumber": "507113427136"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,704,923.56CR",
        "balanceIndicator": "2,704,923.56CR",
        "extendedNarrator": "IMPS-API/507113427002/Konda reddy/FINO0009001/8761/Utility",
        "uuidNumber": "507113427002"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,705,208.56CR",
        "balanceIndicator": "2,705,208.56CR",
        "extendedNarrator": "IMPS-API/507113427000/Pravin Mahadev Neware/IOBA0002184/0575/Utility",
        "uuidNumber": "507113427000"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:32",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "8,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,705,308.56CR",
        "balanceIndicator": "2,705,308.56CR",
        "extendedNarrator": "IMPS-API/507113426683/SAHERA KHATUN/SBIN0000001/7376/Utility",
        "uuidNumber": "507113426683"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,713,308.56CR",
        "balanceIndicator": "2,713,308.56CR",
        "extendedNarrator": "IMPS-API/507113426612/MOHIT BAGDI/HDFC0009049/6248/Utility",
        "uuidNumber": "507113426612"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,716,058.56CR",
        "balanceIndicator": "2,716,058.56CR",
        "extendedNarrator": "IMPS-API/507113426445/HARISHA T R/KARB0000296/4801/Utility",
        "uuidNumber": "507113426445"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,716,558.56CR",
        "balanceIndicator": "2,716,558.56CR",
        "extendedNarrator": "IMPS-API/507113426334/DURGESH MAHAJAN/IPOS0000001/8013/Utility",
        "uuidNumber": "507113426334"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,717,558.56CR",
        "balanceIndicator": "2,717,558.56CR",
        "extendedNarrator": "IMPS-API/507113426128/RUSHIKESH THAKUR/ESFB0009021/0600/Utility",
        "uuidNumber": "507113426128"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,718,058.56CR",
        "balanceIndicator": "2,718,058.56CR",
        "extendedNarrator": "IMPS-API/507113425989/DEEPAK GUPTA/BKID0007036/0424/Utility",
        "uuidNumber": "507113425989"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:55:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,718,558.56CR",
        "balanceIndicator": "2,718,558.56CR",
        "extendedNarrator": "IMPS-API/507113425458/Nabinsamal/BKID0005442/4026/Utility",
        "uuidNumber": "507113425458"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,718,843.56CR",
        "balanceIndicator": "2,718,843.56CR",
        "extendedNarrator": "IMPS-API/507113425054/MUKESH PATEL/BKID0005590/4350/Utility",
        "uuidNumber": "507113425054"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,728,843.56CR",
        "balanceIndicator": "2,728,843.56CR",
        "extendedNarrator": "IMPS-API/507113425019/VASHUDEV RAY SODAYA RAY/PUNB0244200/2716/Utility",
        "uuidNumber": "507113425019"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,733,843.56CR",
        "balanceIndicator": "2,733,843.56CR",
        "extendedNarrator": "IMPS-API/507113424554/EDUBILLI VENKATESH/SBIN0017071/6653/Utility",
        "uuidNumber": "507113424554"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,734,843.56CR",
        "balanceIndicator": "2,734,843.56CR",
        "extendedNarrator": "IMPS-API/507113424663/Shiyal santosh ghusabhai/SBIN0013472/4730/Utility",
        "uuidNumber": "507113424663"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,734,943.56CR",
        "balanceIndicator": "2,734,943.56CR",
        "extendedNarrator": "IMPS-API/507113424625/MR BHAGABHAI MATHURBHAI/PUNB0139700/2653/Utility",
        "uuidNumber": "507113424625"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,910.00DR",
        "amountIndicator": "DR",
        "balance": "2,735,228.56CR",
        "balanceIndicator": "2,735,228.56CR",
        "extendedNarrator": "IMPS-API/507113424529/Patel mitesh Kumar/UBIN0544914/0510/Utility",
        "uuidNumber": "507113424529"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,738,138.56CR",
        "balanceIndicator": "2,738,138.56CR",
        "extendedNarrator": "IMPS-API/507113424448/SATISH GUPTA/SBIN0000168/5784/Utility",
        "uuidNumber": "507113424448"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,739,638.56CR",
        "balanceIndicator": "2,739,638.56CR",
        "extendedNarrator": "IMPS-API/507113424421/Sunita/SBIN0031329/5825/Utility",
        "uuidNumber": "507113424421"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,167.00DR",
        "amountIndicator": "DR",
        "balance": "2,744,388.56CR",
        "balanceIndicator": "2,744,388.56CR",
        "extendedNarrator": "IMPS-API/507113424353/OM CHHAGAN KAMBLE/SBIN0061400/0364/Utility",
        "uuidNumber": "507113424353"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "915.00DR",
        "amountIndicator": "DR",
        "balance": "2,745,555.56CR",
        "balanceIndicator": "2,745,555.56CR",
        "extendedNarrator": "IFT/10178706122/Mr. Akshaya Kumar Mo/12410395/Utility",
        "uuidNumber": "1820769"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,400.00DR",
        "amountIndicator": "DR",
        "balance": "2,746,470.56CR",
        "balanceIndicator": "2,746,470.56CR",
        "extendedNarrator": "IMPS-API/507113424185/SOURAV DAS/ICIC0007803/0438/Utility",
        "uuidNumber": "507113424185"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,752,870.56CR",
        "balanceIndicator": "2,752,870.56CR",
        "extendedNarrator": "IMPS-API/507113424125/SURYA PRASAD SAHOO/ICIC0000467/8670/Utility",
        "uuidNumber": "507113424125"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,753,370.56CR",
        "balanceIndicator": "2,753,370.56CR",
        "extendedNarrator": "IMPS-API/507113423749/MD DILSHER/PUNB0063200/0690/Utility",
        "uuidNumber": "507113423749"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,754,870.56CR",
        "balanceIndicator": "2,754,870.56CR",
        "extendedNarrator": "IMPS-API/507113423575/Karike/IPOS0000001/0703/Utility",
        "uuidNumber": "507113423575"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,755,155.56CR",
        "balanceIndicator": "2,755,155.56CR",
        "extendedNarrator": "IMPS-API/507113423292/Rawal singh/AIRP0000001/6962/Utility",
        "uuidNumber": "507113423292"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "105.00DR",
        "amountIndicator": "DR",
        "balance": "2,755,630.56CR",
        "balanceIndicator": "2,755,630.56CR",
        "extendedNarrator": "IMPS-API/507113423277/Rakesh Kumar Saini/BARB0PIPLAI/2672/Utility",
        "uuidNumber": "507113423277"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,755,735.56CR",
        "balanceIndicator": "2,755,735.56CR",
        "extendedNarrator": "IMPS-API/507113423244/MRS AFLATUN AFLATUN/CBIN0281107/6154/Utility",
        "uuidNumber": "507113423244"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:54:00",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,760,735.56CR",
        "balanceIndicator": "2,760,735.56CR",
        "extendedNarrator": "IMPS-API/507113423112/BABLU PRASAD AHIRWAL/PUNB0244200/3796/Utility",
        "uuidNumber": "507113423112"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,765,735.56CR",
        "balanceIndicator": "2,765,735.56CR",
        "extendedNarrator": "IMPS-API/507113422391/GUFRAN KHAN/ESFB0009027/3384/Utility",
        "uuidNumber": "507113422391"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:45",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,767,235.56CR",
        "balanceIndicator": "2,767,235.56CR",
        "extendedNarrator": "IMPS-API/507113422348/TANUJ KUMAR/CNRB0001649/4166/Utility",
        "uuidNumber": "507113422348"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,767,735.56CR",
        "balanceIndicator": "2,767,735.56CR",
        "extendedNarrator": "IMPS-API/507113422302/JAMMU ARAVIND/JIOP0000001/9726/Utility",
        "uuidNumber": "507113422302"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,768,235.56CR",
        "balanceIndicator": "2,768,235.56CR",
        "extendedNarrator": "IMPS-API/507113422090/PANKAJ KILORIYA/HDFC0007292/9450/Utility",
        "uuidNumber": "507113422090"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,772,235.56CR",
        "balanceIndicator": "2,772,235.56CR",
        "extendedNarrator": "IMPS-API/507113422088/Sadhan ch das/SBIN0011381/8861/Utility",
        "uuidNumber": "507113422088"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,773,185.56CR",
        "balanceIndicator": "2,773,185.56CR",
        "extendedNarrator": "IMPS-API/507113422084/Chandan roy/FINO0000001/4898/Utility",
        "uuidNumber": "507113422084"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,776,035.56CR",
        "balanceIndicator": "2,776,035.56CR",
        "extendedNarrator": "IMPS-API/507113422074/Pradeep/PUNB0497300/7194/Utility",
        "uuidNumber": "507113422074"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,778,885.56CR",
        "balanceIndicator": "2,778,885.56CR",
        "extendedNarrator": "IMPS-API/507113421871/Shubham joshi/SBIN0RRUTGB/9747/Utility",
        "uuidNumber": "507113421871"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "600.00DR",
        "amountIndicator": "DR",
        "balance": "2,779,835.56CR",
        "balanceIndicator": "2,779,835.56CR",
        "extendedNarrator": "IMPS-API/507113422062/Rohit kumar/SBIN0003456/0624/Utility",
        "uuidNumber": "507113422062"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,780,435.56CR",
        "balanceIndicator": "2,780,435.56CR",
        "extendedNarrator": "IMPS-API/507113421340/Shubham joshi/SBIN0RRUTGB/9747/Utility",
        "uuidNumber": "507113421340"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "15,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,781,385.56CR",
        "balanceIndicator": "2,781,385.56CR",
        "extendedNarrator": "IMPS-API/507113421278/MS SHIVA SALES CORPORATION/SBIN0009209/3816/Utility",
        "uuidNumber": "507113421278"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,796,885.56CR",
        "balanceIndicator": "2,796,885.56CR",
        "extendedNarrator": "IMPS-API/507113421322/CHANDRA SHEKHAR GUPTA/HDFC0001443/9806/Utility",
        "uuidNumber": "507113421322"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,801,885.56CR",
        "balanceIndicator": "2,801,885.56CR",
        "extendedNarrator": "IMPS-API/507113421265/Amit/IPOS0000001/8549/Utility",
        "uuidNumber": "507113421265"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,806,735.56CR",
        "balanceIndicator": "2,806,735.56CR",
        "extendedNarrator": "IMPS-API/507113421136/Mrs RATIMA  KHATUN/SBIN0009209/3238/Utility",
        "uuidNumber": "507113421136"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,811,735.56CR",
        "balanceIndicator": "2,811,735.56CR",
        "extendedNarrator": "IMPS-API/507113421236/Tilok singh/BARB0JAWAJA/7029/Utility",
        "uuidNumber": "507113421236"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,811,835.56CR",
        "balanceIndicator": "2,811,835.56CR",
        "extendedNarrator": "IMPS-API/507113421082/NIKUNJ BHARATBHAI JOSHI/SBIN0060103/5220/Utility",
        "uuidNumber": "507113421082"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,816,585.56CR",
        "balanceIndicator": "2,816,585.56CR",
        "extendedNarrator": "IMPS-API/507113421180/Asish Ekka/KKBK0007288/1927/Utility",
        "uuidNumber": "507113421180"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "2,821,335.56CR",
        "balanceIndicator": "2,821,335.56CR",
        "extendedNarrator": "IMPS-API/507113421166/NIKUNJ BHARATBHAI JOSHI/SBIN0060103/5220/Utility",
        "uuidNumber": "507113421166"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,826,085.56CR",
        "balanceIndicator": "2,826,085.56CR",
        "extendedNarrator": "IMPS-API/507113421164/Tridip Pegu/SBIN0010760/3352/Utility",
        "uuidNumber": "507113421164"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "2,827,035.56CR",
        "balanceIndicator": "2,827,035.56CR",
        "extendedNarrator": "IMPS-API/507113421021/Madan lal/SBIN0005870/1376/Utility",
        "uuidNumber": "507113421021"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,831,835.56CR",
        "balanceIndicator": "2,831,835.56CR",
        "extendedNarrator": "IMPS-API/507113421020/Nand Kishore Gupta/CBIN0283868/8227/Utility",
        "uuidNumber": "507113421020"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "2,834,685.56CR",
        "balanceIndicator": "2,834,685.56CR",
        "extendedNarrator": "IMPS-API/507113420744/Madan lal/SBIN0005870/1376/Utility",
        "uuidNumber": "507113420744"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,839,485.56CR",
        "balanceIndicator": "2,839,485.56CR",
        "extendedNarrator": "IMPS-API/507113420745/Lipuna Muduli/ESMF0001251/9107/Utility",
        "uuidNumber": "507113420745"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:53:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,839,770.56CR",
        "balanceIndicator": "2,839,770.56CR",
        "extendedNarrator": "IMPS-API/507113420665/JAYVEER SINGH/PUNB0268100/9208/Utility",
        "uuidNumber": "507113420665"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,840,270.56CR",
        "balanceIndicator": "2,840,270.56CR",
        "extendedNarrator": "IMPS-API/507113420037/PARUL ASSOCIATES/SBIN0000001/8918/Utility",
        "uuidNumber": "507113420037"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,846,270.56CR",
        "balanceIndicator": "2,846,270.56CR",
        "extendedNarrator": "IMPS-API/507113419863/Abdul khan/BARB0MARPAL/9236/Utility",
        "uuidNumber": "507113419863"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,846,770.56CR",
        "balanceIndicator": "2,846,770.56CR",
        "extendedNarrator": "IMPS-API/507113420002/Kanha Prajapati/PUNB0497300/7200/Utility",
        "uuidNumber": "507113420002"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,849,620.56CR",
        "balanceIndicator": "2,849,620.56CR",
        "extendedNarrator": "IMPS-API/507113419801/Pradeep lodha/DBSS0IN0150/8382/Utility",
        "uuidNumber": "507113419801"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,852,470.56CR",
        "balanceIndicator": "2,852,470.56CR",
        "extendedNarrator": "IMPS-API/507113419793/Manoj Kumar Singh/SBIN0006188/2271/Utility",
        "uuidNumber": "507113419793"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,853,420.56CR",
        "balanceIndicator": "2,853,420.56CR",
        "extendedNarrator": "IMPS-API/507113419617/Kanha Prajapati/SBIN0062274/8535/Utility",
        "uuidNumber": "507113419617"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,856,270.56CR",
        "balanceIndicator": "2,856,270.56CR",
        "extendedNarrator": "IMPS-API/507113419314/ABHISHEK YADAV/SBIN0007399/0672/Utility",
        "uuidNumber": "507113419314"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "680.00DR",
        "amountIndicator": "DR",
        "balance": "2,858,270.56CR",
        "balanceIndicator": "2,858,270.56CR",
        "extendedNarrator": "IMPS-API/507113419233/SURAJ SINGH/BKID0009963/4856/Utility",
        "uuidNumber": "507113419233"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,858,950.56CR",
        "balanceIndicator": "2,858,950.56CR",
        "extendedNarrator": "IMPS-API/507113419209/PARUL ASSOCIATES/SBIN0000001/8918/Utility",
        "uuidNumber": "507113419209"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:22",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,883,950.56CR",
        "balanceIndicator": "2,883,950.56CR",
        "extendedNarrator": "IMPS-API/507113418965/BABLU KUMAR SHARMA/IPOS0000001/1134/Utility",
        "uuidNumber": "507113418965"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "2,886,450.56CR",
        "balanceIndicator": "2,886,450.56CR",
        "extendedNarrator": "IMPS-API/507113418949/SOME MANIKUMAR/HDFC0003035/5905/Utility",
        "uuidNumber": "507113418949"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,150.00DR",
        "amountIndicator": "DR",
        "balance": "2,886,950.56CR",
        "balanceIndicator": "2,886,950.56CR",
        "extendedNarrator": "IMPS-API/507113418924/Francis poly merlyn/SBIN0000001/8645/Utility",
        "uuidNumber": "507113418924"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,100.00DR",
        "amountIndicator": "DR",
        "balance": "2,888,100.56CR",
        "balanceIndicator": "2,888,100.56CR",
        "extendedNarrator": "IMPS-API/507113419098/PATHAN KHANAM/CNRB0013667/6085/Utility",
        "uuidNumber": "507113419098"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,889,200.56CR",
        "balanceIndicator": "2,889,200.56CR",
        "extendedNarrator": "IMPS-API/507113418475/Nekenmenla/SBIN0018741/6252/Utility",
        "uuidNumber": "507113418475"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,890,150.56CR",
        "balanceIndicator": "2,890,150.56CR",
        "extendedNarrator": "IMPS-API/507113418660/PARUL ASSOCIATES/SBIN0000001/8918/Utility",
        "uuidNumber": "507113418660"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:52:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,915,150.56CR",
        "balanceIndicator": "2,915,150.56CR",
        "extendedNarrator": "IMPS-API/507113418627/Baraiya Bharat karshanhai/SBIN0003335/3276/Utility",
        "uuidNumber": "507113418627"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:58",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,915,435.56CR",
        "balanceIndicator": "2,915,435.56CR",
        "extendedNarrator": "IMPS-API/507113418042/MAYANK  GUPTA/KKBK0000958/6903/Utility",
        "uuidNumber": "507113418042"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,100.00DR",
        "amountIndicator": "DR",
        "balance": "2,925,435.56CR",
        "balanceIndicator": "2,925,435.56CR",
        "extendedNarrator": "IMPS-API/507113417533/HARSH SHRIVASTAVA/HDFC0000433/8205/Utility",
        "uuidNumber": "507113417533"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "600.00DR",
        "amountIndicator": "DR",
        "balance": "2,926,535.56CR",
        "balanceIndicator": "2,926,535.56CR",
        "extendedNarrator": "IMPS-API/507113417450/SANDEEP D U/KARB0000411/1601/Utility",
        "uuidNumber": "507113417450"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,880.00DR",
        "amountIndicator": "DR",
        "balance": "2,927,135.56CR",
        "balanceIndicator": "2,927,135.56CR",
        "extendedNarrator": "IMPS-API/507113417109/SUJOY SIKDER/SBIN0006985/4451/Utility",
        "uuidNumber": "507113417109"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,930,015.56CR",
        "balanceIndicator": "2,930,015.56CR",
        "extendedNarrator": "IMPS-API/507113417378/Gupta dipak/UJVN0004483/2748/Utility",
        "uuidNumber": "507113417378"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,930,115.56CR",
        "balanceIndicator": "2,930,115.56CR",
        "extendedNarrator": "IMPS-API/507113417071/KENNY SUPREETH/KKBK0000811/9469/Utility",
        "uuidNumber": "507113417071"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,931,615.56CR",
        "balanceIndicator": "2,931,615.56CR",
        "extendedNarrator": "IMPS-API/507113417048/Dinesh mali/SBIN0031099/0205/Utility",
        "uuidNumber": "507113417048"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,931,715.56CR",
        "balanceIndicator": "2,931,715.56CR",
        "extendedNarrator": "IMPS-API/507113417300/Ravindra Kumar/BKID0004677/9136/Utility",
        "uuidNumber": "507113417300"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,931,815.56CR",
        "balanceIndicator": "2,931,815.56CR",
        "extendedNarrator": "IMPS-API/507113417298/Priyanka/ICIC0001088/3467/Utility",
        "uuidNumber": "507113417298"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,931,915.56CR",
        "balanceIndicator": "2,931,915.56CR",
        "extendedNarrator": "IMPS-API/507113417240/BHAVESH BHAVESH/HDFC0000936/2646/Utility",
        "uuidNumber": "507113417240"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:30",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "2,935,915.56CR",
        "balanceIndicator": "2,935,915.56CR",
        "extendedNarrator": "IMPS-API/507113416881/JITENDRA MEHRA/SBIN0000704/6168/Utility",
        "uuidNumber": "507113416881"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "120.00DR",
        "amountIndicator": "DR",
        "balance": "2,937,415.56CR",
        "balanceIndicator": "2,937,415.56CR",
        "extendedNarrator": "IMPS-API/507113415951/zala dhanrajsinh/SBIN0010984/2919/Utility",
        "uuidNumber": "507113415951"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "2,937,535.56CR",
        "balanceIndicator": "2,937,535.56CR",
        "extendedNarrator": "IMPS-API/507113416262/Priyanka/ICIC0001088/3467/Utility",
        "uuidNumber": "507113416262"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,937,635.56CR",
        "balanceIndicator": "2,937,635.56CR",
        "extendedNarrator": "IMPS-API/507113416181/Gupta dipak/UJVN0004483/2748/Utility",
        "uuidNumber": "507113416181"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,937,920.56CR",
        "balanceIndicator": "2,937,920.56CR",
        "extendedNarrator": "IMPS-API/507113416167/Sunita/SBIN0031329/5825/Utility",
        "uuidNumber": "507113416167"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "2,940,770.56CR",
        "balanceIndicator": "2,940,770.56CR",
        "extendedNarrator": "IMPS-API/507113415777/Gupta dipak/UJVN0004483/2748/Utility",
        "uuidNumber": "507113415777"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:51:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,941,245.56CR",
        "balanceIndicator": "2,941,245.56CR",
        "extendedNarrator": "IMPS-API/507113415523/Bojankimutyalanaidu/SBIN0015389/6270/Utility",
        "uuidNumber": "507113415523"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,900.00DR",
        "amountIndicator": "DR",
        "balance": "2,942,195.56CR",
        "balanceIndicator": "2,942,195.56CR",
        "extendedNarrator": "IMPS-API/507113415146/Vigneshwaram b/BARB0NEHRUP/4821/Utility",
        "uuidNumber": "507113415146"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:56",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,946,095.56CR",
        "balanceIndicator": "2,946,095.56CR",
        "extendedNarrator": "IMPS-API/507113415112/MR VINOD KUMAR/SBIN0000001/8668/Utility",
        "uuidNumber": "507113415112"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:54",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,951,095.56CR",
        "balanceIndicator": "2,951,095.56CR",
        "extendedNarrator": "IMPS-API/507113415047/KANAKALA SANDEEP/FDRL0005555/8381/Utility",
        "uuidNumber": "507113415047"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,952,095.56CR",
        "balanceIndicator": "2,952,095.56CR",
        "extendedNarrator": "IMPS-API/507113414313/Rajesh bariya/IPOS0000001/7716/Utility",
        "uuidNumber": "507113414313"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,953,045.56CR",
        "balanceIndicator": "2,953,045.56CR",
        "extendedNarrator": "IMPS-API/507113414266/RaviShankar Gond/PUNB0791900/0142/Utility",
        "uuidNumber": "507113414266"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,953,995.56CR",
        "balanceIndicator": "2,953,995.56CR",
        "extendedNarrator": "IMPS-API/507113414229/Dinesh mali/SBIN0031099/0205/Utility",
        "uuidNumber": "507113414229"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,954,945.56CR",
        "balanceIndicator": "2,954,945.56CR",
        "extendedNarrator": "IMPS-API/507113414206/Shaikh aspakmiya/BARB0DAKORX/0456/Utility",
        "uuidNumber": "507113414206"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,957,795.56CR",
        "balanceIndicator": "2,957,795.56CR",
        "extendedNarrator": "IMPS-API/507113414139/Mayurkumar/AUBL0002135/9742/Utility",
        "uuidNumber": "507113414139"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,958,745.56CR",
        "balanceIndicator": "2,958,745.56CR",
        "extendedNarrator": "IMPS-API/507113414141/Jayesh Bhai lahnuBhai sapta/BARB0RAKHOL/4667/Utility",
        "uuidNumber": "507113414141"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,959,030.56CR",
        "balanceIndicator": "2,959,030.56CR",
        "extendedNarrator": "IMPS-API/507113413197/DHUP CHAND SO UMA/BARB0TILDEL/3897/Utility",
        "uuidNumber": "507113413197"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "2,961,030.56CR",
        "balanceIndicator": "2,961,030.56CR",
        "extendedNarrator": "IMPS-API/507113413028/ashokchaudhary/SBIN0017051/4441/Utility",
        "uuidNumber": "507113413028"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,961,980.56CR",
        "balanceIndicator": "2,961,980.56CR",
        "extendedNarrator": "IMPS-API/507113412969/Pasula naresh/UBIN0813541/7720/Utility",
        "uuidNumber": "507113412969"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,964,830.56CR",
        "balanceIndicator": "2,964,830.56CR",
        "extendedNarrator": "IMPS-API/507113412934/Pradeep/AIRP0000001/1397/Utility",
        "uuidNumber": "507113412934"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,501.00DR",
        "amountIndicator": "DR",
        "balance": "2,967,680.56CR",
        "balanceIndicator": "2,967,680.56CR",
        "extendedNarrator": "IMPS-API/507113412900/MR RAJENDRA AHIRWAR/SBIN0000001/2110/Utility",
        "uuidNumber": "507113412900"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,971,181.56CR",
        "balanceIndicator": "2,971,181.56CR",
        "extendedNarrator": "IMPS-API/507113412898/Parmeshwar Ramrao Chavan/CBIN0283054/9720/Utility",
        "uuidNumber": "507113412898"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,974,031.56CR",
        "balanceIndicator": "2,974,031.56CR",
        "extendedNarrator": "IMPS-API/507113412607/Pradeep lodha/FINO0009001/1457/Utility",
        "uuidNumber": "507113412607"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "2,976,881.56CR",
        "balanceIndicator": "2,976,881.56CR",
        "extendedNarrator": "IMPS-API/507113412844/Kanha Prajapati/PUNB0497300/7200/Utility",
        "uuidNumber": "507113412844"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,880.00DR",
        "amountIndicator": "DR",
        "balance": "2,979,731.56CR",
        "balanceIndicator": "2,979,731.56CR",
        "extendedNarrator": "IMPS-API/507113412808/Madan lal/SBIN0005870/1376/Utility",
        "uuidNumber": "507113412808"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,880.00DR",
        "amountIndicator": "DR",
        "balance": "2,982,611.56CR",
        "balanceIndicator": "2,982,611.56CR",
        "extendedNarrator": "IMPS-API/507113412556/Yashwant kumar nagar/SBIN0031270/0193/Utility",
        "uuidNumber": "507113412556"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:50:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "2,985,491.56CR",
        "balanceIndicator": "2,985,491.56CR",
        "extendedNarrator": "IMPS-API/507113412781/Pardeep/BARB0DBFBAD/1737/Utility",
        "uuidNumber": "507113412781"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,501.00DR",
        "amountIndicator": "DR",
        "balance": "2,985,776.56CR",
        "balanceIndicator": "2,985,776.56CR",
        "extendedNarrator": "IMPS-API/507113411073/MR RAJENDRA AHIRWAR/SBIN0000001/2110/Utility",
        "uuidNumber": "507113411073"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:46",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,989,277.56CR",
        "balanceIndicator": "2,989,277.56CR",
        "extendedNarrator": "IMPS-API/507113411052/DILIP KUMAR/SBIN0000128/2409/Utility",
        "uuidNumber": "507113411052"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "930.00DR",
        "amountIndicator": "DR",
        "balance": "2,990,277.56CR",
        "balanceIndicator": "2,990,277.56CR",
        "extendedNarrator": "IMPS-API/507113411732/RAHUL JADHAV/BKID0000709/7519/Utility",
        "uuidNumber": "507113411732"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "22,000.00DR",
        "amountIndicator": "DR",
        "balance": "2,991,207.56CR",
        "balanceIndicator": "2,991,207.56CR",
        "extendedNarrator": "IMPS-API/507113411033/Lalit kumar/UTIB0001470/5378/Utility",
        "uuidNumber": "507113411033"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "839.72DR",
        "amountIndicator": "DR",
        "balance": "3,013,207.56CR",
        "balanceIndicator": "3,013,207.56CR",
        "extendedNarrator": "IMPS-API/507113411675/Piyush Manoj Kumar Vyas/YESB0000943/0810/Utility",
        "uuidNumber": "507113411675"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,014,047.28CR",
        "balanceIndicator": "3,014,047.28CR",
        "extendedNarrator": "IMPS-API/507113411025/Mr RAMSURAT GUPTA  /SBIN0009209/8049/Utility",
        "uuidNumber": "507113411025"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,019,047.28CR",
        "balanceIndicator": "3,019,047.28CR",
        "extendedNarrator": "IMPS-API/507113411646/AMIT SONI/KKBK0000274/1856/Utility",
        "uuidNumber": "507113411646"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "700.00DR",
        "amountIndicator": "DR",
        "balance": "3,039,047.28CR",
        "balanceIndicator": "3,039,047.28CR",
        "extendedNarrator": "IMPS-API/507113411577/PARSHURAMKUSHWAHA KUMAR/SBIN0006812/2894/Utility",
        "uuidNumber": "507113411577"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,039,747.28CR",
        "balanceIndicator": "3,039,747.28CR",
        "extendedNarrator": "IMPS-API/507113411560/Venkatesh/IDIB000T059/7411/Utility",
        "uuidNumber": "507113411560"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,039,847.28CR",
        "balanceIndicator": "3,039,847.28CR",
        "extendedNarrator": "IMPS-API/507113411555/Vikram singh/BARB0HANSIX/4758/Utility",
        "uuidNumber": "507113411555"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,044,597.28CR",
        "balanceIndicator": "3,044,597.28CR",
        "extendedNarrator": "IMPS-API/507113411512/Sunil ahirwal/SBIN0005514/4945/Utility",
        "uuidNumber": "507113411512"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,044,697.28CR",
        "balanceIndicator": "3,044,697.28CR",
        "extendedNarrator": "IMPS-API/507113411004/Laxman Lodha/UTIB0000679/6704/Utility",
        "uuidNumber": "507113411004"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "18,426.00DR",
        "amountIndicator": "DR",
        "balance": "3,044,797.28CR",
        "balanceIndicator": "3,044,797.28CR",
        "extendedNarrator": "IMPS-API/507113411515/JIJMr  NITESH KUMAR/SBIN0004836/7159/Utility",
        "uuidNumber": "507113411515"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,063,223.28CR",
        "balanceIndicator": "3,063,223.28CR",
        "extendedNarrator": "IMPS-API/507113411511/Dinesh Kumar oad/PUNB0874100/3265/Utility",
        "uuidNumber": "507113411511"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,063,323.28CR",
        "balanceIndicator": "3,063,323.28CR",
        "extendedNarrator": "IMPS-API/507113411505/Laxman Lodha/UTIB0000679/6704/Utility",
        "uuidNumber": "507113411505"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,900.00DR",
        "amountIndicator": "DR",
        "balance": "3,063,423.28CR",
        "balanceIndicator": "3,063,423.28CR",
        "extendedNarrator": "IMPS-API/507113411437/Narsing vignesh/SBIN0000001/6655/Utility",
        "uuidNumber": "507113411437"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:30",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,067,323.28CR",
        "balanceIndicator": "3,067,323.28CR",
        "extendedNarrator": "IMPS-API/507113411353/MR HASEEM/CBIN0281107/3899/Utility",
        "uuidNumber": "507113411353"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,072,323.28CR",
        "balanceIndicator": "3,072,323.28CR",
        "extendedNarrator": "IMPS-API/507113411301/MR RAJENDRA AHIRWAR/SBIN0000001/2110/Utility",
        "uuidNumber": "507113411301"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,076,323.28CR",
        "balanceIndicator": "3,076,323.28CR",
        "extendedNarrator": "IMPS-API/507113411117/SHIVJI KUMAR/BARB0TILDEL/1046/Utility",
        "uuidNumber": "507113411117"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,078,323.28CR",
        "balanceIndicator": "3,078,323.28CR",
        "extendedNarrator": "IMPS-API/507113410796/SUMAN KUMAR/UBIN0550451/5859/Utility",
        "uuidNumber": "507113410796"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,083,323.28CR",
        "balanceIndicator": "3,083,323.28CR",
        "extendedNarrator": "IMPS-API/507113410587/Rabindra kumar mahanty/BARB0SUNABE/0241/Utility",
        "uuidNumber": "507113410587"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,083,423.28CR",
        "balanceIndicator": "3,083,423.28CR",
        "extendedNarrator": "IMPS-API/507113410364/BLMeena BLMeena/IDIB000S559/2201/Utility",
        "uuidNumber": "507113410364"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,083,523.28CR",
        "balanceIndicator": "3,083,523.28CR",
        "extendedNarrator": "IMPS-API/507113410342/Sanju kumari meena/ICIC0006701/0453/Utility",
        "uuidNumber": "507113410342"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:10",
        "description": "CREDIT VIA INTERNAL",
        "amount": "100.00CR",
        "amountIndicator": "CR",
        "balance": "3,083,623.28CR",
        "balanceIndicator": "3,083,623.28CR",
        "extendedNarrator": "IMPS-API/REV/507113410499/GOHEL NARESHBHAI VIRAJIBHAI/BARB0SANAND/0333",
        "uuidNumber": "507113410499"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,083,523.28CR",
        "balanceIndicator": "3,083,523.28CR",
        "extendedNarrator": "IMPS-API/507113410321/deepak/PUNB0694400/6394/Utility",
        "uuidNumber": "507113410321"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,084,473.28CR",
        "balanceIndicator": "3,084,473.28CR",
        "extendedNarrator": "IMPS-API/507113410499/GOHEL NARESHBHAI VIRAJIBHAI/BARB0SANAND/0333/Utility",
        "uuidNumber": "507113410499"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,084,573.28CR",
        "balanceIndicator": "3,084,573.28CR",
        "extendedNarrator": "IMPS-API/507113410481/Atikul/SBIN0016935/0024/Utility",
        "uuidNumber": "507113410481"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,084,673.28CR",
        "balanceIndicator": "3,084,673.28CR",
        "extendedNarrator": "IMPS-API/507113410286/Sanjib Mukherjee/SBIN0061615/7277/Utility",
        "uuidNumber": "507113410286"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,087,523.28CR",
        "balanceIndicator": "3,087,523.28CR",
        "extendedNarrator": "IMPS-API/507113410271/Riyas siddique/SBIN0011055/1735/Utility",
        "uuidNumber": "507113410271"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,900.00DR",
        "amountIndicator": "DR",
        "balance": "3,088,473.28CR",
        "balanceIndicator": "3,088,473.28CR",
        "extendedNarrator": "IMPS-API/507113410255/Nikhil lodha/FINO0009001/9380/Utility",
        "uuidNumber": "507113410255"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,090,373.28CR",
        "balanceIndicator": "3,090,373.28CR",
        "extendedNarrator": "IMPS-API/507113410214/SHIVA/PUNB0415800/5441/Utility",
        "uuidNumber": "507113410214"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,090,473.28CR",
        "balanceIndicator": "3,090,473.28CR",
        "extendedNarrator": "IMPS-API/507113410086/Krishnawatargauttam/SBIN0031079/7399/Utility",
        "uuidNumber": "507113410086"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,095,223.28CR",
        "balanceIndicator": "3,095,223.28CR",
        "extendedNarrator": "IMPS-API/507113410083/Dhoriya sonail ben/BARB0DBPYAD/0157/Utility",
        "uuidNumber": "507113410083"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,095,323.28CR",
        "balanceIndicator": "3,095,323.28CR",
        "extendedNarrator": "IMPS-API/507113410084/RANJIT BHAI HEMUBHAI CHAUHAN/SBIN0002655/3542/Utility",
        "uuidNumber": "507113410084"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "31,200.00DR",
        "amountIndicator": "DR",
        "balance": "3,095,423.28CR",
        "balanceIndicator": "3,095,423.28CR",
        "extendedNarrator": "IMPS-API/507113410162/Shivani /KKBK0000261/6481/Utility",
        "uuidNumber": "507113410162"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:49:02",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "840.00DR",
        "amountIndicator": "DR",
        "balance": "3,126,623.28CR",
        "balanceIndicator": "3,126,623.28CR",
        "extendedNarrator": "IMPS-API/507113410155/ATUL WANKHEDE/BKID0008737/5325/Utility",
        "uuidNumber": "507113410155"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,127,463.28CR",
        "balanceIndicator": "3,127,463.28CR",
        "extendedNarrator": "IMPS-API/507113409330/SUSHILA /KKBK0000958/4517/Utility",
        "uuidNumber": "507113409330"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "24,999.00DR",
        "amountIndicator": "DR",
        "balance": "3,137,463.28CR",
        "balanceIndicator": "3,137,463.28CR",
        "extendedNarrator": "IMPS-API/507113409602/ALBERT   /KKBK0000958/3029/Utility",
        "uuidNumber": "507113409602"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,162,462.28CR",
        "balanceIndicator": "3,162,462.28CR",
        "extendedNarrator": "IMPS-API/507113409136/mohd wajid/UTIB0002519/8862/Utility",
        "uuidNumber": "507113409136"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,163,962.28CR",
        "balanceIndicator": "3,163,962.28CR",
        "extendedNarrator": "IMPS-API/507113409144/Muskan jainav/SBIN0031840/0754/Utility",
        "uuidNumber": "507113409144"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:32",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,166,812.28CR",
        "balanceIndicator": "3,166,812.28CR",
        "extendedNarrator": "IMPS-API/507113408911/JASAWANT SINGH/KKBK0000958/5264/Utility",
        "uuidNumber": "507113408911"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,171,812.28CR",
        "balanceIndicator": "3,171,812.28CR",
        "extendedNarrator": "IMPS-API/507113408796/TANAY KULKARNI/HDFC0002532/4774/Utility",
        "uuidNumber": "507113408796"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,174,812.28CR",
        "balanceIndicator": "3,174,812.28CR",
        "extendedNarrator": "IMPS-API/507113408793/MALATI DEVI/IOBA0009072/2954/Utility",
        "uuidNumber": "507113408793"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "750.00DR",
        "amountIndicator": "DR",
        "balance": "3,177,812.28CR",
        "balanceIndicator": "3,177,812.28CR",
        "extendedNarrator": "IMPS-API/507113408552/BHANDHAKAVI PRASAD/KKBK0000565/1285/Utility",
        "uuidNumber": "507113408552"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "24,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,178,562.28CR",
        "balanceIndicator": "3,178,562.28CR",
        "extendedNarrator": "IMPS-API/507113408550/ANAS/KKBK0000958/7238/Utility",
        "uuidNumber": "507113408550"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,203,062.28CR",
        "balanceIndicator": "3,203,062.28CR",
        "extendedNarrator": "IMPS-API/507113408651/KONDAGARI PRANAY/KKBK0007457/1111/Utility",
        "uuidNumber": "507113408651"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,205,062.28CR",
        "balanceIndicator": "3,205,062.28CR",
        "extendedNarrator": "IMPS-API/507113408604/GUVVALA SANTHI/SBIN0000884/9468/Utility",
        "uuidNumber": "507113408604"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,206,562.28CR",
        "balanceIndicator": "3,206,562.28CR",
        "extendedNarrator": "IMPS-API/507113408128/Rukmani sahoo/CNRB0003522/3751/Utility",
        "uuidNumber": "507113408128"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,206,662.28CR",
        "balanceIndicator": "3,206,662.28CR",
        "extendedNarrator": "IMPS-API/507113408094/KRISHNA PRAKASH HONMANE/MAHB0000276/4731/Utility",
        "uuidNumber": "507113408094"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,209,512.28CR",
        "balanceIndicator": "3,209,512.28CR",
        "extendedNarrator": "IMPS-API/507113408266/Mohamad Gufran/FDRL0007777/7491/Utility",
        "uuidNumber": "507113408266"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,209,797.28CR",
        "balanceIndicator": "3,209,797.28CR",
        "extendedNarrator": "IMPS-API/507113408055/RAFIQUE ALAM/SBIN0000001/0759/Utility",
        "uuidNumber": "507113408055"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,221,797.28CR",
        "balanceIndicator": "3,221,797.28CR",
        "extendedNarrator": "IMPS-API/507113408230/MR RAJENDRA AHIRWAR/SBIN0000001/2110/Utility",
        "uuidNumber": "507113408230"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,226,797.28CR",
        "balanceIndicator": "3,226,797.28CR",
        "extendedNarrator": "IMPS-API/507113407986/INDRASAN DEVI/CBIN0R10001/6766/Utility",
        "uuidNumber": "507113407986"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "300.00DR",
        "amountIndicator": "DR",
        "balance": "3,232,297.28CR",
        "balanceIndicator": "3,232,297.28CR",
        "extendedNarrator": "IMPS-API/507113407826/Nishant kumar/SBIN0016760/4909/Utility",
        "uuidNumber": "507113407826"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,232,597.28CR",
        "balanceIndicator": "3,232,597.28CR",
        "extendedNarrator": "IMPS-API/507113407827/Bibhas sen/HDFC0000980/6762/Utility",
        "uuidNumber": "507113407827"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:48:01",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "24,999.00DR",
        "amountIndicator": "DR",
        "balance": "3,232,882.28CR",
        "balanceIndicator": "3,232,882.28CR",
        "extendedNarrator": "IMPS-API/507113407753/ALBERT   /KKBK0000958/3029/Utility",
        "uuidNumber": "507113407753"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:58",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,513.00DR",
        "amountIndicator": "DR",
        "balance": "3,257,881.28CR",
        "balanceIndicator": "3,257,881.28CR",
        "extendedNarrator": "IMPS-API/507113407701/MR MOHD SHAHID RAZA/SBIN0000001/3734/Utility",
        "uuidNumber": "507113407701"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,259,394.28CR",
        "balanceIndicator": "3,259,394.28CR",
        "extendedNarrator": "IMPS-API/507113407058/Anuroodu/PUNB0595000/1945/Utility",
        "uuidNumber": "507113407058"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,259,679.28CR",
        "balanceIndicator": "3,259,679.28CR",
        "extendedNarrator": "IMPS-API/507113407208/Mohamad Gufran/FDRL0007777/7491/Utility",
        "uuidNumber": "507113407208"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:44",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,259,964.28CR",
        "balanceIndicator": "3,259,964.28CR",
        "extendedNarrator": "IMPS-API/507113407153/MR RAJENDRA AHIRWAR/SBIN0000001/2110/Utility",
        "uuidNumber": "507113407153"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:44",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,264,964.28CR",
        "balanceIndicator": "3,264,964.28CR",
        "extendedNarrator": "IMPS-API/507113407002/Ashish Babulal Marvada/BKID0003819/4690/Utility",
        "uuidNumber": "507113407002"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,265,439.28CR",
        "balanceIndicator": "3,265,439.28CR",
        "extendedNarrator": "IMPS-API/507113407101/SHITALBEN/BARB0DBPYAD/9592/Utility",
        "uuidNumber": "507113407101"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,265,724.28CR",
        "balanceIndicator": "3,265,724.28CR",
        "extendedNarrator": "IMPS-API/507113406966/RINKIMA BEGUM/PUNB0202120/6429/Utility",
        "uuidNumber": "507113406966"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,271,724.28CR",
        "balanceIndicator": "3,271,724.28CR",
        "extendedNarrator": "IMPS-API/507113407073/Parveen/SBIN0020122/6650/Utility",
        "uuidNumber": "507113407073"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,272,674.28CR",
        "balanceIndicator": "3,272,674.28CR",
        "extendedNarrator": "IMPS-API/507113406816/Sajda Banu/SBIN0002656/1679/Utility",
        "uuidNumber": "507113406816"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "300.00DR",
        "amountIndicator": "DR",
        "balance": "3,273,149.28CR",
        "balanceIndicator": "3,273,149.28CR",
        "extendedNarrator": "IMPS-API/507113406814/Elizabet kuli/SBIN0017659/4647/Utility",
        "uuidNumber": "507113406814"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,273,449.28CR",
        "balanceIndicator": "3,273,449.28CR",
        "extendedNarrator": "IMPS-API/507113406926/Pradeep/AIRP0000001/1397/Utility",
        "uuidNumber": "507113406926"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,278,199.28CR",
        "balanceIndicator": "3,278,199.28CR",
        "extendedNarrator": "IMPS-API/507113406762/Mahaveer Singh Jhala/HDFC0005166/5061/Utility",
        "uuidNumber": "507113406762"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,278,699.28CR",
        "balanceIndicator": "3,278,699.28CR",
        "extendedNarrator": "IMPS-API/507113406736/PINTU SAINI/BARB0BALSAW/3385/Utility",
        "uuidNumber": "507113406736"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,279,174.28CR",
        "balanceIndicator": "3,279,174.28CR",
        "extendedNarrator": "IMPS-API/507113406725/State Bank of India/SBIN0015311/0903/Utility",
        "uuidNumber": "507113406725"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,279,649.28CR",
        "balanceIndicator": "3,279,649.28CR",
        "extendedNarrator": "IMPS-API/507113406167/MR RAJENDRA AHIRWAR/SBIN0000001/2110/Utility",
        "uuidNumber": "507113406167"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,284,649.28CR",
        "balanceIndicator": "3,284,649.28CR",
        "extendedNarrator": "IMPS-API/507113406076/Sumit Kumar/IDIB000M517/4036/Utility",
        "uuidNumber": "507113406076"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,285,149.28CR",
        "balanceIndicator": "3,285,149.28CR",
        "extendedNarrator": "IMPS-API/507113406057/Babulal/BARB0BHINDE/7725/Utility",
        "uuidNumber": "507113406057"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,285,434.28CR",
        "balanceIndicator": "3,285,434.28CR",
        "extendedNarrator": "IMPS-API/507113405986/Bibhas sen/HDFC0000980/6762/Utility",
        "uuidNumber": "507113405986"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,285,909.28CR",
        "balanceIndicator": "3,285,909.28CR",
        "extendedNarrator": "IMPS-API/507113405773/GOHEL NARESHBHAI VIRAJIBHAI/BARB0SANAND/0455/Utility",
        "uuidNumber": "507113405773"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,286,384.28CR",
        "balanceIndicator": "3,286,384.28CR",
        "extendedNarrator": "IMPS-API/507113405767/Rinki Devi/AIRP0000001/2314/Utility",
        "uuidNumber": "507113405767"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,288,384.28CR",
        "balanceIndicator": "3,288,384.28CR",
        "extendedNarrator": "IMPS-API/507113405704/Gokaran Prasad Dwivedi/HDFC0001059/0105/Utility",
        "uuidNumber": "507113405704"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,293,234.28CR",
        "balanceIndicator": "3,293,234.28CR",
        "extendedNarrator": "IMPS-API/507113405508/Vikas vinod/JANA0000017/8017/Utility",
        "uuidNumber": "507113405508"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:47:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,632.00DR",
        "amountIndicator": "DR",
        "balance": "3,293,709.28CR",
        "balanceIndicator": "3,293,709.28CR",
        "extendedNarrator": "IMPS-API/507113405440/MAHIP ADHIKARI/HDFC0000003/2647/Utility",
        "uuidNumber": "507113405440"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,299,341.28CR",
        "balanceIndicator": "3,299,341.28CR",
        "extendedNarrator": "IMPS-API/507113404933/KALAVATI DEVI/PUNB0063200/5590/Utility",
        "uuidNumber": "507113404933"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,303,341.28CR",
        "balanceIndicator": "3,303,341.28CR",
        "extendedNarrator": "IMPS-API/507113404620/Gonthina Durga Prasad/SBIN0006832/6541/Utility",
        "uuidNumber": "507113404620"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,308,141.28CR",
        "balanceIndicator": "3,308,141.28CR",
        "extendedNarrator": "IMPS-API/507113404602/Pranita bhushan shelke/SBIN0061706/0299/Utility",
        "uuidNumber": "507113404602"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,308,241.28CR",
        "balanceIndicator": "3,308,241.28CR",
        "extendedNarrator": "IMPS-API/507113404589/Gonthina Durga Prasad/SBIN0006832/6541/Utility",
        "uuidNumber": "507113404589"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,900.00DR",
        "amountIndicator": "DR",
        "balance": "3,313,041.28CR",
        "balanceIndicator": "3,313,041.28CR",
        "extendedNarrator": "IMPS-API/507113404556/surgyan singh meena/BARB0KOTRIX/4570/Utility",
        "uuidNumber": "507113404556"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,314,941.28CR",
        "balanceIndicator": "3,314,941.28CR",
        "extendedNarrator": "IMPS-API/507113404325/Umarbhai OSMANBHAI manek/SBIN0060082/8809/Utility",
        "uuidNumber": "507113404325"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,319,691.28CR",
        "balanceIndicator": "3,319,691.28CR",
        "extendedNarrator": "IMPS-API/507113404323/YUDHISHTHIR SAHU/PUNB0018810/0504/Utility",
        "uuidNumber": "507113404323"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,319,791.28CR",
        "balanceIndicator": "3,319,791.28CR",
        "extendedNarrator": "IMPS-API/507113404552/ISHA/SBIN0041139/2609/Utility",
        "uuidNumber": "507113404552"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,005.00DR",
        "amountIndicator": "DR",
        "balance": "3,320,741.28CR",
        "balanceIndicator": "3,320,741.28CR",
        "extendedNarrator": "IMPS-API/507113404042/ASARAT ALI/SBIN0012380/7603/Utility",
        "uuidNumber": "507113404042"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,322,746.28CR",
        "balanceIndicator": "3,322,746.28CR",
        "extendedNarrator": "IMPS-API/507113403952/RADHESHYAM KUMAR/AIRP0000001/6836/Utility",
        "uuidNumber": "507113403952"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,327,746.28CR",
        "balanceIndicator": "3,327,746.28CR",
        "extendedNarrator": "IMPS-API/507113403925/MOHIT MOHIT/PUNB0224500/1090/Utility",
        "uuidNumber": "507113403925"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,328,746.28CR",
        "balanceIndicator": "3,328,746.28CR",
        "extendedNarrator": "IMPS-API/507113403774/MR SURESH RANA/SBIN0000001/1217/Utility",
        "uuidNumber": "507113403774"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,330,246.28CR",
        "balanceIndicator": "3,330,246.28CR",
        "extendedNarrator": "IMPS-API/507113403508/SAVERA KHATUN/BKID0008400/0805/Utility",
        "uuidNumber": "507113403508"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,340,246.28CR",
        "balanceIndicator": "3,340,246.28CR",
        "extendedNarrator": "IMPS-API/507113403253/NAJBUN KHATUN/SBIN0003157/6799/Utility",
        "uuidNumber": "507113403253"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:46:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,341,196.28CR",
        "balanceIndicator": "3,341,196.28CR",
        "extendedNarrator": "IMPS-API/507113402949/Laljibhai veljibhai minat/JAKA0VASHEE/2289/Utility",
        "uuidNumber": "507113402949"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,345,996.28CR",
        "balanceIndicator": "3,345,996.28CR",
        "extendedNarrator": "IMPS-API/507113402772/MRS PUSPA TOPPO/SBIN0000001/2547/Utility",
        "uuidNumber": "507113402772"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,350,996.28CR",
        "balanceIndicator": "3,350,996.28CR",
        "extendedNarrator": "IMPS-API/507113402265/Mrs REKHA  DEVI/SBIN0009209/4702/Utility",
        "uuidNumber": "507113402265"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,360,996.28CR",
        "balanceIndicator": "3,360,996.28CR",
        "extendedNarrator": "IMPS-API/507113402629/MAHIMA KESHARI/UBIN0933708/8280/Utility",
        "uuidNumber": "507113402629"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:46",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,361,996.28CR",
        "balanceIndicator": "3,361,996.28CR",
        "extendedNarrator": "IMPS-API/507113402539/KESA MADHAN/UBIN0906603/4055/Utility",
        "uuidNumber": "507113402539"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,364,496.28CR",
        "balanceIndicator": "3,364,496.28CR",
        "extendedNarrator": "IMPS-API/507113401813/NIKHIL SHARMA/SBIN0001711/7971/Utility",
        "uuidNumber": "507113401813"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,371,496.28CR",
        "balanceIndicator": "3,371,496.28CR",
        "extendedNarrator": "IMPS-API/507113401466/Samiran pattanayak/UJVN0003502/0820/Utility",
        "uuidNumber": "507113401466"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,372,446.28CR",
        "balanceIndicator": "3,372,446.28CR",
        "extendedNarrator": "IMPS-API/507113401423/Manoj vasava/UTIB0000264/9323/Utility",
        "uuidNumber": "507113401423"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,372,731.28CR",
        "balanceIndicator": "3,372,731.28CR",
        "extendedNarrator": "IMPS-API/507113401425/Kochuthressia/SBIN0003285/0108/Utility",
        "uuidNumber": "507113401425"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,375,581.28CR",
        "balanceIndicator": "3,375,581.28CR",
        "extendedNarrator": "IMPS-API/507113401637/Pradeep Kumar Gupta/SBIN0014308/1913/Utility",
        "uuidNumber": "507113401637"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,376,056.28CR",
        "balanceIndicator": "3,376,056.28CR",
        "extendedNarrator": "IMPS-API/507113401401/Dharmendra/IDIB000C589/2835/Utility",
        "uuidNumber": "507113401401"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "21,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,377,006.28CR",
        "balanceIndicator": "3,377,006.28CR",
        "extendedNarrator": "IMPS-API/507113400682/ANSHU KOL/UBIN0551775/3250/Utility",
        "uuidNumber": "507113400682"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "740.00DR",
        "amountIndicator": "DR",
        "balance": "3,398,506.28CR",
        "balanceIndicator": "3,398,506.28CR",
        "extendedNarrator": "IMPS-API/507113400433/SRIMANTA PATASANI/SBIN0013569/9233/Utility",
        "uuidNumber": "507113400433"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,399,246.28CR",
        "balanceIndicator": "3,399,246.28CR",
        "extendedNarrator": "IMPS-API/507113400039/Rathod kalusinh/SBIN0000381/4449/Utility",
        "uuidNumber": "507113400039"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,399,346.28CR",
        "balanceIndicator": "3,399,346.28CR",
        "extendedNarrator": "IMPS-API/507113400128/Narayan Prasad/PUNB0317400/7856/Utility",
        "uuidNumber": "507113400128"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:45:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,399,446.28CR",
        "balanceIndicator": "3,399,446.28CR",
        "extendedNarrator": "IMPS-API/507113400130/State Bank of India/SBIN0013619/3924/Utility",
        "uuidNumber": "507113400130"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,399,546.28CR",
        "balanceIndicator": "3,399,546.28CR",
        "extendedNarrator": "IMPS-API/507113399679/UTTAM THAKUR/IPOS0000001/7178/Utility",
        "uuidNumber": "507113399679"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:54",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,400,046.28CR",
        "balanceIndicator": "3,400,046.28CR",
        "extendedNarrator": "IMPS-API/507113399635/SUDHIR KUMAR SINGH/SBIN0001610/5393/Utility",
        "uuidNumber": "507113399635"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,402,046.28CR",
        "balanceIndicator": "3,402,046.28CR",
        "extendedNarrator": "IMPS-API/507113399364/REKHA/ICIC0001704/1756/Utility",
        "uuidNumber": "507113399364"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,422,046.28CR",
        "balanceIndicator": "3,422,046.28CR",
        "extendedNarrator": "IMPS-API/507113399283/Narayan Prasad/PUNB0317400/7856/Utility",
        "uuidNumber": "507113399283"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,422,146.28CR",
        "balanceIndicator": "3,422,146.28CR",
        "extendedNarrator": "IMPS-API/507113399038/Rakesh Vaghela/AIRP0000001/8988/Utility",
        "uuidNumber": "507113399038"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,422,431.28CR",
        "balanceIndicator": "3,422,431.28CR",
        "extendedNarrator": "IMPS-API/507113399087/Vivek kumar/BKID0004683/3351/Utility",
        "uuidNumber": "507113399087"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,422,531.28CR",
        "balanceIndicator": "3,422,531.28CR",
        "extendedNarrator": "IMPS-API/507113399016/Sahid Ansari/AIRP0000001/1356/Utility",
        "uuidNumber": "507113399016"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,422,631.28CR",
        "balanceIndicator": "3,422,631.28CR",
        "extendedNarrator": "IMPS-API/507113398959/Bank of baroda/BARB0VJKHAJ/0993/Utility",
        "uuidNumber": "507113398959"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,422,916.28CR",
        "balanceIndicator": "3,422,916.28CR",
        "extendedNarrator": "IMPS-API/507113398776/REKHA/ICIC0001704/1756/Utility",
        "uuidNumber": "507113398776"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,442,916.28CR",
        "balanceIndicator": "3,442,916.28CR",
        "extendedNarrator": "IMPS-API/507113398633/ABHISHEK KUMAR GUPTA/KKBK0RTGSMI/6415/Utility",
        "uuidNumber": "507113398633"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,452,916.28CR",
        "balanceIndicator": "3,452,916.28CR",
        "extendedNarrator": "IMPS-API/507113398538/SHAMSHAD ALI/UBIN0534960/9562/Utility",
        "uuidNumber": "507113398538"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,001.00DR",
        "amountIndicator": "DR",
        "balance": "3,453,416.28CR",
        "balanceIndicator": "3,453,416.28CR",
        "extendedNarrator": "IMPS-API/507113398470/MILAN SADADIYA/SBIN0004864/0620/Utility",
        "uuidNumber": "507113398470"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,458,417.28CR",
        "balanceIndicator": "3,458,417.28CR",
        "extendedNarrator": "IMPS-API/507113398168/MEENA/BARB0TILDEL/6151/Utility",
        "uuidNumber": "507113398168"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,459,417.28CR",
        "balanceIndicator": "3,459,417.28CR",
        "extendedNarrator": "IMPS-API/507113398253/REKHA/ICIC0001704/1756/Utility",
        "uuidNumber": "507113398253"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:44:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,479,417.28CR",
        "balanceIndicator": "3,479,417.28CR",
        "extendedNarrator": "IMPS-API/507113398102/neeraj choudhary/IPOS0000001/3842/Utility",
        "uuidNumber": "507113398102"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:54",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,480,367.28CR",
        "balanceIndicator": "3,480,367.28CR",
        "extendedNarrator": "IMPS-API/507113397715/MRS BABITA DEVI/CBIN0281107/8635/Utility",
        "uuidNumber": "507113397715"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,490,367.28CR",
        "balanceIndicator": "3,490,367.28CR",
        "extendedNarrator": "IMPS-API/507113397638/REKHA/ICIC0001704/1756/Utility",
        "uuidNumber": "507113397638"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,510,367.28CR",
        "balanceIndicator": "3,510,367.28CR",
        "extendedNarrator": "IMPS-API/507113397427/AKLESH PASWAN/UTIB0000073/5364/Utility",
        "uuidNumber": "507113397427"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,513,367.28CR",
        "balanceIndicator": "3,513,367.28CR",
        "extendedNarrator": "IMPS-API/507113397171/CHAITANYA PATEL/KKBK0002849/4976/Utility",
        "uuidNumber": "507113397171"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,513,867.28CR",
        "balanceIndicator": "3,513,867.28CR",
        "extendedNarrator": "IMPS-API/507113396690/VikramBhai NATUBHAI/BKID0003211/0050/Utility",
        "uuidNumber": "507113396690"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,514,817.28CR",
        "balanceIndicator": "3,514,817.28CR",
        "extendedNarrator": "IMPS-API/507113397074/Gopi dave/BARB0VALUDA/6399/Utility",
        "uuidNumber": "507113397074"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,519,617.28CR",
        "balanceIndicator": "3,519,617.28CR",
        "extendedNarrator": "IMPS-API/507113397014/VikramBhai NATUBHAI/BKID0003211/0050/Utility",
        "uuidNumber": "507113397014"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,520,567.28CR",
        "balanceIndicator": "3,520,567.28CR",
        "extendedNarrator": "IMPS-API/507113397017/Sajid ali/SBIN0031847/5709/Utility",
        "uuidNumber": "507113397017"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,525,317.28CR",
        "balanceIndicator": "3,525,317.28CR",
        "extendedNarrator": "IMPS-API/507113396030/VIRENDERKUMAR/BARB0NAJDEL/9498/Utility",
        "uuidNumber": "507113396030"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,925.00DR",
        "amountIndicator": "DR",
        "balance": "3,535,317.28CR",
        "balanceIndicator": "3,535,317.28CR",
        "extendedNarrator": "IMPS-API/507113396013/Bihari Rajak/BARB0VRINDA/0325/Utility",
        "uuidNumber": "507113396013"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,538,242.28CR",
        "balanceIndicator": "3,538,242.28CR",
        "extendedNarrator": "IMPS-API/507113396120/Mr DILSHER  D/SBIN0009209/1731/Utility",
        "uuidNumber": "507113396120"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,542,742.28CR",
        "balanceIndicator": "3,542,742.28CR",
        "extendedNarrator": "IMPS-API/507113396113/ATUL YADAV/SBIN0003223/4472/Utility",
        "uuidNumber": "507113396113"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,547,742.28CR",
        "balanceIndicator": "3,547,742.28CR",
        "extendedNarrator": "IMPS-API/507113395976/Gopi dave/BARB0VALUDA/6399/Utility",
        "uuidNumber": "507113395976"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:43:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,552,542.28CR",
        "balanceIndicator": "3,552,542.28CR",
        "extendedNarrator": "IMPS-API/507113396111/AMIT KUMAR/CBIN0281406/6774/Utility",
        "uuidNumber": "507113396111"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,553,492.28CR",
        "balanceIndicator": "3,553,492.28CR",
        "extendedNarrator": "IMPS-API/507113395425/SHIV SAH/PUNB0063200/8078/Utility",
        "uuidNumber": "507113395425"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,600.00DR",
        "amountIndicator": "DR",
        "balance": "3,555,492.28CR",
        "balanceIndicator": "3,555,492.28CR",
        "extendedNarrator": "IMPS-API/507113395394/Sandip Yadav/FINO0000001/1557/Utility",
        "uuidNumber": "507113395394"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,559,092.28CR",
        "balanceIndicator": "3,559,092.28CR",
        "extendedNarrator": "IMPS-API/507113395212/Mr RAJIV  SAH/SBIN0009209/7323/Utility",
        "uuidNumber": "507113395212"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,563,092.28CR",
        "balanceIndicator": "3,563,092.28CR",
        "extendedNarrator": "IMPS-API/507113395115/Pushkar lal gurjar/SBIN0031226/0853/Utility",
        "uuidNumber": "507113395115"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,563,192.28CR",
        "balanceIndicator": "3,563,192.28CR",
        "extendedNarrator": "IMPS-API/507113394959/Paytm/SBIN0060111/4179/Utility",
        "uuidNumber": "507113394959"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,564,142.28CR",
        "balanceIndicator": "3,564,142.28CR",
        "extendedNarrator": "IMPS-API/507113394958/Prerna devi/SBIN0008748/2314/Utility",
        "uuidNumber": "507113394958"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,564,242.28CR",
        "balanceIndicator": "3,564,242.28CR",
        "extendedNarrator": "IMPS-API/507113394718/ALA UDDIN/KKBK0000662/2170/Utility",
        "uuidNumber": "507113394718"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,600.00DR",
        "amountIndicator": "DR",
        "balance": "3,570,242.28CR",
        "balanceIndicator": "3,570,242.28CR",
        "extendedNarrator": "IMPS-API/507113394659/RAVI KUMAR/SBIN0011845/4907/Utility",
        "uuidNumber": "507113394659"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,571,842.28CR",
        "balanceIndicator": "3,571,842.28CR",
        "extendedNarrator": "IMPS-API/507113394588/GANGADHAR CHOUBEY/SBIN0012539/7850/Utility",
        "uuidNumber": "507113394588"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "24,999.00DR",
        "amountIndicator": "DR",
        "balance": "3,572,842.28CR",
        "balanceIndicator": "3,572,842.28CR",
        "extendedNarrator": "IMPS-API/507113394545/MOTILAL TELECOM/AUBL0002220/3438/Utility",
        "uuidNumber": "507113394545"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "550.00DR",
        "amountIndicator": "DR",
        "balance": "3,597,841.28CR",
        "balanceIndicator": "3,597,841.28CR",
        "extendedNarrator": "IMPS-API/507113394506/KAKI VINOD/SBIN0000904/9929/Utility",
        "uuidNumber": "507113394506"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "512.00DR",
        "amountIndicator": "DR",
        "balance": "3,598,391.28CR",
        "balanceIndicator": "3,598,391.28CR",
        "extendedNarrator": "IMPS-API/507113394418/MAHAVEER MAHAVEER/UBIN0530611/5834/Utility",
        "uuidNumber": "507113394418"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,598,903.28CR",
        "balanceIndicator": "3,598,903.28CR",
        "extendedNarrator": "IMPS-API/507113394034/PUNAM DEVI/PUNB0063200/3938/Utility",
        "uuidNumber": "507113394034"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "8,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,601,903.28CR",
        "balanceIndicator": "3,601,903.28CR",
        "extendedNarrator": "IMPS-API/507113393972/IRFAN ANSARI/IDIB000S028/1203/Utility",
        "uuidNumber": "507113393972"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,609,903.28CR",
        "balanceIndicator": "3,609,903.28CR",
        "extendedNarrator": "IMPS-API/507113393961/SBI/SBIN0005365/4530/Utility",
        "uuidNumber": "507113393961"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,610,853.28CR",
        "balanceIndicator": "3,610,853.28CR",
        "extendedNarrator": "IMPS-API/507113393871/Ashok deka/SBIN0007038/2088/Utility",
        "uuidNumber": "507113393871"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,615,603.28CR",
        "balanceIndicator": "3,615,603.28CR",
        "extendedNarrator": "IMPS-API/507113393921/Ashish kumar/SBIN0000571/4930/Utility",
        "uuidNumber": "507113393921"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,615,703.28CR",
        "balanceIndicator": "3,615,703.28CR",
        "extendedNarrator": "IMPS-API/507113393920/Ambalal gurjar/BARB0DOONGL/6242/Utility",
        "uuidNumber": "507113393920"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:01",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,615,988.28CR",
        "balanceIndicator": "3,615,988.28CR",
        "extendedNarrator": "IMPS-API/507113393787/deepak/SBIN0009209/1934/Utility",
        "uuidNumber": "507113393787"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:42:00",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "3,640,988.28CR",
        "balanceIndicator": "3,640,988.28CR",
        "extendedNarrator": "IMPS-API/507113393769/MRS JAMEELA BEGAM/SBIN0000001/0269/Utility",
        "uuidNumber": "507113393769"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,641,989.28CR",
        "balanceIndicator": "3,641,989.28CR",
        "extendedNarrator": "IMPS-API/507113393495/MO TASLIM/BARB0TILDEL/7486/Utility",
        "uuidNumber": "507113393495"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,642,989.28CR",
        "balanceIndicator": "3,642,989.28CR",
        "extendedNarrator": "IMPS-API/507113393184/MRS SAMTOLA KUMARI/SBIN0000001/8444/Utility",
        "uuidNumber": "507113393184"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "600.00DR",
        "amountIndicator": "DR",
        "balance": "3,654,989.28CR",
        "balanceIndicator": "3,654,989.28CR",
        "extendedNarrator": "IMPS-API/507113393082/CHANDAN A/CNRB0000452/1892/Utility",
        "uuidNumber": "507113393082"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,655,589.28CR",
        "balanceIndicator": "3,655,589.28CR",
        "extendedNarrator": "IMPS-API/507113393128/SAALIM ZAHOOR/HDFC0001739/7243/Utility",
        "uuidNumber": "507113393128"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,661,589.28CR",
        "balanceIndicator": "3,661,589.28CR",
        "extendedNarrator": "IMPS-API/507113392999/Sheikh Amir/AIRP0000001/9794/Utility",
        "uuidNumber": "507113392999"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,661,689.28CR",
        "balanceIndicator": "3,661,689.28CR",
        "extendedNarrator": "IMPS-API/507113392998/Shanmathi/CNRB0001034/3991/Utility",
        "uuidNumber": "507113392998"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,662,164.28CR",
        "balanceIndicator": "3,662,164.28CR",
        "extendedNarrator": "IMPS-API/507113392948/Ashish kumar/SBIN0000571/4930/Utility",
        "uuidNumber": "507113392948"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,663,114.28CR",
        "balanceIndicator": "3,663,114.28CR",
        "extendedNarrator": "IMPS-API/507113392858/deva ram/IPOS0000001/2857/Utility",
        "uuidNumber": "507113392858"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:34",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,664,064.28CR",
        "balanceIndicator": "3,664,064.28CR",
        "extendedNarrator": "IMPS-API/507113392651/Sandip Yadav/FINO0000001/1557/Utility",
        "uuidNumber": "507113392651"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,669,064.28CR",
        "balanceIndicator": "3,669,064.28CR",
        "extendedNarrator": "IMPS-API/507113392404/AHIJUL HOQUE HOUQE/SBIN0007373/4907/Utility",
        "uuidNumber": "507113392404"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,670,064.28CR",
        "balanceIndicator": "3,670,064.28CR",
        "extendedNarrator": "IMPS-API/507113391929/Dinesh chand/YESB0000072/2649/Utility",
        "uuidNumber": "507113391929"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "12,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,674,864.28CR",
        "balanceIndicator": "3,674,864.28CR",
        "extendedNarrator": "IMPS-API/507113391698/RAVINDER/IDIB000N120/7137/Utility",
        "uuidNumber": "507113391698"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,686,864.28CR",
        "balanceIndicator": "3,686,864.28CR",
        "extendedNarrator": "IMPS-API/507113391758/CHALLA MADHUKAR/HDFC0CTGCUB/4197/Utility",
        "uuidNumber": "507113391758"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,687,814.28CR",
        "balanceIndicator": "3,687,814.28CR",
        "extendedNarrator": "IMPS-API/507113391672/Arvind kumar/SBIN0002342/7807/Utility",
        "uuidNumber": "507113391672"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,692,564.28CR",
        "balanceIndicator": "3,692,564.28CR",
        "extendedNarrator": "IMPS-API/507113391641/Baria dalpat/SBIN0011003/0350/Utility",
        "uuidNumber": "507113391641"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,693,039.28CR",
        "balanceIndicator": "3,693,039.28CR",
        "extendedNarrator": "IMPS-API/507113391640/Amit kumar/PUNB0077220/2745/Utility",
        "uuidNumber": "507113391640"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,693,324.28CR",
        "balanceIndicator": "3,693,324.28CR",
        "extendedNarrator": "IMPS-API/507113391601/babulu padhan/UBIN0547778/7425/Utility",
        "uuidNumber": "507113391601"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,693,424.28CR",
        "balanceIndicator": "3,693,424.28CR",
        "extendedNarrator": "IMPS-API/507113391352/sunilkumar/IBKL0001982/7619/Utility",
        "uuidNumber": "507113391352"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:41:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,693,924.28CR",
        "balanceIndicator": "3,693,924.28CR",
        "extendedNarrator": "IMPS-API/507113391559/Axis/UTIB0001199/9159/Utility",
        "uuidNumber": "507113391559"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,694,399.28CR",
        "balanceIndicator": "3,694,399.28CR",
        "extendedNarrator": "IMPS-API/507113390993/MURUGAN K MURUGAN K/SBIN0008464/4083/Utility",
        "uuidNumber": "507113390993"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "26,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,696,899.28CR",
        "balanceIndicator": "3,696,899.28CR",
        "extendedNarrator": "IMPS-API/507113390505/CHIRAG/KKBK0000958/3394/Utility",
        "uuidNumber": "507113390505"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,722,899.28CR",
        "balanceIndicator": "3,722,899.28CR",
        "extendedNarrator": "IMPS-API/507113390481/ANKIT SHETTY/HDFC0000411/8252/Utility",
        "uuidNumber": "507113390481"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,726,899.28CR",
        "balanceIndicator": "3,726,899.28CR",
        "extendedNarrator": "IMPS-API/507113390406/RAKESH GOWDA/CNRB0004788/3539/Utility",
        "uuidNumber": "507113390406"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:44",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,729,399.28CR",
        "balanceIndicator": "3,729,399.28CR",
        "extendedNarrator": "IMPS-API/507113390585/PALAVALASA KUMAR/KKBK0007475/6988/Utility",
        "uuidNumber": "507113390585"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,730,399.28CR",
        "balanceIndicator": "3,730,399.28CR",
        "extendedNarrator": "IMPS-API/507113389986/Kanha prajapati/PUNB0497300/7200/Utility",
        "uuidNumber": "507113389986"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "480.00DR",
        "amountIndicator": "DR",
        "balance": "3,733,249.28CR",
        "balanceIndicator": "3,733,249.28CR",
        "extendedNarrator": "IMPS-API/507113389947/Kantharaju/CNRB0003196/4028/Utility",
        "uuidNumber": "507113389947"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,733,729.28CR",
        "balanceIndicator": "3,733,729.28CR",
        "extendedNarrator": "IMPS-API/507113390118/SANJAY KUMAR/SBIN0032267/3428/Utility",
        "uuidNumber": "507113390118"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,734,679.28CR",
        "balanceIndicator": "3,734,679.28CR",
        "extendedNarrator": "IMPS-API/507113389896/Tuntun kumar/KKBK0004364/3079/Utility",
        "uuidNumber": "507113389896"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,400.00DR",
        "amountIndicator": "DR",
        "balance": "3,739,429.28CR",
        "balanceIndicator": "3,739,429.28CR",
        "extendedNarrator": "IMPS-API/507113389829/Nimesh chanderkant N/SBIN0000001/3906/Utility",
        "uuidNumber": "507113389829"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,743,829.28CR",
        "balanceIndicator": "3,743,829.28CR",
        "extendedNarrator": "IMPS-API/507113389637/PALJEET SINGH/UTIB0000248/1642/Utility",
        "uuidNumber": "507113389637"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,748,829.28CR",
        "balanceIndicator": "3,748,829.28CR",
        "extendedNarrator": "IMPS-API/507113389327/URMILA DEVI/BARB0TILDEL/0528/Utility",
        "uuidNumber": "507113389327"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "700.00DR",
        "amountIndicator": "DR",
        "balance": "3,751,829.28CR",
        "balanceIndicator": "3,751,829.28CR",
        "extendedNarrator": "IMPS-API/507113388696/LADDI DHILLON/UTIB0000834/5590/Utility",
        "uuidNumber": "507113388696"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,752,529.28CR",
        "balanceIndicator": "3,752,529.28CR",
        "extendedNarrator": "IMPS-API/507113388630/RAVI/KKBK0000958/2299/Utility",
        "uuidNumber": "507113388630"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,756,529.28CR",
        "balanceIndicator": "3,756,529.28CR",
        "extendedNarrator": "IMPS-API/507113388491/Omkar/CBIN0281240/7643/Utility",
        "uuidNumber": "507113388491"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,756,814.28CR",
        "balanceIndicator": "3,756,814.28CR",
        "extendedNarrator": "IMPS-API/507113388571/TUNTUN DAS/IPOS0000001/4825/Utility",
        "uuidNumber": "507113388571"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "839.72DR",
        "amountIndicator": "DR",
        "balance": "3,757,314.28CR",
        "balanceIndicator": "3,757,314.28CR",
        "extendedNarrator": "IMPS-API/507113388566/PABITRA SARDAR/BDBL0001015/9632/Utility",
        "uuidNumber": "507113388566"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,758,154.00CR",
        "balanceIndicator": "3,758,154.00CR",
        "extendedNarrator": "IMPS-API/507113388562/JIMMY/CNRB0005438/0581/Utility",
        "uuidNumber": "507113388562"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,758,629.00CR",
        "balanceIndicator": "3,758,629.00CR",
        "extendedNarrator": "IMPS-API/507113388426/Tuntun kumar/KKBK0004364/3079/Utility",
        "uuidNumber": "507113388426"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,763,379.00CR",
        "balanceIndicator": "3,763,379.00CR",
        "extendedNarrator": "IMPS-API/507113388418/Sunil Kumar sharma/UBIN0556815/7010/Utility",
        "uuidNumber": "507113388418"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:40:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,764,329.00CR",
        "balanceIndicator": "3,764,329.00CR",
        "extendedNarrator": "IMPS-API/507113388346/DESHINI VISHAL/SBIN0020155/0963/Utility",
        "uuidNumber": "507113388346"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,604.00DR",
        "amountIndicator": "DR",
        "balance": "3,769,329.00CR",
        "balanceIndicator": "3,769,329.00CR",
        "extendedNarrator": "IMPS-API/507113388219/ATIKUL LASKAR/BKID0004370/1532/Utility",
        "uuidNumber": "507113388219"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:56",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "502.00DR",
        "amountIndicator": "DR",
        "balance": "3,770,933.00CR",
        "balanceIndicator": "3,770,933.00CR",
        "extendedNarrator": "IMPS-API/507113387957/MOHIT SHAMRA/SBIN0050563/7852/Utility",
        "uuidNumber": "507113387957"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "800.00DR",
        "amountIndicator": "DR",
        "balance": "3,771,435.00CR",
        "balanceIndicator": "3,771,435.00CR",
        "extendedNarrator": "IMPS-API/507113388073/SONI BRIJESH/SBIN0013414/5169/Utility",
        "uuidNumber": "507113388073"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,980.00DR",
        "amountIndicator": "DR",
        "balance": "3,772,235.00CR",
        "balanceIndicator": "3,772,235.00CR",
        "extendedNarrator": "IMPS-API/507113387666/MRS MUSMAT TABSUM BEGAM/SBIN0000001/0512/Utility",
        "uuidNumber": "507113387666"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,999.00DR",
        "amountIndicator": "DR",
        "balance": "3,774,215.00CR",
        "balanceIndicator": "3,774,215.00CR",
        "extendedNarrator": "IMPS-API/507113387275/RAM DEVI WO DEVICHARAN/PUNB0244200/9730/Utility",
        "uuidNumber": "507113387275"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,779,214.00CR",
        "balanceIndicator": "3,779,214.00CR",
        "extendedNarrator": "IMPS-API/507113387384/POONAM RAJBHAR WO VIRENDAR RAJBHAR/UBIN0551775/0672/Utility",
        "uuidNumber": "507113387384"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:30",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,780,714.00CR",
        "balanceIndicator": "3,780,714.00CR",
        "extendedNarrator": "IMPS-API/507113387110/PRATEEMA/IDIB000S028/8857/Utility",
        "uuidNumber": "507113387110"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,782,714.00CR",
        "balanceIndicator": "3,782,714.00CR",
        "extendedNarrator": "IMPS-API/507113387073/Mrs BEJANTI  /CBIN0280658/4620/Utility",
        "uuidNumber": "507113387073"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,400.00DR",
        "amountIndicator": "DR",
        "balance": "3,787,714.00CR",
        "balanceIndicator": "3,787,714.00CR",
        "extendedNarrator": "IMPS-API/507113386673/SUSHIL KUMAR/SBIN0031667/4096/Utility",
        "uuidNumber": "507113386673"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "550.00DR",
        "amountIndicator": "DR",
        "balance": "3,793,114.00CR",
        "balanceIndicator": "3,793,114.00CR",
        "extendedNarrator": "IMPS-API/507113386596/NAGA HRITHIKESH CHAKRAVARAM/SBIN0070743/0272/Utility",
        "uuidNumber": "507113386596"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,793,664.00CR",
        "balanceIndicator": "3,793,664.00CR",
        "extendedNarrator": "IMPS-API/507113386537/DURGESH DURGESH/SBIN0060205/1573/Utility",
        "uuidNumber": "507113386537"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "700.00DR",
        "amountIndicator": "DR",
        "balance": "3,799,664.00CR",
        "balanceIndicator": "3,799,664.00CR",
        "extendedNarrator": "IMPS-API/507113386488/FIROJKHA PATHAN/MAHG0004336/4113/Utility",
        "uuidNumber": "507113386488"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:39:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "839.72DR",
        "amountIndicator": "DR",
        "balance": "3,800,364.00CR",
        "balanceIndicator": "3,800,364.00CR",
        "extendedNarrator": "IMPS-API/507113386398/Abhishek arya/CNRB0005596/1240/Utility",
        "uuidNumber": "507113386398"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,801,203.72CR",
        "balanceIndicator": "3,801,203.72CR",
        "extendedNarrator": "IMPS-API/507113385417/MD NAJAM SHEKH/HDFC0001443/1032/Utility",
        "uuidNumber": "507113385417"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,814,203.72CR",
        "balanceIndicator": "3,814,203.72CR",
        "extendedNarrator": "IMPS-API/507113385131/VIRENDERKUMAR/BARB0NAJDEL/9498/Utility",
        "uuidNumber": "507113385131"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,530.00DR",
        "amountIndicator": "DR",
        "balance": "3,827,203.72CR",
        "balanceIndicator": "3,827,203.72CR",
        "extendedNarrator": "IMPS-API/507113385372/AJAY KUMAR/HDFC0001443/9290/Utility",
        "uuidNumber": "507113385372"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,829,733.72CR",
        "balanceIndicator": "3,829,733.72CR",
        "extendedNarrator": "IMPS-API/507113384969/SHEETAL NONE/KKBK0005026/7587/Utility",
        "uuidNumber": "507113384969"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,300.00DR",
        "amountIndicator": "DR",
        "balance": "3,830,233.72CR",
        "balanceIndicator": "3,830,233.72CR",
        "extendedNarrator": "IMPS-API/507113384807/VENKATA GANESH PUTHSALA/SBIN0016345/0497/Utility",
        "uuidNumber": "507113384807"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,831,533.72CR",
        "balanceIndicator": "3,831,533.72CR",
        "extendedNarrator": "IMPS-API/507113384520/ANKIT KUMAR/CBIN0280299/1541/Utility",
        "uuidNumber": "507113384520"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "510.00DR",
        "amountIndicator": "DR",
        "balance": "3,832,533.72CR",
        "balanceIndicator": "3,832,533.72CR",
        "extendedNarrator": "IMPS-API/507113384409/ATUL SINGH/PUNB0195610/1093/Utility",
        "uuidNumber": "507113384409"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:38:19",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "19,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,833,043.72CR",
        "balanceIndicator": "3,833,043.72CR",
        "extendedNarrator": "IMPS-API/507113384271/RAJKUMARI INDRANISANA/SBIN0007440/8955/Utility",
        "uuidNumber": "507113384271"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:37:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,852,043.72CR",
        "balanceIndicator": "3,852,043.72CR",
        "extendedNarrator": "IMPS-API/507113382937/RISHABH THAKUR/KKBK0005133/6939/Utility",
        "uuidNumber": "507113382937"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:37:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,852,543.72CR",
        "balanceIndicator": "3,852,543.72CR",
        "extendedNarrator": "IMPS-API/507113382753/Irfan/BARB0TILDEL/6473/Utility",
        "uuidNumber": "507113382753"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:37:34",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,859,543.72CR",
        "balanceIndicator": "3,859,543.72CR",
        "extendedNarrator": "IMPS-API/507113382718/REWATI DEVI/FINO0000001/0098/Utility",
        "uuidNumber": "507113382718"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:37:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,862,543.72CR",
        "balanceIndicator": "3,862,543.72CR",
        "extendedNarrator": "IMPS-API/507113382450/RAHUL SEN/AIRP0000001/0316/Utility",
        "uuidNumber": "507113382450"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:37:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,863,543.72CR",
        "balanceIndicator": "3,863,543.72CR",
        "extendedNarrator": "IMPS-API/507113382366/AMIT KUMAR/BARB0NEHRUP/1190/Utility",
        "uuidNumber": "507113382366"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:52",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,870,543.72CR",
        "balanceIndicator": "3,870,543.72CR",
        "extendedNarrator": "IMPS-API/507113381249/RAHUL SEN/AIRP0000001/5509/Utility",
        "uuidNumber": "507113381249"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,638.00DR",
        "amountIndicator": "DR",
        "balance": "3,872,043.72CR",
        "balanceIndicator": "3,872,043.72CR",
        "extendedNarrator": "IMPS-API/507113381158/RAHUL PALAS/SBIN0060297/8153/Utility",
        "uuidNumber": "507113381158"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,875,681.72CR",
        "balanceIndicator": "3,875,681.72CR",
        "extendedNarrator": "IMPS-API/507113381047/REKHA/CNRB0004195/7384/Utility",
        "uuidNumber": "507113381047"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,880,481.72CR",
        "balanceIndicator": "3,880,481.72CR",
        "extendedNarrator": "IMPS-API/507113381038/GOGULA RAMADEVI/IPOS0000001/8046/Utility",
        "uuidNumber": "507113381038"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "300.00DR",
        "amountIndicator": "DR",
        "balance": "3,883,481.72CR",
        "balanceIndicator": "3,883,481.72CR",
        "extendedNarrator": "IMPS-API/507113380381/Kusama Lodhi/FINO0001446/0394/Utility",
        "uuidNumber": "507113380381"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,883,781.72CR",
        "balanceIndicator": "3,883,781.72CR",
        "extendedNarrator": "IMPS-API/507113380379/Sneha Pathak/INDB0001410/2420/Utility",
        "uuidNumber": "507113380379"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,151.00DR",
        "amountIndicator": "DR",
        "balance": "3,893,781.72CR",
        "balanceIndicator": "3,893,781.72CR",
        "extendedNarrator": "IMPS-API/507113379045/SAJITH SAHADEVAN/FDRL0001750/1709/Utility",
        "uuidNumber": "507113379045"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:36:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,894,932.72CR",
        "balanceIndicator": "3,894,932.72CR",
        "extendedNarrator": "IMPS-API/507113378969/BHUSHAN PEKHALE/SBIN0061476/8261/Utility",
        "uuidNumber": "507113378969"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,896,932.72CR",
        "balanceIndicator": "3,896,932.72CR",
        "extendedNarrator": "IMPS-API/507113377248/REENA/IPOS0000001/4776/Utility",
        "uuidNumber": "507113377248"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,501.00DR",
        "amountIndicator": "DR",
        "balance": "3,901,932.72CR",
        "balanceIndicator": "3,901,932.72CR",
        "extendedNarrator": "IMPS-API/507113377239/JITENDRA KUMAR SO TULSI RAM/BARB0BUPGBX/9188/Utility",
        "uuidNumber": "507113377239"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,905,433.72CR",
        "balanceIndicator": "3,905,433.72CR",
        "extendedNarrator": "IMPS-API/507113377135/PUNAM/UBIN0551775/5058/Utility",
        "uuidNumber": "507113377135"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,360.00DR",
        "amountIndicator": "DR",
        "balance": "3,906,433.72CR",
        "balanceIndicator": "3,906,433.72CR",
        "extendedNarrator": "IMPS-API/507113377085/TUSAR KANTA DASH/SBIN0010127/8450/Utility",
        "uuidNumber": "507113377085"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:22",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,909,793.72CR",
        "balanceIndicator": "3,909,793.72CR",
        "extendedNarrator": "IMPS-API/507113376133/MAYA DEVI/BARB0NEHRUP/6972/Utility",
        "uuidNumber": "507113376133"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "22,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,916,793.72CR",
        "balanceIndicator": "3,916,793.72CR",
        "extendedNarrator": "IMPS-API/507113375445/upendrkumar/BKID0000150/8139/Utility",
        "uuidNumber": "507113375445"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,938,793.72CR",
        "balanceIndicator": "3,938,793.72CR",
        "extendedNarrator": "IMPS-API/507113375373/Naim  /FINO0009003/4048/Utility",
        "uuidNumber": "507113375373"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:35:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,958,793.72CR",
        "balanceIndicator": "3,958,793.72CR",
        "extendedNarrator": "IMPS-API/507113375368/Durgesh/HDFC0002516/0477/Utility",
        "uuidNumber": "507113375368"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:58",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "700.00DR",
        "amountIndicator": "DR",
        "balance": "3,959,793.72CR",
        "balanceIndicator": "3,959,793.72CR",
        "extendedNarrator": "IMPS-API/507113375168/PARTH PATEL/KKBK0003032/1314/Utility",
        "uuidNumber": "507113375168"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:54",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,960,493.72CR",
        "balanceIndicator": "3,960,493.72CR",
        "extendedNarrator": "IMPS-API/507113375075/SANDEEP/KKBK0RTGSMI/3118/Utility",
        "uuidNumber": "507113375075"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,965,493.72CR",
        "balanceIndicator": "3,965,493.72CR",
        "extendedNarrator": "IMPS-API/507113374618/VIRENDERKUMAR/BARB0NAJDEL/9498/Utility",
        "uuidNumber": "507113374618"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:30",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,975,493.72CR",
        "balanceIndicator": "3,975,493.72CR",
        "extendedNarrator": "IMPS-API/507113373965/KOSIDA KHATOON/BKID0008400/5202/Utility",
        "uuidNumber": "507113373965"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,976,993.72CR",
        "balanceIndicator": "3,976,993.72CR",
        "extendedNarrator": "IMPS-API/507113373691/Mrs PUNAM  DEVI/SBIN0009209/8493/Utility",
        "uuidNumber": "507113373691"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,986,993.72CR",
        "balanceIndicator": "3,986,993.72CR",
        "extendedNarrator": "IMPS-API/507113373614/RAJNI/IPOS0000001/1306/Utility",
        "uuidNumber": "507113373614"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,992,993.72CR",
        "balanceIndicator": "3,992,993.72CR",
        "extendedNarrator": "IMPS-API/507113373047/ABDUL KARIM/PUNB0159420/5900/Utility",
        "uuidNumber": "507113373047"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,994,493.72CR",
        "balanceIndicator": "3,994,493.72CR",
        "extendedNarrator": "IMPS-API/507113373278/VIKAS VIKAS/SBIN0011982/0191/Utility",
        "uuidNumber": "507113373278"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,100.00DR",
        "amountIndicator": "DR",
        "balance": "4,001,493.72CR",
        "balanceIndicator": "4,001,493.72CR",
        "extendedNarrator": "IMPS-API/507113372811/Sruti Dad/FINO0001157/3458/Utility",
        "uuidNumber": "507113372811"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:34:00",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "23,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,002,593.72CR",
        "balanceIndicator": "4,002,593.72CR",
        "extendedNarrator": "IMPS-API/507113372503/upendrkumar/BKID0000150/8139/Utility",
        "uuidNumber": "507113372503"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:33:54",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,100.00DR",
        "amountIndicator": "DR",
        "balance": "4,025,593.72CR",
        "balanceIndicator": "4,025,593.72CR",
        "extendedNarrator": "IMPS-API/507113372257/OMKAR/IPOS0000001/4341/Utility",
        "uuidNumber": "507113372257"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:33:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "14,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,026,693.72CR",
        "balanceIndicator": "4,026,693.72CR",
        "extendedNarrator": "IMPS-API/507113371949/MALEHAR BEGAM/BARB0NEHRUP/9808/Utility",
        "uuidNumber": "507113371949"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:33:45",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "19,900.00DR",
        "amountIndicator": "DR",
        "balance": "4,040,693.72CR",
        "balanceIndicator": "4,040,693.72CR",
        "extendedNarrator": "IMPS-API/507113371796/ND IDOL AGENCIES/YESB0000165/1448/Utility",
        "uuidNumber": "507113371796"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:33:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "505.00DR",
        "amountIndicator": "DR",
        "balance": "4,060,593.72CR",
        "balanceIndicator": "4,060,593.72CR",
        "extendedNarrator": "IMPS-API/507113371098/ABDUL KHURSHID KHAN/SBIN0032248/2616/Utility",
        "uuidNumber": "507113371098"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:33:33",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "11,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,061,098.72CR",
        "balanceIndicator": "4,061,098.72CR",
        "extendedNarrator": "IMPS-API/507113371070/SANJAY SINGH/SBIN0006219/7805/Utility",
        "uuidNumber": "507113371070"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:33:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "502.00DR",
        "amountIndicator": "DR",
        "balance": "4,072,098.72CR",
        "balanceIndicator": "4,072,098.72CR",
        "extendedNarrator": "IMPS-API/507113371268/MINTU DHANIA/SBIN0016255/7484/Utility",
        "uuidNumber": "507113371268"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:33:04",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,072,600.72CR",
        "balanceIndicator": "4,072,600.72CR",
        "extendedNarrator": "IMPS-API/507113370173/Nimesh chanderkant N/SBIN0000001/3906/Utility",
        "uuidNumber": "507113370173"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,097,600.72CR",
        "balanceIndicator": "4,097,600.72CR",
        "extendedNarrator": "IMPS-API/507113369781/GOWTHAM GUNASEKAR/FDRL0001233/7462/Utility",
        "uuidNumber": "507113369781"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "600.00DR",
        "amountIndicator": "DR",
        "balance": "4,098,600.72CR",
        "balanceIndicator": "4,098,600.72CR",
        "extendedNarrator": "IMPS-API/507113369722/AKASH NAIK/SBIN0004427/8567/Utility",
        "uuidNumber": "507113369722"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,099,200.72CR",
        "balanceIndicator": "4,099,200.72CR",
        "extendedNarrator": "IMPS-API/507113369400/JYOTI JYOTI/SBIN0000649/5365/Utility",
        "uuidNumber": "507113369400"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:46",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,104,200.72CR",
        "balanceIndicator": "4,104,200.72CR",
        "extendedNarrator": "IMPS-API/507113369371/SUMITRA KUMARI/BKID0000150/2193/Utility",
        "uuidNumber": "507113369371"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:45",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,114,200.72CR",
        "balanceIndicator": "4,114,200.72CR",
        "extendedNarrator": "IMPS-API/507113369352/SAALIM ZAHOOR/HDFC0001739/7243/Utility",
        "uuidNumber": "507113369352"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,121,200.72CR",
        "balanceIndicator": "4,121,200.72CR",
        "extendedNarrator": "IMPS-API/507113369292/SANGEETA/IPOS0000001/7471/Utility",
        "uuidNumber": "507113369292"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,130,200.72CR",
        "balanceIndicator": "4,130,200.72CR",
        "extendedNarrator": "IMPS-API/507113369188/SANDEEP/KKBK0RTGSMI/3118/Utility",
        "uuidNumber": "507113369188"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,135,200.72CR",
        "balanceIndicator": "4,135,200.72CR",
        "extendedNarrator": "IMPS-API/507113368799/CHHANNU SINGH/IOBA0000002/1660/Utility",
        "uuidNumber": "507113368799"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,145,200.72CR",
        "balanceIndicator": "4,145,200.72CR",
        "extendedNarrator": "IMPS-API/507113368300/FAISAL MOHAMMAD/PUNB0556000/2769/Utility",
        "uuidNumber": "507113368300"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,011.00DR",
        "amountIndicator": "DR",
        "balance": "4,151,200.72CR",
        "balanceIndicator": "4,151,200.72CR",
        "extendedNarrator": "IMPS-API/507113368035/ROHAN SONAVANE/SBIN0006249/2816/Utility",
        "uuidNumber": "507113368035"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:32:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,171,211.72CR",
        "balanceIndicator": "4,171,211.72CR",
        "extendedNarrator": "IMPS-API/507113368212/AVINASH NIKAM/HDFC0001445/4410/Utility",
        "uuidNumber": "507113368212"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:55",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,173,211.72CR",
        "balanceIndicator": "4,173,211.72CR",
        "extendedNarrator": "IMPS-API/507113367857/juti/CBIN0281467/6440/Utility",
        "uuidNumber": "507113367857"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,175,211.72CR",
        "balanceIndicator": "4,175,211.72CR",
        "extendedNarrator": "IMPS-API/507113367638/MR RAVI SHANKAR KUMAR/SBIN0000001/8960/Utility",
        "uuidNumber": "507113367638"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:45",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,180,211.72CR",
        "balanceIndicator": "4,180,211.72CR",
        "extendedNarrator": "IMPS-API/507113367477/SAJID/BARB0NEHRUP/1460/Utility",
        "uuidNumber": "507113367477"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:44",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "4,185,211.72CR",
        "balanceIndicator": "4,185,211.72CR",
        "extendedNarrator": "IMPS-API/507113367377/CHANDRAWATI WO BAL KISHUN/BARB0BUPGBX/4159/Utility",
        "uuidNumber": "507113367377"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,186,711.72CR",
        "balanceIndicator": "4,186,711.72CR",
        "extendedNarrator": "IMPS-API/507113367047/juli/CBIN0281467/1144/Utility",
        "uuidNumber": "507113367047"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "4,189,711.72CR",
        "balanceIndicator": "4,189,711.72CR",
        "extendedNarrator": "IMPS-API/507113367101/TAN JIDA/BKID0008400/1073/Utility",
        "uuidNumber": "507113367101"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,193.00DR",
        "amountIndicator": "DR",
        "balance": "4,190,712.72CR",
        "balanceIndicator": "4,190,712.72CR",
        "extendedNarrator": "IMPS-API/507113366882/LAKHAN JAT/AUBL0002323/0130/Utility",
        "uuidNumber": "507113366882"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,200,905.72CR",
        "balanceIndicator": "4,200,905.72CR",
        "extendedNarrator": "IMPS-API/507113366556/RAM VISHAL SO RAGHUVEER/BKID0ARYAGB/5651/Utility",
        "uuidNumber": "507113366556"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "4,202,905.72CR",
        "balanceIndicator": "4,202,905.72CR",
        "extendedNarrator": "IMPS-API/507113366416/KURBAAN SHARAWAT/HDFC0999999/4351/Utility",
        "uuidNumber": "507113366416"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "4,207,655.72CR",
        "balanceIndicator": "4,207,655.72CR",
        "extendedNarrator": "IMPS-API/507113366283/Nagaraju/SBIN0040386/9828/Utility",
        "uuidNumber": "507113366283"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,208,130.72CR",
        "balanceIndicator": "4,208,130.72CR",
        "extendedNarrator": "IMPS-API/507113366280/malivad ashok kumar dakshu bhai/SBIN0009478/2592/Utility",
        "uuidNumber": "507113366280"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,208,415.72CR",
        "balanceIndicator": "4,208,415.72CR",
        "extendedNarrator": "IMPS-API/507113366142/MANJU DEVI/BARB0BUPGBX/8569/Utility",
        "uuidNumber": "507113366142"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:31:01",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "15,500.00DR",
        "amountIndicator": "DR",
        "balance": "4,213,415.72CR",
        "balanceIndicator": "4,213,415.72CR",
        "extendedNarrator": "IMPS-API/507113366077/Mr LATIFUR  RAHAMAN/SBIN0009209/2807/Utility",
        "uuidNumber": "507113366077"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "4,228,915.72CR",
        "balanceIndicator": "4,228,915.72CR",
        "extendedNarrator": "IMPS-API/507113365500/BIKASH JENA/SBIN0013585/6583/Utility",
        "uuidNumber": "507113365500"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,229,415.72CR",
        "balanceIndicator": "4,229,415.72CR",
        "extendedNarrator": "IMPS-API/507113365375/KURBAAN SHARAWAT/HDFC0999999/4351/Utility",
        "uuidNumber": "507113365375"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:45",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,234,415.72CR",
        "balanceIndicator": "4,234,415.72CR",
        "extendedNarrator": "IMPS-API/507113365177/JAGDISH NADAR/CIUB0000517/5154/Utility",
        "uuidNumber": "507113365177"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,243,415.72CR",
        "balanceIndicator": "4,243,415.72CR",
        "extendedNarrator": "IMPS-API/507113364934/ram anuj/FINO0009003/9231/Utility",
        "uuidNumber": "507113364934"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "209.00DR",
        "amountIndicator": "DR",
        "balance": "4,249,415.72CR",
        "balanceIndicator": "4,249,415.72CR",
        "extendedNarrator": "IMPS-API/507113365000/babyy/KKBK0005911/8111/Utility",
        "uuidNumber": "507113365000"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,249,624.72CR",
        "balanceIndicator": "4,249,624.72CR",
        "extendedNarrator": "IMPS-API/507113364689/KURBAAN SHARAWAT/HDFC0999999/4351/Utility",
        "uuidNumber": "507113364689"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,501.00DR",
        "amountIndicator": "DR",
        "balance": "4,254,624.72CR",
        "balanceIndicator": "4,254,624.72CR",
        "extendedNarrator": "IMPS-API/507113364015/ROHIT KUMAR/UTIB0000248/7384/Utility",
        "uuidNumber": "507113364015"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,800.00DR",
        "amountIndicator": "DR",
        "balance": "4,258,125.72CR",
        "balanceIndicator": "4,258,125.72CR",
        "extendedNarrator": "IMPS-API/507113363924/ANILBHAI BHUNJADA/NSPB0000002/9563/Utility",
        "uuidNumber": "507113363924"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:04",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,259,925.72CR",
        "balanceIndicator": "4,259,925.72CR",
        "extendedNarrator": "IMPS-API/507113363594/KURBAAN SHARAWAT/HDFC0999999/4351/Utility",
        "uuidNumber": "507113363594"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:30:00",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,264,925.72CR",
        "balanceIndicator": "4,264,925.72CR",
        "extendedNarrator": "IMPS-API/507113363460/KAPIL DEV/PUNB0244200/2001/Utility",
        "uuidNumber": "507113363460"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:29:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,500.00DR",
        "amountIndicator": "DR",
        "balance": "4,268,925.72CR",
        "balanceIndicator": "4,268,925.72CR",
        "extendedNarrator": "IMPS-API/507113363370/GOVIND GOVIND/BKID0008841/3875/Utility",
        "uuidNumber": "507113363370"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:29:54",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "4,282,425.72CR",
        "balanceIndicator": "4,282,425.72CR",
        "extendedNarrator": "IMPS-API/507113363088/BANDARU SAI/FDRL0007777/9403/Utility",
        "uuidNumber": "507113363088"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:29:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,282,925.72CR",
        "balanceIndicator": "4,282,925.72CR",
        "extendedNarrator": "IMPS-API/507113362766/KURBAAN SHARAWAT/HDFC0999999/4351/Utility",
        "uuidNumber": "507113362766"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:29:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,287,925.72CR",
        "balanceIndicator": "4,287,925.72CR",
        "extendedNarrator": "IMPS-API/507113362116/SANTHI/BARB0SURIBS/4608/Utility",
        "uuidNumber": "507113362116"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:29:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,293,925.72CR",
        "balanceIndicator": "4,293,925.72CR",
        "extendedNarrator": "IMPS-API/507113361563/MARFAT SEKH/PUNB0244200/5653/Utility",
        "uuidNumber": "507113361563"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:29:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "105.00DR",
        "amountIndicator": "DR",
        "balance": "4,302,925.72CR",
        "balanceIndicator": "4,302,925.72CR",
        "extendedNarrator": "IMPS-API/507113361264/sachin sharma/JIOP0000001/5776/Utility",
        "uuidNumber": "507113361264"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:28:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,303,030.72CR",
        "balanceIndicator": "4,303,030.72CR",
        "extendedNarrator": "IMPS-API/507113360113/MR MURTAJA ANSARI/SBIN0000001/5898/Utility",
        "uuidNumber": "507113360113"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:28:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,328,030.72CR",
        "balanceIndicator": "4,328,030.72CR",
        "extendedNarrator": "IMPS-API/507113359648/SADDAM/BARB0BUPGBX/1635/Utility",
        "uuidNumber": "507113359648"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:27:56",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,338,030.72CR",
        "balanceIndicator": "4,338,030.72CR",
        "extendedNarrator": "IMPS-API/507113358350/MRS GANGA DEVI/SBIN0000001/5696/Utility",
        "uuidNumber": "507113358350"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:27:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,343,030.72CR",
        "balanceIndicator": "4,343,030.72CR",
        "extendedNarrator": "IMPS-API/507113358055/MR MURTAJA ANSARI/SBIN0000001/5898/Utility",
        "uuidNumber": "507113358055"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:27:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "839.72DR",
        "amountIndicator": "DR",
        "balance": "4,348,030.72CR",
        "balanceIndicator": "4,348,030.72CR",
        "extendedNarrator": "IMPS-API/507113357472/Bandhan Bank limited/BDBL0001826/8807/Utility",
        "uuidNumber": "507113357472"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:27:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,348,870.44CR",
        "balanceIndicator": "4,348,870.44CR",
        "extendedNarrator": "IMPS-API/507113356971/MRS GANGA DEVI/SBIN0000001/5696/Utility",
        "uuidNumber": "507113356971"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:27:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,353,870.44CR",
        "balanceIndicator": "4,353,870.44CR",
        "extendedNarrator": "IMPS-API/507113356473/MR MURTAJA ANSARI/SBIN0000001/5898/Utility",
        "uuidNumber": "507113356473"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:27:03",
        "description": "CREDIT VIA INTERNAL",
        "amount": "1,000,000.00CR",
        "amountIndicator": "CR",
        "balance": "4,378,870.44CR",
        "balanceIndicator": "4,378,870.44CR",
        "extendedNarrator": "RTGS/SBINR12025031278246069/BUMPPY MEDIAPRIVATE LIMITED        H 140 SECTOR 63/SBIN0013218",
        "uuidNumber": null
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:27:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,378,870.44CR",
        "balanceIndicator": "3,378,870.44CR",
        "extendedNarrator": "IMPS-API/507113356029/neetu/CBIN0280065/2662/Utility",
        "uuidNumber": "507113356029"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:26:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,381,870.44CR",
        "balanceIndicator": "3,381,870.44CR",
        "extendedNarrator": "IMPS-API/507113355480/MANJU/BARB0NEHRUP/5321/Utility",
        "uuidNumber": "507113355480"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:26:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,929.00DR",
        "amountIndicator": "DR",
        "balance": "3,387,870.44CR",
        "balanceIndicator": "3,387,870.44CR",
        "extendedNarrator": "IMPS-API/507113355425/MOHAN LAL/SBIN0031694/5688/Utility",
        "uuidNumber": "507113355425"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:26:31",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,326.00DR",
        "amountIndicator": "DR",
        "balance": "3,389,799.44CR",
        "balanceIndicator": "3,389,799.44CR",
        "extendedNarrator": "IMPS-API/507113354844/Rahul Sharma/PUNB0244200/4384/Utility",
        "uuidNumber": "507113354844"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:26:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,394,125.44CR",
        "balanceIndicator": "3,394,125.44CR",
        "extendedNarrator": "IMPS-API/507113353952/ROHIMA/BKID0000150/1117/Utility",
        "uuidNumber": "507113353952"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:26:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "200.00DR",
        "amountIndicator": "DR",
        "balance": "3,394,625.44CR",
        "balanceIndicator": "3,394,625.44CR",
        "extendedNarrator": "IMPS-API/507113353604/shishpal/IBKL0000772/5923/Utility",
        "uuidNumber": "507113353604"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:25:17",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "8,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,394,825.44CR",
        "balanceIndicator": "3,394,825.44CR",
        "extendedNarrator": "IMPS-API/507113351357/AKASH YADAV/UBIN0550451/4524/Utility",
        "uuidNumber": "507113351357"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:24:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,402,825.44CR",
        "balanceIndicator": "3,402,825.44CR",
        "extendedNarrator": "IMPS-API/507113349672/INDHUMATHI S/CNRB0004522/1348/Utility",
        "uuidNumber": "507113349672"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:24:21",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "14,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,403,110.44CR",
        "balanceIndicator": "3,403,110.44CR",
        "extendedNarrator": "IMPS-API/507113349156/VISHAL SO SANTOSH/BKID0000150/2732/Utility",
        "uuidNumber": "507113349156"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:24:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,417,110.44CR",
        "balanceIndicator": "3,417,110.44CR",
        "extendedNarrator": "IMPS-API/507113349072/PARYAJI/PUNB0063200/6462/Utility",
        "uuidNumber": "507113349072"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:24:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,419,110.44CR",
        "balanceIndicator": "3,419,110.44CR",
        "extendedNarrator": "IMPS-API/507113348765/MR MURTAJA ANSARI/SBIN0000001/5898/Utility",
        "uuidNumber": "507113348765"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:23:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,444,110.44CR",
        "balanceIndicator": "3,444,110.44CR",
        "extendedNarrator": "IMPS-API/507113347607/DRMRS ISARAVATI ISARAVATI/SBIN0000001/8214/Utility",
        "uuidNumber": "507113347607"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:22:29",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,448,610.44CR",
        "balanceIndicator": "3,448,610.44CR",
        "extendedNarrator": "IMPS-API/507113345074/ANBARI/SBIN0009209/7474/Utility",
        "uuidNumber": "507113345074"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:22:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "49,899.00DR",
        "amountIndicator": "DR",
        "balance": "3,453,610.44CR",
        "balanceIndicator": "3,453,610.44CR",
        "extendedNarrator": "IMPS-API/507113344722/SHEETAL/CNRB0004195/9702/Utility",
        "uuidNumber": "507113344722"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:21:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "3,503,509.44CR",
        "balanceIndicator": "3,503,509.44CR",
        "extendedNarrator": "IMPS-API/507113343055/PONNERI JYOTHEESWAR/SBIN0021280/5280/Utility",
        "uuidNumber": "507113343055"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:21:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "49,900.00DR",
        "amountIndicator": "DR",
        "balance": "3,504,009.44CR",
        "balanceIndicator": "3,504,009.44CR",
        "extendedNarrator": "IMPS-API/507113342695/SHEETAL/CNRB0004195/9702/Utility",
        "uuidNumber": "507113342695"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:21:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,553,909.44CR",
        "balanceIndicator": "3,553,909.44CR",
        "extendedNarrator": "IMPS-API/507113342193/Reetu/IDIB000S028/0681/Utility",
        "uuidNumber": "507113342193"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:21:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,559,409.44CR",
        "balanceIndicator": "3,559,409.44CR",
        "extendedNarrator": "IMPS-API/507113342028/REKHA WO SABHAJIT/BARB0BUPGBX/3176/Utility",
        "uuidNumber": "507113342028"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,562,909.44CR",
        "balanceIndicator": "3,562,909.44CR",
        "extendedNarrator": "IMPS-API/507113340241/MO RAPHIK/KKBK0RTGSMI/5981/Utility",
        "uuidNumber": "507113340241"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,572,909.44CR",
        "balanceIndicator": "3,572,909.44CR",
        "extendedNarrator": "IMPS-API/507113339924/Mrs RADHA BAI BANS/SBIN0000001/4102/Utility",
        "uuidNumber": "507113339924"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,597,909.44CR",
        "balanceIndicator": "3,597,909.44CR",
        "extendedNarrator": "IMPS-API/507113339824/Punjab National Bank/PUNB0874100/3072/Utility",
        "uuidNumber": "507113339824"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "960.00DR",
        "amountIndicator": "DR",
        "balance": "3,598,859.44CR",
        "balanceIndicator": "3,598,859.44CR",
        "extendedNarrator": "IMPS-API/507113339818/Jatan kr Das/SBIN0014616/3066/Utility",
        "uuidNumber": "507113339818"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,501.00DR",
        "amountIndicator": "DR",
        "balance": "3,599,819.44CR",
        "balanceIndicator": "3,599,819.44CR",
        "extendedNarrator": "IMPS-API/507113338683/Sarita devi/PUNB0244200/5747/Utility",
        "uuidNumber": "507113338683"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,603,320.44CR",
        "balanceIndicator": "3,603,320.44CR",
        "extendedNarrator": "IMPS-API/507113338635/MUKKOLLU NAGA VAMSI/SBIN0021294/0666/Utility",
        "uuidNumber": "507113338635"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,603,605.44CR",
        "balanceIndicator": "3,603,605.44CR",
        "extendedNarrator": "IMPS-API/507113338560/Pramesh/BKID0006669/5018/Utility",
        "uuidNumber": "507113338560"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:20:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,603,890.44CR",
        "balanceIndicator": "3,603,890.44CR",
        "extendedNarrator": "IMPS-API/507113338555/Punjab National bank/PUNB0147320/5605/Utility",
        "uuidNumber": "507113338555"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,604,840.44CR",
        "balanceIndicator": "3,604,840.44CR",
        "extendedNarrator": "IMPS-API/507113337414/Pranith kumar/UBIN0810487/1769/Utility",
        "uuidNumber": "507113337414"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,605,840.44CR",
        "balanceIndicator": "3,605,840.44CR",
        "extendedNarrator": "IMPS-API/507113337233/Ramesh Gundeti/SBIN0020474/5257/Utility",
        "uuidNumber": "507113337233"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,910.00DR",
        "amountIndicator": "DR",
        "balance": "3,610,640.44CR",
        "balanceIndicator": "3,610,640.44CR",
        "extendedNarrator": "IMPS-API/507113337410/MONIKA/PUNB0678400/8957/Utility",
        "uuidNumber": "507113337410"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,613,550.44CR",
        "balanceIndicator": "3,613,550.44CR",
        "extendedNarrator": "IMPS-API/507113337074/Ramji koli/KKBK0000810/8460/Utility",
        "uuidNumber": "507113337074"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "480.00DR",
        "amountIndicator": "DR",
        "balance": "3,614,500.44CR",
        "balanceIndicator": "3,614,500.44CR",
        "extendedNarrator": "IMPS-API/507113337072/K sai mounika/SBIN0000836/4498/Utility",
        "uuidNumber": "507113337072"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,614,980.44CR",
        "balanceIndicator": "3,614,980.44CR",
        "extendedNarrator": "IMPS-API/507113337194/Satish b chavan/SBIN0001974/2788/Utility",
        "uuidNumber": "507113337194"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,950.00DR",
        "amountIndicator": "DR",
        "balance": "3,615,930.44CR",
        "balanceIndicator": "3,615,930.44CR",
        "extendedNarrator": "IMPS-API/507113336435/Sintu  Kumar/FINO0009003/5782/Utility",
        "uuidNumber": "507113336435"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,617,880.44CR",
        "balanceIndicator": "3,617,880.44CR",
        "extendedNarrator": "IMPS-API/507113336296/Momin Ali Khan/SBIN0009844/1605/Utility",
        "uuidNumber": "507113336296"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,617,980.44CR",
        "balanceIndicator": "3,617,980.44CR",
        "extendedNarrator": "IMPS-API/507113336274/Asit Mahata/SBIN0031811/1461/Utility",
        "uuidNumber": "507113336274"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,910.00DR",
        "amountIndicator": "DR",
        "balance": "3,618,080.44CR",
        "balanceIndicator": "3,618,080.44CR",
        "extendedNarrator": "IMPS-API/507113336257/MONIKA/PUNB0678400/8957/Utility",
        "uuidNumber": "507113336257"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,620,990.44CR",
        "balanceIndicator": "3,620,990.44CR",
        "extendedNarrator": "IMPS-API/507113336248/Irfan asgar gandhar/BKID0002000/0342/Utility",
        "uuidNumber": "507113336248"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,621,940.44CR",
        "balanceIndicator": "3,621,940.44CR",
        "extendedNarrator": "IMPS-API/507113336194/Sainath Manthiri/UBIN0800678/0433/Utility",
        "uuidNumber": "507113336194"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,910.00DR",
        "amountIndicator": "DR",
        "balance": "3,626,790.44CR",
        "balanceIndicator": "3,626,790.44CR",
        "extendedNarrator": "IMPS-API/507113335789/MONIKA/PUNB0678400/8957/Utility",
        "uuidNumber": "507113335789"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,629,700.44CR",
        "balanceIndicator": "3,629,700.44CR",
        "extendedNarrator": "IMPS-API/507113336192/Sk sajid/SBIN0003900/8465/Utility",
        "uuidNumber": "507113336192"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:04",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,630,650.44CR",
        "balanceIndicator": "3,630,650.44CR",
        "extendedNarrator": "IMPS-API/507113335765/AMIT KUMAR/HDFC0002750/2795/Utility",
        "uuidNumber": "507113335765"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:19:03",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,636,650.44CR",
        "balanceIndicator": "3,636,650.44CR",
        "extendedNarrator": "IMPS-API/507113336057/MOHAMMED SHOAIB/CNRB0005151/9116/Utility",
        "uuidNumber": "507113336057"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,575.00DR",
        "amountIndicator": "DR",
        "balance": "3,642,650.44CR",
        "balanceIndicator": "3,642,650.44CR",
        "extendedNarrator": "IMPS-API/507113335941/SAI RAM/APGV0006226/0408/Utility",
        "uuidNumber": "507113335941"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:53",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,645,225.44CR",
        "balanceIndicator": "3,645,225.44CR",
        "extendedNarrator": "IMPS-API/507113335668/GANGADHAR/KKBK0RTGSMI/0412/Utility",
        "uuidNumber": "507113335668"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "210.00DR",
        "amountIndicator": "DR",
        "balance": "3,670,225.44CR",
        "balanceIndicator": "3,670,225.44CR",
        "extendedNarrator": "IMPS-API/507113335601/Samir/JAKA0AALLAH/4110/Utility",
        "uuidNumber": "507113335601"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:45",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,670,435.44CR",
        "balanceIndicator": "3,670,435.44CR",
        "extendedNarrator": "IMPS-API/507113335350/Mrs Darshan  Devi/SBIN0009209/0857/Utility",
        "uuidNumber": "507113335350"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:44",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,680,435.44CR",
        "balanceIndicator": "3,680,435.44CR",
        "extendedNarrator": "IMPS-API/507113335260/Airtwl/AIRP0000001/3941/Utility",
        "uuidNumber": "507113335260"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,880.00DR",
        "amountIndicator": "DR",
        "balance": "3,680,910.44CR",
        "balanceIndicator": "3,680,910.44CR",
        "extendedNarrator": "IMPS-API/507113335136/Mamunur sudhakar/SBIN0020458/0958/Utility",
        "uuidNumber": "507113335136"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,683,790.44CR",
        "balanceIndicator": "3,683,790.44CR",
        "extendedNarrator": "IMPS-API/507113335119/Asit Mahata/SBIN0031811/1461/Utility",
        "uuidNumber": "507113335119"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,684,265.44CR",
        "balanceIndicator": "3,684,265.44CR",
        "extendedNarrator": "IMPS-API/507113335052/Mohdjahid/UBIN0556947/2564/Utility",
        "uuidNumber": "507113335052"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:34",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,685,215.44CR",
        "balanceIndicator": "3,685,215.44CR",
        "extendedNarrator": "IMPS-API/507113334968/KHATUN/BARB0BUPGBX/7432/Utility",
        "uuidNumber": "507113334968"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:32",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,686,715.44CR",
        "balanceIndicator": "3,686,715.44CR",
        "extendedNarrator": "IMPS-API/507113335024/GANGADHAR/KKBK0RTGSMI/0412/Utility",
        "uuidNumber": "507113335024"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:30",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,711,715.44CR",
        "balanceIndicator": "3,711,715.44CR",
        "extendedNarrator": "IMPS-API/507113334893/ROOPENDRA/IPOS0000001/4979/Utility",
        "uuidNumber": "507113334893"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:13",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,717,215.44CR",
        "balanceIndicator": "3,717,215.44CR",
        "extendedNarrator": "IMPS-API/507113334402/Abhijeet Agarkar/UBIN0554685/2901/Utility",
        "uuidNumber": "507113334402"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,722,215.44CR",
        "balanceIndicator": "3,722,215.44CR",
        "extendedNarrator": "IMPS-API/507113334361/Kanha prajapati/PUNB0497300/7200/Utility",
        "uuidNumber": "507113334361"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,725,065.44CR",
        "balanceIndicator": "3,725,065.44CR",
        "extendedNarrator": "IMPS-API/507113334264/GANGADHAR/KKBK0RTGSMI/0412/Utility",
        "uuidNumber": "507113334264"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,750,065.44CR",
        "balanceIndicator": "3,750,065.44CR",
        "extendedNarrator": "IMPS-API/507113334257/Kanha prajapati/SBIN0062274/8535/Utility",
        "uuidNumber": "507113334257"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,752,915.44CR",
        "balanceIndicator": "3,752,915.44CR",
        "extendedNarrator": "IMPS-API/507113334176/SBI/SBIN0009473/6714/Utility",
        "uuidNumber": "507113334176"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,753,865.44CR",
        "balanceIndicator": "3,753,865.44CR",
        "extendedNarrator": "IMPS-API/507113334153/Ravi kumar/FINO0000001/6752/Utility",
        "uuidNumber": "507113334153"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,754,815.44CR",
        "balanceIndicator": "3,754,815.44CR",
        "extendedNarrator": "IMPS-API/507113334152/Maheshbhai Raysingbhai/SBIN0060323/3082/Utility",
        "uuidNumber": "507113334152"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,754,915.44CR",
        "balanceIndicator": "3,754,915.44CR",
        "extendedNarrator": "IMPS-API/507113334111/MAHESH KUMAR/PUNB0004610/7717/Utility",
        "uuidNumber": "507113334111"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,755,015.44CR",
        "balanceIndicator": "3,755,015.44CR",
        "extendedNarrator": "IMPS-API/507113334067/Shoukin kumar/RMGB0000649/9448/Utility",
        "uuidNumber": "507113334067"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:18:01",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "3,759,865.44CR",
        "balanceIndicator": "3,759,865.44CR",
        "extendedNarrator": "IMPS-API/507113333939/ASIF/BARB0TILDEL/0081/Utility",
        "uuidNumber": "507113333939"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:50",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "14,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,760,866.44CR",
        "balanceIndicator": "3,760,866.44CR",
        "extendedNarrator": "IMPS-API/507113333420/SONIKA /KKBK0000181/9354/Utility",
        "uuidNumber": "507113333420"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,775,366.44CR",
        "balanceIndicator": "3,775,366.44CR",
        "extendedNarrator": "IMPS-API/507113333268/MRS SUNDARI DEVI/SBIN0000001/6481/Utility",
        "uuidNumber": "507113333268"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "25,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,778,366.44CR",
        "balanceIndicator": "3,778,366.44CR",
        "extendedNarrator": "IMPS-API/507113333226/GANGADHAR/KKBK0RTGSMI/0412/Utility",
        "uuidNumber": "507113333226"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,803,366.44CR",
        "balanceIndicator": "3,803,366.44CR",
        "extendedNarrator": "IMPS-API/507113333199/TILESHWAR KUMAR SO BABU LAL SAW/PUNB0063200/4038/Utility",
        "uuidNumber": "507113333199"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,805,366.44CR",
        "balanceIndicator": "3,805,366.44CR",
        "extendedNarrator": "IMPS-API/507113333178/SHAYAM SUNDAR SINGH SO KAILAS/PUNB0MBGB06/0136/Utility",
        "uuidNumber": "507113333178"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,815,366.44CR",
        "balanceIndicator": "3,815,366.44CR",
        "extendedNarrator": "IMPS-API/507113332807/SHANTI LAL/INDB0000337/5915/Utility",
        "uuidNumber": "507113332807"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,818,216.44CR",
        "balanceIndicator": "3,818,216.44CR",
        "extendedNarrator": "IMPS-API/507113333106/MAYUR KHADOLA/IDIB000M069/0741/Utility",
        "uuidNumber": "507113333106"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,819,166.44CR",
        "balanceIndicator": "3,819,166.44CR",
        "extendedNarrator": "IMPS-API/507113332770/Yudhisthir yadav/SBIN0003614/4364/Utility",
        "uuidNumber": "507113332770"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,824,016.44CR",
        "balanceIndicator": "3,824,016.44CR",
        "extendedNarrator": "IMPS-API/507113333072/Airtel/AIRP0000001/1735/Utility",
        "uuidNumber": "507113333072"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:28",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,824,301.44CR",
        "balanceIndicator": "3,824,301.44CR",
        "extendedNarrator": "IMPS-API/507113332654/Md Sarfarazul Haque/KKBK0000958/6588/Utility",
        "uuidNumber": "507113332654"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,844,301.44CR",
        "balanceIndicator": "3,844,301.44CR",
        "extendedNarrator": "IMPS-API/507113331785/Airtel/AIRP0000001/3941/Utility",
        "uuidNumber": "507113331785"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,844,776.44CR",
        "balanceIndicator": "3,844,776.44CR",
        "extendedNarrator": "IMPS-API/507113332028/Dinesh kumar porte/PUNB0250000/7798/Utility",
        "uuidNumber": "507113332028"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,845,726.44CR",
        "balanceIndicator": "3,845,726.44CR",
        "extendedNarrator": "IMPS-API/507113332021/Manoj nath/SBIN0009029/1673/Utility",
        "uuidNumber": "507113332021"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,846,676.44CR",
        "balanceIndicator": "3,846,676.44CR",
        "extendedNarrator": "IMPS-API/507113331776/Mrs SHYAMA  DEVI/SBIN0009209/8252/Utility",
        "uuidNumber": "507113331776"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,850,676.44CR",
        "balanceIndicator": "3,850,676.44CR",
        "extendedNarrator": "IMPS-API/507113331774/Narayan Singh/IBKL0000001/6954/Utility",
        "uuidNumber": "507113331774"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,850,776.44CR",
        "balanceIndicator": "3,850,776.44CR",
        "extendedNarrator": "IMPS-API/507113331995/Sabbir mallik/SBIN0003647/7200/Utility",
        "uuidNumber": "507113331995"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,850,876.44CR",
        "balanceIndicator": "3,850,876.44CR",
        "extendedNarrator": "IMPS-API/507113331971/Bhujan Rahba/HDFC0001300/4106/Utility",
        "uuidNumber": "507113331971"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,851,826.44CR",
        "balanceIndicator": "3,851,826.44CR",
        "extendedNarrator": "IMPS-API/507113331750/Chakme marak/SBIN0007977/1972/Utility",
        "uuidNumber": "507113331750"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,852,776.44CR",
        "balanceIndicator": "3,852,776.44CR",
        "extendedNarrator": "IMPS-API/507113331923/SURESH VIKRAM BHAGAT/JANA0000002/6919/Utility",
        "uuidNumber": "507113331923"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:17:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,853,726.44CR",
        "balanceIndicator": "3,853,726.44CR",
        "extendedNarrator": "IMPS-API/507113331920/karshna damami/AIRP0000001/4565/Utility",
        "uuidNumber": "507113331920"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "8,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,854,201.44CR",
        "balanceIndicator": "3,854,201.44CR",
        "extendedNarrator": "IMPS-API/507113331362/RAM NIWAS/PUNB0244200/9223/Utility",
        "uuidNumber": "507113331362"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,862,701.44CR",
        "balanceIndicator": "3,862,701.44CR",
        "extendedNarrator": "IMPS-API/507113331523/RAUSHAN KUMAR/IPOS0000001/6822/Utility",
        "uuidNumber": "507113331523"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,871,701.44CR",
        "balanceIndicator": "3,871,701.44CR",
        "extendedNarrator": "IMPS-API/507113331055/RAVI/FDRL0000035/9120/Utility",
        "uuidNumber": "507113331055"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,200.00DR",
        "amountIndicator": "DR",
        "balance": "3,878,201.44CR",
        "balanceIndicator": "3,878,201.44CR",
        "extendedNarrator": "IMPS-API/507113330970/RAVINDRA KUMAR PRAJAPATI SO GANGA PRASAD MANJU DE/UBIN0551775/0685/Utility",
        "uuidNumber": "507113330970"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,881,401.44CR",
        "balanceIndicator": "3,881,401.44CR",
        "extendedNarrator": "IMPS-API/507113330695/Jwhwlao Basumatary/SBIN0013378/8950/Utility",
        "uuidNumber": "507113330695"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,886,151.44CR",
        "balanceIndicator": "3,886,151.44CR",
        "extendedNarrator": "IMPS-API/507113330651/FOOLMATI/IPOS0000001/1640/Utility",
        "uuidNumber": "507113330651"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,888,651.44CR",
        "balanceIndicator": "3,888,651.44CR",
        "extendedNarrator": "IMPS-API/507113330630/Samiran pattanayak/UJVN0003502/0820/Utility",
        "uuidNumber": "507113330630"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,889,601.44CR",
        "balanceIndicator": "3,889,601.44CR",
        "extendedNarrator": "IMPS-API/507113330629/Jwhwlao Basumatary/SBIN0013378/8950/Utility",
        "uuidNumber": "507113330629"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:24",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "900.00DR",
        "amountIndicator": "DR",
        "balance": "3,894,351.44CR",
        "balanceIndicator": "3,894,351.44CR",
        "extendedNarrator": "IMPS-API/507113330150/RAJAN SINGH/HDFC0000651/0052/Utility",
        "uuidNumber": "507113330150"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "3,895,251.44CR",
        "balanceIndicator": "3,895,251.44CR",
        "extendedNarrator": "IMPS-API/507113330040/Mrs Rabina Khatoon/SBIN0000001/3732/Utility",
        "uuidNumber": "507113330040"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,896,252.44CR",
        "balanceIndicator": "3,896,252.44CR",
        "extendedNarrator": "IMPS-API/507113329076/Kishan/SBIN0060397/5768/Utility",
        "uuidNumber": "507113329076"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,899,102.44CR",
        "balanceIndicator": "3,899,102.44CR",
        "extendedNarrator": "IMPS-API/507113329028/Shareefsanbaba/UBIN0557331/0172/Utility",
        "uuidNumber": "507113329028"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,900,052.44CR",
        "balanceIndicator": "3,900,052.44CR",
        "extendedNarrator": "IMPS-API/507113329027/Sriramamurthy majji/SBIN0009473/4449/Utility",
        "uuidNumber": "507113329027"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,900.00DR",
        "amountIndicator": "DR",
        "balance": "3,901,002.44CR",
        "balanceIndicator": "3,901,002.44CR",
        "extendedNarrator": "IMPS-API/507113329193/Sai manikanta/IDIB000T006/8239/Utility",
        "uuidNumber": "507113329193"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:16:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,902,902.44CR",
        "balanceIndicator": "3,902,902.44CR",
        "extendedNarrator": "IMPS-API/507113329191/Hiralkumar vijaykumar Rathod/UBIN0934135/0249/Utility",
        "uuidNumber": "507113329191"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,903,852.44CR",
        "balanceIndicator": "3,903,852.44CR",
        "extendedNarrator": "IMPS-API/507113328873/RAHUL KUMAR/FINO0000001/1419/Utility",
        "uuidNumber": "507113328873"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,916,852.44CR",
        "balanceIndicator": "3,916,852.44CR",
        "extendedNarrator": "IMPS-API/507113328381/GUDIYA KUMARI/PUNB0MBGB06/3679/Utility",
        "uuidNumber": "507113328381"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "9,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,920,852.44CR",
        "balanceIndicator": "3,920,852.44CR",
        "extendedNarrator": "IMPS-API/507113328175/BUDHDU RAM/PUNB0244200/9460/Utility",
        "uuidNumber": "507113328175"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,929,852.44CR",
        "balanceIndicator": "3,929,852.44CR",
        "extendedNarrator": "IMPS-API/507113327956/Ardhendu Bikash Bag/SBIN0001553/7451/Utility",
        "uuidNumber": "507113327956"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "3,930,137.44CR",
        "balanceIndicator": "3,930,137.44CR",
        "extendedNarrator": "IMPS-API/507113327932/Mahatab ahamad/SBIN0000088/3584/Utility",
        "uuidNumber": "507113327932"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "3,934,887.44CR",
        "balanceIndicator": "3,934,887.44CR",
        "extendedNarrator": "IMPS-API/507113327850/Airtel/AIRP0000001/3941/Utility",
        "uuidNumber": "507113327850"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,935,362.44CR",
        "balanceIndicator": "3,935,362.44CR",
        "extendedNarrator": "IMPS-API/507113327846/Yudhisthir yadav/SBIN0003614/4364/Utility",
        "uuidNumber": "507113327846"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,940,212.44CR",
        "balanceIndicator": "3,940,212.44CR",
        "extendedNarrator": "IMPS-API/507113327805/Talapa Reddy Ravi/SBIN0005094/0186/Utility",
        "uuidNumber": "507113327805"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,941,212.44CR",
        "balanceIndicator": "3,941,212.44CR",
        "extendedNarrator": "IMPS-API/507113327682/jayanta Das/AIRP0000001/5824/Utility",
        "uuidNumber": "507113327682"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:20",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,941,312.44CR",
        "balanceIndicator": "3,941,312.44CR",
        "extendedNarrator": "IMPS-API/507113326950/SANJU/UBIN0551775/2913/Utility",
        "uuidNumber": "507113326950"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:18",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "11,120.00DR",
        "amountIndicator": "DR",
        "balance": "3,945,312.44CR",
        "balanceIndicator": "3,945,312.44CR",
        "extendedNarrator": "IMPS-API/507113326933/RAVIRAJ SINGH/KKBK0005954/0804/Utility",
        "uuidNumber": "507113326933"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:10",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "3,956,432.44CR",
        "balanceIndicator": "3,956,432.44CR",
        "extendedNarrator": "IMPS-API/507113326567/Barasha bania/SBIN0001171/4835/Utility",
        "uuidNumber": "507113326567"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,956,532.44CR",
        "balanceIndicator": "3,956,532.44CR",
        "extendedNarrator": "IMPS-API/507113326470/Kantharaju/CNRB0003196/4028/Utility",
        "uuidNumber": "507113326470"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,957,482.44CR",
        "balanceIndicator": "3,957,482.44CR",
        "extendedNarrator": "IMPS-API/507113326471/Pooja Devi/SBIN0008261/9389/Utility",
        "uuidNumber": "507113326471"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,958,432.44CR",
        "balanceIndicator": "3,958,432.44CR",
        "extendedNarrator": "IMPS-API/507113326450/BISWAJIT SAMANTA/PUNB0150600/2489/Utility",
        "uuidNumber": "507113326450"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:15:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "3,959,382.44CR",
        "balanceIndicator": "3,959,382.44CR",
        "extendedNarrator": "IMPS-API/507113326384/LAKHAN KALU MENGAL/UBIN0546666/8516/Utility",
        "uuidNumber": "507113326384"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:47",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,501.00DR",
        "amountIndicator": "DR",
        "balance": "3,959,667.44CR",
        "balanceIndicator": "3,959,667.44CR",
        "extendedNarrator": "IMPS-API/507113325840/RITU RANJAN KUMAR/BKID0000150/6011/Utility",
        "uuidNumber": "507113325840"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,963,168.44CR",
        "balanceIndicator": "3,963,168.44CR",
        "extendedNarrator": "IMPS-API/507113325428/SHANABHAI CHANDUBHAI PARMAR/SBIN0060245/7247/Utility",
        "uuidNumber": "507113325428"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,900.00DR",
        "amountIndicator": "DR",
        "balance": "3,964,118.44CR",
        "balanceIndicator": "3,964,118.44CR",
        "extendedNarrator": "IMPS-API/507113325409/Sai manikanta/IDIB000T006/8239/Utility",
        "uuidNumber": "507113325409"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,966,018.44CR",
        "balanceIndicator": "3,966,018.44CR",
        "extendedNarrator": "IMPS-API/507113325405/Pravin Sitaram Deore/CBIN0280270/1452/Utility",
        "uuidNumber": "507113325405"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "209.00DR",
        "amountIndicator": "DR",
        "balance": "3,966,968.44CR",
        "balanceIndicator": "3,966,968.44CR",
        "extendedNarrator": "IMPS-API/507113325567/anandaram pegu/AIRP0000001/2844/Utility",
        "uuidNumber": "507113325567"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,880.00DR",
        "amountIndicator": "DR",
        "balance": "3,967,177.44CR",
        "balanceIndicator": "3,967,177.44CR",
        "extendedNarrator": "IMPS-API/507113325394/Nasim Ansari/SBIN0008083/3121/Utility",
        "uuidNumber": "507113325394"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,970,057.44CR",
        "balanceIndicator": "3,970,057.44CR",
        "extendedNarrator": "IMPS-API/507113325359/subramanayam kancham/SBIN0010600/9550/Utility",
        "uuidNumber": "507113325359"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,500.00DR",
        "amountIndicator": "DR",
        "balance": "3,971,007.44CR",
        "balanceIndicator": "3,971,007.44CR",
        "extendedNarrator": "IMPS-API/507113325357/ASHA/BKID0000150/3192/Utility",
        "uuidNumber": "507113325357"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "17,000.00DR",
        "amountIndicator": "DR",
        "balance": "3,973,507.44CR",
        "balanceIndicator": "3,973,507.44CR",
        "extendedNarrator": "IMPS-API/507113324863/AJMUDDIN/BARB0NAJDEL/6970/Utility",
        "uuidNumber": "507113324863"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "3,990,507.44CR",
        "balanceIndicator": "3,990,507.44CR",
        "extendedNarrator": "IMPS-API/507113324385/Nasim Ansari/SBIN0008083/3121/Utility",
        "uuidNumber": "507113324385"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "3,995,307.44CR",
        "balanceIndicator": "3,995,307.44CR",
        "extendedNarrator": "IMPS-API/507113324360/Khumesh Ambedare/SBIN0000504/6932/Utility",
        "uuidNumber": "507113324360"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:07",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "3,996,257.44CR",
        "balanceIndicator": "3,996,257.44CR",
        "extendedNarrator": "IMPS-API/507113324333/Shoukin kumar/RMGB0000649/9448/Utility",
        "uuidNumber": "507113324333"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,850.00DR",
        "amountIndicator": "DR",
        "balance": "4,001,107.44CR",
        "balanceIndicator": "4,001,107.44CR",
        "extendedNarrator": "IMPS-API/507113324079/Shoukin kumar/RMGB0000649/9448/Utility",
        "uuidNumber": "507113324079"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "4,005,957.44CR",
        "balanceIndicator": "4,005,957.44CR",
        "extendedNarrator": "IMPS-API/507113324295/Pradeep lodha/CNRB0006164/1570/Utility",
        "uuidNumber": "507113324295"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,008,807.44CR",
        "balanceIndicator": "4,008,807.44CR",
        "extendedNarrator": "IMPS-API/507113324068/Awdhesh/IPOS0000001/9902/Utility",
        "uuidNumber": "507113324068"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:14:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "4,009,092.44CR",
        "balanceIndicator": "4,009,092.44CR",
        "extendedNarrator": "IMPS-API/507113324291/Pradeep lodha/CNRB0006164/1570/Utility",
        "uuidNumber": "507113324291"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "20,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,011,942.44CR",
        "balanceIndicator": "4,011,942.44CR",
        "extendedNarrator": "IMPS-API/507113323224/ASHARFI/SBIN0000001/8862/Utility",
        "uuidNumber": "507113323224"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "839.72DR",
        "amountIndicator": "DR",
        "balance": "4,031,942.44CR",
        "balanceIndicator": "4,031,942.44CR",
        "extendedNarrator": "IMPS-API/507113323213/Akash Haldar/KKBK0007709/9102/Utility",
        "uuidNumber": "507113323213"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,032,782.16CR",
        "balanceIndicator": "4,032,782.16CR",
        "extendedNarrator": "IMPS-API/507113323111/Bhagwan Ram/PUNB0190920/4790/Utility",
        "uuidNumber": "507113323111"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,999.00DR",
        "amountIndicator": "DR",
        "balance": "4,033,732.16CR",
        "balanceIndicator": "4,033,732.16CR",
        "extendedNarrator": "IMPS-API/507113322649/JUGAI SAFI/PUNB0063200/1605/Utility",
        "uuidNumber": "507113322649"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "500.00DR",
        "amountIndicator": "DR",
        "balance": "4,041,731.16CR",
        "balanceIndicator": "4,041,731.16CR",
        "extendedNarrator": "IMPS-API/507113322117/KALIYAPPAN M/UBIN0929085/6694/Utility",
        "uuidNumber": "507113322117"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:16",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "4,042,231.16CR",
        "balanceIndicator": "4,042,231.16CR",
        "extendedNarrator": "IMPS-API/507113322115/SAMARU SO KABDIN/HDFC0999999/6739/Utility",
        "uuidNumber": "507113322115"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,043,731.16CR",
        "balanceIndicator": "4,043,731.16CR",
        "extendedNarrator": "IMPS-API/507113322330/Airtel/AIRP0000001/3941/Utility",
        "uuidNumber": "507113322330"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:11",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,044,016.16CR",
        "balanceIndicator": "4,044,016.16CR",
        "extendedNarrator": "IMPS-API/507113322234/Lajpat suny lala/DBSS0IN0811/8252/Utility",
        "uuidNumber": "507113322234"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "4,044,966.16CR",
        "balanceIndicator": "4,044,966.16CR",
        "extendedNarrator": "IMPS-API/507113322044/Munesh kumar/IOBA0000992/0272/Utility",
        "uuidNumber": "507113322044"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,910.00DR",
        "amountIndicator": "DR",
        "balance": "4,045,441.16CR",
        "balanceIndicator": "4,045,441.16CR",
        "extendedNarrator": "IMPS-API/507113321869/Mali Bhushan sainath/PJSB0000038/0111/Utility",
        "uuidNumber": "507113321869"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "960.00DR",
        "amountIndicator": "DR",
        "balance": "4,048,351.16CR",
        "balanceIndicator": "4,048,351.16CR",
        "extendedNarrator": "IMPS-API/507113321870/Yenugula Prasad/CNRB0001810/6546/Utility",
        "uuidNumber": "507113321870"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:13:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,049,311.16CR",
        "balanceIndicator": "4,049,311.16CR",
        "extendedNarrator": "IMPS-API/507113321868/Raju bhai D jamod/SBIN0060252/7302/Utility",
        "uuidNumber": "507113321868"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:54",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,050,261.16CR",
        "balanceIndicator": "4,050,261.16CR",
        "extendedNarrator": "IMPS-API/507113321567/MAMATA/BARB0BUPGBX/9998/Utility",
        "uuidNumber": "507113321567"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:48",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "4,054,261.16CR",
        "balanceIndicator": "4,054,261.16CR",
        "extendedNarrator": "IMPS-API/507113321395/SULEKHA DEVI WO HARIBANSH BAITHA/BARB0TILDEL/2635/Utility",
        "uuidNumber": "507113321395"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:42",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,500.00DR",
        "amountIndicator": "DR",
        "balance": "4,055,262.16CR",
        "balanceIndicator": "4,055,262.16CR",
        "extendedNarrator": "IMPS-API/507113320942/JOTIRMOY DAS/KKBK0009582/0516/Utility",
        "uuidNumber": "507113320942"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:40",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,056,762.16CR",
        "balanceIndicator": "4,056,762.16CR",
        "extendedNarrator": "IMPS-API/507113321067/Moda amin/SBIN0060099/7251/Utility",
        "uuidNumber": "507113321067"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,800.00DR",
        "amountIndicator": "DR",
        "balance": "4,057,712.16CR",
        "balanceIndicator": "4,057,712.16CR",
        "extendedNarrator": "IMPS-API/507113321037/Anjaneyulu munigala/HDFC0002236/3652/Utility",
        "uuidNumber": "507113321037"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "970.00DR",
        "amountIndicator": "DR",
        "balance": "4,062,512.16CR",
        "balanceIndicator": "4,062,512.16CR",
        "extendedNarrator": "IMPS-API/507113320835/BHASKAR PANDA/BARB0PANSKU/1955/Utility",
        "uuidNumber": "507113320835"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,063,482.16CR",
        "balanceIndicator": "4,063,482.16CR",
        "extendedNarrator": "IMPS-API/507113320976/Rajib Biswas/IDIB000T532/0841/Utility",
        "uuidNumber": "507113320976"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,063,767.16CR",
        "balanceIndicator": "4,063,767.16CR",
        "extendedNarrator": "IMPS-API/507113320972/Parvatha rani/BKID0008239/0183/Utility",
        "uuidNumber": "507113320972"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:30",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,064,052.16CR",
        "balanceIndicator": "4,064,052.16CR",
        "extendedNarrator": "IMPS-API/507113320684/PANKAJ KUMAR/PUNB0244200/9335/Utility",
        "uuidNumber": "507113320684"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,068,052.16CR",
        "balanceIndicator": "4,068,052.16CR",
        "extendedNarrator": "IMPS-API/507113320434/RAM DAYAL SO KUMARE/BARB0BUPGBX/8783/Utility",
        "uuidNumber": "507113320434"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:09",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "7,500.00DR",
        "amountIndicator": "DR",
        "balance": "4,073,052.16CR",
        "balanceIndicator": "4,073,052.16CR",
        "extendedNarrator": "IMPS-API/507113319744/MANORANJAN DHALI/KKBK0RTGSMI/0373/Utility",
        "uuidNumber": "507113319744"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,400.00DR",
        "amountIndicator": "DR",
        "balance": "4,080,552.16CR",
        "balanceIndicator": "4,080,552.16CR",
        "extendedNarrator": "IMPS-API/507113319697/RABARI RAJU/CNRB0017030/8250/Utility",
        "uuidNumber": "507113319697"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "4,083,952.16CR",
        "balanceIndicator": "4,083,952.16CR",
        "extendedNarrator": "IMPS-API/507113319692/Achhelal/SBIN0006505/6245/Utility",
        "uuidNumber": "507113319692"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:12:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,900.00DR",
        "amountIndicator": "DR",
        "balance": "4,086,802.16CR",
        "balanceIndicator": "4,086,802.16CR",
        "extendedNarrator": "IMPS-API/507113319661/Pradeep/UTIB0002538/2709/Utility",
        "uuidNumber": "507113319661"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:55",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "10,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,088,702.16CR",
        "balanceIndicator": "4,088,702.16CR",
        "extendedNarrator": "IMPS-API/507113319230/MRS JHINKA/SBIN0000001/8755/Utility",
        "uuidNumber": "507113319230"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:51",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,098,702.16CR",
        "balanceIndicator": "4,098,702.16CR",
        "extendedNarrator": "IMPS-API/507113319025/MR NAND KISHOR S O DHURV SINGH/CBIN0281107/7009/Utility",
        "uuidNumber": "507113319025"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,850.00DR",
        "amountIndicator": "DR",
        "balance": "4,101,702.16CR",
        "balanceIndicator": "4,101,702.16CR",
        "extendedNarrator": "IMPS-API/507113318576/Nitesh Sahani/SBIN0018161/8161/Utility",
        "uuidNumber": "507113318576"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,104,552.16CR",
        "balanceIndicator": "4,104,552.16CR",
        "extendedNarrator": "IMPS-API/507113318702/Ahanthem Roshikanta Singh/CNRB0005081/0814/Utility",
        "uuidNumber": "507113318702"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:23",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,500.00DR",
        "amountIndicator": "DR",
        "balance": "4,105,502.16CR",
        "balanceIndicator": "4,105,502.16CR",
        "extendedNarrator": "IMPS-API/507113318351/RAJESH KUMAR/KKBK0RTGSMI/2760/Utility",
        "uuidNumber": "507113318351"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,080.00DR",
        "amountIndicator": "DR",
        "balance": "4,110,002.16CR",
        "balanceIndicator": "4,110,002.16CR",
        "extendedNarrator": "IMPS-API/507113317445/MANISH KUMAR MEENA/UBIN0551775/5358/Utility",
        "uuidNumber": "507113317445"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:06",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,111,082.16CR",
        "balanceIndicator": "4,111,082.16CR",
        "extendedNarrator": "IMPS-API/507113317442/Ranjeet/BARB0BRGBXX/3585/Utility",
        "uuidNumber": "507113317442"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:05",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,111,367.16CR",
        "balanceIndicator": "4,111,367.16CR",
        "extendedNarrator": "IMPS-API/507113317253/Nikhil nandakumar naik/BKID0001258/0547/Utility",
        "uuidNumber": "507113317253"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:04",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,112,317.16CR",
        "balanceIndicator": "4,112,317.16CR",
        "extendedNarrator": "IMPS-API/507113317228/VIKASH KUMAR SINGH/IPOS0000001/1383/Utility",
        "uuidNumber": "507113317228"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:11:04",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,114,317.16CR",
        "balanceIndicator": "4,114,317.16CR",
        "extendedNarrator": "IMPS-API/507113317221/NASEEM/CNRB0000002/8911/Utility",
        "uuidNumber": "507113317221"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "570.00DR",
        "amountIndicator": "DR",
        "balance": "4,117,317.16CR",
        "balanceIndicator": "4,117,317.16CR",
        "extendedNarrator": "IMPS-API/507113316908/VEERAPURAM NAIDU/YESB0000250/9488/Utility",
        "uuidNumber": "507113316908"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:59",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,117,887.16CR",
        "balanceIndicator": "4,117,887.16CR",
        "extendedNarrator": "IMPS-API/507113316880/Mrs PRIYANKA  /SBIN0009209/2666/Utility",
        "uuidNumber": "507113316880"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:56",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,100.00DR",
        "amountIndicator": "DR",
        "balance": "4,118,887.16CR",
        "balanceIndicator": "4,118,887.16CR",
        "extendedNarrator": "IMPS-API/507113316796/DILVAR SINGH/IBKL0001376/3692/Utility",
        "uuidNumber": "507113316796"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:43",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,120,987.16CR",
        "balanceIndicator": "4,120,987.16CR",
        "extendedNarrator": "IMPS-API/507113316482/BULU PRADHAN/SBIN0000038/7977/Utility",
        "uuidNumber": "507113316482"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:41",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,900.00DR",
        "amountIndicator": "DR",
        "balance": "4,121,937.16CR",
        "balanceIndicator": "4,121,937.16CR",
        "extendedNarrator": "IMPS-API/507113316433/Nikhil/PUNB0497300/1146/Utility",
        "uuidNumber": "507113316433"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,123,837.16CR",
        "balanceIndicator": "4,123,837.16CR",
        "extendedNarrator": "IMPS-API/507113315867/Lanuakum Imchen/SBIN0005839/9582/Utility",
        "uuidNumber": "507113315867"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:37",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,001.00DR",
        "amountIndicator": "DR",
        "balance": "4,124,122.16CR",
        "balanceIndicator": "4,124,122.16CR",
        "extendedNarrator": "IMPS-API/507113315846/Mrs NAVISA  KHATUN/SBIN0009209/9432/Utility",
        "uuidNumber": "507113315846"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:36",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,125,123.16CR",
        "balanceIndicator": "4,125,123.16CR",
        "extendedNarrator": "IMPS-API/507113315829/Chander bhan/KKBK0000286/5339/Utility",
        "uuidNumber": "507113315829"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "285.00DR",
        "amountIndicator": "DR",
        "balance": "4,126,073.16CR",
        "balanceIndicator": "4,126,073.16CR",
        "extendedNarrator": "IMPS-API/507113315818/prakash rai/HDFC0000181/2770/Utility",
        "uuidNumber": "507113315818"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:26",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,126,358.16CR",
        "balanceIndicator": "4,126,358.16CR",
        "extendedNarrator": "IMPS-API/507113315307/Ravi Prakash/FINO0009003/2234/Utility",
        "uuidNumber": "507113315307"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:25",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,128,358.16CR",
        "balanceIndicator": "4,128,358.16CR",
        "extendedNarrator": "IMPS-API/507113315254/ASHA/BKID0000150/3192/Utility",
        "uuidNumber": "507113315254"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:15",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,131,358.16CR",
        "balanceIndicator": "4,131,358.16CR",
        "extendedNarrator": "IMPS-API/507113314974/FOOLCHANDRA KOL/UBIN0539023/5006/Utility",
        "uuidNumber": "507113314974"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:14",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,136,358.16CR",
        "balanceIndicator": "4,136,358.16CR",
        "extendedNarrator": "IMPS-API/507113314780/parmanand/PUNB0244200/4560/Utility",
        "uuidNumber": "507113314780"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:12",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "6,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,140,358.16CR",
        "balanceIndicator": "4,140,358.16CR",
        "extendedNarrator": "IMPS-API/507113314643/NILU DEVI/CBIN0R10001/9864/Utility",
        "uuidNumber": "507113314643"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:08",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,880.00DR",
        "amountIndicator": "DR",
        "balance": "4,146,358.16CR",
        "balanceIndicator": "4,146,358.16CR",
        "extendedNarrator": "IMPS-API/507113314557/manthri sainath/SBIN0011084/3803/Utility",
        "uuidNumber": "507113314557"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:10:01",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "13,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,149,238.16CR",
        "balanceIndicator": "4,149,238.16CR",
        "extendedNarrator": "IMPS-API/507113314330/Ramesh/HDFC0999999/8641/Utility",
        "uuidNumber": "507113314330"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:57",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,162,238.16CR",
        "balanceIndicator": "4,162,238.16CR",
        "extendedNarrator": "IMPS-API/507113314115/MR PANKAJ PANKAJ/SBIN0000001/8754/Utility",
        "uuidNumber": "507113314115"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:55",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "1,480.00DR",
        "amountIndicator": "DR",
        "balance": "4,166,238.16CR",
        "balanceIndicator": "4,166,238.16CR",
        "extendedNarrator": "IMPS-API/507113314092/abhinav deixit/KKBK0RTGSMI/3235/Utility",
        "uuidNumber": "507113314092"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:55",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "2,465.00DR",
        "amountIndicator": "DR",
        "balance": "4,167,718.16CR",
        "balanceIndicator": "4,167,718.16CR",
        "extendedNarrator": "IMPS-API/507113314082/URMILA /FDRL0000035/8991/Utility",
        "uuidNumber": "507113314082"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:49",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "3,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,170,183.16CR",
        "balanceIndicator": "4,170,183.16CR",
        "extendedNarrator": "IMPS-API/507113313900/PANKAJKUMAR/KKBK0000958/6096/Utility",
        "uuidNumber": "507113313900"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:39",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,173,183.16CR",
        "balanceIndicator": "4,173,183.16CR",
        "extendedNarrator": "IMPS-API/507113313567/Ramchandra taterao garudi/SBIN0020301/1701/Utility",
        "uuidNumber": "507113313567"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "475.00DR",
        "amountIndicator": "DR",
        "balance": "4,174,133.16CR",
        "balanceIndicator": "4,174,133.16CR",
        "extendedNarrator": "IMPS-API/507113313435/Khumesh Ambedare/SBIN0000504/6932/Utility",
        "uuidNumber": "507113313435"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:38",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,750.00DR",
        "amountIndicator": "DR",
        "balance": "4,174,608.16CR",
        "balanceIndicator": "4,174,608.16CR",
        "extendedNarrator": "IMPS-API/507113313528/Kochuthressia/SBIN0003285/0108/Utility",
        "uuidNumber": "507113313528"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,179,358.16CR",
        "balanceIndicator": "4,179,358.16CR",
        "extendedNarrator": "IMPS-API/507113313486/PRAVIN SITARAM DEORE/CBIN0280270/1452/Utility",
        "uuidNumber": "507113313486"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "950.00DR",
        "amountIndicator": "DR",
        "balance": "4,180,308.16CR",
        "balanceIndicator": "4,180,308.16CR",
        "extendedNarrator": "IMPS-API/507113313399/Omparkash kumawat/SBIN0031194/2926/Utility",
        "uuidNumber": "507113313399"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:35",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "100.00DR",
        "amountIndicator": "DR",
        "balance": "4,181,258.16CR",
        "balanceIndicator": "4,181,258.16CR",
        "extendedNarrator": "IMPS-API/507113313482/salman/CNRB0004092/1954/Utility",
        "uuidNumber": "507113313482"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:31",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "5,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,181,358.16CR",
        "balanceIndicator": "4,181,358.16CR",
        "extendedNarrator": "IMPS-API/507113313234/MR PANKAJ PANKAJ/SBIN0000001/8754/Utility",
        "uuidNumber": "507113313234"
    },
    {
        "transactionReference": null,
        "valueDate": "120325",
        "postDate": "120325",
        "postTime": "13:09:27",
        "description": "DEBIT VIA INTERNAL T",
        "amount": "4,000.00DR",
        "amountIndicator": "DR",
        "balance": "4,186,358.16CR",
        "balanceIndicator": "4,186,358.16CR",
        "extendedNarrator": "IMPS-API/507113313032/PANKAJ BAIRWA/RMGB0000101/0486/Utility",
        "uuidNumber": "507113313032"
    }
];