import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import swal from "sweetalert";
import Select from "react-select";


const AddFundsAdmin = () => {
  const [ttype, setTtype] = useState([
    // { label: "Add Funds", value: "1" },
    { label: "Reverse Funds", value: "2" },
  ]);
  const [ttype2, setTtype2] = useState([
    { label: "Add Lean", value: "1" },
    { label: "Remove Lean", value: "2" },
  ]);
  const [state, setState] = useState({
    userCode: "",
    utrNo: "",
    amount: "",
    amount2: "",
    bank: null,
    date: "",
    paymode: "",
    ttype: "",
    ttype2: "",
    remaks: "",
    remaks2: "",
  });
  const [userInfo, setUserInfo] = useState(null);
  const [wallet, setWallet] = useState({});
  const [pageAccess, setPageAccess] = useState({});
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const searchUser = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: state.userCode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_user_details_create_load_request_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserInfo(data.data);
      } else {
        swal(
          "User Not Found",
          "Please Enter Valid ID Or Mobile number",
          "error"
        );
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const clearData = () => {
    setState({
      ttype: "",
      amount: "",
      remaks: "",
    });
    setUserInfo(null);
  };

  const validateForm = () => {
    console.log(state);
    if (state.ttype == "") {
      swal("Invalid", "Please Enter Valid Type", "error");
      return;
    } else if (state.amount == "" || state.remarks == "") {
      swal("Invalid", "Fill All Mandatory Fields", "error");
      return;
    }
    addFunds();
  };

  const addFunds = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userInfo.us_code,
        adminid: user.pkid,
        type: state.ttype.value,
        amount: state.amount,
        remaks: state.remaks,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/users/admin_fund_transfer.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        searchUser();
      } else {
        swal("Failed", data.msg, "error");
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      swal("Failed", "Something Went Wrong", "error");
      console.log("error", error);
    }
  };

  const leanAmount = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "userid": userInfo.us_code,
      "adminId": user.pkid,
      "amount": state.amount2,
      "remark": state.remaks2,
      "type": state.ttype2.value,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const res = await fetch("https://apiservices.bumppypay.com/api/Admin/agentLeanAmount", requestOptions);

      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMessage, "success");
        getWalletInfo();
      } else {
        swal("Failed", data.responseMessage, "error");
      }
    } catch (error) {

    }


  }

  const getWalletInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "userid": state.userCode,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const res = await fetch("https://apiservices.bumppypay.com/api/Payment/wallet/getWalletBalance", requestOptions);

      const data = await res.json();
      if (data.status == "0") {
        setWallet(data.data);
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {

    }


  }

  const getPageAccess = (menuId) => {
    const sub_menus_access_info = JSON.parse(sessionStorage.getItem("sub_menus"));
    sub_menus_access_info.forEach((acc) => {
      if (acc.pk_menu_id == menuId) {
        setPageAccess(acc);
        if (acc.str_access_rights == "1") {
          setTtype([...ttype, { label: "Add Funds", value: "1" }])
        }
      }
    })
  }

  useEffect(() => {
    getPageAccess(7);
  }, [])

  console.log(wallet);

  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "1rem" }}>
          <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
            <div className="card-body">
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  Add Funds
                </div>

                <div className="row">
                  <div className="col-6 col-sm-4 col-md-3">
                    <Form.Group>
                      <Form.Label>
                        <span style={{ color: "red" }}>*</span>
                        {`Enter User Code Or Mobile Number`}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User Code Or Mobile Number"
                        value={state.userCode}
                        onChange={(e) => {
                          setState({ ...state, userCode: e.target.value });
                        }}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-6 col-sm-4 col-md-3 d-flex">
                    <Form.Group className="mt-4">
                      <Button
                        onClick={() => {
                          searchUser();
                          getWalletInfo();

                        }}
                        className="btn btn-primary rounded-0"
                      >
                        {" "}
                        Search
                      </Button>
                    </Form.Group>
                    <Form.Group className="mt-4 ml-2">
                      <Button
                        onClick={clearData}
                        className="btn btn-danger rounded-0 ml-3"
                      >
                        {" "}
                        Clear
                      </Button>
                    </Form.Group>
                  </div>
                </div>

                {userInfo && (
                  <div
                    style={{
                      padding: "12px",
                      background: "#3676D8",
                      color: "#FFF",
                      fontWeight: "bolder",
                    }}
                  >
                    <div className="row d-flex">
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User : ${userInfo.us_name} [${userInfo.us_code}]`}
                        </strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User Mobile : ${userInfo.us_loginid}`}
                        </strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong> {`User Type : ${userInfo.usrtype}`}</strong>
                      </div>
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`User Balance : ${twoDecimalPlaces(
                            userInfo.usrbal
                          )}`}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}

                {userInfo && (
                  <div className="row">
                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Amount`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Amount"
                          value={state.amount}
                          onChange={(e) => {
                            setState({ ...state, amount: e.target.value });
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Type`}
                        </Form.Label>
                        <Select
                          options={ttype}
                          value={state.ttype}
                          onChange={(e) => {
                            setState({ ...state, ttype: e });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Remark`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Remark"
                          value={state.remark}
                          onChange={(e) => {
                            setState({ ...state, remarks: e.target.value });
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group className="mt-4">
                        <Button
                          onClick={validateForm}
                          className="btn btn-primary rounded-0"
                        >
                          {" "}
                          Submit
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                )}

                {userInfo && (
                  <div
                    style={{
                      padding: "12px",
                      background: "#3676D8",
                      color: "#FFF",
                      fontWeight: "bolder",
                    }}
                  >
                    <div className="row d-flex">
                      <div className="col-6 col-sm-4 col-md-3">
                        <strong>
                          {" "}
                          {`Lean Amount : ${twoDecimalPlaces(wallet.lean_amount)}`}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}


                {userInfo && (
                  <div className="row">
                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Amount`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Amount"
                          value={state.amount2}
                          onChange={(e) => {
                            setState({ ...state, amount2: e.target.value });
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Type`}
                        </Form.Label>
                        <Select
                          options={ttype2}
                          value={state.ttype2}
                          onChange={(e) => {
                            setState({ ...state, ttype2: e });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Remark`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Remark"
                          value={state.remaks2}
                          onChange={(e) => {
                            setState({ ...state, remaks2: e.target.value });
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-6 col-sm-4 col-md-3">
                      <Form.Group className="mt-4">
                        <Button
                          onClick={leanAmount}
                          className="btn btn-primary rounded-0"
                        >
                          {" "}
                          Submit
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default AddFundsAdmin;
