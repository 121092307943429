import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import { useHistory } from "react-router";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_API,
} from "../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import { COLOR_LOGIN_SCREEN_STRIPE } from "../utils/Themer";
import { formatDateDDMMYY, uniqueID2 } from "../utils/utilFuctions";
import { Stepper as Stepper2 } from "./Stepper";
import { GlobalContext } from "../Context/GlobalState";
import { ADMIN_SESSION_TIMER } from "../utils/Session";
import { useIdleTimer } from "react-idle-timer";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const DocumentUpload = () => {
  const [kycEditDisable, setKycEditDisable] = useState(true);
  const [rejectionSelection, setRejectionSelection] = useState(false);
  const [approvalSelection, setApprovalSelection] = useState(false);
  const [state, setState] = useState({
    remark: "",
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [kycData, setKycData] = useState({});
  const [kycData2, setKycData2] = useState({});
  const [kycData3, setKycData3] = useState({});
  const [uploadedDocs, setUploadedDoc] = useState({
    profile: false,
    POI: false,
    POA_Front: false,
    POA_Back: false,
    sign: false,
    gstdoc: false,
  });
  const [uploadedDocsName, setUploadedDocsName] = useState({
    profile: "",
    POI: "",
    POA_Front: "",
    POA_Back: "",
    sign: "",
    gst: "",
    selfiWithEmployee: "",
    selfiWithDoc: "",
  });
  const [clickedImageURL, setClickedImageURL] = useState("");

  const userId = JSON.parse(localStorage.getItem("kyc"));
  const kyc_by = sessionStorage.getItem("kyc_by");
  const adminId = sessionStorage.getItem("adminId");
  const docId = sessionStorage.getItem("docId");

  const fetchUserKycData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/javascript");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/kyc/fetchkycdata.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 0 && data.data.length > 0) {
        // if (data.data[0].kyc_status == "P" || data.data[0].kyc_status == "A") {
        //   history.push("/kyc/user-kyc-view");
        // }
        setKycEditDisable(data.data[0].relation == "N");
        setKycData({ ...kycData, ...data.data[0], kyc_edit_disabled: data.data[0].relation == "N" });
        setKycData2({ ...kycData, ...data.data[0], kyc_edit_disabled: data.data[0].relation == "N" });
        // setFormValues({
        //   ...formValues,
        //   PAN: data.data[0].pancardno,
        //   ADHAR: data.data[0].aadharcardno,
        //   GST: data.data[0].gstnumber,
        // });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const imagePath = `${BASE_URL_API_TRANXT_API}/users_kyc_doc`;

  const verificationData = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        let selfiWithDoc = "",
          selfiWithEmployee = "";

        if (data.data.relation.includes("|")) {
          selfiWithDoc = data.data.relation.split("|")[0];
          selfiWithEmployee = data.data.relation.split("|")[1];
        }
        setUploadedDocsName({
          ...uploadedDocsName,
          POA_Back: data.data.imgaadharback,
          POA_Front: data.data.imgaadharfront,
          POI: data.data.imgpancard,
          gst: data.data.gstdoc,
          profile: data.data.imguser,
          sign: data.data.signature,
          selfiWithDoc,
          selfiWithEmployee,
        });
        let imguser = `${imagePath}/${MN}_profile.jpeg?v=${uniqueID2()}`;
        if (data.data.imguser != "") {
          imguser = `${imagePath}/${data.data.imguser}.jpeg?v=${uniqueID2()}`;
        }
        let imgpancard = `${imagePath}/${MN}_POI.jpeg?v=${uniqueID2()}`;
        if (data.data.imgpancard != "") {
          imgpancard = `${imagePath}/${data.data.imgpancard
            }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharfront = `${imagePath}/${MN}_POA_Front.jpeg?v=${uniqueID2()}`;
        if (data.data.imgaadharfront != "") {
          imgaadharfront = `${imagePath}/${data.data.imgaadharfront
            }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharback = `${imagePath}/${MN}_POA_Back.jpeg?v=${uniqueID2()}`;
        if (data.data.imgaadharback != "") {
          imgaadharback = `${imagePath}/${data.data.imgaadharback
            }.jpeg?v=${uniqueID2()}`;
        }
        let signature = `${imagePath}/${MN}_sign.jpeg?v=${uniqueID2()}`;
        if (data.data.signature != "") {
          signature = `${imagePath}/${data.data.signature
            }.jpeg?v=${uniqueID2()}`;
        }

        let gstdoc = `${imagePath}/${MN}_gst.jpeg?v=${uniqueID2()}`;
        if (data.data.gstdoc != "") {
          gstdoc = `${imagePath}/${data.data.gstdoc}.jpeg?v=${uniqueID2()}`;
        }

        if (data.data.relation.includes("|")) {
          selfiWithDoc = `${imagePath}/${data.data.relation.split("|")[0]
            }.jpeg?v=${uniqueID2()}`;
          selfiWithEmployee = `${imagePath}/${data.data.relation.split("|")[1]
            }.jpeg?v=${uniqueID2()}`;
        }

        setKycData({
          ...kycData,
          imguser,
          imgpancard,
          imgaadharfront,
          imgaadharback,
          signature,
          gstdoc,
          selfiWithDoc,
          selfiWithEmployee,
        });
        // setUploadedDoc({
        //   profile: data.data.imguser ? true : false,
        //   POI: data.data.imgpancard ? true : false,
        //   POA_Front: data.data.imgaadharfront ? true : false,
        //   POA_Back: data.data.imgaadharback ? true : false,
        //   sign: data.data.signature ? true : false,
        //   gstdoc: data.data.gstdoc ? true : false,
        // });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const verificationData2 = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/Kyc/fetch_kyc_image_data.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        let selfiWithDoc = "",
          selfiWithEmployee = "";

        if (data.data.relation.includes("|")) {
          selfiWithDoc = data.data.relation.split("|")[0];
          selfiWithEmployee = data.data.relation.split("|")[1];
        }

        setUploadedDocsName({
          ...uploadedDocsName,
          POA_Back: data.data.imgaadharback,
          POA_Front: data.data.imgaadharfront,
          POI: data.data.imgpancard,
          gst: data.data.gstdoc,
          profile: data.data.imguser,
          sign: data.data.signature,
          selfiWithDoc,
          selfiWithEmployee,
        });
        setKycData3(data.data);
        let imguser = "";
        if (data.data.imguser != "") {
          imguser = `${imagePath}/${data.data.imguser}.jpeg?v=${uniqueID2()}`;
        }
        let imgpancard = "";
        if (data.data.imgpancard != "") {
          imgpancard = `${imagePath}/${data.data.imgpancard
            }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharfront = "";
        if (data.data.imgaadharfront != "") {
          imgaadharfront = `${imagePath}/${data.data.imgaadharfront
            }.jpeg?v=${uniqueID2()}`;
        }
        let imgaadharback = "";
        if (data.data.imgaadharback != "") {
          imgaadharback = `${imagePath}/${data.data.imgaadharback
            }.jpeg?v=${uniqueID2()}`;
        }
        let signature = "";
        if (data.data.signature != "") {
          signature = `${imagePath}/${data.data.signature
            }.jpeg?v=${uniqueID2()}`;
        }

        let gstdoc = "";
        if (data.data.gstdoc != "") {
          gstdoc = `${imagePath}/${data.data.gstdoc}.jpeg?v=${uniqueID2()}`;
        }

        if (data.data.relation.includes("|")) {
          selfiWithDoc = `${imagePath}/${data.data.relation.split("|")[0]
            }.jpeg?v=${uniqueID2()}`;
          selfiWithEmployee = `${imagePath}/${data.data.relation.split("|")[1]
            }.jpeg?v=${uniqueID2()}`;
        }

        setKycData({
          ...kycData,
          imguser,
          imgpancard,
          imgaadharfront,
          imgaadharback,
          signature,
          gstdoc,
          selfiWithDoc,
          selfiWithEmployee,
        });
        setUploadedDoc({
          profile: data.data.imguser ? true : false,
          POI: data.data.imgpancard ? true : false,
          POA_Front: data.data.imgaadharfront ? true : false,
          POA_Back: data.data.imgaadharback ? true : false,
          sign: data.data.signature ? true : false,
          gstdoc: data.data.gstdoc ? true : false,
          selfiWithDoc: selfiWithDoc ? true : false,
          selfiWithEmployee: selfiWithEmployee ? true : false,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getPaynnowAgentId = async () => {
    console.log("hiii...");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: userId.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/get_paynnow_agentid.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.yesbank_kyc == "Y") {
          history.push("/kyc/user-kyc-view");
        } else {
          history.push("/agent-kyc");
        }
      }
    } catch (error) {
      history.push("/agent-kyc");
      console.error(error);
    }
  };

  // DOCUMENT APPROVE
  const approveAgentDocKYC = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setTransactionLoaderState(true);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        docId: docId,
        remark: state.remark,
        adminid: adminId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/kyc/agent_doc_kyc_approve.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("KYC Approved Successfully", data.data?.result, "success");
        if (kyc_by && kyc_by == "admin") {
          history.push("/admin/all_user_kyc");
        }
      } else {
        swal("KYC Approved Failed", data.data?.result, "error");
      }
      // refresh();
      setTransactionLoaderState(false);
    } catch (error) {
      swal("KYC Approved Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  const rejectAgentDocKYC = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        docId: docId,
        remark: state.remark,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/kyc/agent_doc_kyc_reject.aspx`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data.status == "0") {
        swal("KYC Rejected Successfully", data.data?.result, "success");
        if (kyc_by && kyc_by == "admin") {
          history.push("/admin/all_user_kyc");
        }
      } else {
        swal("KYC Rejected Failed", data.data?.result, "error");
      }
      // refresh();
      setTransactionLoaderState(false);
    } catch (error) {
      swal("KYC Rejection Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    fetchUserKycData();
    verificationData2();
  }, []);

  const redirect = () => {
    const kyc_by = sessionStorage.getItem("kyc_by");
    if (kyc_by && kyc_by == "admin") {
      history.push("/admin/all_user_kyc");
    } else {
      swal("Success", "Document KYC Successfully Done", "success").then(
        (ok) => {
          getPaynnowAgentId();
        }
      );
    }

    //  history.push("/kyc/user-kyc-view");
  };

  const Back = () => {
    history.push("/kyc/document-details");
  };
  const [formValues, setFormValues] = useState({
    PAN: "",
    AdharFront: "",
    AdharBack: "",
    GstDoc: "",
    Userimage: "",
    UserSign: "",
    UserImageWithAdhar: "",
    ShopImage: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const MN = sessionStorage.getItem("MN");
  const steps = localStorage.getItem("steps");

  const convertToBiteArray = (file, callback) => {
    const reader = new FileReader();
    const fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        const arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        var binary = "";
        var len = array.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(array[i]);
        }
        callback(window.btoa(binary));
      }
    };
  };

  const handleClick = (e) => {
    console.log(kycData);
    if (kycEditDisable) {
      swal('Unauthorized', 'KYC images cannot be edited. Please contact the technical team.', 'warning');
      e.preventDefault();
    }
  };

  const ImageUpload = async (e) => {
    if (kycEditDisable) {
      swal('Unauthorized', 'KYC images cannot be edited. Please contact the technical team.', 'warning');
      return;
    }
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    const type = e.target.id;

    if (e.target.files.length > 0) {
      if (e.target.files[0].size > 256000) {
        swal("Invalid", "Image Size should be less than 256 kb", "error");
        return;
      }
      convertToBiteArray(e.target.files[0], (ByteArray) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          imagename: "",
          imagebytedata: ByteArray,
          type: type,
          agentid: MN,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        try {
          fetch(
            "https://apiservices.bumppypay.com/api/Payment/kyc/imageUpload",
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.status == "0") {
                setUploadedDoc({ ...uploadedDocs, [type]: true });

                setUploadedDocsName({
                  ...uploadedDocsName,
                  [type]: data.data,
                });

                documentSubmit(
                  {
                    ...uploadedDocsName,
                    [type]: data.data,
                  },
                  false
                );

                cogoToast.success(data.msg);
              } else {
                cogoToast.error(data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  const ImageUpload2 = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    const type = e.target.id;

    if (e.target.files.length > 0) {
      if (e.target.files[0].size > 256000) {
        swal("Invalid", "Image Size should be less than 256 kb", "error");
        return;
      }
      convertToBiteArray(e.target.files[0], (ByteArray) => {
        var raw = JSON.stringify({
          imagename: `${MN}_${type}`,
          imagebytedata: ByteArray,
        });

        var requestOptions = {
          method: "POST",
          body: raw,
          redirect: "follow",
        };

        try {
          fetch(
            `${BASE_URL_API_TRANXT_API}/api/kyc/uploadimages.aspx`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status == "0") {
                setUploadedDoc({ ...uploadedDocs, [type]: true });
                cogoToast.success(result.msg);
                verificationData();
                // setUploadedImage();
              } else {
                cogoToast.error(result.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    console.log(formValues);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      documentSubmit(uploadedDocsName, true);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!uploadedDocs.POI) {
      errors.PAN = "*Pan is required!";
    }
    if (!uploadedDocs.POA_Front) {
      errors.AdharFront = "*Adhar Front is required";
    }
    if (!uploadedDocs.POA_Back) {
      errors.AdharBack = "*Adhar Back is required!";
    }
    if (!uploadedDocs.profile) {
      errors.Userimage = "*User image is required";
    }
    if (!uploadedDocs.sign) {
      errors.UserSign = "*User Sign is required";
    }
    return errors;
  };

  const documentSubmit = async (docHolder, submit) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: userId.pk_user_id,
        pancard: docHolder.POI,
        aadharFront: docHolder.POA_Front,
        aadharBack: docHolder.POA_Back,
        gstdoc: docHolder.gst,
        userImage: docHolder.profile,
        passportImage: "WEB",
        signature: docHolder.sign,
        selfiWithDoc: docHolder.selfiWithDoc,
        selfiWithEmployee: docHolder.selfiWithEmployee,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/kyc/userKycDocSubmit`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (submit) {
          redirect();
        } else {
          verificationData();
        }
      } else {
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const { removeUserInfo } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    removeUserInfo();
  };

  const onActive = (event) => { };

  const onAction = (event) => { };

  const {
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: ADMIN_SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  console.log(kycData, kycData2, kycData3);

  return (

    <GlobalContext.Consumer>
      {(context) => (
        context.user == null ? <Redirect to="/user/dashboard" /> :
          (<div className="p-4" style={{ fontFamily: "auto" }}>
            {clickedImageURL && (
              <div className="image-overlays" onClick={() => setClickedImageURL("")}>
                <img src={clickedImageURL} alt="Clicked" />
              </div>
            )}
            <div
              className="card"
              style={{ backgroundColor: "aliceblue", textAlign: "center" }}
            >
              {/* <Stepper activeStep={steps} style={{ padding: "15px" }}>
            <Step label="Business Details" />
            <Step label="Personal Details" />
            {/* <Step label="Document Details" />
            <Step label="Document Upload" />
            <Step label="Biometric KYC" />
          </Stepper> */}
              <Stepper2 step={4} />
              <div
                className="card-body m-3 mt-0 pt-0"
                style={{
                  borderRadius: "1rem",
                  boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                }}
              >
                <Row>
                  <Col md={12} lg={12} sm={12}>
                    <h4>
                      <b>Document Upload</b>
                    </h4>
                  </Col>

                  <div
                    className="row"
                    style={{
                      borderRadius: "1rem",
                      border: "2px solid black",
                      boxShadow:
                        "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                    }}
                  >
                    <Col className="text-left" md={12} lg={12} sm={12}>
                      <h5>
                        <strong>Business Details</strong>
                      </h5>
                      <div className="mt-1 p-1">
                        <b>Shop/Business Name: </b>
                        <span>{kycData2.shopname}</span>
                      </div>
                      <div className="mt-1 p-1">
                        <b>Shop Address: </b>
                        <span>{kycData2.shopaddress}</span>
                      </div>
                    </Col>

                    <Row className="text-left">
                      <Col md={12} lg={12} sm={12}>
                        <h5>
                          <strong>Personal Details</strong>
                        </h5>
                      </Col>
                      <Col md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>First Name: </b>
                          <span>{kycData2.userfname} </span>
                        </div>
                      </Col>
                      <Col md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Last Name: </b>
                          <span>{kycData2.userlname}</span>
                        </div>
                      </Col>

                      <Col md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Pan Verified Name: </b>
                          <span>{kycData3.pan_verified_name}</span>
                        </div>
                      </Col>

                      <Col md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Gender: </b>
                          <span>{kycData2.gender}</span>
                        </div>
                      </Col>

                      <Col md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Mobile: </b>
                          <span>{kycData2.usermobile}</span>
                        </div>
                      </Col>
                      <Col md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>DOB: </b>
                          <span>{formatDateDDMMYY(kycData2.dateofbirth)}</span>
                        </div>
                      </Col>
                      <Col md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Email: </b>
                          <span>{kycData2.emailid}</span>
                        </div>
                      </Col>

                      <Col md={12} lg={12} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Home Address: </b>
                          <span>{`${kycData2.useraddress}, ${kycData2.usercity}, ${kycData2.userstate} , ${kycData2.userpincode} `}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row className="text-left">
                      <Col className="text-left" md={12} lg={12} sm={12}>
                        <h5>
                          <strong>Document Details</strong>
                        </h5>
                      </Col>
                      <Col className="text-left" md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Pan Card: </b>
                          <span>{kycData2.pancardno}</span>
                        </div>
                      </Col>
                      <Col className="text-left" md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>Adhar Card: </b>
                          <span>{kycData2.aadharcardno}</span>
                        </div>
                      </Col>
                      <Col className="text-left" md={4} lg={4} sm={12}>
                        <div className="mt-1 p-1">
                          <b>GST Number: </b>
                          <span>{kycData2.gstnumber}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row className="col-12">
                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label className="text-left">
                        <b className="text-left">Passport Size Photo</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.imguser);
                        }}
                        src={kycData.imguser}
                      ></Image>
                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="Userimage"
                        value={formValues.Userimage}
                        id="profile"
                        onChange={ImageUpload}
                        sty
                        className="mx-auto text-center row justify-content-center m-0 p-0"
                      />
                      <p style={{ color: "red" }}>{formErrors.Userimage}</p>
                    </Col>

                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label>
                        <b>Pan Card</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>

                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.imgpancard);
                        }}
                        src={kycData.imgpancard}
                      ></Image>

                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="PAN"
                        value={formValues.PAN}
                        id="POI"
                        onChange={ImageUpload}
                      />
                      <p style={{ color: "red" }}>{formErrors.PAN}</p>
                    </Col>

                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label>
                        <b>Adhar Card Front Side</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.imgaadharfront);
                        }}
                        src={kycData.imgaadharfront}
                      ></Image>
                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="AdharFront"
                        value={formValues.AdharFront}
                        id="POA_Front"
                        onChange={ImageUpload}
                      />
                      <p style={{ color: "red" }}>{formErrors.AdharFront}</p>
                    </Col>
                  </Row>

                  <Row className="mt-2 col-12">
                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label>
                        <b>Adhar Card Back Side</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.imgaadharback);
                        }}
                        src={kycData.imgaadharback}
                      ></Image>
                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="AdharBack"
                        value={formValues.AdharBack}
                        id="POA_Back"
                        onChange={ImageUpload}
                      />
                      <p style={{ color: "red" }}>{formErrors.AdharBack}</p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label>
                        <b>Signature</b>
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.signature);
                        }}
                        src={kycData.signature}
                      ></Image>
                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="UserSign"
                        value={formValues.UserSign}
                        id="sign"
                        onChange={ImageUpload}
                      />
                      <p style={{ color: "red" }}>{formErrors.UserSign}</p>
                    </Col>

                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label>
                        <b>Shop Image</b>
                      </Form.Label>
                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.gstdoc);
                        }}
                        src={kycData.gstdoc}
                      ></Image>
                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="gst"
                        value={formValues.ShopImage}
                        id="gst"
                        onChange={ImageUpload}
                      />
                    </Col>


                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label>
                        <b>LIVE PHOTO WITH DOCUMENTS </b>
                      </Form.Label>
                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.selfiWithDoc);
                        }}
                        src={kycData.selfiWithDoc}
                      ></Image>
                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="selfiWithDoc"
                        value={formValues.selfiWithDoc}
                        id="selfiWithDoc"
                        onChange={ImageUpload}
                      />
                    </Col>
                    <Col className="col-12 col-sm-6 col-md-4 text-left">
                      <Form.Label>
                        <b>AGENT WITH EMPLOYEE PHOTO</b>
                      </Form.Label>
                      <Image
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "30vh",
                          maxWidth: "30vw",
                        }}
                        onClick={() => {
                          setClickedImageURL(kycData.selfiWithEmployee);
                        }}
                        src={kycData.selfiWithEmployee}
                      ></Image>
                      <Form.Control
                        onClick={handleClick}
                        accept=".jpg,.png"
                        type="file"
                        name="selfiWithEmployee"
                        value={formValues.selfiWithEmployee}
                        id="selfiWithEmployee"
                        onChange={ImageUpload}
                      />
                    </Col>


                  </Row>
                  <Col sm={12}>
                    <small>
                      <p className="mt-3" style={{ color: "red" }}>
                        All Fields(*) marked are mandatory
                      </p>
                    </small>
                  </Col>

                  {kyc_by == "admin" && (
                    <React.Fragment>
                      <div className="row w-100">
                        <div className="col-6 mx-auto">
                          <textarea
                                  disabled={kycEditDisable}
                            placeholder={"enter any remarks"}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              setState({ ...state, remark: e.target.value });
                            }}
                          />
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-center">
                            <Button
                              disabled={kycEditDisable}
                              className="btn btn-success rounded-0 m-2"
                              onClick={approveAgentDocKYC}
                            >
                              {" "}
                              Approve
                            </Button>
                            <Button
                              disabled={kycEditDisable}
                              className="btn btn-danger rounded-0 m-2"
                              onClick={rejectAgentDocKYC}
                            >
                              Reject
                            </Button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {/* <Col md={3} lg={3} sm={6} className="mt-4">
                <Button className="btn-block" variant="info" onClick={Back}>
                  Back
                </Button>
              </Col> */}

                  {kyc_by != "admin" && (
                    <Col md={3} lg={3} sm={6} className="mt-4">
                      <Button
                        className="btn-block"
                        variant="success"
                        onClick={handleSubmit}
                      >
                        Next
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>)
      )}
    </GlobalContext.Consumer>



  );
};

export default DocumentUpload;
