import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  formatDateDDMMYY,
  formatDateDDMMYYHHMM,
  uniqueID2,
} from "../../utils/utilFuctions";
import { Link, Redirect, useHistory } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import { basicAuth } from "../../config";
import { CLIENT_CODE, COMPANY_RETAILER_COMPANY_ID } from "../../utils/Client";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { Line, Doughnut } from "react-chartjs-2";
import {
  OverlayTrigger,
  Tooltip,
  ButtonToolbar,
  Popover,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const columns = [
  {
    dataField: "ucode",
    text: "User Details",
  },
  {
    dataField: "usr_mobile",
    text: "Mobile",
  },
  {
    dataField: "us_type",
    text: "User Type",
  },
  {
    dataField: "aadhar_card_no",
    text: "Aadhaar Number",
  },
  {
    dataField: "pan_card_no",
    text: "PAN Card Number",
  },
  {
    dataField: "gst_number",
    text: "GST Number",
  },
  {
    dataField: "shop_name",
    text: "Shop Name",
  },
  {
    dataField: "shopadd",
    text: "Shop Address",
  },
  {
    dataField: "shop_pin_code",
    text: "Shop Pin Code",
  },
  {
    dataField: "shop_city",
    text: "Shop City",
  },
  {
    dataField: "shop_state",
    text: "Shop State",
  },
  {
    dataField: "usParent_code",
    text: "Parent Code",
  },
  {
    dataField: "usParent_name",
    text: "Parent Name",
  },
  {
    dataField: "sales_user_code",
    text: "Sales Code",
  },
  {
    dataField: "sales_user_name",
    text: "Sales Name",
  },
  {
    dataField: "sales_user_mobile",
    text: "Sales Mobile Number",
  },
  {
    dataField: "joiningDate",
    text: "Joining Date",
  },
  {
    dataField: "approved_date",
    text: "Approval Date",
  },
  {
    dataField: "sstatus",
    text: "KYC Status",
  },
  {
    dataField: "yesbankkyc",
    text: "YES BANK KYC Status",
  },
  {
    dataField: "paynnow_agentid",
    text: "YES BANK BC CODE",
  },

];

const spanTdStyle = {
  padding: "5px",
  borderRadius: "5px",
  border: "1px solid",
  backgroundColor: "rgb(222, 244, 237)",
  color: "rgba(43, 193, 85, 1)",
  bordercolor: "rgba(43, 193, 85, 1)",
};

const tdStyleTable = {
  fontFamily: "Poppins",
  fontSize: "0.8rem",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
}

const thStyle = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
};

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const userTypes1 = [
  { label: "All User", value: "" },
  { label: "Approved", value: "Approved" },
  { label: "Pending", value: "Pending" },
  { label: "Rejected", value: "Rejected" },
];

const AllUserKYCTable = ({ setDetails }) => {
  const history = useHistory();
  const { username, password } = basicAuth;
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [inputState, setInputState] = useState({ userType: "" });
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [kycDetails, setKycDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  let fromDate = sessionStorage.getItem("fromDate")
    ? new Date(sessionStorage.getItem("fromDate"))
    : new Date();
  let toDate = sessionStorage.getItem("toDate")
    ? new Date(sessionStorage.getItem("toDate"))
    : new Date();

  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const { Profileinfo } = useContext(GlobalContext);
  const [Status, setStatus] = useState("");

  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [selectedStatus, setSelectedStatus] = useState("");
  const [salesPersonModal, setSalesPersonModal] = useState(false);
  const [salesPersonDetails, setSalesPersonDetails] = useState({});
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const openModal = (details) => {
    setKycDetails(details);
    setModalOpen(true);
  };

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getAllUserKYC = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        usertype: inputState.userType,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_all_user_kyc_list_admin.aspx`,
        requestOptions
      );

      const data = await res.json();

      setData(data.data.map((e) => {
        return { ...e, yesbankkyc: (e.paynnow_agentid && e.yesbankkyc != "") ? "COMPLETE" : "INCOMPLETE", }
      }));
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const aepsEnable = async (details) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8OPYI5vzH4BMrDR9ghp-6y2YqEsuZU2QLm_rKyHRZE5_Je4G7ccSd3Y6Nct8YxwlJQ3TSxSkB14UWLriSIIgZJnx7PzzKqTV7fd84xFNt42K2o5dTNRbKZeLG_8z4f-8lOPZKoCmQj-m9C_yIMABbel1Au6NiN9rxiajZ3KPwIh2AOq-PqzhR0j3m5HD6vZ-Kfd84ErzKDKc1XdeQVUUleU90B8BAG2AgTFSdCqdqDdyEifDjyuMUTM0p2ytcVJrTGwIYj8metmKj2jsgDTeiZGbPl2kOU3KuhGlFSlxBPKv-_H8SNGcbYdY-EznDOAqAzEau6A5JNTdhHhJLFs5n0T4XrYJDDu6A6AcpsJ9OZjS8vFmZ_9TUdhJqduZaglb0ZWnBqrH7B5kHzc_tP9mSDTkkmlPdxuvv7rQzmjHCFOJObwKH7M3Fo6vcg79ls_HZSr8RcOk3_c0fqoH-Hzhs4OHwjX54JwqgO1gGMYrC9qhVQeFQU5pBCvqXnaVs6ayJnhN2tpvyRtIySuXM3j2uWqZ85WmoQRFlVk6D45OD8QNPQgCz9_FBiLV4tQcEe-O7G9XuzdqK8_TzsSPHYn30DwLn6BPIOBvf_9fKCpEEoA7HuJwbyrcQpyj8ap7Cq6O-Ih1viG9wB_TgSme0Fd9wSRCEb-JtoOCCB33fcN0o_6Q"
      );

      var raw = JSON.stringify({
        uid: CLIENT_CODE,
        agentcode: details.uCode,
        companycode: CLIENT_CODE,
        isActive: "1",
        kyc_status: "Y",
        user_id: COMPANY_RETAILER_COMPANY_ID,
        merchantLoginId: details.uCode,
        merchantLoginPin: details.uCode,
        merchantName: details.uName,
        merchantPhoneNumber: details.logId,
        pancard: details.pan_card_no,
        aadharcard: details.aadhar_card_no,
        emailid: details.usr_email,
        fingpay_onboarding: "Y",
        fingpay_kyc: "N",
        bumppy_onboarding: "Y",
        bumppy_kyc: "N",
        bankit_onboarding: "N",
        bankit_kyc: "N",
        pincode: "201301",
        city: details.shopadd,
        district: details.usraddrs,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BASE_URL_PAYNOW}/api/AEPS/kycdataupload`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => console.log("error", error));
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
    }
  };

  const aepsOnboarding = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.com/apibumppypay/v1/web/fingpay/onboardingCron.php",
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data.status) {
        swal("Success", data.data?.remarks, "success");
        // aepsOnboardingSubmit(data.data);
      } else {
        swal("Failed", data?.data?.remarks, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function trimObjectValues(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        trimObjectValues(obj[key]);
      }
    }
  }
  /*
  const aepsOnboardingSubmit = async (data2) => {
    let obj = data2;
    for (let key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        trimObjectValues(obj[key]);
      }
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAZXhhbXBsZS5jb206VGVzdEAxMjM0NQ=="
      );
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8Hsmgpmrd9VLm6VQxL92ArD7Ss5hQymE0v09olctFig0XspoEIy9Vocx4izTE_kln9y9OyzNrnJltoCbqRG1iiGkDAUlgLhtWcLjDinJl3Cd_LqHFCk9tpqs_67gX3T_qSXEvX7VTtmXLMg2nMZsOCwMPbL6GJPPU8UTIPo7ubJFa1jpM-X1jZKEsrLlyO2sOcPmn-Op8T2dgM47H-U_zszxqM-vNLtt5BukFJQzvI0u7UTYyrL3apdznC4i03JhzfCpoSvqvPR5sRtOlrIjDvBAKeFD9rz9ub8EDL_ZV9P2qV6r9VCmZEA4UmTJoFWHwvegtFdteS1GKusBjuL752wawWNxg5mjRNUgWgntJTZPql-fGvgBy14LzFF0TtRVVskh272v0jre-HkuD-iASNIIr9VNLC0DIeh1YFnnxTrrf4vyR8DgKtTdCNZh9zkTV3uDwEnURyy47EcKeftdbNQGxh5MzzVU8_cXGXMSWQO29hC7Jm5H9kI1V6MVpsef72OHpA3O-sctsixq2lFhKZkbvfYVNHFfgKIsjW8DBmNcIiem20TEgvi34coMb0Fj7j5xw3Zpn2JWQA3HX7rSksd_7PUuwq2neyc5YwprbICeR7gz_59M8QwA-CouaZ-uwhYrgvF91ZfGP7S9aqSV_nqqeg9AdVG2dEV5iAIWDm67"
      );

      var raw = JSON.stringify({
        username: "bumppyd",
        password: "796c3ee44232323",
        supermerchantId: 1179,
        latitude: "17.4442806",
        longitude: "78.4809119",
        merchants: [obj],
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.paynnow.com/api/AEPS/kycdatauploadBank",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        swal("Success", data.description, "success");
      } else {
        swal("Failed", data.description, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  */

  const aepsOnboardingSubmit = async (data2) => {
    let obj = data2;
    for (let key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        trimObjectValues(obj[key]);
      }
    }

    try {

      let userid = obj.merchantLoginId.toString().split("|")[0];
      let holderName = obj.merchantLoginId.toString().split("|")[1];
      let accountNo = obj.merchantLoginId.toString().split("|")[2];
      let ifscCode = obj.merchantLoginId.toString().split("|")[3];
      let bankName = obj.merchantLoginId.toString().split("|")[4];
      let latitude = obj.merchantLoginId.toString().split("|")[5];
      let longitude = obj.merchantLoginId.toString().split("|")[6];

      const merchant = {
        merchantLoginId: userid, 
        merchantLoginPin: userid,
        firstName: obj.merchantName.split(" ")[0],
        lastName: obj.merchantName.split(" ")[1],
        middleName:obj.merchantName.split(" ")[1],
        merchantPhoneNumber: obj.merchantPhoneNumber,
        merchantAddress: {
            merchantAddress1:obj.merchantAddress?.merchantAddress,
            merchantAddress2: obj.merchantAddress?.merchantAddress,
            merchantState: obj.merchantState,
            merchantCityName: obj.merchantCityName,
            merchantDistrictName: obj.merchantDistrictName,
            merchantPinCode: obj.merchantPinCode,
            merchantState :obj.merchantBranch
        },
        companyLegalName: obj.companyLegalName,
        userType: "Utilities",
        companyType: 4900,
        emailId: obj.emailId,
        certificateOfIncorporationImage: "False",
        kyc: {
            userPan: obj.kyc?.userPan,
            aadhaarNumber: obj.kyc?.aadhaarNumber,
            gstinNumber: "09AAWFG7763Q1Z1",
            companyOrShopPan:  obj.kyc?.userPan,
            shopAndPanImage: "False"
        },
        settlementV1: {
            companyBankAccountNumber: accountNo,
            bankIfscCode: ifscCode,
            companyBankName: bankName,
            bankAccountName: holderName
        },
        tradeBusinessProof: "True",
        termsConditionCheck: "True",
        cancelledChequeImages: "True",
        physicalVerification: "True",
        videoKycWithLatLongData: "True",
        merchantKycAddressData: {
            shopAddress:obj.merchantAddress?.merchantAddress,
            shopCity: obj.merchantCityName,
            shopDistrict: obj.merchantDistrictName,
            shopState: obj.merchantBranch,
            shopPincode: obj.merchantPinCode,
            shopLatitude: latitude,
            shopLongitude: longitude
        }
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        username: "bumppyd",
        // password: "796c3ee44232323",
        supermerchantId: 1179,
        latitude: "17.4442806",
        longitude: "78.4809119",
        // merchants: [obj],
        password: "145c5e8f71f46fc7d33407c910ed5d1e",
        ipAddress: "35.200.161.138",
        merchant: merchant
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.com/apibumppypay/v1/web/fingpay/merchantonboardv2.php",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "true") {
        swal("Success", data.message, "success");
      } else {
        swal("Failed", data.message, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    const modified = data.filter((e) => {
      return Status == "" || e.sstatus == Status;
    });
    setDataToDisplay(modified);
  }, [Status]);
  useEffect(() => {
    getAllUserKYC();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "35%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };
  const positionStyle = {
    position: "absolute",
    top: -40,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -28,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle2 = {
    position: "absolute",
    top: -54,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };


  const navigateToReviewReport = () => {
    history.push('/admin/user-review-details');
  };


  return (
    <>
      <UserKycDetails
        details={kycDetails}
        isModalOpen={modalOpen}
        setModalOpen={(modalState) => {
          setModalOpen(modalState);
        }}
        refresh={getAllUserKYC}
      />
      <SalesPersonAssign
        details={salesPersonDetails}
        isModalOpen={salesPersonModal}
        setModalOpen={(modalState) => {
          setSalesPersonModal(modalState);
        }}
        refresh={getAllUserKYC}
      />

      <div className="row m-0 p-0 px-3 mb-3">
        <div className="col-3 m-0 p-0">
          <DashboardCardKYC
            label={"Count"}
            label1={"Success"}
            data1={summary.Success}
            label2={"Pending"}
            data2={summary.Pending}
            label3={"Reject"}
            data3={summary.Reject}
          />
        </div>

        <div className="col-7 pl-3 m-0 p-0">
          <div className="row m-0 p-0">
            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div className="col-3 m-0 p-0 px-3 ">
              <Form.Group>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    sessionStorage.setItem("fromDate", date.toISOString());
                  }}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div className="col-3 m-0 p-0 pr-2">
              <Form.Group>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    sessionStorage.setItem("toDate", date.toISOString());
                  }}
                  className="form-control  rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>
            <div
              className="m-0 p-0 hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `User_KYC_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div
                div
                style={{ color: "rgba(255, 255,255, 1)" }}
                className="hover-text bg-dark "
              >
                Export to Excel
              </div>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-3  p-0 m-0">
              <Form.Group className="m-0 p-0">
                <Select
                  placeholder="User Type"
                  options={userTypes}
                  onChange={(e) => {
                    setInputState({ ...inputState, userType: e.value });
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-3 m-0 p-0 pl-3 ">
              <Form.Group>
                <Select
                  placeholder="Status"
                  options={userTypes1}
                  onChange={(e) => {
                    setStatus(e.value);
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-3 m-0 p-0 pl-3 pr-3">
              <Form.Group>
                <Button
                  className="rounded-50 btn btn-primary btn-lg btn-block "
                  onClick={getAllUserKYC}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>

      <hr className="m-0 p-0" />

      <div className="scroll-inner admin-fix-width borderless-table">
        <table className="my-table transparent-table text-center border-bottom ">
          <tr className="borderless-table text-center border-bottom">
            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Sr"}
            </th> */}

            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"User Details"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"ID Cards"}
            </th>
            {/* <th className='text-center' style={{ fontSize: "0.9rem" }}> {"Firm Name"}</th> */}
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Pan Verified Name"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Sales Person"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"State"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Created On"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Updated On"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Doc KYC Status"}
            </th>

            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Yes Bank KYC"}
            </th> */}
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"KYC Remarks"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Approval Date"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Approved By"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Device"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={thStyle}
            >
              {" "}
              {"Action"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
              {/* <td
                className="text-center m-0 p-0  px-1 "
                style={tdStyleTable}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  {ind + 1}
                </div>
              </td> */}
              <td
                className="text-center m-0 p-0  px-1 "
                style={tdStyleTable}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0" >
                  <span className="text-center" onClick={() => {
                   // setDetails({...e});

                  }}>{`${e.ucode}`}</span>
                  <span className="text-center btn-block p-2">
                    {e.us_type}
                  </span>
                  <span className="text-center">{`${e.usr_mobile}`}</span>

                </div>
              </td>
              <td
                className="text-center m-0 p-0  px-1"
                style={tdStyleTable}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">{`GST : ${e.gst_number}`}</span>
                  <span className="text-center">{`PAN : ${e.pan_card_no}`}</span>
                  <span className="text-center">{`Aadhaar : ${e.aadhar_card_no}`}</span>
                </div>
              </td>

              <td
                className="text-center m-0 p-0  px-1"
                style={tdStyleTable}
              >
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  {e.pan_verified == "Y" ? (
                    <span className="text-center">{e.pan_verified_name}</span>
                  ) : (
                    <span className="  text-center btn-block p-2">
                      Not Verified
                    </span>
                  )}
                </div>
              </td>
              <td
                className="text-center m-0 p-0  px-1"
                style={tdStyleTable}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">{`${e.sales_user_name}`}</span>
                  <span className="text-center">{`${e.sales_user_code}`}</span>
                  <span className="text-center">
                    {" "}
                    {`${e.sales_user_mobile}`}
                  </span>
                </div>
              </td>
              <td
                className="text-center m-0 p-0  px-1"
                style={tdStyleTable}
              >
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">{e.shop_state}</span>
                </div>
              </td>
              <td
                className="text-center m-0 p-0  px-1"
                style={tdStyleTable}
              >
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">
                    {formatDateDDMMYYHHMM(e.joiningDate)}
                  </span>
                </div>
              </td>
              <td
                className="text-center m-0 p-0  px-1"
                style={tdStyleTable}
              >
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">
                    {formatDateDDMMYYHHMM(e.dindate)}
                  </span>
                </div>
              </td>

              <td
                className="text-center m-0 p-0 px-1"
                style={tdStyleTable}
              >
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  {e.sstatus == "Approved" ? (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgb(222, 244, 237)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                      }}
                      className="badge  btn-block p-2"
                    >
                      {`${e.sstatus}`}
                    </span>
                  ) : e.sstatus == "Rejected" || e.sstatus == "Reject" ? (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(255, 46, 46, 0.1)",
                        color: "rgba(255, 46, 46, 1)",
                        bordercolor: "rgba(255, 46, 46, 1)",
                      }}
                      className="badge btn-block p-2"
                    >
                      {`${e.sstatus}`}
                    </span>
                  ) : e.sstatus == "Re Submitted" ||
                    e.sstatus == "Re Submitted" ? (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(254, 128, 36, 0.1)",
                        color: "rgba(254, 128, 36, 1)",
                        bordercolor: "rgba(254, 128, 36, 1)",
                      }}
                      className="badge  btn-block p-2"
                    >
                      {`${e.sstatus}`}
                    </span>
                  ) : (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(254, 128, 36, 0.1)",
                        color: "rgba(254, 128, 36, 1)",
                        bordercolor: "rgba(254, 128, 36, 1)",
                      }}
                      className="badge btn-block p-2"
                    >
                      {`${e.sstatus}`}
                    </span>
                  )}
                </div>
              </td>

              {/* <td
                className="text-center m-0 p-0 px-1 "
                style={tdStyleTable}
              >
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  {e.paynnow_agentid && e.paynnow_agentid != "" ? (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgb(222, 244, 237)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                      }}
                      className="badge  btn-block p-2"
                    >
                      {`Complete`}
                    </span>
                  ) : (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(255, 188, 0, 0.1)",
                        color: "rgba(255, 188, 0, 1)",
                        bordercolor: "rgba(255, 188, 0, 1)",
                      }}
                      className="badge  btn-block p-2"
                    >
                      {`InComplete`}
                    </span>
                  )}
                </div>
              </td> */}

              <td style={{ fontSize: "0.8rem", maxWidth: "15rem" }}>
                {e.kyc_remark}
              </td>

              <td
                className="text-center m-0 p-0 "
                style={tdStyleTable}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">{`${e.approved_date}`}</span>
                </div>
              </td>

              <td
                className="text-center m-0 p-0 "
                style={tdStyleTable}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">{`${e.approved_by_user_name}`}</span>
                  <span className="text-center">{`${e.approved_by_user_code}`}</span>
                  <span className="text-center">{`${e.approved_by_user_mobile}`}</span>
                </div>
              </td>

              <td
                className="text-center m-0 p-0 "
                style={tdStyleTable}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <span className="text-center">{`${e.idnewindia.toString().split("|")[1]
                    }`}</span>
                </div>
              </td>

              <td
                className="text-center m-0 p-0 "
                style={{
                  width: "12rem",
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div className="d-flex flex-column align-items-center  p-0 m-0">
                  <div className="row  block">

                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-0 px-1 py-1"
                    >
                      {" "}
                      <div
                        onClick={() => {
                          aepsOnboarding(e.fk_user_id);
                        }}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/AEPS OnBoarding.png")}
                          style={{ width: "" }}
                        />
                        {/* <div style={{"fontFamily":"Poppins",  color:'rgba(0, 0, 0, 1)', }} className="hover-text"><div  >AEPS Onboarding</div></div> */}

                        <div
                          style={positionStyle}
                          className=" hover-text  bg-dark "
                        >
                          AEPS On Boarding
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-0 px-1 py-1"
                    >
                      {" "}
                      <div
                        onClick={() => {
                          setSalesPersonDetails(e);
                          setSalesPersonModal(true);
                        }}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Asign Sales Person.png")}
                          style={{ width: "" }}
                        />
                        {/* <div style={{"fontFamily":"Poppins",  color:'rgba(0, 0, 0, 1)', }} className="hover-text"><div  > Assign Sales</div><div >  Person</div></div> */}
                        <div
                          style={positionStyle2}
                          className="hover-text bg-dark text-center "
                        >
                          {" "}
                          Assign Sales Person
                        </div>
                      </div>
                    </Button>
                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-0 px-1 py-1"
                    >
                      {" "}
                      <div
                        onClick={(evt) => {
                          evt.preventDefault();
                          localStorage.setItem(
                            "kyc",
                            JSON.stringify({ ...user, pk_user_id: e.fk_user_id })
                          );
                          sessionStorage.setItem("kyc_by", "admin");
                          sessionStorage.setItem("adminId", user.pkid);
                          sessionStorage.setItem("docId", e.pk_doc_id);

                          history.push("/kyc/bussiness-details");
                        }}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Edit Details.png")}
                          style={{ width: "" }}
                        />
                        {/* <div style={{"fontFamily":"Poppins", color:'rgba(0, 0, 0, 1)', }} className="hover-text"><div  >Edit</div><div >Details</div></div> */}
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark text-center px-2 py-1 mb-2"
                        >
                          Details
                        </div>
                      </div>
                    </Button>

                    <Button
                     style={{
                      // paddingLeft:"3px",
                      // padding: "5px",
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                      className="btn btn-sm  rounded-100 m-0 p-0 px-1 py-1"
                    >
                      {" "}
                      <div
                          onClick={navigateToReviewReport}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Asign Sales Person.png")}
                          style={{ width: "" }}
                        />
                        {/* <div style={{"fontFamily":"Poppins",  color:'rgba(0, 0, 0, 1)', }} className="hover-text"><div  > Assign Sales</div><div >  Person</div></div> */}
                        <div
                          style={positionStyle2}
                          className="hover-text bg-dark text-center "
                        >
                          {" "}
                          Agent KYC document
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>

      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const UserKycDetails = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const reasons = [
    "Documents are not clear",
    "detail mismatched",
    "PAN Card Not Provided",
    "Aadhaar Card Not Provided",
    "User Image Not Found",
  ];
  const [state, setState] = useState({
    remark: reasons[0],
    docId: "",
  });
  const [rejectionSelection, setRejectionSelection] = useState(false);
  const [approvalSelection, setApprovalSelection] = useState(false);
  const approveAgentDocKYC = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setTransactionLoaderState(true);
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        docId: state.docId,
        remark: state.remark,
        adminid: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/kyc/agent_doc_kyc_approve.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("KYC Approved Successfully", data.data?.result, "success");
      } else {
        swal("KYC Approved Failed", data.data?.result, "error");
      }
      setModalOpen(false);
      refresh();
      setTransactionLoaderState(false);
    } catch (error) {
      swal("KYC Approved Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  const rejectAgentDocKYC = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        docId: state.docId,
        remark: state.remark,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/kyc/agent_doc_kyc_reject.aspx`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data.status == "0") {
        swal("KYC Rejected Successfully", data.data?.result, "success");
      } else {
        swal("KYC Rejected Failed", data.data?.result, "error");
      }
      setModalOpen(false);
      refresh();
      setTransactionLoaderState(false);
    } catch (error) {
      swal("KYC Rejection Failed", "Error From Server", "error");
      setTransactionLoaderState(false);
    }
  };

  const prepareImageUrl = (url) => {
    console.log({ url, check: url.toString().includes(".jpeg.jpeg") });
    let resultUrl = "";
    if (url.toString().includes(".jpeg.jpeg")) {
      resultUrl = url.toString().slice(0, url.length - 5);
    } else {
      resultUrl = url;
    }
    console.log(resultUrl);
    return resultUrl + `?v=${uniqueID2()}`;
  };

  useEffect(() => {
    if (details) {
      setState({
        ...state,
        docId: details.pk_doc_id,
        remark: "",
      });
    }
  }, [details]);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="xl"
    >
      <Modal.Body
        style={{ width: "100%", display: "block" }}
        className="account"
      >
        <div
          style={{
            padding: "12px",
            background: "#3676D8",
            color: "#FFF",
            fontWeight: "bolder",
          }}
          className="d-flex justify-content-between"
        >
          <strong> User KYC Details</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            X
          </strong>
        </div>

        {details && (
          <div className="row">
            <div className="col-12">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-12 text-center">
                  <strong style={{ fontSize: "2rem" }}>
                    {" "}
                    {details.shop_name}
                  </strong>
                </div>
                <table>
                  <tr>
                    <td>
                      <strong>Shop Address : </strong>
                    </td>
                    <td>
                      {" "}
                      <span>{` ${details.shopadd}`}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Agent Name : </strong>
                    </td>
                    <td>
                      <strong>
                        {`${details.usr_first_name} ${details.usr_last_name}`}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Gender : </strong>
                    </td>
                    <td>
                      <span>{` ${details.gender}`}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>DOB : </strong>
                    </td>
                    <td>
                      <span>{` ${formatDateDDMMYY(details.userdob)}`}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>User Address : </strong>
                    </td>
                    <td>
                      <span>{`${details.usraddrs}`}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Pan Card : </strong>
                    </td>
                    <td>
                      <span>{` ${details.pan_card_no}  `}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Aadhaar Card : </strong>
                    </td>
                    <td>
                      <span>{` ${details.aadhar_card_no}  `}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>GST : </strong>
                    </td>
                    <td>
                      <span>{`${details.gst_number}`}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div className="col-12">
              <div className="row g-2">
                <div className="col-6 border border-primary">
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={prepareImageUrl(details.usrimage)}
                  />
                </div>
                <div className="col-6 border border-primary">
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={prepareImageUrl(details.img_pan_card)}
                  />
                </div>
                <div className="col-6 border border-primary">
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={prepareImageUrl(details.img_aadhar_front)}
                  />
                </div>
                <div className="col-6 border border-primary">
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={prepareImageUrl(details.img_aadhar_back)}
                  />
                </div>
                <div className="col-6 border border-primary">
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={prepareImageUrl(details.pdf_gst_doc)}
                  />
                </div>
                <div className="col-6 border border-primary">
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={prepareImageUrl(details.pdf_gst_doc)}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 d-flex flex-column">
              {/* <Button
                className="btn btn-success rounded-0 m-2"
                disabled={
                  details.sstatus == "Approved" || details.sstatus == "Rejected"
                }
                onClick={approveAgentDocKYC}
              >
                {" "}
                Approve
              </Button> */}

              {approvalSelection ? (
                <div className="d-flex">
                  <textarea
                    onChange={(e) => {
                      setState({ ...state, remark: e.target.value });
                    }}
                  />
                  {/* <select
                    className="rounded-0 border-primary mx-1"
                    aria-label="offset"
                    onChange={(e) => {
                      setState({ ...state, remark: e.target.value });
                    }}
                  >
                    {reasons.map((offset) => (
                      <option value={offset}>{offset}</option>
                    ))}
                  </select> */}
                  <Button
                    className="btn btn-success rounded-0 m-2"
                    disabled={
                      details.sstatus == "Approved" ||
                      details.sstatus == "Rejected"
                    }
                    onClick={approveAgentDocKYC}
                  // disabled={
                  //   details.sstatus == "Approved" ||
                  //   details.sstatus == "Rejected"
                  // }
                  >
                    {" "}
                    Approve
                  </Button>
                </div>
              ) : (
                <Button
                  className="btn btn-success rounded-0 m-2"
                  onClick={() => {
                    setApprovalSelection(true);
                  }}
                >
                  {" "}
                  Approve
                </Button>
              )}

              {rejectionSelection ? (
                <div className="d-flex">
                  <textarea
                    onChange={(e) => {
                      setState({ ...state, remark: e.target.value });
                    }}
                  />
                  {/* <select
                    className="rounded-0 border-primary mx-1"
                    aria-label="offset"
                    onChange={(e) => {
                      setState({ ...state, remark: e.target.value });
                    }}
                  >
                    {reasons.map((offset) => (
                      <option value={offset}>{offset}</option>
                    ))}
                  </select> */}
                  <Button
                    className="btn  btn-danger btn-block rounded-0"
                    onClick={rejectAgentDocKYC}
                  // disabled={
                  //   details.sstatus == "Approved" ||
                  //   details.sstatus == "Rejected"
                  // }
                  >
                    Reject
                  </Button>
                </div>
              ) : (
                <Button
                  className="btn btn-danger rounded-0 m-2"
                  onClick={() => {
                    setRejectionSelection(true);
                  }}
                >
                  {" "}
                  Reject
                </Button>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const SalesPersonAssign = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [salesPerson, setSalesPerson] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState({});
  const [state, setState] = useState({
    userId: "",
  });

  const assignSalesPerson = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: state.userId,
        salesid: selectedSalesPerson.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/sales/assign_sales_person.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
        setModalOpen(false);
        refresh();
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllSalesPerson = async () => {
    try {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      const res = await fetch(
        "https://apitranxt.bumppypay.com/api/sales/get_all_sales_person.aspx",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        const modified = data.data.map((e) => {
          return {
            ...e,
            label: `${e.sales_name} [${e.sales_code}]`,
            value: e.sales_code,
          };
        });
        setSalesPerson(modified);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (details) {
      setState({
        ...state,
        userId: details.fk_user_id,
      });
    }
    getAllSalesPerson();
  }, [details]);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="xl"
    >
      <Modal.Body
        style={{ width: "100%", display: "block", overflowY: "hidden" }}
        className="account"
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: "600",
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "left",
            padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontWeight: "bolder",
            fontfamily: "Poppins !impotent",
            fontSize: "28px",
          }}
          className="d-flex justify-content-between"
        >
          <div> Sales Person Assign</div>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            <img
              src={require("../../../assets/images/Vector.png")}
              style={{ width: "" }}
            />
          </strong>
        </div>
        <div className="row">
          <div className="col p-0 mr-2">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Select Sales Person`}
              </Form.Label>
              <Select
                options={salesPerson}
                value={selectedSalesPerson}
                onChange={(e) => {
                  setSelectedSalesPerson(e);
                }}
              />
            </Form.Group>
          </div>
          <div className="col p-0 m-4">
            <Button onClick={assignSalesPerson} className="p-2 px-4">
              Assign
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const DoughnutChart = ({ data1, data2, data3 }) => {
  const defaultData = {
    datasets: [
      {
        data: [100],
        backgroundColor: ["#D9D9D9"], // Use gray color for the chart
      },
    ],
  };

  const doughnutPieData = {
    datasets: [
      {
        data: [data1, data2, data3],

        backgroundColor: ["#0ACF97", "#FFBC00", "rgba(250, 92, 124, 1)"],
        hoverBackgroundColor: ["#0ACF97", "#FFBC00", "#727CF4"],
      },
    ],
  };

  // const chartData = data1.toString().trim()=="" ? defaultData :doughnutPieData;

  const doughnutPieOptions = {
    paddingAngle: 0,
    cutoutPercentage: 60,
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },

    arc: {
      spacing: 0, // Set spacing to 0 to remove the space between colors
    },
  };

  return (
    <div className="">
      <div className=" w-mx h-mx  ">
        <Doughnut data={doughnutPieData} options={doughnutPieOptions} />
      </div>
    </div>
  );
};

const DashboardCardKYC = ({
  label,

  data1,
  data2,
  data3,
}) => {
  return (
    <>
      <div className="card m-0 p-0 px-3 py-2 ">
        <h4 className="card-title p-0 text-dark fw-bold  m-0 ">{label}</h4>

        <hr />

        <div className="row p-0 m-0 align-items-center py-1">
          <div className="col p-0 m-0">
            <SquareColoredIcon
              color="#0ACF97"
              iconName="heart-fill"
              text="Success"
            />
          </div>
          <div className="col p-0 m-0 justify-content-end">{data1}</div>
        </div>

        <div className="row p-0 m-0 align-items-center py-1">
          <div className="col p-0 m-0">
            <SquareColoredIcon
              color="#FFBC00"
              iconName="heart-fill"
              text="Pending"
            />
          </div>
          <div className="col p-0 m-0">
            {data2}
            {/* <ProgressBar value={data2} colour={"bg-warning"} /> */}
          </div>
        </div>

        <div className="row p-0 m-0 align-items-center py-1">
          <div className="col p-0 m-0">
            <SquareColoredIcon
              color="#FA5C7C"
              iconName="heart-fill"
              text="Fail"
            />
          </div>
          <div className="col p-0 m-0 md-1">
            {data3}
            {/* <ProgressBar value={data3} colour={"bg-danger"} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

const SquareColoredIcon = ({ color, iconName, text }) => {
  const iconStyle = {
    backgroundColor: color,
    width: "11px",
    height: "11px",
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    borderRadius: "2px",
    marginright: "2px",
  };

  return (
    <div className=" mr-2" style={iconStyle}>
      <div className="mr-2">
        <i
          className={`bi-${iconName}`}
          style={{ color: "white", fontSize: "24px" }}
        ></i>
      </div>

      <div className="ml-2 " style={{ fontSize: "15px" }}>
        {text}
      </div>
    </div>
  );
};

export default AllUserKYCTable;
