import React, { useState, useEffect, useContext } from "react";
import JsonTable from "./JsonTable";

const OrderApi = () => {

    const [showModal, setShowModal] = useState(false);
    let data = {
        "status": "0",
        "msg": "Success",
        "data": [
            {
                "id": "1",
                "service": "RECHARGE",
                "provider": "AIRTEL DTH",
                "service_alias": "AIRTEL",
                "slabs": [
                    {
                        "id": "1",
                        "charges": "0.000000",
                        "ret_com": "2.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "2",
                        "charges": "0.000000",
                        "ret_com": "1.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "3",
                        "charges": "0.000000",
                        "ret_com": "2.300000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "4",
                        "charges": "0.000000",
                        "ret_com": "2.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "5",
                        "charges": "0.000000",
                        "ret_com": "2.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "6",
                        "charges": "0.000000",
                        "ret_com": "2.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "7",
                        "charges": "0.000000",
                        "ret_com": "2.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "8",
                        "charges": "0.000000",
                        "ret_com": "2.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    },
                    {
                        "id": "9",
                        "charges": "0.000000",
                        "ret_com": "2.500000",
                        "dist_com": "0.500000",
                        "cnf_com": "0.020000",
                        "slab_from": "0.000000",
                        "slab_to": "0.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    }
                ]
            },
            {
                "id": "10",
                "service": "DMT",
                "provider": "DMT",
                "service_alias": "DMT",
                "slabs": [
                    {
                        "id": "10",
                        "charges": "10.000000",
                        "ret_com": "4.000000",
                        "dist_com": "1.000000",
                        "cnf_com": "1.000000",
                        "slab_from": "100.000000",
                        "slab_to": "1000.000000",
                        "status": "Y",
                        "indate": "3/28/2024 10:04:47 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "Y"
                    },
                    {
                        "id": "11",
                        "charges": "1.000000",
                        "ret_com": "0.500000",
                        "dist_com": "0.200000",
                        "cnf_com": "0.030000",
                        "slab_from": "1001.000000",
                        "slab_to": "5000.000000",
                        "status": "Y",
                        "indate": "3/28/2024 9:43:36 AM",
                        "update": "3/28/2024 9:32:00 AM",
                        "updated_by": "9",
                        "fk_group_id": "1",
                        "charge_type": "P"
                    }
                ]
            }
        ]
    }
    return (
        <div>
            <div>
                <JsonTable data={data} />
            </div>
        </div>
    );
};

export default OrderApi;