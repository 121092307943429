import React, { useState,useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { basicAuth } from "../../config";
import Select from "react-select";
import cogoToast from "cogo-toast";
import { BASE_URL_PAYNOW } from "../../utils/Constants";
import { OFFLINE_TEXT, ONLINE_TEXT } from "../../utils/StringConstants";


const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};



const LifeSidebar = ({ details }) => {
  const { username, password } = basicAuth;
    const [state, setState] = useState({
        provider: "",
        consumer: "",
        mobileNumber: "",
        custparams: ""
    });
    const [providers, setProviders] = useState([]);
    const [fetchButtonText, setFetchButtonText] = useState("Fetch Bill");
    const [fetchButtonDisable, setFetchButtonDisable] = useState(false);
    const [billDetails, setBillDetails] = useState(null);
    const [PayClicked, setPayClicked] = useState(false);
    const [CustP, setCustP] = useState([]);

    console.log(billDetails);

    const payBill = async () => {
        setPayClicked(true)
        const biller = state.provider;
        if (biller.length == 0) {
            return;
        }
        if (!billDetails) return;
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Cookie",
            ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq7E_u804ASEqPvM_yW_G_4eXqt18Wn9I-n8I3WtQtS1k63fuzYkZ3II2cGaUfAh06-OButEn8YXvZ_OKTOUg7gP0eyR30BQ_8eI347G7RyBVkL94bbcqn4rlloSevdhRK_itdZf7Cy1pikoJc95VBZtpNVRo8GfCCremQfVWnDGdC-uhQO4_iWArZNQ52pD6yFCxKrKQMZoDpLPBgU_RqwND6lpbx4PqGA89-SxvMRcqNeUppHj8jYHVcp1CnK1n1ncVhEtsR0luOqY1IHgz_J-pRI-lUsGJUDsgFNCww0eb7Mlk8LWwiu4qgYXpPYLlBZf0K7b2mTMgNpH25rMS4-VahYcpxG4zXe1vKHCEHeR7MjVXiAwNh_mdR2GXlgtM9FZ3bOSHjwCYuB6J6uzOd4gNgQcUWzkM1TJ_Fcq04jq6pdB2Ne-aN8XkGNAxnrVQ4SPVwVh-NkRSZYXpdS0XG2r658waXxYLVif7Sw40A-M3V1tLMqWwNSu2rMg5NFhDevaKKiJXcuqHXdrjRSOWLNgkPHS5lmjY11bV0QenW9H7Ahv3xbre32MmYrHlkX7HxKXtlS-uTURS1gQT0_bwPBt3webYhgPJMO8wuIP1HCTlsD98IkmuLeiQ5sSuaylpGJuo22sseXh04qbA3aj_8uo"
        );
        let params = [];
        CustP.forEach((param) => {
            if (param.optional != "true") {
                params.push({
                    name: param.paramName,
                    value: param.value
                });
            }
        })

        var raw = JSON.stringify({
            amount: billDetails.billerResponse.amount,
            mobileNo:  "8700601581",
            billDetails: {
                billerId: biller.billerId,
                customerParams: params
            },
            refId: billDetails.refId,
            isDirectPay: true,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        try {
            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Biller/BillPay`,
                requestOptions
            );
            const raw = await res.text();
            const data = JSON.parse(raw);
            console.log(data);
            setPayClicked(false);
            cogoToast.info(data.status)
        } catch (error) {
            console.log("error", error);
        }
    };
    console.log(billDetails);

    const fetchBill = async () => {
        const biller = state.provider;
        if (biller.length == 0) {
            return;
        }
        setFetchButtonDisable(true);
        setBillDetails(null);
        setFetchButtonText("Fetching bill ...");
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Cookie",
            ".AspNetCore.Identity.Application=CfDJ8PA2Dduz6XVGpbQn-N2Kqq5gCNuTSX1hZVmzSqRADx47lqe6bGFocHiTHywahzfmyy_hxGzt34-c9e73A__uQhz1K_y2TKvnqIc0lLSVxenEuk9l6kzurFY1os90YhONj1qkI2AbuInUzp0l4WuKcQVbg1geMa3e35cjnGSbkRMMjfCOf7VdYuQT1xp3H4r8QBXN6CYFJLFlHp7aL0YZR-JJ3TSxrfTn_-54gPPl852sLVcjFIeMOdXJTu1vpSHK9FdV93vouzmDtYSILdUCqjJ4rZ-rw-c-5Jt6wlqfj81tjbjRGTnDxMqa1y9PF8SeKzHFVmwvvB86NCfk6X12kl0Z6DwaVY2mnoalgrYPP3oEu4jZIK-QA3BE_g15RP6qDxa4g6ePtn_Bn46hjTaY6a-MN_0uydHiJ51ehGQ5eX9sFEV--g-KOo5ty5EUGD2SjX5t5zPBnPLiwOx0q2aFMu5ww8BC7u3OmsIYYHNq59zIG8plliQCkkxPaL9NX3MdnHwvziP5uQUTS0NnRynMsz86khYMnSiA1y9KL1zQKkvxQQKLp-V8F7hoAfn7K_r0yoNpHECXMkLjAv1c_9LsAzc0FNmvY9fOGtwIBIzZE2Cg1ZPB9m6IgADqBv5mUEKAfENApiWviasg1BPjtOIIJgcUdr4lpxMofSD9_b0-qJNv"
        );

        console.log(biller);
        let params = [];
        CustP.forEach((param) => {
            if (param.optional != "true") {
                params.push({
                    name: param.paramName,
                    value: param.value
                },
                {
                    name: "DOB (DD-MMM-YYYY)",
                    value: "05-MAY-1982"
                });
            }
        })

        var raw = JSON.stringify({
            customerMobileNo: "8700601581",
            billDetails: {
                billerId: biller.billerId,
                customerParams: params
            },
        });

        console.log(raw);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        try {
            const res = await fetch(
                `${BASE_URL_PAYNOW}/api/Biller/BillFetch`,
                requestOptions
            );
            const raw = await res.text();
            const data = JSON.parse(raw);
            console.log(data);
            if (data.response && data.response.billerResponse) {
                setBillDetails(data.response);
            }
            setFetchButtonDisable(false);
            setFetchButtonText("Fetch bill");
        } catch (error) {
            console.log("error", error);
            setFetchButtonDisable(false);
            setFetchButtonText("Fetch bill");
        }
    };

    useEffect(() => {
        if (state.provider) {
            setCustP(state.provider.customerParams.map((param, i) => {
                return { ...param, id: i, value: "" };
            }));
        }
        // console.log(CustP);
    }, [state])


    useEffect(() => {
        setProviders(details.providers.map((pro) => {
            return { ...pro, value: pro.billerName, label: pro.billerName };
        }));
    }, [details]);
    
  useEffect(() => {
    document
      .querySelector(".content-wrapper")
      .classList.remove("sidebar-icon-only");
  }, []);
  return (
    <nav
    className="side-sheet sidebar Sidebar23"
    id="sidebar2"
    style={Sidebar22}
>
    <div
        style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
        className="row"
    >
        <div
            style={{ padding: "6px", display: "flex", alignItems: "center" }}
            className="col"
        >
            <strong style={{ color: "#1b68ca" }}>
                {"Life Insurance Bill Payment"}
            </strong>
        </div>
        <div style={{ paddingRight: "0px" }} className="col">
            <span
                style={{
                    fontSize: "2rem",
                    display: "flex",
                    color: "#b2b2b3",
                    justifyContent: "end",
                    ariaHidden: "true",
                    paddingRight: "4px",
                    cursor: "pointer",
                }}
                onClick={() => {
                    document
                        .querySelector(".content-wrapper")
                        .classList.toggle("sidebar-icon-only");
                }}
            >
                &times;
            </span>
        </div>
    </div>
    <Dropdown.Divider />

    <Card style={{ margin: "10px", padding: "10px" }}>
        <Form.Group style={{ flex: "1" }}>

            <Select
                options={providers}
                onChange={(e) => {
                    setState({
                        ...state,
                        provider: e,
                    });
                }}
            />
        </Form.Group>
        {/* <Form.Group style={{ flex: "1" }}>
            <Form.Control
                onChange={(e) => {
                    setState({
                        ...state,
                        consumer: e.target.value,
                    });
                }}
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Consumer`}
            />
        </Form.Group>
        <Form.Group style={{ flex: "1" }}>
            <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Mobile Number`}
                onChange={(e) => {
                    setState({
                        ...state,
                        mobileNumber: e.target.value,
                    });
                }}
            />
        </Form.Group> */}
        {CustP.map((cust, i) => (
            cust.optional!="true"?
            <Form.Group key={cust.id} style={{ flex: "1" }}>
                <Form.Control
                    type="text"
                    className="form-control rounded-0"
                    aria-label="Notes"
                    aria-describedby="basic-addon1"
                    placeholder={cust.paramName}
                    onChange={(e) => {
                        const copy = [...CustP]
                        copy.forEach((ele) => {
                            if (ele.id == cust.id) {
                                ele.value = e.target.value
                            }
                        })
                        setCustP(copy);

                    }}
                    value={cust.value}
                />
            </Form.Group>:""
        ))}
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
                className="rounded-0"
                style={{ margin: "0px 10px", minWidth: "11rem" }}
                variant="primary"
                onClick={fetchBill}
                disabled={fetchButtonDisable}
            >
                {fetchButtonText}
            </Button>
        </div>
    </Card>
    {billDetails && (
        <Card style={{ margin: "10px", padding: "10px" }}>
            <div >
                <img src={require("../../../assets/images/BBPSSidebarLogo.png")} style={{ width: 'auto', height: '1.2rem', backgroundColor: 'whitesmoke' }} />
            </div>
            <table style={{ border: "none", fontFamily: '-webkit-body' }}>
                <tr style={{ background: "none", border: "none" }}>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        Name
                    </td>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        {billDetails.billerResponse.customerName}
                    </td>
                </tr>
                <tr style={{ background: "none" }}>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        Bill Number
                    </td>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        {billDetails.billerResponse.billNumber}
                    </td>
                </tr>
                <tr style={{ background: "none" }}>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        Amount
                    </td>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        {billDetails.billerResponse.amount}
                    </td>
                </tr>
                <tr style={{ background: "none" }}>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        Bill date
                    </td>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        {billDetails.billerResponse.billDate}
                    </td>
                </tr>

                <tr style={{ background: "none" }}>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        Due Date.
                    </td>
                    <td
                        style={{
                            paddingLeft: "1rem",
                            border: "none",
                            background: "none",
                            width: '50%',
                            fontSize: '0.8rem'
                        }}
                    >
                        {" "}
                        {billDetails.billerResponse.dueDate}
                    </td>
                </tr>
                <td
                    style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: '50%',
                        fontSize: '0.8rem'
                    }}
                >
                    {" "}
                    Reference Id
                </td>
                <td
                    style={{
                        paddingLeft: "1rem",
                        border: "none",
                        background: "none",
                        width: '50%',
                        fontSize: '0.8rem'
                    }}
                >
                    {" "}
                    {billDetails.refId}
                </td>
            </table>
            {/* <div >
                <Button className="rounded-0" variant="outline-info" style={{ float: 'right' ,margin:"0px 8px" }}>{ONLINE_TEXT} </Button>
                <Button className="rounded-0" variant="outline-info" style={{ float: 'right' }}> {OFFLINE_TEXT}</Button>
            </div> */}
            <div
                style={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {PayClicked ?
                    <Button className="rounded-0" variant="success" >
                        {" "}
                        Paying ...
                    </Button> :
                    <Button className="rounded-0" variant="success" onClick={payBill}>
                        {" "}
                        Pay Bill
                    </Button>
                }

            </div>
        </Card>
    )}
</nav>
  );
};

export default withRouter(LifeSidebar);
