import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import {
  BASE_URL_APISERVICES,
  BASE_URL_API_TRANXT_API,
} from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { set } from "react-hook-form";
export const columns = [
  {
    dataField: "UserCode",
    text: "User Code",
  },
  {
    dataField: "mobile",
    text: "Mobile",
  },
  {
    dataField: "user_name",
    text: "User Name",
  },
  {
    dataField: "Company",
    text: "Company",
  },
  {
    dataField: "AddLoad",
    text: "Load Amount",
  },
  {
    dataField: "OpenBal",
    text: "Opening Balance",
  },
  {
    dataField: "Balance",
    text: "Balance",
  },
];

const positionStyle = {
  position: "absolute",
  top: -28,
  left: 0,
  fontFamily: "Poppins",
  color: "rgba(255, 255,255, 1)",
  borderRadius: "0.2rem",
};
const positionStyle1 = {
  position: "absolute",
  top: -58,
  left: -15,
  fontFamily: "Poppins",
  color: "rgba(255, 255,255, 1)",
  borderRadius: "0.2rem",
};

const actions = [
  { label: "Select Action", value: "" },
  { label: "Approve", value: "1" },
  { label: "Reject", value: "2" },
];

const AllODRequestDocsTable = () => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({});
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  let fromDate = sessionStorage.getItem("fromDate")
    ? new Date(sessionStorage.getItem("fromDate"))
    : new Date();
  let toDate = sessionStorage.getItem("toDate")
    ? new Date(sessionStorage.getItem("toDate"))
    : new Date();
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [inputState, setInputState] = useState({
    userType: "2",
  });
  const { Profileinfo } = useContext(GlobalContext);
  const [clickedImageURL, setClickedImageURL] = useState("");

  const handleImageClick = (imageUrl) => {
    setClickedImageURL(imageUrl);
  };

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getAllCreditRequests = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.pkid,
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/credit/getAllODRequestsDocs`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setData(data.data);
      } else {
        setData([]);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllCreditRequests();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "25%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  return (
    <>
      {clickedImageURL && (
        <div className="image-overlays" onClick={() => setClickedImageURL("")}>
          <img src={clickedImageURL} alt="Clicked" />
        </div>
      )}
      <div className="row m-0 p-0  pt-1 align-items-center">
        <div className="col-4 m-0 p-0">
          <div
            className="m-0 p-0 pl-3"
            style={{
              fontFamily: "Poppins",
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",
              background: "#FFFFFF",
              color: "#4267B2",
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
            }}
          >
            OD Requests Docs
          </div>
        </div>

        <div className="col-8 m-0 p-0 ">
          <div className="row  m-0 p-0  justify-content-end">
            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div
              className="pr-1 pl-2 p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    sessionStorage.setItem("fromDate", date.toISOString());
                  }}
                  className="form-control rounded-100"
                  placeholder="Start Date"
                />
              </Form.Group>
            </div>
            <div
              className="px-1  p-0 m-0"
              style={{ width: "18%", height: "20%" }}
            >
              <Form.Group className="p-0 m-0">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    sessionStorage.setItem("toDate", date.toISOString());
                  }}
                  className="form-control rounded-100"
                  placeholder="End Date"
                />
              </Form.Group>
            </div>

            <div
              className="pl-1   p-0 m-0"
              style={{ width: "14%", height: "20%" }}
            >
              <Form.Group className="mt-0 p-0 m-0">
                <Button
                  className="rounded-100 mt-4 btn btn-lg btn-block"
                  onClick={getAllCreditRequests}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
            <div
              className="m-0 p-0 hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(
                  `Balance_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div
                div
                style={{ color: "rgba(255, 255,255, 1)" }}
                className="hover-text bg-dark "
              >
                Export to Excel
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0 p-0" />
      <div className="scroll-inner admin-fix-width">
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>

            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"User ID"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"user Name"}
            </th>

            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"User Mobile"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Date Added"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Status"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Docs"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Action"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr className="border-bottom">
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                {ind + 1}
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span className="text-center">{`${e.user_code}`}</span>
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-center">{`${e.user_name}`}</span>
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span className="text-center">{`${e.user_mobile}`}</span>
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span className="text-center">{`${e.indate}`}</span>
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  {e.approved_1 == "A" ? (
                    <span
                      style={{
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                      }}
                      className="px-2 py-2"
                    >
                      {`Approved`}
                    </span>
                  ) : e.approved_1 == "R" ? (
                    <span
                      style={{
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(255, 46, 46, 0.1)",
                        color: "rgba(255, 46, 46, 1)",
                        bordercolor: "rgba(255, 46, 46, 1)",
                      }}
                      className="px-2 py-2"
                    >
                      {`Rejected`}
                    </span>
                  ) : (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(254, 128, 36, 0.1)",
                        color: "rgba(254, 128, 36, 1)",
                        bordercolor: "rgba(254, 128, 36, 1)",
                      }}
                      className="px-4 py-2"
                    >
                      {`Pending`}
                    </span>
                  )}
                </div>
              </td>

              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="row m-0 p-0">
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-0 p-0"
                    onClick={() => {
                      setClickedImageURL(e.agreement_1);
                    }}
                  >
                    {" "}
                    <div className="hover-container">
                      {/* Your content here */}
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Agreement 1
                      </div>
                    </div>
                  </Button>
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-0 p-0"
                    onClick={() => {
                      setClickedImageURL(e.agreement_2);
                    }}
                  >
                    {" "}
                    <div className="hover-container">
                      {/* Your content here */}
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Agreement 2
                      </div>
                    </div>
                  </Button>
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-0 p-0"
                    onClick={() => {
                      setClickedImageURL(e.agreement_3);
                    }}
                  >
                    {" "}
                    <div className="hover-container">
                      {/* Your content here */}
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Agreement 3
                      </div>
                    </div>
                  </Button>
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn btn-sm  rounded-100 m-0 p-0"
                    onClick={() => {
                      setClickedImageURL(e.cheque);
                    }}
                  >
                    {" "}
                    <div className="hover-container">
                      {/* Your content here */}
                      <img
                        src={require("../../../assets/images/Raise Ticket.png")}
                        style={{ width: "" }}
                      />
                      <div
                        style={positionStyle1}
                        className="hover-text bg-dark"
                      >
                        {" "}
                        Cheque
                      </div>
                    </div>
                  </Button>
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: 10,
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "rgba(255, 164, 27, 1)",
                      bordercolor: "rgba(255, 255, 255, 1)",
                    }}
                    className="btn rounded-100  m-0 p-0"
                    onClick={() => {}}
                  >
                    <div className="hover-container">
                      <img
                        src={require("../../../assets/images/Add.png")}
                        style={{ width: "" }}
                      />

                      <div
                        style={positionStyle}
                        className="hover-text bg-dark px-2"
                      >
                        {" "}
                        Approve
                      </div>
                    </div>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const SquareColoredIcon = ({ color, iconName, text }) => {
  const iconStyle = {
    backgroundColor: color,
    width: "11px",
    height: "11px",
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    borderRadius: "2px",
    marginright: "2px",
  };

  return (
    <div className=" mr-2" style={iconStyle}>
      <div className="mr-2">
        <i
          className={`bi-${iconName}`}
          style={{ color: "white", fontSize: "1rem" }}
        ></i>
      </div>
      {text && (
        <div className="ml-2 " style={{ fontSize: ".8rem" }}>
          {text}
        </div>
      )}
    </div>
  );
};

const CreditActionModal = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    action: "",
    amount: "",
    remark: "",
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const actions = [
    { label: "Approve Credit", value: "1" },
    { label: "Reject Credit", value: "2" },
  ];

  const approveCreditRequest = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.pkid,
        refNo: details.credit_id,
        type: state.action.value,
        amount: details.load_amount,
        remaks: state.remark,
        step: "3",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/credit/creditRequestApprove`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.data.id == "Y") {
          swal("Success", data.data.message, "success");
        } else {
          swal("Failed", data.data.message, "error");
        }
        refresh();
        setModalOpen(false);
      } else {
        swal("Failed", data.msg, "error");
      }
      clearForm();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const clearForm = () => {
    setState({
      action: "",
      amount: "",
      remark: "",
    });
  };
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby=""
      size="lg"
      style={{}}
    >
      <Modal.Body
        style={{ display: "block", height: "100%", overflowY: "hidden" }}
        className="account"
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: "600",
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "left",
            padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontWeight: "bolder",
            fontfamily: "Poppins !impotent",
            fontSize: "28px",
          }}
          className="d-flex justify-content-between"
        >
          <div> Credit Request</div>
          <div
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            <img
              src={require("../../../assets/images/Vector.png")}
              style={{ width: "" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>Refrence No</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Remarks"
                value={details?.credit_id}
                disabled
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>User ID</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Remarks"
                value={details?.us_code}
                disabled
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Action
              </Form.Label>
              <Select
                options={actions}
                value={state.action}
                onChange={(e) => {
                  setState({ ...state, action: e });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Remarks (Optional)
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Remarks"
                value={state.remark}
                onChange={(e) => {
                  setState({ ...state, remark: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3">
            <Button
              style={{ backgroundColor: "#0074D9" }}
              className="rounded-100 btn btn-primary btn-lg btn-block"
              onClick={approveCreditRequest}
            >
              Submit
            </Button>
          </div>
          <div className="col-3 p-0 ml-2">
            <Button
              className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
              onClick={clearForm}
            >
              {" "}
              Clear
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ReverseAmountModal = ({
  details,
  isModalOpen,
  setModalOpen,
  refresh,
}) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    action: "",
    amount: "",
    remark: "",
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const actions = [
    { label: "Approve Credit", value: "1" },
    { label: "Reject Credit", value: "2" },
  ];

  const reverseCreditRequest = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.pkid,
        refNo: details.credit_id,
        amount: state.amount,
        remaks: state.remark,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/credit/creditRequestReverseAmount`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.data.id == "Y") {
          swal("Success", data.data.message, "success");
        } else {
          swal("Failed", data.data.message, "error");
        }
        refresh();
        setModalOpen(false);
      } else {
        swal("Failed", data.msg, "error");
      }
      clearForm();
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const clearForm = () => {
    setState({
      action: "",
      amount: "",
      remark: "",
    });
  };
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby=""
      size="lg"
      style={{}}
    >
      <Modal.Body
        style={{ display: "block", height: "100%", overflowY: "hidden" }}
        className="account"
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: "600",
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "left",
            padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
            fontWeight: "bolder",
            fontfamily: "Poppins !impotent",
            fontSize: "28px",
          }}
          className="d-flex justify-content-between"
        >
          <div> Credit Reverse</div>
          <div
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
            <img
              src={require("../../../assets/images/Vector.png")}
              style={{ width: "" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>Refrence No</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Remarks"
                value={details?.credit_id}
                disabled
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>User ID</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Remarks"
                value={details?.us_code}
                disabled
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Amount
              </Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Amount"
                value={state.amount}
                onChange={(e) => {
                  setState({ ...state, amount: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>Remarks (Optional)</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Remarks"
                value={state.remark}
                onChange={(e) => {
                  setState({ ...state, remark: e.target.value });
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3">
            <Button
              style={{ backgroundColor: "#0074D9" }}
              className="rounded-100 btn btn-primary btn-lg btn-block"
              onClick={reverseCreditRequest}
            >
              Submit
            </Button>
          </div>
          <div className="col-3 p-0 ml-2">
            <Button
              className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
              onClick={clearForm}
            >
              {" "}
              Clear
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AllODRequestDocsTable;
