import React, { useState, useEffect, useContext } from "react";
import { Form, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import Select from "react-select";
import { getLatLong, uniqueID, } from "../../utils/utilFuctions";
import swal from "sweetalert";

const RechargeOrPay = () => {    
    function generateUniqueCode() {
        const timestamp = Date.now();
        const randomString = Math.random().toString(36).substring(2, 15);
        const uniqueCode = `${timestamp}_${randomString}`;
        return uniqueCode;
    }
    const [userAcList, setUserAcList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [finalSettlement, setFinalSettlement] = useState({});
    const [mobileNumber, setMobileNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [Tpin, setTpin] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showRecommendedPlans, setShowRecommendedPlans] = useState(false);
    const [errors, setErrors] = useState({
        selectedAccount: '',
        mobileNumber: '',
        amount: '',
        Tpin: ''
    });
    const [selectedRechargePlan, setRechargeSelectedPlan] = useState(null); 
    const [Device, setDevice] = useState("web");
    const [uniqueCode, setUniqueCode] = useState();
    const [plansData, setPlansData] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { user } = useContext(GlobalContext);

    const handleAccountChange = async (selectedOption) => {
        setSelectedAccount(selectedOption);
        setFinalSettlement({
            ...finalSettlement,
            SelectAccount: selectedOption.value,
        })
      //  getAllRechargePlans()
        setShowRecommendedPlans(true);
    }

    useEffect(() => {
        getAllRechargePlans()
    }, [finalSettlement.SelectAccount])
    
    console.log(selectedAccount);

    const validation = (e) => {
        if (e)
            e.preventDefault();
        let valid = true;
        let newErrors = {};

        if (!selectedAccount) {
            newErrors.selectedAccount = 'Please select Provider';
            valid = false;
        }
        if (!mobileNumber) {
            newErrors.mobileNumber = 'Please enter a mobile number';
            valid = false;
        }
        if (!amount) {
            newErrors.amount = 'Please enter an amount';
            valid = false;
        }
        if (!Tpin) {
            newErrors.Tpin = 'Please enter a T-pin';
            valid = false;
        }

        if (valid) {
            billPayOffline();
        } else {
            setErrors(newErrors);
        }
    };

    const handleViewPlan = (plan) => {
        setSelectedPlan(plan);
        setAmount(plan.amount);
        setModalOpen(true);
    };

    // Recharge api 
    const billPayOffline = async (e) => {
        try {
            const coords = await getLatLong();
            setUniqueCode(generateUniqueCode());
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

            const raw = JSON.stringify({
                userid: user.ReferId,
                consumer_no: mobileNumber,
                amount: amount,
                operatorcode: selectedAccount.value,
                ebill_type: "REC",
                unique_id: uniqueID(),
                mobile_no: mobileNumber,
                providerId: "2",
                optional: "",
                latitude: "",
                longitude: "",
                tpin: Tpin,
                device: "WEB",

                // userid:"BR1004",
                // consumer_no:"9368726033",
                // amount: "48",
                // operatorcode: "Vodafone",
                // ebill_type: "REC",
                // unique_id:"29062024155710",
                // mobile_no: "9368726033",
                // providerId: "2",
                // optional: "",
                // latitude: "28.6081458",
                // longitude: "",
                // tpin: "916880",
                // device: "WEB",  
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const response = await fetch(
                `${BASE_URL_APISERVICES}/api/BBPS/billPayOffline`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();

            if (result.responseCode === "00") {
                swal({
                    title: 'Success!',
                    text: result.responseMessage,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            } else {
                swal({
                    title: 'Error!',
                    text: result.responseMessage,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }

            console.log(result);
        } catch (error) {
            console.error("Error:", error);
            swal({
                title: 'Error!',
                text: 'Something went wrong. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    //Operators api
    const getAllOfflineOperators = async (e) => {
        try {
            setUniqueCode(generateUniqueCode());
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                userid: user.ReferId,
                categoryId: "6"
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/bbps/getAllOfflineOperators`,
                requestOptions
            );

            const result = await res.json();

            if (result.responseCode === "00") {
                setUserAcList(
                    result.data.map((item) => ({
                        value: item.code,
                        label: item.code,
                    }))
                );
            } else {
                console.error(result.responseMsg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //Recharge Plam api
    const getAllRechargePlans = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                provider: selectedAccount.value
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/BBPS/getAllRechargePlans`,
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode === '00') {
                setPlansData(data.data);
                if (data.data.length > 0) {
                    setSelectedCategory(data.data[0].plan_category);
                }
            }else{
                setPlansData([]);
                setSelectedCategory("")
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    const recommendedPlans = plansData.flatMap(item => item.plans).slice(0, 4);

    const categorizedPlans = plansData.reduce((acc, item) => {
        if (!acc[item.plan_category]) {
            acc[item.plan_category] = [];
        }
        acc[item.plan_category].push(...item.plans);
        return acc;
    }, {});

    useEffect(() => {
        getAllOfflineOperators();
    }, []);

    return (
        <>

        <UserDetailsUpdate
          details={selectedPlan}
          isModalOpen={modalOpen}
          setModalOpen={setModalOpen} 
        />
      
        <ToastContainer />
      
        <div 
          className="recharge-or-pay" 
          style={{
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f0f0f0',
            // minHeight: '70vh'
          }}
        >
          <div 
            className="header" 
            style={{
              backgroundColor: 'rgb(46, 51, 69)',
              color: 'white',
              padding: '15px 20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h2 style={{ margin: 0 }}>
              <b>Mobile Recharge</b>
            </h2>
          </div>
      
          <div 
            className="content" 
            style={{
              display: 'flex',
              padding: '20px',
              gap: '20px'
            }}
          >
            <div 
              className="form-section col-6" 
              style={{
                flex: 1,
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
            >

              <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Form.Group>
                  <Select
                    onChange={handleAccountChange}
                    options={userAcList}
                    placeholder="Select Provider"
                    style={{ width: '100%' }}
                  />

                  {errors.selectedAccount && 
                    <div className="error-message">{errors.selectedAccount}</div>
                  }
      
                  <Form.Control
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="Mobile Number"
                    className="rounded-0 mt-3"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      fontSize: '16px',
                      marginTop: '15px'
                    }}
                  />
                  {errors.mobileNumber && 
                    <div className="error-message">{errors.mobileNumber}</div>
                  }
      
                  <Form.Control
                    type="number"
                    placeholder="Enter Amount"
                    name="Amount"
                    className="rounded-0 mt-3"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      fontSize: '16px',
                      marginTop: '15px'
                    }}
                  />
                  {errors.amount && 
                    <div className="error-message">{errors.amount}</div>
                  }
      
                  <Form.Control
                    type="number"
                    placeholder="Enter T-pin"
                    minLength="6"
                    maxLength="6"
                    name="Mobile Number"
                    className="rounded-0 mt-3"
                    value={Tpin}
                    onChange={(e) => setTpin(e.target.value)}
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      fontSize: '16px',
                      marginTop: '15px'
                    }}
                  />
                  {errors.Tpin && 
                    <div className="error-message">{errors.Tpin}</div>
                  }
                </Form.Group>
      
                <button
                  type="submit"
                  className="proceed-to-pay"
                  onClick={validation}
                  style={{
                    padding: '10px',
                    backgroundColor: 'rgb(46, 51, 69)',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                  Recharge
                </button>
              </form>
            </div>
      
            {showRecommendedPlans && (
              <div 
                className="plans-section" 
                style={{
                  flex: 1,
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}
              >
                <h3 style={{ marginTop: 0, marginBottom: '15px' }}>
                  <b>Recommended Plans</b>
                </h3>
                <div 
                  className="plans-grid" 
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '15px',
                    marginBottom: '20px'
                  }}
                >
                  {recommendedPlans.map((plan, index) => (
                    <div 
                      key={index} 
                      className="plan-card" 
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '15px',
                        textAlign: 'center'
                      }}
                    >
                      <div 
                        className="price" 
                        style={{
                          fontWeight: 'bold',
                          color: 'rgb(46, 51, 69)',
                          fontSize: '18px'
                        }}
                      >
                        Rs. {plan.amount}
                      </div>
                      <div className="data" style={{ margin: '5px 0' }}>{plan.data}</div>
                      <div className="validity" style={{ fontSize: '14px', color: '#777' }}>
                        Validity: {plan.validity}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      
        <div 
  className="plan-categories" 
  style={{ 
    width: '100%', 
    marginTop: '20px',
    overflow: 'auto',  
    position: 'relative', 
  }}
>
  <div 
    className="category-buttons" 
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '10px',
      marginBottom: '20px'
    }}
  >
    {Object.keys(categorizedPlans).map((category, index) => (
      <button
        key={index}
        className={`category-pill ${selectedCategory === category ? 'active' : ''}`}
        onClick={() => setSelectedCategory(category)}
        style={{
          backgroundColor: selectedCategory === category ? 'rgb(46, 51, 69)' : '#fff',
          color: selectedCategory === category ? '#fff' : 'rgb(46, 51, 69)',
          border: `1px solid ${selectedCategory === category ? 'rgb(46, 51, 69)' : 'rgb(46, 51, 69)'}`,
          borderRadius: '20px',
          padding: '8px 16px',
          fontSize: '14px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease, color 0.3s ease',
        }}
      >
        {category}
      </button>
    ))}
  </div>

  <div 
    className="plan-cards" 
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '20px'
    }}
  >
    {categorizedPlans[selectedCategory]?.slice(0, 4).map((plan, index) => (
      <div
        key={index}
        className="plan-card"
        style={{
          backgroundColor: '#fff',
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          cursor: 'pointer',
          opacity: 1,  
        }}
        onClick={() => {
          setAmount(plan.amount);
        }}
      >
        <div 
          className="plan-price" 
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'rgb(46, 51, 69)',
            marginBottom: '10px'
          }}
        >
          ₹{plan.amount}
        </div>
        <div className="plan-validity" style={{ marginBottom: '5px', color: '#333' }}>
          Validity: {plan.validity}
        </div>
        <div className="plan-data" style={{ marginBottom: '5px', color: '#333' }}>
          {plan.data}
        </div>
        <div className="plan-description" style={{ marginBottom: '5px', color: '#333' }}>
          Voice: {plan.voice}
        </div>
        <button 
          className="view-plan-button"
          onClick={() => handleViewPlan(plan)}
          style={{
            backgroundColor: 'rgb(46, 51, 69)',
            color: '#fff',
            border: 'none',
            borderRadius: '20px',
            padding: '8px 16px',
            marginTop: '10px',
            cursor: 'pointer',
          }}
        >
          View Plan
        </button>
      </div>
    ))}
  </div>
</div>


      </>
    );
};


// Modal code ---->

const UserDetailsUpdate = ({ details, isModalOpen, setModalOpen }) => {

    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        if (details) {
            setUserDetails(details);
        }
    }, [details]);

    return (
        <Modal
            show={isModalOpen}
            onHide={() => setModalOpen(false)}
            aria-labelledby="example-modal-sizes-title-md"
            size="md"
        >
            <Modal.Body style={{ width: "100%", display: "block" }}>
                <div
                    style={{
                        padding: "12px",
                        fontWeight: "bolder",
                    }}
                    className="d-flex justify-content-between"
                >
                    <strong>Plan Details</strong>
                    <strong
                        className="px-1 hover"
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        X
                    </strong>
                </div>
                {details ? (
                    <div className="ml-3">
                        <p><strong>Amount:</strong> ₹{details.amount}</p>
                        <p><strong>Validity:</strong> {details.validity}</p>
                        <p><strong>Data:</strong> {details.data}</p>
                        <p><strong>Voice:</strong> {details.voice}</p>
                        <p><strong>SMS:</strong> {details.sms}</p>
                        <p><strong>Total Data:</strong> {details.total_data}</p>
                        <p><strong>Subscription:</strong> {details.subscription}</p>
                    </div>
                ) : (
                    <p>No details available</p>
                )}
            </Modal.Body>
        </Modal>
    );
};


export default RechargeOrPay;