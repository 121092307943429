import React, { useState, useEffect, useContext } from "react";

import "./custom-tabs.css";
import Transactions, { PayIN, PayinSettlement, Payout } from "./Transactions";
import { Ledger } from "./Ledger";
import Balances from "./Balances";
import Profile from "./Profile";
import { GlobalContext } from "../Context/GlobalState";

const MyAccount2 = () => {
  const [agentId, setAgentId] = useState("");

  let {
    myAccountInfo: info,
    setMyAccountInfo,
    logoutDeveloperLogin,
    myAccountInfo,
  } = useContext(GlobalContext);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const [name, setName] = useState("");
  const [submittedData, setSubmittedData] = useState(null);

  return (
    <div className="p-4 bg-light" style={{ fontFamily: "auto" }}>
      <div style={{ background: "#fff" }}>
        {" "}
        <div
          className="row justify-content-between m-0 px-4 py-2"
          style={{ background: "" }}
        >
          <h2
            style={{
              margin: "0",
              fontWeight: "bolder",
            }}
          >
            My Account
          </h2>
          <button
            className="btn btn-primary mx-4"
            onClick={logoutDeveloperLogin}
            style={{ width: "7rem" }}
          >
            Log Out
          </button>
        </div>
      </div>
      <div>
        <div>
          <div className="card m-0 p-0 pt-3">
            <div className="card-body m-0 p-0">
              <div className="">
                <div
                  defaultActiveKey="profile"
                  className=" tab-header justify-content-between"
                >
                  <div
                    className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => handleTabClick(0)}
                  >
                    Profile
                  </div>
                  <div
                    className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => handleTabClick(1)}
                  >
                    Balances
                  </div>
                  <div
                    className={`tab-item ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => handleTabClick(2)}
                  >
                    Ledger
                  </div>

                  <div
                    className={`tab-item ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => handleTabClick(3)}
                  >
                    Transactions
                  </div>

                  <div
                    className={`tab-item ${activeTab === 4 ? "active" : ""}`}
                    onClick={() => handleTabClick(4)}
                  >
                    Payout
                  </div>

                  <div
                    className={`tab-item ${activeTab === 5 ? "active" : ""}`}
                    onClick={() => handleTabClick(5)}
                  >
                    Pay - In
                  </div>

                  <div
                    className={`tab-item ${activeTab === 6 ? "active" : ""}`}
                    onClick={() => handleTabClick(6)}
                  >
                    Pay - IN Settlement
                  </div>
                </div>
                <div className="tab-content">
                  {activeTab === 0 && (
                    <div>
                      <Profile agentId={agentId} />
                    </div>
                  )}
                  {activeTab === 1 && (
                    <div>
                      <Balances agentId={agentId} />
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      <Ledger agentId={agentId} />
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div>
                      {" "}
                      <Transactions agentId={agentId} />{" "}
                    </div>
                  )}
                  {activeTab === 5 && (
                    <div>
                      {" "}
                      <PayIN agentId={agentId} />
                    </div>
                  )}
                  {activeTab === 4 && (
                    <div>
                      {" "}
                      <Payout agentId={agentId} />
                    </div>
                  )}
                  {activeTab === 6 && (
                    <div>
                      {" "}
                      <PayinSettlement agentId={agentId} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount2;
