import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Button, Row, Col, Form } from "react-bootstrap";
import swal from "sweetalert";
import { writeFile, utils } from "xlsx";
import { Redirect, useHistory } from "react-router";
import { Pagination } from "../admin/pg-report/PGReportTable";
import { BASE_URL_AGENT, BASE_URL_API_TRANXT_API } from "../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../utils/Themer";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  dateFormatter,
  formatDateDDMMYY,
  formatDateDDMMYYHHMM,
  twoDecimalPlaces,
} from "../utils/utilFuctions";
import { withRouter } from "react-router-dom";
import { Dropdown, Card } from "react-bootstrap";

import cogoToast from "cogo-toast";
import { validateTPin } from "../utils/Validations";

const mapData = {
  BZ: 75.0,
  US: 56.25,
  AU: 15.45,
  GB: 25.0,
  RO: 10.25,
  GE: 33.25,
};


export const DashboardReport = () => {
  const InitialValues = { UserName: "" };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState();
  const [sidebarState, setSidebarState] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Provider, setProvider] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [ShopObj, setShopObj] = useState([]);
  const { user } = useContext(GlobalContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [selectedData, setSelectedData] = useState({ id: "", oldBalance: "" });

  const { Profileinfo } = useContext(GlobalContext);
  const [SendotpAdd, setSendotpAdd] = useState(true);
  const [SendotpRev, setSendotpRev] = useState(true);
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (Profileinfo) {
      otpYes();
    }
  }, [Profileinfo]);

  const otpYes = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: Profileinfo?.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        " https://apiservices.bumppypay.com/api/Payment/getUserConfigSettings",

        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.data.add_balance_otp == "N") {
          setSendotpAdd(false);
        }
        if (data.data.reverse_balance_otp == "N") {
          setSendotpRev(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      provider();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.UserName) {
      errors.UserName = "*User name is required!";
    }
    return errors;
  };

  // const str = sessionStorage.getItem('user');
  // const user = JSON.parse(str);

  const { token, myAccountInfo } = useContext(GlobalContext);
  console.log(myAccountInfo);
  const transactionHistoryData = {
    labels: ["Paypal", "Stripe", "Cash"],
    datasets: [
      {
        data: [55, 25, 20],
        backgroundColor: ["#111111", "#00d25b", "#ffab00"],
      },
    ],
  };

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const provider = () => {
    var raw = JSON.stringify({
      UserID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_get_provider_list.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setProvider(result.data);
            setDataToDisplay(result.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    provider();
  }, []);

  const shoplist = () => {
    var raw = JSON.stringify({
      UserID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_get_shoplist.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setShopList(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log(localStorage.getItem("accessToken"));
  console.log(token);
  useEffect(() => {
    console.log("calling");
    shoplist();
  }, []);

  const ExportToExcel = (fileName, Provider) => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(Provider);
    utils.book_append_sheet(wb, ws, "sheet");
    writeFile(wb, `${fileName}.xlsx`);
  };

  useEffect(() => {
    let filteredData = Provider.filter((bank) => {
      let b = false;
      if (Provider.length > 0) {
        for (let key in Provider[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = Provider.filter((bank) => {
      let b = false;
      if (Provider.length > 0) {
        for (let key in Provider[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((Provider.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(Provider.length);
    console.log(startIdx);
    const filteredData = Provider.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [Provider, offset]);

  const ShopWithId = () => {
    const getShopsObject = Provider.filter((data) => {
      return formValues.UserName == data.pk_user_id;
    });
    setShopObj(getShopsObject);
  };

  const requestParentFundRequest = async (childId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        sender: user.ReferId,
        receiver: childId,
        type: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/fund_request_submit.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const reset = () => {
    setShopObj([]);
    setFormValues({ ...formValues, UserName: "" });
  };

  useEffect(() => {
    ShopWithId();
  }, [formValues.UserName]);

  const positionStyle = {
    position: "absolute",
    top: -28,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
    borderRadius: "0.2rem",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -58,
    left: -15,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
    borderRadius: "0.2rem",
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "3" ? (
          <Redirect to="/" />
        ) : (
          <React.Fragment>
            {/* <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <DashboardSidebar details={sidebarState} />
            </div> */}

            <Add
              SendotpAdd={SendotpAdd}
              selectedData={selectedData}
              details={sidebarState}
              isModalOpen={modalOpen}
              setModalOpen={(modalState) => {
                setModalOpen(modalState);
              }}
             refresh={provider}
            />
            <Reverse
              SendotpRev={SendotpRev}
              selectedData={selectedData}
              details={sidebarState}
              isModalOpen2={modalOpen2}
              setModalOpen2={(modalState2) => {
                setModalOpen2(modalState2);
              }}
             refresh={provider}
            />
            {/* <Button
          className=" rounded-50 btn btn-primary btn-lg btn-block "
          onClick={() => {
            setModalOpen(true);
          }}
        >
          {" "}
          Add Bank
        </Button> */}

            <div style={{ backgroundColor: COLOR_BACKGROUND_BODY }}>
              <div className="row ">
                <div className="col-12 grid-margin">
                  <div
                    className="card p-1 mt-4"
                    style={{ borderRadius: "8px" }}
                  >
                    <div className="card-body p-0 m-0 ">
                      <div className="row  p-0 m-0 mx-3  mt-2 d-flex justify-content-between">
                        <div
                          className="row mt-1 m-0 p-0"
                          style={{
                            color: "rgba(66, 103, 178, 1)",
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: "600",
                            lineHeight: "36px",
                            letterSpacing: "0em",
                            textAlign: "left",
                          }}
                        >
                          View Retailer
                        </div>
                        <div className="row mt-1  m-0 p-0">
                          <Form.Group
                            style={{ maxWidth: "30rem", borderRadius: "6px" }}
                            className="ml-2 "
                          >
                            <Form.Control
                              type="text"
                              className="form-control rounded-100 "
                              aria-label="Notes"
                              aria-describedby="basic-addon1"
                              placeholder=" Search in Table."
                              onChange={(e) => {
                                setSearchText(e.target.value);
                              }}
                              value={searchText}
                            />
                          </Form.Group>
                          <Form.Group style={{ marginLeft: "15px" }}>
                            <Button
                              className="btn btn-block btn-lg px-5"
                              style={{ borderRadius: "5px" }}
                            >
                              Search
                            </Button>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="scroll-inner ">
                        <table
                          style={{
                            width: "100%",
                            overflowY: "hidden",
                            overflowX: "hidden",
                          }}
                          className="dashboard-table-style"
                        >
                          <tr>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              SL No.
                            </th>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              Shop Name
                            </th>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              Retailer ID{" "}
                            </th>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              Mobile
                            </th>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              Balance
                            </th>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              User Creation Date{" "}
                            </th>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              KYC Status
                            </th>
                            <th
                              className="my-table transparent-table text-center border-bottom py-4"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "700",
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              Action
                            </th>
                          </tr>

                          {dataToDisplay?.map((provider, i) => (
                            <tr>
                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                {Number(i) + 1}
                              </td>
                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {/* {provider.img && (
                                  <img
                                    style={{ height: "1rem", width: "auto" }}
                                    src={`${BASE_URL_AGENT}${provider.img.substring(
                                      2
                                    )}`}
                                    alt="face"
                                  />
                                )} */}
                                <span className="pl-2">
                                  {provider.us_shop_name}
                                </span>{" "}
                              </td>
                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                <Link
                                  to={`/distributor/retailer_report?id=${provider.us_code}`}
                                >
                                  {" "}
                                  {provider.us_code}{" "}
                                </Link>{" "}
                              </td>
                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                {provider.us_loginid}{" "}
                              </td>
                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {twoDecimalPlaces(provider.userbalance)}{" "}
                              </td>
                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {formatDateDDMMYY(provider.us_create_date)}{" "}
                              </td>

                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                <div className="d-flex flex-column align-items-center">
                                  {provider.kycstatus == "Approved" ? (
                                    <span
                                      style={{
                                        width: "100%",
                                        borderRadius: "5px",
                                        border: "1px solid",
                                        backgroundColor:
                                          "rgba(43, 193, 85, 0.1)",
                                        color: "rgba(43, 193, 85, 1)",
                                        bordercolor: "rgba(43, 193, 85, 1)",
                                      }}
                                      className="py-1"
                                    >
                                      {`${provider.kycstatus}`}
                                    </span>
                                  ) : provider.kycstatus == "Rejected" ||
                                    provider.kycstatus == "Reject" ? (
                                    <span
                                      style={{
                                        width: "100%",
                                        borderRadius: "5px",
                                        border: "1px solid",
                                        backgroundColor:
                                          "rgba(255, 46, 46, 0.1)",
                                        color: "rgba(255, 46, 46, 1)",
                                        bordercolor: "rgba(255, 46, 46, 1)",
                                      }}
                                      className="py-1"
                                    >
                                      {`${provider.kycstatus}`}
                                    </span>
                                  ) : provider.kycstatus == "Re Submitted" ||
                                    provider.kycstatus == "Re Submitted" ? (
                                    <span
                                      style={{
                                        width: "100%",
                                        borderRadius: "5px",
                                        border: "1px solid",
                                        backgroundColor:
                                          "rgba(232, 129, 36, 0.1)",
                                        color: "rgba(232, 129, 36, 0.5)",
                                        bordercolor:
                                          "  rgba(232, 129, 36, 0.5)",
                                      }}
                                      className="py-1"
                                    >
                                      {`${provider.kycstatus}`}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        width: "100%",
                                        borderRadius: "5px",
                                        border: "1px solid",
                                        backgroundColor:
                                          "rgba(232, 129, 36, 0.1)",
                                        color: "rgba(232, 129, 36, 0.5)",
                                        bordercolor:
                                          "  rgba(232, 129, 36, 0.5)",
                                      }}
                                      className="py-1"
                                    >
                                      {`${provider.kycstatus}`}
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td
                                className="text-center border-bottom"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <div className="">
                                  <Button
                                    style={{
                                      // paddingLeft:"3px",
                                      // padding: "5px",
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0 p-1"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "kyc",
                                        JSON.stringify(provider)
                                      );
                                      history.push("/kyc/bussiness-details");
                                    }}
                                    disabled={
                                      provider.kycstatus.toString() ==
                                      "Approved"
                                    }
                                  // disabled={
                                  //   user.kycstatus.toString() == "Approved"
                                  // }
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../assets/images/KYC.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle}
                                        className="hover-text bg-dark px-2 rounded-100"
                                      >
                                        {" "}
                                        KYC
                                      </div>
                                    </div>
                                  </Button>
                                  <span> </span>

                                  <Button
                                    style={{
                                      // paddingLeft:"3px",
                                      // padding: "5px",
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0  p-1"
                                    //  onClick={() => {
                                    //   setSidebarState({
                                    //     ui: "add",
                                    //     user: provider,
                                    //   });
                                    //   document
                                    //     .querySelector(".content-wrapper")
                                    //     .classList.toggle("sidebar-icon-only");
                                    // }}
                                    onClick={() => {
                                      setSidebarState({
                                        ui: "add",
                                        user: provider,
                                      });
                                      setModalOpen(true);

                                      {
                                        setSelectedData({
                                          id: provider.us_code,
                                          oldBalance: provider.userbalance,
                                        });
                                      }
                                    }}
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../assets/images/Add.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle}
                                        className="hover-text bg-dark px-2"
                                      >
                                        {" "}
                                        Add
                                      </div>
                                    </div>
                                  </Button>

                                  <span> </span>

                                  <Button
                                    style={{
                                      // paddingLeft:"3px",
                                      // padding: "5px",
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0  p-1"
                                    //  onClick={() => {
                                    //   setSidebarState({
                                    //     ui: "reverse",
                                    //     user: provider,
                                    //   });
                                    //   document
                                    //     .querySelector(".content-wrapper")
                                    //     .classList.toggle("sidebar-icon-only");
                                    // }}
                                    onClick={() => {
                                      setSidebarState({
                                        ui: "reverse",
                                        user: provider,
                                      });
                                      setModalOpen2(true);
                                      {
                                        setSelectedData({
                                          id: provider.us_code,
                                          oldBalance: provider.userbalance,
                                        });
                                      }
                                    }}
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../assets/images/Reverse.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle}
                                        className="hover-text bg-dark"
                                      >
                                        {" "}
                                        Reverse
                                      </div>
                                    </div>
                                  </Button>

                                  <span> </span>

                                  <Button
                                    style={{
                                      // paddingLeft:"3px",
                                      // padding: "5px",
                                      borderRadius: "5px",
                                      border: "1px none",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      color: "rgba(255, 164, 27, 1)",
                                      bordercolor: "rgba(255, 255, 255, 1)",
                                    }}
                                    className="btn btn-sm  rounded-100 m-0 p-0  p-1"
                                    onClick={() => {
                                      requestParentFundRequest(
                                        provider.us_code
                                      );
                                    }}
                                  >
                                    {" "}
                                    <div className="hover-container">
                                      {/* Your content here */}
                                      <img
                                        src={require("../../assets/images/Assign Tariff.png")}
                                        style={{ width: "" }}
                                      />

                                      <div
                                        style={positionStyle1}
                                        className="hover-text bg-dark"
                                      >
                                        {" "}
                                        Send Fund Acceptance Request
                                      </div>
                                    </div>
                                  </Button>
                                  <span
                                    styles={{ background: "#FF6D60" }}
                                  ></span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </table>
                        <Pagination
                          page={currentPage}
                          limit={pages}
                          callback={(page) => {
                            setCurrentPage(page);
                          }}
                          count={dataToDisplay.length}
                          total={total}
                          callback2={(offsetValue) => {
                            setOffset(offsetValue);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    </GlobalContext.Consumer>
  );
};

const Add = ({
  SendotpAdd,
  details,
  isModalOpen,
  setModalOpen,
  selectedData,
  refresh,
}) => {
  const [tpinShow, setTpinShow] = useState(false);
  const [state, setState] = useState({});
  const [Pin, setPin] = useState("");
  const [msg, setMsg] = useState("");
  const [AddBlance, setAddBlance] = useState("");
  const [Ptype, setPtype] = useState("2");
  const [Amount, setAmount] = useState("");
  const [Remark, setRemark] = useState("");
  const [Reverse, setReverse] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Comment, setComment] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpText, setOtpText] = useState("");
  const { user, refreshWallet, Profileinfo, setTransactionLoaderState } = useContext(GlobalContext);

  const clearForm = () => {
    setPin("");
    setMsg("");
    setComment("");
    setAmount("");
    setAddBlance("");
  };

  useEffect(() => {
    setState(details);
    console.log(details);
  }, [details]);

  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const tpinValidation = async () => {
    validateTPin(
      Profileinfo.mobile,
      Pin,
      () => {
        console.log("Call addblance");
        addblance();
      },
      () => {
        clearForm();
        document
          .querySelector(".content-wrapper")
          .classList.toggle("sidebar-icon-only");
      }
    );
  };

  const addblance = () => {
    setTransactionLoaderState(true)
    console.log({
      paytype: "2",
      retpkid: details.user.pk_user_id,
      distripkid: user.pk_user_id,
      amount: Amount,
      comment: Remark,
    });
    var raw = JSON.stringify({
      paytype: "2",
      retpkid: details.user.pk_user_id,
      distripkid: user.pk_user_id,
      amount: Amount,
      comment: Remark,
    });
    console.log(raw);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_add_balance_retailer_new.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setAddBlance(result);
          if (result.status == "0") {
            cogoToast.success(result.msg);
          } else {
            cogoToast.error(result.msg);
          }

          refreshWallet();
          refresh();
          clearForm();
          document
            .querySelector(".content-wrapper")
            .classList.toggle("sidebar-icon-only");
          setOtpSent(false);
          setOtpValidated(false);
          setTransactionLoaderState(false);
          setModalOpen(false)
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
      clearForm();
    }
  };

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };

  const sendOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: Profileinfo.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/sendOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setOtpSent(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: Profileinfo.mobile,
        otp: otpText,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/validateOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setOtpValidated(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  console.log(details);
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      aria-labelledby=""
      size="40%"
      style={{}}
    >
      <Modal.Body
        style={{
          width: "100%",
          display: "block",
          height: "100%",
          overflowY: "hidden",
        }}
        className="account container-fluid"
      >
        <div
          style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
          className="row"
        >
          <div
            className="row mt-2 m-0 p-2"
            style={{
              color: "rgba(66, 103, 178, 1)",
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "36px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            Retailer Amount
          </div>
          <div style={{ paddingRight: "0px" }} className="col">
            <span
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "4px",
                cursor: "pointer",
              }}
              //  onClick={() => {
              //  document
              //  .querySelector(".content-wrapper")
              //  .classList.toggle("sidebar-icon-only");
              //  }}
              onClick={() => {
                setModalOpen(false);
                setOtpSent(false);
                setOtpValidated(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <Dropdown.Divider />

        <div style={{ fontFamily: "Poppins", fontSize: "32px" }}>
          <Form.Group
            as={Row}
            className=""
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Retailer ID:
            </div>

            <Col sm="12 m-0 p-0 ">
              <Form.Control
                onChange={(e) => {
                  setState({
                    ...state,
                    consumer: e.target.value,
                  });
                }}
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                value={selectedData.id}
                style={{ opacity: 0.5, borderRadius: "4px" }}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className=""
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Old Balance:
            </div>

            <Col sm="12 m-0 p-0">
              {/* <a style={{ height: 20, width: 20 }}>₹</a> */}
              <Form.Control
                // value={details.user ? details.user.userbalance : ""}
                value={selectedData.oldBalance}
                style={{ borderRadius: "4px", opacity: 0.5 }}
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Old Balance`}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className=""
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Add Balance:
            </div>

            <Col sm="12 m-0 p-0">
              {/* <a style={{ height: 20, width: 20 }}>₹</a> */}
              <Form.Control
                // <img src={require("../../assets/images/faces/face1.jpg")} alt="profile" />
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter New Amount`}
                style={{ opacity: 0.5, borderRadius: "4px" }}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className=""
            controlId="exampleForm.ControlTextarea1"
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Remarks:
            </div>

            <Col sm="12 m-0 p-0">
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Remarks`}
                style={{ opacity: 0.5, borderRadius: "4px" }}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className=""
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Enter Pin:
            </div>

            <Col sm="12 m-0 p-0">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <span className="input-group-text bg-transparent border-right-0 no-glow">
                    <i className="mdi mdi-lock-outline text-primary"></i>
                  </span>
                </div>
                <input
                  autocomplete="new-password"
                  autoCorrect="off"
                  autoSave="new-password"
                  type={tpinShow ? "text" : "password"}
                  className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                  id="exampleInputPassword"
                  placeholder={`Enter T Pin`}
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                />
                <div className="input-group-prepend bg-transparent">
                  <span
                    className="input-group-text bg-transparent border-left-0 no-glow"
                    onClick={showTPinClick}
                    style={{ borderRadius: "4px" }}
                  >
                    <i
                      className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                        }`}
                    ></i>
                  </span>
                </div>
              </div>
            </Col>
          </Form.Group>

          {SendotpAdd && otpSent && !otpValidated && (
            <Form.Group
              as={Row}
              className="mb-3"
              style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
            >
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "Poppins",
                  fontSize: "18px",
                }}
                className="text-dark"
              >
                OTP:
              </div>

              <Col sm="12 m-0 p-0">
                <Form.Control
                  onChange={(e) => {
                    setOtpText(e.target.value);
                  }}
                  type="text"
                  className="form-control rounded-0"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder={`Enter OTP`}
                  style={{ opacity: 0.5, borderRadius: "0px" }}
                />
              </Col>
            </Form.Group>
          )}
          <p style={{ color: "red", textAlign: "center" }}>{msg}</p>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="p-3"
          >
            {!SendotpAdd && !otpSent && !otpValidated && (
              <Button
                className="rounded-100"
                style={{
                  margin: "0px 10px",
                  minWidth: "5rem",
                  borderRadius: "4px",
                }}
                onClick={(e) =>
                  Ptype && Amount
                    ? tpinValidation()
                    : setMsg("*Please fill all the fields")
                }
              >
                {"Submit"}
              </Button>
            )}
            {SendotpAdd && !otpSent && !otpValidated && (
              <Button
                className="rounded-100 px-4 "
                style={{
                  margin: "0px ",
                  minWidth: "5rem",
                  borderRadius: "4px",
                }}
                onClick={sendOtp}
              >
                {"Send OTP"}
              </Button>
            )}
            {SendotpAdd && otpSent && !otpValidated && (
              <Button
                className="rounded-100 px-4 "
                style={{
                  margin: "0px 10px",
                  minWidth: "5rem",
                  borderRadius: "4px",
                }}
                onClick={validateOtp}
              >
                {"Validate OTP"}
              </Button>
            )}
            {SendotpAdd && otpValidated && (
              <Button
                className="rounded-100"
                style={{
                  margin: "0px 10px",
                  minWidth: "5rem",
                  borderRadius: "4px",
                }}
                onClick={(e) =>
                  Ptype && Amount
                    ? tpinValidation()
                    : setMsg("*Please fill all the fields")
                }
              >
                {"Submit"}
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Reverse = ({
  SendotpRev,
  details,
  isModalOpen2,
  setModalOpen2,
  selectedData,
  refresh,
}) => {
  const [tpinShow, setTpinShow] = useState(false);
  const [state, setState] = useState({});
  const [Pin, setPin] = useState("");
  const [msg, setMsg] = useState("");
  const [AddBlance, setAddBlance] = useState("");
  const [Ptype, setPtype] = useState("2");
  const [Amount, setAmount] = useState("");
  const [Remark, setRemark] = useState("");
  const [Reverse, setReverse] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Comment, setComment] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [otpText, setOtpText] = useState("");
  const { user, refreshWallet, Profileinfo, setTransactionLoaderState } = useContext(GlobalContext);

  const clearForm = () => {
    setPin("");
    setMsg("");
    setComment("");
    setAmount("");
    setAddBlance("");
  };

  useEffect(() => {
    setState(details);
    console.log(details);
  }, [details]);

  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  const tpinValidation = async () => {
    validateTPin(
      Profileinfo.mobile,
      Pin,
      () => {

        reverse();
      },
      () => {
        // setPayClicked(false);
      }
    );
  };
  // reverse ui
  const reverse = () => {

    setTransactionLoaderState(true)
    var raw = JSON.stringify({
      retpkid: details.user.pk_user_id,
      distripkid: user.pk_user_id,
      amount: Amount1,
      comment: Comment,
      Tpin: Pin,
      paytype: "2",
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };
    console.log(raw);
    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/master/api_master_dist_reverse_balance.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0") {
            cogoToast.success(result.msg);
          } else {
            cogoToast.error(result.msg);
          }
          setReverse(result);
          // cogoToast.info(result.msg);
          refreshWallet();
          refresh();
          document
            .querySelector(".content-wrapper")
            .classList.toggle("sidebar-icon-only");

          setOtpSent(false);
          setOtpValidated(false);
          setTransactionLoaderState(false);
          setModalOpen2(false)
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: details.user.us_loginid,
        //mobile: Profileinfo.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/sendOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setOtpSent(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const sendOtp = async () => {
  // try {
  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");

  // var raw = JSON.stringify({
  // userid: user.ReferId,
  // mobile: Profileinfo.mobile,
  // });

  // var requestOptions = {
  // method: "POST",
  // headers: myHeaders,
  // body: raw,
  // redirect: "follow",
  // };

  // const res = await fetch(
  // "https://apiservices.bumppypay.com/api/Payment/authentication/sendOtp",
  // requestOptions
  // );
  // const data = await res.json();
  // if (data.status == "0") {
  // setOtpSent(true);
  // }
  // } catch (error) {
  // console.log("error", error);
  // }
  // };

  const validateOtp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: details.user.us_loginid,
        otp: otpText,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/authentication/validateOtp",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, 'success')
        setOtpValidated(true);
      } else {
        swal("Failed", data.msg, 'error')
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Modal
      show={isModalOpen2}
      onHide={() => setModalOpen2(false)}
      aria-labelledby=""
      size="40%"
      style={{}}
    >
      <Modal.Body
        style={{
          width: "100%",
          display: "block",
          height: "100%",
          overflowY: "hidden",
        }}
        className="account container-fluid"
      >
        <div
          style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
          className="row"
        >
          <div
            className="row mt-2 m-0 p-2"
            style={{
              color: "rgba(66, 103, 178, 1)",
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "36px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            Retailer Amount
          </div>
          <div style={{ paddingRight: "0px" }} className="col">
            <span
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalOpen2(false);
                setOtpSent(false);
                setOtpValidated(false);
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <Dropdown.Divider />

        <div style={{}}>
          <Form.Group
            as={Row}
            className="mb-1"
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Retailer ID:
            </div>

            <Col sm="12 m-0 p-0">
              <Form.Control
                onChange={(e) => {
                  setState({
                    ...state,
                    consumer: e.target.value,
                  });
                }}
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                //value={details.user ? details.user.us_code : ""}
                value={selectedData.id}
                style={{ opacity: 0.5 }}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-1"
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Old Balance:
            </div>

            <Col sm="12 m-0 p-0">
              {/* <a style={{ height: 20, width: 20 }}>₹</a> */}
              <Form.Control
                //value={details.user ? details.user.userbalance : ""}
                value={selectedData.oldBalance}
                disabled
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Old Blance`}
                style={{ opacity: 0.5 }}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-1"
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Reverse Balance:
            </div>

            <Col sm="12 m-0 p-0">
              {/* <a style={{ height: 20, width: 20 }}>₹</a> */}
              <Form.Control
                // <img src={require("../../assets/images/faces/face1.jpg")} alt="profile" />
                onChange={(e) => {
                  setAmount1(e.target.value);
                }}
                value={Amount1}
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter New Amount`}
                style={{ opacity: 0.5 }}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Remarks:
            </div>

            <Col sm="12 m-0 p-0">
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                type="text"
                className="form-control rounded-100"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder={`Enter Remarks`}
                style={{ opacity: 0.5 }}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-1"
            style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
          >
            <div
              style={{ fontSize: 15, fontFamily: "Poppins", fontSize: "18px" }}
              className="text-dark"
            >
              Enter Pin:
            </div>

            <Col sm="12 m-0 p-0">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <span className="input-group-text bg-transparent border-right-0 no-glow">
                    <i className="mdi mdi-lock-outline text-primary"></i>
                  </span>
                </div>
                <input
                  autocomplete="new-password"
                  autoCorrect="off"
                  autoSave="new-password"
                  type={tpinShow ? "text" : "password"}
                  className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                  id="exampleInputPassword"
                  placeholder={`Enter T Pin`}
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                />
                <div className="input-group-prepend bg-transparent">
                  <span
                    className="input-group-text bg-transparent border-left-0 no-glow"
                    onClick={showTPinClick}
                    style={{ borderRadius: "4px" }}
                  >
                    <i
                      className={`mdi text-primary ${tpinShow ? "mdi-eye" : "mdi-eye-off"
                        }`}
                    ></i>
                  </span>
                </div>
              </div>
            </Col>
          </Form.Group>
          {SendotpRev && otpSent && !otpValidated && (
            <Form.Group
              as={Row}
              className="mb-1"
              style={{ flex: "1", justifyContent: "space-between", margin: 10 }}
            >
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "Poppins",
                  fontSize: "18px",
                }}
                className="text-dark"
              >
                OTP:
              </div>
              <Col sm="12 m-0 p-0">
                <Form.Control
                  onChange={(e) => {
                    setOtpText(e.target.value);
                  }}
                  type="text"
                  className="form-control rounded-0"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder={`Enter OTP`}
                  style={{ opacity: 0.5, borderRadius: "0px" }}
                />
              </Col>
            </Form.Group>
          )}
          <p style={{ color: "red", textAlign: "center" }}>{msg}</p>
          <card
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            {SendotpRev && !otpSent && !otpValidated && (
              <Button
                className="rounded-100 px-4"
                style={{
                  margin: "0px 10px",
                  minWidth: "6rem",
                  borderRadius: "4px",
                }}
                onClick={sendOtp}
              >
                {"Send OTP"}
              </Button>
            )}
            {!SendotpRev && !otpSent && !otpValidated && (
              <Button
                className="rounded-100 px-4"
                style={{
                  margin: "0px 10px",
                  minWidth: "6rem",
                  borderRadius: "4px",
                }}
                onClick={(e) =>
                  Pin && Amount1
                    ? tpinValidation()
                    : setMsg("*Please fill all the fields")
                }
              >
                {"Submit"}
              </Button>
            )}
            {SendotpRev && otpSent && !otpValidated && (
              <Button
                className="rounded-100 "
                style={{
                  margin: "0px 10px",
                  minWidth: "5rem",
                  borderRadius: "4px",
                }}
                onClick={validateOtp}
              >
                {"Validate OTP"}
              </Button>
            )}
            {SendotpRev && otpValidated && (
              <Button
                className="rounded-100 px-4"
                style={{
                  margin: "0px 10px",
                  minWidth: "6rem",
                  borderRadius: "4px",
                }}
                onClick={(e) =>
                  Pin && Amount1
                    ? tpinValidation()
                    : setMsg("*Please fill all the fields")
                }
              >
                {"Submit"}
              </Button>
            )}
          </card>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardReport;