import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  excelMapping,
  ExportToExcel,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {
  getPaynnowTransactionStatus,
  getPaynnowTransactionStatus2,
} from "../util/utility";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import popupImage from "../../../assets/images/invoice.png";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const columns = [
  {
    dataField: "tid",
    text: "TXN ID",
  },
  {
    dataField: "user_code",
    text: "User Code",
  },
  {
    dataField: "user_name",
    text: "User",
  },
  {
    dataField: "user_mobile",
    text: "Mobile",
  },
  {
    dataField: "accountNumber",
    text: "Account",
  },
  {
    dataField: "opening_bal",
    text: "Opening Balance",
  },
  {
    dataField: "amount",
    text: "Amount",
  },
  {
    dataField: "closing_bal",
    text: "Closing Balance",
  },
  {
    dataField: "charges",
    text: "Charges",
  },
  {
    dataField: "sales_user_name",
    text: "Sales Person",
  },
  {
    dataField: "rch_com_distributer",
    text: "Dist Commission",
  },
  {
    dataField: "rch_com_cnf",
    text: "MD Commission",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "date",
    text: "Date",
  },
];

export const columns2 = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "refId",
    text: "RefId",
  },
  {
    dataField: "businessName",
    text: "Business Name",
  },
  {
    dataField: "opening_bal",
    text: "Opening Balance",
  },
  {
    dataField: "amount",
    text: "Amount",
  },
  {
    dataField: "closing_bal",
    text: "Closing Balance",
  },
  {
    dataField: "created_on",
    text: "Date",
  },
];

const SuperAdmin = () => {
  const [agentId, setAgentId] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div className="card m-0 p-0 pt-3">
        <div className="card-body m-0 p-0">
          <div className="col-3 m-0 p-0">
            <div
              className="m-0 p-0 pl-3 align-top"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0em",
                textAlign: "left",
                padding: "0px",
                background: "#FFFFFF",
                color: "#4267B2",
                fontWeight: "bolder",
                fontfamily: "Poppins !impotent",
                fontSize: "28px",
              }}
            >
              Client Topup
            </div>
          </div>

          <div className="">
            <div
              defaultActiveKey="profile"
              className=" tab-header justify-content-start"
            >
              <div
                className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                onClick={() => handleTabClick(0)}
              >
                Client Topup
              </div>
              <div
                className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                History
              </div>
            </div>
            <div className="tab-content">
              {activeTab === 0 && (
                <div>
                  <AllClient agentId={agentId} />
                </div>
              )}
              {activeTab === 1 && (
                <div>
                  <History agentId={agentId} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AllClient = () => {
  const history = useHistory();
  const accountList = [
    { label: "YES BANK [001463400003400]", value: "001463400003400" },
    { label: "YES BANK [001463400006363]", value: "001463400006363" },
    { label: "YES HUB [435111000023]", value: "435111000023" },
  ];
  const { user } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    signerName: "",
    signerEmail: "",
    signerCity: "",
    purpose: "",
    pdfFile: null,
    numPages: null,
    file: null,
  });

  const [state, setState] = useState({
    amount: "",
    topupVia: "",
    RefId: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
    RefId: "",
  });

  const [selectedVendor, setSelectedVendor] = useState({});
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [searchText, setSearchText] = useState("");

  const OpenModal = () => {
    setShowModal(!showModal);
  };
  const GetAllMerchant = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        merchant_id: "2",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://payin.remittpe.com/api/Intent/GetAllMerchant",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0") {
            setDataToDisplay(result.data);
          } else {
            setDataToDisplay([]);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const payoutTopupProccess = async (merchantId) => {
    //     return;
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );
      var raw = JSON.stringify({
        merchantId: merchantId,
        userId: user.pkid,
        amount: state.amount,
        merchantSecret: "",
        referenceId: state.RefId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://payin.remittpe.com/api/Intent/payoutTopupProccess",
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.responseCode == "00") {
            // setData(data?.data)
            swal("Success", data.responseMessage, "success");
          } else {
            swal("Error", "Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  };

  const clearForm = () => {
    setFormData({
      signerName: "",
      signerEmail: "",
      signerCity: "",
      purpose: "",
      pdfFile: null,
      numPages: null,
      file: null,
    });
  };

  useEffect(() => {
    GetAllMerchant();
  }, []);

  return (
    <div className="m-0">
      <Modal show={showModal} onHide={OpenModal}>
        <Modal.Header closeButton>
          <Modal.Title> Topup Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="card-body m-0 p-1 px-2">
              <div>
                <div className="row ">
                  <div className="col ">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`ID`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User ID"
                        value={selectedVendor.merchantId}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                    {errors.UserID && (
                      <div className="text-danger">{errors.UserID}</div>
                    )}
                  </div>

                  <div className="col">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`User Name `}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User Name"
                        value={selectedVendor.userName}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                    {errors.TransactionID && (
                      <div className="text-danger">{errors.TransactionID}</div>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div className="col ">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Business Name`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        maxLength={10}
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Business Name"
                        value={selectedVendor.businessName}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>{" "}
                    {errors.Mobile && (
                      <div className="text-danger">{errors.Mobile}</div>
                    )}
                  </div>

                  <div className="col">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Topup Amount`}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Amount"
                        value={state.amount}
                        onChange={(e) => {
                          setState({ ...state, amount: e.target.value });
                        }}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>{" "}
                    {errors.Name && (
                      <div className="text-danger">{errors.amount}</div>
                    )}
                  </div>
                </div>

                <div className="row ">
                  <div className="col">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Referance ID`}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Referance ID"
                        value={state.RefId}
                        onChange={(e) => {
                          setState({ ...state, RefId: e.target.value });
                        }}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>{" "}
                    {errors.Name && (
                      <div className="text-danger">{errors.RefId}</div>
                    )}
                  </div>

                  <div className="col"></div>
                </div>

                <div className="row ">
                  <div className="col">
                    <Button
                      className="rounded-10 btn-block  "
                      variant="primary"
                      onClick={() => {
                        payoutTopupProccess(selectedVendor.merchantId);
                      }}
                    >
                      {" "}
                      Submit
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      className="rounded-10 btn-block border border-primary"
                      variant="white"
                      onClick={clearForm}
                    >
                      {" "}
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr className="borderless-table text-center border-bottom">
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              Id
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Business Name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              User Name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Payment API Balance
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Virtual Balance
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Action
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr key={ind}>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {ind + 1}
              </td>

              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>
                  <div>{e.merchantId}</div>
                </div>
              </td>

              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>
                  <div>{e.businessName}</div>
                </div>
              </td>

              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>
                  <div>{e.userName}</div>
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div>
                  <div>{e.paymentAPIBalance}</div>
                </div>
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {e.virtualBalance}
              </td>
              <td
                className="text-center border-bottom"
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setSelectedVendor(e);
                    OpenModal();
                  }}
                >
                  Topup
                </button>
                <Button
                  className="btn btn-sm btn-secondary"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    history.push("/admin/ClientHistoryReport");
                  }}
                >
                  History
                </Button>
              </td>
            </tr>
          ))}
        </table>
        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page) => {
            setCurrentPage(page);
          }}
          count={dataToDisplay.length}
          total={total}
          callback2={(offsetValue) => {
            setOffset(offsetValue);
          }}
        />
      </div>
    </div>
  );
};

const History = () => {
  const [pageAccess, setPageAccess] = useState({});
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [billDetails, setBillDetails] = useState({});
  const [amount, setAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getPayoutTopupReport = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        merchantId: "1045",
        userId: "2",
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://payin.remittpe.com/api/Intent/v1/getPayoutTopupReport`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.responseCode == "00") {
            setData(result.data);
          } else {
            setData([]);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "15%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const positionStyle = {
    position: "absolute",
    top: -27,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const getPageAccess = (menuId) => {
    const sub_menus_access_info = JSON.parse(
      sessionStorage.getItem("sub_menus")
    );
    sub_menus_access_info.forEach((acc) => {
      if (acc.pk_menu_id == menuId) {
        setPageAccess(acc);
      }
    });
  };
  useEffect(() => {
    getPayoutTopupReport();
    getPageAccess(14);
  }, []);
  
  return (
    <>
      <div>
        <div className="row m-0 p-0 pt-1 align-items-center">
          <div className="col-3 m-0 p-0">
            <div
              className="m-0 p-0 pl-3 align-top"
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0em",
                textAlign: "left",
                padding: "0px",
                background: "#FFFFFF",
                color: "#4267B2",
                fontWeight: "bolder",
                fontfamily: "Poppins !impotent",
                fontSize: "28px",
              }}
            >
              {" "}
              Client History
            </div>
          </div>

          <div className="col-9 m-0 p-0 ">
            <div className="row m-0 p-0 justify-content-end">
              <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={iconStyle}
                  className="ml-2"
                />
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
              </div>
              <div
                className="pr-1 pl-2 p-0 m-0"
                style={{ width: "18%", height: "20%" }}
              >
                <Form.Group className="p-0 m-0">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control rounded-100"
                    placeholderText="Start Date"
                  />
                </Form.Group>
              </div>
              <div
                className="px-1 p-0 m-0"
                style={{ width: "18%", height: "20%" }}
              >
                <Form.Group className="p-0 m-0">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="form-control rounded-100"
                    placeholderText="End Date"
                  />
                </Form.Group>
              </div>
              <div
                className="pl-1 p-0 m-0"
                style={{ width: "14%", height: "20%" }}
              >
                <Form.Group className="mt-0 p-0 m-0">
                  <Button
                    className=" btn btn-primary btn-lg btn-block "
                    style={{
                      background: "#4267B2",
                      color: "white",
                    }}
                    onClick={getPayoutTopupReport}
                  >
                    {"Search"}
                  </Button>
                </Form.Group>
              </div>

              <div
                className="m-0 p-0 hover-container"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ExportToExcel(
                    `Client_History_Report${now()}`,
                    excelMapping(data, columns2)
                  );
                }}
              >
                <img
                  className="pl-1 p-0"
                  src={require("../../../assets/images/download Icon.png")}
                  style={{ width: `3.5rem`, height: "85%" }}
                />
                <div style={positionStyle1} className="hover-text bg-dark ">
                  Export to Excel
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="m-0 p-0" />

        <div style={{ width: "100%" }}>
          <div style={{ alignItems: "center" }}>
            <div style={{ flex: "1" }}></div>
          </div>
        </div>
        <div className=" scroll-inner admin-fix-width borderless-table ">
          <table
            className="my-table transparent-table text-center border-bottom"
            style={{
              width: "100%",
              overflowX: "auto",
              border: "0px",
              overflow: "auto",
            }}
          >
            <tr>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {"Sr"}
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                ID
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Referance ID
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Business Name
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Opening Bal
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Amount
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Closing Bal
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Created On
              </th>
              <th
                className="my-table transparent-table text-center border-bottom "
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                Updated On
              </th>
            </tr>
            {dataToDisplay.map((e, ind) => (
              <tr>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {ind + 1}
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.id}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div>{e.refId}</div>
                  </div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.businessName}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.opening_bal}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.amount}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>{e.closing_bal}</div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {e.created_on}
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {e.updated_on}
                </td>
              </tr>
            ))}
          </table>
        </div>

        <Pagination
          page={currentPage}
          limit={pages}
          callback={(page) => {
            setCurrentPage(page);
          }}
          count={dataToDisplay.length}
          total={total}
          callback2={(offsetValue) => {
            setOffset(offsetValue);
          }}
        />
      </div>
    </>
  );
};

const ClientHistory = () => {
  const accountList = [
    { label: "YES BANK [001463400003400]", value: "001463400003400" },
    { label: "YES BANK [001463400006363]", value: "001463400006363" },
    { label: "YES HUB [435111000023]", value: "435111000023" },
  ];
  const { user } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    signerName: "",
    signerEmail: "",
    signerCity: "",
    purpose: "",
    pdfFile: null,
    numPages: null,
    file: null,
  });

  const [state, setState] = useState({
    amount: "",
    topupVia: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
  });

  const [selectedOption, setSelectedOption] = useState("");

  const [selectedVendor, setSelectedVendor] = useState({});
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const OpenModal = () => {
    setShowModal(!showModal);
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const getVendorAccounts = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        userid: user.pkid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_APISERVICES}/api/Admin/getAllVendorAccountsAdmin`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == "0") {
            setData(data?.data);
          } else {
            swal("Error", "Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const vendorTopup = async (e) => {
    //     return;
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        adminid: user.pkid,
        vendorid: selectedVendor.id,
        amount: state.amount,
        topupVia: state.topupVia.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_APISERVICES}/api/Admin/adminVendorTopup`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.status == "0") {
            // setData(data?.data)
            swal("Success", data.msg, "success");
          } else {
            swal("Error", "Something went wrong", "error");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {}
  };

  const clearForm = () => {
    setFormData({
      signerName: "",
      signerEmail: "",
      signerCity: "",
      purpose: "",
      pdfFile: null,
      numPages: null,
      file: null,
    });
  };

  useEffect(() => {
    getVendorAccounts();
  }, []);

  const DeteAccount = (vendor_name, acc_no, acc_ifsc, bank_name, id) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure you want to delete this account?\n\nVendor Name: ${vendor_name}\nBank Name: ${bank_name}\nAccount Number: ${acc_no}\n IFSC Code:${acc_ifsc}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log("hwlo");

        deleteVendorTopupAccount(id);
      }
    });
  };

  const deleteVendorTopupAccount = async (id) => {
    try {
      //   setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        accountId: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Admin/deleteVendorTopupAccount",
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMessage, "success");
        // refresh();
      } else {
        swal("Failed", data.responseMessage, "error");
      }
      //   setTransactionLoaderState(false);
    } catch (error) {
      //   setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  return (
    <div className="m-0">
      <Modal show={showModal} onHide={OpenModal}>
        <Modal.Header closeButton>
          <Modal.Title> Topup Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="card-body m-0 p-1 px-2">
              <div>
                <div className="row ">
                  <div className="col ">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Vendor Name`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter User ID"
                        value={selectedVendor.vendor_name}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                    {errors.UserID && (
                      <div className="text-danger">{errors.UserID}</div>
                    )}
                  </div>

                  <div className="col">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Account Number`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Transaction ID"
                        value={selectedVendor.acc_no}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>
                    {errors.TransactionID && (
                      <div className="text-danger">{errors.TransactionID}</div>
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div className="col ">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Vendor Ifsc`}
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        maxLength={10}
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Customer Mobile"
                        value={selectedVendor.acc_ifsc}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>{" "}
                    {errors.Mobile && (
                      <div className="text-danger">{errors.Mobile}</div>
                    )}
                  </div>

                  <div className="col">
                    <Form.Group>
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Topup Amount`}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control rounded-lg"
                        aria-label="Notes"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Amount"
                        value={state.Name}
                        onChange={(e) => {
                          setState({ ...state, amount: e.target.value });
                        }}
                        style={{ borderRadius: "0px" }}
                      />
                    </Form.Group>{" "}
                    {errors.Name && (
                      <div className="text-danger">{errors.Name}</div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <Form.Group className="p-0 m-0">
                      <Form.Label className="text-dark">
                        <span style={{ color: "red" }}>*</span>
                        {`Topup Via`}
                      </Form.Label>

                      <Select
                        value={state.topupVia}
                        options={accountList}
                        onChange={(e) => {
                          setState({ ...state, topupVia: e });
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-3 ">
                    <Button
                      className="rounded-10 btn-block  "
                      variant="primary"
                      onClick={vendorTopup}
                    >
                      {" "}
                      Submit
                    </Button>
                  </div>
                  <div className="col-3 ">
                    <Button
                      className="rounded-10 btn-block border border-primary"
                      variant="white"
                      onClick={clearForm}
                    >
                      {" "}
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="m-0 p-0 card mx-3 center-block scroll-inner">
        <table
          className="my-table transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <tr>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {"Sr"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              vendor_name
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              account
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              ifsc
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              bank
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Txn Mode
            </th>
          </tr>
          {data
            .filter((e) => e.group_name === "API_VENDOR")
            .map((e, ind) => (
              <tr key={ind}>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {ind + 1}
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div>{e.vendor_name}</div>
                  </div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div>{e.acc_no}</div>
                  </div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div>{e.acc_ifsc}</div>
                  </div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div>{e.bank_name}</div>
                  </div>
                </td>
                <td
                  className="text-center border-bottom"
                  style={{
                    fontSize: "0.8rem",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                  }}
                >
                  {e.txn_mode}
                </td>
              </tr>
            ))}
        </table>
      </div>
    </div>
  );
};

export const Pagination = ({
  page,
  limit,
  callback,
  total,
  count,
  callback2,
}) => {
  console.table({ page, limit, callback });
  const selectStyleLeftBorder = {
    borderRadius: "0px",
    fontFamily: "Poppins",
    fontsize: "16px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)", // Remove the border
    borderRadius: "0px",
    borderLeft: "none", // Remove right side border
    borderTop: "none", // Remove top border
    borderBottom: "none", // Remove bottom border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyleBorder = {
    border: "1px solid rgba(0, 0, 0, 0.1)", // Remove the border
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  const selectStyle = {
    border: "none", // Remove the border
    fontFamily: "Poppins",
    fontsize: "16px",
  };
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "12px 0px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>

      <div className="d-flex btn-group p-1" style={selectStyleBorder}>
        <select
          style={selectStyleLeftBorder}
          className=" d-flex justify-content-end "
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset} className="d-flex justify-content-end">
              {offset}
            </option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {"<<"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            className="rounded-0 bg-white text-dark border-white underline-text"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}

        <Button className="bg-white text-primary " style={selectStyle}>
          {" "}
          {page.toString()}
        </Button>

        {/* Next And Previous Button Code */}
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            className="rounded-0 bg-white text-dark border-white"
          >
            {">>"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const UpdateTariffPlanModal = ({
  billDetails,
  isModalOpen,
  setModalOpen2,
  refresh,
}) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [remark, setRemark] = useState("");
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  console.log(remark);

  const handleConfirm = () => {
    console.log("Remark:", remark);
    vendorPaymentsRefund();
  };

  const vendorPaymentsRefund = async () => {
    try {
      setTransactionLoaderState(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic UmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1"
      );

      var raw = JSON.stringify({
        adminId: user.pkid,
        transactionId: billDetails.id,
        remarks: remark,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Admin/cmsTransactionRefund`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode == "00") {
        swal("Success", data.responseMessage, "success");
      } else {
        swal("Failed", data.responseMessage, "error");
      }

      refresh();

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen2(false)}
      aria-labelledby="example-modal-sizes-title-md"
      size="s"
    >
      <Modal.Body
        style={{
          width: "100%",
          display: "block",
          overflowY: "hidden",
          height: "17rem",
        }}
        className="account"
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "28px",
            fontWeight: "600",
            lineHeight: "48px",
            textAlign: "left",
            padding: "0px",
            background: "#FFFFFF",
            color: "#4267B2",
          }}
          className="d-flex justify-content-between pl-2"
        >
          <strong>Are you sure?</strong>
          <strong
            className="px-1 hover"
            onClick={() => {
              setModalOpen2(false);
            }}
          >
            <img
              src={require("../../../assets/images/Vector.png")}
              alt="Close"
            />
          </strong>
        </div>

        <div style={{ padding: "20px" }}>
          <p>
            Refund amount of {twoDecimalPlaces(billDetails.rch_amount)} to{" "}
            {billDetails.user_code}{" "}
          </p>
          <div className="form-group">
            <label htmlFor="remark">Remark</label>
            <input
              type="text"
              className="form-control"
              id="remark"
              placeholder="Enter your remark"
              value={remark}
              onChange={handleRemarkChange}
            />
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-danger mr-2" onClick={handleConfirm}>
              Confirm
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setModalOpen2(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuperAdmin;
