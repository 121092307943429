import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import ViewTariffTable from "./ViewTariffTable";
const ViewTariff = () => {
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div style={{ padding: "1rem" }} className="bg-light" >
          <div style={{ borderRadius: "7px" }} className="card mt-4 p-0 ml-2 mr-2 ">
            <div className="card-body m-0 p-2">
              <div>
               

                <ViewTariffTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default ViewTariff;
