import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Row, Col, Form, Card, Tab, Nav } from "react-bootstrap";
import { Redirect, useHistory } from "react-router";
import Select from "react-select";
import "./tab.css";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";

export const PrepaidRecharge = () => {

    return (
        <GlobalContext.Consumer>
            {(context) =>
                context.user == null ? (
                    <Redirect to="/" />
                ) : (
                    <>
                        <div style={{backgroundColor:COLOR_BACKGROUND_BODY}}>
                            <div className="row ">
                                <div className="col-12 grid-margin">
                                    <div
                                        className="border p-3"
                                        style={{ backgroundColor: "#002970", color: "white" }}
                                    >
                                        <b> Prepaid Recharge</b>
                                    </div>

                                    <Row>
                                        <Col md={4}>
                                            <Card style={{ borderRadius: "15px" }}>
                                                <div style={{ textAlign: "center" }}><h4>Mobile Recharge</h4></div>
                                                <div className="p-4">
                                                    <Form.Control placeholder="Mobile Number" className="mb-3" />
                                                    <Select className="mb-3" />
                                                    <Form.Control placeholder="Circle" className="mb-3" />
                                                    <Form.Control placeholder="Amount" className="mb-3" />
                                                    <Button className="form-control" type="button" variant="outline-info">Recharge</Button>
                                                </div>
                                            </Card>
                                        </Col>

                                        <Col md={8}>
                                            <Card style={{ borderRadius: "15px" }}>
                                                <div className="p-2">
                                                    <Tab.Container defaultActiveKey="first">
                                                        <section className="tabstyle">
                                                            <Nav>
                                                                <Nav.Item >
                                                                    <Nav.Link eventKey="first" style={{ color: 'grey' }}><strong>Browse Plans</strong></Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="second" style={{ color: 'grey' }}><strong>Some Name</strong></Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="third" style={{ color: 'grey' }}><strong>Anything</strong></Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </section>

                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="first">
                                                                <div className="p-3">
                                                                    <h5>Browse Plans of Vi - Delhi NCR</h5>
                                                                    <Tab.Container defaultActiveKey="first" >
                                                                        <section className="tabstyle">
                                                                            <Nav style={{ backgroundColor: 'whitesmoke' }}>
                                                                                <Nav.Item>
                                                                                    <Nav.Link eventKey="first" style={{ color: 'grey' }}><strong>ABCDEF</strong></Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link eventKey="second" style={{ color: 'grey' }}><strong>GHIJKL</strong></Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link eventKey="third" style={{ color: 'grey' }}><strong>MNOPQR</strong></Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link eventKey="fourth" style={{ color: 'grey' }}><strong>STUVW</strong></Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link eventKey="fifth" style={{ color: 'grey' }}><strong>XYZ</strong></Nav.Link>
                                                                                </Nav.Item>
                                                                            </Nav>
                                                                        </section>
                                                                        <Tab.Content>
                                                                            <Tab.Pane eventKey="first">
                                                                                <div className="table-responsive">
                                                                                    <table className="table" >
                                                                                        <thead>
                                                                                            <tr style={{border:'none'}}>
                                                                                                <th style={{border:'none',backgroundColor:'#b9afaf2e'}}>Plans</th>
                                                                                                <th style={{border:'none',backgroundColor:'#b9afaf2e'}}>Plans+</th>
                                                                                                <th style={{border:'none',backgroundColor:'#b9afaf2e'}}>Plans Pro</th>
                                                                                                <th style={{border:'none',backgroundColor:'#b9afaf2e'}}>Plans Pro Max</th>
                                                                                                <th style={{border:'none',backgroundColor:'#b9afaf2e'}}>Plans Ultra Pro Max</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                           
                                                                                                <tr>
                                                                                                    <td style={{border:'none'}}> t</td>
                                                                                                    <td style={{border:'none'}}> i</td>
                                                                                                    <td style={{border:'none'}}> s</td>
                                                                                                    <td style={{border:'none'}}> u</td>
                                                                                                    <td style={{border:'none'}}> m</td>
                                                                                                </tr>
                                                                                        </tbody>
                                                                                    </table>

                                                                                </div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey="second">
                                                                                <div>b</div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey="third">
                                                                                <div>a</div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey="fourth">
                                                                                <div>a</div>
                                                                            </Tab.Pane>
                                                                            <Tab.Pane eventKey="fifth">
                                                                                <div>e</div>
                                                                            </Tab.Pane>
                                                                        </Tab.Content>
                                                                    </Tab.Container>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="second">
                                                                <div className="p-3"><h5>Browse Plans of Vi - Delhi NCR</h5></div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="third">
                                                                <div className="p-3"><h5>Browse Plans of Vi - Delhi NCR</h5></div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Tab.Container>
                                                </div>

                                            </Card>
                                        </Col>

                                    </Row>


                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </GlobalContext.Consumer>
    );
};

export default PrepaidRecharge;
