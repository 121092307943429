import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import { COMPANY_NAME } from "../../utils/StringConstants";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export const columns = [
  {
    dataField: "bankName",
    text: "Bank Name",
  },
  {
    dataField: "ifscCode",
    text: "IFSC Code",
  },
  {
    dataField: "accountNo",
    text: "Account No",
  },
  {
    dataField: "transType",
    text: "Type",
  },
  {
    dataField: "charges",
    text: "Charges",
  },
];

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const LoadBankListTable = () => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);
  const { user ,  setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [inputState, setInputState] = useState({ userType: "" });
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const { Profileinfo } = useContext(GlobalContext);
  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  const getAccountLedger = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        paytype: inputState.userType,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/bank/get_all_load_bank.aspx`,
        requestOptions
      );
      const data = await res.json();
      setData(data.data);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAccountLedger();
  }, []);

  const searchBarStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '6px',
    padding: '0px',
    backgroundColor: '#fff',
    width: '30%', // Adjust the width as needed
    height:'20%',
  };
  const inputStyle = {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
    marginLeft: '8px',
    width: '100%',
    "fontFamily":"Poppins",
    "fontWeight":"400",
  };
  const iconStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
  };



  return (
    <>

    <div className="row m-0 p-0">
      <div className="col-4 m-0 p-0">
                

                <div
                  style={{
                    "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",

                   

                  }}
                >
                  Load Bank List
                </div>

      <AddLoadBankModal
        details={null}
        isModalOpen={modalOpen}
        setModalOpen={(modalState) => {
          setModalOpen(modalState);
        }}
        refresh={getAccountLedger}
      />
      </div>

  
      <div className="col-8 m-0 p-0 ">
      <div className="row  m-0 p-0  justify-content-end">
       
          <div style={searchBarStyle} className="bg-light">
                <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2"/>
                <input
                  type="text"
                  className="form-control pl-2"
                  placeholder="Search"
                  aria-label="Search"
                  style={inputStyle}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />  
                </div>

      <div className=" m-0 p-0 pl-2 ">
       
        <Button
          className=" rounded-50 btn btn-primary btn-lg btn-block "
          onClick={() => {
            setModalOpen(true);
          }}
        >
          {" "}
          Add Bank
        </Button>
        </div>

     
       <div className="m-0 p-0 hover-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(
                `Bank_List_${now()}`,
                excelMapping(data, columns)
              );
            }}
          >
            <img className="pl-1"
              src={require("../../../assets/images/download Icon.png")}
              style={{ width: `3rem`, height: 'auto' }}
            />
             <div div style={{  color:'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
         
          </div>
      </div>
      
  
      </div>
   
      </div>

      <hr className="m-0 p-0"/>

      <div className="my-table scroll-inner admin-fix-width transparent-table ">
        <table className="my-table transparent-table text-center border-bottom justify-content-around ">
          <tr className="my-table transparent-table border-bottom ">
            <th  className="my-table transparent-table text-center border-bottom py-3" style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Sr"}</th>

            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Bank Name"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"IFSC Code"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Account No"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Type"}</th>
            <th  className="my-table transparent-table text-center border-bottom " style={{ "fontFamily":"Poppins","fontSize":"16px","fontWeight":"700","lineHeight":"24px","letterSpacing":"0em","textAlign":"center"}}> {"Charges"}</th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr  className="my-table transparent-table border-bottom py-5">
             <td  style={{"fontFamily":"Poppins",  fontSize: "0.8rem" }}> 
              {" "}
              <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
              <span>{ind + 1}</span>
              </div>
              </td>
              <td  style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3">
                  <span >{`${e.bankName}`}</span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                {" "}
                <div className="d-flex flex-column align-items-center py-3">
                  <span>{e.ifscCode}</span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3">
                  <span >{e.accountNo}</span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3">
                  <span >{e.transType}</span>
                </div>
              </td>
              <td style={{"fontFamily":"Poppins", fontSize: "0.8rem" }}>
                <div className="d-flex flex-column align-items-center py-3">
                  <span >{e.charges}</span>
                </div>
              </td>
            </tr>
          ))}
                  {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const AddLoadBankModal = ({ details, isModalOpen, setModalOpen, refresh }) => {
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };
  const [state, setState] = useState({
    bank_name: "",
    branch: "",
    ifsc_code: "",
    account_no: "",
    tras_type: "NEFT/RTGS/IMPS/UPI",
    charges: "",
    remark: "",
    bank_id: "",
    charge_1: "",
    charge_2: "",
    bank: "",
  });
  const [banks, setBanks] = useState([]);
  const { user ,  setTransactionLoaderState } = useContext(GlobalContext);
  const transactionTypes = [
    { label: "NEFT/RTGS/IMPS/UPI", value: "NEFT/RTGS/IMPS/UPI" },
  ];

  const createTariff = async () => {
    const {
      account_no,
      bank,
      branch,
      charge_1,
      charge_2,
      charges,
      ifsc_code,
      remark,
      tras_type,
    } = state;
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        company_name: COMPANY_NAME,
        account_no,
        bank_id: bank.bankId,
        bank_name: bank.bankName,
        branch,
        charge_1,
        charge_2,
        charges: "No Charges",
        ifsc_code,
        remark,
        tras_type: tras_type.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/bank/create_load_bank.aspx`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("Bank Added Successful", data.msg, "success");
        setModalOpen(false);
        refresh();
      } else {
        swal("Bank Addition Failed", data.msg, "error");
      }
      clearForm();
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
      swal("Bank Addition Failed", "Error From Server", "error");
    }
  };
  const clearForm = () => {
    setState({
      bank_name: "",
      branch: "",
      ifsc_code: "",
      account_no: "",
      tras_type: "NEFT/RTGS/IMPS/UPI",
      charges: "",
      remark: "",
      bank_id: "",
      charge_1: "",
      charge_2: "",
      bank: "",
    });
  };

  const getBankList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/bank/get_all_bank_list.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setBanks(
          data.data.map((role) => {
            return { ...role, label: `${role.bankName}`, value: role.bankId };
          })
        );
      }  setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  const validateForm = () => {
    console.log(state);
    if (
      state.bank == "" ||
      state.branch == "" ||
      state.ifsc_code == "" ||
      state.account_no == ""
    ) {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    }
    createTariff();
  };
  console.log(state);
  useEffect(() => {
    getBankList();
  }, []);
  return (
    <Modal
    show={isModalOpen}
    onHide={() => setModalOpen(false)}
    aria-labelledby=""
    size="lg"

    style={{}}
     
    >
      <Modal.Body
        style={{ width: "100%", display: "block",height: "100%" ,overflowY: 'hidden' }}
        className="account container-fluid"
      >
        <div
           style={{
            "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                    ,padding: "0px",
                    background: "#FFFFFF",
                    color: "#4267B2",
                    fontWeight: "bolder",
                    fontfamily: "Poppins !impotent",
                    fontSize:"28px",

           

          }}
          className="d-flex justify-content-between"
        >
           Add Bank
          <div
             style={{
              "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
                      ,padding: "0px",
                      background: "#FFFFFF",
                      color: "rgba(255, 46, 46, 1)",
                      fontWeight: "bolder",
                      fontfamily: "Poppins !impotent",
                      fontSize:"28px",
  
             
  
            }}
            className="px-1 hover"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {" "}
                                  <img
                                    src={require("../../../assets/images/Vector.png")}
                                    style={{ width: "" }}
                                    />
           
            
          </div>
        </div>
        <div className="">

            <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Bank
              </Form.Label>
              <Select
                options={banks}
                value={state.bank}
                onChange={(e) => {
                  setState({ ...state, bank: e, ifsc_code: e.ifscCode });
                }}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>IFSC Code
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter IFSC Code"
                value={state.ifsc_code}
                onChange={(e) => {
                  setState({ ...state, ifsc_code: e.target.value });
                }}
                style={{ borderRadius: "5px" }}
              />
            </Form.Group>
          </div>
          </div>

          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Account Number{" "}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Account Number"
                value={state.account_no}
                onChange={(e) => {
                  setState({ ...state, account_no: e.target.value });
                }}
                style={{ borderRadius: "5px" }}
              />
            </Form.Group>
          </div>


          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Branch{" "}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Branch Name"
                value={state.branch}
                onChange={(e) => {
                  setState({ ...state, branch: e.target.value });
                }}
                style={{ borderRadius: "5px" }}
              />
            </Form.Group>
          </div>
          </div>

          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>Select Transaction Types
              </Form.Label>
              <Select
                options={transactionTypes}
                value={state.tras_type}
                onChange={(e) => {
                  setState({ ...state, tras_type: e });
                }}
              />
            </Form.Group>
          </div>


          <div className="col-6">
            <Form.Group>
              <Form.Label>Remark</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Remark"
                value={state.remark}
                onChange={(e) => {
                  setState({ ...state, remark: e.target.value });
                }}
                style={{ borderRadius: "5px" }}
              />
            </Form.Group>
          </div>
          </div>

          <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>charge 1
              </Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Charge 1"
                value={state.charge_1}
                onChange={(e) => {
                  setState({
                    ...state,
                    charge_1: e.target.value,
                  });
                }}
                style={{ borderRadius: "5px" }}
              />
            </Form.Group>
          </div>




          <div className="col-6">
            <Form.Group>
              <Form.Label>
                {" "}
                <span style={{ color: "red" }}>*</span>charge 2
              </Form.Label>
              <Form.Control
                type="number"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Charge 2"
                value={state.charge_2}
                onChange={(e) => {
                  setState({ ...state, charge_2: e.target.value });
                }}
                style={{ borderRadius: "5px" }}
              />
            </Form.Group>
          </div>
          </div>


        </div>
        <div className="row ">
        <div className="col-4 ">
          <Button className="rounded-100 btn btn-primary btn-lg btn-block" onClick={validateForm}>
            Add Bank
          </Button>
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadBankListTable;
