import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
    excelMapping,
    ExportToExcel,
    formatDateDDMMYYHHMM,
    generateHashKey2,
    twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { BASE_URL_API_TRANXT, BASE_URL_APISERVICES } from "../../utils/Constants";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { DROP_SHADOW, offsetArr } from "../../utils/CommonConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import { getPaynnowTransactionStatus, getPaynnowTransactionStatus2 } from "../util/utility";

export const columns = [
    {
        dataField: "rch_transaction_id",
        text: "TXN ID",
    },
    // {
    //   dataField: "bank_rrn",
    //   text: "Bank RRN",
    // },
    {
      dataField: "payu_id",
      text: "refrenceId",
    },
    {
        dataField: "rch_indate",
        text: "Date",
    },
    {
        dataField: "user_code",
        text: "User Code",
    },
    {
        dataField: "user_name",
        text: "User Name",
    },
    {
        dataField: "rch_account_no",
        text: "Account Number",
    },
    {
        dataField: "rch_ifsc_code",
        text: "IFSC Code",
    },
    {
        dataField: "rch_before_balance",
        text: "Opening Balance",
    },
    {
        dataField: "rch_amount",
        text: "Amount",
    },
    {
        dataField: "rch_after_balance",
        text: "Closing Balance",
    },
    {
        dataField: "rch_charges",
        text: "Charges",
    },
    
    {
        dataField: "rch_com_cnf",
        text: "Master Comm",
    },
    {
        dataField: "rch_com_distributer",
        text: "Dist Comm",
    },
    {
        dataField: "rch_com_retailer",
        text: "Retailer Comm",
    },
    {
        dataField: "sales_user_code",
        text: "Sales Code",
    },
    {
        dataField: "sales_user_name",
        text: "Sales Name",
    },
    {
        dataField: "sales_user_mobile",
        text: "Sales Mobile Number",
    },
    {
        dataField: "status",
        text: "Status",
    },
    // {
    //   dataField: "shop_pin_code",
    //   text: "Pin Code",
    // },
    // {
    //   dataField: "shop_city",
    //   text: "City",
    // },
    // {
    //   dataField: "shop_state",
    //   text: "State",
    // },
];

const CreditCardBillPaymentReportTable = () => {
    const [pageAccess, setPageAccess] = useState({});
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { user, setTransactionLoaderState } = useContext(GlobalContext);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const fetchPGSettlementReport = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                userid: "",
                adminid: user.pkid,
                fromdate: dateFormatter(startDate),
                todate: dateFormatter(endDate),
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/reports/getCreditCardBillPaymentReport`,
                requestOptions
            )

                .then((response) => response.json())
                .then((result) => {
                    if (result.status == "0" && result.data) {
                        setData(result.data);
                    } else {
                        setData([]);
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (error) {
            console.log(error);
        }
    };


    const pgSettlementRefund = async (tid) => {
        try {
            setTransactionLoaderState(true);

            const hash_key = await generateHashKey2(user.pkid);

            var myHeaders = new Headers();
            myHeaders.append("HASH_KEY", hash_key);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "tid": tid,
                "adminid": user.pkid,
                "service": "CREDIT_CARD_PAYMENT",
                "remark": "TEst"

            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/transactionRefundAdmin`,
                requestOptions
            );
            const data = await res.json();
            if (data.status == "0") {
                swal("Success", "Transaction Refunded Successfully", "success");
                fetchPGSettlementReport();
            } else {
                swal("Failed", data.msg, "error");
            }

            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const refundVerification = (tid, amount, RetailerID) => {
        swal({
            title: "Are you sure?",
            text: `Refund amount of ${amount} to ${RetailerID}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                pgSettlementRefund(tid);
            }
        });
    };


    const fetchData = () => {
        fetchPGSettlementReport(startDate, endDate);
    };


    const getTransactionStatus = async (tid) => {
        const data = await getPaynnowTransactionStatus(tid);
        if (data.responseCode == "00") {
            swal("Success", `Status : ${data.status}`, "success");
        } else {
            swal("Failed", `Status : ${data.status}`, "error");
        }
    }

    const getTransactionStatus2 = async (tid) => {
        const data = await getPaynnowTransactionStatus2(tid);
        if (data.responseCode == "00" && data.data.status.toString().toLowerCase()=="success") {
            let message = "";
            if (data.data.status) {
                message = `API Status : ${data.data.txnStatus} \n`;
            }
            if (data.data.responseMessage) {
                message += `${data.data.responseMessage}`;
            }
            if (data.data.channelReferenceNo) {
                message += ` with RRN [${data.data.channelReferenceNo}]`;
            }
            swal("Success", message, "success");
           // swal("Success", `Status : ${data.data.status} [${data.data.responseMessage}] with RRN [${data.data.channelReferenceNo}]`, "success");
        } else {
            swal("Failed", `Status : ${data.status}`, "error");
        }
    }


    const openInNewTab = () => {
        const newWindow = window.open(
            `${CLIENT}/pages/print?id=090422191784660&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);


    const searchBarStyle = {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '6px',
        padding: '0px',
        backgroundColor: '#fff',
        width: '35%', // Adjust the width as needed
        height: '20%',
    };
    const inputStyle = {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        fontSize: '16px',
        marginLeft: '8px',
        width: '100%',
        "fontFamily": "Poppins",
        "fontWeight": "400",
    };
    const iconStyle = {
        color: 'rgba(0, 0, 0, 0.5)',
    };

    const positionStyle = {
        position: 'absolute',
        top: -27,
        left: 0,
        "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
    };
    const positionStyle1 = {
        position: 'absolute',
        top: -38,
        left: 0,
        "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
    };

    const getPageAccess = (menuId) => {
        const sub_menus_access_info = JSON.parse(sessionStorage.getItem("sub_menus"));
        sub_menus_access_info.forEach((acc) => {
            if (acc.pk_menu_id == menuId) {
                setPageAccess(acc);
            }
        })
    }

    useEffect(() => {
        fetchPGSettlementReport()
        getPageAccess(47);
    }, [])


    return (
        
        <div >

            <div className="row m-0 p-0  pt-1 align-items-center">

                <div className="col-8 m-0 p-0 ">
                    <div className="row  m-0 p-0  justify-content-end">

                        <div style={searchBarStyle} className=" m-0 p-0 pr-2">
                            <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2" />
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Search"
                                aria-label="Search"
                                style={inputStyle}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                value={searchText}
                            />
                        </div>

                        <div className="pr-1 pl-2 p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group className="p-0 m-0">

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="Start Date"
                                />
                            </Form.Group>
                        </div>
                        <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group className="p-0 m-0">

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="End Date"
                                />
                            </Form.Group>
                        </div>


                        <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
                            <Form.Group className="mt-0 p-0 m-0">
                                <Button
                                    className=" rounded-100 btn btn-lg btn-block "

                                    onClick={fetchData}
                                >
                                    {"Search"}
                                </Button>
                            </Form.Group>
                        </div>
                        <div className="m-0 p-0 hover-container"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                ExportToExcel(
                                    `Credit_card_bill_Report_${now()}`,
                                    excelMapping(data, columns)
                                );
                            }}
                        >
                            <img className="pl-1"
                                src={require("../../../assets/images/download Icon.png")}
                                style={{ width: `3.5rem`, height: '85%' }}
                            />
                            <div div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
                        </div>
                    </div>




                </div>

            </div>

            <hr className="m-0 p-0" />


            <div style={{ width: "100%" }}>
                <div style={{ alignItems: "center" }}>
                    <div style={{ flex: "1" }}></div>
                </div>
            </div>
            <div className=" scroll-inner admin-fix-width borderless-table ">
                <table
                    className="my-table transparent-table text-center border-bottom"
                    style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}
                >
                    <tr className="borderless-table text-center border-bottom">
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Txnt Id
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            User ID
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            User
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Account
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Opening Balance
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Amount
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Closing Balance
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Charges
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Sales Person
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Commission
                        </th>

                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Status
                        </th>
                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Date
                        </th>

                        <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                            Action
                        </th>

                    </tr>
                    {dataToDisplay.map((e, ind) => (
                        <tr className="border-bottom">
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                {" "}
                                {ind + 1}
                            </td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                <div>{e.rch_transaction_id}</div>
                            </td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                <div>{e.user_code}</div>
                            </td>

                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.user_name}</td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                <div>{e.rch_account_no}</div>
                                <div>{e.rch_ifsc_code}</div></td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{twoDecimalPlaces(e.rch_before_balance)}</td>

                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                <div>{twoDecimalPlaces(e.rch_amount)}</div>
                            </td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                <div>{twoDecimalPlaces(e.rch_after_balance)}</div>
                            </td>
                            <td className="text-center text-bold border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{twoDecimalPlaces(e.rch_charges)}</td>
                            <td className="text-center  text-bold border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}><div>{e.sales_user_code}</div>
                                <div>{e.sales_user_name}</div>
                                <div>{e.sales_user_mobile}</div></td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                <div>{twoDecimalPlaces(e.tds_retailer)}</div>
                                <div>{twoDecimalPlaces(e.tds_retailer)}</div>
                                <div>{twoDecimalPlaces(e.tds_retailer)}</div></td>
                            <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                {e.status.toString().toLowerCase() == "success" ? (
                                    <label
                                        style={{
                                            paddingLeft: "3px",
                                            paddingRight: "3px",
                                            padding: "5px",
                                            borderRadius: "5px",
                                            border: "1px solid",
                                            backgroundColor: 'rgba(43, 193, 85, 0.1)',
                                            color: 'rgba(43, 193, 85, 1)',
                                            bordercolor: 'rgba(43, 193, 85, 1)',

                                        }}
                                    >
                                        {"Success"}
                                    </label>
                                ) : (
                                    <label
                                        style={{
                                            paddingLeft: "3px",
                                            paddingRight: "3px",
                                            padding: "5px",
                                            borderRadius: "5px",
                                            border: "1px solid",
                                            backgroundColor: 'rgba(254, 128, 36, 0.1)',
                                            color: 'rgba(254, 128, 36, 1)',
                                            bordercolor: 'rgba(254, 128, 36, 1)',

                                        }}
                                    >
                                        {e.status}
                                    </label>
                                )}
                            </td>  <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.rch_recharge_date}</td>

                            <td className="text-center m-0 p-0 pr-0 py-1" style={{ "fontFamily": "Poppins", "fontSize": "0.8rem", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
                                {" "}

                                {pageAccess.str_access_rights == 1 && (

                                    <div className="row m-0 p-0 " >

                                        {e.rch_status_desc == "Refund" ? (
                                            <span>
                                                <Button
                                                    style={{
                                                        borderRadius: "9px",
                                                        border: "1px none",
                                                        backgroundColor: '	rgb(216,216,216)',
                                                        color: '		rgb(216,216,216)',
                                                        bordercolor: '	rgb(216,216,216)',
                                                        "fontSize": "4px !important"
                                                    }}
                                                    className="btn rounded-100  m-0 p-0">
                                                    {" "}
                                                    <div
                                                        className="hover-container"

                                                    > <img src={require("../../../assets/images/Refund.png")}    style={{ width: "2.1rem" }} />
                                                        {/* <div  style={positionStyle} className="hover-text bg-dark  py-1 ">Refund</div> */}
                                                    </div>
                                                </Button>

                                            </span>
                                        ) : (
                                            <span>
                                                <Button
                                                    style={{
                                                        borderRadius: "5px",
                                                        border: "1px none",
                                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                                        color: 'rgba(255, 164, 27, 1)',
                                                        bordercolor: 'rgba(255, 255, 255, 1)',
                                                        "fontSize": "4px !important"
                                                    }}
                                                    className="btn rounded-100  m-0 p-0">
                                                    {" "}
                                                    <div
                                                        className="hover-container"
                                                        onClick={() => {
                                                            refundVerification(e.rch_transaction_id, e.rch_amount, e.user_name);
                                                        }}
                                                        disabled={
                                                            e.rch_status_desc == "Refund" ||
                                                            e.rch_status_desc == "Refunded"
                                                        }
                                                    > <img src={require("../../../assets/images/Refund.png")}    style={{ width: "2.1rem" }} />
                                                        <div style={positionStyle} className="hover-text bg-dark  py-1 ">Refund</div>
                                                    </div>
                                                </Button>
                                            </span>
                                        )}

                                        <Button style={{
                                            // paddingLeft:"3px",
                                            // padding: "5px",
                                            borderRadius: "5px",
                                            border: "1px none",
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            color: 'rgba(255, 164, 27, 1)',
                                            bordercolor: 'rgba(255, 255, 255, 1)',
                                        }} className="btn btn-sm  rounded-100 m-0 p-0 px-2">
                                            {" "}

                                            <div
                                                onClick={() => {

                                                    //showResponse(e.ApiResponse)

                                                }}
                                                className="hover-container"

                                            >
                                                {/* Your content here */}
                                                <img
                                                    src={require("../../../assets/images/Bank Response.png")}
                                                    style={{ width: "2.1rem" }}
                                                />
                                                <div style={positionStyle1} className="hover-text bg-dark"><div  >Bank</div><div > Response</div></div>
                                            </div>


                                        </Button>

                                        <Button style={{
                                            borderRadius: "5px",
                                            border: "1px none",
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            color: 'rgba(255, 164, 27, 1)',
                                            bordercolor: 'rgba(255, 255, 255, 1)',

                                        }}
                                            className="btn btn-sm  rounded-100 m-0 p-0"
                                            onClick={() => {
                                                getTransactionStatus(e.rch_transaction_id)
                                            }}
                                        >
                                            {" "}

                                            <div
                                                className="hover-container"

                                            >
                                                {/* Your content here */}
                                                <img
                                                    src={require("../../../assets/icons/admin/api.png")}
                                                    style={{ width: "2.1rem" }}
                                                />

                                                <div style={positionStyle1} className="hover-text bg-dark"> Transaction Status </div>
                                            </div>

                                        </Button>

                                        <Button style={{
                                            borderRadius: "5px",
                                            border: "1px none",
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            color: 'rgba(255, 164, 27, 1)',
                                            bordercolor: 'rgba(255, 255, 255, 1)',

                                        }}
                                            className="btn btn-sm  rounded-100 m-0 p-0"
                                            onClick={() => {
                                                getTransactionStatus2(e.payu_id)
                                            }}
                                        >
                                            {" "}

                                            <div
                                                className="hover-container"

                                            >
                                                {/* Your content here */}
                                                <img
                                                    src={require("../../../assets/icons/admin/api.png")}
                                                    style={{ width: "2.1rem" }}
                                                />

                                                <div style={positionStyle1} className="hover-text bg-dark"> Transaction Status 2 </div>
                                            </div>

                                        </Button>
                                    </div>

                                )}

                            </td>

                        </tr>
                    ))}
                </table>
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </div>





    );
};

export const Pagination = ({
    page,
    limit,
    callback,
    total,
    count,
    callback2,
}) => {
    console.table({ page, limit, callback });
    const selectStyleLeftBorder = {

        borderRadius: '0px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
        borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
        borderRadius: '0px',
        borderLeft: 'none', // Remove right side border
        borderTop: 'none', // Remove top border
        borderBottom: 'none', // Remove bottom border
        "fontFamily": "Poppins",
        'fontsize': '16px',




    };
    const selectStyleBorder = {
        border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
        borderRadius: '8px',
        "fontFamily": "Poppins",
        'fontsize': '16px',
    };
    const selectStyle = {

        border: 'none', // Remove the border
        "fontFamily": "Poppins",
        'fontsize': '16px',

    };
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",

            }}
        >
            <div style={{ "fontFamily": "Poppins", }}>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>


            <div className="d-flex btn-group p-1" style={selectStyleBorder}>
                <select
                    style={selectStyleLeftBorder}
                    className=" d-flex justify-content-end "
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}

                >
                    {offsetArr.map((offset) => (
                        <option value={offset} className="d-flex justify-content-end">{offset}</option>
                    ))}

                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"

                    >
                        {"<<"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white underline-text"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}



                <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




                {/* Next And Previous Button Code */}
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}

                        className="rounded-0 bg-white text-dark border-white"
                    >
                        {">>"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default CreditCardBillPaymentReportTable;