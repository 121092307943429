import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";

const userTypes = [
  { label: "Master Distributor", value: "4" },
  { label: "Distributor", value: "3" },
  { label: "Retailer", value: "2" },
  { label: "API Partner", value: "5" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const CreateAdminUser = () => {
  const initialState = {
    userType: "",
    fullName: "",
    mobile: "",
    password: "",
    role: "",
  };

  const [state, setState] = useState(initialState);
  const [userRoles, setUserRoles] = useState([]);
  const [parentList, setParentList] = useState([]);
  const { user,setTransactionLoaderState } = useContext(GlobalContext);

  const validateForm = () => {
    
    if
   
     (
      state.fullName == "" ||
      state.password == "" ||
      state.role == "" ||
      state.mobile == ""
    ) {
      swal("Invalid", "Please Fill Mandatory Fields", "error");
      return;
    } else if (state.mobile.length != 10) {
      swal("Invalid", "Please Enter Valid Mobile Number", "error");
      return;
    }
    createUser();
  };

  const clearForm = () => {
    setState(initialState);
  };

  const createUser = async () => {
    try {
      setTransactionLoaderState(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserName: state.fullName,
        mobile: state.mobile,
        password: state.password,
        roleid: state.role.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/users/create_admin_user_admin.aspx`,
        requestOptions
      );

      const data = await res.json();
      if (data.status == "0") {
        swal("User Registration Successful", data.msg, "success");
        clearForm();
      } else {
        swal("User Registration Failed", data.msg, "error");
      }  setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
      swal("User Registration Failed", "Error From Server", "error");
    }
  };

  const getUserList = async () => {
    try {
      setTransactionLoaderState(true)
      var raw = "";

      var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/users/get_all_admin_user_roles_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        setUserRoles(
          data.data.map((role) => {
            return {
              label: `${role.rl_name}`,
              value: role.pk_role_id,
            };
          })
        );
      }
      setTransactionLoaderState(false)
    } catch (error) {
      setTransactionLoaderState(false)
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div style={{ padding: "1rem" }}>
      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <div className="card-body">
          <div>
            <div
              style={{
                padding: "12px",
                background: "#3676D8",
                color: "#FFF",
                fontWeight: "bolder",
              }}
            >
              Create Admin User
            </div>

            <div>
              <div className="row p-3">
                <span>User Info</span>
              </div>
              <div className="row">
                <div className="col p-0 mr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Enter Full Name`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Full Name"
                      value={state.fullName}
                      onChange={(e) => {
                        setState({ ...state, fullName: e.target.value });
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>

                <div className="col p-0 mr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Enter Mobile`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Mobile"
                      value={state.mobile}
                      onChange={(e) => {
                        setState({ ...state, mobile: e.target.value });
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>
                <div className="col p-0 mr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Enter Password`}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Enter Password"
                      value={state.password}
                      onChange={(e) => {
                        setState({ ...state, password: e.target.value });
                      }}
                      style={{ borderRadius: "0px" }}
                    />
                  </Form.Group>
                </div>
                <div className="col p-0 mr-2">
                  <Form.Group>
                    <Form.Label>
                      <span style={{ color: "red" }}>*</span>
                      {`Assign Role`}
                    </Form.Label>
                    <Select
                      options={userRoles}
                      value={state.role}
                      onChange={(e) => {
                        setState({ ...state, role: e });
                      }}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="">
                <div className="row">
                  <div className="col px-0">
                    <Button
                      className="rounded-0 btn-block"
                      variant="success"
                      onClick={validateForm}
                    >
                      {" "}
                      Submit
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      className="rounded-0 btn-block"
                      variant="danger"
                      onClick={clearForm}
                    >
                      {" "}
                      Clear
                    </Button>
                  </div>
                  <div className="col p-0"></div>
                  <div className="col p-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateAdminUser;
