import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import AllUserListTable from "./AllUserListTable";

export const dmtReport = [
  {
    mobile: "9168805979",
    beneficiary: "32905756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "800",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "4/9/2022 7:17:31 PM",
  },
  {
    mobile: "9057568979",
    beneficiary: "568464756846456",
    type: "IMPS",
    utrNo: "0",
    amount: "1000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "13/2/2022 7:17:31 PM",
  },
  {
    mobile: "9168057568",
    beneficiary: "568907568464565",
    type: "IMPS",
    utrNo: "0",
    amount: "40000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "success",
    datetime: "3/4/2022 7:17:31 PM",
  },
  {
    mobile: "9105756879",
    beneficiary: "684649075684646",
    type: "IMPS",
    utrNo: "0",
    amount: "2000",
    charges: "3",
    comm: "0",
    tds: "0",
    status: "pending",
    datetime: "23/5/2022 7:17:31 PM",
  },
];
const AllUserList = () => {
  const [reports, setReports] = useState([]);
  const { user } = useContext(GlobalContext);

  const fetchReport = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=dmttransaction&mobile=${user.ReferId}`,
        requestOptions
      );
      const raw = await res.text();
      const data = JSON.parse(raw);
      setReports(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    fetchReport();
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) => (
       <div style={{ padding: "1rem" }} className="bg-light ">
       <div style={{ borderRadius: "10px" }} className="card  p-0 m-0 mx-2 mt-4 ">
         <div className="card-body m-0 p-2">
           <div>
               

                <AllUserListTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default AllUserList;
