import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card } from "react-bootstrap";
import { urls } from "../../URLs";
import { GlobalContext } from "../../Context/GlobalState";
const { DMTBankitUrl } = urls;

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const Dmt1Sidebar = ({ details, limit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [amountError, setAmountError] = useState("");
  const { setTransactionLoaderState, refreshWallet } =
    useContext(GlobalContext);
  const tPinLength = 6;
  const [amount, setAmount] = useState(0);
  const [tPin, setTPin] = useState("");
  const { benificiary, user, successToast, errorToast } = details;
  const [charges, setCharges] = useState(0);

  const sendMoney = async () => {
    if (amount == "" || Number(amount) < 100 || Number(amount) > 25000) {
      errorToast("Please Enter amount in Range 100 to 25000");
      return;
    } else if (tPin == "" || tPin.length < tPinLength) {
      errorToast("Please enter valid T Pin");
      return;
    }
    setTransactionLoaderState(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "sendmoney");
    urlencoded.append("beneficiaryid", benificiary.recipient_id);
    urlencoded.append("mobile", benificiary.recipient_mobile);
    urlencoded.append("amount", amount);
    urlencoded.append("tid", "IMPS");
    urlencoded.append("uid", user.ReferId);
    urlencoded.append("type", "mobile");
    urlencoded.append("bank", benificiary.bank);
    urlencoded.append("tpin", tPin);
    urlencoded.append("route", "0");
    urlencoded.append("accountno", benificiary.account);
    urlencoded.append("paytmlimit", "0");
    urlencoded.append("bankitlimit", "0");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    try {
      const res = await fetch(DMTBankitUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      successToast("Amount transfer successfull");
      setTransactionLoaderState(false);
      refreshWallet();
      document
        .querySelector(".content-wrapper")
        .classList.toggle("sidebar-icon-only");
    } catch (error) {
      setTransactionLoaderState(false);
      errorToast("Amount transfer failed");
      console.log("error", error);
    }
  };
  useEffect(() => {
    setAmount(0);
    setCharges(0);
    setTPin("");
  }, [details]);

  const close = () => {
    setTPin("");
    document
      .querySelector(".content-wrapper")
      .classList.toggle("sidebar-icon-only");
  };

  return (
    <nav
      className="side-sheet sidebar Sidebar23"
      id="sidebar2"
      style={Sidebar22}
    >
      <div
        style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
        className="row"
      >
        <div
          style={{ paddingLeft: "5px", display: "flex", alignItems: "center" }}
          className="col"
        >
          <strong style={{ color: "#1b68ca" }}> Transfer To Beneficiary</strong>
        </div>
        <div style={{ paddingRight: "0px" }} className="col">
          <span
            style={{
              fontSize: "2rem",
              display: "flex",
              color: "#b2b2b3",
              justifyContent: "end",
              ariaHidden: "true",
              paddingRight: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only");
            }}
          >
            &times;
          </span>
        </div>
      </div>
      <Dropdown.Divider />
      <Card style={{ margin: "10px", padding: "10px" }}>
        <div style={{ display: "flex" }}>
          <span>Domestic Money Transfer Limit</span>
          <strong style={{ color: "green", margin: "0 0 0 1rem" }}>
            {details.limit}{" "}
          </strong>
        </div>
      </Card>
      <Card style={{ margin: "10px", padding: "10px" }}>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Beneficiary Name"
            value={benificiary.recipient_name}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Beneficiary A/C"
            value={benificiary.account}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="AMOUNT"
            style={{ display: "flex", alignItems: "center" }}
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
              const amt = Number(e.target.value);
              if (amt < 100 || amt > 5000) {
                setAmountError("Invalid Amount");
              } else {
                setAmountError("");
              }

              let c = Number(e.target.value) / 100;
              if (Number(c) < 10) {
                c = 10;
              }
              if (Number(c) > 250) {
                c = 250;
              }

              setCharges(c);
            }}
          />
        </Form.Group>
        <div className="row justify-content-between">
          {amountError && (
            <span
              style={{ color: "red", padding: "0px 0px 1rem 0px" }}
            >{`${amountError}`}</span>
          )}

          {charges > 0 && (
            <span
              style={{ color: "red", padding: "0px 0px 1rem 0px" }}
            >{`Charges Rs ${charges}`}</span>
          )}
        </div>
        {/* {charges > 0 && (
          <span style={{ color: "red" }}>{`Charges Rs ${charges}`}</span>
        )} */}
        <Form.Group>
          <Form.Control
            type="text"
            className="form-control"
            aria-label="Notes"
            aria-describedby="basic-addon1"
            placeholder="Enter T-Pin"
            onChange={(e) => {
              setTPin(e.target.value);
            }}
          />
        </Form.Group>

        <div className="row">
          <div className="col p-1">
            <Button
              className="rounded-0 btn-block"
              variant="primary"
              onClick={sendMoney}
              disabled={isLoading}
            >
              {isLoading ? "Transaction Processing.." : "Transfer Now"}
            </Button>
          </div>
          <div className="col p-1">
            <Button
              className="rounded-0 btn-block"
              variant="success"
              onClick={close}
            >
              {" "}
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </nav>
  );
};

export default withRouter(Dmt1Sidebar);
