import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import SideImage from "../../../assets/images/AepsBankit.png";

const Epan = () => {
    const [token, setToken] = useState("");
    const { user } = useContext(GlobalContext);

    const getAccessToken = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                retailerID: user.ReferId,
                firmName: "BUMPPY",
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/Services/EPAN/authenticate",
                requestOptions
            );
            const data = await res.json();
            if (data.statusCode == 1) {
                setToken(data.data.token);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAccessToken();
    }, []);
    console.log(token);
    return (
        <>
            <div className="d-flex justify-content-center align-items-center p-5">
                <div
                    style={{
                        background: "white",
                    }}
                >
                    <div className="row">
                        <div className="col-7 p-md-5 p-sm-4">
                            {" "}
                            <h4 className="d-flex align-items-center">E-PAN Service</h4>
                            <p
                                className="d-flex align-items-center"
                                style={{ color: "black", fontSize: "1rem" }}
                            >
                                E-PAN offers the convenience of easy and secure access to your
                                PAN details online, making it simpler to carry out financial
                                transactions and tax-related activities.
                            </p>
                            <h6>Apply now for E-PAN</h6>
                            <form
                                action="https://digipaydashboard.religaredigital.in/Login/Authenticate"
                                method="Post"
                            >
                                <input type="hidden" name="authentication" value={token} />{" "}
                                <button
                                    className="btn-sm btn-primary d-flex align-items-center justify-content-center mt-md-3"
                                    type="submit"
                                >
                                    {" "}
                                    Apply for E PAN
                                </button>
                            </form>
                        </div>
                        <div className="col-5 d-flex justify-content-center align-items-center">
                            <img
                                alt=""
                                style={{ height: "15rem" }}
                                src={require("../../../assets/images/epan.png")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const AepsBankit = () => {
    const [token, setToken] = useState("");
    const { user } = useContext(GlobalContext);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "", "height=600,width=800'");
        if (newWindow) newWindow.opener = null;
    };

    const getAccessToken = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append(
                "Authorization",
                "Basic " +
                Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString(
                    "base64"
                )
            );
            var raw = JSON.stringify({
                userid: user.ReferId,
                device: "WEB",
                "latitude": "19.847457",
                "longitude": "71.1747549"
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/AEPS/Bankit/Main/generateUrl",
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                openInNewTab(data.data.url);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        // getAccessToken();
    }, []);
    console.log(token);
    return (
        <>
            <div className="d-flex justify-content-center align-items-center p-4">
                <div
                    style={{
                        width: "100%",
                        background: "white",
                    }}
                >
                    <div className="row">
                        <div className="col-7 p-md-5 p-sm-4">
                            {" "}
                            <h4 className="d-flex align-items-center"><b>AEPS BankIt Service</b></h4>
                            <p
                                className="d-flex align-items-center"
                                style={{ color: "black", fontSize: "1rem" }}
                            >
                                                     AEPS (Aadhaar Enabled Payment System) offers a seamless and secure way to access banking services using Aadhaar authentication. This service empowers customers to perform essential banking transactions without the need for a debit card or physical bank visit. With just an Aadhaar number and fingerprint authentication, users can carry out financial activities anytime, anywhere. AEPS is designed to promote digital inclusion, making banking services accessible even in remote areas.

                            </p>
                            <button
                                className="btn-sm btn-success d-flex align-items-center justify-content-center mt-md-3"
                                // type="submit"
                                style={{
                                    borderRadius: ".2rem",
                                    fontWeight: "bold",
                                    width: "10rem",
                                    border: "none",
                                    color: "#ffff",
                                    fontSize: "1rem",
                                    height: "2em",
                                    display: "flex",
                                }}
                                onClick={() => {
                                    getAccessToken();
                                }}
                            >
                                {" "}
                                Open AEPS BankIt
                            </button>
                        </div>
                        <div className="col-5 d-flex justify-content-center align-items-center">
                            <img
                                alt=""
                                style={{ height: "20rem" }}
                                src={SideImage}
                            />
                        </div>
                    </div>
                    <div className="card m-md-4 m-sm-3 p-md-3 p-sm-2">
                        <h5>Information</h5>
                        <p>
                        AEPS (Aadhaar Enabled Payment System) offers a seamless and secure way to access banking services using Aadhaar authentication. This service empowers customers to perform essential banking transactions without the need for a debit card or physical bank visit. With just an Aadhaar number and fingerprint authentication, users can carry out financial activities anytime, anywhere. AEPS is designed to promote digital inclusion, making banking services accessible even in remote areas.

We provide four key services to enhance financial accessibility and convenience:
                        </p>
                        <ol>
                            <li>

                                Balance Inquiry – Check your account balance instantly.
                            </li>
                            <li>

                                Aadhaar Pay – Make cashless transactions using Aadhaar authentication.

                            </li>
                            <li>

                                Mini Statement – View recent transactions for better financial tracking.
                            </li>
                            <li>

                                Cash Withdrawal – Withdraw money securely using Aadhaar-linked accounts.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AepsBankit;