import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { CLIENT } from "../../config";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import swal from "sweetalert";
import swal2 from "@sweetalert/with-react";
import { RaiseTicketModal } from "../util/RaiseTicketModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getPaynnowTransactionStatus } from "../util/utility";
import TransactionsDetailsSidebar from "./TransactionsDetailsSidebar";
import JSONTable from "../../utils/JsonTable";

export const columns = [
  {
    dataField: "txn_id",
    text: "TXN ID",
  },
  {
    dataField: "bank_rrn",
    text: "Bank RRN",
  },
  {
    dataField: "tid",
    text: "REF",
  },
  {
    dataField: "rch_indate",
    text: "Date",
  },
  {
    dataField: "user_code",
    text: "User Code",
  },
  {
    dataField: "user_name",
    text: "User Name",
  },
  {
    dataField: "Name",
    text: "Bank Name",
  },
  {
    dataField: "rch_mobile",
    text: "Retailer Mobile",
  },
  {
    dataField: "ttyp",
    text: "Type",
  },
  {
    dataField: "rch_before_balance",
    text: "Opening Balance",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_after_balance",
    text: "Closing Balance",
  },
  {
    dataField: "rch_com_cnf",
    text: "Master Comm",
  },
  {
    dataField: "rch_com_distributer",
    text: "Dist Comm",
  },
  {
    dataField: "rch_com_retailer",
    text: "Retailer Comm",
  },
  {
    dataField: "sales_user_code",
    text: "Sales Code",
  },
  {
    dataField: "sales_user_name",
    text: "Sales Name",
  },
  {
    dataField: "sales_user_mobile",
    text: "Sales Mobile Number",
  },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "shop_pin_code",
    text: "Pin Code",
  },
  {
    dataField: "shop_city",
    text: "City",
  },
  {
    dataField: "shop_state",
    text: "State",
  },
  {
    dataField: "fk_provider_id",
    text: "Reference ID",
  },
  {
    dataField: "provider_name",
    text: "Provider Name",
  },
];

const servicesOption = [
  { value: 'Cash Withdrawal', label: 'Cash Withdrawal' },
  { value: 'Balance Enquiry', label: 'Balance Enquiry' },
  { value: 'Mini Statement', label: 'Mini Statement' },
  { value: 'Settlement', label: 'AEPS Settlement' },
  { value: 'UPI_SETTLEMENT', label: 'UPI Settlement' },
  { value: 'Aadhar Pay', label: 'Aadhar Pay' }
];

const AEPSReportTable = ({ setDetails }) => {
  const REPORT_TYPE = "AEPS";
  const [pageAccess, setPageAccess] = useState({});
  const [data, setData] = useState([]);
  const [salesPersons, setSalesPersons] = useState(null);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [summary, setSummary] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [serviceSelected, setServiceSelected] = useState("");
  const [raiseTicketModal, setRaiseTicketModal] = useState(false);
  const [raiseTicketDetails, setRaiseTicketDetails] = useState(null);
  const [offset, setOffset] = useState(10);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  let fromDate = sessionStorage.getItem(`${REPORT_TYPE}_fromDate`)
    ? new Date(sessionStorage.getItem(`${REPORT_TYPE}_fromDate`))
    : new Date();
  let toDate = sessionStorage.getItem(`${REPORT_TYPE}_toDate`)
    ? new Date(sessionStorage.getItem(`${REPORT_TYPE}_toDate`))
    : new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isSideSectionVisible, setIsSideSectionVisible] = useState(false);
  const handleRowClick = (rowData) => {
      setSelectedRowData(rowData);
      setIsSideSectionVisible(true);
  };

  const handleClose = () => {
      setIsSideSectionVisible(false);
  };

  console.log(data);

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((data.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(data.length);
    console.log(startIdx);
    console.log(data);
    const filteredData = data.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    console.log(filteredData);
    setDataToDisplay(filteredData);
  }, [data, offset]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const twoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).toString();
  };

  // fuction to check data is valid json or not
  const isJson = (str) => {
    try {
      JSON.parse(str);
      return true
    } catch (e) {
      return false;
    }
  }

  const setPrintData = (res, bank, aadhar, amount, service, mobile, tid) => {
    let data = {
      userid: user.ReferId,
      bank: bank,
      device: 0 == 0 ? "Mantra MFS 100" : 1 == 1 ? "Morpho MSO 1300" : "",
      tid: tid,
      aadhar: aadhar,
      amount: amount,
      service: service,
      rrn: res?.data ? res?.data?.bankRRN : "NA",
      stan: res?.data ? res?.data?.fpTransactionId : "NA",
      time: res?.data ? res?.data?.requestTransactionTime : "NA",
      mobile,
      mobile,
      status: res?.status ? res?.data?.transactionStatus : res?.message,
      balance: res?.data ? res?.data?.balanceAmount : "NA",
      miniStatementStructureModel: res?.data
        ? res?.data?.miniStatementStructureModel
        : null,
      miniOffusStatementStructureModel: res?.data
        ? res?.data?.miniOffusStatementStructureModel
        : null,
      shopname: "",
      image: user.image,
    };
    sessionStorage.setItem("print", JSON.stringify(data));
    localStorage.setItem("print", JSON.stringify(data));
  };

  const getTransactionStatus = async (tid) => {
    const data = await getPaynnowTransactionStatus(tid);
    if (data.responseCode == "00") {
      swal("Success", `Status : ${data.status}`, "success");
    } else {
      swal("Failed", `Status : ${data.status}`, "error");
    }
  }

  const getUPISettlementStatus = async (tid) => {

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "payoutId": tid
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const res = await fetch("https://bumppy.in/razorpay/v1/statusCheckPayout.php", requestOptions);
      const data = await res.json();

      if (data.status == "00") {
        swal("Success", `Status : ${data.data.status}`, "success");
      } else {
        swal("Failed", `${data.msg}`, "error");
      }
    } catch (error) {
      swal("Failed", `Something went wrong`, "error");
    }

  }

  const getTransactionType = (t) => {
    switch (t) {
      case "CW":
        return "Cash Withdrawal";
      case "MS":
        return "Mini Statement";
      case "AP":
        return "Aadhaar Pay";
      case "BE":
        return "Balance Enquiry";
      default:
        return "";
    }
  };

  const openInNewTab2 = (data) => {
    console.log(data);
    return;
    const fr = JSON.parse(data.fullresponse);
    console.log(data);
    setPrintData(
      fr,
      data.a_bankname,
      data.a_aadharno,
      data.amount,
      getTransactionType(data.service),
      "",
      data.fingpayTransactionId
    );
    const newWindow = window.open(
      `/pages/aeps/print?id=${data.tid}&type=AEPS`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const getDMTReport = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/aeps/get_aeps_report_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      // setData(filteredData);
      setData(
        data.data.map((e) => {
          let charges = e.rch_api_message.split("|")[0];
          let gst = e.rch_api_message.split("|")[1];
          let accNo = e.rch_api_message.split("|")[2];
          let ifsc = e.rch_api_message.split("|")[3];
          let txn_mode = e.rch_api_message.split("|")[4];
          let Company = e.Company.split("|")[0];
          let provider_name = e.Company.split("|")[1];
          if (e.fk_provider_id == "") {
            e.fk_provider_id = "NA";
          }
          return {
            ...e,
            user_name: e.username.split("|")[0],
            user_code: e.username.split("|")[1],
            charges, gst, accNo, ifsc, txn_mode, Company, provider_name
          };
        })
      );
      const sales = [];
      const sales_persons = [{ label: "All Sales Person", value: "" }];
      data.data.forEach((ee) => {
        if (!sales.includes(ee.sales_user_code) && ee.sales_user_code != "") {
          sales_persons.push({
            label: `${ee.sales_user_code} ${ee.sales_user_name}`,
            value: ee.sales_user_code,
          });
          sales.push(ee.sales_user_code);
        }
      });

      setSalesPersons(sales_persons);
      if (data.data2) setSummary(data.data2);
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const refundAEPSTransaction = async (tid) => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        trans_id: tid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/refunds/aepssettlement_refund.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const refundVerification = (tid, amount, RetailerID) => {
    swal({
      title: "Are you sure?",
      text: `Refund amount of ${amount} to ${RetailerID}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        refundAEPSTransaction(tid);
      }
    });
  };

  const showResponse = (response) => {
    isJson(response) ? swal2(
      <JSONTable data={JSON.parse(response)} />
    ) : swal2(
      <div>
        {JSON.stringify(response)}
      </div>
    );
    ;
  };
  const raiseTicketShow = (data) => {
    setRaiseTicketDetails({
      tid: data.tid,
      category: "AEPS",
      user_id: data.username.toString().split("|")[1],
    });
    setRaiseTicketModal(true);
  };
  useEffect(() => {
    getDMTReport();
  }, []);

  useEffect(() => {
    if (selectedSalesPerson) {
      if (selectedSalesPerson.value == "") {
        setDataToDisplay(data);
      } else {
        const filteredData = data.filter((ee) => {
          return ee.sales_user_code == selectedSalesPerson.value;
        });
        setDataToDisplay(filteredData);
      }
    }
  }, [selectedSalesPerson]);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: "0px",
    backgroundColor: "#fff",
    width: "35%", // Adjust the width as needed
    height: "20%",
  };
  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    marginLeft: "8px",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "400",
  };
  const iconStyle = {
    color: "rgba(0, 0, 0, 0.5)",
  };

  const positionStyle = {
    position: "absolute",
    top: -27,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -38,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
  };

  const getPageAccess = (menuId) => {
    const sub_menus_access_info = JSON.parse(sessionStorage.getItem("sub_menus"));
    sub_menus_access_info.forEach((acc) => {
      if (acc.pk_menu_id == menuId) {
        setPageAccess(acc);
      }
    })
  }

  useEffect(() => {
    getPageAccess(15);
  }, [])

  useEffect(() => {
    console.log(serviceSelected);
    if (serviceSelected) {
      setDataToDisplay(data.filter((e) => e.ttyp?.toString().toLowerCase() == serviceSelected.value?.toString().toLowerCase()));
    }
  }, [serviceSelected])

  console.log(dataToDisplay);


  return (
    <>
      <RaiseTicketModal
        details={raiseTicketDetails}
        isModalOpen={raiseTicketModal}
        setModalOpen={(modalState) => {
          setRaiseTicketModal(modalState);
        }}
        refresh={() => { }}
      />

      <div className="row m-0 p-0 mb-3">
        <div className="col-6 m-0 p-0 pr-1">
          <div className="row m-0 p-0">
            <div className="col-6 m-0 p-0  pl-2 pr-2">
              <DashboardCardKYC
                label={"Amount"}
                label1={"Success"}
                data1={twoDecimalPlaces(summary.SuccessRecharge)}
                label2={"Pending"}
                data2={twoDecimalPlaces(summary.PendingRecharge)}
                label3={"Fail"}
                data3={twoDecimalPlaces(summary.FailRecharge)}
              />
            </div>
            <div className="col-6 m-0 p-0 pl-2 pr-2">
              <DashboardCardKYC
                label={"Count"}
                label1={"Success"}
                data1={summary.Success}
                label2={"Pending"}
                data2={summary.Pending}
                label3={"Fail"}
                data3={summary.Fail}
              />
            </div>
          </div>
        </div>

        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0">
            <div style={searchBarStyle} className="bg-light m-0 p-0 pr-2">
              <FontAwesomeIcon
                icon={faSearch}
                style={iconStyle}
                className="ml-2"
              />
              <input
                type="text"
                className="form-control "
                placeholder="Search"
                aria-label="Search"
                style={inputStyle}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>

            <div className=" pr-1 pl-2" style={{ width: "25%" }}>
              <Form.Group>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    sessionStorage.setItem(`${REPORT_TYPE}_fromDate`, date.toISOString());
                  }}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div className="px-1" style={{ width: "25%" }}>
              <Form.Group>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    sessionStorage.setItem(`${REPORT_TYPE}_toDate`, date.toISOString());
                  }}
                  className="form-control  rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>
            <div
              className="hover-container"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ExportToExcel(`AEPS_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                className="pl-1"
                src={require("../../../assets/images/download Icon.png")}
                style={{ width: `3.5rem`, height: "85%" }}
              />
              <div
                div
                style={{ color: "rgba(255, 255,255, 1)" }}
                className="hover-text bg-dark "
              >
                Export to Excel
              </div>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="" style={{ width: "35%" }}>
              <Form.Group>
                <Select
                  options={servicesOption}
                  onChange={(e) => {
                    setServiceSelected(e);
                  }}
                />
              </Form.Group>
            </div>

            <div className="" style={{ width: "35%" }}>
              <Form.Group>
                <Select
                  options={salesPersons}
                  onChange={(e) => {
                    setSelectedSalesPerson(e);
                  }}
                />
              </Form.Group>
            </div>
            <div className="px-2" style={{ width: "25%" }}>
              <Form.Group>
                <Button
                  className="rounded-100 mt-4 btn btn-block btn-lg"
                  onClick={getDMTReport}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
          </div>

          {/* <div className="row m-0 p-0">
            <div className="col-5 m-0 p-0 pl-3">
              <Form.Group style={{ maxWidth: "30rem" }}>
                <Form.Control
                  type="text"
                  className="form-control rounded-100 p-2 m-0"
                  aria-label="Notes"
                  aria-describedby="basic-addon1"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                />
              </Form.Group>
            </div>
            <div className="col-1 m-0 p-0 pl-3">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ExportToExcel(
                    `AEPS_Report_${now()}`,
                    excelMapping(dataToDisplay, columns)
                  );
                }}
              >
                <img
                  src={require("../../../assets/images/excel.png")}
                  style={{ width: "1.6rem" }}
                />
                <strong style={{ marginLeft: "4px" }}></strong>
              </div>
            </div>

            <div className="col-3 m-0 p-0 pl-3">
              <Form.Group>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control rounded-100"
                  placeholderText="Start Date"
                />
              </Form.Group>
            </div>
            <div className="col-3 m-0 p-0 pl-3">
              <Form.Group>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control  rounded-100"
                  placeholderText="End Date"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-5 m-0 p-0 pl-3 ">
              <Form.Group>
                <Select
                  options={salesPersons}
                  onChange={(e) => {
                    setSelectedSalesPerson(e);
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-4 m-0 p-0 pl-3 pr-3">
              <Form.Group>
                <Button
                  className="rounded-100 mt-4 btn btn-block btn-lg"
                  onClick={getDMTReport}
                >
                  {"Search"}
                </Button>
              </Form.Group>
            </div>
          </div> */}
        </div>
      </div>

      <hr />

      {/* <div className="row d-flex">
        <div className="col">
          <span
            className="badge bg-success text-light"
            style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
          >
            {`Success Count: ${0}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-warning text-light"
            style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
          >
            {`Pending : ${summary.Pending}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-danger text-light"
            style={{ padding: ".8rem 2rem", borderRadius: "4rem" }}
          >
            {`Fail : ${summary.Fail}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-success text-light"
            style={{
              padding: ".8rem 2rem",
              borderRadius: "4rem",
              width: " 11.5rem",
            }}
          >
            {`Success : ${twoDecimalPlaces(summary.SuccessRecharge)}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-warning text-light"
            style={{
              padding: ".8rem 2rem",
              borderRadius: "4rem",
              width: " 11.5rem",
            }}
          >
            {`Pending : ${twoDecimalPlaces(summary.PendingRecharge)}`}
          </span>
        </div>
        <div className="col">
          <span
            className="badge bg-danger text-light"
            style={{
              padding: ".8rem 2rem",
              borderRadius: "4rem",
              width: " 9.6rem",
            }}
          >
            {`Fail : ${twoDecimalPlaces(summary.FailRecharge)}`}
          </span>
        </div>
      </div> */}

      <div className="scroll-inner admin-fix-width ">
        <table className="my-table transparent-table text-center border-bottom ">
          <tr className="borderless-table text-center border-bottom">
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sr"}
            </th>

            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"REF"}
            </th> */}
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Account"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Retailer"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {"Service"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {"Provider"}
            </th>

            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {"Opening"}
            </th> */}
            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Amount"}
            </th> */}
            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Closing"}
            </th> */}

            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Profit"}
            </th> */}
            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Balance"}
            </th> */}
            {/* <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Sales Person"}
            </th> */}
             <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Date"}
            </th>
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Status"}
            </th>
           
            <th
              className="my-table transparent-table text-center border-bottom "
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              {" "}
              {"Action"}
            </th>
          </tr>
          {dataToDisplay.map((e, ind) => (
            <tr
            className="border-bottom clickable-row"
                                >
              <td
                className="text-center m-0 p-0 pr-2 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
                onClick={() => handleRowClick(e)}

              >
                <a onClick={() => {
                  setDetails(e);

                }} >
                  {ind + 1}
                </a>

              </td>
              {/* <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{`TXN ID : ${e.txn_id ? e.txn_id : "NA"}`}</span>
                  <span>{`Bank RRN : ${e.bank_rrn ? e.bank_rrn : "NA"}`}</span>
                  <span>{`REF : ${e.tid ? e.tid : "NA"}`}</span>
                  <span>{e.rch_indate}</span>
                </div>
              </td> */}
              <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
                onClick={() => handleRowClick(e)}
              >
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span >{e.Name}</span>
                  <span>{e.rch_mobile}</span>
                  {(e.ttyp == "UPI_SETTLEMENT" || e.ttyp == "SETTLEMENT") && (
                    <React.Fragment>
                      <span>{`Acc No : ${e.accNo}`}</span>
                      <span>{`IFSC : ${e.ifsc}`}</span>
                    </React.Fragment>
                  )}


                </div>
              </td>
              <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
                onClick={() => handleRowClick(e)}
              >
                <div className="d-flex flex-column align-items-center">
                  <span style={{ maxWidth: "120px" }}>{e.username}</span>
                  <span>{e.remitterno}</span>
                </div>
              </td>
              <td
                className="text-center m-0 p-0 pl-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
                onClick={() => handleRowClick(e)}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{e.ttyp}</span>
                  {/* <span className="text-danger">{`Deduction : ${twoDecimalPlaces(
                    e.dmt_deduction
                  )}`}</span> */}
                </div>
              </td>

              <td
                className="text-center m-0 p-0 pl-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
                onClick={() => handleRowClick(e)}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{e.provider_name}</span>
                </div>
              </td>
              {/* <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{twoDecimalPlaces(e.rch_before_balance)}</span>
                </div>
              </td> */}

              {/* <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{twoDecimalPlaces(e.rch_amount)}</span>
                </div>
              </td> */}
              {/* <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{twoDecimalPlaces(e.rch_after_balance)}</span>

                </div>
              </td> */}

              {/* <td
                className="text-center m-0 p-0 px-1 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span>{`C Com :${twoDecimalPlaces(e.rch_com_cnf)}`}</span>
                  <span>{`D Com :${twoDecimalPlaces(
                    e.rch_com_distributer
                  )}`}</span>
                 
                  <span>{`Comm :${twoDecimalPlaces(e.rch_com_retailer)}`}</span>
               </div>
              </td> */}
              {/* <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <div>{`Opening :`}</div>
                  <div>{`${twoDecimalPlaces(e.rch_before_balance)}`}</div>
                  <div>{`Closing :`}</div>
                  <div>{`${twoDecimalPlaces(e.rch_after_balance)}`}</div>
                </div>
              </td> */}

              {/* <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {" "}
                <div className="d-flex flex-column align-items-center">
                  <span>{`${e.sales_user_name}`}</span>
                  <span>{`${e.sales_user_code}`}</span>
                  <span>{`${e.sales_user_mobile}`}</span>
                </div>
              </td> */}
  <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
                onClick={() => handleRowClick(e)}
              >
                {" "}
                <div className="d-flex flex-column align-items-center">
                  {/* <span>{`TXN ID : ${e.txn_id ? e.txn_id : "NA"}`}</span>
                  <span>{`Bank RRN : ${e.bank_rrn ? e.bank_rrn : "NA"}`}</span>
                  <span>{`REF : ${e.tid ? e.tid : "NA"}`}</span> */}
                  <span>{e.rch_indate}</span>
                </div>
              </td>
              <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
                onClick={() => handleRowClick(e)}
              >
                <div>
                  {e.rch_status_desc == "Success" ? (
                    <span
                      style={{
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(43, 193, 85, 0.1)",
                        color: "rgba(43, 193, 85, 1)",
                        bordercolor: "rgba(43, 193, 85, 1)",
                      }}
                      className="px-2 py-2"
                    >
                      {`${e.rch_status_desc}`}
                    </span>
                  ) : e.rch_status_desc == "Fail" ? (
                    <span
                      style={{
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(255, 46, 46, 0.1)",
                        color: "rgba(255, 46, 46, 1)",
                        bordercolor: "rgba(255, 46, 46, 1)",
                      }}
                      className="px-2 py-2"
                    >
                      {`${e.rch_status_desc}`}
                    </span>
                  ) : (
                    <span
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid",
                        backgroundColor: "rgba(254, 128, 36, 0.1)",
                        color: "rgba(254, 128, 36, 1)",
                        bordercolor: "rgba(254, 128, 36, 1)",
                      }}
                      className="px-4 py-2"
                    >
                      {`${e.rch_status_desc}`}
                    </span>
                  )}
                </div>
              </td>

              <td
                className="text-center m-0 p-0 py-1"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                {pageAccess.str_access_rights == 1 && (
                  <div className="row m-0 p-0 " style={{ maxWidth: "250px" }}>
                    {e.rch_status_desc == "Refund" ? (
                      <Button
                        style={{
                          borderRadius: "9px",
                          border: "1px none",
                          backgroundColor: "  rgb(216,216,216)",
                          color: "    rgb(216,216,216)",
                          bordercolor: "  rgb(216,216,216)",
                          fontSize: "4px !important",
                        }}
                        className="btn rounded-100  m-0 p-0"
                      >
                        {" "}
                        <div
                          className="hover-container"
                          disabled={
                            e.rch_status_desc == "Refund" ||
                            e.rch_status_desc == "Refunded"
                          }
                        >
                          {/* Your content here */}
                          <img
                            src={require("../../../assets/images/Refund.png")}
                            style={{ width: "" }}
                          />
                          <div
                            style={positionStyle}
                            className="hover-text bg-dark py-1"
                          >
                            Refund
                          </div>
                        </div>
                        {/* Rehit */}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          // paddingLeft:"3px",
                          // padding: "5px",
                          borderRadius: "5px",
                          border: "1px none",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          color: "rgba(255, 164, 27, 1)",
                          bordercolor: "rgba(255, 255, 255, 1)",
                          fontSize: "4px !important",
                        }}
                        className="btn rounded-100  m-0 p-0"
                      >
                        {" "}
                        <div
                          className="hover-container"
                          onClick={() => {
                            refundVerification(
                              e.id,
                              twoDecimalPlaces(e.rch_amount),
                              e.username
                            );
                          }}
                          disabled={
                            e.rch_status_desc == "Refund" ||
                            e.rch_status_desc == "Refunded"
                          }
                        >
                          {/* Your content here */}
                          <img
                            src={require("../../../assets/images/Refund.png")}
                            style={{ width: "" }}
                          />
                          <div
                            style={positionStyle}
                            className="hover-text bg-dark py-1"
                          >
                            Refund
                          </div>
                        </div>
                        {/* Rehit */}
                      </Button>
                    )}

                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-0 px-2"
                    >
                      {" "}
                      <div
                        onClick={() => {
                          showResponse(e.rch_api_message);
                          // openInNewTab2({ fullresponse: e.rch_api_message });
                        }}
                        className="hover-container"
                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Bank Response.png")}
                          style={{ width: "" }}
                        />
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark "
                        >
                          <div>Bank</div>
                          <div> Response</div>
                        </div>
                      </div>
                    </Button>

                    <Button
                      style={{
                        // paddingLeft:"3px",
                        // padding: "5px",
                        borderRadius: "5px",
                        border: "1px none",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        color: "rgba(255, 164, 27, 1)",
                        bordercolor: "rgba(255, 255, 255, 1)",
                      }}
                      className="btn btn-sm  rounded-100 m-0 p-0"
                      onClick={() => {
                        raiseTicketShow(e);
                      }}
                    >
                      {" "}
                      <div className="hover-container">
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/images/Raise Ticket.png")}
                          style={{ width: "" }}
                        />
                        <div
                          style={positionStyle1}
                          className="hover-text bg-dark"
                        >
                          {" "}
                          Raise Ticket
                        </div>
                      </div>
                    </Button>

                    <Button style={{
                      // paddingLeft:"3px",
                      // padding: "5px",
                      borderRadius: "5px",
                      border: "1px none",
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      color: 'rgba(255, 164, 27, 1)',
                      bordercolor: 'rgba(255, 255, 255, 1)',

                    }}
                      className="btn btn-sm  rounded-100 m-0 p-0"
                      onClick={() => {
                        if (e.Company == "UPI") {
                          getUPISettlementStatus(e.fk_provider_id);
                        } else {
                          getTransactionStatus(e.id)
                        }

                      }}
                    >
                      {" "}

                      <div
                        className="hover-container"

                      >
                        {/* Your content here */}
                        <img
                          src={require("../../../assets/icons/admin/api.png")}
                          style={{ width: "2.1rem" }}
                        />

                        <div style={positionStyle1} className="hover-text bg-dark"> Transaction Status </div>
                      </div>

                    </Button>

                  </div>
                )}

              </td>
            </tr>
          ))}
          {data.length <= 0 && <NoDataFound />}
        </table>
      </div>
      <div
                    className={`side-section ${isSideSectionVisible ? "visible" : ""}`}
                >
                    <button className="close-button" onClick={handleClose}>
                        &times;
                    </button>
                    <h5 className=""><b>Details</b></h5>

                    {selectedRowData && (
                         <div    
                         style={{
                           maxHeight: "80%",
                           overflowY: "auto",
                           padding: "5px",
                       }}>
                        <table >
                            <tbody>
                                <tr className="border-bottom border-top" >
                                    <td style={{ fontSize: "12px", border: "none" }}
                                    >Txnt ID :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.txn_id}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Bank RRN :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.bank_rrn}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >REF ID :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.tid}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Date :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.rch_indate}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Account :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.Name}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Number :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.rch_mobile}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Retailer Name :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.username}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Service :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.ttyp}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Provider:</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.provider_name}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Opening :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{twoDecimalPlaces(selectedRowData.rch_before_balance)}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Amount :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{twoDecimalPlaces(selectedRowData.rch_amount)}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Closing :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{twoDecimalPlaces(selectedRowData.rch_after_balance)}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Distributor Commission:</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{twoDecimalPlaces(selectedRowData.rch_com_distributer)}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >MD Commission:</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{twoDecimalPlaces(selectedRowData.rch_com_cnf)}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Retailer Comm:</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                        >{twoDecimalPlaces(selectedRowData.rch_com_retailer)}</td>
                                </tr>
                         
                             
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Sales User Code:</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.sales_user_code}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Sales Person Name:</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.sales_user_name}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Sales Person Number:</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.sales_user_mobile}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >Status  :</td>
                                    <td
                                        style={{ fontSize: "12px", border: "none" }}
                                    >{selectedRowData.rch_status_desc}</td>
                                </tr>

                             
                            </tbody>
                        </table>
                        </div>
                    )}

                </div>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
        callback2={(offsetValue) => {
          setOffset(offsetValue);
        }}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div className="d-flex btn-group">
        <select
          className="rounded-0 form-select-sm"
          aria-label="offset"
          onChange={(e) => {
            callback2(e.target.value);
          }}
        >
          {offsetArr.map((offset) => (
            <option value={offset}>{offset}</option>
          ))}
        </select>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        {page + 1 <= limit ? (
          <Button
            onClick={() => {
              callback(page + 1);
            }}
            variant="light"
            className="rounded-0"
          >
            {"Next"}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export const NoDataFound = () => {
  return (
    <tr className="row w-100 justify-content-center">
      <div className="d-flex flex-column align-items-center p-3">
        <img
          src={require("../../../assets/images/no_data_found.png")}
          style={{ height: "5rem", width: "auto" }}
        />
        <strong>No Data Found</strong>
      </div>
    </tr>
  );
};

const DashboardCardKYC = ({
  label,

  data1,
  data2,
  data3,
}) => {
  return (
    <>
      <div className="card m-0 p-0 px-3 py-2 ">
        <h4 className="card-title p-0 text-dark fw-bold  m-0 ">{label}</h4>

        <hr />

        <div className="row p-0 m-0 align-items-center py-1">
          <div className="col p-0 m-0">
            <SquareColoredIcon
              color="#0ACF97"
              iconName="heart-fill"
              text="Success"
            />
          </div>
          <div className="col p-0 m-0 justify-content-end">{data1}</div>
        </div>

        <div className="row p-0 m-0 align-items-center py-1">
          <div className="col p-0 m-0">
            <SquareColoredIcon
              color="#FFBC00"
              iconName="heart-fill"
              text="Pending"
            />
          </div>
          <div className="col p-0 m-0">
            {data2}
            {/* <ProgressBar value={data2} colour={"bg-warning"} /> */}
          </div>
        </div>

        <div className="row p-0 m-0 align-items-center py-1">
          <div className="col p-0 m-0">
            <SquareColoredIcon
              color="#FA5C7C"
              iconName="heart-fill"
              text="Fail"
            />
          </div>
          <div className="col p-0 m-0 md-1">
            {data3}
            {/* <ProgressBar value={data3} colour={"bg-danger"} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

const SquareColoredIcon = ({ color, iconName, text }) => {
  const iconStyle = {
    backgroundColor: color,
    width: "11px",
    height: "11px",
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    borderRadius: "2px",
    marginright: "2px",
  };

  return (
    <div className=" mr-2" style={iconStyle}>
      <div className="mr-2">
        <i
          className={`bi-${iconName}`}
          style={{ color: "white", fontSize: "24px" }}
        ></i>
      </div>

      <div className="ml-2 " style={{ fontSize: "15px" }}>
        {text}
      </div>
    </div>
  );
};
export default AEPSReportTable;