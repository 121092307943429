import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import { useHistory } from "react-router";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import { GlobalContext } from "../../Context/GlobalState";
import Select from "react-select";

const ODRequestDocUpload = () => {
  const initialState = {
    version: "",
    user: { label: "Select User", value: "" },
  };

  const [state, setState] = useState(initialState);
  const [users, setUsers] = useState([]);
  const [kycData, setKycData] = useState({});
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const history = useHistory();
  const [uploadedDocs, setUploadedDoc] = useState({
    agreement_1: false,
    agreement_2: false,
    agreement_3: false,
    cheque: false,
  });
  const [uploadedDocsName, setUploadedDocsName] = useState({
    agreement_1: "",
    agreement_2: "",
    agreement_3: "",
    cheque: "",
  });
  const userId = JSON.parse(localStorage.getItem("kyc"));

  const Back = () => {
    history.push("/kyc/document-details");
  };
  const [formValues, setFormValues] = useState({
    PAN: "",
    AdharFront: "",
    AdharBack: "",
    GstDoc: "",
    Userimage: "",
    UserSign: "",
    UserImageWithAdhar: "",
    ShopImage: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const MN = sessionStorage.getItem("MN");
  const steps = localStorage.getItem("steps");

  const convertToBiteArray = (file, callback) => {
    const reader = new FileReader();
    const fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        const arrayBuffer = evt.target.result,
          array = new Uint8Array(arrayBuffer);
        var binary = "";
        var len = array.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(array[i]);
        }
        callback(window.btoa(binary));
      }
    };
  };

  const ImageUpload = (e) => {
    if (state.user.value == "") {
      swal("Invalid", "Please Select User First", "error");
      return;
    }
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    const type = e.target.id;

    if (e.target.files.length > 0) {
      if (e.target.files[0].size > 256000) {
        swal("Invalid", "Image Size should be less than 256 kb", "error");
        return;
      }
      convertToBiteArray(e.target.files[0], (ByteArray) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          imagename: `OD_${state.user.value}_${type}`,
          imagebytedata: ByteArray,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        try {
          fetch(
            "https://apiservices.bumppypay.com/api/Payment/imageUpload",
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status == "0") {
                setUploadedDoc({ ...uploadedDocs, [type]: true });
                setUploadedDocsName({
                  ...uploadedDocsName,
                  [type]: `OD_${state.user.value}_${type}`,
                });
                cogoToast.success(result.msg);
                // verificationData();
                // setUploadedImage();
              } else {
                cogoToast.error(result.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!uploadedDocs.agreement_1) {
      errors.agreement_1 = "*Agreement Page 1 required!";
    }
    if (!uploadedDocs.agreement_2) {
      errors.agreement_2 = "*Agreement Page 2 required!";
    }
    if (!uploadedDocs.agreement_3) {
      errors.agreement_3 = "*Agreement Page 3 required!";
    }
    if (!uploadedDocs.cheque) {
      errors.cheque = "*Cheque required!";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      submitODRequest();
    }
  };

  const submitODRequest = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: state.user.value,
        agreement_1: uploadedDocsName.agreement_1,
        agreement_2: uploadedDocsName.agreement_2,
        agreement_3: uploadedDocsName.agreement_3,
        cheque: uploadedDocsName.cheque,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/admin/admininODRequestDocSubmit",
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }
      setTransactionLoaderState(false);
      clearForm();
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };

  const getAllUserList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        usertype: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/admin/get_all_user_list_admin.aspx`,
        requestOptions
      );
      const data = await res.json();
      setUsers(
        data.data.map((user) => {
          return {
            ...user,
            value: user.uCode,
            label: `${user.uName} [${user.uCode}]`,
          };
        })
      );
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };
  const clearForm = () => {
    setFormValues({
      agreement_1: "",
      agreement_2: "",
      agreement_3: "",
      cheque: "",
    });
    setState({
      version: "",
      user: { label: "Select User", value: "" },
    })
  }
  useEffect(() => {
    getAllUserList();
  }, []);

  return (
    <div className="p-4" style={{ fontFamily: "auto" }}>
      <div style={{ padding: "1rem" }} className="bg-light ">
        <div
          style={{ borderRadius: "10px" }}
          className="card  p-0 m-0 mx-2 mt-4 "
        >
          <div className="card-body m-0 p-3">
            <div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  fontWeight: "600",
                  lineHeight: "48px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  padding: "0px",
                  background: "#FFFFFF",
                  color: "#4267B2",
                  fontWeight: "bolder",
                  fontfamily: "Poppins !impotent",
                  fontSize: "28px",
                }}
              >
                OD Request Document Upload
              </div>

              <div>
                <div className="col-10 m-0 p-0">
                  <div className="row m-0 p-0">
                    <div className="col-6 p-0 pr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select User`}
                        </Form.Label>
                        <Select
                          options={users}
                          value={state.user}
                          onChange={(e) => {
                            setState({ ...state, user: e });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-6 p-0 pl-2 ">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          <span style={{ color: "black" }}>User Name</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control rounded-100"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="User Name"
                          maxLength="10"
                          value={state.user.value}
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <Row className="mt-2 col-12">
                  <Col className="col-12 col-sm-6 col-md-4 text-left">
                    <Form.Label>
                      <b>Agreement Page 1</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Image
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "30vh",
                        maxWidth: "30vw",
                      }}
                      src={kycData.imgaadharback}
                    ></Image>
                    <Form.Control
                      accept=".jpg,.png"
                      type="file"
                      name="agreement_1"
                      value={formValues.agreement_1}
                      id="agreement_1"
                      onChange={ImageUpload}
                    />
                    <p style={{ color: "red" }}>{formErrors.agreement_1}</p>
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-4 text-left">
                    <Form.Label>
                      <b>Agreement Page 2</b>
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Image
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "30vh",
                        maxWidth: "30vw",
                      }}
                      src={kycData.signature}
                    ></Image>
                    <Form.Control
                      accept=".jpg,.png"
                      type="file"
                      name="agreement_2"
                      value={formValues.agreement_2}
                      id="agreement_2"
                      onChange={ImageUpload}
                    />
                    <p style={{ color: "red" }}>{formErrors.agreement_2}</p>
                  </Col>

                  <Col md={3} lg={3} sm={6} className="text-left">
                    <Form.Label>
                      <b>Agreement Page 3</b>
                    </Form.Label>
                    <Image
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "30vh",
                        maxWidth: "30vw",
                      }}
                      src={kycData.gstdoc}
                    ></Image>
                    <Form.Control
                      accept=".jpg,.png"
                      type="file"
                      name="agreement_3"
                      value={formValues.agreement_3}
                      id="agreement_3"
                      onChange={ImageUpload}
                    />
                    <p style={{ color: "red" }}>{formErrors.agreement_3}</p>
                  </Col>

                  <Col md={3} lg={3} sm={6} className="text-left">
                    <Form.Label>
                      <b>Cheque</b>
                    </Form.Label>
                    <Image
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "30vh",
                        maxWidth: "30vw",
                      }}
                      src={kycData.gstdoc}
                    ></Image>
                    <Form.Control
                      accept=".jpg,.png"
                      type="file"
                      name="cheque"
                      value={formValues.cheque}
                      id="cheque"
                      onChange={ImageUpload}
                    />
                    <p style={{ color: "red" }}>{formErrors.cheque}</p>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="">
              <div className="row">
                <div className="col-2 p-0 ml-2">
                  <Button
                    className="rounded-50 btn btn-primary btn-lg btn-block"
                    onClick={validate}
                  >
                    {" "}
                    Submit
                  </Button>
                </div>
                <div className="col-2 p-0 ml-2">
                  <Button
                    className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
                    onClick={clearForm}
                  >
                    {" "}
                    Clear
                  </Button>
                </div>
                <div className="col p-0"></div>
                <div className="col p-0"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ODRequestDocUpload;
