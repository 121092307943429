import React, { useContext, useState, useEffect, useRef } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../../URLs";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import Select from "react-select";
import { Pagination } from "../../distributor/Manage Retailer/DistributorReport";
import { QR_TITLE_COMPANY_NAME } from "../../utils/StringConstants";
import swal from "sweetalert";
import { generateHashKey2 } from "../../utils/utilFuctions";

const LoanEnquiry = () => {
  const { topup: topupUrl } = urls;
  const [inputState, setInputState] = useState({
    amount: "",
    mobile: "",
    name: "",
    email: "",
    pan: "",
    aadhar: "",
    address: "",
    city: "",
    state: "",
    loanType: "",
    employeeType: "",
    remark: "",
    pinCode: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [WalletRepo, setWalletRepo] = useState([]);

  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [bank, setBank] = useState("Select Bank");
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [TopupData, setTopupData] = useState({
    msg: "",
    success: false,
  });
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.amount.toString().trim()) {
      errors.amount = "*Amount is required!";
    }
    if (!values.mobile.toString().trim()) {
      errors.mobile = "*Mobile number is required!";
    } else if (values.mobile.toString().trim().length != 10) {
      errors.pan = "*Enter valid Mobile number";
    }
    if (!values.name.toString().trim()) {
      errors.name = "*Name is required!";
    }
    if (!values.pan.toString().trim()) {
      errors.pan = "*Mobile number is required!";
    } else if (values.pan.toString().trim().length != 10) {
      errors.pan = "*Enter valid Pan number";
    }
    if (!values.email.toString().trim()) {
      errors.email = "*Email is required!";
    } else if (!regex.test(values.email.toString().trim())) {
      errors.email = "*This is not a valid email format!";
    }
    if (!values.address.toString().trim()) {
      errors.address = "*Address is required!";
    }
    if (!values.city.toString().trim()) {
      errors.city = "*Please select a City!";
    }
    if (!values.state.toString().trim()) {
      errors.state = "*Please select a state!";
    }
    if (!values.loanType.toString().trim()) {
      errors.loanType = "*Loan Type is required!";
    }
    if (!values.employeeType.toString().trim()) {
      errors.employeeType = "*Employee Type Name is required!";
    }
    if (!values.aadhar.toString().trim()) {
      errors.aadhar = "*Adhar is required!";
    } else if (values.aadhar.toString().trim().length != 12) {
      errors.aadhar = "*Please enter valid Adhar no.!";
    }
    if (!values.pinCode.toString().trim()) {
      errors.pinCode = "*Pin Code is required!";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(inputState));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      LoanEnq();
    }
  }, [formErrors]);

  const clearForm = () => {
    setInputState({
      amount: "",
      mobile: "",
      name: "",
      email: "",
      pan: "",
      aadhar: "",
      address: "",
      city: "",
      state: "",
      loanType: "",
      employeeType: "",
      remark: "",
      pinCode: "",
    });
  };

  const LoanEnq = async () => {
    const raw = "";
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        `${BASE_URL_API_TRANXT}/apiget.aspx?method=loanrequestsubmission&UserID=${user.ReferId}&UserType=${user.UserType}&loan_amount=${inputState.amount}&loan_phone=${inputState.mobile}&loan_customername=${inputState.name}&loan_emailid=${inputState.email}&loan_pancard=${inputState.pan}&loan_aadharno=${inputState.aadhar}&loan_typeofenquiry=${inputState.loanType}&loan_employementtype=${inputState.employeeType}&loan_address=${inputState.address}&loan_city=${inputState.city}&loan_state=${inputState.state}&loan_remarks=${inputState.remark}`,
        requestOptions
      );
      const result = await res.json();
      clearForm();
      swal(result.Result);
      setTransactionLoaderState(false);
    } catch (error) {
      console.log(error);
    }
  };

  const LoanType = [
    {
      label: "Personal Loan",
      value: "Personal Loan",
    },
    {
      label: "Business Loan",
      value: "Business Loan",
    },
    {
      label: "Home Loan",
      value: "Home Loan",
    },
    {
      label: "Loan Against Property",
      value: "Loan Against Property",
    },
    {
      label: "Car Loan",
      value: "Car Loan",
    },
    {
      label: "Life Insurance",
      value: "Life Insurance",
    },
    {
      label: "Motor Insurance",
      value: "Motor Insurance",
    },
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Health Products",
      value: "Health Products",
    },
    {
      label: "Credit Card",
      value: "Credit Card",
    },
  ];

  const EmploymentType = [
    {
      label: "Salaried",
      value: "1",
    },
    {
      label: "Self Employed",
      value: "2",
    },
  ];

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = WalletRepo.filter((bank) => {
      let b = false;
      if (WalletRepo.length > 0) {
        for (let key in WalletRepo[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 10;
    const endIdx = currentPage * 10 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((WalletRepo.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(WalletRepo.length);
    const filteredData = WalletRepo.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [WalletRepo]);
  console.log(pages);

  useEffect(() => {
    for (let key in WalletRepo[0]) {
      console.table(key, WalletRepo[0][key]);
    }
  }, [WalletRepo]);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <>
            <ToastContainer
              autoClose={3000}
              hideProgressBar
              draggableDirection="y"
            />

            <div style={{ padding: "0px" }} className="row p-4">
              <Row
                style={{
                  margin: "0px 12px",
                  padding: "0px 12px",
                  width: "100vw",
                  background: "#2E539F",
                  borderTop: "2px solid #EE343A",
                }}
              >
                <span
                  style={{ color: "#FFFFFF", padding: "0px", fontSize: "2rem" }}
                >
                  Loan Enquiry
                </span>
              </Row>

              <Row style={{ width: "100vw" }}>
                <Col md={6}>
                  {/* first */}
                  <div className="container">
                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="number"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Amount Required"
                            value={inputState.amount}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                amount: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.amount}</p>
                        </Form.Group>
                      </div>

                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="number"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Mobile Number"
                            value={inputState.mobile}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                mobile: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.mobile}</p>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Customer Name"
                            value={inputState.name}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                name: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.name}</p>
                        </Form.Group>
                      </div>

                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Email ID"
                            value={inputState.email}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                email: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.email}</p>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Enter PAN Number"
                            value={inputState.pan}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                pan: e.target.value.toUpperCase(),
                              });
                            }}
                            style={{
                              borderRadius: "0px",
                              textTransform: "uppercase",
                            }}
                          />
                          <p style={{ color: "red" }}>{formErrors.pan}</p>
                        </Form.Group>
                      </div>

                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Enter Aadhar Number"
                            value={inputState.aadhar}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                aadhar: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.aadhar}</p>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Full Address"
                            value={inputState.address}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                address: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.address}</p>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="ASM Code (optional)"
                            style={{ borderRadius: "0px" }}
                            value={inputState.asmCode}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                asmCode: e.target.value,
                              });
                            }}
                          />
                          <p style={{ color: "red" }}>{formErrors.asmCode}</p>
                        </Form.Group>
                      </div>
                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="number"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Pin Code"
                            value={inputState.pinCode}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                pinCode: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.pinCode}</p>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="City Name"
                            value={inputState.city}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                city: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.city}</p>
                        </Form.Group>
                      </div>

                      <div className="col">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="form-control"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="State Name"
                            value={inputState.state}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                state: e.target.value,
                              });
                            }}
                            style={{ borderRadius: "0px" }}
                          />
                          <p style={{ color: "red" }}>{formErrors.state}</p>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col">
                        <Select
                          options={EmploymentType}
                          onChange={(e) => {
                            setInputState({
                              ...inputState,
                              employeeType: e.value,
                            });
                          }}
                        />
                        <p style={{ color: "red" }}>
                          {formErrors.employeeType}
                        </p>
                      </div>
                      <div className="col">
                        <Select
                          options={LoanType}
                          onChange={(e) => {
                            setInputState({ ...inputState, loanType: e.value });
                          }}
                        />
                        <p style={{ color: "red" }}>{formErrors.loanType}</p>
                      </div>
                    </div>
                    <div className="row" style={{ padding: "8px 0px" }}>
                      <div className="col">
                        <Form.Group>
                          <textarea
                            placeholder="Remarks"
                            style={{
                              padding: "5px",
                              width: "100%",
                              borderRadius: "0px",
                            }}
                            value={inputState.remark}
                            onChange={(e) => {
                              setInputState({
                                ...inputState,
                                remark: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  {/* fifth */}
                  <div className="container">
                    <div style={{ padding: "10px" }} className="row">
                      <div className="col">
                        {TopupData.msg != "" && (
                          <Form.Group>
                            <Invalid
                              text={TopupData.msg}
                              style={
                                TopupData.success
                                  ? "card-inverse-success"
                                  : "card-inverse-danger"
                              }
                            />
                          </Form.Group>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* sixth */}
                  <div className="container">
                    <div className="row" style={{ padding: "0px" }}>
                      <div className="col grid-margin"></div>
                      <div className="col"></div>
                      <div className="col">
                        <Button
                          onClick={handleSubmit}
                          className="col"
                          variant="success"
                          style={{ borderRadius: "0px" }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {/* <Row>
                <Col md={12} lg={12} sm={12}>
                  <div className="table-responsive">
                    <table style={{ width: "100%" }} className="table border">
                      <tr>
                        {columns.map((heading) => (
                          <th style={{ fontSize: "0.9rem" }}>{heading.text}</th>
                        ))}
                      </tr>
                      {dataToDisplay.length > 0 ?
                        dataToDisplay.map((e, ind) => (
                          <tr>
                            <td style={{ fontSize: 10 }}>{e.wtw_reference_id}</td>
                            <td style={{ fontSize: 10 }}>{e.SenderID}</td>
                            <td style={{ fontSize: 10 }}>{e.ReceiverID}</td>
                            <td style={{ fontSize: 10 }}>{e.wtw_amount}</td>
                            <td style={{ fontSize: 10 }}>{e.wtw_charges}</td>
                            <td style={{ fontSize: 10 }}>{e.wtw_remark}</td>
                            <td style={{ fontSize: 10 }}>{e.wtw_indate}</td>
                          </tr>
                        )) :
                        <tr ><td colspan="13" className="text-align-center">No Data Available</td></tr>}
                    </table>
                    <Pagination
                      page={currentPage}
                      limit={pages}
                      callback={(page) => {
                        setCurrentPage(page);
                      }}
                      count={dataToDisplay.length}
                      total={total}
                    />

                  </div>
                </Col>
              </Row> */}
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

const Invalid = ({ text, style }) => {
  console.log("hiii");
  return (
    <div className={`${style}`}>
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body">
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};

export default LoanEnquiry;
