import React from "react";

import { GlobalContext } from "../../Context/GlobalState";

import PGReportTable from "./PGReportTable";

const PGReport = () => {
  return (
    <GlobalContext.Consumer>
      {(context) => (
         <div
         style={{ padding: "1rem", backgroundColor: "#F2F6FC" }}
         className=" "
       >
         <div
           style={{ borderRadius: "10px" }}
           className="card mt-4 p-0 mx-2 border-light "
         >
           <div className="card-body m-0 p-2">
              <div>
              

                <PGReportTable />
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default PGReport;
