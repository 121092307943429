import React, { useState, useContext, useEffect } from "react";
import TopupViewTable from "../Topup View/TopupViewTable";
import { writeFile, utils } from "xlsx";
import { GlobalContext } from "../../Context/GlobalState";
import { Redirect } from "react-router";

const TopupView = () => {
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null ? (
          <Redirect to="/" />
        ) : (
          <div style={{backgroundColor:"#F1F2F4" , height:"100%" , width:"100%" }}>
            <div
                style={{
                display: "flex",
               padding: "10px",
                justifyContent: "space-between",
              }}>
               <h4 className="ml-2">
              <strong>Topup History</strong>
              </h4>
            </div>
            <TopupViewTable />
          </div>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default TopupView;
