import React from "react";
import { Link, withRouter } from "react-router-dom";
import { OFFLINE_TEXT } from "../../../utils/StringConstants";

const ShortCuts = ({ path }) => {
  if (!path) path = "";
  console.log(path);
  return (
    <div
      style={{
        background: "#F2F7F8",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "2rem",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
      }}
    >
      <Link to={"/retailer/offline_bill_payment"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            alignItems: "center",
            margin: "0rem 2rem",
          }}
        >
          <img
            src={require("../../../../assets/images/bbps/bill.png")}
            style={{ width: "bill_payment".includes(path) ? "2.5rem" : "2rem" }}
          />
          <div>
            <strong
              style={{ color: "#035FB3", marginRight: ".2rem" }}
              className="menu-title"
            >{`HYBRID `}</strong>
            <span style={{ color: "#035FB3" }} className="menu-title">
              {"Bill Payment"}
            </span>
          </div>
          {/* <span
            style={{ color: "bill_payment".includes(path) ? "red" : "black" }}
          >
            {label}
          </span> */}
        </div>
      </Link>

      {/* <ShortCutIcons
        image={require("../../../../assets/images/bbps/bill.png")}
        label={`${OFFLINE_TEXT} Bill Payment`}
        url={"/retailer/offline_bill_payment"}
        selected={"bill_payment".includes(path)}
      /> */}
      <ShortCutIcons
        image={require("../../../../assets/icons/piped gas.png")}
        label={"Piped Gas"}
        url={"/retailer/offline/PipedGas"}
        selected={path.includes("PipedGas")}
      />
      <ShortCutIcons
        image={require("../../../../assets/icons/electricity.png")}
        label={"Electricity Bill"}
        url={"/retailer/offline/electricity"}
        selected={path.includes("electricity")}
      />
      <ShortCutIcons
        image={require("../../../../assets/icons/water bill.png")}
        label={"Water Bill"}
        url={"/retailer/offline/water_bill"}
        selected={path.includes("water_bill")}
      />
      <ShortCutIcons
        image={require("../../../../assets/icons/cable.png")}
        label={"Cable Recharge"}
        url={"/retailer/offline/CableRecharge"}
        selected={path.includes("CableRecharge")}
      />
    </div>
  );
};
const ShortCutIcons = ({ image, label, url, selected }) => {
  return (
    <Link to={url}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          alignItems: "center",
          margin: "0rem 2rem",
        }}
      >
        <img src={image} style={{ width: selected ? "2.5rem" : "2rem" }} />
        <span style={{ color: selected ? "red" : "black" }}>{label}</span>
      </div>
    </Link>
  );
};

export default ShortCuts;
