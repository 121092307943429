import React, {
  useState,
  createRef,
  useEffect,
  useRef,
  useContext,
} from "react";
import "./Chat.css";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES, BASE_URL_API_TRANXT } from "../../utils/Constants";
import { generateHashKey2 } from "../../utils/utilFuctions";
import { set } from "react-hook-form";

const ChatContent = ({ Ticket, Status }) => {

  const Month = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [Comment, setComment] = useState("");
  const [Chat, setChats] = useState("");
  const [ChatResp, setChatResp] = useState([]);

  const TicketResponse = async () => {
    const tid = sessionStorage.getItem("ticketId");
    if (tid && tid.length > 0) {
      try {
        setTransactionLoaderState(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "ticketId": tid
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const res = await fetch(
          `${BASE_URL_APISERVICES}/api/Payment/support/getAllTicketResponsesList`,
          requestOptions
        );
        const data = await res.json();
        if (data.status == "0") {
          setChatResp(data.data);
          getChatObj(data.data);
          scrollToBottom();
        } else {
          getChatObj([]);
          setChatResp([]);
        }

        setTransactionLoaderState(false);
      } catch (error) {
        setTransactionLoaderState(false);
        console.log("error", error);
      }
    }


  };

  const chatReply = async () => {
    const tid = sessionStorage.getItem("ticketId");
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({

        "userid": user.pkid,
        "replyBy": "ADMIN",
        "ticketId": tid,
        "assignedTo": "1",
        "status": "0",
        "remarks": Comment,
        "replyMessage": Comment,
        "type": "1"

      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/Payment/support/ticketChatReplyAdmin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        clearForm();
        TicketResponse();
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }


  };


  const [ChatObj, setChatObj] = useState([]);

  const dateToStr = (d) => {
    const date = new Date(d);
    return `${date.getHours() < 12 ? date.getHours() : date.getHours() - 12}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      } ${date.getHours() < 12 ? "AM" : "PM"} `;
  };

  // 12/28/2022 4:27:33 PM
  const dateToStr2 = (date) =>
    `${date.getDate()}-${Month[date.getMonth() + 1]
    }-${date.getFullYear()}`;

  const getChatObj = (result) => {
    console.log(result);
    const Chats = result.map((Ch, i) => {
      return {
        key: Ch.id,
        msg: Ch.response_message,
        image:
          Ch.reply_on == 'AGENT'
            ? require("../../../assets/images/avatar.png")
            : require("../../../assets/images/company/logo_square.png"),
        type: Ch.reply_on == 'ADMIN' ? "" : "other",
        date: dateToStr2(new Date(Ch.response_date)),
        time: dateToStr(new Date(Ch.response_date)),
      };
    });
    setChatObj(Chats);

  };

  const messagesEndRef = useRef(null);

  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  // const handleChange = event => {
  //     const fileUploaded = event.target.files[0];
  //     handleFile(fileUploaded);
  // };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    console.log("called");
  };
  const clearForm = () => {
    setComment("")
  }

  useEffect(() => {
    if (Ticket.length > 0) {
      sessionStorage.setItem("ticketId", Ticket);
      // setTicketId(Ticket)
    }

  }, [Ticket]);

  useEffect(() => {
    console.log(Ticket);

    const intervalId = setInterval(() => {
      // if (Ticket.length > 2) {
      TicketResponse();
      //   }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="main__chatcontent">
      <div className="content__header">
        <div className="blocks">
          <div className="current-chatting-user">
            <Avatar
              // isOnline="active"
              image={require("../../../assets/images/company/logo_square.png")}
            />
            <p>Bumppy Payments</p>
          </div>
        </div>

        <div className="blocks">
          <div className="settings">
            <span
              className="btn-nobg"
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "2px",
                cursor: "pointer",
              }}
              onClick={() => {
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
                sessionStorage.removeItem("ticketId");
              }}
            >
              &times;
            </span>
          </div>
        </div>
      </div>
      <div className="content__body scrollView">
        <div className="chat__items">
          {ChatObj.map((itm, index) => {
            return (
              <ChatItem
                animationDelay={index + 2}
                key={itm.key}
                user={itm.type ? itm.type : "me"}
                msg={itm.msg}
                image={itm.image}
                Date={itm.date}
                Time={itm.time}
              />
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="content__footer">
        <div className="sendNewMessage">
          <button className="addFiles" onClick={handleClick}>
            <input
              type="file"
              ref={hiddenFileInput}
              accept=".jpeg,.jpg,.png"
              onChange={(e) => setComment(e.target.files[0])}
              style={{ display: "none" }}
            />
            <i className="fa fa-paperclip"></i>
          </button>
          <input
            type="text"
            placeholder="Type a message here"
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={Comment}
          />
          <button
            className="btnSendMsg"
            id="sendMsgBtn"
            onClick={chatReply}
          >
            <i className="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

const ChatItem = (props) => {
  return (
    <div
      style={{ animationDelay: `0.8s` }}
      className={`chat__item ${props.user ? props.user : ""}`}
    >
      <div className="chat__item__content">
        <div className="chat__msg">{props.msg}</div>
        <div className="chat__meta">
          <span>{props.Date} &nbsp;</span>
          <span>{props.Time}</span>
        </div>
      </div>
      <Avatar
        // isOnline="active"
        image={props.image}
      />
    </div>
  );
};

const Avatar = (props) => {
  return (
    <div className="avatar">
      <div className="avatar-img">
        <img src={props.image} alt="#" />
      </div>
      <span className={`isOnline ${props.isOnline}`}></span>
    </div>
  );
};

const ChatList = (props) => {
  const allChatUsers = [
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTA78Na63ws7B7EAWYgTr9BxhX_Z8oLa1nvOA&usqp=CAU",
      id: 1,
      name: "Tim Hover",
      active: true,
      isOnline: true,
    },
    {
      image:
        "https://pbs.twimg.com/profile_images/1055263632861343745/vIqzOHXj.jpg",
      id: 2,
      name: "Ayub Rossi",
      active: false,
      isOnline: false,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&usqp=CAU",
      id: 3,
      name: "Hamaad Dejesus",
      active: false,
      isOnline: false,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRZ6tM7Nj72bWjr_8IQ37Apr2lJup_pxX_uZA&usqp=CAU",
      id: 4,
      name: "Eleni Hobbs",
      active: false,
      isOnline: true,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRJo1MiPQp3IIdp54vvRDXlhbqlhXW9v1v6kw&usqp=CAU",
      id: 5,
      name: "Elsa Black",
      active: false,
      isOnline: false,
    },
    {
      image:
        "https://huber.ghostpool.com/wp-content/uploads/avatars/3/596dfc2058143-bpfull.png",
      id: 6,
      name: "Kayley Mellor",
      active: false,
      isOnline: true,
    },
    {
      image:
        "https://www.paintingcontest.org/components/com_djclassifieds/assets/images/default_profile.png",
      id: 7,
      name: "Hasan Mcculloch",
      active: false,
      isOnline: true,
    },
    {
      image:
        "https://auraqatar.com/projects/Anakalabel/media//vesbrand/designer4.jpg",
      id: 8,
      name: "Autumn Mckee",
      active: false,
      isOnline: false,
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSM6p4C6imkewkCDW-9QrpV-MMAhOC7GnJcIQ&usqp=CAU",
      id: 9,
      name: "Allen Woodley",
      active: false,
      isOnline: true,
    },
    {
      image: "https://pbs.twimg.com/profile_images/770394499/female.png",
      id: 10,
      name: "Manpreet David",
      active: false,
      isOnline: true,
    },
  ];
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         allChats: this.allChatUsers,
  //     };
  // }

  return (
    <div className="main__chatlist">
      <button className="btn">
        <i className="fa fa-plus"></i>
        <span>New conversation</span>
      </button>
      <div className="chatlist__heading">
        <h2>Chats</h2>
        <button className="btn-nobg">
          <i className="fa fa-ellipsis-h"></i>
        </button>
      </div>
      <div className="chatList__search">
        <div className="search_wrap">
          <input type="text" placeholder="Search Here" required />
          <button className="search-btn">
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
      <div className="chatlist__items ">
        {allChatUsers.map((item, index) => {
          return (
            <ChatListItems
              name={item.name}
              key={item.id}
              animationDelay={index + 1}
              active={item.active ? "active" : ""}
              isOnline={item.isOnline ? "active" : ""}
              image={item.image}
            />
          );
        })}
      </div>
    </div>
  );
};

const ChatListItems = (props) => {
  const selectChat = (e) => {
    for (
      let index = 0;
      index < e.currentTarget.parentNode.children.length;
      index++
    ) {
      e.currentTarget.parentNode.children[index].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
  };

  return (
    <div
      style={{ animationDelay: `0.${props.animationDelay}s` }}
      onClick={selectChat}
      className={`chatlist__item ${props.active ? props.active : ""} `}
    >
      <Avatar
        image={props.image ? props.image : "http://placehold.it/80x80"}
        isOnline={props.isOnline}
      />

      <div className="userMeta">
        <p>{props.name}</p>
        <span className="activeTime">32 mins ago</span>
      </div>
    </div>
  );
};

export default ChatContent;
