import React, { useState, useEffect, useContext } from "react";
import ShortCuts from "../Offline Shortcuts/ShortCuts";
import { Button, Form, ButtonGroup, Card } from "react-bootstrap";
import { basicAuth } from "../../../config";
import { Redirect, useLocation } from "react-router";
import { GlobalContext } from "../../../Context/GlobalState";
import {
  BASE_URL_API_TRANXT_API,
  BASE_URL_PAYNOW,
} from "../../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../../utils/Themer";
import OfflineMobilePrepaidRechargeSidebar from "./OfflineMobilePrepaidRechargeSidebar";
import OfflineMobilePrepaidRechargeHistory from "./OfflineMobilePrepaidRechargeHistory";
import { excelMapping, ExportToExcel } from "../../../utils/utilFuctions";

export const columns = [
  {
    dataField: "rch_operator_id",
    text: "Operator ",
  },
  // {
  //   dataField: "Provider",
  //   text: "Provider",
  // },
  {
    dataField: "Name",
    text: "Number",
  },
  {
    dataField: "rch_payu_id",
    text: "Refrence Number",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_com_retailer",
    text: "Fee",
  },
  {
    dataField: "rch_com_retailer",
    text: "Comm",
  },
  {
    dataField: "tds_retailer",
    text: "TDS",
  },
  // {
  //   dataField: "rch_after_balance",
  //   text: "After Balance",
  // },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];

const OfflineMobilePrepaidRecharge = () => {
  const { username, password } = basicAuth;
  const [providers, setProviders] = useState([]);
  const location = useLocation();
  const [data, setData] = useState([]);
  const { user } = useContext(GlobalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [mobile, setMobile] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(getDatePreviousMonth());
  const [endDate, setEndDate] = useState(new Date());
  const { Profileinfo } = useContext(GlobalContext);

  const getAllProviders = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append(
        "Cookie",
        ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLDeudbzWuuuGKwhiSsa75bUtX_NqwCaj_v4o_hj4oq9t2cr4CA5MVcuNkvX4mpyg54H4S0w3SoSQAIpD0xsTsF30dEOh3UNorm1OTqZUAALf9maVz0uehddtUJFuzniN9qu9RJuKC_2AT8zdbdrxjTpr0Wl2TTwOjqhJhhfJEYxexBisigKKzH8JQ5jYsat6DqofW5DCOS3jgKAPc6Xlz9cxt7gv1QzfkjONGG97fMJy-roetHLJC3ZHeckzq12FZoG7biAgSxL-crQOu056sRyI57fSPtPMdQxjZx-oPpocqKE69utlorNhTrVLULgbWq8pxcBXqkxm8CjGqX9G9Ml7EKXJ9dEE_nRZsufrhgWIXimbxjgHXVP6joIZJSEi1jCpb_EWDzEV8MOsbza5HNgc2A5wAHakm6BPCT3QqFit3KF0MP35HbuehToEJW0DVrXrUyz9ZsbS4ndq96ncud0pxfTHoFBFJaUAWl5ylrRKmZ1La38FafnjGBPtNcJqNjgT2xmEJXbobUIL60eZzmgWL8H2f6yllunvIO0n30EJyFVOELJojx0WQGrYWFfc8lXzzreQuz9XZwLAukacbhZ-ciW3i2rSx1H9yGQkHHnQvoxX18K4DwJCikRGJq-BSZBFzSYj0pICNQot2rj1qTT"
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_PAYNOW}/api/Operator/GetAllOfflineOperators`,
        requestOptions
      );
      const data = await res.json();
      console.log(data);
      if (data) {
        setProviders(
          data.data.filter((pro) => {
            return pro.category_id == 6;
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const now = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/recharge_print?id=${data.tid}&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = data.filter((bank) => {
      let b = false;
      if (data.length > 0) {
        for (let key in data[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getRechargeReport = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserID: user.ReferId,
        mobile: "",
        fromdate: dateFormatter(startDate),
        todate: dateFormatter(endDate),
        report_type: "REC",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_bbps_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setData(result.slice(0, 20));
          setDataToDisplay(result.slice(0, 20));
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };

  useEffect(() => {
    getAllProviders();
    getRechargeReport();
  }, []);

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <OfflineMobilePrepaidRechargeSidebar
                details={{ providers, getRechargeReport }}
              />
            </div>
            <div style={{ backgroundColor: COLOR_BACKGROUND_BODY }}>
              <div className="row" style={{ padding: "0px" }}>
                <ShortCuts path={location.pathname} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "10px 10px 0px 10px",
                }}
              >
                <Button
                  variant="success"
                  className="rounded-0"
                  onClick={() => {
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                >
                  {"Make Mobile Recharge"}
                </Button>
              </div>
              <div style={{ padding: ".5rem" }}>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0px 0px 0px" }}
        >
          <div className="row py-0">
            <Form.Group style={{ marginLeft: "10px" }}>
              <Form.Label > From Date </Form.Label>
              <DatePicker  dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
            <Form.Group style={{ marginLeft: "10px" }}>
            <Form.Label > To Date </Form.Label>
              <DatePicker  dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                placeholderText="End Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          <Form.Group style={{ marginLeft: "10px" }}>
          <Form.Label > Account Number </Form.Label>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Account No"
              onChange={(e) => setMobile(e.target.value)}
            />
          </Form.Group>

          <Form.Group style={{ marginLeft: "10px" }}>
          <div style={{height:'22px'}} />
            <Button
              className="form-control rounded-0"
              variant="success"
              onClick={getRechargeReport}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div> */}
                  </div>

                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: "1" }}>
                        <Form.Group
                          style={{ maxWidth: "12rem", margin: "10px 0px" }}
                        >
                          <Form.Control
                            type="text"
                            className="form-control rounded-0"
                            aria-label="Notes"
                            aria-describedby="basic-addon1"
                            placeholder="Search in Table"
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                            value={searchText}
                          />
                        </Form.Group>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          ExportToExcel(
                            `Recharge_Report_${now()}`,
                            excelMapping(data, columns)
                          );
                        }}
                      >
                        <img
                          src={require("../../../../assets/images/excel.png")}
                          style={{ width: "1.6rem" }}
                        />
                        <strong style={{ marginLeft: "4px" }}>
                          Export to Excel
                        </strong>
                      </div>
                    </div>
                  </div>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
                      {columns.map((heading) => (
                        <th style={{ fontSize: "0.9rem" }}>{heading.text}</th>
                      ))}
                      <th style={{ fontSize: "0.9rem" }}> {"Action"}</th>
                    </tr>
                    {dataToDisplay.map((e, ind) => (
                      <tr>
                        <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
                        {columns.map((cell) =>
                          cell.text == "Status" ? (
                            e[cell.dataField] == "success" ||
                            e[cell.dataField] == "Success" ? (
                              <td style={{ fontSize: "0.8rem" }}>
                                {" "}
                                <label
                                  style={{
                                    padding: "4px",
                                    borderRadius: "4px",
                                    minWidth: "4rem",
                                  }}
                                  className="badge badge-success"
                                >
                                  {"Success"}
                                </label>{" "}
                              </td>
                            ) : (
                              <td style={{ fontSize: "0.8rem" }}>
                                <label
                                  style={{
                                    padding: "4px",
                                    borderRadius: "4px",
                                    minWidth: "4rem",
                                  }}
                                  className="badge badge-warning"
                                >
                                  {e[cell.dataField]}
                                </label>
                              </td>
                            )
                          ) : (
                            <td style={{ fontSize: ".8rem" }}>
                              {e[cell.dataField]}
                            </td>
                          )
                        )}
                        <td>
                          <Button
                            onClick={() => {
                              openInNewTab(e);
                            }}
                            variant="danger"
                            className="rounded-0"
                          >
                            {" "}
                            Print
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </>
              </div>
            </div>
          </>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default OfflineMobilePrepaidRecharge;
