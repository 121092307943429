import React, { useState, useEffect, useContext } from "react";
import TrawloImage from "../../../assets/images/carousel/TrawloBanner2.0.png";
import TrawloLogo from "../../../assets/images/TrawloLogo.png";
import TrawloBanner from "../../../assets/images/carousel/TrawloBanner1.png";

const Trawlo = () => {

  const steps = [
    { label: "Onboarding", status: "In Progress" },
    { label: "Document Submission", status: "Pending" },
    { label: "Document Verification", status: "Pending" },
    { label: "Website Live", status: "Pending" },
  ];

  const currentStep = 1;

  // const steps = ["Onboarding", "Document submission", "Document Verification", "Website Live"];
  const data = [
    {
      name: "Suhas Suryavanshi",
      productName: "Kwality Veg",
      phone: "8178954858",
      onboardingDate: "02 Dec 2024 | 05 : 49 PM",
      currentStep: 1,
    },
    {
      name: "Durgesh Nai Singh",
      productName: "Foodie Junction",
      phone: "8178954858",
      onboardingDate: "02 Dec 2024 | 05 : 49 PM",
      currentStep: 2,
    },
    {
      name: "Suhas Suryavanshi",
      productName: "Kwality Veg",
      phone: "8178954858",
      onboardingDate: "02 Dec 2024 | 05 : 49 PM",
      currentStep: 1,
    },
  ];


  return (
<div>
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <div style={{ display: "flex", padding: "20px", border: "1px solid #E0E0E0", borderRadius: "10px", marginBottom: "20px", backgroundColor: "#FFF9E5" }}>
        <div style={{ flex: 1, paddingRight: "20px" }}>
          <img src={TrawloLogo} style={{ height: "auto", width: "8rem", margin: "2px" }} />
          <div style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Trawlo is your trusted travel booking and arrangement partner, making your journeys easy and memorable. Travel with Trawlo today!
          </div>
          <h5><b>Earn Money with Trawlo:</b></h5>
          <ul style={{ paddingLeft: "20px", marginBottom: "20px" }}>
            <li>Earn ₹150 for every individual ticket booked through Trawlo.</li>
            <li>No earning limits—the more bookings, the more you earn.</li>
            <li>Easy to join and start earning right away.</li>
          </ul>
          <h5><b>Benefits for Clients</b></h5>
          <ul style={{ paddingLeft: "20px", marginBottom: "20px" }}>
            <li>Plan your travel without any worries.</li>
            <li>Book packages and tickets easily through Trawlo.</li>
            <li>Get access to the best deals and arrangements.</li>
            <li>Save time with a platform that simplifies all your travel needs.</li>
            <li>Enjoy easy bookings and excellent customer support for a stress-free journey.</li>
          </ul>
          <div style={{color:'blue'}}>If this is your first time here, let’s get your account up and running!
          Here’s how to set your password:</div>
          <ul style={{ paddingLeft: "20px", marginBottom: "20px" , color:"blue" }}>
            <li>Use your phone number as your login ID. </li>
            <li>Click on "Forgot Password" to begin the setup process.</li>
           
          </ul>
        
          <a
                href="https://trawlo.com/agents/" 
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  backgroundColor: "#28a745",
                  color: "white",
                  textDecoration: "none",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  textAlign: "center",
                  display: "inline-block",
                  cursor: "pointer",
                }}
              >Get Started</a>
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <div style={{ width: "100%", height: "250px", backgroundColor: "#FFF9E5", borderRadius: "10px" }}>
            <img src={TrawloImage} style={{ height: "33rem", width: "auto" }} />
          </div>
        </div>
      </div>

    </div>
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <img src={TrawloBanner} style={{width:'100%'}}/>
    </div>
    </div>
  );
};

export default Trawlo;





