import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { offsetArr } from "../../utils/CommonConfig";


const userTypes = [
 { label: "Master Distributor", value: "4" },
 { label: "Distributor", value: "3" },
 { label: "Retailer", value: "2" },
 { label: "API Partner", value: "5" },
];

const CommercialsSetup = () => {
// const initialState = {
// FromAmount: "",
// ToAmount: "",
// Charges: "",
// RetailerComm: "",
// DistComm: "",
// MDComm: "",
// commType: "",

// };
 const [initialState, setInitialState] = useState({
 FromAmount: "",
 ToAmount: "",
 Charges: "",
 RetailerComm: "",
 DistComm: "",
 MDComm: "",
 commType: "", 
 });

// const handleRadioChange = (e) => {
// setInitialState((prevState) => ({
// ...prevState,
// commType: e.target.value,
// }));
// };

 const [state, setState] = useState({
 FromAmount: "",
 ToAmount: "",
 Charges: "",
 RetailerComm: "",
 DistComm: "",
 MDComm: "",
 commType: "", 
 });
 const [data, setData] = useState([]);

 const [userList, setUserList] = useState([]);
 const [parentList, setParentList] = useState([]);
 const [tariffs, setTariffs] = useState([]);
 const [formErrors, setFormErrors] = useState({ mobile: "" });
 const { user, setTransactionLoaderState } = useContext(GlobalContext);
 const [currentPage, setCurrentPage] = useState(1);

 const [offset, setOffset] = useState(10);
 const [dataToDisplay, setDataToDisplay] = useState([]);
 const [pages, setPages] = useState(0);
 const [total, setTotal] = useState(0);

 const validateForm = () => {
 if (
 state.FromAmount == "" ||
 state.ToAmount == "" ||
 state.Charges == "" ||
 state.RetailerComm == "" ||
 state.DistComm == "" ||
 state.MDComm == "" ||
 state.commType == ""
 ) {
 swal("Invalid", "Please Fill Mandatory Fields", "error");
 return;
 } 
 createTariffPlan();
 };

 const clearForm = () => {
 setState(initialState);
 };

 const createTariffPlan = async () => {
 try {
 setTransactionLoaderState(true);

 const myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append("Authorization", "Basic cmFqbmVlc2hAYnVtcHB5LmNvbTpUZXN0QDEyMzQ1");

 var raw = JSON.stringify({
 adminid: user.pkid,
 service: "VENDOR_MANAGEMENT",
 fromAmount: state.FromAmount,
 toAmount: state.ToAmount,
 charges: state.Charges,
 retCommission: state.RetailerComm,
 distCommission: state.DistComm,
 cnfCommission: state.MDComm,
 chargesType: state.commType
 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
"https://apiservices.bumppypay.com/api/Admin/createTariffPlan",
 requestOptions
 );

 const data = await res.json();
 if (data.responseCode == "00") {
 swal("Tariff Created Successfully", data.msg, "success");
 clearForm();
 } else {
 swal("Tariff Creation Failed", data.msg, "error");
 }
 setTransactionLoaderState(false);
 } catch (error) {
 setTransactionLoaderState(false);
 console.log("error", error);
 swal("User Registration Failed", "Error From Server", "error");
 }
 };

 const fetchTariffPlan = async () => {
 try {
 var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append(
 "Authorization",
 "Basic " + Buffer.from("rajneesh@bumppy.com" + ":" + "Test@12345").toString("base64")
 );
 var raw = JSON.stringify
 ({
 adminId: user.pkid,
 service: "VENDOR_MANAGEMENT"

 });

 var requestOptions = {
 method: "POST",
 headers: myHeaders,
 body: raw,
 redirect: "follow",
 };

 const res = await fetch(
"https://apiservices.bumppypay.com/api/Admin/fetchTariffPlan",
 requestOptions
 )

 .then((response) => response.json())
 .then((result) => {
 if (result.responseCode == "00") {
 setDataToDisplay(result.data);
 } else {
 setDataToDisplay([]);
 }
 })
 .catch((error) => console.log("error", error));
 } catch (error) {
 console.log(error);
 }
};
useEffect(() => {
 fetchTariffPlan()
 
}, [])

 return (
 <div style={{ padding: "1rem" }} className="bg-light ">
 <div style={{ borderRadius: "10px" }} className="card p-0 m-0 mx-2 mt-4 ">
 <div className="card-body m-0 p-3">
 <div>
 <div
 style={{
 "fontFamily":"Poppins","fontSize":"32px","fontWeight":"600","lineHeight":"48px","letterSpacing":"0em","textAlign":"left"
 ,padding: "0px",
 background: "#FFFFFF",
 color: "#4267B2",
 fontWeight: "bolder",
 fontfamily: "Poppins !impotent",
 fontSize:"28px",
 }}
 >
 Commercials - Setup
 </div>

 <div>
 <div className="col-10 m-0 p-0">
 <div className="row m-0 p-0">
 <div className="col-6 p-0 pr-2 ">
 <Form.Group>
 <Form.Label>
 <span style={{ color: "red" }}>*</span>
 <span style={{ color: "black" }}>From Amount</span>
 
 </Form.Label>
 <Form.Control
 type="number"
 className="form-control rounded-100"
 aria-label="Notes"
 aria-describedby="basic-addon1"
 placeholder="Enter From Amount"
 value={state.FromAmount}
 onChange={(e) => {
 setState({ ...state, FromAmount: e.target.value });
 }}
 
 />
 </Form.Group>
 </div>

 <div className="col-6 p-0 pl-2 ">
 <Form.Group>
 <Form.Label>
 <span style={{ color: "red" }}>*</span>
 <span style={{ color: "black" }}>To Amount </span>
 
 </Form.Label>
 <Form.Control
 type="number"
 className="form-control rounded-100"
 aria-label="Notes"
 aria-describedby="basic-addon1"
 placeholder="Enter To Amount"
 value={state.ToAmount}
 onChange={(e) => {
 setState({ ...state, ToAmount: e.target.value });
 }}
 
 />
 </Form.Group>
 </div>
 </div>
 </div>
 
 <div className="col-10 m-0 p-0">
 <div className="row m-0 p-0">
 <div className="col-6 p-0 pr-2">
 <Form.Group >
 <Form.Label>
 <span style={{ color: "red" }}>*</span>
 <span style={{ color: "black" }}>Charges</span>
 
 </Form.Label>
 <Form.Control
 type="number"
 className="form-control rounded-100"
 aria-label="Notes"
 aria-describedby="basic-addon1"
 placeholder="Enter Charges"
 value={state.Charges}
 onChange={(e) => {
 setState({ ...state, Charges: e.target.value });
 }}
 
 />
 </Form.Group>
 </div>

 <div className="col-6 p-0 pl-2 ">
 <Form.Group>
 <Form.Label>
 <span style={{ color: "red" }}>*</span>
 <span style={{ color: "black" }}>Retailer Comm</span>
 
 </Form.Label>
 <Form.Control
 type="number"
 className="form-control rounded-100"
 aria-label="Notes"
 aria-describedby="basic-addon1"
 placeholder="Enter Retailer Comm"
 maxLength="10"
 value={state.RetailerComm}
 onChange={(e) => {
 setState({ ...state, RetailerComm: e.target.value });
 }}
 
 />
 <p style={{ color: "red" }}>{formErrors.mobile}</p>
 </Form.Group>
 </div>
 </div>
 </div>


 <div className="col-10 m-0 p-0">
 <div className="row m-0 p-0">
 <div className="col-6 p-0 pr-2 ">
 <Form.Group>
 <Form.Label>
 <span style={{ color: "red" }}>*</span>
 <span style={{ color: "black" }}>Diss Comm</span>
 
 </Form.Label>
 <Form.Control
 type="number"
 className="form-control rounded-100"
 aria-label="Notes"
 aria-describedby="basic-addon1"
 placeholder="Enter Diss Comm"
 value={state.DistComm}
 onChange={(e) => {
 setState({ ...state, DistComm: e.target.value });
 }}
 
 />
 </Form.Group>
 </div>

 <div className="col-6 p-0 pl-2 ">
 <Form.Group>
 <Form.Label>
 <span style={{ color: "red" }}>*</span>
 <span style={{ color: "black" }}>MD Comm </span>
 
 </Form.Label>
 <Form.Control
 type="number"
 className="form-control rounded-100"
 aria-label="Notes"
 aria-describedby="basic-addon1"
 placeholder="Enter MD Comm"
 value={state.MDComm}
 onChange={(e) => {
 setState({ ...state, MDComm: e.target.value });
 }}
 
 />
 </Form.Group>
 </div>
 </div>
 </div>
 </div>

 
 </div>
 <div className="">
 <div className="row align-items-center">
 <div className="col-4 p-0 ml-2">
 <div className="form-check form-check-inline">
 <input
 className="form-check-input"
 type="radio"
 name="commType"
 id="percentage"
 value="P"
 checked={state.commType === "P"}
 onChange={(e) => {
 setState({ ...state, commType: e.target.value });
 }} />
 <label className="form-check-label" htmlFor="percentage">
 Percentage
 </label>
 </div>
 <div className="form-check form-check-inline">
 <input
 className="form-check-input"
 type="radio"
 name="commType"
 id="fixed"
 value="F"
 checked={state.commType === "F"}
 onChange={(e) => {
 setState({ ...state, commType: e.target.value });
 }} />
 <label className="form-check-label" htmlFor="fixed">
 Fixed
 </label>
 </div>
 </div>
 <div className="col-2 p-0 ml-2">
 <Button
 className="rounded-50 btn btn-primary btn-lg btn-block"
 onClick={validateForm}
 // onClick={submitForm}
 >
 Submit
 </Button>
 </div>
 <div className="col-2 p-0 ml-2">
 <Button
 className="rounded-50 btn border-primary btn-lg btn-block bg-white border text-primary"
 onClick={clearForm}
 >
 Clear
 </Button>
 </div>
 <div className="col p-0"></div>

 </div>
</div>

 </div>
 </div>
 <div className="card">
 <div className=" scroll-inner admin-fix-width borderless-table ">
 <table
 className="my-table transparent-table text-center border-bottom"
 style={{ width: "100%", overflowX: "auto", border: "0px", overflow: "auto", }}
 >
 <tr className="borderless-table text-center border-bottom"
 >
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}> {"Sr"}</th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Tariff ID
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 From Amount
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 To Amount
 </th>
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Charges
 </th>
 
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Type
 </th>
 
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
Distributor Comm </th>
 
 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Master Distributor Comm
 </th>

 <th className="my-table transparent-table text-center border-bottom " style={{ "fontFamily": "Poppins", "fontSize": "16px", "fontWeight": "700", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 Retailer Comm
 </th>

 </tr>
 {dataToDisplay.map((e, ind) => (
 <tr 
 >
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 {" "}
 {ind + 1}
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.tariff_id}</div>
 </td>

 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.from_amt}</div></td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.to_amt}</div>
 </td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.charges}</div>
 </td>

 {/* <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>
 <div>{e.charge_type}</div>
 </td> */}
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', fontFamily: "Poppins", fontWeight: "400", lineHeight: "24px", letterSpacing: "0em", textAlign: "center" }}>
 {e.charge_type.toString().toLowerCase() === "p" ? (
 <span
 style={{
 padding: "5px",
 borderRadius: "5px",
 backgroundColor: 'rgba(43, 193, 85, 0.1)',
 color: 'rgba(43, 193, 85, 1)',
 fontWeight: "bold"
 }}
 >
 Percentage
 </span>
 ) : (
 <span
 style={{
 padding: "5px",
 borderRadius: "5px",
 backgroundColor: 'rgba(254, 128, 36, 0.1)',
 color: 'rgba(254, 128, 36, 1)',
 fontWeight: "bold"
 }}
 >
 Fixed
 </span>
 )}
</td>

 
 <td className="text-center text-bold border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}><div>{e.sales_user_code}</div>
 <div>{e.tf_dist_comm}</div>
 </td>
 
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.tf_cnf_comm}</td>
 <td className="text-center border-bottom" style={{ fontSize: '0.8rem', "fontFamily": "Poppins", "fontWeight": "400", "lineHeight": "24px", "letterSpacing": "0em", "textAlign": "center" }}>{e.tf_ret_comm}</td>

 

 </tr>
 ))}
 </table>
 
 </div>
 <Pagination
 page={currentPage}
 limit={pages}
 callback={(page) => {
 setCurrentPage(page);
 }}
 count={dataToDisplay.length}
 total={total}
 callback2={(offsetValue) => {
 setOffset(offsetValue);
 }}
 />
 </div>
 </div>
 
 );
};
const EmptyArrayHandler = () => {
 return (
 <div className="row">
 <div className="col-12">
 <span style={{ fontSize: "1.4rem" }}>No records Found</span>
 </div>
 </div>
 );
};

export const Pagination = ({
 page,
 limit,
 callback,
 total,
 count,
 callback2,
}) => {
 console.table({ page, limit, callback });
 const selectStyleLeftBorder = {

 borderRadius: '0px',
 "fontFamily": "Poppins",
 'fontsize': '16px',
 borderRight: '1px solid rgba(0, 0, 0, 0.2)', // Remove the border
 borderRadius: '0px',
 borderLeft: 'none', // Remove right side border
 borderTop: 'none', // Remove top border
 borderBottom: 'none', // Remove bottom border
 "fontFamily": "Poppins",
 'fontsize': '16px',




 };
 const selectStyleBorder = {
 border: '1px solid rgba(0, 0, 0, 0.1)', // Remove the border
 borderRadius: '8px',
 "fontFamily": "Poppins",
 'fontsize': '16px',
 };
 const selectStyle = {

 border: 'none', // Remove the border
 "fontFamily": "Poppins",
 'fontsize': '16px',

 };
 return (
 <div
 className="row"
 style={{
 display: "flex",
 alignItems: "end",
 width: "100%",
 margin: "12px 0px",
 justifyContent: "space-between",

 }}
 >
 <div style={{ "fontFamily": "Poppins", }}>
 <strong>{`Showing ${count} of ${total} entries`}</strong>
 </div>


 <div className="d-flex btn-group p-1" style={selectStyleBorder}>
 <select
 style={selectStyleLeftBorder}
 className=" d-flex justify-content-end "
 aria-label="offset"
 onChange={(e) => {
 callback2(e.target.value);
 }}

 >
 {offsetArr.map((offset) => (
 <option value={offset} className="d-flex justify-content-end">{offset}</option>
 ))}

 </select>
 {page - 2 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}

 className="rounded-0 bg-white text-dark border-white"

 >
 {"<<"}
 </Button>
 ) : null}

 {page - 1 > 0 ? (
 <Button
 onClick={() => {
 callback(page - 1);
 }}

 className="rounded-0 bg-white text-dark border-white underline-text"
 >
 {" "}
 {(page - 1).toString()}
 </Button>
 ) : null}



 <Button className="bg-white text-primary " style={selectStyle}> {page.toString()}</Button>




 {/* Next And Previous Button Code */}
 {page + 1 <= limit ? (
 <Button
 onClick={() => {
 callback(page + 1);
 }}

 className="rounded-0 bg-white text-dark border-white"
 >
 {">>"}
 </Button>
 ) : null}
 </div>
 </div>
 );
};
export default CommercialsSetup;