import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown, Form, Card , Modal} from "react-bootstrap";
import { urls } from "../../URLs";
import { GlobalContext } from "../../Context/GlobalState";
import {
  dmtSuccessPopup,
  dmtTransactionFailedPopup,
  dmtTransactionSuccessPopup,
} from "../../utils/Models";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { generateHashKey2 } from "../../utils/utilFuctions";
const { DMTExpressUrl } = urls;

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

const DMTExpressSendMoneyModal = ({ modalstate }) => {
  const {
    wallet,
    setWalletBalance,
    refreshWallet,
    setTransactionLoaderState,
    Profileinfo,
  } = useContext(GlobalContext);
  const { details, isOpen, setModelState , refreshLimit } = modalstate;
  const [isLoading, setIsLoading] = useState(false);
  const tPinLength = 6;
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");
  const [tPin, setTPin] = useState("");
  const { benificiary, user, successToast, errorToast, limit, remitterNumber } =
    details;
  const [charges, setCharges] = useState(0);
  const [tpinShow, setTpinShow] = useState(false);

  const close = () => {
    setTPin("");
    setModelState({ ...modalstate, isOpen: false });
  };
  const sendMoney = async () => {
    let beneNumber = benificiary.recipient_mobile;
    if (beneNumber == null || beneNumber == "") {
      beneNumber = remitterNumber;
    }
    if (tPin == "" || tPin.length < tPinLength) {
      errorToast("Please enter valid T Pin");
      return;
    }
    // if (
    //   Number(amount) != 100000 &&
    //   (Number(amount) < 25000 || Number(amount) > 49750)
    // ) {
    //   swal("Failed", "Please Enter amount in Range 25000 to 49750", "error");
    //   return;
    // }
    // if (Number(amount) > limit) {
    //   swal("Failed", "You have exceeded your daily limit.", "error");
    //   return;
    // }
    setIsLoading(true);
    setTransactionLoaderState(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("dmt", "sendmoney");
    urlencoded.append("beneficiaryid", benificiary.account);
    urlencoded.append("mobile", beneNumber);
    urlencoded.append("amount", amount + ".00");
    urlencoded.append("tid", "IMPS");
    urlencoded.append("uid", user.ReferId);
    urlencoded.append("type", "mobile");
    urlencoded.append("bank", benificiary.ifsc);
    urlencoded.append("tpin", tPin);
    urlencoded.append("route", "0");
    urlencoded.append("accountno", benificiary.account);
    urlencoded.append("paytmlimit", limit);
    urlencoded.append("bankitlimit", limit);
    urlencoded.append(
      "benfname",
      benificiary.recipient_name.toString().replace("&", "")
    );
    urlencoded.append("customernumber", remitterNumber);
   
    const hash_key = await generateHashKey2(user.ReferId);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    try {
      const res = await fetch(DMTExpressUrl, requestOptions);
      const raw = await res.text();
      const data = JSON.parse(raw);
      if (data.status) {
        // dmtTransactionSuccessPopup(
        //   "",
        //   `${amount}.00`,
        //   "Amount transfer successfull"
        // );
        dmtSuccessPopup(amount, "Amount transfer successfull", printReceipt);
        setModelState({ ...modalstate, isOpen: false });
        refreshLimit();
      } else {
        dmtTransactionFailedPopup("", `${amount}.00`, data.message);
      }
      setIsLoading(false);
      setTransactionLoaderState(false);
      refreshWallet();

      console.log(data);
    } catch (error) {
      setIsLoading(false);
      setTransactionLoaderState(false);
      errorToast("Amount transfer failed");
      dmtTransactionFailedPopup("", `${amount}.00`, "Amount transfer failed");
      console.log("error", error);
    }
  };
  useEffect(() => {
    console.log("Sidebar changes");
    setAmount(0);
    setCharges(0);
    setTPin("");
  }, [details, isOpen]);
  const showTPinClick = () => {
    setTpinShow(!tpinShow);
  };
  const openInNewTab = (data) => {
    localStorage.setItem(
      "print",
      JSON.stringify({ ...data, shopName: Profileinfo.shopname })
    );
    const newWindow = window.open(
      `/pages/print?id=${data.tid}&type=DMT / IMPS`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const printReceipt = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let remitterMobile = "";
    // if (mobile.length > 9) {
    //   remitterMobile = mobile;
    // }

    var raw = JSON.stringify({
      UserID: user.ReferId,
      fromdate: dateFormatter(),
      todate: dateFormatter(),
      mobile: remitterMobile,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/retailers/get_retailer_dmt_report.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "0" && result.data) {
            //setData(result.data);
            if (result.data.length > 0) {
              openInNewTab(result.data[0]);
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      show={isOpen}
      onHide={() => setModelState({ ...modalstate, isOpen: false })}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <Modal.Header closeButton>
        <Modal.Title> E Suvidha</Modal.Title>
      </Modal.Header>
      {/* <SweetAlert
      show={popupState}
      title="Are you sure?"
      text="Rs 3 deducted from your Account"
      type="warning"
      showCancelButton
      onConfirm={() => {
        addBeneficiaryVerify();
        setPopupState(false);
      }}
    /> */}

      {isOpen && (
        <Modal.Body
          style={{ width: "100%", display: "block" }}
          className="account"
        >
          <Card style={{ margin: "10px", padding: "10px" }}>
            <div style={{ display: "flex" }}>
              <span>Domestic Money Transfer Limit</span>
              <strong style={{ color: "green", margin: "0 0 0 1rem" }}>
                {details.limit}{" "}
              </strong>
            </div>
          </Card>
          <Card style={{ margin: "10px", padding: "10px" }}>
            <Form.Group>
              <Form.Label>Beneficiary Name</Form.Label>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Beneficiary Name"
                value={benificiary.recipient_name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Beneficiary A/C</Form.Label>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Beneficiary A/C"
                value={benificiary.account}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="AMOUNT"
                style={{ display: "flex", alignItems: "center" }}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  const amt = Number(e.target.value);
                  if (amt < 25000 || amt > 49750) {
                    setAmountError("Kindly enter an amount within the specified range of 25000 to 49750");
                  } else {
                    setAmountError("");
                  }
                  let c = Number(e.target.value) / 100;
                  if (Number(c) < 10) {
                    c = 10;
                  }
                  if (Number(c) > 250) {
                    c = 250;
                  }
                  setCharges(c);
                }}
              />
            </Form.Group>
            <div className="row justify-content-between">
              {amountError && (
                <span
                  style={{ color: "red", padding: "0px 0px 1rem 0px" }}
                >{`${amountError}`}</span>
              )}

              {charges > 0 && (
                <span
                  style={{ color: "red", padding: "0px 0px 1rem 0px" }}
                >{`Charges Rs ${charges}`}</span>
              )}
            </div>

            <Form.Group>
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <span className="input-group-text bg-transparent border-right-0 no-glow">
                    <i className="mdi mdi-lock-outline text-primary"></i>
                  </span>
                </div>
                <input
                  autocomplete="new-password"
                  autoCorrect="off"
                  autoSave="new-password"
                  type={tpinShow ? "text" : "password"}
                  className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                  id="tpin"
                  placeholder="T Pin"
                  value={tPin}
                  onChange={(e) => {
                    setTPin(e.target.value);
                  }}
                />
                <div className="input-group-prepend bg-transparent">
                  <span
                    className="input-group-text bg-transparent border-left-0 no-glow"
                    onClick={showTPinClick}
                    style={{ borderRadius: "4px" }}
                  >
                    <i
                      className={`mdi text-primary ${
                        tpinShow ? "mdi-eye" : "mdi-eye-off"
                      }`}
                    ></i>
                  </span>
                </div>
              </div>
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="rounded-0"
                style={{ margin: "0px 10px", flex: "1" }}
                variant="primary"
                onClick={sendMoney}
                disabled={isLoading}
              >
                {isLoading ? "Transaction Processing.." : "Transfer Now"}
              </Button>
              <Button
                onClick={close}
                className="rounded-0"
                style={{ margin: "0px 10px", flex: "1" }}
                variant="success"
              >
                {" "}
                Cancel
              </Button>
            </div>
          </Card>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default DMTExpressSendMoneyModal;
