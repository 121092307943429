import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Button, Row, Col, Form } from "react-bootstrap";
import swal from "sweetalert";
import { writeFile, utils } from "xlsx";
//import DashboardSidebar from "./DashboardSidebar";
import { Redirect, useHistory } from "react-router";
import { Pagination } from "../../admin/pg-report/PGReportTable";
import { BASE_URL_AGENT, BASE_URL_API_TRANXT_API } from "../../utils/Constants";
import { COLOR_BACKGROUND_BODY } from "../../utils/Themer";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import {
  dateFormatter,
  formatDateDDMMYY,
  formatDateDDMMYYHHMM,
  twoDecimalPlaces,
} from "../../utils/utilFuctions";
import { withRouter } from "react-router-dom";
import { Dropdown, Card } from "react-bootstrap";

import cogoToast from "cogo-toast";
import { validateTPin } from "../../utils/Validations";
import AddFunds from "../../../assets/svg/add_fund.svg";
import Pay_Bill from "../../../assets/svg/pay_bill.svg";
import Send_Money from "../../../assets/svg/send_money.svg";
import Upcomming_Transactions from "../../../assets/svg/upcomming_transactions.svg";
import Electricity from "../../../assets/svg/electricity.svg";
import PrepaidRecharge from "../../../assets/svg/prepaid_recharge.svg";
import { DROP_SHADOW, MONTHS } from "../../utils/CommonConfig";
import Debit from "../../../assets/images/Debit Card.png";
import credit from "../../../assets/images/Credit Card.png";
import Fund from "../../../assets/images/Fund.png";
import wallet from "../../../assets/images/Wallet 2 Wallet.png";
import Retailer from "../../../assets/images/Retailers.png";

export const dropShadow = DROP_SHADOW;


const mapData = {
  BZ: 75.0,
  US: 56.25,
  AU: 15.45,
  GB: 25.0,
  RO: 10.25,
  GE: 33.25,
};


export const Dashboard = () => {
  const InitialValues = { UserName: "" };
  const [formValues, setFormValues] = useState(InitialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState();
  const [sidebarState, setSidebarState] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Provider, setProvider] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [ShopObj, setShopObj] = useState([]);
  const { user } = useContext(GlobalContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [selectedData, setSelectedData] = useState({ id: "", oldBalance: "" });

  const { Profileinfo } = useContext(GlobalContext);
  const [SendotpAdd, setSendotpAdd] = useState(true);
  const [SendotpRev, setSendotpRev] = useState(true);
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (Profileinfo) {
      otpYes();
    }
  }, [Profileinfo]);

  const otpYes = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.ReferId,
        mobile: Profileinfo?.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiservices.bumppypay.com/api/Payment/getUserConfigSettings",

        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        if (data.data.add_balance_otp == "N") {
          setSendotpAdd(false);
        }
        if (data.data.reverse_balance_otp == "N") {
          setSendotpRev(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      provider();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.UserName) {
      errors.UserName = "*User name is required!";
    }
    return errors;
  };

  // const str = sessionStorage.getItem('user');
  // const user = JSON.parse(str);

  const { token, myAccountInfo } = useContext(GlobalContext);
  console.log(myAccountInfo);
  const transactionHistoryData = {
    labels: ["Paypal", "Stripe", "Cash"],
    datasets: [
      {
        data: [55, 25, 20],
        backgroundColor: ["#111111", "#00d25b", "#ffab00"],
      },
    ],
  };

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const provider = () => {
    var raw = JSON.stringify({
      UserID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_get_provider_list.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 0) {
            setProvider(result.data);
            setDataToDisplay(result.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    provider();
  }, []);

  const shoplist = () => {
    var raw = JSON.stringify({
      UserID: user.pk_user_id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    try {
      fetch(
        `${BASE_URL_API_TRANXT_API}/api/distributor/dist_get_shoplist.aspx`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setShopList(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log(localStorage.getItem("accessToken"));
  console.log(token);
  useEffect(() => {
    console.log("calling");
    shoplist();
  }, []);

  const ExportToExcel = (fileName, Provider) => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(Provider);
    utils.book_append_sheet(wb, ws, "sheet");
    writeFile(wb, `${fileName}.xlsx`);
  };

  useEffect(() => {
    let filteredData = Provider.filter((bank) => {
      let b = false;
      if (Provider.length > 0) {
        for (let key in Provider[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = offset - 1;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = Provider.filter((bank) => {
      let b = false;
      if (Provider.length > 0) {
        for (let key in Provider[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * offset;
    const endIdx = currentPage * offset - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  // did mount
  useEffect(() => {
    setPages(Math.floor((Provider.length + (offset - 1)) / offset));
    const startIdx = 0;
    const endIdx = offset - 1;
    setTotal(Provider.length);
    console.log(startIdx);
    const filteredData = Provider.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [Provider, offset]);

  const ShopWithId = () => {
    const getShopsObject = Provider.filter((data) => {
      return formValues.UserName == data.pk_user_id;
    });
    setShopObj(getShopsObject);
  };

  const requestParentFundRequest = async (childId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        sender: user.ReferId,
        receiver: childId,
        type: "1",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_API}/api/user/fund_request_submit.aspx`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Failed", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const reset = () => {
    setShopObj([]);
    setFormValues({ ...formValues, UserName: "" });
  };

  useEffect(() => {
    ShopWithId();
  }, [formValues.UserName]);

  const positionStyle = {
    position: "absolute",
    top: -28,
    left: 0,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
    borderRadius: "0.2rem",
  };
  const positionStyle1 = {
    position: "absolute",
    top: -58,
    left: -15,
    fontFamily: "Poppins",
    color: "rgba(255, 255,255, 1)",
    borderRadius: "0.2rem",
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || context.user.UserType !== "3" ? (
          <Redirect to="/" />
        ) : (
          <React.Fragment>
   <div className="row m-0">
              <QuickAccess />
            </div>

         
  

         
          </React.Fragment>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Dashboard;

const QuickAccess = ({ discription }) => {
  let basicAuth = JSON.parse(sessionStorage.getItem("basic_auth"));
  const [formErrors, setFormErrors] = useState({});
  const [fetchBillModal, setFetchBillModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [Tpin, setTpin] = useState("");
  const [serviceSelected, setServiceSelected] = useState(5);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const {
    token,
    myAccountInfo,
    user,
    setTransactionLoaderState,
    checkWalletBeforeBBPSBillPay,
    Profileinfo,
  } = useContext(GlobalContext);
  const [dashboardInfo, setDashboardInfo] = useState({});
  if (!basicAuth) {
    basicAuth = { username: "Rajneesh@example.com", password: "Test@12345" };
  }
  const { username, password } = basicAuth;
  const [state, setState] = useState({
    provider: "",
    consumer: "",
    mobileNumber: "",
    custparams: "",
  });
  // get all operators

  useEffect(() => {}, [serviceSelected]);
  console.log(state.provider);
  return (
    <div className="bg-white-theme w-100">
      <div
        style={{
          background: "linear-gradient(45deg,#F0F6FB 0%,#F0F6FB 100%)",
          borderRadius: "1rem",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="m-3 rouded-3 p-4"
      >
        <div className="row">
          <h3 className="text-dark">Quick Access</h3>
        </div>
        <div
          className="row rouded-3 justify-content-start m-0 rounded-3"
          style={{ background: "#EAF0F7" }}
        >
          <BBPSIconCard
            redirect={"/distributor/CreditFunds"}
            image={credit}
            title={"Credit"}
            selected={serviceSelected == 1}
          />
          <BBPSIconCard
            redirect={"/distributor/DebitFunds"}
            image={Debit}
            title={"Debit"}
            selected={serviceSelected == 2}
          />
          <BBPSIconCard
            redirect={"/distributor/Report/FundReport"}
            image={Fund}
            title={"Funds"}
            selected={serviceSelected == 2}
          />
          <BBPSIconCard
            redirect={"/distributor/PaymineWallet/WalletToWalletTransfer"}
            image={wallet}
            title={"Wallet to Wallet"}
            selected={serviceSelected == 3}
          />
          <BBPSIconCard
            redirect={"/distributor/view-retailer"}
            image={Retailer}
            title={"Retailer"}
            selected={serviceSelected == 4}
          />
     
        </div>
      </div>
    </div>
  );
};

const BBPSIconCard = ({ image, title, selected, onClick, redirect }) => {
  const history = useHistory();
  const redirectTo = () => {
    if (redirect) history.push(redirect);
  };
  return (
    <div
      className="col-md-2 col-sm-3 col-4 d-flex flex-column justify-content-start align-items-center p-2"
      style={{ background: "transparent" }}
      onClick={() => history.push(redirect)}

    >
      <div
        style={{
          borderRadius: "3rem",
          border: selected ? "2px solid #3C79F5" : "2px solid #FFF",
          background: "#FFF",
          display: "inline-block",
          padding: "1rem",
          boxShadow: dropShadow,
         
        }}
      >
        <img
          src={image}
          style={{ height: "2rem", width: "2rem", color: "#EC5665" }}
          className="bbps_icon"
        />
      </div>

      <span
        className="text-dark text-center mt-2"
        style={{
          fontSize: ".8rem",
          fontWeight: "400",
          fontWeight: selected ? "700" : "400",
        }}
      >
        {title}
      </span>
    </div>
  );
};