import React, { useState ,useEffect,useContext} from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect } from "react-router";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT } from "../../utils/Constants";
import AEPSReportTable from "./AEPSReportTable";

export const columns = [
  {
    dataField: "rch_mobile",
    text: "Mobile",
  },
  {
    dataField: "rch_bank_name",
    text: "Provider",
  },
  {
    dataField: "rch_service",
    text: "Type",
  },
  {
    dataField: "rch_transaction_id",
    text: "UTR NO",
  },

  {
    dataField: "rch_before_balance",
    text: "Before Balance",
  },
  {
    dataField: "rch_amount",
    text: "Amount",
  },
  {
    dataField: "rch_after_balance1",
    text: "After Balance",
  },
  {
    dataField: "rch_com_retailer",
    text: "Comm",
  },
  {
    dataField: "rch_status_desc",
    text: "Status",
  },
  {
    dataField: "rch_indate",
    text: "Date&Time",
  },
];
const RetailerAEPSReport = ({agentId}) => {
  const [reports ,setReports] = useState([]);
  const {user} = useContext(GlobalContext);

  return (
          <div style={{ margin: "10px" }}>
            
            <AEPSReportTable data={reports} columns={columns} agentId={agentId} />
          </div>
  );
};

export default RetailerAEPSReport;
