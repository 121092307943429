import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Error404 extends Component {
  render() {
    return (
      <div>
        <div style={{backgroundColor:'#555555'}}>
        <p style={{color:'white',fontWeight:'bold',fontSize:30}}>Server Error</p>
        </div>
        <div className="border m-3" style={{backgroundColor:'#FFFF'}}>
         <h4 style={{color:'#CC0000',fontWeight:'bold',marginLeft:20}}>404 - File or directory not found.</h4>
         <div>
         <p  style={{color:'#000000',fontWeight:'bold',fontSize:15,marginLeft:20}}>The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
         </div>
        </div>
        {/* <div className="d-flex align-items-center text-center error-page bg-primary pt-5 pb-4 h-100">
          <div className="row flex-grow">
            <div className="col-lg-8 mx-auto text-white">
              <div className="row align-items-center d-flex flex-row">
                <div className="col-lg-6 text-lg-right pr-lg-4">
                  <h1 className="display-1 mb-0">404</h1>
                </div>
                <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>SORRY!</h2>
                  <h3 className="font-weight-light">The page you’re looking for was not found.</h3>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 text-center mt-xl-2">
                  <Link className="text-white font-weight-medium" to="/dashboard">Back to home</Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}

export default Error404
