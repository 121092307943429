import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";

import swal from "sweetalert";

import { basicAuth } from "../../config";

export const columns = [
    {
        dataField: "uName",
        text: "Name",
    },

    {
        dataField: "uCode",
        text: "Code",
    },
    {
        dataField: "logId",
        text: "Mobile",
    },
    {
        dataField: "wallet_bal",
        text: "Wallet Balance",
    },
    {
        dataField: "aeps_bal",
        text: "AEPS Limit",
    },
    {
        dataField: "pg_bal",
        text: "PG Limit",
    },
    {
        dataField: "lien_bal",
        text: "Lien Amount",
    },
    {
        dataField: "status",
        text: "Status",
    },
    {
        dataField: "usParent",
        text: "Parent",
    },
];



const DmtRejectedTransactions = () => {
    const [data, setData] = useState([]);
    const { user } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [details, setDetails] = useState({});
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    // const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpen4, setModalOpen4] = useState(false);
    // const [modalOpen3, setModalOpen3] = useState(false);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const { Profileinfo, setTransactionLoaderState } = useContext(GlobalContext);
    console.log(data);



    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    const getAllCreditRequests = async () => {
        try {
            setTransactionLoaderState(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                userid: user.ReferId
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/DMT/YesBank/Main/getRejectedTransactions`,
                requestOptions
            );
            const data = await res.json();
            if (data.responseCode == "00") {
                setData(data.data);
            } else {
                setData([]);
            }
            console.log(data);
            setTransactionLoaderState(false);
        } catch (error) {
            setTransactionLoaderState(false);
            console.log("error", error);
        }
    };

    const GenerateRefundOTP = async (transaction_id, customer_mobile, agent_id, parner_id, orderid, sorTxnId) => {
        const { username, password } = basicAuth;
        try {

            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                txnId: transaction_id,
                customerMob: customer_mobile,
                agentId: agent_id,
                partnerId: parner_id,
                orderId: orderid,
                channel: "1",
                sortxnId: sorTxnId,
                narration: "Test",
                udf1: "",
                udf2: "",
                udf3: "",
                udf4: "",
                udf5: "",
                hashKey: ""
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://partners.paynnow.com/api/Transactions/GenerateRefundOTP",
                requestOptions
            );
            const data = await res.json();

            if (data.responseCode === '00') {
                setDetails({ transaction_id, customer_mobile, agent_id, parner_id, orderid, sorTxnId });
                setModalOpen4(true)
            } else {
                swal({
                    title: 'Error',
                    text: 'Something went wrong',
                    icon: 'error',
                });
            }
        } catch (error) {
            swal({
                title: 'Error',
                text: 'Something went wrong',
                icon: 'error',
            });
        }
    };



    useEffect(() => {
        getAllCreditRequests();
    }, []);

    return (
        <>
            <div className="card-body m-0 p-2">
                <OtpModel
                    details={details}
                    isModalOpen={modalOpen4}
                    setModalOpen={(modalState) => {
                        setModalOpen4(modalState);
                    }}
                />
                <div className="row m-0 p-0 pt-1 align-items-center">
                    <div className="col-4 m-0 p-0">
                        <div
                            className="m-0 p-0 pl-2"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "32px",
                                fontWeight: "600",
                                lineHeight: "48px",
                                letterSpacing: "0em",
                                textAlign: "left",
                                padding: "0px",
                                background: "#FFFFFF",
                                color: "#4267B2",
                                fontWeight: "bolder",
                                fontfamily: "Poppins !impotent",
                                fontSize: "28px",
                            }}
                        >
                            Rejected Transactions
                        </div>
                    </div>


                </div>
                <hr className="mt-2 p-0" />
                <div className=" my-table scroll-inner admin-fix-width transparent-table mt-2 ">
                    <table
                        style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                        className=" my-table borderless transparent-table"
                    >
                        <tr
                            className="my-table transparent-table text-center border-bottom "
                            style={{ fontFamily: "Poppins", backgroundColor: "#FFFFFF" }}
                        >
                            <th
                                className=" py-3 my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Sr"}
                            </th>

                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Transaction Id"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Customer Mobile"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Amount"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Order Id"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"RRN"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Status"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Acc No"}
                            </th>

                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Beneficiary Mobile"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Refund Status"}
                            </th>
                            <th
                                className="my-table transparent-table text-center border-bottom p-0 m-0 "
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.9rem",
                                    backgroundColor: "#FFFFFF",
                                    color: "#4267B2",
                                    textalign: "center",
                                }}
                            >
                                {" "}
                                {"Action"}
                            </th>
                        </tr>
                        {dataToDisplay.map((e, ind) => (
                            <tr className="my-table transparent-table border-bottom py-5 p-0 m-0 ">
                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">{ind + 1}</div>
                                </td>
                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">{e.transaction_id} </div>
                                </td>
                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">{e.customer_mobile}</div>
                                </td>
                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">{e.amount} </div>
                                </td>
                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">SOR Tran ID : {e.sorTxnId}</div>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">Order ID {e.orderid}</div>
                                </td>
                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">{e.rrn} </div>
                                </td>
                                <td className="text-center"
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: "0.8rem",
                                    }}>
                                    <div className="d-flex flex-column align-items-center m-0" >
                                        {(e.txn_status == "ACTIVE" || e.txn_status == "Processing" || e.txn_status == "InComplete" || e.txn_status == "Initiated") ? (
                                            <span
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid",
                                                    backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                    color: "rgba(43, 193, 85, 1)",
                                                    bordercolor: "rgba(43, 193, 85, 1)",
                                                }}
                                                className="p-2"
                                            >
                                                {`${e.txn_status}`}
                                            </span>
                                        ) : e.txn_status === "REJECTED" ? (
                                            <span
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid",
                                                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                                                    color: " rgba(255, 0, 0, 1)",
                                                    bordercolor: " rgba(255, 0, 0, 1)",
                                                }} className="p-2"
                                            >
                                                {`${e.txn_status}`}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid",
                                                    backgroundColor: "rgba(254, 128, 36, 0.1)",
                                                    color: "rgba(254, 128, 36, 1)",
                                                    bordercolor: "rgba(254, 128, 36, 1)",
                                                }} className="p-2"
                                            >
                                                {`${e.txn_status}`}
                                            </span>
                                        )}
                                    </div>
                                </td>

                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">ACC NO {e.acc_no}</div>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">IFSC CODE : {e.ifsc}</div>
                                </td>
                                <td style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}>
                                    <div className="d-flex flex-column align-items-center p-0 m-0 ">{e.bene_mobile} </div>
                                </td>
                                <td
                                    className="text-center"
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: "0.8rem",
                                    }}
                                >
                                    <div className="d-flex flex-column align-items-center m-0">
                                        {e.refund_status === "N" ? (
                                            <button
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid",
                                                    backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                    color: "rgba(43, 193, 85, 1)",
                                                    borderColor: "rgba(43, 193, 85, 1)",
                                                }}
                                                className="p-2"
                                            >
                                                Eligible for Refund
                                            </button>
                                        ) : (
                                            <span
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "5px",
                                                    border: "1px solid",
                                                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                                                    color: "rgba(255, 0, 0, 1)",
                                                    borderColor: "rgba(255, 0, 0, 1)",
                                                }}
                                                className="p-2"
                                            >
                                                Refunded
                                            </span>
                                        )}
                                    </div>
                                </td>

                                <td style={{ fontFamily: "Poppins", width: "8rem", }}>
                                    <Button style={{
                                        borderRadius: "5px",
                                        border: "1px none",
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        color: 'rgba(255, 164, 27, 1)',
                                        bordercolor: 'rgba(255, 255, 255, 1)',
                                        "fontSize": "4px !important",
                                    }}
                                        className="btn rounded-100  m-0 p-0"
                                        onClick={() => {
                                            // setDetails(e);
                                            // setModalOpen4(true)
                                            GenerateRefundOTP(e.transaction_id, e.customer_mobile, e.agent_id, e.parner_id, e.orderid, e.sorTxnId)
                                        }} >
                                        <div className="hover-container">
                                            <img src={require("../../../assets/images/Refund.png")} style={{ width: "2.1rem", marginLeft: "2.5rem" }} />
                                        </div>
                                    </Button>

                                    {/* <Button
                                                style={{
                     
                                                    borderRadius: "5px",
                                                    border: "1px none",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    color: "rgba(255, 164, 27, 1)",
                                                    bordercolor: "rgba(255, 255, 255, 1)",
                                                }}
                                                className="btn btn-sm rounded-100 m-0 p-1"

                                                onClick={() => {
                                                    setDetails(e);
                                                    setModalOpen4(true)
                                                }}
                                            >
                                                {" "}
                                                <div className="hover-container">
                                                    <div >
                                                        <img
                                                            src={require("../../../assets/images/printer.png")}
                                                            alt="Clickable Image"
                                                        />
                                                    </div>
                                                    <div
                                                        style={positionStyle1}
                                                        className="hover-text bg-dark "
                                                    >
                                                        Dowload Marcom
                                                    </div>
                                                </div>
                                            </Button> */}

                                </td>

                            </tr>
                        ))}
                        {/* {data.length <= 0 && <NoDataFound />} */}
                    </table>
                </div>
                <Pagination
                    page={currentPage}
                    limit={pages}
                    callback={(page) => {
                        setCurrentPage(page);
                    }}
                    count={dataToDisplay.length}
                    total={total}
                    callback2={(offsetValue) => {
                        setOffset(offsetValue);
                    }}
                />
            </div>
        </>
    );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>
            <div className="d-flex btn-group">
                <select
                    className="rounded-0 form-select-sm"
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset}>{offset}</option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {"Previous"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}
                <Button variant="primary"> {page.toString()}</Button>
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {"Next"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

const OtpModel = ({ details, isModalOpen, setModalOpen, refresh }) => {
    const [state, setState] = useState({
        otp: "",
    });
    const { user, setTransactionLoaderState } = useContext(GlobalContext)
    const [data, setData] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [otp, setOtp] = useState(new Array(4).fill(""));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    console.log(state);

    const RefundTransaction = async (transaction_id, customer_mobile, agent_id, parner_id, orderid, sorTxnId) => {
        const { username, password } = basicAuth;
        try {

            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Basic " + Buffer.from(username + ":" + password).toString("base64")
            );
            myHeaders.append("Content-Type", "application/json");

            console.log({
                txnId: transaction_id,
                customerMob: customer_mobile,
                agentId: agent_id,
                partnerId: parner_id,
                orderId: orderid,
                channel: "1",
                sortxnId: sorTxnId,
                narration: "Test",
                udf1: "",
                udf2: "",
                udf3: "",
                udf4: "",
                udf5: "",
                hashKey: "",
                partnerStateCode: "27",
                partnerDistrictCode: "600",
                agentStateCode: "27",
                agentDistrictCode: "600",
                customerStateCode: "27",
                customerDistrictCode: "600",
                otp: state.otp
            });

            var raw = JSON.stringify({
                txnId: transaction_id,
                customerMob: customer_mobile,
                agentId: agent_id,
                partnerId: parner_id,
                orderId: orderid,
                channel: "1",
                sortxnId: sorTxnId,
                narration: "Test",
                udf1: "",
                udf2: "",
                udf3: "",
                udf4: "",
                udf5: "",
                hashKey: "",
                partnerStateCode: "27",
                partnerDistrictCode: "600",
                agentStateCode: "27",
                agentDistrictCode: "600",
                customerStateCode: "27",
                customerDistrictCode: "600",
                otp: state.otp
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://apiservices.bumppypay.com/api/DMT/YesBank/Main/refundRejectedTransaction",
                requestOptions
            );
            const data = await res.json();
            if (data.status == "00") {
                swal("OTP Submitted Successful", data.msg, "success");

            } else {
                swal("Incorrect OTP", data.msg, "error");
            } setTransactionLoaderState(false)
        } catch (error) {
            setTransactionLoaderState(false)
            console.log("error", error);
            swal("Somthing went wrong", "Error From Server", "error");
        }
    };


    return (
        <Modal
            show={isModalOpen}
            onHide={() => setModalOpen(false)}
            aria-labelledby="example-modal-sizes-title-md"
            size="md"
        >
            <Modal.Body style={{ width: "100%", display: "block" }}>

                <div className="card-body">
                    <div className="d-flex justify-content-between"
                    >
                        <div className=""
                            style={{
                                "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                                , padding: "0px",
                                background: "#FFFFFF",
                                color: "#4267B2",
                                fontWeight: "bolder",
                                fontfamily: "Poppins !impotent",
                                fontSize: "28px",
                                marginLeft: "12px"
                            }}
                        >
                            Enter OTP
                        </div>
                        <strong
                            style={{
                                padding: "12px",
                                background: "#FF",
                                color: "black",
                                fontWeight: "bolder",
                            }}
                            className="px-1 hover"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {" "}
                            X
                        </strong>
                    </div>


                    <div className="row ">
                        <div className="col-12">
                            <Form.Group>
                                <Form.Control
                                    type="Number"
                                    className="form-control rounded-lg"
                                    aria-label="Notes"
                                    aria-describedby="basic-addon1"
                                    placeholder="Enter OTP"
                                    value={state.otp}
                                    onChange={(e) => {
                                        setState({ ...state, otp: e.target.value });
                                    }}
                                    style={{ borderRadius: "0px" }}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-12">
                            <Button
                                className="rounded-10 btn-block  "
                                variant="primary"
                                onClick={() => {
                                    const { transaction_id, customer_mobile, agent_id, parner_id, orderid, sorTxnId } = details;
                                    RefundTransaction(transaction_id, customer_mobile, agent_id, parner_id, orderid, sorTxnId)
                                }}
                            >
                                {" "}
                                Submit
                            </Button>
                        </div>
                    </div>



                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DmtRejectedTransactions;