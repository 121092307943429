import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { excelMapping, ExportToExcel } from "../../utils/utilFuctions";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_APISERVICES } from "../../utils/Constants";
import { offsetArr } from "../../utils/CommonConfig";
import Select from "react-select";
import { NoDataFound } from "../bbps-report/BBPSReportTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import swal2 from "@sweetalert/with-react";
export const columns = [
    {
        dataField: "rch_transaction_id",
        text: "Transaction ID",
    },
    {
        dataField: "user_name",
        text: "Sender",
    },
    {
        dataField: "receiver_name",
        text: "Receiver",
    },
    {
        dataField: "rch_before_balance",
        text: "Opening Bal",
    },
    {
        dataField: "rch_amount",
        text: "Transaction Amount",
    },
    {
        dataField: "rch_charges",
        text: "Charges",
    },
    {
        dataField: "gst",
        text: "GST",
    },
    {
        dataField: "rch_after_balance",
        text: "Closing Bal",
    },
    {
        dataField: "sales_user_name",
        text: " Sales User",
    },
    {
        dataField: "rch_status_desc",
        text: "Status",
    },
    {
        dataField: "rch_indate",
        text: "Date",
    },
];

const userTypes = [
    { label: "Debit", value: "2" },
    { label: "Credit", value: "1" },
];

const positionStyle1 = {
    position: 'absolute',
    top: -38,
    left: 0,
    "fontFamily": "Poppins", color: 'rgba(255, 255,255, 1)',
};

const WalletToWalletTable = ({agentId}) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
   // const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [mobile, setMobile] = useState("");
    const [inputState, setInputState] = useState({
        userType: { label: "Debit", value: "1" },
    });
    const [offset, setOffset] = useState(10);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { Profileinfo } = useContext(GlobalContext);
    console.log(data);

    const now = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };

    const showResponse = (response) => {
        swal2(
            <div className="d-flex overflow-auto">
                <table>
                    <tr>
                        {Object.keys(response).map((e) => (
                            <td>{e}</td>
                        ))}
                    </tr>
                </table>
            </div>
        );
    };

    const openInNewTab = (data) => {
        localStorage.setItem(
            "print",
            JSON.stringify({ ...data, shopName: Profileinfo.shopname })
        );
        const newWindow = window.open(
            `/pages/print?id=${data.tid}&type=DMT`,
            "",
            "height=600,width=800'"
        );
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        setPages(Math.floor((filteredData.length + (offset - 1)) / offset));
        setTotal(filteredData.length);
        const startIdx = 0;
        const endIdx = offset - 1;
        filteredData = filteredData.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        setCurrentPage(1);
    }, [searchText]);

    useEffect(() => {
        let filteredData = data.filter((bank) => {
            let b = false;
            if (data.length > 0) {
                for (let key in data[0]) {
                    if (
                        bank[key] &&
                        bank[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchText.toString().toLowerCase())
                    ) {
                        b = true;
                    }
                }
            }
            return b;
        });
        console.log(filteredData);
        const startIdx = (currentPage - 1) * offset;
        const endIdx = currentPage * offset - 1;
        console.log({ startIdx, endIdx });
        filteredData = filteredData.filter((bank, idx) => {
            console.log(idx, idx <= endIdx && idx >= startIdx);
            return idx <= endIdx && idx >= startIdx;
        });
        setDataToDisplay(filteredData);
        console.log({ startIdx, endIdx });
    }, [currentPage]);

    // did mount
    useEffect(() => {
        setPages(Math.floor((data.length + (offset - 1)) / offset));
        const startIdx = 0;
        const endIdx = offset - 1;
        setTotal(data.length);
        console.log(startIdx);
        console.log(data);
        const filteredData = data.filter((bank, idx) => {
            return idx <= endIdx && idx >= startIdx;
        });
        console.log(filteredData);
        setDataToDisplay(filteredData);
    }, [data, offset]);

    function getDatePreviousMonth(date = new Date()) {
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    const dateFormatter = (date) => {
        return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
            }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
    };
    const twoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).toString();
    };

    const getWalletReport = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                adminid: agentId,
                fromdate: dateFormatter(startDate),
                todate: dateFormatter(endDate),
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${BASE_URL_APISERVICES}/api/Payment/admin/walletToWalletReport`,
                requestOptions);
               
  
          const data = await res.json();

          if(data.status=="0"){
            setData(data.data);
          }
         // setData(result);
        } catch (error) {
          setError(error.message);
        }
      };
  
        

    useEffect(() => {
        getWalletReport();
    }, []);




    const searchBarStyle = {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '6px',
        padding: '0px',
        backgroundColor: '#fff',
        width: '25%', // Adjust the width as needed
        height: '20%',

    };
    const inputStyle = {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        fontSize: '16px',
        marginLeft: '8px',
        width: '100%',
        "fontFamily": "Poppins",
        "fontWeight": "400",
    };
    const iconStyle = {
        color: 'rgba(0, 0, 0, 0.5)',
    };


    return (
        <>
            <div className="row m-0 p-0  pt-1 align-items-center">
                <div className="col-4 m-0 p-0">

                    <div className="m-0 p-0 pl-2"
                        style={{
                            "fontFamily": "Poppins", "fontSize": "32px", "fontWeight": "600", "lineHeight": "48px", "letterSpacing": "0em", "textAlign": "left"
                            , padding: "0px",
                            background: "#FFFFFF",
                            color: "#4267B2",
                            fontWeight: "bolder",
                            fontfamily: "Poppins !impotent",
                            fontSize: "28px",
                        }}
                    >
                        Wllet to Wallet Report
                    </div>
                </div>

                <div className="col-8 m-0 p-0 ">
                    <div className="row  m-0 p-0  justify-content-end">

                        <div style={searchBarStyle} className="bg-light m-0 p-0 px-1">
                            <FontAwesomeIcon icon={faSearch} style={iconStyle} className="ml-2 " />
                            <input
                                type="text"
                                className="form-control "
                                placeholder="Search"
                                aria-label="Search"
                                style={inputStyle}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                value={searchText}
                            />
                        </div>

                        <div className=" pl-2 pr-1 p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group className="p-0 m-0">

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="Start Date"
                                />
                            </Form.Group>
                        </div>
                        <div className="px-1  p-0 m-0" style={{ width: '18%', height: '20%', }}>
                            <Form.Group className="p-0 m-0">

                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="form-control rounded-100"
                                    placeholderText="End Date"
                                />
                            </Form.Group>
                        </div>

                 
                        <div className="pl-1   p-0 m-0" style={{ width: '14%', height: '20%', }}>
                            <Form.Group className="mt-0 p-0 m-0">
                                <Button
                                    className="rounded-50 btn btn-primary btn-lg btn-block "

                                onClick={getWalletReport}
                                >
                                    {"Search"}
                                </Button>
                            </Form.Group>
                        </div>
                        <div className="m-0 p-0 hover-container "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                ExportToExcel(
                                    `walletReportTable${now()}`,
                                    excelMapping(dataToDisplay, columns)
                                );
                            }}
                        >
                            <img className="pl-1"
                                src={require("../../../assets/images/download Icon.png")}
                                style={{ width: `3.5rem`, height: '85%' }}
                            />
                            <div style={{ color: 'rgba(255, 255,255, 1)', }} className="hover-text bg-dark ">Export to Excel</div>
                        </div>
                    </div>




                </div>

            </div>

            <hr className="m-0 p-0" />


            <div className=" my-table scroll-inner admin-fix-width transparent-table">
                <table style={{ width: "100%", backgroundColor: '#FFFFFF' }} className="my-table  borderless transparent-table">
                    <tr className="my-table transparent-table text-center border-bottom " style={{ backgroundColor: '#FFFFFF', }}>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }}> {"Sr"}</th>

                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Transection Id"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Sender"}
                        </th>

                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Receiver"}
                        </th>

                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Opening Bal"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"transection Amount"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Charges"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"GST"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Closing Bal"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Sales User"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Status"}
                        </th>
                        <th className="my-table transparent-table text-center border-bottom" style={{ "fontFamily": "Poppins", backgroundColor: '#FFFFFF', }} >
                            {" "}
                            {"Date"}
                        </th>

                    </tr>
                    {data &&
            data.map((e, ind) => (
                    
                        <tr className="my-table transparent-table border-bottom py-5">
                            <td style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {" "}
                                <div className="d-flex flex-column align-items-center py-3 p-0 m-0">
                                    {ind + 1}
                                </div>
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {e.rch_transaction_id}
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                <div>{e.user_name}</div>
                                <div>{e.user_code}</div>
                                <div>{e.rch_mobile}</div>
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                <div>{e.receiver_name}</div>
                                <div>{e.receiver_code}</div>
                                <div>{e.receiver_mobile}</div>
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {e.rch_before_balance}
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {e.rch_amount}
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {e.rch_charges}
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {e.gst}
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {e.rch_after_balance}
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                <div>{e.sales_user_name}</div>
                                <div>{e.sales_user_code}</div>
                                <div>{e.sales_user_mobile}</div>
                            </td>
                            <td
                                className="text-center m-0 p-0 py-1"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "center",
                                }}
                            >
                                <div>
                                    {e.rch_status_desc == "Success" ? (
                                        <span
                                            style={{
                                                borderRadius: "5px",
                                                border: "1px solid",
                                                backgroundColor: "rgba(43, 193, 85, 0.1)",
                                                color: "rgba(43, 193, 85, 1)",
                                                bordercolor: "rgba(43, 193, 85, 1)",
                                            }}
                                            className="px-2 py-2"
                                        >
                                            {`${e.rch_status_desc}`}
                                        </span>
                                    ) : e.rch_status_desc == "Fail" ? (
                                        <span
                                            style={{
                                                borderRadius: "5px",
                                                border: "1px solid",
                                                backgroundColor: "rgba(255, 46, 46, 0.1)",
                                                color: "rgba(255, 46, 46, 1)",
                                                bordercolor: "rgba(255, 46, 46, 1)",
                                            }}
                                            className="px-2 py-2"
                                        >
                                            {`${e.rch_status_desc}`}
                                        </span>
                                    ) : (
                                        <span
                                            style={{
                                                padding: "5px",
                                                borderRadius: "5px",
                                                border: "1px solid",
                                                backgroundColor: "rgba(254, 128, 36, 0.1)",
                                                color: "rgba(254, 128, 36, 1)",
                                                bordercolor: "rgba(254, 128, 36, 1)",
                                            }}
                                            className="px-4 py-2"
                                        >
                                            {`${e.rch_status_desc}`}
                                        </span>
                                    )}
                                </div>
                            </td>
                            <td className=" text-center" style={{ "fontFamily": "Poppins", fontSize: "0.8rem" }}>
                                {e.rch_indate}
                            </td>
                        

                        </tr>
                ))}
                
                </table>
                {error && <p>Error: {error}</p>}
            </div>
            <Pagination
                page={currentPage}
                limit={pages}
                callback={(page) => {
                    setCurrentPage(page);
                }}
                count={dataToDisplay.length}
                total={total}
                callback2={(offsetValue) => {
                    setOffset(offsetValue);
                }}
            />
        </>
    );
};

const Pagination = ({ page, limit, callback, total, count, callback2 }) => {
    console.table({ page, limit, callback });
    return (
        <div
            className="row"
            style={{
                display: "flex",
                alignItems: "end",
                width: "100%",
                margin: "10px 0px",
                justifyContent: "space-between",
            }}
        >
            <div>
                <strong>{`Showing ${count} of ${total} entries`}</strong>
            </div>
            <div className="d-flex btn-group">
                <select
                    className="rounded-0 form-select-sm"
                    aria-label="offset"
                    onChange={(e) => {
                        callback2(e.target.value);
                    }}
                >
                    {offsetArr.map((offset) => (
                        <option value={offset}>{offset}</option>
                    ))}
                </select>
                {page - 2 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {"Previous"}
                    </Button>
                ) : null}

                {page - 1 > 0 ? (
                    <Button
                        onClick={() => {
                            callback(page - 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {" "}
                        {(page - 1).toString()}
                    </Button>
                ) : null}
                <Button variant="primary"> {page.toString()}</Button>
                {page + 1 <= limit ? (
                    <Button
                        onClick={() => {
                            callback(page + 1);
                        }}
                        variant="light"
                        className="rounded-0"
                    >
                        {"Next"}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default WalletToWalletTable;